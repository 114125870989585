export const formInitialValues = {
  resourceTypeId: "",
  ueOrCounterpart: "",
  description: "",
  workingDays: 0,
  perDiemDays: 0,
  estimatedCost: 0,
  totalMoney: 0,
  file: null
};
  