import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledNumberCircle, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  StyledSubtitle,
  StyledText,
} from 'components/Forms/Form4/Section6/Section6.styled';
import Form from 'react-bootstrap/Form';
import TextValue from 'components/TextValue';

const Section6 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`6 - ${t('F1_S6_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <StyledSubtitle>{t('F1_S6_Subtitle_1')}</StyledSubtitle>

        <Form.Group>
          <StyledText>{t('F1_S6_Question_1')}:</StyledText>
          <TextValue value={formValues.name} />
        </Form.Group>  

        <Form.Group style={{display: 'flex'}}>
          <div style={{float: 'left'}}>{t('F1_S6_Question_2')}:</div>
          <TextValue value={formValues.description} />
        </Form.Group>  

        <Form.Group style={{display: 'flex'}}>
          <div style={{float: 'left'}}>{t('F1_S6_Question_3')}:</div>
          <TextValue value={formValues.objective} />
        </Form.Group>  

        <StyledSubtitle>{t('F1_S6_Subtitle_2')}</StyledSubtitle>

        <Form.Group>
          <StyledText>{t('F1_S6_Question_8')}:</StyledText>
          <TextValue value={formValues.contribution} />
        </Form.Group>  
        
        <Form.Group>
          <StyledText>{t('F1_S6_Question_4')}:</StyledText>
          <TextValue value={formValues.latamKnowledge} />
        </Form.Group>  

        <Form.Group>
          <StyledText>{t('F1_S6_Question_5')}:</StyledText>
          <TextValue value={formValues.euKnowledge} />
        </Form.Group>  

        <Form.Group>
          <StyledText>{t('F1_S6_Question_6')}:</StyledText>
          <TextValue value={formValues.newKnowledge} />
        </Form.Group>  

        <Form.Group>
          <StyledText>{t('F1_S6_Question_7')}:</StyledText>
          <TextValue value={formValues.application} />
        </Form.Group>  

        <Form.Group>
          <StyledText>{t('F1_S6_Question_9')}:</StyledText>
          <TextValue value={formValues.leap} />
        </Form.Group>  
          
        <StyledSubtitle>{t('F1_S6_Subtitle_3')}</StyledSubtitle>

        <Form.Group>
          <StyledText>{t('F1_S6_Question_10')}:</StyledText>
          <TextValue value={formValues.benEntities} />
        </Form.Group>  

        <Form.Group>
          <StyledText>{t('F1_S6_Question_11')}:</StyledText>
          <TextValue value={formValues.directBen} />
        </Form.Group>  

        <StyledSubtitle>{t('F1_S6_Subtitle_4')}</StyledSubtitle>

        <StyledText> {t('F3_S6_Subtitle_4_intro')} </StyledText>

        <StyledSubtitle> {t('F1_S6_Subtitle_5')}  </StyledSubtitle>

        <Form.Group>
          <StyledText>{t('F1_S6_Question_16')}:</StyledText>
          <TextValue value={formValues.rights} />
        </Form.Group> 

        <Form.Group>
          <StyledText>{t('F1_S6_Question_17')}:</StyledText>
          <TextValue value={formValues.environment} />
        </Form.Group> 

        <StyledSubtitle> {t('F1_S6_Subtitle_6')} </StyledSubtitle>

        <Form.Group>
          <StyledText>{t('F1_S6_Question_18')}:</StyledText>
          <TextValue value={formValues.innovation} />
        </Form.Group> 

        <StyledSubtitle> {t('F1_S6_Subtitle_7')} </StyledSubtitle>

        <Form.Group>
          <StyledText>{t('F1_S6_Question_19')}:</StyledText>
          <TextValue value={formValues.valueTC} />
        </Form.Group> 

        <Form.Group>
          <StyledText>{t('F1_S6_Question_20')}:</StyledText>
          <TextValue value={formValues.valueEU} />
        </Form.Group> 

        <Form.Group>
          <StyledText>{t('F1_S6_Question_21')}:</StyledText>
          <TextValue value={formValues.valueTP} />
        </Form.Group> 

        <StyledSubtitle> {t('F1_S6_Subtitle_8')} </StyledSubtitle>

        <Form.Group>
          <StyledText>{t('F1_S6_Question_22')}:</StyledText>
          <TextValue value={formValues.covid19} />
        </Form.Group> 
      </StyledBody>
    </SectionContainer>
  )
}


export default Section6;