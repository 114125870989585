import React, { useState, useEffect } from 'react'
import { getBeneficiaryByCountrySummary } from 'services/initiative'

import Header2 from '../components/Header2'
import Table2 from '../components/Table2'
import Row from 'react-bootstrap/Row'
import { StyledDiv, StyledRow, StyledRow2 } from '../../CommonStyled/Tabs.styled'
import { getFundsBeneficiarySummaryByCountry } from 'services/fundsStatistics'
import Table6 from 'pages/statistics/Entities/components/Table6'
import { useTranslation } from 'react-i18next'
import { T3RenderRows } from '../helpers/helpers'

const BeneficiaryByCountrySummary = () => {
  const {t } = useTranslation()

  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const [data2, setData2] = useState(null)
  const [waiting2, setWaiting2] = useState(false)

  const getData = async () => {
    setWaiting(true)
    try {
      let response = await getBeneficiaryByCountrySummary()
      response = response.filter(
        (item) =>
          Object.values(item).reduce(
            (acum, field) => (typeof field === 'number' ? acum + field : 0),
            0,
          ) !== 0,
      )
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  const getData2 = async () => {
    setWaiting2(true)
    try {
      let response = await getFundsBeneficiarySummaryByCountry()
      response = response.filter(
        (item) =>
          Object.values(item).reduce(
            (acum, field) => (typeof field === 'number' ? acum + field : 0),
            0,
          ) !== 0,
      )
      setData2(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting2(false)
    }
  }


  useEffect(() => {
    getData()
    getData2()
  }, [])

  return (
    <StyledDiv>
      <Row>
        <Header2 data={data} data2={data2} waiting={waiting} />
      </Row>
      <StyledRow style={{ marginTop: '-72px' }}>
        <Table2 data={data ? data : null} waiting={waiting} setData={setData} />
      </StyledRow>
      <StyledRow2 style={{marginLeft: '0px'}}>
        <Table6
          title={t('Statistics_beneficiaries_tab3')}
          data={data2}
          waiting={waiting2}
          renderRows={T3RenderRows}
          setData={setData2}
          customWidth='62%'
          customWidthRow1='84%'
          customWidthRow2='16%'
          orderField='country'
          total={true}
        />
      </StyledRow2>
    </StyledDiv>
  )
}

export default BeneficiaryByCountrySummary
