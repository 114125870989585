import axiosInstance from './axiosInstance';

export const contactSupport = async (data) => {
  const url = `/api/support/contact`;
  try {  
    const response = await axiosInstance.post(url, data); 
    return response.data;
  } catch (error) {
    throw error;
  }
}


