import * as Yup from 'yup';
import i18n from 'i18n';

const urlReg = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

export const validationSchema = Yup.object().shape({
  objective: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F1_S6_validation8')
    .nullable(true),
});
