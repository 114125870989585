import styled, {css} from 'styled-components';

export const RoundedRemoveDiv = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.brandColors.red};
    color: white;
    width: 15px;
    height: 15px;
    border: none;
    border-radius: 10px;
    display: flex,
    align-items: center;
    justify-content: center;
  `}
`;