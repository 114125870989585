import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { formInitialValues } from './GeneralInfoForm.initialValues'
import { validationSchema } from './GeneralInfoForm.validations'
import { useTranslation } from 'react-i18next'
import { saveGeneralInfoF3S7 } from 'services/initiative'
import { useParams } from 'react-router-dom'
import { parseDateString } from 'helpers/dateHelpers'
import HelpIcon from 'components/HelpIcon'

import { useConstantsForm1 } from 'customHooks/useConstantsForm1'
import { PrimaryButton } from 'components/Buttons'
import { StyledFormLabel } from '../Section7.styled'
import TextValue from 'components/TextValue'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ErrorButton, StyledSendButton } from 'components/Buttons'
import { Form } from 'react-bootstrap'
import {
  RequiredMark,
  SeparatorDotted,
  CenteredModalButtonContainer,
} from 'components/CommonStyled'
import { RadioLabel } from 'components/Forms/Forms.styled'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const GeneralInfoForm = ({
  handleCloseOk,
  handleClose,
  activity,
  handleCloseSubModalOnSave,
  handleCloseSubModalOnError,
  nonEditable,
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const { filteredCountries } = useConstantsForm1()

  const onSave = async (values) => {
    try {
      await saveGeneralInfoF3S7(id, activity.id, values)
      handleCloseSubModalOnSave()
    } catch (error) {
      handleCloseSubModalOnError()
    }
  }

  const handleChangeRadio = (e, values, setFieldValue) => {
    setFieldValue('format', e?.target?.value)
    if (e?.target?.value === '2') {
      setFieldValue('countryId', '')
      setFieldValue('stateName', '')
      setFieldValue('cityName', '')
      setFieldValue('countriesAdditional', '')
    }
  }

  useEffect(() => {
    const formValues = { ...formInitialValues, ...activity }
    formValues.fromDate = formValues.fromDate
      ? parseDateString(formValues.fromDate)
      : ''
    formValues.toDate = formValues.toDate
      ? parseDateString(formValues.toDate)
      : ''
    formValues.countryId = formValues?.country?.id
    setInitialValues(formValues)
  }, [])

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <StyledFormLabel xs={4}>{t('F1_S7_type')}:</StyledFormLabel>
              <TextValue
                value={
                  values.activityType
                    ? t(`activity_types_${values.activityType.id}`)
                    : ' - '
                }
              />
            </Form.Group>

            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label column xs={2}>
                {t('F1_S7_start_day')} <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col>
                <DatePicker
                  style={{ marginLeft: '0', marginTop: '5px' }}
                  dateFormat="dd/MM/yyyy"
                  selected={values.fromDate}
                  onChange={(date) => setFieldValue('fromDate', date)}
                  className={
                    touched.fromDate && errors.fromDate ? 'error' : null
                  }
                  disabled={nonEditable}
                />
                <FormErrorMessage
                  touched
                  errors={errors.fromDate}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label column xs={2}>
                {t('F1_S7_End_day')} <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col>
                <DatePicker
                  style={{ marginLeft: '0', marginTop: '5px' }}
                  dateFormat="dd/MM/yyyy"
                  selected={values.toDate}
                  onChange={(date) => setFieldValue('toDate', date)}
                  className={touched.toDate && errors.toDate ? 'error' : null}
                  disabled={nonEditable}
                />
                <FormErrorMessage
                  touched
                  errors={errors.toDate}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Col>
                <Form.Label>
                  {t('F1_S7_Short_description')} <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  style={{ resize: 'none', marginRight: '18px' }}
                  value={values.description}
                  placeholder={t('Maximum_1300')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="description"
                  disabled={nonEditable}
                  className={
                    touched.description && errors.description ? 'error' : null
                  }
                />
                <FormErrorMessage
                  touched={touched.description}
                  errors={errors.description}
                />
              </Col>
            </Form.Group>

            <SeparatorDotted />

            <Form.Group as={Row}>
              <Form.Label column lg={4}>
                {t('F1_S7_Format')} <RequiredMark>*</RequiredMark>:
                <HelpIcon info={t('F1_S7_Format_INFO')} />
              </Form.Label>
              <Col lg={8} style={{ marginTop: '20px' }}>
                <RadioLabel>
                  <input
                    type="radio"
                    value="0"
                    checked={String(values.format) === '0'}
                    onChange={(e) =>
                      handleChangeRadio(e, values, setFieldValue)
                    }
                    style={{ marginRight: '10px' }}
                    disabled={nonEditable}
                  />
                  {t('F1_S7_Format0')}
                </RadioLabel>

                <RadioLabel>
                  <input
                    type="radio"
                    value="1"
                    checked={String(values.format) === '1'}
                    onChange={(e) =>
                      handleChangeRadio(e, values, setFieldValue)
                    }
                    style={{ marginRight: '10px' }}
                    disabled={nonEditable}
                  />
                  {t('F1_S7_Format1')}
                </RadioLabel>

                <RadioLabel>
                  <input
                    type="radio"
                    value="2"
                    checked={String(values.format) === '2'}
                    onChange={(e) =>
                      handleChangeRadio(e, values, setFieldValue)
                    }
                    style={{ marginRight: '10px' }}
                    disabled={nonEditable}
                  />
                  {t('F1_S7_Format2')}
                </RadioLabel>

                <Row lg={8} style={{ marginLeft: '0px' }}>
                  <FormErrorMessage
                    touched={touched.format}
                    errors={errors.format}
                  />
                </Row>
              </Col>
            </Form.Group>

            {String(values?.format) !== '2' && (
              <>
                <Form.Group as={Row}>
                  <Form.Label column lg={4}>
                    {t('F1_S7_Country_activity')} <RequiredMark>*</RequiredMark>
                    :
                    <HelpIcon info={t('F1_S7_Country_activity_INFO')} />
                  </Form.Label>
                  <Col lg={5}>
                    <Form.Control
                      as="select"
                      name="countryId"
                      value={values.countryId}
                      disabled={nonEditable}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        touched.countryId && errors.countryId ? 'error' : null
                      }
                    >
                      <option value="">{t('Placeholder_select')}</option>
                      {filteredCountries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {t(country.name)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched={touched.countryId}
                      errors={errors.countryId}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: 'center' }}>
                  <Form.Label column xs={4}>
                    {t('F1_S2_State_Province_Region')}:
                  </Form.Label>
                  <Col xs={5}>
                    <Form.Control
                      type="text"
                      name="stateName"
                      value={values.stateName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={nonEditable}
                      className={
                        touched.stateName && errors.stateName ? 'error' : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.stateName}
                      errors={errors.stateName}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column lg={4}>
                    {t('F1_S2_City')} <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Col lg={5}>
                    <Form.Control
                      type="text"
                      name="cityName"
                      value={values.cityName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={nonEditable}
                      className={
                        touched.cityName && errors.cityName ? 'error' : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.cityName}
                      errors={errors.cityName}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column lg={4}>
                    {t('F1_S7_Countries_additional')}:
                  </Form.Label>
                  <Col lg={5}>
                    <Form.Control
                      type="text"
                      value={values.countriesAdditional}
                      name="countriesAdditional"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={nonEditable}
                      className={
                        touched.countriesAdditional &&
                        errors.countriesAdditional
                          ? 'error'
                          : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.countriesAdditional}
                      errors={errors.countriesAdditional}
                    />
                  </Col>
                </Form.Group>
              </>
            )}

            <SeparatorDotted />

            <Form.Group as={Row}>
              <Form.Label column xs={3}>
                {t('F1_S7_Duration')}:
              </Form.Label>
              <Col xs={3}>
                <Form.Control
                  type="number"
                  min="0"
                  name="duration"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.duration}
                  className={
                    touched.duration && errors.duration ? 'error' : null
                  }
                  disabled={nonEditable}
                />
                <FormErrorMessage
                  touched={touched.duration}
                  errors={errors.duration}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column xs={3}>
                {t('F1_S7_Number_participants')}:
              </Form.Label>
              <Col xs={3}>
                <Form.Control
                  type="number"
                  min="0"
                  name="participants"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.participants}
                  className={
                    touched.participants && errors.participants ? 'error' : null
                  }
                  disabled={nonEditable}
                />
                <FormErrorMessage
                  touched={touched.participants}
                  errors={errors.participants}
                />
              </Col>
            </Form.Group>

            <SeparatorDotted />

            {values.activityType && values.activityType.id == 4 && (
              <>
                <Form.Group as={Row}>
                  <Form.Label column xs={3}>
                    {t('F1_S7_Number_experts')}:
                  </Form.Label>
                  <Col xs={3}>
                    <Form.Control
                      type="number"
                      min="0"
                      name="experts"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.experts}
                      className={
                        touched.experts && errors.experts ? 'error' : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.experts}
                      errors={errors.experts}
                    />
                  </Col>
                </Form.Group>

                <StyledFormLabel
                  xs={4}
                  as={Row}
                  style={{ marginTop: '30px', marginBottom: '15px' }}
                >
                  {t('F1_S7_Number_wd')}:
                </StyledFormLabel>

                <Form.Group as={Row}>
                  <Form.Label column xs={3}>
                    {t('F1_S7_Remote')}:
                  </Form.Label>
                  <Col xs={3}>
                    <Form.Control
                      type="number"
                      min="0"
                      name="daysRemote"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.daysRemote}
                      className={
                        touched.daysRemote && errors.daysRemote ? 'error' : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.daysRemote}
                      errors={errors.daysRemote}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column xs={3}>
                    {t('F1_S7_Onsite')}:
                  </Form.Label>
                  <Col xs={3}>
                    <Form.Control
                      type="number"
                      min="0"
                      name="daysOnsite"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.daysOnsite}
                      className={
                        touched.daysOnsite && errors.daysOnsite ? 'error' : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.daysOnsite}
                      errors={errors.daysOnsite}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel xs={4}>{t('F1_S7_Total')}:</StyledFormLabel>
                  <TextValue value={values.daysRemote + values.daysOnsite} />
                </Form.Group>

                <SeparatorDotted />
              </>
            )}
            <Form.Group>
              <Row>
                <StyledFormLabel>
                  {t('F3_S7_General_work_language')}
                </StyledFormLabel>
                <Form.Check
                  disabled={nonEditable}
                  style={{ marginLeft: '15px' }}
                  name="workLang1"
                  type="checkbox"
                  checked={values.workLang1}
                  value={values.workLang1}
                  onChange={handleChange}
                  label={t('English')}
                />
                <Form.Check
                  disabled={nonEditable}
                  style={{ marginLeft: '15px' }}
                  name="workLang2"
                  type="checkbox"
                  checked={values.workLang2}
                  value={values.workLang2}
                  onChange={handleChange}
                  label={t('French')}
                />
                <Form.Check
                  disabled={nonEditable}
                  style={{ marginLeft: '15px' }}
                  name="workLang3"
                  type="checkbox"
                  checked={values.workLang3}
                  value={values.workLang3}
                  onChange={handleChange}
                  label={t('Portuguese')}
                />
                <Form.Check
                  disabled={nonEditable}
                  style={{ marginLeft: '15px' }}
                  name="workLang4"
                  type="checkbox"
                  checked={values.workLang4}
                  value={values.workLang4}
                  onChange={handleChange}
                  label={t('Spanish')}
                />
              </Row>
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: 'center',
                  textAlign: 'justify',
                  lineHeight: '1.3',
                }}
              >
                {t('F3_S7_General_learning_objectives')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Form.Control
                name="learning"
                as="textarea"
                rows={3}
                placeholder={t('Maximum_1300')}
                style={{ resize: 'none' }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.learning}
                className={touched.learning && errors.learning ? 'error' : null}
                disabled={nonEditable}
              />
              <FormErrorMessage
                touched={touched.learning}
                errors={errors.learning}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: 'center',
                  textAlign: 'justify',
                  lineHeight: '1.3',
                }}
              >
                {t('F3_S7_General_alignment')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Form.Control
                name="generalAlignment"
                as="textarea"
                rows={3}
                placeholder={t('Maximum_1300')}
                style={{ resize: 'none' }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.generalAlignment}
                className={
                  touched.generalAlignment && errors.generalAlignment
                    ? 'error'
                    : null
                }
                disabled={nonEditable}
              />
              <FormErrorMessage
                touched={touched.generalAlignment}
                errors={errors.generalAlignment}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: 'center',
                  textAlign: 'justify',
                  lineHeight: '1.3',
                }}
              >
                {t('F3_S7_General_participation_members')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Form.Control
                name="generalParticipationMembers"
                as="textarea"
                rows={3}
                placeholder={t('Maximum_1300')}
                style={{ resize: 'none' }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.generalParticipationMembers}
                className={
                  touched.generalParticipationMembers &&
                  errors.generalParticipationMembers
                    ? 'error'
                    : null
                }
                disabled={nonEditable}
              />
              <FormErrorMessage
                touched={touched.generalParticipationMembers}
                errors={errors.generalParticipationMembers}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: 'center',
                  textAlign: 'justify',
                  lineHeight: '1.3',
                }}
              >
                {t('F3_S7_General_proposal_cross_issues')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Form.Control
                name="generalProposalCrossIssues"
                as="textarea"
                rows={3}
                placeholder={t('Maximum_1300')}
                style={{ resize: 'none' }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.generalProposalCrossIssues}
                className={
                  touched.generalProposalCrossIssues &&
                  errors.generalProposalCrossIssues
                    ? 'error'
                    : null
                }
                disabled={nonEditable}
              />
              <FormErrorMessage
                touched={touched.generalProposalCrossIssues}
                errors={errors.generalProposalCrossIssues}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: 'center',
                  textAlign: 'justify',
                  lineHeight: '1.3',
                }}
              >
                {t('F3_S7_General_brief_description_schedule')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Form.Control
                name="descSchedule"
                as="textarea"
                rows={3}
                placeholder={t('Maximum_1300')}
                style={{ resize: 'none' }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.descSchedule}
                className={
                  touched.descSchedule && errors.descSchedule ? 'error' : null
                }
                disabled={nonEditable}
              />
              <FormErrorMessage
                touched={touched.descSchedule}
                errors={errors.descSchedule}
              />
            </Form.Group>

            <CenteredModalButtonContainer>
              {nonEditable ? (
                <PrimaryButton
                  onClick={() => {
                    handleCloseOk()
                  }}
                >
                  OK
                </PrimaryButton>
              ) : (
                <>
                  <ErrorButton
                    style={{ marginRight: '20px' }}
                    onClick={handleClose}
                    disabled={nonEditable}
                  >
                    {t('Button_cancel')}
                  </ErrorButton>
                  <StyledSendButton
                    onClick={() => {
                      onSave(values)
                    }}
                    disabled={nonEditable}
                  >
                    {t('Button_save')}
                  </StyledSendButton>
                </>
              )}
            </CenteredModalButtonContainer>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default GeneralInfoForm
