import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
	mgmtFinalizeScore: Yup.string().nullable(),
  mgmtFinalizeComment: Yup.string()
    .min(2,  'F3_S7_validation4')
    .max(1300, 'Error max length 1300')
    .nullable(),
  mgmtFinalizeConfirmed: Yup.boolean(),
});