import Form3Section1 from 'components/Forms/Form3/Section1';
import Form3Section2 from 'components/Forms/Form3/Section2';
import Form3Section3 from 'components/Forms/Form3/Section3';
import Form3Section4 from 'components/Forms/Form3/Section4';
import Form3Section5 from 'components/Forms/Form3/Section5';
import Form3Section6 from 'components/Forms/Form3/Section6';
import Form3Section7 from 'components/Forms/Form3/Section7';
import Form3Section8 from 'components/Forms/Form3/Section8';
import Form3Section9 from 'components/Forms/Form3/Section9';
import Form3Section10 from 'components/Forms/Form3/Section10';
import Form3Section11 from 'components/Forms/Form3/Section11';
import Form3Section12 from 'components/Forms/Form3/Section12';
import Form3Section13 from 'components/Forms/Form3/Section13';
import Form3Section14 from 'components/Forms/Form3/Section14';
import Form4Section16 from 'components/Forms/Form4/Section16';

export const form3Dictionary = {
  1: <Form3Section1 />,
  2: <Form3Section2 />,
  3: <Form3Section3 />,
  4: <Form3Section4 />,
  5: <Form3Section5 />,
  6: <Form3Section6 />,
  7: <Form3Section7 />,
  8: <Form3Section8 />,
  9: <Form3Section9 />,
  10: <Form3Section10 />,
  11: <Form3Section11 />,
  12: <Form3Section12 />,
  13: <Form3Section13 />,
  14: <Form3Section14 />,
  16: <Form4Section16 />,
}