import React from 'react'
import { useTranslation } from 'react-i18next'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageTitle from 'components/PageTitle'
import { PrimaryButton } from 'components/Buttons'
import { PageHeader, PageContent } from './Styled'
import TableRow from './TableRow'
import { useRootStore } from 'stores'
import { roleNamesConst } from 'constants/appConstants'
import { useConstantsFunds } from 'customHooks/useConstantsFunds'
import { fundStatesConst } from 'constants/funds'

const Funds = ({
  loading,
  initiatives,
  filters,
  onFilterByType,
  onFilterByState,
  getData,
  onAddFund
}) => {
  const { t } = useTranslation()

  const { authStore } = useRootStore();

  const { sortedFundTypes: fundTypes } = useConstantsFunds();

  const currentRole = authStore.roles[0];
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM;
  const isAtke = currentRole === roleNamesConst.ATKE;
  const isIntpa = currentRole === roleNamesConst.INTPA;

  return (
    <>
      <PageHeader>
        <PageTitle title={t('funds_page_title')} />
        <Row style={{ alignItems: 'center' }}>
          {t('Grid_status')}
          <Col>
            <Form.Control
              as="select"
              onChange={onFilterByState}
              value={filters?.state}
            >
              <option value="">{t("Placeholder_select")}</option>
              {Object.keys(fundStatesConst).map((item) => (
                <option key={item} value={item}>
                  {t(item)}
                </option>
              ))}
            </Form.Control>
          </Col>
          {t('fund_filter')}
          <Col>
            <Form.Control
              as="select"
              onChange={onFilterByType}
              value={filters?.fundTypeId}
            >
              <option value="">{t("Placeholder_select")}</option>
              {fundTypes.map((entity) => (
                <option key={entity.id} value={entity.id}>
                  {t(`fund_type_${entity.id}`)}
                </option>
              ))}
            </Form.Control>
          </Col>

          <PrimaryButton onClick={getData}>
            {t('Button_filter')}
          </PrimaryButton>
        </Row>
      </PageHeader>
      {isConsortium || isAtke || isIntpa ? (
        <PrimaryButton onClick={onAddFund}>
          {t('add_fund_button')}
        </PrimaryButton>
      ) : null}
      <PageContent>
        {loading ? (
          <p>{t('Loading')}</p>
        ) : (
          <Table>
            <thead>
              <tr>
                <th>{t('Grid_title_code')}</th>
                <th>{t('Grid_title')}</th>
                <th>{t('Grid_fund')}</th>
                <th>{t('Grid_status')}</th>
                <th>{t('Start_Date')}</th>
                <th>{t('End_Date')}</th>
              </tr>
            </thead>
            <tbody>
              {!initiatives.length ? (
                <tr>
                  <td colSpan={4} style={{ textAlign: 'center' }}>
                    {t('No_data_available')}
                  </td>
                </tr>
              ) : (
                initiatives?.map((item) => {
                  return (
                    <TableRow
                      key={item.id}
                      item={item}
                    />
                  )
                })
              )}
            </tbody>
          </Table>
        )}
      </PageContent>
    </>
  )
}

export default Funds
