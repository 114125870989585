import React, { useState, useEffect } from 'react';

import { getProfile } from 'services/auth';

import UserInformation from 'components/UserInformation';

const Profile = () => {

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const loadProfile = async () => {
      setProfile(await getProfile());
    }

    loadProfile();
  }, []);
  
  if (!profile) return null; 

  return <UserInformation profile={profile}/>
}

export default Profile