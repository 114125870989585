import React, {useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useRootStore } from 'stores';
import Proptypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { ModalFooter, FooterLeft, FooterCenter, FooterRight } from './CreateInitiativeDialog.styled';
import { ErrorButton, PrimaryButton } from 'components/Buttons';
import { createInitiative } from 'services/initiative';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { getMaintenanceVariables } from "services/apiConstants";

const CreateInitiativeDialog = ({ title, displayDialog, onCloseModal }) => {
  const [sending, setSending] = useState(false);
  const { initiativeStore } = useRootStore();
  const [closedWindow, setClosedWindow] = useState(false);
  const history = useHistory();
  const {t} = useTranslation();

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getMaintenanceVariables();
        if(response && response[4]){
           setClosedWindow(!response[4].keyValue);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAPI();
   }, [])

  const createInitiativeAction = async (language) => {
    setSending(true);
    try {
      const response = await createInitiative({ language });
      initiativeStore.setInitiativeData({
        id: response.id,
        code: response.code,
        language: response.language
      });
      history.push(`/applicant/initiative/${response.id}/form/1/section/1`)
    } catch (error) {
      setSending(false);
      console.log(error);
    }
  }

  return (
    <Modal show={displayDialog} onHide={onCloseModal} size="lg" animation={false}>
      <Modal.Header>
        <h5>{ title }</h5>
      </Modal.Header>
      <Modal.Body>
       {closedWindow? htmlParser(t('F1_Creation_warning_window_closed')) : htmlParser(t('F1_Creation_warning'))}
      </Modal.Body>
      <ModalFooter>
        <FooterLeft />
        <FooterCenter>
          <PrimaryButton disabled={sending || closedWindow} onClick={() => createInitiativeAction('es')}>Español</PrimaryButton>
          <PrimaryButton disabled={sending || closedWindow} onClick={() => createInitiativeAction('en')}>English</PrimaryButton>
        </FooterCenter>
        <FooterRight>
          <ErrorButton disabled={sending} onClick={onCloseModal}>{t('Button_cancel')}</ErrorButton>
        </FooterRight>
      </ModalFooter>
    </Modal>
  )
}

CreateInitiativeDialog.propTypes = {
  title: Proptypes.string,
  displayDialog: Proptypes.bool,
  onCloseModal: Proptypes.func,
};

export default observer(CreateInitiativeDialog);
