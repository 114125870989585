import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
    items: Yup.array()
    .of(
      Yup.object().shape({
        countryId: Yup.string(),
        quantity: Yup.number().typeError('F3_S10_validation2').min(1, 'F3_S10_validation3').integer(),
      })
    )
})