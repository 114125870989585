import styled, { css } from 'styled-components';
import Button from 'react-bootstrap/Button';

export const EstimatedBugetDiv = styled.div`
  ${({ theme }) => css`
    margin-top: 40px;
    padding: 50px;
    border-radius: 9px;
    border: 1px solid ${theme.brandColors.gray1};
  `}
`;

export const TotalBugetDiv = styled.div`
  ${({ theme }) => css`
    margin-top: 40px;
    padding: 30px;
    border-radius: 9px;
    border: 3px solid ${theme.brandColors.blue};
  `}
`;

export const RowBorder = styled.div`
  ${({ theme }) => css`
    border-top: 3px dotted ${theme.colors.gray[1]};
    width: 100%;
  `}
`;

export const BorderlessTr = styled.tr`
  border-top: hidden;
`;

export const TableHeader = styled.th`
  ${({ theme }) => css`
    width: ${props => props.width}%;
    font-weight: 500;
    color: ${theme.colors.gray[1]};
  `}
`;

export const ResourceNameSpan = styled.span`
  ${({ theme }) => css`
    font-weight: 500;
    font-size: ${props => props.total? '19px':'17px'};
    color: ${theme.colors.gray[1]};
  `}
`;

export const ResourceNumberSpan = styled.span`
  ${({ theme }) => css`
    font-weight: ${props => props.weight || 450};
    font-size: ${props => props.weight?  '19px':'17px'};
    color: ${theme.brandColors.green};
    float: right;
  `}
`;

export const TotalBudgetSpan = styled.span`
  font-size: 20px;
  font-weight: 600;
`;
