import Form4Section1 from 'components/Forms/Form4/Section1';
import Form4Section2 from 'components/Forms/Form4/Section2';
import Form4Section3 from 'components/Forms/Form4/Section3';
import Form4Section4 from 'components/Forms/Form4/Section4';
import Form4Section5 from 'components/Forms/Form4/Section5';
import Form4Section6 from 'components/Forms/Form4/Section6';
import Form4Section7 from 'components/Forms/Form4/Section7';
import Form4Section8 from 'components/Forms/Form4/Section8';
import Form4Section9 from 'components/Forms/Form4/Section9';
import Form4Section10 from 'components/Forms/Form4/Section10';
import Form4Section11 from 'components/Forms/Form4/Section11';
import Form4Section12 from 'components/Forms/Form4/Section12';
import Form4Section13 from 'components/Forms/Form4/Section13';
import Form4Section14 from 'components/Forms/Form4/Section14';
import Form4Section15 from 'components/Forms/Form4/Section15';
import Form4Section16 from 'components/Forms/Form4/Section16';

export const form4Dictionary = {

  1: <Form4Section1 />,
  2: <Form4Section2 />,
  3: <Form4Section3 />,
  4: <Form4Section4 />,
  5: <Form4Section5 />,
  6: <Form4Section6 />,
  7: <Form4Section7 />,
  8: <Form4Section8 />,
  9: <Form4Section9 />,
  10: <Form4Section10 />,
  11: <Form4Section11 />,
  12: <Form4Section12 />,
  13: <Form4Section13 />,
  14: <Form4Section14 />,
  15: <Form4Section15 />,
  16: <Form4Section16 />,

}