import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledNumberCircle, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  StyledIntroParagraph,
} from 'components/Forms/Form2/Section3/Section3.styled';
import FakeAccordionItem from '../../FakeAccordionItem';
import CoapplicantForm from 'components/Forms/Form2/Section3/CoapplicantForm';
import { SeparatorDotted } from 'components/CommonStyled';

const Section3 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState([]);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledNumberCircle> 3 </StyledNumberCircle>
          <StyledTitle> {t('F1_S3_title')} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
          <StyledIntroParagraph>
            {t('F1_S3_intro')}
          </StyledIntroParagraph>
          {(formValues && formValues?.map((coapplicant, key) => (
            <React.Fragment  key={key}>
              <FakeAccordionItem title={t('F1_S3_Accordion')+" "+(key+1)}>
                <CoapplicantForm coapplicantIndex={key} data={coapplicant} /> 
              </FakeAccordionItem>
              <SeparatorDotted />
            </React.Fragment>
          )))}
      </StyledBody>
    </SectionContainer>
  )
}


export default Section3;