import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { getContacts, getImplementationInitiatives } from 'services/initiative';
import Pagination from 'react-bootstrap/Pagination'

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import CustomExportXLSXButton from './CustomExportXLSXButton/CustomExportXLSXButton';

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageTitle from 'components/PageTitle';
import { PageHeader, PageContent } from '../PurchasingManagement/PurchasingManagement.styled';
import { PrimaryButton } from 'components/Buttons';

const Contacts = () => {
  const { t } = useTranslation();

  const [initiativesOptions, setInitiativesOptions] = useState([]);

  const [filterType, setFilterType] = useState(0);
  const [filterInitiative, setFilterInitiative] = useState(0);
  const [filterKeyword, setFilterKeyword] = useState(null);
  const [params, setParams] = useState({});

  const [tableValues, setTableValues] = useState([]);
  
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 100;

  const [searching, setSearching] = useState(false);

  useEffect(() => {
    getInitiatives();
    getValues(0, {});
  }, []);

  async function getValues(page, params) {
    try {
     const response = await getContacts(page, pageSize, params);

     response.content.forEach( (e) => {
        e.type = e.type !== null ? t(`type_${e.type+1}`) : "-";
        e.country = e.country !== null ? t(e.country) : "-";
        e.positionR = e.positionR !== null ? e.positionR : "-";
        e.relation = e.relation !== null ? e.relation : "-";
        e.phone = e.phone !== null ? e.phone : "-";
        e.email = e.email !== null ? e.email : "-";
     })
    
     setTableValues(response.content);
     setLastPage(response.totalPages == 0 ? 0 : response.totalPages-1);
     setCurrentPage(page);
    } catch (error) {
      console.log(error)
    }
  }

  async function getInitiatives() {
    try {
      const response = await getImplementationInitiatives();
      setInitiativesOptions(response);
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeKeyword = (e) => {
    setFilterKeyword(e.target.value);
  }

  const handleChangeInitiative = (e) => {
    setFilterInitiative(e.target.value);
  }

  const handleChangeType = (e) => {
    setFilterType(e.target.value);
  }

  const handleFilter = async () => {
    setSearching(true);
    let params = {};
    if(filterInitiative != 0)
      params.initiativeId= filterInitiative;
    if(filterType != 0)
      params.type= filterType-1;
    if(filterKeyword !== null)
      params.keyword= filterKeyword;
    setParams(params);
    await getValues(0, params);
    setSearching(false);
  }

  const columns = [
    {
      dataField: 'name',
      text: t('F1_S2_Entity_name'),
    }, 
    {
      dataField: 'type',
      text: t('F1_S2_Type_of_entity'),
    }, 
    {
      dataField: 'country',
      text: t('F1_S2_Country'),
    }, 
    {
      dataField: 'email',
      text: t('F1_S2_Email'),
      headerStyle: { width: '280px' },
    }, 
    {
      dataField: 'phone',
      text: t('F1_S2_Phone_number'),
      headerStyle: { width: '200px' },
    }, 
    {
      dataField: 'positionR',
      text: t('Position')
    }, 
    {
      dataField: 'relation',
      text: t('Organization')   
    }
  ];
  
  return (
    <ToolkitProvider
      keyField="id"
      data={ tableValues }
      columns={ columns }
    >
      {props => (
        <>
          <PageHeader>
              <PageTitle title={t('menu_admin_5')} />
          </PageHeader>
          <Row style={{alignItems: "center"}}>
            <Col sm={9} style={{display: 'flex', alignItems: 'center'}}>
              {t('Initiative')}
              <Form.Control style={{marginLeft: '20px', marginRight: '20px'}}
                as="select"
                onChange={handleChangeInitiative}
                value={filterInitiative}
              >
                <option value={0}> {t('ALL')} </option>
                {initiativesOptions && initiativesOptions.map((e) => (
                  <option value={e.id}> {`${e.code} - ${e.name}`} </option>
                ))}
              </Form.Control>
              
              {t('F1_S2_Type_of_entity')}
              <Form.Control style={{marginLeft: '20px', marginRight: '20px'}}
                as="select"
                onChange={handleChangeType}
                value={filterType}
              >
                <option value={0}> {t('ALL')} </option>
                <option value={1}> {t(`type_${1}`)} </option>
                <option value={2}> {t(`type_${2}`)} </option>
                <option value={3}> {t(`type_${3}`)} </option>
                <option value={4}> {t(`type_${4}`)} </option>
                <option value={5}> {t(`type_${5}`)} </option>
                <option value={6}> {t(`type_${6}`)} </option>
                <option value={7}> {t(`type_${7}`)} </option>
                <option value={8}> {t(`type_${8}`)} </option>
                <option value={9}> {t(`type_${9}`)} </option>
              </Form.Control>
      
              {t('Keyword')}
              <Form.Control
                as="textArea"
                rows={1}
                maxLength={20}
                style={{overflow: 'hidden', resize: 'none', marginLeft: '20px', marginRight: '20px'}}
                onChange={handleChangeKeyword}
                onKeyPress={e => {
                  if(e.key === 'Enter') {
                    e.preventDefault()
                    handleFilter();
                  }
                }}
                value={filterKeyword}
              />            
              <PrimaryButton disabled={searching} onClick={() => {handleFilter()}}>{t('Button_search')}</PrimaryButton>
            </Col>

            <Col>
              <CustomExportXLSXButton
                filter={{
                  initiative: filterInitiative,
                  type: filterType,
                  keyword: filterKeyword,
                }}
              />
            </Col> 
          </Row>
          <PageContent>
            <BootstrapTable { ...props.baseProps } />    

            <Pagination style={{display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
              <Pagination.First disabled={currentPage == 0} onClick={() => {getValues(0, params)}}/>
              <Pagination.Prev  disabled={currentPage == 0} onClick={() => {getValues(currentPage-1, params)}} />
              <Pagination.Next  disabled={currentPage == lastPage} onClick={() => {getValues(currentPage+1, params)}} />
              <Pagination.Last  disabled={currentPage == lastPage} onClick={() => {getValues(lastPage, params)}}/>
            </Pagination>        
          </PageContent>
        </>
      )}
    </ToolkitProvider>
  )
};

export default observer(Contacts);
