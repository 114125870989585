import React, { useState, useEffect } from 'react'
import { initialValues } from './Section6.initialValues'
import { observer } from 'mobx-react-lite'
import { useRootStore } from 'stores'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { validationSchema } from './Section6.validations'
import HelpIcon from 'components/HelpIcon'
import { saveFormSection } from 'services/initiative';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer'
import { useConstantsForm1 } from 'customHooks/useConstantsForm1'
import { f3statusNamesConst, roleNamesConst } from 'constants/appConstants';
import Form from 'react-bootstrap/Form'
import { StyledSubtitle, StyledFormLabel } from './Section6.styled'
import { RequiredMark } from 'components/CommonStyled'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const Section6 = () => {
  const { formStore, initiativeStore } = useRootStore()
  const { t } = useTranslation()
  const [formValues, setFormValues] = useState(initialValues)
  const { id, formId, sectionId } = useParams()
  const [saved, setSaved] = useState(false)
  const [nonEditable, setNonEditable] = useState(false)
  const { developmentGoals } = useConstantsForm1()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {
        ...initialValues,
        ...formStore.formSectionData[0],
        goalSecondaryTypeId: formStore?.formSectionData[0]?.goalSecondaryType?.id,
        goalPrimaryTypeId: formStore?.formSectionData[0]?.goalPrimaryType?.id,
      }
      setFormValues(formSectionData)
    }
  }, [formStore.formSectionData])

  useEffect(() => {
    if (saved) window.scrollTo(0, 0)
  }, [saved])

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f3statusNamesConst.DRAFT ||
        initiativeStore.role === roleNamesConst.GUEST,
    )
  }, [initiativeStore.formStateName])

  const onSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values)
      formStore.setSectionStatus(sectionId, complete)
      resetForm({ values })
      setSaved(true)
    } catch (error) {
      console.log(error)
    }
  }

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault()
    isValid ? onSubmit(values, { resetForm }) : alert(t('not_saved_alert'))
  }

  return (
    <FormLayout>
      <FormContainer
        number={6}
        title={t('F1_S6_title')}
        formId="form3section6"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
        isHelp
      >
        <Formik
          enableReinitialize
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isValid,
            resetForm,
            handleChange,
            handleBlur,
          }) => (
            <form
              id="form3section6"
              onSubmit={(e) => {
                onValidateSubmit(e, isValid, values, { resetForm })
              }}
            >
              {dirty && setSaved(false)}
              <StyledSubtitle> {t('F1_S6_Subtitle_1')}</StyledSubtitle>
              <Form.Group as={Row}>
                <StyledFormLabel column xs={3}>
                  {t('F1_S6_Question_1')}
                  <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <Col xs={5} md={9}>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Placeholder_initiative_name')}
                    value={values.name}
                    className={touched.name && errors.name ? 'error' : null}
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.name}
                    errors={errors.name}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={3}>
                  {t('F1_S6_Question_2')}
                  <RequiredMark>*</RequiredMark>:
                  <HelpIcon
                    padding={'0 0 0 0.75rem'}
                    info={t('F1_S6_Question_2_INFO')}
                  />
                </StyledFormLabel>
                <Col xs={5} md={9}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    style={{ resize: 'none' }}
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.description}
                    className={
                      touched.description && errors.description ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.description}
                    errors={errors.description}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={3}>
                  {t('F1_S6_Question_3')}
                  <RequiredMark>*</RequiredMark>:
                  <HelpIcon
                    padding={'0 0 0 0.75rem'}
                    info={t('F1_S6_Question_3_INFO')}
                  />
                </StyledFormLabel>
                <Col xs={5} md={9}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    style={{ resize: 'none' }}
                    name="objective"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="A maximum of 350 characters"
                    value={values.objective}
                    className={
                      touched.objective && errors.objective ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.objective}
                    errors={errors.objective}
                  />
                </Col>
              </Form.Group>

              <StyledSubtitle> {t('F1_S6_Subtitle_2')}</StyledSubtitle>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_8')}
                  <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="contribution"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.contribution}
                    className={
                      touched.contribution && errors.contribution
                        ? 'error'
                        : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.contribution}
                    errors={errors.contribution}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={9}>
                  {t('F1_S6_Question_4')}
                  <RequiredMark>*</RequiredMark>:
                  <HelpIcon info={t('F1_S6_Question_4_INFO')} />
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="latamKnowledge"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.latamKnowledge}
                    className={
                      touched.latamKnowledge && errors.latamKnowledge
                        ? 'error'
                        : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.latamKnowledge}
                    errors={errors.latamKnowledge}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_5')}
                  <RequiredMark>*</RequiredMark>:
                  <HelpIcon info={t('F1_S6_Question_5_INFO')} />
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="euKnowledge"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.euKnowledge}
                    className={
                      touched.euKnowledge && errors.euKnowledge ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.euKnowledge}
                    errors={errors.euKnowledge}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_6')}
                  <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="newKnowledge"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.newKnowledge}
                    className={
                      touched.newKnowledge && errors.newKnowledge
                        ? 'error'
                        : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.newKnowledge}
                    errors={errors.newKnowledge}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_7')}
                  <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="application"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.application}
                    className={
                      touched.application && errors.application ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.application}
                    errors={errors.application}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_9')}:
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="leap"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.leap}
                    className={touched.leap && errors.leap ? 'error' : null}
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.leap}
                    errors={errors.leap}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <StyledSubtitle>
                {t('F1_S6_Subtitle_3')}
                <HelpIcon info={t('F1_S6_Subtitle_3_INFO')} />
              </StyledSubtitle>

              <Form.Group as={Row} style={{ marginTop: '-5px' }}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_10')}
                  <RequiredMark>*</RequiredMark>:
                  <HelpIcon info={t('F1_S6_Question_10_INFO')} />
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="benEntities"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.benEntities}
                    className={
                      touched.benEntities && errors.benEntities ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.benEntities}
                    errors={errors.benEntities}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_11')}
                  <RequiredMark>*</RequiredMark>:
                  <HelpIcon info={t('F1_S6_Question_11_INFO')} />
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="directBen"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.directBen}
                    className={
                      touched.directBen && errors.directBen ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.directBen}
                    errors={errors.directBen}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <StyledSubtitle>
                {t('F1_S6_Subtitle_4')}
                <HelpIcon info={t('F1_S6_Subtitle_4_INFO')} />
              </StyledSubtitle>

              {/* See AD2-1631 */}
              <div style={{ display: 'none' }}>
                <Form.Group as={Row}>
                  <StyledFormLabel column xs={5}>
                    {t('F1_S6_Question_12')}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col xs={5}>
                    <Form.Control
                      name="goalPrimaryTypeId"
                      as="select"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.goalPrimaryTypeId}
                      className={
                        touched.goalPrimaryTypeId && errors.goalPrimaryTypeId
                          ? 'error'
                          : null
                      }
                      disabled={nonEditable}
                    >
                      <option value="">{t('Placeholder_select')}</option>
                      {developmentGoals.map((devGoal) => (
                        <option key={devGoal.id} value={devGoal.id}>
                          {t(`SDG_Goal${devGoal.id}`)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched={touched.goalPrimaryTypeId}
                      errors={errors.goalPrimaryTypeId}
                      style={{ marginLeft: '40px' }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column xs={8}>
                    {t('F1_S6_Question_13')}
                  </StyledFormLabel>
                  <Col xs={5} md={11}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      style={{
                        resize: 'none',
                        marginLeft: '40px',
                      }}
                      name="primaryJustification"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t('Maximum_650')}
                      value={values.primaryJustification}
                      className={
                        touched.primaryJustification &&
                        errors.primaryJustification
                          ? 'error'
                          : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.primaryJustification}
                      errors={errors.primaryJustification}
                      style={{ marginLeft: '40px' }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: 'center' }}>
                  <StyledFormLabel column xs={5}>
                    {t('F1_S6_Question_14')}:
                  </StyledFormLabel>
                  <Col xs={5}>
                    <Form.Control
                      name="goalSecondaryTypeId"
                      as="select"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.goalSecondaryTypeId}
                      className={
                        touched.goalSecondaryTypeId && errors.goalSecondaryTypeId
                          ? 'error'
                          : null
                      }
                      disabled={nonEditable}
                    >
                      <option value="">{t('Placeholder_select')}</option>
                      {developmentGoals.map((devGoal) => (
                        <option key={devGoal.id} value={devGoal.id}>
                          {t(`SDG_Goal${devGoal.id}`)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched={touched.goalSecondaryTypeId}
                      errors={errors.goalSecondaryTypeId}
                      style={{ marginLeft: '40px' }}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column xs={8}>
                    {t('F1_S6_Question_15')}
                  </StyledFormLabel>
                  <Col xs={5} md={11}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      style={{
                        resize: 'none',
                        marginLeft: '40px',
                      }}
                      name="secondaryJustification"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t('Maximum_650')}
                      value={values.secondaryJustification}
                      className={
                        touched.secondaryJustification &&
                        errors.secondaryJustification
                          ? 'error'
                          : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.secondaryJustification}
                      errors={errors.secondaryJustification}
                      style={{ marginLeft: '40px' }}
                    />
                  </Col>
                </Form.Group>
              </div>
              <StyledFormLabel> {t('F3_S6_Subtitle_4_intro')} </StyledFormLabel>

              <StyledSubtitle>
                {t('F1_S6_Subtitle_5')}
                <HelpIcon info={t('F1_S6_Subtitle_5_INFO')} />
              </StyledSubtitle>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_16')}
                  <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="rights"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.rights}
                    className={touched.rights && errors.rights ? 'error' : null}
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.rights}
                    errors={errors.rights}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_17')}
                  <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="environment"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.environment}
                    className={
                      touched.environment && errors.environment ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.environment}
                    errors={errors.environment}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <StyledSubtitle>
                {t('F1_S6_Subtitle_6')}
                <HelpIcon info={t('F1_S6_Subtitle_6_INFO')} />
              </StyledSubtitle>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_18')}:
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="innovation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.innovation}
                    className={
                      touched.innovation && errors.innovation ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.innovation}
                    errors={errors.innovation}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <StyledSubtitle>
                {t('F1_S6_Subtitle_7')}
                <HelpIcon info={t('F1_S6_Subtitle_7_INFO')} />
              </StyledSubtitle>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_19')}
                  <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="valueTC"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.valueTC}
                    className={
                      touched.valueTC && errors.valueTC ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.valueTC}
                    errors={errors.valueTC}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_20')}
                  <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="valueEU"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.valueEU}
                    className={
                      touched.valueEU && errors.valueEU ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.valueEU}
                    errors={errors.valueEU}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_21')}
                  <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="valueTP"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.valueTP}
                    className={
                      touched.valueTP && errors.valueTP ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.valueTP}
                    errors={errors.valueTP}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_21b')}
                  <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="valueSR"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.valueSR}
                    className={
                      touched.valueSR && errors.valueSR ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.valueSR}
                    errors={errors.valueSR}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>

              <StyledSubtitle> {t('F1_S6_Subtitle_8')}</StyledSubtitle>

              <Form.Group as={Row}>
                <StyledFormLabel column xs={8}>
                  {t('F1_S6_Question_22')}:
                </StyledFormLabel>
                <Col xs={5} md={11}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    style={{
                      resize: 'none',
                      marginLeft: '40px',
                    }}
                    name="covid19"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('Maximum_1300')}
                    value={values.covid19}
                    className={
                      touched.covid19 && errors.covid19 ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.covid19}
                    errors={errors.covid19}
                    style={{ marginLeft: '40px' }}
                  />
                </Col>
              </Form.Group>
            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section6)
