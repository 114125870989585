import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RequiredMark } from 'components/CommonStyled';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  return {
    error: (
      <FormErrorMessage
        touched
        errors={getFieldError}
      />
    )
  };
};

const OtherEntitiesForm = ({ formikInstance, entityIndex, entityTypes, countries, nonEditable }) => {
  const { getFieldProps, errors, values } = formikInstance;
  const fieldPath = field => `entities.${entityIndex}.${field}`;
  const { t } = useTranslation();
  const isFromF3 = values.entities[entityIndex].fromF3;
  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S2_Entity_name')}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath('entityName')}
            {...getFieldProps(fieldPath('entityName'))}
            className={errors[fieldPath('entityName')] ? 'error' : null}
            disabled={nonEditable || isFromF3}
          />
          {fieldInfo(formikInstance, fieldPath('entityName')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S2_Official_acronym')}:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath('acronym')}
            {...getFieldProps(fieldPath('acronym'))}
            className={errors[fieldPath('acronym')] ? 'error' : null}
            disabled={nonEditable || isFromF3}
          />
          {fieldInfo(formikInstance, fieldPath('acronym')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S2_Type_of_entity')}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            as="select"
            name={fieldPath('entityTypeId')}
            {...getFieldProps(fieldPath('entityTypeId'))}
            className={errors[fieldPath('entityTypeId')] ? 'error' : null}
            disabled={nonEditable || isFromF3}
          >
            <option value="">{t('Placeholder_select')}</option>
            {entityTypes.map(entity => (
              <option key={entity.id} value={entity.id}>
                {t(`entity_type_${entity.id}`)}
              </option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath('entityTypeId')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S4_Function')}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath('function')}
            {...getFieldProps(fieldPath('function'))}
            className={errors[fieldPath('function')] ? 'error' : null}
            disabled={nonEditable || isFromF3}
          />
          {fieldInfo(formikInstance, fieldPath('function')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S2_Country')}:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            as="select"
            name={fieldPath('countryId')}
            {...getFieldProps(fieldPath('countryId'))}
            className={errors[fieldPath('countryId')] ? 'error' : null}
            disabled={nonEditable || isFromF3}
          >
            <option value="">{t('Placeholder_select')}</option>
            {countries.map(country => (
              <option key={country.id} value={country.id}>
                {t(country.name)}
              </option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath('countryId')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S2_Website')}:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath('web')}
            {...getFieldProps(fieldPath('web'))}
            className={errors[fieldPath('web')] ? 'error' : null}
            disabled={nonEditable || isFromF3}
          />
          {fieldInfo(formikInstance, fieldPath('web')).error}
        </Col>
      </Form.Group>
      
      {/* TODO: add subtitle for new fields */}

      <Form.Group as={Row} style={{ alignItems: 'center', marginTop: '40px' }}>
        <Form.Label column lg={4}>
          {t('F1_S4_Participanting_entity')}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={4}>
          <Form.Control
            type="text"
            name={fieldPath('firstName')}
            {...getFieldProps(fieldPath('firstName'))}
            className={errors[fieldPath('firstName')] ? 'error' : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('firstName')).error}
        </Col>
        <Col lg={4}>
          <Form.Control
            type="text"
            name={fieldPath('lastName')}
            {...getFieldProps(fieldPath('lastName'))}
            className={errors[fieldPath('lastName')] ? 'error' : null}
            disabled={nonEditable || isFromF3}
          />
          {fieldInfo(formikInstance, fieldPath('lastName')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S2_Position')}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath('position')}
            {...getFieldProps(fieldPath('position'))}
            className={errors[fieldPath('position')] ? 'error' : null}
            disabled={nonEditable || isFromF3}
          />
          {fieldInfo(formikInstance, fieldPath('position')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S2_Email')}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath('email')}
            {...getFieldProps(fieldPath('email'))}
            className={errors[fieldPath('email')] ? 'error' : null}
            disabled={nonEditable || isFromF3}
          />
          {fieldInfo(formikInstance, fieldPath('email')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S2_Phone_number')}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath('phone')}
            placeholder={t('Example') + ' +12133734253'}
            {...getFieldProps(fieldPath('phone'))}
            className={errors[fieldPath('phone')] ? 'error' : null}
            disabled={nonEditable || isFromF3}
          />
          {fieldInfo(formikInstance, fieldPath('phone')).error}
        </Col>
      </Form.Group>
    </>
  );
};

export default OtherEntitiesForm;
