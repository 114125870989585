import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Table } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'
import isEmpty from 'lodash/isEmpty'

import { renderRows2, renderHeader2, orderDataByField } from '../helpers/helpers'

const Table2 = ({ data, waiting, setData }) => {
  const { t } = useTranslation()
  const [ascendingOrder, setAscendingOrder] = useState({
    year: true,
    f6StatusId: true,
  })

  const handleOrderData = (field) => {
    orderDataByField(data, field, setData, setAscendingOrder, ascendingOrder)
  }

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data && !isEmpty(data) ? (
      <Table  style={{ fontSize: '14px' }}>
        <colgroup span="2"></colgroup>
        <colgroup span="4" style={{ border: '3px solid black' }}></colgroup>
        <colgroup span="4" style={{ border: '3px solid black' }}></colgroup>
        <colgroup span="3" style={{ border: '3px solid black' }}></colgroup>
        <colgroup span="5" style={{ border: '3px solid black' }}></colgroup>
        <colgroup span="1" style={{ border: '3px solid black' }}></colgroup>
        <colgroup span="2" style={{ border: '3px solid black' }}></colgroup>
        {renderHeader2(handleOrderData)}
        {renderRows2(data)}
      </Table>
  ) : (
    <>{t('No_data_available_2')}</>
  )
}

export default Table2
