import jwt_decode from "jwt-decode";
import axiosInstance from "./axiosInstance";
import {roleNamesConst} from "constants/appConstants";

// TODO: implement a secure way to store jwt (no jwt ot cookies. maybe httpOnly cookies)
export const getToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return !user ? undefined : user.accessToken;
};

const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const deleteUser = () => {
  localStorage.removeItem("user");
};

export const getRegisterCountries = async () => {
  const url = "/api/public/countries";
  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const loginService = async (email, password) => {
  const url = "/api/auth/login";
  try {
    const {
      data: { access_token, refresh_token },
    } = await axiosInstance.post(url, { email, password });
    var decoded = jwt_decode(access_token);
    const userEmail = decoded.email;
    const appRoles = Object.values(roleNamesConst);
    const roles = decoded.realm_access.roles.filter(role => appRoles.includes(role));
    //const {data: {email: userEmail, roles, accessToken}} = await axiosInstance.post(url, { email, password });
    setUser({
      email: userEmail,
      roles: roles,
      accessToken: access_token,
      refreshToken: refresh_token,
    });
    return {
      email: userEmail,
      roles: roles,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const refreshService = async (oldRefreshToken) => {
  const url = "/api/auth/refresh";
  try {
    const {
      data: { access_token, refresh_token },
    } = await axiosInstance.post(
      url,
      {},
      {
        headers: {
          "refreshToken": oldRefreshToken,
        },
      }
    );
    var decoded = jwt_decode(access_token);
    const userEmail = decoded.email;
    const appRoles = Object.values(roleNamesConst);
    const roles = decoded.realm_access.roles.filter((role) =>
      appRoles.includes(role)
    );
    setUser({
      email: userEmail,
      roles: roles,
      accessToken: access_token,
      refreshToken: refresh_token,
    });
  } catch (error) {
    console.log(error);
  }
};

export const registerService = async (
  captchaToken,
  name,
  lastName,
  email,
  password,
  organization,
  countryId
) => {
  const url = "/api/auth/register";
  try {
    const response = await axiosInstance.post(url, {
      "g-recaptcha-response": captchaToken,
      name,
      lastName,
      email,
      password,
      organization,
      countryId
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const confirmationService = async (confirmationToken) => {
  const url = "/api/auth/confirm?token=";
  try {
    const response = await axiosInstance.get(url + confirmationToken);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const logoutService = () => {
  deleteUser();
};

export const recoveryService = async (email) => {
  const url = '/api/auth/reset-by-email';
  try {  
    const response = await axiosInstance.post(url, { email });
    return response;
  } catch (error) {
    throw new Error (error)
  }
};

export const recoveryServiceConfirm = async (token, newPassword) => {
  const url = '/api/auth/reset';
  try {  
    const response = await axiosInstance.post(url, { token, newPassword });
    return response;
  } catch (error) {
    throw new Error (error)
  }
};

export const getProfile = async () => {
  const url = "/api/auth/profile";
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}
