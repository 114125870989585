import styled, { css } from 'styled-components';

export const StyledTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.brandColors.blue};
  `}
  margin-bottom: 30px;
  font-size: 1.7rem;
  font-weight: 500;
`;
