import React, { useState } from 'react'
import { Formik } from 'formik'
import { formInitialValues } from './NewActivityForm.initialValues'
import { validationSchema } from './NewActivityForm.validations'
import { useTranslation } from 'react-i18next'
import { useConstantsForm3 } from 'customHooks/useConstantsForm3'
import { createNewActivityF5S7 } from 'services/initiative'
import { useParams } from 'react-router-dom'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { ErrorButton, StyledSendButton } from 'components/Buttons'
import InputGroup from 'react-bootstrap/InputGroup'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Form } from 'react-bootstrap'
import {
  RequiredMark,
  SeparatorDotted,
  CenteredModalButtonContainer,
} from 'components/CommonStyled'
import ProfileListAccordion from './ProfileListAccordion/ProfileListAccordion'
import { RadioLabel } from 'components/Forms/Forms.styled'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const NewActivityForm = ({
  handleClose,
  handleRefreshList,
  handleCloseOnError,
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [waiting, setWaiting] = useState(false)
  const { countries, activityTypes } = useConstantsForm3()

  const onSave = async (values) => {
    try {
      setWaiting(true)
      const valuesCopy = JSON.parse(JSON.stringify(values))
      valuesCopy.format = parseInt(valuesCopy.format, 10)
      valuesCopy.fromDate = values.fromDate.toISOString()
      valuesCopy.toDate = values.toDate.toISOString()
      await createNewActivityF5S7(id, valuesCopy)
      handleRefreshList()
      handleClose()
    } catch (error) {
      console.log(error)
      handleCloseOnError()
    } finally {
      setWaiting(false)
    }
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={onSave}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit} id="newActivity">
            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label column lg={2}>
                {t('F1_S7_type')} <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col xs={6}>
                <Form.Control
                  as="select"
                  name="activityTypeId"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.activityTypeId}
                  className={
                    props.touched.activityTypeId && props.errors.activityTypeId
                      ? 'error'
                      : null
                  }
                  disabled={waiting}
                >
                  <option value="">{t('Placeholder_select')}</option>
                  {activityTypes.map((activityType) => (
                    <option key={activityType.id} value={activityType.id}>
                      {t(`activity_types_${activityType.id}`)}
                    </option>
                  ))}
                </Form.Control>
                <FormErrorMessage
                  touched
                  errors={props.errors.activityTypeId}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column xs={2}>
                {t('F1_S7_start_day')} <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col>
                <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={props.values.fromDate}
                    onChange={(date) => {
                      props.setFieldValue('fromDate', date)
                    }}
                    className={
                      props.touched.fromDate && props.errors.fromDate
                        ? 'error'
                        : null
                    }
                    disabled={waiting}
                  />
                </InputGroup>
                <FormErrorMessage
                  touched
                  errors={props.errors.fromDate}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label column xs={2}>
                {t('F1_S7_End_day')} <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col>
                <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={props.values.toDate}
                    onChange={(date) => {
                      props.setFieldValue('toDate', date)
                    }}
                    className={
                      props.touched.toDate && props.errors.toDate
                        ? 'error'
                        : null
                    }
                    disabled={waiting}
                  />
                </InputGroup>
                <FormErrorMessage
                  touched
                  errors={props.errors.toDate}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col>
                <Form.Label xs={5} lg={4} style={{ marginTop: '10px' }}>
                  {t('F1_S7_Short_description')} <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  style={{ resize: 'none', marginRight: '18px' }}
                  placeholder={t('Maximum_1300')}
                  name="description"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.description}
                  className={
                    props.touched.description && props.errors.description
                      ? 'error'
                      : null
                  }
                  disabled={waiting}
                />
                <FormErrorMessage
                  touched
                  errors={props.errors.description}
                />
              </Col>
            </Form.Group>

            <SeparatorDotted />

            <Form.Group as={Row}>
              <Form.Label column lg={4}>
                {t('F1_S7_Format')} <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col lg={8} style={{ marginTop: '20px' }}>
                <RadioLabel>
                  <input
                    type="radio"
                    name="format"
                    value="0"
                    checked={props.values?.format === '0'}
                    onChange={(e) => {
                      props.setFieldValue('format', e?.target?.value)
                    }}
                    style={{ marginRight: '10px' }}
                  />
                  {t('F1_S7_Format0')}
                </RadioLabel>

                <RadioLabel>
                  <input
                    type="radio"
                    name="format"
                    value="1"
                    checked={props.values?.format === '1'}
                    onChange={(e) => {
                      props.setFieldValue('format', e?.target?.value)
                    }}
                    style={{ marginRight: '10px' }}
                  />
                  {t('F1_S7_Format1')}
                </RadioLabel>

                <RadioLabel>
                  <input
                    type="radio"
                    name="format"
                    value="2"
                    checked={props.values?.format === '2'}
                    onChange={(e) => {
                      props.setFieldValue('format', e?.target?.value)
                      props.setFieldValue('countryId', '')
                      props.setFieldValue('stateName', '')
                      props.setFieldValue('cityName', '')
                      props.setFieldValue('countriesAdditional', '')
                    }}
                    style={{ marginRight: '10px' }}
                  />
                  {t('F1_S7_Format2')}
                </RadioLabel>
                <FormErrorMessage
                  touched
                  errors={props.errors.format}
                />
              </Col>
            </Form.Group>

            {props.values?.format !== '2' && (
              <>
                <Form.Group as={Row}>
                  <Form.Label column lg={4}>
                    {t('F1_S7_Country_activity')} <RequiredMark>*</RequiredMark>
                    :
                  </Form.Label>
                  <Col lg={5}>
                    <Form.Control
                      as="select"
                      name="countryId"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.countryId}
                      className={
                        props.touched.countryId && props.errors.countryId
                          ? 'error'
                          : null
                      }
                    >
                      <option value="">{t('Placeholder_select')}</option>
                      {countries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {t(country.name)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched
                      errors={props.errors.countryId}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: 'center' }}>
                  <Form.Label column xs={4}>
                    {t('F1_S2_State_Province_Region')}:
                  </Form.Label>
                  <Col xs={5}>
                    <Form.Control
                      type="text"
                      name="stateName"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.stateName}
                      className={
                        props.touched.stateName && props.errors.stateName
                          ? 'error'
                          : null
                      }
                    />
                    <FormErrorMessage
                      touched
                      errors={props.errors.stateName}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column lg={4}>
                    {t('F1_S2_City')} <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Col lg={5}>
                    <Form.Control
                      type="text"
                      name="cityName"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.cityName}
                      className={
                        props.touched.cityName && props.errors.cityName
                          ? 'error'
                          : null
                      }
                    />
                    <FormErrorMessage
                      touched
                      errors={props.errors.cityName}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column lg={4}>
                    {t('F1_S7_Countries_additional')}:
                  </Form.Label>
                  <Col lg={5}>
                    <Form.Control
                      type="text"
                      name="countriesAdditional"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.countriesAdditional}
                      className={
                        props.touched.countriesAdditional &&
                        props.errors.countriesAdditional
                          ? 'error'
                          : null
                      }
                    />
                    <FormErrorMessage
                      touched
                      errors={props.errors.countriesAdditional}
                    />
                  </Col>
                </Form.Group>
              </>
            )}

            <SeparatorDotted />

            <Form.Group as={Row}>
              <Form.Label column xs={3}>
                {t('F1_S7_Duration')} <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col xs={3}>
                <InputGroup>
                  <Form.Control
                    type="number"
                    min="0"
                    name="duration"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.duration}
                    className={
                      props.touched.duration && props.errors.duration
                        ? 'error'
                        : null
                    }
                    disabled={waiting}
                  />
                </InputGroup>
                <FormErrorMessage
                  touched
                  errors={props.errors.duration}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column xs={3}>
                {t('F1_S7_Number_participants')} <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col xs={3}>
                <InputGroup>
                  <Form.Control
                    type="number"
                    min="0"
                    name="participants"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.participants}
                    className={
                      props.touched.participants && props.errors.participants
                        ? 'error'
                        : null
                    }
                    disabled={waiting}
                  />
                </InputGroup>
                <FormErrorMessage
                  touched
                  errors={props.errors.participants}
                />
              </Col>
            </Form.Group>

            {props.values.activityTypeId === '4' && (
              <>
                <SeparatorDotted />

                <Form.Group as={Row}>
                  <Form.Label column xs={3}>
                    {t('F1_S7_Number_experts')}:
                  </Form.Label>
                  <Col xs={3}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        min="0"
                        name="experts"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.experts}
                        className={
                          props.touched.experts && props.errors.experts
                            ? 'error'
                            : null
                        }
                        disabled={waiting}
                      />
                    </InputGroup>
                    <FormErrorMessage
                      touched
                      errors={props.errors.experts}
                    />
                  </Col>
                </Form.Group>

                <Form.Label xs={3} style={{ marginBottom: '30px' }}>
                  {t('F1_S7_Number_wd')}:
                </Form.Label>

                <Form.Group as={Row}>
                  <Form.Label column xs={2}>
                    {t('F1_S7_Remote')}
                  </Form.Label>
                  <Col xs={2}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        min="0"
                        name="daysRemote"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.daysRemote}
                        className={
                          props.touched.daysRemote && props.errors.daysRemote
                            ? 'error'
                            : null
                        }
                        disabled={waiting}
                      />
                    </InputGroup>
                    <FormErrorMessage
                      touched
                      errors={props.errors.daysRemote}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column xs={2}>
                    {t('F1_S7_Onsite')}
                  </Form.Label>
                  <Col xs={2}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        min="0"
                        name="daysOnsite"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.daysOnsite}
                        className={
                          props.touched.daysOnsite && props.errors.daysOnsite
                            ? 'error'
                            : null
                        }
                        disabled={waiting}
                      />
                    </InputGroup>
                    <FormErrorMessage
                      touched
                      errors={props.errors.daysOnsite}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column xs={2}>
                    {t('F1_S7_Total')}
                  </Form.Label>
                  <Col xs={2}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        disabled={true}
                        value={
                          props.values.daysRemote + props.values.daysOnsite
                        }
                      />
                    </InputGroup>
                  </Col>
                </Form.Group>
              </>
            )}

            <SeparatorDotted />

            {/* <Form.Group as={Row}>
              <Col>
                <Form.Label lg={4} style={{ alignItems: 'center' }}>
                  {t('F3_S7_General_justification_activity')}
                  <RequiredMark style={{ marginLeft: '7px' }}>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  style={{ resize: 'none', marginRight: '18px' }}
                  placeholder="A maximum of 650 characters"
                  name="justification"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.justification}
                  className={
                    props.touched.justification && props.errors.justification
                      ? 'error'
                      : null
                  }
                  disabled={waiting}
                />
                <FormErrorMessage
                  touched
                  errors={props.errors.justification}
                />
              </Col>
                </Form.Group> */}

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: 'center',
                  textAlign: 'justify',
                  lineHeight: '1.3',
                }}
              >
                {t('F3_S7_General_learning_objectives')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Form.Control
                name="learning"
                as="textarea"
                rows={4}
                placeholder={t('Maximum_1300')}
                style={{ resize: 'none' }}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.learning}
                className={
                  props.touched.learning && props.errors.learning
                    ? 'error'
                    : null
                }
                disabled={waiting}
              />
              <FormErrorMessage
                touched={props.touched.learning}
                errors={props.errors.learning}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Col>
                <Form.Label lg={4} style={{ alignItems: 'center' }}>
                  {t('F3_S7_General_alignment')}
                  <RequiredMark style={{ marginLeft: '7px' }}>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  style={{ resize: 'none', marginRight: '18px' }}
                  placeholder={t('Maximum_1300')}
                  name="generalAlignment"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.generalAlignment}
                  className={
                    props.touched.generalAlignment &&
                    props.errors.generalAlignment
                      ? 'error'
                      : null
                  }
                  disabled={waiting}
                />
                <FormErrorMessage
                  touched
                  errors={props.errors.generalAlignment}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col>
                <Form.Label lg={4} style={{ alignItems: 'center' }}>
                  {t('F3_S7_General_participation_members')}
                  <RequiredMark style={{ marginLeft: '7px' }}>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  style={{ resize: 'none', marginRight: '18px' }}
                  placeholder={t('Maximum_1300')}
                  name="generalParticipationMembers"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.generalParticipationMembers}
                  className={
                    props.touched.generalParticipationMembers &&
                    props.errors.generalParticipationMembers
                      ? 'error'
                      : null
                  }
                  disabled={waiting}
                />
                <FormErrorMessage
                  touched
                  errors={props.errors.generalParticipationMembers}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col>
                <Form.Label lg={4} style={{ alignItems: 'center' }}>
                  {t('F3_S7_General_proposal_cross_issues')}
                  <RequiredMark style={{ marginLeft: '7px' }}>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  style={{ resize: 'none', marginRight: '18px' }}
                  placeholder={t('Maximum_1300')}
                  name="generalProposalCrossIssues"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.generalProposalCrossIssues}
                  className={
                    props.touched.generalProposalCrossIssues &&
                    props.errors.generalProposalCrossIssues
                      ? 'error'
                      : null
                  }
                  disabled={waiting}
                />
                <FormErrorMessage
                  touched
                  errors={props.errors.generalProposalCrossIssues}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col>
                <Form.Label lg={4} style={{ alignItems: 'center' }}>
                  {t('F3_S7_General_brief_description_schedule')}
                  <RequiredMark style={{ marginLeft: '7px' }}>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  style={{ resize: 'none', marginRight: '18px' }}
                  placeholder={t('Maximum_1300')}
                  name="descSchedule"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.descSchedule}
                  className={
                    props.touched.descSchedule && props.errors.descSchedule
                      ? 'error'
                      : null
                  }
                  disabled={waiting}
                />
                <FormErrorMessage
                  touched
                  errors={props.errors.descSchedule}
                />
              </Col>
            </Form.Group>

            {props.values.activityTypeId === '4' && (
              <>
                <SeparatorDotted />

                <Form.Label style={{ marginBottom: '30px' }}>
                  {t('F5_S7_Profiles_list')}:
                </Form.Label>
                <ProfileListAccordion formikInstance={props} />
              </>
            )}

            <CenteredModalButtonContainer>
              <ErrorButton
                style={{ marginRight: '20px' }}
                onClick={handleClose}
                disabled={waiting}
              >
                {t('Button_cancel')}
              </ErrorButton>
              <StyledSendButton
                type="submit"
                form="newActivity"
                disabled={waiting}
              >
                {t('Button_save')}
              </StyledSendButton>
            </CenteredModalButtonContainer>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default NewActivityForm
