import styled, { css } from 'styled-components';
import Modal from 'react-bootstrap/Modal';

export const StyledCheck = styled.div`
   border-radius: 15px;
   border: red 2px solid;
   padding: 20px 35px 13px 20px;
`;

export const StyledModalBody = styled(Modal.Body)`
  ${({ theme }) => css`
    color: #6f8d9d;
    padding: 30px 40px;
    background-color: ${theme.brandColors.gray4};
    min-height: 500px;
  `}
`;

export const ModalTitle = styled.div`
  font-weight: 700;
  margin-left: -3px;
  margin-bottom: 20px;
`;
