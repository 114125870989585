import React, { useState } from 'react'
import F8BExpostHeader from '../components/F8BExpostHeader'
import { getF8bExpostSummary } from 'services/initiative'
import Row from 'react-bootstrap/Row'
import { StyledDiv, StyledRow2 } from '../../CommonStyled/Tabs.styled'
import F8BSummaryTable from '../components/F8BSummaryTable'
import { useTranslation } from 'react-i18next'
import htmlParser from 'html-react-parser'

const ExPostSummary = () => {
  const { t } = useTranslation();
  
  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const getData = async (params) => {
    setWaiting(true)
    try {
      const response = await getF8bExpostSummary(params)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  return (
    <StyledDiv>
      <Row>
        <F8BExpostHeader data={data} waiting={waiting} getData={getData} />
      </Row>
      <StyledRow2 style={{marginBottom: '20px'}}>{htmlParser(t('Statistics_footnote_14'))}</StyledRow2>
      <div>
        <F8BSummaryTable data={data} waiting={waiting} />
      </div>
    </StyledDiv>
  )
}

export default ExPostSummary
