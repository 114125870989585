import React, { useState } from 'react';
import SendDialog from './SendDialog';
import { useTranslation } from 'react-i18next';
import { StyledSendButton } from './Footer.styled';
import { StyledSendButton as StyledSaveButton } from 'components/Buttons';
import {  
  StyledFooter,
} from './Footer.styled';

const Footer = (props) => {
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);
  const handleOpenModal = () => setDisplayModal(true);
  const handleCloseModal = () => setDisplayModal(false);
 
  return (
    <>
      <SendDialog 
        displayDialog={displayModal}
        title="title"
        onCloseModal={handleCloseModal} 
      />
      <StyledFooter> 
          <StyledSaveButton type='submit' form="form8" disabled={props.saved}>
              {props.saved? t('Button_saved') : t('Button_Save_Later')} 
          </StyledSaveButton>
          <StyledSendButton disabled={props.nonEditable} onClick={handleOpenModal}>{t('Button_send_f8')}</StyledSendButton>
      </StyledFooter>     
    </>
  )
}

export default Footer;