import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { PrimaryButton } from 'components/Buttons';

const EudComments = ( {comments} ) => {

    const { t } = useTranslation();
    
    return (
        <>
        {comments.map((element, index) => {
            const { user, state, comment} = element;
            const name = `${user.name} ${user.lastName}`;
            const organization = "("+t('organization')+": "+(user.organization ? user.organization : '-')+")";
            const text  = comment ? comment : '-';
            return (
            <p key={index}>
                {t('Comments_for')+" "} <i>{`${name} ${organization}`}</i>: {`[${state}]`} <br/> <i style={{whiteSpace:"pre-wrap"}}>{text}</i>
            </p>
            )
        })}
        </>
    )
}

const IntpaComment = ( {comment} ) => {

    const { t } = useTranslation();

    return (
        <p>{`${t('INTPA_comment')}: `}<i>{comment}</i></p>
    )
}

const AtkeComment = ( {comment} ) => {

    const { t } = useTranslation();

    return (
        <p>{`${t('ATKE_comment')}: `}<i>{comment}</i></p>
    )
}

const ModalComments = (props) => {

    const { t } = useTranslation();
    const rol = props.data && (props.data.rol === 0 ? 'INTPA': props.data.rol === 1 ? 'EUD': 'ATKE');

    return (
        <Modal
            {...props}
            size="lg"
            centered
            animation={false}
        >
            <Modal.Header>
                <Modal.Title >
                    {`${t('Comments_for_section')} ${props.sectionId} ${rol}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.data && props.data.rol === 0 &&  <IntpaComment comment={props.data.value} />}
                {props.data && props.data.rol === 1 &&  <EudComments comments={props.data.value} />}
                {props.data && props.data.rol === 2 &&  <AtkeComment comment={props.data.value} />}
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton onClick={props.onHide}>{t('Button_close')}</PrimaryButton>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalComments;