import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { formInitialValues } from './Section10.initialValues';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import htmlParser from 'html-react-parser'

import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import { SeparatorDotted } from 'components/CommonStyled';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import BeneficiaryForm from './BeneficiaryForm';
import EntityForm from './EntityForm';
import FormLayout from '../../FormLayout';
import FormContainer from 'components/FormContainer';
import { StyledTextValue, DescripcionDiv, Text, StyledTitle, StyledSectionWrapper } from './Section10.styled';
import Comments from 'components/Forms/Form4/Comments';

const Section9 = () => {
  const { t } = useTranslation();
  const { formStore } = useRootStore();
  // eslint-disable-next-line no-unused-vars
  const [saved, setSaved] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [nonEditable, setNonEditable] = useState(false);
  const [formValues, setFormValues] = useState(formInitialValues);

  const handleSaved = value => {
    setSaved(value);
  };

  const handleButton = value => {
    setNonEditable(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = { 
        ...formInitialValues, 
        ...formStore.formSectionData[0],
        benEntities: formStore.formSectionData?.[1],
        directBen: formStore.formSectionData?.[2],
      };
      setFormValues(formSectionData);
      formStore.setCurrentSection(10);
    }
  }, [formStore.formSectionData]);

  return (
    <FormLayout>
      <FormContainer number={10} title={t('F3_S10_title')} formId="form4section10">
        <StyledSectionWrapper>
          <StyledTitle> {t('F3_S10_Subtitle_ownership')} </StyledTitle>

          <DescripcionDiv>
            <Form.Group as={Col}>
              <Form.Label>{htmlParser(t('F3_S10_Description_from_CN'))}:</Form.Label>
              <StyledTextValue> {formValues.benEntities} </StyledTextValue>
            </Form.Group>
          </DescripcionDiv>

          <Text>{t('F3_S10_Subtitle_participation')}</Text>

          <Accordion style={{ marginLeft: '-15px', marginTop: '20px' }}>
            {formValues.ownerList
              ? formValues.ownerList.map((entity, key) => (
                <AccordionItem key={key + 1}>
                  <AccordionItemHeader>
                    <AccordionTitle eventKey={key + 1}>
                      {t('F3_S10_Entity')} {key + 1} - {t('F1_S2_Entity_name')}:
                      {entity ? ' ' + entity.entityName : '-'}
                    </AccordionTitle>
                  </AccordionItemHeader>
                  <Accordion.Collapse eventKey={key + 1}>
                    <AccordionItemBody>
                      <EntityForm data={entity} />
                    </AccordionItemBody>
                  </Accordion.Collapse>
                </AccordionItem>
              ))
              : null}
          </Accordion>

          <StyledTitle> {t('F3_S10_Subtitle_direct_beneficiaries')} </StyledTitle>

          <DescripcionDiv>
            <Form.Group as={Col}>
              <Form.Label>{htmlParser(t('F3_S10_Description_from_CN_2'))}:</Form.Label>
              <StyledTextValue> {formValues.directBen} </StyledTextValue>
            </Form.Group>
          </DescripcionDiv>

          <Accordion style={{ marginLeft: '-15px' }}>
            {formValues.benList
              ? formValues.benList.map((beneficiary, key) => (
                <AccordionItem key={key + 1}>
                  <AccordionItemHeader>
                    <AccordionTitle eventKey={key + 1}>
                      {`${t('F3_S10_Beneficiary')} ${key + 1}`}
                      {!isEmpty(beneficiary.beneficiaryType)
                        ? ` - ${t(`beneficiary_type_${beneficiary.beneficiaryType.id}`)}`
                        : ''}
                    </AccordionTitle>
                  </AccordionItemHeader>
                  <Accordion.Collapse eventKey={key + 1}>
                    <AccordionItemBody>
                      <BeneficiaryForm data={beneficiary} />
                    </AccordionItemBody>
                  </Accordion.Collapse>
                </AccordionItem>
              ))
              : null}
          </Accordion>

          <SeparatorDotted />
        </StyledSectionWrapper>

        <Comments onSaved={handleSaved} onButton={handleButton} />
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section9);
