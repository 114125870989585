import styled, {css} from 'styled-components';
import Modal from 'react-bootstrap/Modal'

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
`;

export const FooterLeft = styled.div`
  display: flex;
  flex: 1;
`;

export const FooterCenter = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
`;

export const FooterRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const SyledTitle = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    color: ${theme.brandColors.blue};
  `}
`;