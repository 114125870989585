import styled, { css } from 'styled-components';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

export const StyledFooter = styled.footer`
  ${({ theme }) => css`
    background-color: ${theme.brandColors.blue} ;
    min-height: 40px;
    color: white;
    padding: 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9;
  `}
`;

export const StyledButton = styled.button`
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  box-shadow: none !important;
  border-radius: 5px;
`;

export const StyledRow = styled(Row)`
  padding: 2px;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonLink = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  box-shadow: none !important;
  color: white;
  margin-bottom: 4px;
  text-decoration: underline;
`;
