import * as Yup from 'yup'
import i18n from 'i18n'

export const validationSchema = Yup.object().shape({
  objective: Yup.string()
    .min(2, 'F1_S8_validation1')
    .max(1300, 'F1_S8_validation2'),
  campaigns: Yup.array().of(
    Yup.object().shape({
      mediaTypeId: Yup.string(),
      url: Yup.string().when('mediaTypeId', {
        is: (mediaTypeId) => mediaTypeId > 5,
        then: Yup.string().nullable(true),
        otherwise: Yup.string()
          .min(5, 'F1_S8_validation3')
          .max(100, 'F1_S8_validation4'),
      }),
      comments: Yup.string()
        .min(5, 'F1_S8_validation5')
        .max(100, 'F1_S8_validation6'),
    }),
  ),
})
