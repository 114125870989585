import React from 'react'
import PageTitle from 'components/PageTitle'
import { PageHeader } from '../CommonStyled/Tabs.styled'
import { useTranslation } from 'react-i18next'
import StatisticsTabs from 'components/Tabs'
import ExPostSummary from './tabs/ExPostSummary'
import ExPostForms from './tabs/ExPostForms'

const ExPost = () => {
  const { t } = useTranslation()
  const titles = [
    t('Statistics_expost_tab1'),
    t('Statistics_expost_tab2')
  ]
  const renderTab1Content = () => <ExPostSummary />
  const renderTab2Content = () => <ExPostForms />


  const contents = [renderTab1Content, renderTab2Content]

  return (
    <>
      <PageHeader>
        <PageTitle title={t('menu_admin_6_11')} />
      </PageHeader>
      <StatisticsTabs titles={titles} contents={contents} />
    </>
  )
}

export default ExPost
