import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledNumberCircle, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  StyledFormLabel,
  StyledIntroParagraph,
  StyledBudgetDiv,
  TextValueLeftSpaced,
  StyledSubtitle,
} from 'components/Forms/Form2/Section7/Section7.styled';
import ActivityForm from 'components/Forms/Form2/Section7/ActivityForm';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import TextValue from 'components/TextValue';
import FakeAccordionItem from '../../FakeAccordionItem';
import { SeparatorDotted } from 'components/CommonStyled';

const Section7 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledNumberCircle> 7 </StyledNumberCircle>
          <StyledTitle> {t('F1_S7_title')} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <StyledIntroParagraph>
          {t('F1_S7_intro')}
        </StyledIntroParagraph>

        {(formValues?.activities && formValues?.activities.map((activity, key) => (
          <React.Fragment key={key}>
            <FakeAccordionItem title={activity.name}>
              <ActivityForm activityIndex={key} data={activity} /> 
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}

        {
          formValues?.activities && (
            <StyledBudgetDiv>
              <StyledSubtitle style={{textAlign: 'center'}}>
                {t('F1_S7_Title_aggregated_planning')}
              </StyledSubtitle>
    
              <Form.Group>
                <StyledFormLabel as={Row} style={{marginBottom: '20px', textAlign: 'justify', lineHeight: '1.3'}}> {t('F1_S7_Intervention_methodology')}:</StyledFormLabel>
                <TextValueLeftSpaced>
                  <TextValue value={formValues?.methodology} />
                </TextValueLeftSpaced>
              </Form.Group>  
    
              <Form.Group as={Row}>
                <StyledFormLabel xs={4}>{t('F1_S7_Estimated_start_day')}:</StyledFormLabel>
                <TextValueLeftSpaced>
                  <TextValue value={formValues?.estFromDate? formValues?.estFromDate.split("T")[0] : ''} />
                </TextValueLeftSpaced>             
              </Form.Group>  
    
              <Form.Group as={Row}>
                <StyledFormLabel xs={4}>{t('F1_S7_Estimated_end_day')}:</StyledFormLabel>
                <TextValueLeftSpaced>
                  <TextValue value={formValues?.estToDate? formValues?.estToDate.split("T")[0] : ''} />
                </TextValueLeftSpaced>
              </Form.Group>  
                  
              <StyledFormLabel lg={8} as={Row} style={{marginBottom: '20px', marginTop: '40px'}}>{t('F1_S7_Total_budget_TCI')}</StyledFormLabel>
              
              <Form.Group as={Row}>
                <StyledFormLabel xs={4}>{t('F1_S7_EU_budget')}:</StyledFormLabel>
                <TextValueLeftSpaced>
                  <TextValue 
                    value={
                      formValues?.activities.map(item => item.ueBudget).reduce((prev, next) => prev + next, 0)
                      + " €"
                    } 
                  />
                </TextValueLeftSpaced>
              </Form.Group>  
    
              <Form.Group as={Row}>
                <StyledFormLabel xs={4}>{t('F1_S7_counterpart_budget')}:</StyledFormLabel>
                <TextValueLeftSpaced>
                  <TextValue 
                    value={
                      formValues?.activities.map(item => item.counterPartBudget).reduce((prev, next) => prev + next, 0)
                      +
                      " €"             
                    } 
                  />
                </TextValueLeftSpaced>
              </Form.Group>  
    
              <Form.Group as={Row}>
                <StyledFormLabel xs={4}>{t('F1_S7_total_budget')}:</StyledFormLabel>
                <TextValueLeftSpaced>
                  <TextValue 
                    value={
                      formValues?.activities.map(item => item.ueBudget).reduce((prev, next) => prev + next, 0)
                      +
                      formValues?.activities.map(item => item.counterPartBudget).reduce((prev, next) => prev + next,0)
                      +
                      " €"                
                    } 
                  />
                </TextValueLeftSpaced>
              </Form.Group>  
            </StyledBudgetDiv>
          )
        }

      </StyledBody>
    </SectionContainer>
  )
}


export default Section7;