import React, { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { validationSchema } from './Validations';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { useTranslation } from 'react-i18next';
import FormLayout from '../../FormLayout';
import { saveFundSection } from 'services/funds';
import MainQuestions from './MainQuestions';
import Markers from './Markers';
import { PrimaryButton } from 'components/Buttons';
import { Row, Col } from 'react-bootstrap';
import { StyledSavedButton } from './Styled';

const formInitialValues = {
  knowledge: '',
  impact: '',
  value: '',
  elements: '',
  overall: '',
  level: false,
  oo1: false,
  oo2: false,
  so3: false,
  so4: false,
  out5: false,
  out6: false,
  out7: false,
  out8: false,
  out9: false,
  marker1: false,
  marker2: false,
  marker3: false,
  marker4: false,
  marker5: false,
  marker6: false,
  marker7: false,
  marker8: false,
  marker9: false,
  marker10: false,
  marker11: false,
  marker12: false,
  marker13: false,
  marker14: false,
  marker15: false,
  marker16: false,
  marker17: false,
  marker18: false,
  marker19: false,
}

const Section12 = () => {
  const { id } = useParams();

  const { t } = useTranslation();
  const formRef = useRef();
  const { formStore } = useRootStore();

  const [saved, setSaved] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [sending, setSending] = useState(false);

  const onSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFundSection(id, 12, values);
      formStore.setSectionStatus(12, complete)
      setSaved(true);
      resetForm({values});
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    let newValues = formInitialValues;
    if (formStore.formSectionData) {
      newValues = {
        ...formStore?.formSectionData?.[0],
      }
    }
    setInitialValues(newValues);
  }, [formStore.formSectionData])


  return (
    <FormLayout>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formRef}
        onSubmit={onSubmit}
      >
        {(
          {
            dirty,
            isValid,
            handleSubmit,
          }
        ) => {
          return (
            <form
              onSubmit={handleSubmit}
              id="form55section11"
            >
              {dirty && setSaved(false)}

              <MainQuestions />

              <Markers levelChecked={false} />

              <div style={{marginTop: '50px', marginLeft: '15px'}}>
                <Row style={{justifyContent: 'space-between'}}>
                  <Col>
                    <>
                      {!saved && <PrimaryButton disabled={sending || !isValid} onClick={handleSubmit}> {t('Button_Save_Later')} </PrimaryButton>}
                      {saved && <StyledSavedButton disabled={true} >
                        {t('Button_saved')}
                      </StyledSavedButton> }
                    </>
                  </Col>
                </Row>
              </div>
            </form>
          )}
        }
      </Formik>
    </FormLayout>
  );
};

export default observer(Section12);
