import React, { useState, useRef } from 'react'
import { Formik } from 'formik';
import { formInitialValues } from './ChangeCoApplicantModal.initialValues'
import { validationSchema } from './ChangeCoApplicantModal.validations';
import { changeCoApplicant } from 'services/initiative';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';

import ConfirmCloseModal from 'components/ConfirmCloseModal';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ErrorButton, StyledSendButton} from 'components/Buttons'
import { Form } from 'react-bootstrap'
import { FormFielErrorMessage, RequiredMark, CenteredModalButtonContainer } from 'components/CommonStyled';
import { 
  StyledModalBody,
  ModalTitle,
} from './ChangeCoApplicantModal.styled'

const ChangeCoApplicantModal = ({handleCloseOk, show, handleCloseOnError, oldEmail }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [waiting, setWaiting] = useState(false);
  const formRef = useRef();

  const [modalConfirmationInfo, setModalConfirmationInfo] = useState({
    isShow: false,
    text: '',
    action: () => {}
  });

  const handleCloseModalConfirm = () => {
    setModalConfirmationInfo({
      isShow: false,
      text: '',
      action: () => {}
    })
  }

  const handleOpenModalConfirmOnClose = () => {
    setModalConfirmationInfo({
      isShow: true,
      text: t('Confirmation close modal'),
      action: () => {
        handleCloseOk();
        handleCloseModalConfirm();
      }
    })
  }
  
  const handleOpenModalConfirmOnSubmit = () => {
    setModalConfirmationInfo({
      isShow: true,
      text: htmlParser(t('F5_S3_Change_Person_Confirmation')),
      action: async () => {
        await onSave(formRef.current.values);
        handleCloseOk();
        handleCloseModalConfirm();
      }
    })
  }

  const onSave = async (values) => {
    try {
      setWaiting(true);
      const data = {
        ...values,
        oldCoApplicantEmail: oldEmail
      };
      await changeCoApplicant(id, data);
      window.location.replace('');
      handleCloseOk();
    } catch (error) {
      throw new Error(error);
    } finally {
      setWaiting(false);
    }
  };
  
  const handleSubmit = () => {
    handleOpenModalConfirmOnSubmit();
  }

  return (
    <>
      <ConfirmCloseModal
        show={modalConfirmationInfo.isShow}
        handleCloseConfirm={handleCloseModalConfirm}
        handleActionOnConfirm={modalConfirmationInfo.action}
        text={modalConfirmationInfo.text}
      />

      <Modal show={show} onHide={handleOpenModalConfirmOnClose} size="xl" backdrop="static" animation={false}>
        <StyledModalBody>
          <ModalTitle> {t('F5_S3_Change_Person_Title')} </ModalTitle>

          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            innerRef={formRef}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit}>
                
                <Form.Group as={Row} style={{alignItems: "center"}}>
                  <Form.Label column lg={4}>{t('F1_S3_Responsisble_person')}<RequiredMark>*</RequiredMark>:</Form.Label>
                  <Col lg={4}>
                    <Form.Control
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      className={touched.firstName && errors.firstName ? "error" : null}
                    />
                    {touched.firstName && errors.firstName ? (
                      <FormFielErrorMessage>{errors.firstName}</FormFielErrorMessage>
                    ): null}
                  </Col>
                  <Col lg={4}>
                    <Form.Control
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      className={touched.lastName && errors.lastName ? "error" : null}
                      placeholder={t('F1_S2_Last_Name')}
                    />
                    {touched.lastName && errors.lastName ? (
                      <FormFielErrorMessage>{errors.lastName}</FormFielErrorMessage>
                    ): null}
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column lg={4}>{t('F1_S2_Position')}<RequiredMark>*</RequiredMark>:</Form.Label>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="position"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.position}
                      className={touched.position && errors.position ? "error" : null}
                    />
                    {touched.position && errors.position ? (
                      <FormFielErrorMessage>{errors.position}</FormFielErrorMessage>
                    ): null}
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column lg={4}>{t('F1_S2_Email')}<RequiredMark>*</RequiredMark>:</Form.Label>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className={touched.email && errors.email ? "error" : null}
                    />
                    {touched.email && errors.email ? (
                      <FormFielErrorMessage>{errors.email}</FormFielErrorMessage>
                    ): null}
                  </Col>
                </Form.Group>
                
                <Form.Group as={Row}>
                  <Form.Label column lg={4}>{t('F1_S2_Phone_number')}<RequiredMark>*</RequiredMark>:</Form.Label>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      className={touched.phone && errors.phone ? "error" : null}
                      placeholder={t('Example')+" +12133734253"} 
                    />
                    {touched.phone && errors.phone ? (
                      <FormFielErrorMessage>{errors.phone}</FormFielErrorMessage>
                    ): null}
                    <Form.Check
                      type="checkbox" 
                      name="auth"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.auth}
                      className={touched.auth && errors.auth ? "error" : null}
                      style={{marginTop: "10px"}}
                      label={t('F1_S3_Authorize_user')}
                      checked={values.auth}
                    />
                    {touched.auth && errors.auth ? (
                      <FormFielErrorMessage>{errors.auth}</FormFielErrorMessage>
                    ): null}
                  </Col>
                </Form.Group>

                <CenteredModalButtonContainer>
                  <ErrorButton style={{marginRight: '20px'}} onClick={handleOpenModalConfirmOnClose} disabled={waiting}> {t('Button_cancel')} </ErrorButton>
                  <StyledSendButton type="submit" disabled={waiting}> {t('Button_save')} </StyledSendButton>
                </CenteredModalButtonContainer>
              </Form>
            )}
          </Formik>
        </StyledModalBody>
      </Modal>
    </>
  
  )
}

export default ChangeCoApplicantModal;