export const initialValues = {
  valueTC: "",
  valueEU: "",
  valueTP: "",
  valueTC1: "",
  valueTC2: "",
  valueTC3: "",
  valueTC4: "",
  valueTC5: "",
  valueTC6: "",
  valueEUActors: "",
  valueEUModalities: "",
  regionalIntegration: "",
  agenda2030: "",
  activities: "",
};
