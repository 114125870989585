import styled, { css } from 'styled-components';

export const Label = styled.span`
  ${({ theme }) => css`
    color: ${theme.brandColors.green};
    font-weight: 600;
    &:hover {
      color: ${theme.brandColors.greenHover};
    }
  `}
`;

export const StyledA = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

