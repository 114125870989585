import React from 'react'
import {
  roleStatesEnum,
  functionStatesEnum,
  TARGET_YEARS,
  REGIONS_OPTION_MAP,
  FUNDS_TITLE_ROW,
  INITIATIVE_TITLE_ROW,
} from 'constants/appConstants'
import i18n from 'i18n'
import {
  Th2,
  Td,
  Th1Pointer,
  Th3Pointer,
  Td2,
} from '../../CommonStyled/Components.styled'
import _sumBy from 'lodash/sumBy'
import {
  renderRow,
  renderTotalForColumns,
  renderTotalForColumnsForExport,
  orderByInitiativeCode,
  orderBySring,
} from 'pages/statistics/CommonHelpers/helpers'
import _filter from 'lodash/filter'
import _orderBy from 'lodash/orderBy'

export const getParamObjectDetail = (
  includeCoordinator,
  includePartner,
  includeCollaborator,
  entityRole,
  typeId,
  countryId,
  region,
  year,
  type,
) => {
  let params = {
    includeCoordinator,
    includePartner,
    includeCollaborator,
  }
  if (entityRole != 0) {
    params.entityRole = entityRole - 1
  }
  if (typeId != 0) {
    params.typeId = typeId
  }
  if (region !== 0) {
    params.region = REGIONS_OPTION_MAP[region]
  }
  if (countryId != 0) {
    params.countryId = countryId
  }
  if (year != 0) {
    params.year = year
  }
  if (type) {
    if (type === '*') {
      params.includeFunds = true;
      params.includeInitiatives = true;
    }
    if (type === 'FUNDS') {
      params.includeFunds = true;
      params.includeInitiatives = false;
    }
    if (type === 'INITIATIVES') {
      params.includeFunds = false;
      params.includeInitiatives = true;
    }
  }
  return params
}

export const T1RenderRows = (rows, years) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(`${row.category}`)}</Th2>
        {renderRow(row, years)}
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      {renderTotalForColumns(rows, years)}
    </tr>
  </>
)

export const T7RenderRows = (rows) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(`${row.category}`)}</Th2>
        <Td>{Intl.NumberFormat('es-AR').format(row.total)}</Td>
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      <Td2>{Intl.NumberFormat('es-AR').format(rows?.reduce((acc, curr) => acc + curr?.total, 0))}</Td2>
    </tr>
  </>
)


export const T2RenderRows = (rows, years) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(roleStatesEnum[row?.category])}</Th2>
        {renderRow(row, years)}
      </tr>
    ))}
    <tr>
      <Th2>
        {i18n.t('F1_S7_Total')} <b>*</b>
      </Th2>
      {renderTotalForColumns(rows, years)}
    </tr>
  </>
)


export const T6RenderRows = (rows) => (
  <>
    {rows.map((row) => {
      return (
        <tr>
          <Th2>{i18n.t(roleStatesEnum[row?.category])}</Th2>
          <Td>{Intl.NumberFormat('es-AR').format(row.total)}</Td>
        </tr>
      )
    })}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      <Td2>{Intl.NumberFormat('es-AR').format(rows?.reduce((acc, curr) => acc + curr?.total, 0))}</Td2>
    </tr>
  </>
)


export const getEntitiesByCountrySummaryExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData.forEach((country) => {
    data.push({
      label: i18n.t(`${country?.category}`),
      col1: country['2021'],
      col2: country['2022'],
      col3: country['2023'],
      col4: country['2024'],
      col5: country?.total,
    })
  })
  return data
}

export const getFundsEntitiesByCountrySummaryExportValues = (exportData) => {
  let data = []
  data.push(FUNDS_TITLE_ROW)
  exportData.forEach((country) => {
    data.push({
      label: i18n.t(`${country?.category}`),
      col1: country['total'],
      col2: '',
      col3: '',
      col4: '',
      col5: '',
    })
  })
  return data
}

export const getEntitiesByRoleSummaryExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData.forEach((row) => {
    data.push({
      label: i18n.t(roleStatesEnum[row?.category]),
      col1: row['2021'],
      col2: row['2022'],
      col3: row['2023'],
      col4: row['2024'],
      col5: row?.total,
    })
  })
  return data
}

export const getFundsEntitiesByRoleSummaryExportValues = (exportData) => {
  let data = []
  data.push(FUNDS_TITLE_ROW)
  exportData.forEach((row) => {
    data.push({
      label: i18n.t(roleStatesEnum[row?.category]),
      col1: row['total'],
      col2: '',
      col3: '',
      col4: '',
      col5: '',
    })
  })
  return data
}

export const getEntitiesDetailExportValues = (exportData) => {
  let data = []
  exportData.forEach((row) => {
    data.push({
      col1: row?.code,
      col2: row?.name,
      col3: row?.acronym,
      col4: row?.entityRole
        ? i18n.t(roleStatesEnum[String(row.entityRole)])
        : row?.entityRole === 0
        ? i18n.t(roleStatesEnum['0'])
        : '-',
      col5: row?.countryName ? i18n.t(row?.countryName) : '-',
      col6: row?.typeId ? i18n.t(`entity_type_${row?.typeId}`) : '-',
      col7: row?.func ? i18n.t(row?.func) : '-',
    })
  })
  return data
}

export const getEntitiesByFunctionSummaryExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData.forEach((row) => {
    data.push({
      label: i18n.t(functionStatesEnum[row?.category]),
      col1: row['2021'],
      col2: row['2022'],
      col3: row['2023'],
      col4: row['2024'],
      col5: row?.total,
    })
  })
  let row = renderTotalForColumnsForExport(exportData, TARGET_YEARS)
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: row[1],
    col3: row[2],
    col4: row[3],
    col5: row[4],
  })
  return data
}

export const getFundsEntitiesByFunctionSummaryExportValues = (exportData) => {
  let data = []
  data.push(FUNDS_TITLE_ROW)
  exportData.forEach((row) => {
    data.push({
      label: i18n.t(functionStatesEnum[row?.category]),
      col1: row['total'],
      col2: '',
      col3: '',
      col4: '',
      col5: '',
    })
  })
  let row = renderTotalForColumnsForExport(exportData, ['2021'])
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: '',
    col3: '',
    col4: '',
    col5: '',
  })
  return data
}

export const getEntitiesByTypeSummaryExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData.forEach((row) => {
    data.push({
      label: i18n.t(`entity_type_${row?.category}`),
      col1: row['2021'],
      col2: row['2022'],
      col3: row['2023'],
      col4: row['2024'],
      col5: row?.total,
    })
  })
  let row = renderTotalForColumnsForExport(exportData, [
    '2021',
    '2022',
    '2023',
    '2024',
  ])
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: row[1],
    col3: row[2],
    col4: row[3],
    col5: row[4],
  })
  return data
}

export const getFundsEntitiesByTypeSummaryExportValues = (exportData) => {
  let data = []
  data.push(FUNDS_TITLE_ROW)
  exportData.forEach((row) => {
    data.push({
      label: i18n.t(`entity_type_${row?.category}`),
      col1: row['total'],
      col2: '',
      col3: '',
      col4: '',
      col5: '',
    })
  })
  let row = renderTotalForColumnsForExport(exportData, [
    '2021',
  ])
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: '',
    col3: '',
    col4: '',
    col5: '',
  })
  return data
}

export const T3RenderRows = (rows, years) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>
          {i18n.t(functionStatesEnum[row?.category])}{' '}
          {row?.category === 'coordinator' || row?.category === 'partner' ? (
            <b>*</b>
          ) : null}
        </Th2>
        {renderRow(row, years)}
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      {renderTotalForColumns(rows, years)}
    </tr>
  </>
)

export const T9RenderRows = (rows) => {
  let aux = [...rows];
  const index = aux.findIndex((item) => functionStatesEnum[item?.category] === functionStatesEnum['collaborator']);
  aux.splice(index, 1)

 return (<>
    {aux.map((row) => (
      <tr>
        <Th2>
          {i18n.t(functionStatesEnum[row?.category])}
        </Th2>
        <Td>{Intl.NumberFormat('es-AR').format(row.total)}</Td>
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      <Td2>{Intl.NumberFormat('es-AR').format(aux?.reduce((acc, curr) => acc + curr?.total, 0))}</Td2>
    </tr>
  </>)
}

export const T4RenderRows = (rows, years) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(`entity_type_${row?.category}`)}</Th2>
        {renderRow(row, years)}
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      {renderTotalForColumns(rows, years)}
    </tr>
  </>
)


export const T8RenderRows = (rows) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(`entity_type_${row?.category}`)}</Th2>
        <Td>{Intl.NumberFormat('es-AR').format(row.total)}</Td>
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      <Td2>{Intl.NumberFormat('es-AR').format(rows?.reduce((acc, curr) => acc + curr?.total, 0))}</Td2>
    </tr>
  </>
)


export const T5RenderRows = (rows) =>
  rows?.map((row) => (
    <tr>
      <Td>{row?.code || '-'}</Td>
      <Td>{row?.name || '-'}</Td>
      <Td>{row?.acronym || '-'}</Td>
      <Td>{i18n.t(roleStatesEnum[String(row?.entityRole)])}</Td>
      <Td>{row?.countryName ? i18n.t(row?.countryName) : '-'}</Td>
      <Td>{row?.typeId ? i18n.t(`entity_type_${row?.typeId}`) : '-'}</Td>
      <Td>{row?.func ? i18n.t(row?.func) : '-'}</Td>
    </tr>
  ))

export const isIncludeCoordinatorFilter = (func1) => {
  if (func1 == 0 || func1 == 1) {
    return true
  } else {
    return false
  }
}

export const isIncludePartnerFilter = (func1) => {
  if (func1 == 0 || func1 == 2) {
    return true
  } else {
    return false
  }
}

export const isIncludeCollaboratorFilter = (func1) => {
  if (func1 == 0 || func1 == 3) {
    return true
  } else {
    return false
  }
}

export const orderEntitiesByCountry = (data) => {
  const filteredData = _filter(data, (item) => {
    return item.total > 0
  })
  return _orderBy(filteredData, ['total'], ['desc'])
}

export const orderDataByField = (data, field, setData, setAsc, asc) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'code':
      orderedData = orderByInitiativeCode(data, field, asc)
      break
    case 'countryName':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(item[field])],
        asc[field] ? ['asc', 'asc'] : ['desc', 'desc'],
      )
      break
    case 'typeId':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(`entity_type_${item[field]}`)],
        asc[field] ? ['asc', 'asc'] : ['desc', 'desc'],
      )
      break
    case 'entityRole':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(roleStatesEnum[String(item[field])])],
        asc[field] ? ['asc', 'asc'] : ['desc', 'desc'],
      )
      break
    case 'func':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(item[field])],
        asc[field] ? ['asc', 'asc'] : ['desc', 'desc'],
      )
      break
    default:
      orderedData = orderBySring(data, field, asc)
      break
  }

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}

export const orderData6 = (data, field, setData, setAsc, asc) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'category': 
      orderedData = _orderBy(
        data,
        [(item) => parseInt(item['category'])],
        asc['category'] ? 'asc' : 'desc',
      )
      break
    case 'activity': 
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(`activity_types_${item.category}`)],
        asc['category'] ? 'asc' : 'desc',
      )
      break
    case 'country':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(item['category'])],
        asc['category'] ? 'asc' : 'desc',
      )
      break
    case 'type':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(`beneficiary_type_${item['category']}`)],
        asc['category'] ? 'asc' : 'desc',
      )
      break
    case 'role':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(`F1_S7_Format${item['category']}`)],
        asc['category'] ? 'asc' : 'desc',
      )
      break
    case 'function':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(functionStatesEnum[item['category']])],
        asc['category'] ? 'asc' : 'desc',
      )
      break
    case 'entity':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(`entity_type_${item['category']}`)],
        asc['category'] ? 'asc' : 'desc',
      )
      break
    case 'rol':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(roleStatesEnum[item['category']])],
        asc['category'] ? 'asc' : 'desc',
      )
      break
    case 'total':
      orderedData = _orderBy(
        data,
        [(item) => item['total']],
        asc['total'] ? 'asc' : 'desc',
      )
      break
  }

  if(field === 'total') {
    aux['total'] = !asc['total']
  } else {
    aux['category'] = !asc['category']
  }
  
  setAsc(aux)
  setData(orderedData)
}

export const orderData = (data, field, setData, setAsc, asc) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'category':
      orderedData = _orderBy(
        data,
        [(field) => i18n.t(field.category)],
        asc[field] ? ['asc'] : ['desc'],
      )
      break
    default:
      orderedData = _orderBy(data, [field], asc[field] ? ['asc'] : ['desc'])
      break
  }

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}

export const orderData3 = (data, field, setData, setAsc, asc) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'category':
      orderedData = _orderBy(
        data,
        [(field) => i18n.t(roleStatesEnum[field?.category])],
        asc[field] ? ['asc'] : ['desc'],
      )
      break
    default:
      orderedData = _orderBy(data, [field], asc[field] ? ['asc'] : ['desc'])
      break
  }

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}

export const renderHeaders2 = (years, handleOrderData) => (
  <>
    <Th3Pointer
      style={{ width: '50%' }}
      scope="col"
      onClick={() => handleOrderData('category')}
    >
      {i18n.t('country_report_subtitle9')} &#8645;
    </Th3Pointer>
    {years.map((year) => (
      <Th1Pointer scope="col" onClick={() => handleOrderData(year)}>
        {year} &#8645;
      </Th1Pointer>
    ))}
    <Th1Pointer scope="col" onClick={() => handleOrderData('total')}>
      {i18n.t('F1_S7_Total')} &#8645;
    </Th1Pointer>
  </>
)

export const renderHeaders3 = (years, handleOrderData) => (
  <>
    <Th3Pointer
      style={{ width: '50%' }}
      scope="col"
      onClick={() => handleOrderData('category')}
    >
      {i18n.t('country_report_subtitle8')} &#8645;
    </Th3Pointer>
    {years.map((year) => (
      <Th1Pointer scope="col" onClick={() => handleOrderData(year)}>
        {year} &#8645;
      </Th1Pointer>
    ))}
    <Th1Pointer scope="col" onClick={() => handleOrderData('total')}>
      {i18n.t('F1_S7_Total')} &#8645;
    </Th1Pointer>
  </>
)

export const renderHeaders4 = (years, handleOrderData) => (
  <>
    <Th3Pointer
      style={{ width: '50%' }}
      scope="col"
      onClick={() => handleOrderData('category')}
    >
      {i18n.t('country_report_subtitle6')}  &#8645;
    </Th3Pointer>
    {years.map((year) => (
      <Th1Pointer scope="col" onClick={() => handleOrderData(year)}>
        {year} &#8645;
      </Th1Pointer>
    ))}
    <Th1Pointer scope="col" onClick={() => handleOrderData('total')}>
      {i18n.t('F1_S7_Total')} &#8645;
    </Th1Pointer>
  </>
)

export const renderHeaders5 = (years, handleOrderData) => (
  <>
    <Th3Pointer
      style={{ width: '50%' }}
      scope="col"
      onClick={() => handleOrderData('category')}
    >
      {i18n.t('country_report_subtitle7')} &#8645;
    </Th3Pointer>
    {years.map((year) => (
      <Th1Pointer scope="col" onClick={() => handleOrderData(year)}>
        {year} &#8645;
      </Th1Pointer>
    ))}
    <Th1Pointer scope="col" onClick={() => handleOrderData('total')}>
      {i18n.t('F1_S7_Total')} &#8645;
    </Th1Pointer>
  </>
)

export const orderData2 = (data, field, setData, setAsc, asc) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'category':
      orderedData = _orderBy(
        data,
        [(field) => i18n.t(`entity_type_${field?.category}`)],
        asc[field] ? ['asc'] : ['desc'],
      )
      break
    default:
      orderedData = _orderBy(data, [field], asc[field] ? ['asc'] : ['desc'])
      break
  }

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}
