import React, { useState, useEffect }  from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import { Col } from 'react-bootstrap';
import {
  StyledFooter,
  StyledButton,
  StyledRow,
  StyledButtonLink
} from './CookiesFooter.styled';

const CookiesFooter = ({ handleShowCookiePolicy, handleHideCookieFooter }) => {
  const { t } = useTranslation();
  const [showButtons, setShowButtons] = useState(true);

  const cookie_all = Cookies.get('user-cookie-decision-all');
  const cookie_functional = Cookies.get('user-cookie-decision-functional');
  const EXPIRATION_DAYS = 180; //6 months

  useEffect(() => {
    if(cookie_all === 'true' || cookie_functional === 'true'){
      setShowButtons(false);
    }
  }, [cookie_all, cookie_functional])

  const handleAcceptAll = () => {
    Cookies.set('user-cookie-decision-all', 'true', { expires: EXPIRATION_DAYS });
    Cookies.set('user-cookie-decision-functional', 'false', { expires: EXPIRATION_DAYS });
    Cookies.set('user-cookie-decision-footer', 'false', { expires: EXPIRATION_DAYS });
    setShowButtons(false);
  }

  const handleAcceptFunctional = () => {
    Cookies.set('user-cookie-decision-all', 'false', { expires: EXPIRATION_DAYS });
    Cookies.set('user-cookie-decision-functional', 'true', { expires: EXPIRATION_DAYS });
    Cookies.set('user-cookie-decision-footer', 'false', { expires: EXPIRATION_DAYS });
    setShowButtons(false);
  }

  return (
    <>
      <StyledFooter>
        { showButtons ? (
            <>
              <StyledRow style={{marginBottom: '10px'}}>
                <span>
                  {`${t('Cookies_disclaimer_alert_1')} `}
                  <StyledButtonLink variant="link" onClick={handleShowCookiePolicy} >
                    {t('Cookies_disclaimer_alert_2')}
                  </StyledButtonLink> 
                </span>
              </StyledRow>
              <StyledRow>
                <Col xs={2}>
                  <StyledButton onClick={handleAcceptAll}>
                    {t('Accept_all_cookies')}
                  </StyledButton>
                </Col>
                <Col xs={3}>
                  <StyledButton onClick={handleAcceptFunctional}>
                    {t('Accept_functional_cookies')}
                  </StyledButton>
                </Col>
              </StyledRow>
            </>
          ) : (
            <>
              <StyledRow style={{marginBottom: '10px'}}>
                <span>
                  {`${t('Confirmation_cookies_1')} `}
                  <StyledButtonLink variant="link" onClick={handleShowCookiePolicy} >
                    {t('cookie_policy')}
                  </StyledButtonLink>
                  {` ${t('Confirmation_cookies_2')}`}
                </span>
                <StyledButton style={{marginLeft: '10px'}} onClick={handleHideCookieFooter}>
                  {t('Close_cookies')}
                </StyledButton>
              </StyledRow>
            </>      
          )
        }
      </StyledFooter>
    </>
  )
}

export default CookiesFooter
