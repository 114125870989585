import React, { useState, useEffect } from 'react';
import { getAllF8F9byType } from 'services/initiative';
import { useTranslation } from 'react-i18next';
import { roleNamesConst } from 'constants/appConstants'
import { useRootStore } from 'stores';
import { observer } from 'mobx-react-lite';

import Table from 'react-bootstrap/Table';
import PageTitle from 'components/PageTitle';
import { PageHeader, PageContent } from './ExpostReporting.styled';
import TableRow from './TableRow';

const getFormTypeByRole = (role) => {

  const routeByRole = {
    [roleNamesConst.INTPA]: 'F9c',
    [roleNamesConst.EUD]: 'F9a',
    [roleNamesConst.ATKE]: 'F8b',
    [roleNamesConst.ATNKE]: 'F8b',
    [roleNamesConst.CONSORTIUM]: 'F8b',
    [roleNamesConst.PUBLIC]: 'F8',
    [roleNamesConst.GUEST]: 'F8',
  }
  return routeByRole[role];
}

const ExpostReporting = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [expostReports, setExpostReports] = useState([]);
  const { authStore } = useRootStore();

  const role = authStore.roles[0];
  const isIntpa = role === roleNamesConst.INTPA;
  const isEud = role === roleNamesConst.EUD;
  const isATKE = role === roleNamesConst.ATKE;
  const isATKNKE = role === roleNamesConst.ATNKE;
  const isCONSORTIUM = role === roleNamesConst.CONSORTIUM;

  const pageTitle = isATKE? t('menu_admin_14') : (isEud? t('menu_admin_15') : t('menu_admin_16'));

  useEffect(() => {
    fetchF8F9Forms();
  }, [])

  const fetchF8F9Forms = async () => {
    try {
      setLoading(true);
      const formType = getFormTypeByRole(role);
      const response = await getAllF8F9byType(formType);
      setExpostReports(response);
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <PageHeader>
        <PageTitle title={pageTitle}/>
      </PageHeader>
      <PageContent>
        {loading
          ? <p>{t('Loading')}</p>
          : (
            <Table>
              <thead>
                <tr>
                  <th>{t('Grid_Id')}</th>
                  <th>{t('Grid_Name')}</th>
                  <th>{t('Grid_status_F8a')}</th>
                  <th>{t('Grid_deadline_F8a')}</th>
                  {
                    (isATKE || isATKNKE || isCONSORTIUM) 
                    &&
                    <>
                      <th>{t('Grid_reporting_F8b')}</th>
                      <th>{t('Grid_deadline_F8b')}</th>
                      <th>{t('Grid_status_F9c')}</th>
                      <th>{t('Grid_new_expost')}</th>
                    </>
                  }
                  {
                    (isEud) 
                    &&
                    <>
                      <th>{t('Grid_status_F8b')}</th>
                      <th>{t('Grid_deadline_F8b')}</th>
                      <th>{t('Grid_reporting_F9a')}</th>
                      <th>{t('Grid_deadline_F9a')}</th>
                    </>
                  }
                  {
                    (isIntpa) 
                    &&
                    <>
                      <th>{t('Grid_status_F8b')}</th>
                      <th>{t('Grid_deadline_F8b')}</th>
                      <th>{t('Grid_reporting_F9c')}</th>
                      <th>{t('Grid_deadline_F9c')}</th>
                    </>
                  }
                </tr>
              </thead>
              <tbody>
                {
                  !(expostReports && expostReports.length)
                    ? <tr><td colSpan={4} style={{ textAlign: "center" }}>{t('No_data_available')}</td></tr>
                    : expostReports.map(item => (
                      <TableRow 
                        key={item.id} 
                        item={item} 
                        isIntpa={isIntpa}
                        isEud={isEud}
                        isATKE={isATKE}
                        isATKNKE={isATKNKE}
                        isCONSORTIUM={isCONSORTIUM}
                      />
                    ))
                }
              </tbody>
            </Table>
          )
        }
      </PageContent>
    </>
  )
};

export default observer(ExpostReporting);
