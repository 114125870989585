import React, { useState, useEffect } from 'react'
import { Formik, FieldArray } from 'formik'
import { validationSchema } from './Section10.validations'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { saveFormSection } from 'services/initiative'
import { f3statusNamesConst, roleNamesConst } from 'constants/appConstants'
import { useConstantsForm3 } from 'customHooks/useConstantsForm3'
import { useRootStore } from 'stores'
import { formInitialValues } from './Section10.initialValues'
import { useTranslation } from 'react-i18next'
import htmlParser from 'html-react-parser'

import { PrimaryButton, ErrorButton } from 'components/Buttons'
import Accordion from 'react-bootstrap/Accordion'
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from 'components/CustomAccordion'
import { SeparatorDotted } from 'components/CommonStyled'
import ConfirmCloseModal from 'components/ConfirmCloseModal'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import BeneficiaryForm from './BeneficiaryForm'
import EntityForm from './EntityForm'
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer'
import {
  StyledTextValue,
  DescripcionDiv,
  Text,
  StyledTitle,
  StyledContainer,
  StyledIntroParagraph
} from './Section10.styled'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const beneficiaryObject = {
  beneficiaryType: '',
  beneficiaryDescription: '',
  beneficiaryQuantification: '',
  beneficiaryJustification: '',
  beneficiaryProspect: '',
  answersF3S10BenCountryList: [],
}

const Section10 = () => {
  const { t } = useTranslation()
  const { id, formId, sectionId } = useParams()
  const { formStore, initiativeStore } = useRootStore()
  const [saved, setSaved] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [selectedKeyEntity, setSelectedKeyEntity] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [selectedKeyBeneficiary, setSelectedKeyBeneficiary] = useState(null)
  const [nonEditable, setNonEditable] = useState(false)
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const { beneficiaryTypes, la_ca_eu_countries } = useConstantsForm3()
  const [modalRemove, setModalRemove] = useState(false)

  const openModalRemove = (key) => {
    setModalRemove(true)
    setSelectedKeyEntity(key)
  }

  const closeModalRemove = () => {
    setModalRemove(false)
    setSelectedKeyEntity(null)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f3statusNamesConst.DRAFT ||
        initiativeStore.role === roleNamesConst.GUEST,
    )
  }, [initiativeStore.formStateName])

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {
        ...formInitialValues,
        ...formStore.formSectionData[0],
        benEntities: formStore.formSectionData?.[1],
        directBen: formStore.formSectionData?.[2],
      }
      for (const key in formSectionData) {
        if (!formSectionData[key]) {
          formSectionData[key] = ''
        }
      }
      if (formSectionData.benList) {
        formSectionData.benList.forEach(function (beneficiary) {
          beneficiary.beneficiaryTypeId = beneficiary.beneficiaryType
            ? beneficiary.beneficiaryType.id
            : ''
          for (const key in beneficiary.answersF3S10BenCountryList) {
            if (!beneficiary.answersF3S10BenCountryList[key].countryId) {
              beneficiary.answersF3S10BenCountryList[key].countryId =
                beneficiary.answersF3S10BenCountryList[key].country.id
            }
          }
        })
      }
      setInitialValues(formSectionData)
    }
  }, [formStore.formSectionData])

  useEffect(() => {
    if (saved) window.scrollTo(0, 0)
  }, [saved])

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values)
      formStore.setSectionStatus(sectionId, complete)
      resetForm({ values })
      setSaved(true)
    } catch (error) {
      console.log(error)
    }
  }

  const onValidateSubmit = (e, isValid, values, resetForm) => {
    e.preventDefault()
    isValid ? handleSubmit(values, { resetForm }) : alert(t('not_saved_alert'))
  }

  return (
    <FormLayout>
      <FormContainer
        number={10}
        title={t('F3_S10_title')}
        formId="form3section10"
        isHelp={true}
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <form
              onSubmit={(e) => {
                onValidateSubmit(
                  e,
                  props.isValid,
                  props.values,
                  props.resetForm,
                )
              }}
              id="form3section10"
            >
              {props.dirty && setSaved(false)}

              <StyledContainer>
                <StyledIntroParagraph>
                  {htmlParser(t('F3_S10_intro'))}
                </StyledIntroParagraph>
                <StyledTitle> {t('F3_S10_Subtitle_ownership')} </StyledTitle>
                <DescripcionDiv>
                  <Form.Group as={Col}>
                    <Form.Label style={{ textAlign: 'justify' }}>
                      {htmlParser(t('F3_S10_Description_from_CN'))}
                    </Form.Label>
                    <StyledTextValue>
                      {props.values.benEntities}
                    </StyledTextValue>
                  </Form.Group>
                </DescripcionDiv>

                <Text>{t('F3_S10_Subtitle_participation')}</Text>

                <Accordion>
                  {initialValues.ownerList
                    ? props.values.ownerList.map((entity, key) => (
                        <AccordionItem key={key + 1}>
                          <AccordionItemHeader>
                            <AccordionTitle eventKey={key + 1}>
                              {t('F3_S10_Entity')} {key + 1} -
                              {t('F1_S2_Entity_name')}:
                              {entity ? ' ' + entity.entityName : '-'}
                            </AccordionTitle>
                          </AccordionItemHeader>
                          <Accordion.Collapse eventKey={key + 1}>
                            <AccordionItemBody>
                              <EntityForm
                                formikInstance={props}
                                ownerIndex={key}
                                nonEditable={nonEditable}
                              />
                            </AccordionItemBody>
                          </Accordion.Collapse>
                        </AccordionItem>
                      ))
                    : null}
                </Accordion>

                <StyledTitle>
                  {t('F3_S10_Subtitle_direct_beneficiaries')}
                </StyledTitle>

                <DescripcionDiv>
                  <Form.Group as={Col}>
                    <Form.Label style={{ textAlign: 'justify' }}>
                      {htmlParser(t('F3_S10_Description_from_CN_2'))}
                    </Form.Label>
                    <StyledTextValue>{props.values.directBen}</StyledTextValue>
                  </Form.Group>
                </DescripcionDiv>

                <FieldArray
                  name="benList"
                  render={(arrayHelpers) => (
                    <>
                      {modalRemove && (
                        <ConfirmCloseModal
                          show={modalRemove}
                          text={t('remove_beneficiary', { name: selectedKeyBeneficiary + 1 })}
                          handleActionOnConfirm={() => {
                            arrayHelpers.remove(selectedKeyBeneficiary)
                            closeModalRemove()
                          }}
                          handleCloseConfirm={closeModalRemove}
                        />
                      )}
                      <Accordion>
                        {initialValues.benList
                          ? props.values.benList.map((beneficiary, key) => (
                              <AccordionItem key={key + 1}>
                                <AccordionItemHeader>
                                  <AccordionTitle eventKey={key + 1}>
                                    {`${t('F3_S10_Beneficiary')} ${key + 1}`}
                                    {beneficiary.beneficiaryTypeId
                                      ? ` - ${t(
                                          `beneficiary_type_${beneficiary.beneficiaryTypeId}`,
                                        )}`
                                      : ''}
                                  </AccordionTitle>
                                </AccordionItemHeader>
                                <Accordion.Collapse eventKey={key + 1}>
                                  <AccordionItemBody>
                                    <BeneficiaryForm
                                      formikInstance={props}
                                      beneficiaryIndex={key}
                                      nonEditable={nonEditable}
                                      countries={la_ca_eu_countries}
                                      benTypes={beneficiaryTypes}
                                    />
                                    <ErrorButton
                                      onClick={() => openModalRemove(key)}
                                      disabled={nonEditable}
                                    >
                                      {t('F3_S10_Beneficiary_button_delete')}
                                    </ErrorButton>
                                  </AccordionItemBody>
                                </Accordion.Collapse>
                              </AccordionItem>
                            ))
                          : null}
                      </Accordion>
                      {typeof props.errors.benList === 'string' ? (
                        <FormErrorMessage
                          touched
                          errors={props.errors.benList}
                        />
                      ) : null}
                      <SeparatorDotted />
                      <PrimaryButton
                        onClick={() => arrayHelpers.push(beneficiaryObject)}
                        disabled={nonEditable}
                      >
                        {t('F3_S10_Beneficiary_button_add')}
                      </PrimaryButton>
                    </>
                  )}
                />
              </StyledContainer>
            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section10)
