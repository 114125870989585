export const ADELANTE_1_INITIATIVES = [
  {
    id: 1,
    title: 'Contributing to food security for the population of Cuba',
    title_ES: 'Aporte a la seguridad alimentaria de la población de Cuba',
    entities: [
      {
        name: 'Agencia Chilena de Cooperación Internacional para el Desarrollo',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Chile',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de Agricultura',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Cuba',
        func: null,
        entityRole: null
      },
      {
        name: 'Servicio Agrícola y Ganadero (SAG)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Chile',
        func: null,
        entityRole: null
      }
    ],
    instrument: 'adelante1',
    objective: 'Increasing the availability of safe food of animal origin and raising levels of animal health in order to improve food security for the population of Cuba.',
    objective_ES: 'Elevar los niveles de sanidad animal con el fin de aumentar la disponibilidad de alimentos inocuos de origen animal, mejorando así la seguridad alimentaria para la población de Cuba.',
    primaryGoalId: 0, // TODO
    secondaryGoalId: 0, // TODO
    website: 'https://www.adelante-i.eu/en/contributing-food-security-population-cuba',
    websiteEs: 'https://www.adelante-i.eu/aporte-la-seguridad-alimentaria-de-la-poblacion-de-cuba'
  },
  {
    id: 2,
    title: 'DIALOGAS - Inclusive Development in Latin America: A Great Opportunity for Governments and Social Actors',
    title_ES: 'DIALOGAS - Desarrollo Inclusivo en América Latina: una Oportunidad para Gobiernos y Actores Sociales',
    entities: [
      {
        name: 'Agencia Chilena de Cooperación Internacional para el Desarrollo',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Chile',
        func: null,
        entityRole: null
      },
      {
        name: 'Departamento nacional de planeación',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Colombia',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio Coordinador de Desarrollo Social',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Ecuador',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de Educación (Oficina General de Cooperación Internacional)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Peru',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de Trabajo',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Costa Rica',
        func: null,
        entityRole: null
      },
      {
        name: 'Organización Internacional Ítalo-Latinoamericana (IILA)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Italia',
        func: null,
        entityRole: null
      },
      {
        name: 'Secretaría de Acción Social',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Paraguay',
        func: null,
        entityRole: null
      },
      {
        name: 'Secretaría Técnica de Planificación de la Presidencia',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'El Salvador',
        func: null,
        entityRole: null
      }
    ],
    instrument: 'adelante1',
    objective: 'Strengthening the effectiveness of programs aimed to overcome poverty and improve education,  through the inclusion and evaluation of transversal and socio-emotional skills.',
    objective_ES: 'Incluir las competencias transversales y socioemocionales en los programas educativos y de superación de la pobreza con el fin de mejorar su eficacia, aumentando así las oportunidades para la población más vulnerable.',
    primaryGoalId: 0, // TODO
    secondaryGoalId: 0, // TODO
    website: 'https://www.adelante-i.eu/en/dialogas',
    websiteEs: 'https://www.adelante-i.eu/dialogas'
  },
  {
    id: 3,
    title: 'Entrepreneurship and Business Development in Mesoamerica - EDEM',
    title_ES: 'EDEM - Emprendimiento y Desarrollo Empresarial en Mesoamérica',
    entities: [
      {
        name: 'Agencia Presidencial Para la Cooperación Internacional (APC)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Colombia',
        func: null,
        entityRole: null
      },
      {
        name: 'Cámara de Comercio de Bogota',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Colombia',
        func: null,
        entityRole: null
      },
      {
        name: 'Cámara de Comercio de Cartagena',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Colombia',
        func: null,
        entityRole: null
      },
      {
        name: 'Cámara de Comercio de Santo Domingo',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'República Dominicana',
        func: null,
        entityRole: null
      },
      {
        name: 'Cámara de Comercio e Industria de El Salvador',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'El Salvador',
        func: null,
        entityRole: null
      },
      {
        name: 'Cámara de Comercio e Industria de Tegucigalpa',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Honduras',
        func: null,
        entityRole: null
      },
      {
        name: 'Cámara de Comercio y Servicios',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Nicaragua',
        func: null,
        entityRole: null
      },
      {
        name: 'Centro Regional para la promoción de la micro y pequeña empresa (CENPROMYPE)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: '',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de Comercio, Industria y Turismo',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Colombia',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de Economía',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Guatemala',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de Industria y Comercio',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'República Dominicana',
        func: null,
        entityRole: null
      },
      {
        name: 'Secretaria de Desarrollo Económico - Subsecretaria de Mipyme SSE',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Honduras',
        func: null,
        entityRole: null
      }
    ],
    instrument: 'adelante1',
    objective: 'Supporting entrepreneurs to develop and strengthen their business ideas and supporting small and medium enterprises to increase their capacity for innovation.',
    objective_ES: 'Apoyar a los empresarios y a las pequeñas y medianas empresas con el fin de que desarrollen sus ideas empresariales y aumenten su capacidad de innovación.',
    primaryGoalId: 0, // TODO
    secondaryGoalId: 0, // TODO
    website: 'https://www.adelante-i.eu/en/edem',
    websiteEs: 'https://www.adelante-i.eu/edem'
  },
  {
    id: 4,
    title: 'EVALÚA - Evaluating Government Policies in Latin America and the Caribbean',
    title_ES: 'EVALÚA - Evaluación de políticas públicas en América Latina y Caribe',
    entities: [
      {
        name: 'Dirección Nacional de Planeación (DNP)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Colombia',
        func: null,
        entityRole: null
      },
      {
        name: 'Fundación Internacional y para Iberoamérica de Administración y Políticas Públicas (FIIAPP)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'España',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de Economía y Finanzas',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Peru',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de Hacienda',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Paraguay',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de Planificación Nacional y Política Económica (MIDEPLAN)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Costa Rica',
        func: null,
        entityRole: null
      },
      {
        name: 'Oficina de Planeamiento y Presupuesto Presidencia de la República',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Uruguay',
        func: null,
        entityRole: null
      },
      {
        name: 'Secretaría Técnica Planifica',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Ecuador',
        func: null,
        entityRole: null
      }
    ],
    instrument: 'adelante1',
    objective: 'Institutionalizing public policy evaluation to allow informed and effective decision-making and to contribute to a more equal and sustainable society.',
    objective_ES: 'Institucionalizar la evaluación de las políticas públicas con el fin de fomentar la toma de decisiones informada y efectiva contribuyendo así a una sociedad más igualitaria y sostenible.',
    primaryGoalId: 0, // TODO
    secondaryGoalId: 0, // TODO
    website: 'https://www.adelante-i.eu/en/evalua',
    websiteEs: 'https://www.adelante-i.eu/evalua'
  },
  {
    id: 5,
    title: 'Strengthening Restorative Justice',
    title_ES: 'Fortalecimiento de la Justicia Restaurativa',
    entities: [
      {
        name: 'Poder Judicial',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Costa Rica',
        func: null,
        entityRole: null
      },
      {
        name: 'Escuela Judicial Rodrigo Lara Bonilla',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Colombia',
        func: null,
        entityRole: null
      },
      {
        name: 'Poder Judicial del Estado de México',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Mexico',
        func: null,
        entityRole: null
      }
    ],
    instrument: 'adelante1',
    objective: 'Applying alternative solutions to incarceration, promoting a more humane and equitable justice that provides opportunities to the most vulnerable populations.',
    objective_ES: 'Aplicar soluciones alternativas al encarcelamiento con el fin de promover una justicia más humana y equitativa, aumentando así las oportunidades para las poblaciones más vulnerables.',
    primaryGoalId: 0, // TODO
    secondaryGoalId: 0, // TODO
    website: 'https://www.adelante-i.eu/en/strengthening-restorative-justice',
    websiteEs: 'https://www.adelante-i.eu/fortalecimiento-justicia-restaurativa'
  },
  {
    id: 6,
    title: 'Mirada Ciudadana - Good governance in Mercosur municipalities',
    title_ES: 'Mirada Ciudadana - La buena gobernanza en municipios del Mercosur',
    entities: [
      {
        name: 'Intendencia de Cerro Largo',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Uruguay',
        func: null,
        entityRole: null
      },
      {
        name: 'Municipio de Aceguá',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Brasil',
        func: null,
        entityRole: null
      },
      {
        name: 'Municipio de Candiota',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Brasil',
        func: null,
        entityRole: null
      },
      {
        name: 'Municipio de Peñalolén',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Chile',
        func: null,
        entityRole: null
      },
      {
        name: 'Municipio de Presidente Franco',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Paraguay',
        func: null,
        entityRole: null
      },
      {
        name: 'Municipio de San Bernardino',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Paraguay',
        func: null,
        entityRole: null
      },
      {
        name: 'Municipio de San Isidro',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Argentina',
        func: null,
        entityRole: null
      }
    ],
    instrument: 'adelante1',
    objective: 'This project aims to improve the governance and implementation of quality public policies that reduce the gaps between inequalities existing in the region.',
    objective_ES: 'Fortalecer las capacidades institucionales y la buena gobernabilidad de los municipios con el fin de mejorar la eficiencia de su gestión, logrando así políticas públicas de calidad enfocadas a reducir las brechas de desigualdad.',
    primaryGoalId: 0, // TODO
    secondaryGoalId: 0, // TODO
    website: 'https://www.adelante-i.eu/en/mirada-ciudadana',
    websiteEs: 'https://www.adelante-i.eu/mirada-ciudadana'
  },
  {
    id: 7,
    title: 'Proyecto SEDA - Sustainable Sericulture',
    title_ES: 'Proyecto SEDA - Sericultura sustentable',
    entities: [
      {
        name: 'Centro Turístico y de Capacitación Sericícola y de Rebocería de Jiquilpa-Michoacán',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'México',
        func: null,
        entityRole: null
      },
      {
        name: 'Estación experimental Indio Hatuey',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Cuba',
        func: null,
        entityRole: null
      },
      {
        name: 'Instituto Nacional de Tecnología Agropecuaria (INTA)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Argentina',
        func: null,
        entityRole: null
      },
      {
        name: 'Instituto Nacional de Tecnología Industrial (INTI)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Argentina',
        func: null,
        entityRole: null
      },
      {
        name: 'Società Cooperativa Sociale SOCIOLARIO Onlus',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Italia',
        func: null,
        entityRole: null
      },
      {
        name: 'Universidad de Buenos Aires (Facultad de Agronomía)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Argentina',
        func: null,
        entityRole: null
      },
      {
        name: 'Universidad Estadual de Maringá (Laboratorio de Biotecnología)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Brasil',
        func: null,
        entityRole: null
      },
      {
        name: 'Universidad Estatal Amazónica (Departamento de Ciencias de la Tierra)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Ecuador',
        func: null,
        entityRole: null
      },
      {
        name: 'Universidad Pontificia Bolivariana (Facultad de Ingeniería Textil)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Colombia',
        func: null,
        entityRole: null
      }
    ],
    instrument: 'adelante1',
    objective: 'Providing training, tools and appropriate technology for sericulture to support women and vulnerable populations develop a sustainable economic activity.',
    objective_ES: 'Proporcionar capacidades, herramientas y tecnología apropiada para la sericultura con el fin de favorecer el desarrollo de una actividad económica sostenible adaptada para las poblaciones más vulnerables.',
    primaryGoalId: 0, // TODO
    secondaryGoalId: 0, // TODO
    website: 'https://www.adelante-i.eu/en/proyecto-seda',
    websiteEs: 'https://www.adelante-i.eu/proyecto-seda'
  },
  {
    id: 8,
    title: 'Red Calle - Developing policies to support the homeless',
    title_ES: 'Red Calle - Desarrollo de políticas de atención a las personas en situación de calle',
    entities: [
      {
        name: 'Ministerio de Desarrollo Humano e Inclusión Social',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Costa Rica',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de Desarrollo Social',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Brasil',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de Desarrollo Social',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Chile',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de Desarrollo Social',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Uruguay',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de la Niñez y la Adolescencia',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Paraguay',
        func: null,
        entityRole: null
      },
      {
        name: 'Ministerio de Salud y Protección Social',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Colombia',
        func: null,
        entityRole: null
      },
      {
        name: 'Organización Internacional Ítalo-Latinoamericana (IILA)',
        countryId: 0,
        acronym: null,
        typeId: null,
        countryName: 'Italia',
        func: null,
        entityRole: null
      }
    ],
    instrument: 'adelante1',
    objective: 'Influencing public authorities and sensitizing civil society to the cause of the homeless by means of a more effective people care policy aimed at improving their living conditions.',
    objective_ES: 'Influir en las instituciones y sensibilizar a la sociedad civil sobre la realidad de las personas en situación de calle con el fin de lograr políticas públicas más eficaces orientadas a mejorar sus condiciones de vida.',
    primaryGoalId: 0, // TODO
    secondaryGoalId: 0, // TODO
    website: 'https://www.adelante-i.eu/en/red-calle',
    websiteEs: 'https://www.adelante-i.eu/red-calle'
  },
];