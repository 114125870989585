import React, { useState, useEffect } from 'react';
import { Formik, getIn } from 'formik';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { validationSchema } from './ConsultantsForm.validations';
import { saveConsultantsF5S7, getConsultantsF5S7 } from 'services/initiative';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import { PrimaryButton, ErrorButton, StyledSendButton } from 'components/Buttons';
import { CenteredModalButtonContainer } from 'components/CommonStyled';
import { StyledFormLabel, StyledCheck } from './ConsultantsForm.styled';
import TextValue from 'components/TextValue';
import DayJS from 'dayjs';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const ConsultantsForm = ({handleCloseOk, handleClose, activity, handleCloseSubModalOnSave, handleCloseSubModalOnError, nonEditable}) => {

  const { t } = useTranslation();
  const { id } = useParams();
  const [formValues, setFormValues] = useState([]);
  const [confirms, setConfirms] = useState([]);
  const [allTrue, setAllTrue] = useState(false);

  const fieldPath = (field, key) => `profiles.${key}.${field}`;

  const fieldInfo = (props, field) => {
    const getFieldError = getIn(props.errors, field);
    const getFieldTouched = getIn(props.touched, field);
    return {
      touched: getFieldTouched,
      error: (
        <FormErrorMessage
          touched={getFieldTouched}
          errors={getFieldError}
        />
      )
    }
  }
   
  const buildDataArray = (values) => {
    let array = [];
    values.profiles.forEach((value) => (
      array.push({id: value.id, confirmed: value.consultantsConfirmed})
    ))
    return array;
  }

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr);
    return date.format("DD/MM/YYYY");
  }
  
  const handleSubmit = async (values) => {
    try {
      const array = buildDataArray(values);
      await saveConsultantsF5S7(id, activity.id, array);
      handleCloseSubModalOnSave();
    } catch (error) {
      handleCloseSubModalOnError();
    }
  };

  useEffect(() => {
    async function getValues() {
      
      let response = await getConsultantsF5S7(id, activity.id);
      setFormValues({profiles: response.data});
      
      const profiles = [];
      let confirm = true;
      
      response.data.forEach((profile)=> {
        profiles[profile.id]= profile.consultantsConfirmed;
        if(!profile.consultantsConfirmed)
            confirm = false;
      })
      
      setConfirms(profiles);
      setAllTrue(confirm);
    }
    getValues();
  }, []);
  
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
      {(props) => (
          <Form onSubmit={props.handleSubmit} id="consultants">
              <Accordion>
                {props.values.profiles
                    ? ( props.values.profiles.map((consultant, key) => (
                        <AccordionItem key={key+1}>
                            <AccordionItemHeader>
                                <AccordionTitle eventKey={key+1}>{t('F3_S7_Accordion_consultant')+" "+(key+1)}</AccordionTitle>
                            </AccordionItemHeader>
                            <Accordion.Collapse eventKey={key+1}>
                                <AccordionItemBody style={{marginLeft: '20px'}}>

                                  <StyledFormLabel xs={4}>{t('F3_S7_Consultant_qualifications')}:</StyledFormLabel>
                                  <TextValue value={consultant.skills} />

                                  <StyledFormLabel xs={4}>{t('F3_S7_Consultant_general_experience')}:</StyledFormLabel>
                                  <TextValue value={consultant.generalExp} />

                                  <StyledFormLabel xs={4}>{t('F3_S7_Consultant_specific_experience')}:</StyledFormLabel>
                                  <TextValue value={consultant.specificExp} />

                                  <StyledFormLabel xs={4}>{t('F3_S7_Consultant_requires_translation')}:</StyledFormLabel>
                                  <TextValue value={consultant.interpretation} />

                                  <StyledCheck>
                                    <Form.Group>
                                      <Form.Check 
                                        disabled={( confirms[consultant.id] || nonEditable || (activity.activityState.name === 'CANCELLED'))} 
                                        style={{textAlign: 'justify'}} 
                                        name={fieldPath('consultantsConfirmed', key)}
                                        type="checkbox" 
                                        checked={props.values.profiles[key].consultantsConfirmed }
                                        { ...props.getFieldProps(fieldPath('consultantsConfirmed',key)) }
                                        label={t('F5_S7_Consultants_confirmation_check')} 
                                        className={props.touched[fieldPath('consultantsConfirmed')] && props.errors[fieldPath('consultantsConfirmed')] ? "error" : null}
                                      />
                                      {fieldInfo(props, fieldPath('consultantsConfirmed')).error}
                                    </Form.Group>
                                  </StyledCheck>

                                  { consultant.consultantsTimestamp &&
                                    <div style={{marginTop: '15px', marginLeft: '10px'}}>
                                      {`${t('F5_S7_Confirmed')}: ${consultant.consultantsTimestamp? formatDate(consultant.consultantsTimestamp): ""}`}
                                    </div>
                                  }
                                </AccordionItemBody>
                            </Accordion.Collapse>
                        </AccordionItem>
                      )))
                      : null}
              </Accordion>

              <CenteredModalButtonContainer>
                {
                  (allTrue || (activity.activityState.name === 'CANCELLED') || nonEditable)
                  ?
                    <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
                  :
                    <>
                      <ErrorButton style={{marginRight: '20px'}} onClick={handleClose}> {t('Button_cancel')} </ErrorButton>
                      <StyledSendButton type="submit" form="consultants"> {t('Button_save')} </StyledSendButton>
                    </>
                }
              </CenteredModalButtonContainer>

          </Form>
            )}
      </Formik>
    </>
  )
}

export default observer(ConsultantsForm);