import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { formInitialValues } from './NewEUBudgetLineForm.initialValues'
import { validationSchema } from './NewEUBudgetLineForm.validations'
import {
  addF5S7ActivityDetail,
  saveFile,
  getDownload,
} from 'services/initiative'
import { getUploadFormatsConstants } from 'services/apiConstants'
import { useConstantsForm5 } from 'customHooks/useConstantsForm5'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import htmlParser from 'html-react-parser'

import { PrimaryButton } from 'components/Buttons'
import InputGroup from 'react-bootstrap/InputGroup'
import FileUploader from 'components/FileUploader'
import MoneyInput from 'components/MoneyInput'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { ErrorButton, StyledSendButton } from 'components/Buttons'
import { Form } from 'react-bootstrap'
import {
  FormFielErrorMessage,
  RequiredMark,
  CenteredModalButtonContainer,
} from 'components/CommonStyled'
import { StyledFormLabel } from './NewEUBudgetLineForm.styled'
import { WORKING_DAYS_CONSTANT } from 'constants/budgetConstants';

const NewEUBudgetLineForm = ({
  activity,
  handleCloseAll,
  handleClose,
  handleCloseSubModalOnError,
  setModalInfo,
  nonEditable,
}) => {
  const { t } = useTranslation()
  const { id, formId, sectionId } = useParams()
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const { resourceTypes } = useConstantsForm5()
  const [validFileFormats, setValidFileFormats] = useState([])
  const [isUploadingFile, setIsUploadingFile] = useState(false)

  const PERDIEM_CONSTANT = 250

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getUploadFormatsConstants()
        if (response && response.F3S7) {
          setValidFileFormats(response.F3S7)
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetchAPI()
  }, [])

  useEffect(() => {
    document.getElementById('EURadio').checked = true
    setInitialValues(formInitialValues)
  }, [])

  const onSave = async (values) => {
    try {
      values.totalMoney =
        WORKING_DAYS_CONSTANT * values.workingDays +
        PERDIEM_CONSTANT * values.perDiemDays +
        values.estimatedCost
      const response = await addF5S7ActivityDetail(id, activity.id, values)
      handleCloseAll()
    } catch (error) {
      console.log(error)
      handleCloseSubModalOnError()
    }
  }

  const handleFileUpload = async (e, setFieldValue, handleChange) => {
    try {
      const file = document.getElementById('fileInput').files[0]
      const fileExtension = file.name.split('.').pop()
      if (file.size > 20971520) {
        setModalInfo({
          message: t('Error_file4'),
          type: 'error',
          isOpen: true,
        })
      } else if (!validFileFormats.includes(fileExtension.toUpperCase())) {
        setModalInfo({
          message: t('Error_file5'),
          type: 'error',
          isOpen: true,
        })
      } else {
        setIsUploadingFile(true)
        const uid = await saveFile(id, formId, sectionId, file)
        setFieldValue('file.fileName', file.name)
        setFieldValue('file.uuid', uid)
        handleChange(e)
      }
    } catch (error) {
      setModalInfo({
        message: t('Error_file2'),
        type: 'error',
        isOpen: true,
      })
    } finally {
      setIsUploadingFile(false)
    }
  }

  const handleDownload = async (uuid, downloadName) => {
    try {
      const file = await getDownload(uuid).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', downloadName)
        document.body.appendChild(link)
        link.click()
      })
    } catch (error) {
      setModalInfo({
        message: t('Error_file1'),
        type: 'error',
        isOpen: true,
      })
    }
  }

  const renderResourceTypes = () => {
    let resourceTypesFiltered = [...resourceTypes]
    if (activity.activityType.id != 4) {
      resourceTypesFiltered.shift()
    }
    if (activity.activityType.id == 6) {
      resourceTypesFiltered.splice(0, 3)
    }
    return resourceTypesFiltered.map((resource) => (
      <option key={resource.id} value={resource.id}>
        {t(`resource_type_${resource.id}`)}
      </option>
    ))
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <StyledFormLabel column lg={4}>
                {t('F3_S7_Budgetline_resources_cat')}
                <RequiredMark>*</RequiredMark>:
              </StyledFormLabel>
              <Col lg={4}>
                <Form.Control
                  name="resourceTypeId"
                  as="select"
                  onChange={(e) => {
                    setFieldValue('perDiemDays', 0)
                    setFieldValue('workingDays', 0)
                    setFieldValue('estimatedCost', 0)
                    if (e.currentTarget.value == 12) {
                      document.getElementById('CPRadio').checked = true
                    }
                    handleChange(e)
                  }}
                  value={values.resourceTypeId}
                  disabled={nonEditable}
                >
                  <option value="">{t('Placeholder_select')}</option>
                  {renderResourceTypes()}
                </Form.Control>
                {touched.resourceTypeId && errors.resourceTypeId ? (
                  <FormFielErrorMessage>
                    {errors.resourceTypeId}
                  </FormFielErrorMessage>
                ) : null}
              </Col>
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: 'center',
                  textAlign: 'justify',
                  lineHeight: '1.3',
                }}
              >
                {t('F3_S7_Budgetline_description')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Form.Control
                name="description"
                as="textarea"
                rows={3}
                placeholder="A maximum of 600 characters"
                style={{ resize: 'none' }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                className={
                  touched.description && errors.description ? 'error' : null
                }
                disabled={nonEditable}
              />
              {touched.description && errors.description ? (
                <FormFielErrorMessage>
                  {errors.description}
                </FormFielErrorMessage>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Row>
                <StyledFormLabel>
                  {t('F3_S7_Budgetline_who_will_pay')}
                  <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <label>
                  <input
                    disabled={nonEditable || values.resourceTypeId == 12}
                    id="EURadio"
                    type="radio"
                    name="ueOrCounterpart"
                    value={'0'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ marginLeft: '30px' }}
                  />
                  <span style={{ marginLeft: '5px' }}>
                    {t('F3_S7_Budgetline_EU_pays')}
                  </span>
                </label>
                <label>
                  <input
                    disabled={nonEditable || values.resourceTypeId == 12}
                    id="CPRadio"
                    type="radio"
                    name="ueOrCounterpart"
                    value={'1'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ marginLeft: '50px' }}
                  />
                  <span style={{ marginLeft: '5px' }}>
                    {t('F3_S7_Budgetline_EU_parnership')}
                  </span>
                </label>
              </Row>
              <div style={{ marginLeft: '0' }}>
                {touched.ueOrCounterpart && errors.ueOrCounterpart ? (
                  <FormFielErrorMessage>
                    {errors.ueOrCounterpart}
                  </FormFielErrorMessage>
                ) : null}
              </div>
            </Form.Group>

            {values.resourceTypeId == 1 && (
              <Form.Group as={Row}>
                <Form.Label column md={2} xs={3}>
                  {t('F3_S7_Budgetline_working_days')}
                  <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Col xs={3}>
                  <Form.Control
                    type="number"
                    min="0"
                    name="workingDays"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.workingDays}
                    className={
                      touched.workingDays && errors.workingDays ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  {touched.workingDays && errors.workingDays ? (
                    <FormFielErrorMessage>
                      {errors.workingDays}
                    </FormFielErrorMessage>
                  ) : null}
                </Col>
              </Form.Group>
            )}

            {values.resourceTypeId == 9 && (
              <Form.Group as={Row}>
                <Form.Label column md={2} xs={3}>
                  {t('F3_S7_Budgetline_perdime_days')}
                  <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Col xs={3}>
                  <Form.Control
                    type="number"
                    min="0"
                    name="perDiemDays"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.perDiemDays}
                    className={
                      touched.perDiemDays && errors.perDiemDays ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  {touched.perDiemDays && errors.perDiemDays ? (
                    <FormFielErrorMessage>
                      {errors.perDiemDays}
                    </FormFielErrorMessage>
                  ) : null}
                </Col>
              </Form.Group>
            )}

            {values.resourceTypeId != 9 && values.resourceTypeId != 1 && (
              <Form.Group as={Row} style={{ alignItems: 'center' }}>
                <Form.Label column md={2} xs={3}>
                  {t('F3_S7_Budgetline_estimated_cost')}
                  <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Col xs={3}>
                  <InputGroup style={{ width: '240px' }}>
                    <MoneyInput
                      name="estimatedCost"
                      value={values.estimatedCost}
                      onChange={(val) => setFieldValue('estimatedCost', val)}
                      onBlur={handleBlur}
                      customInput={Form.Control}
                      className={
                        touched.estimatedCost && errors.estimatedCost
                          ? 'error'
                          : null
                      }
                      disabled={nonEditable}
                    />
                  </InputGroup>
                  {touched.estimatedCost && errors.estimatedCost ? (
                    <FormFielErrorMessage>
                      {errors.estimatedCost}
                    </FormFielErrorMessage>
                  ) : null}
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label column md={2} xs={3}>
                {t('F3_S7_Budgetline_total_amount')}:
              </Form.Label>
              <Col xs={3}>
                <InputGroup style={{ width: '240px' }}>
                  <MoneyInput
                    name="estimatedCost"
                    value={
                      WORKING_DAYS_CONSTANT * values.workingDays +
                      PERDIEM_CONSTANT * values.perDiemDays +
                      values.estimatedCost
                    }
                    customInput={Form.Control}
                    disabled={true}
                  />
                </InputGroup>
              </Col>
              <Col xs={4}>
                {htmlParser(t('F3_S7_Budgetline_eur_conversion_link'))}
              </Col>
            </Form.Group>

            <FileUploader
              file={values?.file}
              handleFileUpload={(e) =>
                handleFileUpload(e, setFieldValue, handleChange)
              }
              handleDownload={handleDownload}
              isUploadingFile={isUploadingFile}
              uploadLabel={`${t('F3_S7_Upload_quote')} (${validFileFormats.join(
                ', ',
              )})`}
              nonEditable={nonEditable}
            />

            <CenteredModalButtonContainer>
              {nonEditable ? (
                <PrimaryButton
                  onClick={() => {
                    handleCloseAll()
                  }}
                >
                  
                  OK
                </PrimaryButton>
              ) : (
                <>
                  <ErrorButton
                    style={{ marginRight: '20px' }}
                    onClick={handleClose}
                    disabled={nonEditable}
                  >
                    
                    {t('Button_cancel')}
                  </ErrorButton>
                  <StyledSendButton type="submit" disabled={nonEditable}>
                    
                    {t('Button_save')}
                  </StyledSendButton>
                </>
              )}
            </CenteredModalButtonContainer>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default observer(NewEUBudgetLineForm)
