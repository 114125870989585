export const formInitialValues = {
  resourceTypeId: "",
  ueOrCounterpart: 0,
  description: "",
  workingDays: 0,
  perDiemDays: 0,
  estimatedCost: 0,
  totalMoney: 0,
  file: null
};
  