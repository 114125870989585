import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { useTranslation } from 'react-i18next';
import { formatDateDDMMYY } from 'helpers/dateHelpers';

import Comments from 'components/Forms/Form4/Comments';
import ActivityItem from './ActivityItem';
import ActivityGeneralModal from './ActivityGeneralModal';
import FormLayout from '../../FormLayout';
import FormContainer from 'components/FormContainer';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from 'components/CustomAccordion';
import { StyledDivWrapper, StyledSeparator } from './Section7.styled';
import {
  mustShowFootNote,
  mustShowOldFootNote,
  hasOldBudgetValidation,
} from 'constants/budgetConstants';
import {
  StyledBudgetDiv,
  StyledNoticeParagraph,
  BlackSpan
} from 'components/Forms/Form5/Section7/Section7.styled';
import MoneyTextMask from 'components/MoneyTextMask';
import { useGetBudgetRestrictions } from 'customHooks/useGetBudgetRestrictions';

const Section7 = () => {
  const { t } = useTranslation();
  const { formStore, initiativeStore } = useRootStore();
  const [formValues, setFormValues] = useState({
    activities: [],
  });
  const [nonEditable, setNonEditable] = useState(false);
  const [saved, setSaved] = useState(false);
  const [activityModal, setActivityModal] = useState({
    formikInstance: null,
    show: false,
    formType: 0,
    title: '',
    activity: {},
    activityCode: '',
  });

  const handleSaved = value => {
    setSaved(value);
  };

  const handleButton = value => {
    setNonEditable(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCloseGeneralModal = () => {
    setActivityModal({
      ...activityModal,
      show: false,
    });
  };

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = [...formStore.formSectionData];
      setFormValues(formSectionData[0]);
      formStore.setCurrentSection(7);
    }
  }, [formStore.formSectionData]);

  const hasOldValidation = hasOldBudgetValidation(initiativeStore?.code);

  const {
    euBudgetMin,
    euBudgetLimit,
    euBudgetPercentageLimit
  } = useGetBudgetRestrictions();

  return (
    <FormLayout>
      <FormContainer
        number={7}
        title={t('F3_S7_title')}
        formId="form4section7"
        showSavedMessage={saved}
        showButtonClarif={!nonEditable}
      >
        <StyledDivWrapper>
          <ActivityGeneralModal
            show={activityModal.show}
            formType={activityModal.formType}
            handleCloseOk={() => {
              handleCloseGeneralModal();
            }}
            title={activityModal.title}
            activity={activityModal.activity}
            activityCode={activityModal.activityCode}
          />

          <StyledBudgetDiv>
            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label column xs={5}>
                {t('F1_S7_EU_budget')}:
              </Form.Label>
              <Col xs={3}>
                <InputGroup>
                  <BlackSpan>
                    <MoneyTextMask value={formValues?.ueBudget} disabled={true} customInput={Form.Control} />
                  </BlackSpan>
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label column xs={5}>
                {t('F1_S7_counterpart_budget')}:
              </Form.Label>
              <Col xs={3}>
                <InputGroup>
                  <BlackSpan>
                    <MoneyTextMask
                      value={formValues?.allianceBudget}
                      disabled={true}
                      customInput={Form.Control}
                    />
                  </BlackSpan>
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label column xs={5}>
                {t('F5_S7_Total_amount_executed')}:
              </Form.Label>
              <Col xs={3}>
                <InputGroup>
                  <BlackSpan>
                    <MoneyTextMask value={formValues?.totalBudget} disabled={true} customInput={Form.Control} />
                  </BlackSpan>
                </InputGroup>
              </Col>
            </Form.Group>

            {
              hasOldValidation ? (
                mustShowOldFootNote(
                  formValues?.totalBudget,
                  formValues?.ueBudget,
                  euBudgetMin,
                  euBudgetPercentageLimit
                ) && (
                  <StyledNoticeParagraph>{t('F1_S7_budget_foot_note')}</StyledNoticeParagraph>
                )
              ) : (
                mustShowFootNote(
                  formValues?.totalBudget,
                  formValues?.ueBudget,
                  euBudgetMin,
                  euBudgetLimit,
                  euBudgetPercentageLimit
                ) && (
                  <StyledNoticeParagraph>{t('F1_S7_budget_foot_note2024')}</StyledNoticeParagraph>
                )
              )
            }
          </StyledBudgetDiv>

          <Accordion>
            {formValues.activities.map((activity, key) => (
              <AccordionItem key={key + 1}>
                <AccordionItemHeader>
                  <AccordionTitle eventKey={key + 1}>{`${activity.name} - ${t(
                    `activity_types_${activity.activityType.id}`,
                  )} (${activity.fromDate ? formatDateDDMMYY(activity.fromDate) : '-'})`}</AccordionTitle>
                </AccordionItemHeader>
                <Accordion.Collapse eventKey={key + 1}>
                  <AccordionItemBody style={{ marginLeft: '30px' }}>
                    <ActivityItem handleOpenGeneralModal={setActivityModal} activity={activity} />
                  </AccordionItemBody>
                </Accordion.Collapse>
              </AccordionItem>
            ))}
          </Accordion>
        </StyledDivWrapper>

        <StyledSeparator />

        <Comments onSaved={handleSaved} onButton={handleButton} />
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section7);
