import PrivateRoute from './PrivateRoute';
import Layout from 'layout';
import ApplicantInitiative from 'pages/applicant/Initiative';
import ApplicantCN from 'pages/applicant/ConceptNotes';
import ApplicantProposals from 'pages/applicant/Proposals';
import ApplicantManagement from 'pages/applicant/Management';
import ApplicantExpostReportList from 'pages/applicant/ExpostReporting';
import ApplicantExpostReportForm from 'pages/applicant/ExpostReportForm';
import ManagerDocuments from 'pages/manager/Documents';
import ApplicantContractManagement from 'pages/applicant/ContractManagement';
import FundsContainer from 'pages/manager/Funds/Funds.container';
import React from 'react';
import CommunityReport from 'components/CommunityReport/CommunityReport';

const ApplicantRoutes = () => [
  <PrivateRoute
    path="/applicant/initiative/:id/form/:formId/section/:sectionId"
    component={ApplicantInitiative}
    layout={Layout}
  />,
  <PrivateRoute path="/applicant/concept-notes" component={ApplicantCN} layout={Layout} />,
  <PrivateRoute path="/applicant/proposals" component={ApplicantProposals} layout={Layout} />,
  <PrivateRoute
    path="/applicant/management/implementation-forms"
    component={ApplicantManagement}
    layout={Layout}
  />,
  <PrivateRoute
    path="/applicant/management/expost-reporting"
    component={ApplicantExpostReportList}
    layout={Layout}
  />,
  <PrivateRoute
    path="/applicant/expost-reporting/:formId"
    component={ApplicantExpostReportForm}
    layout={Layout}
  />,
  <PrivateRoute path="/applicant/documents" component={ManagerDocuments} layout={Layout} />,
  <PrivateRoute path="/applicant/contract-management" component={ApplicantContractManagement} layout={Layout} />,
  <PrivateRoute
    path="/applicant/funds"
    component={FundsContainer}
    layout={Layout}
  />,
  <PrivateRoute
    path="/applicant/community"
    component={CommunityReport}
    layout={Layout}
  />,
];

export default ApplicantRoutes;
