import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  overview: Yup.string(),
  relations1: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2'),
  relations2: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2'),
  relations3: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2'),
  relations4:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2'),
});
