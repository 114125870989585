import styled, { css } from 'styled-components'

export const StyledTr = styled.tr`
  ${({ theme }) => css`
    color: ${theme.brandColors.gray1};
  `}
`;

export const StyledSpan = styled.span`
  ${({ theme }) => css`
    color: ${props => props.expired ? "red" : theme.brandColors.gray1};
  `}
`;