import React from 'react'
import { useTranslation } from 'react-i18next'
import Spinner from 'react-bootstrap/Spinner'
import { renderInitiative } from '../helpers/countryReportHelpers'

const CountryReport = ({ country, countryFlag, year, roleName, data, waiting }) => {
  const { t } = useTranslation()

  const renderInitiatives = (initiatives) => {
    return (
      <div>
        {initiatives.map((initiative) => 
          renderInitiative(country, countryFlag, roleName, initiative)
        )}
      </div>
    )
  }

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data ? (
    <>
      {renderInitiatives(data)}
    </>
  ) : (
    <>
      {t('No_data_available_2')}
    </>
  )
}

export default CountryReport
