import styled from 'styled-components'

import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'

export const StyledDropdownMenu = styled(Dropdown.Menu)`
  min-width: 100px;
  min-height:100px;
  overflow-y:hidden;
  overflow-x: hidden;
`;


export const StyledButton = styled(Button)`
  padding: 5px;
  border: none;
  background: none;
  color: black;
  &:hover, &:active{
    border: none;
    background: none;
    text-decoration: none;
  }

`;

export const StyledSpan = styled.span`
  color:#89a2ad;
  font-weight: 600;
  &:hover{
    color: black;
  }
`;







