import styled from 'styled-components';

export const CustomDatePickerButton = styled.div`
  cursor: ${props => props.disabled ? "default" : "pointer"};
  padding-left: 20px;
  padding-right: 20px;
  padding: 5px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  text-align: center;
  border-radius: 4px;
  background-color: ${props => props.disabled ? "#EBEBE4" : "white"};
  color: ${props => props.disabled ? "gray" : "black"};
  border: 1px solid #ced4da;
`;

export const DatePickerContainer = styled.div`
  flex-direction: column !important;
  display: flex !important;
  width: 100px !important;
  margin-left: 10px;
  margin-right: 10px;
`;