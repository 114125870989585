import React from 'react';
import { StyledColorCircle } from './NewMessagesTableIndicator.styled';

const NewMessagesTableIndicator = ({ hasNewMessages }) => {
  return (
    hasNewMessages ? (
      <StyledColorCircle />
    ) : null
  );
};


export default NewMessagesTableIndicator;