import styled, {css} from 'styled-components';
import Row from 'react-bootstrap/Row';

export const StyledTitle = styled.h3`
    ${({ theme }) => css`
        color: ${theme.brandColors.blue};
    `}
`;

export const StyledIndicator = styled.div`
    border: 2px solid lightgrey;
    border-radius: 5px;
    width: 355px;
    height: 250px;
    justify-content: center;
    align-items: center;
`;

export const StyledSubtitle = styled.h5`
   color: grey;
   margin-top: 20px;
   margin-bottom: 20px;
`;

export const StyledText = styled.h5`
    color: grey;
    margin-right: 10px;
`;

export const StyledValue = styled.h5`
    ${({ theme }) => css`
    color: ${theme.brandColors.blue};
    `}
`;

export const StyledRow = styled(Row)`
    display: flex;
    justify-content: center;
`;