/* eslint-disable no-useless-catch */
import axiosInstance from './axiosInstance'
import { initiativeStatusNames } from 'constants/appConstants'

export const createInitiative = async (data) => {
  const url = `/api/initiative/`
  try {
    const response = await axiosInstance.post(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const saveFormSection = async (initiative, form, section, data) => {
  const url = `/api/initiative/${initiative}/form/F${form}/section/${section}`
  try {
    const response = await axiosInstance.put(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const saveFile = async (initiative, form, section, file) => {
  const url = `/api/file/upload`
  try {
    var formData = new FormData()
    formData.append('file', file)
    formData.append('initiative', initiative)
    formData.append('form', form)
    formData.append('section', section)
    const response = await axiosInstance.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFormSection = async (initiative, form, section) => {
  const url = `/api/initiative/${initiative}/form/F${form}/section/${section}`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getConstantsForm = async (form, params) => {
  const url = `/api/constants/form/F${form}`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const sendConceptNote = async (initiative, form) => {
  const url = `/api/initiative/${initiative}/form/F${form}/`
  try {
    const response = await axiosInstance.put(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getDownload = async (uuid) => {
  const url = `/api/file/download/${uuid}`
  try {
    const response = await axiosInstance.get(url, {
      responseType: 'blob',
    })
    return response
  } catch (error) {
    throw error
  }
}

export const getEudList = async (form, initiativeId, participant) => {
  const url = `/api/eud/initiative/${initiativeId}/form/${form}?participant=${participant}`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                         HOMES                                        */
//////////////////////////////////////////////////////////////////////////////////////////

export const intpaF1DeadlineChange = async (initiativeId, formId, dateStr) => {
  const url = `/api/initiative/${initiativeId}/form/F${formId}/deadline`
  try {
    const response = await axiosInstance.patch(url, { date: dateStr })
    return response
  } catch (error) {
    throw error
  }
}

export const getImplementationInitiatives = async () => {
  const url = `/api/initiative`
  try {
    const response = await axiosInstance.get(url, {
      params: {
        initiativeState: initiativeStatusNames.IMPLEMENTATION,
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFormulationInitiatives = async () => {
  const url = `/api/initiative`
  try {
    const response = await axiosInstance.get(url, {
      params: {
        initiativeState: initiativeStatusNames.FORMULATION,
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getPreFormulationInitiatives = async () => {
  const url = `/api/initiative`
  try {
    const response = await axiosInstance.get(url, {
      params: {
        initiativeState: initiativeStatusNames.PRE_FORMULATION,
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                          F2S9                                        */
//////////////////////////////////////////////////////////////////////////////////////////

export const getF2Section9 = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F2/section/9`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const saveForm2GeneralAssesment = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F2/section/9`
  try {
    const response = await axiosInstance.put(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const sendF2toMainAssessor = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F2/`
  try {
    const response = await axiosInstance.patch(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const sendF2Clarifications = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F2/`
  try {
    const response = await axiosInstance.put(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const sendF2IntpaDecision = async (initiative, decision) => {
  const url = `/api/initiative/${initiative}/form/F2/accept`
  try {
    const response = await axiosInstance.put(
      url,
      { accepted: decision },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const addEudToForm2 = async (email, initiativeId) => {
  const url = `/api/eud/${email}/initiative/${initiativeId}/form/F2/`
  try {
    const response = await axiosInstance.post(url)
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                          F4S15                                       */
//////////////////////////////////////////////////////////////////////////////////////////

export const getF4Section15 = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F4/section/15`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const saveForm4GeneralAssesment = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F4/section/15`
  try {
    const response = await axiosInstance.put(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const sendF4toMainAssessor = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F4/`
  try {
    const response = await axiosInstance.patch(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const sendF4IntpaDecision = async (initiative, decision) => {
  const url = `/api/initiative/${initiative}/form/F4/accept`
  try {
    const response = await axiosInstance.put(
      url,
      { accepted: decision },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const enableSection7 = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F4/`
  try {
    const response = await axiosInstance.patch(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const addEudToForm4 = async (email, initiativeId) => {
  const url = `/api/eud/${email}/initiative/${initiativeId}/form/F4/`
  try {
    const response = await axiosInstance.post(url)
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                          F3S7                                        */
//////////////////////////////////////////////////////////////////////////////////////////

export const createNewActivityF3S7 = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F3/section/7/activity/`
  try {
    const response = await axiosInstance.post(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const deleteActivityF3S7 = async (initiative, activityId) => {
  const url = `/api/initiative/${initiative}/form/F3/section/7/activity/${activityId}`
  try {
    const response = await axiosInstance.delete(url)
    return response
  } catch (error) {
    throw error
  }
}

export const saveGeneralInfoF3S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F3/section/7/activity/${activityId}/general`
  try {
    const response = await axiosInstance.put(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const saveProductsF3S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F3/section/7/activity/${activityId}/product`
  try {
    const response = await axiosInstance.put(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const saveConsultantF3S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F3/section/7/activity/${activityId}/profile`
  try {
    const response = await axiosInstance.put(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const saveParticipantF3S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F3/section/7/activity/${activityId}/participant`
  try {
    const response = await axiosInstance.put(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const saveResourceDetailF3S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F3/section/7/activity/${activityId}/resource/detail`
  try {
    const response = await axiosInstance.put(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const deleteResourceDetailF3S7 = async (
  initiative,
  activityId,
  detailId,
) => {
  const url = `/api/initiative/${initiative}/form/F3/section/7/activity/${activityId}/resource/detail/${detailId}`
  try {
    const response = await axiosInstance.delete(url)
    return response.data
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                          F5S2                                        */
//////////////////////////////////////////////////////////////////////////////////////////

export const patchUpdatePerson = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/2/update-applicant`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const addF5S2Applicant = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/2/add-applicant`
  try {
    const response = await axiosInstance.put(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const deleteF5S2Applicant = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/2/add-applicant`
  try {
    const response = await axiosInstance.delete(url, { data: data })
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                          F5S3                                        */
//////////////////////////////////////////////////////////////////////////////////////////

export const changeCoApplicant = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/3/update-coapplicant`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const addF5S3CoApplicant = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/3/add-co-applicant`
  try {
    const response = await axiosInstance.put(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const deleteF5S3CoApplicant = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/3/add-co-applicant`
  try {
    const response = await axiosInstance.delete(url, { data: data })
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                          F5S7                                        */
//////////////////////////////////////////////////////////////////////////////////////////

export const createNewActivityF5S7 = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/`
  try {
    const response = await axiosInstance.post(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const saveGeneralInfoF5S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const saveGeneralInfoMgmF5S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity-at/${activityId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const saveAssessmentsF5S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/assessment`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const saveParticipantsF5S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/participant`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const saveProductsF5S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/product`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const saveProductsMgmF5S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity-at/${activityId}/product`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const addProductF5S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/product`
  try {
    const response = await axiosInstance.post(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const getGeneralInfoF5S7 = async (initiative, activityId) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getProductsF5S7 = async (initiative, activityId) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/product`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getAssessmentsF5S7 = async (initiative, activityId) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/assessment`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const saveAssessmentsMgmF5S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity-at/${activityId}/assessment`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const getParticipantsF5S7 = async (initiative, activityId) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/participant`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getResourcesFromProposalF5S7 = async (initiative, activityId) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/resources-proposal`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getConsultantsF5S7 = async (initiative, activityId) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/profile`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const saveConsultantsF5S7 = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/profile`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const getActivityDetailsF5S7 = async (initiative, activityId) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const cancelActivityAT = async (initiative, activityId) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/cancel`
  try {
    const response = await axiosInstance.patch(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getF5S7TDR = async (initiative, activityId) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/tdr`
  try {
    let response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

///////////////////////////// BUDGET ////////////////////////////////////////////////////

export const modifyActivityDetailF5S7 = async (
  initiative,
  activityId,
  detailId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const modifyActivityDetailF5S7AT = async (
  initiative,
  activityId,
  detailId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity-at/${activityId}/detail/${detailId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const saveF5S7ActivityParticipantsMgmt = async (
  initiative,
  activityId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/participants-list`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const addF5S7ActivityDetailProvider = async (
  initiative,
  activityId,
  detailId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/provider`
  try {
    const response = await axiosInstance.post(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const modifyF5S7ActivityDetailProvider = async (
  initiative,
  activityId,
  detailId,
  providerId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/provider/${providerId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const setF5S7ActivityDetailSelectedProvider = async (
  initiative,
  activityId,
  detailId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/selected-provider`
  try {
    const response = await axiosInstance.post(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const changeF5S7ActivityDetailSelectedProvider = async (
  initiative,
  activityId,
  detailId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/selected-provider`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const modifyF5S7ActivityDetailSelectedProvider = async (
  initiative,
  activityId,
  detailId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/selected-provider`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const deleteF5S7ActivityDetailProvider = async (
  initiative,
  activityId,
  detailId,
  providerId,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/provider/${providerId}`
  try {
    const response = await axiosInstance.delete(url)
    return response
  } catch (error) {
    throw error
  }
}

export const addF5S7ActivityDetailDocument = async (
  initiative,
  activityId,
  detailId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/document`
  try {
    const response = await axiosInstance.post(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const modifyF5S7ActivityDetailDocument = async (
  initiative,
  activityId,
  detailId,
  documentId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/document/${documentId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const deleteF5S7ActivityDetailDocument = async (
  initiative,
  activityId,
  detailId,
  documentId,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/document/${documentId}`
  try {
    const response = await axiosInstance.delete(url)
    return response
  } catch (error) {
    throw error
  }
}

export const addF5S7ActivityDetailPayment = async (
  initiative,
  activityId,
  detailId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/payment`
  try {
    const response = await axiosInstance.post(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const modifyF5S7ActivityDetailPayment = async (
  initiative,
  activityId,
  detailId,
  paymentId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/payment/${paymentId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const deleteF5S7ActivityDetailPayment = async (
  initiative,
  activityId,
  detailId,
  paymentId,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/payment/${paymentId}`
  try {
    const response = await axiosInstance.delete(url)
    return response
  } catch (error) {
    throw error
  }
}

export const addF5S7provider = async (data) => {
  const url = `/api/F5/S7/provider/`
  try {
    const response = await axiosInstance.post(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const modifyF5S7provider = async (providerId, data) => {
  const url = `/api/F5/S7/provider/${providerId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const modifyF5S7ActivityDetailFinalize = async (
  initiative,
  activityId,
  detailId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/finalize`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const getF5S7ActivityDetailFinalize = async (
  initiative,
  activityId,
  detailId,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/finalize`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getEntities = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F5/entities`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getActivityDetailF5S7 = async (
  initiative,
  activityId,
  detailId,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getDetailProvidersF5S7Budget = async (
  initiative,
  activityId,
  detailId,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/provider`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getAllProvidersF5S7 = async () => {
  const url = `/api/F5/S7/provider`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getSelectedProviderF5S7Budget = async (
  initiative,
  activityId,
  detailId,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/selected-provider`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getDocumentsF5S7Budget = async (
  initiative,
  activityId,
  detailId,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/document`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getPaymentsF5S7Budget = async (
  initiative,
  activityId,
  detailId,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/payment`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const cancelF5S7ActivityDetail = async (
  initiative,
  activityId,
  detailId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/${detailId}/cancel`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const addF5S7ActivityDetail = async (initiative, activityId, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/7/activity/${activityId}/detail/`
  try {
    const response = await axiosInstance.post(url, data)
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                          F5S10                                       */
//////////////////////////////////////////////////////////////////////////////////////////

export const addBeneficiary = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/10/beneficiary/`
  try {
    const response = await axiosInstance.post(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const deleteBeneficiary = async (initiative, beneficiaryId) => {
  const url = `/api/initiative/${initiative}/form/F5/section/10/beneficiary/${beneficiaryId}`
  try {
    const response = await axiosInstance.delete(url)
    return response
  } catch (error) {
    throw error
  }
}

export const editBeneficiary = async (initiative, data, beneficiaryId) => {
  const url = `/api/initiative/${initiative}/form/F5/section/10/beneficiary/${beneficiaryId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const addBeneficiaryCountry = async (
  initiative,
  data,
  beneficiaryId,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/10/beneficiary/${beneficiaryId}/country`
  try {
    const response = await axiosInstance.put(url, data)
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                          F5S11                                       */
//////////////////////////////////////////////////////////////////////////////////////////

export const saveF5S11Goal = async (initiative, goalId, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/11/goal/${goalId}`
  try {
    const response = await axiosInstance.put(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                          F5S14                                       */
//////////////////////////////////////////////////////////////////////////////////////////

export const getF5S14PublicationById = async (initiative, publicationId) => {
  const url = `/api/initiative/${initiative}/form/F5/section/14/publication/${publicationId}`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const modifyF5S14PublicationById = async (
  initiative,
  publicationId,
  data,
) => {
  const url = `/api/initiative/${initiative}/form/F5/section/14/publication/${publicationId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const addF5S14Publication = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/14/publication/`
  try {
    const response = await axiosInstance.post(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const addF5S14CommunicationTool = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/14/communication-tool/`
  try {
    const response = await axiosInstance.post(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const addF5S14Material = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F5/section/14/material/`
  try {
    const response = await axiosInstance.post(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const editF5S14Material = async (initiative, data, id) => {
  const url = `/api/initiative/${initiative}/form/F5/section/14/material/${id}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const deleteF5S14Material = async (initiative, id) => {
  const url = `/api/initiative/${initiative}/form/F5/section/14/material/${id}`
  try {
    const response = await axiosInstance.delete(url)
    return response.data
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                          F5S17                                       */
//////////////////////////////////////////////////////////////////////////////////////////

export const addComment = async (initiative, form, section, data) => {
  const url = `/api/initiative/${initiative}/form/F${form}/section/${section}`
  try {
    const response = await axiosInstance.post(url, data)
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                          F5S18                                       */
//////////////////////////////////////////////////////////////////////////////////////////

export const getAssessment = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F6/assessment`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const saveF6Assessment = async (initiative, values) => {
  const url = `/api/initiative/${initiative}/form/F6/assessment`
  try {
    const response = await axiosInstance.patch(url, values)
    return response
  } catch (error) {
    throw error
  }
}

export const saveF7AssessmentINTPA = async (initiative, values) => {
  const url = `/api/initiative/${initiative}/form/F7/assessment-intpa`
  try {
    const response = await axiosInstance.patch(url, values)
    return response
  } catch (error) {
    throw error
  }
}

export const saveF7AssessmentEUD = async (initiative, values) => {
  const url = `/api/initiative/${initiative}/form/F7/assessment-eud`
  try {
    const response = await axiosInstance.patch(url, values)
    return response
  } catch (error) {
    throw error
  }
}

export const sendF6ToMainAssessor = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F6/`
  try {
    const response = await axiosInstance.post(url)
    return response
  } catch (error) {
    throw error
  }
}

export const sendF7 = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F7/`
  try {
    const response = await axiosInstance.post(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getCancelJustification = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F5/cancel-justification`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const sendF5BackToApplicant = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F5/applicant`
  try {
    const response = await axiosInstance.patch(url)
    return response
  } catch (error) {
    throw error
  }
}

export const finalizeF5 = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F5/finalize`
  try {
    const response = await axiosInstance.patch(url)
    return response
  } catch (error) {
    throw error
  }
}

export const cancelInitiativeIntpa = async (initiative, data) => {
  const url = `/api/initiative/${initiative}/form/F5/cancel`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

export const rollbackCancelInitiativeIntpa = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F5/rollbackCancel`
  try {
    const response = await axiosInstance.patch(url)
    return response
  } catch (error) {
    throw error
  }
}

export const addEudToForm5 = async (email, initiativeId) => {
  const url = `/api/eud/${email}/initiative/${initiativeId}/form/F5/`
  try {
    const response = await axiosInstance.post(url)
    return response
  } catch (error) {
    throw error
  }
}

export const addEudToForm9 = async (email, initiativeId) => {
  const url = `/api/eud/${email}/initiative/${initiativeId}/form/F5/`
  try {
    const response = await axiosInstance.post(url)
    return response
  } catch (error) {
    throw error
  }
}

export const sendF5 = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F5/`
  try {
    const response = await axiosInstance.patch(url)
    return response
  } catch (error) {
    throw error
  }
}

export const createNewF8 = async (initiative) => {
  const url = `/api/initiative/${initiative}/form/F5/F8/`
  try {
    const response = await axiosInstance.post(url)
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                      DASHBOARD                                       */
//////////////////////////////////////////////////////////////////////////////////////////

export const getPeriodElapsed = async (filter) => {
  const url = `/api/dashboard/period-elapsed`
  try {
    const response = await axiosInstance.get(url, { params: { year: filter } })
    return response
  } catch (error) {
    throw error
  }
}

export const getIncidentals = async (filter) => {
  const url = `/api/dashboard/incidentals`
  try {
    const response = await axiosInstance.get(url, { params: { year: filter } })
    return response
  } catch (error) {
    throw error
  }
}

export const getWorkingDays = async (filter) => {
  const url = `/api/dashboard/working-days`
  try {
    const response = await axiosInstance.get(url, { params: { year: filter } })
    return response
  } catch (error) {
    throw error
  }
}

export const getInitiativePercentages = async (filter) => {
  const url = `/api/dashboard/initiative-percentages`
  try {
    const response = await axiosInstance.get(url, { params: { year: filter } })
    return response
  } catch (error) {
    throw error
  }
}

export const getPreFormulationDashboardData = async (qp) => {
  const url = `/api/dashboard/preFormulationStage`
  try {
    const response = await axiosInstance.get(url, { params: qp })
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                      F6                                              */
//////////////////////////////////////////////////////////////////////////////////////////

export const getF6SectionComments = async (initiative, section) => {
  const url = `/api/initiative/${initiative}/form/F6/section/${section}`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getBudgetByTypes = async (qp) => {
  const url = `/api/dashboard/budget-by-type`
  try {
    const response = await axiosInstance.get(url, { params: qp })
    return response
  } catch (error) {
    throw error
  }
}

export const saveSectionComments = async (initiative, section, data, form) => {
  const url = `/api/initiative/${initiative}/form/${form}/section/${section}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                      F8 - F9                                         */
//////////////////////////////////////////////////////////////////////////////////////////

export const getAllF8F9byType = async (formType) => {
  const url = `/api/initiative/form/${formType}`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getF8F9ByIdAndType = async (formType, id) => {
  const url = `/api/initiative/form/${formType}/${id}`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getF8ById = async (formId) => {
  const url = `/api/initiative/form/F8/${formId}`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const modifyF8 = async (formId, data) => {
  const url = `/api/initiative/form/F8/${formId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const sendF8 = async (formId) => {
  const url = `/api/initiative/form/F8/${formId}/send`
  try {
    const response = await axiosInstance.patch(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const sendF8b = async (formId) => {
  const url = `/api/initiative/form/F8b/${formId}/send`
  try {
    const response = await axiosInstance.post(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const sendF9a = async (formId) => {
  const url = `/api/initiative/form/F9a/${formId}/send`
  try {
    const response = await axiosInstance.patch(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const sendF9c = async (formId) => {
  const url = `/api/initiative/form/F9c/${formId}/send`
  try {
    const response = await axiosInstance.patch(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getF8bById = async (formId) => {
  const url = `/api/initiative/form/F8b/${formId}`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const modifyF8b = async (formId, data) => {
  const url = `/api/initiative/form/F8b/${formId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getF9aById = async (formId) => {
  const url = `/api/initiative/form/F9a/${formId}`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const modifyF9a = async (formId, data) => {
  const url = `/api/initiative/form/F9a/${formId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getF9cById = async (formId) => {
  const url = `/api/initiative/form/F9c/${formId}`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const modifyF9c = async (formId, data) => {
  const url = `/api/initiative/form/F9c/${formId}`
  try {
    const response = await axiosInstance.patch(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const cancelF8b = async (formId) => {
  const url = `/api/initiative/form/F8b/${formId}/cancel`
  try {
    const response = await axiosInstance.patch(url)
    return response.data
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                      CONTACTS                                       */
//////////////////////////////////////////////////////////////////////////////////////////

export const getContacts = async (page, pageSize, qp) => {
  const url = `/api/dashboard/contacts?page=${page}&pageSize=${pageSize}`
  try {
    const response = await axiosInstance.get(url, { params: qp })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getContacts2 = async (qp) => {
  const url = `/api/dashboard/contacts`
  try {
    const response = await axiosInstance.get(url, { params: qp })
    return response.data
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                      DOCUMENTS                                       */
//////////////////////////////////////////////////////////////////////////////////////////

export const getDocuments = async (params) => {
  const url = `/api/dashboard/documents`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getDocumentsApplicant = async (params) => {
  const url = `/api/documents`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                    PURCHASING MANAGEMENT                             */
//////////////////////////////////////////////////////////////////////////////////////////

export const getF5S7BuyBudget = async (params) => {
  const url = `/api/dashboard/buy-budget`
  try {
    let response = await axiosInstance.get(url, { params: params })
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                    INVOICE    MANAGEMENT                             */
//////////////////////////////////////////////////////////////////////////////////////////

export const getInvoiceManagment = async (params) => {
  const url = `/api/dashboard/payments`
  try {
    let response = await axiosInstance.get(url, { params: params })
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                  ACTIVITY REPORT                                     */
//////////////////////////////////////////////////////////////////////////////////////////

export const getActivities = async (params) => {
  const url = `/api/dashboard/activities`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                               F5 - S7 - AT - NEW ACTIONS                             */
//////////////////////////////////////////////////////////////////////////////////////////

export const changeParticipantState = async (
  initiativeId,
  activityId,
  payload,
) => {
  const url = `/api/initiative/${initiativeId}/form/F5/section/7/activity-at/${activityId}/participant`
  try {
    const response = await axiosInstance.patch(url, payload)
    return response.data
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                               BUDGET RESTRICTION                                     */
//////////////////////////////////////////////////////////////////////////////////////////

export const getBudgetRestriction = async () => {
  const url = `/api/constants/budget-restriction`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                    MEETINGS                                          */
//////////////////////////////////////////////////////////////////////////////////////////

export const getMeeting = async (initiativeId, meetingId) => {
  const url = `/api/initiative/${initiativeId}/form/F5/section/7/meeting/${meetingId}`
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const editMeeting = async (initiativeId, meeting) => {
  const url = `/api/initiative/${initiativeId}/form/F5/section/7/meeting`
  try {
    const response = await axiosInstance.put(url, meeting)
    return response
  } catch (error) {
    throw error
  }
}

export const deleteMeeting = async (initiativeId, meetingId) => {
  const url = `/api/initiative/${initiativeId}/form/F5/section/7/meeting/${meetingId}`
  try {
    const response = await axiosInstance.delete(url)
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                    DOCUMENT                                          */
//////////////////////////////////////////////////////////////////////////////////////////

export const saveDocument = async (data) => {
  const url = '/api/documents/'
  try {
    const response = await axiosInstance.post(url, data)
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////

export const modifyDates = async (initiativeId, data) => {
  const url = `/api/initiative/${initiativeId}/form/F5/modify-dates`
  try {
    const response = await axiosInstance.patch(url, data)
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                    REVIEW F2/F1                                      */
//////////////////////////////////////////////////////////////////////////////////////////

export const reviewF2Decision = async (initiativeId, data) => {
  const url = `/api/initiative/${initiativeId}/form/F2/decision`
  try {
    const response = await axiosInstance.put(url, data)
    return response
  } catch (error) {
    throw error
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
/*                                   STATISTICS                                         */
//////////////////////////////////////////////////////////////////////////////////////////

export const getCountries = async () => {
  const url = '/api/public/countries?applicantCountries=true'
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getODS = async () => {
  const url = '/api/constants/ods'
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getBenTypes = async () => {
  const url = '/api/constants/ben-types'
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getEntityTypes = async () => {
  const url = '/api/constants/entity-types'
  try {
    const response = await axiosInstance.get(url)
    return response
  } catch (error) {
    throw error
  }
}

export const getFormulationSummary = async (params) => {
  const url = '/api/reporting/formulation-summary'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getPreFormulationSummary = async (params) => {
  const url = '/api/reporting/pre-formulation-summary'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getInitiativesSummary = async (params) => {
  const url = '/api/reporting/ods-summary-new'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getInitiativesDetail = async (params) => {
  const url = '/api/reporting/initiative-stats'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getEntitiesByCountrySummary = async (params) => {
  const url = '/api/reporting/entity-summary-by-country'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getEntitiesByRoleSummary = async (params) => {
  const url = '/api/reporting/entity-summary-by-role'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getEntitiesByFunctionSummary = async (params) => {
  const url = '/api/reporting/entity-summary-by-function'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getEntitiesByTypeSummary = async (params) => {
  const url = '/api/reporting/entity-summary-by-entity-type'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getActivitiesDetail = async (params) => {
  const url = '/api/reporting/activity-stats'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getEntitiesDetail = async (params) => {
  const url = `/api/reporting/entity-stats`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getActivitiesByCountrySummary = async (params) => {
  const url = `/api/reporting/activity-summary-by-country`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getActivitiesByFormatSummary = async (params) => {
  const url = `/api/reporting/activity-summary-by-format`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getActivitiesByTypeSummary = async (params) => {
  const url = `/api/reporting/activity-summary-by-activity-type`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getProductsByTypeSummary = async (params) => {
  const url = `/api/reporting/product-summary-by-type`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getBeneficiaryByTypeSummary = async (params) => {
  const url = `/api/reporting/beneficiary-summary-by-type`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getBeneficiaryByCountrySummary = async () => {
  const url = 'api/reporting/beneficiary-summary-by-country'
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getExecutionSummary = async (params) => {
  const url = `/api/reporting/statistical-execution-summary`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getF6Results = async (params) => {
  const url = '/api/reporting/f6-report'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getF6ResultsMarkers = async (params) => {
  const url = '/api/reporting/f6-report2'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getPartnerships = async (params) => {
  const url = '/api/reporting/alliance-stats'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getBeneficiariesDetail = async (params) => {
  const url = '/api/reporting/beneficiary-stats'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getExpostSummary = async () => {
  const url = '/api/reporting/expost-summary'
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}


export const getF8bExpostSummary = async (params) => {
  const url = '/api/reporting/f8b-expost-summary'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getODSList = async (params) => {
  const url = '/api/reporting/ods-list'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getCountryReport = async (params) => {
  const url = `/api/reporting/country-report`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getSummaryTarget = async (params) => {
  const url = '/api/reporting/ods-target-summary'
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

