import { useContext } from 'react';
import { useAccordionToggle, } from 'react-bootstrap/AccordionToggle';
import AccordionContext from "react-bootstrap/AccordionContext";
import { AccordionItemTitle, ArrowImage } from './CustomAccordion.styled'
import BlueArrow from 'assets/flecha-celest-big-off.png';
import GreenArrow from 'assets/flecha-verde-big-on.png'

export const AccordionTitle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <AccordionItemTitle onClick={decoratedOnClick}>
      <ArrowImage src={isCurrentEventKey ? GreenArrow : BlueArrow} alt='Arrow'/>
      { children }
    </AccordionItemTitle>
  );
}
