import styled from 'styled-components';

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
  alig-items: center
`;

export const PageContent = styled.section`
  margin: 3rem 0px;
`