import React, { useState, useEffect } from 'react'
import { getImplementationInitiatives } from 'services/initiative'
import { useTranslation } from 'react-i18next'
import { useRootStore } from 'stores'
import {
  f5statusNamesConst,
  roleNamesConst,
  f4statusNamesConst,
} from 'constants/appConstants'
import { renderYears } from 'helpers/dateHelpers'

import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageTitle from 'components/PageTitle'
import { PrimaryButton } from 'components/Buttons'
import { PageHeader, PageContent } from './Management.styled'
import TableRow from './TableRow'

const Management = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [initiatives, setInitiatives] = useState([])
  const [filterValue, setFilterValue] = useState('ALL')
  const { authStore } = useRootStore()
  const [filteredInitiatives, setFilteredInitiatives] = useState([])
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [changeSelectedYear, setChangeSelectedYear] = useState(false)

  const role = authStore.roles[0]

  useEffect(() => {
    getData(true)
  }, [])

  useEffect(() => {
    if (changeSelectedYear) {
      handleFilter1(initiatives, new Date().getFullYear() - 1)
    }
  }, [changeSelectedYear])

  const handleFilter1 = (initiatives, year) => {
    let aux = initiatives
    setSelectedYear(year)
    aux = aux.filter((e) =>
      e.code?.includes(`-${year.toString().substring(2, 4)}`),
    )
    setFilteredInitiatives(aux)
  }

  const getData = async (flag) => {
    setLoading(true)
    try {
      const initiatives = await getImplementationInitiatives()
      setInitiatives(initiatives)
      handleFilter2(
        initiatives,
        role === roleNamesConst.ATKE || role === roleNamesConst.ATNKE || role === roleNamesConst.CONSORTIUM,
        flag,
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    setFilterValue(e.target.value)
  }

  const handleFilter2 = (initiatives, isATKEorCONSORTIUM, flag) => {
    let aux = initiatives
    try {
      if (filterValue !== 'ALL') {
        if (isATKEorCONSORTIUM)
          aux = aux.filter((el) => el.formStateName === filterValue)
        else aux = aux.filter((el) => el.assessmentStatus === filterValue)
      }
      if (selectedYear)
        aux = aux.filter((e) =>
          e.code?.includes(`-${selectedYear.toString().substring(2, 4)}`),
        )
      setFilteredInitiatives(aux)
      if (flag) {
        setChangeSelectedYear(aux.length === 0)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleFilterYearChange = (e) => {
    setSelectedYear(e.target.value)
  }

  return (
    <>
      <PageHeader>
        <PageTitle
          title={
            role === roleNamesConst.ATKE ||
            role === roleNamesConst.CONSORTIUM ||
            role === roleNamesConst.ATNKE
              ? t('menu_admin_10')
              : t('menu_admin_11')
          }
        />
        <Row style={{ alignItems: 'center' }}>
          {t('Dashboard_Year')}
          <Col xs={3}>
            <Form.Control
              as="select"
              onChange={handleFilterYearChange}
              value={selectedYear}
            >
              {renderYears()}
            </Form.Control>
          </Col>
          {
            role === roleNamesConst.ATKE ||
            role === roleNamesConst.ATNKE ||
            role === roleNamesConst.CONSORTIUM ? (
              <>
                {t('F5_status')}
                <Col>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    value={filterValue}
                  >
                    <option value="ALL"> {t('Button_filter_all')} </option>
                    <option value={f5statusNamesConst.DRAFT}>
                      {t('DRAFT')}
                    </option>
                    <option value={f5statusNamesConst.SENT}>
                      {t('SENT')}
                    </option>
                    <option value={f5statusNamesConst.SENT_FINAL}>
                      {t('SENT_FINAL')}
                    </option>
                    <option value={f5statusNamesConst.EX_POST}>
                      {t('EX_POST')}
                    </option>
                    <option value={f5statusNamesConst.ASSESSMENT}>
                      {t('ASSESSMENT')}
                    </option>
                    <option value={f5statusNamesConst.FINALIZED}>
                      {t('FINALIZED')}
                    </option>
                    <option value={f5statusNamesConst.CANCELLED}>
                      {t('CANCELED')}
                    </option>
                  </Form.Control>
                </Col>
              </>
            ) : (
              <>
                {t('Assessment_status')}
                <Col>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    value={filterValue}
                  >
                    <option value="ALL"> {t('Button_filter_all')} </option>
                    <option value={f4statusNamesConst.PENDING}>
                      Pending
                    </option>
                    <option value={f4statusNamesConst.ONGOING}>
                      Ongoing
                    </option>
                    <option value={f4statusNamesConst.DONE}> Done </option>
                    {role === roleNamesConst.EUD && (
                      <option value={f4statusNamesConst.EXPIRED}>
                        Expired
                      </option>
                    )}
                  </Form.Control>
                </Col>
              </>
            )
          }
          <PrimaryButton
            onClick={() => {
              handleFilter2(
                initiatives,
                role === roleNamesConst.ATKE ||
                role === roleNamesConst.ATNKE ||
                role === roleNamesConst.CONSORTIUM,
              )
            }}
          >
            {t('Button_filter')}
          </PrimaryButton>
        </Row>
      </PageHeader>
      <PageContent>
        {loading ? (
          <p>{t('Loading')}</p>
        ) : (
          <Table>
            <thead>
              <tr>
                <th>{t('Grid_title_code')}</th>
                <th>{t('Grid_title')}</th>
                <th>
                  {role === roleNamesConst.ATKE ||
                  role === roleNamesConst.CONSORTIUM ||
                  role === roleNamesConst.ATNKE
                    ? t('Grid_status_F5')
                    : t('Grid_status_F6')}
                </th>
                <th>
                  {role === roleNamesConst.ATKE ||
                  role === roleNamesConst.CONSORTIUM ||
                  role === roleNamesConst.ATNKE
                    ? t('Grid_deadline_F5')
                    : t('Grid_deadline_F6')}
                </th>
                <th>
                  {role === roleNamesConst.ATKE ||
                  role === roleNamesConst.CONSORTIUM ||
                  role === roleNamesConst.ATNKE
                    ? t('Grid_reporting_F6')
                    : t('Grid_My_assessment')}
                </th>
                <th>
                  {role === roleNamesConst.ATKE ||
                  role === roleNamesConst.CONSORTIUM ||
                  role === roleNamesConst.ATNKE
                    ? t('Grid_deadline_F6')
                    : t('Grid_deadline')}
                </th>
                <th>{t('Grid_messages')}</th>
              </tr>
            </thead>
            <tbody>
              {!(filteredInitiatives && filteredInitiatives.length) ? (
                <tr>
                  <td colSpan={4} style={{ textAlign: 'center' }}>
                    {t('No_data_available')}
                  </td>
                </tr>
              ) : (
                filteredInitiatives.map((item) => {
                  if (
                    item.formStateName &&
                    item.formStateName !== f5statusNamesConst.DRAFT
                  ) {
                    item.formDeadline = null
                  }
                  if (
                    item.assessmentStatus &&
                    item.assessmentStatus !== f4statusNamesConst.ONGOING &&
                    item.assessmentStatus !== f4statusNamesConst.PENDING
                  ) {
                    item.assessmentDeadline = null
                  }
                  return <TableRow key={item.id} item={item} />
                })
              )}
            </tbody>
          </Table>
        )}
      </PageContent>
    </>
  )
}

export default Management
