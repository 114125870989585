import React, { useState, useEffect } from 'react'
import _chunk from 'lodash/chunk'
import { getActivitiesByTypeSummary } from 'services/initiative'
import { useTranslation } from 'react-i18next'
import Header4 from '../components/Header4'
import Table4 from '../components/Table4'
import Row from 'react-bootstrap/Row'
import { StyledDiv, StyledRow, StyledRow2 } from '../../CommonStyled/Tabs.styled'
import { T8RenderRows, getActivitiesByTypeSummaryExportValues, getFundsActivitiesByTypeSummaryExportValues } from '../helpers/helpers'
import { ExportStatisticsByYearsToXLSX } from '../../../../helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'
import Table6 from 'pages/statistics/Entities/components/Table6'
import { getFundsActivitySummaryByActivityType } from 'services/fundsStatistics'

const ActivitiesByTypeSummary = () => {
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const [data2, setData2] = useState(null)
  const [waiting2, setWaiting2] = useState(false)

  const getData = async (param) => {
    setWaiting(true)
    try {
      const response = await getActivitiesByTypeSummary(param)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  const getData2 = async (param) => {
    setWaiting(true)
    try {
      const response = await getFundsActivitySummaryByActivityType(param)
      setData2(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting2(false)
    }
  }

  useEffect(() => {
    getData()
    getData2()
  }, [])

  return (
    <StyledDiv>
      <Row>
        <Header4
          changeLabel={true}
          waiting={waiting}
          getData={getData}
          getData2={getData2}
          renderExportButton={() => (
            <ExportToXLSXButton
              data={data}
              mapData={getActivitiesByTypeSummaryExportValues}
              data2={data2}
              mapData2={getFundsActivitiesByTypeSummaryExportValues}
              disabled={waiting}
              exportName={t('Statistics_activities_tab4')}
            >
              <ExportStatisticsByYearsToXLSX
                data={[]}
                label={t('Statistics_activities_tab4')}
              />
            </ExportToXLSXButton>
          )}
        />
      </Row>
      <StyledRow>
        <Table4 data={data} waiting={waiting} setData={setData} title={undefined} />
      </StyledRow>
      <StyledRow2 style={{marginLeft: '0px'}}>
        <Table6
          title={t('Statistics_activities_tab4')}
          data={data2}
          waiting={waiting2}
          renderRows={T8RenderRows}
          customWidth='62%'
          customWidthRow1='84%'
          customWidthRow2='16%'
          setData={setData2}
          orderField='activity'
          total={true}
        />
      </StyledRow2>
    </StyledDiv>
  )
}

export default ActivitiesByTypeSummary
