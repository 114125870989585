import styled, {css} from 'styled-components';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

export const StyledIntroParagraph = styled.p`
  line-height: 1.3rem;
`;

export const StyledGoal = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.gray[5]};
    margin-bottom: 30px;
  `}
`;

export const StyledFormLabel = styled(Form.Label)`
  padding-left: 65px;
`;

export const Separator = styled.hr`
  border-top: 3px solid #bbb;
`;

export const StyledSubtitle = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    margin-bottom: 10px;
    color: ${theme.brandColors.blue};
    letter-spacing: 1px;
    font-weight: 600;
    font-size: ${props => props.size || '18px'};
    line-height: 1.3;
  `}
`;


export const StyledContainer = styled.div`
  padding-left: 50px;
`;