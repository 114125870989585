export const initialValues = {
  section1Data: null,
  section2Data: null,
  section3Data: null,
  section4Data: null,
  section5Data: null,
  section6Data: null,
  section7Data: null,
  section8Data: null,
  ready: false,
};
