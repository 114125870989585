import React, {useState } from 'react'
import { FormFielErrorMessage, CenteredModalButtonContainer } from 'components/CommonStyled';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next';
import { ErrorButton, PrimaryButton } from 'components/Buttons'
import CustomModal from 'components/Modal';
import { StyledModalBody } from 'components/Modal/Modal.styled';
import { onSendContact } from 'services/community';

const ContactInitiativeModal = ({ isOpen, onClose, initiativeId, type }) => {
  const { t } = useTranslation();

  const [question, setQuestion] = useState("");
  const [sending, setSending] = useState(false);
  const [modal, setModal] = React.useState({
		isOpen: false,
		message: "",
		type: 'default'
	});
	const [formError, setFormError] = useState({
		isShow: false,
		msg: ""
	});

  const onHideModal = () => {
		setModal({...modal, isOpen: false});
  };

  const handleChange = (e) => {
    setFormError({
      isShow: false,
      msg:""
    });
    setQuestion(e.target.value);
  }

  const handleSubmit = async () => {
    if(question?.length < 1){
      setFormError({
        isShow: true,
        msg: t('Error field cannot be empty')
      })
    }
    else if(question.length > 1300){
      setFormError({
        isShow: true,
        msg: t('Error max length 1300')
      })
    }
    else{
      setSending(true);
      try{
        await onSendContact(initiativeId, type, question);
        setModal({
          ...modal, 
          message: t('Contact_form_confirmation'),
          type: "success",
          isOpen: true,
        });
		onClose();
      } catch(error){
        console.log(error);
      } finally {
        setSending(false);
			}
    }
  }

  return (
		<>
			{modal.isOpen ? (
				<CustomModal 
					show={modal.isOpen}
					onHide={onHideModal}
					message={modal.message}
					type={modal.type}
				/>
			) : null}
			<Modal show={isOpen} onHide={onClose} size="lg" animation={false}>
				<StyledModalBody>
					<div style={{
						display: 'flex',
						flexDirection: 'column'
					}}>
						<div>
							{t('community_contact_intro')}
						</div>

						<div style={{ marginTop: '32px'}}>
							{t('community_contact_question')}
						</div>

						<Form.Control
							as="textarea"
							style={{resize: 'none'}}
							rows={6} 
							onChange={handleChange}
							value={question}
							placeholder={t('Maximum_1300')}
						/>

						{formError.isShow && <FormFielErrorMessage> {formError.msg} </FormFielErrorMessage>}

						<CenteredModalButtonContainer>
							<ErrorButton
								style={{marginRight: '20px'}}
								disabled={sending} 
								onClick={onClose}
							> 
								{t('Button_cancel')}
							</ErrorButton>
							<PrimaryButton 
								disabled={sending} 
								onClick={handleSubmit}
							> 
								{t('Button_send')} 
							</PrimaryButton>
						</CenteredModalButtonContainer>
					</div>
				</StyledModalBody>
			</Modal>
		</>
  )
}

export default ContactInitiativeModal;