import styled from 'styled-components'
import Row from 'react-bootstrap/Row'

export const StyledDiv = styled.div`
  padding: 30px 0px 30px 0px;
`
export const StyledRow = styled(Row)`
  padding: 50px 15px 0px 15px;
  display: flex;
  justify-content: center;
`
export const StyledRow2 = styled(Row)`
  padding: 15px 15px 0px 15px;
  display: flex;
  justify-content: flex-start;
  text-align: justify;
`
export const StyledRow3 = styled(Row)`
  padding: 40px 15px 0px 15px;
  display: flex;
  justify-content: flex-start;
  text-align: justify;
`

export const StyledSubtitle3 = styled.div`
  font-weight: bold;
  margin-top: 30px;
`

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
`
export const PageContent = styled.section`
  margin: 3rem 0px;
`
export const TableSeparator = styled.div`
  margin-top: 50px;
`
export const StyledSubtitle = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 650;
  font-size: 17px;
  align-items: center;
`

export const StyledSubtitle2 = styled.div`
  font-weight: 650;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
`
