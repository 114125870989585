import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useRootStore } from 'stores'
import { formInitialValues } from './Section12.initialValues'
import { useTranslation } from 'react-i18next'
import htmlParser from 'html-react-parser'

import TextValue from 'components/TextValue'
import Form from 'react-bootstrap/Form'
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer'
import Comments from 'components/Forms/Form4/Comments'
import {
  StyledFormLabel,
  StyledSeparator,
  StyledSectionWrapper,
  StyledSubtitle,
  Text,
} from './Section12.styled'
import { isWindows2021 } from 'helpers/isWindow2021Helper'

const Section12 = () => {
  const { t } = useTranslation()
  const [saved, setSaved] = useState(false)
  const [nonEditable, setNonEditable] = useState(false)
  const { formStore, initiativeStore } = useRootStore()
  const [formValues, setFormValues] = useState(formInitialValues)
  const [window2021, setWindow2021] = useState(true)

  const handleSaved = (value) => {
    setSaved(value)
  }

  const handleButton = (value) => {
    setNonEditable(value)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (initiativeStore.code) {
      setWindow2021(isWindows2021(initiativeStore.code))
    }
  }, [initiativeStore.code])

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {
        ...formInitialValues,
        valueTC: formStore.formSectionData[1],
        valueEU: formStore.formSectionData[2],
        valueTP: formStore.formSectionData[3],
        ...formStore.formSectionData[0],
      }
      setFormValues(formSectionData)
      formStore.setCurrentSection(12)
    }
  }, [formStore.formSectionData])

  return (
    <FormLayout>
      <FormContainer
        number={12}
        title={t('F1_s2_title')}
        formId="form4section12"
        showSavedMessage={saved}
        showButtonClarif={!nonEditable}
        showFooter={false}
      >
        <StyledSectionWrapper>
          <Text>{htmlParser(t('F3_S12_Intro'))}</Text>

          <StyledSubtitle size="20px">
            {htmlParser(t('F3_S12_Subtitle_TC'))}
          </StyledSubtitle>

          <Form.Group>
            <StyledFormLabel>
              {htmlParser(t('F3_S12_Justfication_TC'))}:
            </StyledFormLabel>
            <TextValue value={formValues.valueTC} />
          </Form.Group>

          <Form.Group>
            <StyledFormLabel>
              {htmlParser(t('F3_S12_Justfication_partnership'))}:
            </StyledFormLabel>
            <TextValue value={formValues.valueEU} />
          </Form.Group>

          {window2021 ? (
            <>
              <Text> {htmlParser(t('F3_S12_Subtitle_Added_TC'))} </Text>

              <Form.Group>
                <StyledFormLabel>
                  {htmlParser(t('F3_S12_Ownership'))}:
                </StyledFormLabel>
                <TextValue value={formValues.valueTC1} />
              </Form.Group>

              <Form.Group>
                <StyledFormLabel>
                  {htmlParser(t('F3_S12_Complementarity'))}:
                </StyledFormLabel>
                <TextValue value={formValues.valueTC2} />
              </Form.Group>

              <Form.Group>
                <StyledFormLabel>
                  {htmlParser(t('F3_S12_Sharing_knowledge'))}:
                </StyledFormLabel>
                <TextValue value={formValues.valueTC3} />
              </Form.Group>

              <Form.Group>
                <StyledFormLabel>
                  {htmlParser(t('F3_S12_Joint_creation'))}:
                </StyledFormLabel>
                <TextValue value={formValues.valueTC4} />
              </Form.Group>

              <Form.Group>
                <StyledFormLabel>
                  {htmlParser(t('F3_S12_Increasing_volume'))}:
                </StyledFormLabel>
                <TextValue value={formValues.valueTC5} />
              </Form.Group>

              <Form.Group>
                <StyledFormLabel>
                  {htmlParser(t('F3_S12_Global_and_regional'))}:
                </StyledFormLabel>
                <TextValue value={formValues.valueTC6} />
              </Form.Group>
            </>
          ) : null}

          <StyledSubtitle size="20px">
            {htmlParser(t('F3_S12_Subtitle_Added_EU'))}
          </StyledSubtitle>

          <Form.Group>
            <StyledFormLabel>
              {htmlParser(t('F3_S12_EU_Added_value'))}
            </StyledFormLabel>
            <TextValue value={formValues.valueTP} />
          </Form.Group>

          <Form.Group>
            <StyledFormLabel>
              {htmlParser(t('F3_S12_Added_EU_actors'))}:
            </StyledFormLabel>
            <TextValue value={formValues.valueEUActors} />
          </Form.Group>

          {window2021 ? (
            <Form.Group>
              <StyledFormLabel>
                {htmlParser(t('F3_S12_From_comparison_modalities'))}:
              </StyledFormLabel>
              <TextValue value={formValues.valueEUModalities} />
            </Form.Group>
          ) : null}
        </StyledSectionWrapper>

        <StyledSeparator />

        <Comments onSaved={handleSaved} onButton={handleButton} />
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section12)
