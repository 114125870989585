import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  StyledRadioLabel,
  StyledRadioSection,
  StyledRadioSectionTitle,
  StyledSeparator,
} from "../Section3.styled";
import { RequiredMark } from "components/CommonStyled";
import { getIn } from "formik";

import { useTranslation } from "react-i18next";

import HelpIcon from "components/HelpIcon";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  const getFieldTouched = getIn(formikInstance.touched, field);
  return {
    touched: getFieldTouched,
    error: (
      <FormErrorMessage
        touched={getFieldTouched}
        errors={getFieldError}
      />
    )
  };
};

const CoapplicantForm = ({
  formikInstance,
  coapplicantIndex,
  countries,
  entityTypes,
  nonEditable,
}) => {
  const { t } = useTranslation();
  const {
    getFieldProps,
    touched,
    errors,
    values,
    handleChange,
  } = formikInstance;
  const fieldPath = (field) => `coapplicants.${coapplicantIndex}.${field}`;

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_Entity_name")}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath("entityName")}
            {...getFieldProps(fieldPath("entityName"))}
            className={
              touched[fieldPath("entityName")] &&
              errors[fieldPath("entityName")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath("entityName")).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_Official_acronym")}:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath("acronym")}
            {...getFieldProps(fieldPath("acronym"))}
            className={
              touched[fieldPath("acronym")] && errors[fieldPath("acronym")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath("acronym")).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_Type_of_entity")}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={4}>
          <Form.Control
            as="select"
            name={fieldPath("entityTypeId")}
            {...getFieldProps(fieldPath("entityTypeId"))}
            className={
              touched[fieldPath("entityTypeId")] &&
              errors[fieldPath("entityTypeId")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          >
            <option value="">{t("Placeholder_select")}</option>
            {entityTypes.map((entity) => (
              <option key={entity.id} value={entity.id}>
                {t(`entity_type_${entity.id}`)}
              </option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath("entityTypeId")).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row} style={{ alignItems: "center" }}>
        <Form.Label column lg={4}>
          {t("F1_S2_Role")}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8} style={{ marginTop: "10px" }}>
          <StyledRadioLabel>
            <input
              type="radio"
              name={fieldPath("role")}
              value="0"
              checked={values.coapplicants[coapplicantIndex].role === "0"}
              onChange={handleChange}
              style={{ marginRight: "10px" }}
              disabled={nonEditable}
            />
            {t("F1_S2_Recipient")}
          </StyledRadioLabel>

          <StyledRadioLabel>
            <input
              type="radio"
              name={fieldPath("role")}
              value="1"
              checked={values.coapplicants[coapplicantIndex].role === "1"}
              onChange={handleChange}
              style={{ marginRight: "10px" }}
              disabled={nonEditable}
            />
            {t("F1_S2_Provider1")}
          </StyledRadioLabel>

          <StyledRadioLabel>
            <input
              type="radio"
              name={fieldPath("role")}
              value="2"
              checked={values.coapplicants[coapplicantIndex].role === "2"}
              onChange={handleChange}
              style={{ marginRight: "10px" }}
              disabled={nonEditable}
            />
            {t("F1_S2_Provider2")}
          </StyledRadioLabel>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label className="align-self-start" column lg={4}>
          {t("F1_S2_Contribution")}
          <RequiredMark>*</RequiredMark>:{" "}
        </Form.Label>
        <Col lg={8}>
          <StyledRadioSection>
            <StyledRadioSectionTitle>
              {t("F1_S2_Role_help")}
            </StyledRadioSectionTitle>
            <Form.Check
              name={fieldPath("optionA")}
              type="checkbox"
              checked={values.coapplicants[coapplicantIndex].optionA}
              disabled={nonEditable}
              {...getFieldProps(fieldPath("optionA"))}
              label={t("F1_S2_Provider_detail_A")}
            />
            <Form.Check
              name={fieldPath("optionB")}
              type="checkbox"
              checked={values.coapplicants[coapplicantIndex].optionB}
              disabled={nonEditable}
              {...getFieldProps(fieldPath("optionB"))}
              label={t("F1_S2_Provider_detail_B")}
            />
            <Form.Check
              name={fieldPath("optionC")}
              type="checkbox"
              checked={values.coapplicants[coapplicantIndex].optionC}
              disabled={nonEditable}
              {...getFieldProps(fieldPath("optionC"))}
              label={t("F1_S2_Provider_detail_C")}
            />
            <Form.Check
              name={fieldPath("optionD")}
              type="checkbox"
              checked={values.coapplicants[coapplicantIndex].optionD}
              disabled={nonEditable}
              {...getFieldProps(fieldPath("optionD"))}
              label={t("F1_S2_Provider_detail_D")}
            />
            <Form.Control
              type="text"
              name={fieldPath("other")}
              {...getFieldProps(fieldPath("other"))}
              className={
                touched[fieldPath("other")] && errors[fieldPath("other")]
                  ? "error"
                  : null
              }
              disabled={
                !values.coapplicants[coapplicantIndex].optionD || nonEditable
              }
            />
            {fieldInfo(formikInstance, fieldPath("other")).error}
          </StyledRadioSection>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_Country")}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={4}>
          <Form.Control
            as="select"
            name={fieldPath("countryId")}
            {...getFieldProps(fieldPath("countryId"))}
            className={
              touched[fieldPath("countryId")] && errors[fieldPath("countryId")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          >
            <option value="">{t("Placeholder_select")}</option>
            {countries.map((country) => (
              <option key={country.id} value={country.id}>
                {t(country.name)}
              </option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath("countryId")).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_State_Province_Region")}:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath("stateName")}
            {...getFieldProps(fieldPath("stateName"))}
            className={
              touched[fieldPath("stateName")] && errors[fieldPath("stateName")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath("stateName")).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_City")}:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath("cityName")}
            {...getFieldProps(fieldPath("cityName"))}
            className={
              touched[fieldPath("cityName")] && errors[fieldPath("cityName")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath("cityName")).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_Website")}:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath("web")}
            {...getFieldProps(fieldPath("web"))}
            className={
              touched[fieldPath("web")] && errors[fieldPath("web")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath("web")).error}
        </Col>
      </Form.Group>

      <StyledSeparator />

      <Form.Group as={Row} style={{ alignItems: "center" }}>
        <Form.Label column lg={4}>
          {t("F1_S3_Responsisble_person")}
          <RequiredMark>*</RequiredMark>:
          <HelpIcon padding={'0 0 0 0.75rem'} info={t("F1_S3_Responsible_person_INFO")} />
        </Form.Label>
        <Col lg={4}>
          <Form.Control
            type="text"
            placeholder={t("F1_S2_First_name")}
            name={fieldPath("firstNameCoApplicant")}
            {...getFieldProps(fieldPath("firstNameCoApplicant"))}
            className={
              touched[fieldPath("firstNameCoApplicant")] &&
              errors[fieldPath("firstNameCoApplicant")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath("firstNameCoApplicant")).error}
        </Col>
        <Col lg={4}>
          <Form.Control
            type="text"
            placeholder={t("F1_S2_Last_Name")}
            name={fieldPath("lastNameCoApplicant")}
            {...getFieldProps(fieldPath("lastNameCoApplicant"))}
            className={
              touched[fieldPath("lastNameCoApplicant")] &&
              errors[fieldPath("lastNameCoApplicant")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath("lastNameCoApplicant")).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_Position")}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath("position")}
            {...getFieldProps(fieldPath("position"))}
            className={
              touched[fieldPath("position")] && errors[fieldPath("position")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath("position")).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_Email")}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath("email")}
            {...getFieldProps(fieldPath("email"))}
            className={
              touched[fieldPath("email")] && errors[fieldPath("email")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath("email")).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_Phone_number")}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            placeholder={t("Example") + " +12133734253"}
            name={fieldPath("phone")}
            {...getFieldProps(fieldPath("phone"))}
            className={
              touched[fieldPath("phone")] && errors[fieldPath("phone")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath("phone")).error}
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Form.Check
              type="checkbox"
              name={fieldPath("auth")}
              {...getFieldProps(fieldPath("auth"))}
              style={{ marginTop: "10px" }}
              label={t("F1_S3_Authorize_user")}
              disabled={nonEditable}
              checked={values.coapplicants[coapplicantIndex].auth}
            />
            <HelpIcon padding={'0.75rem 0 0 0.75rem'} info={t("F1_S3_Authorize_user_INFO")} />
          </div>
        </Col>
      </Form.Group>
    </>
  );
};

export default CoapplicantForm;
