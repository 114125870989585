import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledS7SubsectionTitle } from '../../CommonStyles';
import { SeparatorDotted } from 'components/CommonStyled';
import FakeAccordionItem from '../../../FakeAccordionItem';
import ProductForm from 'components/Forms/Form4/Section7/ProductsForm/ProductForm';

const Products = ({ activity }) => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledS7SubsectionTitle> {t('F3_S7_Subtitle_Products')} </StyledS7SubsectionTitle>
      {(activity && activity.productList && activity.productList.map((product, key) => (
        <React.Fragment key={key}>
          <FakeAccordionItem title={t('F3_S7_Accordion_product')+" "+(key+1)} noMargin>
            <ProductForm data={product} /> 
          </FakeAccordionItem>
          <SeparatorDotted />
        </React.Fragment>
      )))}
    </div>
  )
}

export default Products;