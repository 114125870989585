import React from 'react'
import { useTranslation } from 'react-i18next';
import htmlParser from "html-react-parser";

import ParticipantForm from './ParticipantForm';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import { PrimaryButton } from 'components/Buttons';
import "react-datepicker/dist/react-datepicker.css";
import { CenteredModalButtonContainer } from 'components/CommonStyled';
import { DisclaimerDiv } from './ParticipantsForm.styled';

const ParticipantsForm = ({ handleCloseOk, activity }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Accordion>
        {activity.participantList
          ? ( activity.participantList.map((participant, key) => (
              <AccordionItem key={key+1}>
                <AccordionItemHeader>
                  <AccordionTitle eventKey={key+1}>{t('F3_S7_Accordion_Participant')+" "+(key+1)}</AccordionTitle>
                </AccordionItemHeader>
                <Accordion.Collapse eventKey={key+1}>
                  <AccordionItemBody>
                    <ParticipantForm data={participant} /> 
                  </AccordionItemBody>
                </Accordion.Collapse>
              </AccordionItem>
            )))
          : null}
      </Accordion>

      <DisclaimerDiv>
        {htmlParser(t('F3_S7_Participant_disclaimer'))}
      </DisclaimerDiv>

      <CenteredModalButtonContainer>
        <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
      </CenteredModalButtonContainer>
    </div>
  )
}

export default ParticipantsForm;