import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import TextValue from 'components/TextValue';

const LessonForm = ({ data }) => {
  const {t} = useTranslation();

  const countryNamesFromArray = (countries) => {
    let str = '';
    countries.forEach(c => {
      str = str + `${t(c.name)} - `;
    });
    return str;
  };

  return (
    <div style={{marginLeft: '50px'}}>
      <Form.Group>
        <Form.Label lg={4}>{t('F5_S15_Lesson_title')}:</Form.Label>
        <TextValue value={data.lessonTitle} />
      </Form.Group>  

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S15_Lesson_type')}:</Form.Label>
        <TextValue value={data.lessonType ? t(`lesson_type_${data.lessonType.id}`) : ''} />
      </Form.Group>  

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S15_Lesson_moment')}:</Form.Label>
        <TextValue value={data.lessonMoment ? t(`lesson_moment_${data.lessonMoment.id}`) : ''} />
      </Form.Group>  

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S15_Lesson_description')}:</Form.Label>
        <TextValue value={data.lessonEvent} />
      </Form.Group>  
      
      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S15_Lesson_decision')}:</Form.Label>
        <TextValue value={data.lessonDecisions} />
      </Form.Group>  

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S15_Lesson_derived_from')}:</Form.Label>
        <TextValue value={data.lessonLearning} />
      </Form.Group>  

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S15_Lesson_Opportunities')}:</Form.Label>
        <TextValue value={data.lessonImprovement} />
      </Form.Group>  

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S15_Lesson_This_knowledge')}:</Form.Label>
        <TextValue value={data.lessonUseFor ? t(`lesson_use_for_${data.lessonUseFor.id}`) : ''} />
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S15_Lesson_Countries_reference')}:</Form.Label>
        <TextValue value={data.lessonCountryList ? countryNamesFromArray(data.lessonCountryList) : ''} />
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S15_Lesson_Actors_involved')}:</Form.Label>
        <TextValue value={data.lessonActors} />
      </Form.Group>  

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S15_Lesson_Keywords')}:</Form.Label>
        <TextValue value={data.lessonKeywords} />
      </Form.Group>  
    </div>
  )

}

export default LessonForm;
