import React, {  useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col } from 'react-bootstrap'
import { PrimaryButton } from 'components/Buttons'
import {
  Col1,
  StyledFormLabel,
  FullRow,
} from '../../CommonStyled/Components.styled'
import Select from 'react-select';
import html2pdf from 'html2pdf.js';

const CountryReportHeader = ({
  countryOptions,
  data,
  getData,
  waiting,
}) => {
  const { t } = useTranslation()
  // filters
  const [countryFilter, setCountryFilter] = useState([])
  // pdf export
  const [pdfExportDisabled, setPdfExportDisabled] = useState(false);

  const handleFilter = () => {
    const params = { countryIdList: countryFilter.toString() };
    getData(params)
  }

  const generatePDF = () => {
    try {
      window.scrollTo(0, 0);
      const pages = Array.from(document.querySelectorAll('div[aria-label^="pdf-page-"]'))
      const pdfOptions = {
        margin: 1,
        filename: 'country-report.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { 
          scale: 1.0,
          width: 1050,
        },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
      };
      let worker = html2pdf()
        .set(pdfOptions)
        .from(pages[0])
    
      if (pages.length > 1) {
        worker = worker.toPdf()
        pages.slice(1).forEach((element, index) => {
          worker = worker
            .get('pdf')
            .then(pdf => {
              pdf.addPage()
            })
            .from(element)
            .toContainer()
            .toCanvas()
            .toPdf()
        })
      }
      worker = worker.save().then(() => {
        setPdfExportDisabled(false);
      });
    } catch (e) {
      console.log(e)
    }
  };

  return (
    <>
      <FullRow>
        <Col1>
          <StyledFormLabel>{t('F1_S2_Country')}</StyledFormLabel>
          <Col xs={4}>
            <Select
              isMulti
              options={countryOptions}
              onChange={val => {
                const valuesArray = val.map(a => a.value);
                setCountryFilter(valuesArray)
              }}
              placeholder={t('Placeholder_select')}
              closeMenuOnSelect={false}
              defaultValue={[]}
              name="countryId"
            />
          </Col>

          <PrimaryButton
            onClick={handleFilter}
            disabled={waiting}
          >
            {t('Button_search')}
          </PrimaryButton>
        </Col1>
      </FullRow>
     <FullRow>
      <div style={{marginTop: '20pt'}}>
        <PrimaryButton
          onClick={generatePDF}
          disabled={waiting || pdfExportDisabled || !data}
        >
          {t('Export_to_PDF')}
        </PrimaryButton>
      </div>
     </FullRow>
    
    </>
  )
}

export default CountryReportHeader
