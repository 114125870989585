import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import TextValue from 'components/TextValue';
import { useTranslation } from 'react-i18next';

import {
  StyledFormLabel
} from '../Section8.styled';


const MediaForm = ({mediaTypes, data }) => {

  const {t} = useTranslation();
  return (
    <>
      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S8_Media_type')}:</StyledFormLabel>
        <TextValue value={data.mediaType? t(`media_type_${data.mediaType.id}`): ' - '} />
      </Form.Group>  

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S8_Link_account')}:</StyledFormLabel>
        <TextValue value={data.url} />
      </Form.Group>  
      
      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S8_Media_name')}:</StyledFormLabel>
        <TextValue value={data.comments} />
      </Form.Group>  
    </>
  )

}

export default MediaForm;
