export const initialValues = {
  objective: "",
  samePerson: false,
  firstname: "",
  lastname: "",
  email: "",
  entityName: "",
  phone: "",
  auth: false,
  items: [],
};
