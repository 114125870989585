import styled, { css } from 'styled-components';

export const SectionContainer = styled.div`
  ${({ theme }) => css`
    padding: 30px 40px;
    background-color: ${theme.brandColors.gray4};
    border: 2px solid #d1d8e2;
    border-radius: 20px 0 20px 0;
    width: 100%;
    margin-bottom: 30px;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex: 2;
  align-items: start;
`;

export const  StyledTitleHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  font-weight: 500;
  margin-bottom: 30px;
`;

export const  StyledNumberCircle = styled.div`
  ${({ theme }) => css`
    min-width: 45px;
    min-height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
    background-color: ${theme.brandColors.gray2};
  `}
`;

export const  StyledBody = styled.div`
  color: #6f8d9d;
  font-weight: 500;
  line-height: 97%;
`;

export const  StyledTitle = styled.span`
  color: #0eb1cf;
  margin-left: 10px;
  font-size:30px;
`;

export const StyledSubsectionTitle = styled.div`
  color: #0eb1cf;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const StyledS7SubsectionTitle = styled.div`
  color: #0eb1cf;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export const Separator = styled.hr`
  margin-top: 50px;
  margin-bottom: 30px;
  border-top: 3px solid #bbb;
`;

