import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { getInitiativePercentages } from 'services/initiative';

import CustomXLSXExportButton from './exportToXLSx';
import Spinner from 'react-bootstrap/Spinner';
import CustomModal from 'components/Modal';
import { PrimaryButton } from 'components/Buttons';
import { StyledTitle, StyledColTitle, StyledCol, InitiativeNameCol, NameDiv } from './DashboardInitiatives.styled';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Form from 'react-bootstrap/Form';

const DashboardInitiatives = () => {
  const { t } = useTranslation();
  const [waiting, setWaiting] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [initiatives, setInitiatives] = useState([]);
  const [selectedYear, setSelectedYear] = useState('ALL');

  useEffect(() => {
    getValues(null);
  }, []);

  async function getValues(filter) {
    try {
      setWaiting(true);
      const response = await getInitiativePercentages(filter);
      setInitiatives(response.data);
    } catch (error) {
      setModalErrorOpen(true);
      console.log(error);
    } finally {
      setWaiting(false);
    }
  }

  const renderYears = () => {
    var indents = [];
    for (var i = 2021; i <= 2024; i++) {
      indents.push(<option value={i}> {i} </option>);
    }
    return indents;
  };

  const handleFilter = () => {
    if (selectedYear !== 'ALL') {
      getValues(selectedYear);
    } else {
      getValues(null);
    }
  };

  const handleFilterYearChange = e => {
    setSelectedYear(e.target.value);
  };

  if (waiting)
    return (
      <Col style={{ marginTop: '10%', textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </Col>
    );

  return (
    <>
      <CustomModal
        show={modalErrorOpen}
        onHide={() => setModalErrorOpen(false)}
        message={t('Error try again later')}
        type="error"
      />

      <StyledTitle>{t('Dashboard_Initiatives')}</StyledTitle>
      <Row style={{ marginTop: '30px' }}>
        <Col xs={3} style={{ display: 'flex', alignItems: 'center' }}>
          {t('Dashboard_Year')}
          <Form.Control
            as="select"
            onChange={handleFilterYearChange}
            value={selectedYear}
            style={{ marginLeft: '20px', marginRight: '10px' }}
          >
            <option value="ALL"> {t('Button_filter_all')} </option>
            {renderYears()}
          </Form.Control>
          <PrimaryButton onClick={handleFilter} disabled={waiting}>
            {t('Button_filter')}
          </PrimaryButton>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CustomXLSXExportButton initiatives={initiatives} />
        </Col>
      </Row>

      <Row style={{ marginTop: '50px' }}>
        <Col sm={4} className="align-self-center">
          <StyledColTitle sm={2}>{t('Dashboard_Initiative')}</StyledColTitle>
        </Col>
        <Col sm={7} style={{ display: 'flex' }}>
          <StyledColTitle sm={4} className="align-self-center">
            {t('Period_elapsed')}
          </StyledColTitle>
          <StyledColTitle sm={4} className="align-self-center">
            {t('Executed_Budget_EU')}
          </StyledColTitle>
          <StyledColTitle sm={6} className="align-self-center">
            {t('Executed_Budget_COUNTERPART')}
          </StyledColTitle>
        </Col>
      </Row>

      {initiatives.length > 0 ? (
        initiatives.map(item => (
          <Row>
            <InitiativeNameCol sm={4} className="align-self-center">
              <NameDiv>
                {item.initiativeCode} / {item.initiativeName}
              </NameDiv>
            </InitiativeNameCol>
            <Col sm={7} style={{ display: 'flex', marginBottom: '10px' }}>
              <StyledCol sm={4} className="align-self-center">
                <ProgressBar
                  now={item.durationPercentage ? item.durationPercentage : 0}
                  label={`${item.durationPercentage ? item.durationPercentage.toFixed(1) : 0}%`}
                  variant="info"
                  style={{ height: '50px', width: '70%' }}
                />
              </StyledCol>
              <StyledCol sm={4} className="align-self-center">
                <ProgressBar
                  now={item.euBudgetPercentage ? item.euBudgetPercentage : 0}
                  label={`${item.euBudgetPercentage ? item.euBudgetPercentage.toFixed(1) : 0}%`}
                  variant="info"
                  style={{ height: '50px', width: '70%' }}
                />
              </StyledCol>
              <StyledCol sm={4} className="align-self-center">
                <ProgressBar
                  now={item.counterpartBudgetPercentage ? item.counterpartBudgetPercentage : 0}
                  label={`${item.counterpartBudgetPercentage ? item.counterpartBudgetPercentage.toFixed(1) : 0}%`}
                  variant="info"
                  style={{ height: '50px', width: '70%' }}
                />
              </StyledCol>
            </Col>
          </Row>
        ))
      ) : (
        <Row>
          <Col sm={3}></Col>
          <Col sm={7}>
            <StyledCol sm={8}></StyledCol>
            <StyledCol sm={8} style={{ justifyContent: 'center' }}>
              <i> {t('Items_no_items')} </i>
            </StyledCol>
          </Col>
        </Row>
      )}

      <Row style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
        <PrimaryButton onClick={() => getValues(selectedYear == 'ALL' ? null : selectedYear)} disabled={waiting}>
          {t('Button_Reload')}
        </PrimaryButton>
      </Row>
    </>
  );
};

export default observer(DashboardInitiatives);
