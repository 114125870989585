import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  goalPrimaryTypeId: Yup.string()
    .required('F3_S14_validation1'),
  primaryTargetList: Yup.array()
    .of(
      Yup.object().shape({
        targetIndicatorList: Yup.array()
          .of(
            Yup.object().shape({
              selectedIndicator: Yup.boolean()
            })
          )
          .test('testMinimum','F3_S11_validation1', indicators => {
            return indicators && ((indicators.length == 0) || indicators.some(indicator => indicator.selectedIndicator))
          })
      })
      ,
    )
    .min(1, 'F3_S11_validation2'),
  secondaryTargetList: Yup.array()
    .of(
      Yup.object().shape({
        targetIndicatorList:  Yup.array()
          .of(
            Yup.object().shape({
              selectedIndicator: Yup.boolean()
            })
          )
          .test('testMinimum', 'F3_S11_validation1', indicators => {
            return indicators && ((indicators.length == 0) || indicators.some(indicator => indicator.selectedIndicator))
          })
      })
    )
    .min(1, 'F3_S11_validation2'),
  goal10TargetList: Yup.array()
    .of(
      Yup.object().shape({
        targetIndicatorList:  Yup.array()
          .of(
            Yup.object().shape({
              selectedIndicator: Yup.boolean()
            })
          )
      })
    ),
  goal17TargetList: Yup.array()
    .of(
      Yup.object().shape({
        targetIndicatorList:  Yup.array()
          .of(
            Yup.object().shape({
              selectedIndicator: Yup.boolean()
            })
          )
          .test('testMinimum', 'F3_S11_validation1', indicators => {
            return indicators && ((indicators.length == 0) || indicators.some(indicator => indicator.selectedIndicator))
          })
      })
    )
    .min(1,  'F3_S11_validation2'),
});
