import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateDDMMYY } from 'helpers/dateHelpers';

import { MeetingsContainer, ButtonTitle } from './Meetings.styled';
import { PrimaryButton } from 'components/Buttons';
import { StyledSubtitle1, RoundedRemoveDiv } from 'components/CommonStyled';
import { StyledButtonLink } from 'components/Buttons';
import Row from 'react-bootstrap/Row';
import TextValue from 'components/TextValue';

const Meetings = ({ items, onAddMeeting, onRemoveMeeting, onEditMeeting, isAbleToAdd }) => {
  const { t } = useTranslation();

  const renderMeetings = () => {
    return items?.map((item, index) => (
      <Row style={{ marginBottom: '7px' }} key={index}>
        <ButtonTitle onClick={() => onEditMeeting(item)}>
          {item.code} - {item.title}
        </ButtonTitle>
        <div style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
          <TextValue
            value={`${item.date ? `(${formatDateDDMMYY(item.date)})` : ''} - [${
              item.confirmed ? t('F5_S7_Budget_Status_FINALIZED') : t('F5_S7_Budget_Status_PLANNED')
            }]`}
          />
        </div>
        {(!item.confirmed && !(item?.code?.includes('R1-') || item?.code?.includes('R2-'))) && (
          <div style={{marginLeft: '15px', marginTop: '2px' }}>
            <StyledButtonLink
              type="button"
              onClick={() => {
                onRemoveMeeting(item);
              }}
            >
              <RoundedRemoveDiv> x </RoundedRemoveDiv>
            </StyledButtonLink>
          </div>
        )}
      </Row>
    ));
  };

  return (
    <div>
      <StyledSubtitle1> {t('F5_S7_Meeting')} </StyledSubtitle1>
      <MeetingsContainer>
        {renderMeetings()}
      </MeetingsContainer>
      {isAbleToAdd && <PrimaryButton onClick={onAddMeeting}>{t('F5_S7_Add_Meeting')}</PrimaryButton>}
    </div>
  );
};
export default Meetings;
