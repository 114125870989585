import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CoapplicantForm from 'components/Forms/Form5/Section3/CoapplicantForm';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody 
} from '../CommonStyles'
import FakeAccordionItem from '../../FakeAccordionItem';
import { SeparatorDotted } from 'components/CommonStyled';

const formInitialValues = [];

const Section3 = ({ data }) => {
  const {t} = useTranslation();
  const [formValues, setFormValues] = useState(formInitialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data]);

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`3 - ${t('F1_S3_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        {(formValues && formValues.map((coapplicant, key) => (
          <React.Fragment  key={key}>
            <FakeAccordionItem title={`${t('F1_S3_Accordion')} ${key+1} - ${coapplicant.entityName}`}>
              <CoapplicantForm 
                coapplicantIndex={key} 
                data={coapplicant}
                handleReplacePersonButton={null}
                isAtke={false}
              /> 
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}
      </StyledBody>
    </SectionContainer>
  )
}

export default Section3;