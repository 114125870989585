// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Formik, FieldArray } from 'formik';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { saveFundSection } from 'services/funds';
import isEmpty from 'lodash/isEmpty';
import FormLayout from '../../FormLayout';
import Accordion from 'react-bootstrap/Accordion';
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from 'components/CustomAccordion';
import FormContainer from 'components/FormContainer';
import { PrimaryButton, ErrorButton } from 'components/Buttons';
import { SeparatorDotted } from 'components/CommonStyled';
import ModalConfirm from 'components/ModalConfirm';
import { validationSchema } from './Validations';
import { arrayOrderHelper } from 'helpers/arrayOrderHelper';
import { useTranslation } from 'react-i18next';
import { useConstantsFunds } from 'customHooks/useConstantsFunds';
import PartnerEntityForm from './PartnerEntityForm';
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const partnerObject = {
  entityName: '',
  acronym: '',
  entityTypeId: '',
  role: '0',
  countryId: '',
};

const Section3 = () => {
  const { t } = useTranslation();

  const { formStore } = useRootStore();

  const { la_ca_eu_countries: countries, entityTypes } = useConstantsFunds();
  const { id } = useParams();

  const [saved, setSaved] = useState(false);
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);

  const [initialValues, setInitialValues] = useState({
    partners: [{ ...partnerObject }],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const arr = [];
    if (formStore.formSectionData) {
      formStore.formSectionData.forEach(element => {
        arr.push({
          entityName: setStringFieldValue(element.entityName),
          acronym: setStringFieldValue(element.acronym),
          entityTypeId: element.entityType ? element.entityType.id : '',
          role: element.role ? element.role.toString() : '0',
          countryId: element.country ? element.country.id : '',
        });
      });

      setInitialValues({
        partners: arrayOrderHelper(arr),
      });
    } else {
      setInitialValues({
        partners: [{ ...partnerObject }],
      });
    }
  }, [formStore.formSectionData]);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  const openModalRemove = key => {
    setModalRemove(true);
    setSelectedKey(key);
  };

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  };

  const setStringFieldValue = newValue => {
    return newValue ? newValue : '';
  };

  const onSubmit = async (values, { resetForm }) => {
    try {
      for (let i = 0; i< values.partners.length; i++) {
        values.partners[i].number = `${i}`;
      }
      const response = await saveFundSection(id, 3, [...values.partners]);
      let bool = true && response.length;
      if (response) {
        response.forEach(element => {
          bool = bool && element.complete;
        });
      }
      formStore.setSectionStatus(3, bool);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, resetForm) => {
    e.preventDefault();
    isValid ? onSubmit(values, { resetForm }) : alert(t('not_saved_alert'));
  };

  return (
    <FormLayout>
      <FormContainer
        number={3}
        title={t('F1_S3_title')}
        showSavedMessage={saved}
        formId="form55section3"
        showSaveButton
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {props => (
            <form
              onSubmit={e => {
                onValidateSubmit(e, props.isValid, props.values, props.resetForm);
              }}
              id="form55section3"
            >
              {props.dirty && setSaved(false)}

              <FieldArray
                name="partners"
                render={arrayHelpers => (
                  <>
                    {modalRemove && (
                      <ModalConfirm
                        show={modalRemove}
                        confirmText={t('Button_remove')}
                        onConfirm={() => {
                          arrayHelpers.remove(selectedKey);
                          closeModalRemove();
                        }}
                        onClose={closeModalRemove}
                      >
                        <p>{t('Confirmation remove coapp') + ' ' + (selectedKey + 1)}?</p>
                      </ModalConfirm>
                    )}
                    <Accordion>
                      {initialValues.partners
                        ? props.values.partners.map((partner, key) => (
                          <AccordionItem key={key + 1}>
                            <AccordionItemHeader>
                              <AccordionTitle eventKey={key + 1}>
                                {`${t('F1_S3_Accordion')} ${key + 1} ${isEmpty(partner.entityName) ? '' : `- ${
                                  partner.entityName
                                }`}`}
                              </AccordionTitle>
                            </AccordionItemHeader>
                            <Accordion.Collapse eventKey={key + 1}>
                              <AccordionItemBody>
                                <PartnerEntityForm
                                  index={key}
                                  countries={countries}
                                  entityTypes={entityTypes}
                                />
                                <ErrorButton
                                  onClick={() => openModalRemove(key)}
                                >
                                  {t('F1_S3_Button_remove_entity')}
                                </ErrorButton>
                              </AccordionItemBody>
                            </Accordion.Collapse>
                          </AccordionItem>
                        ))
                        : null}
                    </Accordion>
                    {typeof props.errors.partners === 'string' ? (
                      <FormErrorMessage
                        touched
                        errors={props.errors.partners}
                      />
                    ) : null}

                    <SeparatorDotted />
                    <PrimaryButton
                      onClick={() => arrayHelpers.push(partnerObject)}
                    >
                      {t('F1_S3_Button_add_entity')}
                    </PrimaryButton>
                  </>
                )}
              />
            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section3);
