import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  //overviewExecutedBudget: Yup.string()
  //  .min(10, 'F3_S5_validation1'))
  //  .max(1300, 'F1_S6_validation8'))
  //  .nullable(true),
  overviewCoFinancing: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F1_S6_validation8')
    .nullable(true),
});
