import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { validationSchema } from "./Section8.validations";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { saveFormSection } from "services/initiative";
import { useTranslation } from "react-i18next";
import { useRootStore } from "stores";
import { formInitialValues } from "./Section8.initialValues";
import { f3statusNamesConst, roleNamesConst } from "constants/appConstants";
import { SVGGantt } from "gantt";
import { parseDateString } from "helpers/dateHelpers";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RequiredMark } from "components/CommonStyled";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import FormLayout from "../../FormLayout";
import FormContainer from "components/FormContainer";
import { StyledFormLabel } from "components/Forms/Form3/Section5/Section5.styled";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const Section8 = () => {
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { formStore, initiativeStore } = useRootStore();
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f3statusNamesConst.DRAFT ||
        initiativeStore.role === roleNamesConst.GUEST
    );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (formStore.formSectionData) {
      handleGanttData(formStore.formSectionData[0].gantt);
      const formSectionData = {
        ...formInitialValues,
        ...formStore.formSectionData[1],
      };
      formSectionData.startDate = formStore.formSectionData[0].startDate
        ? parseDateString(formStore.formSectionData[0].startDate)
        : "";
      formSectionData.endDate = formStore.formSectionData[0].endDate
        ? parseDateString(formStore.formSectionData[0].endDate)
        : "";
      for (const key in formSectionData) {
        if (!formSectionData[key]) {
          formSectionData[key] = "";
        }
      }
      setInitialValues(formSectionData);
    }
  }, [formStore.formSectionData]);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  const handleGanttData = (data) => {
    const ganttData = [];
    data.forEach((activity, index) => {
      ganttData.push({
        id: index,
        text: `${t(`activity_types_${activity.activityType.id}`)} / ${
          activity.name
        }`,
        start: new Date(activity.fromDate),
        end: new Date(activity.toDate),
      });
    });
    ganttData.sort(function (a, b) {
      return new Date(a.start) - new Date(b.start);
    });
    new SVGGantt("#gantt", ganttData, {
      viewMode: "week",
    });
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      valuesCopy["startDate"] = valuesCopy["startDate"]
        ? new Date(valuesCopy["startDate"]).toISOString()
        : "";
      valuesCopy["endDate"] = valuesCopy["endDate"]
        ? new Date(valuesCopy["endDate"]).toISOString()
        : "";
      const { complete } = await saveFormSection(
        id,
        formId,
        sectionId,
        valuesCopy
      );
      formStore.setSectionStatus(sectionId, complete);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid ? handleSubmit(values, { resetForm }) : alert(t("not_saved_alert"));
  };

  return (
    <FormLayout>
      <FormContainer
        number={8}
        title={t("F3_S8_title")}
        formId="form3section8"
        isHelp={true}
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            resetForm,
            handleChange,
            handleBlur,
            dirty,
            setFieldValue,
          }) => (
            <form
              onSubmit={(e) => {
                onValidateSubmit(e, isValid, values, { resetForm });
              }}
              id="form3section8"
            >
              {dirty && setSaved(false)}

              <div
                style={{
                  marginLeft: "50px",
                  marginBottom: "20px",
                  overflowX: "auto",
                  maxWidth: "100vw",
                }}
              >
                <div id="gantt" />
              </div>

              <Form.Group
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "50px",
                  alignItems: "center",
                }}
              >
                <Col>
                  <StyledFormLabel
                    style={{ marginTop: "8px", marginRight: "10px" }}
                  >
                    {t("F3_S8_Start_day")}:
                  </StyledFormLabel>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={values.startDate}
                    onChange={(date) => setFieldValue("startDate", date)}
                    className={
                      touched.startDate && errors.startDate ? "error" : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched
                    errors={errors.startDate}
                  />
                </Col>
                <Col>
                  <StyledFormLabel
                    style={{ marginTop: "8px", marginRight: "10px" }}
                  >
                    {t("F3_S8_End_day")}:
                  </StyledFormLabel>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={values.endDate}
                    onChange={(date) => setFieldValue("endDate", date)}
                    className={
                      touched.endDate && errors.endDate ? "error" : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched
                    errors={errors.endDate}
                  />
                </Col>
              </Form.Group>

              <Form.Group style={{ paddingLeft: "50px" }}>
                <StyledFormLabel>
                  {t("F1_S7_Intervention_methodology")}
                  <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <Form.Control
                  name="methodology"
                  as="textarea"
                  rows={3}
                  style={{ resize: "none" }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.methodology}
                  disabled={nonEditable}
                  className={
                    touched.methodology && errors.methodology ? "error" : null
                  }
                />
                <FormErrorMessage
                  touched={touched.methodology}
                  errors={errors.methodology}
                />
              </Form.Group>

              <Form.Group style={{ paddingLeft: "50px" }}>
                <StyledFormLabel>
                  {t("F3_S8_justification_deviation")}:
                </StyledFormLabel>
                <Form.Control
                  name="justificationDeviation"
                  as="textarea"
                  rows={3}
                  style={{ resize: "none" }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.justificationDeviation}
                  disabled={nonEditable}
                  className={
                    touched.justificationDeviation &&
                    errors.justificationDeviation
                      ? "error"
                      : null
                  }
                />
                <FormErrorMessage
                  touched={touched.justificationDeviation}
                  errors={errors.justificationDeviation}
                />
              </Form.Group>

              <Form.Group style={{ paddingLeft: "50px" }}>
                <StyledFormLabel>
                  {t("F3_S8_risks")} <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <Form.Control
                  name="covid19"
                  as="textarea"
                  rows={3}
                  style={{ resize: "none" }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.covid19}
                  disabled={nonEditable}
                  className={touched.covid19 && errors.covid19 ? "error" : null}
                />
                <FormErrorMessage
                  touched={touched.covid19}
                  errors={errors.covid19}
                />
              </Form.Group>
            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section8);
