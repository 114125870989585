import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getFundsConstants } from 'services/funds';

const initialValues = {
  countries: [],
  la_ca_eu_countries: [],
  entityTypes: [],
  beneficiaryTypes: [],
  developmentGoals: [],
  developmentGoalTargets: [],
  developmentGoalTargetIndicators: [],
  activityTypes: [],
  mediaTypes: [],
  resourceTypes: [],
  productTypes: [],
  lessonTypes: [],
  lessonMoments: [],
  lessonUseForList: [],
  fundTypes: [],
  sortedFundTypes: [],
  relationshipBackgrounds: [],
}

export const useConstantsFunds = () => {
  const { t } = useTranslation()
  const [values, setValues] = useState(initialValues)

  // sort by name
  const sortByName = (list, i18nPrefix) => {
    const listCopy = [...list]
    return listCopy.sort((a, b) => {
      const nameA = t(`${i18nPrefix}${a.id}`).toUpperCase(); // ignore upper and lowercase
      const nameB = t(`${i18nPrefix}${b.id}`).toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  }

  useEffect(() => {
    async function fetchAPI() {
      try {
        const {
          countries,
          la_ca_eu_countries,
          entityTypes,
          beneficiaryTypes,
          developmentGoals,
          developmentGoalTargets,
          developmentGoalTargetIndicators,
          activityTypes,
          mediaTypes,
          resourceTypes,
          productTypes,
          lessonTypes,
          lessonMoments,
          lessonUseForList,
          fundTypes,
          relationshipBackgrounds,
        } = await getFundsConstants();

        setValues(prevState => ({
          ...prevState,
          countries,
          la_ca_eu_countries,
          entityTypes,
          beneficiaryTypes,
          developmentGoals,
          developmentGoalTargets,
          developmentGoalTargetIndicators,
          activityTypes,
          mediaTypes,
          resourceTypes,
          productTypes,
          lessonTypes,
          lessonMoments,
          lessonUseForList,
          fundTypes,
          sortedFundTypes: sortByName(fundTypes, 'fund_type_'),
          relationshipBackgrounds
        }));
      }
      catch(e){
        console.log(e);
      }
    }
    fetchAPI();
  }, []);

  return values;
}

