import React from 'react';
import { useTranslation } from 'react-i18next';

import TextValue from 'components/TextValue';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RequiredMark } from 'components/CommonStyled';

 
const ParticipantForm = ({ data }) => {
  const {t} = useTranslation();

  return (
    <div style={{marginLeft: '30px'}}>
      <Row>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F1_S2_First_name')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <TextValue value={data.firstName} />
          </Form.Group>  
        </Col>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F1_S2_Last_Name')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <TextValue value={data.lastName} />
          </Form.Group>  
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F3_S7_Participant_nationality')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <TextValue value={data.nationality? t(data.nationality.name): ""} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F3_S7_Participant_city_departure')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <TextValue value={data.city} />
          </Form.Group>  
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F3_S7_Participant_organization')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <TextValue value={data.organizationName} />
          </Form.Group>  
        </Col>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F3_S7_Participant_type_entity')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <TextValue value={data.entityType? t(`entity_type_${data.entityType.id}`): ""} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F1_S2_Email')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <TextValue value={data.email} />
          </Form.Group>  
        </Col>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F1_S2_Phone_number')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <TextValue value={data.phone} />
          </Form.Group>  
        </Col>
      </Row>

    </div>
  )

}

export default ParticipantForm;
