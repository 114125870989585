import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form'
import { PrimaryButton } from 'components/Buttons';

export const StyledFormLabel = styled(Form.Label)`
  ${({ theme }) => css`
    color: ${theme.colors.gray[1]};
  `}
  font-weight: 500;
`;

export const StyledTitle = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    margin-bottom: 10px;
    color: ${theme.brandColors.blue};
    font-weight: 600;
    font-size: 23px;
  `}
`;

export const StyledCheckSubtitle = styled.div`
  margin-top: 20px;
`;

export const  StyledSavedButton = styled(PrimaryButton)`
  ${({ theme }) => css`
    &:disabled {
      background-color:${theme.brandColors.green};
      color: white;
      border: ${theme.colorGroups.success.bgColor};
    }
  `}
`;