import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateDDMMYY } from 'helpers/dateHelpers';

import { StyledS7SubsectionTitle } from '../../CommonStyles';
import TextValue from 'components/TextValue';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "react-datepicker/dist/react-datepicker.css";
import Form from 'react-bootstrap/Form'
import { SeparatorDotted } from 'components/CommonStyled';
import { RadioLabel } from 'components/Forms/Forms.styled';

const GeneralInfo = ({ activity }) => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledS7SubsectionTitle> {t('F3_S7_Subtitle_General_Information')} </StyledS7SubsectionTitle>
      <Form.Group as={Row}>
        <Form.Label column xs={1}>{t('F1_S7_type')}:</Form.Label>
        <TextValue value={activity.activityType ? t(`activity_types_${activity.activityType.id}`) : ' - '} />
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column xs={2}>{t('F1_S7_start_day')}:</Form.Label>
        <TextValue value={formatDateDDMMYY(activity.fromDate)}/>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column xs={2}>{t('F1_S7_End_day')}:</Form.Label>
        <TextValue value={formatDateDDMMYY(activity.toDate)}/>
      </Form.Group>

      <Form.Label row xs={2}>{t('F1_S7_Short_description')}:</Form.Label>
      <Form.Group>
        <TextValue value={activity.description} />
      </Form.Group>

      <SeparatorDotted />

      <Form.Group as={Row}>
        <Form.Label as={Row} column xs={2}>
          {t('F1_S7_Format')}:
        </Form.Label>
        <Col lg={8} style={{ marginLeft: '50px', marginTop: '30px' }}>
          <RadioLabel>
            <input
              type="radio"
              checked={String(activity.format) === '0'}
              style={{ marginRight: '10px' }}
              disabled
            />
            {t('F1_S7_Format0')}
          </RadioLabel>

          <RadioLabel>
            <input
              type="radio"
              checked={String(activity.format) === '1'}
              style={{ marginRight: '10px' }}
              disabled
            />
            {t('F1_S7_Format1')}
          </RadioLabel>

          <RadioLabel>
            <input
              type="radio"
              checked={String(activity.format) === '2'}
              style={{ marginRight: '10px' }}
              disabled
            />
            {t('F1_S7_Format2')}
          </RadioLabel>
        </Col>
      </Form.Group>
      
      {String(activity.format) !== '2' && (
        <>
          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {t('F1_S7_Country_activity')}:
            </Form.Label>
            <TextValue value={activity.country ? t(activity.country.name) : ' - '} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={3}>
              {t('F1_S2_State_Province_Region')}:
            </Form.Label>
            <TextValue value={activity.stateName} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {t('F1_S2_City')}:
            </Form.Label>
            <TextValue value={activity.cityName} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={4}>
              {t('F1_S7_Countries_additional')}:
            </Form.Label>
            <TextValue value={activity.countriesAdditional} />
          </Form.Group>
        </>
      )}

      <SeparatorDotted />

      {(activity.activityType && (activity.activityType.id == 4 || activity.activityType.id == 7)) && 
        <>
          <Form.Group as={Row}>
            <Form.Label column xs={2}>{t('F1_S7_Duration')}:</Form.Label>
            <TextValue value={activity.duration} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>{t('F1_S7_Number_participants')}:</Form.Label>
            <TextValue value={activity.participants} />
          </Form.Group>
    
          <SeparatorDotted />
        </>
      }
      
      {(activity.activityType && activity.activityType.id == 4) &&
        <>
          <Form.Group as={Row}>
            <Form.Label column xs={2}>{t('F1_S7_Number_experts')}:</Form.Label>
            <TextValue value={activity.experts} />
          </Form.Group>
    
          <Form.Label xs={4} as={Row} style={{ marginTop: '30px', marginBottom: '15px' }}>{t('F1_S7_Number_wd')}:</Form.Label>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>{t('F1_S7_Remote')}:</Form.Label>
            <TextValue value={activity.daysRemote} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>{t('F1_S7_Onsite')}:</Form.Label>
            <TextValue value={activity.daysOnsite} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>{t('F1_S7_Total')}:</Form.Label>
            <TextValue value={activity.daysRemote + activity.daysOnsite} />
          </Form.Group>

          <SeparatorDotted />
        </>
      }
      <Form.Group>
        <Row style={{ alignItems: "center" }}>
          <Form.Label column xs={2} >{t('F3_S7_General_work_language')}</Form.Label>
          <Col xs={2}>
            <Form.Check disabled={true} style={{marginLeft: '15px'}} name="workLang1" type="checkbox" checked={activity.workLang1} label={t('English')} />
          </Col>
          <Col xs={2}>
          < Form.Check disabled={true} style={{marginLeft: '15px'}} name="workLang2" type="checkbox" checked={activity.workLang2} label={t('French')} />
          </Col>
          <Col xs={2}>
            <Form.Check disabled={true} style={{marginLeft: '15px'}} name="workLang3" type="checkbox" checked={activity.workLang3} label={t('Portugues')} />
          </Col>
          <Col xs={2}>
            <Form.Check disabled={true} style={{marginLeft: '15px'}} name="workLang4" type="checkbox" checked={activity.workLang4} label={t('Spanish')} />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>{t('F3_S7_General_learning_objectives')}:</Form.Label>
        <TextValue value={activity.learning} />
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>{t('F3_S7_General_alignment')}:</Form.Label>
        <TextValue value={activity.generalAlignment} />
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>{t('F3_S7_General_participation_members')}:</Form.Label>
        <TextValue value={activity.generalParticipationMembers} />
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>{t('F3_S7_General_proposal_cross_issues')}:</Form.Label>
        <TextValue value={activity.generalProposalCrossIssues} />
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>{t('F3_S7_General_brief_description_schedule')}:</Form.Label>
        <TextValue value={activity.descSchedule} />
      </Form.Group>
    </div>
  )
}

export default GeneralInfo;