import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionContainer = styled.div`
  ${({ theme }) => css`
    border-top: 1px dotted ${theme.brandColors.blue};
    flex: 1;
    padding: 10px 0px;
  `}
`;

export const SectionTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.brandColors.green};
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
  `}
`;

export const MyAssessmentDiv = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.brandColors.darkBlue};
    padding: 10px 15px;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 15px;
  `}
`;

export const CommentsNumber = styled.div`
  ${({ theme }) => css`
    position: absolute;
    color: ${theme.colors.white};
    background-color: ${theme.brandColors.green};
    border-radius: 5px;
    right: 0;
    margin-right: 25px;
    margin-top: -3px;
    padding: 3px;
    padding-left: 7px;
    padding-right: 7px;
    font-weight: 500;
    font-size: 15px;
  `}
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`