import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Proptypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import { 
  ModalFooter, 
  FooterLeft, 
  FooterCenter, 
  FooterRight,
  TextValueLeftSpaced,
 } from './AddBeneficiaryDialog.styled';
import { PrimaryButton } from 'components/Buttons';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup';
import TextValue from 'components/TextValue';

const CountryRow = ({ country, quantity }) => {
  const { t } = useTranslation();

  return (
    <Form.Group as={Row}>
      <Col xs={7}>
        <Form.Group as={Row}>
          <TextValueLeftSpaced>
            <TextValue value={country? t(`${country.name}`) : ' - '} />
          </TextValueLeftSpaced>
        </Form.Group>
      </Col>
      <Col xs={3}>
        <Form.Group as={Row}>
          <TextValueLeftSpaced>
            <TextValue value={quantity} />
          </TextValueLeftSpaced>
        </Form.Group>
      </Col>
      <Col xs={1} />
    </Form.Group>
  )
}

const AddBeneficiaryDialog = ({ data, displayDialog, onCloseModal }) => {

  return (
    <Modal show={displayDialog} onHide={onCloseModal} size="md" animation={false}>
      <Modal.Body>
        <ListGroup>
          {data.answersF3S10BenCountryList
            ? ( data.answersF3S10BenCountryList.map((country, key) => (
                <ListGroup.Item key={key+1}>
                  <CountryRow country={country.country} quantity={country.quantity} />  
                </ListGroup.Item>
              )))
            : null}
        </ListGroup>
      </Modal.Body>
      <ModalFooter>
        <FooterLeft />
        <FooterCenter>
          <PrimaryButton onClick={onCloseModal}>OK</PrimaryButton>
        </FooterCenter>
        <FooterRight />
      </ModalFooter>
    </Modal>
  )
}

AddBeneficiaryDialog.propTypes = {
  data: Proptypes.object,
};

export default observer(AddBeneficiaryDialog);
