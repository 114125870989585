import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { formInitialValues } from './SelectProviderForm.initialValues';
import { validationSchema } from './SelectProviderForm.validations';
import { changeF5S7ActivityDetailSelectedProvider, getDetailProvidersF5S7Budget, setF5S7ActivityDetailSelectedProvider, modifyF5S7ActivityDetailSelectedProvider, saveFile, getDownload } from 'services/initiative';
import { getUploadFormatsConstants } from "services/apiConstants";
import { useRootStore } from 'stores';
import { roleNamesConst } from 'constants/appConstants';
import DayJS from 'dayjs';

import FileUploader from 'components/FileUploader';
import { StyledFormLabel, StyledCheck } from './SelectProviderForm.styled'
import TextValue from 'components/TextValue';
import MoneyInput from 'components/MoneyInput';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ErrorButton, PrimaryButton, StyledSendButton} from 'components/Buttons'
import { Form } from 'react-bootstrap'
import { FormFielErrorMessage, RequiredMark, CenteredModalButtonContainer } from 'components/CommonStyled';

const SelectProviderForm = ({ activity, detail, handleCloseOk, handleClose, handleCloseSubModalOnError, setModalInfo, nonEditable, payments }) => {
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { authStore } = useRootStore();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [providers, setProviders] = useState([]);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const role = authStore.roles[0];
  const isAtke = role === roleNamesConst.ATKE
  const [validFileFormats, setValidFileFormats] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [changeSelectedProvider, setChangeProviderSelected] = useState(false);

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr);
    return date.format("DD/MM/YYYY");
  }

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getUploadFormatsConstants();
        if(response && response.F3S7){
          setValidFileFormats(response.F3S7);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAPI();
   }, [])

  useEffect(() => {
    if(detail.currentSelectedProvider){
      setIsEditing(true);
      setInitialValues({
        ...detail.currentSelectedProvider,
        detailProviderId: detail.currentSelectedProvider.mgmtF5S7ActivityDetailProvider.id
      });
    }
    setChangeProviderSelected(detail.providerSelected);
  }, [detail])

  const handleSubmit = async (values) => {
    try {
      if(isEditing)
        await modifyF5S7ActivityDetailSelectedProvider(id, activity.id, detail.id, values);
      else
        if(changeSelectedProvider)
           await changeF5S7ActivityDetailSelectedProvider(id, activity.id, detail.id, values);    
        else 
            await setF5S7ActivityDetailSelectedProvider(id, activity.id, detail.id, values);
            
      handleCloseOk();
    } catch (error) {
      handleCloseSubModalOnError();
    }
  };

  useEffect(() => {
    async function getValues() {
      const response = await getDetailProvidersF5S7Budget(id, activity.id, detail.id);
      const allProviders = [...response.data];
      setProviders(allProviders);
    }
    getValues();
  }, []);

  const handleFileUpload = async (e, setFieldValue, handleChange) => {
    try{
      const file = document.getElementById("fileInput").files[0];
      const fileExtension = file.name.split('.').pop();
      if(file.size > 20971520){
        setModalInfo({
          message: t('Error_file4'),
          type: "error",
          isOpen: true,
        });
      }else if(!validFileFormats.includes(fileExtension.toUpperCase())){
        setModalInfo({
          message: t('Error_file5'),
          type: "error",
          isOpen: true,
        });
      }
      else{
        setIsUploadingFile(true);
        const uid = await saveFile(id, formId, sectionId, file);
        setFieldValue('sheetEval.fileName', file.name);
        setFieldValue('sheetEval.uuid', uid);
        setFieldValue('fileUploaded', true);
        handleChange(e);
      }
    } catch (error) {
      setModalInfo({
        message: t('Error_file2'),
        type: "error",
        isOpen: true,
      });
    }
    finally {
      setIsUploadingFile(false);
    }
  }

  const handleDownload = async (uuid, downloadName) => {
    try {
      const file = await getDownload(uuid)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download", downloadName
        );
        document.body.appendChild(link);
        link.click();
    });
    } catch (error) {
      setModalInfo({
        message: t('Error_file1'),
        type: "error",
        isOpen: true,
      });
    }    
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
         }) => (
          <Form onSubmit={handleSubmit} id="selectProvider" >

            {
              isEditing
              ?
                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>{t('F5_S7_Budget_Select_Provider_Selected')}:</StyledFormLabel>
                  <TextValue value={(values.mgmtF5S7ActivityDetailProvider)? values.mgmtF5S7ActivityDetailProvider.mgmtF5S7Provider.name: ' - '} />
                </Form.Group>  
              :
                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>{t('F5_S7_Budget_Select_Provider_Selected')}<RequiredMark>*</RequiredMark>:</StyledFormLabel>
                  <Col lg={4}>
                    <Form.Control 
                      name="detailProviderId"
                      as="select"
                      onChange={handleChange}
                      value={values.detailProviderId}
                      disabled={nonEditable}
                    >
                      <option value="">{t('Placeholder_select')}</option>
                      {providers.map(detailProvider => (
                          <option key={detailProvider.id} value={detailProvider.id}>{detailProvider.mgmtF5S7Provider.name}</option>
                      ))}
                    </Form.Control>
                    {touched.detailProviderId && errors.detailProviderId ? (
                      <FormFielErrorMessage>{errors.detailProviderId}</FormFielErrorMessage>
                    ): null}
                  </Col>
                </Form.Group>  
            }
          

            <Form.Group>
              <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}} >{t('F5_S7_Budget_Select_Provider_Justification')}<RequiredMark>*</RequiredMark>:</Form.Label>
              <Form.Control
                name="justification"
                as="textarea" 
                rows={5} 
                placeholder={t('Maximum_1300')}
                style={{resize: 'none'}}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.justification}
                className={errors.justification ? "error" : null}
                disabled={nonEditable}
              />
              {touched.justification && errors.justification ? (
                <FormFielErrorMessage>{errors.justification}</FormFielErrorMessage>
              ): null}
            </Form.Group>

            <FileUploader
              file={values?.sheetEval}
              handleFileUpload={(e) => handleFileUpload(e, setFieldValue, handleChange)}
              handleDownload={handleDownload}
              isUploadingFile={isUploadingFile}
              uploadLabel={t('F5_S7_Budget_Select_Provider_Upload')}
              nonEditable={nonEditable}
            />

            <Form.Group as={Row}>
              <Form.Label column md={3} xs={3}>{t('F5_S7_Budget_Select_Provider_Unit')}<RequiredMark>*</RequiredMark>:</Form.Label>
              <Col xs={3}>
                <MoneyInput
                  name="unitPrice"
                  style={{ width: '170px' }}
                  value={values.unitPrice} 
                  onChange={val => setFieldValue("unitPrice", val)}
                  onBlur={handleBlur}
                  customInput={Form.Control}
                  className={errors.unitPrice ? "error" : null}
                  disabled={nonEditable}
                />
                {errors.unitPrice ? (
                  <FormFielErrorMessage>{errors.unitPrice}</FormFielErrorMessage>
                ): null}
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column xs={3}>{t('F5_S7_Budget_Select_Provider_Quantity')} <RequiredMark>*</RequiredMark>:</Form.Label>
              <Col xs={3}>
                <Form.Control
                  type="number"
                  name="quantity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.quantity}
                  className={touched.quantity && errors.quantity ? "error" : null}
                  disabled={nonEditable}
                />
                {touched.quantity && errors.quantity ? (
                  <FormFielErrorMessage>{errors.quantity}</FormFielErrorMessage>
                ): null}
              </Col>
              <Col xs={3}>
                  {t("F5_S7_Budget_Select_Provider_Quantity_Note")}
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column md={3} xs={3}>{t('F5_S7_Budget_Select_Provider_Total')}:</Form.Label>
              <Col xs={3}>
                <MoneyInput
                  name="totalAmount"
                  style={{ width: '170px' }}
                  value={values.quantity * values.unitPrice} 
                  onChange={val => setFieldValue("totalAmount", val)}
                  onBlur={handleBlur}
                  customInput={Form.Control}
                  disabled={true}
                />
              </Col>
            </Form.Group>
            
            {
               isEditing
               &&
                <>
                  {
                    (detail && (detail.ueOrCounterpart == 0) && detail.resourceType && (detail.resourceType.id == 8 || detail.resourceType.id == 9))?
                    <StyledCheck style={{marginTop: '20px'}}>
                      <Form.Group> 
                        <Form.Check 
                          disabled={true} 
                          style={{marginLeft: '15px', textAlign: 'justify'}} 
                          type="checkbox" 
                          checked={true} 
                          label={t('F5_S7_Budget_Select_Provider_Submodal_Confirmed1')} 
                        />
                      </Form.Group>
                    </StyledCheck>
                  :
                    <>
                      <StyledCheck>
                        <Form.Group>
                          <Form.Check 
                            disabled={true} 
                            style={{marginLeft: '15px', textAlign: 'justify'}} 
                            type="checkbox" 
                            checked={values.confirmPartnership} 
                            label={t('F5_S7_Budget_Select_Provider_Submodal_Confirmed1')} 
                          />
                        </Form.Group>
                      </StyledCheck>
                      {
                        values.confirmPartnership
                        &&
                        <div>
                          {t('F5_S7_Confirmed')}: {values.confirmPartnershipUsername} - {values.confirmPartnershipTimestamp? formatDate(values.confirmPartnershipTimestamp): ''}
                        </div>
                      }
                    </>
                  }

                  <StyledCheck style={{marginTop: '30px'}}>
                    <Form.Group>
                      <Form.Check 
                        disabled={nonEditable || values.confirmAvailability || (isAtke && payments?.length > 0)} 
                        style={{marginLeft: '15px', textAlign: 'justify'}} 
                        name="confirmAvailability"
                        id="checkboxAvailability" 
                        type="checkbox" 
                        checked={values.confirmAvailability} 
                        value={values.confirmAvailability} 
                        onChange={handleChange} 
                        label={t('F5_S7_Budget_Select_Provider_Submodal_Confirmed2')} 
                        className={touched.confirmAvailability && errors.confirmAvailability ? "error" : null}
                      />
                      {touched.confirmAvailability && errors.confirmAvailability ? (
                        <FormFielErrorMessage>{errors.confirmAvailability}</FormFielErrorMessage>
                      ): null}
                    </Form.Group>
                  </StyledCheck>
                  {
                    values.confirmAvailability
                    &&
                    <div>
                      {t('F5_S7_Confirmed')}: {values.confirmAvailabilityUsername} - {values.confirmAvailabilityTimestamp? formatDate(values.confirmAvailabilityTimestamp): ''}
                    </div>
                  }
                </>
            }

            <CenteredModalButtonContainer>
              {
                (nonEditable && !(isAtke))
                ?
                  <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
                :
                  <>
                    <ErrorButton style={{marginRight: '20px'}} onClick={handleClose} disabled={nonEditable && !(isAtke)}> {t('Button_cancel')} </ErrorButton>
                    <StyledSendButton type="submit" form="selectProvider" disabled={nonEditable && !(isAtke)}> {t('Button_save')} </StyledSendButton>
                  </>
              }
            </CenteredModalButtonContainer>

          </Form>
        )}
      </Formik>
    </div>
  )
}

export default observer(SelectProviderForm);