import React, { useEffect, useState } from 'react';
import { formInitialValues } from './EudForm.initialValues';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getF9aById } from 'services/initiative';
import ApplicantF8a from '../ApplicantF8ReadOnly';
import { useFormikContext } from 'formik';

import Form from 'react-bootstrap/Form';
import { StyledLabel, StyledTitle } from '../ExpostReportForm.styles';
import { FormFielErrorMessage } from 'components/CommonStyled';
import F8bReadOnly from '../F8bReadOnly';

const EudForm = ({ setSaved, saved, nonEditable, setNonEditable, setFormInitialValues }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(formInitialValues);
  const { formId } = useParams();
  const { 
    values,
    errors,
    touched,
    isValid,
    dirty,
    resetForm,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormikContext();

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getF9aById(formId);
        setFormData(response);
        setFormInitialValues({ ...response.form9a, form9c: response?.form9c})
        setNonEditable(response.form8b.status.stateEnum !== 'DONE' || response.form9a.status.stateEnum === 'DONE' || response.form9a.status.stateEnum === 'CANCELED');
      } catch (error) {
        console.log(error)
      }
    };
    fetchAPI();
  }, [])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);  
  },[saved])

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid
      ? handleSubmit(values, { resetForm })
      : alert(t('not_saved_alert'));
  }

  return (
    <form 
        onSubmit={(e) => {onValidateSubmit(e, isValid, values, { resetForm })}}
        id="form9a"
    >
        {dirty && (setSaved(false))}

        <ApplicantF8a formData={formData.form8} />

        <F8bReadOnly formData={formData.form8b} />

        <StyledTitle style={{marginTop: '40px'}}>{t('F9a_title_2')}:</StyledTitle>
        
        <Form.Group>
          <StyledLabel>{t('F9a_assessment_1')}:</StyledLabel>
              <Form.Control
                  as="textarea" 
                  name="comment1"
                  rows={3} 
                  style={{resize: 'none'}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.comment1}
                  className={touched?.comment1 && errors?.comment1 ? "error" : null}
                  disabled={nonEditable}
                />
              {touched?.comment1 && errors?.comment1 ? (
                <FormFielErrorMessage>{errors?.comment1}</FormFielErrorMessage>
              ): null}
        </Form.Group> 

        <Form.Group>
          <StyledLabel>{t('F9a_assessment_2')}:</StyledLabel>
              <Form.Control
                  as="textarea" 
                  name="comment2"
                  rows={3} 
                  style={{resize: 'none'}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.comment2}
                  className={touched?.comment2 && errors?.comment2 ? "error" : null}
                  disabled={nonEditable}
                />
              {touched?.comment2 && errors?.comment2 ? (
                <FormFielErrorMessage>{errors?.comment2}</FormFielErrorMessage>
              ): null}
        </Form.Group>

        <Form.Group>
          <StyledLabel>{t('F9a_assessment_3')}:</StyledLabel>
              <Form.Control
                  as="textarea" 
                  name="recommendation"
                  rows={3} 
                  style={{resize: 'none'}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.recommendation}
                  className={touched?.recommendation && errors?.recommendation ? "error" : null}
                  disabled={nonEditable}
                />
              {touched?.recommendation && errors?.recommendation ? (
                <FormFielErrorMessage>{errors?.recommendation}</FormFielErrorMessage>
              ): null}
        </Form.Group> 
    </form>
  )
}

export default EudForm;
