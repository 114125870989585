import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.brandColors.blue};
  `}
  font-size: 20px;
  font-weight: 500;
`;

export const StyledSubtitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.brandColors.blue};
  `}
  font-size: 20px;
  font-weight: 400;
`;

export const StyledLabel = styled(Form.Label)`
  ${({ theme }) => css`
    color: ${theme.brandColors.blue};
  `}
  color: #6f8d9d;
  font-weight: 500;
  text-align: justify;
`;

export const StyledQuestions = styled(Form.Label)`
  ${({ theme }) => css`
    color: ${theme.brandColors.darkBlue};
  `}
  font-weight: 500;
  text-align: justify;
  font-size: 17px;
  margin-bottom: 20px;
  margin-top: 15px;
`;