import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateDDMMYY } from 'helpers/dateHelpers';
import { mapActivityItems } from 'helpers/f5Helpers';

import CustomBudgetTable from 'components/CustomBudgetTable';
import Form from 'react-bootstrap/Form';
import { RequiredMark } from 'components/CommonStyled';
import {
  StyledFormLabel,
  BlackSubtitle,
  Title,
} from 'components/Forms/Form5/Section9/Section9.styled';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody 
} from '../CommonStyles';

export const formInitialValues = [
  {
    overviewExecutedBudget: "",
    overviewCoFinancing: "",
  },
];

const Section9 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(formInitialValues);

  const [consolidatedBudgetRows, setConsolidatedBudgetRows] = useState([]);
  const [executedBudgetRows, setExecutedBudgetRows] = useState([]);
  const [totalBudgetConsolidated, setTotalBudgetConsolidated] = useState({
    eu_budget: 0,
    counterpart_budget: 0,
    total_budget: 0
  });
  const [totalBudgetExecuted, setTotalBudgetExecuted] = useState({
    eu_budget: 0,
    counterpart_budget: 0,
    total_budget: 0
  });

  useEffect(() => {
    if(data){
      if(data[0]){
        setFormValues({...data[0]});
      }
      if(data[1]){
        const rows =  data[1];
        const total = rows.pop();
        setConsolidatedBudgetRows(rows);
        setTotalBudgetConsolidated(total);
      }
      if(data[2].items){
        const rows =  data[2].items;
        const total = rows.pop();
        setExecutedBudgetRows(rows);
        setTotalBudgetExecuted(total);
      }
    }
  }, [data])

  const getLastModifiedTable = () => {
    if(data && data[2] && data[2].lastModified){
      return formatDateDDMMYY(data[2].lastModified);
    }
    else
      return '-';
  }

  const lastModifiedTable = getLastModifiedTable();
  
  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`9 - ${t('F3_S9_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <Title> {t('F5_S9_Subtitle_consolidated_budget')} </Title>
        <CustomBudgetTable
          items={consolidatedBudgetRows}
          totalItem={totalBudgetConsolidated}
        />

        <Title> {t('F5_S9_Subtitle_executed_budget')} </Title>
        {`(${t('F5_S9_LASTUPDATE')}: ${lastModifiedTable} )`}
        <CustomBudgetTable
          items={executedBudgetRows}
          totalItem={totalBudgetExecuted}
        />

        <BlackSubtitle> {t('F5_S7_Activity_assessment_1')} </BlackSubtitle>
        {(formValues && formValues[3]) && mapActivityItems(formValues[3])}
  
        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_Overview_of_TCI')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="overviewExecutedBudget"
            as="textarea" 
            rows={7} 
            style={{resize: 'none'}}
            value={formValues.overviewExecutedBudget}
            disabled={true}
            placeholder={t('Maximum_1300')}
          />
        </Form.Group>

        <BlackSubtitle> {t('F5_S7_Activity_assessment_2')} </BlackSubtitle>
        {(formValues && formValues[4]) && mapActivityItems(formValues[4])}

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_Overview_of_TCI')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="overviewCoFinancing"
            as="textarea" 
            rows={7} 
            style={{resize: 'none'}}
            value={formValues.overviewCoFinancing}
            disabled={true}
            placeholder={t('Maximum_1300')}
          />
        </Form.Group>
      </StyledBody>
    </SectionContainer>
  )
}

export default Section9;
