import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  lessons: Yup.array()
  .of(
    Yup.object().shape({
      lessonTitle: Yup.string()
        .min(10, 'F3_S5_validation1')
        .max(650, 'F3_S7_validation5')
        .nullable(true),
      lessonTypeId: Yup.string()
        .min(1, 'F1_S2_validation5'),
      lessonMomentId: Yup.string()
        .min(1, 'F1_S2_validation5'),
      lessonEvent: Yup.string()
        .min(10, 'F3_S5_validation1')
        .max(1300, 'F1_S6_validation8')
        .nullable(true),
      lessonDecisions: Yup.string()
        .min(10, 'F3_S5_validation1')
        .max(1300, 'F1_S6_validation8')
        .nullable(true),
      lessonLearning: Yup.string()
        .min(10, 'F3_S5_validation1')
        .max(1300, 'F1_S6_validation8')
        .nullable(true),
      lessonImprovement: Yup.string()
        .min(10, 'F3_S5_validation1')
        .max(1300, 'F1_S6_validation8')
        .nullable(true),
      lessonUseForId: Yup.string()
        .min(1, 'F1_S2_validation5'),
      lessonCountryIdList: Yup.array(Yup.number())
        .min(1, 'F5_S15_validation2'),
      lessonActors: Yup.string()
        .min(10, 'F3_S5_validation1')
        .max(200, 'F1_S4_validation2')
        .nullable(true),
      lessonKeywords: Yup.string()
        .min(10, 'F3_S5_validation1')
        .max(200, 'F1_S4_validation2')
        .nullable(true),
    })
  )
  .min(1,'F5_S15_validation1')
});
