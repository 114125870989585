import i18n from 'i18n'
import _orderBy from 'lodash/orderBy'

export const renderCountriesOptions = (countriesOptions) => {
  let aux = _orderBy(countriesOptions, [(field) => i18n.t(field.name)], ['asc'])
  return <>
    <option value=""> {i18n.t('ALL')} </option>
    {aux.map((country) => (
      <option key={country.id} value={country.id}>
        {i18n.t(country.name)}
      </option>
    ))}
  </>
}
