import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { validationSchema } from "./Register.validations";
import htmlParser from 'html-react-parser';

import { registerService, getRegisterCountries } from "services/auth";
import { getMaintenanceVariables } from "services/apiConstants";

import {
  StyledButton,
  StyledWarningP,
  StyledContainer,
  StyledTitle,
  StyledSubmitDiv,
} from "pages/public/Register/Register.styled";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Footer from "components/Footer";
import PublicHeader from "components/PublicHeader";
import CustomModal from "components/Modal";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const Register = () => {

  let history = useHistory();
  const [countries, setCountries] = useState([]);
  const [buttonState, setButtonState] = useState(true);
  const [disableRegister, setDisableRegister] = useState(true);
  const [captchaMissing, setCaptchaMissing] = useState();
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const { t } = useTranslation();

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) setButtonState(false);
    else setButtonState(true);
  };

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getMaintenanceVariables();
        if (response && response[0]) {
          setDisableRegister(!response[0].keyValue);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAPI();
  }, []);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getRegisterCountries();
        setCountries(response.data);
      } catch (e) {
        setModal({
          ...modal,
          message: t('error_country_list'),
          type: "error",
          isOpen: true,
        });
      }
    }
    fetchAPI();

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js`,
      function () {
        console.log("Recaptcha loaded.");
      }
    );
  }, []);

  const [modal, setModal] = React.useState({
    isOpen: false,
    message: "",
    type: "default",
  });

  const onHideModal = () => {
    setModal({ ...modal, isOpen: false });
    if(modal.type === 'success')
       history.push('/home');
  };

  const submitData = async (
    captchaToken,
    name,
    lastName,
    email,
    password,
    organization,
    countryId
  ) => {
    try {
     await registerService(
      captchaToken,
        name,
        lastName,
        email,
        password,
        organization,
        countryId
      );
      setModal({
        ...modal,
        message: t('Registration_confirmation'),
        type: "success",
        isOpen: true,
      });
    } catch (error) {
      setModal({
        ...modal,
        message:
          "Registration failed. E-mail already used. Please use another.",
        type: "error",
        isOpen: true,
      });
    }
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <PublicHeader />

      <StyledTitle> {t("Registration_sign_up")} </StyledTitle>
      <StyledContainer>
      {modal.isOpen && (
        <CustomModal
          show={modal.isOpen}
          onHide={onHideModal}
          message={modal.message}
          type={modal.type}
        />
      )}
        <Formik
          initialValues={{
            name: "",
            lastName: "",
            email: "",
            organization: "",
            countryId: "",
            password: "",
            cpass: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);

            window.grecaptcha.ready(() => {
              var captchaToken = window.grecaptcha.getResponse();

              if (captchaToken.length == 0) {
                setCaptchaMissing("Captcha not verified");
              } else {
                submitData(
                  captchaToken,
                  values.name,
                  values.lastName,
                  values.email,
                  values.password,
                  values.organization,
                  values.countryId
                );
              }
              setSubmitting(false);
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form className="mx-auto" onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{t("F1_S2_Entity_name")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      className={touched.name && errors.name ? "error" : null}
                    />
                    {touched.name && errors.name ? (
                      <StyledWarningP>{errors.name}</StyledWarningP>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group>
                    <Form.Label>{t("F1_S2_Last_Name")} </Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      className={
                        touched.lastName && errors.lastName ? "error" : null
                      }
                    />
                    {touched.lastName && errors.lastName ? (
                      <StyledWarningP>{errors.lastName}</StyledWarningP>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{t("F1_S2_Email")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className={touched.email && errors.email ? "error" : null}
                    />
                    {touched.email && errors.email ? (
                      <StyledWarningP>{errors.email}</StyledWarningP>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group>
                    <Form.Label>{t("Registration_organization")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="organization"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.organization}
                      className={
                        touched.organization && errors.organization
                          ? "error"
                          : null
                      }
                    />
                    {touched.organization && errors.organization ? (
                      <StyledWarningP>{errors.organization}</StyledWarningP>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{t("F1_S2_Country")}</Form.Label>
                    <Form.Control
                      name="countryId"
                      as="select"
                      onChange={handleChange}
                      value={values.countryId}
                    >
                      <option value="">{t('Placeholder_select')}</option>
                      {countries &&
                        countries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {t(country.name)}
                          </option>
                        ))}
                    </Form.Control>
                    {touched.countryId && errors.countryId ? (
                      <StyledWarningP>{errors.countryId}</StyledWarningP>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>{t("Registration_password")}</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {touched.password && errors.password ? (
                      <StyledWarningP>{errors.password}</StyledWarningP>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group>
                    <Form.Label>
                      {t("Registration_confirm_password")}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="cpass"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cpass}
                    />
                    {touched.cpass && errors.cpass ? (
                      <StyledWarningP>{errors.cpass}</StyledWarningP>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              <div style={{ marginBottom: "12px", marginTop: "10px" }}>
                <Form.Check
                  name="termsAndConditions"
                  type="checkbox"
                  onChange={handleChangeCheckbox}
                  label={htmlParser(t("Registration_checkbox_agree_terms"))}
                />
              </div>

              <Row>
                <Col></Col>
                <Col>
                  <div
                    class="g-recaptcha"
                    data-sitekey={RECAPTCHA_SITE_KEY}
                  ></div>
                  {captchaMissing ? (
                    <StyledWarningP>{captchaMissing}</StyledWarningP>
                  ) : null}
                </Col>
                <Col></Col>
              </Row> 

              <StyledSubmitDiv>
                <StyledButton
                  type="submit"
                  disabled={isSubmitting || buttonState || disableRegister}
                >
                  {t("Registration_sign_up")}
                </StyledButton>
              </StyledSubmitDiv>
            </Form>
          )}
        </Formik>
      </StyledContainer>
      <Footer public={true} />
    </div>
  );
};

export default Register;
