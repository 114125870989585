import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { ModalFooter, FooterLeft, FooterCenter, FooterRight, StyledModalBody, StyledGreenP } from './F4ADialog.styled';
import { PrimaryButton } from 'components/Buttons';
import { formInitialValues } from '../Section15.initialValues';
import { useTranslation } from 'react-i18next';

const F4ADialog = ({ values, displayDialog, onCloseModal, initiative }) => {

  const { t } = useTranslation();
  const [currentValues, setCurrentValues] = useState(formInitialValues);

  const swapValue = (obj) => {
    Object.keys(obj).forEach(key => {
      if (!obj[key]) {
        obj[key] = ' - ';
      }
    });
  };
  
  useEffect(() => {
    const formValues = { ...values };
    swapValue(formValues);
    setCurrentValues(formValues);
  }, [values]);

  return (
    <Modal show={displayDialog} onHide={onCloseModal} size="lg" animation={false}>
      {currentValues.user &&
        <Modal.Header>
          <h5>{t('Assessment_by')+" "+currentValues.user.organization+", "+currentValues.user.name+" "+currentValues.user.lastName+" - "+t('Initiative')+" "+initiative} </h5>
        </Modal.Header>
      }
      <StyledModalBody>
        <StyledGreenP> {t('Received_on')+" "+ (currentValues.statusTimestamp ? currentValues.statusTimestamp.split('T')[0] : ' - ')}  </StyledGreenP>
        <br />
        <br />
        <StyledGreenP>{t('F2_assess_criteria5')}</StyledGreenP>
        <p>
          <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.quality}</span>
        </p>
        <StyledGreenP>{t('F2_assess_criteria4')}</StyledGreenP>
        <p>
          <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.theme}</span>
        </p>
        <StyledGreenP>{t('F2_assess_criteria7')}</StyledGreenP>
        <p>
          <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.participants}</span>
        </p>
        <StyledGreenP>{t('F2_assess_criteria2')}</StyledGreenP>
        <p>
          <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.triangular}</span>
        </p>
        <StyledGreenP>{t('F2_assess_criteria1')}</StyledGreenP>
        <p>
          <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.alignment}</span>
        </p>
        <StyledGreenP>{t('F2_assess_criteria3')}</StyledGreenP>
        <p>
          <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.euPriorities}</span>
        </p>
        <StyledGreenP>{t('F2_assess_criteria6')}</StyledGreenP>
        <p>
          <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.coherence}</span>
        </p>
        <StyledGreenP>{t('F2_assess_criteria8')}</StyledGreenP>
        <p>
          <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.opportunity}</span>
        </p>
        <StyledGreenP>{t('F2_overall_asssessment')}</StyledGreenP>
        <p>
          <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.assessment}</span>
        </p>
      </StyledModalBody>
      <ModalFooter>
        <FooterLeft />
        <FooterCenter>
          <PrimaryButton onClick={onCloseModal}>{t('Button_close')}</PrimaryButton>
        </FooterCenter>
        <FooterRight />
      </ModalFooter>
    </Modal>
  )
}

F4ADialog.propTypes = {
  values: Proptypes.object,
  displayDialog: Proptypes.bool,
  onCloseModal: Proptypes.func,
  initiative: Proptypes.string,
};

export default F4ADialog;
