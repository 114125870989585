import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FakeAccordionItem from '../../FakeAccordionItem';
import { SeparatorDotted } from 'components/CommonStyled';
import TextValue from 'components/TextValue';
import Form from 'react-bootstrap/Form';
import { StyledFormLabel, StyledCheck } from 'components/Forms/Form5/Section7/ConsultantsForm/ConsultantsForm.styled';
import { StyledTitle } from '../CommonStyles';

const Section7Consultants = ({ activity, data }) => {
  const {t} = useTranslation();
  const [formValues, setFormValues] = useState([]);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data]);

  return (
    <div>
      <StyledTitle style={{marginBottom: '30px'}}> {t('F1_S7_Accordion')} - {activity ? activity.name : ''} - {t('F3_S7_Subtitle_Consultants')} </StyledTitle>

      {(formValues && formValues.map((consultant, key) => (
        <React.Fragment  key={key}>
          <FakeAccordionItem title={t('F3_S7_Accordion_product')+" "+(key+1)} >
            <div style={{marginLeft: '50px'}}>
              <StyledFormLabel xs={4}>{t('F3_S7_Consultant_qualifications')}:</StyledFormLabel>
              <TextValue value={consultant.skills} />

              <StyledFormLabel xs={4}>{t('F3_S7_Consultant_general_experience')}:</StyledFormLabel>
              <TextValue value={consultant.generalExp} />

              <StyledFormLabel xs={4}>{t('F3_S7_Consultant_specific_experience')}:</StyledFormLabel>
              <TextValue value={consultant.specificExp} />

              <StyledFormLabel xs={4}>{t('F3_S7_Consultant_requires_translation')}:</StyledFormLabel>
              <TextValue value={consultant.interpretation} />

              <StyledCheck>
                <Form.Group>
                  <Form.Check 
                    style={{textAlign: 'justify'}} 
                    type="checkbox" 
                    checked={consultant.consultantsConfirmed }
                    label={t('F5_S7_Consultants_confirmation_check')} 
                  />
                </Form.Group>
              </StyledCheck>
            </div>
          </FakeAccordionItem>
          <SeparatorDotted />
        </React.Fragment>
      )))}
    </div>
  )

}

export default Section7Consultants;
