import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'

export const RadioLabel = styled.label`
  margin-right: 60px;
`;

export const NewMessagesTableContainer = styled.div`
  margin-top: 10px;
  margin-right: 25px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const StyledGreenFormLabel = styled(Form.Label)`
  ${({ theme }) => css`
    color: ${theme.brandColors.green};
  `}
  font-weight: 500;
`;

export const StyledCustomLink = styled.a`
  text-decoration: "underline";
  cursor: pointer;
`;

export const StyledUploadButton = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  font-weight: 600;
  text-decoration: underline;
`;

export const StyledIntroParagraph = styled.p`
  text-align: justify;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #6f8d9d;
  font-weight: 500;
  line-height: 110%;
`;

export const GreySubtitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.brandColors.gray1};
    margin-top: 50px;
    font-size: 20px;
    font-weight: 700;
    text-align: justify;
    line-height: 1.3;
  `}
`;
