import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import _isNil from "lodash/isNil";
import _sumBy from "lodash/sumBy";
import BeneficiaryFormModal from "./BeneficiaryFormModal";
import { validationSchema } from "./Section10.validations";
import { observer } from "mobx-react-lite";
import { useRootStore } from "stores";
import { useParams } from "react-router-dom";
import { formInitialValues } from "./Section10.initialValues";
import { useTranslation } from "react-i18next";
import { saveFormSection } from "services/initiative";
import TextValue from "components/TextValue";
import { f5statusNamesConst, roleNamesConst } from "constants/appConstants";
import Accordion from "react-bootstrap/Accordion";
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from "components/CustomAccordion";
import { deleteBeneficiary } from "services/initiative";
import DirectBeneficiaryForm from "components/Forms/Form5/Section10/DirectBeneficiaryForm";
import { useConstantsForm3 } from "customHooks/useConstantsForm3";
import ConfirmCloseModal from "components/ConfirmCloseModal";
import { Link } from "react-router-dom";
import Comments from "../Comments";
import Form from "react-bootstrap/Form";
import FormLayout from "../../FormLayout";
import FormContainer from "components/FormContainer";
import { RequiredMark } from "components/CommonStyled";
import { PrimaryButton, ErrorButton, SuccessButton } from "components/Buttons";
import {
  StyledFormLabel,
  StyledDivWrapper,
  StyledTitle,
  StyledSubtitle,
  StyledButtonLink,
  RoundedAddDiv,
  DivButtonWrapper,
} from "./Section10.styled";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { isWindows2021 } from "helpers/isWindow2021Helper";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const Section10 = () => {
  const { t } = useTranslation();
  const { id: initiativeId, formId, sectionId } = useParams();
  const { formStore, initiativeStore, authStore } = useRootStore();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [modalForm, setModalForm] = useState({ isShow: false, content: null });
  const [nonEditable, setNonEditable] = useState(false);
  const [nonEditableGB, setNonEditableGB] = useState(false);
  const [saved, setSaved] = useState(false);
  const [disabledComments, setDisabledComments] = useState(true);
  const [beneficiariesList, setBeneficiariesList] = useState([]);
  const [dataModal, setDataModal] = useState({ isShow: false, content: null });
  const { la_ca_eu_countries } = useConstantsForm3();
  const [modalRemove, setModalRemove] = useState({
    isShow: false,
    id: null,
    key: null,
  });
  const currentRole = authStore.roles[0];
  const isPublic = currentRole === roleNamesConst.PUBLIC;
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM;
  const isAtke = currentRole === roleNamesConst.ATKE;
  const isIntpa = currentRole === roleNamesConst.INTPA;
  const isEud = currentRole === roleNamesConst.EUD;
  const [isF7, setIsF7] = useState(false);
  const [window2021, setWindow2021] = useState(true)

  const isApplicantEditable = isPublic && formStore.formStatus === f5statusNamesConst.DRAFT;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  useEffect(() => {
    setNonEditable(
      (initiativeStore.formStateName !== f5statusNamesConst.DRAFT ||
        initiativeStore.role !== roleNamesConst.APPLICANT)
    );
    setNonEditableGB(
      (initiativeStore.formStateName !== f5statusNamesConst.DRAFT ||
        initiativeStore.role !== roleNamesConst.APPLICANT) &&
        (initiativeStore.formStateName === f5statusNamesConst.FINALIZED ||
          initiativeStore.role !== roleNamesConst.ATKE)
    );

  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (initiativeStore.code) {
      setWindow2021(isWindows2021(initiativeStore.code))
    }
  }, [initiativeStore.code])

  useEffect(() => {
    if (initiativeStore.formStateName)
      setIsF7(
        (isIntpa || isEud) &&
          initiativeStore.formStateName !== f5statusNamesConst.DRAFT &&
          initiativeStore.formStateName !== f5statusNamesConst.SENT
      );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {
        ...formInitialValues,
        ...formStore.formSectionData[0],
        benEntities: formStore.formSectionData?.[1],
        directBen: formStore.formSectionData?.[2],
      };
      setInitialValues(formSectionData);
      setBeneficiariesList(formStore.formSectionData[0].benList);
    }
  }, [formStore.formSectionData]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFormSection(
        initiativeId,
        formId,
        sectionId,
        values
      );
      formStore.setSectionStatus(sectionId, complete);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteBeneficiary(initiativeId, id);
      setBeneficiariesList(response.data.benList);
      setModalRemove({ isShow: false, id: null, key: null });
    } catch (error) {
      console.log(error);
    }
  };

  const activities = (activities) => {
    return activities.map((activity) => (
      <Form.Group style={{ display: "flex" }}>
        <StyledFormLabel style={{ margin: "0px" }}>
          {activity.activityName}:
        </StyledFormLabel>
        <TextValue value={activity.assessment} />
      </Form.Group>
    ));
  };

  const handleSaved = (value) => {
    setSaved(value);
  };

  const handleButton = (value) => {
    setDisabledComments(value);
  };

  const getQuantityAllCountries = (beneficiary) => {
    return _isNil(beneficiary.quantityAllCountries)
      ? _sumBy(beneficiary.answersF5S10BenCountryList, "quantity")
      : beneficiary.quantityAllCountries;
  };

  return (
    <FormLayout>
      <FormContainer
        number={10}
        title={t("F3_S10_title")}
        formId="form5section10"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
        showButtonClarif={!disabledComments}
      >
        <StyledDivWrapper>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              dirty,
            }) => {
              return (
                <form onSubmit={handleSubmit} id="form5section10">
                  {dirty && setSaved(false)}

                  {modalForm.isShow && (
                    <BeneficiaryFormModal
                      show={modalForm.isShow}
                      onHide={() => {
                        setModalForm({ isShow: false, content: null });
                      }}
                      content={modalForm.content}
                      onSetBenList={setBeneficiariesList}
                    />
                  )}

                  {dataModal.isShow && (
                    <DirectBeneficiaryForm
                      countries={la_ca_eu_countries}
                      show={dataModal.isShow}
                      content={dataModal.content}
                      initiativeId={initiativeId}
                      onHide={() => {
                        setDataModal({
                          isShow: false,
                          content: { id: null, list: null },
                        });
                      }}
                      onSetBenList={setBeneficiariesList}
                    />
                  )}

                  {modalRemove.isShow && (
                    <ConfirmCloseModal
                      show={modalRemove.isShow}
                      text={t('remove_beneficiary', { name: modalRemove.key + 1 })}
                      handleActionOnConfirm={() => {
                        handleDelete(modalRemove.id);
                      }}
                      handleCloseConfirm={() => {
                        setModalRemove({ isShow: false, id: null, key: null });
                      }}
                    />
                  )}

                  <StyledTitle style={{ marginBottom: "30px" }}>
                    <Link
                      to={`${authStore.getBaseRouteByRole()}/initiative/${initiativeId}/form/${
                        isPublic ? "3" : "4"
                      }/section/10`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("F5_Subtitle_Description_from_proposal")}
                    </Link>
                  </StyledTitle>

                  <StyledTitle style={{ marginBottom: "30px" }}>
                    {t("F5_Subtitle_Assessment_TCI_Implementation")}
                  </StyledTitle>

                  {window2021 ? (
                    <>
                      <StyledSubtitle>
                        {t("F5_S7_Activity_assessment_6")}:
                      </StyledSubtitle>

                      <div style={{ marginTop: "20px" }}>
                        {formStore.formSectionData
                          ? activities(formStore.formSectionData[1])
                          : "-"}
                      </div>

                      <Form.Group style={{ marginTop: "25px" }}>
                        <StyledFormLabel>
                          {t("F5_Overview_of_TCI")}
                          <RequiredMark>*</RequiredMark>:
                        </StyledFormLabel>
                        <Form.Control
                          name="overviewRelationship"
                          as="textarea"
                          rows={6}
                          style={{ resize: "none" }}
                          placeholder={t("Maximum_1300")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.overviewRelationship}
                          disabled={nonEditable}
                          className={
                            touched.overviewRelationship &&
                            errors.overviewRelationship
                              ? "error"
                              : null
                          }
                        />
                        <FormErrorMessage
                          touched={touched.overviewRelationship}
                          errors={errors.overviewRelationship}
                        />
                      </Form.Group>

                      <StyledSubtitle>
                        {t("F5_S7_Activity_assessment_7")}:
                      </StyledSubtitle>

                      <div style={{ marginTop: "20px" }}>
                        {formStore.formSectionData
                          ? activities(formStore.formSectionData[2])
                          : "-"}
                      </div>

                      <Form.Group style={{ marginTop: "25px" }}>
                        <StyledFormLabel>
                          {t("F5_Overview_of_TCI")}
                          <RequiredMark>*</RequiredMark>:
                        </StyledFormLabel>
                        <Form.Control
                          name="overviewParticipation"
                          as="textarea"
                          rows={6}
                          style={{ resize: "none" }}
                          placeholder={t("Maximum_1300")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.overviewParticipation}
                          disabled={nonEditable}
                          className={
                            touched.overviewParticipation &&
                            errors.overviewParticipation
                              ? "error"
                              : null
                          }
                        />
                        <FormErrorMessage
                          touched={touched.overviewParticipation}
                          errors={errors.overviewParticipation}
                        />
                      </Form.Group>

                      <StyledSubtitle>
                        {t("F5_S7_Activity_assessment_8")}:
                      </StyledSubtitle>

                      <div style={{ marginTop: "20px" }}>
                        {formStore.formSectionData
                          ? activities(formStore.formSectionData[3])
                          : "-"}
                      </div>

                      <Form.Group style={{ marginTop: "25px" }}>
                        <StyledFormLabel>
                          {t("F5_Overview_of_TCI")}
                          <RequiredMark>*</RequiredMark>:
                        </StyledFormLabel>
                        <Form.Control
                          name="overviewKnowledge"
                          as="textarea"
                          rows={6}
                          style={{ resize: "none" }}
                          placeholder={t("Maximum_1300")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.overviewKnowledge}
                          disabled={nonEditable}
                          className={
                            touched.overviewKnowledge && errors.overviewKnowledge
                              ? "error"
                              : null
                          }
                        />
                        <FormErrorMessage
                          touched={touched.overviewKnowledge}
                          errors={errors.overviewKnowledge}
                        />
                      </Form.Group>
                    </>
                  ) : null}

                  <Accordion style={{ marginBottom: "20px", marginLeft: '-20px' }}>
                    {
                      beneficiariesList?.map((beneficiary, key) => (
                        <AccordionItem key={key + 1}>
                          <AccordionItemHeader>
                            <AccordionTitle eventKey={key + 1}>
                              {t("F3_S10_Beneficiary") + " " + (key + 1)}
                              {' - '}
                              {t(
                                `beneficiary_type_${beneficiary.beneficiaryType.id}`
                              )}
                            </AccordionTitle>
                          </AccordionItemHeader>
                          <Accordion.Collapse eventKey={key + 1}>
                            <AccordionItemBody style={{marginLeft: '30px'}}>
                              <Form.Group style={{ display: "flex" }}>
                                <StyledFormLabel style={{ marginTop: "7px" }}>
                                  {t("F5_S10_type_of_beneficiary")}:
                                </StyledFormLabel>
                                <TextValue
                                  value={
                                    beneficiary.beneficiaryType
                                      ? t(
                                          `beneficiary_type_${beneficiary.beneficiaryType.id}`
                                        )
                                      : ""
                                  }
                                />
                              </Form.Group>

                              <Form.Group>
                                <Form.Label>
                                  {t("F3_S10_Description")}:
                                </Form.Label>
                                <Form.Control
                                  name={`benList.${key}.beneficiaryDescription`}
                                  as="textarea"
                                  value={beneficiary.beneficiaryDescription}
                                  style={{ resize: "none" }}
                                  rows={5}
                                  disabled
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Form.Group>

                              <Form.Group style={{ display: "flex" }}>
                                <StyledFormLabel style={{ marginTop: "7px" }}>
                                  {t("F5_S10_beneficiary_quantification")}:
                                </StyledFormLabel>
                                <TextValue
                                  value={beneficiary.beneficiaryQuantification}
                                />
                              </Form.Group>

                              <Form.Group style={{ display: "flex" }}>
                                <StyledFormLabel style={{ marginTop: "10px" }}>
                                  {t("F5_S10_quantity_all_countries")}:
                                </StyledFormLabel>
                                <TextValue
                                  value={getQuantityAllCountries(beneficiary)}
                                />
                                <StyledButtonLink
                                  type="button"
                                  onClick={() => {
                                    setDataModal({
                                      isShow: true,
                                      content: {
                                        id: beneficiary.id,
                                        list:
                                          beneficiary.answersF5S10BenCountryList,
                                      },
                                    });
                                  }}
                                  disabled={nonEditableGB}
                                >
                                  <DivButtonWrapper disabled={nonEditableGB}>
                                    <RoundedAddDiv disabled={nonEditableGB}>
                                      +
                                    </RoundedAddDiv>
                                    {t("F3_S10_Add_direct_beneficiaries")}
                                  </DivButtonWrapper>
                                </StyledButtonLink>
                              </Form.Group>

                              {beneficiary.answersF5S10BenCountryList ? (
                                <>
                                  {beneficiary.answersF5S10BenCountryList.map(
                                    (item, key) => (
                                      <Row
                                        key={key}
                                        style={{
                                          marginLeft: "20px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Col xs lg="2">
                                          <StyledFormLabel>
                                            {t(item?.country?.name)}:
                                          </StyledFormLabel>
                                        </Col>
                                        <Col xs lg="2">
                                          <TextValue
                                            style={{ margin: "0px" }}
                                            value={item.quantity}
                                          />
                                        </Col>
                                      </Row>
                                    )
                                  )}
                                </>
                              ) : null}

                              <Form.Group style={{marginTop: '25px'}}>
                                <Form.Label>
                                  {t("F3_S10_Justification")}:
                                </Form.Label>
                                <Form.Control
                                  name={`benList.${key}.beneficiaryJustification`}
                                  as="textarea"
                                  value={beneficiary.beneficiaryJustification}
                                  style={{ resize: "none" }}
                                  rows={5}
                                  disabled
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Form.Group>

                              <Form.Group>
                                <Form.Label>
                                  {t("F3_S10_Prospects_for_harnessing")}:
                                </Form.Label>
                                <Form.Control
                                  name={`benList.${key}.beneficiaryProspect`}
                                  as="textarea"
                                  value={beneficiary.beneficiaryProspect}
                                  style={{ resize: "none" }}
                                  rows={5}
                                  disabled
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Form.Group>

                              {(!beneficiary.fromF3 || isApplicantEditable) && (
                                <>
                                  <div style={{ display: "flex" }}>
                                    <SuccessButton
                                      style={{
                                        marginRight: "30px",
                                        paddingLeft: "46px",
                                        paddingRight: "46px",
                                      }}
                                      onClick={() => {
                                        setModalForm({
                                          isShow: true,
                                          content: beneficiary,
                                        });
                                      }}
                                      disabled={nonEditableGB}
                                    >
                                      {t("Button_edit")}
                                    </SuccessButton>
                                    {!isApplicantEditable ? (
                                      <ErrorButton
                                        style={{
                                          paddingLeft: "40px",
                                          paddingRight: "40px",
                                        }}
                                        onClick={() => {
                                          setModalRemove({
                                            isShow: true,
                                            id: beneficiary.id,
                                            key: key,
                                          });
                                        }}
                                        disabled={nonEditableGB}
                                      >
                                        {t("Button_delete")}
                                      </ErrorButton>
                                    ) : null}
                                  </div>
                                </>
                              )}
                            </AccordionItemBody>
                          </Accordion.Collapse>
                        </AccordionItem>
                      ))}
                  </Accordion>

                  <PrimaryButton
                    style={{ marginRight: "30px" }}
                    onClick={() => {
                      setModalForm({ isShow: true, content: null });
                    }}
                    disabled={nonEditableGB}
                  >
                    {t("F3_S10_Beneficiary_button_add")}
                  </PrimaryButton>

                  <Form.Group style={{ marginTop: "30px" }}>
                    <StyledFormLabel>
                      {t("F5_S10_Justification_beneficiaries")}:
                    </StyledFormLabel>
                    <Form.Control
                      name="justificationBeneficiaries"
                      as="textarea"
                      rows={6}
                      style={{ resize: "none" }}
                      placeholder={t("Maximum_1300")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.justificationBeneficiaries}
                      disabled={nonEditable}
                      className={
                        touched.justificationBeneficiaries &&
                        errors.justificationBeneficiaries
                          ? "error"
                          : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.justificationBeneficiaries}
                      errors={errors.justificationBeneficiaries}
                    />
                  </Form.Group>

                  <Form.Group style={{ marginTop: "25px" }}>
                    <StyledFormLabel>
                      {t("F5_S10_Explain_deviation")}
                      <RequiredMark>*</RequiredMark>:
                    </StyledFormLabel>
                    <Form.Control
                      name="explainDerivation"
                      as="textarea"
                      rows={6}
                      style={{ resize: "none" }}
                      placeholder={t("Maximum_1300")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.explainDerivation}
                      disabled={nonEditable}
                      className={
                        touched.explainDerivation && errors.explainDerivation
                          ? "error"
                          : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.explainDerivation}
                      errors={errors.explainDerivation}
                    />
                  </Form.Group>

                  <Form.Group style={{ marginTop: "25px" }}>
                    <StyledFormLabel>
                      {t("F5_S10_Explain_other_data")}:
                    </StyledFormLabel>
                    <Form.Control
                      name="explainData"
                      as="textarea"
                      rows={6}
                      style={{ resize: "none" }}
                      placeholder={t("Maximum_1300")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.explainData}
                      disabled={nonEditable}
                      className={
                        touched.explainData && errors.explainData ? "error" : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.explainData}
                      errors={errors.explainData}
                    />
                  </Form.Group>

                  <Form.Group style={{ marginTop: "25px" }}>
                    <StyledFormLabel>
                      {t("F5_S10_Beneficiary_strengthening")}
                      <RequiredMark>*</RequiredMark>:
                    </StyledFormLabel>
                    <Form.Control
                      name="beneficiaryStrengthening"
                      as="textarea"
                      rows={5}
                      style={{ resize: "none" }}
                      placeholder={t("Maximum_6500")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.beneficiaryStrengthening}
                      disabled={nonEditable}
                      className={
                        touched.beneficiaryStrengthening && errors.beneficiaryStrengthening ? "error" : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.beneficiaryStrengthening}
                      errors={errors.beneficiaryStrengthening}
                    />
                  </Form.Group>

                  <Form.Group style={{ marginTop: "25px" }}>
                    <StyledFormLabel>
                      {t("F5_S10_Additional_benefits")}:
                    </StyledFormLabel>
                    <Form.Control
                      name="additionalBenefits"
                      as="textarea"
                      rows={5}
                      style={{ resize: "none" }}
                      placeholder={t("Maximum_6500")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.additionalBenefits}
                      disabled={nonEditable}
                      className={
                        touched.additionalBenefits && errors.additionalBenefits ? "error" : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.additionalBenefits}
                      errors={errors.additionalBenefits}
                    />
                  </Form.Group>

                </form>
              )
            }}
          </Formik>

          {(isAtke || isConsortium || isF7) && (
            <Comments onSaved={handleSaved} onButton={handleButton} />
          )}
        </StyledDivWrapper>
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section10);
