import React, { useState, useEffect } from 'react'
import Header1 from '../components/Header1'
import Table2 from '../components/Table2'
import Table3 from '../components/Table3'
import { getFundExecutionSummary } from 'services/fundsStatistics'
import Row from 'react-bootstrap/Row'
import { StyledDiv, StyledRow, StyledRow2 } from '../../CommonStyled/Tabs.styled'
import { getExecutionSummary } from 'services/initiative'
import htmlParser from 'html-react-parser'
import { useTranslation } from 'react-i18next'

const ExecutionSummary = () => {
  const { t } = useTranslation()

  const [table1Data, setTable1Data] = useState(null)
  const [table2Data, setTable2Data] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const getTable1Data = async (param) => {
    setWaiting(true)
    try {
      const response = await getExecutionSummary(param)
      setTable1Data(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  const getTable2Data = async (param) => {
    setWaiting(true)
    try {
      const response = await getFundExecutionSummary(param)
      setTable2Data(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  useEffect(() => {
    getTable1Data()
    getTable2Data()
  }, [])

  return (
    <StyledDiv>
      <Row>
        <Header1
          data={table1Data}
          data2={table2Data}
          waiting={waiting}
        />
      </Row>
      <StyledRow style={{ justifyContent: 'flex-start' }}>
        <Table2 data={table1Data} waiting={waiting} />
      </StyledRow>
      <StyledRow style={{ justifyContent: 'flex-start', marginBottom: '50px' }}>
        <Table3 data={table2Data} waiting={waiting} />
      </StyledRow>
      <StyledRow2 >{htmlParser(t('Statistics_footnote_7'))}</StyledRow2>
      <StyledRow2>{htmlParser(t('Statistics_footnote_10'))}</StyledRow2>
    </StyledDiv>
  )
}

export default ExecutionSummary
