import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { formInitialValues } from './Section4.initialValues';
import isEmpty from 'lodash/isEmpty';

import FormLayout from '../../FormLayout';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import FormContainer from 'components/FormContainer';
import { StyledIntroParagraph, StyledSeparator } from './Section4.styled';
import OtherEntitiesForm from 'components/Forms/Form2/Section4/OtherEntitiesForm';
import RequestClarification from '../RequestClarification';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { arrayOrderHelper } from 'helpers/arrayOrderHelper';

const Section4 = () => {
  const { formStore } = useRootStore();
  const [saved, setSaved] = useState(false);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [nonEditable, setNonEditable] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = [...formStore.formSectionData];
      setFormValues(arrayOrderHelper(formSectionData));
    }
    formStore.setCurrentSection(4);
  }, [formStore.formSectionData]);

  const handleSaved = value => {
    setSaved(value);
  };

  const handleButton = value => {
    setNonEditable(value);
  };

  return (
    <FormLayout>
      <FormContainer
        number={4}
        title={t('F1_S4_title')}
        formId="form2section4"
        showSavedMessageF2={saved}
        showButtonClarif={!nonEditable}
        showSavedMessage={false}
        showFooter={false}
        buttonText="SAVE CLARIFICATION REQUEST"
      >
        <StyledIntroParagraph>{htmlParser(t('F1_S4_intro'))}</StyledIntroParagraph>
        <Accordion>
          {formValues &&
            formValues.map((entity, key) => (
              <AccordionItem key={key + 1}>
                <AccordionItemHeader>
                  <AccordionTitle eventKey={key + 1}>{`${t('F1_S4_Accordion')} ${key + 1} ${
                    isEmpty(entity.entityName) ? '' : `- ${entity.entityName}`
                  }`}</AccordionTitle>
                </AccordionItemHeader>
                <Accordion.Collapse eventKey={key + 1}>
                  <AccordionItemBody>
                    <OtherEntitiesForm entityIndex={key} data={entity} />
                  </AccordionItemBody>
                </Accordion.Collapse>
              </AccordionItem>
            ))}
        </Accordion>

        <StyledSeparator />
        <RequestClarification onSaved={handleSaved} onButton={handleButton} />
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section4);
