export const formInitialValues = {
  entityName:"", 
  acronym:"", 
  entityType:{},      
  role:"0",
  optionA: false,
  optionB: false,
  optionC: false,
  optionD: false,
  other: "",
  address:"",
  cityName:"",
  stateName:"",
  country:{},
  web:"",
  ownerIsSigner: false,
  firstNameSigner:"",
  lastNameSigner:"",
  positionSigner:"",
  emailSigner:"",
  phoneSigner:"",
  firstNameTciApplicant:"",
  lastNameTciApplicant:"",
  positionResp:"",
  emailResp:"",
  phoneResp:"",
  triangulardoc:{},
  triangulardocUploaded: false
};
