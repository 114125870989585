import React from 'react'
import i18n from 'i18n'
import {
  Th2,
  Td,
  Th3Pointer,
  Th1Pointer,
} from '../../CommonStyled/Components.styled'

import _orderBy from 'lodash/orderBy'

import { useRootStore } from 'stores'

import {
  orderByInitiativeCode,
  orderBySring,
} from 'pages/statistics/CommonHelpers/helpers'
import { INITIATIVE_TITLE_ROW, FUNDS_TITLE_ROW } from 'constants/appConstants'
import { renderCodeCell } from 'pages/statistics/Activities/helpers/helpers'

export const renderODSOptions = () => (
  <>
    <option key={0} value={0}>
      {i18n.t('ALL')}
    </option>
    <option key={1} value={1}>
      {i18n.t('SDG_Primary')}
    </option>
    <option key={2} value={2}>
      {i18n.t('SDG_Secondary')}
    </option>
  </>
)

export const renderTypeODSOptions = () => (
  <>
    <option key={0} value={''}>
      {i18n.t('ALL')}
    </option>
    <option key={1} value={'primary'}>
      {i18n.t('primary')}
    </option>
    <option key={2} value={'secondary'}>
      {i18n.t('secondary')}
    </option>
    <option key={3} value={'adelante'}>
      {i18n.t('ADELANTE_SDG')}
    </option>
  </>
)

export const getParamObject = (filter) => {
  const param = { odsP: true, odsS: true }
  if (filter != 0) {
    if (filter == 1) {
      param.odsS = false
    } else {
      param.odsP = false
    }
  }
  return param
}

export const getParamObjectDetail = (year, odsP, odsS) => {
  let params = {}
  if (year != 0) {
    params.year = year
  }
  if (odsP != 0) {
    params.odsP = odsP
  }
  if (odsS != 0) {
    params.odsS = odsS
  }
  return params
}

const T1renderRow = (object, years) => {
  let row = []
  for (let i = 0; i < years.length; i++) {
    row.push(
      <Td>
        {object[years[i]] ? object[years[i]] : object[years[i]] == 0 ? 0 : '-'}
      </Td>,
    )
  }
  row.push(<Td>{object['total']}</Td>)
  return row
}

export const T1RenderRows = (rows, years) =>
  rows.map((row) => (
    <tr>
      <Th2>{i18n.t(`SDG_Goal${row.category}`)}</Th2>
      {T1renderRow(row, years)}
    </tr>
  ))

export const T5RenderRows = (rows, years) =>
  rows.map((row) => (
    <tr>
      <Th2>{i18n.t(`SDG_Goal${row.category}`)}</Th2>
      <Td>{row.total}</Td>
    </tr>
  ))


export const T2RenderRows = (rows) =>
  rows.map((row) => (
    <tr>
      <Td>{row?.code}</Td>
      <Td>{row?.name || ' - '}</Td>
      <Td>{row?.odsP}</Td>
      <Td>{row?.odsS}</Td>
    </tr>
  ))

export const T3RenderRows = (rows) => {
  const { authStore } = useRootStore()
  const baseRoute = authStore.getBaseRouteByRole()

  return rows.map((row) => (
    <tr>
      {renderCodeCell(baseRoute, row?.code, row?.initiativeId, row?.fundId, 11 )}
      <Td>{row?.name ? row.name : '-'}</Td>
      <Td>{row?.ods ? row.ods : '-'}</Td>
      <Td>{row?.odstarget ? row.odstarget : '-'}</Td>
      <Td>{row?.indicator ? row.indicator : '-'}</Td>
      <Td>{row?.odstype ? i18n.t(row.odstype) : '-'}</Td>
    </tr>
  ))
}

export const T4RenderRows = (rows, years) =>
  rows.map((row) => (
    <>
      {row.total === 0 ? null : (
        <tr>
          <Th2>{i18n.t(`SDG_Target${row.category}`)}</Th2>
          {T1renderRow(row, years)}
        </tr>
      )}
    </>
  ))

export const T6RenderRows = (rows, years) =>
  rows.map((row) => (
    <>
      {row.total === 0 ? null : (
        <tr>
          <Th2>{i18n.t(`SDG_Target${row.category}`)}</Th2>
          <Td>{row.total}</Td>
        </tr>
      )}
    </>
  ))

export const getInitiativesSummaryExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData.forEach((ods) => {
    data.push({
      label: i18n.t(`SDG_Goal${ods?.category}`),
      col1: ods['2021'],
      col2: ods['2022'],
      col3: ods['2023'],
      col4: ods['2024'],
      col5: ods['2021'] + ods['2022'] + ods['2023'] + ods['2024'],
    })
  })
  return data
}

export const getFundsInitiativesSummaryExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData.forEach((ods) => {
    data.push({
      label: i18n.t(`SDG_Goal${ods?.category}`),
      col1: ods['total'],
      col2: '',
      col3: '',
      col4: '',
      col5: '',
    })
  })
  return data
}


export const getInitiativesSummaryTargetExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData.forEach((ods) => {
    data.push({
      label: i18n.t(`SDG_Target${ods?.category}`),
      col1: ods['2021'],
      col2: ods['2022'],
      col3: ods['2023'],
      col4: ods['2024'],
      col5: ods['2021'] + ods['2022'] + ods['2023'] + ods['2024'],
    })
  })
  return data
}

export const getFundsInitiativesSummaryTargetExportValues = (exportData) => {
  let data = []
  data.push(FUNDS_TITLE_ROW)
  exportData.forEach((ods) => {
    data.push({
      label: i18n.t(`SDG_Target${ods?.category}`),
      col1: ods['total'],
      col2: '',
      col3: '',
      col4: '',
      col5: '',
    })
  })
  return data
}


export const getInitiativesGoalIndicatorsList = (exportData) => {
  let data = []
  exportData.forEach((item) => {
    data.push({
      col1: item?.code ? item.code : '-',
      col2: item?.name ? item.name : '-',
      col3: item?.ods ? item.ods : '-',
      col4: item?.odstarget ? item.odstarget : '-',
      col5: item?.indicator ? item.indicator : '-',
      col6: item?.odstype ? i18n.t(item.odstype) : '-',
    })
  })
  return data
}

export const getInitiativesDetailExportValues = (exportData) => {
  let data = []
  exportData.forEach((ods) => {
    data.push({
      code: ods.code,
      title: ods.name,
      odsp: i18n.t(`SDG_Goal${ods?.odsP}`),
      odss: i18n.t(`SDG_Goal${ods?.odsS}`),
    })
  })
  return data
}

export const orderDataByField = (data, field, setData, setAsc, asc) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'name':
      orderedData = orderBySring(data, field, asc)
      break
    case 'code':
      orderedData = orderByInitiativeCode(data, field, asc)
      break
    default:
      orderedData = _orderBy(data, [field], asc[field] ? ['asc'] : ['desc'])
      break
  }

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}

export const orderDataByField2 = (data, field, setData, setAsc, asc) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'name':
      orderedData = orderBySring(data, field, asc)
      break
    case 'code':
      orderedData = orderByInitiativeCode(data, field, asc)
      break
    case 'odstype':
      orderedData = _orderBy(
        data,
        [(field) => i18n.t(field)],
        asc[field] ? ['asc'] : ['desc'],
      )
    default:
      orderedData = _orderBy(data, [field], asc[field] ? ['asc'] : ['desc'])
      break
  }

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}

export const orderData = (data, field, setData, setAsc, asc) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  orderedData = _orderBy(
    data,
    [(item) => parseInt(item[field])],
    asc[field] ? ['asc'] : ['desc'],
  )

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}

export const renderHeaders2 = (years, handleOrderData) => (
  <>
    <Th3Pointer
      style={{ width: '65%' }}
      scope="col"
      onClick={() => handleOrderData('category')}
    >
      {i18n.t('Initiatives_target')} &#8645;
    </Th3Pointer>
    {years.map((year) => (
      <Th1Pointer scope="col" onClick={() => handleOrderData(year)}>
        {year} &#8645;
      </Th1Pointer>
    ))}
    <Th1Pointer scope="col" onClick={() => handleOrderData('total')}>
      {i18n.t('F1_S7_Total')} &#8645;
    </Th1Pointer>
  </>
)

export const renderHeaders3 = (years, handleOrderData) => (
  <>
    <Th3Pointer scope="col" onClick={() => handleOrderData('category')}>
      {i18n.t('Statistics_title_initiative_summary')} &#8645;
    </Th3Pointer>
    {years.map((year) => (
      <Th1Pointer scope="col" onClick={() => handleOrderData(year)}>
        {year} &#8645;
      </Th1Pointer>
    ))}
    <Th1Pointer scope="col" onClick={() => handleOrderData('total')}>
      {i18n.t('F1_S7_Total')} &#8645;
    </Th1Pointer>
  </>
)
