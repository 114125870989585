import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import { Table, Th1, Th1Pointer } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'

import { T1RenderRows } from '../helpers/helpers'
import { orderDataByField } from '../helpers/helpers'
import { useRootStore } from 'stores'

const Table1 = ({ data, waiting, setData }) => {
  const { t } = useTranslation()
  const { authStore } = useRootStore();

  const [ascendingOrder, setAscendingOrder] = useState({
    code: true,
    title: true,
    typeId: true,
    countryName: true,
    fromDate: true,
  })

  const baseRoute = authStore.getBaseRouteByRole();

  const handleOrderData = (field) => {
    orderDataByField(data, field, setData, setAscendingOrder, ascendingOrder)
  }

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data && !isEmpty(data) ? (
    <Table>
      <tr>
        <Th1Pointer onClick={() => handleOrderData('code')}>
          {t('Initiative_code')} &#8645;
        </Th1Pointer>
        <Th1Pointer onClick={() => handleOrderData('title')}>
          {t('F5_S7_Title_of_the_activity')} &#8645;
        </Th1Pointer>
        <Th1Pointer onClick={() => handleOrderData('countryName')}>
          {t('Country_host')} &#8645;
        </Th1Pointer>
        <Th1Pointer onClick={() => handleOrderData('countryRegion')}>
          {t('Region')} &#8645;
        </Th1Pointer>
        <Th1Pointer onClick={() => handleOrderData('eventFormat')}>
          {t('F1_S7_Format')} &#8645;
        </Th1Pointer>
        <Th1Pointer onClick={() => handleOrderData('typeId')}>
          {t('Type')} &#8645;
        </Th1Pointer>
        <Th1Pointer onClick={() => handleOrderData('fromDate')}>
          {t('desde')} &#8645;
        </Th1Pointer>
        <Th1>{t('hasta')}</Th1>
      </tr>
      {T1RenderRows(baseRoute, data)}
    </Table>
  ) : (
    t('No_data_available_2')
  )
}

export default Table1
