import React, { useEffect, useState } from 'react';
import { formInitialValues } from './ExpostReportForm.initialValues';
import { useTranslation } from 'react-i18next';
import { getF8ById, modifyF8 } from 'services/initiative';
import { Formik } from 'formik';
import { validationSchema } from './ExpostReportForm.validations';
import { useParams } from 'react-router-dom';
import TextValue from 'components/TextValue';
import htmlParser from 'html-react-parser'
import Footer from './Footer';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import PageTitle from 'components/PageTitle';
import { FormFielErrorMessage } from 'components/CommonStyled';
import { StyledDivWrapper } from 'components/FormContainer/FormContainer.styled';
import { StyledQuestions, StyledLabel } from '../../manager/ExpostReportForm/ExpostReportForm.styles';
import { StyledSendButton as StyledSaveButton } from 'components/Buttons';
import { SeparatorDotted } from 'components/CommonStyled';

const ExpostReporting = () => {
  const { t } = useTranslation();
  const [nonEditable, setNonEditable] = useState(false);
  const [initiativeCode, setInitiativeCode] = useState("");
  const [formData, setFormData] = useState(formInitialValues);
  const [saved, setSaved] = useState(false);
  const { formId } = useParams();

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getF8ById(formId);
        setFormData(response);
        setNonEditable(response.form8.status.stateEnum === 'DONE' || response.form8.status.stateEnum === 'CANCELED');
      } catch (error) {
        console.log(error)
      } 
    };
   
    fetchAPI();
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let initiative = params.get('initiative');
    setInitiativeCode(initiative);
  }, [])

  const onSubmit = async (values, {resetForm}) => {
    try {
      await modifyF8(formId, values);
      resetForm({values});
      setSaved(true);
    } catch (error) {
      setSaved(false);
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid
      ? onSubmit(values, { resetForm })
      : alert(t('not_saved_alert'));
  }

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);  
  },[saved])

  return (
    <>
      <PageTitle title={`${t('menu_admin_13')} #${initiativeCode}`} />
       <div style={{paddingRight: '300px'}}>
         <StyledDivWrapper>
          {!nonEditable && (
              <Row style={{justifyContent: 'flex-end', marginTop: '10px', marginBottom: '20px', marginRight: '0px'}}>
                  <StyledSaveButton type='submit' form="form8" disabled={saved}>
                    {saved? t('Button_saved') : t('Button_Save_Later')} 
                  </StyledSaveButton>
              </Row>
            )}

            {(formData.form9c !== null && formData.form9c.assessment9c !== null) && <>
              <StyledQuestions>{t('f8a_intpa_final_assessment')}:</StyledQuestions>
              <TextValue value={formData.form9c.assessment9c} />
              <SeparatorDotted />
            </>}
            
            <span>{htmlParser(t('F8_intro'))}</span>
            <SeparatorDotted />

            <Formik 
              enableReinitialize
              initialValues={formData.form8}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {( {values,
                  errors,
                  touched,
                  isValid,
                  dirty,
                  resetForm,
                  handleChange,
                  handleBlur,
                }) => {
                    return(
                      <form 
                        onSubmit={(e) => {onValidateSubmit(e, isValid, values, { resetForm })}}
                        id="form8"
                      >
                        {dirty && (setSaved(false))}
                        <StyledQuestions>{t('F8a_question_1')}:</StyledQuestions>

                        <Form.Group>
                          <StyledLabel>{t('F8a_question_1.1')}:</StyledLabel>
                            <Form.Control
                                as="textarea" 
                                name="expect1"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.expect1}
                                className={touched.expect1 && errors.expect1 ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.expect1 && errors.expect1 ? (
                              <FormFielErrorMessage>{errors.expect1}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        <Form.Group>
                          <StyledLabel>{t('F8a_question_1.2')}:</StyledLabel>
                            <Form.Control
                                as="textarea"
                                name="expect2"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.expect2}
                                className={touched.expect2 && errors.expect2 ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.expect2 && errors.expect2 ? (
                              <FormFielErrorMessage>{errors.expect2}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        <Form.Group>
                          <StyledLabel>{t('F8a_question_1.3')}:</StyledLabel>
                            <Form.Control
                                as="textarea" 
                                name="expect3"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.expect3}
                                className={touched.expect3 && errors.expect3 ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.expect3 && errors.expect3 ? (
                              <FormFielErrorMessage>{errors.expect3}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>


                        <StyledQuestions>{t('F8a_question_2')}:</StyledQuestions>

                        <Form.Group>
                          <StyledLabel>{t('F8a_question_2.1')}:</StyledLabel>
                            <Form.Control
                                as="textarea" 
                                name="expect4"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.expect4}
                                className={touched.expect4 && errors.expect4 ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.expect4 && errors.expect4 ? (
                              <FormFielErrorMessage>{errors.expect4}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        <Form.Group>
                          <StyledLabel>{t('F8a_question_2.2')}:</StyledLabel>
                            <Form.Control
                                as="textarea" 
                                name="expect5"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.expect5}
                                className={touched.expect5 && errors.expect5 ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.expect5 && errors.expect5 ? (
                              <FormFielErrorMessage>{errors.expect5}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        <Form.Group>
                          <StyledLabel>{t('F8a_question_2.3')}:</StyledLabel>
                            <Form.Control
                                as="textarea" 
                                name="expect6"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.expect6}
                                className={touched.expect6 && errors.expect6 ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.expect6 && errors.expect6 ? (
                              <FormFielErrorMessage>{errors.expect6}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        <Form.Group>
                          <StyledQuestions>{t('F8a_question_2.5')}:</StyledQuestions>
                            <Form.Control
                                as="textarea" 
                                name="done"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.done}
                                className={touched.done && errors.done ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.done && errors.done ? (
                              <FormFielErrorMessage>{errors.done}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        <Form.Group>
                          <StyledQuestions>{t('F8a_question_2.6')}:</StyledQuestions>
                            <Form.Control
                                as="textarea" 
                                name="notDone"
                                style={{resize: 'none'}}
                                rows={3} 
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.notDone}
                                className={touched.notDone && errors.notDone ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.notDone && errors.notDone ? (
                              <FormFielErrorMessage>{errors.notDone}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        <Form.Group>
                          <StyledQuestions>{t('F8a_question_3')}:</StyledQuestions>
                            <Form.Control
                                as="textarea" 
                                name="conclusion1"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.conclusion1}
                                className={touched.conclusion1 && errors.conclusion1 ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.conclusion1 && errors.conclusion1 ? (
                              <FormFielErrorMessage>{errors.conclusion1}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        <Form.Group>
                          <StyledQuestions>{t('F8a_question_4')}:</StyledQuestions>
                            <Form.Control
                                as="textarea" 
                                name="conclusion2"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.conclusion2}
                                className={touched.conclusion1 && errors.conclusion2 ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.conclusion2 && errors.conclusion2 ? (
                              <FormFielErrorMessage>{errors.conclusion2}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        <Form.Group>
                          <StyledQuestions>{t('F8a_question_5')}:</StyledQuestions>
                            <Form.Control
                                as="textarea" 
                                name="conclusion3"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.conclusion3}
                                className={touched.conclusion3 && errors.conclusion3 ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.conclusion3 && errors.conclusion3 ? (
                              <FormFielErrorMessage>{errors.conclusion3}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        <Form.Group>
                          <StyledQuestions>{t('F8a_question_6')}:</StyledQuestions>
                            <Form.Control
                                as="textarea" 
                                name="conclusion4"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.conclusion4}
                                className={touched.conclusion4 && errors.conclusion4 ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.conclusion4 && errors.conclusion4 ? (
                              <FormFielErrorMessage>{errors.conclusion4}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        <Form.Group>
                          <StyledQuestions>{t('F8a_question_7')}:</StyledQuestions>
                            <Form.Control
                                as="textarea" 
                                name="conclusion5"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.conclusion5}
                                className={touched.conclusion5 && errors.conclusion5 ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.conclusion5 && errors.conclusion5 ? (
                              <FormFielErrorMessage>{errors.conclusion5}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        
                        <Form.Group>
                          <StyledQuestions>{t('F8a_question_8')}:</StyledQuestions>
                            <Form.Control
                                as="textarea" 
                                name="conclusion6"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.conclusion6}
                                className={touched.conclusion6 && errors.conclusion6 ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.conclusion6 && errors.conclusion6 ? (
                              <FormFielErrorMessage>{errors.conclusion6}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        
                        <Form.Group>
                          <StyledQuestions>{t('F8a_question_9')}:</StyledQuestions>
                            <Form.Control
                                as="textarea" 
                                name="conclusion7"
                                rows={3} 
                                style={{resize: 'none'}}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.conclusion7}
                                className={touched.conclusion7 && errors.conclusion7 ? "error" : null}
                                disabled={nonEditable}
                            />
                            {touched.conclusion7 && errors.conclusion7 ? (
                              <FormFielErrorMessage>{errors.conclusion7}</FormFielErrorMessage>
                            ): null}
                        </Form.Group>

                        {!nonEditable && (
                           <Footer saved={saved} nonEditable={nonEditable}/>
                        )} 
                      </form>
                    )}
                }
            </Formik>
          </StyledDivWrapper>
        </div>
    </>
  )
}

export default ExpostReporting;
