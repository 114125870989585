import React from 'react'

import {
  Label,
  StyledA
} from './CustomToggle.styled'


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <StyledA
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{}}
  >
    <Label>{children}</Label>
  </StyledA>
));

export default CustomToggle;