import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'stores';
import { instance } from 'services/axiosInstance';
import { deleteUser } from "services/auth";

import CustomModal from 'components/Modal';

const ErrorBoundary = () => {
  const { t } = useTranslation();
  const { errorStore } = useRootStore();

  const onCloseModal = () => {
    errorStore.closeError();
  }

  instance.interceptors.response.use(
    (response) => response, 
    (error) => {
      if(error.response && error.response.status === 401) {
        deleteUser(); // remove user from localstorage
        if(window.location.pathname !== "/login")
          window.location.href = "/login"
      }
      else if(error.response) {
        // See SA2-737
        if (error?.response?.config?.url?.includes('/F5/section/2/add-applicant') && error?.response?.data?.errorCode === 410) {
          error.response.data.errorCode = 9999;
        }
        if (error?.response?.config?.url?.includes('/F5/section/3/add-co-applicant') && error?.response?.data?.errorCode === 410) {
          error.response.data.errorCode = 9999;
        }
        // if has response show the error
        if (error.response.status !== 406) {
          errorStore.setError(
            (error.response.data && error.response.data.errorCode) ? 
              t(`api_error_${error.response.data.errorCode}`) 
            : 
              t('Error try again later')
          );
          throw error;
        }
      }
      else {
        throw error;
      }
    }
  );

  return (
    <div>
      {errorStore.isShowModal && (
        <CustomModal
          id="error-modal"
          show={errorStore.isShowModal}
          onHide={onCloseModal}
          message={errorStore.modalText}
          type="error"
        />
      )}
    </div>
  )
}


export default observer(ErrorBoundary);