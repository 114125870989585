import React from 'react';
import { Link } from 'react-router-dom';
import { useRootStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { f2statusNamesConst } from 'constants/appConstants';
import { formatDateDDMMYY } from 'helpers/dateHelpers';
import { useTranslation } from 'react-i18next';
import { getCNStateHelper } from 'helpers/cnStateHelper';

import { roleNamesConst } from 'constants/appConstants';
import DeadlineButton from 'components/DeadlineButton';
import { StyledTr, StyledSpan } from './TableRow.styled';
import NewMessagesTableIndicator from 'components/NewMessagesTableIndicator';
import { NewMessagesTableContainer } from 'components/Forms/Forms.styled';

const TableRow = ({ item, setdeadlineModalData }) => {
  const { initiativeStore, authStore } = useRootStore();

  const setInitiativeOnStore = initiative => {
    initiativeStore.setInitiativeData(initiative);
  };

  const todayDate = new Date();
  const baseRoute = authStore.getBaseRouteByRole();
  const { t } = useTranslation();

  return (
    <StyledTr key={item.id}>
      <td style={{ minWidth: '100px' }}>
        <Link
          onClick={() => setInitiativeOnStore(item)}
          to={`${baseRoute}/initiative/${item.id}/form/2/section/1`}
        >
          {item.code}
        </Link>
      </td>
      <td style={{ maxWidth: '550px', overflowWrap: 'break-word' }}>
        {item.name ? item.name : t('Name_not_defined_yet')}
      </td>
      <td style={{ minWidth: '100px' }}>
        {
          getCNStateHelper(
            item.formStateName, 
            item.formVersion, 
            item.f2bDecided, 
            item.f2cDecided
          )
        }
      </td>
      <td>
        {item.formDeadline ? (
          <>
            {formatDateDDMMYY(item.formDeadline)}
            {authStore.roles && authStore.roles[0] === roleNamesConst.INTPA && (
              <DeadlineButton
                onClick={() => {
                  setdeadlineModalData({
                    show: true,
                    initiativeId: item.id,
                    deadlineDateString: item.formDeadline,
                  });
                }}
              />
            )}
          </>
        ) : (
          ' - '
        )}
      </td>
      <td>
        {item.assessmentStatus
          ? item.assessmentStatus +
            (item.assessmentStatus !== f2statusNamesConst.DONE &&
            item.assessmentStatus !== f2statusNamesConst.EXPIRED &&
            item.formVersion === 2
              ? ' v2'
              : '')
          : ' - '}
      </td>
      <td>
        {item.assessmentDeadline ? (
          <StyledSpan expired={todayDate > new Date(item.assessmentDeadline)}>
            {item.assessmentDeadline ? formatDateDDMMYY(item.assessmentDeadline) : ' - '}
          </StyledSpan>
        ) : (
          ' - '
        )}
      </td>
      <td>
        <NewMessagesTableContainer>
          <NewMessagesTableIndicator hasNewMessages={item.unreadComments > 0} />
        </NewMessagesTableContainer>
      </td>
    </StyledTr>
  );
};

export default observer(TableRow);
