import styled, { css } from 'styled-components';

export const StyledColorCircle = styled.div`
  ${({ theme }) => css`
    width: 12px;
    height: 12px;
    background: ${theme.brandColors.red};
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
  `}
`;
