import React, { useEffect, useState } from 'react';
import { formInitialValues } from './AtkeConsortiumForm.initialValues';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getF8bById } from 'services/initiative';
import { useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form';
import { StyledTitle, StyledLabel } from '../ExpostReportForm.styles';
import ApplicantF8ReadOnly from '../ApplicantF8ReadOnly';
import Markers from './Markers';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const AtkeConsortiumForm = ({ setSaved, saved, nonEditable, setNonEditable, setFormInitialValues }) => {
  const { t } = useTranslation();
  const { formId } = useParams();
  const [formData, setFormData] = useState(formInitialValues);
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    resetForm,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormikContext();

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getF8bById(formId);
        setFormData(response);
        setFormInitialValues({ ...response.form8b, form9aList: response?.form9aList, form9c: response?.form9c})
        setNonEditable(response.form8.status.stateEnum !== 'DONE' || response.form8b.status.stateEnum === 'DONE' || response.form8b.status.stateEnum === 'CANCELED');
      } catch (error) {
        console.log(error)
      }
    };
    fetchAPI();
  }, [])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);
  },[saved])

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid
      ? handleSubmit(values, { resetForm })
      : alert(t('not_saved_alert'));
  }

  return (
    <form
      onSubmit={(e) => {onValidateSubmit(e, isValid, values, { resetForm })}}
      id="form8b"
    >
      {dirty && (setSaved(false))}

      <ApplicantF8ReadOnly formData={formData.form8} />

      <StyledTitle style={{marginTop: '40px'}}>{t('F8b_title_2')}:</StyledTitle>
      <Form.Group>
        <StyledLabel>{t('F8b_assessment_1')}:</StyledLabel>
          <Form.Control
            as="textarea"
            name="comment1"
            rows={3}
            style={{resize: 'none'}}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.comment1}
            className={touched.comment1 && errors.comment1 ? "error" : null}
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.comment1}
            errors={errors.comment1}
          />
      </Form.Group>

      <Form.Group>
        <StyledLabel>{t('F8b_assessment_2')}:</StyledLabel>
          <Form.Control
            as="textarea"
            name="comment2"
            rows={3}
            style={{resize: 'none'}}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.comment2}
            className={touched.comment2 && errors.comment2 ? "error" : null}
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.comment2}
            errors={errors.comment2}
          />
      </Form.Group>

      <Form.Group>
        <StyledLabel>{t('F8b_assessment_3')}:</StyledLabel>
        <Form.Control
          as="textarea"
          name="recommendation"
          rows={3}
          style={{resize: 'none'}}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.recommendation}
          className={touched.recommendation && errors.recommendation ? "error" : null}
          disabled={nonEditable}
        />
        <FormErrorMessage
          touched={touched.recommendation}
          errors={errors.recommendation}
        />
      </Form.Group>

      <Markers nonEditable={nonEditable} />
    </form>
  )
}

export default AtkeConsortiumForm;
