import styled from 'styled-components';
import Row from 'react-bootstrap/Row';

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const PageContent = styled.section`
  margin-top: 3rem;
`;

export const CenteredRow = styled(Row)`
  justify-content: center;
`;