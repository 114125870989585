import React, {useEffect, useState} from 'react';
import { useRootStore } from 'stores';
import { observer } from 'mobx-react-lite';
import {f3statusNamesConst, roleNamesConst} from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { useParams } from 'react-router-dom';

import { StyledExportButton } from 'components/Forms/Form1/Form1Footer/Form1Footer.styled';
import PDFGenerationModal from 'components/PDFGenerationModal';
import SendProposalDialog from './SendProposalDialog';
import { 
  StyledSendButton, 
  StyledFooter,
  FooterContainer
} from './Form3Footer.styled'

const Form3Footer = () => {
  const { t } = useTranslation(); 
  const [disableButton, setDisableButton] = useState(true);
  const { formStore, initiativeStore } = useRootStore();
  const { id } = useParams();

  const [displayModal, setDisplayModal] = useState(false);
  const [displayPDFModal, setDisplayPDFModal] = useState(false);

  const handleOpenModal = () => setDisplayModal(true);
  const handleCloseModal = () => setDisplayModal(false);

  const handleOpenPDFModal = () => setDisplayPDFModal(true);
  const handleClosePDFModal = () => setDisplayPDFModal(false);

  const isformVersion2 = initiativeStore.formVersion === 2;
  const isDraftv1 = (initiativeStore.formStateName === f3statusNamesConst.DRAFT) && (initiativeStore.formVersion == 1);
  
  useEffect(() => {
    if (formStore.sectionsStatus) {
      setDisableButton(!allTrue(formStore.sectionsStatus) || !(formStore.formStatus === f3statusNamesConst.DRAFT) || (initiativeStore.role === roleNamesConst.GUEST));
    }
  }, [formStore.sectionsStatus, formStore.formStatus])

  
  function allTrue(obj)
  {
    for(var o in obj) {
      if(obj[o] === "INCOMPLETE" || obj[o] === "NEW") return false;
    }
    return true;
  }


  return (
    <>
      <SendProposalDialog 
        displayDialog={displayModal}
        title={t('modal_title')} 
        onCloseModal={handleCloseModal} 
        isDraftv1={isDraftv1}
      />

      <PDFGenerationModal
        show={displayPDFModal}
        onClose={handleClosePDFModal}
        initiativeId={id}
        initiativeCode={initiativeStore?.code}
        formId={3}
      />

      <FooterContainer>
        {
          isDraftv1 ?
            htmlParser(t('F3_footer'))
          :
            htmlParser(t('F3_footer_v2'))
        }
        <StyledFooter>
          <StyledExportButton onClick={handleOpenPDFModal}>
            {t('pdf_gen_export')}
          </StyledExportButton>
          <StyledSendButton
            disabled={disableButton}
            onClick={handleOpenModal}
          > 
            {isformVersion2? t('F3_Button_send_proposal'):t('F3_Button_send_draft')}
          </StyledSendButton>
        </StyledFooter> 
      </FooterContainer>
    </>
  )
}

export default observer(Form3Footer);