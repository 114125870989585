import styled, { css } from 'styled-components'
import Form from 'react-bootstrap/Form';

export const StyledFormLabel = styled(Form.Label)`
  padding-left: 12px;
  margin-bottom: 7px;
  margin-top: 15px;
`;

export const StyledCheck = styled.div`
   border-radius: 15px;
   border: red 2px solid;
   display: flex !important;
   justify-content: center;
   margin-top: 30px;
   padding: 20px 10px 10px 10px;
   margin-left: 10px;
`;