import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getBudgetByTypes,
  getImplementationInitiatives,
} from 'services/initiative'

import CustomXLSXExportButton from './exportToXLSx'
import Spinner from 'react-bootstrap/Spinner'
import MoneyTextMask from 'components/MoneyTextMask'
import { PrimaryButton } from 'components/Buttons'
import { StyledTitle } from './DashboardExecutedBudget.styled'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'

const DashboardExecutedBudget = () => {
  const { t } = useTranslation()
  const [waiting, setWaiting] = useState(false)
  const [euTableValues, setEuTableValues] = useState([])
  const [counterpartTableValues, setCounterpartTableValues] = useState([])

  const [initiatives, setInitiatives] = useState([])

  const [selectedInitiative, setSelectedInitiative] = useState('')
  const [selectedYear, setSelectedYear] = useState('')

  useEffect(() => {
    getFiltersValues()
    getTableValues()
  }, [])

  const handleFilterInitiativeChange = (e) => {
    setSelectedInitiative(e.target.value)
  }

  const handleFilterYearChange = (e) => {
    setSelectedYear(e.target.value)
  }

  const sumPropertyValue = (items, prop) =>
    items.reduce((a, b) => a + b[prop], 0)

  async function getFiltersValues() {
    try {
      setWaiting(true)
      const response = await getImplementationInitiatives()
      setInitiatives([...response])
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  async function getTableValues(qp) {
    try {
      setWaiting(true)
      const response = await getBudgetByTypes(qp)
      const listEu = [...response.data[0]]
      const listCounterpart = [...response.data[1]]
      listEu.sort((a, b) => (a.resourceTypeId > b.resourceTypeId ? 1 : -1))
      listCounterpart.sort((a, b) =>
        a.resourceTypeId > b.resourceTypeId ? 1 : -1,
      )
      setEuTableValues(listEu)
      setCounterpartTableValues(listCounterpart)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  const renderYears = () => {
    var indents = []
    for (var i = 2021; i <= 2024; i++) {
      indents.push(<option value={i}> {i} </option>)
    }
    return indents
  }

  const handleFilter = () => {
    const qp = {}
    if (selectedInitiative !== 'ALL') {
      qp['initiativeId'] = selectedInitiative
    }
    if (selectedYear !== 'ALL') {
      qp['year'] = selectedYear
    }
    getTableValues(qp)
  }

  if (waiting)
    return (
      <Col style={{ marginTop: '10%', textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </Col>
    )

  const getTotalDeviation = (deviationF5, deviationF3) => {
    return ((deviationF5 - deviationF3) / deviationF3  * 100);
  }

  return (
    <>
      <StyledTitle>{t('Dashboard_Execution')}</StyledTitle>

      <Row style={{ alignItems: 'center', marginLeft: '0px' }}>
        {t('Dashboard_Initiative')}
        <Col xs={2}>
          <Form.Control
            as="select"
            onChange={handleFilterInitiativeChange}
            value={selectedInitiative}
          >
            <option value="ALL"> {t('Button_filter_all')} </option>
            {initiatives.map((initiative) => (
              <option
                value={initiative.id}
              >{`${initiative.code} - ${initiative.name}`}</option>
            ))}
          </Form.Control>
        </Col>
        {t('Dashboard_Year')}
        <Col xs={2}>
          <Form.Control
            as="select"
            onChange={handleFilterYearChange}
            value={selectedYear}
          >
            <option value="ALL"> {t('Button_filter_all')} </option>
            {renderYears()}
          </Form.Control>
        </Col>
        <PrimaryButton onClick={handleFilter} disabled={waiting}>
          {t('Button_filter')}
        </PrimaryButton>
      </Row>

      <Row
        style={{
          justifyContent: 'space-between',
          marginTop: '40px',
          marginLeft: '0px',
        }}
      >
        {t('F1_S7_EU_budget')}
        <CustomXLSXExportButton tableValues={euTableValues} />
      </Row>

      <Table border="1" style={{ marginTop: '30px' }}>
        <tr style={{ backgroundColor: '#c2deb1' }}>
          <th scope="col">{t('Budget_category')}</th>
          <th scope="col">{t('Authorized')}</th>
          <th scope="col">{t('Implementation')}</th>
          <th scope="col">{t('Deviation')}</th>
          <th scope="col">{t('Paid')}</th>
          <th scope="col">{t('Executed_level_dashboard')}</th>
        </tr>
        {euTableValues?.map((resource) => (
          <>
            <tr>
              <td>{t(`resource_type_${resource.resourceTypeId}`)}</td>
              <td>
                <MoneyTextMask value={resource.authorized} />
              </td>
              <td>
                <MoneyTextMask value={resource.implementation} />
              </td>
              <td>{(resource.deviation * 100)?.toFixed(2) } % </td>
              <td>
                {resource.paid ? <MoneyTextMask value={resource.paid} /> : '-'}
              </td>
              <td>{(resource.implementation? (resource.paid / resource.implementation)*100 : 0).toFixed(2)} %</td>
            </tr>
          </>
        ))}
        <tr>
          <td>
            <strong>{t('Dashboard_total_value')}</strong>
          </td>
          <td>
            <strong>
              <MoneyTextMask
                value={sumPropertyValue(euTableValues, 'authorized')}
              />
            </strong>
          </td>
          <td>
            <strong>
              <MoneyTextMask
                value={sumPropertyValue(euTableValues, 'implementation')}
              />
            </strong>
          </td>
          <td>
            <strong>
              {getTotalDeviation(sumPropertyValue(euTableValues, 'implementation'), sumPropertyValue(euTableValues, 'authorized'))?.toFixed(2)} %
            </strong>
          </td>
          <td>
            <strong>
              <MoneyTextMask value={sumPropertyValue(euTableValues, 'paid')} />
            </strong>
          </td>
        </tr>
      </Table>

      <Row
        style={{
          justifyContent: 'space-between',
          marginTop: '40px',
          marginLeft: '0px',
        }}
      >
        {t('F1_S7_counterpart_budget')}
        <CustomXLSXExportButton tableValues={counterpartTableValues} />
      </Row>

      <Table border="1" style={{ marginTop: '30px' }}>
        <tr style={{ backgroundColor: '#c2deb1' }}>
          <th scope="col">{t('Budget_category')}</th>
          <th scope="col">{t('Authorized')}</th>
          <th scope="col">{t('Implementation')}</th>
          <th scope="col">{t('Deviation')}</th>
          <th scope="col">{t('Paid')}</th>
          <th scope="col">{t('Executed_level_dashboard')}</th>
        </tr>
        {counterpartTableValues?.map((resource) => (
          <>
            <tr>
              <td>{t(`resource_type_${resource.resourceTypeId}`)}</td>
              <td>
                <MoneyTextMask value={resource.authorized} />
              </td>
              <td>
                <MoneyTextMask value={resource.implementation} />
              </td>
              <td>{(resource.deviation * 100)?.toFixed(2)} % </td>
              <td>
                {resource.paid ? <MoneyTextMask value={resource.paid} /> : '-'}
              </td>
              <td>
                {resource.implementation && resource.paid
                  ? ((resource.paid / resource.implementation) * 100).toFixed(
                      2,
                    ) + '%'
                  : '-'}
              </td>
            </tr>
          </>
        ))}
        <tr>
          <td>
            <strong>{t('Dashboard_total_value')}</strong>
          </td>
          <td>
            <strong>
              <MoneyTextMask
                value={sumPropertyValue(counterpartTableValues, 'authorized')}
              />
            </strong>
          </td>
          <td>
            <strong>
              <MoneyTextMask
                value={sumPropertyValue(
                  counterpartTableValues,
                  'implementation',
                )}
              />
            </strong>
          </td>
          <td>
            <strong>
            {getTotalDeviation(sumPropertyValue(counterpartTableValues, 'implementation'), sumPropertyValue(counterpartTableValues, 'authorized'))?.toFixed(2)} %
            </strong>
          </td>
          <td>
            <strong>
              <MoneyTextMask
                value={sumPropertyValue(counterpartTableValues, 'paid')}
              />
            </strong>
          </td>
        </tr>
      </Table>
    </>
  )
}

export default DashboardExecutedBudget
