import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledNumberCircle, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  DescripcionDiv,
  StyledTextValue,
  Text,
} from 'components/Forms/Form4/Section10/Section10.styled';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import BeneficiaryForm from 'components/Forms/Form4/Section10/BeneficiaryForm';
import EntityForm from 'components/Forms/Form4/Section10/EntityForm';
import FakeAccordionItem from '../../FakeAccordionItem';
import { SeparatorDotted } from 'components/CommonStyled';

const Section10 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`10 - ${t('F3_S10_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <StyledTitle> {t('F3_S10_Subtitle_ownership')} </StyledTitle>
        <DescripcionDiv style={{marginTop: '20px'}}>
          <Form.Group as={Col}>
            <Form.Label>{t('F3_S10_Description')}:</Form.Label>
            <StyledTextValue> {formValues.benEntities} </StyledTextValue>
          </Form.Group>
        </DescripcionDiv>

        <Text>
          {t('F3_S10_Subtitle_participation')}       
        </Text>
        <div style={{marginBottom: '20px'}} />
                      
        {(formValues && formValues.ownerList && formValues.ownerList.map((entity, key) => (
          <React.Fragment key={key}>
            <FakeAccordionItem 
              title={`${t('F3_S10_Entity')} ${key+1} - ${t('F1_S2_Entity_name')}: ${entity? entity.entityName: "-"}`}
              noMargin
            >
              <div style={{marginLeft: '-30px'}}>
                <EntityForm data={entity} />
              </div>
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}

        <StyledTitle > {t('F3_S10_Subtitle_direct_beneficiaries')} </StyledTitle>

        <DescripcionDiv style={{marginTop: '20px'}} >
          <Form.Group as={Col}>
            <Form.Label>{t('F3_S10_Description')}:</Form.Label>
            <StyledTextValue> {formValues.directBen} </StyledTextValue>
          </Form.Group>
        </DescripcionDiv>
        
        {(formValues && formValues.benList && formValues.benList.map((beneficiary, key) => (
          <React.Fragment key={key}>
            <FakeAccordionItem
              title={`${t('F3_S10_Beneficiary')} ${key+1}`}
              noMargin
            >
              <div style={{marginLeft: '-30px'}}>
                <BeneficiaryForm data={beneficiary} />  
              </div>
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}

      </StyledBody>
    </SectionContainer>
  )
}


export default Section10;