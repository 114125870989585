export const formInitialValues = {
  benEntities: "",
  directBen: "",
  ownerList: [],
  benList: [
    {
      beneficiaryType: "",
      beneficiaryDescription: "",
      beneficiaryQuantification: "",
      beneficiaryJustification: "",
      beneficiaryProspect: "",
      answersF3S10BenCountryList: []
    }
  ]
};
