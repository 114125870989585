import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';
import htmlParser from 'html-react-parser';

import TextValue from 'components/TextValue';
import Form from 'react-bootstrap/Form';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  StyledFormLabel,
  Text,
  Separator,
} from 'components/Forms/Form4/Section13/Section13.styled';

const Section13 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);
  
  useEffect(() => {
    if (data) {
      setFormValues({
        ...(data?.[0] || {}),
        rights: data?.[1],
        environment: data?.[2],
        link: data?.[3],
        valueTP: data?.[4],
        valueSR_2: data?.[5],
      })
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`13 - ${t('F3_S13_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <Text>
          {htmlParser(t('F3_S13_Intro'))}
        </Text>

        <Separator />
        
        <Form.Group>
          <StyledFormLabel>{htmlParser(t('F3_S13_Rights_based_approach'))}:</StyledFormLabel>
          <TextValue value={formValues.rights} />
        </Form.Group>  

        <Form.Group>
          <StyledFormLabel>{htmlParser(t('F3_S13_Environment_CC'))}:</StyledFormLabel>
          <TextValue value={formValues.environment} />
        </Form.Group>  
        
        <Form.Group>
          <StyledFormLabel>{htmlParser(t('F3_S13_Link_with_national'))}:</StyledFormLabel>
          <TextValue value={formValues.link} />
        </Form.Group>  

        <Form.Group>
          <StyledFormLabel>{htmlParser(t('F3_S13_Prospects_continued'))}:</StyledFormLabel>
          <TextValue value={formValues.continued} />
        </Form.Group>  

        <Form.Group>
          <StyledFormLabel>{htmlParser(t('F3_S13_Prospects_taking'))}:</StyledFormLabel>
          <TextValue value={formValues.advantage} />
        </Form.Group>  
      </StyledBody>
    </SectionContainer>
  )
}


export default Section13;