import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formInitialValues } from 'components/Forms/Form5/Section7/GeneralInfoForm/GeneralInfoForm.initialValues';
import { formatDateDDMMYY } from 'helpers/dateHelpers';

import { RequiredMark, SeparatorDotted } from 'components/CommonStyled';
import {StyledFormLabel, StyledCheck} from 'components/Forms/Form5/Section7/GeneralInfoForm/GeneralInfoForm.styled'
import TextValue from 'components/TextValue';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { StyledTitle } from '../CommonStyles';

const Section7GeneralInfo = ({ activity, data }) => {
  const {t} = useTranslation();
  const [formValues, setFormValues] = useState(formInitialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data]);

  return (
    <div style={{marginLeft: '15px'}}>
      <StyledTitle style={{marginBottom: '30px'}}> {t('F1_S7_Accordion')} - {activity ? activity.name : ''} - {t('F3_S7_Subtitle_General_Information')} </StyledTitle>
      
      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_type')}:</StyledFormLabel>
        <TextValue value={formValues.activityType ? t(`activity_types_${formValues.activityType.id}`) : ' - '} />
      </Form.Group>

      <Form.Group as={Row} style={{display: 'flex', alignItems:"center"}}>
        <Form.Label column xs={2}>{t('F1_S7_start_day')}<RequiredMark>*</RequiredMark>:</Form.Label>
        <TextValue value={formValues.fromDate ? formatDateDDMMYY(formValues.fromDate) : ' - '} />
      </Form.Group>

      <Form.Group as={Row} style={{display: 'flex', alignItems:"center"}}>
        <Form.Label column xs={2}>{t('F1_S7_End_day')}<RequiredMark>*</RequiredMark>:</Form.Label>
        <TextValue value={formValues.toDate ? formatDateDDMMYY(formValues.fromDate) : ' - '} />
      </Form.Group>

      <Form.Group>
        <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}} >{t('F5_S7_Title_of_the_activity')}<RequiredMark>*</RequiredMark>: </Form.Label>
        <TextValue value={formValues.title} />
      </Form.Group>

      <Form.Group>
        <StyledFormLabel as={Row} style={{ marginTop: '30px', marginBottom: '15px' }}>{t('F1_S7_Short_description')}:</StyledFormLabel>
        <TextValue value={formValues.description} />
      </Form.Group>

      <SeparatorDotted />

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_Country_activity')}:</StyledFormLabel>
        <TextValue value={formValues.country ? t(formValues.country.name) : ' - '} />
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S2_State_Province_Region')}:</StyledFormLabel>
        <TextValue value={formValues.stateName} />
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S2_City')}:</StyledFormLabel>
        <TextValue value={formValues.cityName} />
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_Countries_additional')}:</StyledFormLabel>
        <TextValue value={formValues.countriesAdditional} />
      </Form.Group>

      <SeparatorDotted />

      {(formValues.activityType && (formValues.activityType.id == 4 || formValues.activityType.id == 7)) && 
        <>
          <Form.Group as={Row}>
            <Form.Label column xs={3}>{t('F1_S7_Duration')}:</Form.Label>
            <TextValue value={formValues.duration} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={3}>{t('F1_S7_Number_participants')}:</Form.Label>
            <TextValue value={formValues.participants} />
          </Form.Group>
    
          <SeparatorDotted />
        </>
      }
      
      {(formValues.activityType && formValues.activityType.id == 4) &&
        <>
          <Form.Group as={Row}>
            <Form.Label column xs={3}>{t('F1_S7_Number_experts')}:</Form.Label>
            <TextValue value={formValues.experts} />
          </Form.Group>

          <StyledFormLabel xs={4} as={Row} style={{ marginTop: '30px', marginBottom: '15px' }}>{t('F1_S7_Number_wd')}:</StyledFormLabel>

          <Form.Group as={Row}>
            <Form.Label column xs={3}>{t('F1_S7_Remote')}:</Form.Label>
            <TextValue value={formValues.daysRemote} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={3}>{t('F1_S7_Onsite')}:</Form.Label>
            <TextValue value={formValues.daysOnsite} />
          </Form.Group>

          <Form.Group as={Row}>
            <StyledFormLabel xs={4}>{t('F1_S7_Total')}:</StyledFormLabel>
            <TextValue value={formValues.daysRemote + formValues.daysOnsite} />
          </Form.Group>

          <SeparatorDotted />
        </>
      }
      <Form.Group>
        <Row>
          <StyledFormLabel>{t('F3_S7_General_work_language')}<RequiredMark>*</RequiredMark>:</StyledFormLabel>
          <Form.Check 
            disabled={true} 
            style={{marginLeft: '15px'}} 
            name="workLang1" 
            type="checkbox" 
            checked={formValues.workLang1} 
            value={formValues.workLang1} 
            label={t('English')} 
          />
          <Form.Check 
            disabled={true} 
            style={{marginLeft: '15px'}} 
            name="workLang2" 
            type="checkbox" 
            checked={formValues.workLang2} 
            value={formValues.workLang2} 
            label={t('French')} 
          />
          <Form.Check 
            disabled={true} 
            style={{marginLeft: '15px'}} 
            name="workLang3" 
            type="checkbox" 
            checked={formValues.workLang3} 
            value={formValues.workLang3}
            label={t('Portuguese')} 
          />
          <Form.Check 
            disabled={true} 
            style={{marginLeft: '15px'}} 
            name="workLang4" 
            type="checkbox" 
            checked={formValues.workLang4} 
            value={formValues.workLang4}
            label={t('Spanish')} 
          />
        </Row>
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F3_S7_General_justification_activity')}:</StyledFormLabel>
        <TextValue value={formValues.justification} />
      </Form.Group>

      {(formValues.activityType && (formValues.activityType.id == 4 || formValues.activityType.id == 7)) && 
        <>
          <Form.Group>
            <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}} >{t('F3_S7_General_objectives')}<RequiredMark>*</RequiredMark>: </Form.Label>
            <TextValue value={formValues.objective} />
          </Form.Group>
        </>
      }

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F3_S7_General_learning_objectives')}:</StyledFormLabel>
        <TextValue value={formValues.learning} />
      </Form.Group>

      {(formValues.activityType && (formValues.activityType.id == 1 || formValues.activityType.id == 2 || formValues.activityType.id == 3)) && 
        <>
          <Form.Group>
            <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}} >{t('F3_S7_General_speakers')}<RequiredMark>*</RequiredMark>: </Form.Label>
            <TextValue value={formValues.speakers} />
          </Form.Group>
        </>
      }

      <Form.Group>
        <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}} >{t('F3_S7_General_brief_description_schedule')}<RequiredMark>*</RequiredMark>: </Form.Label>
        <TextValue value={formValues.descSchedule} />
      </Form.Group>

      <Form.Group>
        <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}} >{t('F3_S7_General_workplan')}<RequiredMark>*</RequiredMark>: </Form.Label>
        <TextValue value={formValues.workPlans} />
      </Form.Group>

      <StyledCheck>
        <Form.Group>
          <Form.Check 
            style={{marginLeft: '15px', textAlign: 'justify'}} 
            name="genInfoConfirmed" 
            type="checkbox" 
            checked={formValues.genInfoConfirmed} 
            value={formValues.genInfoConfirmed} 
            label={t('F5_S7_General_confirmation_check')} 
          />
        </Form.Group>
      </StyledCheck>
    </div>
  )

}

export default Section7GeneralInfo;
