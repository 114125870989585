import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import { Table, Th1, Th1Pointer } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'

import { T3RenderRows } from '../helpers/helpers'
import { orderDataByField2 } from '../helpers/helpers'

const Table3 = ({ data, waiting, setData }) => {
  const { t } = useTranslation()
  const [ascendingOrder, setAscendingOrder] = useState({
    code: true,
    name: true,
    ods: true,
    odstype: true,
    indicator: true,
    odstarget:true,
  })

  const handleOrderData = (field) => {
    orderDataByField2(data, field, setData, setAscendingOrder, ascendingOrder)
  }

  const countDifferentODS = () => {
    const odsList = [];
    data?.forEach(item => {
      if (!odsList.includes(item.ods)) {
        odsList.push(item.ods);
      }
    });
    return odsList.length;
  }


  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data && !isEmpty(data) ? (
    <>
      <div style={{width: '100%', alignItems: 'flex-start', marginTop: '15px', marginBottom: '15px'}}>
        <b>{`${t('ODS_count')} ${countDifferentODS()}`}</b>
      </div>
      <Table>
        <tr>
          <Th1Pointer onClick={() => handleOrderData('code')}>
            {t('Initiative_code')} &#8645;
          </Th1Pointer>
          <Th1Pointer onClick={() => handleOrderData('name')} width="50%">
            {t('Title_initiative')} &#8645;
          </Th1Pointer>
          <Th1Pointer onClick={() => handleOrderData('ods')}>
            {t('SDG')} &#8645;
          </Th1Pointer>
          <Th1>
            {t('F3_S11_Target')}
          </Th1>
          <Th1>
            {t('F3_S11_Indicators')}
          </Th1>
          <Th1Pointer onClick={() => handleOrderData('odstype')}>
            {t('type_SDG')} &#8645;
          </Th1Pointer>
        </tr>
        {T3RenderRows(data)}
      </Table>
    </>
  ) : (
    t('No_data_available_2')
  )
}

export default Table3

