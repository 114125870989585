import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledIntroParagraph = styled.p`
  line-height: 1.3rem;
  padding-left: 50px;
`;

export const StyledRequiredSpan = styled.span`
  color: red;
`;

export const StyledWarningP = styled.p`
  color: #FF6565;
  margin: 0px;
  margin-top: 2px;
  font-size: .8em;
`;

export const StyledFormLabel = styled(Form.Label)`
  padding-left: 65px;
`;

export const StyledSeparator = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  height: 2px;
  background-image: linear-gradient(to right, #bac5cc 33%, rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 6px 2px;
  background-repeat: repeat-x;
`;

export const StyledDiv = styled.div`
  margin-left: 50px;
  line-height: 20px;
`;

export const StyledAclaration = styled.span`
  font-style: italic;
  font-weight: 450;
`;

export const StyledRadioSectionTitle = styled.div`
  margin-left:-30px;
  font-weight: 600px;
`;

export const StyledRadioSection = styled.div`
  padding-left:30px;
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 30px;
`;

export const StyledRadioLabel = styled.label`
  margin-right: 60px;
`;

export const StyledCustomAccordionContainer = styled.div`
  margin-left: 50px;
`;
