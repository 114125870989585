import i18n from 'i18n'
import { Td, Th4, Td2 } from 'pages/statistics/CommonStyled/Components.styled'

import _orderBy from 'lodash/orderBy'

import {
  orderByInitiativeCode,
  orderBySring,
} from 'pages/statistics/CommonHelpers/helpers'

const getTotals = (data) => {
  let totals = [
    data.reduce((a, b) => a + b.numberInteg, 0),
    data.reduce((a, b) => a + b.beneficiaries, 0),
    data.reduce((a, b) => a + b.firstOf, 0),
    data.reduce((a, b) => a + b.secondOf, 0),
    '-',
    data.reduce((a, b) => a + b.collaborators, 0),
  ]

  return totals
}

export const renderRows = (data) => {
  let rows = []
  data.forEach(
    ({
      code,
      name,
      region,
      coordRole,
      numberInteg,
      collaborators,
      beneficiaries,
      firstOf,
      secondOf,
      countries,
    }) => {
      rows.push(
        <tr>
          <Td>{code ? code : '-'}</Td>
          <Td>{name ? name : '-'}</Td>
          <Td>{region ? i18n.t(region) : '-'}</Td>
          <Td>
            {coordRole
              ? i18n.t(`coordRole${coordRole}`)
              : coordRole == 0
              ? i18n.t(`coordRole${coordRole}`)
              : '-'}
          </Td>
          <Td> {numberInteg ? numberInteg : numberInteg == 0 ? 0 : '-'}</Td>
          <Td>
            {beneficiaries ? beneficiaries : beneficiaries == 0 ? 0 : '-'}
          </Td>
          <Td> {firstOf ? firstOf : firstOf == 0 ? 0 : '-'}</Td>
          <Td> {secondOf ? secondOf : secondOf == 0 ? 0 : '-'}</Td>
          <Td> {countries ? countries : countries == 0 ? 0 : '-'}</Td>
          <Td>
            {collaborators ? collaborators : collaborators == 0 ? 0 : '-'}
          </Td>
        </tr>,
      )
    },
  )
  rows.push(
    <tr>
      <Th4 colSpan={4}>{i18n.t('F1_S7_Total')}</Th4>
      {getTotals(data).map((element) => (
        <Td2>{element}</Td2>
      ))}
    </tr>,
  )

  return rows
}

export const getPartnershipsExportValues = (exportData) => {
  let data = []
  exportData?.forEach((row) => {
    data.push({
      col1: row?.code ? row.code : '-',
      col2: row?.name ? row.name : '-',
      col3: row?.region ? i18n.t(row.region) : '-',
      col4: row?.coordRole
        ? i18n.t(`coordRole${row.coordRole}`)
        : row.coordRole == 0
        ? i18n.t(`coordRole${row.coordRole}`)
        : '-',
      col5: row?.numberInteg ? row.numberInteg : row.numberInteg == 0 ? 0 : '-',
      col6: row?.beneficiaries
        ? row.beneficiaries
        : row.beneficiaries == 0
        ? 0
        : '-',
      col7: row?.firstOf ? row.firstOf : row.firstOf == 0 ? 0 : '-',
      col8: row?.secondOf ? row.secondOf : row.secondOf == 0 ? 0 : '-',
      col9: row?.countries ? row.countries : row.countries == 0 ? 0 : '-',
      col10: row?.collaborators
        ? row.collaborators
        : row.collaborators == 0
        ? 0
        : '-',
    })
  })
  const totals = getTotals(exportData)
  data.push({
    col1: '',
    col2: '',
    col3: '',
    col4: i18n.t('F1_S7_Total'),
    col5: totals[0],
    col6: totals[1],
    col7: totals[2],
    col8: totals[3],
    col9: totals[4],
    col10: totals[5],
  })
  return data
}

export const orderDataByField = (data, field, setData, setAsc, asc) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'name':
      orderedData = orderBySring(data, field, asc)
      break
    case 'code':
      orderedData = orderByInitiativeCode(data, field, asc)
      break
    case 'region':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(item?.region)],
        asc[field] ? ['asc'] : ['desc'],
      )
      break
    case 'coordRole':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(`coordRole${item?.coordRole}`)],
        asc[field] ? ['asc'] : ['desc'],
      )
      break
  }

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}
