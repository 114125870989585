import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportActivitiesDetailToXLSX } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'

import { getCountries } from 'services/initiative'

import { Form, Row } from 'react-bootstrap'
import { PrimaryButton } from 'components/Buttons'
import {
  StyledRow,
  StyledFormGroup,
  StyledFormLabel,
} from '../../CommonStyled/Components.styled'

import { renderCountriesOptions } from 'helpers/renderCountriesHelper'
import { renderStatesOptions } from 'helpers/renderStatesHelper'
import { renderYears } from 'helpers/dateHelpers'
import { renderActivitiesTypesOptions } from 'helpers/renderaActivitiesTypesHelper'
import { getActivitiesDetailExportValues } from '../helpers/helpers'
import { renderFormatOptions, renderTypeOptions } from '../../CommonHelpers/helpers'

const Header1 = ({ data, waiting, getData }) => {
  const { t } = useTranslation()
  const [countriesOptions, setCountriesOptions] = useState([])

  const [countryFilter, setCountryFilter] = useState('')
  const [regionsFilter, setRegionsFilter] = useState('')
  const [yearsFilter, setYearsFilter] = useState(0)
  const [activitiesFilter, setActivitiesFilter] = useState('')
  const [formatFilter, setFormatFilter] = useState('')
  const [typeFilter, setTypeFilter] = useState('*')

  useEffect(() => {
    getCountriesOptions()
  }, [])

  const getCountriesOptions = async () => {
    try {
      const response = await getCountries()
      setCountriesOptions(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilter = () => {
    let param = {}
    if (countryFilter) param.countryId = countryFilter
    if (regionsFilter) param.region = regionsFilter
    if (yearsFilter && yearsFilter != 0) param.year = yearsFilter
    if (activitiesFilter) param.typeId = activitiesFilter
    if (formatFilter) param.format = formatFilter
    if (typeFilter) {
      if (typeFilter === '*') {
        param.includeFunds = true;
        param.includeInitiatives = true;
      }
      if (typeFilter === 'FUNDS') {
        param.includeFunds = true;
        param.includeInitiatives = false;
      }
      if (typeFilter === 'INITIATIVES') {
        param.includeFunds = false;
        param.includeInitiatives = true;
      }
    }
    getData(param)
  }

  return (
    <>
      <StyledRow>
        <StyledFormGroup style={{ width: '15%' }}>
          <StyledFormLabel>{t('Country_host')}</StyledFormLabel>
          <Form.Control
            name="countryId"
            as="select"
            onChange={(e) => {
              setCountryFilter(e.target.value)
            }}
            value={countryFilter}
          >
            {renderCountriesOptions(countriesOptions)}
          </Form.Control>
        </StyledFormGroup>

        <StyledFormGroup style={{ width: '15%' }}>
          <StyledFormLabel>{t('Region')}</StyledFormLabel>
          <Form.Control
            name="regionId"
            as="select"
            onChange={(e) => {
              setRegionsFilter(e.target.value)
            }}
            value={regionsFilter}
          >
            {renderStatesOptions()}
          </Form.Control>
        </StyledFormGroup>
        <StyledFormGroup style={{ width: '15%' }}>
          <StyledFormLabel>{t('F1_S7_Format')}</StyledFormLabel>
          <Form.Control
            name="eventFormat"
            as="select"
            onChange={(e) => {
              setFormatFilter(e.target.value)
            }}
            value={formatFilter}
          >
            {renderFormatOptions()}
          </Form.Control>
        </StyledFormGroup>
        <StyledFormGroup style={{ width: '15%' }}>
          <StyledFormLabel>{t('Type')}</StyledFormLabel>
          <Form.Control
            name="activityType"
            as="select"
            onChange={(e) => {
              setActivitiesFilter(e.target.value)
            }}
            value={activitiesFilter}
          >
            {renderActivitiesTypesOptions()}
          </Form.Control>
        </StyledFormGroup>
        <StyledFormGroup style={{ width: '15%' }}>
          <StyledFormLabel>{t('Dashboard_Year_2')}</StyledFormLabel>
          <Form.Control
            name="year"
            as="select"
            onChange={(e) => {
              setYearsFilter(e.target.value)
            }}
            value={yearsFilter}
          >
            {renderYears()}
          </Form.Control>
        </StyledFormGroup>
      </StyledRow>
      <StyledRow style={{ justifyContent: 'flex-start' }}>
        <StyledFormGroup style={{ width: '30%' }}>
          <StyledFormLabel>{t('type_filter_stat')}</StyledFormLabel>
          <Form.Control
            name="type"
            as="select"
            onChange={(e) => {
              setTypeFilter(e.target.value)
            }}
            value={typeFilter}
          >
            {renderTypeOptions()}
          </Form.Control>
        </StyledFormGroup>

        <Row style={{ width: '15%', marginLeft: '30px'}}>
          <PrimaryButton
            onClick={() => {
              handleFilter()
            }}
            disabled={waiting}
          >
            {t('Button_search')}
          </PrimaryButton>
        </Row>

        <Row style={{ width: '20%', alignItems: 'center'}}>
          <ExportToXLSXButton
            data={data}
            mapData={getActivitiesDetailExportValues}
            disabled={waiting}
            exportName={t('Statistics_activities_tab1')}
          >
            <ExportActivitiesDetailToXLSX data={data} />
          </ExportToXLSXButton>
        </Row>
      </StyledRow>
    </>
  )
}

export default Header1
