import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import htmlParser from "html-react-parser";
import { observer } from 'mobx-react-lite';

import CustomBudgetDetailsTable from 'components/CustomBudgetDetailsTable';
import BudgetLineForm from '../BudgetLineForm';
import MoneyTextMask from 'components/MoneyTextMask';
import Table from 'react-bootstrap/Table';
import { StyledFormLabel } from '../Section7.styled';
import TextValue from 'components/TextValue';
import { PrimaryButton } from 'components/Buttons';
import "react-datepicker/dist/react-datepicker.css";
import { Form } from 'react-bootstrap';
import { CenteredModalButtonContainer } from 'components/CommonStyled';
import {
  RowBorder,
  BorderlessTr,
  TableHeader,
  ResourceNameSpan,
  ResourceNumberSpan,
  EstimatedBugetDiv,
  TotalBugetDiv,
  TotalBudgetSpan,
} from './ResourcesForm.styled';

const ResourcesForm = ({ handleCloseOk, activity }) => {
  const { t } = useTranslation();
  const [currentBudget, setCurrentBudget] = useState({});
  const [budgetLineModalShow, setBudgetLineModalShow] = useState(false);
  const [localBudgetLineList, setLocalBudgetLineList] = useState([...activity.resourceList]);

  useEffect(() => {
    const flattedArray = handleFlatArray(activity.resourceList);
    setLocalBudgetLineList([...flattedArray]);
  }, [activity, activity.resourceList])

  const handleOpenBudgetLine = (detailExtended) => {
    setCurrentBudget(detailExtended);
    setBudgetLineModalShow(true);
  }

  const handleFlatArray = (array) => {
    const flatArray = array.map(resource => {
      if(resource.details){
        return resource.details.map(detail => {
          return {
            ...detail,
            resourceType: resource.resourceType
          }
        })
      }
    });
    return flatArray.flat();
  }

  return (
    <div>
      <BudgetLineForm 
        handleCloseOk={() => setBudgetLineModalShow(false)}
        show={budgetLineModalShow}
        currentBudget={currentBudget}
      />
      
        <div>
          {htmlParser(t('F3_S7_Resource_help_text'))}
          
          <CustomBudgetDetailsTable
            items={localBudgetLineList}
            isOpenable={true}
            hasRemoveButton={false}
            nonEditable={false}
            handleOpenBudgetLine={handleOpenBudgetLine}
          />
                  
          <EstimatedBugetDiv>
            <Table bordered={ false }>
              <thead>
                <BorderlessTr>
                  <TableHeader width={"45"}></TableHeader>
                  <TableHeader width={"15"}>{t('F3_S7_Resource_column_EU')}</TableHeader>
                  <TableHeader width={"15"}>{t('F3_S7_Resource_column_counterpart')}</TableHeader>
                  <TableHeader width={"15"}>{t('F3_S7_Resource_column_total')}</TableHeader>
                </BorderlessTr>
              </thead>
              <tbody>
                <BorderlessTr>
                  <td colSpan="4">
                    <RowBorder />
                  </td>
                </BorderlessTr>
                <BorderlessTr>
                  <td><ResourceNameSpan>{t('F3_S7_Resource_estimated_totals')}</ResourceNameSpan></td>
                  <td><ResourceNumberSpan> <MoneyTextMask value={activity.estimatedUeBudget} /> </ResourceNumberSpan></td>
                  <td><ResourceNumberSpan> <MoneyTextMask value={activity.estimatedCounterpartBudget} /> </ResourceNumberSpan></td>
                  <td><ResourceNumberSpan> <MoneyTextMask value={activity.estimatedTotalBudget} /> </ResourceNumberSpan></td>
                </BorderlessTr>
              </tbody>
            </Table>

            <Form.Group>
              <StyledFormLabel>{t('F3_S7_Resource_justification')}:</StyledFormLabel>
              <TextValue value={activity.justBudget} />
            </Form.Group>  
          </EstimatedBugetDiv>
          
          <TotalBugetDiv>
            <Table bordered={ false }>
              <thead>
                <BorderlessTr>
                  <TableHeader width={"45"}><TotalBudgetSpan> {t('F3_S7_Resource_total_activity')} {activity.name} </TotalBudgetSpan> </TableHeader>
                  <TableHeader width={"15"}>
                    <ResourceNumberSpan style={{color: 'black'}}>
                      <MoneyTextMask value={activity.ueBudget} />
                    </ResourceNumberSpan>
                  </TableHeader>
                  <TableHeader width={"15"}>
                    <ResourceNumberSpan style={{color: 'black'}}>
                      <MoneyTextMask value={activity.counterpartBudget} />
                    </ResourceNumberSpan>
                  </TableHeader>
                  <TableHeader width={"15"}>
                    <ResourceNumberSpan style={{color: 'black'}}>
                      <MoneyTextMask value={activity.totalBudget} />
                    </ResourceNumberSpan>
                  </TableHeader>
                </BorderlessTr>
              </thead>
            </Table>
          </TotalBugetDiv>

          <CenteredModalButtonContainer>
            <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
          </CenteredModalButtonContainer>

        </div>
    </div>
  )
}

export default observer(ResourcesForm);