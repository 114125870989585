import PrivateRoute from './PrivateRoute'
import Layout from 'layout'
import ManagerInitiative from 'pages/manager/Initiative'
import ManagerCN from 'pages/manager/ConceptNotes'
import ManagerProposals from 'pages/manager/Proposals'
import ManagerManagement from 'pages/manager/Management'
import ManagerDashboard from 'pages/manager/Dashboard'
import ManagerDashboardInitiatives from 'pages/manager/DashboardInitiatives'
import ManagerDashboardExecutedBudget from 'pages/manager/DashboardExecutedBudget'
import ManagerDashboardPreformulation from 'pages/manager/DashboardPreformulation'
import ManagerExpostReportList from 'pages/manager/ExpostReporting'
import ManagerExpostReportForm from 'pages/manager/ExpostReportForm'
import ManagerPurchasing from 'pages/manager/PurchasingManagement'
import ManagerInvoice from 'pages/manager/InvoiceManagement'
import ManagerContacts from 'pages/manager/Contacts'
import ManagerDocuments from 'pages/manager/Documents'
// Statistics system
import StatsFormulation from 'pages/statistics/Formulation'
import StatsPreformulation from 'pages/statistics/Preformulation'
import StatsExecution from 'pages/statistics/Execution'
import StatsEntities from 'pages/statistics/Entities'
import StatsPartnerships from 'pages/statistics/Partnerships'
import StatsInitiatives from 'pages/statistics/Initiatives'
import StatsExPost from 'pages/statistics/ExPost'
import StatsActivities from 'pages/statistics/Activities'
import StatsDirectBeneficiaries from 'pages/statistics/DirectBeneficiaries'
import StatsResults from 'pages/statistics/Results'
import StatsCountry from 'pages/statistics/Country'
import React from 'react'
import StatsCountryFunds from 'pages/statistics/Country/FundsCountry'
import FundsContainer from 'pages/manager/Funds/Funds.container'

const ConsortiumRoutes = () => [
  <PrivateRoute
    key={1}
    path="/consortium/initiative/:id/form/:formId/section/:sectionId"
    component={ManagerInitiative}
    layout={Layout}
  />,
  <PrivateRoute
    key={2}
    path="/consortium/concept-notes"
    component={ManagerCN}
    layout={Layout}
  />,
  <PrivateRoute
    key={3}
    path="/consortium/proposals"
    component={ManagerProposals}
    layout={Layout}
  />,
  <PrivateRoute
    key={4}
    path="/consortium/management/implementation-forms"
    component={ManagerManagement}
    layout={Layout}
  />,
  <PrivateRoute
    key={5}
    path="/consortium/management/contract-management"
    component={ManagerPurchasing}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/management/invoice-management"
    component={ManagerInvoice}
    layout={Layout}
  />,
  <PrivateRoute
    key={6}
    path="/consortium/dashboard"
    component={ManagerDashboard}
    layout={Layout}
  />,
  <PrivateRoute
    key={7}
    path="/consortium/dashboard-initiatives"
    component={ManagerDashboardInitiatives}
    layout={Layout}
  />,
  <PrivateRoute
    key={8}
    path="/consortium/dashboard-executed-budget"
    component={ManagerDashboardExecutedBudget}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/dashboard-preformulation-stage"
    component={ManagerDashboardPreformulation}
    layout={Layout}
  />,
  <PrivateRoute
    key={9}
    path="/consortium/management/expost-reporting"
    component={ManagerExpostReportList}
    layout={Layout}
  />,
  <PrivateRoute
    key={10}
    path="/consortium/expost-reporting/:formId"
    component={ManagerExpostReportForm}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/contacts"
    component={ManagerContacts}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/documents"
    component={ManagerDocuments}
    layout={Layout}
  />,
  // Statistics system
  <PrivateRoute
    path="/consortium/statistics/formulation"
    component={StatsFormulation}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/statistics/preformulation"
    component={StatsPreformulation}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/statistics/execution"
    component={StatsExecution}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/statistics/entities"
    component={StatsEntities}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/statistics/partnerships"
    component={StatsPartnerships}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/statistics/agenda2030"
    component={StatsInitiatives}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/statistics/expost"
    component={StatsExPost}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/statistics/direct-beneficiaries"
    component={StatsDirectBeneficiaries}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/statistics/activities"
    component={StatsActivities}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/statistics/results"
    component={StatsResults}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/statistics/country"
    component={StatsCountry}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/statistics/country-funds"
    component={StatsCountryFunds}
    layout={Layout}
  />,
  <PrivateRoute
    path="/consortium/funds"
    component={FundsContainer}
    layout={Layout}
  />,
]

export default ConsortiumRoutes
