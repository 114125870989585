import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  valueTC: Yup.string(),
  valueEU: Yup.string(),
  valueTP: Yup.string(),
  valueTC1: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(650, 'F1_S6_validation9'),
  valueTC2: Yup.string()
    .min(10,  'F3_S5_validation1')
    .max(650, 'F1_S6_validation9'),
  valueTC3: Yup.string()
    .min(10,  'F3_S5_validation1')
    .max(650, 'F1_S6_validation9'),
  valueTC4: Yup.string()
    .min(10,  'F3_S5_validation1')
    .max(650, 'F1_S6_validation9'),
  valueTC5: Yup.string()
    .min(10,  'F3_S5_validation1')
    .max(650, 'F1_S6_validation9'),
  valueTC6: Yup.string()
    .min(10,  'F3_S5_validation1')
    .max(650, 'F1_S6_validation9'),
  valueEUActors: Yup.string()
    .min(10,  'F3_S5_validation1')
    .max(650, 'F1_S6_validation9'),
  valueEUModalities: Yup.string()
    .min(10,  'F3_S5_validation1')
    .max(650, 'F1_S6_validation9'),
});
