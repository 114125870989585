import React from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { StyledFormLabel } from '../MyAssessmentForm/MyAssessmentForm.styled'
import { useTranslation } from 'react-i18next'
import TextValue from 'components/TextValue'
import _isNil from 'lodash/isNil';

const TAReviewReadOnly = ({ values }) => {
  const { t } = useTranslation()

  return (
    <>
      <Col style={{ marginTop: '30px', display: 'flex' }}>
        <StyledFormLabel xs={5}>{t('AT_recommendation')}:</StyledFormLabel>
        <Form.Check
          style={{ marginLeft: '30px', marginRight: '30px' }}
          type="radio"
          label={t('AT_recommendation_option1')}
          disabled={true}
          checked={_isNil(values.recommends) ? null : values.recommends}
        />
        <Form.Check
          type="radio"
          label={t('AT_recommendation_option2')}
          disabled={true}
          checked={_isNil(values.recommends) ? null: !values.recommends}
        />
      </Col>

      <Col style={{ marginTop: '20px', marginBottom: '50px' }}>
        <StyledFormLabel xs={5}>{t('AT_justification')}:</StyledFormLabel>
        <TextValue value={values.recommendationJustification} />
      </Col>
    </>
  )
}

export default TAReviewReadOnly
