/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRootStore } from 'stores'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import MoneyTextMask from 'components/MoneyTextMask'
import {
  getInvoiceManagment,
  getImplementationInitiatives,
  getAllProvidersF5S7,
} from 'services/initiative'

import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageTitle from 'components/PageTitle'
import {
  PageHeader,
  PageContent,
  CenteredRow,
} from './InvoiceManagement.styled'
import { PrimaryButton } from 'components/Buttons'
import CustomExportXLSXButton from './CustomExportXLSXButton'
import { TARGET_YEARS } from 'constants/appConstants'
import { renderYearOptions } from 'pages/statistics/CommonHelpers/helpers'

const InvoiceManagement = () => {
  const { t } = useTranslation()
  const { authStore } = useRootStore()

  const [filterProvider , setFilterProvider] = useState(0)
  const [filterInitiative, setFilterInitiative] = useState(0)
  const [filterCompleted, setFilterCompleted] = useState(0)
  const [filterPaid, setFilterPaid] = useState(0)
  const [filterEU, setFilterEU] = useState(0)
  const [filterYear, setFilterYear] = useState(0)

  const [tableValues, setTableValues] = useState([])

  const [initiativesOptions, setInitiativesOptions] = useState([])
  const [providersOptions, setProvidersOptions] = useState([])

  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const [waiting, setWaiting] = useState(false)

  const [dataToExport, setDataToExport] = useState([])

  const [params, setParams] = useState({})

  useEffect(() => {
    getInitiativesandProviders()
    getValues(0, false, {})
    getAllPages({})
  }, [])

  async function getInitiativesandProviders() {
    try {
      const initiatives = await getImplementationInitiatives()
      setInitiativesOptions(initiatives)
      const providers = await getAllProvidersF5S7()
      setProvidersOptions(providers.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber)
    getValues(newPageNumber, true, params)
  }

  async function getValues(pageNumber, willFilter, filterParams) {
    setWaiting(true)
    try {
      let customParams = {
        page: pageNumber,
        pageSize: 100,
      }
      if (willFilter) {
        customParams = {
          ...customParams,
          ...filterParams,
        }
      }
      const response = await getInvoiceManagment(customParams);
      const data = response.data.content
      setTotalPages(response.data.totalPages)
      data.forEach((item) => {
        item.category = t(`resource_type_${item.category}`)
        item.budget = t(`table_budget_${item.budget}`)
        item.status = t(`table_status_${item.status}`)
      })
      setTableValues([...data])
      setWaiting(false)
    } catch (error) {
      console.log(error)
      setWaiting(false)
    }
  }

  async function getAllPages(filterParams) {
    try {
      const response = await getInvoiceManagment(filterParams)
      const data = response.data.content;
      data.forEach((item) => {
        item.category = t(`resource_type_${item.category}`)
        item.paymentEnabled = item.paymentEnabled ? t('Yes') : t('No');
        item.paid = item.paymentEnabled ? t('Yes') : t('No');
        item.invoiced = item.paymentEnabled ? t('Yes') : t('No');
        item.activityNumber = `A${item.activityNumber}`;
      })
      setDataToExport(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeInitiative = (e) => {
    setFilterInitiative(e.target.value)
  }

  const handleChangeCompleted = (e) => {
    setFilterCompleted(e.target.value)
  }

  const handleChangePaid = (e) => {
    setFilterPaid(e.target.value)
  }

  const handleChangeEU = (e) => {
    setFilterEU(e.target.value)
  }

  const handleChangeProvider = (e) => {
    setFilterProvider(e.target.value)
  }

  const handleFilter = () => {
    let customParams = {}
    if (filterInitiative != 0) customParams.initiativeId = filterInitiative;
    if (filterProvider != 0) customParams.providerId = filterProvider;
    if (filterCompleted != 0) customParams.completed = filterCompleted === 1;
    if (filterPaid != 0) customParams.paid = filterPaid === 1;
    if (filterEU != 0) customParams.invoiced = filterEU === 1;
    if (filterYear != 0) params.year = filterYear
    setParams(customParams)
    getValues(0, true, customParams)
    getAllPages(customParams)
    handlePageChange(0)
  }

  const renderBinaryOptions = () => {
    let options = []
    options.push(<option value={0}> {t('ALL')} </option>)
    options.push(<option value={1}> {t('Yes')} </option>)
    options.push(<option value={2}> {t('No')} </option>)
    return options
  }

  const columns = [
    {
      dataField: 'initiative',
      text: t('Initiative'),
      headerStyle: { width: '110px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <Link
            to={`${authStore.getBaseRouteByRole()}/initiative/${
              row.initiativeId
            }/form/5/section/7?displayExecutedBudget=true&activityNumber=${
              row.activityId
            }`}
            target="_blank"
            rel="noreferrer"
          >
            {row.initiative}
          </Link>
        </div>
      ),
    },
    {
      dataField: 'initiativeId',
      text: '',
      hidden: true,
      csvExport: false,
    },
    {
      dataField: 'activityId',
      text: '',
      hidden: true,
      csvExport: false,
    },
    {
      dataField: 'activityNumber',
      text: t('Activity'),
      headerStyle: { width: '80px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
      formatter: (cell, row, rowIndex, extraData) => `A${row.activityNumber}`,
    },
    {
      dataField: 'category',
      text: t('Category'),
      headerStyle: { width: '120px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
    },
    {
      dataField: 'provider',
      text: t('Provider'),
      headerStyle: { width: '250px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
    },
    {
      dataField: 'description',
      text: t('Description'),
      headerStyle: { width: '180px', textAlign: 'center', fontSize: '12px' },
      formatter: (cell, row, rowIndex, extraData) => (
        <div style={{ fontSize: '12px', textAlign: 'justify' }}>
          {`${row.description.split(' ').splice(0, 20).join(' ')} ${
            row.description.split(' ').length > 20 ? '...' : ''
          }`}
        </div>
      ),
    },
    {
      dataField: 'amount',
      text: t('invoice_mgmt_amount'),
      headerStyle: { width: '120px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
      footerStyle: { textAlign: 'center', fontSize: '13px' },
      formatter: (cell, row, rowIndex, extraData) => (
        <MoneyTextMask value={row.amount} />
      ),
    },
    {
      dataField: 'paymentEnabled',
      text: t('invoice_mgmt_completed'),
      formatter: (cell, row, rowIndex, extraData) => (
        <div> { row.paymentEnabled ? t('Yes') : t('No')} </div>
      ),
      headerStyle: { width: '70px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
    },
    {
      dataField: 'paid',
      text: t('invoice_mgmt_paid'),
      formatter: (cell, row, rowIndex, extraData) => (
        <div> { row.paid ? t('Yes') : t('No')} </div>
      ),
      headerStyle: { width: '70px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
    },
    {
      dataField: 'invoiced',
      text: t('invoice_mgmt_eu'),
      formatter: (cell, row, rowIndex, extraData) => (
        <div> { row.invoiced ? t('Yes') : t('No')} </div>
      ),
      headerStyle: { width: '70px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
    },
  ]

  const pagination = paginationFactory({
    sizePerPage: 100,
    hideSizePerPage: true,
  })

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={tableValues}
        columns={columns}
        pagination={pagination}
        exportCSV={{
          fileName: `${t('menu_admin_21')}.csv`,
          separator: ',',
          ignoreHeader: false,
          noAutoBOM: false,
          blobType: 'text/csv;charset=UTF-8',
        }}
      >
        {(props) => (
          <>
            <PageHeader>
              <PageTitle title={t('menu_admin_21_1')} />
            </PageHeader>
            <Row style={{ alignItems: 'center' }}>
              <Col style={{ display: 'flex', alignItems: 'center' }}>
                {t('Initiative')}
                <Form.Control
                  style={{ marginLeft: '10px', marginRight: '10px', maxWidth: '250px' }}
                  as="select"
                  onChange={handleChangeInitiative}
                  value={filterInitiative}
                >
                  <option value={0}> {t('ALL')} </option>
                  {initiativesOptions &&
                    initiativesOptions.map((e) => (
                      <option value={e.id}> {e.code} </option>
                    ))}
                </Form.Control>

                {t('Provider')}
                <Form.Control
                  style={{ marginLeft: '10px', marginRight: '10px', maxWidth: '250px' }}
                  as="select"
                  onChange={handleChangeProvider}
                  value={filterProvider}
                >
                  <option value={0}> {t('ALL')} </option>
                  {providersOptions &&
                    providersOptions.map((e) => (
                      <option value={e.id}> {e.name} </option>
                    ))}
                </Form.Control>

                {t('invoice_mgmt_completed')}
                <Form.Control
                  style={{ marginLeft: '10px', marginRight: '10px', maxWidth: '100px' }}
                  as="select"
                  onChange={handleChangeCompleted}
                  value={filterCompleted}
                >
                  {renderBinaryOptions()}
                </Form.Control>

                {t('invoice_mgmt_paid')}
                <Form.Control
                  style={{ marginLeft: '10px', marginRight: '15px', maxWidth: '100px' }}
                  as="select"
                  onChange={handleChangePaid}
                  value={filterPaid}
                >
                  {renderBinaryOptions()}
                </Form.Control>

                {t('invoice_mgmt_eu')}
                <Form.Control
                  style={{ marginLeft: '10px', marginRight: '15px', maxWidth: '100px' }}
                  as="select"
                  onChange={handleChangeEU}
                  value={filterEU}
                >
                  {renderBinaryOptions()}
                </Form.Control>

                {t('Dashboard_Year')}
                <Form.Control
                  name="year"
                  as="select"
                  onChange={(e) => {
                    setFilterYear(e.target.value)
                  }}
                  value={filterYear}
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                >
                  {renderYearOptions(TARGET_YEARS)}
                </Form.Control>
              </Col>
            </Row>
            <Row style={{ alignItems: 'center', marginTop: '10px' }}>
              <Col sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                <PrimaryButton
                  onClick={handleFilter}
                  disabled={waiting}
                  style={{ marginRight: '20px' }}
                >
                  {t('Button_filter')}
                </PrimaryButton>
                <CustomExportXLSXButton
                  {...props.csvProps}
                  values={dataToExport}
                  disabled={waiting}
                />
              </Col>
            </Row>
            <PageContent>
              <BootstrapTable {...props.baseProps} />
            </PageContent>
          </>
        )}
      </ToolkitProvider>
      <CenteredRow>
        <Pagination>
          <Pagination.First disabled={currentPage == 0} onClick={() => handlePageChange(0)} />
          {totalPages !== 0 && currentPage !== 0 && <Pagination.Ellipsis />}
          {currentPage !== 0 && <Pagination.Item onClick={() => handlePageChange(currentPage - 1)} >{currentPage - 1}</Pagination.Item>}
          <Pagination.Item active> {currentPage} </Pagination.Item>
          {totalPages !== 0 && currentPage !== (totalPages - 1) && <Pagination.Item onClick={() => handlePageChange(currentPage + 1)} >{currentPage + 1}</Pagination.Item>}
          {totalPages !== 0 && currentPage !== (totalPages - 1) && <Pagination.Ellipsis />}
          <Pagination.Last disabled={currentPage == (totalPages - 1)} onClick={() => handlePageChange(totalPages - 1)}/>
        </Pagination>
      </CenteredRow>
    </>
  )
}

export default observer(InvoiceManagement)
