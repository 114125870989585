import { useEffect, useState } from 'react';
import { toJS } from 'mobx'
import { useRootStore } from 'stores';

const initialState = {
  intpaComment: null,
  eudComments: null,
}

export const useGetComments = () => {

  const { formStore } = useRootStore();
  const [values, setValues] = useState(initialState);

  useEffect(() => {
    const comments = { 
      ...initialState,
      intpaComment: formStore.intpaComment,
      eudComments: toJS(formStore.eudComments)
    }
    setValues(comments);
  }, [formStore.intpaComment, formStore.eudComments]);

  return values;
}