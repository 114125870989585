import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActivitySubItem, CompleteImage } from './ActivityItem.styled'

import completeIcon from 'assets/complete-mark.png';

const ActivityItem = ({ handleRefreshList, handleOpenGeneralModal, activity }) => {

  const {t} = useTranslation();
  
  const handleOpenGeneralInfo = () => {
    handleOpenGeneralModal({
      handleRefreshList: handleRefreshList,
      show: true,
      formType: 0,
      title: t('F3_S7_Subtitle_General_Information'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const handleOpenProducts = () => {
    handleOpenGeneralModal({
      handleRefreshList: handleRefreshList,
      show: true,
      formType: 1,
      title: t('F3_S7_Subtitle_Products'),
      activity: activity,
      activityCode: activity.name,
      info: t('F3_S7_Subtitle_Products_INFO'),
    });
  }

  const handleOpenConsultants = () => {
    handleOpenGeneralModal({
      handleRefreshList: handleRefreshList,
      show: true,
      formType: 2,
      title: t('F3_S7_Subtitle_Consultants'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const handleOpenParticipants = () => {
    handleOpenGeneralModal({
      handleRefreshList: handleRefreshList,
      show: true,
      formType: 3,
      title: t('F3_S7_Subtitle_Participants_assistance'),
      activity: activity,
      activityCode: activity.name,
      info: t('F3_S7_Subtitle_Participants_assistance_INFO'),
    });
  }

  const handleOpenResources = () => {
    handleOpenGeneralModal({
      handleRefreshList: handleRefreshList,
      show: true,
      formType: 4,
      title: t('F3_S7_Subtitle_Resources_required'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  return (
    <>
      <ActivitySubItem onClick={() => {handleOpenGeneralInfo()}}> {t('F3_S7_Subtitle_General_Information')} {(activity.general_info_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>
      <ActivitySubItem onClick={() => {handleOpenProducts()}}> {t('F3_S7_Subtitle_Products')} {(activity.product_list_complete) &&  <CompleteImage src={completeIcon} />}</ActivitySubItem>
      {(activity.activityType && activity.activityType.id == 4 ) && <ActivitySubItem onClick={() => {handleOpenConsultants()}}> {t('F3_S7_Subtitle_Consultants')} {(activity.profile_list_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>}
      {(activity.activityType && activity.activityType.id != 4 ) && <ActivitySubItem onClick={() => {handleOpenParticipants()}}> {t('F3_S7_Subtitle_Participants_assistance')} {(activity.participant_list_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>}
      <ActivitySubItem onClick={() => {handleOpenResources()}}> {t('F3_S7_Subtitle_Resources_required')} {(activity.resource_list_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>
    </>
  )

}

export default ActivityItem;
