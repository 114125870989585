import React, { useState } from 'react'
import DayJS from 'dayjs'

import { formInitialValues } from '../Section9.initialValues'
import { PrimaryButton } from 'components/Buttons'
import { f2statusNamesConst } from 'constants/appConstants'

import F2ADialog from '../F2ADialog'
import InviteDialog from '../InviteDialog'

import { StyledAccordionBodyDiv, StyledEUDLink } from './EUDAssessments.styled'
import { SeparatorDotted } from 'components/CommonStyled'
import { StyledTitle } from '../Section9.styled'
import { useTranslation } from 'react-i18next'

const EUDAssessments = ({
  isIntpa,
  f2AValues,
  intpaAssessment,
  initiativeCode,
  nonEditable,
}) => {
  const { t } = useTranslation()
  const [displayModalInvite, setDisplayModalInvite] = useState(false)
  const handleOpenModalInvite = () => setDisplayModalInvite(true)
  const handleCloseModalInvite = () => setDisplayModalInvite(false)

  const [displayModalF2A, setDisplayModalF2A] = useState(false)
  const handleOpenModalF2A = () => setDisplayModalF2A(true)
  const handleCloseModalF2A = () => setDisplayModalF2A(false)

  const [currentF2AValues, setCurrentF2AValues] = useState(formInitialValues)

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr)
    return date.format('DD/MM/YYYY')
  }

  const handleClick = (f2a) => {
    setCurrentF2AValues(f2a)
    handleOpenModalF2A()
  }

  const renderF2AValues = () => {
    return f2AValues.map((f2a) => (
      <div>
        {`${f2a.user.organization}, ${f2a.user.name} ${f2a.user.lastName} - `}
        {f2a.stateName === f2statusNamesConst.DONE ? (
          <StyledEUDLink
            key={f2a.id}
            value={f2a.id}
            onClick={() => {
              handleClick(f2a)
            }}
          >
            {t('Received_on') +
              ' ' +
              (f2a && f2a.statusTimestamp
                ? formatDate(f2a.statusTimestamp)
                : '-')}
          </StyledEUDLink>
        ) : (
          t('Pending')
        )}
      </div>
    ))
  }

  return (
    <>
      {displayModalInvite && (
        <InviteDialog
          displayDialog={displayModalInvite}
          onCloseModal={handleCloseModalInvite}
        />
      )}

      {displayModalF2A && (
        <F2ADialog
          values={currentF2AValues}
          displayDialog={displayModalF2A}
          onCloseModal={handleCloseModalF2A}
          initiative={initiativeCode}
        />
      )}

      <StyledTitle>{t('F2_EUDs_assessments')}</StyledTitle>

      <StyledAccordionBodyDiv>
        {renderF2AValues()}

        <div style={{ width: '400px' }}>
          <SeparatorDotted />

          {!isIntpa && (
            <>
              {`INTPA - `}
              {intpaAssessment &&
              intpaAssessment.stateName === f2statusNamesConst.DONE ? (
                <StyledEUDLink
                  onClick={() => {
                    handleClick(intpaAssessment)
                  }}
                >
                  {t('Received_on') + ' ' + intpaAssessment.statusTimestamp
                    ? formatDate(intpaAssessment.statusTimestamp)
                    : '-'}
                </StyledEUDLink>
              ) : (
                t('Pending')
              )}
            </>
          )}
        </div>

        {isIntpa && (
          <div style={{ marginTop: '30px' }}>
            <PrimaryButton
              disabled={nonEditable}
              onClick={handleOpenModalInvite}
            >
              {'+' + ' ' + t('F2_invite_assessors')}
            </PrimaryButton>
          </div>
        )}
      </StyledAccordionBodyDiv>
    </>
  )
}

export default EUDAssessments
