export const formInitialValues = {
    fromDate: "",
    toDate: "",
    title: "",
    duration: 0,
    participants: 0,
    experts: 0,
    daysRemote: 0,
    daysOnsite: 0,
    workLang1: null,
    workLang2: null,
    workLang3: null,
    workLang4: null,
    justification: "",
    objective: "",
    learning: "",
    descSchedule: "",
    workPlans: "",
    genInfoConfirmed: null,
};