import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { formInitialValues } from './Section4.initialValues';
import { useTranslation } from 'react-i18next';
import Comments from 'components/Forms/Form4/Comments';
import isEmpty from 'lodash/isEmpty';

import FormLayout from '../../FormLayout';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import FormContainer from 'components/FormContainer';
import OtherEntitiesForm from './OtherEntitiesForm';

import { StyledSeparator } from './Section4.styled';
import { arrayOrderHelper } from 'helpers/arrayOrderHelper';

const Section4 = () => {
  const { t } = useTranslation();
  const { formStore } = useRootStore();
  const [formValues, setFormValues] = useState(formInitialValues);
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);

  const handleSaved = value => {
    setSaved(value);
  };

  const handleButton = value => {
    setNonEditable(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = [...formStore.formSectionData];
      setFormValues(arrayOrderHelper(formSectionData));
      formStore.setCurrentSection(4);
    }
  }, [formStore.formSectionData]);

  return (
    <FormLayout>
      <FormContainer
        number={4}
        title={t('F1_S4_title')}
        formId="form4section4"
        showSavedMessage={saved}
        showButtonClarif={!nonEditable}
      >
        <Accordion style={{ paddingLeft: '40px' }}>
          {formValues &&
            formValues.map((entity, key) => (
              <AccordionItem key={key + 1}>
                <AccordionItemHeader>
                  <AccordionTitle eventKey={key + 1}>{`${t('F1_S4_Accordion')} ${key + 1} ${
                    isEmpty(entity.entityName) ? '' : `- ${entity.entityName}`
                  }`}</AccordionTitle>
                </AccordionItemHeader>
                <Accordion.Collapse eventKey={key + 1}>
                  <AccordionItemBody>
                    <OtherEntitiesForm data={entity} />
                  </AccordionItemBody>
                </Accordion.Collapse>
              </AccordionItem>
            ))}
        </Accordion>

        <StyledSeparator />

        <Comments onSaved={handleSaved} onButton={handleButton} />
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section4);
