import React, { useState, useEffect } from 'react'
import { validationSchema } from './Section7.validations'
import { observer } from 'mobx-react-lite'
import { useRootStore } from 'stores'
import { Formik, FieldArray } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getFormSection } from 'services/initiative'
import { deleteActivityF3S7 } from 'services/initiative'
import htmlParser from 'html-react-parser'
import { f3statusNamesConst, roleNamesConst } from 'constants/appConstants'
import { formatDateDDMMYY } from 'helpers/dateHelpers'

import { PrimaryButton, ErrorButton } from 'components/Buttons'
import { SeparatorDotted } from 'components/CommonStyled'
import ConfirmCloseModal from 'components/ConfirmCloseModal'
import CustomModal from 'components/Modal'
import ActivityItem from './ActivityItem'
import ActivityGeneralModal from './ActivityGeneralModal'
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer'
import Accordion from 'react-bootstrap/Accordion'
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from 'components/CustomAccordion'
import {
  mustShowFootNote,
  mustShowOldFootNote,
  hasOldBudgetValidation,
} from 'constants/budgetConstants'
import {
  StyledBudgetDiv,
  StyledNoticeParagraph,
  BlackSpan,
} from 'components/Forms/Form5/Section7/Section7.styled'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import { DisabledSectionDiv, StyledWarningP } from './Section7.styled'
import MoneyTextMask from 'components/MoneyTextMask'
import { useGetBudgetRestrictions } from 'customHooks/useGetBudgetRestrictions'

const Section7Disabled = () => {
  const { t } = useTranslation()

  return (
    <DisabledSectionDiv>
      {htmlParser(t('F3_S7_warning_draft'))}
    </DisabledSectionDiv>
  )
}

const Section7v2 = () => {
  const [confirmationModalShow, setConfirmationModalShow] = useState(false)
  const [activityModal, setActivityModal] = useState({
    formikInstance: null,
    show: false,
    formType: 0,
    title: '',
    activity: {},
    activityCode: '',
  })
  const { initiativeStore, formStore } = useRootStore()
  const { t } = useTranslation()
  const [modalRemove, setModalRemove] = useState(false)
  const [activityToDeleteName, setActivityToDeleteName] = useState('')
  const [selectedKey, setSelectedKey] = useState(null)
  const [initialValues, setInitialValues] = useState({
    activities: [],
  })
  const { id, formId, sectionId } = useParams()
  const [nonEditable, setNonEditable] = useState(false)
  const {
    euBudgetMin,
    euBudgetLimit,
    euBudgetPercentageLimit
  } = useGetBudgetRestrictions();

  const [accordionRerenderKey, setAccordionRerenderKey] = useState(1)
  const handleReinitialize = () =>
    setAccordionRerenderKey(accordionRerenderKey + 1)

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f3statusNamesConst.DRAFT ||
        initiativeStore.formVersion != 2 ||
        initiativeStore.role === roleNamesConst.GUEST,
    )
  }, [initiativeStore.formStateName])

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    message: '',
    type: 'info',
  })

  useEffect(() => {
    if (
      formStore.formSectionData &&
      formStore.formSectionData[0] &&
      formStore.formSectionData[0]
    ) {
      setInitialValues({ ...formStore.formSectionData[0] })
    }
  }, [formStore.formSectionData])

  const onHideModalInfo = () => {
    setModalInfo({ ...modalInfo, isOpen: false })
  }

  const openModalRemove = (activityId, activityName) => {
    setModalRemove(true)
    setActivityToDeleteName(activityName)
    setSelectedKey(activityId)
  }

  const closeModalRemove = () => {
    setModalRemove(false)
    setSelectedKey(null)
  }

  const handleCloseGeneralModal = () => {
    setActivityModal({
      ...activityModal,
      show: false,
    })
  }

  const handleOpenNewActivityForm = () => {
    setActivityModal({
      formikInstance: null,
      show: true,
      formType: 5,
      handleClose: handleCloseGeneralModal,
      title: t('New_activity'),
      activityCode: '',
    })
  }

  const isSection7Complete = (activities) => {
    let isComplete = Array.isArray(activities) && activities.length >= 1
    activities.forEach((activity) => {
      isComplete = isComplete && activity.complete
    })
    return isComplete
  }

  const handleRefreshList = async () => {
    try {
      const response = await getFormSection(id, formId, sectionId)
      if (response.section) {
        const activities = [...response.section[0].activities]
        setInitialValues({
          ...response?.section?.[0],
        })
        const isComplete = isSection7Complete(activities)
        formStore.setSectionStatus(sectionId, isComplete)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleRefreshList()
  }, [])

  const hasOldValidation = hasOldBudgetValidation(initiativeStore?.code)

  return (
    <>
      <CustomModal
        show={modalInfo.isOpen}
        onHide={onHideModalInfo}
        message={modalInfo.message}
        type={modalInfo.type}
      />

      <ConfirmCloseModal
        show={confirmationModalShow}
        handleCloseConfirm={() => setConfirmationModalShow(false)}
        handleActionOnConfirm={handleCloseGeneralModal}
        text={t('Confirmation close modal')}
      />

      <ActivityGeneralModal
        handleRefreshList={handleRefreshList}
        show={activityModal.show}
        formType={activityModal.formType}
        handleClose={() => {
          setConfirmationModalShow(true)
        }}
        handleCloseOnSave={() => {
          handleCloseGeneralModal()
          setModalInfo({
            ...modalInfo,
            message: t('saved'),
            type: 'success',
            isOpen: true,
          })
          setTimeout(function () {
            onHideModalInfo()
          }, 2000)
        }}
        handleCloseOnError={() => {
          setModalInfo({
            ...modalInfo,
            message: t('Error try again later'),
            type: 'error',
            isOpen: true,
          })
        }}
        handleCloseOk={() => {
          handleCloseGeneralModal();
          handleRefreshList()
        }}
        title={activityModal.title}
        activity={activityModal.activity}
        activityCode={activityModal.activityCode}
        setModalInfo={setModalInfo}
        nonEditable={nonEditable}
        info={activityModal.info}
      />

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { values } = props;
          const footnoteOn =
            hasOldValidation ?
              mustShowOldFootNote(
                values.totalBudget,
                values.ueBudget,
                euBudgetMin,
                euBudgetPercentageLimit
              ) :
              mustShowFootNote(
                values?.totalBudget,
                values?.ueBudget,
                euBudgetMin,
                euBudgetLimit,
                euBudgetPercentageLimit
              );

          const footnoteMsg =
            hasOldValidation ?
              t('F1_S7_budget_foot_note')
              :
              t('F1_S7_budget_foot_note2024');

          return (
            <form id="form3section7">
              <div key={accordionRerenderKey}>
                <FieldArray
                  name="activities"
                  // eslint-disable-next-line no-unused-vars
                  render={(arrayHelpers) => (
                    <>
                      {modalRemove && (
                        <ConfirmCloseModal
                          show={modalRemove}
                          text={`${t(
                            'Confirmation remove activity',
                          )} ${activityToDeleteName}?`}
                          handleActionOnConfirm={async () => {
                            await deleteActivityF3S7(id, selectedKey)
                            await handleRefreshList()
                            closeModalRemove()
                            handleReinitialize()
                          }}
                          handleCloseConfirm={closeModalRemove}
                        />
                      )}

                      <StyledBudgetDiv
                        style={{ marginLeft: '50px', marginBottom: '20px' }}
                      >
                        <Form.Group as={Row} style={{ alignItems: 'center' }}>
                          <Form.Label column xs={5}>
                            {t('F1_S7_EU_budget')}:
                          </Form.Label>
                          <Col xs={3}>
                            <InputGroup>
                              <BlackSpan>
                                <MoneyTextMask
                                  value={props.values.ueBudget}
                                  disabled={true}
                                  customInput={Form.Control}
                                />
                              </BlackSpan>
                            </InputGroup>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} style={{ alignItems: 'center' }}>
                          <Form.Label column xs={5}>
                            {t('F1_S7_counterpart_budget')}:
                          </Form.Label>
                          <Col xs={3}>
                            <InputGroup>
                              <BlackSpan>
                                <MoneyTextMask
                                  value={props.values.allianceBudget}
                                  disabled={true}
                                  customInput={Form.Control}
                                />
                              </BlackSpan>
                            </InputGroup>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} style={{ alignItems: 'center' }}>
                          <Form.Label column xs={5}>
                            {t('F5_S7_Total_amount_executed')}:
                          </Form.Label>
                          <Col xs={3}>
                            <InputGroup>
                              <BlackSpan>
                                <MoneyTextMask
                                  value={props.values.totalBudget}
                                  disabled={true}
                                  customInput={Form.Control}
                                />
                              </BlackSpan>
                            </InputGroup>
                          </Col>
                        </Form.Group>

                        {
                          footnoteOn ? (
                            <StyledNoticeParagraph>
                              {footnoteMsg}
                            </StyledNoticeParagraph>
                          ) : null
                        }
                        {htmlParser(t('F1_S7_budget_foot_link'))}
                        <br /><br />
                        {htmlParser(t('F1_S7_budget_foot_link2'))}
                      </StyledBudgetDiv>
                      <Accordion style={{ paddingLeft: '35px' }}>
                        {props.values.activities.map((activity, key) => (
                          <AccordionItem key={key + 1}>
                            <AccordionItemHeader>
                              <AccordionTitle eventKey={key + 1}>{`${
                                activity.name
                              } - ${t(
                                `activity_types_${activity.activityType.id}`,
                              )} (${
                                activity.fromDate
                                  ? formatDateDDMMYY(activity.fromDate)
                                  : '-'
                              })`}</AccordionTitle>
                            </AccordionItemHeader>
                            <Accordion.Collapse eventKey={key + 1}>
                              <AccordionItemBody style={{ marginLeft: '30px' }}>
                                <ActivityItem
                                  handleRefreshList={handleRefreshList}
                                  handleOpenGeneralModal={setActivityModal}
                                  activity={activity}
                                />
                                <ErrorButton
                                  onClick={() =>
                                    openModalRemove(activity.id, activity.name)
                                  }
                                  disabled={nonEditable}
                                >
                                  {t('F1_S7_Button_remove_activity')}
                                </ErrorButton>
                              </AccordionItemBody>
                            </Accordion.Collapse>
                          </AccordionItem>
                        ))}
                      </Accordion>
                      <div style={{ marginLeft: '50px' }}>
                        {typeof props.errors.activities === 'string' ? (
                          <StyledWarningP>
                            {props.errors.activities}
                          </StyledWarningP>
                        ) : null}
                        <SeparatorDotted />
                        <PrimaryButton
                          onClick={handleOpenNewActivityForm}
                          disabled={nonEditable}
                        >
                          {t('F1_S7_Button_add_activity')}
                        </PrimaryButton>
                      </div>
                    </>
                  )}
                />
              </div>
            </form>
          )
        }}
      </Formik>
    </>
  )
}

const Section7 = () => {
  const { initiativeStore } = useRootStore()
  const { t } = useTranslation()
  return (
    <FormLayout>
      <FormContainer number={7} title={t('F3_S7_title')} formId="form3section7"  isHelp>
        {initiativeStore.formVersion == 1 ? (
          <Section7Disabled />
        ) : (
          <Section7v2 />
        )}
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section7)
