import React, { useState, useEffect } from 'react'
import { Formik, FieldArray } from 'formik';
import { formInitialValues } from './ConsultantsForm.initialValues'
import { validationSchema } from './ConsultantsForm.validations';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { saveConsultantF3S7 } from 'services/initiative';

import ConfirmCloseModal from 'components/ConfirmCloseModal';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';

import { PrimaryButton, ErrorButton, StyledSendButton } from 'components/Buttons';
import "react-datepicker/dist/react-datepicker.css";
import { Form } from 'react-bootstrap';
import { FormFielErrorMessage, SeparatorDotted, CenteredModalButtonContainer } from 'components/CommonStyled';
import ConsultantForm from './ConsultantForm';

const consultantObject = {
  skills: "",
  generalExp: "",
  specificExp: "",
  interpretation: ""
}

const ConsultantsForm = ({ handleCloseOk, handleClose, activity, handleCloseSubModalOnSave, handleCloseSubModalOnError, nonEditable }) => {

  const { t } = useTranslation();
  const { id } = useParams();
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [initialValues, setInitialValues] = useState(formInitialValues);

  const openModalRemove = (key) => {
    setModalRemove(true);
    setSelectedKey(key);
  }

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  }

  useEffect(() => {
    const formValues = {...formInitialValues, ...activity};
    setInitialValues(formValues);
  }, [])

  
  const onSave = async (values) => {
    try {
      await saveConsultantF3S7(id, activity.id, values.profileList);
      handleCloseSubModalOnSave();
    } catch (error) {
      handleCloseSubModalOnError();
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSave}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <FieldArray 
              name='profileList'
              render={arrayHelpers => (
                <>
                  {modalRemove && (
                    <ConfirmCloseModal 
                      show={modalRemove}
                      text={`Delete consultant ${selectedKey+1}?`}
                      handleActionOnConfirm={() => {
                        arrayHelpers.remove(selectedKey);
                        closeModalRemove();
                      }}
                      handleCloseConfirm={closeModalRemove}
                    />
                  )}
                  <Accordion>
                    {props.values.profileList
                      ? ( props.values.profileList.map((consultant, key) => (
                          <AccordionItem key={key+1}>
                            <AccordionItemHeader>
                              <AccordionTitle eventKey={key+1}>{t('F3_S7_Accordion_consultant')+" "+(key+1)}</AccordionTitle>
                            </AccordionItemHeader>
                            <Accordion.Collapse eventKey={key+1}>
                              <AccordionItemBody>
                                <ConsultantForm formikInstance={props} consultantIndex={key} nonEditable={nonEditable}/> 
                                <ErrorButton disabled={nonEditable} onClick={() => openModalRemove(key)}>{t('F3_S7_Consultant_button_delete')}</ErrorButton>
                              </AccordionItemBody>
                            </Accordion.Collapse>
                          </AccordionItem>
                        )))
                      : null}
                  </Accordion>
                  { 
                    typeof props.errors.profileList === 'string' 
                      ? <FormFielErrorMessage>{props.errors.profileList}</FormFielErrorMessage> 
                      : null
                  }
                  <SeparatorDotted />
                  <PrimaryButton disabled={nonEditable} onClick={() => arrayHelpers.push(consultantObject)} >{t('F3_S7_Consultant_button_add')}</PrimaryButton>
                </>
              )}
            />
            <CenteredModalButtonContainer>
              {
                nonEditable
                ?
                  <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
                :
                  <>
                    <ErrorButton style={{marginRight: '20px'}} onClick={handleClose} disabled={nonEditable}> {t('Button_cancel')} </ErrorButton>
                    <StyledSendButton onClick={() => {onSave(props.values)}} disabled={nonEditable}> {t('Button_save')} </StyledSendButton>
                  </>
              }
            </CenteredModalButtonContainer>
            
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ConsultantsForm;