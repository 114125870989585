import React, { useState, useEffect } from 'react'
import Header1 from '../components/Header1'
import Table1 from '../components/Table1'
import { getInitiativesSummary } from 'services/initiative'
import Row from 'react-bootstrap/Row'
import { StyledDiv, StyledRow, StyledRow2 } from '../../CommonStyled/Tabs.styled'
import { T5RenderRows, getParamObject } from '../helpers/helpers'
import Table6 from 'pages/statistics/Entities/components/Table6'
import { getFundsODSSummary } from 'services/fundsStatistics'
import { useTranslation } from 'react-i18next'

const InitiativesSummary = () => {
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const [data2, setData2] = useState(null)
  const [waiting2, setWaiting2] = useState(false)

  const getData = async (param) => {
    setWaiting(true)
    try {
      const parsedParams = param?.odsP && param?.odsS ? undefined : { odsType: param?.odsP ? 'primary' : 'secondary' };
      const response = await getInitiativesSummary(parsedParams)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  const getData2 = async (param) => {
    setWaiting2(true)
    try {
      const parsedParams = param?.odsP && param?.odsS ? undefined : { odsType: param?.odsP ? 'primary' : 'secondary' };
      let response = await getFundsODSSummary(parsedParams)
      setData2(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting2(false)
    }
  }


  useEffect(() => {
    getData(getParamObject(0))
    getData2(getParamObject(0))
  }, [])

  return (
    <StyledDiv>
      <Row>
        <Header1 data={data} getData={getData} waiting={waiting} data2={data2} getData2={getData2} />
      </Row>
      <StyledRow>
        <Table1 data={data} waiting={waiting} setData={setData} />
      </StyledRow>
      <StyledRow2 style={{marginLeft: '0px'}}>
        <Table6
          title={t('Statistics_title_initiative_summary')}
          data={data2}
          waiting={waiting2}
          renderRows={T5RenderRows}
          setData={setData2}
          customWidth='63%'
          customWidthRow1='84%'
          customWidthRow2='16%'
          total={true}
        />
      </StyledRow2>
    </StyledDiv>
  )
}

export default InitiativesSummary
