import React from 'react'
import { useTranslation } from 'react-i18next';

import ResourcesForm from '../ResourcesForm';
import ParticipantsForm from '../ParticipantsForm';
import ConsultantsForm from '../ConsultantsForm';
import ProductsForm from '../ProductsForm';
import GeneralInfoForm from '../GeneralInfoForm';
import Modal from 'react-bootstrap/Modal';
import { 
  StyledModalBody,
  ModalTitle
} from './ActivityGeneralModal.styled'

const ActivityGeneralModal = ({show, formType, title, activity, activityCode, handleCloseOk}) => {
  
  const { t } = useTranslation();

  const getFormByType = (type) => {
    const fromByType = {
      [0]: <GeneralInfoForm handleCloseOk={handleCloseOk} activity={activity} />,
      [1]: <ProductsForm handleCloseOk={handleCloseOk} activity={activity}  />,
      [2]: <ConsultantsForm handleCloseOk={handleCloseOk} activity={activity} />,
      [3]: <ParticipantsForm handleCloseOk={handleCloseOk} activity={activity} />,
      [4]: <ResourcesForm handleCloseOk={handleCloseOk} activity={activity} />,
    }
    return fromByType[type];
  }

  return (
    <>
      <Modal show={show} onHide={handleCloseOk} size="xl" backdrop="static" animation={false}>
        <StyledModalBody>

          <ModalTitle>{`${title} ${!activityCode? "": `- ${t('F1_S7_Accordion')} ${activityCode}`}`}</ModalTitle>
          
          <div style={{marginTop: '40px'}}>
            {getFormByType(formType)}
          </div>

        </StyledModalBody>
      </Modal>
    </>
  )
}

export default ActivityGeneralModal;