import Form1Section1 from 'components/Forms/Form1/Section1';
import Form1Section2 from 'components/Forms/Form1/Section2';
import Form1Section3 from 'components/Forms/Form1/Section3';
import Form1Section4 from 'components/Forms/Form1/Section4';
import Form1Section5 from 'components/Forms/Form1/Section5';
import Form1Section6 from 'components/Forms/Form1/Section6';
import Form1Section7 from 'components/Forms/Form1/Section7';
import Form1Section8 from 'components/Forms/Form1/Section8';
import Form1Section10 from 'components/Forms/Form1/Section10';

export const form1Dictionary = {
  1: <Form1Section1 />,
  2: <Form1Section2 />,
  3: <Form1Section3 />,
  4: <Form1Section4 />,
  5: <Form1Section5 />,
  6: <Form1Section6 />,
  7: <Form1Section7 />,
  8: <Form1Section8 />,
  10: <Form1Section10 />,
};