// @ts-nocheck
import * as Yup from 'yup'


export const validationSchema = Yup.object().shape({
  methodology: Yup.string()
    .min(2, 'F1_S7_validation1')
    .max(1300, 'F1_S7_validation2')
    .nullable(true),
  activities: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .min(2, 'F1_S2_validation1')
        .max(500, 'Maximum_500')
        .nullable(true),
      format: Yup.string().oneOf(['', '0', '1', '2']),
      activityTypeId: Yup.string().nullable(true),
      productCount: Yup.number()
        .min(0, 'F3_S10_validation3')
        .nullable(true),
      mainProducts: Yup.string()
        .min(2, 'F1_S7_validation1')
        .max(1300, 'F1_S7_validation2')
        .nullable(true),
      observations: Yup.string()
        .min(2, 'F1_S7_validation1')
        .max(6500, 'F2_S9_validation1')
        .nullable(true),
      fromDate: Yup.date()
        .typeError('F1_S7_validation9')
        .nullable(true),
      toDate: Yup.date()
        .min(Yup.ref('fromDate'), 'F1_S7_validation4')
        .typeError('F1_S7_validation9')
        .nullable(true),
    }),
  ),
})
