import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import { 
  StyledAccordionBodyDiv,
  StyledFormLabel,
} from './MyAssessmentForm.styled'

import { StyledTitle } from '../Section15.styled';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const MyAssesmentForm = ({ formikInstance, nonEditable }) => {
  const { getFieldProps, touched, errors } = formikInstance;
  const {t} = useTranslation();

  return (
    <>
      <StyledTitle>{t('Button_my_assessment')}</StyledTitle>
      <StyledAccordionBodyDiv>
        <Form.Group>
          <Col>
            <StyledFormLabel xs={5}>{t('F2_assess_criteria5')}</StyledFormLabel>
            <Form.Control
              name='quality'
              as="textarea"
              style={{resize: "none"}}
              rows={8} 
              className={touched.quality && errors.quality ? "error" : null}
              { ...getFieldProps('quality') }
              disabled={nonEditable}
            />
            <FormErrorMessage
              touched={touched.quality}
              errors={errors.quality}
            />             
          </Col>
        </Form.Group>  

        <Form.Group>
          <Col>
            <StyledFormLabel xs={5}>{t('F2_assess_criteria4')}</StyledFormLabel>
            <Form.Control
              name='theme'
              as="textarea"
              style={{resize: "none"}}
              rows={8} 
              className={touched.theme && errors.theme ? "error" : null}
              { ...getFieldProps('theme') }
              disabled={nonEditable}
            />
            <FormErrorMessage
              touched={touched.theme}
              errors={errors.theme}
            />             
          </Col>
        </Form.Group>  

        <Form.Group>
          <Col>
            <StyledFormLabel xs={5}>{t('F2_assess_criteria7')}</StyledFormLabel>
            <Form.Control
              name='participants'
              as="textarea"
              style={{resize: "none"}}
              rows={8} 
              className={touched.participants && errors.participants ? "error" : null}
              { ...getFieldProps('participants') }
              disabled={nonEditable}
            />
            <FormErrorMessage
              touched={touched.participants}
              errors={errors.participants}
            />                 
          </Col>
        </Form.Group>  

        <Form.Group>
          <Col>
            <StyledFormLabel xs={5}>{t('F2_assess_criteria2')}</StyledFormLabel>
            <Form.Control
              name='triangular'
              as="textarea"
              style={{resize: "none"}}
              rows={8} 
              className={touched.triangular && errors.triangular ? "error" : null}
              { ...getFieldProps('triangular') }
              disabled={nonEditable}
            />
            <FormErrorMessage
              touched={touched.triangular}
              errors={errors.triangular}
            />   
          </Col>
        </Form.Group>  

        <Form.Group>
          <Col>
            <StyledFormLabel xs={5}>{t('F2_assess_criteria1')}</StyledFormLabel>
            <Form.Control
              name='alignment'
              as="textarea"
              style={{resize: "none"}}
              rows={8} 
              className={touched.alignment && errors.alignment ? "error" : null}
              { ...getFieldProps('alignment') }
              disabled={nonEditable}
            />
            <FormErrorMessage
              touched={touched.alignment}
              errors={errors.alignment}
            />           
          </Col>
        </Form.Group>  

        <Form.Group>
          <Col>
            <StyledFormLabel xs={5}>{t('F2_assess_criteria3')}</StyledFormLabel>
            <Form.Control
              name='euPriorities'
              as="textarea"
              style={{resize: "none"}}
              rows={8} 
              className={touched.euPriorities && errors.euPriorities ? "error" : null}
              { ...getFieldProps('euPriorities') }
              disabled={nonEditable}
            />
            <FormErrorMessage
              touched={touched.euPriorities}
              errors={errors.euPriorities}
            />        
          </Col>
        </Form.Group>  

        <Form.Group>
          <Col>
            <StyledFormLabel xs={5}>{t('F2_assess_criteria6')}</StyledFormLabel>
            <Form.Control
              name='coherence'
              as="textarea"
              style={{resize: "none"}}
              rows={8} 
              className={touched.coherence && errors.coherence ? "error" : null}
              { ...getFieldProps('coherence') }
              disabled={nonEditable}
            />
            <FormErrorMessage
              touched={touched.coherence}
              errors={errors.coherence}
            />     
          </Col>
        </Form.Group>  
        
        <Form.Group>
          <Col>
            <StyledFormLabel xs={5}>{t('F2_assess_criteria8')}</StyledFormLabel>
            <Form.Control
              name='opportunity'
              as="textarea"
              style={{resize: "none"}}
              rows={8} 
              className={touched.opportunity && errors.opportunity ? "error" : null}
              { ...getFieldProps('opportunity') }
              disabled={nonEditable}
            />
            <FormErrorMessage
              touched={touched.opportunity}
              errors={errors.opportunity}
            />   
          </Col>
        </Form.Group>  

      </StyledAccordionBodyDiv>
    </>
  )
}

export default MyAssesmentForm;