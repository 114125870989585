import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import { Table, Th1Pointer, Th2, Th5 } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'

import { T1RenderRows } from '../helpers/helpers'
import { orderDataByField } from '../helpers/helpers'
import _sumBy from 'lodash/sumBy'
import { StyledRow2 } from 'pages/statistics/CommonStyled/Tabs.styled'

const Table1 = ({ data, waiting, setData }) => {
  const { t } = useTranslation()
  const [ascendingOrder, setAscendingOrder] = useState({
    code: true,
    name: true,
    beneficiaryType: true,
    countryName: true,
    countryRegion: true,
    quantity: true,
  })

  const handleOrderData = (field) => {
    orderDataByField(data, field, setData, setAscendingOrder, ascendingOrder)
  }

  const total = _sumBy(data, 'quantity');

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data && !isEmpty(data) ? (
    <StyledRow2 style={{ width: '100%', marginTop: '24px'}}>
      <Table style={{ marginTop: '16px'}}>
        <tr>
          <Th1Pointer onClick={() => handleOrderData('code')}>
            {t('Initiative_code')} &#8645;
          </Th1Pointer>
          <Th1Pointer onClick={() => handleOrderData('name')}>
            {t('Title_initiative')} &#8645;
          </Th1Pointer>
          <Th1Pointer onClick={() => handleOrderData('beneficiaryType')}>
            {t('Dashboard_ben_type')} &#8645;
          </Th1Pointer>
          <Th1Pointer onClick={() => handleOrderData('countryName')}>
            {t('F1_S2_Country')} &#8645;
          </Th1Pointer>
          <Th1Pointer onClick={() => handleOrderData('countryRegion')}>
            {t('Region')} &#8645;
          </Th1Pointer>
          <Th1Pointer onClick={() => handleOrderData('quantity')}>
            {t('F1_S7_Total')} &#8645;
          </Th1Pointer>
        </tr>
        {T1RenderRows(data)}
        <tr >
          <Th2 colSpan={4} />
          <Th2 >{t('F1_S7_Total')}</Th2>
          <Th5>{Intl.NumberFormat('es-AR').format(total)}</Th5>
        </tr>
      </Table>
    </StyledRow2>
  ) : (
    t('No_data_available_2')
  )
}

export default Table1
