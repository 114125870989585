import React, { useState, useEffect } from 'react';
import {
  getFormSection,
  getGeneralInfoF5S7,
  getProductsF5S7,
  getParticipantsF5S7,
  getConsultantsF5S7,
  getResourcesFromProposalF5S7,
  getActivityDetailsF5S7,
  getAssessmentsF5S7,
} from 'services/initiative';
import { initialValues } from './constants';
import html2pdf from 'html2pdf.js';

import Section1 from './Section1/Section1';
import Section2 from './Section2/Section2';
import Section3 from './Section3/Section3';
import Section4 from './Section4/Section4';
import Section5 from './Section5/Section5';
import Section6 from './Section6/Section6';
import Section7 from './Section7/Section7';
import Section7GeneralInfo from './Section7GeneralInfo/Section7GeneralInfo';
import Section7Products from './Section7Products/Section7Products';
import Section7Participants from './Section7Participants/Section7Participants.js';
import Section7Consultants from './Section7Consultants/Section7Consultants';
import Section7BudgetF3 from './Section7BudgetF3/Section7BudgetF3';
import Section7ExecutedBudget from './Section7ExecutedBudget/Section7ExecutedBudget';
import Section8 from './Section8/Section8';
import Section9 from './Section9/Section9';
import Section10 from './Section10/Section10';
import Section11 from './Section11/Section11';
import Section12 from './Section12/Section12';
import Section13 from './Section13/Section13';
import Section14 from './Section14/Section14';
import Section15 from './Section15/Section15';
import Section16 from './Section16/Section16';
import Section17 from './Section17/Section17';

const F5PDFGen = ({ initiativeId, handleError, onCreatePDF }) => {
  const [sectionsData, setSectionsData] = useState(initialValues);

  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    if(sectionsData.ready && !sectionsData.isReadyToGenerate){
      fetchS7Subsections();
    }
  }, [sectionsData]);

  useEffect(() => {
    if(sectionsData.isReadyToGenerate){
      generatePDF();
    }
  }, [sectionsData]);

  const generatePDF = () => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      const pages = Array.from(document.querySelectorAll('div[aria-label^="pdf-page-"]'))
      const pdfOptions = {
        margin: 1,
        filename: `initiative-${initiativeId}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2.0,
          width: 1200,
        },
        jsPDF: { unit: 'in', format: 'a1', orientation: 'portrait' },
      };
      let worker = html2pdf()
        .set(pdfOptions)
        .from(pages[0])

      if (pages.length > 1) {
        worker = worker.toPdf()
        pages.slice(1).forEach((element, index) => {
          worker = worker
            .get('pdf')
            .then(pdf => {
              pdf.addPage()
            })
            .from(element)
            .toContainer()
            .toCanvas()
            .toPdf()
        })
      }
      worker = worker.save().then(() => {
        onCreatePDF();
        setSectionsData(initialValues);
      });
    }, 5000);
  };

  const fetchS7Subsections = async () => {
    const sectionsDataCopy = {...sectionsData};
    await Promise.all(sectionsData.section7Data[0].activities.map(async (activity) => {
      console.log('ACTIVITY',activity)
      sectionsDataCopy.section7SubsectionsData.push({
        activity: activity,
        acivityType: activity.activityType,
        generalInfo: await getGeneralInfoF5S7(initiativeId, activity.id),
        products: await getProductsF5S7(initiativeId, activity.id),
        participants: (activity.activityType && activity.activityType.id != 4 ) ? await getParticipantsF5S7(initiativeId, activity.id) : [],
        consultants: (activity.activityType && activity.activityType.id == 4 ) ? await getConsultantsF5S7(initiativeId, activity.id) : [],
        budgetF3: activity?.fromF3 ? await getResourcesFromProposalF5S7(initiativeId, activity.id) : null,
        executedBudget: await getActivityDetailsF5S7(initiativeId, activity.id),
        assessment: await getAssessmentsF5S7(initiativeId, activity.id),
      });
    }));
    sectionsDataCopy.isReadyToGenerate = true;
    setSectionsData(sectionsDataCopy);
  };

  const fetchSections = async () => {
    let response;
    let sectionDataCopy = {...sectionsData};
    try {
      for (let i = 1; i < 18; i++) {
        response = await getFormSection(initiativeId, 5, i);
        sectionDataCopy[`section${i}Data`] = response.section;
      }
      sectionDataCopy['ready'] = true;
      setSectionsData(sectionDataCopy);
    } catch (e) {
      handleError();
    }
  };

  return (
    <div id='pdfTargetDiv'>
      <div aria-label="pdf-page-1" style={{width: '1200px'}}>
        <Section1 data={sectionsData.section1Data} />
      </div>
      <div aria-label="pdf-page-2" style={{width: '1200px'}}>
        <Section2 data={sectionsData.section2Data} />
      </div>
      <div aria-label="pdf-page-3" style={{width: '1200px'}}>
        <Section3 data={sectionsData.section3Data} />
      </div>
      <div aria-label="pdf-page-4" style={{width: '1200px'}}>
        <Section4 data={sectionsData.section4Data} />
      </div>
      <div aria-label="pdf-page-5" style={{width: '1200px'}}>
        <Section5 data={sectionsData.section5Data} />
      </div>
      <div aria-label="pdf-page-6" style={{width: '1200px'}}>
        <Section6 data={sectionsData.section6Data} />
      </div>
      <div aria-label="pdf-page-7" style={{width: '1200px'}}>
        <Section7 data={sectionsData.section7Data} />
      </div>
      {sectionsData.section7SubsectionsData.map(activity => (
        <>
          <div aria-label={`pdf-page-7-1-${activity.id}`} style={{width: '1200px'}}>
            <Section7GeneralInfo data={activity?.generalInfo?.data} activity={activity.activity} />
          </div>
          <div aria-label={`pdf-page-7-2-${activity.id}`} style={{width: '1200px'}}>
            <Section7Products data={activity?.products?.data} activity={activity.activity} />
          </div>
          {(activity.activityType && activity.activityType.id == 4 ) && (
            <div aria-label={`pdf-page-7-3-${activity.id}`} style={{width: '1200px'}}>
              <Section7Participants data={activity?.participants?.data} activity={activity.activity} />
            </div>
          )}
          {(activity.activityType && activity.activityType.id == 4 ) && (
            <div aria-label={`pdf-page-7-4-${activity.id}`} style={{width: '1200px'}}>
              <Section7Consultants data={activity?.consultants?.data} activity={activity.activity} />
            </div>
          )}
          <div aria-label={`pdf-page-7-5-${activity.id}`} style={{width: '1200px'}}>
            <Section7BudgetF3 data={activity?.budgetF3?.data} activity={activity.activity} />
          </div>
          <div aria-label={`pdf-page-7-6-${activity.id}`} style={{width: '1200px'}}>
            <Section7ExecutedBudget data={activity?.executedBudget?.data} activity={activity.activity} />
          </div>
        </>
      ))}
      <div aria-label="pdf-page-8" style={{width: '1200px'}}>
        <Section8 data={sectionsData.section8Data} />
      </div>
      <div aria-label="pdf-page-9" style={{width: '1200px'}}>
        <Section9 data={sectionsData.section9Data} />
      </div>
      <div aria-label="pdf-page-10" style={{width: '1200px'}}>
        <Section10 data={sectionsData.section10Data} />
      </div>
      <div aria-label="pdf-page-11" style={{width: '1200px'}}>
        <Section11 data={sectionsData.section11Data} />
      </div>
      <div aria-label="pdf-page-12" style={{width: '1200px'}}>
        <Section12 data={sectionsData.section12Data} />
      </div>
      <div aria-label="pdf-page-13" style={{width: '1200px'}}>
        <Section13 data={sectionsData.section13Data} />
      </div>
      <div aria-label="pdf-page-14" style={{width: '1200px'}}>
        <Section14 data={sectionsData.section14Data} />
      </div>
      <div aria-label="pdf-page-15" style={{width: '1200px'}}>
        <Section15 data={sectionsData.section15Data} />
      </div>
      <div aria-label="pdf-page-16" style={{width: '1200px'}}>
        <Section16 data={sectionsData.section16Data} />
      </div>
      <div aria-label="pdf-page-17" style={{width: '1200px'}}>
        <Section17 data={sectionsData.section17Data} />
      </div>
    </div>
  )
};

export default F5PDFGen;
