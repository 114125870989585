import React, { useState} from 'react'

import Dropdown from 'react-bootstrap/Dropdown'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import {useHistory} from 'react-router-dom'

import {
  StyledDropdownMenu,
  StyledButton,
  StyledSpan
} from './HeaderUserMenu.styled'

import { logoutService } from 'services/auth';
import VerticallyCenteredModal from 'components/ModalHtml';
import { useTranslation } from 'react-i18next';

const HeaderUserMenu = () => {

  const {t} = useTranslation();
  let history = useHistory();
  const PROFILE_URL = "/profile";

  const [modalShow, setModalShow] = useState(false);

  const performLogout = () => {
    logoutService();
    history.push('/login');
  }

  const handleButtonNotifications = () => {
    history.push('/notifications');
  }

  const handleProfile = async () => {
    setModalShow(true);
  }

  return (
    <div>
      <VerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={PROFILE_URL}
      />
      <StyledDropdownMenu rootCloseEvent='click' >
        <Col>
          <Row>
            <StyledButton className="text-left" onClick={handleProfile} block>
              <StyledSpan>{t('menu_profile')}</StyledSpan>
            </StyledButton>
          </Row>

          <Dropdown.Divider />
          
          <Row>
            <StyledButton className="text-left" onClick={handleButtonNotifications} block>
              <StyledSpan>{t('menu_notifications')}</StyledSpan> 
            </StyledButton>
          </Row>

          <Dropdown.Divider />
          
          <Row>
            <StyledButton className="text-left" onClick={performLogout} block>
              <StyledSpan> {t('menu_sign_out')} </StyledSpan>  
            </StyledButton>
          </Row>
        </Col>
      </StyledDropdownMenu>
    </div>

  )
}

export default HeaderUserMenu;