import i18n from 'i18n'
import { formatDateDDMMYY } from 'helpers/dateHelpers'
import {
  Td,
  Th2,
  Th1Pointer,
  Td2,
} from 'pages/statistics/CommonStyled/Components.styled'
import {
  renderRow,
  renderTotalForColumns,
  renderTotalForColumnsForExport,
  orderByDate,
  orderBySring,
} from 'pages/statistics/CommonHelpers/helpers'
import _filter from 'lodash/filter'
import _orderBy from 'lodash/orderBy'
import { INITIATIVE_TITLE_ROW, FUNDS_TITLE_ROW } from 'constants/appConstants'
import React from 'react'

export const renderCodeCell = (baseRoute, code, initiativeId, fundId, section = 7) => {
  const isFund = !!fundId;
  const route = `${baseRoute}/initiative/${isFund ? fundId : initiativeId}/form/${isFund ? '55' : '5' }/section/${section}`;
  return (
    <Td>
      <a href={route}>
        {code}
      </a>
    </Td>
    )
}

export const T1RenderRows = (baseRoute, rows) =>
  rows.map(
    ({
      typeId,
      eventFormat,
      countryName,
      code,
      title,
      fromDate,
      toDate,
      countryRegion,
      initiativeId,
      fundId
    }) => {
      return (
        <tr>
          {renderCodeCell(baseRoute, code, initiativeId, fundId)}
          <Td>{title ? title : <i> {i18n.t('activity_preparation')} </i>}</Td>
          <Td>{i18n.t(countryName)}</Td>
          <Td>{i18n.t(countryRegion)}</Td>
          <Td>{i18n.t(`F1_S7_Format${eventFormat}`)}</Td>
          <Td>{i18n.t(`activity_types_${typeId}`)}</Td>
          <Td>{formatDateDDMMYY(fromDate)}</Td>
          <Td>{formatDateDDMMYY(toDate)}</Td>
        </tr>
      )
    }
  )

export const getActivitiesByCountrySummaryExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData.forEach((country) => {
    data.push({
      label: i18n.t(`${country?.category}`),
      col1: country['2021'],
      col2: country['2022'],
      col3: country['2023'],
      col4: country['2024'],
      col5: country?.total,
    })
  })
  return data
}

export const getFundsActivitiesByCountrySummaryExportValues = (exportData) => {
  let data = []
  data.push(FUNDS_TITLE_ROW)
  exportData.forEach((country) => {
    data.push({
      label: i18n.t(`${country?.category}`),
      col1: country['total'],
      col2: '',
      col3: '',
      col4: '',
      col5: '',
    })
  })
  return data
}

export const getActivitiesByRoleSummaryExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData.forEach((row) => {
    data.push({
      label: i18n.t(`F1_S7_Format${row?.category}`),
      col1: row['2021'],
      col2: row['2022'],
      col3: row['2023'],
      col4: row['2024'],
      col5: row?.total,
    })
  })
  let row = renderTotalForColumnsForExport(exportData, [
    '2021',
    '2022',
    '2023',
    '2024',
  ])
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: row[1],
    col3: row[2],
    col4: row[3],
    col5: row[4],
  })
  return data
}

export const getFundsActivitiesByRoleSummaryExportValues = (exportData) => {
  let data = []
  data.push(FUNDS_TITLE_ROW)
  exportData.forEach((row) => {
    data.push({
      label: i18n.t(`F1_S7_Format${row?.category}`),
      col1: row['total'],
      col2: '',
      col3: '',
      col4: '',
      col5: '',
    })
  })
  let row = renderTotalForColumnsForExport(exportData, [
    '2021',
  ])
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: '',
    col3: '',
    col4: '',
    col5: '',
  })
  return data
}

export const getActivitiesByTypeSummaryExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData.forEach((row) => {
    data.push({
      label: i18n.t(`activity_types_${row?.category}`),
      col1: row['2021'],
      col2: row['2022'],
      col3: row['2023'],
      col4: row['2024'],
      col5: row?.total,
    })
  })
  let row = renderTotalForColumnsForExport(exportData, [
    '2021',
    '2022',
    '2023',
    '2024',
  ])
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: row[1],
    col3: row[2],
    col4: row[3],
    col5: row[4],
  })
  return data
}

export const getFundsActivitiesByTypeSummaryExportValues = (exportData) => {
  let data = []
  data.push(FUNDS_TITLE_ROW)
  exportData.forEach((row) => {
    data.push({
      label: i18n.t(`activity_types_${row?.category}`),
      col1: row['total'],
      col2: '',
      col3: '',
      col4: '',
      col5: '',
    })
  })
  let row = renderTotalForColumnsForExport(exportData, [
    '2021',
  ])
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: '',
    col3: '',
    col4: '',
    col5: '',
  })
  return data
}

export const getProductsByTypeSummarySummaryExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData?.forEach((row) => {
    data.push({
      label: i18n.t(`product_type_${row?.category}`),
      col1: row['total'],
      col2: '',
      col3: '',
      col4: '',
      col5: '',
    })
  })
  let row = renderTotalForColumnsForExport(exportData, [
    '2021',
  ])
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: '',
    col3: '',
    col4: '',
    col5: '',
  })
  return data
}

export const getFundsProductsByTypeSummarySummaryExportValues = (exportData) => {
  let data = []
  data.push(FUNDS_TITLE_ROW)
  exportData?.forEach((row) => {
    data.push({
      label: i18n.t(`product_type_${row?.category}`),
      col1: row['2021'],
      col2: row['2022'],
      col3: row['2023'],
      col4: row['2024'],
      col5: row?.total,
    })
  })
  let row = renderTotalForColumnsForExport(exportData, [
    '2021',
    '2022',
    '2023',
    '2024',
  ])
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: row[1],
    col3: row[2],
    col4: row[3],
    col5: row[4],
  })
  return data
}

export const T2RenderRows = (rows, years) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(row?.category)}</Th2>
        {renderRow(row, years)}
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      {renderTotalForColumns(rows, years)}
    </tr>
  </>
)

export const T3RenderRows = (rows, years) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(`F1_S7_Format${row?.category}`)}</Th2>
        {renderRow(row, years)}
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      {renderTotalForColumns(rows, years)}
    </tr>
  </>
)

export const T4RenderRows = (rows, years) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(`activity_types_${row?.category}`)}</Th2>
        {renderRow(row, years)}
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      {renderTotalForColumns(rows, years)}
    </tr>
  </>
)


export const T5RenderRows = (rows, years) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(`product_type_${row?.category}`)}</Th2>
        {renderRow(row, years)}
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      {renderTotalForColumns(rows, years)}
    </tr>
  </>
)

export const getActivitiesDetailExportValues = (exportData) => {
  let data = []
  exportData?.forEach((row) => {
    data.push({
      col1: row?.code,
      col2: row?.title,
      col3: row?.countryName ? i18n.t(row.countryName) : '-',
      col4: row?.countryRegion ? i18n.t(row.countryRegion) : '-',
      col5: row?.eventFormat
        ? i18n.t(`F1_S7_Format${row.eventFormat}`)
        : row?.eventFormat == 0
        ? i18n.t(`F1_S7_Format${row.eventFormat}`)
        : '-',
      col6: row?.typeId ? i18n.t(`activity_types_${row.typeId}`) : '-',
      col7: row?.fromDate ? formatDateDDMMYY(row.fromDate) : '-',
      col8: row?.toDate ? formatDateDDMMYY(row.toDate) : '-',
    })
  })
  return data
}

export const orderActivitiesByCountry = (data) => {
  const filteredData = _filter(data, (item) => {
    return item.total > 0
  })
  return _orderBy(filteredData, ['total'], ['desc'])
}

export const orderDataByField = (data, field, setData, setAsc, asc) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'title':
      orderedData = orderBySring(data, field, asc)
      break
    case 'eventFormat':
      orderedData = orderBySring(data, field, asc)
      break
    case 'countryRegion':
      orderedData = orderBySring(data, field, asc)
      break
    case 'typeId':
      orderedData = _orderBy(
        data,
        [
          (item) =>
            i18n.t(`activity_types_${item?.typeId}`).includes('-')
              ? `${i18n.t(`activity_types_${item?.typeId}`).split('-')[0]}${
                  i18n.t(`activity_types_${item?.typeId}`).split('-')[1]
                }`
              : i18n.t(`activity_types_${item?.typeId}`),
        ],
        asc[field] ? ['asc'] : ['desc'],
      )
      break
    case 'code':
      orderedData = _orderBy(
        data,
        [
          (item) => {
            const array = item?.code.split('-')
            return parseInt(array[2])
          },
          (item) => {
            const array = item?.code.split('-')
            return parseInt(array[0].substring(1, array[0].length))
          },
          (item) => {
            const array = item?.code.split('-')
            return parseInt(array[1].substring(3, array[1].length))
          },
        ],
        asc[field] ? ['asc', 'asc', 'asc'] : ['desc', 'desc', 'desc'],
      )
      break
    case 'countryName':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(item.countryName)],
        asc[field] ? ['asc', 'asc', 'asc'] : ['desc', 'desc', 'desc'],
      )
      break
    case 'fromDate':
      orderedData = orderByDate(data, field, asc)
      break
    default:
      orderedData = _orderBy(data, [field], asc[field] ? ['asc'] : ['desc'])
  }

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}

export const orderDataByFieldToSummary = (
  data,
  field,
  setData,
  setAsc,
  asc,
  table,
) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'category':
      switch (table) {
        case 2:
          orderedData = _orderBy(
            data,
            [(field) => i18n.t(field.category)],
            asc[field] ? ['asc'] : ['desc'],
          )
          break
          break
        case 3:
          orderedData = _orderBy(
            data,
            [(field) => i18n.t(`F1_S7_Format${field?.category}`)],
            asc[field] ? ['asc'] : ['desc'],
          )
          break
        case 4:
          orderedData = _orderBy(
            data,
            [(field) => i18n.t(`activity_types_${field?.category}`)],
            asc[field] ? ['asc'] : ['desc'],
          )
          break
        case 5:
          orderedData = _orderBy(
            data,
            [(field) => i18n.t(`product_type_${field?.category}`)],
            asc[field] ? ['asc'] : ['desc'],
          )
          break
      }
      break
    default:
      orderedData = _orderBy(data, [field], asc[field] ? ['asc'] : ['desc'])
  }

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}

export const renderHeaders = (
  years,
  data,
  setData,
  setAscendingOrder,
  ascendingOrder,
) => (
  <>
    {years.map((year) => (
      <Th1Pointer
        width="10%"
        scope="col"
        onClick={() =>
          orderDataByFieldToSummary(
            data,
            year,
            setData,
            setAscendingOrder,
            ascendingOrder,
            null,
          )
        }
      >
        {year} &#8645;
      </Th1Pointer>
    ))}
    <Th1Pointer
      width="10%"
      scope="col"
      onClick={() =>
        orderDataByFieldToSummary(
          data,
          'total',
          setData,
          setAscendingOrder,
          ascendingOrder,
          null,
        )
      }
    >
      {i18n.t('F1_S7_Total')} &#8645;
    </Th1Pointer>
  </>
)

export const T6RenderRows = (rows) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(row?.category)}</Th2>
        <Td>{row.total}</Td>
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      <Td2>{rows?.reduce((acc, curr) => acc + curr?.total, 0)}</Td2>
    </tr>
  </>
)


export const T7RenderRows = (rows) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(`F1_S7_Format${row?.category}`)}</Th2>
        <Td>{row.total}</Td>
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      <Td2>{rows?.reduce((acc, curr) => acc + curr?.total, 0)}</Td2>
    </tr>
  </>
)

export const T8RenderRows = (rows) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(`activity_types_${row?.category}`)}</Th2>
        <Td>{row.total}</Td>
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      <Td2>{rows?.reduce((acc, curr) => acc + curr?.total, 0)}</Td2>
    </tr>
  </>
)
