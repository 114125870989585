import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { StyledFormLabel, TextValueLeftSpaced } from '../Section7.styled';
import { SeparatorDotted } from 'components/CommonStyled';
import TextValue from 'components/TextValue';
import { useTranslation } from 'react-i18next';
import { RadioLabel } from 'components/Forms/Forms.styled';
import Col from 'react-bootstrap/Col';

const ActivityForm = ({ activityIndex, data }) => {

  const { t } = useTranslation();

  // Si no tiene formato asumo que es presencial
  const formatId = data?.format ? String(data.format) : '0';

  return (
    <>
      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_type')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.activityType ? t(`activity_types_${data.activityType.id}`) : ' - '} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_start_day')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.fromDate.split("T")[0]} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_End_day')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.toDate.split("T")[0]} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group>
        <StyledFormLabel as={Row} style={{ marginTop: '30px', marginBottom: '15px' }}>{t('F1_S7_Short_description')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.description} />
        </TextValueLeftSpaced>
      </Form.Group>

      <SeparatorDotted />

      <Form.Group as={Row}>
        <StyledFormLabel as={Row} style={{ marginTop: '30px', marginBottom: '15px', marginLeft: '0px' }}>
          {t('F1_S7_Format')}:
        </StyledFormLabel>
        <Col lg={8} style={{ marginLeft: '50px', marginTop: '30px' }}>
          <RadioLabel>
            <input
              type="radio"
              checked={formatId === '0'}
              style={{ marginRight: '10px' }}
              disabled
            />
            {t('F1_S7_Format0')}
          </RadioLabel>

          <RadioLabel>
            <input
              type="radio"
              checked={formatId === '1'}
              style={{ marginRight: '10px' }}
              disabled
            />
            {t('F1_S7_Format1')}
          </RadioLabel>

          <RadioLabel>
            <input
              type="radio"
              checked={formatId === '2'}
              style={{ marginRight: '10px' }}
              disabled
            />
            {t('F1_S7_Format2')}
          </RadioLabel>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_Country_activity')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.country ? t(data.country.name) : ' - '} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S2_State_Province_Region')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.stateName} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S2_City')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.cityName} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_Countries_additional')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.countriesAdditional} />
        </TextValueLeftSpaced>
      </Form.Group>

      <SeparatorDotted />

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_Duration')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.duration} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_Number_participants')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.participants} />
        </TextValueLeftSpaced>
      </Form.Group>

      <SeparatorDotted />

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_Number_experts')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.experts} />
        </TextValueLeftSpaced>
      </Form.Group>

      <StyledFormLabel xs={4} as={Row} style={{ marginTop: '30px', marginBottom: '15px' }}>{t('F1_S7_Number_wd')}:</StyledFormLabel>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_Remote')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.daysRemote} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_Onsite')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.daysOnsite} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F1_S7_Total')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.daysRemote + data.daysOnsite} />
        </TextValueLeftSpaced>
      </Form.Group>

      <SeparatorDotted />

      <StyledFormLabel xs={4} as={Row} style={{ marginBottom: '30px' }}>{t('F1_S7_estimated_budget')}:</StyledFormLabel>

      <Form.Group as={Row}>
        <StyledFormLabel xs={5}>{t('F1_S7_EU_budget')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.ueBudget + " €"} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={5}>{t('F1_S7_counterpart_budget')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.counterPartBudget + " €"} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel xs={5}>{t('F1_S7_total_budget')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.ueBudget + data.counterPartBudget + " €"} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group>
        <StyledFormLabel as={Row} style={{ marginTop: '30px', marginBottom: '15px' }}>{t('F1_S7_Justification_activity')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.justBudget} />
        </TextValueLeftSpaced>
      </Form.Group>
    </>
  )

}

export default ActivityForm;
