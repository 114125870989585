import React, { useState } from 'react'
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ErrorButton, SuccessButton } from 'components/Buttons'
import { StyledGreenFormLabel } from '../Section9.styled'
import { reviewF2Decision } from 'services/initiative'
import ConfirmCloseModal from 'components/ConfirmCloseModal'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const ReviewINTPA = ({ formikInstance, nonEditable, redirectToConceptNotes }) => {
  const { values, getFieldProps, errors, setFieldValue } = formikInstance;
  const { id } = useParams();
  const [modalData, setModalData] = useState({
    show: false,
    boolean: null,
    error: null,
  })
  const { t } = useTranslation()

  const decision = async (boolean, error) => {
    setFieldValue('recommends', boolean)
    try {
      if (!error) {
        await reviewF2Decision(id, {
          recommends: boolean,
          justification: getFieldProps('justification').value || '',
        });
        redirectToConceptNotes();
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <ConfirmCloseModal
        show={modalData.show}
        handleCloseConfirm={() =>
          setModalData({ show: false, boolean: null, error: null })
        }
        handleActionOnConfirm={() =>
          decision(modalData.boolean, modalData.error)
        }
        text={`${t('F2c_final_confirmation_to_send_part3')} ${
          modalData.boolean
            ? t('Continue_assessment')
            : t('Reject')
        } ${t('F2c_final_confirmation_to_send_part4')}`}
      />
      <Col style={{ marginTop: '50px', marginBottom: '50px' }}>
        <SuccessButton
          disabled={nonEditable}
          onClick={() =>
            setModalData({
              show: true,
              boolean: true,
              error: errors.justification,
            })
          }
        >
          {t('INTPA_button_continue_assessment')}
        </SuccessButton>
      </Col>

      <Col>
        <StyledGreenFormLabel style={{color: 'red'}}>{t('F2_intpa_comments_reject_review')}:</StyledGreenFormLabel>
        <Form.Control
          as="textarea"
          style={{ resize: 'none' }}
          rows={5}
          name="justification"
          className={errors.justification ? 'error' : null}
          {...getFieldProps('justification')}
          disabled={nonEditable}
        />
        <FormErrorMessage
          touched
          errors={errors.justification}
        />
        
        <ErrorButton
          style={{ marginTop: '30px' }}
          disabled={nonEditable || _isNil(values.justification) || _isEmpty(values.justification)}
          onClick={() =>
            setModalData({
              show: true,
              boolean: false,
              error: errors.justification,
            })
          }
        >
          {t('Button_Reject_concept_note')}
        </ErrorButton>
      </Col>
    </>
  )
}

export default ReviewINTPA
