import { useEffect, useState } from 'react';
import { toJS } from 'mobx'
import { useRootStore } from 'stores';

const initialState = {
  inptaClarification: null,
  eudClarifications: null,
}

export const useGetClarifications = () => {

  const { formStore } = useRootStore();
  const [values, setValues] = useState(initialState);

  useEffect(() => {
    const clarifications = { 
      ...initialState,
      inptaClarification: formStore.inptaClarification,
      eudClarifications: toJS(formStore.eudClarifications)
    }
    setValues(clarifications);
  }, [formStore.inptaClarification, formStore.eudClarifications]);

  return values;
}