import { action, makeObservable, observable} from 'mobx';
import { getUser } from 'services/auth';
import { roleNamesConst } from 'constants/appConstants'

export class AuthStore {
  isLoggedIn = false;
  email = null;
  roles = null;

  constructor() {
    makeObservable(this, {
      isLoggedIn: observable,
      email: observable,
      roles: observable,
      login: action,
      logout: action
    });
    this.loadInitialData()
  }

  // load user initial data from localstorage
  loadInitialData() {
    const user = getUser();
    if(user) {
      const { email, roles } = user;
      this.login(email, roles);
    }
  }
  
  login(email, roles) {
    this.isLoggedIn = true;
    this.email = email;
    this.roles = roles;
  }

  logout() {
    this.isLoggedIn = false;
    this.email = null;
    this.roles = null;
  }

  getBaseRouteByRole() {
    const role = this.roles[0];
    const routeByRole = {
      [roleNamesConst.INTPA]: '/intpa',
      [roleNamesConst.EUD]: '/eud',
      [roleNamesConst.ATKE]: '/ta',
      [roleNamesConst.ATNKE]: '/ta',
      [roleNamesConst.CONSORTIUM]: '/consortium',
      [roleNamesConst.PUBLIC]: '/applicant',
      [roleNamesConst.GUEST]: '/applicant',
    }
    return routeByRole[role];

  }
}
