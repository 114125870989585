import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { saveFormSection } from 'services/initiative';
import { Formik, FieldArray } from 'formik';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormLayout from '../../FormLayout';
import { validationSchema } from './Section8.validations';
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from 'components/CustomAccordion';
import FormContainer from 'components/FormContainer';
import { PrimaryButton, ErrorButton } from 'components/Buttons';
import { SeparatorDotted } from 'components/CommonStyled';
import ModalConfirm from 'components/ModalConfirm';
import MediaForm from './MediaForm';
import { useConstantsForm1 } from 'customHooks/useConstantsForm1';
import { f1statusNamesConst } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';

import {
  StyledIntroParagraph,
  StyledWarningP,
  StyledRequiredSpan,
  StyledParagraph,
} from './Section8.styled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const mediaTypeObject = {
  mediaTypeId: '',
  url: '',
  comments: '',
};

const Section8 = () => {
  const { t } = useTranslation();
  const { mediaTypes: mediaNames } = useConstantsForm1();
  const { formStore, initiativeStore } = useRootStore();
  const { id, formId, sectionId } = useParams();
  const [clarification, setClarification] = useState({
    show: false,
    msg: '',
  });
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const [initialValues, setInitialValues] = useState({
    objective: '',
    campaigns: [mediaTypeObject],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const arr = [];
    if (formStore.formSectionData) {
      if (formStore.formSectionData[0].campaigns) {
        formStore.formSectionData[0].campaigns.forEach(element => {
          arr.push({
            mediaTypeId: element.mediaType ? element.mediaType.id : '',
            url: setStringFieldValue(element.url),
            comments: setStringFieldValue(element.comments),
          });
        });
      }
      setInitialValues({
        objective: setStringFieldValue(formStore.formSectionData[0].objective),
        campaigns: arr,
      });
    }
  }, [formStore.formSectionData]);

  useEffect(() => {
    if (initiativeStore.formStateName === f1statusNamesConst.DRAFT) {
      if (initiativeStore.formVersion === 2) {
        if (formStore.formSectionClarification) {
          setNonEditable(false);
          setClarification({
            show: true,
            msg: formStore.formSectionClarification
              ? formStore.formSectionClarification
              : t('No_clarifications'),
          });
        } else {
          setNonEditable(true);
        }
      } else {
        setNonEditable(false);
      }
    } else {
      setNonEditable(true);
    }
  }, [
    initiativeStore.currentFormStateName,
    initiativeStore.formVersion,
    formStore.formSectionClarification,
  ]);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  // Handle remove media modal confirmation
  const openModalRemove = key => {
    setModalRemove(true);
    setSelectedKey(key);
  };

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  };

  const setStringFieldValue = newValue => {
    return newValue ? newValue : '';
  };

  const onSubmit = async (values, { resetForm }) => {
    try {
      const response = await saveFormSection(id, formId, sectionId, values);
      let bool = response.complete;
      formStore.setSectionStatus(sectionId, bool);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, resetForm) => {
    e.preventDefault();
    isValid ? onSubmit(values, { resetForm }) : alert(t('not_saved_alert'));
  };

  return (
    <FormLayout>
      <FormContainer
        number={8}
        title={t('F1_S8_title')}
        isHelp={true}
        formId="form1section8"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
      >
        <StyledIntroParagraph>{t('F1_S8_intro')}</StyledIntroParagraph>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {props => (
            <form
              onSubmit={e => {
                onValidateSubmit(e, props.isValid, props.values, props.resetForm);
              }}
              id="form1section8"
            >
              {props.dirty && setSaved(false)}
              {clarification.show && (
                <Form.Group style={{ marginLeft: '50px', paddingTop: '10px' }} as={Row}>
                  <Form.Label>{t('Clarification_requests')}</Form.Label>
                  <Form.Control
                    name="overview"
                    as="textarea"
                    style={{ border: '2px solid red', resize: 'none' }}
                    rows={4}
                    disabled={true}
                    value={clarification.msg}
                  />
                </Form.Group>
              )}

              <Form.Group as={Row}>
                <Col>
                  <Form.Label xs={5} lg={4}>
                    {t('F1_S8_Objetive')} <StyledRequiredSpan>*</StyledRequiredSpan>:
                  </Form.Label>
                  <Form.Control
                    name="objective"
                    as="textarea"
                    rows={3}
                    style={{ resize: 'none', marginRight: '18px' }}
                    placeholder={t('Maximum_1300')}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.objective}
                    className={props.touched.objective && props.errors.objective ? 'error' : null}
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={props.touched.objective}
                    errors={props.errors.objective}
                  />
                </Col>
              </Form.Group>

              <StyledParagraph>{t('F1_S8_Subtitle_communication_tools')}</StyledParagraph>

              <FieldArray
                name="campaigns"
                render={arrayHelpers => (
                  <>
                    {modalRemove && (
                      <ModalConfirm
                        show={modalRemove}
                        confirmText={t('Button_remove')}
                        onConfirm={() => {
                          arrayHelpers.remove(selectedKey);
                          closeModalRemove();
                        }}
                        onClose={closeModalRemove}
                      >
                        <p>{t('Confirmation remove tool') + ' ' + (selectedKey + 1)}?</p>
                      </ModalConfirm>
                    )}
                    <Accordion>
                      {initialValues.campaigns
                        ? props.values.campaigns.map((mediaType, key) => (
                          <AccordionItem key={key + 1}>
                            <AccordionItemHeader>
                              <AccordionTitle eventKey={key + 1}>
                                {`${t('F1_S8_Accordion')} ${key + 1}`}
                                {mediaType.mediaTypeId ? ` - ${t(`media_type_${mediaType.mediaTypeId}`)}`: ''}
                              </AccordionTitle>
                            </AccordionItemHeader>
                            <Accordion.Collapse eventKey={key + 1}>
                              <AccordionItemBody>
                                <MediaForm
                                  formikInstance={props}
                                  mediaTypeIndex={key}
                                  mediaNames={mediaNames}
                                  nonEditable={nonEditable}
                                />
                                <ErrorButton
                                  onClick={() => openModalRemove(key)}
                                  disabled={nonEditable}
                                >
                                  {t('F1_S8_Button_delete_item')}
                                </ErrorButton>
                              </AccordionItemBody>
                            </Accordion.Collapse>
                          </AccordionItem>
                        ))
                        : null}
                    </Accordion>
                    <FormErrorMessage
                      touched
                      errors={props.errors.campaigns}
                    />
                    <SeparatorDotted />
                    <PrimaryButton
                      onClick={() => arrayHelpers.push(mediaTypeObject)}
                      disabled={nonEditable}
                    >
                      {t('F1_S8_Button_add_item')}
                    </PrimaryButton>
                  </>
                )}
              />
            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section8);
