

import React, { useState, useEffect } from 'react'
import htmlParser from 'html-react-parser'

import PageTitle from 'components/PageTitle'
import { useTranslation } from 'react-i18next'
import Header from '../components/Header'
import Table from '../components/Table'

import {
  StyledRow,
  StyledRow3,
  StyledRow2,
  StyledSubtitle3,
} from '../../CommonStyled/Tabs.styled'
import { getResultExportValues } from '../helpers/helpers'
import { getF6Results } from '../../../../services/initiative'

const Tab1 = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([1])
  const [waiting, setWaiting] = useState(false)

  const getData = async (params) => {
    setWaiting(true)
    try {
      const results = await getF6Results(params)
      setData(results)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <div style={{ marginBottom: '32px'}} />
      <Header data={data} waiting={waiting} getData={getData} getExportValues={getResultExportValues} />
      {data?.length ? (
        <StyledRow3>
          <b>{`${t('total_results')}: ${data?.length}`}</b>
        </StyledRow3>
      ) : null}
      <StyledSubtitle3>
        <a href="#footnote"> {t('Indicators_results')}</a>
      </StyledSubtitle3>
      <StyledRow>
        <Table data={data} waiting={waiting} setData={setData} />
      </StyledRow>
      <div id="footnote">
        {htmlParser(t('Statistics_footnote_6'))}
      </div>
    </>
  )
}

export default Tab1
