import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { getDownload, saveFile } from 'services/initiative';
import Spinner from 'react-bootstrap/Spinner';
import { StyledUploadButton } from 'components/Forms/Form5/Section7/ProductsForm/ProductForm/ProductForm.styled';
import Form from 'react-bootstrap/Form';
import { RequiredMark } from 'components/CommonStyled';
import { StyledCustomLink } from 'components/Forms/Forms.styled';

const FileUploading = ({ setModalInfo, params, handleChange, values, setFieldValue, nonEditable, setMissingField }) => {
  const { t } = useTranslation();
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const handleDownload = async (uuid, downloadName) => {
    try {
      await getDownload(uuid).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      setModalInfo({
        message: t('Error_file1'),
        type: 'error',
        isOpen: true,
      });
    }
  };

  const handleFileUpload = async e => {
    try {
      const file = document.getElementById('fileInput').files[0];
      if (file.size > 31457280) {
        setModalInfo({
          message: t('Error_file6'),
          type: 'error',
          isOpen: true,
        });
      } else {
        setIsUploadingFile(true);
        const uid = await saveFile(params.id, params.formId, params.sectionId, file);
        setFieldValue('file.fileName', file.name);
        setFieldValue('file.uuid', uid);
        setMissingField(false);
        handleChange(e);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploadingFile(false);
    }
  };

  return isUploadingFile ? (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
      <Spinner animation="border" size="sm" />
      <span style={{ marginLeft: '10px', fontStyle: 'italic' }}> Uploading document...</span>
    </div>
  ) : (
    <>
      {values && values.file ? (
        <div style={{ marginTop: '30px', marginBottom: '30px' }}>
          <span>
            ({t('F5_S7_products_documents_upload')},
            <StyledCustomLink
              style={{ marginLeft: '5px', marginRight: '2px' }}
              onClick={() => handleDownload(values.file.uuid, values.file.fileName)}
            >
              {values.file ? values.file.fileName : null}
            </StyledCustomLink>
            )
          </span>
        </div>
      ) : (
        <>
          <div style={{ display: 'flex' }}>
            <StyledUploadButton
              variant="link"
              disabled={nonEditable}
              onClick={() => {
                document.getElementById('fileInput').click();
              }}
            >
              {t('F5_S7_Products_upload_document')}
            </StyledUploadButton>
            <RequiredMark style={{marginLeft: '-8px'}}>*</RequiredMark>
          </div>
          <Form.File
            id="fileInput"
            style={{ visibility: 'hidden', background: 'red' }}
            onChange={e => {
              handleFileUpload(e);
            }}
            type="file"
          />
        </>
      )}
    </>
  );
};

export default observer(FileUploading);
