import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react-lite';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import { useTranslation } from 'react-i18next';
import { Formik } from "formik";
import { validationSchema } from "./Validations";
import { useRootStore } from "stores";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { RequiredMark } from "components/CommonStyled";
import {
  StyledFormLabel, StyledRequiredSpan,
} from "./Styled";
import { saveFundSection, setFundState } from 'services/funds';
import { useConstantsFunds } from 'customHooks/useConstantsFunds';
import { InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { fundStatesConst } from 'constants/funds';
import { PrimaryButton } from 'components/Buttons';
import AddPersonForm from './AddPersonForm/AddPersonForm';
import AddedPersonsList from './AddedPersonsList';
import { roleNamesConst } from 'constants/appConstants';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';


const Section1 = () => {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState({
    "fundTypeId": "",
    "title": "",
    "fromDate": "",
    "toDate": "",
    "observations": ""
  });

  const [saved, setSaved] = useState(false);
  const [modalDataAddPerson, setModalDataAddPerson] = useState(false)
  const [addedUsersList, setAddedUsersList] = useState([])

  const { formStore, initiativeStore, authStore } = useRootStore();
  const { id } = useParams();
  const { sortedFundTypes: fundTypes } = useConstantsFunds();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = formStore?.formSectionData;
      setAddedUsersList(formSectionData?.[1])
    }
  }, [formStore.formSectionData])

  useEffect(() => {
    if (formStore.formSectionData) {
      const newData = { ...formStore.formSectionData[0] }
      delete newData.id;
      delete newData.complete;
      setInitialValues({
        ...newData,
        fundTypeId: newData?.fundType?.id,
        fundType: undefined,
        fromDate: newData?.fromDate ? new Date(newData?.fromDate) : null,
        toDate: newData?.toDate ? new Date(newData?.toDate) : null,
        statusId: initiativeStore?.statusName ?? ''
      });
    }
  }, [formStore.formSectionData, initiativeStore?.statusName])

  const onSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFundSection(id, 1, values);
      formStore.setSectionStatus(1, complete);
      if (values?.statusId && values?.statusId !== '') {
        await setFundState(id, values?.statusId)
      }
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid ? onSubmit(values, { resetForm }) : alert(t("not_saved_alert"));
  };

  const currentRole = authStore.roles[0];
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM;
  const isAtke = currentRole === roleNamesConst.ATKE;
  const isIntpa = currentRole === roleNamesConst.INTPA;

  const canInvitePeople = isConsortium || isAtke || isIntpa;

  return (
    <FormLayout>
      <FormContainer
        number={1}
        title={t('F1_S1_title')}
        formId="form55section1"
        showSavedMessage={saved}
        showSaveButton
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isValid,
            resetForm,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => {
            if (dirty) {
              setSaved(false)
            }
            return (
              <form
                onSubmit={(e) => {
                  onValidateSubmit(e, isValid, values, { resetForm });
                }}
                id="form55section1"
              >
                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F55_main_title")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={4}>
                    <Form.Control
                      name="fundTypeId"
                      as="select"
                      onChange={handleChange}
                      value={values.fundTypeId}
                    >
                      <option value="">{t("Placeholder_select")}</option>
                      {fundTypes.map((entity) => (
                        <option key={entity.id} value={entity.id}>
                          {t(`fund_type_${entity.id}`)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched={touched.fundTypeId}
                      errors={errors.fundTypeId}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("fund_title")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      className={
                        touched.title && errors.title ? "error" : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.title}
                      errors={errors.title}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: 'center' }}>
                  <StyledFormLabel column lg={4}>
                    {t('Start_Date')} <StyledRequiredSpan>*</StyledRequiredSpan>:
                  </StyledFormLabel>
                  <Col lg={4}>
                    <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={values.fromDate}
                        onChange={date => setFieldValue('fromDate', date)}
                        className={errors.fromDate ? 'error' : null}
                      />
                    </InputGroup>
                    <FormErrorMessage
                      touched
                      errors={errors.fromDate}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: 'center' }}>
                  <StyledFormLabel column xs={4}>
                    {t('End_Date')} <StyledRequiredSpan>*</StyledRequiredSpan>:
                  </StyledFormLabel>
                  <Col lg={4}>
                    <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={values.toDate}
                        onChange={date => setFieldValue('toDate', date)}
                        className={errors.toDate ? 'error' : null}
                      />
                    </InputGroup>
                    <FormErrorMessage
                      touched
                      errors={errors.toDate}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("Status")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={4}>
                    <Form.Control
                      name="statusId"
                      as="select"
                      onChange={handleChange}
                      value={values.statusId}
                    >
                      <option value="">{t("Placeholder_select")}</option>
                      {Object.keys(fundStatesConst).map((item) => (
                        <option key={item} value={item}>
                          {t(item)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched={touched.statusId}
                      errors={errors.statusId}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column xs={4}>
                    {t('fund_observations')}:
                  </StyledFormLabel>
                  <Col>
                    <Form.Control
                      name="observations"
                      as="textarea"
                      rows={3}
                      style={{ resize: 'none' }}
                      placeholder={t('Maximum_2000')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.observations}
                      className={
                        touched.observations && errors.observations ? 'error' : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.observations}
                      errors={errors.observations}
                    />
                  </Col>
                </Form.Group>

                {modalDataAddPerson && (
                  <AddPersonForm
                    show={modalDataAddPerson}
                    onHide={() => {
                      setModalDataAddPerson(false)
                    }}
                    addedUsersList={addedUsersList}
                  />
                )}

                <AddedPersonsList addedUsersList={addedUsersList} canInvitePeople={canInvitePeople} />

                {canInvitePeople ? (
                  <div style={{ marginLeft: '50px', marginTop: '30px' }}>
                    <PrimaryButton
                      onClick={() => {
                        setModalDataAddPerson(true)
                      }}
                    >
                      {t('Add_person_button')}
                    </PrimaryButton>
                  </div>
                ) : null}
              </form>
            );
          }}
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section1);