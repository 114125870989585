import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportResultsToXLSX } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'

import { Form } from 'react-bootstrap'
import { PrimaryButton } from 'components/Buttons'
import {
  Col1,
  Col2,
  StyledFormGroup,
  StyledFormLabel,
} from '../../CommonStyled/Components.styled'
import Row from 'react-bootstrap/Row'

import { renderTypeOptions } from 'pages/statistics/CommonHelpers/helpers'

const Header = ({ data, getData, waiting, getExportValues }) => {
  const { t } = useTranslation()
  const [typeFilter, setTypeFilter] = useState('*')

  const handleFilter = () => {
    let params = {}
    if (typeFilter) {
      if (typeFilter === '*') {
        params.includeFunds = true;
        params.includeInitiatives = true;
      }
      if (typeFilter === 'FUNDS') {
        params.includeFunds = true;
        params.includeInitiatives = false;
      }
      if (typeFilter === 'INITIATIVES') {
        params.includeFunds = false;
        params.includeInitiatives = true;
      }
    }
    getData(params)
  }

  return (
    <Row>
      <Col1 xs={7}>
        <StyledFormGroup style={{ width: '60%'}}>
          <StyledFormLabel>{t('type_filter_stat')}</StyledFormLabel>
          <Form.Control
            name="type"
            as="select"
            onChange={(e) => {
              setTypeFilter(e.target.value)
            }}
            value={typeFilter}
          >
            {renderTypeOptions()}
          </Form.Control>
        </StyledFormGroup>

        <PrimaryButton
          onClick={() => {
            handleFilter()
          }}
          disabled={waiting}
        >
          {t('Button_search')}
        </PrimaryButton>
      </Col1>
      <Col2>
        <ExportToXLSXButton
          data={data}
          mapData={getExportValues}
          disabled={waiting}
          exportName={t('menu_admin_6_7')}
        >
          <ExportResultsToXLSX data={data} />
        </ExportToXLSXButton>
      </Col2>
    </Row>
  )
}

export default Header
