import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import TextValue from 'components/TextValue';
import { useTranslation } from 'react-i18next';
import FormLayout from '../../FormLayout';
import FormContainer from 'components/FormContainer';
import Comments from '../Comments';
import { roleNamesConst, f5statusNamesConst } from 'constants/appConstants';

import Form from 'react-bootstrap/Form';

import {
  StyledSubtitle,
  StyledDivWrapper,
  StyledText,
} from './Section6.styled';


const Section6 = () => {

  const { formStore, authStore, initiativeStore } = useRootStore();
  const {t} = useTranslation();
  const [formValues, setFormValues] = useState([]);
  const currentRole = authStore.roles[0];
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM;
  const isAtke = currentRole === roleNamesConst.ATKE;
  const isIntpa = currentRole === roleNamesConst.INTPA;
  const isEud = currentRole === roleNamesConst.EUD;
  const [isF7, setIsF7] = useState(false);
  const [saved, setSaved] = useState(false);
  const [disabledComments, setDisabledComments] = useState(true);
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = [...formStore.formSectionData];
      setFormValues(formSectionData[0]);
    }
  }, [formStore.formSectionData])

  useEffect(() => {
    if(initiativeStore.formStateName)
      setIsF7(((isIntpa || isEud) && initiativeStore.formStateName !== f5statusNamesConst.DRAFT && initiativeStore.formStateName !== f5statusNamesConst.SENT ));
  }, [initiativeStore.formStateName])

  const handleSaved = (value) => {
    setSaved(value);
  }

  const handleButton = (value) => {
    setDisabledComments(value);
  }

  return (
    <FormLayout>
      <FormContainer 
        number={6} 
        title={t('F1_S6_title')}
        formId='form5section6'
        showSavedMessage={saved}
        showButtonClarif={!disabledComments}
      >
        <StyledDivWrapper>  
          <StyledSubtitle>{t('F1_S6_Subtitle_1')}</StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_1')}:</StyledText>
            <TextValue value={formValues.name} />
          </Form.Group>  

          <Form.Group style={{display: 'flex'}}>
            <div style={{float: 'left'}}>{t('F1_S6_Question_2')}:</div>
            <TextValue value={formValues.description} />
          </Form.Group>  

          <Form.Group style={{display: 'flex'}}>
            <div style={{float: 'left'}}>{t('F1_S6_Question_3')}:</div>
            <TextValue value={formValues.objective} />
          </Form.Group>  
          
          <StyledSubtitle>{t('F1_S6_Subtitle_2')}</StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_8')}:</StyledText>
            <TextValue value={formValues.contribution} />
          </Form.Group>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_4')}:</StyledText>
            <TextValue value={formValues.latamKnowledge} />
          </Form.Group>  

          <Form.Group>
            <StyledText>{t('F1_S6_Question_5')}:</StyledText>
            <TextValue value={formValues.euKnowledge} />
          </Form.Group>  

          <Form.Group>
            <StyledText>{t('F1_S6_Question_6')}:</StyledText>
            <TextValue value={formValues.newKnowledge} />
          </Form.Group>  

          <Form.Group>
            <StyledText>{t('F1_S6_Question_7')}:</StyledText>
            <TextValue value={formValues.application} />
          </Form.Group>    

          <Form.Group>
            <StyledText>{t('F1_S6_Question_9')}:</StyledText>
            <TextValue value={formValues.leap} />
          </Form.Group>  
            
          <StyledSubtitle>{t('F1_S6_Subtitle_3')}</StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_10')}:</StyledText>
            <TextValue value={formValues.benEntities} />
          </Form.Group>  

          <Form.Group>
            <StyledText>{t('F1_S6_Question_11')}:</StyledText>
            <TextValue value={formValues.directBen} />
          </Form.Group>  

          <StyledSubtitle>{t('F1_S6_Subtitle_4')}</StyledSubtitle>

          <Form.Group style={{display: 'flex'}}>
            <div style={{float: 'left'}}>{t('F3_S6_Subtitle_4_intro')}:</div>
          </Form.Group>  

          <StyledSubtitle> {t('F1_S6_Subtitle_5')}  </StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_16')}:</StyledText>
            <TextValue value={formValues.rights} />
          </Form.Group> 

          <Form.Group>
            <StyledText>{t('F1_S6_Question_17')}:</StyledText>
            <TextValue value={formValues.environment} />
          </Form.Group> 

          <StyledSubtitle> {t('F1_S6_Subtitle_6')} </StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_18')}:</StyledText>
            <TextValue value={formValues.innovation} />
          </Form.Group> 

          <StyledSubtitle> {t('F1_S6_Subtitle_7')} </StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_19')}:</StyledText>
            <TextValue value={formValues.valueTC} />
          </Form.Group> 

          <Form.Group>
            <StyledText>{t('F1_S6_Question_20')}:</StyledText>
            <TextValue value={formValues.valueEU} />
          </Form.Group> 

          <Form.Group>
            <StyledText>{t('F1_S6_Question_21')}:</StyledText>
            <TextValue value={formValues.valueTP} />
          </Form.Group> 
          
          <StyledSubtitle> {t('F1_S6_Subtitle_8')} </StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_22')}:</StyledText>
            <TextValue value={formValues.covid19} />
          </Form.Group> 

          {(isAtke || isConsortium || isF7 )&&
             <Comments onSaved={handleSaved} 
                       onButton={handleButton}/>
          } 

        </StyledDivWrapper>   
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section6);
