import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import TextValue from 'components/TextValue'

import {
  StyledFormLabel,
} from '../Section4.styled';

import { useTranslation } from 'react-i18next';


const OtherEntitiesForm = ({ entityIndex, data }) => {
  const {t} = useTranslation();

  return (
    <>
      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Entity_name')}:</StyledFormLabel>
        <TextValue value={data.entityName} />
      </Form.Group>  

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Official_acronym')}:</StyledFormLabel>
        <TextValue value={data.acronym} />
      </Form.Group>  

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Type_of_entity')}:</StyledFormLabel>
        <TextValue value={data.entityType?  t(`entity_type_${data.entityType.id}`): ' - '} />
      </Form.Group>  

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S4_Function')}:</StyledFormLabel>
        <TextValue value={data.function} />
      </Form.Group>  

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Country')}:</StyledFormLabel>
        <TextValue value={data.country? t(data.country.name): ' - '} />
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Website')}:</StyledFormLabel>
        <TextValue value={data.web} />
      </Form.Group> 

      <Form.Group as={Row} style={{alignItems: 'center', marginTop: '40px'}}>
        <StyledFormLabel column lg={4}>
          {t('F1_S4_Participanting_entity')}:
        </StyledFormLabel>
        <TextValue value={data.firstName} />
        <TextValue value={data.lastName} />
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Position')}:</StyledFormLabel>
        <TextValue value={data.position} />
      </Form.Group>  

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Email')}:</StyledFormLabel>
        <TextValue value={data.email} />
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Phone_number')}:</StyledFormLabel>
        <TextValue value={data.phone} />
      </Form.Group>  
    </>
  )

}

export default OtherEntitiesForm;
