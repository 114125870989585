import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getPreFormulationDashboardData } from 'services/initiative';

import CustomXLSXExportButton from './exportToXLSx';
import Spinner from 'react-bootstrap/Spinner';
import MoneyTextMask from 'components/MoneyTextMask';
import { PrimaryButton } from 'components/Buttons';
import { StyledTitle } from './DashboardPreformulation.styled';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

const DashboardPreformulation = () => {
  const { t } = useTranslation();
  const [waiting, setWaiting] = useState(false);
  const [tableValues, setTableValues] = useState([]);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    getTableValues({ year: selectedYear });
  }, []);

  const handleFilterYearChange = e => {
    setSelectedYear(e.target.value);
  };

  async function getTableValues(qp) {
    try {
      setWaiting(true);
      const response = await getPreFormulationDashboardData(qp);
      setTableValues({ ...response.data });
    } catch (error) {
      console.log(error);
    } finally {
      setWaiting(false);
    }
  }

  const renderYears = () => {
    var indents = [];
    indents.push(<option value={0}> {t('Button_filter_all')} </option>);
    for (var i = 2021; i <= 2024; i++) {
      indents.push(<option value={i}> {i} </option>);
    }
    return indents;
  };

  const handleFilter = () => {
    if (selectedYear != 0) {
      getTableValues({ year: selectedYear });
    } else {
      getTableValues(null);
    }
  };

  if (waiting)
    return (
      <Col style={{ marginTop: '10%', textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </Col>
    );

  return (
    <>
      <StyledTitle>{t('Dashboard_Preformulation_cn')}</StyledTitle>

      <Row style={{ justifyContent: 'flex-end' }}>
        <CustomXLSXExportButton tableValues={tableValues} />
      </Row>

      <Row style={{ alignItems: 'center', marginLeft: '0px' }}>
        {t('Dashboard_Year')}
        <Col xs={2}>
          <Form.Control as="select" onChange={handleFilterYearChange} value={selectedYear}>
            {renderYears()}
          </Form.Control>
        </Col>
        <PrimaryButton onClick={handleFilter} disabled={waiting}>
          {t('Button_filter')}
        </PrimaryButton>
      </Row>

      <Table border="1" style={{ marginTop: '30px' }}>
        <tr style={{ backgroundColor: '#c2deb1' }}>
          <th scope="col"></th>
          <th scope="col">{t('Dashboard_Preform_approved')}</th>
          <th scope="col">{t('Dashboard_Preform_assessment')}</th>
          <th scope="col">{t('Dashboard_Preform_total')}</th>
        </tr>
        <tr>
          <td>
            <strong>{t('Dashboard_Preform_adelante_window')}</strong>
          </td>
          <td>
            <MoneyTextMask value={tableValues?.approvedWindowBudget} />
          </td>
          <td>
            <MoneyTextMask value={tableValues?.assessmentWindowBudget} />
          </td>
          <td>
            <MoneyTextMask value={tableValues?.totalWindowBudget} />
          </td>
        </tr>
        <tr>
          <td>
            <strong>{t('Dashboard_Preform_counterpart')}</strong>
          </td>
          <td>
            <MoneyTextMask value={tableValues?.approvedCounterpartBudget} />
          </td>
          <td>
            <MoneyTextMask value={tableValues?.assessmentCounterpartBudget} />
          </td>
          <td>
            <MoneyTextMask value={tableValues?.totalCounterpartBudget} />
          </td>
        </tr>
        <tr>
          <td>
            <strong>{t('Dashboard_Preform_days')}</strong>
          </td>
          <td>
            {tableValues?.approvedNumberOfWorkingDays} ({tableValues?.approvedNumberOfRemoteWorkingDays} /
            {tableValues?.approvedNumberOfOnSiteWorkingDays})
          </td>
          <td>
            {tableValues?.assessmentNumberOfWorkingDays} ({tableValues?.assessmentNumberOfRemoteWorkingDays} /
            {tableValues?.assessmentNumberOfOnSiteWorkingDays})
          </td>
          <td>
            {tableValues?.totalNumberOfWorkingDays} ({tableValues?.totalNumberOfRemoteWorkingDays} /
            {tableValues?.totalNumberOfOnSiteWorkingDays})
          </td>
        </tr>
        <tr>
          <td colSpan={4}>{t('Dashboard_Preform_disclaimer')}</td>
        </tr>
      </Table>
    </>
  );
};

export default DashboardPreformulation;
