import React, { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { validationSchema } from './Section15.validations';
import { formInitialValues } from './Section15.initialValues';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router-dom';
import { useRootStore } from 'stores';
import { roleNamesConst } from 'constants/appConstants';
import { getF4Section15, saveForm4GeneralAssesment, sendF4toMainAssessor, sendF4IntpaDecision, enableSection7 } from 'services/initiative';
import { PrimaryButton, ErrorButton, SuccessButton } from 'components/Buttons';
import {f3statusNamesConst, f4statusNamesConst} from 'constants/appConstants'
import { useTranslation } from 'react-i18next';

import IntpaATComment from './IntpaATComment'
import EUDAssessments from './EUDAssessments';
import SendDialog from './SendDialog';
import MyAssesmentForm from './MyAssessmentForm';
import ATCommentForm from './ATCommentForm';

import FormLayout from '../../FormLayout'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {
  StyledSeparator,
  StyledGreenFormLabel,
  StyledSavedButton,
} from './Section15.styled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const Section15 = () => {
  const { id } = useParams();
  const history = useHistory();
  const {t} = useTranslation();
  const formRef = useRef();
  const { formStore, authStore } = useRootStore();

  const [nonEditable, setNonEditable] = useState(true);
  const [sending, setSending] = useState(false);

  const [displayModalSend, setDisplayModalSend] = useState(false);
  const [intpaDecision, setIntpaDecision] = useState(false)
  const [formValues, setFormValues] = useState(formInitialValues);
  const [saved, setSaved] = useState(false);
  const [form4states, setForm4states] = useState({
    eudForms: [],
    atForm: {},
    intpaForm: {},
    initiative: {}
  });
 
  const role = authStore.roles[0];
  const isIntpa = role === roleNamesConst.INTPA;
  const isEud = role === roleNamesConst.EUD;
  const isTa = role === roleNamesConst.ATKE || role === roleNamesConst.ATNKE || role === roleNamesConst.CONSORTIUM;
  const isATKNKE = role === roleNamesConst.ATNKE;
  const isCONSORTIUM = role === roleNamesConst.CONSORTIUM;

  async function fetchAPI() {
    try {
      const { eudForms, atForm, intpaForm, initiative } = await getF4Section15(id);
      setForm4states({ eudForms, atForm, intpaForm, initiative });
    }
    catch(e){
      console.log(e);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);
  }, [saved])
  
  useEffect(() => {
    fetchAPI();
  }, []);

  useEffect(() => {
    if(isTa){
      setFormValues(form4states.atForm);
    }
    else if(isEud && form4states.eudForms && form4states.eudForms[0]){
      setFormValues(form4states.eudForms[0]);
    }
    else if(isIntpa){
      setFormValues(form4states.intpaForm);
    }
    formStore.setCurrentSection(15);
  }, [formStore, form4states, isTa, isEud, isIntpa])
  
  useEffect(() => {
    if(Object.entries(form4states.initiative).length > 0){
      
      const isSent = form4states.initiative && (form4states.initiative.formStateName === f3statusNamesConst.SENT);
      if(isEud){
        (isSent && 
        ((form4states.eudForms[0].stateName === f4statusNamesConst.PENDING) || 
          (form4states.eudForms[0].stateName === f4statusNamesConst.ONGOING)))? setNonEditable(false) : setNonEditable(true); 
      }
      else if(isTa){
        (isSent && ((form4states.atForm.stateName === f4statusNamesConst.PENDING) ||
          (form4states.atForm.stateName === f4statusNamesConst.ONGOING )))? setNonEditable(false) : setNonEditable(true); 
      }
      else if(isIntpa){
        isSent? setNonEditable(false) : setNonEditable(true);
      }

      if(isATKNKE || isCONSORTIUM){
        setNonEditable(true);
      }
    }
  }, [form4states.initiative, form4states.eudForms, form4states.atForm, form4states.intpaForm, isIntpa, isEud, isTa, isATKNKE, isCONSORTIUM]);

  const handleSendIntpaDecision = async (decision) => {
    try {
      setSending(true);
      await handleSave(formRef.current.values);
      await sendF4IntpaDecision(id, decision);
      history.push(`${authStore.getBaseRouteByRole()}/proposals`);
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  }

  const handleSendToMainAssessor = (errors, setErrors, values) => {
    if(!values.assessment || values.assessment===""){
      alert(t('not_saved_alert'));
      setErrors({
        ...errors, 
        assessment: "*Assessment must not be empty"
      });
    }
    else{
      handleOpenModalSend();
    }
  }

  const handleSend = async () => {
    try {
      setSending(true);
      await handleSave(formRef.current.values);
      await sendF4toMainAssessor(id);
      history.push(`${authStore.getBaseRouteByRole()}/proposals`);
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  };

  const handleSave = async (values, resetForm ) => {
    try {
      setSending(true);
      await saveForm4GeneralAssesment(id, values);
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  }; 

  const handleOpenModalSend = (approval) => {
    setIntpaDecision(approval);
    setDisplayModalSend(true);
  }
  
  const handleCloseModalSend = () => setDisplayModalSend(false);

  const handleEnableS7 = async () => {
    try {
      setSending(true);
      await enableSection7(id);
      history.push(`${authStore.getBaseRouteByRole()}/proposals`);   
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  }; 
  
  return ( 
    <FormLayout>
      { displayModalSend && <SendDialog 
        displayDialog={displayModalSend}
        isIntpa={isIntpa}
        intpaDecision={intpaDecision}
        onSend={isIntpa? handleSendIntpaDecision: handleSend}
        onCloseModal={handleCloseModalSend} 
      /> }

      <Formik 
        enableReinitialize
        initialValues={formValues}
        validationSchema={validationSchema}
        innerRef={formRef}
        onSubmit={handleSave}
      >
        { props => (
            <Form style={{minWidth: '100%'}} id="form4section15">
            {props.dirty && setSaved(false)}
            
            <div style={{display: "flex", justifyContent: "flex-end", marginRight: "30px"}}>
                
                {!saved && <PrimaryButton disabled={nonEditable || sending} onClick={() => {handleSave(props.values, props.resetForm )}}> 
                {t('Button_Save_Later')}
                </PrimaryButton>}
                {saved && <StyledSavedButton disabled={true} > 
                {t('Button_saved')}
                </StyledSavedButton> }
           
            </div> 

              {isIntpa && 
                <IntpaATComment atComment={form4states.atForm.assessment? form4states.atForm.assessment : " - "}/>
              } 
              
              {(isTa && !isATKNKE && !isCONSORTIUM && form4states.atForm.stateName === f4statusNamesConst.PENDING_DRAFT) &&
               <div style={{display: 'flex', justifyContent: 'center'}}>
                  <SuccessButton style={{background: '#15A7CB'}} disabled={sending} onClick={() => {handleEnableS7()}}>{t('enableS7')}</SuccessButton>
               </div>
              } 
              
              {(isIntpa || isTa) && 
                <EUDAssessments isIntpa={isIntpa} f4AValues={form4states.eudForms} intpaAssessment={form4states.intpaForm} initiativeCode={form4states.initiative? form4states.initiative.code: ''} nonEditable={nonEditable}/>
              } 
                           
              {(isIntpa || isEud) && 
                <>
                  <MyAssesmentForm formikInstance={props} nonEditable={nonEditable}/>
                  <StyledSeparator />
                  <div style={{ marginLeft: "35px", marginTop: "40px", marginRight: "15px" }}>
                    <Form.Group>
                      <StyledGreenFormLabel>{isIntpa? t('F2_intpa_comments'): t('F2_overall_asssessment')}</StyledGreenFormLabel>
                      <Form.Control
                        as="textarea"
                        style={{resize: "none"}}
                        rows={8} 
                        name="assessment"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.assessment}
                        style={{resize: "none"}}
                        disabled={nonEditable}
                        className={props.touched.assessment && props.errors.assessment ? "error" : null}
                      />
                      <FormErrorMessage
                        touched={props.touched.assessment}
                        errors={props.errors.assessment}
                      />
                    </Form.Group>
                  </div>
                </>
              } 

              {isTa && <ATCommentForm formikInstance={props} nonEditable={nonEditable}/>}
              
              <div style={{marginTop: '40px', marginLeft: '40px', marginRight: '20px'}}>
                <Row style={{justifyContent: 'space-between'}}>
                  <Col>
                    {(!isIntpa) &&
                        <> {!saved && <PrimaryButton disabled={nonEditable || sending} onClick={() => {handleSave(props.values, props.resetForm )}}> {t('Button_Save_Later')} </PrimaryButton>}
                        {saved && <StyledSavedButton disabled={true} > 
                          {t('Button_saved')}
                        </StyledSavedButton> } 
                        </>
                    }
                  </Col>
                  <Col  style={{marginBottom: "40px"}} className="col-auto">
                    {(!isIntpa)?
                    <SuccessButton disabled={nonEditable || sending} onClick={() => { handleSendToMainAssessor(props.errors, props.setErrors, props.values) }}> {t('Button_Send_Assessor')} </SuccessButton>
                    :
                    <> {!saved && <PrimaryButton disabled={nonEditable || sending} onClick={() => {handleSave(props.values, props.resetForm )}}> {t('Button_Save_Later')} </PrimaryButton>}
                       {saved && <StyledSavedButton disabled={true} > 
                            {t('Button_saved')}
                        </StyledSavedButton> } 
                    </>
                    }
                  </Col>
                </Row>

            {isIntpa && 
                <Row style={{justifyContent: 'space-between'}}>
                  <Col >
                    <ErrorButton disabled={nonEditable || sending || !props.values.assessment} onClick={() => {handleOpenModalSend(false)}} > {t('Button_Reject_proposal')} </ErrorButton>
                  </Col>
                  <Col className="col-auto">
                    <SuccessButton disabled={nonEditable || sending || !props.values.assessment} onClick={() => {handleOpenModalSend(true)}}> {t('Button_Approve_proposal')} </SuccessButton>
                  </Col>
                </Row>
            }    
              </div>
            </Form>
        )}
      </Formik>

    </FormLayout>
  )
}

export default observer(Section15)