import React from 'react';
import { useTranslation } from 'react-i18next';

import { 
  StyledHeader,
} from './Header.styled'
import Container from 'react-bootstrap/Container';
import { StyledSendButton as StyledSaveButton } from 'components/Buttons';

const Header = (props) => {
  const { t } = useTranslation();
  const formid = props.role == 0 ? 'form8b' : props.role == 1 ? 'form9a' : 'form9c';
 
  return (
    <>
      <Container fluid>
        <StyledHeader>
          {!props.nonEditable &&
            <StyledSaveButton type='submit' form={formid} disabled={props.saved}>{props.saved? t('Button_saved') : t('Button_Save_Later')} </StyledSaveButton> }
        </StyledHeader> 
      </Container>    
    </>
  )
}

export default Header;