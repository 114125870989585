import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import {
  getDocuments,
  getDocumentsApplicant,
  getImplementationInitiatives,
  getDownload,
} from "services/initiative";
import { useRootStore } from "stores";
import { roleNamesConst } from "constants/appConstants";
import { formatDateDDMMYY } from "helpers/dateHelpers";

import Pagination from "react-bootstrap/Pagination";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import PageTitle from "components/PageTitle";
import {
  PageHeader,
  PageContent,
} from "../PurchasingManagement/PurchasingManagement.styled";
import { PrimaryButton } from "components/Buttons";
import { StyledCol } from "pages/manager/ActivityReport/ActivityReport.styled";
import DocumentsUploaderModal from "./DocumentsUploaderModal";
import { StyledCustomLink } from 'components/Forms/Forms.styled';

const Documents = () => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  const [initiativesOptions, setInitiativesOptions] = useState([]);

  const [filterType, setFilterType] = useState(0);
  const [filterInitiative, setFilterInitiative] = useState(0);

  const [tableValues, setTableValues] = useState([]);

  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const PAGE_SIZE = 100;

  const [params, setParams] = useState({});

  const [showModal, setShowModal] = useState(false);
  const role = authStore.roles[0];

  const isPublic = role === roleNamesConst.PUBLIC;
  const isEud = role === roleNamesConst.EUD;
  const isAtke = role === roleNamesConst.ATKE;
  const isConsortium = role === roleNamesConst.CONSORTIUM;

  useEffect(() => {
    getInitiatives();
    getValues(0);
  }, []);

  const handleDownload = async (uuid, downloadName) => {
    try {
      await getDownload(uuid).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadName);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.log(error);
    }
  };

  async function getValues(page, params) {
    try {
      let response;
      if (isPublic || isEud) {
        response = await getDocumentsApplicant({
          page: page,
          pageSize: PAGE_SIZE,
          ...params,
        });
      } else {
        response = await getDocuments({
          page: page,
          pageSize: PAGE_SIZE,
          ...params,
        });
      }
      response.content.forEach((e) => {
        e.actividad = e.actividad !== null ? e.actividad : " - ";
        e.fileName = e.fileName !== null ? e.fileName : " - ";
        e.description = e.description !== null ? e.description : " - ";
        e.updatedAt =
          e.updatedAt !== null ? formatDateDDMMYY(e.updatedAt) : " - ";
      });
      setTableValues(response.content);
      setLastPage(response.totalPages == 0 ? 0 : response.totalPages - 1);
      setCurrentPage(page);
    } catch (error) {
      console.log(error);
    }
  }

  async function getInitiatives() {
    try {
      const response = await getImplementationInitiatives();
      setInitiativesOptions(response);
    } catch (error) {
      console.log(error);
    }
  }

  const handleChangeInitiative = (e) => {
    setFilterInitiative(e.target.value);
  };

  const handleChangeType = (e) => {
    setFilterType(e.target.value);
  };

  const handleFilter = () => {
    let params = {};
    if (filterType != 0) params.type = filterType - 1;
    if (filterInitiative != 0) params.initiativeId = filterInitiative;

    setParams(params);
    getValues(0, params);
  };

  const columns = [
    {
      dataField: "actividad",
      text: t("Document_level"),
      headerStyle: { width: "150px" },
    },
    {
      dataField: "typeOfFile",
      text: t("F5_S15_Lesson_type"),
      headerStyle: { width: "250px" },
      // eslint-disable-next-line no-unused-vars
      formatter: (cell, row, rowIndex, extraData) =>
        // TODO : row.typeOfFile ? t(`document_type_${row.typeOfFile}`) : ' - ',
        row.typeOfFile ? row.typeOfFile : " - ",
    },
    {
      dataField: "fileName",
      text: t("documents_file_name"),
      headerStyle: { width: "300px" },
      // eslint-disable-next-line no-unused-vars
      formatter: (cell, row, rowIndex, extraData) => (
        <div style={{ overflowWrap: "break-word" }}>
          <StyledCustomLink
            onClick={() => handleDownload(row.fileUuid, row.fileName)}
          >
            {row.fileUuid ? row.fileName : null}
          </StyledCustomLink>
        </div>
      ),
    },
    {
      dataField: "fileUuid",
      text: "",
      hidden: true,
    },
    {
      dataField: "description",
      text: t("documents_description_comment"),
      headerStyle: { width: "300px" },
    },
    {
      dataField: "updatedAt",
      text: t("documents_date"),
      headerStyle: { width: "200px" },
    },
  ];

  return (
    <>
      {showModal ? (
        <DocumentsUploaderModal
          show={showModal}
          onHide={() => setShowModal(false)}
        />
      ) : null}
      <ToolkitProvider keyField="id" data={tableValues} columns={columns}>
        {(props) => (
          <>
            <PageHeader>
              <PageTitle title={t("menu_admin_18")} />
            </PageHeader>
            <Row style={{ alignItems: "center" }}>
              <StyledCol>
                {t("Initiative")}
                <Form.Control
                  as="select"
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    width: "300px",
                  }}
                  onChange={handleChangeInitiative}
                  value={filterInitiative}
                >
                  <option value={0}> {t("ALL")} </option>
                  {initiativesOptions &&
                    initiativesOptions.map((e) => (
                      <option value={e.id}> {`${e.code} - ${e.name}`} </option>
                    ))}
                </Form.Control>

                {t("F1_S2_Type_of_entity")}
                <Form.Control
                  as="select"
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    width: "300px",
                  }}
                  onChange={handleChangeType}
                  value={filterType}
                >
                  <option value="0"> {t("ALL")} </option>
                  <option value="1"> {t(`document_type_${0}`)} </option>
                  <option value="2"> {t(`document_type_${1}`)} </option>
                  <option value="3"> {t(`document_type_${2}`)} </option>
                  <option value="4"> {t(`document_type_${3}`)} </option>
                  <option value="5"> {t(`document_type_${4}`)} </option>
                  <option value="6"> {t(`document_type_${5}`)} </option>
                  <option value="7"> {t(`document_type_${6}`)} </option>
                  <option value="8"> {t(`document_type_${7}`)} </option>
                </Form.Control>
                <PrimaryButton
                  onClick={() => {
                    handleFilter();
                  }}
                >
                  {t("Button_search")}
                </PrimaryButton>
              </StyledCol>
              <StyledCol style={{ justifyContent: "flex-end" }}>
                {(isAtke || isConsortium) && (
                  <PrimaryButton
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    + Añadir Documento
                  </PrimaryButton>
                )}
              </StyledCol>
            </Row>
            <PageContent>
              <BootstrapTable {...props.baseProps} />

              <Pagination
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <Pagination.First
                  disabled={currentPage == 0}
                  onClick={() => getValues(0, params)}
                />
                <Pagination.Prev
                  disabled={currentPage == 0}
                  onClick={() => getValues(currentPage - 1, params)}
                />
                <Pagination.Next
                  disabled={currentPage == lastPage}
                  onClick={() => getValues(currentPage + 1, params)}
                />
                <Pagination.Last
                  disabled={currentPage == lastPage}
                  onClick={() => getValues(lastPage, params)}
                />
              </Pagination>
            </PageContent>
          </>
        )}
      </ToolkitProvider>
    </>
  );
};

export default observer(Documents);
