import styled, { css } from 'styled-components'
import Tabs from 'react-bootstrap/Tabs'

export const StyledTabs = styled(Tabs)`
  ${({ theme }) => css`
    .nav-item.nav-link.active {
      color: ${theme.brandColors.blue} !important;
    }
    .nav-item.nav-link {
      color: ${theme.colors.gray[1]} !important;
    }
    font-weight: bold;
  `}
`
