import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { renderODSOptions, getParamObject, getInitiativesSummaryExportValues, getFundsInitiativesSummaryExportValues } from '../helpers/helpers'

import { Form } from 'react-bootstrap'
import { PrimaryButton } from 'components/Buttons'
import {
  Col1,
  Col2,
  StyledFormGroup,
  StyledFormLabel,
} from '../../CommonStyled/Components.styled'
import { ExportStatisticsByYearsToXLSX } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'

const Header1 = ({ data, data2, getData, getData2, waiting }) => {
  const { t } = useTranslation()
  const [SDGFilter, setSDGFilter] = useState(0)

  const handleFilter = () => {
    getData(getParamObject(SDGFilter))
    getData2(getParamObject(SDGFilter))
  }

  return (
    <>
      <Col1 xs={7}>
        <StyledFormGroup>
          <StyledFormLabel>{t('type_SDG')}</StyledFormLabel>
          <Form.Control
            name="SDG"
            as="select"
            onChange={(e) => {
              setSDGFilter(e.target.value)
            }}
            value={SDGFilter}
          >
            {renderODSOptions()}
          </Form.Control>
        </StyledFormGroup>
        <PrimaryButton
          onClick={() => {
            handleFilter()
          }}
          disabled={waiting}
        >
          {t('Button_search')}
        </PrimaryButton>
      </Col1>
      <Col2>
        <ExportToXLSXButton
          data={data}
          mapData={getInitiativesSummaryExportValues}
          data2={data2}
          mapData2={getFundsInitiativesSummaryExportValues}
          disabled={waiting}
          exportName={t('Statistics_initiatives_tab1')}
        >
          <ExportStatisticsByYearsToXLSX label={t('Statistics_title_initiative_summary')} data={[]} />
        </ExportToXLSXButton>
      </Col2>
    </>
  )
}

export default Header1
