import _sumBy from 'lodash/sumBy';

export const WORKING_DAYS_CONSTANT = 425;

export const defaultBudgetConstants = {
  euBudgetMin: 100000.0,
  euBudgetLimit: 150000.0,
  euBudgetPercentageLimit: 0.75,
};

export const defaultBudgetConstantsOld = {
  euBudgetMin: 100000.0,
  euBudgetLimit: 200000.0,
  euBudgetPercentageLimit: 0.75,
};


export const mustShowFootNote = (
  totalBudget,
  ueBudget,
  euBudgetLimitMin,
  euBudgetLimitMax,
  euBudgetPercentageLimit
) => {
  return (
    totalBudget > 0 &&
    ueBudget <= totalBudget &&
    (ueBudget > euBudgetLimitMax || ueBudget < euBudgetLimitMin || ueBudget > euBudgetPercentageLimit * totalBudget)
  );
};

export const mustShowOldFootNote = (
  totalBudget,
  ueBudget,
  euBudgetLimitMax,
  euBudgetPercentageLimit
) => {
  return (
    totalBudget > 0 &&
    ueBudget <= totalBudget &&
    (ueBudget > euBudgetLimitMax || ueBudget > euBudgetPercentageLimit * totalBudget)
  );
};

export const hasOldBudgetValidation = (initiativeCode) => {
  // If the initiative contains a -21, we have to use the old validation (budget <= 75000)
  return initiativeCode?.includes('-21');
};

export const calculateTotalEuBudgetFromActivities = (activities) => {
  return _sumBy(activities, 'ueBudget');
};

export const calculateTotalBudgetFromActivities = (activities) => {
  return _sumBy(activities, 'counterPartBudget') + _sumBy(activities, 'ueBudget');
};