import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { formInitialValues } from './AddMeetingModal.initialValues';
import { validationSchema } from './AddMeetingModal.validations';
import { parseDateString } from 'helpers/dateHelpers';
import { getMeeting} from 'services/initiative';
import { useParams } from 'react-router-dom';

import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { PrimaryButton } from 'components/Buttons';
import { StyledCheck, StyledModalBody, ModalTitle,  } from './AddMeetingModal.styled';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ErrorButton, StyledSendButton } from 'components/Buttons';
import { Form } from 'react-bootstrap';
import {
  FormFielErrorMessage,
  RequiredMark,
  CenteredModalButtonContainer,
} from 'components/CommonStyled';

const AddMeetingModal = ({
  show,
  handleClose,
  isDisabled,
  isDisabledCheck,
  currentMeeting,
  onEdit
}) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const getCurrentMeeting = async (meetingId) => {
    setLoading(true);
    const response = await getMeeting(id, meetingId);
    setInitialValues({
      ...response?.data,
      date: new Date(parseDateString(response?.data?.date)),
    });
    setLoading(false);
  };

  useEffect(() => { 
    if (currentMeeting) {
      getCurrentMeeting(currentMeeting.id);
    } else {
      setInitialValues({
        ...currentMeeting,
      });
    }
  }, [currentMeeting]);

  if (loading)
    return (
      <Modal show={show} onHide={handleClose} size="xl" backdrop="static" animation={false}>
        <StyledModalBody>
          <Col style={{ marginTop: '20%', textAlign: 'center' }}>
            <Spinner animation="border" variant="secondary" />
          </Col>
        </StyledModalBody>
      </Modal>
    );

  return (
    <Modal show={show} onHide={handleClose} size="xl" backdrop="static" animation={false}>
      <StyledModalBody>
        <ModalTitle>
          {t('F5_S7_Meeting_modal_title')}
        </ModalTitle>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onEdit}
        >
          {({ values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue }) => (
            <Form onSubmit={handleSubmit} id="meeting">
              <Form.Group as={Row} style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Label column xs={2}>
                  {t('F5_S7_Meeting_date')}<RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Col>
                  <DatePicker
                    style={{ marginLeft: '0', marginTop: '5px' }}
                    dateFormat="dd/MM/yyyy"
                    selected={values.date}
                    onChange={date => setFieldValue('date', date)}
                    className={touched.date && errors.date ? 'error' : null}
                    disabled={isDisabled || values.confirmed}
                  />
                  {errors.date ? (
                    <FormFielErrorMessage>{errors.date}</FormFielErrorMessage>
                  ) : null}
                </Col>
              </Form.Group>

              <Form.Group>
                <Form.Label style={{ alignItems: 'center', textAlign: 'justify', lineHeight: '1.3' }}>
                  {t('F5_S7_Meeting_title')}<RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  name="title"
                  as="textarea"
                  rows={1}
                  style={{ resize: 'none' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title || ''}
                  className={touched.title && errors.title ? 'error' : null}
                  disabled={isDisabled || values.confirmed}
                />
                {touched.title && errors.title ? (
                  <FormFielErrorMessage>{errors.title}</FormFielErrorMessage>
                ) : null}
              </Form.Group>

              <Form.Group>
                <Form.Label
                  style={{ alignItems: 'center', textAlign: 'justify', lineHeight: '1.3' }}
                >
                  {t('F5_S7_Meeting_comments')}:
                </Form.Label>
                <Form.Control
                  name="comments"
                  as="textarea"
                  rows={3}
                  placeholder={t('Maximum_1300')}
                  style={{ resize: 'none' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.comments || ''}
                  className={touched.comments && errors.comments ? 'error' : null}
                  disabled={isDisabled || values.confirmed}
                />
                {touched.comments && errors.comments ? (
                  <FormFielErrorMessage>{errors.comments}</FormFielErrorMessage>
                ) : null}
              </Form.Group>

              <StyledCheck>
                <Form.Group>
                  <Form.Check
                    disabled={isDisabledCheck}
                    style={{ marginLeft: '15px', textAlign: 'justify' }}
                    name="confirmed"
                    type="checkbox"
                    checked={values.confirmed}
                    value={values.confirmed}
                    onChange={handleChange}
                    label={t('F5_S7_Meeting_checkbox')}
                    className={touched.confirmed && errors.confirmed ? 'error' : null}
                  />
                  {touched.confirmed && errors.confirmed ? (
                    <FormFielErrorMessage>{errors.confirmed}</FormFielErrorMessage>
                  ) : null}
                </Form.Group>
              </StyledCheck>

              {values.genInfoTimestamp && (
                <div style={{ marginTop: '10px' }}>
                  {`${t('F5_S7_Confirmed')}: ${
                    values.genInfoTimestamp ? parseDateString(values.genInfoTimestamp) : ''
                  }`}
                </div>
              )}
              <CenteredModalButtonContainer>
                {isDisabled ? (
                  <PrimaryButton
                    onClick={handleClose}
                  >
                    OK
                  </PrimaryButton>
                ) : (
                  <>
                    <ErrorButton style={{ marginRight: '20px' }} onClick={handleClose}>
                      {t('Button_cancel')}
                    </ErrorButton>
                    <StyledSendButton type="submit" form="meeting">
                      {t('Button_save')}
                    </StyledSendButton>
                  </>
                )}
              </CenteredModalButtonContainer>
            </Form>
          )}
        </Formik>
      </StyledModalBody>
    </Modal>
  );
};

export default AddMeetingModal;
