import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  provider: Yup.string()
    .min(2, 'F3_S7_validation1')
    .max(650, 'F3_S7_validation2')
    .nullable(true)
    .test('providerCheck', 'Error field cannot be empty', function (value) {
      if(this.options.context.budgetConfirmed && !this.parent.provider) return false
      return true }),
  totalRealMoney: Yup.number()
    .min(0, 'F1_S7_validation10')
    .test('totalRealMoneyCheck', 'Error field cannot be empty', function (value) {
      if(this.options.context.budgetConfirmed && (this.parent.totalRealMoney == null)) return false
      return true }),
  entityContribution: Yup.string()
    .min(0, 'F1_S7_validation10')
    .nullable(true)
    .test('entityContribution', 'Error field cannot be empty', function (value) {
      if((this.options.context.budgetConfirmed && this.options.context.ueOrCounterpart == 1) && (this.parent.entityContribution == null)) return false
      return true }),
  quote: Yup.object().nullable(),
  justification: Yup.string()
    .min(2, 'F3_S7_validation1')
    .max(650, 'F3_S7_validation2')
    .nullable(true)
    .test('providerCheck', 'Error field cannot be empty', function (value) {
      if(this.options.context.budgetConfirmed && !this.parent.justification) return false
      return true }),
  budgetConfirmed: Yup.boolean()
});
