import React, {useState} from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import { sendF8b, sendF9a, sendF9c, cancelF8b, modifyF8b, modifyF9a, modifyF9c } from 'services/initiative';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { useRootStore } from "stores";
import Proptypes from 'prop-types';
import { useFormikContext } from 'formik';

import CustomModal from 'components/Modal';
import Modal from 'react-bootstrap/Modal';
import { ModalFooter, FooterLeft, FooterCenter, FooterRight } from './SendNCancelDialog.styled';
import { ErrorButton, PrimaryButton } from 'components/Buttons';


const SendDialog = ({ displayDialog, onCloseModal, role, content, cancel }) => {
  const { formId } = useParams();
  const [sending, setSending] = useState(false);
  const history = useHistory();
  const {t} = useTranslation();
  const { authStore } = useRootStore();
  const [modalError, setModalError] = useState(false);
  const { values } = useFormikContext() ?? {};

  const sendAction = async () => {
    try {
      setSending(true);
      
      if(cancel) {
        await cancelF8b(formId);
      }
      else if(role == 0) { //ATKE and Consortium
        await modifyF8b(formId,values);
        await sendF8b(formId);
      } else if (role == 1) { //EUD
        await modifyF9a(formId,values);
        await sendF9a(formId);
      } else if ( role == 2) { //INTPA
        await modifyF9c(formId, values);
        await sendF9c(formId);
      } 
      history.push(`${authStore.getBaseRouteByRole()}/management/expost-reporting`)
    } catch (error) {
      setModalError(true);
      console.log(error);
    }
    finally{
      onCloseModal();
      setSending(false);
    }
  }

  return (
    <>
      <CustomModal
        show={modalError}
        onHide={() => setModalError(false)}
        message={t("Error try again later")}
        type="error"
      />

      <Modal show={displayDialog} onHide={onCloseModal} size="lg">
        <Modal.Body>
          {htmlParser(content)}
        </Modal.Body>
        <ModalFooter>
          <FooterLeft />
          <FooterCenter>
            <PrimaryButton disabled={sending} onClick={() => sendAction()}>{t('Yes')}</PrimaryButton>
            <ErrorButton disabled={sending} onClick={onCloseModal}>{t('No')}</ErrorButton>
          </FooterCenter>
          <FooterRight />
        </ModalFooter>
      </Modal>
    </>

  )
}

SendDialog.propTypes = {
  title: Proptypes.string,
  displayDialog: Proptypes.bool,
  onCloseModal: Proptypes.func,
};

export default observer(SendDialog);
