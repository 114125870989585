import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';

import MoneyTextMask from 'components/MoneyTextMask';
import Table from 'react-bootstrap/Table';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledNumberCircle, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  TableHeader,
  RowBorder,
  BorderlessTr,
  ResourceNameSpan,
  ResourceNumberSpan
} from 'components/Forms/Form4/Section9/Section9.styled';

const Section9 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);
  const [totalBudget, setTotalBudget] = useState({
    eu_budget: 0,
    counterpart_budget: 0,
    total_budget: 0
  });
  
  useEffect(() => {
    if(data && Array.isArray(data) && data.length ){
      const dataCopy = [...data[0]];
      const total = dataCopy.pop();
      setFormValues(dataCopy);
      setTotalBudget(total);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`9 - ${t('F3_S9_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <Table bordered={ false } style={{marginLeft: '30px'}}>
          <thead>
            <BorderlessTr>
              <TableHeader width={"40"}></TableHeader>
              <TableHeader width={"10"}>{t('F3_S7_Resource_column_EU')}</TableHeader>
              <TableHeader width={"10"}>{t('F3_S7_Resource_column_counterpart')}</TableHeader>
              <TableHeader width={"10"}>{t('F3_S7_Resource_column_total')}</TableHeader>
            </BorderlessTr>
          </thead>
          <tbody>
            {formValues.map(resource => (
              <>
                <BorderlessTr>
                  <td colSpan="4">
                    <RowBorder />
                  </td>
                </BorderlessTr>
                <BorderlessTr key={resource.resourceType? t(`resource_type_${resource.resourceType.id}`): ' - '}>
                  <td><ResourceNameSpan>{resource.resourceType? t(`resource_type_${resource.resourceType.id}`): ' - '}</ResourceNameSpan></td>
                  <td><ResourceNumberSpan> <MoneyTextMask value={resource.eu_budget} /> </ResourceNumberSpan></td>
                  <td><ResourceNumberSpan> <MoneyTextMask value={resource.counterpart_budget} /> </ResourceNumberSpan></td>
                  <td><ResourceNumberSpan> <MoneyTextMask value={resource.total_budget} /> </ResourceNumberSpan></td>
                </BorderlessTr>
              </>
            ))}
            <BorderlessTr>
              <td colSpan="4">
                <RowBorder />
              </td>
            </BorderlessTr>
            <BorderlessTr>
              <td> <ResourceNameSpan total={true}>{t('Total TCI budget')}</ResourceNameSpan></td>
              <td> <ResourceNumberSpan weight={600}> <MoneyTextMask value={totalBudget.eu_budget} /> </ResourceNumberSpan></td>
              <td> <ResourceNumberSpan weight={600}> <MoneyTextMask value={totalBudget.counterpart_budget} /> </ResourceNumberSpan></td>
              <td> <ResourceNumberSpan weight={600}> <MoneyTextMask value={totalBudget.total_budget} /> </ResourceNumberSpan></td>
            </BorderlessTr>
          </tbody>
        </Table>
      </StyledBody>
    </SectionContainer>
  )
}


export default Section9;