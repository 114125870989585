import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { formInitialValues } from './FinalizeForm.initialValues';
import { validationSchema } from './FinalizeForm.validations';
import { getF5S7ActivityDetailFinalize, modifyF5S7ActivityDetailFinalize } from 'services/initiative';
import { useRootStore } from 'stores';
import { roleNamesConst } from 'constants/appConstants';
import DayJS from 'dayjs';

import { StyledFormLabel, StyledCheck } from './FinalizeForm.styled'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ErrorButton, PrimaryButton, StyledSendButton} from 'components/Buttons'
import { Form } from 'react-bootstrap'
import { FormFielErrorMessage, CenteredModalButtonContainer } from 'components/CommonStyled';

const FinalizeForm = ({ activity, detail, handleCloseOk, handleClose, handleCloseSubModalOnError, nonEditable }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { authStore } = useRootStore();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const role = authStore.roles[0];
  const isAtke = role === roleNamesConst.ATKE

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr);
    return date.format("DD/MM/YYYY");
  }

  const handleSubmit = async (values) => {
    try {
      await modifyF5S7ActivityDetailFinalize(id, activity.id, detail.id, values);
      handleCloseOk();
    } catch (error) {
      handleCloseSubModalOnError();
    }
  };

  useEffect(() => {
    async function getValues() {
      const response = await getF5S7ActivityDetailFinalize(id, activity.id, detail.id);
      const formValues = {...response.data};
      setInitialValues(formValues);
    }
    getValues();
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
         }) => (
          <Form onSubmit={handleSubmit} id="payments" >
            
            {t("F5_S7_Budget_Admin_Finalize_Submodal_important")}

            <Form.Group style={{marginTop: "20px"}}>
              <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}} >{t('F5_S7_Budget_Admin_Finalize_Submodal_comments')}: </Form.Label>
              <Form.Control
                name="mgmtFinalizeComment"
                as="textarea" 
                rows={5} 
                placeholder={t('Maximum_1300')}
                style={{resize: 'none'}}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mgmtFinalizeComment}
                className={errors.mgmtFinalizeComment ? "error" : null}
                disabled={nonEditable}
              />
              {errors.mgmtFinalizeComment ? (
                <FormFielErrorMessage>{errors.mgmtFinalizeComment}</FormFielErrorMessage>
              ): null}
            </Form.Group>

            <Form.Group as={Row}>
              <StyledFormLabel column lg={4}>{t('F5_S7_Budget_Admin_Finalize_Submodal_score')}:</StyledFormLabel>
              <Col lg={4}>
                <Form.Control 
                  name="mgmtFinalizeScore"
                  as="select"
                  onChange={handleChange}
                  value={values.mgmtFinalizeScore}
                  disabled={nonEditable}
                >
                  <option value="">{t('Placeholder_select')}</option>
                  <option value="1"> 1 </option>
                  <option value="2"> 2 </option>
                  <option value="3"> 3 </option>
                  <option value="4"> 4 </option>
                  <option value="5"> 5 </option>
                </Form.Control>
                {touched.mgmtFinalizeScore && errors.mgmtFinalizeScore ? (
                  <FormFielErrorMessage>{errors.mgmtFinalizeScore}</FormFielErrorMessage>
                ): null}
              </Col>
            </Form.Group>          

            <StyledCheck style={{marginTop: '15px'}}>
              <Form.Group>
                <Form.Check 
                  disabled={nonEditable || !values.detailReady} 
                  style={{marginLeft: '15px', textAlign: 'justify'}} 
                  name="mgmtFinalizeConfirmed"
                  type="checkbox" 
                  checked={values.mgmtFinalizeConfirmed} 
                  value={values.mgmtFinalizeConfirmed} 
                  onChange={handleChange} 
                  label={t('F5_S7_Budget_Admin_Finalize_Submodal_finalize')} 
                  className={touched.mgmtFinalizeConfirmed && errors.mgmtFinalizeConfirmed ? "error" : null}
                />
                {touched.mgmtFinalizeConfirmed && errors.mgmtFinalizeConfirmed ? (
                  <FormFielErrorMessage>{errors.mgmtFinalizeConfirmed}</FormFielErrorMessage>
                ): null}
              </Form.Group>
            </StyledCheck>
            {
              values.mgmtFinalizeConfirmed
              &&
              <div>
                {t('F5_S7_Confirmed')}: {values.mgmtFinalizeConfirmedUsername} - {values.mgmtFinalizeConfirmedTimestamp? formatDate(values.mgmtFinalizeConfirmedTimestamp): ''}
              </div>
            }

            <CenteredModalButtonContainer>
              {
                (nonEditable && !(isAtke))
                ?
                  <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
                :
                  <>
                    <ErrorButton style={{marginRight: '20px'}} onClick={handleClose} disabled={nonEditable && !(isAtke)}> {t('Button_cancel')} </ErrorButton>
                    <StyledSendButton type="submit" form="payments" disabled={nonEditable && !(isAtke)}> {t('Button_save')} </StyledSendButton>
                  </>
              }
            </CenteredModalButtonContainer>

          </Form>
        )}
      </Formik>
    </div>
  )
}

export default observer(FinalizeForm);