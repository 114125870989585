export const formInitialValues = {
  role: 0,
  knowledge: '',
  impact: '',
  value: '',
  elements: '',
  overall: '',
  level: false,
  oo1: false,
  oo2: false,
  so3: false,
  so4: false,
  out5: false,
  out6: false,
  out7: false,
  out8: false,
  out9: false,
  assessmentQ1: '',
  assessmentQ2: '',
  assessmentQ3: '',
  assessmentQ4: '',
  assessmentQ5: '',
  assessmentQ6: '',
  assessmentQ7: '',
  assessmentQ8: '',
  assessmentQ9: '',

  marker1: false,
  marker2: false,
  marker3: false,
  marker4: false,
  marker5: false,
  marker6: false,
  marker7: false,
  marker8: false,
  marker9: false,
  marker10: false,
  marker11: false,
  marker12: false,
  marker13: false,
  marker14: false,
  marker15: false,
  marker16: false,
  marker17: false,
  marker18: false,
  marker19: false,
}