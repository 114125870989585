import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { getIn } from 'formik';

import AddBeneficiaryDialog from '../AddBeneficiaryDialog';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RoundedAddDiv, StyledButtonLink, DivButtonWrapper } from '../AddBeneficiaryDialog/AddBeneficiaryDialog.styled'
import { RequiredMark } from 'components/CommonStyled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';


const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  const getFieldTouched = getIn(formikInstance.touched, field);
  return {
    touched: getFieldTouched,
    error: (
      <FormErrorMessage
        touched={getFieldTouched}
        errors={getFieldError}
      />
    )
  }
}

const BeneficiaryForm = ({ formikInstance, beneficiaryIndex, nonEditable, countries, benTypes }) => {
  const { t } = useTranslation();
  const { getFieldProps, touched, errors, values, setFieldValue } = formikInstance;
  const fieldPath = (field) => `benList.${beneficiaryIndex}.${field}`
  const [displayModal, setDisplayModal] = useState(false);
  const handleOpenModal = () => setDisplayModal(true);
  const handleCloseModal = () => setDisplayModal(false);

  return (
    <>
      { displayModal && <AddBeneficiaryDialog
        formikInstance={formikInstance}
        beneficiaryIndex={beneficiaryIndex}
        countries={countries}
        nonEditable={nonEditable}
        displayDialog={displayModal}
        onCloseModal={handleCloseModal} 
      /> }
      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F3_S10_Type_beneficiary')}:<RequiredMark>*</RequiredMark></Form.Label>
        <Col lg={4}>
          <Form.Control 
            as="select"
            name={fieldPath('beneficiaryTypeId')}
            { ...getFieldProps(fieldPath('beneficiaryTypeId')) }
            className={touched[fieldPath('beneficiaryTypeId')] && errors[fieldPath('beneficiaryTypeId')] ? "error" : null}
            disabled={nonEditable}
          >
            <option value="">{t('Placeholder_select')}</option>
            {benTypes.map(beneficiary => (
              <option key={beneficiary.id} value={beneficiary.id}>{t(`beneficiary_type_${beneficiary.id}`)}</option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath('beneficiaryTypeId')).error}
        </Col>
      </Form.Group>  

      <Form.Group>
        <Form.Label>{t('F3_S10_Description')}:</Form.Label>
        <Form.Control
          name={fieldPath('beneficiaryDescription')}
          as="textarea" 
          rows={3} 
          style={{resize: 'none'}}
          placeholder= {t('Maximum_650')}
          className={touched[fieldPath('beneficiaryDescription')] && errors[fieldPath('beneficiaryDescription')] ? "error" : null}
          { ...getFieldProps(fieldPath('beneficiaryDescription')) }
          disabled={nonEditable}
        />
        {fieldInfo(formikInstance, fieldPath('beneficiaryDescription')).error}
      </Form.Group>

      <Form.Group as={Row}> 
        <Form.Label column xs={4}>{t('F3_S10_Beneficiary_quantificiation')}:<RequiredMark>*</RequiredMark></Form.Label>
        <Col xs={2}>
          <InputGroup>
            <Form.Control
              type="number"
              min="0"
              name={fieldPath('beneficiaryQuantification')}
              value={values.benList[beneficiaryIndex].answersF3S10BenCountryList.reduce(function (acc, obj) { return acc + obj.quantity }, 0)}
              disabled={true}
            />
          </InputGroup>
        </Col>
        <Col>
          <StyledButtonLink type="button" onClick={() => {handleOpenModal()}} disabled={nonEditable} > <DivButtonWrapper> <RoundedAddDiv>+</RoundedAddDiv>{t('F3_S10_Add_direct_beneficiaries')} </DivButtonWrapper> </StyledButtonLink>
        </Col>
      </Form.Group> 
      
      <Form.Group>
        <Form.Label>{t('F3_S10_Justification')}<RequiredMark>*</RequiredMark>: </Form.Label>
        <Form.Control
          name={fieldPath('beneficiaryJustification')}
          as="textarea" 
          rows={3} 
          style={{resize: 'none'}}
          placeholder= {t('Maximum_650')}
          className={touched[fieldPath('beneficiaryJustification')] && errors[fieldPath('beneficiaryJustification')] ? "error" : null}
          { ...getFieldProps(fieldPath('beneficiaryJustification')) }
          disabled={nonEditable}
        />
        {fieldInfo(formikInstance, fieldPath('beneficiaryJustification')).error}
      </Form.Group>

      <Form.Group>
        <Form.Label>{t('F3_S10_Prospects_for_harnessing')}<RequiredMark>*</RequiredMark>: </Form.Label>
        <Form.Control
          name={fieldPath('beneficiaryProspect')}
          as="textarea" 
          rows={3} 
          style={{resize: 'none'}}
          placeholder= {t('Maximum_650')}
          className={touched[fieldPath('beneficiaryProspect')] && errors[fieldPath('beneficiaryProspect')] ? "error" : null}
          { ...getFieldProps(fieldPath('beneficiaryProspect')) }
          disabled={nonEditable}
        />
        {fieldInfo(formikInstance, fieldPath('beneficiaryProspect')).error}
      </Form.Group>
    </>
  )
}

export default BeneficiaryForm;