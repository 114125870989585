import axiosInstance from './axiosInstance'

export const getCommunityReport = async () => {
	const url = `/api/community/report`
	try {
			const response = await axiosInstance.get(url)
			return response.data
	} catch (error) {
			throw error
	}
}

export const canAccessCommunity = async () => {
	const url = `/api/community/can-access`
	try {
			const response = await axiosInstance.get(url)
			return response.data
	} catch (error) {
			throw error
	}
}

export const onSendContact = async (initiativeId, type, message) => {
	const url = 
		type === 'initiative' ? 
			`/api/community/initiatives/${initiativeId}/contact` 
		: type === 'adelante1' ? 
			`/api/community/adelante_1/${initiativeId}/contact`
		: `/api/community/funds/${initiativeId}/contact`  
	try {
		const response = await axiosInstance.post(url, { message })
		return response.data
	} catch (error) {
		throw error
	}
}