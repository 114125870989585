import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Col2,
  FullRow,
  StyledFormGroup2,
  StyledFormLabel,
} from '../../CommonStyled/Components.styled'
import { getExPostF8BSummaryExportValues } from '../helpers/helpers'
import { ExportStatisticsF8BSummaryToXLSX } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'
import { Form } from 'react-bootstrap'
import { renderYearOptions } from 'pages/statistics/CommonHelpers/helpers'
import { TARGET_YEARS } from 'constants/appConstants'
import { PrimaryButton } from 'components/Buttons'

const F8BExpostHeader = ({ data, waiting, getData }) => {
  const { t } = useTranslation()

  const [yearsFilter, setYearsFilter] = useState(0)

  const handleFilter = () => {
    let param = {}
    if (yearsFilter && yearsFilter != 0) param.year = yearsFilter
    getData(param)
  }

  return (
    <>
      <FullRow style={{ alignItems: 'center', marginLeft: '20px'}}>
        <StyledFormGroup2>
          <StyledFormLabel>{t('Dashboard_Year')}</StyledFormLabel>
          <Form.Control
            name="year"
            as="select"
            onChange={(e) => {
              setYearsFilter(e.target.value)
            }}
            value={yearsFilter}
          >
            {renderYearOptions(TARGET_YEARS, t('initiative_window_empty'))}
          </Form.Control>
        </StyledFormGroup2>

        <PrimaryButton
          onClick={() => {
            handleFilter()
          }}
          disabled={waiting || !yearsFilter || yearsFilter === '0'}
        >
          {t('Button_search')}
        </PrimaryButton>

        <Col2>
          <ExportToXLSXButton
            data={data}
            mapData={getExPostF8BSummaryExportValues}
            disabled={waiting || !yearsFilter || yearsFilter === '0' || !data}
            exportName={t('Statistics_expost_tab1')}
          >
              <ExportStatisticsF8BSummaryToXLSX
                label={t('Statistics_expost_tab1')}
                data={[]}
              />
          </ExportToXLSXButton>
        </Col2>
      </FullRow>
    </>
  )
}

export default F8BExpostHeader
