import styled, {css} from 'styled-components';
import Col from 'react-bootstrap/Col';

export const StyledTitle = styled.h3`
    ${({ theme }) => css`
        color: ${theme.brandColors.blue};
    `}
`;

export const StyledColTitle = styled(Col)`
    margin-bottom: 15px;
`;

export const StyledCol = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: flex-start; 
`;

export const InitiativeNameCol = styled(Col)`
    display: flex;
`;

export const NameDiv = styled.div`
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;