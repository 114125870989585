import React, { useState } from 'react';
import DayJS from 'dayjs'

import { formInitialValues } from '../Section15.initialValues';
import { PrimaryButton } from 'components/Buttons';
import { f4statusNamesConst } from 'constants/appConstants';

import F4ADialog from '../F4ADialog'
import InviteDialog from '../InviteDialog'

import {
  StyledAccordionBodyDiv,
  StyledEUDLink,
} from './EUDAssessments.styled';
import { SeparatorDotted } from 'components/CommonStyled'
import { StyledTitle } from '../Section15.styled';
import { useTranslation } from 'react-i18next';

const EUDAssessments = ({ isIntpa, f4AValues, intpaAssessment, initiativeCode, nonEditable }) => {
  
  const { t } = useTranslation();
  const [currentF4AValues, setCurrentF4AValues] = useState(formInitialValues);

  const [displayModalInvite, setDisplayModalInvite] = useState(false);
  const handleOpenModalInvite = () => setDisplayModalInvite(true);
  const handleCloseModalInvite = () => setDisplayModalInvite(false);

  const [displayModalF4A, setDisplayModalF4A] = useState(false);
  const handleOpenModalF4A = () => setDisplayModalF4A(true);
  const handleCloseModalF4A = () => setDisplayModalF4A(false);

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr);
    return date.format("DD/MM/YYYY");
  }

  const handleClick = (f4a) => {
    setCurrentF4AValues(f4a);
    handleOpenModalF4A();
  }

  const renderF4AValues = () => {
    
    return f4AValues.map(f4a => (
      <div>
        {`${f4a.user.organization}, ${f4a.user.name} ${f4a.user.lastName} - `}
        {(f4a.stateName === f4statusNamesConst.DONE)
          ?
          <StyledEUDLink key={f4a.id} value={f4a.id} onClick={() => { handleClick(f4a) }}>
            {t('Received_on')+" "+((f4a && f4a.statusTimestamp) ? formatDate(f4a.statusTimestamp) : '-')}
          </StyledEUDLink>
          :
          t('Pending')
        }
      </div>
    ))
  }

  return (
    <>
      { displayModalInvite && <InviteDialog
        displayDialog={displayModalInvite}
        onCloseModal={handleCloseModalInvite}
      />}

      { displayModalF4A && <F4ADialog
        values={currentF4AValues}
        displayDialog={displayModalF4A}
        onCloseModal={handleCloseModalF4A}
        initiative={initiativeCode}
      />}

      <StyledTitle>{t('F2_EUDs_assessments')}</StyledTitle>

      <StyledAccordionBodyDiv>
        {renderF4AValues()}

        <div style={{ width: '400px' }}>
          <SeparatorDotted />

          {!isIntpa &&
            <>
              {`INTPA - `}
              {
                intpaAssessment && (intpaAssessment.stateName === f4statusNamesConst.DONE)
                  ?
                  <StyledEUDLink onClick={() => { handleClick(intpaAssessment) }}>
                    {t('Received_on')+" "+(intpaAssessment.statusTimestamp) ? formatDate(intpaAssessment.statusTimestamp) : '-'}
                  </StyledEUDLink>
                  :
                  t('Pending')
              }
            </>
          }
        </div>

        {isIntpa &&
          <div style={{ marginTop: '30px' }}>
            <PrimaryButton disabled={nonEditable} onClick={handleOpenModalInvite}>{"+" + " " + t('F2_invite_assessors')}</PrimaryButton>
          </div>}
      </StyledAccordionBodyDiv>

    </>
  )
}

export default EUDAssessments;