import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';

import CustomBudgetDetailsTable from 'components/CustomBudgetDetailsTable';
import MoneyTextMask from 'components/MoneyTextMask';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { StyledFormLabel } from 'components/Forms/Form4/Section7/Section7.styled';
import TextValue from 'components/TextValue';
import { StyledS7SubsectionTitle } from '../../CommonStyles';
import {
  RowBorder,
  BorderlessTr,
  TableHeader,
  ResourceNameSpan,
  ResourceNumberSpan,
  EstimatedBugetDiv,
  TotalBugetDiv,
  TotalBudgetSpan,
} from 'components/Forms/Form4/Section7/ResourcesForm/ResourcesForm.styled';

const Resources = ({ activity }) => {
  const { t } = useTranslation();
  const [localBudgetLineList, setLocalBudgetLineList] = useState([...activity.resourceList]);

  useEffect(() => {
    const flattedArray = handleFlatArray(activity.resourceList);
    setLocalBudgetLineList([...flattedArray]);
  }, [activity, activity.resourceList])

  const handleFlatArray = (array) => {
    const flatArray = array.map(resource => {
      if(resource.details){
        return resource.details.map(detail => {
          return {
            ...detail,
            resourceType: resource.resourceType
          }
        })
      }
    });
    return flatArray.flat();
  }


  return (
    <div>
      <StyledS7SubsectionTitle> {t('Budget_caps')} </StyledS7SubsectionTitle>

      {htmlParser(t('F3_S7_Resource_help_text'))}

      <CustomBudgetDetailsTable
        items={localBudgetLineList}
        isOpenable={true}
        hasRemoveButton={false}
        nonEditable={false}
        handleOpenBudgetLine={() => {}}
      />

      <EstimatedBugetDiv style={{marginLeft: '-30px', marginRight: '-30px', padding: '5px'}}>
        <Table bordered={ false }>
          <thead>
            <BorderlessTr>
              <TableHeader width={"40"}></TableHeader>
              <TableHeader width={"20"}>{t('F3_S7_Resource_column_EU')}</TableHeader>
              <TableHeader width={"20"}>{t('F3_S7_Resource_column_counterpart')}</TableHeader>
              <TableHeader width={"25"}>{t('F3_S7_Resource_column_total')}</TableHeader>
            </BorderlessTr>
          </thead>
          <tbody>
            <BorderlessTr>
              <td colSpan="4">
                <RowBorder />
              </td>
            </BorderlessTr>
            <BorderlessTr>
              <td><ResourceNameSpan>{t('F3_S7_Resource_estimated_totals')}</ResourceNameSpan></td>
              <td><ResourceNumberSpan> <MoneyTextMask value={activity.estimatedUeBudget} /> </ResourceNumberSpan></td>
              <td><ResourceNumberSpan> <MoneyTextMask value={activity.estimatedCounterpartBudget} /> </ResourceNumberSpan></td>
              <td><ResourceNumberSpan> <MoneyTextMask value={activity.estimatedTotalBudget} /> </ResourceNumberSpan></td>
            </BorderlessTr>
          </tbody>
        </Table>

        <Form.Group>
          <StyledFormLabel>{t('F3_S7_Resource_justification')}:</StyledFormLabel>
          <TextValue value={activity.justBudget} />
        </Form.Group>  
      </EstimatedBugetDiv>
      
      <TotalBugetDiv style={{marginLeft: '-30px', marginRight: '-30px'}}>
        <Table bordered={ false }>
          <thead>
            <BorderlessTr>
              <TableHeader width={"45"}><TotalBudgetSpan> {t('F3_S7_Resource_total_activity')} {activity.name} </TotalBudgetSpan> </TableHeader>
              <TableHeader width={"20"}>
                <ResourceNumberSpan style={{color: 'black'}}>
                  <MoneyTextMask value={activity.ueBudget} />
                </ResourceNumberSpan>
              </TableHeader>
              <TableHeader width={"20"}>
                <ResourceNumberSpan style={{color: 'black'}}>
                  <MoneyTextMask value={activity.counterpartBudget} />
                </ResourceNumberSpan>
              </TableHeader>
              <TableHeader width={"25"}>
                <ResourceNumberSpan style={{color: 'black'}}>
                  <MoneyTextMask value={activity.totalBudget} />
                </ResourceNumberSpan>
              </TableHeader>
            </BorderlessTr>
          </thead>
        </Table>
      </TotalBugetDiv>

    </div>
  )
}

export default Resources;