import React from 'react';
import TextValue from 'components/TextValue';
import { StyledTitle, StyledLabel, StyledSubtitle } from '../ExpostReportForm.styles';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';

const F8bReadOnly = ({formData}) => {
    const { t } = useTranslation();
    return (
      <>
        <StyledTitle style={{marginTop: '40px'}}>{t('F9a_title_1')}:</StyledTitle>

        <Form.Group>
          <StyledLabel>{t('F8b_assessment_1')}:</StyledLabel>
          <TextValue value={formData.comment1} />
        </Form.Group>

        <Form.Group>
          <StyledLabel>{t('F8b_assessment_2')}:</StyledLabel>
          <TextValue value={formData.comment2} />
        </Form.Group>

        <Form.Group>
          <StyledLabel>{t('F8b_assessment_3')}:</StyledLabel>
          <TextValue value={formData.recommendation} />
        </Form.Group>

        <Form.Group style={{marginLeft: '15px', marginTop: '64px'}}>
          <Row>
            <Form.Label>{t('F8b_assessment_4')}:</Form.Label>
            <label>
              <input
                type="radio"
                checked={typeof formData.assessment4 === 'boolean' && formData.assessment4}
                style={{marginLeft: '30px'}}
                disabled
              />
              <span style={{marginLeft: '5px'}}> {t('Yes')} </span>
            </label>
            <label>
              <input
                type="radio"
                checked={typeof formData.assessment4 === 'boolean' && !formData.assessment4}
                disabled
                style={{marginLeft: '50px'}}
              />
              <span style={{marginLeft: '5px'}}> {t('No')} </span>
            </label>
          </Row>
        </Form.Group>

        <StyledTitle style={{marginTop: '16px'}}>{t('F8b_assessment_title_5')}:</StyledTitle>
        <StyledSubtitle style={{marginBottom: '16px'}}>{t('F8b_assessment_subtitle_5')}:</StyledSubtitle>

        <Form.Check
          type="checkbox"
          checked={formData.assessment5}
          value={formData.assessment5}
          disabled
          label={t('F8b_assessment_5')}
        />

        <Form.Check
          name="assessment6"
          type="checkbox"
          checked={formData.assessment6}
          value={formData.assessment6}
          disabled
          label={t('F8b_assessment_6')}
        />

        <Form.Check
          name="assessment7"
          type="checkbox"
          checked={formData.assessment7}
          value={formData.assessment7}
          disabled
          label={t('F8b_assessment_7')}
        />

        <Form.Check
          name="assessment8"
          type="checkbox"
          checked={formData.assessment8}
          value={formData.assessment8}
          disabled
          label={t('F8b_assessment_8')}
        />

        <StyledTitle style={{marginTop: '16px'}}>{t('F8b_assessment_title_9')}:</StyledTitle>

        <Form.Check
          name="assessment9"
          type="checkbox"
          checked={formData.assessment9}
          value={formData.assessment9}
          disabled
          label={t('F8b_assessment_9')}
        />


        <Form.Check
          name="assessment10"
          type="checkbox"
          checked={formData.assessment10}
          value={formData.assessment10}
          disabled
          label={t('F8b_assessment_10')}
        />


        <Form.Check
          name="assessment11"
          type="checkbox"
          checked={formData.assessment11}
          value={formData.assessment11}
          disabled
          label={t('F8b_assessment_11')}
        />

        <StyledTitle style={{marginTop: '16px'}}>{t('F8b_assessment_title_12')}:</StyledTitle>
        <StyledSubtitle style={{marginBottom: '16px'}}>{t('F8b_assessment_subtitle_12')}:</StyledSubtitle>

        <Form.Check
          name="assessment12"
          type="checkbox"
          checked={formData.assessment12}
          value={formData.assessment12}
          disabled
          label={t('F8b_assessment_12')}
        />

        <Form.Check
          name="assessment13"
          type="checkbox"
          checked={formData.assessment13}
          value={formData.assessment13}
          disabled
          label={t('F8b_assessment_13')}
        />

        <Form.Check
          name="assessment14"
          type="checkbox"
          checked={formData.assessment14}
          value={formData.assessment14}
          disabled
          label={t('F8b_assessment_14')}
        />

        <StyledTitle style={{marginTop: '16px'}}>{t('F8b_assessment_title_15')}:</StyledTitle>


        <Form.Check
          name="assessment15"
          type="checkbox"
          checked={formData.assessment15}
          value={formData.assessment15}
          disabled
          label={t('F8b_assessment_15')}
        />
      </>
    )
}

export default F8bReadOnly;