import i18n from 'i18n'
import { states } from 'constants/appConstants'

export const renderStatesOptions = () => {
  let options = []
  options.push(<option value=""> {i18n.t('ALL')} </option>)
  for (const property in states) {
    options.push(
      <option key={property} value={property}>
        {i18n.t(states[property])}
      </option>,
    )
  }
  return options
}
