import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { formatDateDDMMYY } from 'helpers/dateHelpers';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledNumberCircle, 
  StyledTitle, 
  StyledBody,
  StyledSubsectionTitle
} from '../CommonStyles'
import FakeAccordionItem from '../../FakeAccordionItem';
import { SeparatorDotted } from 'components/CommonStyled';
import ActivityDetail from './ActivityDetail';

const Section7 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({
    activities: [],
  });

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`7 - ${t('F3_S7_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <StyledSubsectionTitle> </StyledSubsectionTitle>
        {(formValues && formValues.activities && formValues.activities.map((activity, key) => (
          <React.Fragment key={key}>
            <FakeAccordionItem 
              title={`${activity.name} - ${t(`activity_types_${activity.activityType.id}`)} (${activity.fromDate? formatDateDDMMYY(activity.fromDate): "-"})`}
              noMargin
            >
              <ActivityDetail data={activity} />    
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}
      </StyledBody>
    </SectionContainer>
  )
}


export default Section7;