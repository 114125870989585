import styled, { css } from 'styled-components';
import Button from 'react-bootstrap/Button';

export const StyledTr = styled.tr`
  ${({ theme }) => css`
    color: ${theme.brandColors.gray1};
  `}
`;

export const StyledButtonLink = styled(Button)`
  padding: 0 ;
  border: none;
  background: none;
  box-shadow: none;
  margin-bottom: 4px;
`;