import { saveFile, getDownload } from 'services/initiative'
import i18n from 'i18n'

export const handleFileUpload = async (
  e,
  setFieldValue,
  handleChange,
  setIsUploadingFile,
  setModal,
  id,
) => {
  try {
    const file = document.getElementById('fileInput').files[0]
    const fileExtension = file.name.split('.').pop()

    if (file.size > 20971520) {
      setModal({
        message: i18n.t('Error_file4'),
        type: 'error',
        isOpen: true,
      })
    } else if (fileExtension.toUpperCase() !== 'PDF') {
      setModal({
        message: i18n.t('Error_file3'),
        type: 'error',
        isOpen: true,
      })
    } else {
      setIsUploadingFile(true)
      const uid = await saveFile(id, 5, 14, file)
      setFieldValue('file.fileName', file.name)
      setFieldValue('file.uuid', uid)
      handleChange(e)
    }
  } catch (error) {
    setModal({
      message: i18n.t('Error_file2'),
      type: 'error',
      isOpen: true,
    })
  } finally {
    setIsUploadingFile(false)
  }
}

export const handleDownload = async (uuid, downloadName, setModal) => {
  try {
    await getDownload(uuid).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', downloadName)
      document.body.appendChild(link)
      link.click()
    })
  } catch (error) {
    setModal({
      message: i18n.t('Error_file1'),
      type: 'error',
      isOpen: true,
    })
  }
}

export const groupByActivity = (list) => {
  let activity = {}
  let newList = []
  list?.forEach((material) => {
    if (!activity.hasOwnProperty(material.activity.id)) {
      activity[material.activity.id] = {
        materials: [],
        activity: material.activity
      }
    }
    activity[material.activity.id].materials.push({
      id: material.id,
      activityId: material.activity.id,
      materialType: material.materialType,
      url: material.url,
      file: material.file,
      description: material.description,
    })
  })
  
  for (const property in activity) {
     newList.push(activity[property])
  }

  return newList
}
