import React from 'react'
import { useTranslation } from 'react-i18next';

import ProductForm from './ProductForm';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import "react-datepicker/dist/react-datepicker.css";
import {PrimaryButton} from 'components/Buttons';
import { CenteredModalButtonContainer } from 'components/CommonStyled';

const ProductsForm = ({ handleCloseOk, activity }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Accordion>
        {activity.productList
          ? ( activity.productList.map((product, key) => (
              <AccordionItem key={key+1}>
                <AccordionItemHeader>
                  <AccordionTitle eventKey={key+1}>{t('F3_S7_Accordion_product')+" "+(key+1)}</AccordionTitle>
                </AccordionItemHeader>
                <Accordion.Collapse eventKey={key+1}>
                  <AccordionItemBody>
                    <ProductForm data={product} /> 
                  </AccordionItemBody>
                </Accordion.Collapse>
              </AccordionItem>
            )))
          : null}
      </Accordion>
    
      <CenteredModalButtonContainer>
        <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
      </CenteredModalButtonContainer>
    </div>
  )
}

export default ProductsForm;