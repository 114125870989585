import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { ModalFooter, FooterLeft, FooterCenter, FooterRight, StyledModalBody, StyledGreenP } from './F9ADialog.styled';
import { PrimaryButton } from 'components/Buttons';
import {formInitialValues} from '../EudForm/EudForm.initialValues'
import { useTranslation } from 'react-i18next';
import { formatDateDDMMYY } from 'helpers/dateHelpers';

const F9ADialog = ({ values, displayDialog, onCloseModal, initiative, isINTPAAssessment }) => {
  const { t } = useTranslation();
  const [currentValues, setCurrentValues] = useState(formInitialValues);

  const swapValue = (obj) => {
    Object.keys(obj).forEach(key => {
      if (!obj[key]) {
        obj[key] = ' - ';
      }
    });
  };
  
  useEffect(() => {
    const formValues = { ...values };
    swapValue(formValues);
    setCurrentValues(formValues);
  }, [values]);

  return (
    <Modal show={displayDialog} onHide={onCloseModal} size="lg" animation={false}>
      {currentValues.user &&
        <Modal.Header>
          <h5>{t('Assessment_by')+" "+currentValues.user.organization+", "+currentValues.user.name+" "+currentValues.user.lastName+" - "+t('Initiative')+" "+initiative} </h5>
        </Modal.Header>
      }
      <StyledModalBody>
        <StyledGreenP> {t('Received_on')+" "+ (currentValues.statusTimestamp ? currentValues.statusTimestamp.split('T')[0] : ' - ')}  </StyledGreenP>
        <br />
        <br />
  
        {isINTPAAssessment ? (
          <>
            <StyledGreenP>{t('F9c_assessment_1')}</StyledGreenP>
            <p>
              <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.comment1}</span>
            </p>
            <StyledGreenP>{t('F9c_assessment_2')}</StyledGreenP>
            <p>
              <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.comment2}</span>
            </p>
            <StyledGreenP>{t('F9c_assessment_3')}</StyledGreenP>
            <p>
              <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.assessment9c}</span>
            </p>

            <StyledGreenP>{t('F9c_radio_label_1')}</StyledGreenP>
            <input 
              type="radio" 
              checked={!currentValues.endExPost}
              disabled
              style={{marginLeft: '10px'}}
           
            />
            <br />
            <br />
            <StyledGreenP>{t('F9c_radio_label_2')}</StyledGreenP>
            <input 
              type="radio" 
              checked={currentValues.endExPost}
              disabled
              style={{marginLeft: '10px'}}
           
            />
            <br />
            <br />
            <StyledGreenP>{t('F9c_assessment_5')}</StyledGreenP>
            <p>
              <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.dateNewExPost ? formatDateDDMMYY(currentValues.dateNewExPost) : '-'}</span>
            </p>
          </>
        ) : (
          <>
            <StyledGreenP>{t('F9a_assessment_1')}</StyledGreenP>
            <p>
              <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.comment1}</span>
            </p>
            <StyledGreenP>{t('F9a_assessment_2')}</StyledGreenP>
            <p>
              <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.comment2}</span>
            </p>
            <StyledGreenP>{t('F9a_assessment_3')}</StyledGreenP>
            <p>
              <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{currentValues.recommendation}</span>
            </p>
          </>
        )}
    
      </StyledModalBody>
      <ModalFooter>
        <FooterLeft />
        <FooterCenter>
          <PrimaryButton onClick={onCloseModal}>{t('Button_close')}</PrimaryButton>
        </FooterCenter>
        <FooterRight />
      </ModalFooter>
    </Modal>
  )
}

F9ADialog.propTypes = {
  values: Proptypes.object,
  displayDialog: Proptypes.bool,
  onCloseModal: Proptypes.func,
  initiative: Proptypes.string,
};

export default F9ADialog;
