import { EXECUTION_CATEGORY_LABEL, EXECUTION_FUNDS_CATEGORY_LABEL, FUNDS_TITLE_ROW, INITIATIVE_TITLE_ROW } from 'constants/appConstants'
import i18n from 'i18n'
import React from 'react'
import { Th2, Td } from '../../CommonStyled/Components.styled'

const T1renderRow = (object, years) => {
  let row = []
  for (let i = 0; i < years.length; i++) {
    row.push(
      <Td>
        {object[years[i]] ? Intl.NumberFormat('es-AR').format(object[years[i]]) : object[years[i]] == 0 ? 0 : '-'}
      </Td>,
    )
  }
  row.push(<Td>{Intl.NumberFormat('es-AR').format(object.total)}</Td>)
  return row
}

export const T1RenderRows = (rows, years) =>
  rows.map((row) => (
    <tr>
      <Th2>{`${i18n.t(EXECUTION_CATEGORY_LABEL[row?.category])} ${
        row?.category === 'entities'
          ? '*'
          : row?.category === 'initiatives'
          ? '**'
          : ''
      }`}</Th2>
      {T1renderRow(row, years)}
    </tr>
  ))

export const T2RenderRows = (rows, years) =>
  rows.map((row) => (
    <tr>
      <Th2>{`${i18n.t(EXECUTION_CATEGORY_LABEL[row?.category])} ${
        row?.category === 'entities'
          ? '*'
          : row?.category === 'countries'
          ? '**'
          : ''
      }`}</Th2>
      {T1renderRow(row, years)}
    </tr>
  ))

  export const T3RenderRows = (rows) =>
    rows.map((row) => (
      <tr>
        <Th2>{`${i18n.t(EXECUTION_FUNDS_CATEGORY_LABEL[row?.category])} ${
          row?.category === 'entities'
            ? '*'
            : row?.category === 'countries'
            ? '**'
            : ''
        }`}</Th2>
        <Td>{Intl.NumberFormat('es-AR').format(row.total)}</Td>
      </tr>
    ))

export const getExecutionSummaryExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData.forEach((item) => {
    data.push({
      label: i18n.t(EXECUTION_CATEGORY_LABEL[item?.category]),
      col1: item['2021'],
      col2: item['2022'],
      col3: item['2023'],
      col4: item['2024'],
      col5: item['2021'] + item['2022'] + item['2023'] + item['2024'],
    })
  })
  return data
}

export const getFundsExecutionSummaryExportValues = (exportData) => {
  let data = []
  data.push(FUNDS_TITLE_ROW)
  exportData.forEach((item) => {
    data.push({
      label: i18n.t(EXECUTION_CATEGORY_LABEL[item?.category]),
      col1: item['total'],
      col2: '',
      col3: '',
      col4: '',
      col5: '',
    })
  })
  return data
}
