export const formInitialValues = {
  activityTypeId: "",
  duration: 0,
  description: "",
  fromDateStr: "",
  toDateStr: "",
  participants: 0,
  countryId: "",
  cityName: "",
  stateName: "",
  ueBudget: 0.000001,
  counterPartBudget: 0.000001,
  totalBudget: 0.000002,
  justBudget: "",
  experts: 0,
  daysRemote: 0,
  daysOnsite: 0
};