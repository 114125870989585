/* eslint-disable no-useless-catch */
import axiosInstance from './axiosInstance'

export const getFundExecutionSummary = async (params) => {
  const url = `/api/reporting/funds-execution-summary`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFundsEntitySummaryByRole = async (params) => {
  const url = `/api/reporting/funds-entity-summary-by-role`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFundsEntitySummaryByFunction = async (params) => {
  const url = `/api/reporting/funds-entity-summary-by-function`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFundsEntitySummaryByCountry = async (params) => {
  const url = `/api/reporting/funds-entity-summary-by-country`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFundsEntitySummaryByEntityType = async (params) => {
  const url = `/api/reporting/funds-entity-summary-by-entity-type`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFundsActivitySummaryByActivityType = async (params) => {
  const url = `/api/reporting/funds-activity-summary-by-activity-type`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFundsActivitySummaryByFormat = async (params) => {
  const url = `/api/reporting/funds-activity-summary-by-format`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFundsActivitySummaryByCountry = async (params) => {
  const url = `/api/reporting/funds-activity-summary-by-country`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFundsBeneficiarySummaryByType = async (params) => {
  const url = `/api/reporting/funds-beneficiary-summary-by-type`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFundsBeneficiarySummaryByCountry = async (params) => {
  const url = `/api/reporting/funds-beneficiary-summary-by-country`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}


export const getFundsODSTargetSummary = async (params) => {
  const url = `/api/reporting/funds-ods-target-summary`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFundsODSSummary = async (params) => {
  const url = `/api/reporting/funds-ods-summary`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFundsCountryReport = async (params) => {
  const url = `/api/reporting/funds-country-report`
  try {
    const response = await axiosInstance.get(url, { params: params })
    return response.data
  } catch (error) {
    throw error
  }
}