import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { roleNamesConst, f4statusNamesConst } from 'constants/appConstants';
import { useRootStore } from 'stores';
import { saveFormSection } from 'services/initiative';
import { useGetComments } from 'customHooks/useGetComments';
import { PrimaryButton } from 'components/Buttons';
import Modal from 'components/Modal';
import { FakeLink, FormFielErrorMessage } from 'components/CommonStyled';
import { 
  ClarificationsLinkContainer, 
  RequestClarificationLabel, 
  StyledDiv, 
  ButtonContainer,
  ModalTitle,
  StyledSavedClarifButton,
} from './Comments.styled';

import { useTranslation } from 'react-i18next';
import i18n from 'i18n';


const EudComments = ({ comments }) => (
  <>
    {comments.length ? comments.map((item, index) => {
      const { user, state: status, comment } = item;
      const name = `${user.name} ${user.lastName}`;
      const organization = "("+i18n.t('organization')+": "+(user.organization ? user.organization : '-')+")";
      const text  = comment ? comment : '-';
      return (
        <p key={index}>
         {i18n.t('Comments_for')+" "} <i>{`${name} ${organization}`}</i>: {`[${status}]`} <br/> <i style={{whiteSpace:"pre-wrap"}}>{text}</i>
        </p>
      )
    }) : i18n.t('EUD_comments:')}
  </>
)

const IntpaComment = ({ comments }) => (
  <p>{i18n.t('INTPA_comment:')+" "}<i>{comments? comments: '-'}</i></p>
)

const EudCommentsModal = () => {
  const { eudComments } = useGetComments();  
  const { sectionId } = useParams();
  const [modal, setModal] = useState(false);
  const {t} = useTranslation();

  return (
    <>
    {modal && <Modal
      size="lg"
      show={modal}
      onHide={() => setModal(false)}
      header={<ModalTitle>{t('Comments_for_section')+" "+sectionId+" (EUD)"} </ModalTitle>}
      message={<EudComments comments={eudComments} />}
      footer={<PrimaryButton onClick={() => setModal(false)}>{t('Button_close')}</PrimaryButton>}
    />}
    <ClarificationsLinkContainer>
      <FakeLink onClick={() => setModal(true)}>{t('F4_view_commnts_EUD')}</FakeLink>
    </ClarificationsLinkContainer>
    </>
  )
}

const IntpaCommentModal = () => {
  const { intpaComment } = useGetComments();  
  const { sectionId } = useParams();
  const [eudModal, setModal] = useState(false);
  const {t} = useTranslation();

  return (
    <>
    {eudModal && <Modal
      size="lg"
      show={eudModal}
      onHide={() => setModal(false)}
      header={<ModalTitle>{t('Comments_for_section')+" "+sectionId+" (INTPA)"} </ModalTitle>}
      message={<IntpaComment comments={intpaComment} />}
      footer={<PrimaryButton onClick={() => setModal(false)}>{t('Button_close')}</PrimaryButton>}
    />}
    <ClarificationsLinkContainer>
      <FakeLink onClick={() => setModal(true)}>{t('F4_view_commnts_INTPA')}</FakeLink>
    </ClarificationsLinkContainer>
    </>
  )
}

const Comments = (props) => {

  const {t} = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { authStore, initiativeStore } = useRootStore();
  const [comment, setComment] = useState('');
  const { intpaComment, eudComments } = useGetComments(); 
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(true);
  const [error, setError] = useState(false);
  

  
  useEffect(() => {
    setNonEditable(!(initiativeStore.assessmentStatus === f4statusNamesConst.PENDING || initiativeStore.assessmentStatus === f4statusNamesConst.ONGOING) || (isATKNKE || isCONSORTIUM));
      if(props.onButton)
        props.onButton(nonEditable);
      
  },[initiativeStore,props.onButton])

  useEffect(() => {
    if (isIntpa && intpaComment!= null) {
        setComment(intpaComment);
    }
    else
      if (isEud && eudComments!= null) {
         setComment(eudComments[0].comment);
      }
    
  }, [intpaComment, eudComments])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);
  }, [saved]);

  const handleCommentsChange = (event) => {
    const { value } = event.target;
    setComment(value);
    setSaved(false);
    props.onSaved(false);
    if (value.length) {
      setError(false)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!comment.length) {
      setError(true);
      return;
    }
    try {
      await saveFormSection(id, formId, sectionId, { comment });
      setSaved(true);
      props.onSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const role = authStore.roles[0];

  const isIntpa = role === roleNamesConst.INTPA || role === roleNamesConst.ATNKE || role === roleNamesConst.CONSORTIUM;
  const isEud = role === roleNamesConst.EUD;
  const isTa = role === roleNamesConst.ATKE;
  const isATKNKE = role === roleNamesConst.ATNKE;
  const isCONSORTIUM = role === roleNamesConst.CONSORTIUM;

  const shouldDisplayEudModal = isIntpa || isTa;
  const shouldDisplayIntpaModal = isTa;
  const shouldDisplayForm = isIntpa || isEud;

  return (
    <>
      {shouldDisplayEudModal && <EudCommentsModal />}
      {shouldDisplayIntpaModal && <IntpaCommentModal />}
      {shouldDisplayForm && (
        <form onSubmit={onSubmit} id={`form4section${sectionId}`}>
          <Form.Group>
            <Row>
              <RequestClarificationLabel>{t('F4_Comments')}</RequestClarificationLabel>
            </Row>
            <StyledDiv>
              <Form.Control 
                as="textarea"
                style={{resize: 'none'}}
                rows={4}
                value= {comment}
                onChange={handleCommentsChange}
                disabled={nonEditable}
                />
              { error && <FormFielErrorMessage>{t('F3_S14_validation1')}</FormFielErrorMessage> }
            </StyledDiv>
            <ButtonContainer>
            
             {!nonEditable && <StyledSavedClarifButton type="submit" disabled={saved} >
                {!saved? t('Button_Save_Comments'): t('Button_Saved_Comments')}
              </StyledSavedClarifButton> }
            </ButtonContainer>
          </Form.Group>
        </form>
      )}
    </>
  )
}

export default Comments;
