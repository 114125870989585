import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  role: Yup.number(), //0: F6, ATKE,CONSORTIUM , 1: F7 EUD, 2: F7 INTPA
  knowledge: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 0),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
  impact: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 0),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
  value: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 0),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
  elements: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 0),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
  overall: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 0),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
  level: Yup.boolean()
    .when('role', {
      is: role => (role == 0),
      then: Yup.boolean()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.boolean()
        .nullable(true)
    }),
  oo1: Yup.boolean(),
  oo2: Yup.boolean(),
  so3: Yup.boolean(),
  so4: Yup.boolean(),
  out5: Yup.boolean(),
  out6: Yup.boolean(),
  out7: Yup.boolean(),
  out8: Yup.boolean(),
  out9: Yup.boolean(),
  assessmentQ1: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 1),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
  assessmentQ2: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 1),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
  assessmentQ3: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 1),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
  assessmentQ4: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 1),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
  assessmentQ5: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 1),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
  assessmentQ6: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 1 || role == 2),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
  assessmentQ7: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 1),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
  assessmentQ8: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 1),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
  assessmentQ9: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .when('role', {
      is: role => (role == 1),
      then: Yup.string()
        .nullable(true)
        .required('F3_S7_validation3'),
      otherwise: Yup.string()
        .nullable(true)
    }),
});