import React from "react";

import ResourcesForm from '../ResourcesForm'
import ParticipantsForm from '../ParticipantsForm'
import ConsultantsForm from '../ConsultantsForm'
import ProductsForm from '../ProductsForm'
import NewActivityForm from '../NewActivityForm'
import GeneralInfoForm from '../GeneralInfoForm'
import HelpIcon from "components/HelpIcon";
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'

import { StyledModalBody, ModalTitle } from './ActivityGeneralModal.styled'

const ActivityGeneralModal = ({
  handleRefreshList,
  show,
  formType,
  handleClose,
  handleCloseOnSave,
  handleCloseOnError,
  title,
  activity,
  activityCode,
  setModalInfo,
  handleCloseOk,
  nonEditable,
  info,
}) => {
  const { t } = useTranslation()

  const handleCloseSubModalOnSave = async () => {
    await handleRefreshList()
    handleCloseOnSave()
  }

  const getFormByType = (type) => {
    const fromByType = {
      [0]: (
        <GeneralInfoForm
          handleCloseOk={handleCloseOk}
          handleClose={handleClose}
          activity={activity}
          handleCloseSubModalOnSave={handleCloseSubModalOnSave}
          handleCloseSubModalOnError={handleCloseOnError}
          nonEditable={nonEditable}
        />
      ),
      [1]: (
        <ProductsForm
          handleCloseOk={handleCloseOk}
          handleClose={handleClose}
          activity={activity}
          handleCloseSubModalOnSave={handleCloseSubModalOnSave}
          handleCloseSubModalOnError={handleCloseOnError}
          nonEditable={nonEditable}
        />
      ),
      [2]: (
        <ConsultantsForm
          handleCloseOk={handleCloseOk}
          handleClose={handleClose}
          activity={activity}
          handleCloseSubModalOnSave={handleCloseSubModalOnSave}
          handleCloseSubModalOnError={handleCloseOnError}
          nonEditable={nonEditable}
        />
      ),
      [3]: (
        <ParticipantsForm
          handleCloseOk={handleCloseOk}
          handleClose={handleClose}
          activity={activity}
          handleCloseSubModalOnSave={handleCloseSubModalOnSave}
          handleCloseSubModalOnError={handleCloseOnError}
          nonEditable={nonEditable}
        />
      ),
      [4]: (
        <ResourcesForm
          handleCloseOk={handleCloseOk}
          activity={activity}
          handleRefreshList={handleRefreshList}
          setModalInfo={setModalInfo}
          nonEditable={nonEditable}
        />
      ),
      [5]: (
        <NewActivityForm
          handleCloseOk={handleCloseOk}
          handleClose={handleClose}
          handleCloseOnSave={handleCloseOnSave}
          handleRefreshList={handleRefreshList}
          nonEditable={nonEditable}
        />
      ),
    }
    return fromByType[type]
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        backdrop="static"
        animation={false}
      >
        <StyledModalBody>
          <ModalTitle>
            {`${title} ${
              !activityCode ? '' : `- ${t('F1_S7_Accordion')} ${activityCode}`
            }`}
            {info ? (
              <HelpIcon
                info={info}
              />
            ) : null}
          </ModalTitle>
          <div style={{ marginTop: '40px' }}>{getFormByType(formType)}</div>
        </StyledModalBody>
      </Modal>
    </>
  );
};

export default ActivityGeneralModal
