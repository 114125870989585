import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { validationSchema } from "./Section5.validations";
import { observer } from "mobx-react-lite";
import { useRootStore } from "stores";
import { useParams } from "react-router-dom";
import { formInitialValues } from "./Section5.initialValues";
import { useTranslation } from "react-i18next";
import { saveFormSection } from "services/initiative";
import TextValue from "components/TextValue";
import { f5statusNamesConst, roleNamesConst } from "constants/appConstants";
import Comments from "../Comments";
import Form from "react-bootstrap/Form";
import FormLayout from "../../FormLayout";
import FormContainer from "components/FormContainer";
import Accordion from "react-bootstrap/Accordion";
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from "components/CustomAccordion";
import { RequiredMark } from "components/CommonStyled";
import {
  StyledFormLabel,
  StyledDivWrapper,
  StyledTitle,
} from "./Section5.styled";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const Section5 = () => {
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { formStore, initiativeStore, authStore } = useRootStore();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [nonEditable, setNonEditable] = useState(false);
  const [saved, setSaved] = useState(false);
  const [disabledComments, setDisabledComments] = useState(true);
  const currentRole = authStore.roles[0];
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM;
  const isAtke = currentRole === roleNamesConst.ATKE;
  const isIntpa = currentRole === roleNamesConst.INTPA;
  const isEud = currentRole === roleNamesConst.EUD;
  const [isF7, setIsF7] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f5statusNamesConst.DRAFT ||
        initiativeStore.role !== roleNamesConst.APPLICANT
    );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {
        ...formInitialValues,
        ...formStore.formSectionData[0],
      };
      setInitialValues(formSectionData);
    }
  }, [formStore.formSectionData]);

  useEffect(() => {
    if (initiativeStore.formStateName)
      setIsF7(
        (isIntpa || isEud) &&
          initiativeStore.formStateName !== f5statusNamesConst.DRAFT &&
          initiativeStore.formStateName !== f5statusNamesConst.SENT
      );
  }, [initiativeStore.formStateName]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values);
      formStore.setSectionStatus(sectionId, complete);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaved = (value) => {
    setSaved(value);
  };

  const handleButton = (value) => {
    setDisabledComments(value);
  };

  return (
    <FormLayout>
      <FormContainer
        number={5}
        title={t("F1_S5_title")}
        formId="form5section5"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
        showButtonClarif={!disabledComments}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            dirty,
          }) => (
            <form onSubmit={handleSubmit} id="form5section5">
              {dirty && setSaved(false)}

              <StyledDivWrapper>
                <Accordion style={{ marginBottom: "25px" }}>
                  <AccordionItem key="0">
                    <AccordionItemHeader>
                      <AccordionTitle eventKey="0">
                        {t("F5_Subtitle_Description_from_CN")}
                      </AccordionTitle>
                    </AccordionItemHeader>
                    <Accordion.Collapse eventKey="0">
                      <AccordionItemBody style={{ marginLeft: "20px" }}>
                        <Form.Group>
                          <StyledFormLabel>
                            {t("F1_S5_Provide_an_overview")}:
                          </StyledFormLabel>
                          <TextValue value={values.overview} />
                        </Form.Group>
                      </AccordionItemBody>
                    </Accordion.Collapse>
                  </AccordionItem>

                  <AccordionItem key="1">
                    <AccordionItemHeader>
                      <AccordionTitle eventKey="1">
                        {t("F5_Subtitle_Description_from_proposal")}
                      </AccordionTitle>
                    </AccordionItemHeader>
                    <Accordion.Collapse eventKey="1">
                      <AccordionItemBody style={{ marginLeft: "30px" }}>
                        <Form.Group>
                          <StyledFormLabel>
                            {t("F3_S5_Question_1")}:
                          </StyledFormLabel>
                          <TextValue value={values.relations1} />
                        </Form.Group>

                        <Form.Group>
                          <StyledFormLabel>
                            {t("F3_S5_Question_2")}:
                          </StyledFormLabel>
                          <TextValue value={values.relations2} />
                        </Form.Group>

                        <Form.Group>
                          <StyledFormLabel>
                            {t("F3_S5_Question_3")}:
                          </StyledFormLabel>
                          <TextValue value={values.relations3} />
                        </Form.Group>

                        <Form.Group>
                          <StyledFormLabel>
                            {t("F3_S5_Question_4")}:
                          </StyledFormLabel>
                          <TextValue value={values.relations4} />
                        </Form.Group>
                      </AccordionItemBody>
                    </Accordion.Collapse>
                  </AccordionItem>
                </Accordion>

                <StyledTitle>
                  {t("F5_Subtitle_Assessment_TCI_Implementation")}
                </StyledTitle>

                <Form.Group style={{ marginTop: "25px" }}>
                  <StyledFormLabel>
                    {t("F5_S5_Perspectives_joint_work")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="perspectives"
                    as="textarea"
                    rows={6}
                    style={{ resize: "none" }}
                    placeholder={t("Maximum_1300")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.perspectives}
                    disabled={nonEditable}
                    className={
                      touched.perspectives && errors.perspectives
                        ? "error"
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.perspectives}
                    errors={errors.perspectives}
                  />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel>
                    {t("F5_S5_Assessment_joint_work")}:
                  </StyledFormLabel>
                  <Form.Control
                    name="assessment"
                    as="textarea"
                    rows={6}
                    style={{ resize: "none" }}
                    placeholder={t("Maximum_1300")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.assessment}
                    disabled={nonEditable}
                    className={
                      touched.assessment && errors.assessment ? "error" : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.assessment}
                    errors={errors.assessment}
                  />
                </Form.Group>
              </StyledDivWrapper>
            </form>
          )}
        </Formik>

        {(isAtke || isConsortium || isF7) && (
          <div style={{ marginLeft: "50px" }}>
            <Comments onSaved={handleSaved} onButton={handleButton} />
          </div>
        )}
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section5);
