import styled from 'styled-components';
import Form from 'react-bootstrap/Form';


export const StyledFormLabel = styled(Form.Label)`
  padding-left: 65px;
`;

export const StyledRadioSection = styled.div`
  padding-top: 10px;
  line-height: 30px;
`;

export const StyledRadioLabel = styled.label`
  margin-right: 60px;
`;
