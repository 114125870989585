import styled, { css } from 'styled-components';

export const StyledTitle = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    color: ${theme.brandColors.blue};
    font-weight: 600;
    font-size: 23px;
    margin-bottom: 20px;
  `}
`;

export const StyledTextValue = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.gray[5]};
  `}
  line-height: 1.3;
`;

export const StyledIntroParagraph = styled.p`
  line-height: 1.3rem;
  margin-bottom: 25px;
  text-align: justify;
`;

export const DescripcionDiv = styled.div`
  ${({ theme }) => css`
    padding: 15px;
    border: 2px solid ${theme.colors.gray[3]};
    margin-bottom: 20px;
  `}
`;

export const Text = styled.div`
  line-height: 1.3;
  text-align: justify;
  margin-bottom: 10px;
`;


export const StyledContainer = styled.div`
  padding-left: 50px;
`;

