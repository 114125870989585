import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';
import { formatDateDDMMYY } from 'helpers/dateHelpers';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  StyledFormLabel,
} from 'components/Forms/Form4/Section8/Section8.styled';
import Form from 'react-bootstrap/Form';
import TextValue from 'components/TextValue';

const Section8 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (data) {
      setFormValues({
        ...data[0],
        ...data[1],
      });
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`8 - ${t('F3_S8_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <Form.Group style={{display: 'flex', justifyContent: 'space-between'}}>
          <StyledFormLabel style={{marginTop: '8px'}}>{t('F3_S8_Start_day')}:</StyledFormLabel>
          <TextValue value={formValues? formatDateDDMMYY(formValues.startDate) : "-"} />

          <StyledFormLabel style={{marginTop: '8px'}}>{t('F3_S8_End_day')}:</StyledFormLabel>
          <TextValue value={formValues? formatDateDDMMYY(formValues.endDate) : "-"} />
        </Form.Group>
  
        <Form.Group>
          <StyledFormLabel> {t('F1_S7_Intervention_methodology')}: </StyledFormLabel>
          <TextValue value={formValues.methodology} />
        </Form.Group>  

        <Form.Group>
          <StyledFormLabel> {t('F3_S8_justification_deviation')}: </StyledFormLabel>
          <TextValue value={formValues.justificationDeviation} />
        </Form.Group>  

        <Form.Group>
          <StyledFormLabel> {t('F1_S6_Question_22')}: </StyledFormLabel>
          <TextValue value={formValues.covid19} />
        </Form.Group>  
      </StyledBody>
    </SectionContainer>
  )
}


export default Section8;