import React, { useState, useEffect, useRef } from 'react'
import { Formik } from 'formik'
import { validationSchema } from './Section9.validations'
import { formInitialValues } from './Section9.initialValues'
import { observer } from 'mobx-react-lite'
import { useParams, useHistory } from 'react-router-dom'
import { useRootStore } from 'stores'
import { roleNamesConst } from 'constants/appConstants'
import {
  getF2Section9,
  saveForm2GeneralAssesment,
  sendF2toMainAssessor,
  sendF2Clarifications,
  sendF2IntpaDecision,
  reviewF2Decision,
} from 'services/initiative'
import { PrimaryButton, ErrorButton, SuccessButton } from 'components/Buttons'
import { f1statusNamesConst, f2statusNamesConst } from 'constants/appConstants'
import { isSentStatus } from 'helpers/cnStateHelper'

import IntpaATComment from './IntpaATComment'
import EUDAssessments from './EUDAssessments'
import SendClarificationsDialog from './SendClarificationsDialog'
import SendDialog from './SendDialog'
import MyAssesmentForm from './MyAssessmentForm'
import ReviewINTPA from './ReviewINTPA/ReviewINTPA'
import ATCommentForm from './ATCommentForm'
import TAReviewReadOnly from './TAReviewReadOnly'

import FormLayout from '../../FormLayout'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import {
  StyledSeparator,
  StyledGreenFormLabel,
  StyledSavedButton,
} from './Section9.styled'

import { useTranslation } from 'react-i18next'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const Section9 = () => {
  const { t } = useTranslation()
  const formRef = useRef()
  const { formStore, authStore, initiativeStore } = useRootStore()
  const { id } = useParams()
  const history = useHistory()
  const role = authStore.roles[0]

  const isIntpa = role === roleNamesConst.INTPA
  const isEud = role === roleNamesConst.EUD
  const isTa =
    role === roleNamesConst.ATKE ||
    role === roleNamesConst.ATNKE ||
    role === roleNamesConst.CONSORTIUM
  const isATKNKE = role === roleNamesConst.ATNKE
  const isCONSORTIUM = role === roleNamesConst.CONSORTIUM
  const [isV1, setIsV1] = useState(false)

  const [form2states, setForm2states] = useState({
    eudForms: [],
    atForm: {},
    intpaForm: {},
    initiative: {},
  })

  const reviewINTPAState =
    !initiativeStore.f2cDecided && initiativeStore.f2bDecided
  const reviewTAState =
    !initiativeStore.f2bDecided && !initiativeStore.f2cDecided

  const isStatusSent = isSentStatus(
    form2states.initiative.formStateName,
    initiativeStore.f2bDecided,
    initiativeStore.f2cDecided,
  )
  // si !reviewINTPAState && !reviewTAState entonces es estado SENT y se muestra todo lo de antes
  // si alguno es true no se muestra todo lo de antes (estado sent) y se muestra lo que se tiene que mostrar

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  async function fetchAPI() {
    try {
      const { eudForms, atForm, intpaForm, initiative } = await getF2Section9(
        id,
      )
      setForm2states({ eudForms, atForm, intpaForm, initiative })
    } catch (e) {
      console.log(e)
    }
  }

  const [sending, setSending] = useState(false)
  const [nonEditable, setNonEditable] = useState(true)

  useEffect(() => {
    if (Object.entries(form2states.initiative).length > 0) {
      const isSent =
        form2states.initiative &&
        form2states.initiative.formStateName === f1statusNamesConst.SENT
      if (isEud) {
        isSent &&
        (form2states.eudForms[0].stateName === f2statusNamesConst.PENDING ||
          form2states.eudForms[0].stateName === f2statusNamesConst.ONGOING)
          ? setNonEditable(false)
          : setNonEditable(true)
      } else if (isTa) {
        isSent &&
        (form2states.atForm.stateName === f2statusNamesConst.PENDING ||
          form2states.atForm.stateName === f2statusNamesConst.ONGOING)
          ? setNonEditable(false)
          : setNonEditable(true)
      } else if (isIntpa) {
        isSent ? setNonEditable(false) : setNonEditable(true)
      }
      if (isATKNKE || isCONSORTIUM) {
        setNonEditable(true)
      }
      setIsV1(form2states.initiative.formVersion === 1)
    }
  }, [
    form2states.initiative,
    form2states.eudForms,
    form2states.atForm,
    form2states.intpaForm,
    isIntpa,
    isEud,
    isTa,
    isATKNKE,
    isCONSORTIUM,
    reviewTAState,
    reviewINTPAState,
  ])

  const [displayModalSend, setDisplayModalSend] = useState(false)
  const handleOpenModalSend = (approval) => {
    setIntpaDecision(approval)
    setDisplayModalSend(true)
  }
  const handleCloseModalSend = () => setDisplayModalSend(false)

  const [intpaDecision, setIntpaDecision] = useState(false)
  const [formValues, setFormValues] = useState(formInitialValues)
  const [saved, setSaved] = useState(false)

  const [
    displayModalSendClarifications,
    setDisplayModalSendClarifications,
  ] = useState(false)
  const handleOpenModalSendClarifications = () =>
    setDisplayModalSendClarifications(true)
  const handleCloseModalSendClarifications = () =>
    setDisplayModalSendClarifications(false)

  useEffect(() => {
    if (saved) window.scrollTo(0, 0)
  }, [saved])

  useEffect(async () => {
    await fetchAPI()
  }, [])

  useEffect(() => {
    if (isTa) {
      setFormValues({
        ...form2states.atForm,
        justification: form2states?.atForm?.recommendationJustification,
      })
    } else if (isEud && form2states.eudForms && form2states.eudForms[0]) {
      setFormValues(form2states.eudForms[0])
    } else if (isIntpa) {
      setFormValues(form2states.intpaForm)
    }
    formStore.setCurrentSection(9)
  }, [formStore, form2states, isTa, isEud, isIntpa])

  const handleSendIntpaDecision = async (decision) => {
    try {
      await handleSave(formRef.current.values)
      await sendF2IntpaDecision(id, decision)
      redirectToConceptNotes()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSendToMainAssessor = (errors, setErrors, values) => {
    if (!reviewTAState && (!values.assessment || values.assessment === '')) {
      alert(t('not_saved_alert'))
      setErrors({
        ...errors,
        assessment: t('Assessment_must_not_be_empty'),
      })
    } else {
      handleOpenModalSend()
    }
  }

  const redirectToConceptNotes = () => {
    history.push(`${authStore.getBaseRouteByRole()}/concept-notes`)
  }

  const handleSend = async () => {
    try {
      if (reviewTAState) {
        await reviewF2Decision(id, {
          recommends: formRef.current.values.recommends,
          justification: formRef.current.values.justification,
        })
      } else {
        await handleSave(formRef.current.values)
        await sendF2toMainAssessor(id)
      }
      redirectToConceptNotes()
    } catch (error) {
      console.log(error)
    }
  }

  const handleSendClarifications = async () => {
    try {
      setSending(true)
      await handleSave(formRef.current.values)
      await sendF2Clarifications(id)
      redirectToConceptNotes()
    } catch (error) {
      console.log(error)
    } finally {
      setSending(false)
    }
  }

  const handleSave = async (values, resetForm) => {
    try {
      setSending(true)
      await saveForm2GeneralAssesment(id, values)
      resetForm({ values })
      setSaved(true)
    } catch (error) {
      console.log(error)
    } finally {
      setSending(false)
    }
  }

  return (
    <FormLayout>
      <SendDialog
        displayDialog={displayModalSend}
        isIntpa={isIntpa}
        intpaDecision={intpaDecision}
        onSend={isIntpa ? handleSendIntpaDecision : handleSend}
        onCloseModal={handleCloseModalSend}
        closeOnError={() => {
          setDisplayModalSend(false)
        }}
      />

      <SendClarificationsDialog
        displayDialog={displayModalSendClarifications}
        onSend={handleSendClarifications}
        onCloseModal={handleCloseModalSendClarifications}
        closeOnError={() => {
          setDisplayModalSendClarifications(false)
        }}
      />

      <Formik
        enableReinitialize
        initialValues={formValues}
        validationSchema={validationSchema}
        innerRef={formRef}
        onSubmit={handleSave}
      >
        {(props) => (
          <Form style={{ minWidth: '100%' }} id="form1section9">
            {props.dirty && setSaved(false)}

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: '30px',
              }}
            >
              {!reviewINTPAState && !reviewTAState && (
                <>
                  {!saved && (
                    <PrimaryButton
                      disabled={nonEditable}
                      onClick={() => {
                        handleSave(props.values, props.resetForm)
                      }}
                    >
                      {t('Button_Save_Later')}
                    </PrimaryButton>
                  )}
                  {saved && (
                    <StyledSavedButton disabled={true}>
                      {t('Button_saved')}
                    </StyledSavedButton>
                  )}
                </>
              )}
            </div>

            {(isIntpa ||
              (isEud && !reviewINTPAState && !reviewTAState)) && 
                <TAReviewReadOnly values={form2states?.atForm} />
            }

            {!reviewINTPAState && !reviewTAState && (isIntpa || isEud) && (
              <>
                <IntpaATComment
                  atComment={
                    form2states?.atForm?.assessment
                      ? form2states?.atForm?.assessment
                      : ' - '
                  }
                />
              </>
            )}

            {!reviewINTPAState && !reviewTAState && (isTa || isIntpa) && (
              <EUDAssessments
                isIntpa={isIntpa}
                f2AValues={form2states.eudForms}
                intpaAssessment={form2states.intpaForm}
                initiativeCode={
                  form2states.initiative ? form2states.initiative.code : ''
                }
                nonEditable={nonEditable}
              />
            )}

            {isIntpa && isV1 && !reviewINTPAState && !reviewTAState && (
              <Row>
                <Col
                  style={{
                    marginBottom: '40px',
                    marginTop: '30px',
                    textAlign: 'center',
                  }}
                >
                  <SuccessButton
                    disabled={nonEditable || sending}
                    onClick={() => {
                      handleOpenModalSendClarifications()
                    }}
                  >
                    {t('Button_Send_Clarifications')}
                  </SuccessButton>
                </Col>
              </Row>
            )}

            <StyledSeparator style={{ marginLeft: '10px' }} />

            {!reviewINTPAState && !reviewTAState && (isIntpa || isEud) && (
              <div>
                <MyAssesmentForm
                  formikInstance={props}
                  nonEditable={nonEditable}
                />
                <StyledSeparator />
                <div
                  style={{
                    marginLeft: '35px',
                    marginTop: '40px',
                    marginRight: '15px',
                  }}
                >
                  <Form.Group>
                    <StyledGreenFormLabel>
                      {isIntpa
                        ? t('F2_intpa_comments')
                        : t('F2_overall_asssessment')}
                    </StyledGreenFormLabel>
                    <Form.Control
                      as="textarea"
                      style={{ resize: 'none' }}
                      rows={8}
                      name="assessment"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.assessment}
                      disabled={nonEditable}
                      className={
                        props.touched.assessment && props.errors.assessment
                          ? 'error'
                          : null
                      }
                    />
                    <FormErrorMessage
                      touched={props.touched.assessment}
                      errors={props.errors.assessment}
                    />
                  </Form.Group>
                </div>
              </div>
            )}

            {isIntpa && reviewINTPAState && (
              <ReviewINTPA
                formikInstance={props}
                nonEditable={false}
                redirectToConceptNotes={redirectToConceptNotes}
              />
            )}
            {isTa && (
              <ATCommentForm
                formikInstance={props}
                nonEditable={nonEditable}
                decisionNonEditable={!reviewTAState}
                isSentStatus={isStatusSent}
              />
            )}

            <div
              style={{
                marginTop: '40px',
                marginLeft: '40px',
                marginRight: '20px',
              }}
            >
              <Row style={{ justifyContent: 'space-between' }}>
                <Col>
                  {!isIntpa && !reviewINTPAState && !reviewTAState && (
                    <>
                      {!saved && (
                        <PrimaryButton
                          disabled={nonEditable || sending}
                          onClick={() => {
                            handleSave(props.values, props.resetForm)
                          }}
                        >
                          {t('Button_Save_Later')}
                        </PrimaryButton>
                      )}
                      {saved && (
                        <StyledSavedButton disabled={true}>
                          {t('Button_saved')}
                        </StyledSavedButton>
                      )}
                    </>
                  )}
                </Col>
                <Col style={{ marginBottom: '40px' }} className="col-auto">
                  {!isIntpa &&
                  ((isEud && !reviewTAState && !reviewINTPAState) || isTa) ? (
                    <SuccessButton
                      disabled={nonEditable || sending}
                      onClick={() => {
                        handleSendToMainAssessor(
                          props.errors,
                          props.setErrors,
                          props.values,
                        )
                      }}
                    >
                      {t('Button_Send_Assessor')}
                    </SuccessButton>
                  ) : reviewINTPAState || reviewTAState ? null : (
                    <>
                      {!saved && (
                        <PrimaryButton
                          disabled={nonEditable || sending}
                          onClick={() => {
                            handleSave(props.values, props.resetForm)
                          }}
                        >
                          {t('Button_Save_Later')}
                        </PrimaryButton>
                      )}
                      {saved && (
                        <StyledSavedButton disabled={true}>
                          {t('Button_saved')}
                        </StyledSavedButton>
                      )}
                    </>
                  )}
                </Col>
              </Row>

              {isIntpa && !reviewINTPAState && !reviewTAState && (
                <Row style={{ justifyContent: 'space-between' }}>
                  <Col>
                    <ErrorButton
                      disabled={nonEditable || sending}
                      onClick={() => {
                        handleOpenModalSend(false)
                      }}
                    >
                      {t('Button_Reject_concept_note')}
                    </ErrorButton>
                  </Col>
                  <Col className="col-auto">
                    <SuccessButton
                      disabled={nonEditable || sending}
                      onClick={() => {
                        handleOpenModalSend(true)
                      }}
                    >
                      {t('Button_Aprove_concept_note')}
                    </SuccessButton>
                  </Col>
                </Row>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </FormLayout>
  )
}

export default observer(Section9)
