import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledFormGroup2, StyledFormLabel } from 'pages/statistics/CommonStyled/Components.styled'
import Form from 'react-bootstrap/esm/Form'
import { PrimaryButton } from 'components/Buttons'
import { useAdelanteCommunityFeaturesContext } from 'customHooks/useAdelanteCommunityFeatures'
import { Row } from 'react-bootstrap'
import ExportToXLSXButton from 'components/ExportToXLSXButton'
import { getMappedCommunityValues } from './helpers'
import { ExportCommunityToXLSX } from 'helpers/xlsxStatisticsExporter'
import htmlParser from 'html-react-parser';

const Header = () => {
  const { t } = useTranslation()
  
  const { filteredItems, searchItems } = useAdelanteCommunityFeaturesContext();
  
  const [keyword, setKeyword] = useState('');
  
  return (
    <>
      <div style={{ width: '30vw', marginLeft: '-5px' }}>
        <img
          src={t('community_title_asset')}
          width="100%"
          alt="Adelante Community Title"
        />
      </div>
      <Row style={{ marginLeft: '0vw', marginBottom: '32px', marginTop: '32px', gap: '16px', alignItems: 'center'}}>
        {htmlParser(t('community_intro_text'))}

        <StyledFormGroup2 style={{ width: '30%' }}>
          <StyledFormLabel>{t('search_by_keyword')}</StyledFormLabel>
          <Form.Control
            name="keyword"
            onChange={(e) => {
              setKeyword(e.target.value)
            }}
            value={keyword}
            onKeyDown={e => {
              if(e.key === 'Enter') {
                e.preventDefault()
                searchItems(keyword)
              }
            }}
          />
        </StyledFormGroup2>

        <PrimaryButton
          onClick={() => {
            searchItems(keyword)
          }}
        >
          {t('Button_search')}
        </PrimaryButton>

        <ExportToXLSXButton
          data={filteredItems}
          mapData={getMappedCommunityValues}
          exportName={t('menu_admin_community')}
        >
          <ExportCommunityToXLSX label={t('menu_admin_community')} data={[]} />
        </ExportToXLSXButton>
      </Row>
    </>
  )
}

export default Header
