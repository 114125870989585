import React from 'react'

import { recoveryService } from 'services/auth';

import {StyledDivWrapper, StyledWarningP, StyledRequiredSpan } from 'pages/public/PassRecovery/PassRecovery.styled'

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import PublicHeader from 'components/PublicHeader';
import CustomModal from 'components/Modal';
import PageTitle from 'components/PageTitle';
import { useTranslation } from 'react-i18next';

const PassRecovery = () => {

  const {t} = useTranslation();
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [modal, setModal] = React.useState({
    isOpen: false,
    message: "",
    type: 'default'
  });

  const onHideModal = () => {
    setModal({...modal, isOpen: false});
  };

  async function handleSubmit(e){
    if(!email.length){
      setEmailError(true);
      return
    }
    else{
      try {
        setEmailError(false)
        await recoveryService(email);
        setModal({
          ...modal, 
          message: t('Error please check email'),
          type: "info",
          isOpen: true,
        });
      } catch (error){
        console.log(error);
      }
    }
  }

  return (
    <>
    <PublicHeader />
      <StyledDivWrapper>
        { modal.isOpen && <CustomModal 
            show={modal.isOpen}
            onHide={onHideModal}
            message={modal.message}
            type={modal.type}
        /> }
        
        <PageTitle title={t('forgot_password_title')}/>
        <Form>
            <p>
             {t('forgot_password_instructions')}
            </p>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>{t('forgot_password_email')}<StyledRequiredSpan>*</StyledRequiredSpan> </Form.Label>
              <Form.Control 
                type="email" 
                placeholder={t('F1_S2_Email')}
                onChange={(e) => setEmail(e.target.value)}
              />
              {  emailError && <StyledWarningP>{t('Error email cannot be empty')}</StyledWarningP>}
            </Form.Group>
            <Button variant="info" onClick={handleSubmit} >
              {t('forgot_password_button')}
            </Button>
        </Form>
      </StyledDivWrapper>
    </>
  )
}

export default PassRecovery