import styled, { css } from 'styled-components';
import Badge from 'react-bootstrap/Badge';
import Image from 'react-bootstrap/Image';

export const StyledBadge = styled(Badge)`
  ${({ theme }) => css`
    position: relative;
    top: 7px;
    left: ${props => props.twodigits ? "-34px" : "-31px"};
    color: ${theme.colors.white};
    font-size: 10px;
  `}
`;

export const StyledImageButton = styled(Image)`
  min-height: 35px;
  min-width: 35px;
  object-fit: fill; 
`;