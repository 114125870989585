import React, { useState, useEffect } from 'react'
import _chunk from 'lodash/chunk'
import { getEntitiesByRoleSummary } from 'services/initiative'
import { useTranslation } from 'react-i18next'

import Header3 from '../components/Header3'
import Table2 from '../components/Table2'
import Row from 'react-bootstrap/Row'
import {
  StyledDiv,
  StyledRow2,
} from '../../CommonStyled/Tabs.styled'
import ExportToXLSXButton from 'components/ExportToXLSXButton'
import { ExportStatisticsByYearsToXLSX } from 'helpers/xlsxStatisticsExporter'
import { T6RenderRows, getEntitiesByRoleSummaryExportValues, getFundsEntitiesByRoleSummaryExportValues } from '../helpers/helpers'
import Table6 from '../components/Table6'
import { getFundsEntitySummaryByRole } from 'services/fundsStatistics'

const EntitiesByRoleSummary = () => {
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const [data2, setData2] = useState(null)
  const [waiting2, setWaiting2] = useState(false)

  const getData = async (filters) => {
    setWaiting(true)
    try {
      const response = await getEntitiesByRoleSummary(filters)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  const getData2 = async (filters) => {
    setWaiting2(true)
    try {
      const response = await getFundsEntitySummaryByRole(filters)
      setData2(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting2(false)
    }
  }


  useEffect(() => {
    getData()
    getData2()
  }, [])

  return (
    <StyledDiv>
      <Row>
        <Header3
          getData={getData}
          getData2={getData2}
          waiting={waiting || waiting2}
          renderExportButton={() => (
            <ExportToXLSXButton
              data={data}
              mapData={getEntitiesByRoleSummaryExportValues}
              data2={data2}
              mapData2={getFundsEntitiesByRoleSummaryExportValues}
              disabled={waiting}
              exportName={t('Statistics_entities_tab1')}
            >
              <ExportStatisticsByYearsToXLSX
                label={t('Statistics_entities_tab1')}
                data={[]}
              />
            </ExportToXLSXButton>
          )}
        />
      </Row>
      <StyledRow2>
        <Table2 data={data} waiting={waiting} setData={setData} />
      </StyledRow2>
      <StyledRow2>{t('Statistics_footnote_3')}</StyledRow2>
      <StyledRow2>
        <Table6
          title={t('country_report_subtitle6')}
          data={data2}
          waiting={waiting2}
          renderRows={T6RenderRows}
          setData={setData2}
          orderField='rol'
          customWidth='61%'
          customWidthRow1='84%'
          customWidthRow2='16%'
          total={true}
        />
      </StyledRow2>
    </StyledDiv>
  )
}

export default EntitiesByRoleSummary
