import React from 'react';
import { Title } from './PageTitle.styled'

const PageTitle = ({ title }) => (
    <Title>
      {title}
    </Title>
  );

export default PageTitle;
