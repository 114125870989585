import React from 'react';
import { observer } from 'mobx-react-lite';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import { useTranslation } from 'react-i18next';
import { ResourceNameSpan } from './Styled';

const Section4 = () => {
  const { t } = useTranslation();

  return (
    <FormLayout>
      <FormContainer
        number={4}
        title={t('F1_S4_title')}
        formId="form55section4"
        showSavedMessage={false}
      >
        <div style={{ marginLeft: '50px'}}>
          <ResourceNameSpan>{t('not_available_for_funds')}</ResourceNameSpan>
        </div>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section4);