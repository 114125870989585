import styled, { css } from 'styled-components';
import Button from 'react-bootstrap/Button';

const BaseButton = styled(Button)`
  text-align: center;
  display: inline-block;
  font-weight: 600;
  border-radius: 16px;
  height: 2em;
  padding: 0px 20px;
  letter-spacing: 1px;
`;

export const PrimaryButton = styled(BaseButton)`
  ${({ theme }) => css`
    background-color: ${theme.brandColors.blue};
    border: ${theme.colorGroups.info.color};
    &:hover {
      background-color: ${theme.colorGroups.info.color};
    }
    &:disabled {
      background-color: white;
      color: ${theme.colors.gray[2]};
      border: 2px solid ${theme.colors.gray[2]};
    }
  `}
`;

export const WarningButton = styled(BaseButton)`
  ${({ theme }) => css`
    color: ${theme.colors.gray[1]};
    background-color: ${theme.brandColors.yellow};
    border: ${theme.colorGroups.warning.color};
    &:hover {
      background-color: ${theme.colorGroups.warning.color};
    }
    &:disabled {
      background-color: white;
      color: ${theme.colors.gray[2]};
      border: 2px solid ${theme.colors.gray[2]};
    }
  `}
`;

export const ErrorButton = styled(BaseButton)`
  ${({ theme }) => css`
    background-color: ${theme.brandColors.red};
    border: ${theme.colorGroups.error.color};
    &:hover {
      background-color: ${theme.colorGroups.error.color};
    }
    &:disabled {
      background-color: white;
      color: ${theme.colors.gray[2]};
      border: 2px solid ${theme.colors.gray[2]};
    }
  `}
`

export const SuccessButton = styled(BaseButton)`
  ${({ theme }) => css`
    background-color: ${theme.brandColors.green};
    border: ${theme.colorGroups.success.bgColor};
    &:hover {
      background-color: ${theme.colorGroups.success.color};
    }
    &:disabled {
      background-color: white;
      color: ${theme.colors.gray[2]};
      border: 2px solid ${theme.colors.gray[2]};
    }
  `}
`
export const  StyledSendButton = styled(PrimaryButton)`
${({ theme }) => css`
  background-color: ${theme.brandColors.lightBlue};
  color: ${theme.colors.white};
  border: none;
  text-align: center;
  display: inline-block;
  font-weight: 600;
  border-radius: 16px;
  margin: -5px 0px;
  width: auto;
  height: 2em;
  padding: 0px 20px;
  letter-spacing: 2px;
  &:hover {
    background-color: ${theme.brandColors.lightBlueHover};
  }
  &:disabled {
    background-color:${theme.brandColors.green};
    color: white;
    border: ${theme.colorGroups.success.bgColor};
    &:hover: none !important;
  }
`}
`;

export const StyledButtonLink = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  box-shadow: none !important;
  margin-bottom: 4px;
`;