import * as Yup from 'yup';
import i18n from 'i18n';

const urlReg = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

export const validationSchema = Yup.object().shape({
  activityId: Yup.string()
    .min(1, 'F1_S2_validation5')
    .required('F5_S7_required'),
  materialTypeId: Yup.string()
    .min(1, 'F1_S2_validation5')
    .required('F5_S7_required'),
  url: Yup.string()
    .min(5, 'F1_S8_validation3')
    .max(200, 'F1_S2_validation8')
    .matches(urlReg, 'F1_S2_validation15')
    .nullable(true),
  description: Yup.string()
    .min(2, 'F1_S2_validation3')
    .max(1000, 'F5_S14_validation1')
    .nullable(true),
});