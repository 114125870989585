import i18n from 'i18n'
import { Th1, Td, Td2 } from '../CommonStyled/Components.styled'
import _map from 'lodash/map'
import _fill from 'lodash/fill'
import _sumBy from 'lodash/sumBy'
import _orderBy from 'lodash/orderBy'
import React from 'react'
import { compareInitiatives } from 'helpers/arrayOrderHelper'

export const total = (array) => {
  const aux = array.filter((item) => item !== '-')
  return aux.reduce((a, b) => a + b, 0)
}

export const renderTotals = (array) => {
  const result = total(array)
  return <Td>{isNaN(result) ? '-' : Intl.NumberFormat('es-AR').format(result)}</Td>
}

export const renderHeaders = (years) => (
  <>
    {years.map((year) => (
      <Th1 scope="col" width="10%">{year}</Th1>
    ))}
    <Th1 scope="col" width="10%">{i18n.t('F1_S7_Total')}</Th1>
  </>
)

export const renderFunctionOptions = () => (
  <>
    <option key={0} value={0}>
      {i18n.t('ALL')}
    </option>
    <option key={1} value={1}>
      {i18n.t('F1_s2_title')}
    </option>
    <option key={2} value={2}>
      {i18n.t('Partner_entity')}
    </option>
    <option key={3} value={3}>
      {i18n.t('Collaborating_entity')}
    </option>
  </>
)

export const renderRoleOptions = () => (
  <>
    <option key={0} value={0}>
      {i18n.t('ALL')}
    </option>
    <option key={1} value={1}>
      {i18n.t('F1_S2_Recipient')}
    </option>
    <option key={2} value={2}>
      {i18n.t('F1_S2_Provider1')}
    </option>
    <option key={3} value={3}>
      {i18n.t('F1_S2_Provider2')}
    </option>
  </>
)

export const renderRegionOptions = () => (
  <>
    <option key={0} value={0}>
      {i18n.t('ALL')}
    </option>
    <option key={1} value={1}>
      {i18n.t('LA')}
    </option>
    <option key={2} value={2}>
      {i18n.t('CA')}
    </option>
    <option key={3} value={3}>
      {i18n.t('EU')}
    </option>
    <option key={4} value={4}>
      {i18n.t('Other')}
    </option>
  </>
)

export const renderYearOptions = (years, allLabel = i18n.t('ALL')) => (
  <>
    <option key={0} value={0}>
      {allLabel}
    </option>
    {years?.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ))}
  </>
)

export const renderTypeOptions = () => (
  <>
    <option key={0} value="*">
      {i18n.t('ALL')}
    </option>
    <option key="INITIATIVES" value="INITIATIVES">
      {i18n.t('type_filter_stat_initiatives')}
    </option>
    <option key="FUNDS" value="FUNDS">
      {i18n.t('type_filter_stat_funds')}
    </option>
  </>
)

export const renderAllODSOptions = (options) => (
  <>
    <option key={0} value={''}>
      {i18n.t('ALL')}
    </option>
    {options?.map((option) => (
      <option key={option?.id} value={option?.id}>
        {i18n.t(`SDG_Goal${option?.id}`)}
      </option>
    ))}
  </>
)

export const renderBenefTypeOptions = (options) => (
  <>
    <option key={0} value={0}>
      {i18n.t('ALL')}
    </option>
    {options?.map((option) => (
      <option key={option?.id} value={option?.id}>
        {i18n.t(`beneficiary_type_${option?.id}`)}
      </option>
    ))}
  </>
)

export const renderEntityTypeOptions = (options) => (
  <>
    <option key={0} value={0}>
      {i18n.t('ALL')}
    </option>
    {options?.map((option) => (
      <option key={option?.id} value={option?.id}>
        {i18n.t(`entity_type_${option?.id}`)}
      </option>
    ))}
  </>
)

export const renderActivitiesOptions = (options) => (
  <>
    <option key={0} value={0}>
      {i18n.t('ALL')}
    </option>
    {options?.map((option) => (
      <option key={option?.id} value={option?.id}>
        {option.code}
      </option>
    ))}
  </>
)

export const renderInitiativeOptions = (options) => (
  <>
    <option key={0} value={''}>
      {i18n.t('ALL')}
    </option>
    {options?.map((option) => (
      <option key={option?.id} value={option?.id}>
        {option.code}
      </option>
    ))}
  </>
)

export const renderInitiativeOptions2 = (options) => (
  <>
    <option key={0} value={''}>
      {i18n.t('initiative_selector_empty')}
    </option>
    {options?.map((option) => (
      <option key={option?.id} value={option?.id}>
        {`${option.code} - ${option.name}`}
      </option>
    ))}
  </>
)

export const renderInitiativeOptions3 = (options) => {
  const orderedOptions = options?.sort(compareInitiatives);
  return (
    <>
      <option key={0} value={''}>
        {i18n.t('initiative_selector_empty_2')}
      </option>
      {orderedOptions?.map((option) => (
        <option key={option?.id} value={option?.id}>
          {`${option.code} - ${option.name}`}
        </option>
      ))}
    </>
  )
}


export const renderFormatOptions = () => (
  <>
    <option key={0} value={''}>
      {i18n.t('ALL')}
    </option>
    <option key={1} value={0}>
      {i18n.t('F1_S7_Format0')}
    </option>
    <option key={2} value={1}>
      {i18n.t('F1_S7_Format1')}
    </option>
    <option key={3} value={2}>
      {i18n.t('F1_S7_Format2')}
    </option>
  </>
)

export const renderTotalForColumns = (rows, years) => {
  let row = []
  let total = 0
  years.forEach((year) => {
    const yearTotal = _sumBy(rows, year)
    total += yearTotal
    row.push(<Td2>{Intl.NumberFormat('es-AR').format(yearTotal)}</Td2>)
  })
  row.push(<Td2>{Intl.NumberFormat('es-AR').format(total)}</Td2>)
  return row
}

export const renderTotalForColumnsForExport = (rows, years) => {
  let row = []
  let total = 0
  years.forEach((year) => {
    const yearTotal = _sumBy(rows, year)
    total += yearTotal
    row.push(Intl.NumberFormat('es-AR').format(yearTotal))
  })
  row.push(Intl.NumberFormat('es-AR').format(total))
  return row
}

export const renderRow = (object, years) => {
  let row = []
  for (let i = 0; i < years.length; i++) {
    row.push(
      <Td>
        {object[years[i]] ? Intl.NumberFormat('es-AR').format(object[years[i]]) : object[years[i]] == 0 ? 0 : '-'}
      </Td>,
    )
  }
  row.push(<Td>{Intl.NumberFormat('es-AR').format(object['total'])}</Td>)
  return row
}

export const orderByDate = (data, field, asc) =>
  _orderBy(
    data,
    [
      (item) => new Date(item[field])?.getFullYear(),
      (item) => new Date(item[field])?.getMonth(),
      (item) => new Date(item[field])?.getDate(),
    ],
    asc[field] ? ['asc', 'asc', 'asc'] : ['desc', 'desc', 'desc'],
  )

export const orderBySring = (data, field, asc) =>
  _orderBy(
    data,
    [(item) => String(item[field])?.replace(/ /g, '').toUpperCase() ],
    asc[field] ? ['asc'] : ['desc'],
  )

export const orderByInitiativeCode = (data, field, asc) =>
  _orderBy(
    data,
    [
      (item) => {
        const array = item[field]?.split('-')
        return parseInt(array[1])
      },
      (item) => {
        const array = item[field]?.split('-')
        return parseInt(array[0].substring(3, array[0].length))
      },
    ],
    asc[field] ? ['asc', 'asc'] : ['desc', 'desc'],
  )
