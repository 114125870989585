import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActivitySubItem, CompleteImage } from 'components/Forms/Form5/Section7/ActivityItem/ActivityItem.styled';
import completeIcon from 'assets/complete-mark.png';

const ActivityItem = ({ activity }) => {
  const {t} = useTranslation();

  return (
    <div>
      <ActivitySubItem> {t('F3_S7_Subtitle_General_Information')} {(activity.general_info_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>
      <ActivitySubItem> {t('F3_S7_Subtitle_Products')} {(activity.product_list_complete) &&  <CompleteImage src={completeIcon} />}</ActivitySubItem>
      {(activity.activityType && activity.activityType.id == 4 ) && <ActivitySubItem> {t('F3_S7_Subtitle_Consultants')} {(activity.profile_list_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>}
      {(activity.activityType && activity.activityType.id != 4 ) && <ActivitySubItem> {t('F3_S7_Subtitle_Participants_assistance')} {(activity.participant_list_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>}
      <ActivitySubItem> {t('F5_S9_Subtitle_consolidated_budget')} <CompleteImage src={completeIcon}/> </ActivitySubItem>
      <ActivitySubItem> {t('F5_S9_Subtitle_executed_budget')} {(activity.resource_list_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>
      <ActivitySubItem> {t('F5_S7_Subtitle_activity_assessment')} {(activity.assessment_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>
    </div>
  )

}

export default ActivityItem;
