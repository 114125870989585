import React, { useState, useEffect } from 'react';
import { Formik, FieldArray } from 'formik';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { saveFormSection } from 'services/initiative';
import { useConstantsForm1 } from 'customHooks/useConstantsForm1';
import { f5statusNamesConst, roleNamesConst } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { validationSchema } from './Section4.validations';
import Comments from '../Comments';

import FormLayout from '../../FormLayout';
import Accordion from 'react-bootstrap/Accordion';
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from 'components/CustomAccordion';
import FormContainer from 'components/FormContainer';
import { PrimaryButton, ErrorButton } from 'components/Buttons';
import { SeparatorDotted } from 'components/CommonStyled';
import { StyledDiv } from './Section4.styled';
import ModalConfirm from 'components/ModalConfirm';
import OtherEntitiesForm from './OtherEntitiesForm';
import { FormFielErrorMessage } from 'components/CommonStyled';
import { arrayOrderHelper } from 'helpers/arrayOrderHelper';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const entityObject = {
  entityName: '',
  acronym: '',
  entityTypeId: '',
  function: '',
  countryId: '',
  web: '',
  fromF3: false,
  number: '',
};

const formInitialValues = {
  entities: [],
};

const Section4 = () => {
  const { t } = useTranslation();
  const { formStore, initiativeStore, authStore } = useRootStore();
  const { id, formId, sectionId } = useParams();
  const { countries, entityTypes } = useConstantsForm1();
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const currentRole = authStore.roles[0];
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM;
  const isAtke = currentRole === roleNamesConst.ATKE;
  const isIntpa = currentRole === roleNamesConst.INTPA;
  const isEud = currentRole === roleNamesConst.EUD;
  const [isF7, setIsF7] = useState(false);
  const [disabledComments, setDisabledComments] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const arr = [];
    if (formStore.formSectionData) {
      formStore.formSectionData.forEach(element => {
        arr.push({
          entityName: setStringFieldValue(element.entityName),
          acronym: setStringFieldValue(element.acronym),
          entityTypeId: element.entityType ? element.entityType.id : '',
          function: setStringFieldValue(element.function),
          countryId: element.country ? element.country.id : '',
          web: setStringFieldValue(element.web),
          fromF3: element.fromF3,
          firstName: setStringFieldValue(element.firstName),
          lastName: setStringFieldValue(element.lastName),
          phone: setStringFieldValue(element.phone),
          email: setStringFieldValue(element.email),
          position: setStringFieldValue(element.position),
          number: setStringFieldValue(element.number),
        });
      });
      setInitialValues({
        entities: arrayOrderHelper(arr),
      });
    } else {
      setInitialValues(formInitialValues);
    }
  }, [formStore.formSectionData]);

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f5statusNamesConst.DRAFT ||
        initiativeStore.role !== roleNamesConst.APPLICANT,
    );
  }, [initiativeStore.formStateName, initiativeStore.role]);

  useEffect(() => {
    if (initiativeStore.formStateName)
      setIsF7(
        (isIntpa || isEud) &&
          initiativeStore.formStateName !== f5statusNamesConst.DRAFT &&
          initiativeStore.formStateName !== f5statusNamesConst.SENT,
      );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  const openModalRemove = key => {
    setModalRemove(true);
    setSelectedKey(key);
  };

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  };

  const onSubmit = async (values, { resetForm }) => {
    try {
      for (let i = 0; i< values.entities.length; i++) {
        values.entities[i].number = `${i}`;
      }
      const response = await saveFormSection(id, formId, sectionId, [...values.entities]);
      let bool = true;
      if (response) {
        response.forEach(element => {
          bool = bool && element.complete;
        });
      }
      formStore.setSectionStatus(sectionId, bool || !response.length);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const setStringFieldValue = newValue => {
    return newValue ? newValue : '';
  };

  const onValidateSubmit = (e, isValid, values, resetForm) => {
    e.preventDefault();
    isValid ? onSubmit(values, { resetForm }) : alert(t('not_saved_alert'));
  };

  const handleSaved = value => {
    setSaved(value);
  };

  const handleButton = value => {
    setDisabledComments(value);
  };

  return (
    <FormLayout>
      <FormContainer
        number={4}
        title={t('F1_S4_title')}
        formId="form5section4"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
        showButtonClarif={!disabledComments}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {props => (
            <form
              onSubmit={e => {
                onValidateSubmit(e, props.isValid, props.values, props.resetForm);
              }}
              id="form5section4"
            >
              {props.dirty && setSaved(false)}

              <FieldArray
                name="entities"
                render={arrayHelpers => (
                  <>
                    {modalRemove && (
                      <ModalConfirm
                        show={modalRemove}
                        confirmText={t('Button_remove')}
                        onConfirm={() => {
                          arrayHelpers.remove(selectedKey);
                          closeModalRemove();
                        }}
                        onClose={closeModalRemove}
                      >
                        <p>{t('Confirmation remove entity') + ' ' + (selectedKey + 1)}?</p>
                      </ModalConfirm>
                    )}
                    <Accordion style={{ marginLeft: '32px' }}>
                      {initialValues.entities
                        ? props.values.entities.map((entity, key) => (
                          <AccordionItem key={key + 1}>
                            <AccordionItemHeader>
                              <AccordionTitle eventKey={key + 1}>
                                {t('F1_S4_Accordion') +
                                    ' ' +
                                    (key + 1) +
                                    ' - ' +
                                    entity.entityName}
                              </AccordionTitle>
                            </AccordionItemHeader>
                            <Accordion.Collapse eventKey={key + 1}>
                              <AccordionItemBody>
                                <OtherEntitiesForm
                                  formikInstance={props}
                                  entityIndex={key}
                                  entityTypes={entityTypes}
                                  countries={countries}
                                  nonEditable={nonEditable}
                                />
                                {!entity.fromF3 && (
                                  <ErrorButton
                                    onClick={() => openModalRemove(key)}
                                    disabled={nonEditable}
                                  >
                                    {t('F1_S4_Button_remove_entity')}
                                  </ErrorButton>
                                )}
                              </AccordionItemBody>
                            </Accordion.Collapse>
                          </AccordionItem>
                        ))
                        : null}
                    </Accordion>

                    <StyledDiv>
                      <FormErrorMessage
                        touched
                        errors={props.errors.entities}
                      />
                      <SeparatorDotted />

                      <p>{t('F5_S4_Add_participant_instructions')}</p>
                      <br />

                      <PrimaryButton
                        onClick={() => arrayHelpers.push(entityObject)}
                        disabled={nonEditable}
                      >
                        {t('F1_S4_Button_add_entity')}
                      </PrimaryButton>
                    </StyledDiv>
                  </>
                )}
              />
            </form>
          )}
        </Formik>

        {(isAtke || isConsortium || isF7) && (
          <div style={{ marginLeft: '50px' }}>
            <Comments onSaved={handleSaved} onButton={handleButton} />
          </div>
        )}
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section4);
