import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportBeneficiariesDetailToXLSX } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'

import {
  getCountries,
  getImplementationInitiatives,
  getBenTypes,
} from 'services/initiative'

import { Form } from 'react-bootstrap'
import { PrimaryButton } from 'components/Buttons'
import {
  Col1,
  Col2,
  StyledFormGroup,
  StyledFormGroup2,
  StyledFormLabel,
  FullRow,
} from '../../CommonStyled/Components.styled'

import { renderCountriesOptions } from 'helpers/renderCountriesHelper'
import { renderStatesOptions } from 'helpers/renderStatesHelper'
import { renderYears } from 'helpers/dateHelpers'
import { renderBenefTypeOptions, renderTypeOptions } from '../../CommonHelpers/helpers'
import { getBeneficiariesDetailExportValues } from '../helpers/helpers'
import { renderInitiativeOptions } from '../../CommonHelpers/helpers'

const Header1 = ({ data, waiting, getData }) => {
  const { t } = useTranslation()
  const [countriesOptions, setCountriesOptions] = useState([])
  const [initiativesOptions, setInitiativesOptions] = useState([])
  const [benTypesOptions, setBenTypesOptions] = useState([])

  const [countryFilter, setCountryFilter] = useState('')
  const [regionsFilter, setRegionsFilter] = useState('')
  const [yearsFilter, setYearsFilter] = useState(0)
  const [beneficiariesFilter, setBeneficiariesFilter] = useState('')
  const [initiativesFilter, setInitiativesFilter] = useState('')
  const [typeFilter, setTypeFilter] = useState('*')

  useEffect(() => {
    getCountriesOptions()
    getInitiativesOptions()
    getBenTypesOptions()
  }, [])

  const getCountriesOptions = async () => {
    try {
      const response = await getCountries()
      setCountriesOptions(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getInitiativesOptions = async () => {
    try {
      const response = await getImplementationInitiatives()
      setInitiativesOptions(response)
    } catch (error) {
      console.log(error)
    }
  }

  const getBenTypesOptions = async () => {
    try {
      const response = await getBenTypes()
      setBenTypesOptions(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilter = () => {
    let param = {}
    if (countryFilter && countryFilter != 0) param.countryId = countryFilter
    if (regionsFilter && regionsFilter != 0) param.region = regionsFilter
    if (yearsFilter && yearsFilter != 0) param.year = yearsFilter
    if (beneficiariesFilter && beneficiariesFilter != 0)
      param.typeId = beneficiariesFilter
    if (initiativesFilter && initiativesFilter != 0)
      param.initiativeId = initiativesFilter
    if (typeFilter) {
      if (typeFilter === '*') {
        param.includeFunds = true;
        param.includeInitiatives = true;
      }
      if (typeFilter === 'FUNDS') {
        param.includeFunds = true;
        param.includeInitiatives = false;
      }
      if (typeFilter === 'INITIATIVES') {
        param.includeFunds = false;
        param.includeInitiatives = true;
      }
    }
    getData(param)
  }

  return (
    <>
      <FullRow>
        <Col1>
          <StyledFormGroup2>
            <StyledFormLabel>{t('initiative_project')}</StyledFormLabel>
            <Form.Control
              name="initiativeId"
              as="select"
              onChange={(e) => {
                setInitiativesFilter(e.target.value)
              }}
              value={initiativesFilter}
            >
              {renderInitiativeOptions(initiativesOptions)}
            </Form.Control>
          </StyledFormGroup2>
          <StyledFormGroup style={{ width: '35%' }}>
            <StyledFormLabel>{t('F3_S10_Beneficiary')}</StyledFormLabel>
            <Form.Control
              name="typeId"
              as="select"
              onChange={(e) => {
                setBeneficiariesFilter(e.target.value)
              }}
              value={beneficiariesFilter}
            >
              {renderBenefTypeOptions(benTypesOptions)}
            </Form.Control>
          </StyledFormGroup>
          <StyledFormGroup2>
            <StyledFormLabel>{t('F1_S2_Country')}</StyledFormLabel>
            <Form.Control
              name="countryId"
              as="select"
              onChange={(e) => {
                setCountryFilter(e.target.value)
              }}
              value={countryFilter}
            >
              {renderCountriesOptions(countriesOptions)}
            </Form.Control>
          </StyledFormGroup2>
          <StyledFormGroup2 style={{ marginRight: '0px' }}>
            <StyledFormLabel>{t('Region')}</StyledFormLabel>
            <Form.Control
              name="regionId"
              as="select"
              onChange={(e) => {
                setRegionsFilter(e.target.value)
              }}
              value={regionsFilter}
            >
              {renderStatesOptions()}
            </Form.Control>
          </StyledFormGroup2>
        </Col1>
      </FullRow>
      <FullRow>
        <Col1 xs={6}>
          <StyledFormGroup style={{ width: '20%' }}>
            <StyledFormLabel>{t('Dashboard_Year_2')}</StyledFormLabel>
            <Form.Control
              name="year"
              as="select"
              onChange={(e) => {
                setYearsFilter(e.target.value)
              }}
              value={yearsFilter}
            >
              {renderYears()}
            </Form.Control>
          </StyledFormGroup>

          <StyledFormGroup style={{ width: '50%' }}>
            <StyledFormLabel>{t('type_filter_stat')}</StyledFormLabel>
            <Form.Control
              name="type"
              as="select"
              onChange={(e) => {
                setTypeFilter(e.target.value)
              }}
              value={typeFilter}
            >
              {renderTypeOptions()}
            </Form.Control>
          </StyledFormGroup>

          <PrimaryButton
            onClick={() => {
              handleFilter()
            }}
            disabled={waiting}
          >
            {t('Button_search')}
          </PrimaryButton>
        </Col1>
        <Col2>
          <ExportToXLSXButton
            data={data}
            mapData={getBeneficiariesDetailExportValues}
            disabled={waiting}
            exportName={t('Statistics_beneficiaries_tab1')}
          >
            <ExportBeneficiariesDetailToXLSX data={data} />
          </ExportToXLSXButton>
        </Col2>
      </FullRow>
    </>
  )
}

export default Header1
