import React, { useEffect, useState } from 'react'
import { Formik, FieldArray } from 'formik';
import { formInitialValues } from './ObjectiveForm.initialValues'
import { validationSchema } from './ObjectiveForm.validations';
import { saveF5S11Goal } from 'services/initiative';
import { useConstantsForm3 } from 'customHooks/useConstantsForm3';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'stores';

import ConfirmCloseModal from 'components/ConfirmCloseModal';
import TargetForm from '../TargetForm';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion'
import TextValue from 'components/TextValue';
import Modal from 'react-bootstrap/Modal';
import { PrimaryButton } from 'components/Buttons';
import Row from 'react-bootstrap/Row';
import {ErrorButton, StyledSendButton} from 'components/Buttons'
import { Form } from 'react-bootstrap'
import { CenteredModalButtonContainer, SeparatorDotted } from 'components/CommonStyled';
import {
  StyledModalBody,
  ModalTitle,
} from './ObjectiveForm.styled'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const targetObject = {
  targetGoalId: 1,
  targetJustification: '',
  targetIndicatorList: null,
};

const ObjectiveForm = ({show, handleClose, handleCloseOk, initiativeId, goalId, title, data, nonEditable}) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const { formStore } = useRootStore();
  const { developmentGoalTargets, developmentGoalTargetIndicators } = useConstantsForm3();
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);

  const openModalRemove = (key) => {
    setSelectedKey(key);
    setModalRemove(true);
  }

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  }

  useEffect(() => {
    if(data !== null){
      setInitialValues({
        goal: data.goal,
        justification: data.justification,
        targetList: data.targetList,
      });
    }else
      setInitialValues(formInitialValues);
  }, [data])

  const onSave = async (values) => {
    try {
      const response = await saveF5S11Goal(initiativeId, goalId, values.targetList);
      formStore.setFormSectionData([
        {
          ...response
        },
        formStore.formSectionData[1]
      ]);
      handleCloseOk();
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values) => {
    e.preventDefault();
    isValid
      ? onSave(values)
      : alert(t('not_saved_alert'));
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" animation={false}>
      <StyledModalBody>

        <ModalTitle>{title}</ModalTitle>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSave}
        >
          {(props) => (
            <form
              id="objectiveForm"
              onSubmit={(e) => {onValidateSubmit(e, props.isValid, props.values)}}
            >
              <Form.Group as={Row}>
                <Form.Label column xs={6}>{title}:</Form.Label>
                <TextValue value={props.values.goal? t(`SDG_Goal${props.values.goal.id}`): " - "} />
              </Form.Group>

              <Form.Group>
                <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}} >{t('F3_S11_Justification')}: </Form.Label>
                <Form.Control
                  name="justification"
                  as="textarea"
                  rows={3}
                  placeholder={t('Maximum_650')}
                  style={{resize: 'none'}}
                  value={props.values.justification? props.values.justification: '-'}
                  disabled={true}
                />
              </Form.Group>

              <FieldArray
                name='targetList'
                render={arrayHelpers => (
                  <>
                    {modalRemove && (
                      <ConfirmCloseModal
                        show={modalRemove}
                        text={`${t('Delete_target')} ${selectedKey+1}?`}
                        handleActionOnConfirm={() => {
                          arrayHelpers.remove(selectedKey);
                          closeModalRemove();
                        }}
                        handleCloseConfirm={closeModalRemove}
                      />
                    )}
                    <Accordion style={{marginLeft: '-20px'}}>
                      {props.values.targetList && props.values.goal
                        ? ( props.values.targetList.map((target, key) => (
                            <AccordionItem key={key+1}>
                              <AccordionItemHeader>
                                <AccordionTitle eventKey={key+1}>{t('F3_S11_Target')} {key+1}</AccordionTitle>
                              </AccordionItemHeader>
                              <Accordion.Collapse eventKey={key+1}>
                                <AccordionItemBody>
                                  <TargetForm formikInstance={props} targetIndex={key} devGoalTargets={developmentGoalTargets} indicatorsList={developmentGoalTargetIndicators} nonEditable={nonEditable}/>
                                  <ErrorButton onClick={() => openModalRemove(key, arrayHelpers)} disabled={nonEditable}>{t('F3_S11_Target_button_delete')}</ErrorButton>
                                </AccordionItemBody>
                              </Accordion.Collapse>
                            </AccordionItem>
                          )))
                        : null}
                    </Accordion>
                    <FormErrorMessage
                      touched
                      errors={props.errors.targetList}
                    />

                    <SeparatorDotted />
                    <PrimaryButton onClick={() => arrayHelpers.push(targetObject)} disabled={nonEditable}>{t('F3_S11_Target_button_add')}</PrimaryButton>
                  </>
                )}
              />

              <SeparatorDotted />

              <CenteredModalButtonContainer>
              {
                nonEditable
                ?
                  <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
                :
                  <>
                    <ErrorButton style={{marginRight: '20px'}} onClick={handleClose} disabled={nonEditable}> {t('Button_cancel')} </ErrorButton>
                    <StyledSendButton type="submit" form="objectiveForm" disabled={nonEditable}> {t('Button_save')} </StyledSendButton>
                  </>
              }
              </CenteredModalButtonContainer>
            </form>
          )}
        </Formik>
      </StyledModalBody>
    </Modal>
  )
}

export default ObjectiveForm;