import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  productsConfirmed: Yup.boolean(),
  productList: Yup.array()
    .of(
      Yup.object().shape({
        productType: Yup.object(),
        name: Yup.string(),
        dueDate: Yup.date(),
        description: Yup.string(),
        comments: Yup.string()
          .min(2, 'F3_S7_validation4')
          .max(650, 'F1_S6_validation9')
          .nullable(true)
          .test('commentsCheck', 'Error field cannot be empty', function (value) {
            if(this.options.context.productsConfirmed && !this.parent.comments) return false
            return true }),
        media: Yup.object().nullable(),
      })
    ),
});