import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';

import { SeparatorDotted } from 'components/CommonStyled';
import LessonForm from './LessonForm';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody 
} from '../CommonStyles'
import FakeAccordionItem from '../../FakeAccordionItem';

const Section15 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState([]);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data]);

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`15 - ${t('F5_S15_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        {(formValues && formValues.map((lesson, key) => (
          <React.Fragment  key={key}>
            <FakeAccordionItem title={t('F5_S15_Accordion_lesson_learnt')+" "+(key+1)}>
              <LessonForm data={lesson} /> 
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}
      </StyledBody>
    </SectionContainer>
  )
}

export default Section15;


