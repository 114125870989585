import React from 'react';
import { useRootStore } from 'stores';
import { f1statusNamesConst } from 'constants/appConstants';
import DayJS from 'dayjs';
import { useTranslation } from 'react-i18next';
import { StyledTr, StyledButtonLink } from './TableRow.styled';
import { useHistory } from 'react-router-dom';
import NewMessagesTableIndicator from 'components/NewMessagesTableIndicator';
import { NewMessagesTableContainer } from 'components/Forms/Forms.styled';

const TableRow = ({ item, onModalData, closedWindow }) => {
  const { t } = useTranslation();
  const { initiativeStore } = useRootStore();
  let history = useHistory();

  const setInitiativeOnStore = initiative => {
    initiativeStore.setInitiativeData(initiative);
    if (
      initiative.formStateName === f1statusNamesConst.DRAFT &&
      initiative.formVersion == 1 &&
      closedWindow
    )
      onModalData({ isShow: true, id: initiative.id });
    else history.push(`/applicant/initiative/${item.id}/form/1/section/1`);
  };

  const formatDate = dateStr => {
    const date = DayJS(dateStr);
    return date.format('DD/MM/YYYY');
  };

  return (
    <>
      <StyledTr key={item.id}>
        <td style={{ minWidth: '100px' }}>
          <StyledButtonLink
            variant="link"
            onClick={() => {
              setInitiativeOnStore(item);
            }}
          >
            {item.code}
          </StyledButtonLink>
        </td>
        <td style={{ maxWidth: '600px', overflowWrap: 'break-word' }}>
          {item.name ? item.name : t('Name_not_defined_yet')}
        </td>
        <td>{item.statusTimeStamp ? formatDate(item.statusTimeStamp) : ' - '}</td>
        <td style={{ minWidth: '100px' }}>
          {t(item.formStateName) +
            (item.formStateName === f1statusNamesConst.DRAFT ||
            item.formStateName === f1statusNamesConst.SENT
              ? ' v' + item.formVersion
              : '')}
        </td>
        <td> {item.formDeadline ? formatDate(item.formDeadline) : '-'} </td>
        <td>
          <NewMessagesTableContainer>
            <NewMessagesTableIndicator hasNewMessages={item.unreadComments > 0} />
          </NewMessagesTableContainer>
        </td>
      </StyledTr>
    </>
  );
};

export default TableRow;
