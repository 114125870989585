import styled, {css} from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  margin: 10px 0px;
`;

export const StyledNumberCircle = styled.div`
  ${({ theme, status }) => {
    const circleColor = {
      'NEW': theme.colors.gray[3],
      'INCOMPLETE': theme.colors.gray[3],
      'COMPLETE': theme.brandColors.green,
      'DONE': theme.brandColors.green,
      'CLARIFICATION': theme.brandColors.red
    }
    return css`
    min-width: 1.8rem;
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${status ? circleColor[status] : circleColor['NEW']};
    color: ${theme.colors.white};
    border-radius: 50%;
  `}}
`
export const StyledItemTitle = styled.span`
  ${({ theme, current }) => css`
    font-weight: 600;
    color: ${current ? theme.brandColors.blue : theme.colors.gray[2]};
    margin-left: 10px;
  `}
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`