import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { roleNamesConst, f5statusNamesConst } from 'constants/appConstants'
import { formInitialValues } from './Section3.initialValues'
import { useTranslation } from 'react-i18next'
import Accordion from 'react-bootstrap/Accordion'
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from 'components/CustomAccordion'
import { PrimaryButton } from 'components/Buttons'

import { useRootStore } from 'stores'

import ChangeCoApplicantModal from './ChangeCoApplicantModal'
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer'
import CoapplicantForm from './CoapplicantForm'
import CustomModal from 'components/Modal'
import Comments from '../Comments'
import { arrayOrderHelper } from 'helpers/arrayOrderHelper'
import { addUserHelperF5S3 } from 'helpers/addUserHelper'
import AddedPersonsList from 'components/AddedPersonsList/AddedPersonsList'
import AddPersonForm from 'components/AddPersonForm'

const Section3 = () => {
  const { formStore, authStore, initiativeStore } = useRootStore()
  const { t } = useTranslation()

  const [formValues, setFormValues] = useState(formInitialValues)

  const currentRole = authStore.roles[0]
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM
  const isAtke = currentRole === roleNamesConst.ATKE
  const isIntpa = currentRole === roleNamesConst.INTPA
  const isEud = currentRole === roleNamesConst.EUD
  const [isF7, setIsF7] = useState(false)
  const [saved, setSaved] = useState(false)
  const [disabledComments, setDisabledComments] = useState(true)
  const [isModalError, setIsModalErrorOpen] = useState(false)
  const [isModalReplacePerson, setIsModalReplacePerson] = useState(false)

  const [addedUsersList, setAddedUsersList] = useState([])

  const [disabledAddUser, setDisabledAddUser] = useState(false)

  const [oldEmail, setOldEmail] = useState('')

  const [modalDataAddPerson, setModalDataAddPerson] = useState({
    show: false,
    isF5S2: false,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = [...formStore?.formSectionData].slice(0, -1);
      setFormValues(arrayOrderHelper(formSectionData))
      const addedUsers = addUserHelperF5S3(formStore.formSectionData)
      setDisabledAddUser(addedUsers.disableButton)
      setAddedUsersList(addedUsers.list)
    }
  }, [formStore.formSectionData])

  useEffect(() => {
    if (initiativeStore.formStateName)
      setIsF7(
        (isIntpa || isEud) &&
          initiativeStore.formStateName !== f5statusNamesConst.DRAFT &&
          initiativeStore.formStateName !== f5statusNamesConst.SENT,
      )
  }, [initiativeStore.formStateName])

  const handleSaved = (value) => {
    setSaved(value)
  }

  const handleButton = (value) => {
    setDisabledComments(value)
  }

  const handleReplacePersonButton = (email) => {
    setOldEmail(email)
    setIsModalReplacePerson(true)
  }

  return (
    <FormLayout>
      {modalDataAddPerson.show && (
        <AddPersonForm
          show={modalDataAddPerson.show}
          onHide={() => {
            setModalDataAddPerson({ show: false, isF5S2: false })
          }}
          isF5S2={modalDataAddPerson.isF5S2}
        />
      )}

      <ChangeCoApplicantModal
        handleCloseOk={() => {
          setIsModalReplacePerson(false)
        }}
        show={isModalReplacePerson}
        handleCloseOnError={() => {
          setIsModalErrorOpen(true)
          setIsModalReplacePerson(false)
        }}
        oldEmail={oldEmail}
      />

      <CustomModal
        show={isModalError}
        onHide={() => setIsModalErrorOpen(false)}
        message={t('Error try again later')}
        type="error"
      />

      <FormContainer
        number={3}
        title={t('F1_S3_title')}
        formId="form5section3"
        showSavedMessage={saved}
        showButtonClarif={!disabledComments}
      >
        <Accordion style={{ paddingLeft: '35px' }}>
          {formValues &&
            formValues.map((coapplicant, key) => (
              <AccordionItem key={key + 1}>
                <AccordionItemHeader>
                  <AccordionTitle eventKey={key + 1}>{`${t(
                    'F1_S3_Accordion',
                  )} ${key + 1} - ${coapplicant.entityName}`}</AccordionTitle>
                </AccordionItemHeader>
                <Accordion.Collapse eventKey={key + 1}>
                  <AccordionItemBody>
                    <CoapplicantForm
                      coapplicantIndex={key}
                      data={coapplicant}
                      handleReplacePersonButton={handleReplacePersonButton}
                      isAtke={isAtke}
                    />
                  </AccordionItemBody>
                </Accordion.Collapse>
              </AccordionItem>
            ))}
        </Accordion>

        {(isAtke || isConsortium) && (
          <>
            <AddedPersonsList addedUsersList={addedUsersList} s2={false} />
            <div style={{ marginLeft: '50px', marginTop: '30px' }}>
              <PrimaryButton
                disabled={disabledAddUser}
                onClick={() => {
                  setModalDataAddPerson({ ...modalDataAddPerson, show: true })
                }}
              >
                {t('Add_person_button')}
              </PrimaryButton>
            </div>
          </>
        )}

        {(isAtke || isConsortium || isF7) && (
          <div style={{ marginLeft: '50px' }}>
            <Comments onSaved={handleSaved} onButton={handleButton} />
          </div>
        )}
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section3)
