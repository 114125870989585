import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledNumberCircle, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  StyledFormLabel,
  TextValueLeftSpaced,
} from 'components/Forms/Form2/Section5/Section5.styled';
import Form from 'react-bootstrap/Form';
import TextValue from 'components/TextValue';

const Section5 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledNumberCircle> 5 </StyledNumberCircle>
          <StyledTitle> {t('F1_S5_title')} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <Form.Group>
          <StyledFormLabel style={{textAlign: 'justify', lineHeight: '1.3'}}>{t('F1_S5_Provide_an_overview')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.overview} />
          </TextValueLeftSpaced>
        </Form.Group>
      </StyledBody>
    </SectionContainer>
  )
}


export default Section5;