import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { PrimaryButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';

const VerticallyCenteredModal = (props) => {
  const {t} = useTranslation();

    return (
      <Modal
        {...props}
        centered
        dialogClassName="my-modal"
        size= "lg"
        scrollable={true}
        animation={false}
      >
        <Modal.Body style={{height: "80vh", width: "70vw !important"}}>

        {htmlParser(props.data)}
        
        </Modal.Body>
        <Modal.Footer>
        <PrimaryButton onClick={props.onHide}>{t('Button_close')}</PrimaryButton>
       </Modal.Footer>
      </Modal>
    );
  }

  export default VerticallyCenteredModal; 