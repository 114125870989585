import Form5Section1 from 'components/Forms/Form5/Section1';
import Form5Section2 from 'components/Forms/Form5/Section2';
import Form5Section3 from 'components/Forms/Form5/Section3';
import Form5Section4 from 'components/Forms/Form5/Section4';
import Form5Section5 from 'components/Forms/Form5/Section5';
import Form5Section6 from 'components/Forms/Form5/Section6';
import Form5Section7 from 'components/Forms/Form5/Section7';
import Form5Section8 from 'components/Forms/Form5/Section8';
import Form5Section9 from 'components/Forms/Form5/Section9';
import Form5Section10 from 'components/Forms/Form5/Section10';
import Form5Section11 from 'components/Forms/Form5/Section11';
import Form5Section12 from 'components/Forms/Form5/Section12';
import Form5Section13 from 'components/Forms/Form5/Section13';
import Form5Section14 from 'components/Forms/Form5/Section14';
import Form5Section15 from 'components/Forms/Form5/Section15';
import Form5Section16 from 'components/Forms/Form5/Section16';
import Form5Section17 from 'components/Forms/Form5/Section17';
import Form5Section18 from 'components/Forms/Form5/Section18';

export const form5Dictionary = {
  1: <Form5Section1 />,
  2: <Form5Section2 />,
  3: <Form5Section3 />,
  4: <Form5Section4 />,
  5: <Form5Section5 />,
  6: <Form5Section6 />,
  7: <Form5Section7 />,
  8: <Form5Section8 />,
  9: <Form5Section9 />,
  10: <Form5Section10 />,
  11: <Form5Section11 />,
  12: <Form5Section12 />,
  13: <Form5Section13 />,
  14: <Form5Section14 />,
  15: <Form5Section15 />,
  16: <Form5Section16 />,
  17: <Form5Section17 />,
  18: <Form5Section18 />
}