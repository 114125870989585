import React, {useState} from 'react';
import { observer } from 'mobx-react-lite';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';

import Modal from 'react-bootstrap/Modal';
import { ModalFooter, FooterLeft, FooterCenter, FooterRight } from './SendDialog.styled';
import { ErrorButton, PrimaryButton } from 'components/Buttons';

const SendDialog = ({ displayDialog, isIntpa, intpaDecision = false, onSend, onCloseModal, closeOnError }) => {
  const [sending, setSending] = useState(false);
  const { t } = useTranslation();

  const sendAction = async () => {
    try {
      setSending(true);
      isIntpa? await onSend(intpaDecision) : await onSend();
      onCloseModal();
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  }

  return (
    <>


      <Modal show={displayDialog} onHide={onCloseModal} size="lg" animation={false}>
        <Modal.Body>
          <>
          { isIntpa?
            <>
              {`${t('F2c_final_confirmation_to_send_part1')} ${intpaDecision? t('Approve'): t('Reject')} ${t('F2c_final_confirmation_to_send_part2')}`}
              <br/>
              {`${t('F2c_final_confirmation_to_send_part3')} ${intpaDecision? t('Approve'): t('Reject')} ${t('F2c_final_confirmation_to_send_part4')}`} 
            </>
            :
            <>
              {htmlParser(t('F2ab_confirmation_to_send'))}
            </>
          }
          </>
        </Modal.Body>
        <ModalFooter>
          <FooterLeft />
          <FooterCenter>
            <PrimaryButton disabled={sending} onClick={() => sendAction()}>{t('Yes')}</PrimaryButton>
            <ErrorButton disabled={sending} onClick={onCloseModal}>{t('No')}</ErrorButton>
          </FooterCenter>
          <FooterRight />
        </ModalFooter>
      </Modal>
    </>
  )
}

SendDialog.propTypes = {
  displayDialog: Proptypes.bool,
  isIntpa: Proptypes.bool,
  intpaDecision: Proptypes.bool,
  onSend: Proptypes.func,
  onCloseModal: Proptypes.func,
  closeOnError: Proptypes.func,
};

export default observer(SendDialog);
