import React, { useEffect, useState } from 'react';
import { formInitialValues } from './IntpaForm.initialValues';
import { useTranslation } from 'react-i18next';
import { getF9cById } from 'services/initiative';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { parseDateString } from 'helpers/dateHelpers';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ApplicantF8a from '../ApplicantF8ReadOnly';
import F8bReadOnly from '../F8bReadOnly';
import { StyledLabel, StyledTitle } from '../ExpostReportForm.styles';
import EUDAssessments from './EUDAssessments';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const IntpaForm = ({ setSaved, saved, nonEditable, setNonEditable, setFormInitialValues }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(formInitialValues);
  const { formId } = useParams();
  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    resetForm,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    async function fetchAPI() {
      try {
       const response = await getF9cById(formId);
       setFormData(response);
       setFormInitialValues({
         ...response.form9c,
         dateNewExPost: (response.form9c.dateNewExPost ? parseDateString(response.form9c.dateNewExPost) : ""),
        });
       setNonEditable(response.form8b.status.stateEnum !== 'DONE' || response.form9c.status.stateEnum === 'DONE' || response.form9c.status.stateEnum === 'CANCELED');
      } catch (error) {
        console.log(error)
      }
    };
    fetchAPI();
  }, [])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);
  },[saved])

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid
      ? handleSubmit(values, { resetForm })
      : alert(t('not_saved_alert'));
  }

  return (
    <form
        onSubmit={(e) => {onValidateSubmit(e, isValid, values, { resetForm })}}
        id="form9c"
    >
          {dirty && (setSaved(false))}
          <ApplicantF8a formData={formData.form8} />

          <F8bReadOnly formData={formData.form8b} />

          <StyledTitle style={{marginTop: '40px'}}>{t('F9c_title_2')}:</StyledTitle>
            <EUDAssessments
              assessmentList={formData.form9aList}
              nonEditable={nonEditable}
            />

          <StyledTitle style={{marginTop: '40px'}}>{t('F9c_title_1')}:</StyledTitle>

          <Form.Group>
            <StyledLabel>{t('F9c_assessment_1')}:</StyledLabel>
              <Form.Control
                as="textarea"
                name="comment1"
                rows={3}
                style={{resize: 'none'}}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.comment1}
                className={touched.comment1 && errors.comment1 ? "error" : null}
                disabled={nonEditable}
              />
              <FormErrorMessage
                touched={touched.comment1}
                errors={errors.comment1}
              />
          </Form.Group>

          <Form.Group>
            <StyledLabel>{t('F9c_assessment_2')}:</StyledLabel>
                <Form.Control
                  as="textarea"
                  name="comment2"
                  rows={3}
                  style={{resize: 'none'}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.comment2}
                  className={touched.comment2 && errors.comment2 ? "error" : null}
                  disabled={nonEditable}
                />
                <FormErrorMessage
                  touched={touched.comment2}
                  errors={errors.comment2}
                />
          </Form.Group>

          <Form.Group>
            <StyledLabel>{t('F9c_assessment_3')}:</StyledLabel>
                <Form.Control
                  as="textarea"
                  name="assessment9c"
                  rows={3}
                  style={{resize: 'none'}}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.assessment9c}
                  className={touched.assessment9c && errors.assessment9c ? "error" : null}
                  disabled={nonEditable}
                />
                <FormErrorMessage
                  touched={touched.assessment9c}
                  errors={errors.assessment9c}
                />
          </Form.Group>

          <StyledLabel>{t('F9c_assessment_4')}:</StyledLabel>
          <Form.Group style={{alignItems: "center", display: 'flex'}}>
              <StyledLabel>
                <input
                  type="radio"
                  name="endExPost_2"
                  checked={!values.endExPost}
                  onChange={() => {
                    setFieldValue('dateNewExPost', null);
                    setFieldValue('endExPost', !values.endExPost);
                  }}
                  style={{marginRight: '10px'}}
                  disabled={nonEditable}
                />
                {t('F9c_radio_label_1')}
              </StyledLabel>

              <StyledLabel style={{marginLeft: '50px'}}>
                <input
                  type="radio"
                  name="endExPost"
                  checked={values.endExPost}
                  onChange={() => setFieldValue('endExPost', !values.endExPost)}
                  style={{marginRight: '10px'}}
                  disabled={nonEditable}
                />
                {t('F9c_radio_label_2')}
              </StyledLabel>
          </Form.Group>

        <Form.Group style={{display: 'flex'}}>
          <StyledLabel>{t('F9c_assessment_5')}:</StyledLabel>
          <Col>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={values.dateNewExPost}
              onChange={date => setFieldValue('dateNewExPost',date)}
              className={errors.dateNewExPost ? "error" : null}
              disabled={nonEditable || !values.endExPost}
            />
            <FormErrorMessage
              touched
              errors={errors.dateNewExPost}
            />
          </Col>
        </Form.Group>
    </form>
  )
}

export default IntpaForm;
