import React, { useState, useEffect } from 'react'
import { getBeneficiaryByTypeSummary } from 'services/initiative'
import { useTranslation } from 'react-i18next'

import Header6 from '../components/Header6'
import Table6 from '../components/Table6'
import Table7 from 'pages/statistics/Entities/components/Table6'
import Row from 'react-bootstrap/Row'
import { StyledDiv, StyledRow, StyledRow2 } from '../../CommonStyled/Tabs.styled'
import { getFundsBeneficiarySummaryByType } from 'services/fundsStatistics'
import { T4RenderRows } from '../helpers/helpers'

const BeneficiaryByTypeSummary = () => {
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const [data2, setData2] = useState(null)
  const [waiting2, setWaiting2] = useState(false)

  const getData = async (param) => {
    setWaiting(true)
    try {
      const response = await getBeneficiaryByTypeSummary(param)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  const getData2 = async (param) => {
    setWaiting2(true)
    try {
      const response = await getFundsBeneficiarySummaryByType(param)
      setData2(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting2(false)
    }
  }

  useEffect(() => {
    getData({})
    getData2({})
  }, [])

  return (
    <StyledDiv>
      <Row>
        <Header6
          label={t('Statistics_activities_tab6')}
          waiting={waiting}
          data={data}
          data2={data2}
          getData={getData}
          getData2={getData2}
        />
      </Row>
      <StyledRow style={{ marginTop: '-72px' }}>
        <Table6 data={data} waiting={waiting} setData={setData} title={undefined} />
      </StyledRow>
      <StyledRow2 style={{marginLeft: '0px'}}>
        <Table7
          title={t('Statistics_beneficiaries_tab2')}
          data={data2}
          waiting={waiting2}
          renderRows={T4RenderRows}
          setData={setData2}
          customWidth='62%'
          customWidthRow1='84%'
          customWidthRow2='16%'
          orderField='type'
          total={true}
        />
      </StyledRow2>
    </StyledDiv>
  )
}

export default BeneficiaryByTypeSummary
