import styled, { css } from 'styled-components';

export const StyledTitle = styled.h4`
    ${({ theme }) => css`
        color: ${theme.brandColors.gray1}
    `};
    margin-top: 10px;
    margin-bottom: 20px;
`;

export const StyledSeparator = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  height: 2px;
  background-image: linear-gradient(to right, #bac5cc 33%, rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 6px 2px;
  background-repeat: repeat-x;
`;
