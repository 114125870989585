import React, { useState, useEffect } from 'react'
import { getBeneficiaryByTypeSummaryExportValues, getFundsBeneficiaryByTypeSummaryExportValues } from '../helpers/helpers'
import {
  Col1,
  Col2,
} from '../../CommonStyled/Components.styled'
import { ExportStatisticsByYearsToXLSX } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'
import { useTranslation } from 'react-i18next'
import { getCountries } from 'services/initiative'

const Header6 = ({ getData, getData2, data, data2, label, waiting }) => {
  const { t } = useTranslation()
  const [countriesOptions, setCountriesOptions] = useState([])
  const [countryFilter, setCountryFilter] = useState(0)

  const [regionsFilter, setRegionsFilter] = useState('')

  useEffect(() => {
    getCountriesOptions()
  }, [])

  const getCountriesOptions = async () => {
    try {
      const response = await getCountries()
      setCountriesOptions(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilter = () => {
    let param = {}
    if (countryFilter) param.countryId = countryFilter
    if (regionsFilter) param.region = regionsFilter
    getData(param)
    getData2(param)
  }

  return (
    <>
      <Col1 xs={8}>
        {/* <StyledFormGroup>
          <StyledFormLabel>{t('F1_S2_Country')}</StyledFormLabel>
          <Form.Control
            name="countryId"
            as="select"
            onChange={(e) => {
              setCountryFilter(e.target.value)
            }}
            value={countryFilter}
          >
            {renderCountriesOptions(countriesOptions)}
          </Form.Control>
        </StyledFormGroup>
        <StyledFormGroup>
          <StyledFormLabel>{t('Region')}</StyledFormLabel>
          <Form.Control
            name="regionId"
            as="select"
            onChange={(e) => {
              setRegionsFilter(e.target.value)
            }}
            value={regionsFilter}
          >
            {renderStatesOptions()}
          </Form.Control>
        </StyledFormGroup>
        <PrimaryButton
          onClick={() => {
            handleFilter()
          }}
          disabled={waiting}
        >
          {t('Button_search')}
        </PrimaryButton>
        */}
      </Col1>
      <Col2>
        <ExportToXLSXButton
          data={data}
          mapData={getBeneficiaryByTypeSummaryExportValues}
          data2={data2}
          mapData2={getFundsBeneficiaryByTypeSummaryExportValues}
          disabled={waiting}
          exportName={label}
        >
          <ExportStatisticsByYearsToXLSX label={label} data={[]} />
        </ExportToXLSXButton>
      </Col2>
    </>
  )
}

export default Header6
