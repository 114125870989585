import React from "react";
import PublicHeader from "components/PublicHeader";
import Footer from "components/Footer";
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';

import { TemporaryTextStyle } from "./MaintenanceHome.styled";

const MaintenanceHome = () => {

  const { t } = useTranslation();
  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <PublicHeader />

      <TemporaryTextStyle />
      <TemporaryTextStyle />

      <TemporaryTextStyle style={{ color: "#7fc05a" }}>
        {htmlParser(t('Maintenance_mode_message_green'))}
      </TemporaryTextStyle>

      <TemporaryTextStyle style={{ color: "#17abcd" }}>
      {htmlParser(t('Maintenance_mode_message_blue'))}
      </TemporaryTextStyle>

      <Footer public={true} />
    </div>
  );
};

export default MaintenanceHome;
