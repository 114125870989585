import React, { useState, useEffect } from 'react';
import { Formik, FieldArray } from 'formik';
import { validationSchema } from './Section14.validations';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { saveFormSection } from 'services/initiative';
import { f3statusNamesConst, roleNamesConst } from 'constants/appConstants';
import { useRootStore } from 'stores';
import { formInitialValues } from './Section14.initialValues';
import { useConstantsForm1 } from 'customHooks/useConstantsForm1';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';

import { Title, Separator, StyledSectionWrapper, StyledFormLabel } from './Section14.styled';

import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PrimaryButton, ErrorButton } from 'components/Buttons';
import MediaForm from './MediaForm';
import ConfirmCloseModal from 'components/ConfirmCloseModal';
import { FormFielErrorMessage, RequiredMark, SeparatorDotted } from 'components/CommonStyled';
import FormLayout from '../../FormLayout';
import FormContainer from 'components/FormContainer';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const mediaObject = {
  mediaTypeId: '',
  url: '',
  comments: '',
  //quantity: 0,
};

const Section14 = () => {
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { formStore, initiativeStore } = useRootStore();
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const { mediaTypes } = useConstantsForm1();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f3statusNamesConst.DRAFT || initiativeStore.role === roleNamesConst.GUEST,
    );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = { ...formInitialValues, ...formStore.formSectionData[0] };
      for (const key in formSectionData) {
        if (!formSectionData[key]) {
          formSectionData[key] = '';
        }
      }
      formSectionData.items.forEach(e => {
        e.mediaTypeId = e.mediaType ? e.mediaType?.id : '';
      });
      setFormValues(formSectionData);
    }
  }, [formStore.formSectionData]);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  // Handle remove media modal confirmation
  const openModalRemove = key => {
    setModalRemove(true);
    setSelectedKey(key);
  };

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await saveFormSection(id, formId, sectionId, values);
      let bool = true && response.items;
      if (response.items) {
        response.items.forEach(element => {
          bool = bool && element.complete;
        });
      }
      formStore.setSectionStatus(sectionId, bool);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, resetForm) => {
    e.preventDefault();
    isValid ? handleSubmit(values, { resetForm }) : alert(t('not_saved_alert'));
  };

  return (
    <FormLayout>
      <FormContainer
        number={14}
        title={t('F3_S14_title')}
        formId="form3section14"
        isHelp={true}
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
      >
        <Formik
          enableReinitialize
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {props => (
            <form
              onSubmit={e => {
                onValidateSubmit(e, props.isValid, props.values, props.resetForm);
              }}
              id="form3section14"
            >
              {props.dirty && setSaved(false)}

              <StyledSectionWrapper>
                <Form.Group>
                  <StyledFormLabel>{htmlParser(t('F1_S8_Objetive'))}: </StyledFormLabel>
                  <Form.Control
                    name="objective"
                    as="textarea"
                    rows={4}
                    style={{ resize: 'none' }}
                    placeholder={t('Maximum_1300')}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.objective}
                    disabled={nonEditable}
                    className={props.touched.objective && props.errors.objective ? 'error' : null}
                  />
                  <FormErrorMessage
                    touched={props.touched.objective}
                    errors={props.errors.objective}
                  />
                </Form.Group>

                <StyledFormLabel>{htmlParser(t('F3_S14_Subtitle_communication_tools'))}</StyledFormLabel>

                <div style={{ marginBottom: '20px' }} />

                <FieldArray
                  name="items"
                  render={arrayHelpers => (
                    <>
                      {modalRemove && (
                        <ConfirmCloseModal
                          show={modalRemove}
                          handleCloseConfirm={closeModalRemove}
                          handleActionOnConfirm={() => {
                            arrayHelpers.remove(selectedKey);
                            closeModalRemove();
                          }}
                          text={t('Confirmation remove tool') + ' ' + (selectedKey + 1) + '?'}
                        />
                      )}
                      <Accordion>
                        {props.values.items
                          ? props.values.items.map((item, key) => (
                            <AccordionItem key={key + 1}>
                              <AccordionItemHeader>
                                <AccordionTitle eventKey={key + 1}>
                                  {`${t('F1_S8_Accordion')} ${key + 1}`}
                                  {item?.mediaType?.id ? ` - ${t(`media_type_${item?.mediaType?.id}`)}` : ''}
                                </AccordionTitle>
                              </AccordionItemHeader>
                              <Accordion.Collapse eventKey={key + 1}>
                                <AccordionItemBody>
                                  <MediaForm
                                    formikInstance={props}
                                    mediaToolIndex={key}
                                    mediaNames={mediaTypes}
                                    nonEditable={nonEditable}
                                  />
                                  <ErrorButton onClick={() => openModalRemove(key)} disabled={nonEditable}>
                                    {t('F1_S8_Button_delete_item')}
                                  </ErrorButton>
                                </AccordionItemBody>
                              </Accordion.Collapse>
                            </AccordionItem>
                          ))
                          : null}
                      </Accordion>
                      {typeof props.errors.items === 'string' ? (
                        <FormFielErrorMessage>{props.errors.items}</FormFielErrorMessage>
                      ) : null}
                      <SeparatorDotted />
                      <PrimaryButton onClick={() => arrayHelpers.push(mediaObject)} disabled={nonEditable}>
                        {t('F1_S8_Button_add_item')}
                      </PrimaryButton>
                    </>
                  )}
                />

                <Separator />

                <Title> {t('F3_S14_Subtitle_focal_point')} </Title>

                <Form.Group as={Row}>
                  <Col lg={12}>
                    <Form.Check
                      name="samePerson"
                      type="checkbox"
                      value={props.values.samePerson}
                      onChange={e => {
                        props.setFieldValue('firstname', '');
                        props.setFieldValue('lastname', '');
                        props.setFieldValue('email', '');
                        props.setFieldValue('entityName', '');
                        props.setFieldValue('phone', '');
                        props.setFieldValue('auth', false);
                        props.handleChange(e);
                      }}
                      checked={props.values.samePerson}
                      style={{ marginTop: '20px', marginBottom: '20px' }}
                      label={t('F3_S14_Checkbox_same_person')}
                      disabled={nonEditable}
                    />
                    {props.errors.samePerson ? (
                      <FormFielErrorMessage>{t('F3_S14_Error_focal_point')}</FormFielErrorMessage>
                    ) : null}
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column xs={2}>
                    {t('F1_S2_First_name')}: <RequiredMark>*</RequiredMark>
                  </Form.Label>
                  <Col xs={3}>
                    <Form.Control
                      type="text"
                      name="firstname"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.firstname}
                      className={props.errors.firstname ? 'error' : null}
                      disabled={props.values.samePerson || nonEditable}
                    />
                    <FormErrorMessage
                      touched
                      errors={props.errors.firstname}
                    />
                  </Col>
                  <Form.Label column xs={2}>
                    {t('F1_S2_Last_Name')}: <RequiredMark>*</RequiredMark>
                  </Form.Label>
                  <Col xs={3}>
                    <Form.Control
                      type="text"
                      name="lastname"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.lastname}
                      className={props.errors.lastname ? 'error' : null}
                      disabled={props.values.samePerson || nonEditable}
                    />
                    <FormErrorMessage
                      touched
                      errors={props.errors.lastname}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column xs={2}>
                    {t('F1_S2_Email')}: <RequiredMark>*</RequiredMark>
                  </Form.Label>
                  <Col xs={3}>
                    <Form.Control
                      type="text"
                      name="email"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.email}
                      className={props.errors.email ? 'error' : null}
                      disabled={props.values.samePerson || nonEditable}
                    />
                    <FormErrorMessage
                      touched
                      errors={props.errors.email}
                    />
                  </Col>
                  <Form.Label column xs={2}>
                    {t('F3_S14_Entity')}: <RequiredMark>*</RequiredMark>
                  </Form.Label>
                  <Col xs={3}>
                    <Form.Control
                      type="text"
                      name="entityName"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.entityName}
                      className={props.errors.entityName ? 'error' : null}
                      disabled={props.values.samePerson || nonEditable}
                    />
                    <FormErrorMessage
                      touched
                      errors={props.errors.entityName}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: 'center' }}>
                  <Form.Label column lg={2}>
                    {t('F1_S2_Phone_number')}: <RequiredMark>*</RequiredMark>
                  </Form.Label>
                  <Col lg={4}>
                    <Form.Control
                      type="text"
                      name="phone"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.phone}
                      className={props.errors.phone ? 'error' : null}
                      disabled={props.values.samePerson || nonEditable}
                    />
                    <FormErrorMessage
                      touched
                      errors={props.errors.phone}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      name="auth"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.auth}
                      style={{ marginTop: '10px' }}
                      label={t('F1_S3_Authorize_user')}
                      disabled={nonEditable || props.values.samePerson}
                      checked={props.values.auth}
                    />
                  </Col>
                </Form.Group>
              </StyledSectionWrapper>
            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section14);
