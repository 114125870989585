import React from 'react'
import { useTranslation } from 'react-i18next';

import MoneyTextMask from 'components/MoneyTextMask';
import Table from 'react-bootstrap/Table';
import {
  TableHeader,
  RowBorder,
  BorderlessTr,
  ResourceNameSpan,
  ResourceNumberSpan
} from './CustomBudgetTable.styled';

const CustomBudgetTable = ({ items, totalItem }) => {
  const { t } = useTranslation();

  return (
    <Table bordered={false} style={{marginLeft: '-10px'}}>
      <thead>
        <BorderlessTr>
          <TableHeader width={"55"}></TableHeader>
          <TableHeader width={"15"}>{t('F3_S7_Resource_column_EU')}</TableHeader>
          <TableHeader width={"15"}>{t('F3_S7_Resource_column_counterpart')}</TableHeader>
          <TableHeader width={"15"}>{t('F3_S7_Resource_column_total')}</TableHeader>
        </BorderlessTr>
      </thead>
      <tbody>
        {items.map(resource => (
          <>
            <BorderlessTr>
              <td colSpan="4">
                <RowBorder />
              </td>
            </BorderlessTr>
            <BorderlessTr key={resource.resourceType? t(`resource_type_${resource.resourceType.id}`): ' - '}>
              <td><ResourceNameSpan>{resource.resourceType? t(`resource_type_${resource.resourceType.id}`): ' - '}</ResourceNameSpan></td>
              <td><ResourceNumberSpan> <MoneyTextMask value={resource.eu_budget} /> </ResourceNumberSpan></td>
              <td><ResourceNumberSpan> <MoneyTextMask value={resource.counterpart_budget} /> </ResourceNumberSpan></td>
              <td><ResourceNumberSpan> <MoneyTextMask value={resource.total_budget} /> </ResourceNumberSpan></td>
            </BorderlessTr>
          </>
        ))}
        <BorderlessTr>
          <td colSpan="4">
            <RowBorder />
          </td>
        </BorderlessTr>
        <BorderlessTr>
          <td> <ResourceNameSpan total={true}>{t('Total TCI budget')}</ResourceNameSpan></td>
          <td> <ResourceNumberSpan weight={600}> {totalItem? <MoneyTextMask value={totalItem.eu_budget} />: '-'} </ResourceNumberSpan></td>
          <td> <ResourceNumberSpan weight={600}> {totalItem? <MoneyTextMask value={totalItem.counterpart_budget} />: '-'} </ResourceNumberSpan></td>
          <td> <ResourceNumberSpan weight={600}> {totalItem? <MoneyTextMask value={totalItem.total_budget} />: '-'} </ResourceNumberSpan></td>
        </BorderlessTr>
      </tbody>
    </Table>
  )
}

export default CustomBudgetTable;