export const formInitialValues = {
  name: "",
  dtGlobalCode: "",
  contact: "",
  email: "",
  phone: "",
  comments: "",
  active: false,
  address: "",
  city: "",
  state: "",
  countryId: ""
};