import styled, { css } from 'styled-components';
import Col from 'react-bootstrap/Col';

export const StyledTextValue = styled(Col)`
  display: flex;
  align-items: center;
  text-align: justify;
  overflow-wrap: break-word;
  ${({ theme }) => css`
    color: ${theme.colors.gray[5]};
  `}
`;