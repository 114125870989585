import React from 'react'
import PageTitle from 'components/PageTitle'
import { PageHeader } from '../CommonStyled/Tabs.styled'
import { useTranslation } from 'react-i18next'
import StatisticsTabs from 'components/Tabs'
import DirectBeneficiariesDetail from './tabs/DirectBeneficiariesDetail'
import BeneficiaryByTypeSummary from './tabs/BeneficiaryByTypeSummary'
import BeneficiaryByCountrySummary from './tabs/BeneficiaryByCountrySummary'

const StatsDirectBeneficiaries = () => {
  const { t } = useTranslation()
  const titles = [
    t('Statistics_beneficiaries_tab1'),
    t('Statistics_beneficiaries_tab2'),
    t('Statistics_beneficiaries_tab3'),
  ]
  const renderTab1Content = () => <DirectBeneficiariesDetail />
  const renderTab2Content = () => <BeneficiaryByTypeSummary />
  const renderTab3Content = () => <BeneficiaryByCountrySummary />

  const contents = [renderTab1Content, renderTab2Content, renderTab3Content]

  return (
    <>
      <PageHeader>
        <PageTitle title={t('menu_admin_6_9')} />
      </PageHeader>
      <StatisticsTabs titles={titles} contents={contents} />
    </>
  )
}

export default StatsDirectBeneficiaries
