import React from 'react'
import PageTitle from 'components/PageTitle'
import { PageHeader } from '../CommonStyled/Tabs.styled'
import { useTranslation } from 'react-i18next'

import Preformulationsummary from './tabs/PreformulationSummary'
import StatisticsTabs from 'components/Tabs'

const StatsPreformulation = () => {
  const { t } = useTranslation()
  const titles = [t('Statistics_formulation_tab1')]
  const renderTab1Content = () => <Preformulationsummary />

  const contents = [renderTab1Content]

  return (
    <>
      <PageHeader>
        <PageTitle title={t('menu_admin_6_1_1')} />
      </PageHeader>
      <StatisticsTabs titles={titles} contents={contents} />
    </>
  )
}

export default StatsPreformulation
