import * as Yup from 'yup';
import i18n from 'i18n';

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'F1_S4_validation1')
    .max(650, 'F1_S6_validation9')
    .required('F5_S7_required'),
  dtGlobalCode: Yup.string()
    .min(2, 'F1_S4_validation1')
    .max(100, 'F1_S2_validation7'),
  contact: Yup.string()
    .min(2, 'F1_S4_validation1')
    .max(650, 'F1_S6_validation9'),
  email: Yup.string()
    .email('F1_S2_validation23')
    .max(100, 'F1_S2_validation24'),
  phone:  Yup.string()
    .min(2, 'F1_S2_validation20')
    .max(100, 'F1_S2_validation21')
    .matches(phoneRegExp, 'F1_S2_validation22'),
  comments: Yup.string()
    .min(2, 'F1_S4_validation1')
    .max(1300, 'F1_S6_validation8'),
  active: Yup.string()
    .min(2, 'F1_S4_validation1')
    .max(650, 'F1_S6_validation9'),
  address: Yup.string()
    .min(2, 'F1_S4_validation1')
    .max(650, 'F1_S6_validation9'),
  city: Yup.string()
    .min(2, 'F1_S4_validation1')
    .max(650, 'F1_S6_validation9'),
  state: Yup.string()
    .min(2, 'F1_S4_validation1')
    .max(650, 'F1_S6_validation9'),
  countryId: Yup.string(),
});