import React, {useState} from 'react';
import { observer } from 'mobx-react-lite';
import Proptypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { ModalFooter, FooterLeft, FooterCenter, FooterRight } from './SendClarificationsDialog.styled';
import { ErrorButton, PrimaryButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';


const SendClarificationsDialog = ({ displayDialog, onSend, onCloseModal, closeOnError }) => {
  const [sending, setSending] = useState(false);
  const {t} = useTranslation();

  const sendAction = async () => {
    try {
      setSending(true);
      await onSend();
      onCloseModal();
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  }

  return (
    <Modal show={displayDialog} onHide={onCloseModal} size="lg" animation={false}>
      <Modal.Body>
        <>
          {htmlParser(t('F2c_clarif_confirmation_to_send'))}
        </>
      </Modal.Body>
      <ModalFooter>
        <FooterLeft />
        <FooterCenter>
          <PrimaryButton disabled={sending} onClick={() => sendAction()}>{t('Yes')}</PrimaryButton>
          <ErrorButton disabled={sending} onClick={onCloseModal}>{t('No')}</ErrorButton>
        </FooterCenter>
        <FooterRight />
      </ModalFooter>
    </Modal>
  )
}

SendClarificationsDialog.propTypes = {
  displayDialog: Proptypes.bool,
  onSend: Proptypes.func,
  onCloseModal: Proptypes.func,
  closeOnError: Proptypes.func,
};

export default observer(SendClarificationsDialog);
