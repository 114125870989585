import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  goal: Yup.object(),
  justification: Yup.string(),
  targetList: Yup.array()
    .of(
      Yup.object().shape({
        targetJustification: Yup.string()
          .min(2, 'F1_S4_validation1')
          .max(650,  'F1_S6_validation9'),
        targetIndicatorList:  Yup.array()
          .of(
            Yup.object().shape({
              selectedIndicator: Yup.boolean()
            })
          )
          .test('testMinimum', 'F3_S11_validation1', indicators => {
            return indicators && ((indicators.length == 0) || indicators.some(indicator => indicator.selectedIndicator))
          })
      })
    )
    .min(1, 'F3_S11_validation2'),
});
