import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { validationSchema } from "./Section12.validations";
import { observer } from "mobx-react-lite";
import { useRootStore } from "stores";
import { useParams } from "react-router-dom";
import { formInitialValues } from "./Section12.initialValues";
import { useTranslation } from "react-i18next";
import { saveFormSection } from "services/initiative";
import TextValue from "components/TextValue";
import { f5statusNamesConst, roleNamesConst } from "constants/appConstants";
import { Link } from "react-router-dom";
import Comments from "../Comments";
import { isWindows2021 } from "helpers/isWindow2021Helper";
import htmlParser from 'html-react-parser'
import Form from "react-bootstrap/Form";
import FormLayout from "../../FormLayout";
import FormContainer from "components/FormContainer";
import { RequiredMark } from "components/CommonStyled";
import {
  StyledFormLabel,
  StyledDivWrapper,
  StyledTitle,
  StyledSubtitle,
  StyledSubtitle2,
} from "./Section12.styled";
import { mapActivityItems } from "helpers/f5Helpers";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const Section12 = () => {
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { formStore, initiativeStore, authStore } = useRootStore();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [nonEditable, setNonEditable] = useState(false);
  const [disabledComments, setDisabledComments] = useState(true);
  const [saved, setSaved] = useState(false);
  const currentRole = authStore.roles[0];
  const isPublic = currentRole === roleNamesConst.PUBLIC;
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM;
  const isAtke = currentRole === roleNamesConst.ATKE;
  const isIntpa = currentRole === roleNamesConst.INTPA;
  const isEud = currentRole === roleNamesConst.EUD;
  const [isF7, setIsF7] = useState(false);
  const [window2021, setWindow2021] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  useEffect(() => {
    if (initiativeStore.code) {
      setWindow2021(isWindows2021(initiativeStore.code));
    }
  }, [initiativeStore.code]);

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f5statusNamesConst.DRAFT ||
        initiativeStore.role !== roleNamesConst.APPLICANT
    );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (initiativeStore.formStateName)
      setIsF7(
        (isIntpa || isEud) &&
          initiativeStore.formStateName !== f5statusNamesConst.DRAFT &&
          initiativeStore.formStateName !== f5statusNamesConst.SENT
      );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {
        ...formInitialValues,
        ...formStore.formSectionData[0],
      };
      setInitialValues(formSectionData);
    }
  }, [formStore.formSectionData]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values);
      formStore.setSectionStatus(sectionId, complete);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaved = (value) => {
    setSaved(value);
  };

  const handleButton = (value) => {
    setDisabledComments(value);
  };

  return (
    <FormLayout>
      <FormContainer
        number={12}
        title={t("F3_S12_title")}
        formId="form5section12"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
        showButtonClarif={!disabledComments}
      >
        <StyledDivWrapper>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              dirty,
            }) => (
              <form onSubmit={handleSubmit} id="form5section12">
                {dirty && setSaved(false)}

                <StyledTitle style={{ marginBottom: "30px" }}>
                  <Link
                    to={`${authStore.getBaseRouteByRole()}/initiative/${id}/form/${
                      isPublic ? "3" : "4"
                    }/section/12`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("F5_Subtitle_Description_from_proposal")}
                  </Link>
                </StyledTitle>

                <StyledTitle style={{ marginBottom: "30px" }}>
                  {t("F5_Subtitle_Assessment_TCI_Implementation")}
                </StyledTitle>

                <StyledSubtitle>
                  {t("F5_S7_Activity_assessment_6")}:
                </StyledSubtitle>

                <div style={{ marginTop: "20px" }}>
                  {formStore.formSectionData
                    ? mapActivityItems(formStore.formSectionData[1])
                    : "-"}
                </div>

                <Form.Group style={{ marginTop: "25px" }}>
                  <StyledFormLabel>
                    {t("F5_S12_Roles_distribution_effectiveness")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="overviewRelationship"
                    as="textarea"
                    rows={6}
                    style={{ resize: "none" }}
                    placeholder={t("Maximum_1300")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.overviewRelationship}
                    disabled={nonEditable}
                    className={
                      touched.overviewRelationship &&
                      errors.overviewRelationship
                        ? "error"
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.overviewRelationship}
                    errors={errors.overviewRelationship}
                  />
                </Form.Group>

                <StyledSubtitle>
                  {t("F5_S7_Activity_assessment_10")}:
                </StyledSubtitle>

                <div style={{ marginTop: "20px" }}>
                  {formStore.formSectionData
                    ? mapActivityItems(formStore.formSectionData[2])
                    : "-"}
                </div>

                <Form.Group style={{ marginTop: "25px" }}>
                  <StyledFormLabel>
                    {t("F5_S12_Knowledge_contribution")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="overviewTriangular"
                    as="textarea"
                    rows={6}
                    style={{ resize: "none" }}
                    placeholder={t("Maximum_1300")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.overviewTriangular}
                    disabled={nonEditable}
                    className={
                      touched.overviewTriangular && errors.overviewTriangular
                        ? "error"
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.overviewTriangular}
                    errors={errors.overviewTriangular}
                  />
                </Form.Group>

                {window2021 ? (
                  <>
                    <StyledSubtitle>
                      {t("F5_S7_Activity_assessment_11")}:
                    </StyledSubtitle>

                    <div style={{ marginTop: "20px" }}>
                      {formStore.formSectionData
                        ? formStore.formSectionData[2].map((activity) => (
                            <Form.Group style={{ display: "flex" }}>
                              <StyledFormLabel style={{ margin: "0px" }}>
                                {activity.activityName}:
                              </StyledFormLabel>
                              <TextValue value={activity.assessment} />
                            </Form.Group>
                          ))
                        : "-"}
                    </div>

                    <Form.Group style={{ marginTop: "25px" }}>
                      <StyledFormLabel>
                        {t("F5_Overview_of_TCI")}
                        <RequiredMark>*</RequiredMark>:
                      </StyledFormLabel>
                      <Form.Control
                        name="overviewEuropean"
                        as="textarea"
                        rows={6}
                        style={{ resize: "none" }}
                        placeholder={t("Maximum_1300")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.overviewEuropean}
                        disabled={nonEditable}
                        className={
                          touched.overviewEuropean && errors.overviewEuropean
                            ? "error"
                            : null
                        }
                      />
                      <FormErrorMessage
                        touched={touched.overviewEuropean}
                        errors={errors.overviewEuropean}
                      />
                    </Form.Group>

                    <Form.Group style={{ marginTop: "25px" }}>
                      <StyledFormLabel>
                        {t("F5_S12_Value_added_TCI")}
                        <RequiredMark>*</RequiredMark>:
                      </StyledFormLabel>
                      <Form.Control
                        name="valueAddedTCI"
                        as="textarea"
                        rows={6}
                        style={{ resize: "none" }}
                        placeholder={t("Maximum_1300")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.valueAddedTCI}
                        disabled={nonEditable}
                        className={
                          touched.valueAddedTCI && errors.valueAddedTCI
                            ? "error"
                            : null
                        }
                      />
                      <FormErrorMessage
                        touched={touched.valueAddedTCI}
                        errors={errors.valueAddedTCI}
                      />
                    </Form.Group>

                    <Form.Group style={{ marginTop: "25px" }}>
                      <StyledFormLabel>
                        {t("F5_S12_Contribution_beneficiary_entities")}
                        <RequiredMark>*</RequiredMark>:
                      </StyledFormLabel>
                      <Form.Control
                        name="contributionBeneficiaryEntities"
                        as="textarea"
                        rows={6}
                        style={{ resize: "none" }}
                        placeholder={t("Maximum_1300")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.contributionBeneficiaryEntities}
                        disabled={nonEditable}
                        className={
                          touched.contributionBeneficiaryEntities &&
                          errors.contributionBeneficiaryEntities
                            ? "error"
                            : null
                        }
                      />
                      <FormErrorMessage
                        touched={touched.contributionBeneficiaryEntities}
                        errors={errors.contributionBeneficiaryEntities}
                      />
                    </Form.Group>

                    <Form.Group style={{ marginTop: "25px" }}>
                      <StyledFormLabel>
                        {t("F5_S12_Benefits_for_entitities_additional")}
                        <RequiredMark>*</RequiredMark>:
                      </StyledFormLabel>
                      <Form.Control
                        name="benefitsForEntitiesAdditional"
                        as="textarea"
                        rows={6}
                        style={{ resize: "none" }}
                        placeholder={t("Maximum_1300")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.benefitsForEntitiesAdditional}
                        disabled={nonEditable}
                        className={
                          touched.benefitsForEntitiesAdditional &&
                          errors.benefitsForEntitiesAdditional
                            ? "error"
                            : null
                        }
                      />
                      <FormErrorMessage
                        touched={touched.benefitsForEntitiesAdditional}
                        errors={errors.benefitsForEntitiesAdditional}
                      />
                    </Form.Group>

                    <Form.Group style={{ marginTop: "25px" }}>
                      <StyledFormLabel>
                        {t("F5_S12_Benefits_for_entitities_provider")}
                        <RequiredMark>*</RequiredMark>:
                      </StyledFormLabel>
                      <Form.Control
                        name="benefitsForEntitiesProvider"
                        as="textarea"
                        rows={6}
                        style={{ resize: "none" }}
                        placeholder={t("Maximum_1300")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.benefitsForEntitiesProvider}
                        disabled={nonEditable}
                        className={
                          touched.benefitsForEntitiesProvider &&
                          errors.benefitsForEntitiesProvider
                            ? "error"
                            : null
                        }
                      />
                      <FormErrorMessage
                        touched={touched.benefitsForEntitiesProvider}
                        errors={errors.benefitsForEntitiesProvider}
                      />
                    </Form.Group>

                    <Form.Group style={{ marginTop: "25px" }}>
                      <StyledFormLabel>
                        {t("F5_S12_Value_partnership_itself")}
                        <RequiredMark>*</RequiredMark>:
                      </StyledFormLabel>
                      <Form.Control
                        name="valuePartnershipItself"
                        as="textarea"
                        rows={6}
                        style={{ resize: "none" }}
                        placeholder={t("Maximum_1300")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.valuePartnershipItself}
                        disabled={nonEditable}
                        className={
                          touched.valuePartnershipItself &&
                          errors.valuePartnershipItself
                            ? "error"
                            : null
                        }
                      />                      
                      <FormErrorMessage
                        touched={touched.valuePartnershipItself}
                        errors={errors.valuePartnershipItself}
                      />
                    </Form.Group>
                  </>
                ) : null}

                <StyledSubtitle2>
                  {htmlParser(t("F5_S12_Subtitle_Added_TC"))}
                </StyledSubtitle2>

                <Form.Group style={{ marginTop: "25px" }}>
                  <StyledFormLabel>
                    {t("F5_S12_Ownership")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="ownership"
                    as="textarea"
                    rows={6}
                    style={{ resize: "none" }}
                    placeholder={t("Maximum_1300")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ownership}
                    disabled={nonEditable}
                    className={
                      touched.ownership && errors.ownership ? "error" : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.ownership}
                    errors={errors.ownership}
                  />
                </Form.Group>

                <Form.Group style={{ marginTop: "25px" }}>
                  <StyledFormLabel>
                    {t("F5_S12_Complementarity")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="complementarity"
                    as="textarea"
                    rows={6}
                    style={{ resize: "none" }}
                    placeholder={t("Maximum_1300")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.complementarity}
                    disabled={nonEditable}
                    className={
                      touched.complementarity && errors.complementarity
                        ? "error"
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.complementarity}
                    errors={errors.complementarity}
                  />
                </Form.Group>

                <Form.Group style={{ marginTop: "25px" }}>
                  <StyledFormLabel>
                    {t("F5_S12_Sharing_knowledge")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="sharingKnowledge"
                    as="textarea"
                    rows={6}
                    style={{ resize: "none" }}
                    placeholder={t("Maximum_1300")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.sharingKnowledge}
                    disabled={nonEditable}
                    className={
                      touched.sharingKnowledge && errors.sharingKnowledge
                        ? "error"
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.sharingKnowledge}
                    errors={errors.sharingKnowledge}
                  />
                </Form.Group>

                <Form.Group style={{ marginTop: "25px" }}>
                  <StyledFormLabel>
                    {t("F5_S12_Joint_creation")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="jointCreation"
                    as="textarea"
                    rows={6}
                    style={{ resize: "none" }}
                    placeholder={t("Maximum_1300")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.jointCreation}
                    disabled={nonEditable}
                    className={
                      touched.jointCreation && errors.jointCreation
                        ? "error"
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.jointCreation}
                    errors={errors.jointCreation}
                  />
                </Form.Group>

                <Form.Group style={{ marginTop: "25px" }}>
                  <StyledFormLabel>
                    {t("F5_S12_Increasing_volume")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="increasingVolume"
                    as="textarea"
                    rows={6}
                    style={{ resize: "none" }}
                    placeholder={t("Maximum_1300")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.increasingVolume}
                    disabled={nonEditable}
                    className={
                      touched.increasingVolume && errors.increasingVolume
                        ? "error"
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.increasingVolume}
                    errors={errors.increasingVolume}
                  />
                </Form.Group>

                <Form.Group style={{ marginTop: "25px" }}>
                  <StyledFormLabel>
                    {t("F5_S12_Global_and_regional")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="globalAndRegional"
                    as="textarea"
                    rows={6}
                    style={{ resize: "none" }}
                    placeholder={t("Maximum_1300")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.globalAndRegional}
                    disabled={nonEditable}
                    className={
                      touched.globalAndRegional && errors.globalAndRegional
                        ? "error"
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.globalAndRegional}
                    errors={errors.globalAndRegional}
                  />
                </Form.Group>

                <Form.Group style={{ marginTop: "25px" }}>
                  <StyledFormLabel>
                    {t("F5_S12_Coordination_EU")}
                   :
                  </StyledFormLabel>
                  <Form.Control
                    name="coordinationEU"
                    as="textarea"
                    rows={7}
                    style={{ resize: "none" }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.coordinationEU}
                    disabled={nonEditable}
                    placeholder={t("Maximum_1300")}
                    className={
                      touched.coordinationEU && errors.coordinationEU
                        ? "error"
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.coordinationEU}
                    errors={errors.coordinationEU}
                  />
                </Form.Group>
              </form>
            )}
          </Formik>

          {(isAtke || isConsortium || isF7) && (
            <Comments onSaved={handleSaved} onButton={handleButton} />
          )}
        </StyledDivWrapper>
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section12);
