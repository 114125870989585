import React from 'react';
import { Link } from 'react-router-dom';
import { useRootStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { formatDateDDMMYY } from 'helpers/dateHelpers';
import { useTranslation } from 'react-i18next';
import { StyledTr, StyledSpan } from './TableRow.styled';

const TableRow = ({ item }) => {
  const { initiativeStore, authStore } = useRootStore();

  const setInitiativeOnStore = initiative => {
    initiativeStore.setInitiativeData(initiative);
  };

  const baseRoute = authStore.getBaseRouteByRole();
  const { t } = useTranslation();

  return (
    <StyledTr key={item.id}>
      <td style={{ minWidth: '100px' }}>
        <Link
          onClick={() => setInitiativeOnStore(item)}
          to={`${baseRoute}/initiative/${item.id}/form/55/section/1`}
        >
          {item.code}
        </Link>
      </td>
      <td style={{ maxWidth: '550px', overflowWrap: 'break-word' }}>
        {item.name ? item.name : t('Name_not_defined_yet')}
      </td>
      <td style={{ minWidth: '100px' }}>
        {item?.fundTypeName
          ? t(`fund_type_${item?.fundTypeName}`)
          : ' - '}
      </td>
      <td>
        {item?.statusName
          ? t(item?.statusName)
          : ' - '}
      </td>
      <td>
        {item.fromDate ? (
          <StyledSpan>
            {item.fromDate ? formatDateDDMMYY(item.fromDate) : ' - '}
          </StyledSpan>
        ) : (
          ' - '
        )}
      </td>
      <td>
        {item.toDate ? (
          <StyledSpan>
            {item.toDate ? formatDateDDMMYY(item.toDate) : ' - '}
          </StyledSpan>
        ) : (
          ' - '
        )}
      </td>
    </StyledTr>
  );
};

export default observer(TableRow);
