import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { formInitialValues } from './UploadDocumentForm.initialValues';
import { validationSchema } from './UploadDocumentForm.validations';
import { addF5S7ActivityDetailDocument, modifyF5S7ActivityDetailDocument, saveFile, getDownload } from 'services/initiative';
import { getUploadFormatsConstants } from "services/apiConstants";
import { useRootStore } from 'stores';
import { roleNamesConst } from 'constants/appConstants';

import FileUploader from 'components/FileUploader';
import {ErrorButton, PrimaryButton, StyledSendButton} from 'components/Buttons'
import { Form } from 'react-bootstrap'
import { FormFielErrorMessage, CenteredModalButtonContainer } from 'components/CommonStyled';

const UploadDocumentForm = ({ activity, detail, handleCloseOk, handleClose, handleCloseSubModalOnError, setModalInfo, nonEditable }) => {
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { authStore } = useRootStore();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const role = authStore.roles[0];
  const isAtke = role === roleNamesConst.ATKE
  const [validFileFormats, setValidFileFormats] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getUploadFormatsConstants();
        if(response && response.F3S7){
          setValidFileFormats(response.F3S7);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAPI();
   }, [])

  useEffect(() => {
    if(detail.currentDocument){
      setIsEditing(true);
      setInitialValues({
        ...detail.currentDocument
      });
    }
  }, [detail])

  const handleSubmit = async (values) => {
    try {
      if(isEditing){
        await modifyF5S7ActivityDetailDocument(id, activity.id, detail.id, values.id, values);
      }
      else{
        await addF5S7ActivityDetailDocument(id, activity.id, detail.id, values);
      }
      handleCloseOk();
    } catch (error) {
      handleCloseSubModalOnError();
    }
  };

  const handleFileUpload = async (e, setFieldValue, handleChange) => {
    try{
      const file = document.getElementById("fileInput").files[0];
      const fileExtension = file.name.split('.').pop();
      if(file.size > 20971520){
        setModalInfo({
          message: t('Error_file4'),
          type: "error",
          isOpen: true,
        });
      }else if(!validFileFormats.includes(fileExtension.toUpperCase())){
        setModalInfo({
          message: t('Error_file5'),
          type: "error",
          isOpen: true,
        });
      }
      else{
        setIsUploadingFile(true);
        const uid = await saveFile(id, formId, sectionId, file);
        setFieldValue('file.fileName', file.name);
        setFieldValue('file.uuid', uid);
        setFieldValue('fileUploaded', true);
        handleChange(e);
      }
    } catch (error) {
      setModalInfo({
        message: t('Error_file2'),
        type: "error",
        isOpen: true,
      });
    }
    finally {
      setIsUploadingFile(false);
    }
  }

  const handleDownload = async (uuid, downloadName) => {
    try {
      const file = await getDownload(uuid)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download", downloadName
        );
        document.body.appendChild(link);
        link.click();
    });
    } catch (error) {
      setModalInfo({
        message: t('Error_file1'),
        type: "error",
        isOpen: true,
      });
    }    
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
         }) => (
          <Form onSubmit={handleSubmit} id="uploadDocs" >
            <FileUploader
              file={values?.file}
              handleFileUpload={(e) => handleFileUpload(e, setFieldValue, handleChange)}
              handleDownload={handleDownload}
              isUploadingFile={isUploadingFile}
              uploadLabel={t('F5_S7_Products_upload_document')}
              nonEditable={nonEditable}
            />

            <Form.Group>
              <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}} >{t('F5_S7_Products_document_comments')}: </Form.Label>
              <Form.Control
                name="comments"
                as="textarea" 
                rows={3} 
                placeholder={t('Maximum_200')}
                style={{resize: 'none'}}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.comments}
                className={errors.comments ? "error" : null}
                disabled={nonEditable}
              />
              {errors.comments ? (
                <FormFielErrorMessage>{errors.comments}</FormFielErrorMessage>
              ): null}
            </Form.Group>


            <CenteredModalButtonContainer>
              {
                (nonEditable && !(isAtke))
                ?
                  <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
                :
                  <>
                    <ErrorButton style={{marginRight: '20px'}} onClick={handleClose} disabled={nonEditable && !(isAtke)}> {t('Button_cancel')} </ErrorButton>
                    <StyledSendButton type="submit" form="uploadDocs" disabled={nonEditable && !(isAtke)}> {t('Button_save')} </StyledSendButton>
                  </>
              }
            </CenteredModalButtonContainer>

          </Form>
        )}
      </Formik>
    </div>
  )
}

export default observer(UploadDocumentForm);