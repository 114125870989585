import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { formInitialValues } from './Section11.initialValues';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';

import TargetForm from './TargetForm';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion'
import TextValue from 'components/TextValue';
import Form from 'react-bootstrap/Form';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import Comments from 'components/Forms/Form4/Comments';
import {
  StyledGoal,
  StyledIntroParagraph,
  StyledSectionWrapper,
  StyledSubtitle,
  LinedSeparator,
} from './Section11.styled';
import { SeparatorDotted } from 'components/CommonStyled';

const Section11 = () => {

  const { t } = useTranslation();
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const { formStore } = useRootStore();
  const [formValues, setFormValues] = useState(formInitialValues);

  const handleSaved = (value) => {
    setSaved(value);
  }

  const handleButton = (value) => {
    setNonEditable(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = { ...formInitialValues, ...formStore.formSectionData[0] };
      setFormValues(formSectionData);
      formStore.setCurrentSection(11);
    }
  }, [formStore.formSectionData])



  return (
    <FormLayout>
      <FormContainer 
        number={11} 
        title={t('F3_S11_title')}
        formId="form4section11"
        showSavedMessage= {saved}
        showButtonClarif= {!nonEditable}
        showFooter={false}
      >
        <StyledSectionWrapper>

          <StyledIntroParagraph>
            {htmlParser(t('F3_S11_Intro'))}
          </StyledIntroParagraph>
          
          <StyledSubtitle size="20px"> {htmlParser(t('F3_S11_Subtitle_SDG_primary'))} </StyledSubtitle>
          <LinedSeparator />
          <StyledGoal> {formValues.goalPrimaryType? t(`SDG_Goal${formValues.goalPrimaryType.id}`): ' - '} </StyledGoal>

          <Form.Group>
            <Form.Label>{t('F3_S11_Justification')}:</Form.Label>
            <TextValue value={formValues.primaryJustification} />
          </Form.Group> 

          <Accordion style={{marginLeft: '-20px'}}>
            {formValues.primaryTargetList && formValues.goalPrimaryType
              ? ( formValues.primaryTargetList.map((target, key) => (
                  <AccordionItem key={key+1}>
                    <AccordionItemHeader>
                      <AccordionTitle eventKey={key+1}>{t('F3_S11_Target')} {key+1}</AccordionTitle>
                    </AccordionItemHeader>
                    <Accordion.Collapse eventKey={key+1}>
                      <AccordionItemBody>
                        <TargetForm data={target}/> 
                      </AccordionItemBody>
                    </Accordion.Collapse>
                  </AccordionItem>
                )))
              : null}
          </Accordion>

          <SeparatorDotted />

          <StyledSubtitle> {t('F3_S11_Subtitle_SDG_secondary')}:</StyledSubtitle>
          <LinedSeparator />
          <StyledGoal> {formValues.goalSecondaryType? t(`SDG_Goal${formValues.goalSecondaryType.id}`): ' - '} </StyledGoal>
 
          <Form.Group>
            <Form.Label>{t('F3_S11_Justification')}:</Form.Label>
            <TextValue value={formValues.secondaryJustification} />
          </Form.Group> 

          <Accordion style={{marginLeft: '-20px'}}>
            {formValues.secondaryTargetList && formValues.goalSecondaryType
              ? ( formValues.secondaryTargetList.map((target, key) => (
                  <AccordionItem key={key+1}>
                    <AccordionItemHeader>
                      <AccordionTitle eventKey={key+1}>{t('F3_S11_Target')} {key+1}</AccordionTitle>
                    </AccordionItemHeader>
                    <Accordion.Collapse eventKey={key+1}>
                      <AccordionItemBody>
                        <TargetForm data={target} /> 
                      </AccordionItemBody>
                    </Accordion.Collapse>
                  </AccordionItem>
                )))
              : null}
          </Accordion>
          
          <SeparatorDotted />

          <StyledSubtitle> {t('F3_S11_Subtitle_SDG_10')} </StyledSubtitle>
          <LinedSeparator />
          <StyledGoal> {formValues.goal10Type? t(`SDG_Goal${formValues.goal10Type.id}`): ' - '} </StyledGoal>
        
          <Form.Group>
            <Form.Label>{t('F3_S11_Justification')}:</Form.Label>
            <TextValue value={formValues.goal10Justification} />
          </Form.Group> 

          <Accordion style={{marginLeft: '-20px'}}>
            {formValues.goal10TargetList && formValues.goal10Type
              ? ( formValues.goal10TargetList.map((target, key) => (
                  <AccordionItem key={key+1}>
                    <AccordionItemHeader>
                      <AccordionTitle eventKey={key+1}>{t('F3_S11_Target')} {key+1}</AccordionTitle>
                    </AccordionItemHeader>
                    <Accordion.Collapse eventKey={key+1}>
                      <AccordionItemBody>
                        <TargetForm data={target}/> 
                      </AccordionItemBody>
                    </Accordion.Collapse>
                  </AccordionItem>
                )))
              : null}
          </Accordion>
    
          <SeparatorDotted />

          <StyledSubtitle> {t('F3_S11_Subtitle_SDG_17')} </StyledSubtitle>
          <LinedSeparator />
          <StyledGoal> {formValues.goal17Type? t(`SDG_Goal${formValues.goal17Type.id}`): ' - '} </StyledGoal>

          <Form.Group>
            <Form.Label>{t('F3_S11_Justification')}:</Form.Label>
            <TextValue value={formValues.goal17Justification} />
          </Form.Group> 

          <Accordion style={{marginLeft: '-20px'}}>
            {formValues.goal17TargetList && formValues.goal17Type
              ? ( formValues.goal17TargetList.map((target, key) => (
                  <AccordionItem key={key+1}>
                    <AccordionItemHeader>
                      <AccordionTitle eventKey={key+1}>{t('F3_S11_Target')} {key+1}</AccordionTitle>
                    </AccordionItemHeader>
                    <Accordion.Collapse eventKey={key+1}>
                      <AccordionItemBody>
                        <TargetForm data={target} /> 
                      </AccordionItemBody>
                    </Accordion.Collapse>
                  </AccordionItem>
                )))
              : null}
          </Accordion>

        </StyledSectionWrapper>

        <SeparatorDotted />

        <Comments 
          onSaved={handleSaved} 
          onButton={handleButton} 
        />
        
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section11);
