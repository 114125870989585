import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { PrimaryButton } from 'components/Buttons';

const WarningModal = (props) => {

    return (
      <Modal
        {...props}
        centered
        animation={false}
      >
        <Modal.Body style={{textAlign: 'justify'}}>
            {props.content}
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'center'}}>
        <PrimaryButton onClick={props.onHide}>OK</PrimaryButton>
       </Modal.Footer>
      </Modal>
    );
  }

  export default WarningModal; 