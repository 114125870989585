import * as Yup from 'yup';
import i18n from 'i18n';

const yesterday = new Date(Date.now() - 86400000);

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'F1_S2_validation3')
    .max(100, 'F1_S2_validation4')
    .required('F5_S7_required'),
  productTypeId: Yup.string()
    .min(1, 'F1_S2_validation5')
    .required('F5_S7_required'),
  dueDate: Yup.date()
    .required('F5_S7_required'),
  description: Yup.string()
    .min(2, 'F3_S7_validation1')
    .max(650, 'F3_S7_validation2')
    .required('F5_S7_required'),
});

