import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledNumberCircle, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import FakeAccordionItem from '../../FakeAccordionItem';
import OtherEntitiesForm from 'components/Forms/Form4/Section4/OtherEntitiesForm';
import { SeparatorDotted } from 'components/CommonStyled';

const Section4 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState([]);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`4 - ${t('F1_S4_title')}`}  </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        {(formValues && formValues.map((entity, key) => (
          <React.Fragment key={key}>
            <FakeAccordionItem title={t('F1_S3_Accordion')+" "+(key+1)}>
              <OtherEntitiesForm entityIndex={key} data={entity} /> 
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}
      </StyledBody>
    </SectionContainer>
  )
}


export default Section4;