import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  fundTypeId: Yup.string().required('F3_S7_validation3'),
  title: Yup.string()
    .min(2, 'F1_S2_validation1')
    .max(500, 'Maximum_500')
    .required('F3_S7_validation3'),
  fromDate: Yup.date()
    .typeError('F1_S7_validation9')
    .nullable()
    .required('F3_S7_validation3'),
  toDate: Yup.date()
    .min(Yup.ref('fromDate'), 'F1_S7_validation4')
    .typeError('F1_S7_validation9')
    .nullable()
    .required('F3_S7_validation3'),
  statusId: Yup.string().required('F3_S7_validation3'),
  observations: Yup.string()
    .min(2, 'F1_S2_validation1')
    .max(2000, 'Maximum_2000'),
});
