import React, {useState, useEffect} from 'react';
import { Formik } from 'formik';
import { validationSchema } from './Section16.validations';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { useParams } from 'react-router-dom';
import { formInitialValues } from './Section16.initialValues';
import { useTranslation } from 'react-i18next';
import { saveFormSection } from 'services/initiative';
import { f5statusNamesConst, roleNamesConst } from 'constants/appConstants';
import Comments from '../Comments';
import { mapActivityItems } from 'helpers/f5Helpers';
import { isWindows2021 } from "helpers/isWindow2021Helper";
import Form from 'react-bootstrap/Form';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import { RequiredMark } from 'components/CommonStyled';
import {
  StyledFormLabel,
  StyledDivWrapper,
  Title,
  BlackSubtitle,
} from './Section16.styled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const Section16 = () => {
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { formStore, initiativeStore, authStore } = useRootStore();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [nonEditable, setNonEditable] = useState(true);
  const [disabledComments, setDisabledComments] = useState(true);
  const [saved, setSaved] = useState(false);
  const currentRole = authStore.roles[0];
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM;
  const isAtke = currentRole === roleNamesConst.ATKE;
  const isIntpa = currentRole === roleNamesConst.INTPA;
  const isEud = currentRole === roleNamesConst.EUD;
  const [isF7, setIsF7] = useState(false);
  const [window2021, setWindow2021] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);  
  },[saved])

  useEffect(() => {
    if (initiativeStore.code) {
      setWindow2021(isWindows2021(initiativeStore.code))
    }
  }, [initiativeStore.code])
  
  useEffect(() => {
    setNonEditable((initiativeStore.formStateName !== f5statusNamesConst.DRAFT) || (initiativeStore.role !== roleNamesConst.APPLICANT))
  }, [initiativeStore.formStateName])
  
  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {...formInitialValues, ...formStore.formSectionData[0]};
      setInitialValues(formSectionData);
    }
  }, [formStore.formSectionData])

  useEffect(() => {
    if(initiativeStore.formStateName)
      setIsF7(((isIntpa || isEud) && initiativeStore.formStateName !== f5statusNamesConst.DRAFT && initiativeStore.formStateName !== f5statusNamesConst.SENT ));
  }, [initiativeStore.formStateName])

  const handleSubmit = async (values, {resetForm} ) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values);
      formStore.setSectionStatus(sectionId, complete)
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaved = (value) => {
    setSaved(value);
  }

  const handleButton = (value) => {
    setDisabledComments(value);
  }

  return (
    <FormLayout>
      <FormContainer 
        number={16} 
        title={t('F5_S16_title')}
        formId="form5section16"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
        showButtonClarif={!disabledComments}
        >
        <StyledDivWrapper>
          <Formik 
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {( {values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                dirty
            }) => (
              <form onSubmit={handleSubmit} id="form5section16">
                {dirty && (setSaved(false))}
                
                <Title> {t('F5_Subtitle_Assessment_TCI_Implementation')} </Title>

                <BlackSubtitle> {t('F5_S7_Activity_assessment_3')} </BlackSubtitle>
                {mapActivityItems(formStore.formSectionData && formStore.formSectionData[1])}
          
                {window2021 ? (
                  <>
                    <BlackSubtitle> {t('F5_S7_Activity_assessment_4')} </BlackSubtitle>
                    {mapActivityItems(formStore.formSectionData && formStore.formSectionData[2])}

                    <BlackSubtitle> {t('F5_S7_Activity_assessment_5')} </BlackSubtitle>
                    {mapActivityItems(formStore.formSectionData && formStore.formSectionData[3])}
                  </>
                ) : null}


                <Form.Group style={{marginTop: '25px'}}>
                  <StyledFormLabel>
                    {t('F5_S16_Overview_of_TCI')}<RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                      name="overviewOrganization"
                      as="textarea" 
                      rows={8} 
                      style={{resize: 'none'}}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.overviewOrganization}
                      disabled={nonEditable}
                      placeholder={t('Maximum_6500')}
                      className={touched.overviewOrganization && errors.overviewOrganization ? "error" : null}
                  />
                  <FormErrorMessage
                    touched={touched.overviewOrganization}
                    errors={errors.overviewOrganization}
                  />
                </Form.Group>
                
                <Form.Group style={{marginTop: '25px'}}>
                  <StyledFormLabel>
                    {t('F5_S16_support_AT_team')}<RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="supportATTeam"
                    as="textarea" 
                    rows={7} 
                    style={{resize: 'none'}}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.supportATTeam}
                    disabled={nonEditable}
                    placeholder={t('Maximum_6500')}
                    className={touched.supportATTeam && errors.supportATTeam ? "error" : null}
                  />
                  <FormErrorMessage
                    touched={touched.supportATTeam}
                    errors={errors.supportATTeam}
                  />
                </Form.Group>

              </form>
              )}
          </Formik> 

          {(isAtke || isConsortium || isF7 ) &&
             <Comments onSaved={handleSaved} 
                       onButton={handleButton}/>
          }

        </StyledDivWrapper>                 
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section16);


