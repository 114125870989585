import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
    overviewRelationship: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
    overviewParticipation:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
    overviewKnowledge:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
    explainDerivation:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
    explainData:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
    beneficiaryStrengthening:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(6500, 'F2_S9_validation1')
    .nullable(true),
    additionalBenefits:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(6500, 'F2_S9_validation1')
    .nullable(true),
    justificationBeneficiaries:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
});