import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Pagination from 'react-bootstrap/Pagination'

const PaginationController = ({ visibility, totalPages, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(0)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
    onPageChange(pageNumber)
  }

  return visibility ? (
    <Pagination>
      <Pagination.First
        disabled={currentPage == 0}
        onClick={() => handlePageChange(0)}
      />
      {totalPages !== 0 && currentPage !== 0 && currentPage > 1 && (
        <Pagination.Ellipsis />
      )}
      {currentPage !== 0 && (
        <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
          {currentPage - 1}
        </Pagination.Item>
      )}
      <Pagination.Item active> {currentPage} </Pagination.Item>
      {totalPages !== 0 && currentPage !== totalPages - 1 && (
        <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
          {currentPage + 1}
        </Pagination.Item>
      )}
      {totalPages !== 0 && currentPage < totalPages - 2 && (
        <Pagination.Ellipsis />
      )}
      <Pagination.Last
        disabled={currentPage == totalPages - 1}
        onClick={() => handlePageChange(totalPages - 1)}
      />
    </Pagination>
  ) : null
}

export default PaginationController
