import React from 'react';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RequiredMark } from 'components/CommonStyled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';


const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  const getFieldTouched = getIn(formikInstance.touched, field);
  return {
    touched: getFieldTouched,
    error: (
      <FormErrorMessage
        touched={getFieldTouched}
        errors={getFieldError}
      />
    )
  }
}
  
const ConsultantForm = ({ formikInstance, consultantIndex }) => {
  const {t} = useTranslation();
  const { getFieldProps, touched, errors } = formikInstance;
  const fieldPath = (field) => `profileList.${consultantIndex}.${field}`;

  return (
    <>
      <Form.Group as={Row}>
        <Col>
          <Form.Label lg={4} style={{ alignItems: "center" }} >{t('F3_S7_Consultant_qualifications')} <RequiredMark style={{ marginLeft: "7px" }}>*</RequiredMark>:
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_650')}
            name={fieldPath('skills')}
            {...getFieldProps(fieldPath('skills'))}
            className={touched[fieldPath('skills')] && errors[fieldPath('skills')] ? "error" : null}
          />
          {fieldInfo(formikInstance, fieldPath('skills')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <Form.Label lg={4} style={{ alignItems: "center" }} >{t('F3_S7_Consultant_general_experience')} <RequiredMark style={{ marginLeft: "7px" }}>*</RequiredMark>:
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_650')}
            name={fieldPath('generalExp')}
            {...getFieldProps(fieldPath('generalExp'))}
            className={touched[fieldPath('generalExp')] && errors[fieldPath('generalExp')] ? "error" : null}
          />
          {fieldInfo(formikInstance, fieldPath('generalExp')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <Form.Label lg={4} style={{ alignItems: "center" }} >{t('F3_S7_Consultant_specific_experience')} <RequiredMark style={{ marginLeft: "7px" }}>*</RequiredMark>:
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_650')}
            name={fieldPath('specificExp')}
            {...getFieldProps(fieldPath('specificExp'))}
            className={touched[fieldPath('specificExp')] && errors[fieldPath('specificExp')] ? "error" : null}
          />
          {fieldInfo(formikInstance, fieldPath('specificExp')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <Form.Label lg={4} style={{ alignItems: "center" }} >{t('F3_S7_Consultant_requires_translation')} <RequiredMark style={{ marginLeft: "7px" }}>*</RequiredMark>:
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_650')}
            name={fieldPath('interpretation')}
            {...getFieldProps(fieldPath('interpretation'))}
            className={touched[fieldPath('interpretation')] && errors[fieldPath('interpretation')] ? "error" : null}
          />
          {fieldInfo(formikInstance, fieldPath('interpretation')).error}
        </Col>
      </Form.Group>
    </>
  )

}

export default ConsultantForm;
