import React from 'react';
import { useTranslation } from 'react-i18next';

import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import TextValue from 'components/TextValue';
import { StyledFormLabel, TextValueLeftSpaced } from '../Section10.styled';

const EntityForm = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Form.Group>
      <StyledFormLabel as={Row}>{t('F3_S10_Degee_of_involvement')}:</StyledFormLabel>
      <TextValueLeftSpaced>
        <TextValue value={data.degree} />
      </TextValueLeftSpaced>
    </Form.Group>
  )
}

export default EntityForm;