import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledNumberCircle, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  TextValueLeftSpaced,
  StyledSubtitle,
} from 'components/Forms/Form2/Section6/Section6.styled';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import TextValue from 'components/TextValue';

const Section6 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledNumberCircle> 6 </StyledNumberCircle>
          <StyledTitle> {t('F1_S6_title')} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <StyledSubtitle>{t('F1_S6_Subtitle_1')}</StyledSubtitle>

        <Form.Group as={Row}>
          <Form.Label column lg={4}>{t('F1_S6_Question_1')}:</Form.Label>
          <TextValue value={formValues?.name} />
        </Form.Group>  

        <Form.Group as={Row}>
          <Form.Label column lg={4}>{t('F1_S6_Question_2')}:</Form.Label>
          <TextValue value={formValues?.description} />
        </Form.Group>  

        <Form.Group as={Row}>
          <Form.Label column lg={4}>{t('F1_S6_Question_3')}:</Form.Label>
          <TextValue value={formValues?.objective} />
        </Form.Group>  

        <StyledSubtitle> {t('F1_S6_Subtitle_2')} </StyledSubtitle>

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_4')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.latamKnowledge} />
          </TextValueLeftSpaced>
        </Form.Group>  

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_5')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.euKnowledge} />
          </TextValueLeftSpaced>
        </Form.Group>  

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_6')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.newKnowledge} />
          </TextValueLeftSpaced>
        </Form.Group>  

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_7')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.application} />
          </TextValueLeftSpaced>
        </Form.Group>    

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_8')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.contribution} />
          </TextValueLeftSpaced>
        </Form.Group>  

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_9')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.leap} />
          </TextValueLeftSpaced>
        </Form.Group>
          
        <StyledSubtitle> {t('F1_S6_Subtitle_3')} </StyledSubtitle>

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_10')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.benEntities} />
          </TextValueLeftSpaced>
        </Form.Group>  

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_11')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.directBen} />
          </TextValueLeftSpaced>
        </Form.Group>  

        <StyledSubtitle> {t('F1_S6_Subtitle_4')} </StyledSubtitle>

        <Form.Group as={Row}>
          <Form.Label column lg={5}>{t('F1_S6_Question_12')}:</Form.Label>
          <TextValue value={(formValues?.goalPrimaryType && formValues?.goalPrimaryType.id)? t(`SDG_Goal${formValues?.goalPrimaryType.id}`): ' - '} />
        </Form.Group>  

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_13')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.primaryJustification} />
          </TextValueLeftSpaced>
        </Form.Group> 

        <Form.Group as={Row}>
          <Form.Label column lg={5}>{t('F1_S6_Question_14')}:</Form.Label>
          <TextValue value={(formValues?.goalSecondaryType && formValues?.goalSecondaryType.id)? t(`SDG_Goal${formValues?.goalSecondaryType.id}`): ' - '} />
        </Form.Group> 

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_15')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.secondaryJustification} />
          </TextValueLeftSpaced>
        </Form.Group> 

        <StyledSubtitle> {t('F1_S6_Subtitle_5')} </StyledSubtitle>

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_16')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.rights} />
          </TextValueLeftSpaced>
        </Form.Group> 

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_17')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.environment} />
          </TextValueLeftSpaced>
        </Form.Group> 

        <StyledSubtitle> {t('F1_S6_Subtitle_6')} </StyledSubtitle>

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_18')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.innovation} />
          </TextValueLeftSpaced>
        </Form.Group> 


        <StyledSubtitle> {t('F1_S6_Subtitle_7')}</StyledSubtitle>

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_19')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.valueTC} />
          </TextValueLeftSpaced>
        </Form.Group> 

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_20')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.valueEU} />
          </TextValueLeftSpaced>
        </Form.Group> 

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_21')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.valueTP} />
          </TextValueLeftSpaced>
        </Form.Group> 

        <StyledSubtitle> {t('F1_S6_Subtitle_8')} </StyledSubtitle>

        <Form.Group>
          <Form.Label>{t('F1_S6_Question_22')}:</Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.covid19} />
          </TextValueLeftSpaced>
        </Form.Group> 

      </StyledBody>
    </SectionContainer>
  )
}


export default Section6;