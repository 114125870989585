import styled from 'styled-components';

export const StyledTitle = styled.h3`
  margin-bottom: 15px;
`;

export const StyledIntroParagraph = styled.p`
  line-height: 1.3rem;
  text-align: justify;
  margin-left: 50px;
`;

export const StyledVideosContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 70px;
  margin-top: 30px;
`;

export const StyledVideoItem = styled.div`
  display: flex;
  min-width: 400px;
  align-items: center;
`;

export const StyledCheckboxContainer= styled.div`
  display: flex;
  flex-direction: row;
  width: 30px;
`;

export const StyledImageContainer = styled.div`
  margin: 20px;
`;
