import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

export const Text = styled.div`
  margin-left: 50px;
  line-height: 1.25;
  margin-bottom: 30px;
  text-align: justify;
`;

export const StyledSectionWrapper = styled.div`
  padding-left: 50px;
`;

export const StyledFormLabel = styled(Form.Label)`
  text-align: justify;
  line-height: 1.3;
`;
