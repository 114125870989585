import React, { useState, useEffect } from 'react'
import Header3 from '../components/Header3'
import Table3 from '../components/Table3'
import htmlParser from 'html-react-parser';
import { getODSList } from 'services/initiative'
import Row from 'react-bootstrap/Row'
import { StyledDiv, StyledRow, StyledRow2 } from '../../CommonStyled/Tabs.styled'
import { useTranslation } from 'react-i18next';

const InitiativesSummary = () => {
  const { t } = useTranslation();

  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const getData = async (param) => {
    setWaiting(true)
    try {
      const response = await getODSList(param)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <StyledDiv>
      <Row>
        <Header3 data={data} getData={getData} waiting={waiting}  />
      </Row>
      <StyledRow style={{marginLeft: '0px'}}>
        <Table3 data={data} waiting={waiting} setData={setData} />
      </StyledRow>
      <StyledRow2>{htmlParser(t('Statistics_footnote_13'))}</StyledRow2>
    </StyledDiv>
  )
}

export default InitiativesSummary
