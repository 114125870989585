import styled, {css} from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledFormLabel = styled(Form.Label)`
    text-align: justify;
    line-height: 1.3;
`;

export const StyledDivWrapper = styled.div`
  margin-left: 50px;
`;

export const StyledLinkSeparator = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    margin-top: 35px;
    margin-bottom: 16px;
    text.align: justify;
    color: ${theme.brandColors.blue};
    font-weight: 650;
  `}
`;

export const BlackSubtitle = styled.div`
  ${({ theme }) => css`
    margin-top: 35px;
    margin-bottom: 15px;
    text.align: justify;
    color: ${theme.colors.black};
    font-weight: 650;
  `}
`;

export const ActivityItem = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ActivityItemTitle = styled.span`
  white-space: nowrap;
  margin-right: 10px;
  margin-bottom: 15px;
  font-weight: 650;
`;

export const ActivityItemBody = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    white-space: "pre-wrap";
    margin-bottom: 15px;
  `}
`;

export const StyledFakeLink = styled.button`
  padding: 0;
  border: none;
  background: none;
  box-shadow: none !important;
  color: #007bff;
  margin-right: 10px;
  margin-bottom: 15px;
  font-weight: 650;
`;

export const StyledSDGTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    flex-wrap: wrap;
    max-width: 600px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 650;
  `}
`;