import React, { useState } from 'react'
import htmlParser from 'html-react-parser';

import CustomModal from 'components/Modal';
import { Form } from 'react-bootstrap'
import { FormFielErrorMessage, CenteredModalButtonContainer, RequiredMark } from 'components/CommonStyled';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import {ErrorButton} from 'components/Buttons'
import { 
  StyledModalBody,
  Separator,
  StyledSendButton,
} from './CancelInitiativeModal.styled'

const CancelInitiativeModal = ({ show, handleClose, handleConfirm }) => {
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);
  const [justification, setJustification] = useState("");
  const [formError, setFormError] = useState({
    isShow: false,
    msg: ""
  });

  const handleChange = (e) => {
    setFormError({
      isShow: false,
      msg:""
    });
    setJustification(e.target.value);
  }

  const handleSubmit = async () => {
    if(!justification.length > 0){
      setFormError({
        isShow: true,
        msg: t('Error field cannot be empty')
      })
    }
    else if(justification.length < 10){
      setFormError({
        isShow: true,
        msg: t('F1_S7_validation16')
      })
    }
    else if(justification.length > 1300){
      setFormError({
        isShow: true,
        msg: t('Error max length 1300')
      })
    }
    else{
      try{
        setSending(true);
        await handleConfirm(justification);
      }
      catch(e){
        console.log(e)
      } finally{
        setSending(false);
      }
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" animation={false}>
      <StyledModalBody>

        <div style={{textAlign: 'justify'}}>{htmlParser(t('F5_S17_Admin_Cancel_Confirmation_p1'))}</div>

        <Separator />
        
        <Form.Label> {t('F3_S11_Justification')} <RequiredMark>*</RequiredMark>: </Form.Label>
        <Form.Control
          as="textarea"
          style={{resize: 'none'}}
          rows={6} 
          onChange={handleChange}
          value={justification}
          placeholder={t('Maximum_1300')}
        />

        {formError.isShow && <FormFielErrorMessage> {formError.msg} </FormFielErrorMessage>}

        <Separator />

          {htmlParser(t('F5_S17_Admin_Cancel_Confirmation_p2'))}

        <Separator />

        <CenteredModalButtonContainer>
          <ErrorButton style={{marginRight: '20px'}} disabled={sending} onClick={handleClose}> {t('No')} </ErrorButton>
          <StyledSendButton disabled={sending} onClick={() => {handleSubmit()}}> {t('Yes')} </StyledSendButton>
        </CenteredModalButtonContainer>
      </StyledModalBody>
    </Modal>
  )
}

export default CancelInitiativeModal;