import styled, { css } from 'styled-components'

export const MainTitle = styled.div`
  ${({ theme }) => css`
    margin-top: 10px;
    color: ${theme.brandColors.green};
    font-size: 20px;
    font-weight: bold;
  `}
`

export const InitiativeTitle = styled.div`
${({ theme }) => css`
  margin-top: -20px;
  color: ${theme.brandColors.green};
  font-size: 20px;
  font-weight: bold;
`}
`


export const SubTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.brandColors.green};
    font-size: 15px;
    font-weight: 600;
    color: #7390A0;
  `}
`

export const Subtitle2 = styled.div`
  margin-top: 10px;
  color: #7390A0; 
  font-style: italic; 
  font-weight: normal;
  font-size: 10pt;
`

export const ItemText = styled.p`
  margin: 0;
  padding-left: 26pt;
`

export const ReportTitle = styled.div`
  color: #7390A0; 
  font-weight: bold;
  font-size: 20px;
`
