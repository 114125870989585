import React from 'react'
import PageTitle from 'components/PageTitle'
import { PageHeader } from '../../../pages/statistics/CommonStyled/Tabs.styled'
import { useTranslation } from 'react-i18next'
import StatisticsTabs from 'components/Tabs'
import List from './tabs/List/List'
import Gantt from './tabs/Gantt/Gantt'

const ActivityReport = () => {
  const { t } = useTranslation()
  const titles = [
    t('activity_report_tab1'),
    t('activity_report_tab2'),
  ]

  const renderTab1Content = () => <List />
  const renderTab2Content = () => <Gantt />


  const contents = [
    renderTab1Content,
    renderTab2Content,
  ]

  return (
    <>
      <PageHeader>
        <PageTitle title={t('Activities')} />
      </PageHeader>
      <StatisticsTabs titles={titles} contents={contents} />
    </>
  )
}

export default ActivityReport
