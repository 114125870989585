import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
	file: Yup.object()
    .nullable()
    .required('F3_S7_validation3'),
  comments: Yup.string()
    .min(2,  'F3_S7_validation4')
    .max(200, 'F1_S4_validation2')
    .nullable(),
});