import styled, { css } from 'styled-components';
import Card from 'react-bootstrap/Card';

export const AccordionItem = styled(Card)`
  border: none;
  background-color: transparent;
`

export const AccordionItemHeader = styled(Card.Header)`
  border: none;
  background-color: transparent;
`;

export const AccordionItemTitle = styled.h5`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.brandColors.blue};
  `}
`;

export const AccordionItemBody = styled(Card.Body)`
  margin-bottom: 30px;
  padding-bottom: 30px;
`;

export const ArrowImage = styled.img`
  margin-right: 10px;
`