import React from 'react';
import infoIcon from 'assets/icon-info.png';
import Image from 'react-bootstrap/Image';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import htmlParser from 'html-react-parser';


const HelpIcon = (props) => {

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={
        <Popover>
          <Popover.Content>
            {props.info? htmlParser(props.info): ""}
          </Popover.Content>
        </Popover>
      }
    >
      <Button style={{ padding: props.padding ? props.padding: '0.375rem 0.75rem'}} variant="outline-light"> <Image src={infoIcon} /> </Button>
  </OverlayTrigger>
  )
  
}

export default HelpIcon;