import React, {useEffect, useState} from 'react';
import { roleNamesConst } from 'constants/appConstants';
import { getMaintenanceVariables } from "services/apiConstants";
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useRootStore } from 'stores';
import i18n from 'i18n';

import Notifications from 'pages/Notifications';
import Layout from 'layout';
import PrivateRoute from './PrivateRoute';
import { Switch, Route, Redirect } from 'react-router-dom';
import NewHome from 'pages/public/NewHome';
import MaintenanceHome from 'pages/public/MaintenanceHome'
import EUDRoutes from './EUDRoutes';
import IntpaRoutes from './IntpaRoutes';
import ApplicantRoutes from './ApplicantRoutes';
import ATKERoutes from './ATKERoutes';
import CommonRoutes from './CommonRoutes';
import ConsortiumRoutes from './ConsortiumRoutes';
import NotFoundPage from 'pages/public/NotFound';
import { useLocation } from 'react-router-dom';

const AppRouter = () => {
  const history = useHistory();
  const location = useLocation()

  const { initiativeStore, formStore, authStore } = useRootStore();
  const [maintenanceEnabled, setMaintenanceEnabled] = useState(true);

  const isLoggedIn = !!authStore.roles;

  var sessionStorage = window.sessionStorage;
  var lng;


  useEffect(() => {
    checkLangCondition(location)
  }, [location]);

  useEffect(() => {
    history.listen((location) => {
      checkLangCondition(location)
    })
  },[history, initiativeStore, location, location?.pathname])

  useEffect(() => {
    if (location?.pathname?.includes('/initiative') && !location?.pathname?.includes('form/55') && (sessionStorage.getItem('language') !== initiativeStore.language)) {
      checkLangCondition(location)
    }
  }, [initiativeStore.language]);

  const checkLangCondition = (locationObj) => {
    if (locationObj.pathname.includes('/initiative') && !location?.pathname?.includes('form/55')){
      if (!(initiativeStore.language === null)) {
        i18n.changeLanguage(initiativeStore.language);
        // This is to keep the language when the page is reloaded
        sessionStorage.setItem('language', initiativeStore.language);
      }
      // This is so as not to change the language within a concept note
      sessionStorage.setItem('button','disabled');
    }
    else {
      lng = localStorage.getItem('userLanguage') === null? 'en': localStorage.getItem('userLanguage');
      i18n.changeLanguage(lng);
      sessionStorage.setItem('language',lng);
      sessionStorage.setItem('button','nonDisabled');
    }
  }

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getMaintenanceVariables();
        if(response && response[3]){
          setMaintenanceEnabled(response[3].keyValue);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAPI();
  }, [])

  const getBaseRouteByRole = (role) => {
    const routeByRole = {
      [roleNamesConst.INTPA]: IntpaRoutes(),
      [roleNamesConst.EUD]: EUDRoutes(),
      [roleNamesConst.ATKE]: ATKERoutes(),
      [roleNamesConst.ATNKE]: ATKERoutes(),
      [roleNamesConst.CONSORTIUM]: ConsortiumRoutes(),
      [roleNamesConst.PUBLIC]: ApplicantRoutes(),
      [roleNamesConst.GUEST]: ApplicantRoutes(),
    };
    return routeByRole[role];
  }

  return (
    <Switch>
      { authStore.roles && getBaseRouteByRole(authStore.roles[0]) }
      {CommonRoutes()}
      <Route exact path="/">
        {isLoggedIn ? <Redirect to={`${authStore.getBaseRouteByRole()}/concept-notes`} /> : maintenanceEnabled ? <MaintenanceHome /> : <NewHome />}
      </Route>
      <Route exact path="/home">
        {isLoggedIn ? <Redirect to={`${authStore.getBaseRouteByRole()}/concept-notes`} /> : maintenanceEnabled ? <MaintenanceHome /> : <NewHome />}
      </Route>
      {isLoggedIn && (
        <PrivateRoute exact path="/notifications" component={Notifications} layout={Layout} />
      )}
      <Route component={NotFoundPage} />
    </Switch>
  )
}

export default observer(AppRouter);
