import React, { forwardRef } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerContainer, CustomDatePickerButton } from './CustomDatePicker.styled';

const CustomDatePickerInput = forwardRef(({ value, onClick, disabled }, ref) => (
  <CustomDatePickerButton onClick={onClick} ref={ref} disabled={disabled}>
    {value}
  </CustomDatePickerButton>
));

const CustomDatePicker = ({ value, onChange, disabled}) => {
  return (
    <DatePickerContainer>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        selected={value}
        onChange={onChange}
        className={null}
        disabled={disabled}
        customInput={
          <CustomDatePickerInput />
        }
      />
    </DatePickerContainer>
  )
}

export default CustomDatePicker;