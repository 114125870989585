import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  assessmentCovid19: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  interventionLogic:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  startDate: Yup.date()
    .typeError('F1_S7_validation9'),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), 'F1_S7_validation4')
    .typeError('F1_S7_validation9'),
});