import i18n from 'i18n'
import {
  Td,
  Th1Pointer,
  Th2,
  Th3Pointer,
  Td2
} from '../../CommonStyled/Components.styled'
import {
  orderByInitiativeCode,
  orderBySring,
} from 'pages/statistics/CommonHelpers/helpers'
import {
  renderRow,
  renderTotalForColumns,
  renderTotalForColumnsForExport,
} from 'pages/statistics/CommonHelpers/helpers'
import _orderBy from 'lodash/orderBy'
import { INITIATIVE_TITLE_ROW, FUNDS_TITLE_ROW } from 'constants/appConstants'
import React from 'react'

export const T1RenderRows = (rows) =>
  rows.map((row) => (
    <tr>
      <Td>{row?.code ? row?.code : '-'}</Td>
      <Td>{row?.name ? row?.name : '-'}</Td>
      <Td>
        {row?.beneficiaryType
          ? i18n.t(`beneficiary_type_${row?.beneficiaryType}`)
          : row?.beneficiaryType == 0
          ? i18n.t(`beneficiary_type_${row?.beneficiaryType}`)
          : '-'}
      </Td>
      <Td>{row?.countryName ? i18n.t(row?.countryName) : '-'}</Td>
      <Td>{row?.countryRegion ? i18n.t(row?.countryRegion) : '-'}</Td>
      <Td>{row?.quantity ? Intl.NumberFormat('es-AR').format(row.quantity) : row?.quantity === 0 ? 0 : '-'}</Td>
    </tr>
  ))

export const orderDataByField = (data, field, setData, setAsc, asc) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'name':
      orderedData = orderBySring(data, field, asc)
      break
    case 'beneficiaryType':
      orderedData = _orderBy(
        data,
        [
          (item) =>
            item?.beneficiaryType
              ? i18n.t(`beneficiary_type_${item?.beneficiaryType}`)
              : item?.beneficiaryType == 0
              ? i18n.t(`beneficiary_type_${item?.beneficiaryType}`)
              : '',
        ],
        asc[field] ? ['asc'] : ['desc'],
      )
      break
    case 'code':
      orderedData = orderByInitiativeCode(data, field, asc)
      break
    case 'countryRegion':
      orderedData = _orderBy(
        data,
        [(item) => (item?.countryRegion ? i18n.t(item?.countryRegion) : '')],
        asc[field] ? ['asc'] : ['desc'],
      )
      break
    case 'countryName':
      orderedData = _orderBy(
        data,
        [(item) => (item?.countryName ? i18n.t(item?.countryName) : '')],
        asc[field] ? ['asc'] : ['desc'],
      )
      break
    default:
      orderedData = _orderBy(data, [field], asc[field] ? ['asc'] : ['desc'])
  }

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}

export const getBeneficiariesDetailExportValues = (exportData) => {
  let data = []
  exportData.forEach((item) => {
    data.push({
      col1: item.code,
      col2: item.name,
      col3: item?.beneficiaryType
        ? i18n.t(`beneficiary_type_${item?.beneficiaryType}`)
        : item?.beneficiaryType == 0
        ? i18n.t(`beneficiary_type_${item?.beneficiaryType}`)
        : '-',
      col4: item?.countryName ? i18n.t(item?.countryName) : '-',
      col5: item?.countryRegion ? i18n.t(item?.countryRegion) : '-',
      col6: item.quantity,
    })
  })
  return data
}

/*--------------------------------------------------------------------- */

export const T6RenderRows = (rows, years) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(`beneficiary_type_${row?.category}`)}</Th2>
        {renderRow(row, years)}
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      {renderTotalForColumns(rows, years)}
    </tr>
  </>
)

export const orderDataByFieldToSummary = (
  data,
  field,
  setData,
  setAsc,
  asc,
) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'category':
      orderedData = _orderBy(
        data,
        [(field) => i18n.t(`beneficiary_type_${field?.category}`)],
        asc[field] ? ['asc'] : ['desc'],
      )
      break
    default:
      orderedData = _orderBy(data, [field], asc[field] ? ['asc'] : ['desc'])
  }

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}

export const renderHeaders = (
  years,
  data,
  setData,
  setAscendingOrder,
  ascendingOrder,
) => (
  <>
    {years.map((year) => (
      <Th1Pointer
        width="10%"
        scope="col"
        onClick={() =>
          orderDataByFieldToSummary(
            data,
            year,
            setData,
            setAscendingOrder,
            ascendingOrder,
          )
        }
      >
        {year} &#8645;
      </Th1Pointer>
    ))}
    <Th1Pointer
      width="10%"
      scope="col"
      onClick={() =>
        orderDataByFieldToSummary(
          data,
          'total',
          setData,
          setAscendingOrder,
          ascendingOrder,
        )
      }
    >
      {i18n.t('F1_S7_Total')} &#8645;
    </Th1Pointer>
  </>
)

export const getBeneficiaryByTypeSummaryExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData?.forEach((row) => {
    data.push({
      label: i18n.t(`beneficiary_type_${row?.category}`),
      col1: row['2021'],
      col2: row['2022'],
      col3: row['2023'],
      col4: row['2024'],
      col5: row?.total,
    })
  })
  let row = renderTotalForColumnsForExport(exportData, [
    '2021',
    '2022',
    '2023',
    '2024',
  ])
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: row[1],
    col3: row[2],
    col4: row[3],
    col5: row[4],
  })
  return data
}

export const getFundsBeneficiaryByTypeSummaryExportValues = (exportData) => {
  let data = []
  data.push(FUNDS_TITLE_ROW)
  exportData?.forEach((row) => {
    data.push({
      label: i18n.t(`beneficiary_type_${row?.category}`),
      col1: row['total'],
      col2: '',
      col3: '',
      col4: '',
      col5: '',
    })
  })
  let row = renderTotalForColumnsForExport(exportData, [
    '2021',
  ])
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: '',
    col3: '',
    col4: '',
    col5: '',
  })
  return data
}


/*--------------------------------------------------------------------- */

export const T2RenderRows = (rows, years) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(`${row.category}`)}</Th2>
        {renderRow(row, years)}
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      {renderTotalForColumns(rows, years)}
    </tr>
  </>
)

export const orderDataByFieldToSummary2 = (
  data,
  field,
  setData,
  setAsc,
  asc,
) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'category':
      orderedData = _orderBy(
        data,
        [(field) => i18n.t(field.category)],
        asc[field] ? ['asc'] : ['desc'],
      )
      break
    default:
      orderedData = _orderBy(data, [field], asc[field] ? ['asc'] : ['desc'])
  }

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}

export const renderHeaders2 = (years, handleOrderData) => (
  <>
    <Th3Pointer
      width="50%"
      scope="col"
      onClick={() => handleOrderData('category')}
    >
      {i18n.t('Statistics_beneficiaries_tab3')} &#8645;
    </Th3Pointer>
    {years.map((year) => (
      <Th1Pointer width="10%" scope="col" onClick={() => handleOrderData(year)}>
        {year} &#8645;
      </Th1Pointer>
    ))}
    <Th1Pointer
      width="10%"
      scope="col"
      onClick={() => handleOrderData('total')}
    >
      {i18n.t('F1_S7_Total')} &#8645;
    </Th1Pointer>
  </>
)

export const getEntitiesByCountrySummaryExportValues = (exportData) => {
  let data = []
  data.push(INITIATIVE_TITLE_ROW)
  exportData.forEach((country) => {
    data.push({
      label: i18n.t(`${country?.category}`),
      col1: country['2021'],
      col2: country['2022'],
      col3: country['2023'],
      col4: country['2024'],
      col5: country?.total,
    })
  })
  let row = renderTotalForColumnsForExport(exportData, [
    '2021',
    '2022',
    '2023',
    '2024',
  ])
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: row[1],
    col3: row[2],
    col4: row[3],
    col5: row[4],
  })
  return data
}

export const getFundsEntitiesByCountrySummaryExportValues = (exportData) => {
  let data = []
  data.push(FUNDS_TITLE_ROW)
  exportData.forEach((country) => {
    data.push({
      label: i18n.t(`${country?.category}`),
      col1: country['total'],
      col2: '',
      col3: '',
      col4: '',
      col5: '',
    })
  })
  let row = renderTotalForColumnsForExport(exportData, [
    '2021',
  ])
  data.push({
    label: i18n.t('F1_S7_Total'),
    col1: row[0],
    col2: '',
    col3: '',
    col4: '',
    col5: '',
  })
  return data
}

export const getTotalBeneficiaries = (data) =>
  Intl.NumberFormat('es-AR').format(
    data?.reduce((accumulator, item) => accumulator + item.quantity, 0),
  )

export const T3RenderRows = (rows) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(`${row.category}`)}</Th2>
        <Td>{Intl.NumberFormat('es-AR').format(row.total)}</Td>
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      <Td2>{Intl.NumberFormat('es-AR').format(rows?.reduce((acc, curr) => acc + curr?.total, 0))}</Td2>
    </tr>
  </>
)

export const T4RenderRows = (rows) => (
  <>
    {rows.map((row) => (
      <tr>
        <Th2>{i18n.t(`beneficiary_type_${row?.category}`)}</Th2>
        <Td>{Intl.NumberFormat('es-AR').format(row.total)}</Td>
      </tr>
    ))}
    <tr>
      <Th2>{i18n.t('F1_S7_Total')}</Th2>
      <Td2>{Intl.NumberFormat('es-AR').format(rows?.reduce((acc, curr) => acc + curr?.total, 0))}</Td2>
    </tr>
  </>
)