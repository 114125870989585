import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getCountries } from 'services/initiative'

import { renderCountriesOptions } from 'helpers/renderCountriesHelper'

import { Form } from 'react-bootstrap'
import { PrimaryButton } from 'components/Buttons'
import {
  Col1,
  StyledFormLabel,
  StyledFormGroup2,
  FullRow,
} from '../../CommonStyled/Components.styled'
import { getExportValues } from '../helpers/helpers'
import ExportToXLSXButton from 'components/ExportToXLSXButton'
import { ExportCountryReportToXLSX } from 'helpers/xlsxStatisticsExporter'

const Header = ({
  getData,
  waiting,
  preFormulationData,
  formulationData,
  implementationData,
  entitiesByRoleData,
  entitiesByFunctionData,
  entitiesByTypeData,
  entitiesFromCountryData,
  activitiesFromCountryData,
  activitiesByRoleData,
  activitiesByTypeData,
  beneficiariesByTypeData,
  beneficiariesDetailData,
}) => {
  const { t } = useTranslation()
  const [countriesOptions, setCountriesOptions] = useState([])
  // filters
  const [countryFilter, setCountryFilter] = useState(0)
  const [yearFilter, setYearFilter] = useState(0)

  const [includeOnExport, setIncludeOnExport] = useState(false)

  useEffect(() => {
    getCountriesOptions()
  }, [])

  const getCountriesOptions = async () => {
    try {
      const response = await getCountries()
      setCountriesOptions(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilter = () => {
    getData({ countryId: countryFilter, year: yearFilter })
  }

  return (
    <>
      <FullRow>
        <Col1>
          <StyledFormGroup2>
            <StyledFormLabel>{t('F1_S2_Country')}</StyledFormLabel>
            <Form.Control
              name="countryId"
              as="select"
              onChange={(e) => {
                setCountryFilter(e.target.value)
              }}
              value={countryFilter}
              disabled={false}
            >
              {renderCountriesOptions(countriesOptions)}
            </Form.Control>
          </StyledFormGroup2>

          {/* <StyledFormGroup2>
            <StyledFormLabel>{t('Dashboard_Year')}</StyledFormLabel>
            <Form.Control
              name="year"
              as="select"
              onChange={(e) => {
                setYearFilter(e.target.value)
              }}
              value={yearFilter}
            >
              {renderYearOptions(TARGET_YEARS)}
            </Form.Control>
          </StyledFormGroup2>*/}

          <PrimaryButton
            onClick={() => {
              handleFilter()
            }}
            disabled={waiting}
          >
            {t('Button_search')}
          </PrimaryButton>
        </Col1>
      </FullRow>
      <FullRow>
        <Col1>
          <Form.Check
            type="checkbox"
            checked={includeOnExport}
            value={includeOnExport}
            onClick={() => setIncludeOnExport(!includeOnExport)}
            label={t('export_checkbox1')}
            style={{ marginRight: '20px' }}
          />
          <ExportToXLSXButton
            data={{
              preFormulationData: preFormulationData,
              formulationData: formulationData,
              implementationData: implementationData,
              entitiesByRoleData: entitiesByRoleData,
              entitiesByFunctionData: entitiesByFunctionData,
              entitiesByTypeData: entitiesByTypeData,
              entitiesFromCountryData: entitiesFromCountryData,
              activitiesFromCountryData: activitiesFromCountryData,
              activitiesByRoleData: activitiesByRoleData,
              activitiesByTypeData: activitiesByTypeData,
              beneficiariesByTypeData: beneficiariesByTypeData,
              beneficiariesDetailData: beneficiariesDetailData,
              includeOnExport: includeOnExport,
            }}
            mapData={getExportValues}
            disabled={waiting}
            exportName={t('menu_admin_6_8.1')}
          >
            <ExportCountryReportToXLSX label={t('menu_admin_6_8.1')} data={[]} />
          </ExportToXLSXButton>
        </Col1>
      </FullRow>
    </>
  )
}

export default Header
