import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const PageContent = styled.section`
  margin-top: 3rem;
`;

export const CenteredRow = styled(Row)`
  justify-content: center;
`;

export const StyledCol = styled(Col)`
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-top: 18px;
    justify-content: center;
`;
