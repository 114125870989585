import isEmpty from 'lodash/isEmpty';

export const addUserHelperF5S2 = (list) => {
  return list ? list.length >= 3 : false
}

export const addUserHelperF5S3 = (data) => {
  let dataCopy = data
  let emails = []
  let i = 0
  let usersList = dataCopy.pop()
  if (usersList && isEmpty(usersList)) {
    return false
  } else {
    dataCopy.forEach((element) => {
      emails[i] = element.email
      i++
    })
    const addedUsers = usersList.filter(
      (element) => !emails.includes(element.email),
    )
    return { disableButton: addedUsers.length >= 3, list: addedUsers }
  }
}
