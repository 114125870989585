import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

import {PrimaryButton} from 'components/Buttons';
import AddBeneficiaryDialog from '../AddBeneficiaryDialog';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextValue from 'components/TextValue';
import { StyledFormLabel, TextValueLeftSpaced } from '../Section10.styled';

const BeneficiaryForm = ({ data }) => {
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);
  const handleOpenModal = () => setDisplayModal(true);
  const handleCloseModal = () => setDisplayModal(false);

  return (
    <>
      { displayModal && <AddBeneficiaryDialog
        data={data}
        displayDialog={displayModal}
        onCloseModal={handleCloseModal} 
      /> }

      <Form.Group as={Row}>
        <StyledFormLabel xs={4}>{t('F3_S10_Type_beneficiary')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.beneficiaryType ? t(`beneficiary_type_${data.beneficiaryType.id}`) : ' - '} />
        </TextValueLeftSpaced>
      </Form.Group>


      <Form.Group>
        <StyledFormLabel as={Row}>{t('F3_S10_Description')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.beneficiaryDescription} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group as={Row}> 
        <StyledFormLabel xs={4}>{t('F3_S10_Beneficiary_quantificiation')}:</StyledFormLabel>
        <Col xs={2}>
          <Form.Group as={Row}>
            <TextValueLeftSpaced>
              <TextValue value={data.beneficiaryQuantification} />
            </TextValueLeftSpaced>
          </Form.Group>
        </Col>
        <Col>
          <PrimaryButton onClick={() => {handleOpenModal()}}> {t('F3_S10_Beneficiary_button_percountry')}  </PrimaryButton>
        </Col>
      </Form.Group> 
      
      <Form.Group>
        <StyledFormLabel as={Row}>{t('F3_S10_Justification')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.beneficiaryJustification} />
        </TextValueLeftSpaced>
      </Form.Group>

      <Form.Group>
        <StyledFormLabel as={Row}>{t('F3_S10_Prospects_for_harnessing')}:</StyledFormLabel>
        <TextValueLeftSpaced>
          <TextValue value={data.beneficiaryProspect} />
        </TextValueLeftSpaced>
      </Form.Group>

    </>
  )
}

export default BeneficiaryForm;