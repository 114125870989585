import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Formik } from 'formik'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { validationSchema } from './AddPersonForm.validations'
import {
  RequiredMark,
  CenteredModalButtonContainer,
} from 'components/CommonStyled'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CustomModal from 'components/Modal'
import { ErrorButton, PrimaryButton } from 'components/Buttons'
import ConfirmCloseModal from 'components/ConfirmCloseModal'
import htmlParser from 'html-react-parser'
import { addF5S2Applicant, addF5S3CoApplicant } from 'services/initiative'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const ReplacePersonForm = (props) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [closeModalShow, setCloseModalShow] = useState(false)
  const [sending, setSending] = useState(false)
  const [modal, setModal] = useState({
    isOpen: false,
    message: '',
    type: 'default',
  })

  useEffect(() => {}, [])

  const handleSubmit = async (values) => {
    setSending(true)
    let response
    try {
      if (props.isF5S2) {
        response = await addF5S2Applicant(id, values)
      } else {
        response = await addF5S3CoApplicant(id, values)
      }
    } catch (error) {
      console.log(error)
    }

    if (response) {
      setModal({
        ...modal,
        message: t('Success'),
        type: 'success',
        isOpen: true,
      })
      setTimeout(function () {
        setModal({ isOpen: false, message: '', type: 'info' })
        setSending(false)
        window.location.replace('')
        props.onHide()
      }, 2000)
    } else {
      setSending(false)
    }
  }

  return (
    <Modal {...props} centered size="lg" animation={false}>
      <Modal.Body>
        {modal.isOpen && (
          <CustomModal
            show={modal.isOpen}
            onHide={() => {
              setModal({ isOpen: false, message: '', type: 'info' })
              setSending(false)
              window.location.replace('')
              props.onHide()
            }}
            message={modal.message}
            type={modal.type}
          />
        )}

        {closeModalShow && (
          <ConfirmCloseModal
            show={closeModalShow}
            handleCloseConfirm={() => {
              setCloseModalShow(false)
            }}
            handleActionOnConfirm={() => {
              props.onHide()
            }}
            text={t('Confirmation close modal')}
          />
        )}

        <div style={{ marginBottom: '20px', textAlign: 'justify' }}>
          {props.isF5S2
            ? htmlParser(t('F5_S2_Authorize_admin'))
            : htmlParser(t('F5_S3_Authorize_guest'))}
        </div>

        <Formik
          enableReinitialize
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit}>
              <Form.Group as={Row} style={{ alignItems: 'center' }}>
                <Form.Label column lg={4}>
                  {t('F1_S2_Email')}
                  <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Col lg={8}>
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className={touched.email && errors.email ? 'error' : null}
                  />
                  <FormErrorMessage
                    touched={touched.email}
                    errors={errors.email}
                  />
                </Col>
              </Form.Group>

              <CenteredModalButtonContainer>
                <ErrorButton
                  disabled={sending}
                  onClick={() => setCloseModalShow(true)}
                >
                  {t('Button_cancel')}
                </ErrorButton>
                <PrimaryButton
                  type="submit"
                  disabled={sending}
                  style={{
                    marginTop: '15px',
                    marginBottom: '15px',
                    marginLeft: '15px',
                  }}
                >
                  {t('Button_save')}
                </PrimaryButton>
              </CenteredModalButtonContainer>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ReplacePersonForm
