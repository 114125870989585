import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { getIn, useFormikContext } from 'formik';
import AddBeneficiaryDialog from '../AddBeneficiaryDialog';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RoundedAddDiv, StyledButtonLink, DivButtonWrapper } from '../AddBeneficiaryDialog/Styled'
import { RequiredMark } from 'components/CommonStyled';
import TextValue from 'components/TextValue';
import _isNil from 'lodash/isNil';
import _sumBy from 'lodash/sumBy';
import { StyledFormLabel } from './Styled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const BeneficiaryForm = ({ beneficiaryIndex, countries, benTypes }) => {
  const { t } = useTranslation();
  const { getFieldProps, touched, errors, values } = useFormikContext();

  const fieldPath = (field) => `benList.${beneficiaryIndex}.${field}`

  const [displayModal, setDisplayModal] = useState(false);

  const handleOpenModal = () => setDisplayModal(true);
  const handleCloseModal = () => setDisplayModal(false);

  const fieldInfo = (field) => {
    return (
      <FormErrorMessage
        touched={getIn(touched, field)}
        errors={getIn(errors, field)}
      />
    )
  }

  return (
    <>
      { displayModal && (
        <AddBeneficiaryDialog
          beneficiaryIndex={beneficiaryIndex}
          countries={countries}
          displayDialog={displayModal}
          onCloseModal={handleCloseModal}
        /> )
      }
      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S10_type_of_beneficiary')}<RequiredMark>*</RequiredMark>:</Form.Label>
        <Col lg={4}>
          <Form.Control
            as="select"
            name={fieldPath('beneficiaryTypeId')}
            { ...getFieldProps(fieldPath('beneficiaryTypeId')) }
            className={touched[fieldPath('beneficiaryTypeId')] && errors[fieldPath('beneficiaryTypeId')] ? "error" : null}
          >
            <option value="">{t('Placeholder_select')}</option>
            {benTypes.map(beneficiary => (
              <option key={beneficiary.id} value={beneficiary.id}>{t(`beneficiary_type_${beneficiary.id}`)}</option>
            ))}
          </Form.Control>
          {fieldInfo(fieldPath('beneficiaryTypeId')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column xs={4}>{t('F3_S10_Beneficiary_quantificiation')}<RequiredMark>*</RequiredMark>:</Form.Label>
        <Col xs={2}>
          <InputGroup>
            <Form.Control
              type="number"
              min="0"
              name={fieldPath('beneficiaryQuantification')}
              value={values.benList[beneficiaryIndex]?.answersFundS10BenCountryDTOList?.reduce(function (acc, obj) { return acc + obj.quantity }, 0)}
              disabled={true}
            />
          </InputGroup>
        </Col>
        <Col>
          <StyledButtonLink type="button" onClick={() => {handleOpenModal()}}>
            <DivButtonWrapper>
              <RoundedAddDiv>
                +
              </RoundedAddDiv>
              {t('F3_S10_Add_direct_beneficiaries')}
            </DivButtonWrapper>
          </StyledButtonLink>
        </Col>
      </Form.Group>

      {values.benList[beneficiaryIndex]?.answersFundS10BenCountryDTOList ? (
        <>
          {values.benList[beneficiaryIndex]?.answersFundS10BenCountryDTOList.map(
            (item, key) => {
              const countryName = countries?.find(country => country?.id == item?.countryId);
              return (
                <Row
                  key={key}
                  style={{
                    marginLeft: "20px",
                    marginBottom: '16px'
                  }}
                >
                  <Col xs lg="2">
                    <StyledFormLabel>
                      {t(countryName?.name)}:
                    </StyledFormLabel>
                  </Col>
                  <Col xs lg="2">
                    <TextValue
                      value={item.quantity}
                    />
                  </Col>
                </Row>
              )
            })
          }
        </>
      ) : null}
    </>
  )
}

export default BeneficiaryForm;