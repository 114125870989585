import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Container,
  LeftContent,
  CenterContent,
  RightContent,
  ObjectFitImage,
  AdelanteLogo,
  StyledNoPaddingLogo,
  StyledLenguajeSelector,
  StyledLink,
  StyledButtonLink
} from './PublicHeader.styled';

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import profileIcon from "assets/icon-account.png"
import logoEN from "assets/logo-A2-EN.png";
import logoES from "assets/logo-A2-ES.png";
import extranetLogo from "assets/extranet-logo.png";

import { useTranslation } from 'react-i18next';


const Header = () => {

  const location = useLocation();
  const { t, i18n } = useTranslation();
  var localStorage = window.localStorage;

  const handleLanguage = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem('userLanguage',value);
    // This is to keep the language when the page is reloaded
    sessionStorage.setItem('language',value);
  }

  return (
    <Container>
      <LeftContent>
        <Link to="/home" > <AdelanteLogo  src={(i18n.language === 'en')? logoEN:logoES} alt="Adelante2 logo" style={{ objectFit: 'contain'}} /> </Link>
      </LeftContent>
      <CenterContent>
      </CenterContent>
      <RightContent>
        <Col xs={8}>
          <Row>
            <StyledNoPaddingLogo src={extranetLogo} alt="Extranet logo" />
          </Row>
        </Col>
        <Col xs={4}/>
        <Col xs={6}>
          <Row className="justify-content-md-end">
          <StyledLenguajeSelector> <StyledButtonLink  variant="link" onClick={() => {handleLanguage('en')}}>{t('English')}</StyledButtonLink> | <StyledButtonLink  variant="link" onClick={() => {handleLanguage('es')}}> {t('Spanish')}</StyledButtonLink> </StyledLenguajeSelector>
          </Row>
          <Row className="justify-content-md-center">
            <ObjectFitImage src={profileIcon}/>{location.pathname==='/login'? <StyledLink to="/register"> {t('Home_register')} </StyledLink>: <StyledLink to="/login"> {t('Home_login')} </StyledLink>}
          </Row>
        </Col>
      </RightContent>
    </Container>

  )
}

export default observer(Header);