import React, { useEffect, useState } from 'react'
import Proptypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import {
  ModalFooter,
  FooterLeft,
  FooterCenter,
  FooterRight,
  StyledModalBody,
  StyledGreenP,
} from './EUDAssessmentDialog.styled'
import { PrimaryButton } from 'components/Buttons'
import { formInitialValues } from '../../Section18.initialValues'
import { useTranslation } from 'react-i18next'

const EUDAssessmentDialog = ({
  values,
  displayDialog,
  onCloseModal,
  isIntpa,
}) => {
  const { t } = useTranslation()
  const [currentValues, setCurrentValues] = useState(formInitialValues)

  useEffect(() => {
    const formValues = { ...values }
    swapValue(formValues)
    setCurrentValues(formValues)
  }, [values])

  const swapValue = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (!obj[key]) {
        obj[key] = ' - '
      }
    })
  }

  return (
    <Modal
      show={displayDialog}
      onHide={onCloseModal}
      size="lg"
      animation={false}
    >
      {currentValues.user && (
        <Modal.Header>
          <h5>
            {t('Assessment_by') +
              ' ' +
              currentValues.user.organization +
              ', ' +
              currentValues.user.name +
              ' ' +
              currentValues.user.lastName}
          </h5>
        </Modal.Header>
      )}
      <StyledModalBody>
        <StyledGreenP>
          {t('Received_on') +
            ' ' +
            (currentValues.stateTimestamp
              ? currentValues.stateTimestamp.split('T')[0]
              : '-')}
        </StyledGreenP>
        <br />
        <br />
        {!isIntpa ? (
          <>
            <StyledGreenP>{t('F7_EUD_assessment_question1')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ1}
              </span>
            </p>
            <StyledGreenP>{t('F7_EUD_assessment_question2')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ2}
              </span>
            </p>
            <StyledGreenP>{t('F7_EUD_assessment_question3')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ3}
              </span>
            </p>
            <StyledGreenP>{t('F7_EUD_assessment_question4')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ4}
              </span>
            </p>
            <StyledGreenP>{t('F7_EUD_assessment_question5')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ5}
              </span>
            </p>
            <StyledGreenP>{t('F7_EUD_assessment_question6')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ6}
              </span>
            </p>
            <StyledGreenP>{t('F7_EUD_assessment_question7')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ7}
              </span>
            </p>
            <StyledGreenP>{t('F7_EUD_assessment_question8')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ8}
              </span>
            </p>
            <StyledGreenP>{t('F7_EUD_assessment_question9')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ9}
              </span>
            </p>
          </>
        ) : (
          <>
            <StyledGreenP>{t('F7_INTPA_assessment_question1')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ1}
              </span>
            </p>
            <StyledGreenP>{t('F7_INTPA_assessment_question2')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ2}
              </span>
            </p>
            <StyledGreenP>{t('F7_INTPA_assessment_question3')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ3}
              </span>
            </p>
            <StyledGreenP>{t('F7_INTPA_assessment_question4')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ4}
              </span>
            </p>
            <StyledGreenP>{t('F7_INTPA_assessment_question5')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ5}
              </span>
            </p>
            <StyledGreenP>{t('F7_INTPA_assessment_question6')}</StyledGreenP>
            <p>
              <span
                style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
              >
                {currentValues.assessmentQ6}
              </span>
            </p>
          </>
        )}
      </StyledModalBody>
      <ModalFooter>
        <FooterLeft />
        <FooterCenter>
          <PrimaryButton onClick={onCloseModal}>
            {t('Button_close')}
          </PrimaryButton>
        </FooterCenter>
        <FooterRight />
      </ModalFooter>
    </Modal>
  )
}

EUDAssessmentDialog.propTypes = {
  values: Proptypes.object,
  displayDialog: Proptypes.bool,
  onCloseModal: Proptypes.func,
}

export default EUDAssessmentDialog
