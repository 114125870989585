import axiosInstance from './axiosInstance';

export const getAllService = async () => {
  const url = '/api/notification';
  try {  
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw new Error (error)
  }
}

export const markAllAsReadService = async () => {
  const url = '/api/notification/read';
  try {  
    const response = await axiosInstance.put(url);
    return response;
  } catch (error) {
    throw new Error (error)
  }
}

export const markReadByID = async (id) => {
  const url = `/api/notification/read/${id}`;
  try {  
    const response = await axiosInstance.put(url);
    return response;
  } catch (error) {
    throw new Error (error)
  }
}

export const markDoneByID = async (id) => {
  const url = `/api/notification/done/${id}`;
  try {  
    const response = await axiosInstance.put(url);
    return response;
  } catch (error) {
    throw new Error (error)
  }
}

export const markUndoneByID = async (id) => {
  const url = `/api/notification/undone/${id}`;
  try {  
    const response = await axiosInstance.put(url);
    return response;
  } catch (error) {
    throw new Error (error)
  }
}