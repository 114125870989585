import React, { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CenteredModalButtonContainer } from 'components/CommonStyled';
import { modifyDates } from 'services/initiative';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ErrorButton, PrimaryButton } from 'components/Buttons';
import { FormFielErrorMessage } from 'components/CommonStyled';

const DateModal = ({startDate, endDate, field, onHide, show}) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams();

  const handleSubmit = async (values) => {
    setDisabled(true);
    try {
      await modifyDates(id, values);
      onHide();
    } catch (error) {
      console.log(error);
      setDisabled(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Body>
        <div style={{textAlign: 'justify', marginBottom: '20px'}}>{t('F5_S8_date_confirmation')}</div>
        <Formik
          enableReinitialize
          initialValues={{ startDate: startDate, endDate: endDate }}
          validationSchema={Yup.object().shape({
            startDate: Yup.date().max(Yup.ref('endDate'), t('F1_S7_validation4_2')).typeError(t('F1_S7_validation9')),
            endDate: Yup.date()
              .min(Yup.ref('startDate'), t('F1_S7_validation4'))
              .typeError(t('F1_S7_validation9')),
          })}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <form onSubmit={() => handleSubmit(values)} id="changeDate">
              <Row style={{ alignItems: 'center', marginLeft: '5px'}}>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={values[field]}
                  onChange={(date) => setFieldValue(field, date)}
                  className={
                    touched[field] && errors[field] ? 'error' : null
                  }
                />
              </Row>
              {errors[field] ? (
                <Row style={{ marginLeft: '5px'}}>
                  <FormFielErrorMessage>
                    {errors[field]}
                  </FormFielErrorMessage>
                </Row>
              ) : null}

              <CenteredModalButtonContainer>
                <ErrorButton
                  onClick={() => {
                    onHide();
                  }}
                >
                  {t('Button_cancel')}
                </ErrorButton>
                <PrimaryButton
                  type="submit"
                  form="changeDate"
                  style={{
                    marginTop: '15px',
                    marginBottom: '15px',
                    marginLeft: '20px',
                  }}
                  disabled={disabled}
                >
                  {t('Button_save')}
                </PrimaryButton>
              </CenteredModalButtonContainer>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default DateModal;
