import React, {useState} from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import { sendConceptNote } from 'services/initiative';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { useRootStore } from "stores";
import Proptypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import { ModalFooter, FooterLeft, FooterCenter, FooterRight } from './SendCNDialog.styled';
import { ErrorButton, PrimaryButton } from 'components/Buttons';


const SendCNDialog = ({ title, displayDialog, onCloseModal }) => {
  const { id, formId } = useParams();
  const [sending, setSending] = useState(false);
  const history = useHistory();
  const {t} = useTranslation();
  const { authStore } = useRootStore();

  const sendCNAction = async () => {
    try {
      setSending(true);
      await sendConceptNote(id, formId);
      history.push(`${authStore.getBaseRouteByRole()}/concept-notes`)
    } catch (error) {
      console.log(error);
    }
    finally{
      onCloseModal();
      setSending(false);
    }
  }

  return (
    <Modal show={displayDialog} onHide={onCloseModal} size="lg" animation={false}>
      <Modal.Header>
        <h5>{ title }</h5>
      </Modal.Header>
      <Modal.Body>
        {htmlParser(t('F1_confirmation_to_send'))}
      </Modal.Body>
      <ModalFooter>
        <FooterLeft />
        <FooterCenter>
          <PrimaryButton disabled={sending} onClick={() => sendCNAction()}>{t('Yes')}</PrimaryButton>
          <ErrorButton disabled={sending} onClick={onCloseModal}>{t('No')}</ErrorButton>
        </FooterCenter>
        <FooterRight />
      </ModalFooter>
    </Modal>
  )
}

SendCNDialog.propTypes = {
  title: Proptypes.string,
  displayDialog: Proptypes.bool,
  onCloseModal: Proptypes.func,
};

export default observer(SendCNDialog);
