import React, { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { validationSchema } from './Section18.validations';
import { formInitialValues } from './Section18.initialValues';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router-dom';
import { useRootStore } from 'stores';
import { roleNamesConst } from 'constants/appConstants';
import {
  saveF6Assessment,
  saveF7AssessmentINTPA,
  saveF7AssessmentEUD,
  cancelInitiativeIntpa,
  rollbackCancelInitiativeIntpa,
  finalizeF5,
  sendF5BackToApplicant,
  getAssessment,
  sendF6ToMainAssessor,
  sendF7,
  getCancelJustification,
  createNewF8,
  getFormSection,
  getEudList,
} from 'services/initiative';
import htmlParser from 'html-react-parser';
import { f5statusNamesConst } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';

import ReadOnlyATAssessment from './ReadOnlyATAssessment';
import SendToMainAssessorModal from './SendToMainAssessorModal';
import SendINTPAF7Modal from './SendINTPAF7Modal';
import CancelInitiativeModal from './CancelInitiativeModal';
import ConfirmCloseModal from 'components/ConfirmCloseModal';
import EUDAssessments from './EUDAssessments';
import INTPAAssessmentForm from './INTPAAssessmentForm';
import EUDAssessmentForm from './EUDAssessmentForm';
import ATAssessmentForm from './ATAssessmentForm';
import CustomModal from 'components/Modal';
import FormLayout from '../../FormLayout';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { ErrorButton, PrimaryButton } from 'components/Buttons';
import { SeparatorDotted } from 'components/CommonStyled';
import TextValue from 'components/TextValue';
import { StyledTitle } from './Section18.styled';
import { StyledGreenFormLabel } from 'components/Forms/Forms.styled';
import { StyledTitle as StyledTitleButton } from './ReadOnlyATAssessment/ReadOnlyATAssessment.styled';

const Section18 = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const formRef = useRef();
  const { authStore, initiativeStore, formStore } = useRootStore();

  const [modalError, setModalError] = useState(false);
  const [nonEditable, setNonEditable] = useState(true);
  const [sending, setSending] = useState(false);

  const [level, setLevel] = useState(false);
  const [displayModalSend, setDisplayModalSend] = useState(false);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [saved, setSaved] = useState(false);
  const [cancelJustification, setCancelJustification] = useState(null);

  const role = authStore.roles[0];
  const isIntpa = role === roleNamesConst.INTPA;
  const isEud = role === roleNamesConst.EUD;
  const isAT = role === roleNamesConst.ATKE;
  const isATNKE = role === roleNamesConst.ATNKE;
  const isCONSORTIUM = role === roleNamesConst.CONSORTIUM;

  const [notSentandDraft, setNotSentandDraft] = useState(false);

  useEffect(() => {
    setNotSentandDraft(initiativeStore.formStateName !== f5statusNamesConst.DRAFT &&
      initiativeStore.formStateName !== f5statusNamesConst.SENT);
  }, [initiativeStore.formStateName]);

  const [isF6, setIsF6] = useState(false);

  const isF6NoHook = initiativeStore.formStateName === f5statusNamesConst.SENT ||
  initiativeStore.formStateName === f5statusNamesConst.DRAFT;

  useEffect(() => {
    setIsF6(initiativeStore.formStateName === f5statusNamesConst.SENT ||
      initiativeStore.formStateName === f5statusNamesConst.DRAFT);
  }, [initiativeStore.formStateName]);

  const isApplicant = role === roleNamesConst.PUBLIC;

  const [eudAssessments, setEUDAssessments] = useState([]);

  const [cancelInitiativeModalInfo, setCancelInitiativeModalInfo] = useState({
    isShow: false,
  });
  const [isConfirmModalShow, setIsConfirmModalShow] = useState({
    isShow: false,
    msg: '',
    onConfirm: () => {},
  });
  const [modalIntpaF7Open, setModalIntpaF7Open] = useState(false);

  async function fetchAPIData() {
    try {
      await fetchStatus();
      if (
        initiativeStore.formStateName !== f5statusNamesConst.CANCELLED &&
        !(isIntpa && (isF6NoHook || initiativeStore.formStateName === null)) &&
        !isApplicant
      ) {
        const response = await getAssessment(id);
        if (isAT || isATNKE || isCONSORTIUM) {
          setFormValues({
            formValues,
            intpaAssessment: {
              assessment: response.intpaAssessment,
              state: response.intpaState,
              stateTimestamp: response.intpaStateTimestamp,
            },
            ...response.atAssessment,
            level: response.atAssessment.level ? 'true' : 'false',
            role: 0,
          });
          setLevel(response.atAssessment.level);
          if (isATNKE) {
            setNonEditable(true);
          }
          if (
            !isF6 ||
            (initiativeStore.assessmentStatus &&
              initiativeStore.assessmentStatus === 'DONE')
          ) {
            setNonEditable(true);
          } else {
            setNonEditable(false);
          }
        } else if (isIntpa) {
          setFormValues({
            formValues,
            ...response.intpaAssessment,
            atAssessment: response.atAssessment,
            level: response.intpaAssessment.level ? 'true' : 'false',
            role: 2,
          });
          setLevel(response.intpaAssessment.level);
          if (
            isF6 ||
            (initiativeStore.assessmentStatus &&
              initiativeStore.assessmentStatus === 'DONE')
          ) {
            setNonEditable(true);
          } else {
            setNonEditable(false);
          }
        } else if (isEud) {
          setFormValues({
            formValues,
            ...response.eudAssessments[0].assessment,
            status: response.eudAssessments[0].status,
            level: response.eudAssessments[0].level ? 'true' : 'false',
            role: 1,
          });
          setLevel(response.eudAssessments[0].level);
          if (
            isF6 ||
            (response.eudAssessments[0] &&
              (response.eudAssessments[0].state === 'DONE' ||
                response.eudAssessments[0].state === 'EXPIRED'))
          ) {
            setNonEditable(true);
          } else {
            setNonEditable(false);
          }
        }
        setEUDAssessments(response.eudAssessments);
      }
      if (
        isIntpa && (initiativeStore.formStateName === f5statusNamesConst.DRAFT ||
        initiativeStore.formStateName === f5statusNamesConst.SENT)
      ) {
        const response = await getEudList('F5', id, true);
        setEUDAssessments(response?.data?.map(item => {
          return {
            ...item,
            user: item
          }
        }));
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchStatus() {
    try {
      if (isApplicant) {
        const response = await getFormSection(id, 5, 19);
        setFormValues({
          justification: response?.section?.[0] || '',
        });
      } else {
        const response = await getCancelJustification(id);
        setCancelJustification(response);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchAPIData();
  }, [isF6, isIntpa, initiativeStore.formStateName]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  const handleSendBackButton = () => {
    setIsConfirmModalShow({
      isShow: true,
      msg: htmlParser(t('F5_S17_Admin_SendBack_Confirmation')),
      onConfirm: () => sendBackF5(id),
    });
  };

  const handleFinalizeInitiativeButton = () => {
    setIsConfirmModalShow({
      isShow: true,
      msg: htmlParser(t('F5_S17_Admin_Finalize_Confirmation')),
      onConfirm: () => finalizeInitiative(id),
    });
  };

  const handleCancelInitiativeButton = () => {
    setCancelInitiativeModalInfo({
      isShow: true,
    });
  };

  const handleRollbackCancelInitiativeButton = () => {
    setIsConfirmModalShow({
      isShow: true,
      msg: t('F5_S17_Admin_Cancel_Rollback_Confirmation'),
      onConfirm: () => rollbackCancelInitiative(id),
    });
  };

  const handleRequestExpostButton = () => {
    setIsConfirmModalShow({
      isShow: true,
      msg: htmlParser(t('F5_S18_Request_Expost_Confirmation')),
      onConfirm: () => requestExpost(id),
    });
  };

  const cancelInitiative = async (justification) => {
    try {
      await cancelInitiativeIntpa(id, {
        justification: justification,
      });
      if (isAT) {
        history.push(
          `${authStore.getBaseRouteByRole()}/management/implementation-forms`
        );
      } else {
        history.push(
          `${authStore.getBaseRouteByRole()}/management/final-assessments`
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const rollbackCancelInitiative = async (initiativeId) => {
    try {
      await rollbackCancelInitiativeIntpa(initiativeId);
      if (isAT) {
        history.push(
          `${authStore.getBaseRouteByRole()}/management/implementation-forms`
        );
      } else {
        history.push(
          `${authStore.getBaseRouteByRole()}/management/final-assessments`
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sendBackF5 = async (initiativeId) => {
    try {
      await sendF5BackToApplicant(initiativeId);
      history.push(
        `${authStore.getBaseRouteByRole()}/management/implementation-forms`
      );
    } catch (e) {
      console.log(e);
    }
  };

  const finalizeInitiative = async (initiativeId) => {
    try {
      await finalizeF5(initiativeId);
      if (isAT) {
        history.push(
          `${authStore.getBaseRouteByRole()}/management/implementation-forms`
        );
      } else {
        history.push(
          `${authStore.getBaseRouteByRole()}/management/final-assessments`
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenModalIntpaF7 = () => {
    setModalIntpaF7Open(true);
  };

  const handleCloseModalIntpaF7 = () => {
    setModalIntpaF7Open(false);
  };

  const requestExpost = async (id) => {
    try {
      setSending(true);
      await createNewF8(id);
      if (isAT) {
        history.push(
          `${authStore.getBaseRouteByRole()}/management/implementation-forms`
        );
      } else {
        history.push(
          `${authStore.getBaseRouteByRole()}/management/final-assessments`
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  };

  const onSendF7 = async (id) => {
    try {
      setSending(true);
      await handleSave(formRef.current.values, formRef.current.resetForm);
      await sendF7(id);
      history.push(
        `${authStore.getBaseRouteByRole()}/management/final-assessments`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  };

  const handleSendF7 = async () => {
    try {
      setSending(true);
      onValidateSend(formRef.current.isValid, onSendF7);
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  };

  const onSendF6 = async (id) => {
    try {
      setSending(true);
      await handleSave(formRef.current.values, formRef.current.resetForm);
      await sendF6ToMainAssessor(id);
      history.push(
        `${authStore.getBaseRouteByRole()}/management/implementation-forms`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  };

  const handleSendF6 = async () => {
    try {
      setSending(true);
      onValidateSend(formRef.current.isValid, onSendF6);
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  };

  const handleSendToMainAssessor = () => {
    handleOpenModalSend();
  };

  const handleSave = async (values, resetForm) => {
    try {
      setSending(true);
      isF6
        ? await saveF6Assessment(id, values)
        : isIntpa
        ? await saveF7AssessmentINTPA(id, values)
        : await saveF7AssessmentEUD(id, values);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    } finally {
      setSending(false);
    }
  };

  const handleOpenModalSend = () => {
    setDisplayModalSend(true);
  };

  const handleCloseModalSendToAssessor = () => setDisplayModalSend(false);

  const onValidateSubmit = async (isValid, values, resetForm) => {
    isValid ? await handleSave(values, resetForm) : alert(t('not_saved_alert'));
  };

  const onValidateSend = async (isValid, onSend) => {
    isValid ? await onSend(id) : alert(t('not_saved_alert'));
  };

  return (
    <FormLayout>
      <SendToMainAssessorModal
        show={displayModalSend}
        onClose={handleCloseModalSendToAssessor}
        onSend={isEud ? handleSendF7 : handleSendF6}
        setError={setModalError}
        isEud={isEud}
      />

      <SendINTPAF7Modal
        show={modalIntpaF7Open}
        onClose={handleCloseModalIntpaF7}
        onSend={handleSendF7}
        setError={() => setModalError(true)}
      />

      <ConfirmCloseModal
        show={isConfirmModalShow.isShow}
        handleCloseConfirm={() =>
          setIsConfirmModalShow({
            isShow: false,
          })
        }
        handleActionOnConfirm={isConfirmModalShow.onConfirm}
        text={isConfirmModalShow.msg}
      />

      <CancelInitiativeModal
        show={cancelInitiativeModalInfo.isShow}
        handleClose={() =>
          setCancelInitiativeModalInfo({
            ...cancelInitiativeModalInfo,
            isShow: false,
          })
        }
        handleConfirm={(justification) => cancelInitiative(justification)}
      />

      <CustomModal
        show={modalError}
        onHide={() => setModalError(false)}
        message={t('Error try again later')}
        type="error"
      />

      <Formik
        enableReinitialize
        initialValues={formValues}
        validationSchema={validationSchema}
        innerRef={formRef}
        onSubmit={handleSave}
      >
        {(props) => (
          <Form
            style={{ minWidth: '100%' }}
            id="form5section18"
            onSubmit={props.handleSubmit}
          >
            {props.dirty && setSaved(false)}

            {isIntpa && (
              <div style={{ marginTop: '25px', marginLeft: '15px' }}>
                <StyledTitleButton>
                  {t('F6_S18_button_assessment')}
                </StyledTitleButton>
                <ErrorButton
                  onClick={() => handleCancelInitiativeButton()}
                  disabled={
                    initiativeStore.formStateName !== f5statusNamesConst.DRAFT
                  }
                >
                  {t('F5_S17_Admin_Cancel')}
                </ErrorButton>
              </div>
            )}

            {(isAT || isCONSORTIUM || isATNKE || isIntpa) && (
              <div style={{ marginLeft: '15px', marginTop: '20px' }}>
                <StyledTitle> {t('Grid_status')} </StyledTitle>
                <div style={{ marginBottom: '20px' }}>
                  <Col>
                    <Row>
                      {`${t('F5_S7_status')}: ${
                        cancelJustification
                          ? cancelJustification.status.name
                          : ''
                      }`}
                    </Row>
                  </Col>
                </div>

                {!isIntpa && (
                  <Col xs={6}>
                    <Row style={{ marginTop: '25px' }}>
                      <PrimaryButton
                        onClick={() => handleSendBackButton()}
                        disabled={
                          initiativeStore.formStateName !==
                          f5statusNamesConst.SENT
                        }
                      >
                        {t('F5_S17_Admin_SendBack')}
                      </PrimaryButton>
                    </Row>
                    <Row style={{ marginTop: '25px' }}>
                      <PrimaryButton
                        disabled={
                          initiativeStore.formStateName !==
                          f5statusNamesConst.EXPOST
                        }
                        onClick={handleRequestExpostButton}
                      >
                        {t('F6_S18_Expost')}
                      </PrimaryButton>
                    </Row>
                    <Row style={{ marginTop: '25px' }}>
                      <ErrorButton
                        onClick={() => handleFinalizeInitiativeButton()}
                        disabled={
                          initiativeStore.formStateName !==
                          f5statusNamesConst.EXPOST
                        }
                      >
                        {t('F5_S17_Admin_Finalize')}
                      </ErrorButton>
                    </Row>
                  </Col>
                )}

                {initiativeStore.formStateName === f5statusNamesConst.CANCELLED && (
                  <>
                    <div style={{ marginBottom: '20px' }}>
                      <Col>
                        <Row style={{ marginTop: '10px' }}>
                          <span style={{ fontWeight: '600' }}>
                            {' '}
                            {t('F3_S11_Justification')}:{' '}
                          </span>
                        </Row>
                        <Row style={{ marginLeft: '-30px' }}>
                          <TextValue
                            value={
                              cancelJustification
                                ? cancelJustification.cancelJustification
                                : ''
                            }
                          />
                        </Row>
                      </Col>
                    </div>
                    <ErrorButton
                      style={{ visibility: 'hidden' }}
                      onClick={() => handleRollbackCancelInitiativeButton()}
                      disabled={
                        initiativeStore.formStateName !==
                        f5statusNamesConst.CANCELLED
                      }
                    >
                      {t('F5_S17_Admin_Cancel_Rollback')}
                    </ErrorButton>
                  </>
                )}

                <SeparatorDotted />

                {!isIntpa && notSentandDraft && (
                  <>
                    <EUDAssessments
                      assessmentList={eudAssessments}
                      isIntpa={isIntpa}
                      intpaAssessment={props.values.intpaAssessment}
                      nonEditable={false}
                    />

                    <SeparatorDotted />
                  </>
                )}

                {!isIntpa && (
                  <ATAssessmentForm
                    formikInstance={props}
                    nonEditable={nonEditable}
                    handleSendToMainAssessor={handleSendToMainAssessor}
                    saved={saved}
                    sending={sending}
                    onValidateSubmit={onValidateSubmit}
                    isSent={
                      initiativeStore.formStateName ===
                      f5statusNamesConst.SENT
                    }
                    levelChecked={level}
                  />
                )}
              </div>
            )}

            {isEud && (
              <EUDAssessmentForm
                formikInstance={props}
                nonEditable={nonEditable}
                handleSendToMainAssessor={handleSendToMainAssessor}
                onValidateSubmit={onValidateSubmit}
                saved={saved}
                sending={sending}
              />
            )}

            {isIntpa && (
              <div style={{ marginLeft: '15px', marginTop: '20px' }}>
                {!(isIntpa && isF6) && (
                  <>
                    <ReadOnlyATAssessment data={props.values.atAssessment} />
                    <SeparatorDotted />
                  </>
                )}

                <EUDAssessments
                  isIntpa
                  intpaAssessment={null}
                  assessmentList={eudAssessments}
                  nonEditable={initiativeStore.assessmentStatus === 'DONE'}
                />

                {!(isIntpa && isF6) && (
                  <>
                    <SeparatorDotted />
                    <INTPAAssessmentForm
                      formikInstance={props}
                      nonEditable={nonEditable}
                      handleCloseImplementationPhase={handleOpenModalIntpaF7}
                      onValidateSubmit={onValidateSubmit}
                      saved={saved}
                      sending={sending}
                    />
                  </>
                )}
              </div>
            )}

            {isApplicant && (
              <div
                style={{
                  marginLeft: '15px',
                  marginTop: '20px',
                  marginRight: '20px',
                }}
              >
                <StyledTitle> {t('Grid_status')} </StyledTitle>
                <div style={{ marginBottom: '20px' }}>
                  <Col>
                    <Row>
                      {`${t('F5_S7_status')}: ${
                        initiativeStore.formStateName
                      }`}
                    </Row>
                  </Col>

                  <SeparatorDotted />

                  <Col>
                    <Row style={{ marginTop: '10px' }}>
                      <StyledGreenFormLabel>
                        {' '}
                        {t('F7_EUD_assessment_question9')}{' '}
                      </StyledGreenFormLabel>
                    </Row>
                    <Row style={{ marginLeft: '-15px' }}>
                      <Form.Control
                        as="textarea"
                        style={{ resize: 'none' }}
                        rows={8}
                        value={formValues.justification}
                        disabled
                      />
                    </Row>
                  </Col>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </FormLayout>
  );
};

export default observer(Section18);
