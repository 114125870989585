import React from 'react'
import { Link } from 'react-router-dom'
import { useRootStore } from 'stores'
import { useTranslation } from 'react-i18next'
import { formatDateDDMMYY } from 'helpers/dateHelpers'

import { StyledTr, StyledSpan } from './TableRow.styled'
import NewMessagesTableIndicator from 'components/NewMessagesTableIndicator'
import { NewMessagesTableContainer } from 'components/Forms/Forms.styled'

import { f5statusNamesConst } from 'constants/appConstants'

const TableRow = ({ item }) => {
  const { t } = useTranslation()
  const { initiativeStore } = useRootStore()
  const todayDate = new Date()

  const setInitiativeOnStore = (initiative) => {
    initiativeStore.setInitiativeData(initiative)
  }

  return (
    <StyledTr key={item.id}>
      <td style={{ minWidth: '100px' }}>
        <Link
          onClick={() => setInitiativeOnStore(item)}
          to={`/applicant/initiative/${item.id}/form/5/section/1`}
        >
          {item.code}
        </Link>
      </td>
      <td style={{ maxWidth: '600px', overflowWrap: 'break-word' }}>
        {item.name ? item.name : t('Name_not_defined_yet')}
      </td>
      <td>{item.startDate ? formatDateDDMMYY(item.startDate) : '-'}</td>
      <td>{item.endDate ? formatDateDDMMYY(item.endDate) : '-'}</td>
      <td style={{ minWidth: '100px' }}>{t(item.formStateName)}</td>
      <td>
        {item.formDeadline &&
        item.formStateName === f5statusNamesConst.DRAFT ? (
          <StyledSpan expired={todayDate > new Date(item.formDeadline)}>
            {formatDateDDMMYY(item.formDeadline)}
          </StyledSpan>
        ) : (
          ' - '
        )}
      </td>
      <td>
        <NewMessagesTableContainer>
          <NewMessagesTableIndicator hasNewMessages={item.unreadComments > 0} />
        </NewMessagesTableContainer>
      </td>
    </StyledTr>
  )
}

export default TableRow
