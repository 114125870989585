import styled from 'styled-components';

export const StyledAccordionBodyDiv = styled.div`
margin-left: 20px;
`;

export const StyledEUDLink = styled.div`
display: inline;
text-decoration: underline;
color: blue;
font-weight: 550;
cursor: pointer;
`;