import Form from 'react-bootstrap/Form';
import styled, { css } from 'styled-components';
import {PrimaryButton} from 'components/Buttons';

export const StyledDiv = styled.div`
  margin-left: 50px;
  line-height: 20px;
`;

export const StyledSavedClarifButton = styled(PrimaryButton)`
  ${({ theme }) => css`
  &:hover {
    background-color: ${theme.brandColors.lightBlueHover};
  }
  &:disabled {
    background-color:${theme.brandColors.green};
    color: white;
    border: ${theme.colorGroups.success.bgColor};
  }
  `}
`;

export const ClarificationsLinkContainer = styled(StyledDiv)`
  margin-bottom: 20px;
`

export const RequestClarificationLabel = styled(Form.Label)`
  ${({theme}) => css`
    padding-left: 65px;
    color: ${theme.brandColors.green};
  `}
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
`;

export const ModalTitle = styled.h5`
  ${({ theme }) => css`
    color: ${theme.colors.gray[1]};
  `}
`;
