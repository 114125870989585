import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { validationSchema } from './Section6.validations';
import { initialValues } from './Section6.initialValues';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { saveFormSection } from 'services/initiative';
import { useConstantsForm1 } from 'customHooks/useConstantsForm1';
import {f1statusNamesConst} from 'constants/appConstants';
import HelpIcon from 'components/HelpIcon';

import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';

import { useTranslation } from 'react-i18next';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { RequiredMark } from 'components/CommonStyled';
import {
  StyledFormLabel,
  StyledSubtitle
} from './Section6.styled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const Section6 = () => {

  const {t} = useTranslation();
  const { formStore, initiativeStore } = useRootStore();
  const { id, formId, sectionId } = useParams();
  const [formValues, setFormValues] = useState(initialValues);
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const { developmentGoals } = useConstantsForm1();


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (formStore.formSectionData) {
      setFormValues({
        name: setStringFieldValue(formStore.formSectionData[0].name),
        description: setStringFieldValue(formStore.formSectionData[0].description),
        objective: setStringFieldValue(formStore.formSectionData[0].objective),
        latamKnowledge: setStringFieldValue(formStore.formSectionData[0].latamKnowledge),
        euKnowledge: setStringFieldValue(formStore.formSectionData[0].euKnowledge),
        newKnowledge: setStringFieldValue(formStore.formSectionData[0].newKnowledge),
        application: setStringFieldValue(formStore.formSectionData[0].application),
        leap: setStringFieldValue(formStore.formSectionData[0].leap),
        contribution: setStringFieldValue(formStore.formSectionData[0].contribution),
        benEntities: setStringFieldValue(formStore.formSectionData[0].benEntities),
        directBen: setStringFieldValue(formStore.formSectionData[0].directBen),
        goalPrimaryTypeId: (formStore.formSectionData[0].goalPrimaryType? formStore.formSectionData[0].goalPrimaryType.id: ""),
        primaryJustification: setStringFieldValue(formStore.formSectionData[0].primaryJustification),
        goalSecondaryTypeId: (formStore.formSectionData[0].goalSecondaryType? formStore.formSectionData[0].goalSecondaryType.id: ""),
        secondaryJustification: setStringFieldValue(formStore.formSectionData[0].secondaryJustification),
        rights: setStringFieldValue(formStore.formSectionData[0].rights),
        environment: setStringFieldValue(formStore.formSectionData[0].environment),
        innovation: setStringFieldValue(formStore.formSectionData[0].innovation),
        valueTC: setStringFieldValue(formStore.formSectionData[0].valueTC),
        valueEU: setStringFieldValue(formStore.formSectionData[0].valueEU),
        valueTP: setStringFieldValue(formStore.formSectionData[0].valueTP),
        valueSR: setStringFieldValue(formStore.formSectionData[0].valueSR),
        covid19: setStringFieldValue(formStore.formSectionData[0].covid19),
      });
    }
  }, [formStore.formSectionData])

  const [clarification, setClarification] = useState({
    show: false,
    msg: "",
  });
  useEffect(() => {
    if(initiativeStore.formStateName === f1statusNamesConst.DRAFT){
      if(initiativeStore.formVersion === 2){
        if(formStore.formSectionClarification){
          setNonEditable(false);
          setClarification({
            show: true,
            msg: (formStore.formSectionClarification? formStore.formSectionClarification: t('No_clarifications'))
          });
        }
        else{
          setNonEditable(true);
        }
      }
      else{
        setNonEditable(false);
      }
    }
    else{
      setNonEditable(true);
    }
  }, [initiativeStore.currentFormStateName, initiativeStore.formVersion, formStore.formSectionClarification])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);
  }, [saved])

  const onSubmit = async (values, {resetForm}) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values);
      formStore.setSectionStatus(sectionId, complete)
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const setStringFieldValue = (newValue) => {
    return (newValue? newValue:"" );
  }

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid
      ? onSubmit(values, { resetForm })
      : alert(t('not_saved_alert'));
  }

  return (
    <FormLayout>
      <FormContainer
        number={6}
        title={t('F1_S6_title')}
        isHelp={true}
        formId='form1section6'
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
      >
          <Formik
            enableReinitialize
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
          {( {values,
              errors,
              touched,
              dirty,
              isValid,
              resetForm,
              handleChange,
              handleBlur }) => (
                <form
                  id="form1section6"
                  onSubmit={(e) => {onValidateSubmit(e, isValid, values, { resetForm })}}
                >
                  {dirty && (setSaved(false))}
                    { clarification.show &&
                    <Form.Group
                      style={{marginLeft: '50px', paddingTop: '10px'}}
                      as={Row}
                    >
                      <Form.Label>{t('Clarification_requests')}</Form.Label>
                      <Form.Control
                        as="textarea"
                        style={{border: '2px solid red', resize: 'none'}}
                        rows={4}
                        disabled={true}
                        value={clarification.msg}
                      />
                    </Form.Group>}

                    <StyledSubtitle> {t('F1_S6_Subtitle_1')}
                    </StyledSubtitle>
                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={3}>{t('F1_S6_Question_1')}<RequiredMark>*</RequiredMark>:</StyledFormLabel>
                      <Col xs={5} md={9}>
                        <Form.Control
                            type="text"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Placeholder_initiative_name')}
                            value={values.name}
                            className={touched.name && errors.name ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.name}
                          errors={errors.name}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={3}>{t('F1_S6_Question_2')}<RequiredMark>*</RequiredMark>:
                       <HelpIcon padding={'0 0 0 0.75rem'} info={t('F1_S6_Question_2_INFO')}/>
                      </StyledFormLabel>
                      <Col xs={5} md={9}>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            style={{resize: 'none'}}
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_1300')}
                            value={values.description}
                            className={touched.description && errors.description ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.description}
                          errors={errors.description}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={3}>{t('F1_S6_Question_3')}<RequiredMark>*</RequiredMark>:
                        <HelpIcon padding={'0 0 0 0.75rem'} info={t('F1_S6_Question_3_INFO')}/>
                      </StyledFormLabel>
                      <Col xs={5} md={9}>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          style={{resize: 'none'}}
                          name="objective"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={t('Maximum_350')}
                          value={values.objective}
                          className={touched.objective && errors.objective ? "error" : null}
                          disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.objective}
                          errors={errors.objective}
                        />
                      </Col>
                    </Form.Group>

                    <StyledSubtitle> {t('F1_S6_Subtitle_2')}
                    </StyledSubtitle>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>{t('F1_S6_Question_8')}<RequiredMark>*</RequiredMark>:</StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="contribution"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_1300')}
                            value={values.contribution}
                            className={touched.contribution && errors.contribution ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.contribution}
                          errors={errors.contribution}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={9}>{t('F1_S6_Question_4')}<RequiredMark>*</RequiredMark>:
                        <HelpIcon info={t('F1_S6_Question_4_INFO')}/>
                      </StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="latamKnowledge"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_1300')}
                            value={values.latamKnowledge}
                            className={touched.latamKnowledge && errors.latamKnowledge ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.latamKnowledge}
                          errors={errors.latamKnowledge}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>{t('F1_S6_Question_5')}<RequiredMark>*</RequiredMark>:
                        <HelpIcon info={t('F1_S6_Question_5_INFO')}/>
                      </StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="euKnowledge"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_1300')}
                            value={values.euKnowledge}
                            className={touched.euKnowledge && errors.euKnowledge ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.euKnowledge}
                          errors={errors.euKnowledge}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>{t('F1_S6_Question_6')}<RequiredMark>*</RequiredMark>:</StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="newKnowledge"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_1300')}
                            value={values.newKnowledge}
                            className={touched.newKnowledge && errors.newKnowledge ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.newKnowledge}
                          errors={errors.newKnowledge}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>{t('F1_S6_Question_7')}<RequiredMark>*</RequiredMark>:</StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="application"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_1300')}
                            value={values.application}
                            className={touched.application && errors.application ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.application}
                          errors={errors.application}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>{t('F1_S6_Question_9')}:</StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="leap"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_1300')}
                            value={values.leap}
                            className={touched.leap && errors.leap ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.leap}
                          errors={errors.leap}
                        />
                      </Col>
                    </Form.Group>

                    <StyledSubtitle> {t('F1_S6_Subtitle_3')}
                      <HelpIcon info={t('F1_S6_Subtitle_3_INFO')}/>
                    </StyledSubtitle>

                    <Form.Group as={Row} style={{marginTop: '-5px'}}>
                      <StyledFormLabel column xs={8}>{t('F1_S6_Question_10')}<RequiredMark>*</RequiredMark>:
                        <HelpIcon info={t('F1_S6_Question_10_INFO')}/>
                      </StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="benEntities"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_1300')}
                            value={values.benEntities}
                            className={touched.benEntities && errors.benEntities ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.benEntities}
                          errors={errors.benEntities}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>{t('F1_S6_Question_11')}<RequiredMark>*</RequiredMark>:
                        <HelpIcon info={t('F1_S6_Question_11_INFO')}/>
                      </StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="directBen"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_1300')}
                            value={values.directBen}
                            className={touched.directBen && errors.directBen ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.directBen}
                          errors={errors.directBen}
                        />
                      </Col>
                    </Form.Group>

                    <StyledSubtitle> {t('F1_S6_Subtitle_4')}
                      <HelpIcon info={t('F1_S6_Subtitle_4_INFO')}/>
                    </StyledSubtitle>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={5}>{t('F1_S6_Question_12')}<RequiredMark>*</RequiredMark>:</StyledFormLabel>
                      <Col xs={5}>
                        <Form.Control
                          name="goalPrimaryTypeId"
                          as="select"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.goalPrimaryTypeId}
                          className={touched.goalPrimaryTypeId && errors.goalPrimaryTypeId ? "error" : null}
                          disabled={nonEditable}
                        >
                          <option value="">{t('Placeholder_select')}</option>
                          {developmentGoals.map(devGoal => (
                            <option key={devGoal.id} value={devGoal.id}>{t(`SDG_Goal${devGoal.id}`)}</option>
                          ))}
                        </Form.Control>
                        <FormErrorMessage
                          touched={touched.goalPrimaryTypeId}
                          errors={errors.goalPrimaryTypeId}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>{t('F1_S6_Question_13')}</StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="primaryJustification"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_650')}
                            value={values.primaryJustification}
                            className={touched.primaryJustification && errors.primaryJustification ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.primaryJustification}
                          errors={errors.primaryJustification}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} style={{alignItems: "center"}}>
                      <StyledFormLabel column xs={5}>{t('F1_S6_Question_14')}:</StyledFormLabel>
                      <Col xs={5}>
                        <Form.Control
                          name="goalSecondaryTypeId"
                          as="select"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.goalSecondaryTypeId}
                          className={touched.goalSecondaryTypeId && errors.goalSecondaryTypeId ? "error" : null}
                          disabled={nonEditable}
                        >
                          <option value="">{t('Placeholder_select')}</option>
                          {developmentGoals.map(devGoal => (
                            <option key={devGoal.id} value={devGoal.id}>{t(`SDG_Goal${devGoal.id}`)}</option>
                          ))}

                        </Form.Control>
                        <FormErrorMessage
                          touched={touched.goalSecondaryTypeId}
                          errors={errors.goalSecondaryTypeId}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>{t('F1_S6_Question_15')}</StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="secondaryJustification"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_650')}
                            value={values.secondaryJustification}
                            className={touched.secondaryJustification && errors.secondaryJustification ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.secondaryJustification}
                          errors={errors.secondaryJustification}
                        />
                      </Col>
                    </Form.Group>

                    <StyledSubtitle> {t('F1_S6_Subtitle_5')}
                      <HelpIcon info={t('F1_S6_Subtitle_5_INFO')}/>
                    </StyledSubtitle>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>
                        {t('F1_S6_Question_16')}
                        <RequiredMark>*</RequiredMark>:
                        <HelpIcon padding={'0 0 0 0.75rem'} info={t('F1_S6_Question_16_INFO')}/>
                      </StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="rights"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_650')}
                            value={values.rights}
                            className={touched.rights && errors.rights ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.rights}
                          errors={errors.rights}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>
                        {t('F1_S6_Question_17')}
                        <RequiredMark>*</RequiredMark>:
                        <HelpIcon padding={'0 0 0 0.75rem'} info={t('F1_S6_Question_17_INFO')}/>
                      </StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="environment"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_650')}
                            value={values.environment}
                            className={touched.environment && errors.environment ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.environment}
                          errors={errors.environment}
                        />
                      </Col>
                    </Form.Group>

                    <StyledSubtitle> {t('F1_S6_Subtitle_6')}
                      <HelpIcon info={t('F1_S6_Subtitle_6_INFO')}/>
                    </StyledSubtitle>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>{t('F1_S6_Question_18')}:</StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="innovation"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_650')}
                            value={values.innovation}
                            className={touched.innovation && errors.innovation ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.innovation}
                          errors={errors.innovation}
                        />
                      </Col>
                    </Form.Group>

                    <StyledSubtitle> {t('F1_S6_Subtitle_7')}
                       <HelpIcon info={t('F1_S6_Subtitle_7_INFO')}/>
                    </StyledSubtitle>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>{t('F1_S6_Question_19')}<RequiredMark>*</RequiredMark>:</StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="valueTC"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_1300')}
                            value={values.valueTC}
                            className={touched.valueTC && errors.valueTC ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.valueTC}
                          errors={errors.valueTC}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>{t('F1_S6_Question_20')}<RequiredMark>*</RequiredMark>:</StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="valueEU"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_1300')}
                            value={values.valueEU}
                            className={touched.valueEU && errors.valueEU ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.valueEU}
                          errors={errors.valueEU}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>
                        {t('F1_S6_Question_21')}
                        <RequiredMark>*</RequiredMark>:
                        <HelpIcon info={t("F1_S6_Question_21_INFO")} />
                      </StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="valueTP"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_1300')}
                            value={values.valueTP}
                            className={touched.valueTP && errors.valueTP ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.valueTP}
                          errors={errors.valueTP}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>
                        {t('F1_S6_Question_21b')}
                        <RequiredMark>*</RequiredMark>:
                      </StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="valueSR"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_1300')}
                            value={values.valueSR}
                            className={touched.valueSR && errors.valueSR ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.valueSR}
                          errors={errors.valueSR}
                        />
                      </Col>
                    </Form.Group>

                    <StyledSubtitle> {t('F1_S6_Subtitle_8')}
                    </StyledSubtitle>

                    <Form.Group as={Row}>
                      <StyledFormLabel column xs={8}>{t('F1_S6_Question_22')}:</StyledFormLabel>
                      <Col xs={5} md={11}>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            style={{
                              resize: 'none',
                              marginLeft: '40px',
                            }}
                            name="covid19"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('Maximum_650')}
                            value={values.covid19}
                            className={touched.covid19 && errors.covid19 ? "error" : null}
                            disabled={nonEditable}
                        />
                        <FormErrorMessage
                          touched={touched.covid19}
                          errors={errors.covid19}
                        />
                      </Col>
                    </Form.Group>
                  </form>
            )}
          </Formik>
        </FormContainer>
    </FormLayout>
  )
}

export default observer(Section6);
