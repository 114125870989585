import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
    beneficiaryTypeId: Yup.string()
      .required('F3_S7_validation3')
      .typeError('F3_S10_validation1'),
    beneficiaryDescription: Yup.string()
      .min(10, 'F1_S6_validation7')
      .max(650, 'F1_S6_validation9')
      .nullable(true),
    beneficiaryJustification: Yup.string()
      .min(10, 'F1_S6_validation7')
      .max(650, 'F1_S6_validation9')
      .nullable(true),
    beneficiaryProspect: Yup.string()
      .min(10, 'F1_S6_validation7')
      .max(650, 'F1_S6_validation9')
      .nullable(true),
});