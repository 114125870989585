import React, { useEffect, useState } from 'react'
import PublicHeader from 'components/PublicHeader'
import { getMaintenanceVariables } from 'services/apiConstants'
import Footer from 'components/Footer'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useTranslation } from 'react-i18next'
import htmlParser from 'html-react-parser'
import img from 'assets/documents.png'
//import img_window_closed from 'assets/document_window_closed.png';

import {
  StyledContainer,
  StyledTitle,
  StyledTextLeft,
  StyledTextRight,
  StyledTextTitle,
  StyledButton,
  StyledLeftContent,
  StyledText,
  DocumentsImg,
  DocumentImg,
  StyledButtonLink,
  StyledTextCenter,
  StyledDiv,
} from './NewHome.styled'

const PublicHome = () => {
  const { t } = useTranslation()
  const [enableHomePage, setEnableHomePage] = useState(true)

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getMaintenanceVariables()
        if (response?.[5]) {
          setEnableHomePage(response[5].keyValue)
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetchAPI()
  }, [])

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <PublicHeader />

      <StyledContainer>
        <Row>
          <StyledTitle>
            {enableHomePage
              ? t('Home_main_title')
              : t('Home_main_title_window_closed')}
          </StyledTitle>
        </Row>
        <Row style={{ marginTop: '15px' }}>
          <div>
            <div
              style={{
                minWidth: '59%',
                float: 'right',
                marginLeft: '40px',
                marginTop: '8px',
                marginBottom: '8px',
              }}
            >
              <iframe
                width="100%"
                height="350px"
                src={t('Home_video')}
                frameBorder="0"
                allowFullScreen
              />
            </div>
            <div>
              <StyledText>
                {enableHomePage
                  ? htmlParser(t('Home_description'))
                  : htmlParser(t('Home_description_window_closed'))}
              </StyledText>
            </div>
          </div>
        </Row>
        {enableHomePage ? (
          <>
            <Row style={{ marginTop: '30px' }}>
              <StyledLeftContent>
                <StyledTextLeft>
                  {htmlParser(t('Home_welcome_window'))}
                </StyledTextLeft>
                <StyledText style={{ textAlign: 'right' }}>
                  {htmlParser(t('Home_welcome_text'))}
                </StyledText>
              </StyledLeftContent>
              <Col>
                <DocumentsImg
                  src={img}
                  alt="Documents img"
                  style={{ objectFit: 'contain' }}
                />
              </Col>
              <Col>
                <StyledTextRight>
                  {htmlParser(t('Home_document_title'))}
                </StyledTextRight>
                <StyledTextTitle>{htmlParser(t('Title_guia'))}</StyledTextTitle>
                <div style={{ marginLeft: '15px' }}>
                  <StyledButton
                    variant="link"
                    style={{ marginLeft: '0px' }}
                    href={t('Button_guide_esp')}
                    target="_blank"
                    rel="noreferrer"
                    size="sm"
                  >
                    ESPAÑOL
                  </StyledButton>
                  <StyledButton
                    variant="link"
                    href={t('Button_guide_eng')}
                    target="_blank"
                    rel="noreferrer"
                    size="sm"
                  >
                    ENGLISH
                  </StyledButton>
                  <StyledButton
                    variant="link"
                    href={t('Button_guide_por')}
                    target="_blank"
                    rel="noreferrer"
                    size="sm"
                  >
                    PORTUGUÊS
                  </StyledButton>
                </div>
                <StyledTextTitle>
                  {htmlParser(t('Title_rules'))}
                </StyledTextTitle>
                <div style={{ marginLeft: '15px' }}>
                  <StyledButton
                    style={{ marginLeft: '0px' }}
                    variant="link"
                    href={t('Button_rules_esp')}
                    target="_blank"
                    rel="noreferrer"
                    size="sm"
                  >
                    ESPAÑOL
                  </StyledButton>
                  <StyledButton
                    variant="link"
                    href={t('Button_rules_eng')}
                    target="_blank"
                    rel="noreferrer"
                    size="sm"
                  >
                    ENGLISH
                  </StyledButton>
                  <StyledButton
                    variant="link"
                    href={t('Button_rules_por')}
                    target="_blank"
                    rel="noreferrer"
                    size="sm"
                  >
                    PORTUGUÊS
                  </StyledButton>
                </div>
                <div style={{ marginTop: '15px' }}>
                  <div
                    style={{
                      fontSize: '19px',
                      color: '#15A7CB',
                      fontWeight: '500',
                      float: 'left',
                      marginRight: '3px',
                    }}
                  >
                    {'>>'}
                  </div>
                  <StyledButtonLink
                    variant="link"
                    href={t('Link_questions')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('Link_questions_title')}
                  </StyledButtonLink>
                </div>
              </Col>
            </Row>
            <Col
              style={{
                justifyContent: 'center',
                marginTop: '30px',
                display: 'flex',
              }}
            >
              <StyledDiv>
                <StyledTextCenter style={{ marginTop: '10px' }}>
                  {htmlParser(t('Home_ready'))}
                </StyledTextCenter>
                <StyledTextCenter
                  style={{ marginTop: '-8px', marginBottom: '20px' }}
                >
                  {htmlParser(t('Home_register_link'))}
                </StyledTextCenter>
                <StyledText style={{ textAlign: 'center' }}>
                  {htmlParser(t('Home_already_registered'))}
                </StyledText>
              </StyledDiv>
            </Col>
          </>
        ) : (
          <>
            <div>
              <Row style={{ marginTop: '20px', marginLeft: '-13px' }}>
                <a href={t('Home_banner_link')} target="_blank">
                  <img
                    src={t('Home_banner_new')}
                    width="108%"
                    alt="Documents img"
                  />
                </a>
              </Row>
            </div>
            <Row style={{ marginTop: '40px', marginLeft: '-35px' }}>
              <DocumentImg
                src={t('Home_portada_guia_new')}
                alt="Documents img"
              />
              <Col
                style={{
                  marginLeft: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div>
                  <StyledTextLeft>
                    {htmlParser(t('Home_texto_guia'))}
                  </StyledTextLeft>
                  <div style={{ marginTop: '3px' }}>
                    <StyledButton
                      style={{ marginLeft: '0px' }}
                      variant="link"
                      href={t('Button_guide_esp')}
                      target="_blank"
                      rel="noreferrer"
                      size="sm"
                    >
                      ESPAÑOL
                    </StyledButton>
                    <StyledButton
                      variant="link"
                      href={t('Button_guide_eng')}
                      target="_blank"
                      rel="noreferrer"
                      size="sm"
                    >
                      ENGLISH
                    </StyledButton>
                    <StyledButton
                      variant="link"
                      href={t('Button_guide_por')}
                      target="_blank"
                      rel="noreferrer"
                      size="sm"
                    >
                      PORTUGUÊS
                    </StyledButton>
                  </div>
                </div>
              </Col>
            </Row>

            {enableHomePage ? (
              <>
                <Row style={{ marginTop: '40px', marginLeft: '-35px' }}>
                  <DocumentImg
                    src={t('Home_portada_normas_new')}
                    alt="Documents img"
                  />
                  <Col
                    style={{
                      marginLeft: '10px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <StyledTextLeft style={{ color: '#15A7CB' }}>
                        {htmlParser(t('Home_texto_normas'))}
                      </StyledTextLeft>
                      <div style={{ marginTop: '3px' }}>
                        <StyledButton
                          style={{ marginLeft: '0px' }}
                          variant="link"
                          href={t('Button_rules_esp')}
                          target="_blank"
                          rel="noreferrer"
                          size="sm"
                        >
                          ESPAÑOL
                        </StyledButton>
                        <StyledButton
                          variant="link"
                          href={t('Button_rules_eng')}
                          target="_blank"
                          rel="noreferrer"
                          size="sm"
                        >
                          ENGLISH
                        </StyledButton>
                        <StyledButton
                          variant="link"
                          href={t('Button_rules_por')}
                          target="_blank"
                          rel="noreferrer"
                          size="sm"
                        >
                          PORTUGUÊS
                        </StyledButton>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row style={{ marginTop: '40px', marginLeft: '-35px' }}>
                  <DocumentImg
                    src={t('Home_portada_elementos_new')}
                    alt="Documents img"
                  />
                  <Col
                    style={{
                      marginLeft: '10px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <StyledTextLeft style={{ color: '#15A7CB' }}>
                        {htmlParser(t('Home_texto_elementos_orientadores'))}
                      </StyledTextLeft>
                      <div style={{ marginTop: '3px' }}>
                        <StyledButton
                          style={{ marginLeft: '0px' }}
                          variant="link"
                          href={t('Button_elementos_orientadores_esp')}
                          target="_blank"
                          rel="noreferrer"
                          size="sm"
                        >
                          ESPAÑOL
                        </StyledButton>
                        <StyledButton
                          variant="link"
                          href={t('Button_elementos_orientadores_eng')}
                          target="_blank"
                          rel="noreferrer"
                          size="sm"
                        >
                          ENGLISH
                        </StyledButton>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row style={{ marginTop: '40px', marginLeft: '-35px' }}>
                  <DocumentImg
                    src={t('Home_portada_faq_new')}
                    alt="Documents img"
                  />
                  <Col
                    style={{
                      marginLeft: '10px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <StyledTextLeft style={{ color: '#15A7CB' }}>
                        {htmlParser(t('Home_texto_preguntas_frecuentes'))}
                      </StyledTextLeft>
                      <div style={{ marginTop: '3px' }}>
                        <StyledButton
                          style={{ marginLeft: '0px' }}
                          variant="link"
                          href={t('Button_preguntas_frecuentes_esp')}
                          target="_blank"
                          rel="noreferrer"
                          size="sm"
                        >
                          ESPAÑOL
                        </StyledButton>
                        <StyledButton
                          variant="link"
                          href={t('Button_preguntas_frecuentes_eng')}
                          target="_blank"
                          rel="noreferrer"
                          size="sm"
                        >
                          ENGLISH
                        </StyledButton>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            ) : null}

            <Col style={{ marginLeft: '20%', marginTop: '3%' }}>
              <StyledTextLeft style={{ color: '#15A7CB' }}>
                {htmlParser(t('Home_register_link'))}
              </StyledTextLeft>
              <StyledText>
                {htmlParser(t('Home_already_registered'))}
              </StyledText>
            </Col>
          </>
        )}
      </StyledContainer>

      <Footer public={true} />
    </div>
  )
}

export default PublicHome
