import styled, {css} from 'styled-components';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export const StyledFormLabel = styled(Form.Label)`
    text-align: justify;
    line-height: 1.3;
`;

export const StyledDivWrapper = styled.div`
  margin-left: 50px;
`;

export const StyledTitle = styled.h5`
  ${({ theme }) => css`
    color: ${theme.brandColors.blue};
  `}
`;

export const StyledSubtitle = styled.b`
    text-align: justify;
    color: black;
    line-height: 1.3;
`;

export const StyledButtonLink = styled(Button)`
    border: none;
    background: none;
    box-shadow: none !important;
    &:hover, &:active, &:focus{
      border: none !important;
      background: none !important;
      text-decoration: none !important;
    };
    &:disabled {
      background: none !important;
`;

export const RoundedAddDiv = styled.div`
  ${({ theme }) => css`
    background-color: ${props => props.disabled ? theme.colors.gray[2] : theme.brandColors.blue};
    color: white;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content:center;
    font-size: 20px;
    padding-bottom: 5px;
    margin-right: 10px;
  `}
`;

export const DivButtonWrapper = styled.div`
  ${({ theme }) => css`
    color: ${props => props.disabled ? theme.colors.gray[2] : theme.brandColors.blue};
    display: flex;
    white-space: nowrap;
    align-items: center;
    margin-right: 100px;
    font-weight: 600;
  `}
`;

export const RoundedRemoveDiv = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.brandColors.red};
    color: white;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 15px;
    display: flex,
    align-items: center;
    justify-content: center,
    font-size: 20px;
  `}
`;