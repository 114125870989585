import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import htmlParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'stores';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import {
  StyledVideosContainer,
  StyledVideoItem,
  StyledCheckboxContainer,
  StyledImageContainer,
  StyledIntroParagraph
} from './Section1.styled';

const initialValues = {
  checkOne: false,
  checkTwo: false,
  checkThree: false,
  checkFour: false,
  checkFive: false,
};

const Section1 = () => {

  const { formStore } = useRootStore();
  const [formValues, setFormValues] = useState(initialValues);
  const {t} = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  useEffect(() => {
    let newData = initialValues;
    if (formStore.formSectionData) {
      newData = { ...formStore.formSectionData[0] }
      delete newData.id;
      delete newData.complete;
    } 
    setFormValues(newData);
    formStore.setCurrentSection(1);
  }, [formStore.formSectionData])

  return (
    <FormLayout>
      <FormContainer 
        number={1} 
        title={t('F1_S1_title')}
        formId="form2section1"
        >
        <StyledIntroParagraph>
          { htmlParser(t('F1_S1_intro')) }
        </StyledIntroParagraph>
        
          <StyledVideosContainer>
          <StyledVideoItem>
              <StyledCheckboxContainer>
              <input 
                  type="checkbox" 
                  name="checkOne" 
                  checked={formValues.checkOne} 
                  disabled
                />                    
              </StyledCheckboxContainer>
              <StyledImageContainer>
                { htmlParser(t('F1_S1_aknowledge1'))}
              </StyledImageContainer>
            </StyledVideoItem>

            <StyledVideoItem>
              <StyledCheckboxContainer > 
              <input 
                  type="checkbox" 
                  name="checkTwo" 
                  checked={formValues.checkTwo} 
                  disabled
                />                    
              </StyledCheckboxContainer>
              <StyledImageContainer>
              { htmlParser(t('F1_S1_aknowledge2'))}
              </StyledImageContainer>
            </StyledVideoItem>
            <StyledVideoItem>
              <StyledCheckboxContainer>
              <input 
                  type="checkbox" 
                  name="checkThree" 
                  checked={formValues.checkThree} 
                  disabled
                />                    
              </StyledCheckboxContainer>
              <StyledImageContainer>
                { htmlParser(t('F1_S1_aknowledge3'))}
              </StyledImageContainer>
            </StyledVideoItem>
            <StyledVideoItem>
              <StyledCheckboxContainer>
              <input 
                  type="checkbox" 
                  name="checkFive" 
                  checked={formValues.checkFive} 
                  disabled
                />                    
              </StyledCheckboxContainer>
              <StyledImageContainer >
                { htmlParser(t('F1_S1_aknowledge5')) }
              </StyledImageContainer>
            </StyledVideoItem>
            <StyledVideoItem>
              <StyledCheckboxContainer>
              <input 
                  type="checkbox" 
                  name="checkFour" 
                  checked={formValues.checkFour} 
                  disabled
                />                    
              </StyledCheckboxContainer>
              <StyledImageContainer >
                {t('F1_S1_aknowledge4')}
              </StyledImageContainer>
            </StyledVideoItem>
          </StyledVideosContainer>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section1);


