import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useRootStore } from "stores";
import { useParams } from "react-router-dom";
import { formInitialValues } from "./Section9.initialValues";
import { useTranslation } from "react-i18next";
import { validationSchema } from "./Section9.validations";
import { saveFormSection } from "services/initiative";
import { f5statusNamesConst, roleNamesConst } from "constants/appConstants";
import Comments from "../Comments";
import { isWindows2021 } from "helpers/isWindow2021Helper";
import CustomBudgetTable from "components/CustomBudgetTable";
import FormLayout from "../../FormLayout";
import FormContainer from "components/FormContainer";
import Form from "react-bootstrap/Form";
import { RequiredMark } from "components/CommonStyled";
import {
  StyledFormLabel,
  StyledDivWrapper,
  BlackSubtitle,
  Title,
  ActivityItemTitle,
  ActivityItemBody,
  ActivityItem,
} from "./Section9.styled";
import { formatDateWithHourAndSecs } from "helpers/dateHelpers";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const Section9 = () => {
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { formStore, initiativeStore, authStore } = useRootStore();
  const [nonEditable, setNonEditable] = useState(true);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [saved, setSaved] = useState(false);
  const [disabledComments, setDisabledComments] = useState(true);
  const currentRole = authStore.roles[0];
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM;
  const isAtke = currentRole === roleNamesConst.ATKE;
  const isIntpa = currentRole === roleNamesConst.INTPA;
  const isEud = currentRole === roleNamesConst.EUD;
  const [isF7, setIsF7] = useState(false);
  const [window2021, setWindow2021] = useState(true)

  const [consolidatedBudgetRows, setConsolidatedBudgetRows] = useState([]);
  const [executedBudgetRows, setExecutedBudgetRows] = useState([]);
  const [totalBudgetConsolidated, setTotalBudgetConsolidated] = useState({
    eu_budget: 0,
    counterpart_budget: 0,
    total_budget: 0,
  });
  const [totalBudgetExecuted, setTotalBudgetExecuted] = useState({
    eu_budget: 0,
    counterpart_budget: 0,
    total_budget: 0,
  });

  const getLastModifiedTable = () => {
    if (
      formStore.formSectionData &&
      formStore.formSectionData[2] &&
      formStore.formSectionData[2].lastModified
    ) {
      return formatDateWithHourAndSecs(
        formStore.formSectionData[2].lastModified
      );
    } else return "-";
  };

  const lastModifiedTable = getLastModifiedTable();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f5statusNamesConst.DRAFT ||
        initiativeStore.role !== roleNamesConst.APPLICANT
    );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (initiativeStore.code) {
      setWindow2021(isWindows2021(initiativeStore.code))
    }
  }, [initiativeStore.code])

  useEffect(() => {
    if (initiativeStore.formStateName)
      setIsF7(
        (isIntpa || isEud) &&
          initiativeStore.formStateName !== f5statusNamesConst.DRAFT &&
          initiativeStore.formStateName !== f5statusNamesConst.SENT
      );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (formStore.formSectionData) {
      if (formStore.formSectionData[0]) {
        const formValuesCopy = { ...formStore.formSectionData[0] };
        setFormValues(formValuesCopy);
      }
      if (formStore.formSectionData[1]) {
        const rows = formStore.formSectionData[1];
        const total = rows.pop();
        setConsolidatedBudgetRows(rows);
        setTotalBudgetConsolidated(total);
      }
      if (formStore.formSectionData[2].items) {
        const rows = formStore.formSectionData[2].items;
        const total = rows.pop();
        setExecutedBudgetRows(rows);
        setTotalBudgetExecuted(total);
      }
    }
  }, [formStore.formSectionData]);

  const mapActivityItems = (array) => {
    return array && Array.isArray(array)
      ? array.map((assessment) => (
          <ActivityItem>
            <ActivityItemTitle>{`${assessment.activityName}: `}</ActivityItemTitle>
            <ActivityItemBody>
              {assessment.assessment ? assessment.assessment : " - "}
            </ActivityItemBody>
          </ActivityItem>
        ))
      : " - ";
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await saveFormSection(id, formId, sectionId, values);
      formStore.setSectionStatus(sectionId, response.complete);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaved = (value) => {
    setSaved(value);
  };

  const handleButton = (value) => {
    setDisabledComments(value);
  };

  return (
    <FormLayout>
      <FormContainer
        number={9}
        title={t("F3_S9_title")}
        formId="form5section9"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
        showButtonClarif={!disabledComments}
      >
        <StyledDivWrapper>
          <Formik
            enableReinitialize
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              dirty,
            }) => (
              <form onSubmit={handleSubmit} id="form5section9">
                {dirty && setSaved(false)}

                <Title> {t("F5_S9_Subtitle_consolidated_budget")} </Title>
                <CustomBudgetTable
                  items={consolidatedBudgetRows}
                  totalItem={totalBudgetConsolidated}
                />

                <Title> {t("F5_S9_Subtitle_executed_budget")} </Title>
                {`(${t("F5_S9_LASTUPDATE")}: ${lastModifiedTable} )`}
                <CustomBudgetTable
                  items={executedBudgetRows}
                  totalItem={totalBudgetExecuted}
                />

                {window2021 ? (
                  <>
                    <BlackSubtitle>
                      {t("F5_S7_Activity_assessment_1")}
                    </BlackSubtitle>
                    {formStore.formSectionData &&
                      formStore.formSectionData[3] &&
                      mapActivityItems(formStore.formSectionData[3])}
                  </>
                ) : null}


                <Form.Group style={{ marginTop: "25px", display: 'none' }}>
                  <StyledFormLabel>
                    {t("F5_Overview_of_TCI")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="overviewExecutedBudget"
                    as="textarea"
                    rows={7}
                    style={{ resize: "none" }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.overviewExecutedBudget}
                    disabled={nonEditable}
                    placeholder={t("Maximum_1300")}
                    className={
                      touched.overviewExecutedBudget &&
                      errors.overviewExecutedBudget
                        ? "error"
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.overviewExecutedBudget}
                    errors={errors.overviewExecutedBudget}
                  />
                </Form.Group>

                <BlackSubtitle>
                  {t("F5_S7_Activity_assessment_2")}
                </BlackSubtitle>
                {formStore.formSectionData &&
                  formStore.formSectionData[4] &&
                  mapActivityItems(formStore.formSectionData[4])}

                <Form.Group style={{ marginTop: "25px" }}>
                  <StyledFormLabel>
                    {t("F5_S9_Consistency_cofinancing")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="overviewCoFinancing"
                    as="textarea"
                    rows={7}
                    style={{ resize: "none" }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.overviewCoFinancing}
                    disabled={nonEditable}
                    placeholder={t("Maximum_1300")}
                    className={
                      touched.overviewCoFinancing && errors.overviewCoFinancing
                        ? "error"
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.overviewCoFinancing}
                    errors={errors.overviewCoFinancing}
                  />
                </Form.Group>
              </form>
            )}
          </Formik>

          {(isAtke || isConsortium || isF7) && (
            <Comments onSaved={handleSaved} onButton={handleButton} />
          )}
        </StyledDivWrapper>
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section9);
