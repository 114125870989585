import PrivateRoute from './PrivateRoute';
import Layout from 'layout';
import ManagerInitiative from 'pages/manager/Initiative';
import ManagerCN from 'pages/manager/ConceptNotes';
import ManagerProposals from 'pages/manager/Proposals';
import ManagerManagement from 'pages/manager/Management';
import ManagerDashboard from 'pages/manager/Dashboard';
import ManagerDashboardInitiatives from 'pages/manager/DashboardInitiatives';
import ManagerDashboardExecutedBudget from 'pages/manager/DashboardExecutedBudget';
import ManagerDashboardPreformulation from 'pages/manager/DashboardPreformulation';
import ManagerExpostReportList from 'pages/manager/ExpostReporting';
import ManagerExpostReportForm from 'pages/manager/ExpostReportForm';
import ManagerDocuments from 'pages/manager/Documents';
// Statistics system
import StatsFormulation from 'pages/statistics/Formulation'
import StatsPreformulation from 'pages/statistics/Preformulation'
import StatsExecution from 'pages/statistics/Execution'
import StatsEntities from 'pages/statistics/Entities'
import StatsPartnerships from 'pages/statistics/Partnerships'
import StatsInitiatives from 'pages/statistics/Initiatives'
import StatsExPost from 'pages/statistics/ExPost'
import StatsActivities from 'pages/statistics/Activities'
import StatsDirectBeneficiaries from 'pages/statistics/DirectBeneficiaries'
import StatsResults from 'pages/statistics/Results'
import StatsCountry from 'pages/statistics/Country'
import StatsCountryFunds from 'pages/statistics/Country/FundsCountry'

const EUDRoutes = () => [
  <PrivateRoute
    path="/eud/initiative/:id/form/:formId/section/:sectionId"
    component={ManagerInitiative}
    layout={Layout}
  />,
  <PrivateRoute path="/eud/concept-notes" component={ManagerCN} layout={Layout} />,
  <PrivateRoute path="/eud/proposals" component={ManagerProposals} layout={Layout} />,
  <PrivateRoute
    path="/eud/management/final-assessments"
    component={ManagerManagement}
    layout={Layout}
  />,
  <PrivateRoute path="/eud/dashboard" component={ManagerDashboard} layout={Layout} />,
  <PrivateRoute
    path="/eud/dashboard-initiatives"
    component={ManagerDashboardInitiatives}
    layout={Layout}
  />,
  <PrivateRoute
    path="/eud/dashboard-executed-budget"
    component={ManagerDashboardExecutedBudget}
    layout={Layout}
  />,
  <PrivateRoute
    path="/eud/dashboard-preformulation-stage"
    component={ManagerDashboardPreformulation}
    layout={Layout}
  />,
  <PrivateRoute
    path="/eud/management/expost-reporting"
    component={ManagerExpostReportList}
    layout={Layout}
  />,
  <PrivateRoute
    path="/eud/expost-reporting/:formId"
    component={ManagerExpostReportForm}
    layout={Layout}
  />,
  <PrivateRoute path="/eud/documents" component={ManagerDocuments} layout={Layout} />,
   // Statistics system
  <PrivateRoute
   path="/eud/statistics/formulation"
   component={StatsFormulation}
   layout={Layout}
 />,
 <PrivateRoute
   path="/eud/statistics/preformulation"
   component={StatsPreformulation}
   layout={Layout}
 />,
 <PrivateRoute
   path="/eud/statistics/execution"
   component={StatsExecution}
   layout={Layout}
 />,
 <PrivateRoute
   path="/eud/statistics/entities"
   component={StatsEntities}
   layout={Layout}
 />,
 <PrivateRoute
   path="/eud/statistics/partnerships"
   component={StatsPartnerships}
   layout={Layout}
 />,
 <PrivateRoute
   path="/eud/statistics/agenda2030"
   component={StatsInitiatives}
   layout={Layout}
 />,
 <PrivateRoute
   path="/eud/statistics/expost"
   component={StatsExPost}
   layout={Layout}
 />,
 <PrivateRoute
   path="/eud/statistics/direct-beneficiaries"
   component={StatsDirectBeneficiaries}
   layout={Layout}
 />,
 <PrivateRoute
   path="/eud/statistics/activities"
   component={StatsActivities}
   layout={Layout}
 />,
 <PrivateRoute
   path="/eud/statistics/results"
   component={StatsResults}
   layout={Layout}
 />,
 <PrivateRoute
   path="/eud/statistics/country"
   component={StatsCountry}
   layout={Layout}
 />,
 <PrivateRoute
  path="/eud/statistics/country-funds"
  component={StatsCountryFunds}
  layout={Layout}
  />,
];

export default EUDRoutes;
