import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import {
  AdelanteLogo,
  CenterContent,
  Container,
  LeftContent,
  StyledNoPaddingLogo,
  RightContent,
  StyledLenguajeSelector,
  StyledButtonLink,
  StyledEnvSign,
} from './Header.styled';

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import HeaderButtonGroup from 'components/HeaderButtonGroup';

import logoEN from "assets/logo-A2-EN.png";
import logoES from "assets/logo-A2-ES.png";
import testingLogo from "assets/logo-A2-testing.png";
import extranetLogo from "assets/extranet-logo.png";

const Header = () => {
  const { authStore} = useRootStore();
  const location = useLocation();
  const { t, i18n} = useTranslation();
  var localStorage = window.localStorage;
  const [env, setEnv] = useState(null);

  const headerLogo = i18n.language === 'en'? logoEN : logoES;

  const handleLanguage = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem('userLanguage',value);
    // This is to keep the language when the page is reloaded
    sessionStorage.setItem('language',value);

    if(location.pathname.includes('/contract-management') || location.pathname.includes('/contacts'))
      window.location.replace('');
  };

  const isInDevelopment = (baseUrl) => {
    return baseUrl && baseUrl.includes('dev.');
  };

  const isInQa = (baseUrl) => {
    return baseUrl && baseUrl.includes('qa.');
  };

  const handleChangeEnvironment = () => {
    if (isInDevelopment(process.env.REACT_APP_API_URL)) {
      setEnv("DEV");
    } else if (isInQa(process.env.REACT_APP_API_URL)) {
      setEnv("QA");
    }
  };

  useEffect(() => {
    handleChangeEnvironment();
  }, [])

  return (
    <Container>
      <LeftContent>
        <AdelanteLogo src={env !== null ? testingLogo : headerLogo} alt="Adelante2 logo" style={{ objectFit: 'contain'}} />
        {env !== null &&
          <StyledEnvSign>{env}</StyledEnvSign>
        }
      </LeftContent>
      <CenterContent>
      </CenterContent>
      <RightContent>
        <Col xs={8}>
          <Row>
            <StyledNoPaddingLogo src={extranetLogo} alt="Extranet logo" />
          </Row>
          <Row>
            {t('welcome')} {authStore.email}
          </Row>
        </Col>
        <Col xs={4}/>
        <Col xs={8}>
          <Row className="justify-content-md-end">
            {sessionStorage.getItem('button') ==='nonDisabled' &&
              <StyledLenguajeSelector>
                <StyledButtonLink variant="link" onClick={() => {handleLanguage('en')}} >
                  {t('English')}
                </StyledButtonLink>
                  {' | '}
                <StyledButtonLink  variant="link" onClick={() => {handleLanguage('es')}} >
                  {t('Spanish')}
                </StyledButtonLink>
              </StyledLenguajeSelector>
            }
          </Row>
          <Row className="justify-content-md-end">
            <HeaderButtonGroup />
          </Row>
        </Col>
      </RightContent>
    </Container>
  )
}

export default observer(Header);
