import React, {useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import htmlParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'stores';

import {
  StyledText,
} from './Section1.styled';

const Section1 = () => {

const { t } = useTranslation();
const { formStore } = useRootStore();

  useEffect(() => {
    formStore.setCurrentSection(1);
  }, [formStore])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  return (
    <FormLayout>
      <FormContainer 
        number={1} 
        title={t('F1_S1_title')}
        formId="form4section1"
        >
        <StyledText>
         {htmlParser(t('F3_S1_Intro'))}
        </StyledText>    
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section1);