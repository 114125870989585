import React, { useState, useEffect, useRef } from 'react'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { useRootStore } from 'stores'
import { useParams } from 'react-router-dom'
import { formInitialValues } from './Section14.initialValues'
import { useTranslation } from 'react-i18next'
import { validationSchema } from './Section14.validations'
import { saveFormSection, getFormSection } from 'services/initiative'
import { f5statusNamesConst, roleNamesConst } from 'constants/appConstants'
import Comments from '../Comments'
import { groupByActivity } from './helper'
import isEmpty from 'lodash/isEmpty'
import CustomModal from 'components/Modal'
import CommunicationToolList from './CommunicationToolList'
import NewCommunicationToolForm from './NewCommunicationToolForm'
import NewDifussionMaterialForm from './NewDifussionMaterialForm'
import DifussionMaterial from './DiffusionMaterial'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TextValue from 'components/TextValue'
import ConfirmCloseModal from 'components/ConfirmCloseModal'
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer'
import { StyledFormLabel, StyledDivWrapper, Title } from './Section14.styled'
import { RequiredMark } from 'components/CommonStyled'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const Section14 = () => {
  const { t } = useTranslation()
  const formRef = useRef()
  const { id, formId, sectionId } = useParams()
  const { formStore, initiativeStore, authStore } = useRootStore()
  const [nonEditable, setNonEditable] = useState(true)
  const [formValues, setFormValues] = useState(formInitialValues)
  const [saved, setSaved] = useState(false)
  const [disabledComments, setDisabledComments] = useState(true)
  const currentRole = authStore.roles[0]
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM
  const isAtke = currentRole === roleNamesConst.ATKE
  const isIntpa = currentRole === roleNamesConst.INTPA
  const isEud = currentRole === roleNamesConst.EUD
  const [isF7, setIsF7] = useState(false)
  const [closingModalInfo, setClosingModalInfo] = useState({
    isOpen: false,
    text: '',
    action: null,
  })

  const [communicationToolFormModal, setCommunicationToolFormModal] = useState(
    false,
  )
  const [difussionMaterialFormModal, setDifussionMaterialFormModal] = useState({
    show: false,
    isEdit: false,
    data: null,
  })

  const [activities, setActivities] = useState([])

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    message: t('Error try again later'),
    type: 'error',
  })

  useEffect(() => {
    getInitiativeActivities()
  }, [])

  async function getInitiativeActivities() {
    try {
      const response = await getFormSection(id, formId, 7)
      setActivities(response.section[0].activities)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName === f5statusNamesConst.FINALIZED ||
        initiativeStore.formStateName === f5statusNamesConst.CANCELLED ||
        initiativeStore.role !== roleNamesConst.APPLICANT,
    )
  }, [initiativeStore.formStateName])

  useEffect(() => {
    if (initiativeStore.formStateName)
      setIsF7(
        (isIntpa || isEud) &&
          initiativeStore.formStateName !== f5statusNamesConst.DRAFT &&
          initiativeStore.formStateName !== f5statusNamesConst.SENT,
      )
  }, [initiativeStore.formStateName])

  useEffect(() => {
    if (formStore.formSectionData && formStore.formSectionData[0]) {
      const formValuesCopy = { ...formStore.formSectionData[0] }
      setFormValues(formValuesCopy)
    }
  }, [formStore.formSectionData])

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await saveFormSection(id, formId, sectionId, values)
      formStore.setSectionStatus(sectionId, response.complete)
      resetForm({ values })
      setSaved(true)
    } catch (error) {
      console.log(error)
    }
  }

  const setToolsLocalList = (tools) => {
    setFormValues({
      ...formRef.current.values,
      communicationTools: [...tools],
    })
  }

  const setMaterialList = (materials) => {
    setFormValues({
      ...formRef.current.values,
      materials: materials,
    })
  }

  const handleSaved = (value) => {
    setSaved(value)
  }

  const handleButton = (value) => {
    setDisabledComments(value)
  }

  return (
    <>
      <ConfirmCloseModal
        show={closingModalInfo.isOpen}
        handleCloseConfirm={() =>
          setClosingModalInfo({
            isOpen: false,
            text: '',
            action: null,
          })
        }
        handleActionOnConfirm={closingModalInfo.action}
        text={closingModalInfo.text}
      />

      {modalInfo.show && (
        <CustomModal
          show={modalInfo.isOpen}
          onHide={() => setModalInfo({ ...modalInfo, isOpen: false })}
          message={modalInfo.message}
          type="error"
        />
      )}

      {communicationToolFormModal ? (
        <NewCommunicationToolForm
          handleCloseOk={() => setCommunicationToolFormModal(false)}
          handleClose={() =>
            setClosingModalInfo({
              isOpen: true,
              text: t('Confirmation close modal'),
              action: () => setCommunicationToolFormModal(false),
            })
          }
          handleCloseOnError={() => {
            setCommunicationToolFormModal(false)
          }}
          nonEditable={nonEditable}
          setToolsLocalList={setToolsLocalList}
          show={communicationToolFormModal}
        />
      ) : null}

      {difussionMaterialFormModal.show ? (
        <NewDifussionMaterialForm
          handleCloseOk={() =>
            setDifussionMaterialFormModal({
              show: false,
              isEdit: false,
              data: null,
            })
          }
          handleClose={() =>
            setClosingModalInfo({
              isOpen: true,
              text: t('Confirmation close modal'),
              action: () =>
                setDifussionMaterialFormModal({
                  show: false,
                  isEdit: false,
                  data: null,
                }),
            })
          }
          handleCloseOnError={() => {
            setDifussionMaterialFormModal({
              show: false,
              isEdit: false,
              data: null,
            })
          }}
          nonEditable={nonEditable}
          setMaterialList={setMaterialList}
          show={difussionMaterialFormModal.show}
          activities={activities}
          isEdit={difussionMaterialFormModal.isEdit}
          data={difussionMaterialFormModal.data}
          setModalInfo={setModalInfo}
        />
      ) : null}

      <FormLayout>
        <FormContainer
          number={14}
          title={t('F3_S14_title')}
          formId="form5section14"
          showSavedMessage={saved}
          showSaveButton={!nonEditable}
          showButtonClarif={!disabledComments}
        >
          <StyledDivWrapper>
            <Formik
              enableReinitialize
              initialValues={formValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              innerRef={formRef}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit} id="form5section14">
                  {props.dirty && setSaved(false)}

                  <Form.Group style={{ marginTop: '25px' }}>
                    <StyledFormLabel>
                      {t('F1_S8_Objetive')}
                      <RequiredMark>*</RequiredMark>:
                    </StyledFormLabel>
                    <Form.Control
                      name="objective"
                      as="textarea"
                      rows={7}
                      style={{ resize: 'none' }}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.objective}
                      disabled={nonEditable}
                      placeholder={t('Maximum_1300')}
                      className={
                        props.touched.objective && props.errors.objective
                          ? 'error'
                          : null
                      }
                    />
                    <FormErrorMessage
                      touched={props.touched.objective}
                      errors={props.errors.objective}
                    />
                  </Form.Group>

                  <Title> {t('F5_S14_Communication_Tools_title')} </Title>
                  <CommunicationToolList
                    items={props.values.communicationTools}
                    handleOpenModal={() => setCommunicationToolFormModal(true)}
                  />

                  <Title>{t('F5_S14_difussion_material')}</Title>
                  <DifussionMaterial
                    items={
                      !isEmpty(props.values.materials)
                        ? groupByActivity(props.values.materials)
                        : []
                    }
                    handleOpenModal={setDifussionMaterialFormModal}
                    setMaterialList={setMaterialList}
                    handleClose={setClosingModalInfo}
                    setModalInfo={setModalInfo}
                  />

                  <Title> {t('F3_S14_Subtitle_focal_point')} </Title>

                  <Form.Group as={Row}>
                    <Form.Label column xs={2}>
                      {t('F1_S2_First_name')} <RequiredMark>*</RequiredMark>:
                    </Form.Label>
                    <Col xs={3} className="align-self-center">
                      <TextValue value={formValues.firstname} />
                    </Col>
                    <Form.Label className="align-self-center" column xs={2}>
                      {t('F1_S2_Last_Name')} <RequiredMark>*</RequiredMark>:
                    </Form.Label>
                    <Col xs={3} className="align-self-center">
                      <TextValue value={formValues.lastname} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column xs={5}>
                      {t('F1_S2_Email')} <RequiredMark>*</RequiredMark>:
                    </Form.Label>
                    <Col xs={5} className="align-self-center">
                      <TextValue value={formValues.email} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label className="align-self-center" column xs={2}>
                      {t('F3_S14_Entity')} <RequiredMark>*</RequiredMark>:
                    </Form.Label>
                    <Col xs={3} className="align-self-center">
                      <TextValue value={formValues.entityName} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column lg={2}>
                      {t('F1_S2_Phone_number')}:<RequiredMark>*</RequiredMark>
                    </Form.Label>
                    <Col lg={4} className="align-self-center">
                      <TextValue value={formValues.phone} />
                    </Col>
                  </Form.Group>
                </form>
              )}
            </Formik>

            {(isAtke || isConsortium || isF7) && (
              <Comments onSaved={handleSaved} onButton={handleButton} />
            )}
          </StyledDivWrapper>
        </FormContainer>
      </FormLayout>
    </>
  )
}

export default observer(Section14)
