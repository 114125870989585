import * as Yup from 'yup';
import i18n from 'i18n';

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

export const validationSchema = Yup.object().shape({
  participantList: Yup.array()
    .of(
      Yup.object().shape({
        willAttendTrue: Yup.boolean()
          .nullable(true),
        willNotAttend: Yup.boolean()
          .nullable(true),
        firstName: Yup.string()
          .min(2, 'F3_S7_validation4')
          .max(100, 'F3_S7_validation14')
          .nullable(true)
          .test('firstNameCheck', 'F5_S7_required', function (value) {
            if((this.parent.willAttendTrue == true || this.parent.willNotAttend == true) && !this.parent.firstName) return false
            return true }),
        lastName: Yup.string()
          .min(2, 'F3_S7_validation4')
          .max(100, 'F3_S7_validation14')
          .nullable(true)
          .test('lastNameCheck', 'F5_S7_required', function (value) {
            if((this.parent.willAttendTrue == true || this.parent.willNotAttend == true) && !this.parent.lastName) return false
            return true }),
        nationalityId: Yup.string()
          .min(1, 'F1_S2_validation5')
          .nullable(true)
          .test('nationalityIdCheck', 'F5_S7_required', function (value) {
            if((this.parent.willAttendTrue == true || this.parent.willNotAttend == true) && !(this.parent.nationalityId)) return false
            return true }),
        city: Yup.string()
          .min(2, 'F3_S7_validation4')
          .max(100, 'F3_S7_validation14')
          .nullable(true)
          .test('cityCheck', 'F5_S7_required', function (value) {
            if((this.parent.willAttendTrue == true || this.parent.willNotAttend == true) && !this.parent.city) return false
            return true }),
        email: Yup.string()
          .email('F1_S2_validation23')
          .max(100, 'F1_S2_validation24')
          .nullable(true)
          .test('emailCheck', 'F5_S7_required', function (value) {
            if((this.parent.willAttendTrue == true || this.parent.willNotAttend == true) && !this.parent.email) return false
            return true }),
        phone: Yup.string()
          .min(2, 'F1_S2_validation20')
          .max(100, 'F1_S2_validation21')
          .matches(phoneRegExp, 'F1_S2_validation22')
          .nullable(true)
          .test('phoneCheck', 'F5_S7_required', function (value) {
            if((this.parent.willAttendTrue == true || this.parent.willNotAttend == true) && !this.parent.phone) return false
            return true }),
        entityTypeId: Yup.string()
          .min(1, 'F1_S2_validation5')
          .nullable(true)
          .test('entityTypeCheck', 'F5_S7_required', function (value) {
            if((this.parent.willAttendTrue == true || this.parent.willNotAttend == true) && !(this.parent.entityTypeId)) return false
            return true }),
        organizationName: Yup.string()
          .min(2, 'F3_S7_validation4')
          .max(100, 'F3_S7_validation14')
          .nullable(true)
          .test('organizationNameCheck', 'F5_S7_required', function (value) {
            if((this.parent.willAttendTrue == true || this.parent.willNotAttend == true) && !this.parent.organizationName) return false
            return true }),
      })
    ),
});