import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { validationSchema } from "./Section13.validations";
import { observer } from "mobx-react-lite";
import { useRootStore } from "stores";
import { useParams, Link } from "react-router-dom";
import { formInitialValues } from "./Section13.initialValues";
import { useTranslation } from "react-i18next";
import { saveFormSection } from "services/initiative";
import TextValue from "components/TextValue";
import { f5statusNamesConst, roleNamesConst } from "constants/appConstants";
import Comments from "../Comments";
import Form from "react-bootstrap/Form";
import FormLayout from "../../FormLayout";
import FormContainer from "components/FormContainer";
import { RequiredMark } from "components/CommonStyled";
import {
  StyledFormLabel,
  StyledDivWrapper,
  StyledTitle,
  StyledSubtitle,
} from "./Section13.styled";
import { GreySubtitle } from "components/Forms/Forms.styled";
import HelpIcon from "components/HelpIcon";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const Section13 = () => {
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { formStore, initiativeStore, authStore } = useRootStore();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [nonEditable, setNonEditable] = useState(false);
  const [saved, setSaved] = useState(false);
  const [disabledComments, setDisabledComments] = useState(true);
  const currentRole = authStore.roles[0];
  const isPublic = currentRole === roleNamesConst.PUBLIC;
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM;
  const isAtke = currentRole === roleNamesConst.ATKE;
  const isIntpa = currentRole === roleNamesConst.INTPA;
  const isEud = currentRole === roleNamesConst.EUD;
  const [isF7, setIsF7] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f5statusNamesConst.DRAFT ||
        initiativeStore.role !== roleNamesConst.APPLICANT
    );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (initiativeStore.formStateName)
      setIsF7(
        (isIntpa || isEud) &&
          initiativeStore.formStateName !== f5statusNamesConst.DRAFT &&
          initiativeStore.formStateName !== f5statusNamesConst.SENT
      );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {
        ...formInitialValues,
        ...formStore.formSectionData[0],
      };
      setInitialValues(formSectionData);
    }
  }, [formStore.formSectionData]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values);
      formStore.setSectionStatus(sectionId, complete);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaved = (value) => {
    setSaved(value);
  };

  const handleButton = (value) => {
    setDisabledComments(value);
  };

  return (
    <FormLayout>
      <FormContainer
        number={13}
        title={t("F3_S13_title")}
        formId="form5section13"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
        showButtonClarif={!disabledComments}
      >
        <StyledDivWrapper>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              dirty,
            }) => (
              <form onSubmit={handleSubmit} id="form5section13">
                {dirty && setSaved(false)}

                <StyledTitle style={{ marginBottom: "30px" }}>
                  <Link
                    to={`${authStore.getBaseRouteByRole()}/initiative/${id}/form/${
                      isPublic ? "3" : "4"
                    }/section/13`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("F5_Subtitle_Description_from_proposal")}
                  </Link>
                </StyledTitle>

                <StyledTitle style={{ marginBottom: "30px" }}>
                  {t("F5_Subtitle_Assessment_TCI_Implementation")}
                </StyledTitle>

                <StyledSubtitle>
                  {t("F5_S7_Activity_assessment_12")}:
                </StyledSubtitle>

                <div style={{ marginTop: "20px" }}>
                  {formStore.formSectionData
                    ? formStore.formSectionData[1].map((activity) => (
                        <Form.Group style={{ display: "flex" }}>
                          <StyledFormLabel style={{ margin: "0px" }}>
                            {activity.activityName}:
                          </StyledFormLabel>
                          <TextValue value={activity.assessment} />
                        </Form.Group>
                      ))
                    : "-"}
                </div>
                
                <GreySubtitle> {t('F5_S13_subtitle')} <HelpIcon info={t("F5_S13_subtitle_INFO")} /></GreySubtitle>

                <Form.Group style={{ marginTop: "25px" }}>
                  <StyledFormLabel>
                    {t("F5_S13_human_rights")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="overviewMeasures"
                    as="textarea"
                    rows={6}
                    style={{ resize: "none" }}
                    placeholder={t("Maximum_1300")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.overviewMeasures}
                    disabled={nonEditable}
                    className={
                      touched.overviewMeasures && errors.overviewMeasures
                        ? "error"
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.overviewMeasures}
                    errors={errors.overviewMeasures}
                  />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel>
                    {t("F5_S13_TCI_contribute_awarness")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="tciContribute"
                    as="textarea"
                    rows={6}
                    style={{ resize: "none" }}
                    placeholder={t("Maximum_1300")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tciContribute}
                    disabled={nonEditable}
                    className={
                      touched.tciContribute && errors.tciContribute
                        ? "error"
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.tciContribute}
                    errors={errors.tciContribute}
                  />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel>
                    {t("F5_S13_TCI_promoted_cross_cutting")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="tciApproach"
                    as="textarea"
                    rows={6}
                    style={{ resize: "none" }}
                    placeholder={t("Maximum_1300")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tciApproach}
                    disabled={nonEditable}
                    className={
                      touched.tciApproach && errors.tciApproach ? "error" : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.tciApproach}
                    errors={errors.tciApproach}
                  />
                </Form.Group>
              </form>
            )}
          </Formik>

          {(isAtke || isConsortium || isF7) && (
            <Comments onSaved={handleSaved} onButton={handleButton} />
          )}
        </StyledDivWrapper>
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section13);
