import styled, {css} from 'styled-components';

export const StyledText = styled.p`
  ${({ theme }) => css`
    line-height: 1.3rem;
    color: ${theme.colors.gray[5]};
    text-align: justify;
  `}
`;

