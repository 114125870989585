import * as Yup from 'yup';
import i18n from 'i18n';

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const urlReg = /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;

export const validationSchema = Yup.object().shape({
  entities: Yup.array().of(
    Yup.object().shape({
      entityName: Yup.string()
        .min(2, 'F1_S2_validation1')
        .max(200, 'F1_S2_validation2'),
      acronym: Yup.string()
        .min(2, 'F1_S2_validation3')
        .max(100, 'F1_S2_validation4'),
      entityTypeId: Yup.string(),
      function: Yup.string()
        .min(2, 'F1_S4_validation1')
        .max(200, 'F1_S4_validation2'),
      countryId: Yup.string(),
      web: Yup.string()
        .min(5, 'F1_S2_validation6')
        .max(200, 'F1_S2_validation7')
        .matches(urlReg, 'F1_S2_validation15'),
      firstName: Yup.string()
        .min(2,  'F1_S2_validation16')
        .max(200, 'F1_S2_validation17'),
      lastName: Yup.string()
        .min(2,  'F1_S2_validation16')
        .max(200, 'F1_S2_validation17'),
      position: Yup.string()
        .min(2, 'F1_S2_validation18')
        .max(100, 'F1_S2_validation19'),
      email: Yup.string()
        .email('F1_S2_validation23')
        .max(100, 'F1_S2_validation24'),
      phone: Yup.string()
        .min(2, 'F1_S2_validation20')
        .max(100, 'F1_S2_validation21')
        .matches(phoneRegExp, 'F1_S2_validation22'),
    }),
  ),
});
