import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  fromDate: Yup.date()
    .typeError('F1_S7_validation9')
    .when('genInfoConfirmed', {
        is: true,
        then: Yup.date().required('F5_S7_required')
  }),
  toDate: Yup.date()
    .min(Yup.ref('fromDate'), 'F1_S7_validation4')
    .typeError('F1_S7_validation9')
    .when('genInfoConfirmed', {
        is: true,
        then: Yup.date().required('F5_S7_required')
    }),
  title: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(200, 'F1_S2_validation8')
    .nullable(true)
    .when('genInfoConfirmed', {
        is: true,
        then: Yup.string().required('F5_S7_required')
    }),
  format: Yup.string()
    .oneOf(['0', '1', '2'])
    .required('F1_S7_validation20'),
  countryId: Yup.string().when(['format'], {
    is: (format) => format === "0" || format === "1",
    then: Yup.string()
      .required('F1_S7_validation12'),
    otherwise: Yup.string().nullable(true)
  }),
  cityName: Yup.string().when(['format'], {
    is: (format) => format === "0" || format === "1",
    then: Yup.string()
      .min(3, 'F1_S2_validation11')
      .max(100, 'F1_S2_validation12')
      .required('F1_S7_validation13'),
    otherwise: Yup.string().nullable(true)
  }),
  stateName: Yup.string()
    .min(3, 'F1_S2_validation13')
    .max(100, 'F1_S2_validation14'),
  countriesAdditional: Yup.string()
    .max(300, 'F1_S7_validation14')
    .nullable(true),
  duration: Yup.number()
    .min(0, 'F1_S7_validation10')
    .when(['genInfoConfirmed', 'activityType'], {
        is: (genInfoConfirmed, activityType) => genInfoConfirmed && activityType && (activityType.id !== '4' && activityType.id !== '7') ,
        then: Yup.number().required('F5_S7_required')
    }),
  participants: Yup.number()
    .min(0, 'F1_S7_validation10')
    .when(['genInfoConfirmed', 'activityType'], {
        is: (genInfoConfirmed, activityType) => genInfoConfirmed && activityType && (activityType.id !== '4' && activityType.id !== '7') ,
        then: Yup.number().required('F5_S7_required')
    }),
  experts: Yup.number()
    .min(0, 'F1_S7_validation10')
    .when(['genInfoConfirmed', 'activityType'], {
        is: (genInfoConfirmed, activityType) => genInfoConfirmed && activityType && (activityType.id === '4' || activityType.id === '7') ,
        then: Yup.number().required('F5_S7_required')
    }),
  daysRemote: Yup.number()
    .min(0, 'F1_S7_validation10')
    .when(['genInfoConfirmed', 'activityType'], {
        is: (genInfoConfirmed, activityType) => genInfoConfirmed && activityType && (activityType.id === '4' || activityType.id === '7') ,
        then: Yup.number().required('F5_S7_required')
    }),
  daysOnsite: Yup.number()
    .min(0, 'F1_S7_validation10')
    .when(['genInfoConfirmed', 'activityType'], {
        is: (genInfoConfirmed, activityType) => genInfoConfirmed && activityType && (activityType.id === '4' || activityType.id === '7') ,
        then: Yup.number().required('F5_S7_required')
    }),
  workLang1: Yup.boolean().nullable(true).test(
    'allRequired',
    'F5_S7_required',
    function(item) {
      return (this.parent.genInfoConfirmed? (item || this.parent.workLang2 || this.parent.workLang3 || this.parent.workLang4) === true : true)
    }
  ),
  workLang2: Yup.boolean().nullable(true),
  workLang3: Yup.boolean().nullable(true),
  workLang4: Yup.boolean().nullable(true),
  objective: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when(['genInfoConfirmed', 'activityType'], {
        is: (genInfoConfirmed, activityType) => genInfoConfirmed && activityType && (activityType.id === '4' || activityType.id === '7' ) ,
        then: Yup.string().required('F5_S7_required')
    }),
  generalAlignment: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('genInfoConfirmed', {
        is: true,
        then: Yup.string().nullable(true)
    }),
  generalParticipationMembers: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('genInfoConfirmed', {
        is: true,
        then: Yup.string().nullable(true)
    }),
  generalProposalCrossIssues: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('genInfoConfirmed', {
        is: true,
        then: Yup.string().nullable(true)
    }),
  descSchedule: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('genInfoConfirmed', {
        is: true,
        then: Yup.string().required('F5_S7_required')
    }),
});