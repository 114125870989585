import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Form from 'react-bootstrap/Form';
import TextValue from 'components/TextValue'

import { useRootStore } from 'stores';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';

import { formInitialValues } from './Section5.initialValues';
import {
  StyledFormLabel,
  StyledSeparator,
  TextValueLeftSpaced
} from './Section5.styled';
import RequestClarification from '../RequestClarification';
import { useTranslation } from 'react-i18next';

const Section5 = () => {

  const {t} = useTranslation();
  const { formStore } = useRootStore();
  const [saved, setSaved] = useState(false);
  const [formValues, setFotmValues] = useState(formInitialValues);
  const [nonEditable, setNonEditable] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = { ...formInitialValues, ...formStore.formSectionData[0] };
      setFotmValues(formSectionData);
    }
    formStore.setCurrentSection(5);
  }, [formStore.formSectionData])

  const handleSaved = (value) => {
    setSaved(value);
  }

  const handleButton = (value) => {
    setNonEditable(value);
  }

  return (
    <FormLayout>
      <FormContainer 
        number={5} 
        title={t('F1_S5_title')}
        formId='form2section5'
        showSavedMessageF2={saved}
        showSavedMessage={false}
        showButtonClarif= {!nonEditable}
        showFooter={false}
        buttonText="SAVE CLARIFICATION REQUEST"
      >
        <Form.Group>
          <StyledFormLabel style={{textAlign: 'justify', lineHeight: '1.3'}}>{t('F1_S5_Provide_an_overview')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.overview} />
          </TextValueLeftSpaced>
        </Form.Group>  
        
        <StyledSeparator />
        <RequestClarification onSaved={handleSaved} 
                              onButton={handleButton} />
                            
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section5);


