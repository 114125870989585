import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { validationSchema } from './Section5.validations';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { saveFormSection } from 'services/initiative';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import { RequiredMark } from 'components/CommonStyled';
import {f1statusNamesConst} from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';


const formInitialValues = {
  overview: ""
};

const Section5 = () => {

  const {t} = useTranslation();

  const { formStore, initiativeStore } = useRootStore();
  const { id, formId, sectionId } = useParams();
  const [clarification, setClarification] = useState({
    show: false,
    msg: "",
  });
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  useEffect(() => {
    let newValues = formInitialValues;
    if (formStore.formSectionData) { 
      newValues = { ...formInitialValues, overview: formStore.formSectionData[0].overview}
    }
    setInitialValues(newValues);
  }, [formStore.formSectionData])
 
  useEffect(() => {
    if(initiativeStore.formStateName === f1statusNamesConst.DRAFT){
      if(initiativeStore.formVersion === 2){
        if(formStore.formSectionClarification){
          setNonEditable(false);
          setClarification({
            show: true,
            msg: (formStore.formSectionClarification? formStore.formSectionClarification: t('No_clarifications'))
          });
        }
        else{
          setNonEditable(true);
        }
      }
      else{
        setNonEditable(false);
      }
    }
    else{
      setNonEditable(true);
    }
  }, [initiativeStore.currentFormStateName, initiativeStore.formVersion, formStore.formSectionClarification])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);
  }, [saved])

  const onSubmit = async (values, {resetForm}) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values);
      formStore.setSectionStatus(sectionId, complete)
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid
      ? onSubmit(values, { resetForm })
      : alert(t('not_saved_alert'));
  }

  return (
    <FormLayout>
      <FormContainer 
        number={5} 
        title={t('F1_S5_title')}
        isHelp={true}
        formId="form1section5"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
      >
        <Formik 
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {( {values,
              errors,
              touched,
              dirty,
              resetForm,
              handleChange,
              handleBlur,
              isValid }) => (
                <form 
                  onSubmit={(e) => {onValidateSubmit(e, isValid, values, { resetForm })}}
                  id="form1section5"
                >
                  {dirty && (setSaved(false))}

                  { clarification.show &&
                  <Form.Group
                    style={{marginLeft: '50px', paddingTop: '10px'}}
                    as={Row}
                  >
                    <Form.Label>{t('Clarification_requests')}</Form.Label>
                    <Form.Control
                      as="textarea"
                      style={{border: '2px solid red',resize: 'none'}}
                      rows={4} 
                      disabled={true}
                      value={clarification.msg}
                    />
                  </Form.Group>}

                  <Form.Group style={{marginLeft: '50px', paddingTop: '10px'}}>
                    <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}} >{t('F1_S5_Provide_an_overview')}<RequiredMark>*</RequiredMark>:
                    </Form.Label>
                    <Form.Control
                      name="overview"
                      as="textarea" 
                      rows={12} 
                      placeholder={t('Maximum_1300')}
                      style={{resize: 'none'}}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.overview}
                      className={touched.overview && errors.overview ? "error" : null}
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.overview}
                      errors={errors.overview}
                    />
                  </Form.Group>
                </form>
              )}
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section5);

