import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActivitySubItem } from './ActivityItem.styled'

const ActivityItem = ({ handleOpenGeneralModal, activity }) => {

  const {t} = useTranslation();
  
  const handleOpenGeneralInfo = () => {
    handleOpenGeneralModal({
      show: true,
      formType: 0,
      title: t('F3_S7_Subtitle_General_Information'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const handleOpenProducts = () => {
    handleOpenGeneralModal({
      show: true,
      formType: 1,
      title: t('F3_S7_Subtitle_Products'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const handleOpenConsultants = () => {
    handleOpenGeneralModal({
      show: true,
      formType: 2,
      title: t('F3_S7_Subtitle_Consultants'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const handleOpenParticipants = () => {
    handleOpenGeneralModal({
      show: true,
      formType: 3,
      title: t('F3_S7_Subtitle_Participants_assistance'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const handleOpenResources = () => {
    handleOpenGeneralModal({
      show: true,
      formType: 4,
      title: t('F3_S7_Subtitle_Resources_required'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  return (
    <>
      <ActivitySubItem onClick={() => {handleOpenGeneralInfo()}}> {t('F3_S7_Subtitle_General_Information')} </ActivitySubItem>
      <ActivitySubItem onClick={() => {handleOpenProducts()}}> {t('F3_S7_Subtitle_Products')} </ActivitySubItem>
      {(activity.activityType && activity.activityType.id == 4 ) && <ActivitySubItem onClick={() => {handleOpenConsultants()}}> {t('F3_S7_Subtitle_Consultants')}  </ActivitySubItem>}
      {(activity.activityType && activity.activityType.id != 4 ) && <ActivitySubItem onClick={() => {handleOpenParticipants()}}> {t('F3_S7_Subtitle_Participants_assistance')} </ActivitySubItem>}
      <ActivitySubItem onClick={() => {handleOpenResources()}}> {t('F3_S7_Subtitle_Resources_required')} </ActivitySubItem>
    </>
  )

}

export default ActivityItem;
