import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import {ErrorButton} from 'components/Buttons'
import F1PDFGenerator from 'components/PDFgenerator/F1/F1PdfGen';
import F3PDFGenerator from 'components/PDFgenerator/F3/F3PdfGen';
import F5PDFGenerator from 'components/PDFgenerator/F5/F5PdfGen';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { 
  StyledModalBody,
  StyledSendButton,
} from './PDFGenerationModal.styled'
import Modal from 'react-bootstrap/Modal'
import { CenteredModalButtonContainer } from 'components/CommonStyled';

const PDFGenerationModal = ({ show, onClose, initiativeId, initiativeCode, formId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleGenerate = () => {
    setLoading(true);
  };

  const handleDone = () => {
    onClose();
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={onClose} size="lg" animation={false} backdrop="static" keyboard={false}>
      <StyledModalBody>     
        {
          loading ? (
            <div style={{ maxHeight: '400px', overflow: 'hidden' }}>
              <div style={{ marginTop: '5%', textAlign: 'center'}}> 
                {t('pdf_gen_generating')}
              </div>
              <Col style={{marginTop: '20%', marginBottom: '25%', textAlign: 'center'}}>
                <Spinner animation="border" variant="secondary"/>
              </Col>
              <div>
                {
                  (formId == 1) ? (
                    <F1PDFGenerator initiativeId={initiativeId} handleError={onClose} onCreatePDF={handleDone} />
                  ) : (
                    (formId == 3) ? (
                      <F3PDFGenerator initiativeId={initiativeId} initiativeCode={initiativeCode} handleError={onClose} onCreatePDF={handleDone} />
                    ) : (
                      <F5PDFGenerator initiativeId={initiativeId} handleError={onClose} onCreatePDF={handleDone} />
                    )
                  )
                }
              </div>
            </div>
          ) : (
            <>
              {t('pdf_gen_warning')}
              <CenteredModalButtonContainer>
                <ErrorButton style={{marginRight: '20px'}} onClick={onClose}> {t('Button_cancel')} </ErrorButton>
                <StyledSendButton onClick={handleGenerate}> {t('pdf_gen_generate')} </StyledSendButton>
              </CenteredModalButtonContainer>
            </>
          )
        }
      </StyledModalBody>
    </Modal>
  )
}

export default PDFGenerationModal;