import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { getPeriodElapsed, getIncidentals, getWorkingDays } from 'services/initiative';
import { PrimaryButton } from 'components/Buttons';

import CustomXLSXExportButton from './exportToXLSx';
import { StyledTitle, StyledIndicator, StyledSubtitle, StyledValue, StyledText, StyledRow } from './Dashboard.styled';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ProgressBar from 'react-bootstrap/ProgressBar';
import MoneyTextMask from 'components/MoneyTextMask';
import Spinner from 'react-bootstrap/Spinner';

const Dashboard = () => {
  const { t } = useTranslation();
  const [periodElapsed, setPeriodElapsed] = useState({});
  const [waiting1, setWaiting1] = useState(true);
  const [error1, setError1] = useState(false);

  const [incidentals, setIncidentals] = useState({});
  const [waiting2, setWaiting2] = useState(true);
  const [error2, setError2] = useState(false);

  const [workingDays, setWorkingDays] = useState({});
  const [waiting3, setWaiting3] = useState(true);
  const [error3, setError3] = useState(false);

  useEffect(() => {
    getValues();
  }, []);

  async function getValues() {
    let response;
    setWaiting1(true);
    setWaiting2(true);
    setWaiting3(true);

    try {
      response = await getPeriodElapsed();
      setPeriodElapsed(response.data);
    } catch (error) {
      setError1(true);
    }
    setWaiting1(false);

    try {
      response = await getIncidentals();
      setIncidentals(response.data);
    } catch (error) {
      setError2(true);
    }
    setWaiting2(false);

    try {
      response = await getWorkingDays();
      setWorkingDays(response.data);
    } catch (error) {
      setError3(true);
    }
    setWaiting3(false);
  }

  return (
    <>
      <StyledTitle>{t('Dashboard_Adelante2')}</StyledTitle>

      <Row style={{ justifyContent: 'flex-end' }}>
        <CustomXLSXExportButton
          waiting3={waiting3}
          periodElapsed={periodElapsed}
          incidentals={incidentals}
          workingDays={workingDays}
        />
      </Row>
      <Row style={{ marginLeft: '20px', marginTop: '40px', marginBottom: '40px' }}>
        <Col>
          <StyledIndicator>
            <StyledRow style={{ marginTop: '10px' }}>
              <StyledSubtitle>{t('Period_elapsed')}</StyledSubtitle>
            </StyledRow>

            {waiting1 ? (
              <StyledRow>
                <Spinner animation="border" variant="secondary" />
              </StyledRow>
            ) : error1 ? (
              <StyledRow style={{ color: 'red', textAlign: 'center' }}>{t('error_loading_data')}</StyledRow>
            ) : (
              <>
                <StyledRow style={{ marginBottom: '35px' }}>
                  <ProgressBar
                    now={periodElapsed.percentage}
                    label={`${periodElapsed.percentage.toFixed(2)}%`}
                    variant="info"
                    style={{ height: '50px', width: '70%' }}
                  />
                </StyledRow>

                <StyledRow>
                  <StyledText>{t('Elapsed')}:</StyledText>
                  <StyledValue>{`${periodElapsed.elapsed} ${t('Months')}`}</StyledValue>
                </StyledRow>

                <StyledRow>
                  <StyledText>{t('Remaining')}:</StyledText>
                  <StyledValue>{`${periodElapsed.remaining} ${t('Months')}`}</StyledValue>
                </StyledRow>
              </>
            )}
          </StyledIndicator>
        </Col>

        <Col>
          <StyledIndicator>
            <StyledRow style={{ marginTop: '10px' }}>
              <StyledSubtitle>{t('Incidentals')}</StyledSubtitle>
            </StyledRow>

            {waiting2 ? (
              <StyledRow>
                <Spinner animation="border" variant="secondary" />
              </StyledRow>
            ) : error2 ? (
              <StyledRow style={{ color: 'red', textAlign: 'center' }}>{t('error_loading_data')}</StyledRow>
            ) : (
              <>
                <StyledRow style={{ marginBottom: '35px' }}>
                  <ProgressBar
                    now={incidentals.percentage}
                    label={`${incidentals.percentage.toFixed(2)}%`}
                    variant="info"
                    style={{ height: '50px', width: '70%' }}
                  />
                </StyledRow>

                <StyledRow>
                  <StyledText>{t('Consumed')}:</StyledText>
                  <StyledValue>
                    <MoneyTextMask value={incidentals.consumed} />
                  </StyledValue>
                </StyledRow>

                <StyledRow>
                  <StyledText>{t('Available')}:</StyledText>
                  <StyledValue>
                    <MoneyTextMask value={incidentals.available} />
                  </StyledValue>
                </StyledRow>
              </>
            )}
          </StyledIndicator>
        </Col>

        <Col>
          <StyledIndicator>
            <StyledRow style={{ marginTop: '10px' }}>
              <StyledSubtitle>{t('Nke_working_days')}</StyledSubtitle>
            </StyledRow>

            {waiting3 ? (
              <StyledRow>
                <Spinner animation="border" variant="secondary" />
              </StyledRow>
            ) : error3 ? (
              <StyledRow style={{ color: 'red', textAlign: 'center' }}>{t('error_loading_data')}</StyledRow>
            ) : (
              <>
                <StyledRow style={{ marginBottom: '35px' }}>
                  <ProgressBar
                    now={workingDays.percentage}
                    label={`${workingDays.percentage.toFixed(2)}%`}
                    variant="info"
                    style={{ height: '50px', width: '70%' }}
                  />
                </StyledRow>

                <StyledRow>
                  <StyledText>{t('Executed')}:</StyledText>
                  <StyledValue>{`${workingDays.consumed} ${t('Working')}`}</StyledValue>
                </StyledRow>

                <StyledRow>
                  <StyledText>{t('Available')}:</StyledText>
                  <StyledValue>{`${workingDays.available} ${t('Working')}`}</StyledValue>
                </StyledRow>
              </>
            )}
          </StyledIndicator>
        </Col>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <PrimaryButton onClick={() => getValues()} disabled={waiting3}>
          {t('Button_Reload')}
        </PrimaryButton>
      </Row>
    </>
  );
};

export default observer(Dashboard);
