import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { formInitialValues } from './NewDifussionMaterialForm.initialValues'
import { validationSchema } from './NewDifussionMaterialForm.validations'
import { addF5S14Material, editF5S14Material } from 'services/initiative'

import Modal from 'react-bootstrap/Modal'
import FileUploader from 'components/FileUploader'
import { handleFileUpload, handleDownload } from '../helper'

import { ErrorButton, PrimaryButton } from 'components/Buttons'
import Form from 'react-bootstrap/Form'
import {
  RequiredMark,
  CenteredModalButtonContainer,
} from 'components/CommonStyled'
import {
  StyledModalBody,
  ModalTitle,
  StyledCol,
  StyledDiv,
} from './NewDifussionMaterialForm.styled'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const NewDifussionMaterialForm = ({
  handleCloseOk,
  handleClose,
  handleCloseOnError,
  nonEditable,
  setMaterialList,
  show,
  activities,
  isEdit,
  data,
  setModalInfo,
  fixedActivity,
}) => {
  const { t } = useTranslation()
  const { id } = useParams()

  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [sending, setSending] = useState(false)

  useEffect(() => {
    if (isEdit) {
      setInitialValues({
        activityId: data?.activityId || fixedActivity?.id,
        materialTypeId: data?.materialType.id,
        url: data?.url,
        description: data?.description,
        file: data?.file,
      })
    }
  }, [data])

  const handleSubmit = async (values) => {
    setSending(true)
    let response
    try {
      if (isEdit) {
        response = await editF5S14Material(id, values, data?.id)
        setMaterialList(response.materials)
      } else {
        response = await addF5S14Material(id, values)
        setMaterialList(response)
      }

      handleCloseOk()
    } catch (error) {
      handleCloseOnError()
    }
  }

  const renderMaterialOptions = () => {
    let options = []
    options.push(<option value="">{t('Placeholder_select')}</option>)
    for (let i = 1; i <= 7; i++) {
      options.push(
        <option key={i} value={i}>
          {t(`material_type_${i}`)}
        </option>,
      )
    }
    return options
  }

  const renderActivities = () => {
    let options = [];
    options.push(<option value={fixedActivity?.id}> {fixedActivity?.name} </option>);
    if (!fixedActivity) {
      options = [<option value="">{t('Placeholder_select')}</option>]
      activities?.forEach((e) => (
        options.push(<option value={e?.id}> {e?.name} </option>)
      ))
    }
    return options;
  }

  useEffect(() => {
    if (!isEdit && fixedActivity?.id) {
      setInitialValues({
        ...initialValues,
        activityId: fixedActivity?.id,
      })
    }
  }, [fixedActivity?.id, isEdit])

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        animation={false}
      >
        <StyledModalBody>
          <ModalTitle>
            <span>{t('F5_S14_Modal_Title7')}</span>
          </ModalTitle>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit} id="newDifussionMaterialForm">
                <StyledDiv>
                  <StyledCol>
                    <Form.Label>
                      {t('F5_S14_activity')}
                      <RequiredMark>*</RequiredMark>:
                    </Form.Label>
                  </StyledCol>
                  <StyledCol>
                    <Form.Control
                      name="activityId"
                      as="select"
                      onChange={handleChange}
                      value={values.activityId}
                      disabled={nonEditable || fixedActivity}
                    >
                      {renderActivities()}
                    </Form.Control>
                    <FormErrorMessage
                      touched={touched.activityId}
                      errors={errors.activityId}
                    />
                  </StyledCol>
                </StyledDiv>

                <StyledDiv>
                  <StyledCol>
                    <Form.Label>
                      {t('F5_S14_difussion_material_type')}
                      <RequiredMark>*</RequiredMark>:
                    </Form.Label>
                  </StyledCol>
                  <StyledCol>
                    <Form.Control
                      name="materialTypeId"
                      as="select"
                      onChange={handleChange}
                      value={values.materialTypeId}
                      disabled={nonEditable}
                    >
                      {renderMaterialOptions()}
                    </Form.Control>
                    <FormErrorMessage
                      touched={touched.materialTypeId}
                      errors={errors.materialTypeId}
                    />
                  </StyledCol>
                </StyledDiv>

                <StyledDiv style={{ margin: '0px' }}>
                  <StyledCol sm={3}>
                    <Form.Label>{t('F1_S8_Link_account')}:</Form.Label>
                  </StyledCol>
                  <StyledCol sm={9}>
                    <Form.Control
                      type="text"
                      name="url"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.url}
                      className={touched.url && errors.url ? 'error' : null}
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.url}
                      errors={errors.url}
                    />
                  </StyledCol>
                </StyledDiv>

                <StyledCol>
                  <FileUploader
                    file={values.file}
                    handleFileUpload={(e) =>
                      handleFileUpload(
                        e,
                        setFieldValue,
                        handleChange,
                        setIsUploadingFile,
                        setModalInfo,
                        id,
                      )
                    }
                    handleDownload={() =>
                      handleDownload(
                        values.file.uuid,
                        values.file.fileName,
                        setModalInfo,
                      )
                    }
                    isUploadingFile={isUploadingFile}
                    uploadLabel={t('F5_S14_upload_document')}
                    nonEditable={nonEditable}
                  />
                </StyledCol>

                <StyledCol>
                  <Form.Label>{t('F1_S8_Media_name')}:</Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    rows={2}
                    placeholder={t('Maximum_1000')}
                    style={{ resize: 'none' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    className={
                      touched.description && errors.description ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.description}
                    errors={errors.description}
                  />
                </StyledCol>

                <CenteredModalButtonContainer>
                  <ErrorButton
                    style={{ marginRight: '20px' }}
                    onClick={handleClose}
                    disabled={sending}
                  >
                    {t('Button_cancel')}
                  </ErrorButton>
                  <PrimaryButton
                    type="submit"
                    form="newDifussionMaterialForm"
                    disabled={sending || nonEditable}
                  >
                    {t('Button_save')}
                  </PrimaryButton>
                </CenteredModalButtonContainer>
              </Form>
            )}
          </Formik>
        </StyledModalBody>
      </Modal>
    </>
  )
}

export default NewDifussionMaterialForm
