import i18n from 'i18n'

export const roleNamesConst = {
  APPLICANT: "APPLICANT",
  PUBLIC: "PUBLIC",
  INTPA: "INTPA",
  EUD: "EUD",
  ATKE: "ATKE",
  ATNKE: "ATNKE",
  CONSORTIUM: "CONSORTIUM",
  EXTERNO: "EXTERNO",
  SUPERADMIN: "SUPER_ADMIN",
  OWNER: "OWNER",
  GUEST: "GUEST",
};

export const f1statusNamesConst = {
  DRAFT: "DRAFT",
  SENT: "SENT",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
  EXPIRED: "EXPIRED",
};

export const f2statusNamesConst = {
  PENDING: "PENDING",
  PENDINGV2: "PENDING V2",
  ONGOING: "ONGOING",
  ONGOINGV2: "ONGOING V2",
  DONE: "DONE",
  EXPIRED: "EXPIRED"
};

export const f3statusNamesConst = {
  DRAFT: "DRAFT",
  SENT: "SENT",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
  EXPIRED: "EXPIRED",
};

export const f4statusNamesConst = {
  PENDING_DRAFT: "PENDING_S7",
  ENABLE_S7: "ENABLE_S7",
  PENDING: "PENDING",
  ONGOING: "ONGOING",
  DONE: "DONE",
  EXPIRED: "EXPIRED",
};

export const f5statusNamesConst = {
  DRAFT: "DRAFT",
  SENT: "SENT",
  ASSESSMENT: "ASSESSMENT",
  EXPOST: "EX_POST",
  FINALIZED: "FINALIZED",
  CANCELLED: "CANCELED",
};

export const f5ActivityStatesConst = {
  INCOMPLETE: "INCOMPLETE",
  CONFIRMED: "CONFIRMED",
  CANCELLED: "CANCELLED",
};

export const f5ActivityBudgetStatesConst = {
  PLANNED: "PLANNED",
  CONTRACTING: "CONTRACTING",
  EXECUTION: "EXECUTION",
  FINALIZED: "FINALIZED",
  CANCELLED: "CANCELLED",
};

export const initiativeStatusNames = {
  PRE_FORMULATION: "PRE_FORMULATION",
  FORMULATION: "FORMULATION",
  IMPLEMENTATION: "IMPLEMENTATION",
  REPORTING: "REPORTING",
  FINAL_ASSESSMENT: "FINAL_ASSESSMENT",
  EXPOST: "EXPOST",
  EXPOST_ASSESSMENT: "EXPOST_ASSESSMENT",
  REJECTED: "REJECTED",
  EXPIRED: "EXPIRED",
  CANCELED: "CANCELED",
};

export const sectionStateF1Const = {
  NEW: "NEW",
  INCOMPLETE: "INCOMPLETE",
  COMPLETE: "COMPLETE",
  DONE: "DONE",
  CLARIFICATION: "CLARIFICATION",
};

export const expostStatesConst = {
  DONE: "DONE",
  SENT: "SENT",
}

export const f5StatesEnum = {
  1: "DRAFT",
  2: "SENT",
  3: "FINALIZED",
  4: "CANCELED",
  5: "ASSESSMENT",
  6: "EX_POST",
}

export const roleStatesEnum = {
  '0': "F1_S2_Recipient",
  '1': "F1_S2_Provider1",
  '2': "F1_S2_Provider2",
}

export const functionStatesEnum = {
  'coordinator': "coordinator",
  'partner': "partner",
  'collaborator': "collaborator",
}

export const states = {
  LA: "LA",
  CA: "CA",
  EU: "EU",
  other: 'Other',
}

export const REGIONS_OPTION_MAP = {
  1: "LA",
  2: "CA",
  3: "EU",
  4: "other",
}

export const EXECUTION_CATEGORY_LABEL = {
  "entities": "execution_category_1",
  "alliances": "execution_category_2",
  "initiatives": "execution_category_3",
  "activities": "execution_category_4",
  "countries": "execution_category_5",
  "ods": "execution_category_6",
  "beneficiaries": "execution_category_7",
  "collaborators": "execution_category_8",
  "totalEntities": "total_entities",
}

export const EXECUTION_FUNDS_CATEGORY_LABEL = {
  ...EXECUTION_CATEGORY_LABEL,
  "initiatives": "funds_page_title",
}


export const EXPOST_CATEGORY_LABEL = {
  "done": "F6_state_3",
  "cancelled": "f6Status5",
  "ongoing": "F6_state_2",
  "pending": "F6_state_1",
  "expired": "EXPIRED",
}

export const TARGET_YEARS = ['2021', '2022', '2023', '2024']

export const FUNDS_TITLE_ROW = {
  label: i18n.t('triang_coop_funds_proj'),
  col1: '',
  col2: '',
  col3: '',
  col4: '',
  col5: '',
}

export const INITIATIVE_TITLE_ROW = {
  label: i18n.t('triang_coop_initiatives'),
  col1: '',
  col2: '',
  col3: '',
  col4: '',
  col5: '',
}