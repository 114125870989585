import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import { Table, Th3Pointer } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'

import {
  T5RenderRows,
  renderHeaders,
  orderDataByFieldToSummary,
} from '../helpers/helpers'
import { TARGET_YEARS } from 'constants/appConstants'

const Table5 = ({ data, waiting, setData }) => {
  const { t } = useTranslation()
  const [ascendingOrder, setAscendingOrder] = useState({
    category: true,
    2021: true,
    2022: true,
    2023: true,
    2024: true,
    total: true,
  })

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data && !isEmpty(data) ? (
    <Table>
      <colgroup>
        <col span="5" />
        <col span="5" style={{ backgroundColor: '#a6b3bb' }} />
      </colgroup>
      <tr>
        <Th3Pointer
          onClick={() =>
            orderDataByFieldToSummary(
              data,
              'category',
              setData,
              setAscendingOrder,
              ascendingOrder,
              5,
            )
          }
        >
          {t(t('Statistics_activities_tab5'))} &#8645;
        </Th3Pointer>
        {renderHeaders(
          TARGET_YEARS,
          data,
          setData,
          setAscendingOrder,
          ascendingOrder,
        )}
      </tr>
      {T5RenderRows(data, TARGET_YEARS)}
    </Table>
  ) : (
    t('No_data_available_2')
  )
}

export default Table5
