import i18n from 'i18n'

const activitiesTypesOptions = () => {
  let options = []
  for (let index = 1; index <= 8; index++) {
    options.push(
      <option key={index} value={index}>
        {i18n.t(`activity_types_${index}`)}
      </option>,
    )
  }
  return options
}

export const renderActivitiesTypesOptions = (countriesOptions) => (
  <>
    <option value=""> {i18n.t('ALL')} </option>
    {activitiesTypesOptions()}
  </>
)
