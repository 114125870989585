import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportPartnershipsToXLSX } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'

import { Form } from 'react-bootstrap'
import { PrimaryButton } from 'components/Buttons'
import {
  Col1,
  Col2,
  StyledFormGroup,
  StyledFormGroup2,
  StyledFormLabel,
} from '../../CommonStyled/Components.styled'
import Row from 'react-bootstrap/Row'

import { renderStatesOptions } from 'helpers/renderStatesHelper'
import { renderYears } from 'helpers/dateHelpers'
import { getPartnershipsExportValues } from '../helpers/helpers'
import { renderTypeOptions } from 'pages/statistics/CommonHelpers/helpers'

const Header = ({ data, waiting, getData }) => {
  const { t } = useTranslation()

  const [regionsFilter, setRegionsFilter] = useState('')
  const [yearsFilter, setYearsFilter] = useState(0)
  const [typeFilter, setTypeFilter] = useState('*')

  const handleFilter = () => {
    let params = {}
    if (regionsFilter) params.params = regionsFilter
    if (yearsFilter && yearsFilter != 0) params.year = yearsFilter
    if (typeFilter) {
      if (typeFilter === '*') {
        params.includeFunds = true;
        params.includeInitiatives = true;
      }
      if (typeFilter === 'FUNDS') {
        params.includeFunds = true;
        params.includeInitiatives = false;
      }
      if (typeFilter === 'INITIATIVES') {
        params.includeFunds = false;
        params.includeInitiatives = true;
      }
    }
    getData(params)
  }

  return (
    <Row>
      <Col1 xs={10}>
        <StyledFormGroup style={{ width: '40%' }}>
          <StyledFormLabel>{t('Country_coordinating_entity')}</StyledFormLabel>
          <Form.Control
            name="regionId"
            as="select"
            onChange={(e) => {
              setRegionsFilter(e.target.value)
            }}
            value={regionsFilter}
          >
            {renderStatesOptions()}
          </Form.Control>
        </StyledFormGroup>

        <StyledFormGroup style={{ width: '20%' }}>
          <StyledFormLabel>{t('Dashboard_Year_2')}</StyledFormLabel>
          <Form.Control
            name="year"
            as="select"
            onChange={(e) => {
              setYearsFilter(e.target.value)
            }}
            value={yearsFilter}
          >
            {renderYears()}
          </Form.Control>
        </StyledFormGroup>

        <StyledFormGroup2 style={{ width: '30%' }}>
          <StyledFormLabel>{t('type_filter_stat')}</StyledFormLabel>
          <Form.Control
            name="type"
            as="select"
            onChange={(e) => {
              setTypeFilter(e.target.value)
            }}
            value={typeFilter}
          >
            {renderTypeOptions()}
          </Form.Control>
        </StyledFormGroup2>

        <PrimaryButton
          onClick={() => {
            handleFilter()
          }}
          disabled={waiting}
        >
          {t('Button_search')}
        </PrimaryButton>
      </Col1>
      <Col2>
        <ExportToXLSXButton
          data={data}
          mapData={getPartnershipsExportValues}
          disabled={waiting}
          exportName={t('menu_admin_6_4')}
        >
          <ExportPartnershipsToXLSX data={data} />
        </ExportToXLSXButton>
      </Col2>
    </Row>
  )
}

export default Header
