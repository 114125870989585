import styled, { css } from 'styled-components'
import { PrimaryButton } from 'components/Buttons'
import Button from 'react-bootstrap/Button';

export const StyledDivWrapper = styled.div`
  ${({ theme }) => css`
    padding: 30px 40px;
    background-color: ${theme.brandColors.gray4};
    border: 2px solid #d1d8e2;
    border-radius: 20px 0 20px 0;
    width: 100%;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex: 2;
  align-items: start;
`;

export const  StyledTitleHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  font-weight: 500;
  margin-bottom: 30px;
`;

export const  StyledNumberCircle = styled.div`
  ${({ theme }) => css`
    min-width: 45px;
    min-height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
    background-color: ${theme.brandColors.gray2};
  `}
`;

export const  StyledTitle = styled.span`
  color: #0eb1cf;
  margin-left: 10px;
  font-size:30px;
`;

export const  StyledBody = styled.div`
  color: #6f8d9d;
  font-weight: 500;
  line-height: 97%;
`;

export const  StyledFooter = styled.div`
  margin-top: 50px;
  padding: 10px;
  object-fit: contain;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const  StyledSendButton = styled(PrimaryButton)`
  ${({ theme }) => css`
    background-color: ${theme.brandColors.lightBlue};
    color: ${theme.colors.white};
    border: none;
    text-align: center;
    display: inline-block;
    font-weight: 600;
    border-radius: 16px;
    margin: -5px 0px;
    width: auto;
    height: 2em;
    padding: 0px 20px;
    letter-spacing: 2px;
    &:hover {
      background-color: ${theme.brandColors.lightBlueHover};
    }
    &:disabled {
      background-color:${theme.brandColors.green};
      color: white;
      border: ${theme.colorGroups.success.bgColor};
      &:hover: none !important;
    }
  `}
`;

export const StyledHelpDiv = styled.div`
  width: 500px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: -1px 3px 1px #aad8e2, 1px 3px 1px #aad8e2;
`;

export const StyledHelpTitle = styled.div`
  ${({ theme }) => css`
    padding-bottom: 10px;
    color: ${theme.brandColors.green};
    font-size: 18px;
    font-weight: 650;
  `}
`;

export const StyledButtonLink = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  box-shadow: none !important;
  margin-bottom: 4px;
`;

export const StyledSupport = styled.button`
  padding: 0;
  border: none;
  background: none;
  box-shadow: none !important;
  margin-bottom: 4px;
  color: #007bff;
`;

