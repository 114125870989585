import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  overviewOrganization: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(6500, 'F2_S9_validation1')
    .nullable(true),
  supportATTeam: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(6500, 'F2_S9_validation1')
    .nullable(true),
});
