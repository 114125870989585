import React, {useState, useEffect} from 'react';
import { formInitialValues } from 'components/Forms/Form5/Section16/Section16.initialValues';
import { useTranslation } from 'react-i18next';
import { mapActivityItems } from 'helpers/f5Helpers';

import Form from 'react-bootstrap/Form';
import { RequiredMark } from 'components/CommonStyled';
import {
  StyledFormLabel,
  Title,
  BlackSubtitle,
} from 'components/Forms/Form5/Section16/Section16.styled';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody 
} from '../CommonStyles'

const Section16 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(formInitialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data]);

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`16 - ${t('F5_S16_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <Title> {t('F5_Subtitle_Assessment_TCI_Implementation')} </Title>

        <BlackSubtitle> {t('F5_S7_Activity_assessment_3')} </BlackSubtitle>
        {mapActivityItems(formValues[1] || [])}

        <BlackSubtitle> {t('F5_S7_Activity_assessment_4')} </BlackSubtitle>
        {mapActivityItems(formValues[2] || [])}

        <BlackSubtitle> {t('F5_S7_Activity_assessment_5')} </BlackSubtitle>
        {mapActivityItems(formValues[3] || [])}

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S16_Overview_of_TCI')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="overviewOrganization"
            as="textarea" 
            rows={8} 
            style={{resize: 'none'}}
            value={formValues[0].overviewOrganization}
            disabled={true}
            placeholder={t('Maximum_6500')}
          />
        </Form.Group>
      </StyledBody>
    </SectionContainer>
  )
}

export default Section16;


