import React, { useState, useEffect } from 'react'
import Header3 from '../components/Header3'
import Table3 from '../components/Table3'
import { getEntitiesByFunctionSummary } from 'services/initiative'
import { useTranslation } from 'react-i18next'

import Row from 'react-bootstrap/Row'
import {
  StyledDiv,
  StyledRow,
  StyledRow2,
} from '../../CommonStyled/Tabs.styled'

import ExportToXLSXButton from 'components/ExportToXLSXButton'
import { T9RenderRows, getEntitiesByFunctionSummaryExportValues, getFundsEntitiesByFunctionSummaryExportValues } from '../helpers/helpers'
import { ExportStatisticsByYearsToXLSX } from 'helpers/xlsxStatisticsExporter'
import { getFundsEntitySummaryByFunction } from 'services/fundsStatistics'
import Table6 from '../components/Table6'

const EntitiesByFunctionSummary = () => {
  const { t } = useTranslation()
  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const [data2, setData2] = useState(null)
  const [waiting2, setWaiting2] = useState(false)

  const getData = async (param) => {
    setWaiting(true)
    try {
      const response = await getEntitiesByFunctionSummary(param)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  const getData2 = async (param) => {
    setWaiting2(true)
    try {
      let response = await getFundsEntitySummaryByFunction(param)
      setData2(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting2(false)
    }
  }

  useEffect(() => {
    getData()
    getData2()
  }, [])


  return (
    <StyledDiv>
      <Row>
        <Header3
          getData={getData}
          getData2={getData2}
          waiting={waiting}
          renderExportButton={() => (
            <ExportToXLSXButton
              data={data}
              mapData={getEntitiesByFunctionSummaryExportValues}
              data2={data2}
              mapData2={getFundsEntitiesByFunctionSummaryExportValues}
              disabled={waiting}
              exportName={t('Statistics_entities_tab3')}
            >
              <ExportStatisticsByYearsToXLSX
                label={t('Statistics_entities_tab3')}
                data={[]}
              />
            </ExportToXLSXButton>
          )}
        />
      </Row>
      <StyledRow>
        <Table3 data={data} waiting={waiting} setData={setData} />
      </StyledRow>
      <StyledRow2 style={{marginLeft: '0px'}}>{t('Statistics_footnote_2')}</StyledRow2>
      <StyledRow2 style={{marginLeft: '0px'}}>
        <Table6
          title={t('country_report_subtitle7')}
          data={data2}
          waiting={waiting2}
          renderRows={T9RenderRows}
          customWidth='62%'
          customWidthRow1='84%'
          customWidthRow2='16%'
          orderField='function'
          setData={setData2}
          total={true}
        />
      </StyledRow2>
    </StyledDiv>
  )
}

export default EntitiesByFunctionSummary
