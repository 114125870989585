import React, { useState, useEffect } from 'react';
import { validationSchema } from './Section7.validations';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getFormSection, editMeeting, deleteMeeting } from 'services/initiative';
import { f5statusNamesConst, roleNamesConst, f5ActivityStatesConst } from 'constants/appConstants';
import { initialValues } from './Section7.initialValues';
import Comments from '../Comments';

import { PrimaryButton } from 'components/Buttons';
import ConfirmCloseModal from 'components/ConfirmCloseModal';
import CustomModal from 'components/Modal';
import { RequiredMark, SeparatorDotted } from 'components/CommonStyled';
import ActivityItem from './ActivityItem';
import ActivityGeneralModal from './ActivityGeneralModal';
import AddMeetingModal from './AddMeetingModal';
import FormLayout from '../../FormLayout';
import FormContainer from 'components/FormContainer';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import MoneyTextMask from 'components/MoneyTextMask';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { StyledBudgetDiv, StyledNoticeParagraph, BlackSpan, ActivityStateSpan } from './Section7.styled';
import Meetings from 'components/Meetings';
import {
  mustShowFootNote,
  mustShowOldFootNote,
  hasOldBudgetValidation
} from 'constants/budgetConstants';
import { formatDateDDMMYY } from 'helpers/dateHelpers';
import { useGetBudgetRestrictions } from 'customHooks/useGetBudgetRestrictions';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const Section7 = () => {
  const { t } = useTranslation();
  const [activityModal, setActivityModal] = useState({
    formikInstance: null,
    show: false,
    formType: 0,
    title: '',
    activity: {},
    activityCode: '',
  });
  const { initiativeStore, formStore, authStore } = useRootStore();
  const [formValues, setFormValues] = useState(initialValues);
  const { id, formId, sectionId } = useParams();
  const [nonEditable, setNonEditable] = useState(false);
  const [nonEditableMgm, setNonEditableMgm] = useState(false);
  const [closeModalShow, setCloseModalShow] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [meetingsModalData, setMeetingsModalData] = useState({
    show: false,
    currentItem: null,
  });
  const [meetingsRemoveModalData, setMeetingsRemoveModalData] = useState({
    show: false,
    currentItem: null,
  });

  const currentRole = authStore.roles[0];

  const isConsortium = currentRole === roleNamesConst.CONSORTIUM;
  const isAtke = currentRole === roleNamesConst.ATKE;
  const isIntpa = currentRole === roleNamesConst.INTPA;
  const isEud = currentRole === roleNamesConst.EUD;
  const isPublic = currentRole === roleNamesConst.PUBLIC;

  const [isF7, setIsF7] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    message: t('Error try again later'),
    type: 'error',
  });
  const [saved, setSaved] = useState(false);
  const [disabledComments, setDisabledComments] = useState(true);



  const initiativeYear = initiativeStore?.code?.split('-')?.[1];
  const isBefore2024 = initiativeYear ? Number(initiativeYear) < 24 : true;
  const budgetLimitText = isBefore2024 ? t('F1_S7_budget_foot_note2022') : t('F1_S7_budget_foot_note2024');
  const {
    euBudgetMin,
    euBudgetLimit,
    euBudgetPercentageLimit
  } = useGetBudgetRestrictions(!isBefore2024);

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f5statusNamesConst.DRAFT || initiativeStore.role !== roleNamesConst.APPLICANT,
    );
    setNonEditableMgm(initiativeStore.formStateName !== f5statusNamesConst.DRAFT);
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (formStore.formSectionData && formStore.formSectionData[0]) {
      setFormValues({ ...formStore.formSectionData[0] });
    }
  }, [formStore.formSectionData]);

  useEffect(() => {
    if (initiativeStore.formStateName)
      setIsF7(
        (isIntpa || isEud) &&
          initiativeStore.formStateName !== f5statusNamesConst.DRAFT &&
          initiativeStore.formStateName !== f5statusNamesConst.SENT,
      );
  }, [initiativeStore.formStateName]);

  const onHideModalInfo = () => {
    setModalInfo({
      isOpen: false,
      message: t('Error try again later'),
      type: 'error',
    });
  };

  const handleCloseGeneralModal = () => {
    setActivityModal({
      ...activityModal,
      show: false,
    });
  };

  const handleOpenGeneralModal = () => {
    setActivityModal({
      ...activityModal,
      show: true,
    });
  };

  const isSection7Complete = activities => {
    let isComplete = Array.isArray(activities) && activities?.length > 1;
    activities.forEach(activity => {
      isComplete = isComplete && activity.complete;
    });
    return isComplete;
  };

  const handleRefreshList = async () => {
    try {
      const response = await getFormSection(id, formId, sectionId);
      if (response.section) {
        const activities = [...response.section[0].activities];
        setFormValues({ ...response.section[0] });
        const isComplete = isSection7Complete(activities);
        formStore.setSectionStatus(sectionId, isComplete);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenNewActivityForm = () => {
    setActivityModal({
      formikInstance: null,
      show: true,
      formType: 7,
      handleClose: handleCloseGeneralModal,
      title: t('New_activity'),
      activityCode: '',
    });
  };

  const handleSaved = value => {
    setSaved(value);
  };

  const handleButton = value => {
    setDisabledComments(value);
  };

  /*************************************
            START MEETINGS
  ////////////////////////////////////*/

  const handleOpenAddMeetingModal = () => {
    setMeetingsModalData({
      show: true,
      currentItem: null,
    });
  };

  const handleCloseAddMeetingModal = () => {
    setMeetingsModalData({
      show: false,
      currentItem: null,
    });
  };

  const handleOpenRemoveMeetingModal = meeting => {
    setMeetingsRemoveModalData({
      show: true,
      currentItem: meeting,
    });
  };

  const handleCloseRemoveMeetingModal = () => {
    setMeetingsRemoveModalData({
      show: false,
      currentItem: null,
    });
  };

  const handleOpenEditMeetingModal = meeting => {
    setMeetingsModalData({
      show: true,
      currentItem: meeting,
    });
  };

  const handleEditMeeting = async meeting => {
    const response = await editMeeting(id, meeting);
    const meetingsCopy = [...formValues.meetings];
    const currentIdx = meetingsCopy.findIndex(meet => meet.id === response?.data?.id);
    if (currentIdx > -1) {
      meetingsCopy[currentIdx] = response?.data;
      setFormValues({
        ...formValues,
        meetings: meetingsCopy,
      });
    } else {
      setFormValues({
        ...formValues,
        meetings: [
          ...formValues.meetings,
          { ...response?.data }
        ],
      });
    }
    handleCloseAddMeetingModal();
  };

  const handleRemoveMeeting = async () => {
    await deleteMeeting(id, meetingsRemoveModalData?.currentItem?.id);
    const meetingsCopy = [...formValues.meetings];
    const currentIdx = meetingsCopy.findIndex(meet => meet.id === meetingsRemoveModalData?.currentItem?.id);
    meetingsCopy.splice(currentIdx, 1);
    setFormValues({
      ...formValues,
      meetings: meetingsCopy,
    });
    handleCloseRemoveMeetingModal();
  };

  /*************************************
               END MEETINGS
  */////////////////////////////////////

  const hasOldValidation = hasOldBudgetValidation(initiativeStore?.code);



  return (
    <FormLayout>
      <AddMeetingModal
        show={meetingsModalData.show}
        handleClose={handleCloseAddMeetingModal}
        isDisabled={initiativeStore.formStateName !== f5statusNamesConst.DRAFT || !(isAtke || isConsortium || isPublic)}
        isDisabledCheck={!(isAtke || isConsortium)}
        currentMeeting={meetingsModalData.currentItem}
        onEdit={handleEditMeeting}
      />

      <ConfirmCloseModal
        show={meetingsRemoveModalData.show}
        handleCloseConfirm={handleCloseRemoveMeetingModal}
        handleActionOnConfirm={handleRemoveMeeting}
        text={t('F5_S7_Conf_delete_Meeting')}
      />

      <CustomModal show={modalInfo.isOpen} onHide={onHideModalInfo} message={modalInfo.message} type="error" />

      <CustomModal
        show={successModalShow}
        onHide={() => {
          handleCloseGeneralModal();
          setSuccessModalShow(false);
        }}
        message={t('saved')}
        type="success"
      />

      <ConfirmCloseModal
        show={closeModalShow}
        handleCloseConfirm={() => setCloseModalShow(false)}
        handleActionOnConfirm={handleCloseGeneralModal}
        text={t('Confirmation close modal')}
      />

      <FormContainer
        number={7}
        title={t('F3_S7_title')}
        formId="form5section7"
        showSavedMessage={saved}
        showButtonClarif={!disabledComments}
      >
        <ActivityGeneralModal
          handleRefreshList={handleRefreshList}
          show={activityModal.show}
          formType={activityModal.formType}
          handleClose={() => {
            setCloseModalShow(true);
          }}
          handleOpenGeneralModal={handleOpenGeneralModal}
          handleCloseOnSave={() => {
            handleCloseGeneralModal();
          }}
          handleCloseOnError={() => {
            setModalInfo({
              ...modalInfo,
              message: t('Error try again later'),
              type: 'error',
              isOpen: true,
            });
          }}
          handleCloseOk={() => {
            handleCloseGeneralModal();
          }}
          title={activityModal.title}
          activity={activityModal.activity}
          activityCode={activityModal.activityCode}
          setModalInfo={setModalInfo}
          nonEditable={nonEditable}
          nonEditableMgm={nonEditableMgm}
        />

        <Formik enableReinitialize initialValues={formValues} validationSchema={validationSchema}>
          {props => (
            <form id="form5section7">
              <div style={{ paddingLeft: '50px' }}>
                <StyledBudgetDiv>
                  <Form.Group as={Row} style={{ alignItems: 'center' }}>
                    <Form.Label column xs={5}>
                      {t('F1_S7_EU_budget')}:
                    </Form.Label>
                    <Col xs={3}>
                      <InputGroup>
                        <BlackSpan>
                          <MoneyTextMask value={props.values.ueBudget} disabled={true} customInput={Form.Control} />
                        </BlackSpan>
                      </InputGroup>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} style={{ alignItems: 'center' }}>
                    <Form.Label column xs={5}>
                      {t('F1_S7_counterpart_budget')}:
                    </Form.Label>
                    <Col xs={3}>
                      <InputGroup>
                        <BlackSpan>
                          <MoneyTextMask
                            value={props.values.allianceBudget}
                            disabled={true}
                            customInput={Form.Control}
                          />
                        </BlackSpan>
                      </InputGroup>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} style={{ alignItems: 'center' }}>
                    <Form.Label column xs={5}>
                      {t('F5_S7_Total_amount_executed')}:
                    </Form.Label>
                    <Col xs={3}>
                      <InputGroup>
                        <BlackSpan>
                          <MoneyTextMask value={props.values.totalBudget} disabled={true} customInput={Form.Control} />
                        </BlackSpan>
                      </InputGroup>
                    </Col>
                  </Form.Group>

                  {
                    hasOldValidation ? (
                      mustShowOldFootNote(
                        props.values.totalBudget,
                        props.values.ueBudget,
                        euBudgetMin,
                        euBudgetPercentageLimit
                      ) && (
                        <StyledNoticeParagraph>{t('F1_S7_budget_foot_note')}</StyledNoticeParagraph>
                      )
                    ) : (
                      mustShowFootNote(
                        props.values.totalBudget,
                        props.values.ueBudget,
                        euBudgetMin,
                        euBudgetLimit,
                        euBudgetPercentageLimit
                      ) && (
                        <StyledNoticeParagraph>{budgetLimitText}</StyledNoticeParagraph>
                      )
                    )
                  }
                </StyledBudgetDiv>

                <Accordion style={{ marginLeft: '-15px' }}>
                  {props.values.activities.map((activity, key) => {
                    const isActivityComplete =
                      activity.general_info_complete &&
                      activity.product_list_complete &&
                      ((activity?.activityType?.id == 4 && activity.profile_list_complete) ||
                        (activity?.activityType?.id != 4 && activity.participant_list_complete)) &&
                      activity.resource_list_complete &&
                      activity.assessment_complete;

                    return (
                      <AccordionItem key={key + 1}>
                        <AccordionItemHeader>
                          <AccordionTitle eventKey={key + 1}>
                            {t('F1_S7_Accordion') + '  '}
                            {activity.name + '  '}
                            {`- ${activity.activityType ? t(`activity_types_${activity.activityType.id}`) : ' '} (${
                              activity.fromDate ? formatDateDDMMYY(activity.fromDate) : ' '
                            })  `}
                            {activity.activityState && (
                              <>
                                {'-  '}
                                <ActivityStateSpan
                                  cancelled={activity.activityState.name === f5ActivityStatesConst.CANCELLED}
                                >
                                  [{isActivityComplete ? t('table_status_4') : t(activity.activityState.name)}]
                                </ActivityStateSpan>
                              </>
                            )}
                          </AccordionTitle>
                        </AccordionItemHeader>
                        <Accordion.Collapse eventKey={key + 1}>
                          <AccordionItemBody style={{ marginLeft: '30px' }}>
                            <ActivityItem
                              handleRefreshList={handleRefreshList}
                              handleOpenGeneralModal={setActivityModal}
                              activity={activity}
                              setModalInfo={setModalInfo}
                            />
                          </AccordionItemBody>
                        </Accordion.Collapse>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
                <FormErrorMessage
                  touched
                  errors={props.errors.activities}
                />

                {(isAtke || isConsortium) && (
                  <>
                    <SeparatorDotted />
                    <PrimaryButton
                      onClick={handleOpenNewActivityForm}
                      disabled={nonEditableMgm || initiativeStore.role !== roleNamesConst.ATKE}
                    >
                      {t('F1_S7_Button_add_activity')}
                    </PrimaryButton>
                  </>
                )}
              </div>
            </form>
          )}
        </Formik>

        <div style={{ marginLeft: '50px' }}>
          <SeparatorDotted />

          <Meetings
            items={formValues.meetings}
            onAddMeeting={handleOpenAddMeetingModal}
            onRemoveMeeting={handleOpenRemoveMeetingModal}
            onEditMeeting={handleOpenEditMeetingModal}
            isAbleToAdd={
              (isAtke || isConsortium || isPublic) && initiativeStore.formStateName === f5statusNamesConst.DRAFT
            }
          />
        </div>

        {(isAtke || isConsortium || isF7) && (
          <div style={{ marginLeft: '50px' }}>
            <Comments onSaved={handleSaved} onButton={handleButton} />
          </div>
        )}
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section7);
