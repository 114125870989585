import React from 'react';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import { RequiredMark } from 'components/CommonStyled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';



const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  const getFieldTouched = getIn(formikInstance.touched, field);
  return {
    touched: getFieldTouched,
    error: (
      <FormErrorMessage
        touched={getFieldTouched}
        errors={getFieldError}
      />
    )
  }
}

const EntityForm = ({ formikInstance, ownerIndex, nonEditable }) => {
  const { t } = useTranslation();
  const { getFieldProps, touched, errors } = formikInstance;
  const fieldPath = (field) => `ownerList.${ownerIndex}.${field}`;

  return (
    <Form.Group>
      <Form.Label>{t('F3_S10_Degee_of_involvement')}<RequiredMark>*</RequiredMark>: </Form.Label>
      <Form.Control
        name={fieldPath('degree')}
        as="textarea" 
        rows={3} 
        style={{resize: 'none'}}
        placeholder= {t('Maximum_650')}
        className={touched[fieldPath('degree')] && errors[fieldPath('degree')] ? "error" : null}
        { ...getFieldProps(fieldPath('degree')) }
        disabled={nonEditable}
      />
      {fieldInfo(formikInstance, fieldPath('degree')).error}
    </Form.Group>
  )
}

export default EntityForm;