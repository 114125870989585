import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';
import htmlParser from 'html-react-parser';

import TextValue from 'components/TextValue';
import Form from 'react-bootstrap/Form';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  StyledFormLabel,
  StyledSubtitle,
  Text,
} from 'components/Forms/Form4/Section12/Section12.styled';
import { isWindows2021 } from 'helpers/isWindow2021Helper';

const Section12 = ({ data, code }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);
  
  useEffect(() => {
    if (data) {
      setFormValues({
        ...(data?.[0] || {}),
        valueTC: data?.[1],
        valueEU: data?.[2],
        valueTP: data?.[3],
      })
    }
  }, [data])

  const window2021 = isWindows2021(code);
  
  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle>  {`12 - ${t('F3_S12_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <Text>
          {htmlParser(t('F3_S12_Intro'))}
        </Text>
        
        <StyledSubtitle size="20px"> {htmlParser(t('F3_S12_Subtitle_TC'))}  </StyledSubtitle>
        
        <Form.Group>
          <StyledFormLabel>{htmlParser(t('F3_S12_Justfication_TC'))}:</StyledFormLabel>
          <TextValue value={formValues.valueTC} />
        </Form.Group>  

        <Form.Group>
          <StyledFormLabel>{htmlParser(t('F3_S12_Justfication_partnership'))}:</StyledFormLabel>
          <TextValue value={formValues.valueEU} />
        </Form.Group>  
        
        {window2021 ? (
          <>  
            <Text> {htmlParser(t('F3_S12_Subtitle_Added_TC'))} </Text>

            <Form.Group>
              <StyledFormLabel>{htmlParser(t('F3_S12_Ownership'))}:</StyledFormLabel>
              <TextValue value={formValues.valueTC1} />
            </Form.Group> 

            <Form.Group>
              <StyledFormLabel>{htmlParser(t('F3_S12_Complementarity'))}:</StyledFormLabel>
              <TextValue value={formValues.valueTC2} />
            </Form.Group> 

            <Form.Group>
              <StyledFormLabel>{htmlParser(t('F3_S12_Sharing_knowledge'))}:</StyledFormLabel>
              <TextValue value={formValues.valueTC3} />
            </Form.Group> 

            <Form.Group>
              <StyledFormLabel>{htmlParser(t('F3_S12_Joint_creation'))}:</StyledFormLabel>
              <TextValue value={formValues.valueTC4} />
            </Form.Group> 

            <Form.Group>
              <StyledFormLabel>{htmlParser(t('F3_S12_Increasing_volume'))}:</StyledFormLabel>
              <TextValue value={formValues.valueTC5} />
            </Form.Group> 

            <Form.Group>
              <StyledFormLabel>{htmlParser(t('F3_S12_Global_and_regional'))}:</StyledFormLabel>
              <TextValue value={formValues.valueTC6} />
            </Form.Group> 
          </>
        ) : null}

        <StyledSubtitle size="20px"> {htmlParser(t('F3_S12_Subtitle_Added_EU'))} </StyledSubtitle>

        <Form.Group>
          <StyledFormLabel>{htmlParser(t('F3_S12_EU_Added_value'))}</StyledFormLabel>
          <TextValue value={formValues.valueTP} />
        </Form.Group>  

        <Form.Group>
          <StyledFormLabel>{htmlParser(t('F3_S12_Added_EU_actors'))}:</StyledFormLabel>
          <TextValue value={formValues.valueEUActors} />
        </Form.Group> 

        {window2021 ? (
          <Form.Group>
            <StyledFormLabel>{htmlParser(t('F3_S12_From_comparison_modalities'))}:</StyledFormLabel>
            <TextValue value={formValues.valueEUModalities} />
          </Form.Group> 
        ) : null}
      </StyledBody>
    </SectionContainer>
  )
}


export default Section12;