import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useRootStore } from "stores";
import { useParams, Link } from "react-router-dom";
import { formInitialValues } from "./Section11.initialValues";
import { useTranslation } from "react-i18next";
import DayJS from "dayjs";
import { validationSchema } from "./Section11.validations";
import { saveFormSection } from "services/initiative";
import { f5statusNamesConst, roleNamesConst } from "constants/appConstants";
import Comments from "../Comments";
import { isWindows2021 } from "helpers/isWindow2021Helper";

import ConfirmCloseModal from "components/ConfirmCloseModal";
import CustomModal from "components/Modal";
import ObjectiveFormModal from "./ObjectiveForm";
import FormLayout from "../../FormLayout";
import FormContainer from "components/FormContainer";
import Form from "react-bootstrap/Form";
import { RequiredMark } from "components/CommonStyled";
import {
  StyledFormLabel,
  StyledDivWrapper,
  BlackSubtitle,
  Title,
  ActivityItemTitle,
  ActivityItemBody,
  ActivityItem,
  StyledFakeLink,
  StyledSDGTitle,
  StyledLinkSeparator,
} from "./Section11.styled";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const Section11 = () => {
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { formStore, initiativeStore, authStore } = useRootStore();
  const [nonEditable, setNonEditable] = useState(true);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [saved, setSaved] = useState(false);
  const [disabledComments, setDisabledComments] = useState(true);
  const [SDGModal, setSDGModal] = useState({
    isShow: false,
    title: "",
    goalId: "",
    data: null,
  });
  const [closeModalShow, setCloseModalShow] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const currentRole = authStore.roles[0];
  const isPublic = currentRole === roleNamesConst.PUBLIC;
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM;
  const isAtke = currentRole === roleNamesConst.ATKE;
  const isIntpa = currentRole === roleNamesConst.INTPA;
  const isEud = currentRole === roleNamesConst.EUD;
  const [isF7, setIsF7] = useState(false);
  const [window2021, setWindow2021] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f5statusNamesConst.DRAFT ||
        initiativeStore.role !== roleNamesConst.APPLICANT
    );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (formStore.formSectionData && formStore.formSectionData[0]) {
      const formValuesCopy = { ...formStore.formSectionData[0] };
      setFormValues(formValuesCopy);
    }
  }, [formStore.formSectionData]);

  useEffect(() => {
    if (initiativeStore.formStateName)
      setIsF7(
        (isIntpa || isEud) &&
          initiativeStore.formStateName !== f5statusNamesConst.DRAFT &&
          initiativeStore.formStateName !== f5statusNamesConst.SENT
      );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (initiativeStore.code) {
      setWindow2021(isWindows2021(initiativeStore.code))
    }
  }, [initiativeStore.code])

  const mapActivityItems = (array) => {
    return array && Array.isArray(array)
      ? array.map((assessment) => (
          <ActivityItem>
            <ActivityItemTitle>{`${assessment.activityName}: `}</ActivityItemTitle>
            <ActivityItemBody>
              
              {assessment.assessment ? assessment.assessment : " - "}
            </ActivityItemBody>
          </ActivityItem>
        ))
      : " - ";
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await saveFormSection(id, formId, sectionId, values);
      formStore.setSectionStatus(sectionId, response.complete);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmClose = () => {
    setSDGModal({ isShow: false, title: "", goalId: "", data: null });
    setCloseModalShow(false);
  };

  const handleSaved = (value) => {
    setSaved(value);
  };

  const handleButton = (value) => {
    setDisabledComments(value);
  };

  return (
    <>
      <ObjectiveFormModal
        show={SDGModal.isShow}
        handleClose={() => setCloseModalShow(true)}
        handleCloseOk={() =>
          setSDGModal({ isShow: false, title: "", goalId: "", data: null })
        }
        initiativeId={id}
        goalId={SDGModal.goalId}
        title={SDGModal.title}
        data={SDGModal.data}
        nonEditable={nonEditable}
      />

      <FormLayout>
        <CustomModal
          show={successModalShow}
          onHide={() => {
            setSDGModal({ isShow: false, title: "", goalId: "", data: null });
            setSuccessModalShow(false);
          }}
          message={t("saved")}
          type="success"
        />

        <ConfirmCloseModal
          show={closeModalShow}
          handleCloseConfirm={() => setCloseModalShow(false)}
          handleActionOnConfirm={handleConfirmClose}
          text={t("Confirmation close modal")}
        />

        <FormContainer
          number={11}
          title={t("F3_S11_title")}
          formId="form5section11"
          showSavedMessage={saved}
          showSaveButton={!nonEditable}
          showButtonClarif={!disabledComments}
        >
          <StyledDivWrapper>
            <Formik
              enableReinitialize
              initialValues={formValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                dirty,
              }) => (
                <form onSubmit={handleSubmit} id="form5section11">
                  {dirty && setSaved(false)}

                  <Title>
                    <Link
                      to={`${authStore.getBaseRouteByRole()}/initiative/${id}/form/${
                        isPublic ? "3" : "4"
                      }/section/11`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("F5_Subtitle_Description_from_proposal")}
                    </Link>
                  </Title>

                  <Title>
                    {t("F5_Subtitle_Assessment_TCI_Implementation")}
                  </Title>

                  {window2021 ? (
                    <>
                      <BlackSubtitle>
                        {t("F5_S7_Activity_assessment_9")}
                      </BlackSubtitle>
                      {formStore.formSectionData &&
                        formStore.formSectionData[1] &&
                        mapActivityItems(formStore.formSectionData[1])}

                      <Form.Group style={{ marginTop: "25px" }}>
                        <StyledFormLabel>
                          {t("F5_Overview_of_TCI")}
                          <RequiredMark>*</RequiredMark>:
                        </StyledFormLabel>
                        <Form.Control
                          name="overviewSDG"
                          as="textarea"
                          rows={7}
                          style={{ resize: "none" }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.overviewSDG}
                          disabled={nonEditable}
                          placeholder={t("Maximum_1300")}
                          className={
                            touched.overviewSDG && errors.overviewSDG
                              ? "error"
                              : null
                          }
                        />
                        <FormErrorMessage
                          touched={touched.overviewSDG}
                          errors={errors.overviewSDG}
                        />
                      </Form.Group>

                      <Form.Group style={{ marginTop: "25px" }}>
                        <StyledFormLabel>
                          {t("F5_S11_From_contribution_agenda")}
                          <RequiredMark>*</RequiredMark>:
                        </StyledFormLabel>
                        <Form.Control
                          name="contribution2030"
                          as="textarea"
                          rows={7}
                          style={{ resize: "none" }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contribution2030}
                          disabled={nonEditable}
                          placeholder={t("Maximum_1300")}
                          className={
                            touched.contribution2030 && errors.contribution2030
                              ? "error"
                              : null
                          }
                        />
                        <FormErrorMessage
                          touched={touched.contribution2030}
                          errors={errors.contribution2030}
                        />
                      </Form.Group>
                    </>
                  ) : null}

                  <Title> {t("F5_S11_Subtitle_new_SDGs")} </Title>

                  <ActivityItem>
                    <StyledSDGTitle>
                      {t("F3_S11_Subtitle_SDG_primary")}:
                    </StyledSDGTitle>
                    {values.goalPrimaryType && (
                      <StyledFakeLink
                        type="button"
                        onClick={() => {
                          setSDGModal({
                            isShow: true,
                            title: t("F3_S11_Subtitle_SDG_primary"),
                            goalId: 0,
                            data: {
                              goal: values.goalPrimaryType,
                              justification: values.primaryJustification,
                              targetList: values.primaryTargetList,
                            },
                          });
                        }}
                      >
                        {t(`SDG_Goal${values.goalPrimaryType.id}`)}
                      </StyledFakeLink>
                    )}
                  </ActivityItem>

                  <StyledLinkSeparator />

                  <ActivityItem>
                    <StyledSDGTitle>
                      {t("F3_S11_Subtitle_SDG_secondary")}:
                    </StyledSDGTitle>
                    {values.goalSecondaryType && (
                      <StyledFakeLink
                        type="button"
                        onClick={() => {
                          setSDGModal({
                            isShow: true,
                            title: t("F3_S11_Subtitle_SDG_secondary"),
                            goalId: 1,
                            data: {
                              goal: values.goalSecondaryType,
                              justification: values.secondaryJustification,
                              targetList: values.secondaryTargetList,
                            },
                          });
                        }}
                      >
                        {t(`SDG_Goal${values.goalSecondaryType.id}`)}
                      </StyledFakeLink>
                    )}
                  </ActivityItem>

                  <StyledLinkSeparator />

                  <ActivityItem>
                    <StyledSDGTitle>
                      
                      {t("F3_S11_Subtitle_SDG_10")}:
                    </StyledSDGTitle>
                    {values.goal10Type && (
                      <StyledFakeLink
                        type="button"
                        onClick={() => {
                          setSDGModal({
                            isShow: true,
                            title: t("F3_S11_Subtitle_SDG_10"),
                            goalId: 2,
                            data: {
                              goal: values.goal10Type,
                              justification: values.goal10Justification,
                              targetList: values.goal10TargetList,
                            },
                          });
                        }}
                      >
                        {t(`SDG_Goal${values.goal10Type.id}`)}
                      </StyledFakeLink>
                    )}
                  </ActivityItem>

                  <StyledLinkSeparator />

                  <ActivityItem>
                    <StyledSDGTitle>
                      {t("F3_S11_Subtitle_SDG_17")}:
                    </StyledSDGTitle>
                    {values.goal17Type && (
                      <StyledFakeLink
                        type="button"
                        onClick={() => {
                          setSDGModal({
                            isShow: true,
                            title: t("F3_S11_Subtitle_SDG_17"),
                            goalId: 3,
                            data: {
                              goal: values.goal17Type,
                              justification: values.goal17Justification,
                              targetList: values.goal17TargetList,
                            },
                          });
                        }}
                      >
                        {t(`SDG_Goal${values.goal17Type.id}`)}
                      </StyledFakeLink>
                    )}
                  </ActivityItem>

                  <Form.Group style={{ marginTop: "25px" }}>
                    <StyledFormLabel>
                      {t("F5_S11_Justification_deviation_SDG")}:
                    </StyledFormLabel>
                    <Form.Control
                      name="justificationDeviationSDG"
                      as="textarea"
                      rows={7}
                      style={{ resize: "none" }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.justificationDeviationSDG}
                      disabled={nonEditable}
                      placeholder={t("Maximum_1300")}
                      className={
                        touched.justificationDeviationSDG &&
                        errors.justificationDeviationSDG
                          ? "error"
                          : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.justificationDeviationSDG}
                      errors={errors.justificationDeviationSDG}
                    />
                  </Form.Group>
                </form>
              )}
            </Formik>

            {(isAtke || isConsortium || isF7) && (
              <Comments onSaved={handleSaved} onButton={handleButton} />
            )}
          </StyledDivWrapper>
        </FormContainer>
      </FormLayout>
    </>
  );
};

export default observer(Section11);
