import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import Table2 from '../components/Table2'
import { getPreFormulationSummary } from 'services/initiative'
import { useTranslation } from 'react-i18next'

import Row from 'react-bootstrap/Row'
import {
  StyledDiv,
  StyledRow,
  StyledRow2,
} from '../../CommonStyled/Tabs.styled'

import ExportToXLSXButton from 'components/ExportToXLSXButton'
import { ExportStatisticsByYearsToXLSX } from 'helpers/xlsxStatisticsExporter'
import { getPreformulationExportValues } from '../helpers/helpers'
import { table1TransformData } from '../helpers/helpers'

import { TARGET_YEARS } from 'constants/appConstants'

import htmlParser from 'html-react-parser'

const Preformulationsummary = () => {
  const { t } = useTranslation()
  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const getData = async (param) => {
    setWaiting(true)
    try {
      const response = await getPreFormulationSummary({ countryId: param })
      setData(table1TransformData(response, TARGET_YEARS))
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  useEffect(() => {
    getData(null)
  }, [])

  return (
    <StyledDiv>
      <Row>
        <Header
          getData={getData}
          waiting={waiting}
          renderExportButton={() => (
            <ExportToXLSXButton
              data={data}
              mapData={getPreformulationExportValues}
              disabled={waiting}
              exportName={t('Statistics_formulation_tab1')}
            >
              <ExportStatisticsByYearsToXLSX
                label={t('menu_admin_2')}
                data={[]}
              />
            </ExportToXLSXButton>
          )}
        />
      </Row>
      <StyledRow2 >{htmlParser(t('Statistics_footnote_14'))}</StyledRow2>
      <StyledRow>
        <Table2 data={data} waiting={waiting} title={t('table_title_preformulation')} />
      </StyledRow>
      <StyledRow2>{t('Statistics_footnote_1')}</StyledRow2>
    </StyledDiv>
  )
}

export default Preformulationsummary
