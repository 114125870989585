import styled, { css } from 'styled-components';
import Button from 'react-bootstrap/Button';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 130px;
    padding: 20px;
    background-color: ${theme.colors.white};
  `}
`;

export const LeftContent = styled.div`
  flex: 2;
`;

export const CenterContent = styled.div`
  flex: 1;
`;

export const RightContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const ObjectFitImage = styled.img`
  object-fit: contain;
`;

export const AdelanteLogo = styled(ObjectFitImage)`
  margin-right: 10px;
`;

export const StyledNoPaddingLogo = styled.img`
  object-fit: contain;
  margin-top:-20px;
`;

export const LogoutLabel = styled.span`
  ${({ theme }) => css`
    cursor: pointer;
    font-weight: bold;
    color: ${theme.brandColors.blue}
  `}
`

export const StyledLenguajeSelector = styled.div`
  margin-top: -15px;
  margin-bottom: 2px;
`;

export const StyledButtonLink = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  box-shadow: none !important;
  margin-bottom: 4px;
`;

export const StyledEnvSign = styled.div`
  color: #00CC00;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: 10px;
`;