import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Proptypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { ModalFooter, FooterLeft, FooterCenter, FooterRight, SyledTitle } from './InviteDialog.styled';
import { PrimaryButton } from 'components/Buttons';
import Form from 'react-bootstrap/Form';
import { getEudList, addEudToForm9 } from 'services/initiative';
import CustomModal from 'components/Modal';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const InviteDialog = ({ displayDialog, onCloseModal }) => {
  const { t } = useTranslation();
  const [eudList, setEudList] = useState([]);
  const [selected, setSelected] = useState();
  const [sending, setSending] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false
  });

  const params = new URLSearchParams(window.location.search);
  let initiative = (params.get('initiative')?.split('ICT')[1])?.split('-')[0];

  useEffect(async () => {
    try {
      const response = await getEudList("F5", initiative, false);
      setEudList(response?.data);
      if (response?.data?.length > 0) {
        setSelected(response?.data[0].email)
      }
    } catch (error) {
      console.log(error);
    }
  }, [])


  const inviteAction = async () => {
    setSending(true);
    try {
      if (selected !== null) {
        await addEudToForm9(selected, initiative);
        setSending(false);
        setModal({
          isOpen: true,
          type: "success",
          message: t('Added_eud')
        })
      } else {
        setSending(false);
        setModal({
          isOpen: true,
          type: "error",
          message: t('Please_select_eud')
        })
      }
    } catch (error) {
      console.log(error);
      setSending(false);
      setModal({
        isOpen: true,
        type: "error",
        message: t('Could_not_add_eud')
      })
    }
  }

  const onHideModal = () => {
    setModal({isOpen: false});
};

  return (
    <Modal show={displayDialog} onHide={onCloseModal} size="md" animation={false}>
      <Modal.Body>
          <CustomModal
            show={modal.isOpen}
            onHide={onHideModal}
            message={modal.message}
            type={modal.type}
          />
        <SyledTitle> {t('F2_invite_modal_title')} </SyledTitle>
        <Form.Control as="select" value={selected} onChange={e => { setSelected(e.target.value) }}>
          {eudList.map(e => (
            <option value={e.email} key={e.email}>{`${e.organization? e.organization: ''} - ${e.name? e.name: ''} ${e.lastName? e.lastName: ''}`}</option>
          ))}
        </Form.Control>
      </Modal.Body>
      <ModalFooter>
        <FooterLeft />
        <FooterCenter>
          <PrimaryButton disabled={sending} onClick={() => inviteAction()}>{t('Button_Invite')}</PrimaryButton>
        </FooterCenter>
        <FooterRight />
      </ModalFooter>
    </Modal>
  )
}

InviteDialog.propTypes = {
  displayDialog: Proptypes.bool,
  onCloseModal: Proptypes.func,
};

export default observer(InviteDialog);
