import styled, { css } from 'styled-components';

export const SeparatorDotted = styled.div`
  ${({ theme }) => css`
    height: 2px;
    border-bottom: 2px dotted ${theme.colors.gray[3]};
    margin: 30px 0px;
  `}
`

export const StyledSubtitle1 = styled.b`
  ${({ theme }) => css`
    text-align: justify;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    color: ${theme.brandColors.blue}
  `}
`;

export const RoundedRemoveDiv = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.brandColors.red};
    color: white;
    width: 15px;
    height: 15px;
    line-height: 0.8;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
  `}
`;

export const RequiredMark = styled.span`
  ${({ theme }) => css`
    color: ${theme.brandColors.red};
    margin-left: 5px;
  `}
`;

export const FakeLink = styled.span`
  color: #007bff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const FormFielErrorMessage = styled.p`
  ${({ theme }) => css`
    color: ${theme.brandColors.red};
    margin: 0px;
    margin-top: 2px;
    font-size: 0.8em;
  `}
`;

export const CenteredModalButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
`;

export const ActivityItem = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ActivityItemTitle = styled.span`
  white-space: nowrap;
  margin-right: 10px;
  margin-bottom: 15px;
  font-weight: 650;
`;

export const ActivityItemBody = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    white-space: 'pre-wrap';
    margin-bottom: 15px;
  `}
`;

export const MessagesSectionStyles = {
  StyledSectionWrapper: styled.div`
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 50px;
    width: 100%;
    height: 100%;
  `,

  StyledUserComment: styled.div`
    ${({ theme }) => css`
      color: ${theme.brandColors.green};
      padding-right: 10px;
      font-size: 1.05rem;
    `}
  `,

  StyledDate: styled.div`
    font-size: 14px;
  `,

  StyledUserResponse: styled.div`
    ${({ theme }) => css`
      color: ${theme.brandColors.blue};
      padding-right: 10px;
      font-size: 1.05rem;
    `}
  `,

  StyledResponse: styled.div`
    min-height: 80px;
    height: auto;
    background: #f4f4f4;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    font-weight: 500;
    margin-left: 40px;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: justify;
  `,

  StyledComment: styled.div`
    width: 100%;
    background: #f4f4f4;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    font-weight: 500;
    text-align: justify;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
  `,

  StyledButton: styled.div`
    display: flex;
    justify-content: flex-end;
    min-height: 45px;
    align-items: center;
  `,

  StyledTitle: styled.div`
    ${({ theme }) => css`
      color: ${theme.brandColors.blue};
      margin-bottom: 30px;
      font-size: 1.7rem;
      font-weight: 500;
    `}
  `,
};
