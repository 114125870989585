import styled, {css} from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledFormLabel = styled(Form.Label)`
    text-align: justify;
    line-height: 1.3;
`;

export const StyledDivWrapper = styled.div`
  margin-left: 50px;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    margin-top: 35px;
    margin-bottom: 16px;
    text.align: justify;
    color: ${theme.brandColors.blue};
    font-weight: 650;
  `}
`;