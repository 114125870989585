import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFormSectionComponent } from 'lib/FormsSectionsDictionary';
import { useRootStore } from 'stores';

const Initiative = () => {
  const { id, formId, sectionId } = useParams();
  const { formStore } = useRootStore();
  const formSection = getFormSectionComponent(formId, sectionId);

  useEffect(() => {
    if(formId === "2"){
      formStore.loadForm2Data(id, sectionId);
    }
    else if(formId === "4"){
      formStore.loadForm4Data(id, sectionId);
    }
    else if(formId === "5"){
      formStore.loadForm5Data(id, sectionId);
    }
    else if(formId === "55"){
      formStore.loadForm55Data(id, sectionId);
    }
  },[formStore, id, formId, sectionId])

  return formSection ? formSection : null;

}

export default Initiative;
