import styled, { css } from 'styled-components';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

export const StyledContainer = styled(Row)`
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding-top: 50px;
`;

export const StyledTitle = styled.span`
  ${({ theme }) => css`
    font-size: 35px;
    font-weight: 700;
    color: ${theme.brandColors.blue};
  `}
`;

export const StyledSubtitle = styled(Link)`
  ${({ theme }) => css`
    all: revert;
    font-size: 25px;
    font-weight: 600;
    color: ${theme.brandColors.green};
    text-decoration: none;
  `}
`;