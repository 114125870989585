import React, { useEffect, useState, useRef } from 'react';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { formInitialValues } from './ProductsForm.initialValues';
import { validationSchema } from './ProductsForm.validations';
import { saveProductsF5S7, getProductsF5S7, saveProductsMgmF5S7 } from 'services/initiative';
import DayJS from 'dayjs';
import { useRootStore } from 'stores';
import { roleNamesConst } from 'constants/appConstants';
import { getUploadFormatsConstants } from "services/apiConstants";

import { PrimaryButton } from 'components/Buttons';
import {StyledCheck} from './ProductsForm.styled'
import ProductForm from './ProductForm';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import "react-datepicker/dist/react-datepicker.css";
import {ErrorButton, StyledSendButton} from 'components/Buttons'
import { Form } from 'react-bootstrap'
import { SeparatorDotted, CenteredModalButtonContainer } from 'components/CommonStyled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const ProductsForm = ({handleCloseOk, handleClose, setModalInfo, activity, handleCloseSubModalOnSave, handleCloseSubModalOnError,handleOpenNewProductModal, newProductObj, nonEditable, nonEditableMgm }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const formRef = useRef();
  const { authStore } = useRootStore();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [validFileFormats, setValidFileFormats] = useState([]);
  const [disabled, setDisabled] = useState(nonEditable);
  const role = authStore.roles[0];
  const isAtke = role === roleNamesConst.ATKE;
  const isPublic = role === roleNamesConst.PUBLIC;

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr);
    return date.format("DD/MM/YYYY");
  }

  const handleNewProduct = () => {
    handleOpenNewProductModal(true);
  }

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getUploadFormatsConstants();
        if(response && response.F3S7){
          setValidFileFormats(response.F3S7);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAPI();
   }, [])

  useEffect(() => {
    async function getValues() {
      let response = await getProductsF5S7(id, activity.id);

      response.data.productList.map( (product) => {
          product.dueDate = new Date(product.dueDate);
      })

      const formValues = {...formInitialValues, ...response.data};
      setInitialValues(formValues);

      if(!isPublic)
        setDisabled(true);
      else
        setDisabled(formValues.productsConfirmed);

      if(activity.activityState.name === 'CANCELLED'){
          setDisabled(true);
      }
    }
    getValues();
  }, []);

  useEffect(() => {
    if(newProductObj && Array.isArray(newProductObj.productList)){
      setInitialValues({
        ...formRef.current.values,
        productList: [...newProductObj.productList]
      })
    }
  }, [newProductObj])

  const handleSubmit = async (values) => {
    try {
      if(isAtke)
        await saveProductsMgmF5S7(id, activity.id, values);
      else
        await saveProductsF5S7(id, activity.id, values);
      handleCloseSubModalOnSave();
    } catch (error) {
      console.log(error)
      handleCloseSubModalOnError();
    }
  };

  const onValidateSubmit = (e, isValid, values) => {
    e.preventDefault();
    isValid
      ? handleSubmit(values)
      : alert(t('not_saved_alert'));
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        innerRef={formRef}
        validate={(value) => {
          try {
            validateYupSchema(value, validationSchema, true, value);
          } catch (err) {
            return yupToFormErrors(err);
          }
            return {};
        }}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form
            onSubmit={(e) => { onValidateSubmit(e, props.isValid, props.values) }}
            id="products"
          >
            <Accordion style={{marginLeft: '-20px'}}>
              {props.values.productList
                ? ( props.values.productList.map((product, key) => (
                    <AccordionItem key={key+1}>
                      <AccordionItemHeader>
                        <AccordionTitle eventKey={key+1}>{t('F3_S7_Accordion_product')+" "+(key+1)}</AccordionTitle>
                      </AccordionItemHeader>
                      <Accordion.Collapse eventKey={key+1}>
                        <AccordionItemBody>
                          <ProductForm formikInstance={props} productIndex={key} setModalInfo={setModalInfo} validFileFormats={validFileFormats} nonEditable={disabled} />
                        </AccordionItemBody>
                      </Accordion.Collapse>
                    </AccordionItem>
                  )))
                : null}
            </Accordion>
            <FormErrorMessage
              touched
              errors={props.errors.productList}
            />

            <SeparatorDotted />
            {isPublic &&
            <PrimaryButton disabled={disabled} onClick={() => handleNewProduct()} >{t('F3_S7_Product_button_add')}</PrimaryButton>}

            <StyledCheck style={{marginTop: '20px'}}>
              <Form.Group>
                <Form.Check
                  disabled={(disabled && !(isAtke)) || (isAtke && nonEditableMgm) || (activity.activityState.name === 'CANCELLED')}
                  style={{marginLeft: '15px', textAlign: 'justify'}}
                  name="productsConfirmed"
                  type="checkbox"
                  checked={props.values.productsConfirmed}
                  value={props.values.productsConfirmed}
                  onChange={props.handleChange}
                  label={t('F5_S7_Products_confirmation_check')}
                  className={props.touched.productsConfirmed && props.errors.productsConfirmed ? "error" : null}
                />
                <FormErrorMessage
                  touched={props.touched.productsConfirmed}
                  errors={props.errors.productsConfirmed}
                />
              </Form.Group>
            </StyledCheck>

            {
              props.values.genInfoTimestamp
              &&
              <div style={{marginTop: '10px'}}>
                {`${t('F5_S7_Confirmed')}: ${props.values.genInfoTimestamp? formatDate(props.values.genInfoTimestamp): ""}`}
              </div>
            }

            <CenteredModalButtonContainer>
              {
                ((disabled && !(isAtke)) || (isAtke && nonEditableMgm) || (activity.activityState.name === 'CANCELLED'))
                ?
                  <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
                :
                  <>
                    <ErrorButton style={{marginRight: '20px'}} onClick={handleClose}> {t('Button_cancel')} </ErrorButton>
                    <StyledSendButton type="submit" form="products"> {t('Button_save')} </StyledSendButton>
                  </>
              }
            </CenteredModalButtonContainer>

          </Form>
        )}
      </Formik>
    </div>
  )
}


export default observer(ProductsForm);