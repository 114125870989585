import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { formInitialValues } from './NewProviderForm.initialValues';
import { validationSchema } from './NewProviderForm.validations';
import { addF5S7provider, modifyF5S7provider } from 'services/initiative';
import { useRootStore } from 'stores';
import { roleNamesConst } from 'constants/appConstants';
import { useConstantsForm5 } from 'customHooks/useConstantsForm5';

import { StyledFormLabel } from './NewProviderForm.styled'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ErrorButton, PrimaryButton, StyledSendButton} from 'components/Buttons'
import { Form } from 'react-bootstrap'
import { FormFielErrorMessage, RequiredMark, CenteredModalButtonContainer } from 'components/CommonStyled';

const NewProviderForm = ({ detail, handleCloseOk, handleCloseSubModalOnError, nonEditable, setBudgetDetailAdminModalData }) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [isEditing, setIsEditing] = useState(false);
  const role = authStore.roles[0];
  const isAtke = role === roleNamesConst.ATKE;
  const isConsortium = role === roleNamesConst.CONSORTIUM;
  const { countries } = useConstantsForm5();

  const disableButtons = !(isAtke || isConsortium);

  useEffect(() => {
    if(detail.providerObj){
      setIsEditing(true);
      setInitialValues({
        ...detail.providerObj,
        countryId: detail.providerObj.country? detail.providerObj.country.id: ''
      });
    }
  }, [detail])

  const handleReturnToProvider = () => {
    setBudgetDetailAdminModalData({
      isShow: true,
      type: 0,
      title: t('F5_S7_Budget_Admins_Title1'),
      detail: {
        ...detail,
        providerObj: null
      }
    })
  }

  const handleSubmit = async (values) => {
    try {
      if(isEditing){
        await modifyF5S7provider(values.id, values);
      }
      else{
        await addF5S7provider(values);
      }
      setBudgetDetailAdminModalData({
        isShow: true,
        type: 0,
        title: t('F5_S7_Budget_Admins_Title1'),
        detail: detail
      })
    } catch (error) {
      handleCloseSubModalOnError();
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
         }) => (
          <Form onSubmit={handleSubmit} id="newProvider" >
            
            <Form.Group as={Row}>
              <StyledFormLabel column xs={4}>{t('F1_S2_Budget_Provider_Name')}<RequiredMark>*</RequiredMark>:</StyledFormLabel>
              <Col xs={6}>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  className={touched.name && errors.name ? "error" : null}
                  disabled={nonEditable}
                />
                {touched.name && errors.name ? (
                  <FormFielErrorMessage>{errors.name}</FormFielErrorMessage>
                ): null}
              </Col>
            </Form.Group>  

            <Form.Group as={Row}>
              <StyledFormLabel column xs={4}>{t('F1_S2_Budget_Provider_DTGCode')}:</StyledFormLabel>
              <Col xs={6}>
                <Form.Control
                  type="text"
                  name="dtGlobalCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dtGlobalCode}
                  className={touched.dtGlobalCode && errors.dtGlobalCode ? "error" : null}
                  disabled={nonEditable}
                />
                {touched.dtGlobalCode && errors.dtGlobalCode ? (
                  <FormFielErrorMessage>{errors.dtGlobalCode}</FormFielErrorMessage>
                ): null}
              </Col>
            </Form.Group> 

            <Form.Group as={Row} style={{alignItems: "center"}} >
              <StyledFormLabel column lg={4}>{t('F1_S2_Budget_Provider_Address')}:</StyledFormLabel>
              <Col lg={8}>
                <Form.Control
                  type="text"
                  name="address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  className={touched.address && errors.address ? "error" : null}
                  disabled={nonEditable}
                />
                {touched.address && errors.address ? (
                  <FormFielErrorMessage>{errors.address}</FormFielErrorMessage>
                ): null}
              </Col>
            </Form.Group>  

            <Form.Group as={Row}>
              <StyledFormLabel column lg={4}>{t('F1_S2_Country')}:</StyledFormLabel>
              <Col lg={4}>
                <Form.Control 
                  name="countryId"
                  as="select"
                  onChange={handleChange}
                  value={values.countryId}
                  disabled={nonEditable}
                >
                  <option value="">{t('Placeholder_select')}</option>
                  {countries.map(country => (
                      <option key={country.id} value={country.id}>{t(country.name)}</option>
                  ))}
                </Form.Control>
                {touched.countryId && errors.countryId ? (
                  <FormFielErrorMessage>{errors.countryId}</FormFielErrorMessage>
                ): null}
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <StyledFormLabel column lg={4}>{t('F1_S2_State_Province_Region')}:</StyledFormLabel>
              <Col lg={8}>
                <Form.Control
                  type="text"
                  name="state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.state}
                  className={touched.state && errors.state ? "error" : null}
                  disabled={nonEditable}
                />
                {touched.state && errors.state ? (
                  <FormFielErrorMessage>{errors.state}</FormFielErrorMessage>
                ): null}
              </Col>
            </Form.Group>  

            <Form.Group as={Row}>
              <StyledFormLabel column lg={4}>{t('F1_S2_City')}:</StyledFormLabel>
              <Col lg={8}>
                <Form.Control
                  type="text"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  className={touched.city && errors.city ? "error" : null}
                  disabled={nonEditable}
                />
                {touched.city && errors.city ? (
                  <FormFielErrorMessage>{errors.city}</FormFielErrorMessage>
                ): null}
              </Col>
            </Form.Group>  

            <Form.Group as={Row}>
              <StyledFormLabel column xs={4}>{t('F1_S2_Budget_Provider_Contact')}:</StyledFormLabel>
              <Col xs={6}>
                <Form.Control
                  type="text"
                  name="contact"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contact}
                  className={touched.contact && errors.contact ? "error" : null}
                  disabled={nonEditable}
                />
                {touched.contact && errors.contact ? (
                  <FormFielErrorMessage>{errors.contact}</FormFielErrorMessage>
                ): null}
              </Col>
            </Form.Group>  

            <Form.Group as={Row}>
              <StyledFormLabel column lg={4}>{t('F1_S2_Phone_number')}:</StyledFormLabel>
              <Col lg={8}>
                <Form.Control
                  type="text"
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  placeholder={t('Example')+" +12133734253"} 
                  className={touched.phone && errors.phone ? "error" : null}
                  disabled={nonEditable}
                />
                {touched.phone && errors.phone ? (
                  <FormFielErrorMessage>{errors.phone}</FormFielErrorMessage>
                ): null}
              </Col>
            </Form.Group>

            <Form.Group as={Row} style={{alignItems: "center"}}>
              <StyledFormLabel column lg={4}>{t('F1_S2_Email')}:</StyledFormLabel>
              <Col lg={8}>
                <Form.Control
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className={touched.email && errors.email ? "error" : null}
                  disabled={nonEditable}
                />
                {!values.ownerIsSigner && touched.email && errors.email ? (
                  <FormFielErrorMessage>{errors.email}</FormFielErrorMessage>
                ): null}
              </Col>
            </Form.Group>

            <Form.Group>
              <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}} >{t('F1_S2_Budget_Provider_Notes')}: </Form.Label>
              <Form.Control
                name="comments"
                as="textarea" 
                rows={3} 
                placeholder={t('Maximum_1300')}
                style={{resize: 'none'}}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.comments}
                className={errors.comments ? "error" : null}
                disabled={nonEditable}
              />
              {errors.comments ? (
                <FormFielErrorMessage>{errors.comments}</FormFielErrorMessage>
              ): null}
            </Form.Group>

            <CenteredModalButtonContainer>
              {
                disableButtons
                ?
                  <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
                :
                  <>
                    <ErrorButton style={{marginRight: '20px'}} onClick={handleReturnToProvider} disabled={disableButtons}> {t('Button_cancel')} </ErrorButton>
                    <StyledSendButton type="submit" form="newProvider" disabled={disableButtons}> {t('Button_save')} </StyledSendButton>
                  </>
              }
            </CenteredModalButtonContainer>

          </Form>
        )}
      </Formik>
    </div>
  )
}

export default observer(NewProviderForm);