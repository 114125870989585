import React, { useState, useEffect } from 'react'
import { getPreFormulationInitiatives } from 'services/initiative'
import { useTranslation } from 'react-i18next'
import { f1statusNamesConst, f2statusNamesConst } from 'constants/appConstants'
import { renderYears } from 'helpers/dateHelpers'

import DeadlineChangeModal from 'components/DeadlineChangeModal'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageTitle from 'components/PageTitle'
import { PrimaryButton } from 'components/Buttons'
import { PageHeader, PageContent } from './ConceptNotes.styled'
import TableRow from './TableRow'

const ConceptNotes = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [initiatives, setInitiatives] = useState([])
  const [filteredInitiatives, setFilteredInitiatives] = useState([])
  const [deadlineModalData, setdeadlineModalData] = useState({
    show: false,
    initiativeId: '',
    deadlineDateString: '',
  })
  const [showRejAndExp, setshowRejAndExp] = useState(false)

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  const [filterValue, setFilterValue] = useState('ALL')

  const [changeSelectedYear, setChangeSelectedYear] = useState(false)

  useEffect(() => {
    getData(true)
  }, [])

  useEffect(() => {
    if (changeSelectedYear) {
      handleFilter1(initiatives, new Date().getFullYear() - 1)
    }
  }, [changeSelectedYear])

  const getData = async (flag) => {
    setLoading(true)
    try {
      const initiatives = await getPreFormulationInitiatives()
      setInitiatives(initiatives)
      handleFilter2(initiatives, flag)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleFilter1 = (initiatives, year) => {
    setSelectedYear(year)
    const initiativesCopy = initiatives.filter(
      (e) =>
        e.formStateName !== f1statusNamesConst.REJECTED &&
        e.formStateName !== f1statusNamesConst.EXPIRED &&
        e.code?.includes(`-${year.toString().substring(2, 4)}`),
    )
    setFilteredInitiatives(initiativesCopy)
  }

  const handleFilter2 = (initiatives, flag = false) => {
    let aux = initiatives
    try {
      if (filterValue !== 'ALL') {
        const filterStatus = filterValue.split(' ')[0]
        const filterV2 = filterValue.includes('2')
        aux = aux.filter(
          (e) =>
            e.assessmentStatus === filterStatus &&
            (!filterV2 || e.formVersion === 2),
        )
      }
      if (!showRejAndExp) {
        aux = aux.filter(
          (e) =>
            e.formStateName !== f1statusNamesConst.REJECTED &&
            e.formStateName !== f1statusNamesConst.EXPIRED,
        )
      }
      if (selectedYear) {
        aux = aux.filter((e) =>
          e.code?.includes(`-${selectedYear.toString().substring(2, 4)}`),
        )
      }
      setFilteredInitiatives(aux)

      if (flag) {
        setChangeSelectedYear(aux.length === 0)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleRefreshList = () => {
    getData(false)
  }

  const handleChange = (e) => {
    setFilterValue(e.target.value)
  }

  const handleChangeShowRejAndExp = (e) => {
    setshowRejAndExp(e.target.checked)
  }

  const handleFilterYearChange = (e) => {
    setSelectedYear(e.target.value)
  }

  return (
    <>
      <DeadlineChangeModal
        show={deadlineModalData.show}
        onClose={() =>
          setdeadlineModalData({ ...deadlineModalData, show: false })
        }
        initiativeId={deadlineModalData.initiativeId}
        formId={1}
        deadlineDateString={deadlineModalData.deadlineDateString}
        handleRefreshList={handleRefreshList}
      />

      <PageHeader>
        <PageTitle title={t('menu_admin_2')} />
        <Row style={{ alignItems: 'center' }}>
          {t('Dashboard_Year')}
          <Col>
            <Form.Control
              as="select"
              onChange={handleFilterYearChange}
              value={selectedYear}
            >
              {renderYears()}
            </Form.Control>
          </Col>
          <Col xs={3}>
            <Form.Check
              name="showRejAndExp"
              type="checkbox"
              checked={showRejAndExp}
              onChange={handleChangeShowRejAndExp}
              label={t('Checkbox_Show_Rejected_and_Expired')}
            />
          </Col>
          {t('Assessment_status')}
          <Col>
            <Form.Control
              as="select"
              onChange={handleChange}
              value={filterValue}
            >
              <option value="ALL"> All </option>
              <option value={f2statusNamesConst.PENDING}> Pending </option>
              <option value={f2statusNamesConst.ONGOING}> Ongoing </option>
              <option value={f2statusNamesConst.DONE}> Done </option>
              <option value={f2statusNamesConst.PENDINGV2}> Pending v2 </option>
              <option value={f2statusNamesConst.ONGOINGV2}> Ongoing v2 </option>
            </Form.Control>
          </Col>
          <PrimaryButton onClick={() => handleFilter2(initiatives)}>
            {t('Button_filter')}
          </PrimaryButton>
        </Row>
      </PageHeader>
      <PageContent>
        {loading ? (
          <p>{t('Loading')}</p>
        ) : (
          <Table>
            <thead>
              <tr>
                <th>{t('Grid_title_code')}</th>
                <th>{t('Grid_title')}</th>
                <th>{t('Grid_status_F1')}</th>
                <th>{t('Grid_deadline_F1')}</th>
                <th>{t('Grid_My_assessment')}</th>
                <th>{t('Grid_deadline')}</th>
                <th>{t('Grid_messages')}</th>
              </tr>
            </thead>
            <tbody>
              {!filteredInitiatives.length ? (
                <tr>
                  <td colSpan={4} style={{ textAlign: 'center' }}>
                    {t('No_data_available')}
                  </td>
                </tr>
              ) : (
                filteredInitiatives?.map((item) => {
                  if (
                    item.formStateName &&
                    (item.formStateName === f1statusNamesConst.APPROVED ||
                      item.formStateName === f1statusNamesConst.REJECTED)
                  ) {
                    item.formDeadline = null
                  }
                  if (
                    item.assessmentStatus &&
                    item.assessmentStatus !== f2statusNamesConst.ONGOING &&
                    item.assessmentStatus !== f2statusNamesConst.PENDING
                  ) {
                    item.assessmentDeadline = null
                  }
                  return (
                    <TableRow
                      key={item.id}
                      item={item}
                      setdeadlineModalData={setdeadlineModalData}
                    />
                  )
                })
              )}
            </tbody>
          </Table>
        )}
      </PageContent>
    </>
  )
}

export default ConceptNotes
