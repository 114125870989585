import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useRootStore } from 'stores';
import Proptypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { ModalFooter } from './CreateFundDialog.styled';
import { ErrorButton, PrimaryButton } from 'components/Buttons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addFund } from 'services/funds';

const CreateFundDialog = ({ isVisible, onCloseModal }) => {
  const { t } = useTranslation();

  const [sending, setSending] = useState(false);

  const { initiativeStore, authStore } = useRootStore();
  const history = useHistory();

  const baseRoute = authStore.getBaseRouteByRole();


  const createFund = async () => {
    setSending(true);
    try {
      const response = await addFund();
      initiativeStore.setInitiativeData({
        id: response.id,
        code: response.code,
      });
      history.push(`${baseRoute}/initiative/${response.id}/form/55/section/1`)
    } catch (error) {
      setSending(false);
      console.log(error);
    }
  }

  return (
    <Modal show={isVisible} onHide={onCloseModal} size="lg" animation={false}>
      <Modal.Header>
        <h5>{t('create_fund_title')}</h5>
      </Modal.Header>
      <Modal.Body>
       {t('create_fund_subtitle')}
      </Modal.Body>
      <ModalFooter>
        <ErrorButton variant="secondary" disabled={sending} onClick={onCloseModal}>
          {t('No')}
        </ErrorButton>
        <PrimaryButton disabled={sending} onClick={createFund}>
          {t('Yes')}
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  )
}

CreateFundDialog.propTypes = {
  title: Proptypes.string,
  displayDialog: Proptypes.bool,
  onCloseModal: Proptypes.func,
};

export default observer(CreateFundDialog);
