import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { validationSchema } from './Section5.validations'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { saveFormSection } from 'services/initiative'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import htmlParser from 'html-react-parser'
import { RequiredMark } from 'components/CommonStyled'
import { f3statusNamesConst, roleNamesConst } from 'constants/appConstants'
import { useRootStore } from 'stores'
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer'
import { formInitialValues } from './Section5.initialValues'
import {
  StyledTextValue,
  DescripcionDiv,
  Text,
  Separator,
  StyledSectionWrapper,
  StyledFormLabel,
} from './Section5.styled'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const Section5 = () => {
  const { id, formId, sectionId } = useParams()
  const { formStore, initiativeStore } = useRootStore()
  const [saved, setSaved] = useState(false)
  const [nonEditable, setNonEditable] = useState(false)
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f3statusNamesConst.DRAFT ||
        initiativeStore.role === roleNamesConst.GUEST,
    )
  }, [initiativeStore.formStateName])

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {
        ...formInitialValues,
        ...formStore.formSectionData[0],
      }
      for (const key in formSectionData) {
        if (!formSectionData[key]) {
          formSectionData[key] = ''
        }
      }
      setInitialValues(formSectionData)
    }
  }, [formStore.formSectionData])

  useEffect(() => {
    if (saved) window.scrollTo(0, 0)
  }, [saved])

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values)
      formStore.setSectionStatus(sectionId, complete)
      resetForm({ values })
      setSaved(true)
    } catch (error) {
      console.log(error)
    }
  }

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault()
    isValid ? handleSubmit(values, { resetForm }) : alert(t('not_saved_alert'))
  }

  return (
    <FormLayout>
      <FormContainer
        number={5}
        title={t('F1_S5_title')}
        formId="form3section5"
        isHelp={true}
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            handleChange,
            handleBlur,
            dirty,
            resetForm,
          }) => (
            <form
              onSubmit={(e) => {
                onValidateSubmit(e, isValid, values, { resetForm })
              }}
              id="form3section5"
            >
              {dirty && setSaved(false)}
              <StyledSectionWrapper>
                <DescripcionDiv>
                  <Form.Group as={Col}>
                    <StyledFormLabel>
                      {htmlParser(t('F3_S5_Description_from_CN'))}:
                    </StyledFormLabel>
                    <StyledTextValue> {values.overview} </StyledTextValue>
                  </Form.Group>
                </DescripcionDiv>

                <Text>{htmlParser(t('F3_S5_Subtitle'))}</Text>

                <Separator />

                <Form.Group>
                  <StyledFormLabel>
                    {t('F3_S5_Question_1')}: <RequiredMark>*</RequiredMark>{' '}
                  </StyledFormLabel>
                  <Form.Control
                    name="relations1"
                    as="textarea"
                    rows={3}
                    style={{ resize: 'none' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.relations1}
                    disabled={nonEditable}
                    className={
                      touched.relations1 && errors.relations1 ? 'error' : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.relations1}
                    errors={errors.relations1}
                  />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel>{t('F3_S5_Question_2')}:</StyledFormLabel>

                  <Form.Control
                    name="relations2"
                    as="textarea"
                    rows={3}
                    style={{ resize: 'none' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.relations2}
                    disabled={nonEditable}
                    className={
                      touched.relations2 && errors.relations2 ? 'error' : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.relations2}
                    errors={errors.relations2}
                  />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel>{t('F3_S5_Question_3')}:</StyledFormLabel>

                  <Form.Control
                    name="relations3"
                    as="textarea"
                    rows={3}
                    style={{ resize: 'none' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.relations3}
                    disabled={nonEditable}
                    className={
                      touched.relations3 && errors.relations3 ? 'error' : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.relations3}
                    errors={errors.relations3}
                  />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel>{t('F3_S5_Question_4')}:</StyledFormLabel>

                  <Form.Control
                    name="relations4"
                    as="textarea"
                    rows={3}
                    style={{ resize: 'none' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.relations4}
                    disabled={nonEditable}
                    className={
                      touched.relations4 && errors.relations4 ? 'error' : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.relations4}
                    errors={errors.relations4}
                  />
                </Form.Group>
              </StyledSectionWrapper>
            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section5)
