export const initialValues = {
  section1Data: null,
  section2Data: null,
  section3Data: null,
  section4Data: null,
  section5Data: null,
  section6Data: null,
  section7Data: null,
  section7SubsectionsData: [],
  section8Data: null,
  section9Data: null,
  section10Data: null,
  section11Data: null,
  section12Data: null,
  section13Data: null,
  section14Data: null,
  section15Data: null,
  section16Data: null,
  section17Data: null,
  ready: false,
  isReadyToGenerate: false,
};
