import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {PrimaryButton, ErrorButton} from 'components/Buttons';
import { useTranslation } from 'react-i18next';

const ModalConfirm = ({
  show,
  displayHeader = false,
  headerText = '',
  onConfirm,
  confirmText,
  onClose,
  children,
}) => {
  const {t} = useTranslation();
  return (
    <Modal show={show} animation={false}>
      { displayHeader && <Modal.Header closeButton>
        <Modal.Title>{headerText}</Modal.Title>
      </Modal.Header> }
      <Modal.Body>
        { children }
      </Modal.Body>
      <Modal.Footer>
        <ErrorButton onClick={onClose} variant="secondary">{t('Button_close')}</ErrorButton>
        <PrimaryButton onClick={onConfirm} variant="primary">{confirmText}</PrimaryButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirm;
