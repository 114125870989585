import React from 'react'
import { useTranslation } from 'react-i18next'
import Items from './Items'

import { SeparatorDotted } from 'components/CommonStyled'
import { PrimaryButton } from 'components/Buttons'

const CommunicationToolList = ({ items, handleOpenModal }) => {
  const { t } = useTranslation()

  const renderItemsByType = (items) => {
    let list = []
    let filteredItems = []

    for (let i = 1; i <= 11; i++) {
      filteredItems = items.filter((tool) => tool.mediaType.id == i)
      if (filteredItems.length > 0) {
        list.push(
          <div style={{ marginBottom: '25px' }} key={i}>
            <span> {t(`media_type_${i}`)} </span>
            <Items items={filteredItems} itemType={i} />
          </div>,
        )
      }
    }
    return list
  }

  return (
    <div>
      {renderItemsByType(items)}
      <PrimaryButton onClick={() => handleOpenModal()}>
        {t('F1_S8_Button_add_item')}
      </PrimaryButton>
      <SeparatorDotted />
    </div>
  )
}

export default CommunicationToolList
