import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';
import htmlParser from 'html-react-parser';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  Seciton5Separator,
  Text,
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  StyledFormLabel,
  DescripcionDiv,
} from 'components/Forms/Form4/Section5/Section5.styled';
import Form from 'react-bootstrap/Form';
import TextValue from 'components/TextValue';

const Section5 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`5 - ${t('F1_S5_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <DescripcionDiv>
          <Form.Group>
            <StyledFormLabel>{htmlParser(t('F3_S5_Description_from_CN'))}:</StyledFormLabel>
            <TextValue value={formValues.overview} />
          </Form.Group> 
        </DescripcionDiv>   

        <Text>{htmlParser(t('F3_S5_Subtitle'))}</Text>

        <Seciton5Separator />

        <Form.Group>
          <StyledFormLabel>{t('F3_S5_Question_1')}:</StyledFormLabel>  
          <TextValue value={formValues.relations1} />      
        </Form.Group>  
                        
        <Form.Group>
          <StyledFormLabel>{t('F3_S5_Question_2')}:</StyledFormLabel>
          <TextValue value={formValues.relations2} />
        </Form.Group>  

        <Form.Group>
          <StyledFormLabel>{t('F3_S5_Question_3')}:</StyledFormLabel>
          <TextValue value={formValues.relations3} />
        </Form.Group>  
                        
        <Form.Group >
          <StyledFormLabel>{t('F3_S5_Question_4')}:</StyledFormLabel>
          <TextValue value={formValues.relations4} />
        </Form.Group>  
      </StyledBody>
    </SectionContainer>
  )
}


export default Section5;