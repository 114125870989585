import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

import { recoveryServiceConfirm } from 'services/auth';

import {StyledDivWrapper, StyledWarningP } from 'pages/public/PassRecovery/PassRecovery.styled'

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import PublicHeader from 'components/PublicHeader';
import CustomModal from 'components/Modal';
import PageTitle from 'components/PageTitle';

const PassRecoveryConfirm = () => {

  const {t} = useTranslation();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token');

  const defaultFieldsState = {
    incPass: false
  };

  const [password, setPassword] = useState("");

  const [fieldsState, setFieldsState] = useState(defaultFieldsState);
  const [modal, setModal] = React.useState({
    isOpen: false,
    message: "",
    type: 'default'
  });

  const onHideModal = () => {
    setModal({...modal, isOpen: false});
  };

  async function handleSubmit(e){
    e.preventDefault();

    if (!password.length) {
      setFieldsState({ ...defaultFieldsState, incPass: true });
      return;
    }
    else{
      try {
        const response = await recoveryServiceConfirm(token, password);
        if (response.status === 200) {
          window.location.href = "/login";
        }
      } catch (error){
        console.log(error);
      }
    }
  }

  return (
    <>
    <PublicHeader />
      <StyledDivWrapper>
        { modal.isOpen && <CustomModal 
            show={modal.isOpen}
            onHide={onHideModal}
            message={modal.message}
            type={modal.type}
        /> }
        
        <PageTitle title="Password recovery"/>
        <Form onSubmit={handleSubmit}>
            <p>
              {t('Enter your new password')}
            </p>
            <Form.Group controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder={t('Registration_password')}
              onChange={(e) => setPassword(e.target.value)}
            />
            {fieldsState.incPass && (
              <StyledWarningP>{t('Error password empty')}</StyledWarningP>
            )}
            </Form.Group>
            <Button variant="info" onClick={handleSubmit} >
              {t('Button_send')}
            </Button>
        </Form>
      </StyledDivWrapper>
    </>
  )
}

export default PassRecoveryConfirm