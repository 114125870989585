import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  expect1: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  expect2: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  expect3: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  expect4: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  expect5: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  expect6: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  done: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  notDone: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  conclusion1: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  conclusion2: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  conclusion3: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  conclusion4: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  conclusion5: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  conclusion6: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
  conclusion7: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(6500, i18n.t('F1_S6_validation9'))
    .nullable(true),
});
