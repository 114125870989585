import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  resourceTypeId: Yup.string()
    .min(1, 'F1_S2_validation5')
    .required('F1_S2_validation5'),
  ueOrCounterpart: Yup.number()
    .oneOf([0, 1])
    .required('F1_S2_validation5'),
  workingDays: Yup.number()
    .min(0, 'F1_S7_validation10')
    .required('F1_S7_validation11'),
  perDiemDays: Yup.number()
    .min(0, 'F1_S7_validation10')
    .required('F1_S7_validation11'),
  estimatedCost: Yup.number()
    .min(0, 'F1_S7_validation10')
    .required('F1_S7_validation11'),
  description: Yup.string()
    .min(2, 'F3_S7_validation1')
    .max(650, 'F3_S7_validation2')
    .required('F3_S7_validation3'),
});
