import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledFormLabel = styled(Form.Label)`
  padding-left: 65px;
  margin-bottom: 5px;
`;

export const StyledTitle = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    margin-bottom: 20px;
    color: ${theme.brandColors.blue};
    font-weight: 600;
    font-size: 23px;
  `}
`;

export const StyledTextValue = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.gray[5]};
    text-align: justify;
  `}
`;

export const DescripcionDiv = styled.div`
  ${({ theme }) => css`
    padding: 15px;
    border: 2px solid ${theme.colors.gray[3]};
    margin-bottom: 20px;
  `}
`;

export const Text = styled.span`
  line-height: 1.25;
`;

export const TextValueLeftSpaced = styled.div`
  padding-left: 65px;
`;

export const StyledSectionWrapper = styled.div`
  padding-left: 50px;
`;
