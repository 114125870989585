import React from 'react';
import { useTranslation } from 'react-i18next';


import Col from 'react-bootstrap/Col';
import PublicHeader from 'components/PublicHeader';
import { StyledContainer, StyledTitle, StyledSubtitle } from './NotFound.styled';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <PublicHeader />
      <Col>
        <StyledContainer>
          <StyledTitle> { t('page_not_found') } </StyledTitle>
        </StyledContainer>
        <StyledContainer>
          <StyledSubtitle
            to='/home'
            rel="noreferrer"
          >
            { t('return_home') }
          </StyledSubtitle>
        </StyledContainer>
      </Col>
    </>
  )
}

export default NotFound;
