import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  activityAsst1: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('assessmentConfirmed', {
        is: true,
        then: Yup.string().nullable(true).typeError('Error field cannot be empty')
    }),
  activityAsst2: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('assessmentConfirmed', {
        is: true,
        then: Yup.string().typeError('Error field cannot be empty')
    }),
  activityAsst3: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('assessmentConfirmed', {
        is: true,
        then: Yup.string().typeError('Error field cannot be empty')
    }),
  activityAsst4: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('assessmentConfirmed', {
        is: true,
        then: Yup.string().nullable(true).typeError('Error field cannot be empty')
    }),
  activityAsst5: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('assessmentConfirmed', {
        is: true,
        then: Yup.string().nullable(true).typeError('Error field cannot be empty')
    }),
  activityAsst6: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('assessmentConfirmed', {
        is: true,
        then: Yup.string().typeError('Error field cannot be empty')
    }),
  activityAsst7: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('assessmentConfirmed', {
        is: true,
        then: Yup.string().nullable(true).typeError('Error field cannot be empty')
    }),
  activityAsst8: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('assessmentConfirmed', {
        is: true,
        then: Yup.string().nullable(true).typeError('Error field cannot be empty')
    }),
  activityAsst9: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('assessmentConfirmed', {
        is: true,
        then: Yup.string().nullable(true).typeError('Error field cannot be empty')
    }),
  activityAsst10: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('assessmentConfirmed', {
        is: true,
        then: Yup.string().typeError('Error field cannot be empty')
    }),
  activityAsst11: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('assessmentConfirmed', {
        is: true,
        then: Yup.string().nullable(true).typeError('Error field cannot be empty')
    }),
  activityAsst12: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true)
    .when('assessmentConfirmed', {
        is: true,
        then: Yup.string().typeError('Error field cannot be empty')
    }),
    assessmentConfirmed: Yup.boolean(),
});