import { ActivityItem, ActivityItemBody, ActivityItemTitle } from 'components/CommonStyled';
import i18n from 'i18n';

export const mapActivityItems = (array) => {
  return (
    (array && Array.isArray(array))
    ? 
      array.map((assessment) => (
        <ActivityItem>
          <ActivityItemTitle>{`${assessment.activityName}: `}</ActivityItemTitle>
          <ActivityItemBody> {assessment.assessment?  assessment.assessment:' - '} </ActivityItemBody>
        </ActivityItem>
      ))
    :
      " - "
  )
};

export const handleLabel = (participant) => {
  return participant.firstName+" "+participant.lastName+" - "+(participant.confirmed ? participant.willAttend ? i18n.t('F5_S7_Participant_attend'): i18n.t('F5_S7_Participant_Not_attend') : i18n.t('F5_S7_Not_confirmed'));
}