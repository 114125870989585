import styled, { css } from 'styled-components'
import { SuccessButton, PrimaryButton } from 'components/Buttons'

export const  StyledSendButton = styled(SuccessButton)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    border: none;
    text-align: center;
    display: inline-block;
    font-weight: 600;
    border-radius: 16px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    height: 2em;
    padding-top: 0px;
    letter-spacing: 2px;
  `}
`;

export const  StyledExportButton = styled(PrimaryButton)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    color: ${theme.brandColors.lightBlue};
    border: 2px solid ${theme.brandColors.lightBlue};
    text-align: center;
    display: inline-block;
    font-weight: 600;
    border-radius: 16px;
    margin: 10px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    height: 2em;
    padding-top: 0px;
    letter-spacing: 2px;
    &:hover {
      background-color: ${theme.colors.white};
      color: ${theme.colorGroups.info.color};
      border: 2px solid ${theme.colorGroups.info.color};
    }
  `}
`;

export const StyledRow = styled.div`
  object-fit: contain;
  display: flex;
  justify-content: ${props => props.oneElement ? 'flex-end': 'space-between'};
  align-items: center;
  padding: 10px;
`;
