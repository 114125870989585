export const formInitialValues = {
  activityTypeId: '',
  fromDate: '',
  toDate: '',
  description: '',
  countryId: '',
  cityName: '',
  stateName: '',
  countriesAdditional: '',
  duration: 0,
  participants: 0,
  experts: 0,
  daysRemote: 0,
  daysOnsite: 0,
  learning: '',
  justification: '',
  generalAlignment: '',
  generalParticipationMembers: '',
  generalProposalCrossIssues: '',
  descSchedule: '',
  profileList: [],
}
