import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import {
  Table,
  Th1,
  Th3Pointer,
  Th1Pointer,
  Th3,
} from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'
import { StyledRow2 } from 'pages/statistics/CommonStyled/Tabs.styled'
import { orderData6 } from '../helpers/helpers'

const Table6 = ({
  title,
  data,
  waiting,
  renderRows,
  customWidth = undefined,
  customWidthRow1 = undefined,
  customWidthRow2 = undefined,
  setData = undefined,
  orderField = 'category',
  total = undefined,
}) => {
  const { t } = useTranslation()
  const [ascendingOrder, setAscendingOrder] = useState({
    [orderField]: true,
  })

  const handleOrderData = (field) => {
    orderData6(data, field, setData, setAscendingOrder, ascendingOrder)
  }

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data && !isEmpty(data) ? (
    <StyledRow2 style={{ width: customWidth ?? '50%', marginTop: '24px' }}>
      <b>{t('triang_coop_funds_proj')}</b>
      <Table style={{ marginTop: '16px' }}>
        <tr>
          {setData ? (
            <Th3Pointer
              style={{ width: '73%' }}
              onClick={() => handleOrderData(orderField)}
            >
              {title} &#8645;
            </Th3Pointer>
          ) : (
            <Th3 style={{ width: customWidthRow1 ?? '85%' }}>{title}</Th3>
          )}
          {total ? <Th1Pointer scope="col" style={{ width: customWidthRow2 }} onClick={() => handleOrderData('total')}>{t('F1_S7_Total')} &#8645;</Th1Pointer> : 
            <Th1 scope="col" style={{ width: customWidthRow2 }}></Th1>
          }
        </tr>
        {renderRows(data)}
      </Table>
    </StyledRow2>
  ) : (
    t('No_data_available_2')
  )
}

export default Table6
