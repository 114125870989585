import React, { useState } from 'react';
import SendNCancelDialog from './SendNCancelDialog';
import { useTranslation } from 'react-i18next';

import { 
  StyledSendButton,
  StyledRow,
} from './Footer.styled'
import Container from 'react-bootstrap/Container';
import { StyledSendButton as StyledSaveButton, ErrorButton } from 'components/Buttons';

const Footer = (props) => {
  const { t } = useTranslation();
  const [modalData, setModalData] = useState({show: false, content: "", cancel: null});
  const handleCloseModal = () => setModalData({show: false, content: "", cancel: null});
  const formid = props.role == 0 ? 'form8b' : props.role == 1 ? 'form9a' : 'form9c';
 
  return (
    <>
      <SendNCancelDialog 
        displayDialog={modalData.show}
        title={modalData.title}
        onCloseModal={handleCloseModal} 
        role={props.role}
        content={modalData.content}
        cancel={modalData.cancel}
      />

      <Container fluid style={{marginTop: '30px'}}>
        <StyledRow oneElement={props.nonEditable}> 
          {!props.nonEditable &&
          <StyledSaveButton type='submit' form={formid} disabled={props.saved}>{props.saved? t('Button_saved') : t('Button_Save_Later')} </StyledSaveButton> }
          <StyledSendButton disabled={props.nonEditable} onClick={() => setModalData({show: true, content: t('F8_confirmation_to_send'), cancel: false})}>{props.role == 0 || props.role == 1 ? t('Button_Send_Assessor') : t('forgot_password_button')}</StyledSendButton>
        </StyledRow>
        <div style={{ display: 'none' }}>
          <StyledRow oneElement={true}>
              <ErrorButton 
                style={{letterSpacing: '2px'}} 
                disabled={props.nonEditable || props.f8aDone} 
                onClick={()=> setModalData({
                  show: true, 
                  content: t('F8_confirmation_to_cancel'), 
                  cancel: true
                })}
              >
                {t('Button_cancel_f8')}
              </ErrorButton>
          </StyledRow>
        </div>
      </Container>    
    </>
  )
}

export default Footer;