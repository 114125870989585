import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal'

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
`;

export const FooterLeft = styled.div`
  display: flex;
  flex: 1;
`;

export const FooterCenter = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
`;

export const FooterRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
