import styled, {css} from 'styled-components';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
`;

export const FooterLeft = styled.div`
  display: flex;
  flex: 1;
`;

export const FooterCenter = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
`;

export const FooterRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const TextValueLeftSpaced = styled.div`
  padding-left: 65px;
`;

export const DivButtonWrapper = styled.div`
  display: flex;
  color: black;
  white-space: nowrap;
  text-align: center;
`;