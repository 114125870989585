import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { validationSchema } from './Section13.validations'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { saveFormSection } from 'services/initiative'
import { useTranslation } from 'react-i18next'
import { formInitialValues } from './Section13.initialValues'
import htmlParser from 'html-react-parser'
import TextValue from 'components/TextValue'
import Form from 'react-bootstrap/Form'
import { f3statusNamesConst, roleNamesConst } from 'constants/appConstants'
import { useRootStore } from 'stores'
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer'
import { Text, StyledSectionWrapper, StyledFormLabel } from './Section13.styled'
import { isWindows2021 } from 'helpers/isWindow2021Helper'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const Section13 = () => {
  const { t } = useTranslation()
  const { id, formId, sectionId } = useParams()
  const { formStore, initiativeStore } = useRootStore()
  const [saved, setSaved] = useState(false)
  const [nonEditable, setNonEditable] = useState(false)
  const [formValues, setFormValues] = useState(formInitialValues)
  const [window2021, setWindow2021] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f3statusNamesConst.DRAFT ||
        initiativeStore.role === roleNamesConst.GUEST,
    )
    if (initiativeStore.code) {
      setWindow2021(isWindows2021(initiativeStore.code))
    }
  }, [initiativeStore.formStateName, initiativeStore.code])

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {
        ...formInitialValues,
        ...formStore.formSectionData[0],
        rights: formStore.formSectionData[1],
        environment: formStore.formSectionData[2],
        link: formStore.formSectionData[3],
        valueTP: formStore.formSectionData[4],
        valueSR_2: formStore.formSectionData[5],
      }
      for (const key in formSectionData) {
        if (!formSectionData[key]) {
          formSectionData[key] = ''
        }
      }
      setFormValues(formSectionData)
    }
  }, [formStore.formSectionData])

  useEffect(() => {
    if (saved) window.scrollTo(0, 0)
  }, [saved])

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values)
      formStore.setSectionStatus(sectionId, complete)
      resetForm({ values })
      setSaved(true)
    } catch (error) {
      console.log(error)
    }
  }

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault()
    isValid ? handleSubmit(values, { resetForm }) : alert(t('not_saved_alert'))
  }

  return (
    <FormLayout>
      <FormContainer
        number={13}
        title={t('F3_S13_title')}
        formId="form3section13"
        isHelp={true}
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
      >
        <Formik
          enableReinitialize
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            resetForm,
            handleChange,
            handleBlur,
            dirty,
          }) => (
            <form
              onSubmit={(e) => {
                onValidateSubmit(e, isValid, values, { resetForm })
              }}
              id="form3section13"
            >
              {dirty && setSaved(false)}
              <Text>{htmlParser(t('F3_S13_Intro'))}</Text>

              <StyledSectionWrapper>
                <Form.Group>
                  <StyledFormLabel>
                    {htmlParser(t('F3_S13_Rights_based_approach'))}:
                  </StyledFormLabel>
                  <TextValue value={values.rights} />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel>
                    {htmlParser(t('F3_S13_Environment_CC'))}:
                  </StyledFormLabel>
                  <TextValue value={values.environment} />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel>
                    {htmlParser(t('F3_S13_Link_with_national'))}:
                  </StyledFormLabel>
                  <TextValue value={values.link} />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel>
                    {htmlParser(t('F1_S6_Question_21'))}:
                  </StyledFormLabel>
                  <TextValue value={values.valueTP} />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel>
                    {htmlParser(t('F1_S6_Question_21b'))}:
                  </StyledFormLabel>
                  <TextValue value={values.valueSR_2} />
                </Form.Group>

                {window2021 ? (
                  <>
                    <Form.Group>
                      <StyledFormLabel>
                        {htmlParser(t('F3_S13_Possible_leaps'))}:
                      </StyledFormLabel>
                      <Form.Control
                        name="leaps"
                        as="textarea"
                        rows={3}
                        style={{ resize: 'none' }}
                        placeholder={t('Maximum_650')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.leaps}
                        disabled={nonEditable}
                        className={
                          touched.leaps && errors.leaps ? 'error' : null
                        }
                      />
                      <FormErrorMessage
                        touched={touched.leaps}
                        errors={errors.leaps}
                      />
                    </Form.Group>

                    <Form.Group>
                      <StyledFormLabel>
                        {htmlParser(t('F3_S13_Prospects_projection'))}:
                      </StyledFormLabel>
                      <Form.Control
                        name="projection"
                        as="textarea"
                        rows={3}
                        style={{ resize: 'none' }}
                        placeholder={t('Maximum_650')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.projection}
                        disabled={nonEditable}
                        className={
                          touched.projection && errors.projection
                            ? 'error'
                            : null
                        }
                      />
                      <FormErrorMessage
                        touched={touched.projection}
                        errors={errors.projection}
                      />
                    </Form.Group>
                  </>
                ) : null}

                <Form.Group>
                  <StyledFormLabel>
                    {htmlParser(t('F3_S13_Prospects_taking'))}:
                  </StyledFormLabel>
                  <Form.Control
                    name="advantage"
                    as="textarea"
                    rows={3}
                    style={{ resize: 'none' }}
                    placeholder={t('Maximum_650')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.advantage}
                    disabled={nonEditable}
                    className={
                      touched.advantage && errors.advantage ? 'error' : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.advantage}
                    errors={errors.advantage}
                  />
                </Form.Group>
              </StyledSectionWrapper>
            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section13)
