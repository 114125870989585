import { action, makeObservable, observable } from 'mobx';

export class ErrorStore {
  rootStore;
  isShowModal = null;
  modalText = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      isShowModal: observable,
      modalText: observable,
      setError: action,
      closeError: action,
    });
  }

  setError(errorMsg){
    this.isShowModal = true;
    this.modalText = errorMsg;
  }

  closeError(){
    this.isShowModal = false;
  }

}
