import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getActivities } from 'services/initiative';
import { formatDateDDMMYY } from 'helpers/dateHelpers';
import { ExportActivitiesToXLSX } from 'helpers/xlsxExporter';
import { PrimaryButton } from 'components/Buttons';

const CustomExportXLSXButton = (props) => {
  const { t } = useTranslation();
  const [exporting, setExporting] = useState(false);
  const [exportData, setExportData] = useState(null);
  const [buttonReady, setButtonReady] = useState(false);

  const handleClick = async () => {
    setExporting(true);
    const data = await getAllValues();  
    setExportData(data);
    setButtonReady(true);
    setExporting(false);
  };

  async function getAllValues() {
    try {
      let params = {};
      if(props.filter.initiative != 0)
        params.initiativeId = props.filter.initiative;
      if(props.filter.status != 0)
        params.statusId = props.filter.status;
      if(props.filter.type != 0)
        params.type = props.filter.type;
      if(props.filter.country != 0)
        params.countryId = props.filter.country;
      if(props.filter.fromDate !== null)
        params.startDate = props.filter.fromDate.toISOString();
      if(props.filter.toDate !== null)
        params.endDate = props.filter.toDate.toISOString();
      if(props.filter.checkParticipants)
        params.participants = true;
      else
        params.participants = false;
      if(props.filter.checkFinalized)
        params.includeFinalized = true;
      else
        params.includeFinalized = false;
      const response = await getActivities(params);
      const rowValues = response.data.content;
      rowValues.forEach((item) => {
        item.startDate = item.startDate !== null ? formatDateDDMMYY(item.startDate) : '-';
        item.endDate = item.endDate !== null ? formatDateDDMMYY(item.endDate) : '-';
        item.country = item.country !== null ? t(item.country) : '-';
        item.totalBudget = item.totalBudget !== null ? `${item.totalBudget}` : '';
        item.authorized = item.authorized !== null ? `${item.authorized}` : '';
        item.title = item.title !== null ? `${item.title}` : ''; 
      })
      return rowValues;
    } catch (error) {
      console.log(error)
    }
  };

  const onDownloadReady = () => {
    setExportData(null);
    setButtonReady(false);
  };

  return (
    <>
      <ExportActivitiesToXLSX data={exportData} isReady={buttonReady} onReady={onDownloadReady} />
      <PrimaryButton
        onClick={handleClick}
        style={{width: '200px', marginLeft: '-70px'}}
        disabled={exporting}
      >
        { t('Export_to_XLSX') }
      </PrimaryButton>
    </>
  );
};

export default CustomExportXLSXButton;