import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import { Table } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'

import { T1RenderRows, orderData, renderHeaders2 } from '../helpers/helpers'
import { TARGET_YEARS } from 'constants/appConstants'
import { StyledRow2 } from 'pages/statistics/CommonStyled/Tabs.styled'
import htmlParser from 'html-react-parser'

const Table1 = ({ data, waiting, setData }) => {
  const { t } = useTranslation()
  const [ascendingOrder, setAscendingOrder] = useState({
    category: true,
    2021: true,
    2022: true,
    2023: true,
    2024: true,
    total: true,
  })

  const handleOrderData = (field) => {
    orderData(data, field, setData, setAscendingOrder, ascendingOrder)
  }

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
    ) : data && !isEmpty(data) ? (
    <>
      <b>{t('triang_coop_initiatives')}</b>
      <Table>
        <colgroup>
          <col span="5" />
          <col span="5" style={{ backgroundColor: '#a6b3bb' }} />
        </colgroup>
        <tr>{renderHeaders2(TARGET_YEARS, handleOrderData)}</tr>
        {T1RenderRows(data, TARGET_YEARS)}
      </Table>
      <StyledRow2>{htmlParser(t('Statistics_footnote_11'))}</StyledRow2>
    </>
  ) : (
    t('No_data_available_2')
  )
}

export default Table1
