import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  challenge: Yup.string()
    .min(10, 'F1_S5_validation1')
    .max(2500, 'Maximum_2500')
    .required('F3_S7_validation3'),
  objective: Yup.string()
    .min(10, 'F1_S5_validation1')
    .max(350, 'validation_max_350')
    .required('F3_S7_validation3'),
  knowledge: Yup.string()
    .min(10, 'F1_S5_validation1')
    .max(2500, 'Maximum_2500')
    .required('F3_S7_validation3'),
});
