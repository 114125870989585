import React, { useState, useEffect } from 'react'
import Header4 from '../components/Header4'
import Table4 from '../components/Table4'

import { getSummaryTarget } from 'services/initiative'

import Row from 'react-bootstrap/Row'
import { StyledDiv, StyledRow, StyledRow2 } from '../../CommonStyled/Tabs.styled'
import Table6 from 'pages/statistics/Entities/components/Table6'
import { useTranslation } from 'react-i18next'
import { T6RenderRows } from '../helpers/helpers'
import { getFundsODSTargetSummary } from 'services/fundsStatistics'

const InitiativesSummaryTarget = () => {
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const [data2, setData2] = useState(null)
  const [waiting2, setWaiting2] = useState(false)

  const getData = async (param) => {
    setWaiting(true)
    try {
      const response = await getSummaryTarget(param)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  const getData2 = async (param) => {
    setWaiting2(true)
    try {
      const response = await getFundsODSTargetSummary(param)
      setData2(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting2(false)
    }
  }

  useEffect(() => {
    getData()
    getData2()
  }, [])

  return (
    <StyledDiv>
      <Row>
        <Header4 data={data} getData={getData} data2={data2} getData2={getData2} waiting={waiting} />
      </Row>
      <StyledRow>
        <Table4 data={data} waiting={waiting} setData={setData} />
      </StyledRow>
      <StyledRow2 style={{marginLeft: '0px'}}>
        <Table6
          title={t('Initiatives_target')}
          data={data2}
          waiting={waiting2}
          renderRows={T6RenderRows}
          customWidth='74%'
          customWidthRow1='91%'
          customWidthRow2='9%'
          setData={setData2}
          total={true}
        />
      </StyledRow2>
    </StyledDiv>
  )
}

export default InitiativesSummaryTarget
