import React from 'react'
import PageTitle from 'components/PageTitle'
import { PageHeader } from '../CommonStyled/Tabs.styled'
import { useTranslation } from 'react-i18next'
import FundsCountryReport from './tabs/FundsCountryReport'
import StatisticsTabs from 'components/Tabs'

const StatsFundsCountry = () => {
  const { t } = useTranslation()

  const titles = [t('country_initiative_report_tab_name')]

  const renderTab1Content = () => <FundsCountryReport />

  const contents = [
    renderTab1Content,
  ]

  return (
    <>
      <PageHeader>
        <PageTitle title={t('menu_admin_6_8.2')} />
      </PageHeader>
      <StatisticsTabs titles={titles} contents={contents} />
    </>
  )
}

export default StatsFundsCountry
