import React, { useEffect } from 'react';
import ReactExport from 'react-export-excel';
import i18n from 'i18n';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportInvoiceToXLSX = ({ data, isReady, onReady }) => {
  useEffect(() => {
    if (isReady) {
      document.getElementById('download').click();
      onReady();
    }
  }, [isReady]);

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={i18n.t('menu_admin_21')}
    >
      <ExcelSheet data={data} name={i18n.t('menu_admin_21')}>
        <ExcelColumn label={i18n.t('Initiative')} value="initiative" />
        <ExcelColumn label={i18n.t('Activity')} value="activityNumber" />
        <ExcelColumn label={i18n.t('Category')} value="category" />
        <ExcelColumn label={i18n.t('Provider')} value="provider" />
        <ExcelColumn label={i18n.t('Description')} value="description" />
        <ExcelColumn label={i18n.t('invoice_mgmt_amount')} value="amount" />
        <ExcelColumn label={i18n.t('invoice_mgmt_completed')} value="paymentEnabled" />
        <ExcelColumn label={i18n.t('invoice_mgmt_paid')} value="paid" />
        <ExcelColumn label={i18n.t('invoice_mgmt_eu')} value="invoiced" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export const ExportPurchasingToXLSX = ({ data, isReady, onReady }) => {
  useEffect(() => {
    if (isReady) {
      document.getElementById('download').click();
      onReady();
    }
  }, [isReady]);

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={i18n.t('menu_admin_9')}
    >
      <ExcelSheet data={data} name={i18n.t('menu_admin_9')}>
        <ExcelColumn label={i18n.t('Initiative')} value="initiative" />
        <ExcelColumn label={i18n.t('Activity')} value="activity" />
        <ExcelColumn label={i18n.t('Category')} value="category" />
        <ExcelColumn label={i18n.t('Description')} value="description" />
        <ExcelColumn label={i18n.t('Provider')} value="provider" />
        <ExcelColumn label={i18n.t('Status')} value="status" />
        <ExcelColumn label={i18n.t('Working_Days')} value="workingDays" />
        <ExcelColumn label={i18n.t('Authorized')} value="authorized" />
        <ExcelColumn label={i18n.t('Implementation')} value="implementation" />
        <ExcelColumn label={i18n.t('Paid')} value="paid" />
        <ExcelColumn label={i18n.t('Deviation')} value="deviation" />
        <ExcelColumn label={i18n.t('Budget')} value="budget" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export const ExportActivitiesToXLSX = ({ data, isReady, onReady }) => {
  useEffect(() => {
    if (isReady) {
      document.getElementById('download').click();
      onReady();
    }
  }, [isReady]);

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={i18n.t('Activities')}
    >
      <ExcelSheet data={data} name={i18n.t('Activities')}>
        <ExcelColumn label={i18n.t('Code')} value="code" />
        <ExcelColumn label={i18n.t('Start_Date')} value="startDate" />
        <ExcelColumn label={i18n.t('End_Date')} value="endDate" />
        <ExcelColumn label={i18n.t('F1_S7_type')} value="type" />
        <ExcelColumn label={i18n.t('F5_S15_Lesson_title')} value="title" />
        <ExcelColumn label={i18n.t('F1_S2_Country')} value="country" />
        <ExcelColumn label={i18n.t('Status')} value="status" />
        <ExcelColumn
          label={i18n.t('Authorized')}
          value={col => Number.parseFloat(col.authorized)}
        />
        <ExcelColumn
          label={i18n.t('Implementation_nocut')}
          value={col => Number.parseFloat(col.totalBudget)}
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

export const ExportMainDashboardToXLSX = ({ data, isReady, onReady }) => {
  useEffect(() => {
    if (isReady) {
      document.getElementById('download').click();
      onReady();
    }
  }, [isReady]);

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={i18n.t('Dashboard_Adelante2')}
    >
      <ExcelSheet data={data} name={i18n.t('Dashboard_Adelante2')}>
        <ExcelColumn
          label={`${i18n.t('Period_elapsed')} / ${i18n.t('Elapsed')}`}
          value="period_elapsed"
        />
        <ExcelColumn
          label={`${i18n.t('Period_elapsed')} / ${i18n.t('Remaining')}`}
          value="period_remaining"
        />
        <ExcelColumn
          label={`${i18n.t('Incidentals')} / ${i18n.t('Consumed')}`}
          value="incidentals_consumed"
        />
        <ExcelColumn
          label={`${i18n.t('Incidentals')} / ${i18n.t('Available')}`}
          value="incidentals_available"
        />
        <ExcelColumn
          label={`${i18n.t('Nke_working_days')} / ${i18n.t('Executed')}`}
          value="workingDays_executed"
        />
        <ExcelColumn
          label={`${i18n.t('Nke_working_days')} / ${i18n.t('Available')}`}
          value="workingDays_available"
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

export const ExportContactsToXLSX = ({ data, isReady, onReady }) => {
  useEffect(() => {
    if (isReady) {
      document.getElementById('download').click();
      onReady();
    }
  }, [isReady]);

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={i18n.t('menu_admin_5')}
    >
      <ExcelSheet data={data} name={i18n.t('menu_admin_5')}>
        <ExcelColumn label={i18n.t('F1_S2_Entity_name')} value="name" />
        <ExcelColumn label={i18n.t('F1_S2_Type_of_entity')} value="type" />
        <ExcelColumn label={i18n.t('F1_S2_Country')} value="country" />
        <ExcelColumn label={i18n.t('F1_S2_Email')} value="email" />
        <ExcelColumn label={i18n.t('F1_S2_Phone_number')} value="phone" />
        <ExcelColumn label={i18n.t('Position')} value="positionR" />
        <ExcelColumn label={i18n.t('Organization')} value="relation" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export const ExportInitiativesDashboardToXLSX = ({ data, isReady, onReady }) => {
  useEffect(() => {
    if (isReady) {
      document.getElementById('download').click();
      onReady();
    }
  }, [isReady]);

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={i18n.t('Dashboard_Initiatives')}
    >
      <ExcelSheet data={data} name={i18n.t('Dashboard_Initiatives')}>
        <ExcelColumn label={i18n.t('Dashboard_Initiative')} value="initiative" />
        <ExcelColumn label={i18n.t('Period_elapsed')} value="durationPercentage" />
        <ExcelColumn label={i18n.t('Executed_Budget_EU')} value="euBudgetPercentage" />
        <ExcelColumn
          label={i18n.t('Executed_Budget_COUNTERPART')}
          value="counterpartBudgetPercentage"
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

export const ExportExecutedBudgetDashboardToXLSX = ({ data, isReady, onReady }) => {
  useEffect(() => {
    if (isReady) {
      document.getElementById('download').click();
      onReady();
    }
  }, [isReady]);

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={i18n.t('Dashboard_Execution')}
    >
      <ExcelSheet data={data} name={i18n.t('Dashboard_Execution')}>
        <ExcelColumn label={i18n.t('Budget_category')} value="resourceType" />
        <ExcelColumn
          label={i18n.t('Authorized')}
          value="authorized"
        />
        <ExcelColumn
          label={i18n.t('Implementation')}
          value="implementation"
        />
        <ExcelColumn
          label={i18n.t('Deviation')}
          value="percentage"
        />
        <ExcelColumn
          label={i18n.t('Paid')}
          value="paid"
        />
        <ExcelColumn
          label={i18n.t('Executed_level_dashboard')}
          value="level"
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

export const ExportPreformulationDashboardToXLSX = ({ data, isReady, onReady }) => {
  useEffect(() => {
    if (isReady) {
      document.getElementById('download').click();
      onReady();
    }
  }, [isReady]);

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={i18n.t('Dashboard_Preformulation_cn')}
    >
      <ExcelSheet data={data} name={i18n.t('Dashboard_Preformulation_cn')}>
        <ExcelColumn label="" value="label" />
        <ExcelColumn
          label={i18n.t('Dashboard_Preform_approved')}
          value="col1"
        />
        <ExcelColumn
          label={i18n.t('Dashboard_Preform_assessment')}
          value="col2"
        />
        <ExcelColumn
          label={i18n.t('Dashboard_Preform_total')}
          value="col3"
        />
      </ExcelSheet>
    </ExcelFile>
  );
};