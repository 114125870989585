import { ThemeProvider } from 'styled-components';

const gray = {
  1: '#7c8f9c', //Textos menu, nombres campo
  2: '#a6b3bb', //Circulos secciones
  3: '#cbd4da', //Circulos menu derecha
  4: '#f7f8f8', //Fondo form
  5: '#2c2c2c'
}

const themeValues = {
  colors: {
    black: '#000',
    white: '#fff',
    gray,
  },
  brandColors: {
    yellow: '#FEC109',
    blue: '#15A7CB',
    darkBlue: '#004898',
    red: '#EF4242',
    gray1: gray[1],
    gray2: gray[2],
    gray3: gray[3],
    gray4: gray[4],
    green: '#8ac765',
    greenHover: '#7cb45c',
    grayFooter: '#a2b4bd',
    lightBlue: '#64b3d0',
    lightBlueHover: '#0199b6',
  },
  colorGroups: {
    default: {
      color: '#000',
      bgColor: '#fff',
      borderColor: gray[4],
    },
    info: {
      color: '#004085',
      bgColor: '#cce5ff',
      borderColor: '#cce5ff',
    },
    error: {
      color: '#721c24',
      bgColor: '#f8d7da',
      borderColor: '#f5c6cb',
    },
    warning: {
      color: '#856404',
      bgColor: '#fff3cd',
      borderColor: '#ffeeba',
    },
    success: {
      color: '#155724',
      bgColor: '#d4edda',
      borderColor: '#c3e6cb',
    },
  }
}

const Theme = ({ children }) => (
  <ThemeProvider theme={themeValues}>
    { children }
  </ThemeProvider>
)

export default Theme;

