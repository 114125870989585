import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { formInitialValues } from './GeneralInfoForm.initialValues'
import { validationSchema } from './GeneralInfoForm.validations'
import {
  saveGeneralInfoF5S7,
  getGeneralInfoF5S7,
  saveGeneralInfoMgmF5S7,
} from 'services/initiative'
import { useRootStore } from 'stores'
import { roleNamesConst } from 'constants/appConstants'
import { formatDateDDMMYY, parseDateString } from 'helpers/dateHelpers'
import { useConstantsForm5 } from 'customHooks/useConstantsForm5'

import HelpIcon from 'components/HelpIcon'
import { PrimaryButton } from 'components/Buttons'
import { StyledFormLabel, StyledCheck } from './GeneralInfoForm.styled'
import TextValue from 'components/TextValue'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ErrorButton, StyledSendButton } from 'components/Buttons'
import { Form } from 'react-bootstrap'
import {
  FormFielErrorMessage,
  RequiredMark,
  SeparatorDotted,
  CenteredModalButtonContainer,
} from 'components/CommonStyled'
import { RadioLabel } from 'components/Forms/Forms.styled'

const GeneralInfoForm = ({
  handleCloseOk,
  handleClose,
  activity,
  handleCloseSubModalOnSave,
  handleCloseSubModalOnError,
  nonEditable,
  nonEditableMgm,
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { authStore } = useRootStore()
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [disabled, setDisabled] = useState(nonEditable)
  const { countries } = useConstantsForm5()
  const role = authStore.roles[0]
  const isAtke = role === roleNamesConst.ATKE
  const isPublic = role === roleNamesConst.PUBLIC

  const handleSubmit = async (values) => {
    try {
      if (isAtke) await saveGeneralInfoMgmF5S7(id, activity.id, values)
      else await saveGeneralInfoF5S7(id, activity.id, values)
      handleCloseSubModalOnSave()
    } catch (error) {
      handleCloseSubModalOnError()
    }
  }

  useEffect(() => {
    async function getValues() {
      let response = await getGeneralInfoF5S7(id, activity.id)
      const formValues = { ...formInitialValues, ...response.data }
      formValues.fromDate = formValues.fromDate
        ? parseDateString(formValues.fromDate)
        : ''
      formValues.toDate = formValues.toDate
        ? parseDateString(formValues.toDate)
        : ''
      formValues.countryId = formValues.country ? formValues.country.id : ''
      setInitialValues(formValues)

      if (!isPublic) setDisabled(true)
      else setDisabled(formValues.genInfoConfirmed)

      if (activity.activityState.name === 'CANCELLED') {
        setDisabled(true)
      }
    }
    getValues()
  }, [])

  const handleChangeRadio = (e, setFieldValue) => {
    setFieldValue('format', parseInt(e?.target?.value))
    if (e?.target?.value === '2') {
      setFieldValue('countryId', '')
      setFieldValue('stateName', '')
      setFieldValue('cityName', '')
      setFieldValue('countriesAdditional', '')
    }
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} id="generalInfo">
            <Form.Group as={Row}>
              <StyledFormLabel xs={4}>{t('F1_S7_type')}:</StyledFormLabel>
              <TextValue
                value={
                  values.activityType
                    ? t(`activity_types_${values.activityType.id}`)
                    : ' - '
                }
              />
            </Form.Group>

            <Form.Group
              as={Row}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Form.Label column xs={2}>
                {t('F1_S7_start_day')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col>
                <DatePicker
                  style={{ marginLeft: '0', marginTop: '5px' }}
                  dateFormat="dd/MM/yyyy"
                  selected={values.fromDate}
                  onChange={(date) => setFieldValue('fromDate', date)}
                  className={
                    touched.fromDate && errors.fromDate ? 'error' : null
                  }
                  disabled={disabled}
                />
                {errors.fromDate ? (
                  <FormFielErrorMessage>{errors.fromDate}</FormFielErrorMessage>
                ) : null}
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Form.Label column xs={2}>
                {t('F1_S7_End_day')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col>
                <DatePicker
                  style={{ marginLeft: '0', marginTop: '5px' }}
                  dateFormat="dd/MM/yyyy"
                  selected={values.toDate}
                  onChange={(date) => setFieldValue('toDate', date)}
                  className={touched.toDate && errors.toDate ? 'error' : null}
                  disabled={disabled}
                />
                {errors.toDate ? (
                  <FormFielErrorMessage>{errors.toDate}</FormFielErrorMessage>
                ) : null}
              </Col>
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: 'center',
                  textAlign: 'justify',
                  lineHeight: '1.3',
                }}
              >
                {t('F5_S7_Title_of_the_activity')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Form.Control
                name="title"
                as="textarea"
                rows={3}
                placeholder={t('Maximum_200')}
                style={{ resize: 'none' }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                className={touched.title && errors.title ? 'error' : null}
                disabled={disabled}
              />
              {touched.title && errors.title ? (
                <FormFielErrorMessage>{errors.title}</FormFielErrorMessage>
              ) : null}
            </Form.Group>

            <Form.Group>
              <StyledFormLabel
                as={Row}
                style={{ marginTop: '30px', marginBottom: '15px' }}
              >
                {t('F1_S7_Short_description')}:
              </StyledFormLabel>
              <TextValue value={values.description} />
            </Form.Group>

            <SeparatorDotted />

            <Form.Group as={Row}>
              <Form.Label column lg={4}>
                {t('F1_S7_Format')} <RequiredMark>*</RequiredMark>:
                <HelpIcon info={t('F1_S7_Format_INFO')} />
              </Form.Label>
              <Col lg={8} style={{ marginTop: '20px' }}>
                <RadioLabel>
                  <input
                    type="radio"
                    value="0"
                    checked={values?.format == 0}
                    onChange={(e) => handleChangeRadio(e, setFieldValue)}
                    style={{ marginRight: '10px' }}
                    disabled={disabled}
                  />
                  {t('F1_S7_Format0')}
                </RadioLabel>

                <RadioLabel>
                  <input
                    type="radio"
                    value="1"
                    checked={values?.format == 1}
                    onChange={(e) => handleChangeRadio(e, setFieldValue)}
                    style={{ marginRight: '10px' }}
                    disabled={disabled}
                  />
                  {t('F1_S7_Format1')}
                </RadioLabel>

                <RadioLabel>
                  <input
                    type="radio"
                    value="2"
                    checked={values?.format == 2}
                    onChange={(e) => handleChangeRadio(e, setFieldValue)}
                    style={{ marginRight: '10px' }}
                    disabled={disabled}
                  />
                  {t('F1_S7_Format2')}
                </RadioLabel>

                {touched.format && errors.format ? (
                  <FormFielErrorMessage>{errors.format}</FormFielErrorMessage>
                ) : null}
              </Col>
            </Form.Group>

            {values?.format != 2 && (
              <>
                <Form.Group as={Row}>
                  <Form.Label column lg={4}>
                    {t('F1_S7_Country_activity')} <RequiredMark>*</RequiredMark>
                    :
                    <HelpIcon info={t('F1_S7_Country_activity_INFO')} />
                  </Form.Label>
                  <Col lg={5}>
                    <Form.Control
                      as="select"
                      name="countryId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.countryId}
                      className={
                        touched.countryId && errors.countryId ? 'error' : null
                      }
                      disabled={disabled}
                    >
                      <option value="">{t('Placeholder_select')}</option>
                      {countries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {t(country.name)}
                        </option>
                      ))}
                    </Form.Control>
                    {touched.countryId && errors.countryId ? (
                      <FormFielErrorMessage>
                        {errors.countryId}
                      </FormFielErrorMessage>
                    ) : null}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: 'center' }}>
                  <Form.Label column xs={4}>
                    {t('F1_S2_State_Province_Region')}:
                  </Form.Label>
                  <Col xs={5}>
                    <Form.Control
                      type="text"
                      name="stateName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.stateName}
                      className={
                        touched.stateName && errors.stateName ? 'error' : null
                      }
                      disabled={disabled}
                    />
                    {touched.stateName && errors.stateName ? (
                      <FormFielErrorMessage>
                        {errors.stateName}
                      </FormFielErrorMessage>
                    ) : null}
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column lg={4}>
                    {t('F1_S2_City')} <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Col lg={5}>
                    <Form.Control
                      type="text"
                      name="cityName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cityName}
                      className={
                        touched.cityName && errors.cityName ? 'error' : null
                      }
                      disabled={disabled}
                    />
                    {touched.cityName && errors.cityName ? (
                      <FormFielErrorMessage>
                        {errors.cityName}
                      </FormFielErrorMessage>
                    ) : null}
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column lg={4}>
                    {t('F1_S7_Countries_additional')}:
                  </Form.Label>
                  <Col lg={5}>
                    <Form.Control
                      type="text"
                      name="countriesAdditional"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.countriesAdditional}
                      className={
                        touched.countriesAdditional &&
                        errors.countriesAdditional
                          ? 'error'
                          : null
                      }
                      disabled={disabled}
                    />
                    {touched.countriesAdditional &&
                    errors.countriesAdditional ? (
                      <FormFielErrorMessage>
                        {errors.countriesAdditional}
                      </FormFielErrorMessage>
                    ) : null}
                  </Col>
                </Form.Group>
              </>
            )}

            <SeparatorDotted />

            <Form.Group as={Row}>
              <Form.Label column xs={3}>
                {t('F1_S7_Duration')}:
              </Form.Label>
              <Col xs={3}>
                <Form.Control
                  type="number"
                  min="0"
                  name="duration"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.duration}
                  className={
                    touched.duration && errors.duration ? 'error' : null
                  }
                  disabled={disabled}
                />
                {touched.duration && errors.duration ? (
                  <FormFielErrorMessage>{errors.duration}</FormFielErrorMessage>
                ) : null}
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column xs={3}>
                {t('F1_S7_Number_participants')}:
              </Form.Label>
              <Col xs={3}>
                <Form.Control
                  type="number"
                  min="0"
                  name="participants"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.participants}
                  className={
                    touched.participants && errors.participants ? 'error' : null
                  }
                  disabled={disabled}
                />
                {touched.participants && errors.participants ? (
                  <FormFielErrorMessage>
                    {errors.participants}
                  </FormFielErrorMessage>
                ) : null}
              </Col>
            </Form.Group>

            <SeparatorDotted />

            {values.activityType && values.activityType.id == 4 && (
              <>
                <Form.Group as={Row}>
                  <Form.Label column xs={3}>
                    {t('F1_S7_Number_experts')}:
                  </Form.Label>
                  <Col xs={3}>
                    <Form.Control
                      type="number"
                      min="0"
                      name="experts"
                      value={values.experts}
                      disabled
                    />
                  </Col>
                </Form.Group>

                <StyledFormLabel
                  xs={4}
                  as={Row}
                  style={{ marginTop: '30px', marginBottom: '15px' }}
                >
                  {t('F1_S7_Number_wd')}:
                </StyledFormLabel>

                <Form.Group as={Row}>
                  <Form.Label column xs={3}>
                    {t('F1_S7_Remote')}:
                  </Form.Label>
                  <Col xs={3}>
                    <Form.Control
                      type="number"
                      min="0"
                      name="daysRemote"
                      value={values.daysRemote}
                      disabled
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column xs={3}>
                    {t('F1_S7_Onsite')}:
                  </Form.Label>
                  <Col xs={3}>
                    <Form.Control
                      type="number"
                      min="0"
                      name="daysOnsite"
                      value={values.daysOnsite}
                      disabled
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel xs={4}>{t('F1_S7_Total')}:</StyledFormLabel>
                  <TextValue value={values.daysRemote + values.daysOnsite} />
                </Form.Group>

                <SeparatorDotted />
              </>
            )}
            <Form.Group>
              <Row>
                <StyledFormLabel>
                  {t('F3_S7_General_work_language')}
                  <RequiredMark>*</RequiredMark>:
                </StyledFormLabel>
                <Form.Check
                  disabled={disabled}
                  style={{ marginLeft: '15px' }}
                  name="workLang1"
                  type="checkbox"
                  checked={values.workLang1}
                  value={values.workLang1}
                  onChange={handleChange}
                  label={t('English')}
                />
                <Form.Check
                  disabled={disabled}
                  style={{ marginLeft: '15px' }}
                  name="workLang2"
                  type="checkbox"
                  checked={values.workLang2}
                  value={values.workLang2}
                  onChange={handleChange}
                  label={t('French')}
                />
                <Form.Check
                  disabled={disabled}
                  style={{ marginLeft: '15px' }}
                  name="workLang3"
                  type="checkbox"
                  checked={values.workLang3}
                  value={values.workLang3}
                  onChange={handleChange}
                  label={t('Portuguese')}
                />
                <Form.Check
                  disabled={disabled}
                  style={{ marginLeft: '15px' }}
                  name="workLang4"
                  type="checkbox"
                  checked={values.workLang4}
                  value={values.workLang4}
                  onChange={handleChange}
                  label={t('Spanish')}
                />
              </Row>
              {errors.workLang1 ? (
                <FormFielErrorMessage>{errors.workLang1}</FormFielErrorMessage>
              ) : null}
            </Form.Group>

            <Form.Group as={Row}>
              <StyledFormLabel xs={4}>
                {t('F3_S7_General_learning_objectives')}:
              </StyledFormLabel>
              <TextValue value={values.learning} />
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: 'center',
                  textAlign: 'justify',
                  lineHeight: '1.3',
                }}
              >
                {t('F3_S7_General_alignment')}:
              </Form.Label>
              <Form.Control
                name="generalAlignment"
                as="textarea"
                rows={3}
                placeholder={t('Maximum_1300')}
                style={{ resize: 'none' }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.generalAlignment}
                className={
                  touched.generalAlignment && errors.generalAlignment
                    ? 'error'
                    : null
                }
                disabled={true}
              />
              {touched.generalAlignment && errors.generalAlignment ? (
                <FormFielErrorMessage>
                  {errors.generalAlignment}
                </FormFielErrorMessage>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: 'center',
                  textAlign: 'justify',
                  lineHeight: '1.3',
                }}
              >
                {t('F3_S7_General_participation_members')}:
              </Form.Label>
              <Form.Control
                name="generalParticipationMembers"
                as="textarea"
                rows={3}
                placeholder={t('Maximum_1300')}
                style={{ resize: 'none' }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.generalParticipationMembers}
                className={
                  touched.generalParticipationMembers &&
                  errors.generalParticipationMembers
                    ? 'error'
                    : null
                }
                disabled={true}
              />
              {touched.generalParticipationMembers &&
              errors.generalParticipationMembers ? (
                <FormFielErrorMessage>
                  {errors.generalParticipationMembers}
                </FormFielErrorMessage>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: 'center',
                  textAlign: 'justify',
                  lineHeight: '1.3',
                }}
              >
                {t('F3_S7_General_proposal_cross_issues')}:
                <HelpIcon info={t('F3_S7_General_proposal_cross_issues_INFO')} />
              </Form.Label>
              <Form.Control
                name="generalProposalCrossIssues"
                as="textarea"
                rows={3}
                placeholder={t('Maximum_1300')}
                style={{ resize: 'none' }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.generalProposalCrossIssues}
                className={
                  touched.generalProposalCrossIssues &&
                  errors.generalProposalCrossIssues
                    ? 'error'
                    : null
                }
                disabled={true}
              />
              {touched.generalProposalCrossIssues &&
              errors.generalProposalCrossIssues ? (
                <FormFielErrorMessage>
                  {errors.generalProposalCrossIssues}
                </FormFielErrorMessage>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: 'center',
                  textAlign: 'justify',
                  lineHeight: '1.3',
                }}
              >
                {t('F3_S7_General_brief_description_schedule')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Form.Control
                name="descSchedule"
                as="textarea"
                rows={3}
                placeholder={t('Maximum_1300')}
                style={{ resize: 'none' }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.descSchedule}
                className={
                  touched.descSchedule && errors.descSchedule ? 'error' : null
                }
                disabled={disabled}
              />
              {touched.descSchedule && errors.descSchedule ? (
                <FormFielErrorMessage>
                  {errors.descSchedule}
                </FormFielErrorMessage>
              ) : null}
            </Form.Group>

            <StyledCheck>
              <Form.Group>
                <Form.Check
                  disabled={
                    (disabled && !isAtke) ||
                    (isAtke && nonEditableMgm) ||
                    activity.activityState.name === 'CANCELLED'
                  }
                  style={{ marginLeft: '15px', textAlign: 'justify' }}
                  name="genInfoConfirmed"
                  type="checkbox"
                  checked={values.genInfoConfirmed}
                  value={values.genInfoConfirmed}
                  onChange={handleChange}
                  label={t('F5_S7_General_confirmation_check')}
                  className={
                    touched.genInfoConfirmed && errors.genInfoConfirmed
                      ? 'error'
                      : null
                  }
                />
                {touched.genInfoConfirmed && errors.genInfoConfirmed ? (
                  <FormFielErrorMessage>
                    {errors.genInfoConfirmed}
                  </FormFielErrorMessage>
                ) : null}
              </Form.Group>
            </StyledCheck>

            {values.genInfoTimestamp && (
              <div style={{ marginTop: '10px' }}>
                {`${t('F5_S7_Confirmed')}: ${
                  values.genInfoTimestamp
                    ? formatDateDDMMYY(values.genInfoTimestamp)
                    : ''
                }`}
              </div>
            )}
            <CenteredModalButtonContainer>
              {(disabled && !isAtke) ||
              (isAtke && nonEditableMgm) ||
              activity.activityState.name === 'CANCELLED' ? (
                <PrimaryButton
                  onClick={() => {
                    handleCloseOk()
                  }}
                >
                  OK
                </PrimaryButton>
              ) : (
                <>
                  <ErrorButton
                    style={{ marginRight: '20px' }}
                    onClick={handleClose}
                  >
                    {t('Button_cancel')}
                  </ErrorButton>
                  <StyledSendButton type="submit" form="generalInfo">
                    {t('Button_save')}
                  </StyledSendButton>
                </>
              )}
            </CenteredModalButtonContainer>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default observer(GeneralInfoForm)
