import React, { useEffect, useMemo, useState } from 'react'
import { useRootStore } from 'stores'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { getToken } from 'services/auth'
import { roleNamesConst } from 'constants/appConstants'

import Nav from 'react-bootstrap/Nav'
import { Container, Link, Dropdown, DropdownItem, DropdownLink } from './Navbar.styled'
import { getFunds } from 'services/funds'
import { useAdelanteCommunityFeaturesContext } from 'customHooks/useAdelanteCommunityFeatures'
import { canAccessCommunity } from 'services/community'

const Navbar = () => {
  //Manage Initiative lang
  const { t } = useTranslation()

  const isLoggedIn = !!getToken()
  const { authStore } = useRootStore();

  const [canApplicantSeeFunds, setCanApplicantSeeFunds] = useState(false);
  const [canSeeComunityLink, setCanSeeComunityLink] = useState(false);

  const fetchFunds = async () => {
    const funds = await getFunds({});
    setCanApplicantSeeFunds(funds && Array.isArray(funds) && funds?.length > 0)
  };

  useEffect(() => {
    fetchFunds();
  }, [])

  const publicNavbar = [
    {
      name: t('menu_app_1'),
      path: '/applicant/concept-notes',
      dropdown: false,
    },
    { name: t('menu_app_2'), path: '/applicant/proposals', dropdown: false },
    {
      name: t('menu_app_3'),
      path: '/applicant/management',
      dropdown: true,
      options: [
        {
          path: '/applicant/management/implementation-forms',
          name: t('menu_admin_12'),
        },
        {
          path: '/applicant/management/expost-reporting',
          name: t('menu_admin_13'),
        },
      ],
    },
    { 
      name: t('menu_admin_22'), 
      path: "/applicant/funds", 
      dropdown: false, 
      notVisible: !canApplicantSeeFunds 
    },
    { name: t('menu_admin_19'), path: '/applicant/documents' },
    { path: '/applicant/contract-management', name: t('menu_admin_9') },
  ]

  const intpaNavbar = [
    {
      name: t('menu_admin_2'),
      path: '/intpa/concept-notes',
      active: true,
      dropdown: false,
    },
    { name: t('menu_admin_3'), path: '/intpa/proposals', dropdown: false },
    {
      name: t('menu_admin_4'),
      path: '/intpa/management',
      dropdown: true,
      options: [
        { path: '/intpa/management/activities', name: t('menu_admin_20') },
        {
          path: '/intpa/management/final-assessments',
          name: t('menu_admin_11'),
        },
        {
          path: '/intpa/management/expost-reporting',
          name: t('menu_admin_16'),
        },
      ],
    },
    {name: t('menu_admin_22'), path: "/intpa/funds", dropdown: false },
    { name: t('menu_admin_5'), path: '/intpa/contacts' },
    { name: t('menu_admin_19'), path: '/intpa/documents' },
    {
      name: t('menu_admin_6'),
      path: '/intpa/statistics',
      dropdown: true,
      options: [
        { path: '/intpa/statistics/preformulation', name: t('menu_admin_6_1_1') },
        { path: '/intpa/statistics/formulation', name: t('menu_admin_6_1_2') },
        { path: '/intpa/statistics/execution', name: t('menu_admin_6_2') },
        {
          path: '/intpa/statistics/entities',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_3')}</>,
        },
        {
          path: '/intpa/statistics/partnerships',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_4')}</>,
        },
        /*  {
          path: '/intpa/statistics/initiatives',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_5')} </>,
        },
    */
        {
          path: '/intpa/statistics/activities',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_6')}</>,
        },
        {
          path: '/intpa/statistics/direct-beneficiaries',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_9')}</>,
        },
        {
          path: '/intpa/statistics/agenda2030',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_10')} </>,
        },
        {
          path: '/intpa/statistics/expost',
          name: t('menu_admin_6_11'),
        },
        { path: '/intpa/statistics/country', name: t('menu_admin_6_8.1') },
        { path: '/intpa/statistics/country-funds', name: t('menu_admin_6_8.2') },
        { path: '/intpa/statistics/results', name: t('menu_admin_6_7b') },
      ],
    },
    //  {name: t('menu_admin_7'), path: "/intpa/events"},
  ]

  const taNavbar = [
    { name: t('menu_admin_2'), path: '/ta/concept-notes', dropdown: false },
    { name: t('menu_admin_3'), path: '/ta/proposals', dropdown: false },
    {
      name: t('menu_admin_4'),
      path: '/ta/management',
      dropdown: true,
      options: [
        { path: '/ta/management/contract-management', name: t('menu_admin_9') },
        { path: '/ta/management/invoice-management', name: t('menu_admin_21') },
        { path: '/ta/management/activities', name: t('menu_admin_20') },
        {
          path: '/ta/management/implementation-forms',
          name: t('menu_admin_10'),
        },
        { path: '/ta/management/expost-reporting', name: t('menu_admin_14') },
      ],
    },
    {name: t('menu_admin_22'), path: "/ta/funds", dropdown: false },
    { name: t('menu_admin_5'), path: '/ta/contacts' },
    { name: t('menu_admin_19'), path: '/ta/documents' },
    {
      name: t('menu_admin_6'),
      path: '/ta/statistics',
      dropdown: true,
      options: [
        { path: '/ta/statistics/preformulation', name: t('menu_admin_6_1_1') },
        { path: '/ta/statistics/formulation', name: t('menu_admin_6_1_2') },
        { path: '/ta/statistics/execution', name: t('menu_admin_6_2') },
        {
          path: '/ta/statistics/entities',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_3')}</>,
        },
        {
          path: '/ta/statistics/partnerships',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_4')}</>,
        },
        /*  {
          path: '/ta/statistics/initiatives',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_5')} </>,
        },
    */
        {
          path: '/ta/statistics/activities',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_6')}</>,
        },
        {
          path: '/ta/statistics/direct-beneficiaries',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_9')}</>,
        },
        {
          path: '/ta/statistics/agenda2030',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_10')} </>,
        },
        {
          path: '/ta/statistics/expost',
          name: t('menu_admin_6_11'),
        },
        { path: '/ta/statistics/country', name: t('menu_admin_6_8.1') },
        { path: '/ta/statistics/country-funds', name: t('menu_admin_6_8.2') },
        { path: '/ta/statistics/results', name: t('menu_admin_6_7b') },
      ],
    },
  ]

  const consortiumNavbar = [
    {
      name: t('menu_admin_2'),
      path: '/consortium/concept-notes',
      dropdown: false,
    },
    { name: t('menu_admin_3'), path: '/consortium/proposals', dropdown: false },
    {
      name: t('menu_admin_4'),
      path: '/consortium/management',
      dropdown: true,
      options: [
        {
          path: '/consortium/management/contract-management',
          name: t('menu_admin_9'),
        },
        {
          path: '/consortium/management/invoice-management',
          name: t('menu_admin_21'),
        },
        {
          path: '/consortium/management/implementation-forms',
          name: t('menu_admin_10'),
        },
        {
          path: '/consortium/management/expost-reporting',
          name: t('menu_admin_14'),
        },
      ],
    },
    {name: t('menu_admin_22'), path: "/consortium/funds", dropdown: false },
    { name: t('menu_admin_5'), path: '/consortium/contacts' },
    { name: t('menu_admin_19'), path: '/consortium/documents' },
    {
      name: t('menu_admin_6'),
      path: '/consortium/statistics',
      dropdown: true,
      options: [
        {
          path: '/consortium/statistics/preformulation',
          name: t('menu_admin_6_1_1'),
        },
        {
          path: '/consortium/statistics/formulation',
          name: t('menu_admin_6_1_2'),
        },
        { path: '/consortium/statistics/execution', name: t('menu_admin_6_2') },
        {
          path: '/consortium/statistics/entities',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_3')}</>,
        },
        {
          path: '/consortium/statistics/partnerships',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_4')}</>,
        },
        /*  {
          path: '/consortium/statistics/initiatives',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_5')} </>,
        },
    */
        {
          path: '/consortium/statistics/activities',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_6')}</>,
        },
        {
          path: '/consortium/statistics/direct-beneficiaries',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_9')}</>,
        },
        {
          path: '/consortium/statistics/agenda2030',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_10')} </>,
        },
        {
          path: '/consortium/statistics/expost',
          name: t('menu_admin_6_11'),
        },
        { path: '/consortium/statistics/country', name: t('menu_admin_6_8.1') },
        { path: '/consortium/statistics/country-funds', name: t('menu_admin_6_8.2') },
        { path: '/consortium/statistics/results', name: t('menu_admin_6_7b') },
      ],
    },
    //  {name: t('menu_admin_7'), path: "/consortium/events"},
  ]

  const eudNavbar = [
    { name: t('menu_admin_2'), path: '/eud/concept-notes', dropdown: false },
    { name: t('menu_admin_3'), path: '/eud/proposals', dropdown: false },
    {
      name: t('menu_admin_4'),
      path: '/eud/management',
      dropdown: true,
      options: [
        { path: '/eud/management/final-assessments', name: t('menu_admin_11') },
        { path: '/eud/management/expost-reporting', name: t('menu_admin_15') },
      ],
    },
    { name: t('menu_admin_19'), path: '/eud/documents' },
    {
      name: t('menu_admin_6'),
      path: '/eud/statistics',
      dropdown: true,
      options: [
        { path: '/eud/statistics/preformulation', name: t('menu_admin_6_1_1') },
        { path: '/eud/statistics/formulation', name: t('menu_admin_6_1_2') },
        { path: '/eud/statistics/execution', name: t('menu_admin_6_2') },
        {
          path: '/eud/statistics/entities',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_3')}</>,
        },
        {
          path: '/eud/statistics/partnerships',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_4')}</>,
        },
        {
          path: '/eud/statistics/activities',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_6')}</>,
        },
        {
          path: '/eud/statistics/direct-beneficiaries',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_9')}</>,
        },
        {
          path: '/eud/statistics/agenda2030',
          name: <>&nbsp;&nbsp;{t('menu_admin_6_10')} </>,
        },
        {
          path: '/eud/statistics/expost',
          name: t('menu_admin_6_11'),
        },
        { path: '/eud/statistics/country', name: t('menu_admin_6_8.1') },
        { path: '/eud/statistics/country-funds', name: t('menu_admin_6_8.2') },
        { path: '/eud/statistics/results', name: t('menu_admin_6_7b') },
      ],
    },
    //  {name: t('menu_admin_7'), path: "/eud/events"},
  ]

  const arrayByRole = {
    [roleNamesConst.INTPA]: intpaNavbar,
    [roleNamesConst.EUD]: eudNavbar,
    [roleNamesConst.ATKE]: taNavbar,
    [roleNamesConst.ATNKE]: taNavbar,
    [roleNamesConst.CONSORTIUM]: consortiumNavbar,
    [roleNamesConst.PUBLIC]: publicNavbar,
  }

  const renderItems = (navbarArray) => {
    return navbarArray.map((page) => {
      if (page.notVisible) return null;
      if (!page.dropdown)
        return (
          <Link key={page.path} to={page.path}>
            {page.name}
          </Link>
        )
      else {
        return (
          <Dropdown title={page.name} id="nav-dropdown">
            {page.options.map((option) => (
              <DropdownItem>
                <DropdownLink key={option.path} to={option.path}>
                  {option.name}
                </DropdownLink>
              </DropdownItem>
            ))}
          </Dropdown>
        )
      }
    })
  }

  async function fetchCommunityAccess() {
    try {
      const result = await canAccessCommunity();
      console.log(result)
      setCanSeeComunityLink(!!result?.canAccess)
    }
    catch(e){
      console.log(e);
    }
  }

  useEffect(() => {
    fetchCommunityAccess();
  }, []);

  const communityPath = useMemo(() => {
    return `${authStore.getBaseRouteByRole()}/community`
  }, [])
  
  return (
    <Container>
      <Nav className="mr-auto" style={{ alignItems: 'center' }}>
        {authStore.roles[0] !== roleNamesConst.PUBLIC && (
          <Dropdown title={t('menu_admin_1')} id="basic-nav-dropdown">
            {/* <DropdownItem href={`${authStore.getBaseRouteByRole()}/dashboard`}>
              {t('Dashboard_Adelante2')}
            </DropdownItem> */}
            <DropdownItem>
              <DropdownLink key={`${authStore.getBaseRouteByRole()}/dashboard-executed-budget`} to={`${authStore.getBaseRouteByRole()}/dashboard-executed-budget`}>
                {t('Dashboard_Execution')}
              </DropdownLink>
            </DropdownItem>
            <DropdownItem>
              <DropdownLink key={`${authStore.getBaseRouteByRole()}/dashboard-initiatives`} to={`${authStore.getBaseRouteByRole()}/dashboard-initiatives`}>
                {t('Dashboard_Initiatives')}
              </DropdownLink>
            </DropdownItem>
            <DropdownItem>
              <DropdownLink key={`${authStore.getBaseRouteByRole()}/dashboard-preformulation-stage`} to={`${authStore.getBaseRouteByRole()}/dashboard-preformulation-stage`}>
                {t('Dashboard_Preformulation_cn')}
              </DropdownLink>
            </DropdownItem>
          </Dropdown>
        )}
        {isLoggedIn &&
          authStore.roles &&
          renderItems(arrayByRole[authStore.roles[0]])
        }
        {canSeeComunityLink ? (
          <Link key="community" to={communityPath}>
            <div style={{ width: '120px'}}>
              <img
                src={t('community_navbar_asset')}
                width="100%"
                alt="Adelante Community"
              />
            </div>
          </Link>
        ) : null}
      </Nav>
    </Container>
  )
}

export default observer(Navbar)
