import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledS7SubsectionTitle } from '../../CommonStyles';
import { SeparatorDotted } from 'components/CommonStyled';
import FakeAccordionItem from '../../../FakeAccordionItem';
import ConsultantForm from 'components/Forms/Form4/Section7/ConsultantsForm/ConsultantForm';

const Consultants = ({ activity }) => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledS7SubsectionTitle> {t('F3_S7_Subtitle_Consultants')} </StyledS7SubsectionTitle>
      {(activity && activity.profileList && activity.profileList.map((consultant, key) => (
        <React.Fragment key={key}>
          <FakeAccordionItem title={t('F3_S7_Accordion_consultant')+" "+(key+1)} noMargin>
            <ConsultantForm data={consultant} /> 
          </FakeAccordionItem>
          <SeparatorDotted />
        </React.Fragment>
      )))}
    </div>
  )
}

export default Consultants;