// @ts-nocheck
import i18n from 'i18n';

const renderEntitiesAsString = (entities) => {
  let resultString = '';
  entities?.forEach((item) => {
    resultString = resultString.concat(`${item?.name} - ${item?.countryName}`);
    if (item?.typeId && item?.entityRole) {
      resultString = resultString.concat(` (${i18n.t(`entity_type_${item?.typeId}`)} - ${i18n.t(`coordRole${item?.entityRole}`)})`);
    }
    resultString = resultString.concat(', ');
  });
  return resultString;
};

export const getMappedCommunityValues = (filteredData, currentLang) => {
	const values = [];
	filteredData?.forEach((item) => {
		const title = item.instrument === 'adelante1' && currentLang === 'es' && item.title_ES ? item.title_ES : item.title;
		const objective = item.instrument === 'adelante1' && currentLang === 'es' && item.objective_ES ? item.objective_ES : item.objective;
		const link = item.instrument === 'adelante1' && currentLang === 'es' && item.websiteEs ? item.websiteEs : item.website;
		const websiteLink = link?.includes('http') ? link: `https://${link}`;

		values.push({
			label: title,
			col1: renderEntitiesAsString(item.entities),
			col2: item.instrument || ' - ',
			col3: objective,
			col4: item.primaryGoalId ? i18n.t(`SDG_Goal${item.primaryGoalId}`) : ' - ',
			col5: item.secondaryGoalId ? i18n.t(`SDG_Goal${item.secondaryGoalId}`) : ' - ',
			col6: websiteLink,
		})
	})
	return values;
};