import React from 'react';
import { useTranslation } from 'react-i18next';

import TextValue from 'components/TextValue';
import Form from 'react-bootstrap/Form';
  
const ConsultantForm = ({ data }) => {
  const {t} = useTranslation();

  return (
    <div style={{marginLeft: '30px'}}>
      <Form.Group>
        <Form.Label xs={4}>{t('F3_S7_Consultant_qualifications')}:</Form.Label>
        <TextValue value={data.skills} />
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>{t('F3_S7_Consultant_general_experience')}:</Form.Label>
        <TextValue value={data.generalExp} />
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>{t('F3_S7_Consultant_specific_experience')}:</Form.Label>
        <TextValue value={data.specificExp} />
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>{t('F3_S7_Consultant_requires_translation')}:</Form.Label>
        <TextValue value={data.interpretation} />
      </Form.Group>
    </div>
  )

}

export default ConsultantForm;
