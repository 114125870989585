import styled, { css } from 'styled-components'

export const StyledSubtitle = styled.div`
  ${({ theme }) => css`
    margin-top: 5px;
    margin-bottom: 10px;
    padding-left: 15px;
    color: ${theme.brandColors.blue};
    letter-spacing: 2px;
    font-weight: 650;
  `}
`;

export const StyledRequiredSpan = styled.span`
  color: red;
`;

export const StyledWarningP = styled.p`
  color: #FF6565;
  margin-top: 2px;
  font-size: .8em;
`;

export const StyledIntroParagraph = styled.p`
  line-height: 1.3rem;
  padding-left: 50px;
  text-align: justify;
`;

export const StyledNoticeParagraph = styled.p`
  font-size: .8em;
  line-height: 1.3rem;
  margin-bottom: 0px;
  text-align: justify;
  color: red;
`;

export const StyledBudgetDiv = styled.div`
  ${({ theme }) => css`
    margin-top: 40px;
    padding: 50px;
    border-radius: 9px;
    border: 2px solid ${theme.brandColors.blue};
  `}
`;