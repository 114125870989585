import React from 'react';
import TextValue from 'components/TextValue';
import { StyledTitle, StyledLabel, StyledQuestions } from '../ExpostReportForm.styles';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

const ApplicantF8a = ({formData}) => {
    const { t } = useTranslation();
    return ( <>

        <StyledTitle>{t('F8b_title_1')}:</StyledTitle>

        <StyledQuestions>{t('F8a_question_1')}:</StyledQuestions>

        <Form.Group>
           <StyledLabel>{t('F8a_question_1.1')}:</StyledLabel>
           <TextValue value={formData.expect1} />
       </Form.Group>

       <Form.Group>
         <StyledLabel>{t('F8a_question_1.2')}:</StyledLabel>
           <TextValue value={formData.expect2} />
       </Form.Group>

       <Form.Group>
         <StyledLabel>{t('F8a_question_1.3')}:</StyledLabel>
           <TextValue value={formData.expect3} />
       </Form.Group>

       <StyledQuestions>{t('F8a_question_2')}:</StyledQuestions>

        <Form.Group>
           <StyledLabel>{t('F8a_question_2.1')}:</StyledLabel>
           <TextValue value={formData.expect4} />
       </Form.Group>

       <Form.Group>
         <StyledLabel>{t('F8a_question_2.2')}:</StyledLabel>
           <TextValue value={formData.expect5} />
       </Form.Group>

       <Form.Group>
         <StyledLabel>{t('F8a_question_2.3')}:</StyledLabel>
           <TextValue value={formData.expect6} />
       </Form.Group>

       <Form.Group>
          <StyledQuestions>{t('F8a_question_2.5')}:</StyledQuestions>
          <TextValue value={formData.done} />
       </Form.Group>

       <Form.Group>
          <StyledQuestions>{t('F8a_question_2.6')}:</StyledQuestions>
          <TextValue value={formData.notDone} />
       </Form.Group>

       <Form.Group>
         <StyledQuestions>{t('F8a_question_3')}:</StyledQuestions>
           <TextValue value={formData.conclusion1} />
       </Form.Group>

       <Form.Group>
         <StyledQuestions>{t('F8a_question_4')}:</StyledQuestions>
           <TextValue value={formData.conclusion2} />
       </Form.Group>

       <Form.Group>
         <StyledQuestions>{t('F8a_question_5')}:</StyledQuestions>
           <TextValue value={formData.conclusion3} />
       </Form.Group>

       <Form.Group>
         <StyledQuestions>{t('F8a_question_6')}:</StyledQuestions>
           <TextValue value={formData.conclusion4} />
       </Form.Group>

       <Form.Group>
         <StyledQuestions>{t('F8a_question_7')}:</StyledQuestions>
           <TextValue value={formData.conclusion5} />
       </Form.Group>

       <Form.Group>
         <StyledQuestions>{t('F8a_question_8')}:</StyledQuestions>
           <TextValue value={formData.conclusion6} />
       </Form.Group>

       <Form.Group>
         <StyledQuestions>{t('F8a_question_9')}:</StyledQuestions>
           <TextValue value={formData.conclusion7} />
       </Form.Group>

    </>)
}

export default ApplicantF8a;