import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { formInitialValues } from './Section5.initialValues';
import { useTranslation } from 'react-i18next';
import TextValue from 'components/TextValue';
import Comments from 'components/Forms/Form4/Comments';
import htmlParser from 'html-react-parser';

import Form from 'react-bootstrap/Form';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';

import {
  StyledFormLabel,
  StyledSeparator,
  StyledDivWrapper,
  DescripcionDiv
} from './Section5.styled';

const Section5 = () => {

  const { t } = useTranslation();
  const { formStore } = useRootStore();
  const [formValues, setFormValues] = useState(formInitialValues);
  const [nonEditable, setNonEditable] = useState(false);
  const [saved, setSaved] = useState(false);
  
  const handleSaved = (value) => {
    setSaved(value);
  }

  const handleButton = (value) => {
    setNonEditable(value);
  }
 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = [...formStore.formSectionData];
      setFormValues(formSectionData[0]);
      formStore.setCurrentSection(5);
    }
  }, [formStore.formSectionData])


  return (
    <FormLayout>
      <FormContainer 
        number={5} 
        title={t('F1_S5_title')}
        formId="form4section5"
        showSavedMessage= {saved}
        showButtonClarif= {!nonEditable}
        >
        <StyledDivWrapper>  

            <DescripcionDiv>
                <Form.Group>
                <StyledFormLabel>{htmlParser(t('F3_S5_Description_from_CN'))}:</StyledFormLabel>
                    <TextValue value={formValues.overview} />
                </Form.Group> 
            </DescripcionDiv>   

            <Form.Group>
            <StyledFormLabel>{t('F3_S5_Question_1')}:</StyledFormLabel>  
                <TextValue value={formValues.relations1} />      
            </Form.Group>  
                            
            <Form.Group>
            <StyledFormLabel>{t('F3_S5_Question_2')}:</StyledFormLabel>
                   <TextValue value={formValues.relations2} />
            </Form.Group>  

            <Form.Group>
            <StyledFormLabel>{t('F3_S5_Question_3')}:</StyledFormLabel>
                <TextValue value={formValues.relations3} />
            </Form.Group>  
                            
            <Form.Group >
                <StyledFormLabel>{t('F3_S5_Question_4')}:</StyledFormLabel>
                    <TextValue value={formValues.relations4} />
            </Form.Group>  

        </StyledDivWrapper>

        <StyledSeparator />

        <Comments onSaved={handleSaved}
                  onButton={handleButton} />   
                        
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section5);


