export const TW_LINK = 'https://twitter.com/ADELANTE_UE_ALC';
export const FB_LINK = 'https://www.facebook.com/ADELANTE.UE.ALC';
export const LK_LINK = 'https://www.linkedin.com/company/adelante-ue-alc/';
export const AD_LINK = 'https://adelante2.eu';
export const PP_EN_LINK = 'https://www.adelante2.eu/docs-pdf/privacy-policy.html';
export const PP_ES_LINK = 'https://www.adelante2.eu/docs-pdf/politica-privacidad.html';
export const LN_LINK = 'https://www.adelante2.eu/docs-pdf/legal-notice.html';
export const AL_LINK = 'https://www.adelante2.eu/docs-pdf/aviso-legal.html';
export const CP_LINK = 'https://www.adelante2.eu/docs-pdf/cookie-policy.html';
export const PC_LINK = 'https://www.adelante2.eu/docs-pdf/politica-de-cookies.html';
export const D_ES_LINK = 'https://www.adelante2.eu/docs-pdf/documentos.html';
export const D_EN_LINK = 'https://www.adelante2.eu/docs-pdf/documents.html';  

