import * as Yup from 'yup'
import i18n from 'i18n'

const yesterday = new Date(Date.now() - 86400000)

export const validationSchema = Yup.object().shape({
  fromDate: Yup.date()
    .min(yesterday, 'F1_S7_validation3')
    .typeError('F1_S7_validation9'),
  toDate: Yup.date()
    .min(Yup.ref('fromDate'), 'F1_S7_validation4')
    .typeError('F1_S7_validation9'),
  description: Yup.string()
    .min(10, 'F1_S7_validation6')
    .max(1300, 'F1_S7_validation7')
    .required('F1_S7_validation8'),
  format: Yup.string()
    .oneOf(['0', '1', '2'])
    .required('F1_S7_validation20'),
  countryId: Yup.string().when(['format'], {
    is: (format) => format === '0' || format === '1',
    then: Yup.string().required('F1_S7_validation12'),
    otherwise: Yup.string().nullable(true),
  }),
  cityName: Yup.string().when(['format'], {
    is: (format) => format === '0' || format === '1',
    then: Yup.string()
      .min(3, 'F1_S2_validation11')
      .max(100, 'F1_S2_validation12')
      .required('F1_S7_validation13'),
    otherwise: Yup.string().nullable(true),
  }),
  stateName: Yup.string()
    .min(3, 'F1_S2_validation13')
    .max(100, 'F1_S2_validation14'),
  countriesAdditional: Yup.string()
    .max(300, 'F1_S7_validation14')
    .nullable(true),
  duration: Yup.number()
    .min(0, 'F1_S7_validation10')
    .required('F1_S7_validation11'),
  participants: Yup.number()
    .min(0, 'F1_S7_validation10')
    .required('F1_S7_validation11'),
  experts: Yup.number()
    .min(0, 'F1_S7_validation10')
    .required('F1_S7_validation11'),
  daysRemote: Yup.number()
    .min(0, 'F1_S7_validation10')
    .required('F1_S7_validation11'),
  daysOnsite: Yup.number()
    .min(0, 'F1_S7_validation10')
    .required('F1_S7_validation11'),
  workLang1: Yup.boolean(),
  workLang2: Yup.boolean(),
  workLang3: Yup.boolean(),
  workLang4: Yup.boolean(),
  generalAlignment: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300)
    .nullable(true),
  generalParticipationMembers: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true),
  learning: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true),
  results: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true),
  generalProposalCrossIssues: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true),
  descSchedule: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true),
  trainer: Yup.boolean(),
})
