import React from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { Th2, Th3, Table } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'
import { renderRows } from '../helpers/helpers'
import { renderHeaders } from 'pages/statistics/CommonHelpers/helpers'
import { TARGET_YEARS } from 'constants/appConstants'
import i18n from 'i18n';

const Table2 = ({ data, waiting, title = i18n.t('menu_admin_2') }) => {
  const { t } = useTranslation()

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data && !isEmpty(data) ? (
    <Table>
      <colgroup>
        <col span="5" />
        <col span="5" style={{ backgroundColor: '#a6b3bb' }} />
      </colgroup>
      <tr>
        <Th3 style={{ width: '50%' }}>{title}</Th3>
        {renderHeaders(TARGET_YEARS)}
      </tr>
      <tr>
        <Th2 scope="row">{t('Submitted')}</Th2>
        {renderRows(data.submitted, TARGET_YEARS.length)}
      </tr>
      <tr>
        <Th2 scope="row">{t('Pending_review')}</Th2>
        {renderRows(data.pendingReview, TARGET_YEARS.length)}
      </tr>
      <tr>
        <Th2 scope="row">{t('Rejected_first_filter')}</Th2>
        {renderRows(data.rejected, TARGET_YEARS.length)}
      </tr>
      <tr>
        <Th2 scope="row">{t('Not_rejected_first_filter')}</Th2>
        {renderRows(data.notRejected, TARGET_YEARS.length)}
      </tr>
      <tr>
        <Th2 scope="row">{t('Request_for_clarifications')}</Th2>
        {renderRows(data.requestsForClarifications, TARGET_YEARS.length)}
      </tr>
      <tr>
        <Th2 scope="row">{t('Pending_review2')}</Th2>
        {renderRows(data.pendingReview2, TARGET_YEARS.length)}
      </tr>
      <tr>
        <Th2 scope="row">{t('Not_acepted')}</Th2>
        {renderRows(data.notAccepted, TARGET_YEARS.length)}
      </tr>
      <tr>
        <Th2 scope="row">{t('Acepted')}</Th2>
        {renderRows(data.accepted, TARGET_YEARS.length)}
      </tr>
    </Table>
  ) : (
    t('No_data_available_2')
  )
}

export default Table2
