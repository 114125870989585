import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  profileList: Yup.array()
  .of(
    Yup.object().shape({
      skills: Yup.string()
        .min(2, 'F3_S7_validation4')
        .max(650, 'F3_S7_validation5'),
      generalExp: Yup.string()
        .min(2, 'F3_S7_validation4')
        .max(650, 'F3_S7_validation5'),
      specificExp: Yup.string()
        .min(2, 'F3_S7_validation4')
        .max(650, 'F3_S7_validation5'),
      interpretation: Yup.string()
        .min(2, 'F3_S7_validation4')
        .max(650, 'F3_S7_validation5'),
    })
  )
  .required('F3_S7_validation6')
  .min(1, 'F3_S7_validation7')
});
