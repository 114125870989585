import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { validationSchema } from './Section12.validations'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { saveFormSection } from 'services/initiative'
import Form from 'react-bootstrap/Form'
import { useTranslation } from 'react-i18next'
import { formInitialValues } from './Section12.initialValues'
import htmlParser from 'html-react-parser'

import TextValue from 'components/TextValue'
import { RequiredMark } from 'components/CommonStyled'
import { f3statusNamesConst, roleNamesConst } from 'constants/appConstants'
import { useRootStore } from 'stores'
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer'
import {
  StyledFormLabel,
  StyledSubtitle,
  Text,
  StyledContainer,
} from './Section12.styled'
import { isWindows2021 } from 'helpers/isWindow2021Helper'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const Section12 = () => {
  const { t } = useTranslation()
  const { id, formId, sectionId } = useParams()
  const { formStore, initiativeStore } = useRootStore()
  const [saved, setSaved] = useState(false)
  const [nonEditable, setNonEditable] = useState(false)
  const [formValues, setFormValues] = useState(formInitialValues)
  const [window2021, setWindow2021] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f3statusNamesConst.DRAFT ||
        initiativeStore.role === roleNamesConst.GUEST,
    )
    if (initiativeStore.code) {
      setWindow2021(isWindows2021(initiativeStore.code))
    }
  }, [initiativeStore.formStateName, initiativeStore.code])

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {
        ...formInitialValues,
        valueTC: formStore.formSectionData[1],
        valueEU: formStore.formSectionData[2],
        valueTP: formStore.formSectionData[3],
        ...formStore.formSectionData[0],
      }
      for (const key in formSectionData) {
        if (!formSectionData[key]) {
          formSectionData[key] = ''
        }
      }
      setFormValues(formSectionData)
      console.log(formSectionData)
    }
  }, [formStore.formSectionData])

  useEffect(() => {
    if (saved) window.scrollTo(0, 0)
  }, [saved])

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values)
      formStore.setSectionStatus(sectionId, complete)
      resetForm({ values })
      setSaved(true)
    } catch (error) {
      console.log(error)
    }
  }

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault()
    isValid ? handleSubmit(values, { resetForm }) : alert(t('not_saved_alert'))
  }

  return (
    <FormLayout>
      <FormContainer
        number={12}
        title={t('F3_S12_title')}
        formId="form3section12"
        isHelp={true}
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
      >
        <Formik
          enableReinitialize
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            resetForm,
            handleChange,
            handleBlur,
            dirty,
          }) => (
            <form
              onSubmit={(e) => {
                onValidateSubmit(e, isValid, values, { resetForm })
              }}
              id="form3section12"
            >
              {dirty && setSaved(false)}

              <StyledContainer>
                <Text>{htmlParser(t('F3_S12_Intro'))}</Text>

                <StyledSubtitle size="20px">
                  {htmlParser(t('F3_S12_Subtitle_TC'))}
                </StyledSubtitle>

                <Form.Group>
                  <StyledFormLabel>
                    {htmlParser(t('F3_S12_Justfication_TC'))}:
                  </StyledFormLabel>
                  <TextValue value={formValues.valueTC} />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel>
                    {htmlParser(t('F3_S12_Justfication_partnership'))}:
                  </StyledFormLabel>
                  <TextValue value={formValues.valueEU} />
                </Form.Group>
                {window2021 ? (
                  <>
                    <Text> {htmlParser(t('F3_S12_Subtitle_Added_TC'))} </Text>

                    <Form.Group>
                      <StyledFormLabel>
                        {htmlParser(t('F3_S12_Ownership'))}:
                        <RequiredMark>*</RequiredMark>
                      </StyledFormLabel>
                      <Form.Control
                        name="valueTC1"
                        as="textarea"
                        rows={3}
                        style={{ resize: 'none' }}
                        placeholder={t('Maximum_650')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.valueTC1}
                        disabled={nonEditable}
                        className={
                          touched.valueTC1 && errors.valueTC1 ? 'error' : null
                        }
                      />
                      <FormErrorMessage
                        touched={touched.valueTC1}
                        errors={errors.valueTC1}
                      />
                    </Form.Group>

                    <Form.Group>
                      <StyledFormLabel>
                        {htmlParser(t('F3_S12_Complementarity'))}:
                        <RequiredMark>*</RequiredMark>
                      </StyledFormLabel>
                      <Form.Control
                        name="valueTC2"
                        as="textarea"
                        rows={3}
                        style={{ resize: 'none' }}
                        placeholder={t('Maximum_650')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.valueTC2}
                        disabled={nonEditable}
                        className={
                          touched.valueTC2 && errors.valueTC2 ? 'error' : null
                        }
                      />
                      <FormErrorMessage
                        touched={touched.valueTC2}
                        errors={errors.valueTC2}
                      />
                    </Form.Group>

                    <Form.Group>
                      <StyledFormLabel>
                        {htmlParser(t('F3_S12_Sharing_knowledge'))}:
                        <RequiredMark>*</RequiredMark>
                      </StyledFormLabel>
                      <Form.Control
                        name="valueTC3"
                        as="textarea"
                        rows={3}
                        style={{ resize: 'none' }}
                        placeholder={t('Maximum_650')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.valueTC3}
                        disabled={nonEditable}
                        className={
                          touched.valueTC3 && errors.valueTC3 ? 'error' : null
                        }
                      />
                      <FormErrorMessage
                        touched={touched.valueTC3}
                        errors={errors.valueTC3}
                      />
                    </Form.Group>

                    <Form.Group>
                      <StyledFormLabel>
                        {htmlParser(t('F3_S12_Joint_creation'))}:
                        <RequiredMark>*</RequiredMark>
                      </StyledFormLabel>
                      <Form.Control
                        name="valueTC4"
                        as="textarea"
                        rows={3}
                        style={{ resize: 'none' }}
                        placeholder={t('Maximum_650')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.valueTC4}
                        disabled={nonEditable}
                        className={
                          touched.valueTC4 && errors.valueTC4 ? 'error' : null
                        }
                      />
                      <FormErrorMessage
                        touched={touched.valueTC4}
                        errors={errors.valueTC4}
                      />
                    </Form.Group>

                    <Form.Group>
                      <StyledFormLabel>
                        {htmlParser(t('F3_S12_Increasing_volume'))}:
                        <RequiredMark>*</RequiredMark>
                      </StyledFormLabel>
                      <Form.Control
                        name="valueTC5"
                        as="textarea"
                        rows={3}
                        style={{ resize: 'none' }}
                        placeholder={t('Maximum_650')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.valueTC5}
                        disabled={nonEditable}
                        className={
                          touched.valueTC5 && errors.valueTC5 ? 'error' : null
                        }
                      />
                      <FormErrorMessage
                        touched={touched.valueTC5}
                        errors={errors.valueTC5}
                      />
                    </Form.Group>

                    <Form.Group>
                      <StyledFormLabel>
                        {htmlParser(t('F3_S12_Global_and_regional'))}:
                        <RequiredMark>*</RequiredMark>
                      </StyledFormLabel>
                      <Form.Control
                        name="valueTC6"
                        as="textarea"
                        rows={3}
                        style={{ resize: 'none' }}
                        placeholder={t('Maximum_650')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.valueTC6}
                        disabled={nonEditable}
                        className={
                          touched.valueTC6 && errors.valueTC6 ? 'error' : null
                        }
                      />
                      <FormErrorMessage
                        touched={touched.valueTC6}
                        errors={errors.valueTC6}
                      />
                    </Form.Group>
                  </>
                ) : null}

                <StyledSubtitle size="20px">
                  {htmlParser(t('F3_S12_Subtitle_Added_EU'))}
                </StyledSubtitle>

                <Form.Group>
                  <StyledFormLabel>
                    {htmlParser(t('F3_S12_EU_Added_value'))}:
                  </StyledFormLabel>
                  <TextValue value={formValues.valueTP} />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel>
                    {htmlParser(t('F3_S12_Added_EU_actors'))}:
                  </StyledFormLabel>
                  <Form.Control
                    name="valueEUActors"
                    as="textarea"
                    rows={3}
                    style={{ resize: 'none' }}
                    placeholder={t('Maximum_650')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.valueEUActors}
                    disabled={nonEditable}
                    className={
                      touched.valueEUActors && errors.valueEUActors
                        ? 'error'
                        : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.valueEUActors}
                    errors={errors.valueEUActors}
                  />
                </Form.Group>

                {window2021 ? (
                  <Form.Group>
                    <StyledFormLabel>
                      {htmlParser(t('F3_S12_From_comparison_modalities'))}:
                      <RequiredMark>*</RequiredMark>
                    </StyledFormLabel>
                    <Form.Control
                      name="valueEUModalities"
                      as="textarea"
                      rows={3}
                      style={{ resize: 'none' }}
                      placeholder={t('Maximum_650')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.valueEUModalities}
                      disabled={nonEditable}
                      className={
                        touched.valueEUModalities && errors.valueEUModalities
                          ? 'error'
                          : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.valueEUModalities}
                      errors={errors.valueEUModalities}
                    />
                  </Form.Group>
                ) : null}
              </StyledContainer>
            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section12)
