import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Formik, FieldArray } from 'formik';
import Accordion from 'react-bootstrap/Accordion';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import FormLayout from '../../FormLayout';
import { validationSchema } from './Section4.validations';
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from 'components/CustomAccordion';
import FormContainer from 'components/FormContainer';
import { PrimaryButton, ErrorButton } from 'components/Buttons';
import { SeparatorDotted } from 'components/CommonStyled';
import ModalConfirm from 'components/ModalConfirm';
import OtherEntitiesForm from './OtherEntitiesForm';
import { StyledIntroParagraph } from './Section4.styled';
import { saveFormSection } from 'services/initiative';
import { useConstantsForm1 } from 'customHooks/useConstantsForm1';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { f1statusNamesConst } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { arrayOrderHelper } from 'helpers/arrayOrderHelper';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const entityObject = {
  entityName: '',
  acronym: '',
  entityTypeId: '',
  function: '',
  countryId: '',
  web: '',
  firstName: '',
  lastName: '', 
  position: '',
  email: '', 
  phone: '',
  number: '',
};

const formInitialValues = {
  entities: [],
};

const Section4 = () => {
  const { t } = useTranslation();

  const { formStore, initiativeStore } = useRootStore();
  const { id, formId, sectionId } = useParams();
  const [clarification, setClarification] = useState({
    show: false,
    msg: '',
  });
  const { countries, entityTypes } = useConstantsForm1();
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const arr = [];
    if (formStore.formSectionData) {
      formStore.formSectionData.forEach(element => {
        arr.push({
          entityName: setStringFieldValue(element.entityName),
          acronym: setStringFieldValue(element.acronym),
          entityTypeId: element.entityType ? element.entityType.id : '',
          function: setStringFieldValue(element.function),
          countryId: element.country ? element.country.id : '',
          web: setStringFieldValue(element.web),
          firstName: setStringFieldValue(element.firstName),
          lastName: setStringFieldValue(element.lastName),
          position: setStringFieldValue(element.position),
          phone: setStringFieldValue(element.phone),
          email: setStringFieldValue(element.email),
          number: setStringFieldValue(element.number),
        });
      });
      setInitialValues({
        entities: arrayOrderHelper(arr),
      });
    } else {
      setInitialValues(formInitialValues);
    }
  }, [formStore.formSectionData]);

  useEffect(() => {
    if (initiativeStore.formStateName === f1statusNamesConst.DRAFT) {
      if (initiativeStore.formVersion === 2) {
        if (formStore.formSectionClarification) {
          setNonEditable(false);
          setClarification({
            show: true,
            msg: formStore.formSectionClarification
              ? formStore.formSectionClarification
              : t('No_clarifications'),
          });
        } else {
          setNonEditable(true);
        }
      } else {
        setNonEditable(false);
      }
    } else {
      setNonEditable(true);
    }
  }, [
    initiativeStore.currentFormStateName,
    initiativeStore.formVersion,
    formStore.formSectionClarification,
  ]);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  const openModalRemove = key => {
    setModalRemove(true);
    setSelectedKey(key);
  };

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  };

  const onSubmit = async (values, { resetForm }) => {
    try {
      for (let i = 0; i< values.entities.length; i++) {
        values.entities[i].number = `${i}`;
      }
      const response = await saveFormSection(id, formId, sectionId, [...values.entities]);
      let bool = true;
      if (response) {
        response.forEach(element => {
          bool = bool && element.complete;
        });
      }
      formStore.setSectionStatus(sectionId, bool || !response.length);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const setStringFieldValue = newValue => {
    return newValue ? newValue : '';
  };

  const onValidateSubmit = (e, isValid, values, resetForm) => {
    e.preventDefault();
    isValid ? onSubmit(values, { resetForm }) : alert(t('not_saved_alert'));
  };

  return (
    <FormLayout>
      <FormContainer
        number={4}
        title={t('F1_S4_title')}
        isHelp={true}
        formId="form1section4"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
      >
        <StyledIntroParagraph>{htmlParser(t('F1_S4_intro'))}</StyledIntroParagraph>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {props => (
            <form
              onSubmit={e => {
                onValidateSubmit(e, props.isValid, props.values, props.resetForm);
              }}
              id="form1section4"
            >
              {props.dirty && setSaved(false)}

              {clarification.show && (
                <Form.Group style={{ marginLeft: '50px', paddingTop: '10px' }} as={Row}>
                  <Form.Label>{t('Clarification_requests')}</Form.Label>
                  <Form.Control
                    as="textarea"
                    style={{ border: '2px solid red', resize: 'none' }}
                    rows={4}
                    disabled={true}
                    value={clarification.msg}
                  />
                </Form.Group>
              )}

              <FieldArray
                name="entities"
                render={arrayHelpers => (
                  <>
                    {modalRemove && (
                      <ModalConfirm
                        show={modalRemove}
                        confirmText={t('Button_remove')}
                        onConfirm={() => {
                          arrayHelpers.remove(selectedKey);
                          closeModalRemove();
                        }}
                        onClose={closeModalRemove}
                      >
                        <p>{t('Confirmation remove entity') + ' ' + (selectedKey + 1)}?</p>
                      </ModalConfirm>
                    )}
                    <Accordion>
                      {initialValues.entities
                        ? props.values.entities.map((entity, key) => (
                          <AccordionItem key={key + 1}>
                            <AccordionItemHeader>
                              <AccordionTitle eventKey={key + 1}>
                                {`${t('F1_S4_Accordion')} ${key+1} ${isEmpty(entity.entityName) ? '' : `- ${
                                  entity.entityName
                                }`}`}
                              </AccordionTitle>
                            </AccordionItemHeader>
                            <Accordion.Collapse eventKey={key + 1}>
                              <AccordionItemBody>
                                <OtherEntitiesForm
                                  formikInstance={props}
                                  entityIndex={key}
                                  entityTypes={entityTypes}
                                  countries={countries}
                                  nonEditable={nonEditable}
                                />
                                <ErrorButton
                                  onClick={() => openModalRemove(key)}
                                  disabled={nonEditable}
                                  style={{marginTop: '50px'}}
                                >
                                  {t('F1_S4_Button_remove_entity')}
                                </ErrorButton>
                              </AccordionItemBody>
                            </Accordion.Collapse>
                          </AccordionItem>
                        ))
                        : null}
                    </Accordion>
                    {typeof props.errors.entities === 'string' ? (
                      <FormErrorMessage
                        touched
                        errors={props.errors.entities}
                      />
                    ) : null}
                    <SeparatorDotted />
                    <PrimaryButton
                      onClick={() => arrayHelpers.push(entityObject)}
                      disabled={nonEditable}
                    >
                      {t('F1_S4_Button_add_entity')}
                    </PrimaryButton>
                  </>
                )}
              />
            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section4);
