import React from 'react';
import GeneralInfo from '../GeneralInfo';
import Products from '../Products';
import Consultants from '../Consultants';
import Participants from '../Participants';
import Resources from '../Resources';
import { Separator } from '../../CommonStyles';

const ActivityDetail = ({ data }) => {
  return (
    <div>
      <GeneralInfo activity={data} />

      <Separator />

      <Products activity={data} />

      <Separator />

      {(data.activityType && data.activityType.id == 4 ) && (
        <>
          <Consultants activity={data} />
          <Separator />
        </>
      )}

      

      {(data.activityType && data.activityType.id != 4 ) && (
        <>
          <Participants activity={data} />
          <Separator />
        </>
      )}
      
      <Resources activity={data} />    
    </div>
  )
};

export default ActivityDetail;
