import React from 'react'
import PageTitle from 'components/PageTitle'
import { PageHeader } from '../CommonStyled/Tabs.styled'
import { useTranslation } from 'react-i18next'
import StatisticsTabs from 'components/Tabs'
import EntitiesByCountrySummary from './tabs/EntitiesByCountrySummary'
import EntitiesByRoleSummary from './tabs/EntitiesByRoleSummary'
import EntitiesByFunctionSummary from './tabs/EntitiesByFunctionSummary'
import EntitiesByTypeSummary from './tabs/EntitiesByTypeSummary'
import EntitiesDetail from './tabs/EntitiesDetail'

const StatsEntities = () => {
  const { t } = useTranslation()
  const titles = [
    t('Statistics_entities_tab5'),
    t('Statistics_entities_tab1'),
    t('Statistics_entities_tab4'),
    t('Statistics_entities_tab2'),
    t('Statistics_entities_tab3'),
  ]

  const renderTab1Content = () => <EntitiesDetail />
  const renderTab2Content = () => <EntitiesByRoleSummary />
  const renderTab3Content = () => <EntitiesByCountrySummary />
  const renderTab4Content = () => <EntitiesByTypeSummary />
  const renderTab5Content = () => <EntitiesByFunctionSummary />
  

  const contents = [
    renderTab1Content,
    renderTab2Content,
    renderTab3Content,
    renderTab4Content,
    renderTab5Content,
  ]

  return (
    <>
      <PageHeader>
        <PageTitle title={t('menu_admin_6_3')} />
      </PageHeader>
      <StatisticsTabs titles={titles} contents={contents} />
    </>
  )
}

export default StatsEntities
