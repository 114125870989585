export const IsAssistanceCheckboxDisabled = (applicantNonEditable, isParticipantConfirmed, isAtke) => {
  return (applicantNonEditable || isParticipantConfirmed) && !isAtke;
}

export const mapParticipantsState = (values) => {
  const payload = [];
  values?.participantList?.forEach(participant => {
    const willAttend = computeWillAttend(participant.willAttendTrue, participant.willNotAttend);
    payload.push({
      id: participant.id,
      willAttend: willAttend,
      confirmed: true,
    });
  });
  return payload;
};

const computeWillAttend = (willAttendTrue, willAttendFalse) => {
  return willAttendTrue ? true : (willAttendFalse === false ? null : false);
};
