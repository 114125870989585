import React from 'react';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';

import Form from 'react-bootstrap/Form';
import TextValue from 'components/TextValue';
import { StyledFormLabel } from '../Section14.styled';


const MediaForm = ({ data }) => {

  const { t } = useTranslation();
  
  return (
    <>
      <Form.Group>
        <StyledFormLabel>{htmlParser(t('F1_S8_Media_type'))}:</StyledFormLabel>
        <TextValue value={data.mediaType? t(`media_type_${data.mediaType.id}`): ' - '} />
      </Form.Group>  

      <Form.Group>
        <StyledFormLabel>{htmlParser(t('F1_S8_Link_account'))}:</StyledFormLabel>
        <TextValue value={data.url} />
      </Form.Group>  

      <Form.Group>
        <StyledFormLabel>{htmlParser(t('F1_S8_Media_name'))}:</StyledFormLabel>
        <TextValue value={data.comments} />
      </Form.Group>  

      {/* See AD2-1656 */}
      <div style={{ display: 'none'}}>
        <Form.Group>
          <StyledFormLabel>{htmlParser(t('F3_S14_Number_users'))}:</StyledFormLabel>
          <TextValue value={data.quantity} />
        </Form.Group>  
      </div>
    </>
  )

}

export default MediaForm;
