import React from 'react';
import { useTranslation } from 'react-i18next';
import DayJS from 'dayjs';

import TextValue from 'components/TextValue';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


const ProductForm = ({ data }) => {

  const {t} = useTranslation();

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr);
    return date.format("DD/MM/YYYY");
  }
  
  return (
    <div style={{marginLeft: '30px'}}>
      <Form.Group as={Row}>
        <Form.Label column xs={2}>{t('F3_S7_Product_type')}:</Form.Label>
        <TextValue value={data.productType ? t(`product_type_${data.productType.id}`) : ' - '} />
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column xs={2}>{t('F3_S7_Product_title')}:</Form.Label>
        <TextValue value={data.name} />
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column xs={2}>{t('F1_S7_End_day')}:</Form.Label>
        <TextValue value={formatDate(data.dueDate)}/>
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>{t('F3_S7_Product_Description')}:</Form.Label>
        <TextValue value={data.description} />
      </Form.Group>
    </div>
  )

}

export default ProductForm;
