import React, {useState, useEffect} from 'react';
import { Formik, FieldArray } from 'formik';
import { validationSchema } from './Section15.validations';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { useParams } from 'react-router-dom';
import { formInitialValues } from './Section15.initialValues';
import { useTranslation } from 'react-i18next';
import { saveFormSection } from 'services/initiative';
import {f5statusNamesConst, roleNamesConst} from 'constants/appConstants';
import { useConstantsForm5 } from 'customHooks/useConstantsForm5';
import Comments from '../Comments';
import { SeparatorDotted, FormFielErrorMessage } from 'components/CommonStyled';
import { PrimaryButton, ErrorButton } from 'components/Buttons';
import ConfirmCloseModal from 'components/ConfirmCloseModal';
import LessonForm from './LessonForm';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion'
import {
  StyledDivWrapper,
} from './Section15.styled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const lessonObject = {
  lessonTypeId: '',
  lessonTitle: '',
  lessonMomentId: '',
  lessonEvent: '',
  lessonDecisions: '',
  lessonLearning: '',
  lessonImprovement: '',
  lessonUseForId: '',
  lessonCountryIdList: [],
  lessonActors: '',
  lessonKeywords: '',
};

const Section15 = () => {
  const { t } = useTranslation();
    const { id, formId, sectionId } = useParams();
  const { formStore, initiativeStore, authStore } = useRootStore();
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [nonEditable, setNonEditable] = useState(false);
  const [saved, setSaved] = useState(false);
  const [disabledComments, setDisabledComments] = useState(true);
  const currentRole = authStore.roles[0];
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM;
  const isAtke = currentRole === roleNamesConst.ATKE;
  const isIntpa = currentRole === roleNamesConst.INTPA;
  const isEud = currentRole === roleNamesConst.EUD;
  const [isF7, setIsF7] = useState(false);
  const [formattedCountries, setFormattedCountries] = useState([]);
  const { lessonTypes, la_ca_eu_countries, lessonMoments, lessonUseForList } = useConstantsForm5();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const array = [];
    la_ca_eu_countries.forEach(element => {
      array.push({
        value: element.id,
        label: t(element.name)
      })
    })
    setFormattedCountries(array);
  }, [la_ca_eu_countries])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);
  },[saved])

  const openModalRemove = (key) => {
    setModalRemove(true);
    setSelectedKey(key);
  }

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  }

  useEffect(() => {
    setNonEditable((initiativeStore.formStateName === f5statusNamesConst.FINALIZED) || (initiativeStore.formStateName === f5statusNamesConst.CANCELLED) || (initiativeStore.role !== roleNamesConst.APPLICANT))
  }, [initiativeStore.formStateName])

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = [...formStore.formSectionData];
      formSectionData.forEach(element => {
        element.lessonTypeId = element.lessonType? element.lessonType.id: '';
        element.lessonMomentId = element.lessonMoment? element.lessonMoment.id: '';
        element.lessonUseForId = element.lessonUseFor? element.lessonUseFor.id: '';
        const defaultCountryValues = [];
        if(element.lessonCountryList){
          element.lessonCountryList.forEach( a => {
            defaultCountryValues.push({
              value: a.id,
              label: t(a.name)
            })
          });
        }
        element.countryIdListDefaultValues = defaultCountryValues;
        element.lessonCountryIdList = defaultCountryValues.map(item => item.value);
      })
      setInitialValues({
        lessons: formSectionData
      });
    }
  }, [formStore.formSectionData])

  useEffect(() => {
    if(initiativeStore.formStateName)
      setIsF7(((isIntpa || isEud) && initiativeStore.formStateName !== f5statusNamesConst.DRAFT && initiativeStore.formStateName !== f5statusNamesConst.SENT ));
  }, [initiativeStore.formStateName])

  const handleSubmit = async (values, {resetForm} ) => {
    try {
      const response = await saveFormSection(id, formId, sectionId, values.lessons);
      formStore.setSectionStatus(sectionId, response.complete)
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaved = (value) => {
    setSaved(value);
  }

  const handleButton = (value) => {
    setDisabledComments(value);
  }

  return (
    <FormLayout>
      <FormContainer
        number={15}
        title={t('F5_S15_title')}
        formId="form5section15"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
        showButtonClarif={!disabledComments}
        >
        <StyledDivWrapper>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {( props ) => (
              <form onSubmit={props.handleSubmit} id="form5section15">
                {props.dirty && (setSaved(false))}

                  <FieldArray
                    name='lessons'
                    render={arrayHelpers => (
                      <>
                        {modalRemove && (
                          <ConfirmCloseModal
                            show={modalRemove}
                            text={`${t('Confirmation remove lesson')} ${selectedKey+1}?`}
                            handleActionOnConfirm={() => {
                              arrayHelpers.remove(selectedKey);
                              closeModalRemove();
                            }}
                            handleCloseConfirm={closeModalRemove}
                          />
                        )}
                        <Accordion style={{marginLeft: '-12px'}}>
                          {initialValues.lessons
                            ? ( props.values.lessons.map((lesson, key) => (
                                <AccordionItem key={key+1}>
                                  <AccordionItemHeader>
                                    <AccordionTitle eventKey={key+1}>{t('F5_S15_Accordion_lesson_learnt')+" "+(key+1)}</AccordionTitle>
                                  </AccordionItemHeader>
                                  <Accordion.Collapse eventKey={key+1}>
                                    <AccordionItemBody>
                                      <LessonForm
                                        formikInstance={props}
                                        lessonIndex={key}
                                        countries={formattedCountries}
                                        defaultValue={lesson.countryIdListDefaultValues}
                                        lessonTypes={lessonTypes}
                                        lessonMoments={lessonMoments}
                                        lessonUseForList={lessonUseForList}
                                        nonEditable={nonEditable}
                                      />
                                      <ErrorButton onClick={() => openModalRemove(key)} disabled={nonEditable}>{t('F5_S15_Lesson_button_delete')}</ErrorButton>
                                    </AccordionItemBody>
                                  </Accordion.Collapse>
                                </AccordionItem>
                              )))
                            : null}
                        </Accordion>
                        <FormErrorMessage
                          touched
                          errors={props.errors.lessons}
                        />

                        <SeparatorDotted />
                        <PrimaryButton onClick={() => arrayHelpers.push(lessonObject)} disabled={nonEditable}>{t('F5_S15_Lesson_button_add')}</PrimaryButton>
                      </>
                    )}
                  />


              </form>
              )}
          </Formik>

          {(isAtke || isConsortium || isF7 ) &&
             <Comments onSaved={handleSaved}
                       onButton={handleButton}/>
          }

        </StyledDivWrapper>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section15);


