import React, { useEffect, useState } from "react";
import { Formik, validateYupSchema, yupToFormErrors } from "formik";
import { formInitialValues } from "./BudgetLineModal.initialValues";
import { validationSchema } from "./BudgetLineModal.validations";
import {
  modifyActivityDetailF5S7,
  modifyActivityDetailF5S7AT,
  getActivityDetailF5S7,
  saveFile,
  getDownload,
  getEntities,
} from "services/initiative";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useRootStore } from "stores";
import { useTranslation } from "react-i18next";
import htmlParser from "html-react-parser";
import { getUploadFormatsConstants } from "services/apiConstants";
import {
  roleNamesConst,
  f5ActivityStatesConst,
  f5ActivityBudgetStatesConst,
} from "constants/appConstants";

import { StyledCustomLink } from "components/Forms/Forms.styled";
import ContractSteps from "./ContractSteps";
import TextValue from "components/TextValue";
import { PrimaryButton } from "components/Buttons";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import MoneyInput from "components/MoneyInput";
import Modal from "react-bootstrap/Modal";
import { StyledFormLabel } from "../Section7.styled";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ErrorButton, StyledSendButton } from "components/Buttons";
import { Form } from "react-bootstrap";
import {
  FormFielErrorMessage,
  RequiredMark,
  CenteredModalButtonContainer,
  SeparatorDotted,
} from "components/CommonStyled";
import {
  StyledModalBody,
  ModalTitle,
  StyledUploadButton,
  StyledCheck,
} from "./BudgetLineModal.styled";
import { formatDateDDMMYY } from "helpers/dateHelpers";

const BudgetLineModal = ({
  show,
  detail,
  handleClose,
  handleCloseOnSave,
  handleCloseOnError,
  activity,
  setModalInfo,
  setBudgetDetailAdminModalData,
  nonEditable,
}) => {
  const { authStore } = useRootStore();
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const [initialValues, setInitialValues] = useState(formInitialValues);

  const [validFileFormats, setValidFileFormats] = useState([]);
  const [entities, setEntities] = useState([]);

  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const role = authStore.roles[0];
  const euPays = detail && detail.ueOrCounterpart == 0;
  const isNonEditableByResourceType =
    detail &&
    detail.ueOrCounterpart == 0 &&
    detail.resourceType &&
    (detail.resourceType.id == 8 || detail.resourceType.id == 9);

  const isAtke = role === roleNamesConst.ATKE;
  const isConsortium = role === roleNamesConst.CONSORTIUM;
  const isManager = role !== roleNamesConst.PUBLIC;

  const isApplicantNonEditable = euPays && role === roleNamesConst.PUBLIC;
  const isManagerNonEditable =
    detail &&
    isManager &&
    ((!isAtke && !isConsortium) ||
      detail.resourceDetailState.stateEnum ===
        f5ActivityBudgetStatesConst.FINALIZED ||
      detail.resourceDetailState.stateEnum ===
        f5ActivityBudgetStatesConst.CANCELLED);
  const isApplicantNonUpdatable =
    isApplicantNonEditable &&
    (detail.resourceDetailState.stateEnum ===
      f5ActivityBudgetStatesConst.EXECUTION ||
      detail.resourceDetailState.stateEnum ===
        f5ActivityBudgetStatesConst.FINALIZED ||
      detail.resourceDetailState.stateEnum ===
        f5ActivityBudgetStatesConst.CANCELLED);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getActivityDetailF5S7(
          id,
          activity.id,
          detail.id
        );
        if (response && response.data) {
          let providerInfo = {};
          if (euPays && response.data.selectedProvider) {
            providerInfo = {
              provider:
                response.data.selectedProvider.mgmtF5S7ActivityDetailProvider
                  .mgmtF5S7Provider.name,
              totalRealMoney: response.data.selectedProvider.totalAmount,
              justification: response.data.selectedProvider.justification,
            };
          }
          setInitialValues({
            ...response.data,
            ...providerInfo,
          });
        }
        const entitiesResponse = await getEntities(id);
        setEntities(entitiesResponse);
      } catch (e) {
        console.log(e);
      }
    }
    if (detail !== null) {
      document.getElementById("EURadio").checked = detail.ueOrCounterpart == 0;
      document.getElementById("CPRadio").checked = detail.ueOrCounterpart == 1;
      setDisabled(
        detail.budgetConfirmed ||
          !(activity.activityState.name === f5ActivityStatesConst.CONFIRMED)
      );
      fetchAPI();
    } else setInitialValues(formInitialValues);
  }, [detail]);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getUploadFormatsConstants();
        if (response && response.F3S7) {
          setValidFileFormats(response.F3S7);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAPI();
  }, []);

  const onSave = async (values) => {
    try {
      if (isAtke)
        await modifyActivityDetailF5S7AT(id, activity.id, detail.id, values);
      else await modifyActivityDetailF5S7(id, activity.id, detail.id, values);
      handleCloseOnSave();
    } catch (error) {
      console.log(error);
      handleCloseOnError();
    }
  };

  const handleFileUpload = async (e, setFieldValue, handleChange) => {
    try {
      const file = document.getElementById("fileInput").files[0];
      const fileExtension = file.name.split(".").pop();
      if (file.size > 20971520) {
        setModalInfo({
          message: t("Error_file4"),
          type: "error",
          isOpen: true,
        });
      } else if (!validFileFormats.includes(fileExtension.toUpperCase())) {
        setModalInfo({
          message: t("Error_file5"),
          type: "error",
          isOpen: true,
        });
      } else {
        setIsUploadingFile(true);
        const uid = await saveFile(id, formId, sectionId, file);
        setFieldValue("quote.fileName", file.name);
        setFieldValue("quote.uuid", uid);
        handleChange(e);
      }
    } catch (error) {
      setModalInfo({
        message: t("Error_file2"),
        type: "error",
        isOpen: true,
      });
    } finally {
      setIsUploadingFile(false);
    }
  };

  const handleDownload = async (uuid, downloadName) => {
    try {
      const file = await getDownload(uuid).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadName);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      setModalInfo({
        message: t("Error_file1"),
        type: "error",
        isOpen: true,
      });
    }
  };

  const onValidateSubmit = (e, isValid, values) => {
    e.preventDefault();
    isValid ? onSave(values) : alert(t("not_saved_alert"));
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      backdrop="static"
      animation={false}
    >
      <StyledModalBody>
        <ModalTitle>{`${t("F3_S7_Budgetline_subtitle")} ${
          !(activity && activity.name)
            ? ""
            : `- ${t("F1_S7_Accordion")} ${activity.name}`
        }`}</ModalTitle>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={(value) => {
            try {
              validateYupSchema(value, validationSchema, true, value);
            } catch (err) {
              return yupToFormErrors(err);
            }
            return {};
          }}
          onSubmit={onSave}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            isValid,
            handleBlur,
            setFieldValue,
          }) => (
            <Form
              onSubmit={(e) => {
                onValidateSubmit(e, isValid, values);
              }}
            >
              <Form.Group as={Row}>
                <Form.Label column xs={2}>
                  {t("F3_S7_Product_type")}:
                </Form.Label>
                <TextValue
                  value={
                    detail && detail.resourceType && detail.resourceType.id
                      ? t(`resource_type_${detail.resourceType.id}`)
                      : "-"
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.Label style={{ textAlign: "justify", lineHeight: "1.3" }}>
                  {t("F3_S7_Product_Description")}:
                </Form.Label>
                <TextValue value={values.description} />
              </Form.Group>

              <Form.Group>
                <Row>
                  <StyledFormLabel>
                    {t("F3_S7_Budgetline_who_will_pay")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <label>
                    <input
                      disabled={true}
                      id="EURadio"
                      type="radio"
                      name="ueOrCounterpart"
                      value={"0"}
                      style={{ marginLeft: "30px" }}
                    />
                    <span style={{ marginLeft: "5px" }}>
                      {t("F3_S7_Budgetline_EU_pays")}
                    </span>
                  </label>
                  <label>
                    <input
                      disabled={true}
                      id="CPRadio"
                      type="radio"
                      name="ueOrCounterpart"
                      value={"1"}
                      style={{ marginLeft: "50px" }}
                    />
                    <span style={{ marginLeft: "5px" }}>
                      {t("F3_S7_Budgetline_EU_parnership")}
                    </span>
                  </label>
                </Row>
              </Form.Group>

              {detail.resourceType && detail.resourceType.id == 1 && (
                <Form.Group as={Row}>
                  <Form.Label column xs={2}>
                    {t("F3_S7_Budgetline_working_days")}:
                  </Form.Label>
                  <TextValue value={initialValues.workingDays} />
                </Form.Group>
              )}

              {detail.resourceType && detail.resourceType.id == 9 && (
                <Form.Group as={Row}>
                  <Form.Label column xs={2}>
                    {t("F3_S7_Budgetline_perdime_days")}:
                  </Form.Label>
                  <TextValue value={initialValues.perDiemDays} />
                </Form.Group>
              )}

              <Form.Group as={Row} style={{ alignItems: "center" }}>
                <Form.Label column md={1} xs={1}>
                  {t("F3_S7_Budgetline_estimated_cost")}:
                </Form.Label>
                <Col xs={1}>
                  <InputGroup style={{ width: "170px" }}>
                    <MoneyInput
                      name="totalMoney"
                      value={values.totalMoney}
                      customInput={Form.Control}
                      disabled={true}
                    />
                  </InputGroup>
                </Col>
              </Form.Group>

              {detail && detail.file && (
                <StyledCustomLink
                  onClick={() =>
                    handleDownload(detail.file.uuid, detail.file.fileName)
                  }
                >
                  {t("F5_S7_See_quote_proposal")}
                </StyledCustomLink>
              )}

              <SeparatorDotted />

              {isManager && euPays ? (
                <ContractSteps
                  activity={activity}
                  detail={detail}
                  isManagerNonEditable={isManagerNonEditable}
                  setBudgetDetailAdminModalData={setBudgetDetailAdminModalData}
                  handleCloseOnSave={handleCloseOnSave}
                  setModalInfo={setModalInfo}
                />
              ) : (
                <>
                  <Form.Group as={Row}>
                    <StyledFormLabel column xs={3}>
                      {t("F5_S7_Selected_provider")}
                      {!euPays && <RequiredMark>*</RequiredMark>}:
                    </StyledFormLabel>
                    <Col lg={8}>
                      <Form.Control
                        type="text"
                        name="provider"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.provider}
                        className={errors.provider ? "error" : null}
                        disabled={
                          nonEditable || disabled || isApplicantNonEditable
                        }
                      />
                      {errors.provider ? (
                        <FormFielErrorMessage>
                          {errors.provider}
                        </FormFielErrorMessage>
                      ) : null}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} style={{ alignItems: "center" }}>
                    <Form.Label column md={3} xs={3}>
                      {t("F5_S7_Total_amount")}
                      {!euPays && <RequiredMark>*</RequiredMark>}:
                    </Form.Label>
                    <Col xs={3}>
                      <InputGroup style={{ width: "170px" }}>
                        <MoneyInput
                          name="totalRealMoney"
                          value={values.totalRealMoney}
                          onChange={(val) =>
                            setFieldValue("totalRealMoney", val)
                          }
                          onBlur={handleBlur}
                          customInput={Form.Control}
                          className={errors.totalRealMoney ? "error" : null}
                          disabled={
                            nonEditable || disabled || isApplicantNonEditable
                          }
                        />
                      </InputGroup>
                      {errors.totalRealMoney ? (
                        <FormFielErrorMessage>
                          {errors.totalRealMoney}
                        </FormFielErrorMessage>
                      ) : null}
                    </Col>
                    {!euPays && (
                      <Col xs={6}>
                        {htmlParser(t("F3_S7_Budgetline_eur_conversion_link"))}
                      </Col>
                    )}
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column md={3} xs={3}>
                      {t("F5_S7_entity_money")}
                      {values.ueOrCounterpart == 1 && (<RequiredMark>*</RequiredMark>)}:
                    </Form.Label>
                    <Col xs={3}>
                      <Form.Control
                        name="entityContribution"
                        as="select"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.entityContribution}
                        className={
                          touched.entityContribution &&
                          errors.entityContribution
                            ? "error"
                            : null
                        }
                        disabled={nonEditable || disabled || isApplicantNonEditable}
                      >
                        <option value="">{t("Placeholder_select")}</option>
                        {entities.map((entityName) => (
                          <option key={entityName} value={entityName}>
                            {entityName}
                          </option>
                        ))}
                      </Form.Control>
                      {touched.entityContribution && errors.entityContribution ? (
                        <FormFielErrorMessage>
                          {errors.entityContribution}
                        </FormFielErrorMessage>
                      ) : null}
                    </Col>
                  </Form.Group>

                  {!euPays ? (
                    <>
                      <div
                        style={{
                          visibility: isUploadingFile ? "visible" : "hidden",
                        }}
                      >
                        <Spinner animation="border" />
                        <span> Uploading document...</span>
                      </div>

                      <div>
                        <StyledUploadButton
                          variant="link"
                          disabled={
                            nonEditable || disabled || isApplicantNonEditable
                          }
                          onClick={(e) => {
                            document.getElementById("fileInput").click();
                          }}
                        >
                          {t("F5_S7_Upload_supporting_document")} (
                          {validFileFormats.join(", ")})
                        </StyledUploadButton>

                        <br />

                        {values.quote && (
                          <StyledCustomLink
                            onClick={() =>
                              handleDownload(
                                values.quote.uuid,
                                values.quote.fileName
                              )
                            }
                          >
                            {t("F5_S7_See_quote_final")}
                          </StyledCustomLink>
                        )}

                        <Form.File
                          id="fileInput"
                          style={{ visibility: "hidden" }}
                          onChange={(e) => {
                            handleFileUpload(e, setFieldValue, handleChange);
                          }}
                          type="file"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {values.quote && (
                        <StyledCustomLink
                          onClick={() =>
                            handleDownload(
                              values.quote.uuid,
                              values.quote.fileName
                            )
                          }
                        >
                          {t("F5_S7_See_quote_final")}
                        </StyledCustomLink>
                      )}
                    </>
                  )}

                  <Form.Group>
                    <Form.Label
                      style={{
                        alignItems: "center",
                        textAlign: "justify",
                        lineHeight: "1.3",
                      }}
                    >
                      {t("F5_S7_Budget_justification")}
                      {!euPays && <RequiredMark>*</RequiredMark>}:
                    </Form.Label>
                    <Form.Control
                      name="justification"
                      as="textarea"
                      rows={3}
                      placeholder={
                        euPays && !values.justification
                          ? "-"
                          : "A maximum of 600 characters"
                      }
                      style={{ resize: "none" }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.justification}
                      className={errors.justification ? "error" : null}
                      disabled={
                        nonEditable ||
                        disabled ||
                        isNonEditableByResourceType ||
                        (euPays && !values.selectedProvider)
                      }
                    />
                    {errors.justification ? (
                      <FormFielErrorMessage>
                        {errors.justification}
                      </FormFielErrorMessage>
                    ) : null}
                  </Form.Group>

                  {isNonEditableByResourceType ? (
                    <StyledCheck style={{ marginTop: "20px" }}>
                      <Form.Group>
                        <Form.Check
                          disabled={true}
                          style={{ marginLeft: "15px", textAlign: "justify" }}
                          type="checkbox"
                          checked={true}
                          label={
                            euPays
                              ? t("F5_S7_Budget_confirmation_check_EU")
                              : t("F5_S7_Budget_confirmation_check_counterpart")
                          }
                        />
                      </Form.Group>
                    </StyledCheck>
                  ) : (
                    <StyledCheck style={{ marginTop: "20px" }}>
                      <Form.Group>
                        <Form.Check
                          disabled={
                            ((disabled ||
                              (euPays && !values.selectedProvider)) &&
                              !isAtke) ||
                            (isAtke && !euPays && !values.budgetConfirmed) ||
                            (isManager && !isAtke)
                          }
                          style={{ marginLeft: "15px", textAlign: "justify" }}
                          name="budgetConfirmed"
                          type="checkbox"
                          checked={values.budgetConfirmed}
                          value={values.budgetConfirmed}
                          onChange={handleChange}
                          label={
                            euPays
                              ? t("F5_S7_Budget_confirmation_check_EU")
                              : t("F5_S7_Budget_confirmation_check_counterpart")
                          }
                          className={errors.budgetConfirmed ? "error" : null}
                        />
                        {errors.budgetConfirmed ? (
                          <FormFielErrorMessage>
                            {errors.budgetConfirmed}
                          </FormFielErrorMessage>
                        ) : null}
                      </Form.Group>
                    </StyledCheck>
                  )}

                  {values.budgetTimestamp && (
                    <div style={{ marginTop: "10px" }}>
                      {`${t("F5_S7_Confirmed")}: ${
                        values.budgetTimestamp
                          ? formatDateDDMMYY(values.budgetTimestamp)
                          : ""
                      }`}
                    </div>
                  )}
                </>
              )}

              <div style={{ marginTop: "10px" }}>
                {`${t("F5_S7_status")}: ${
                  values.resourceDetailState
                    ? t(
                        `F5_S7_Budget_Status_${values.resourceDetailState.stateEnum}`
                      )
                    : ""
                }`}
              </div>

              <CenteredModalButtonContainer>
                {isApplicantNonUpdatable ||
                (isManager && euPays) ||
                (isAtke && !euPays && values.budgetConfirmed) ||
                activity.activityState.name === "CANCELLED" ||
                (isManager && !isAtke) ||
                isNonEditableByResourceType ? (
                  <PrimaryButton
                    onClick={() => {
                      handleCloseOnSave();
                    }}
                  >
                    OK
                  </PrimaryButton>
                ) : (
                  <>
                    <ErrorButton
                      style={{ marginRight: "20px" }}
                      onClick={handleClose}
                    >
                      {t("Button_cancel")}
                    </ErrorButton>
                    <StyledSendButton type="submit">
                      
                      {t("Button_save")}
                    </StyledSendButton>
                  </>
                )}
              </CenteredModalButtonContainer>
            </Form>
          )}
        </Formik>
      </StyledModalBody>
    </Modal>
  );
};

export default observer(BudgetLineModal);
