import React, { useEffect, useState, useRef } from 'react';
import { Formik, validateYupSchema, yupToFormErrors, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { formInitialValues } from './ParticipantsForm.initialValues';
import { validationSchema } from './ParticipantsForm.validations';
import {
  saveParticipantsF5S7,
  getParticipantsF5S7,
  saveF5S7ActivityParticipantsMgmt,
  changeParticipantState
} from 'services/initiative';
import { useRootStore } from 'stores';
import { roleNamesConst } from 'constants/appConstants';
import { useConstantsForm5 } from 'customHooks/useConstantsForm5';
import htmlParser from 'html-react-parser';
import ModalParticipantsList from './ModalParticipantsList';
import { handleLabel } from 'helpers/f5Helpers';
import { mapParticipantsState } from './ParticipantForm/ParticipantForm.helper';

import { PrimaryButton } from 'components/Buttons';
import ParticipantForm from './ParticipantForm';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import "react-datepicker/dist/react-datepicker.css";
import {ErrorButton, StyledSendButton, StyledButtonLink} from 'components/Buttons'
import Form from 'react-bootstrap/Form'
import { SeparatorDotted, CenteredModalButtonContainer } from 'components/CommonStyled';
import {
  StyledCheck
} from '../ParticipantsForm/ParticipantForm/ParticipantForm.styled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const participantObject = {
  firstName: "",
  lastName: "",
  nationalityId: "",
  city: "",
  email: "",
  phone: "",
  entityTypeId: "",
  organizationName: "",
  willAttend: null,
  willAttendTrue: false,
  willNotAttend: false,
  confirmed: false,
  mgmtTicketPurchased: false,
}

const ParticipantsForm = ({ handleCloseOk, handleClose, activity, handleCloseSubModalOnSave, handleCloseSubModalOnError, nonEditable, nonEditableMgm }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const formRef = useRef();
  const { authStore } = useRootStore();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [disabled, setDisabled] = useState(nonEditable);
  const role = authStore.roles[0];
  const isAtke = role === roleNamesConst.ATKE;
  const isConsortium = role === roleNamesConst.CONSORTIUM;
  const isPublic = role === roleNamesConst.PUBLIC;
  const { entityTypes, countries } = useConstantsForm5();
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    async function getValues() {
      let response = await getParticipantsF5S7(id, activity.id);
      const formValues = {
        participantList: [...response.data.participantList],
        mgmtParticipantListClosed: response.data.mgmtParticipantListClosed
      };
      formValues.participantList.forEach(participant => {
        const willAttend = participant.willAttend;
        participant.willAttendTrue = (willAttend !== null && willAttend === true);
        participant.willNotAttend = (willAttend !== null && willAttend === false);
        participant.nationalityId = participant.nationality? participant.nationality.id : "";
        participant.entityTypeId = participant.entityType? participant.entityType.id : "";
      });
      setInitialValues(formValues);
      setDisabled(nonEditable || response.data.mgmtParticipantListClosed);

      if(activity.activityState.name === 'CANCELLED'){
        setDisabled(true);
      }
    }
    getValues();

  }, []);

  const handleSubmit = async (values) => {
    try {
      let dataArray = [];
      if(isAtke || isConsortium){
         values.participantList.forEach(participant => {
          dataArray.push({id: participant.id, mgmtTicketPurchased: participant.mgmtTicketPurchased})
        })
        if (initialValues?.mgmtParticipantListClosed) {
          await saveF5S7ActivityParticipantsMgmt(id, activity.id, {participantList: dataArray, mgmtParticipantListClosed: false});
          await changeParticipantState(id, activity.id, mapParticipantsState(values))
          await saveF5S7ActivityParticipantsMgmt(id, activity.id, {participantList: dataArray, mgmtParticipantListClosed: values.mgmtParticipantListClosed});
        } else {
          await changeParticipantState(id, activity.id, mapParticipantsState(values))
          await saveF5S7ActivityParticipantsMgmt(id, activity.id, {participantList: dataArray, mgmtParticipantListClosed: values.mgmtParticipantListClosed});
        }
      }
      else{
        const valuesCopy = JSON.parse(JSON.stringify(values));
        valuesCopy.participantList.forEach(participant => {
          if((participant.willAttend === null) && (participant.willAttendTrue === true || participant.willNotAttend === true)){
            const willAttend = (participant.willAttendTrue === true && participant.willNotAttend === false);
            participant.willAttend = willAttend;
            participant.confirmed = true;
          }
          else{
            participant.willAttend = null;
            participant.confirmed = false;
          }

        })
        await saveParticipantsF5S7(id, activity.id, valuesCopy.participantList);
      }
      handleCloseSubModalOnSave();
    } catch (error) {
      console.log(error)
      handleCloseSubModalOnError();
    }
  };

  const onValidateSubmit = (e, isValid, values) => {
    e.preventDefault();
    isValid
      ? handleSubmit(values)
      : alert(t('not_saved_alert'));
  }



  return (
    <div>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        innerRef={formRef}
        validate={(value) => {
          try {
            validateYupSchema(value, validationSchema, true, value);
          } catch (err) {
            return yupToFormErrors(err);
          }
            return {};
        }}
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <Form
              onSubmit={(e) => { onValidateSubmit(e, props.isValid, props.values) }}
              id="participants"
            >
              {modalShow && <ModalParticipantsList
                  show={modalShow}
                  onHide={()=> {setModalShow(false)}}
                  participantsList={props.values.participantList}
              />}

              <FieldArray
                name='participantList'
                render={arrayHelpers => (
                  <>
                    <Accordion style={{marginLeft: "-20px"}}>
                      {props.values.participantList
                        ? ( props.values.participantList.map((participant, key) => (
                            <AccordionItem key={key+1}>
                              <AccordionItemHeader>
                                <AccordionTitle eventKey={key+1}>{handleLabel(participant)}</AccordionTitle>
                              </AccordionItemHeader>
                              <Accordion.Collapse eventKey={key+1}>
                                <AccordionItemBody>
                                  <ParticipantForm
                                    formikInstance={props}
                                    participantIndex={key}
                                    entityTypes={entityTypes}
                                    countries={countries}
                                    nonEditable={disabled}
                                    isAtke={isAtke}
                                    isConsortium={isConsortium}
                                    isPublic={isPublic}
                                    nonEditableMgm={nonEditableMgm}
                                    cancelled={activity.activityState.name === 'CANCELLED'}
                                  />
                                </AccordionItemBody>
                              </Accordion.Collapse>
                            </AccordionItem>
                          )))
                        : null}
                    </Accordion>
                    <FormErrorMessage
                      touched
                      errors={props.errors.participantList}
                    />

                    <SeparatorDotted />
                    {isPublic &&
                      <PrimaryButton disabled={disabled} onClick={() => arrayHelpers.push(participantObject)} >{t('F3_S7_Participant_button_add')}</PrimaryButton>
                    }
                  </>
                )}
              />

              <div style={{marginTop: "30px"}}/>
              {isPublic &&
                <span style={{color: "black"}}> {htmlParser(t('F3_S7_Participant_disclaimer'))} </span>
              }

              {!isPublic && <>

                <StyledButtonLink variant="link" onClick={() => {setModalShow(true)}}>
                  {t('F5_S7_Download_participant_list')}
                </StyledButtonLink>

                <StyledCheck style={{marginTop: '20px', paddingBottom: '17px'}}>
                  <Form.Check
                      name="mgmtParticipantListClosed"
                      type="checkbox"
                      value={props.values.mgmtParticipantListClosed}
                      checked={props.values.mgmtParticipantListClosed}
                      onChange={props.handleChange}
                      disabled={!(isAtke || isConsortium) ||  (nonEditableMgm && (isAtke || isConsortium) || (activity.activityState.name === 'CANCELLED'))}
                      label={htmlParser(t("F5_S7_Checkbox_list_close"))}
                    />
                </StyledCheck>

              </>}

              <CenteredModalButtonContainer>
                {
                  ((nonEditable && !(isAtke || isConsortium)) || (nonEditableMgm && !isPublic) || (disabled && isPublic) || (activity.activityState.name === 'CANCELLED'))
                  ?
                    <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
                  :
                    <>
                      <ErrorButton style={{marginRight: '20px'}} onClick={handleClose}> {t('Button_cancel')} </ErrorButton>
                      <StyledSendButton type="submit" form="participants"> {t('Button_save')} </StyledSendButton>
                    </>
                }
              </CenteredModalButtonContainer>

            </Form>
          )
        }}
      </Formik>
    </div>
  )
}


export default observer(ParticipantsForm);