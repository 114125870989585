import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledDivWrapper = styled.div`
  margin-left: 50px;
`;

export const StyledFormLabel = styled(Form.Label)`
  text-align: justify;
  line-height: 1.3;
`;

