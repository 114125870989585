import i18n from 'i18n'
import _orderBy from 'lodash/orderBy'
import { Th2, Td, Th1, Th3, Th4, CompleteImage } from '../../CommonStyled/Components.styled'
import React from 'react'
import { renderTotals } from 'pages/statistics/CommonHelpers/helpers'
import { EXPOST_CATEGORY_LABEL, TARGET_YEARS } from 'constants/appConstants'
import completeIcon from 'assets/complete-mark.png';

export const getSingleTableExportData = (tableData, tableName, hasExpired) => {
  // table data is an object
  const rows = mapColumnsToRows(tableData, TARGET_YEARS);
  // rows = { done: [1,0,0,0,1], ongoing: ...}
  let data = [];
  let data2 = [];
  data.push({
    label: tableName,
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
  })
  const keys = Object.keys(rows).reverse();

  keys.forEach((itemKey) => {
    if ((itemKey !== 'cancelled') && (itemKey !== 'expired' || hasExpired)) {
      data2.push({
        label: i18n.t(EXPOST_CATEGORY_LABEL[itemKey]),
        col1: rows[itemKey][0],
        col2: rows[itemKey][1],
        col3: rows[itemKey][2],
        col4: rows[itemKey][3],
        col5: rows[itemKey][0] + rows[itemKey][1] + rows[itemKey][2] + rows[itemKey][3],
      })
    }
  })

  data = [...data, ...data2]
  data.push({
    label: '',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
  })
  return data;
}

export const getExPostSummaryExportValues = (exportData) => {
  let data = [];
  const f8TableData = getSingleTableExportData(exportData.f8, i18n.t('F8_report_title'))
  const f8bTableData = getSingleTableExportData(exportData.f8b, i18n.t('F8b_report_title'))
  const f9aTableData = getSingleTableExportData(exportData.f9a, i18n.t('F9a_report_title'), true)
  const f9cTableData = getSingleTableExportData(exportData.f9c, i18n.t('F9c_report_title'))
  data = [...f8TableData, ...f8bTableData, ...f9aTableData, ...f9cTableData]
  return data
}

export const getExPostF8BSummaryExportValues = (exportData) => {
  const renderNumber = (number) => 
    String(number) === '0' || !number ? '' : String(number)

  let data = [
    {
      label: i18n.t('F8b_completed_expost'),
      col1: renderNumber(exportData?.[0]?.complete),
      col2: renderNumber(exportData?.[1]?.complete),
      col3: renderNumber(exportData?.[2]?.complete),
    },
    {
      label: i18n.t('F8b_assessment_4'),
      col1: renderNumber(exportData?.[0]?.assessment4),
      col2: renderNumber(exportData?.[1]?.assessment4),
      col3: renderNumber(exportData?.[2]?.assessment4),
    },
    {
      label: `${i18n.t('F8b_assessment_title_5')}`,
      col1: '',
      col2: '',
      col3: '',
    },
    {
      label: i18n.t('F8b_assessment_5'),
      col1: renderNumber(exportData?.[0]?.assessment5),
      col2: renderNumber(exportData?.[1]?.assessment5),
      col3: renderNumber(exportData?.[2]?.assessment5),
    },
    {
      label: i18n.t('F8b_assessment_6'),
      col1: renderNumber(exportData?.[0]?.assessment6),
      col2: renderNumber(exportData?.[1]?.assessment6),
      col3: renderNumber(exportData?.[2]?.assessment6),
    },
    {
      label: i18n.t('F8b_assessment_7'),
      col1: renderNumber(exportData?.[0]?.assessment7),
      col2: renderNumber(exportData?.[1]?.assessment7),
      col3: renderNumber(exportData?.[2]?.assessment7),
    },
    {
      label: i18n.t('F8b_assessment_8'),
      col1: renderNumber(exportData?.[0]?.assessment8),
      col2: renderNumber(exportData?.[1]?.assessment8),
      col3: renderNumber(exportData?.[2]?.assessment8),
    },
    {
      label: i18n.t('F8b_assessment_title_9'),
      col1: '',
      col2: '',
      col3: '',
    },
    {
      label: i18n.t('F8b_assessment_9'),
      col1: renderNumber(exportData?.[0]?.assessment9),
      col2: renderNumber(exportData?.[1]?.assessment9),
      col3: renderNumber(exportData?.[2]?.assessment9),
    },
    {
      label: i18n.t('F8b_assessment_10'),
      col1: renderNumber(exportData?.[0]?.assessment10),
      col2: renderNumber(exportData?.[1]?.assessment10),
      col3: renderNumber(exportData?.[2]?.assessment10),
    },
    {
      label: i18n.t('F8b_assessment_11'),
      col1: renderNumber(exportData?.[0]?.assessment11),
      col2: renderNumber(exportData?.[1]?.assessment11),
      col3: renderNumber(exportData?.[2]?.assessment11),
    },
    {
      label: `${i18n.t('F8b_assessment_title_12')}`,
      col1: '',
      col2: '',
      col3: '',
    },
    {
      label: i18n.t('F8b_assessment_12'),
      col1: renderNumber(exportData?.[0]?.assessment12),
      col2: renderNumber(exportData?.[1]?.assessment12),
      col3: renderNumber(exportData?.[2]?.assessment12),
    },
    {
      label: i18n.t('F8b_assessment_13'),
      col1: renderNumber(exportData?.[0]?.assessment13),
      col2: renderNumber(exportData?.[1]?.assessment13),
      col3: renderNumber(exportData?.[2]?.assessment13),
    },
    {
      label: i18n.t('F8b_assessment_14'),
      col1: renderNumber(exportData?.[0]?.assessment14),
      col2: renderNumber(exportData?.[1]?.assessment14),
      col3: renderNumber(exportData?.[2]?.assessment14),
    },
    {
      label: i18n.t('F8b_assessment_title_15'),
      col1: '',
      col2: '',
      col3: '',
    },
    {
      label: i18n.t('F8b_assessment_15'),
      col1: renderNumber(exportData?.[0]?.assessment15),
      col2: renderNumber(exportData?.[1]?.assessment15),
      col3: renderNumber(exportData?.[2]?.assessment15),
    },
  ];
  return data
}

export const renderRows = (row, length) => {
  if (row) {
    let aux = []
    for (let i = 0; i <= length - 1; i++)
      aux.push(<Td>{row[i] ? row[i] : row[i] == 0 ? 0 : '-'}</Td>)
    aux.push(renderTotals(row))
    return aux
  }
}

export const expostRenderRows = (columns, years, hasExpired) => {
  const rows = mapColumnsToRows(columns, years);
  return (
    <>
      <tr>
        <Th2 scope="row">{i18n.t('F6_state_1')}</Th2>
        {renderRows(rows.pending, years.length)}
      </tr>
      <tr>
        <Th2 scope="row">{i18n.t('F6_state_2')}</Th2>
        {renderRows(rows.ongoing, years.length)}
      </tr>
      <tr>
        <Th2 scope="row">{i18n.t('F6_state_3')}</Th2>
        {renderRows(rows.done, years.length)}
      </tr>

      {hasExpired ? (
        <tr>
          <Th2 scope="row">{i18n.t('EXPIRED')}</Th2>
          {renderRows(rows.expired, years.length)}
        </tr>
      ) : null}
    </>
  )
}

const mapColumnsToRows = (columns, years) => {
  let rows = {
    done: [],
    cancelled: [],
    ongoing: [],
    pending: [],
    expired: [],
  },
  aux = []

  for (let i = 0; i < years.length; i++) {
    aux = columns?.filter((item) => item.year == years[i])[0]
    rows.done[i] = aux ? aux.done : 0
    rows.cancelled[i] = aux ? aux.cancelled : 0
    rows.ongoing[i] = aux ? aux.ongoing : 0
    rows.pending[i] = aux ? aux.pending : 0
    rows.expired[i] = aux ? aux.expired : 0
  }

  return rows

}


export const f8bSummaryRenderRows = (rows) => {
  const renderNumber = (number) => 
    String(number) === '0' || !number ? '' : String(number)
  return (
    <>
      <tr>
        <Th2 scope="row">{i18n.t('F8b_completed_expost')}</Th2>
        <Td>{renderNumber(rows?.[0]?.complete)}</Td>
        <Td>{renderNumber(rows?.[1]?.complete)}</Td>
        <Td>{renderNumber(rows?.[2]?.complete)}</Td>
      </tr>
      <tr>
        <Th2 scope="row">{i18n.t('F8b_assessment_4')}</Th2>
        <Td>{renderNumber(rows?.[0]?.assessment4)}</Td>
        <Td>{renderNumber(rows?.[1]?.assessment4)}</Td>
        <Td>{renderNumber(rows?.[2]?.assessment4)}</Td>
      </tr>
      <tr>
        <Th2 scope="row">{i18n.t('F8b_assessment_title_5')}</Th2>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_5')}</Th2>
        <Td>{renderNumber(rows?.[0]?.assessment5)}</Td>
        <Td>{renderNumber(rows?.[1]?.assessment5)}</Td>
        <Td>{renderNumber(rows?.[2]?.assessment5)}</Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_6')}</Th2>
        <Td>{renderNumber(rows?.[0]?.assessment6)}</Td>
        <Td>{renderNumber(rows?.[1]?.assessment6)}</Td>
        <Td>{renderNumber(rows?.[2]?.assessment6)}</Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_7')}</Th2>
        <Td>{renderNumber(rows?.[0]?.assessment7)}</Td>
        <Td>{renderNumber(rows?.[1]?.assessment7)}</Td>
        <Td>{renderNumber(rows?.[2]?.assessment7)}</Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_8')}</Th2>
        <Td>{renderNumber(rows?.[0]?.assessment8)}</Td>
        <Td>{renderNumber(rows?.[1]?.assessment8)}</Td>
        <Td>{renderNumber(rows?.[2]?.assessment8)}</Td>
      </tr>
      <tr>
        <Th2 scope="row">{i18n.t('F8b_assessment_title_9')}</Th2>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_9')}</Th2>
        <Td>{renderNumber(rows?.[0]?.assessment9)}</Td>
        <Td>{renderNumber(rows?.[1]?.assessment9)}</Td>
        <Td>{renderNumber(rows?.[2]?.assessment9)}</Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_10')}</Th2>
        <Td>{renderNumber(rows?.[0]?.assessment10)}</Td>
        <Td>{renderNumber(rows?.[1]?.assessment10)}</Td>
        <Td>{renderNumber(rows?.[2]?.assessment10)}</Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_11')}</Th2>
        <Td>{renderNumber(rows?.[0]?.assessment11)}</Td>
        <Td>{renderNumber(rows?.[1]?.assessment11)}</Td>
        <Td>{renderNumber(rows?.[2]?.assessment11)}</Td>
      </tr>
      <tr>
        <Th2 scope="row">{i18n.t('F8b_assessment_title_12')}</Th2>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_12')}</Th2>
        <Td>{renderNumber(rows?.[0]?.assessment12)}</Td>
        <Td>{renderNumber(rows?.[1]?.assessment12)}</Td>
        <Td>{renderNumber(rows?.[2]?.assessment12)}</Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_13')}</Th2>
        <Td>{renderNumber(rows?.[0]?.assessment13)}</Td>
        <Td>{renderNumber(rows?.[1]?.assessment13)}</Td>
        <Td>{renderNumber(rows?.[2]?.assessment13)}</Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_14')}</Th2>
        <Td>{renderNumber(rows?.[0]?.assessment14)}</Td>
        <Td>{renderNumber(rows?.[1]?.assessment14)}</Td>
        <Td>{renderNumber(rows?.[2]?.assessment14)}</Td>
      </tr>

      <tr>
        <Th2 scope="row">{i18n.t('F8b_assessment_title_15')}</Th2>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_15')}</Th2>
        <Td>{renderNumber(rows?.[0]?.assessment15)}</Td>
        <Td>{renderNumber(rows?.[1]?.assessment15)}</Td>
        <Td>{renderNumber(rows?.[2]?.assessment15)}</Td>
      </tr>
    </>
  )
}


export const f8bSummaryRenderRowsTab2 = (rows) => {
  const renderTd = (currentValue) => (
    <Td>{currentValue ? <CompleteImage src={completeIcon} /> : ''}</Td>
  )

  return (
    <>
      <tr>
        <Th2 scope="row">{i18n.t('F8b_completed_expost')}</Th2>
        {renderTd(rows?.[0]?.complete)}
        {renderTd(rows?.[1]?.complete)}
        {renderTd(rows?.[2]?.complete)}
      </tr>
      <tr>
        <Th2 scope="row">{i18n.t('F8b_assessment_4')}</Th2>
        {renderTd(rows?.[0]?.assessment4)}
        {renderTd(rows?.[1]?.assessment4)}
        {renderTd(rows?.[2]?.assessment4)}
      </tr>
      <tr>
        <Th2 scope="row">{i18n.t('F8b_assessment_title_5')}</Th2>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_5')}</Th2>
        {renderTd(rows?.[0]?.assessment5)}
        {renderTd(rows?.[1]?.assessment5)}
        {renderTd(rows?.[2]?.assessment5)}
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_6')}</Th2>
        {renderTd(rows?.[0]?.assessment6)}
        {renderTd(rows?.[1]?.assessment6)}
        {renderTd(rows?.[2]?.assessment6)}
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_7')}</Th2>
        {renderTd(rows?.[0]?.assessment7)}
        {renderTd(rows?.[1]?.assessment7)}
        {renderTd(rows?.[2]?.assessment7)}
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_8')}</Th2>
        {renderTd(rows?.[0]?.assessment8)}
        {renderTd(rows?.[1]?.assessment8)}
        {renderTd(rows?.[2]?.assessment8)}
      </tr>
      <tr>
        <Th2 scope="row">{i18n.t('F8b_assessment_title_9')}</Th2>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_9')}</Th2>
        {renderTd(rows?.[0]?.assessment9)}
        {renderTd(rows?.[1]?.assessment9)}
        {renderTd(rows?.[2]?.assessment9)}
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_10')}</Th2>
        {renderTd(rows?.[0]?.assessment10)}
        {renderTd(rows?.[1]?.assessment10)}
        {renderTd(rows?.[2]?.assessment10)}
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_11')}</Th2>
        {renderTd(rows?.[0]?.assessment11)}
        {renderTd(rows?.[1]?.assessment11)}
        {renderTd(rows?.[2]?.assessment11)}
      </tr>
      <tr>
        <Th2 scope="row">{i18n.t('F8b_assessment_title_12')}</Th2>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_12')}</Th2>
        {renderTd(rows?.[0]?.assessment12)}
        {renderTd(rows?.[1]?.assessment12)}
        {renderTd(rows?.[2]?.assessment12)}
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_13')}</Th2>
        {renderTd(rows?.[0]?.assessment13)}
        {renderTd(rows?.[1]?.assessment13)}
        {renderTd(rows?.[2]?.assessment13)}
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_14')}</Th2>
        {renderTd(rows?.[0]?.assessment14)}
        {renderTd(rows?.[1]?.assessment14)}
        {renderTd(rows?.[2]?.assessment14)}
      </tr>

      <tr>
        <Th2 scope="row">{i18n.t('F8b_assessment_title_15')}</Th2>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
        <Td style={{ backgroundColor: '#c6e0b4' }}></Td>
      </tr>
      <tr>
        <Th2 scope="row" style={{fontWeight: '500'}}>{i18n.t('F8b_assessment_15')}</Th2>
        {renderTd(rows?.[0]?.assessment15)}
        {renderTd(rows?.[1]?.assessment15)}
        {renderTd(rows?.[2]?.assessment15)}
      </tr>
    </>
  )
}