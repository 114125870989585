import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import MoneyTextMask from 'components/MoneyTextMask';
import { getActivities, getImplementationInitiatives } from 'services/initiative';
import { formatDateDDMMYY } from 'helpers/dateHelpers';
import { getRegisterCountries } from "services/auth";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import CustomDatePicker from 'components/CustomDatePicker';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageTitle from 'components/PageTitle';
import { PageHeader, PageContent, CenteredRow } from './List.styled';
import { PrimaryButton } from 'components/Buttons';
import CustomExportXLSXButton from './CustomExportXLSXButton/CustomExportXLSXButton';

const List = () => {
  const { t } = useTranslation();
  const {authStore} = useRootStore();

  const [filterInitiative, setFilterInitiative] = useState(0);
  const [filterStatus, setFilterStatus] = useState(0);
  const [filterType, setFilterType] = useState(0);
  const [filterCountry, setFilterCountry] = useState(0);
  const [checkWithParticipants, setCheckWithParticipants] = useState(false);
  const [checkFinalizedInit, setCheckFinalizedInit] = useState(false);
  const [fromDateFilter, setFromDateFilter] = useState(null);
  const [toDateFilter, setToDateFilter] = useState(null);

  const [countries, setCountries] = useState([]);
  const [tableValues, setTableValues] = useState([]);

  const [initiativesOptions, setInitiativesOptions] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getRegisterCountries();
        setCountries(response.data);
      } catch (e) {
        console.log(e);
      }
    }
    getInitiatives();
    getValues(0, false, {});
    fetchAPI();
  }, []);

  async function getInitiatives() {
    try {
      const response = await getImplementationInitiatives();
      setInitiativesOptions(response);
    } catch (error) {
      console.log(error)
    }
  }

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber);
    getValues(newPageNumber, true, getParams());
  };

  async function getValues(pageNumber, willFilter, filterParams) {
    try {
      let params = {
        page: pageNumber,
        pageSize: 100,
      };
      if (willFilter) {
        params = {
          ...params,
          ...filterParams,
        }
      }
      const response = await getActivities(params);
      const rowValues = response.data.content;
      setTotalPages(response.data.totalPages);
      setTableValues([...rowValues]);
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeStatus = (e) => {
    setFilterStatus(e.target.value);
  }

  const handleChangeInitiative = (e) => {
    setFilterInitiative(e.target.value);
  }

  const handleChangeType = (e) => {
    setFilterType(e.target.value);
  }

  const handleChangeCountry = (e) => {
    setFilterCountry(e.target.value);
  }

  const getParams = () => {
    let params = {};
    if(filterInitiative != 0)
      params.initiativeId = filterInitiative;
    if(filterStatus != 0)
      params.statusId = filterStatus;
    if(filterType != 0)
      params.type = filterType;
    if(filterCountry != 0)
      params.countryId = filterCountry;
    if(fromDateFilter !== null)
      params.startDate = fromDateFilter.toISOString();
    if(toDateFilter !== null)
      params.endDate = toDateFilter.toISOString();
    if(checkFinalizedInit)
      params.includeFinalized = true;
    else
      params.includeFinalized = false;
    if(checkWithParticipants)
      params.participants = true;
    else
      params.participants = false;
    return params;
  }

  const handleFilter = () => {
    getValues(0, true, getParams());
  }

  const handleChangeCheckWithParticipants = (e) => {
    setCheckWithParticipants(e.target.checked);
  };

  const handleChangeCheckFinalizedInitiatives = (e) => {
    setCheckFinalizedInit(e.target.checked);
  };

  const columns = [
    {
      dataField: 'code',
      text: t('Code'),
      headerStyle: { width: '120px', textAlign: 'center', fontSize: '14px' },
      style: {fontSize: '14px', textAlign: 'center'},
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <Link to={`${authStore.getBaseRouteByRole()}/initiative/${row.initiativeId}/form/5/section/7`} target="_blank" rel="noreferrer">{row.code}</Link>
        </div>
      )
    },
    {
      dataField: 'startDate',
      text: t('Start_Date'),
      headerStyle: { width: '110px', textAlign: 'center', fontSize: '14px' },
      style:{fontSize: '14px', textAlign: 'center'},
      formatter: (cell, row, rowIndex, extraData) => (
        row.startDate ? formatDateDDMMYY(row.startDate) : '-'
      )
    },
    {
      dataField: 'endDate',
      text: t('End_Date'),
      headerStyle: { width: '110px', textAlign: 'center', fontSize: '14px' },
      style:{fontSize: '14px', textAlign: 'center'},
      formatter: (cell, row, rowIndex, extraData) => (
        row.endDate ? formatDateDDMMYY(row.endDate) : '-'
      )
    },
    {
      dataField: 'type',
      text: t('F1_S7_type'),
      headerStyle: {width: '130px', textAlign: 'center', fontSize: '14px' },
      style: {fontSize: '13px', textAlign: 'center'},
    },
    {
      dataField: 'title',
      text: t('F5_S15_Lesson_title'),
      headerStyle: { minWidth: '220px', textAlign: 'center', fontSize: '14px' },
      style: {fontSize: '14px', textAlign: 'justify'}
    },
    {
      dataField: 'country',
      text: t('F1_S2_Country'),
      headerStyle: { width: '158px', textAlign: 'center', fontSize: '14px' },
      style: {fontSize: '14px', textAlign: 'center'},
      formatter: (cell, row, rowIndex, extraData) => (
        row.country ? t(row.country) : '-'
      )
    },
    {
      dataField: 'status',
      text: t('Status'),
      headerStyle: {width: '155px', textAlign: 'center', fontSize: '14px' },
      style: {fontSize: '13px', textAlign: 'center'},
    },
    {
      dataField: 'initiative',
      text: t('Initiative'),
      headerStyle: { width: '158px', textAlign: 'center', fontSize: '14px' },
      style: {fontSize: '14px', textAlign: 'center'},
      hidden: true,
      csvExport: false
    },
    {
      dataField: 'initiativeId',
      text: "",
      hidden: true,
      csvExport: false
    },
    {
      dataField: 'authorized',
      text: t('Authorized'),
      headerStyle: { width: '160px', textAlign: 'center', fontSize: '14px' },
      style: {fontSize: '14px', textAlign: 'center'},
      formatter: (cell, row, rowIndex, extraData) => (
        <MoneyTextMask value={row.authorized} />
      )
    },
    {
      dataField: 'totalBudget',
      text: t('Implementation_nocut'),
      headerStyle: { width: '160px', textAlign: 'center', fontSize: '14px' },
      style: {fontSize: '14px', textAlign: 'center'},
      formatter: (cell, row, rowIndex, extraData) => (
        <MoneyTextMask value={row.totalBudget} />
      )
    },
  ];

    const pagination = paginationFactory({
        sizePerPage: 100,
        hideSizePerPage: true
    });

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={ tableValues }
        columns={ columns }
        pagination={ pagination }
      >
        {
          props => (
            <>
              <PageHeader>
                <PageTitle title={t('activity_report_tab1')} />
              </PageHeader>
              <Row style={{alignItems: "center", marginLeft: '0', marginBottom: '15px'}}>
                  {t('Initiative')}
                  <Form.Control
                    style={{marginLeft: '10px', marginRight: '30px', width: '150px'}}
                    as="select"
                    onChange={handleChangeInitiative}
                    value={filterInitiative}
                  >
                    <option value={0}> {t('ALL')} </option>
                    {initiativesOptions && initiativesOptions.map( (e) => (
                        <option value={e.id}> {e.code} </option>
                    ))}
                  </Form.Control>

                  {t('Status')}
                  <Form.Control
                    style={{marginLeft: '10px', marginRight: '30px', width: '180px'}}
                    as="select"
                    onChange={handleChangeStatus}
                    value={filterStatus}
                  >
                    <option value={0}> {t('ALL')} </option>
                    <option value={1}> {t('table_status_1')} </option>
                    <option value={2}> {t('CONFIRMED')} </option>
                  </Form.Control>

                  {t('Type')}
                  <Form.Control
                    style={{marginLeft: '10px', marginRight: '30px', width: '180px'}}
                    as="select"
                    onChange={handleChangeType}
                    value={filterType}
                  >
                    <option value={0}> {t('ALL')} </option>
                    <option value={1}> {t('activity_types_1')} </option>
                    <option value={2}> {t('activity_types_2')} </option>
                    <option value={3}> {t('activity_types_3')} </option>
                    <option value={4}> {t('activity_types_4')} </option>
                    <option value={5}> {t('activity_types_5')} </option>
                    <option value={6}> {t('activity_types_6')} </option>
                    <option value={7}> {t('activity_types_7')} </option>
                    <option value={8}> {t('activity_types_8')} </option>
                  </Form.Control>

                  {t('F1_S2_Country')}
                  <Form.Control
                    style={{marginLeft: '10px', marginRight: '30px', width: '180px'}}
                    as="select"
                    onChange={handleChangeCountry}
                    value={filterCountry}
                  >
                    <option value={0}> {t('ALL')} </option>
                    {countries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {t(country.name)}
                      </option>
                    ))}
                  </Form.Control>



                  {t('Grid_start_date_from')}
                  <CustomDatePicker
                    value={fromDateFilter}
                    onChange={(date) => setFromDateFilter(date)}
                    disabled={false}
                  />

                  {t('Grid_start_date_to')}
                  <CustomDatePicker
                    value={toDateFilter}
                    onChange={(date) => setToDateFilter(date)}
                    disabled={false}
                  />
              </Row>
              <Row style={{alignItems: "center"}}>
                <Col style={{maxWidth: '290px'}}>
                  <Form.Check
                    name="showFinalized"
                    type="checkbox"
                    checked={checkFinalizedInit}
                    onChange={handleChangeCheckFinalizedInitiatives}
                    label={t('Checkbox_Finalized_initiatives')}
                  />
                </Col>
                <Col sm={3}>
                  <Form.Check
                    name="showOnlyWithParticipants"
                    type="checkbox"
                    checked={checkWithParticipants}
                    onChange={handleChangeCheckWithParticipants}
                    label={t('Checkbox_Only_Participants')}
                  />
                </Col>
                <Col sm={3} style={{display: 'flex', alignItems: 'center'}}>
                  <PrimaryButton onClick={handleFilter}>{t('Button_filter')}</PrimaryButton>
                  <CustomExportXLSXButton
                    { ...props.csvProps }
                    filter={{
                      initiative: filterInitiative,
                      status: filterStatus,
                      type: filterType,
                      country: filterCountry,
                      fromDate: fromDateFilter,
                      toDate: toDateFilter,
                      checkFinalized: checkFinalizedInit,
                      checkParticipants: checkWithParticipants,
                    }}
                  />
                </Col>
              </Row>
              <PageContent>
                <BootstrapTable { ...props.baseProps } />
              </PageContent>
            </>
          )
        }
      </ToolkitProvider>
      <CenteredRow>
        <Pagination>
          <Pagination.First disabled={currentPage == 0} onClick={() => handlePageChange(0)} />
          {currentPage !== 0 && <Pagination.Ellipsis />}
          {currentPage !== 0 && <Pagination.Item onClick={() => handlePageChange(currentPage - 1)} >{currentPage - 1}</Pagination.Item>}
          <Pagination.Item active> {currentPage} </Pagination.Item>
          {currentPage !== (totalPages - 1) && <Pagination.Item onClick={() => handlePageChange(currentPage + 1)} >{currentPage + 1}</Pagination.Item>}
          {currentPage !== (totalPages - 1) && <Pagination.Ellipsis />}
          <Pagination.Last disabled={currentPage == (totalPages - 1)} onClick={() => handlePageChange(totalPages - 1)}/>
        </Pagination>
      </CenteredRow>
    </>
  )
};

export default observer(List);