import React from 'react';
import FormLayout from '../../FormLayout';

const SectionNotFound = () => {
  return (
    <FormLayout>
      <p>Section not found</p>
    </FormLayout>
  )
}

export default SectionNotFound;
