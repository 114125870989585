import * as Yup from 'yup';
import i18n from 'i18n';

const urlReg = /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;
const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

export const validationSchema = Yup.object().shape({
  entities: Yup.array().of(
    Yup.object().shape({
      entityName: Yup.string()
        .required('F3_S7_validation3')
        .min(2, 'F1_S2_validation1')
        .max(200, 'F1_S2_validation2'),
      acronym: Yup.string()
        .min(2, 'F1_S2_validation3')
        .max(100, 'F1_S2_validation4'),
      entityTypeId: Yup.string().required('F3_S7_validation3'),
      function: Yup.string()
        .required('F3_S7_validation3')
        .min(2, 'F1_S4_validation1')
        .max(200, 'F1_S4_validation2'),
      countryId: Yup.string(),
      web: Yup.string()
        .min(5, 'F1_S2_validation6')
        .max(200, 'F1_S2_validation7')
        .matches(urlReg, 'F1_S2_validation15'),
      firstName: Yup.string()
        .required('F3_S7_validation3')
        .min(2, 'F1_S4_validation1')
        .max(200, 'F1_S4_validation2'),
      lastName: Yup.string()
        .required('F3_S7_validation3')
        .min(2, 'F1_S4_validation1')
        .max(200, 'F1_S4_validation2'),
      position: Yup.string()
        .required('F3_S7_validation3')
        .min(2, 'F1_S4_validation1')
        .max(200, 'F1_S4_validation2'),
      email: Yup.string()
        .required('F3_S7_validation3')
        .email('F1_S2_validation23'),
      phone: Yup.string()
        .required('F3_S7_validation3')
        .min(2, 'F1_S4_validation1')
        .max(200, 'F1_S4_validation2')
        .matches(phoneRegExp, 'F1_S2_validation22'),
    })
  ),
});
