import React from 'react'
import { Formik } from 'formik'
import { formInitialValues } from './NewActivityForm.initialValues'
import { validationSchema } from './NewActivityForm.validations'
import { useTranslation } from 'react-i18next'
import { useConstantsForm3 } from 'customHooks/useConstantsForm3'
import { createNewActivityF3S7 } from 'services/initiative'
import { useParams } from 'react-router-dom'

import { RadioLabel } from 'components/Forms/Forms.styled'
import { PrimaryButton } from 'components/Buttons'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ErrorButton, StyledSendButton } from 'components/Buttons'
import { Form } from 'react-bootstrap'
import {
  RequiredMark,
  SeparatorDotted,
  CenteredModalButtonContainer,
} from 'components/CommonStyled'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const NewActivityForm = ({
  handleCloseOk,
  handleClose,
  handleCloseOnSave,
  handleRefreshList,
  nonEditable,
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { countries, activityTypes } = useConstantsForm3()

  const onSave = async (values) => {
    try {
      const valuesCopy = JSON.parse(JSON.stringify(values))
      valuesCopy.totalBudget = values.ueBudget + values.counterPartBudget
      valuesCopy.fromDateStr = values.fromDateStr.toISOString()
      valuesCopy.toDateStr = values.toDateStr.toISOString()
      await createNewActivityF3S7(id, valuesCopy)
      handleRefreshList()
      handleCloseOnSave()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={onSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label column lg={2}>
                {t('F1_S7_type')} <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col xs={6}>
                <Form.Control
                  as="select"
                  name="activityTypeId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.activityTypeId}
                  className={
                    touched.activityTypeId && errors.activityTypeId
                      ? 'error'
                      : null
                  }
                  disabled={nonEditable}
                >
                  <option value="">{t('Placeholder_select')}</option>
                  {activityTypes.map((activityType) => (
                    <option key={activityType.id} value={activityType.id}>
                      {t(`activity_types_${activityType.id}`)}
                    </option>
                  ))}
                </Form.Control>
                <FormErrorMessage
                  touched
                  errors={errors.activityTypeId}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column xs={2}>
                {t('F1_S7_start_day')} <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col>
                <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={values.fromDateStr}
                    onChange={(date) => {
                      setFieldValue('fromDateStr', date)
                    }}
                    className={
                      touched.fromDateStr && errors.fromDateStr ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                </InputGroup>
                <FormErrorMessage
                  touched
                  errors={errors.fromDateStr}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} style={{ alignItems: 'center' }}>
              <Form.Label column xs={2}>
                {t('F1_S7_End_day')} <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col>
                <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={values.toDateStr}
                    onChange={(date) => {
                      setFieldValue('toDateStr', date)
                    }}
                    className={
                      touched.toDateStr && errors.toDateStr ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                </InputGroup>
                <FormErrorMessage
                  touched
                  errors={errors.toDateStr}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col>
                <Form.Label xs={5} lg={4} style={{ marginTop: '10px' }}>
                  {t('F1_S7_Short_description')} <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  style={{ resize: 'none', marginRight: '18px' }}
                  placeholder="A maximum of 1.300 characters"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  className={
                    touched.description && errors.description ? 'error' : null
                  }
                  disabled={nonEditable}
                />
                <FormErrorMessage
                  touched
                  errors={errors.description}
                />
              </Col>
            </Form.Group>

            <SeparatorDotted />

            <Form.Group as={Row}>
              <Form.Label column lg={4}>
                {t('F1_S7_Format')} <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col lg={8} style={{ marginTop: '20px' }}>
                <RadioLabel>
                  <input
                    type="radio"
                    name="format"
                    value="0"
                    checked={values?.format === '0'}
                    onChange={(e) => {
                      setFieldValue('format', e?.target?.value)
                    }}
                    style={{ marginRight: '10px' }}
                  />
                  {t('F1_S7_Format0')}
                </RadioLabel>

                <RadioLabel>
                  <input
                    type="radio"
                    name="format"
                    value="1"
                    checked={values?.format === '1'}
                    onChange={(e) => {
                      setFieldValue('format', e?.target?.value)
                    }}
                    style={{ marginRight: '10px' }}
                  />
                  {t('F1_S7_Format1')}
                </RadioLabel>

                <RadioLabel>
                  <input
                    type="radio"
                    name="format"
                    value="2"
                    checked={values?.format === '2'}
                    onChange={(e) => {
                      setFieldValue('format', e?.target?.value)
                      setFieldValue('countryId', '')
                      setFieldValue('stateName', '')
                      setFieldValue('cityName', '')
                      setFieldValue('countriesAdditional', '')
                    }}
                    style={{ marginRight: '10px' }}
                  />
                  {t('F1_S7_Format2')}
                </RadioLabel>
                <FormErrorMessage
                  touched
                  errors={errors.format}
                />
              </Col>
            </Form.Group>

            {values?.format !== '2' && (
              <>
                <Form.Group as={Row}>
                  <Form.Label column lg={4}>
                    {t('F1_S7_Country_activity')} <RequiredMark>*</RequiredMark>
                    :
                  </Form.Label>
                  <Col lg={5}>
                    <Form.Control
                      as="select"
                      name="countryId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.countryId}
                      className={
                        touched.countryId && errors.countryId ? 'error' : null
                      }
                    >
                      <option value="">{t('Placeholder_select')}</option>
                      {countries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {t(country.name)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched
                      errors={errors.countryId}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: 'center' }}>
                  <Form.Label column xs={4}>
                    {t('F1_S2_State_Province_Region')}:
                  </Form.Label>
                  <Col xs={5}>
                    <Form.Control
                      type="text"
                      name="stateName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.stateName}
                      className={
                        touched.stateName && errors.stateName ? 'error' : null
                      }
                    />
                    <FormErrorMessage
                      touched
                      errors={errors.stateName}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column lg={4}>
                    {t('F1_S2_City')} <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Col lg={5}>
                    <Form.Control
                      type="text"
                      name="cityName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cityName}
                      className={
                        touched.cityName && errors.cityName ? 'error' : null
                      }
                    />
                    <FormErrorMessage
                      touched
                      errors={errors.cityName}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column lg={4}>
                    {t('F1_S7_Countries_additional')}:
                  </Form.Label>
                  <Col lg={5}>
                    <Form.Control
                      type="text"
                      name="countriesAdditional"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.countriesAdditional}
                      className={
                        touched.countriesAdditional &&
                        errors.countriesAdditional
                          ? 'error'
                          : null
                      }
                    />
                    <FormErrorMessage
                      touched
                      errors={errors.countriesAdditional}
                    />
                  </Col>
                </Form.Group>
              </>
            )}

            <SeparatorDotted />

            <Form.Group as={Row}>
              <Form.Label column xs={3}>
                {t('F1_S7_Duration')} <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col xs={3}>
                <InputGroup>
                  <Form.Control
                    type="number"
                    min="0"
                    name="duration"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.duration}
                    className={
                      touched.duration && errors.duration ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                </InputGroup>
                <FormErrorMessage
                  touched
                  errors={errors.duration}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column xs={3}>
                {t('F1_S7_Number_participants')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col xs={3}>
                <InputGroup>
                  <Form.Control
                    type="number"
                    min="0"
                    name="participants"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.participants}
                    className={
                      touched.participants && errors.participants
                        ? 'error'
                        : null
                    }
                    disabled={nonEditable}
                  />
                </InputGroup>
                <FormErrorMessage
                  touched
                  errors={errors.participants}
                />
              </Col>
            </Form.Group>

            {values.activityTypeId === '4' && (
              <>
                <SeparatorDotted />

                <Form.Group as={Row}>
                  <Form.Label column xs={3}>
                    {t('F1_S7_Number_experts')}:
                  </Form.Label>
                  <Col xs={3}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        min="0"
                        name="experts"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.experts}
                        className={
                          touched.experts && errors.experts ? 'error' : null
                        }
                        disabled={nonEditable}
                      />
                    </InputGroup>
                    <FormErrorMessage
                      touched
                      errors={errors.experts}
                    />
                  </Col>
                </Form.Group>

                <Form.Label xs={3} style={{ marginBottom: '30px' }}>
                  {t('F1_S7_Number_wd')}:
                </Form.Label>

                <Form.Group as={Row}>
                  <Form.Label column xs={2}>
                    {t('F1_S7_Remote')}
                  </Form.Label>
                  <Col xs={2}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        min="0"
                        name="daysRemote"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.daysRemote}
                        className={
                          touched.daysRemote && errors.daysRemote
                            ? 'error'
                            : null
                        }
                        disabled={nonEditable}
                      />
                    </InputGroup>
                    <FormErrorMessage
                      touched
                      errors={errors.daysRemote}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column xs={2}>
                    {t('F1_S7_Onsite')}
                  </Form.Label>
                  <Col xs={2}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        min="0"
                        name="daysOnsite"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.daysOnsite}
                        className={
                          touched.daysOnsite && errors.daysOnsite
                            ? 'error'
                            : null
                        }
                        disabled={nonEditable}
                      />
                    </InputGroup>
                    <FormErrorMessage
                      touched
                      errors={errors.daysOnsite}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column xs={2}>
                    {t('F1_S7_Total')}
                  </Form.Label>
                  <Col xs={2}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        disabled={true}
                        value={values.daysRemote + values.daysOnsite}
                      />
                    </InputGroup>
                  </Col>
                </Form.Group>
              </>
            )}

            <SeparatorDotted />

            <Form.Group as={Row}>
              <Col>
                <Form.Label lg={4} style={{ alignItems: 'center' }}>
                  {t('F1_S7_Justification_activity')}
                  <RequiredMark style={{ marginLeft: '7px' }}>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  style={{ resize: 'none', marginRight: '18px' }}
                  placeholder="A maximum of 650 characters"
                  name="justBudget"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.justBudget}
                  className={
                    touched.justBudget && errors.justBudget ? 'error' : null
                  }
                  disabled={nonEditable}
                />
                <FormErrorMessage
                  touched
                  errors={errors.justBudget}
                />
              </Col>
            </Form.Group>

            <CenteredModalButtonContainer>
              {nonEditable ? (
                <PrimaryButton
                  onClick={() => {
                    handleCloseOk()
                  }}
                >
                  OK
                </PrimaryButton>
              ) : (
                <>
                  <ErrorButton
                    style={{ marginRight: '20px' }}
                    onClick={handleClose}
                    disabled={nonEditable}
                  >
                    {t('Button_cancel')}
                  </ErrorButton>
                  <StyledSendButton
                    onClick={() => {
                      onSave(values)
                    }}
                    disabled={nonEditable}
                  >
                    {t('Button_save')}
                  </StyledSendButton>
                </>
              )}
            </CenteredModalButtonContainer>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default NewActivityForm
