import React from 'react'
import PageTitle from 'components/PageTitle'
import { PageHeader } from '../CommonStyled/Tabs.styled'
import { useTranslation } from 'react-i18next'
import StatisticsTabs from 'components/Tabs'
import ActivitiesDetail from './tabs/ActivitiesDetail'
import ActivitiesByCountrySummary from './tabs/ActivitiesByCountrySummary'
import ActivitiesByRoleSummary from './tabs/ActivitiesByRoleSummary'
import ActivitiesByTypeSummary from './tabs/ActivitiesByTypeSummary'
import ProductsByTypeSummary from './tabs/ProductsByTypeSummary'

const StatsActivities = () => {
  const { t } = useTranslation()

  const titles = [
    t('Statistics_activities_tab1'),
    t('Statistics_activities_tab2'),
    t('Statistics_activities_tab3'),
    t('Statistics_activities_tab4'),
    t('Statistics_activities_tab5'),
  ]

  const renderTab1Content = () => <ActivitiesDetail />
  const renderTab2Content = () => <ActivitiesByCountrySummary />
  const renderTab3Content = () => <ActivitiesByRoleSummary />
  const renderTab4Content = () => <ActivitiesByTypeSummary />
  const renderTab5Content = () => <ProductsByTypeSummary />

  const contents = [
    renderTab1Content,
    renderTab2Content,
    renderTab3Content,
    renderTab4Content,
    renderTab5Content,
  ]

  return (
    <>
      <PageHeader>
        <PageTitle title={t('menu_admin_6_6')} />
      </PageHeader>
      <StatisticsTabs titles={titles} contents={contents} />
    </>
  )
}

export default StatsActivities
