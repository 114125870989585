import React, {useState } from 'react'
import { contactSupport } from 'services/contact'

import { Form } from 'react-bootstrap'
import { FormFielErrorMessage, CenteredModalButtonContainer } from 'components/CommonStyled';
import Modal from 'react-bootstrap/Modal'
import CustomModal from 'components/Modal';
import { useTranslation } from 'react-i18next';
import {ErrorButton, PrimaryButton} from 'components/Buttons'

import { 
  StyledModalBody,
  Separator,
} from './ContactSupporForm.styled'

const ContactSupportForm = ({show, handleClose, initiativeCode, formName, sectionName, sectionNumber, sendTo}) => {
  
  const { t } = useTranslation();

  const [modal, setModal] = React.useState({
      isOpen: false,
      message: "",
      type: 'default'
  });

  const [sending, setSending] = useState(false);

  const onHideModal = () => {
      setModal({...modal, isOpen: false});
  };

  const [question, setQuestion] = useState("");
  const [formError, setFormError] = useState({
    isShow: false,
    msg: ""
  });

  const handleChange = (e) => {
    setFormError({
      isShow: false,
      msg:""
    });
    setQuestion(e.target.value);
  }

  const handleSubmit = async () => {
    if(!question.length > 0){
      setFormError({
        isShow: true,
        msg: t('Error field cannot be empty')
      })
    }
    else if(question.length > 1300){
      setFormError({
        isShow: true,
        msg: t('Error max length 1300')
      })
    }
    else{
      setSending(true);
      try{
        await contactSupport({
          initiativeCode: initiativeCode,
          formName: formName,
          sectionName: sectionName,
          sectionNumber: sectionNumber,
          question: question,
          sendTo: sendTo
        });
        setModal({
          ...modal, 
          message: t('Contact_form_confirmation'),
          type: "success",
          isOpen: true,
        });
        setTimeout(function(){
          handleClose();
          setSending(false);
        },2000);
      }
      catch(error){
        console.log(error);
        setSending(false);
      }
    }
  }

  return (
    <>
      { modal.isOpen && <CustomModal 
        show={modal.isOpen}
        onHide={onHideModal}
        message={modal.message}
        type={modal.type}
      /> }
      <Modal show={show} onHide={handleClose} size="lg" animation={false}>
        <StyledModalBody>
         {t('Contact_form_question')}
          
          <Separator />

          <Form.Control
            as="textarea"
            style={{resize: 'none'}}
            rows={6} 
            onChange={handleChange}
            value={question}
            placeholder={t('Maximum_1300')}
          />

          {formError.isShow && <FormFielErrorMessage> {formError.msg} </FormFielErrorMessage>}

          <Separator />

          {t('Contact_form_footnote')}

          <CenteredModalButtonContainer>
            <ErrorButton style={{marginRight: '20px'}} disabled={sending} onClick={handleClose}> {t('Button_cancel')} </ErrorButton>
            <PrimaryButton disabled={sending} onClick={() => {handleSubmit()}}> {t('Button_send')} </PrimaryButton>
          </CenteredModalButtonContainer>
        </StyledModalBody>
      </Modal>
    </>
  )
}

export default ContactSupportForm;