import React from 'react';
import NumberFormat from 'react-number-format';

const MoneyTextMask = props => {
  return (
    <NumberFormat
      displayType="text"
      isNumericString={true}
      decimalSeparator=','
      thousandSeparator='.'
      prefix='€  '
      decimalScale={2}
      fixedDecimalScale={true}
      value={props.value}
      {... props}
    />
  )
}


export default MoneyTextMask;