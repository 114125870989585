import React, { useState, useEffect } from 'react'
import _chunk from 'lodash/chunk'
import { getActivitiesByFormatSummary } from 'services/initiative'
import { useTranslation } from 'react-i18next'

import Header4 from '../components/Header4'
import Table3 from '../components/Table3'
import Row from 'react-bootstrap/Row'
import { StyledDiv, StyledRow, StyledRow2 } from '../../CommonStyled/Tabs.styled'

import { T7RenderRows, getActivitiesByRoleSummaryExportValues, getFundsActivitiesByRoleSummaryExportValues } from '../helpers/helpers'
import { ExportStatisticsByYearsToXLSX } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'
import Table6 from 'pages/statistics/Entities/components/Table6'
import { getFundsActivitySummaryByFormat } from 'services/fundsStatistics'

const ActivitiesByRoleSummary = () => {
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const [data2, setData2] = useState(null)
  const [waiting2, setWaiting2] = useState(false)

  const getData = async (param) => {
    setWaiting(true)
    try {
      const response = await getActivitiesByFormatSummary(param)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }


  const getData2 = async (param) => {
    setWaiting2(true)
    try {
      let response = await getFundsActivitySummaryByFormat(param)
      setData2(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting2(false)
    }
  }

  useEffect(() => {
    getData()
    getData2()
  }, [])

  return (
    <StyledDiv>
      <Row>
        <Header4
          waiting={waiting}
          getData={getData}
          getData2={getData2}
          renderExportButton={() => (
            <ExportToXLSXButton
              data={data}
              mapData={getActivitiesByRoleSummaryExportValues}
              data2={data2}
              mapData2={getFundsActivitiesByRoleSummaryExportValues}
              disabled={waiting}
              exportName={t('Statistics_activities_tab3')}
            >
              <ExportStatisticsByYearsToXLSX
                label={t('Statistics_activities_tab3')}
                data={[]}
              />
            </ExportToXLSXButton>
          )}
          changeLabel
        />
      </Row>
      <StyledRow>
        <Table3 data={data} waiting={waiting} setData={setData} title={t('Statistics_activities_tab3')} />
      </StyledRow>
      <StyledRow2 style={{marginLeft: '0px'}}>
        <Table6
          title={t('Statistics_activities_tab3')}
          data={data2}
          waiting={waiting2}
          renderRows={T7RenderRows}
          setData={setData2}
          customWidth='62%'
          customWidthRow1='84%'
          customWidthRow2='16%'
          orderField='role'
          total={true}
        />
      </StyledRow2>
    </StyledDiv>
  )
}

export default ActivitiesByRoleSummary
