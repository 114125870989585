import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportExecutedBudgetDashboardToXLSX } from 'helpers/xlsxExporter';
import { PrimaryButton } from 'components/Buttons';

const CustomXLSXExportButton = (props) => {
  const { t } = useTranslation();
  const [exporting, setExporting] = useState(false);
  const [exportData, setExportData] = useState(null);
  const [buttonReady, setButtonReady] = useState(false);

  const handleClick = async () => {
    setExporting(true);
    const data = await getAllValues();  
    setExportData(data);
    setButtonReady(true);
    setExporting(false);
  };

  async function getAllValues() {
    try {
      var data = [];
      props.tableValues.forEach(item => {
        data.push(
        {
          resourceType: t(`resource_type_${item.resourceTypeId}`), 
          authorized: item.authorized,
          implementation: item.implementation,
          percentage: `${item.deviation} %`,
          paid: item.paid,
          level: `${item.paid && item.implementation ? item.paid / item.implementation : 0} %`,
        })
     })
      return data;
    } catch (error) {
      console.log(error)
      return [];
    }
  }

  const onDownloadReady = () => {
    setExportData(null);
    setButtonReady(false);
  };

  return (
    <>
      <ExportExecutedBudgetDashboardToXLSX data={exportData} isReady={buttonReady} onReady={onDownloadReady} />
      <PrimaryButton
        onClick={handleClick}
        style={{width: '220px'}}
        disabled={exporting}
      >
        { t('Export_to_XLSX') }
      </PrimaryButton>
    </>
  );
};

export default CustomXLSXExportButton;