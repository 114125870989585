import React, { useState, useEffect } from 'react';
import _isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { f5ActivityBudgetStatesConst, f5ActivityStatesConst } from 'constants/appConstants';
import { 
  getDetailProvidersF5S7Budget, 
  getSelectedProviderF5S7Budget, 
  getDocumentsF5S7Budget, 
  getPaymentsF5S7Budget,
  deleteF5S7ActivityDetailProvider,
  deleteF5S7ActivityDetailDocument,
  deleteF5S7ActivityDetailPayment,
  cancelF5S7ActivityDetail
} from 'services/initiative';
import { useParams } from 'react-router-dom';

import CancelBudgetDetailModal from './CancelBudgetDetailModal';
import MoneyTextMask from 'components/MoneyTextMask';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SeparatorDotted } from 'components/CommonStyled';
import { ErrorButton, PrimaryButton } from 'components/Buttons';
import { 
  StepTitle, 
  ItemSeparator, 
  ShortDescription, 
  StyledButtonLink, 
  RoundedRemoveDiv,
  OpenItemButton
} from './ContractSteps.styled';

const ContractSteps = ({ activity, detail, setBudgetDetailAdminModalData, handleCloseOnSave, isManagerNonEditable, setModalInfo }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [detailProviders, setDetailProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState({});
  const [documents, setDocuments] = useState([]);
  const [payments, setPayments] = useState([]);
  const [isCancelBudgetDetailAlert, setIsCancelBudgetDetailAlert] = useState(false);
  const [disabled, setDisabled] = useState(isManagerNonEditable);
  const [disabledPayments, setDisabledPayments] = useState(false);

  const isResourceNonApplicantCheckeable = (detail.resourceType && (detail.resourceType.id == 8 || detail.resourceType.id == 9));

  useEffect(() => {
    async function fetchAPI() {
      try {
        let response = await getDetailProvidersF5S7Budget(id, activity.id, detail.id);
        if(response && response.data){
          setDetailProviders(response.data);
        }
        response = await getSelectedProviderF5S7Budget(id, activity.id, detail.id);
        if(response && response.data){
          setSelectedProvider(response.data);
        }
        response = await getDocumentsF5S7Budget(id, activity.id, detail.id);
        if(response && response.data){
          setDocuments(response.data);
        }
        response = await getPaymentsF5S7Budget(id, activity.id, detail.id);
        if(response && response.data){
          setPayments(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAPI();
  }, [])

  useEffect(() => {
    setDisabledPayments(handleDisabledPayments() || 
      (detail && (detail.resourceDetailState.name === f5ActivityBudgetStatesConst.FINALIZED 
                  || detail.resourceDetailState.name === f5ActivityBudgetStatesConst.CANCELLED
    )));
  }, [selectedProvider])

  const handleDisabledPayments = () => {
    if(selectedProvider || !disabled) {
      return !(selectedProvider.confirmAvailability && (selectedProvider.confirmPartnership || isResourceNonApplicantCheckeable) && activity.activityState.stateEnum === f5ActivityStatesConst.CONFIRMED);
    }
    else
      return true;
  }

  const renderProviders = (arrayItem, handleOpenItem, handleRemoveItem) => {
    return (arrayItem?.map( item => (
      <Row style={{marginBottom: '10px'}} key={item.id}>
        <Col xs={3}>
          <OpenItemButton 
            variant="link" 
            onClick={() => {
              handleOpenItem(item);
            }}
          >
            {item.mgmtF5S7Provider? item.mgmtF5S7Provider.name: ' - '}
          </OpenItemButton>
        </Col>
        <Col xs={item.selected? 4:6}>
          <ShortDescription> {item.mgmtF5S7Provider? item.mgmtF5S7Provider.comments: ' - '} </ShortDescription>
        </Col>
        {item.selected &&
          <Col xs={2}>
            {t("F5_S7_Budget_Admins_Provider_Selected")}
          </Col>
        }
        <Col xs={2}>
          <MoneyTextMask value={item.totalAmount? item.totalAmount: 0.0} />
        </Col>
        <Col xs={1}>
          <StyledButtonLink type="button" style={{visibility: (disabled || item.selected)? "hidden":"visible"}} onClick={() => {handleRemoveItem(item.id)}} > <RoundedRemoveDiv>  x </RoundedRemoveDiv> </StyledButtonLink>
        </Col>
      </Row>
    )))
  }

  const renderDocuments = (arrayItem, handleOpenItem, handleRemoveItem) => {
    return (arrayItem?.map( item => (
      <Row style={{marginBottom: '10px'}} key={item.id}>
        <Col xs={3}>
          <OpenItemButton 
            variant="link" 
            onClick={() => {
              handleOpenItem(item);
            }}
            disabled={_isNil(item.file)}
          >
            {item.file? item.file.fileName: t('Document_not_found')}
          </OpenItemButton>
        </Col>
        <Col xs={8}>
          {item.comments? item.comments: ' - '}
        </Col>
        <Col xs={1}>
          <StyledButtonLink type="button" style={{visibility: disabled? "hidden":"visible"}} onClick={() => {handleRemoveItem(item.id)}} > <RoundedRemoveDiv>  x </RoundedRemoveDiv> </StyledButtonLink>
        </Col>
      </Row>
    )))
  }

  const renderPayments = (arrayItem, handleOpenItem, handleRemoveItem) => {
    return (arrayItem?.map( item => (
      <Row style={{marginBottom: '10px'}} key={item.id}>
        <Col xs={3}>
          <OpenItemButton 
            variant="link" 
            onClick={() => {
              handleOpenItem(item);
            }}
          >
            {item.description? item.description: ' - '}
          </OpenItemButton>
        </Col>
        
        <Col xs={2}>
          <MoneyTextMask value={item.amountPayment? item.amountPayment: 0.0} />
        </Col>

        <Col xs={2}>
          {item.paymentEnabled && t("F5_S7_Budget_Select_Payments_Steps_COMPLETED")}
        </Col>
      
        <Col xs={2}>
          {item.paid && t("F5_S7_Budget_Select_Payments_Steps_PAID")}
        </Col>

        <Col xs={2}>
          {item.invoiced && t("F5_S7_Budget_Select_Payments_Invoice")}
        </Col>

        <Col xs={1}>
          <StyledButtonLink type="button" style={{visibility: (disabled || item.paid)? "hidden":"visible"}} onClick={() => {handleRemoveItem(item)}} > <RoundedRemoveDiv>  x </RoundedRemoveDiv> </StyledButtonLink>
        </Col>
      </Row>
    )))
  }

  //Detail provider

  const handleOpenModifyDetailProvider = (currentDetailProvider) => {
    handleCloseOnSave();
    setBudgetDetailAdminModalData({
      isShow: true,
      type: 0,
      title: t('F5_S7_Budget_Admins_Title1'),
      detail: {
        ...detail,
        detailProviderObj: currentDetailProvider,
        currentSelectedProvider: null,
        currentDocument: null,
        currentPayment: null
      },
      payments: payments,
    })
  };

  const handleRemoveDetailProvider = async (providerId) => {
    try {
      const response = await deleteF5S7ActivityDetailProvider(id, activity.id, detail.id, providerId);
      setDetailProviders(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  const handleOpenAddProvider = () => {
    handleCloseOnSave();
    setBudgetDetailAdminModalData({
      isShow: true,
      type: 0,
      title: t('F5_S7_Budget_Admins_Title1'),
      detail: {
        ...detail,
        detailProviderObj: null,
        currentSelectedProvider: null,
        currentDocument: null,
        currentPayment: null
      },
      payments: payments,
    })
  };

  //Select provider

  const handleOpenSelectProvider = (changeSelectedProvider) => {
    handleCloseOnSave();
    setBudgetDetailAdminModalData({
      isShow: true,
      type: 2,
      title: t('F5_S7_Budget_Admins_Title2'),
      detail: {
        ...detail,
        detailProviderObj: null,
        currentSelectedProvider: null,
        currentDocument: null,
        currentPayment: null, 
        providerSelected: changeSelectedProvider,
      },
      payments: payments,
    })  
  }

  const handleOpenUpdateSelectProvider = () => {
    handleCloseOnSave();
    setBudgetDetailAdminModalData({
      isShow: true,
      type: 2,
      title: t('F5_S7_Budget_Admins_Title2'),
      detail: {
        ...detail,
        detailProviderObj: null,
        currentSelectedProvider: selectedProvider,
        currentDocument: null,
        currentPayment: null
      },
      payments: payments,
    })  
  }

  //Documents

  const handleOpenAddDocument = () => {
    handleCloseOnSave();
    setBudgetDetailAdminModalData({
      isShow: true,
      type: 3,
      title: t('F5_S7_Budget_Admins_Title3'),
      detail: {
        ...detail,
        detailProviderObj: null,
        currentSelectedProvider: null,
        currentDocument: null,
        currentPayment: null
      },
      payments: payments,
    })  
  }

  const handleOpenModifyDocument = (currentDocument) => {
    handleCloseOnSave();
    setBudgetDetailAdminModalData({
      isShow: true,
      type: 3,
      title: t('F5_S7_Budget_Admins_Title3'),
      detail: {
        ...detail,
        detailProviderObj: null,
        currentSelectedProvider: null,
        currentDocument: currentDocument,
        currentPayment: null
      },
      payments: payments,
    })
  };

  const handleRemoveDocument = async (documentId) => {
    try {
      const response = await deleteF5S7ActivityDetailDocument(id, activity.id, detail.id, documentId);
      setDocuments(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  //Payments 

  const handleOpenAddPayment = () => {
    handleCloseOnSave();
    setBudgetDetailAdminModalData({
      isShow: true,
      type: 4,
      title: t('F5_S7_Budget_Admins_Title4'),
      detail: {
        ...detail,
        detailProviderObj: null,
        currentSelectedProvider: null,
        currentDocument: null,
        currentPayment: null
      },
      payments: payments,
    })  
  }

  const handleOpenModifyPayment = (currentPayment) => {
    handleCloseOnSave();
    setBudgetDetailAdminModalData({
      isShow: true,
      type: 4,
      title: t('F5_S7_Budget_Admins_Title4'),
      detail: {
        ...detail,
        detailProviderObj: null,
        currentSelectedProvider: null,
        currentDocument: null,
        currentPayment: currentPayment
      },
      payments: payments,
    })
  };

  const handleRemovePayment = async (payment) => {
    try {
      const response = await deleteF5S7ActivityDetailPayment(id, activity.id, detail.id, payment.id);
      setPayments(response?.data);
    } catch (e) {
      console.log(e);
    }
  }

  //Finalize and Cancel 

  const handleOpenFinalize = () => {
    handleCloseOnSave();
    setBudgetDetailAdminModalData({
      isShow: true,
      type: 5,
      title: t('F5_S7_Budget_Admins_Title5'),
      detail: {
        ...detail,
        detailProviderObj: null,
        currentSelectedProvider: null,
        currentDocument: null,
        currentPayment: null
      },
      payments: payments,
    })  
  }

  const handleCancelBudgetDetail = () => {
    setIsCancelBudgetDetailAlert(true);
  }

  const handleCancelBudgetLine = async (justification) => {
    try{
      await cancelF5S7ActivityDetail(id, activity.id, detail.id, {
        justification: justification
      })
      setDisabled(true);
    } catch(e){
      console.log(e)
    } finally {
      setIsCancelBudgetDetailAlert(false);
    }
  }
  
  return (
    <div>
      <CancelBudgetDetailModal
        show={isCancelBudgetDetailAlert}
        handleClose={() => setIsCancelBudgetDetailAlert(false)}
        handleConfirm={() => handleCancelBudgetLine()}
      />

      <StepTitle> {t('F5_S7_Budget_Admins_Step1')} </StepTitle>
      <ItemSeparator />
      {renderProviders(detailProviders, handleOpenModifyDetailProvider, handleRemoveDetailProvider)}
      <PrimaryButton onClick={() => handleOpenAddProvider()} disabled={disabled}> {t('F5_S7_Budget_Admins_AddProvider')} </PrimaryButton>

      <SeparatorDotted />

      <StepTitle> {t('F5_S7_Budget_Admins_Step2')} </StepTitle>
      <ItemSeparator />
      {
        !(selectedProvider && selectedProvider.mgmtF5S7ActivityDetailProvider)
        ?
          <PrimaryButton disabled={detail && (detail.resourceDetailState.stateEnum === f5ActivityBudgetStatesConst.PLANNED || disabled)} onClick={() => handleOpenSelectProvider(false)} > 
            {t('F5_S7_Budget_Admins_SelectProvider')} 
          </PrimaryButton>
          :
          <Col xs={10} style={{marginLeft: '-15px'}}>
            <Row>
              <Col xs={4}>
                {t('F1_S2_Budget_Provider_Name')} 
              </Col>
              <Col xs={6}>
                {selectedProvider.mgmtF5S7ActivityDetailProvider.mgmtF5S7Provider.name}
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                {t('F5_S7_Budget_Select_Provider_Steps_Total')} 
              </Col>
              <Col xs={3}>
                <MoneyTextMask value={selectedProvider.totalAmount? selectedProvider.totalAmount: 0.0} /> 
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                {t('F5_S7_Budget_Select_Provider_Steps_Confirmed1')} 
              </Col>
              <Col xs={3}>
                {selectedProvider.confirmPartnership || isResourceNonApplicantCheckeable? t('Yes') : t('No') }
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                {t('F5_S7_Budget_Select_Provider_Steps_Confirmed2')} 
              </Col>
              <Col xs={3}>
                {selectedProvider.confirmAvailability? t('Yes') : t('No') }
              </Col>
            </Row>
            <Row>
              <PrimaryButton disabled={disabled} style={{marginLeft: "15px", marginTop: '15px'}} onClick={() => handleOpenUpdateSelectProvider()} > 
                {t('F5_S7_Budget_Select_Provider_Button_Update')} 
              </PrimaryButton>
            </Row>
            <Row>
              <PrimaryButton disabled={disabled || (payments.length > 0)} style={{marginLeft: "15px", marginTop: '15px'}} onClick={() => handleOpenSelectProvider(true)} > 
                {t('F5_S7_Budget_Select_Provider_Button_Change')} 
              </PrimaryButton>
            </Row>
          </Col>
      }

      <SeparatorDotted />

      <StepTitle> {t('F5_S7_Budget_Admins_Step3')} </StepTitle>
      <ItemSeparator />
      {renderDocuments(documents, handleOpenModifyDocument, handleRemoveDocument)}
      <PrimaryButton disabled={disabled} onClick={() => handleOpenAddDocument()}> {t('F5_S7_Budget_Admins_AddDocument')} </PrimaryButton>

      <SeparatorDotted />

      <StepTitle> {t('F5_S7_Budget_Admins_Step4')} </StepTitle>
      <ItemSeparator />
      {renderPayments(payments, handleOpenModifyPayment, handleRemovePayment)}
      <PrimaryButton disabled={disabledPayments} onClick={() => handleOpenAddPayment()}> {t('F5_S7_Budget_Admins_AddPayment')} </PrimaryButton>

      <SeparatorDotted />

      <StepTitle> {t('F5_S7_Budget_Admins_Step5')} </StepTitle>
      <ItemSeparator />
      <PrimaryButton disabled={detail && (detail.resourceDetailState.stateEnum === f5ActivityBudgetStatesConst.PLANNED || disabled)} onClick={() => handleOpenFinalize()}> {t('F5_S7_Budget_Admins_Finalize')} </PrimaryButton>

      <SeparatorDotted />

      <ErrorButton disabled={disabled || (payments.length > 0)} onClick={() => handleCancelBudgetDetail()}> {t('F5_S7_Budget_Admins_Cancel')} </ErrorButton>

    </div>
  )
}

export default ContractSteps;