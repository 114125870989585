import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledSubtitle = styled.div`
  ${({ theme }) => css`
    margin-top: 25px;
    margin-bottom: 20px;
    color: ${theme.brandColors.blue};
    letter-spacing: 1px;
    font-weight: 600;
    font-size: ${props => props.size || '18px'};
    line-height: 1.3
  `}
`;

export const StyledFormLabel = styled(Form.Label)`
  padding-left: 0;
  text-align: justify;
  line-height: 1.3;
`;

export const StyledSeparator = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 50px;
  height: 2px;
  background-image: linear-gradient(to right, #bac5cc 33%, rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 6px 2px;
  background-repeat: repeat-x;
`;

export const Text = styled.div`
  line-height: 1.25;
  margin-bottom: 15px;
  text-align: justify;
`;

export const Separator = styled.div`
  margin-bottom: 20px;
`;

export const StyledContainer = styled.div`
  padding-left: 50px;
`;