export const dataTransformer = (data) => {
  const transformedData = []
  const localActivities = []

  data.activities.forEach((item, index) => {
    transformedData.push({
      title: item?.title || '',
      countryId: item?.country ? item.country.id : null,
      format: item?.format ? String(item.format) : item.format === 0 ? '0' : '',
      activityTypeId: item?.activityType ? item?.activityType.id : null,
      productCount: item?.productCount || null,
      mainProducts: item?.mainProducts || '',
      observations: item?.observations || '',
      fromDate: item?.fromDate ? new Date(item.fromDate) : '',
      toDate: item?.toDate ? new Date(item.toDate) : '',
      name: item?.name || '',
      activityNumber: item?.activityNumber || null,
    })

    localActivities.push({
      name: item?.name || '',
      number: item?.activityNumber || null,
      index,
    })
  })

  return {
    data: {
      methodology: data?.methodology || '',
      activities: transformedData,
    },
    localActivities,
  }
}
