import styled, {css} from 'styled-components';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
`;

export const FooterLeft = styled.div`
  display: flex;
  flex: 1;
`;

export const FooterCenter = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
`;

export const FooterRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const SyledTitle = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    color: ${theme.brandColors.blue};
  `}
`;

export const StyledButtonLink = styled(Button)`
  border: none;
  background: none;
  box-shadow: none !important;
  &:hover, &:active, &:focus{
    border: none !important;
    background: none !important;
    text-decoration: none !important;
  }
`;

export const DivButtonWrapper = styled.div`
  display: flex;
  color: black;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
`;

export const RoundedRemoveDiv = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.brandColors.red};
    color: white;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 15px;
    display: flex,
    align-items: center;
    justify-content: center,
    font-size: 20px;
  `}
`;

export const RoundedAddDiv = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.brandColors.blue};
    color: white;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding-bottom: 5px;
    margin-right: 10px;
  `}
`;

export const StyledSeparator = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 50px;
  height: 2px;
  background-image: linear-gradient(to right, #bac5cc 33%, rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 6px 2px;
  background-repeat: repeat-x;
`;