import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { validationSchema } from './Validations';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { saveFundSection } from 'services/funds';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import Form from 'react-bootstrap/Form'
import { RequiredMark } from 'components/CommonStyled';
import { useTranslation } from 'react-i18next';
import { Col, InputGroup, Row } from 'react-bootstrap';
import MoneyInput from 'components/MoneyInput';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';


const formInitialValues = {
  fundContribution: 0,
  otherContributions: 0,
};

const Section9 = () => {
  const {t} = useTranslation();

  const { formStore } = useRootStore();
  const { id } = useParams();

  const [saved, setSaved] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    let newValues = formInitialValues;
    if (formStore.formSectionData) {
      newValues = {
        fundContribution: formStore?.formSectionData?.[0]?.fundContribution,
        otherContributions: formStore?.formSectionData?.[0]?.otherContributions,
      }
    }
    setInitialValues(newValues);
  }, [formStore.formSectionData])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);
  }, [saved])

  const onSubmit = async (values, {resetForm}) => {
    try {
      const { complete } = await saveFundSection(id, 9, values);
      formStore.setSectionStatus(9, complete)
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid
      ? onSubmit(values, { resetForm })
      : alert(t('not_saved_alert'));
  }

  return (
    <FormLayout>
      <FormContainer
        number={9}
        title={t('F55_S9_title')}
        formId="form55section9"
        showSavedMessage={saved}
        showSaveButton
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {( {values,
              errors,
              touched,
              dirty,
              resetForm,
              setFieldValue,
              handleBlur,
              isValid }) => (
                <form
                  onSubmit={(e) => {onValidateSubmit(e, isValid, values, { resetForm })}}
                  id="form55section9"
                >
                  {dirty && (setSaved(false))}

                  <Form.Group as={Row}>
                    <Form.Label column xs={3}>
                      {t('contribution_fund')}
                      <RequiredMark>*</RequiredMark>:
                    </Form.Label>
                    <Col xs={3}>
                      <InputGroup>
                        <MoneyInput
                          name="fundContribution"
                          value={values.fundContribution}
                          onChange={(val) => setFieldValue("fundContribution", val)}
                          onBlur={handleBlur}
                          customInput={Form.Control}
                          className={
                            touched.fundContribution && errors.fundContribution
                              ? "error"
                              : null
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage
                        touched={touched?.fundContribution}
                        errors={errors?.fundContribution}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column xs={3}>
                      {t('other_contribution_fund')}
                      <RequiredMark>*</RequiredMark>:
                    </Form.Label>

                    <Col xs={3}>
                      <InputGroup>
                        <MoneyInput
                          name="otherContributions"
                          value={values.otherContributions}
                          onChange={(val) => setFieldValue("otherContributions", val)}
                          onBlur={handleBlur}
                          customInput={Form.Control}
                          className={
                            touched.otherContributions && errors.otherContributions
                              ? "error"
                              : null
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage
                        touched={touched?.otherContributions}
                        errors={errors?.otherContributions}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column xs={3}>
                      {t('total_budget_fund')}:
                    </Form.Label>
                    <Col xs={3}>
                      <InputGroup>
                        <MoneyInput
                          value={(values.fundContribution + values.otherContributions)?.toFixed(2)}
                          disabled
                          customInput={Form.Control}
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </form>
              )}
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section9);

