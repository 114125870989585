import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
  StyledDiv,
  TableSeparator,
} from '../../CommonStyled/Tabs.styled'
import CountryReportHeader from '../components/CountryReportHeader'
import { getCountryReport } from 'services/initiative'
import CountryInitiativeReport from '../components/CountryReport';
import { getCountries } from 'services/initiative'
import _find from 'lodash/find';
import { AdelanteLogo } from 'components/PublicHeader/PublicHeader.styled'
import { ReportTitle } from '../components/CountryReport.styled'
import logoEN from "assets/logo-A2-EN.png";
import logoES from "assets/logo-A2-ES.png";
import _orderBy from 'lodash/orderBy'
import { getFundsCountryReport } from 'services/fundsStatistics'

const FundsCountryReport = () => {
  const { t, i18n} = useTranslation()

  const [countriesOptions, setCountriesOptions] = useState([])

  const [countries, setCountries] = useState([]);
  const [year, setYear] = useState([]);

  const [data, setData] = useState(null);
  const [waiting, setWaiting] = useState(false);

  const getCountryData = async (params) => {
    try {
      setWaiting(true)
      setCountries(JSON.parse("[" + params?.countryIdList + "]"))
      setYear(params?.year)
      const response = await getFundsCountryReport(params);
      setData(response);
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  };

  const getCountriesOptions = async () => {
    try {
      const response = await getCountries()
      const orderedCountries = _orderBy(response?.data, [(field) => i18n.t(field.name)], ['asc'])
      setCountriesOptions(orderedCountries.map(item => {
        return {
          label: t(item.name),
          value: item.id,
          flag: item.flag,
        }
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const getCountryNameById = (countryId) => {
    const selectedCountry = _find(countriesOptions, country => country.value === countryId);
    return selectedCountry?.label;
  };

  const getCountryFlagById = (countryId) => {
    const selectedCountry = _find(countriesOptions, country => country.value === countryId);
    return selectedCountry?.flag;
  };

  useEffect(() => {
    getCountriesOptions()
  }, [i18n?.language])

  return (
    <StyledDiv>
    <Row>
      <CountryReportHeader
        countryOptions={countriesOptions}
        data={data}
        getData={getCountryData}
        waiting={waiting}
      />
    </Row>
    <Col>
      <div id="pdfTargetDiv" style={{maxWidth: '800px'}}>
        {data?.map((countryInitiatives, index) => {
          if (
            countryInitiatives.beneficiaries.length === 0 && 
            countryInitiatives.firstProviders.length === 0 && 
            countryInitiatives.secondProviders.length === 0
          ) {
            return (
              <>
                {t('No_data_available_2')}
              </>
            )
          }
          const countryName = getCountryNameById(countries[index])
          const countryFlag = getCountryFlagById(countries[index])
          return (
            <div aria-label={`pdf-page-${index}`} style={{width: '1200px'}}>
              <div style={{ textAlign: 'center', marginTop: '40pt'}}>
                <AdelanteLogo
                  src={(i18n.language === 'en')? logoEN:logoES}
                  alt="Adelante2 logo"
                  style={{ objectFit: 'contain'}}
                />
                <div style={{marginTop: '20px'}}>
                  <ReportTitle>{t('triang_coop_funds_proj_2')}</ReportTitle>
                  <ReportTitle>{t('ben_country_report_title2', { countryName: countryName})}</ReportTitle>
                </div>
              </div>
              <TableSeparator />
              <CountryInitiativeReport
                country={countryName}
                countryFlag={countryFlag}
                year={year}
                roleName={t('F1_S2_Provider1')}
                data={countryInitiatives?.firstProviders}
                waiting={waiting}
              />
              <TableSeparator />
              <CountryInitiativeReport
                country={countryName}
                countryFlag={countryFlag}
                year={year}
                roleName={t('F1_S2_Provider2')}
                data={countryInitiatives?.secondProviders}
                waiting={waiting}
              />
              <TableSeparator />
              <CountryInitiativeReport
                country={countryName}
                countryFlag={countryFlag}
                year={year}
                roleName={t('coordRole0')}
                data={countryInitiatives?.beneficiaries}
                waiting={waiting}
              />
            </div>
          )
        })}
      </div>
    </Col>
    </StyledDiv>
  )
}

export default FundsCountryReport
