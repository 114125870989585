import React, { useState, useEffect } from 'react'
import { getFunds } from 'services/funds'
import Funds from './Funds'
import CreateFundDialog from './CreateFundDialog/CreateFundDialog'

const FundsContainer = () => {
  const [loading, setLoading] = useState(true)
  const [initiatives, setInitiatives] = useState([])
  const [filters, setFilters] = useState({})
  const [isCreationVisible, setIsCreationVisible] = useState(false);

  const getData = async () => {
    setLoading(true)
    try {
      const funds = await getFunds(filters)
      setInitiatives(funds)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const handleFilterByType = (e) => {
    setFilters({ ...filters, fundTypeId: e?.target?.value})
  }

  const handleFilterByState = (e) => {
    setFilters({ ...filters, fundState: e?.target?.value})
  }

  const onAddFund = () => {
    setIsCreationVisible(true)
  }

  return (
    <>
      <CreateFundDialog
        isVisible={isCreationVisible}
        onCloseModal={() => setIsCreationVisible(false)}
      />
      <Funds
        loading={loading}
        initiatives={initiatives}
        filters={filters}
        onFilterByType={handleFilterByType}
        onFilterByState={handleFilterByState}
        getData={getData}
        onAddFund={onAddFund}
      />
    </>
  )
}

export default FundsContainer
