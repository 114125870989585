import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  ownerList:  Yup.array()
    .of(
      Yup.object().shape({
        entityName: Yup.string(),
        degree: Yup.string()
          .min(10, 'F1_S6_validation7')
          .max(650,  'F1_S6_validation9')
          .nullable(true),
      })
    ),
  benList: Yup.array()
    .of(
      Yup.object().shape({
        beneficiaryTypeId: Yup.string()
          .typeError('F3_S10_validation1'),
        beneficiaryDescription: Yup.string()
          .min(10, 'F1_S6_validation7')
          .max(650, 'F1_S6_validation9')
          .nullable(true),
        beneficiaryJustification: Yup.string()
          .min(10, 'F1_S6_validation7')
          .max(650, 'F1_S6_validation9')
          .nullable(true),
        beneficiaryProspect: Yup.string()
          .min(10, 'F1_S6_validation7')
          .max(650, 'F1_S6_validation9')
          .nullable(true),
        answersF3S10BenCountryList: Yup.array()
          .of(
            Yup.object().shape({
              countryId: Yup.string(),
              quantity: Yup.number().typeError('F3_S10_validation2').min(1, 'F3_S10_validation3').integer(),
            })
          )
      })
    )
    .min(1, 'F3_S10_validation4')
});
