import styled, { css } from 'styled-components';
import Button from 'react-bootstrap/Button';

export const TableHeader = styled.th`
  ${({ theme }) => css`
    width: ${props => props.width}%;
    font-weight: 500;
    color: ${theme.colors.gray[1]};
  `}
`;

export const ResourceNameSpan = styled.span`
  ${({ theme }) => css`
    font-weight: 500;
    font-size: ${props => props.total? '19px':'17px'};
    color: ${theme.colors.gray[1]};
  `}
`;

export const ResourceNumberSpan = styled.span`
  ${({ theme }) => css`
    font-weight: ${props => props.weight || 450};
    font-size: ${props => props.weight?  '19px':'17px'};
    color: ${theme.brandColors.green};
    float: right;
  `}
`;

export const RowBorder = styled.div`
  ${({ theme }) => css`
    border-top: 3px dotted ${theme.colors.gray[1]};
    width: 100%;
  `}
`;

export const BorderlessTr = styled.tr`
  border-top: hidden;
`;

export const MarginlessTd = styled.td`
  margin: 0 !important;
  padding: 0 10px 0 10px !important;
`;

export const BudgetLineButton = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  font-weight: 600;
  text-decoration: underline;
  font-size: 20px;
`;

export const StyledButtonLink = styled(Button)`
  border: none;
  background: none;
  box-shadow: none !important;
  &:hover, &:active, &:focus{
    border: none !important;
    background: none !important;
    text-decoration: none !important;
  }
`;

export const RoundedRemoveDiv = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.brandColors.red};
    color: white;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 15px;
    text-align: center;
    font-size: 20px;
    margin-right: 5px;
  `}
`;