import React from 'react'
import { useTranslation } from 'react-i18next'
import { deleteF5S14Material } from 'services/initiative'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import { SeparatorDotted } from 'components/CommonStyled'
import { Row, Col } from 'react-bootstrap'
import { PrimaryButton } from 'components/Buttons'
import Accordion from 'react-bootstrap/Accordion'
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from 'components/CustomAccordion'
import { formatDateDDMMYY } from 'helpers/dateHelpers'
import { renderItemsByType } from './Constants'

const DifussionMaterial = ({
  items,
  handleOpenModal,
  setMaterialList,
  handleClose,
  setModalInfo,
}) => {
  const { t } = useTranslation()
  const { id } = useParams()

  const handleDelete = async (materialId) => {
    try {
      const response = await deleteF5S14Material(id, materialId)
      setMaterialList(response.materials)
    } catch (error) {
      console.log(error)
    }
  }

  const renderActivities = (items) => (
    <Accordion>
      {items?.map(({ activity, materials }, key) => (
        <AccordionItem key={key + 1} style={{ marginBottom: '10px' }}>
          <AccordionItemHeader>
            <AccordionTitle eventKey={key + 1}>
              {t('F1_S7_Accordion') + '  '}
              {activity.name + '  '}
              {`- ${
                activity.activityType
                  ? t(`activity_types_${activity.activityType.id}`)
                  : ' '
              } (${
                activity.fromDate ? formatDateDDMMYY(activity.fromDate) : ' '
              })`}
            </AccordionTitle>
          </AccordionItemHeader>
          <Accordion.Collapse eventKey={key + 1}>
            <AccordionItemBody style={{ marginLeft: '30px' }}>
              <>
                <Row style={{ marginBottom: '20px' }}>
                  <Col>
                    {`${t('F1_S7_type')}: ${
                      activity.activityType
                        ? t(`activity_types_${activity.activityType.id}`)
                        : '-'
                    }`}
                  </Col>
                  <Col>
                    {`${t('F1_S7_start_day')}: ${
                      activity.fromDate
                        ? formatDateDDMMYY(activity.fromDate)
                        : '-'
                    }`}
                  </Col>
                  <Col>
                    {`${t('F1_S7_End_day')}: ${
                      activity.toDate ? formatDateDDMMYY(activity.toDate) : '-'
                    }`}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {`${t('F1_S7_Format')}: ${
                      activity.format === 0
                        ? t('F1_S7_Format0')
                        : activity.format === 1
                        ? t('F1_S7_Format1')
                        : t('F1_S7_Format2')
                    }`}
                  </Col>
                  <Col>
                    {`${t('F1_S7_Country_activity')}: ${
                      activity.country ? t(activity.country.name) : '-'
                    }`}
                  </Col>
                </Row>
                <SeparatorDotted />
                {renderItemsByType(isEmpty(materials) ? [] : materials, handleOpenModal, handleClose, handleDelete, setModalInfo)}
              </>
            </AccordionItemBody>
          </Accordion.Collapse>
        </AccordionItem>
      ))}
    </Accordion>
  )


  return (
    <>
      {renderActivities(items)}
      <PrimaryButton
        onClick={() =>
          handleOpenModal({
            show: true,
            isEdit: false,
            data: null,
          })
        }
      >
        {t('F5_S14_add_difussion_material_button')}
      </PrimaryButton>
      <SeparatorDotted />
    </>
  )
}

export default DifussionMaterial
