export const formInitialValues = {
  primaryTargetList: [
    {
      targetGoalId: '',
      targetJustification: '',
      targetIndicatorList: null,
    }
  ],
  secondaryTargetList: [
    {
      targetGoalId: '',
      targetJustification: '',
      targetIndicatorList: null,
    }
  ],
  goal10TypeId: "10",
  goal10Justification: "",
  goal10TargetList: [],
  goal17TypeId: "17",
  goal17Justification: "",
  goal17TargetList: [
    {
      targetGoalId: '',
      targetJustification: '',
      targetIndicatorList: null,
    }
  ]
};
