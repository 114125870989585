import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react-lite';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import { useTranslation } from 'react-i18next';
import { Formik } from "formik";
import { validationSchema } from "./Validations";
import { useRootStore } from "stores";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { FormFielErrorMessage, RequiredMark } from "components/CommonStyled";
import {
  StyledFormLabel, StyledRadioLabel,
} from "./Styled";
import { saveFundSection } from 'services/funds';
import { useConstantsFunds } from 'customHooks/useConstantsFunds';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const Section2 = () => {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState({
    "entityName": "",
    "acronym": "",
    "entityTypeId": "",
    "role": "",
    "countryId": ""
  });

  const [saved, setSaved] = useState(false);

  const { formStore, initiativeStore } = useRootStore();
  const { id } = useParams();
  const { entityTypes, la_ca_eu_countries: countries } = useConstantsFunds();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (formStore.formSectionData) {
      const newData = { ...formStore.formSectionData[0] }
      newData.entityTypeId = newData.entityType
        ? newData.entityType.id
        : "";
        newData.countryId = newData.country
        ? newData.country.id
        : "";
      delete newData.id;
      delete newData.complete;
      delete newData.entityType;
      delete newData.country;
      setInitialValues({
        ...newData,
      });
    }
  }, [formStore.formSectionData, initiativeStore?.statusName])

  const onSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFundSection(id, 2, values);
      formStore.setSectionStatus(2, complete);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid ? onSubmit(values, { resetForm }) : alert(t("not_saved_alert"));
  };

  return (
    <FormLayout>
      <FormContainer
        number={2}
        title={t('F1_s2_title')}
        formId="form55section2"
        showSavedMessage={saved}
        showSaveButton
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isValid,
            resetForm,
            handleChange,
            handleBlur,
          }) => {
            if (dirty) {
              setSaved(false)
            }
            return (
              <form
                onSubmit={(e) => {
                  onValidateSubmit(e, isValid, values, { resetForm });
                }}
                id="form55section2"
              >
                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Entity_name")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="entityName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.entityName}
                      className={
                        touched.entityName && errors.entityName ? "error" : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.entityName}
                      errors={errors.entityName}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Official_acronym")}:
                  </StyledFormLabel>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="acronym"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.acronym}
                      className={
                        touched.acronym && errors.acronym ? "error" : null
                      }
                    />
                    <FormErrorMessage
                      touched={touched.acronym}
                      errors={errors.acronym}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Type_of_entity")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={4}>
                    <Form.Control
                      name="entityTypeId"
                      as="select"
                      onChange={handleChange}
                      value={values.entityTypeId}
                    >
                      <option value="">{t("Placeholder_select")}</option>
                      {entityTypes?.map((entity) => (
                        <option key={entity.id} value={entity.id}>
                          {t(`entity_type_${entity.id}`)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched={touched.entityTypeId}
                      errors={errors.entityTypeId}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: "center" }}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Role")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={8} style={{ marginTop: "10px" }}>
                    <StyledRadioLabel>
                      <input
                        type="radio"
                        name="role"
                        value={"0"}
                        checked={values.role == 0}
                        onChange={handleChange}
                        style={{ marginRight: "10px" }}
                      />
                      {t("F1_S2_Recipient")}
                    </StyledRadioLabel>

                    <StyledRadioLabel>
                      <input
                        type="radio"
                        name="role"
                        value={"1"}
                        checked={values.role == 1}
                        onChange={handleChange}
                        style={{ marginRight: "10px" }}
                      />
                      {t("F1_S2_Provider1")}
                    </StyledRadioLabel>

                    <StyledRadioLabel>
                      <input
                        type="radio"
                        name="role"
                        value={"2"}
                        checked={values.role == 2}
                        onChange={handleChange}
                        style={{ marginRight: "10px" }}
                      />
                      {t("F1_S2_Provider2")}
                    </StyledRadioLabel>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Country")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={4}>
                    <Form.Control
                      name="countryId"
                      as="select"
                      onChange={handleChange}
                      value={values.countryId}
                    >
                      <option value="">{t("Placeholder_select")}</option>
                      {countries?.map((country) => (
                        <option key={country.id} value={country.id}>
                          {t(country.name)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched={touched.countryId}
                      errors={errors.countryId}
                    />
                  </Col>
                </Form.Group>
              </form>
            );
          }}
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section2);