import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Formik } from 'formik'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { validationSchema } from './Validations'
import {
  FormFielErrorMessage,
  RequiredMark,
  CenteredModalButtonContainer,
} from 'components/CommonStyled'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CustomModal from 'components/Modal'
import { ErrorButton, PrimaryButton } from 'components/Buttons'
import ConfirmCloseModal from 'components/ConfirmCloseModal'
import { addFundGuest } from 'services/funds'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const AddPersonForm = (props) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [closeModalShow, setCloseModalShow] = useState(false)
  const [sending, setSending] = useState(false)
  const [modal, setModal] = useState({
    isOpen: false,
    message: '',
    type: 'default',
  })

  useEffect(() => {}, [])

  const handleSubmit = async (values) => {
    setSending(true)
    let response
    try {
      response = await addFundGuest(id, {
        email: values?.email,
        readOnly: !values?.canEdit
      })
    } catch (error) {
      console.log(error)
    }

    if (response) {
      setModal({
        ...modal,
        message: t('Success'),
        type: 'success',
        isOpen: true,
      })
      setTimeout(function () {
        setModal({ isOpen: false, message: '', type: 'info' })
        setSending(false)
        window.location.replace('')
        props.onHide()
      }, 2000)
    } else {
      setSending(false)
    }
  }

  return (
    <Modal {...props} centered size="lg" animation={false}>
      <Modal.Body>
        {modal.isOpen && (
          <CustomModal
            show={modal.isOpen}
            onHide={() => {
              setModal({ isOpen: false, message: '', type: 'info' })
              setSending(false)
              window.location.replace('')
              props.onHide()
            }}
            message={modal.message}
            type={modal.type}
          />
        )}

        {closeModalShow && (
          <ConfirmCloseModal
            show={closeModalShow}
            handleCloseConfirm={() => {
              setCloseModalShow(false)
            }}
            handleActionOnConfirm={() => {
              props.onHide()
            }}
            text={t('Confirmation close modal')}
          />
        )}

        <Formik
          enableReinitialize
          initialValues={{ email: '', canEdit: false }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => {
            const isAlreadyIncluded = props?.addedUsersList?.map(item => item?.user?.email)?.includes(values.email);
            return (
              <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: '20px', textAlign: 'justify' }}>
                  <Form.Check
                    name="canEdit"
                    type="checkbox"
                    checked={values.canEdit}
                    value={values.canEdit}
                    onChange={handleChange}
                    label={t("funds_Authorize_admin")}
                  />
                </div>

                <Form.Group as={Row} style={{ alignItems: 'center' }}>
                  <Form.Label column lg={4}>
                    {t('F1_S2_Email')}
                    <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className={touched.email && errors.email ? 'error' : null}
                    />
                    <FormErrorMessage
                      touched={touched.email}
                      errors={errors.email}
                    />
                    {isAlreadyIncluded && <FormFielErrorMessage>{t('user_invite_duplicated_error')}</FormFielErrorMessage>}
                  </Col>
                </Form.Group>

                <CenteredModalButtonContainer>
                  <ErrorButton
                    disabled={sending}
                    onClick={() => setCloseModalShow(true)}
                  >
                    {t('Button_cancel')}
                  </ErrorButton>
                  <PrimaryButton
                    type="submit"
                    disabled={sending || isAlreadyIncluded}
                    style={{
                      marginTop: '15px',
                      marginBottom: '15px',
                      marginLeft: '15px',
                    }}
                  >
                    {t('Button_save')}
                  </PrimaryButton>
                </CenteredModalButtonContainer>
              </form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default AddPersonForm
