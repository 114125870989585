import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {StyledTitle, StyledRow, StyledCol, StyledText, StyledDiv, StyledColTitle, StyledDivTotal} from 'components/Forms/Form5/Section7/ResourcesF3Form/ResourcesF3Form.styled';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MoneyTextMask from 'components/MoneyTextMask';
import { StyledTitle as StyledGlobalTitle } from '../CommonStyles';

const Section7BudgetF3 = ({ activity, data }) => {
  const {t} = useTranslation();
  const [formValues, setFormValues] = useState([]);
  const [activityTotal, setActivityTotal] = useState({
    ueBudget: 0,
    counterpartBudget: 0,
    totalCost: 0,
  });

  useEffect(() => {
    if (data) {
      let total = {
        ueBudget: 0,
        counterpartBudget: 0,
        totalCost: 0,
      }
      data.forEach(({ueBudget, counterpartBudget, totalCost}) => {
        total.ueBudget += ueBudget;
        total.counterpartBudget += counterpartBudget;
        total.totalCost += totalCost;
      })
      setActivityTotal(total);
      setFormValues(data);
    }
  }, [data]);

  return (
    <div>
      <StyledGlobalTitle style={{marginBottom: '30px'}}> {t('F1_S7_Accordion')} - {activity ? activity.name : ''} - {t('F5_S9_Subtitle_consolidated_budget')} </StyledGlobalTitle>

      {formValues.length !== 0 && (
        <Row>
          <Col sm={6}>
          </Col>
          <Col sm={6} style={{display:'flex'}}>
            <StyledColTitle sm={4}>
              {t('F3_S7_Resource_column_EU')}
            </StyledColTitle>
            <StyledColTitle sm={4}>
              {t('F3_S7_Resource_column_counterpart')}
            </StyledColTitle>
            <StyledColTitle sm={4}>
              {t('F3_S7_Resource_column_total')}
            </StyledColTitle>
          </Col>
        </Row>
      )}

      {formValues && formValues.map(({resourceType, details}) =>( 
        details.map(({ueOrCounterpart, totalMoney, description, file})=> (
         
         <StyledRow>
            <Col sm={6}>
              <StyledTitle>
                {t(`resource_type_${resourceType.id}`)}
              </StyledTitle>
            </Col>
            <Col sm={6} style={{display:'flex'}}>
              <StyledCol sm={4}>
                <MoneyTextMask value={ueOrCounterpart == 0 ? totalMoney: "-"}/>
              </StyledCol>
              <StyledCol sm={4}>
              <MoneyTextMask value={ueOrCounterpart == 1 ? totalMoney: "-"}/>
              </StyledCol>
              <StyledCol sm={4}>
                <MoneyTextMask value={totalMoney}/>
              </StyledCol>
            </Col>
            <StyledDiv> 
              {t('Description')}:
              <StyledText>
                {description? description: "-" }
              </StyledText>
            </StyledDiv>   
          </StyledRow>

        ))  
      ))}

      {formValues.length !== 0 &&
        <StyledDivTotal>
            <Col sm={6} style={{fontSize: '18px'}}>
              {t('F3_S7_Resource_total_activity')+" "+ (activity ? activity.name : '')}
            </Col>
            <Col sm={6} style={{display:'flex'}}>
                <StyledCol sm={4}>
                  <MoneyTextMask value={activityTotal.ueBudget} />
                </StyledCol>
                <StyledCol sm={4}>
                  <MoneyTextMask value={activityTotal.counterpartBudget} />
                </StyledCol>
                <StyledCol sm={4}>
                  <MoneyTextMask value={activityTotal.totalCost} />
                </StyledCol>
            </Col>
        </StyledDivTotal>}
    </div>
  )

}

export default Section7BudgetF3;
