export const formInitialValues = [
    {
      entityName: '',
      acronym: '',
      entityTypeId: {
        id: '1',
        name: "Public - national level",
      },
      role: "0",
      optionA: false,
      optionB: false,
      optionC: false,
      optionD: false,
      other: "",
      recipient: '',
      cityName: '',
      stateName: '',     
      countryId: {
        id: '4',
        name: 'Aphganistan',
      },
      web: '',
      firstNameCoApplicant: '',
      lastNameCoApplicant: '',
      position: '',
      email: '',
      phone:'',
      auth: false,
    }
  ];