import React, { useState, useEffect } from 'react'
import _chunk from 'lodash/chunk'
import { getActivitiesByCountrySummary } from 'services/initiative'
import { useTranslation } from 'react-i18next'

import Header2 from '../components/Header2'
import Table2 from '../components/Table2'
import Row from 'react-bootstrap/Row'
import {
  StyledDiv,
  StyledRow,
  StyledRow2,
} from '../../CommonStyled/Tabs.styled'
import { T6RenderRows, orderActivitiesByCountry } from '../helpers/helpers'
import Table6 from 'pages/statistics/Entities/components/Table6'
import { getFundsActivitySummaryByCountry } from 'services/fundsStatistics'

const ActivitiesByCountrySummary = () => {
  const { t } = useTranslation()

  const [data, setData] = useState([])
  const [waiting, setWaiting] = useState(false)

  const [data2, setData2] = useState(null)
  const [waiting2, setWaiting2] = useState(false)

  const getData = async () => {
    setWaiting(true)
    try {
      let response = await getActivitiesByCountrySummary({})
      response = orderActivitiesByCountry(response)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  const getData2 = async () => {
    setWaiting2(true)
    try {
      let response = await getFundsActivitySummaryByCountry({})
      response = orderActivitiesByCountry(response)
      setData2(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting2(false)
    }
  }

  useEffect(() => {
    getData()
    getData2()
  }, [])

  return (
    <StyledDiv>
      <Row>
        <Header2
          label={t('Statistics_activities_tab2')}
          data={data}
          data2={data2}
          waiting={waiting}
        />
      </Row>
      <StyledRow style={{ marginTop: '-72px' }}>
        <Table2
          data={data}
          waiting={waiting}
          setData={setData}
        />
      </StyledRow>
      <StyledRow2>
        <Table6
          title={t('Statistics_activities_tab2')}
          data={data2}
          waiting={waiting2}
          renderRows={T6RenderRows}
          setData={setData2}
          customWidth='63%'
          customWidthRow1='84%'
          customWidthRow2='16%'
          orderField='country'
          total={true}
        />
      </StyledRow2>
      <StyledRow2>{t('Statistics_footnote_4')}</StyledRow2>
    </StyledDiv>
  )
}

export default ActivitiesByCountrySummary