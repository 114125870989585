import React, { useState } from 'react';
import { useRootStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { f3statusNamesConst } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { StyledExportButton } from 'components/Forms/Form1/Form1Footer/Form1Footer.styled';
import PDFGenerationModal from 'components/PDFGenerationModal';
import { 
  StyledFooter,
  FooterContainer
} from '../../Form3/Form3Footer/Form3Footer.styled'

const Form4Footer = () => {
  const { t } = useTranslation(); 
  const { initiativeStore } = useRootStore();
  const { id } = useParams();

  const [displayPDFModal, setDisplayPDFModal] = useState(false);

  const handleOpenPDFModal = () => setDisplayPDFModal(true);
  const handleClosePDFModal = () => setDisplayPDFModal(false);

  return (
    <>
      <PDFGenerationModal
        show={displayPDFModal}
        onClose={handleClosePDFModal}
        initiativeId={id}
        initiativeCode={initiativeStore?.code}
        formId={3}
      />

      <FooterContainer>
        <StyledFooter>
          <StyledExportButton onClick={handleOpenPDFModal}>
            {t('pdf_gen_export')}
          </StyledExportButton>
        </StyledFooter> 
      </FooterContainer>
    </>
  )
}

export default observer(Form4Footer);