import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';
import htmlParser from 'html-react-parser';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledNumberCircle, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  StyledIntroParagraph,
  StyledSubtitle,
  LinedSeparator,
  StyledGoal,
} from 'components/Forms/Form4/Section11/Section11.styled';
import Form from 'react-bootstrap/Form';
import TextValue from 'components/TextValue';
import TargetForm from 'components/Forms/Form4/Section11/TargetForm';
import FakeAccordionItem from '../../FakeAccordionItem';
import { SeparatorDotted } from 'components/CommonStyled';

const Section11 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`11 - ${t('F3_S11_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <StyledIntroParagraph>
          {htmlParser(t('F3_S11_Intro'))}
        </StyledIntroParagraph>
        
        <StyledSubtitle size="20px"> {htmlParser(t('F3_S11_Subtitle_SDG_primary'))} </StyledSubtitle>
        <LinedSeparator />
        <StyledGoal> {formValues.goalPrimaryType? t(`SDG_Goal${formValues.goalPrimaryType.id}`): ' - '} </StyledGoal>

        <Form.Group>
          <Form.Label>{t('F3_S11_Justification')}:</Form.Label>
          <TextValue value={formValues.primaryJustification} />
        </Form.Group> 

        {(formValues && formValues.goalPrimaryType && formValues.primaryTargetList.map((target, key) => (
          <React.Fragment key={key}>
            <FakeAccordionItem title={`${t('F3_S11_Target')} ${key+1}`} noMargin>
              <TargetForm data={target}/>  
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}

        <SeparatorDotted />

        <StyledSubtitle> {t('F3_S11_Subtitle_SDG_secondary')}:</StyledSubtitle>
        <LinedSeparator />
        <StyledGoal> {formValues.goalSecondaryType? t(`SDG_Goal${formValues.goalSecondaryType.id}`): ' - '} </StyledGoal>

        <Form.Group>
          <Form.Label>{t('F3_S11_Justification')}:</Form.Label>
          <TextValue value={formValues.secondaryJustification} />
        </Form.Group> 

        {(formValues && formValues.goalSecondaryType && formValues.secondaryTargetList.map((target, key) => (
          <React.Fragment key={key}>
            <FakeAccordionItem title={`${t('F3_S11_Target')} ${key+1}`} noMargin>
              <TargetForm data={target}/>  
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}
        
        <SeparatorDotted />

        <StyledSubtitle> {t('F3_S11_Subtitle_SDG_10')} </StyledSubtitle>
        <LinedSeparator />
        <StyledGoal> {formValues.goal10Type? t(`SDG_Goal${formValues.goal10Type.id}`): ' - '} </StyledGoal>
      
        <Form.Group>
          <Form.Label>{t('F3_S11_Justification')}:</Form.Label>
          <TextValue value={formValues.goal10Justification} />
        </Form.Group> 

        {(formValues && formValues.goal10Type && formValues.goal10TargetList.map((target, key) => (
          <React.Fragment key={key}>
            <FakeAccordionItem title={`${t('F3_S11_Target')} ${key+1}`} noMargin>
              <TargetForm data={target}/>  
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}
 
        <SeparatorDotted />

        <StyledSubtitle> {t('F3_S11_Subtitle_SDG_17')} </StyledSubtitle>
        <LinedSeparator />
        <StyledGoal> {formValues.goal17Type? t(`SDG_Goal${formValues.goal17Type.id}`): ' - '} </StyledGoal>

        <Form.Group>
          <Form.Label>{t('F3_S11_Justification')}:</Form.Label>
          <TextValue value={formValues.goal17Justification} />
        </Form.Group> 

        {(formValues && formValues.goal17Type && formValues.goal17TargetList.map((target, key) => (
          <React.Fragment key={key}>
            <FakeAccordionItem title={`${t('F3_S11_Target')} ${key+1}`} noMargin>
              <TargetForm data={target} />   
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}
      </StyledBody>
    </SectionContainer>
  )
}


export default Section11;