import React, {useState} from 'react';
import { observer } from 'mobx-react-lite';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';

import Modal from 'react-bootstrap/Modal';
import { ModalFooter, FooterLeft, FooterCenter, FooterRight } from './SendToMainAssessorModal.styled';
import { ErrorButton, PrimaryButton } from 'components/Buttons';

const SendToMainAssessorModal = ({ show, onClose, onSend, setError, isEud }) => {
  const [sending, setSending] = useState(false);
  const { t } = useTranslation();

  const sendAction = async () => {
    try {
      setSending(true);
      await onSend();
      onClose();
    } catch (error) {
      setError();
      onClose();
      console.log(error);
    } finally {
      setSending(false);
    }
  }

  return (
    <Modal show={show} onHide={onClose} size="lg" animation={false}>
      <Modal.Body>
        {htmlParser(isEud? t('F7A_confirmation_to_send_EUD'): t('F6_confirmation_to_send_AT'))}
      </Modal.Body>

      <ModalFooter>
        <FooterLeft />
        <FooterCenter>
          <PrimaryButton disabled={sending} onClick={() => sendAction()}>{t('Yes')}</PrimaryButton>
          <ErrorButton disabled={sending} onClick={onClose}>{t('No')}</ErrorButton>
        </FooterCenter>
        <FooterRight />
      </ModalFooter>
    </Modal>
  )
}

SendToMainAssessorModal.propTypes = {
  show: Proptypes.bool,
  onClose: Proptypes.func,
  onSend: Proptypes.func,
  setError: Proptypes.func,
};

export default observer(SendToMainAssessorModal);
