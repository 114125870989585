import * as Yup from 'yup'
import i18n from 'i18n'

const yesterday = new Date(Date.now() - 86400000)

export const validationSchema = Yup.object().shape(
  {
    activityTypeId: Yup.string().required('F3_S7_validation9'),
    description: Yup.string()
      .min(10, 'F3_S7_validation10')
      .max(1300, 'F3_S7_validation11')
      .required('F5_S7_required'),
    fromDate: Yup.date()
      .min(yesterday, 'F1_S7_validation3')
      .typeError('F1_S7_validation9'),
    toDate: Yup.date()
      .min(Yup.ref('fromDate'), 'F1_S7_validation4')
      .typeError('F1_S7_validation9'),
    duration: Yup.number().when('activityTypeId', {
      is: (activityType) => activityType !== '4' && activityType !== '7',
      then: Yup.number()
        .min(0, 'F1_S7_validation10')
        .required('F1_S7_validation11'),
      otherwise: Yup.number().nullable(true),
    }),
    participants: Yup.number().when('activityTypeId', {
      is: (activityType) => activityType != '4' && activityType != '7',
      then: Yup.number()
        .min(0, 'F1_S7_validation10')
        .required('F1_S7_validation11'),
      otherwise: Yup.number().nullable(true),
    }),
    experts: Yup.number().when('activityTypeId', {
      is: (activityType) => activityType === '4',
      then: Yup.number()
        .min(0, 'F1_S7_validation10')
        .required('F1_S7_validation11'),
      otherwise: Yup.number().nullable(true),
    }),
    daysRemote: Yup.number().when(['activityTypeId', 'daysOnsite'], {
      is: (activityType, days) =>
        activityType === '4' && !(Number.parseInt(days) > 0),
      then: Yup.number()
        .min(0, 'F1_S7_validation10')
        .required('F1_S7_validation11'),
      otherwise: Yup.number().nullable(true),
    }),
    daysOnsite: Yup.number().when(['activityTypeId', 'daysRemote'], {
      is: (activityType, days) =>
        activityType === '4' && !(Number.parseInt(days) > 0),
      then: Yup.number()
        .min(0, 'F1_S7_validation10')
        .required('F1_S7_validation11'),
      otherwise: Yup.number().nullable(true),
    }),
    format: Yup.string()
      .oneOf(['0', '1', '2'])
      .required('F1_S7_validation20'),
    countryId: Yup.string().when(['format'], {
      is: (format) => format === '0' || format === '1',
      then: Yup.string().required('F1_S7_validation12'),
      otherwise: Yup.string().nullable(true),
    }),
    cityName: Yup.string().when(['format'], {
      is: (format) => format === '0' || format === '1',
      then: Yup.string()
        .min(3, 'F1_S2_validation11')
        .max(100, 'F1_S2_validation12')
        .required('F1_S7_validation13'),
      otherwise: Yup.string().nullable(true),
    }),
    stateName: Yup.string()
      .min(3, 'F1_S2_validation13')
      .max(100, 'F1_S2_validation14'),
    countriesAdditional: Yup.string()
      .max(300, 'F1_S7_validation14')
      .nullable(true),
    justification: Yup.string()
      .min(10, 'F1_S7_validation16')
      .max(650, 'F1_S7_validation17'),
    learning: Yup.string()
      .min(10, 'F3_S7_validation10')
      .max(1300, 'F3_S7_validation11'),
    generalAlignment: Yup.string()
      .min(10, 'F3_S7_validation10')
      .max(1300, 'F3_S7_validation11'),
    generalParticipationMembers: Yup.string()
      .min(10, 'F3_S7_validation10')
      .max(1300, 'F3_S7_validation11'),
    generalProposalCrossIssues: Yup.string()
      .min(10, 'F3_S7_validation10')
      .max(1300, 'F3_S7_validation11'),
    descSchedule: Yup.string()
      .min(10, 'F3_S7_validation10')
      .max(1300, 'F3_S7_validation11'),
    profileList: Yup.array()
      .of(
        Yup.object().shape({
          skills: Yup.string()
            .min(2, 'F3_S7_validation4')
            .max(650, 'F3_S7_validation5')
            .required('F5_S7_required'),
          generalExp: Yup.string()
            .min(2, 'F3_S7_validation4')
            .max(650, 'F3_S7_validation5')
            .required('F5_S7_required'),
          specificExp: Yup.string()
            .min(2, 'F3_S7_validation4')
            .max(650, 'F3_S7_validation5')
            .required('F5_S7_required'),
          interpretation: Yup.string()
            .min(2, 'F3_S7_validation4')
            .max(650, 'F3_S7_validation5')
            .required('F5_S7_required'),
        }),
      )
      .when(['activityTypeId'], {
        is: (activityType) => activityType === '4',
        then: Yup.array()
          .min(1, 'F3_S7_validation7')
          .required('F5_S7_required'),
        otherwise: Yup.array().nullable(true),
      }),
  },
  [
    ['daysRemote', 'daysOnsite'],
    ['ueBudget', 'counterPartBudget'],
  ],
)
