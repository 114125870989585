import styled from 'styled-components';
import {Link} from 'react-router-dom';


export const StyledContainer = styled.div`
  margin-top: 2%;
  margin-left: 30%;
  margin-right: 30%;
  padding-bottom: 100px;
`;

export const StyledTitle = styled.div`
  margin-bottom: 10px;
  color: #88c564;
  font-size: 40px;
  font-weight: 600;
  text-align: left;
`;

export const StyledText = styled.span`
  color: #96abb6;
`;

export const StyledImageContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
`;

export const StyledSignUpContainer = styled.div`
  padding: 20px;
  font-weight: 600;
  border: 4px solid #87c665;
  color: #88c564;
  border-radius: 15px;
  text-align: center;
`;

export const StyledSignInContainer = styled.div`
  padding: 20px;
  color: #88c564;
  text-align: center;
  font-size: 20px;
`;

export const StyledLink = styled(Link)`
  color: #88c564;
`;

export const TemporaryTextStyle = styled.p`
  text-align:center; 
  font-size:20px; 
`;
