import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportInitiativesDashboardToXLSX } from 'helpers/xlsxExporter';
import { PrimaryButton } from 'components/Buttons';

const CustomXLSXExportButton = (props) => {
  const { t } = useTranslation();
  const [exporting, setExporting] = useState(false);
  const [exportData, setExportData] = useState(null);
  const [buttonReady, setButtonReady] = useState(false);

  const handleClick = async () => {
    setExporting(true);
    const data = await getAllValues();  
    setExportData(data);
    setButtonReady(true);
    setExporting(false);
  };

  async function getAllValues() {
    try {
      var data = [];
      props.initiatives.forEach(item => {
        data.push(
        {
          initiative: `${item.initiativeCode} / ${item.initiativeName}`, 
          durationPercentage: `${item.durationPercentage? item.durationPercentage : 0} %`,
          euBudgetPercentage: `${item.euBudgetPercentage? item.euBudgetPercentage : 0} %`,
          counterpartBudgetPercentage: `${item.counterpartBudgetPercentage? item.counterpartBudgetPercentage : 0} %`,
        })
      });
      return data;
    } catch (error) {
      console.log(error)
      return [];
    }
  }

  const onDownloadReady = () => {
    setExportData(null);
    setButtonReady(false);
  };

  return (
    <>
      <ExportInitiativesDashboardToXLSX data={exportData} isReady={buttonReady} onReady={onDownloadReady} />
      <PrimaryButton
        onClick={handleClick}
        style={{width: '220px'}}
        disabled={exporting}
      >
        { t('Export_to_XLSX') }
      </PrimaryButton>
    </>
  );
};

export default CustomXLSXExportButton;