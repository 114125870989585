import React, { useState, useEffect } from 'react';
import { Formik, FieldArray } from 'formik';
import Accordion from 'react-bootstrap/Accordion';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { useConstantsForm1 } from 'customHooks/useConstantsForm1';
import { f1statusNamesConst } from 'constants/appConstants';
import { validationSchema } from './Section7.validations';
import { saveFormSection } from 'services/initiative';
import { parseDateString } from 'helpers/dateHelpers';

import DatePicker from 'react-datepicker';
import htmlParser from 'html-react-parser';
import { formatDateDDMMYY } from 'helpers/dateHelpers';
import 'react-datepicker/dist/react-datepicker.css';
import FormLayout from '../../FormLayout';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from 'components/CustomAccordion';
import FormContainer from 'components/FormContainer';
import { PrimaryButton, ErrorButton } from 'components/Buttons';
import { SeparatorDotted } from 'components/CommonStyled';
import ModalConfirm from 'components/ModalConfirm';
import ActivityForm from './ActivityForm';
import HelpIcon from 'components/HelpIcon';

import { useTranslation } from 'react-i18next';
import MoneyInput from 'components/MoneyInput';
import {
  StyledIntroParagraph,
  StyledNoticeParagraph,
  StyledWarningP,
  StyledBudgetDiv,
  StyledSubtitle,
  StyledRequiredSpan,
} from './Section7.styled';
import {
  mustShowFootNote,
  calculateTotalEuBudgetFromActivities,
  calculateTotalBudgetFromActivities
} from 'constants/budgetConstants';
import { useGetBudgetRestrictions } from 'customHooks/useGetBudgetRestrictions';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const activityObject = {
  activityTypeId: '',
  duration: 0,
  description: '',
  fromDateStr: '',
  toDateStr: '',
  participants: 0,
  experts: 0,
  daysRemote: 0,
  daysOnsite: 0,
  countryId: '',
  cityName: '',
  stateName: '',
  countriesAdditional: '',
  ueBudget: 0,
  counterPartBudget: 0,
  justBudget: '',
  name: '',
  activityNumber: null,
};

const Section7 = () => {
  const { t } = useTranslation();
  const { formStore, initiativeStore } = useRootStore();
  const { id, formId, sectionId } = useParams();
  const { filteredCountries, activityTypes } = useConstantsForm1();
  const [initialValues, setInitialValues] = useState({
    methodology: '',
    estFromDateStr: '',
    estToDateStr: '',
    activities: [{ ...activityObject }],
  });
  const [localActivitiesNumber, setLocalActivitiesNumber] = useState([
    {
      name: '',
      activityNumber: '',
      localActivityNumber: 1,
    },
  ]);

  const {
    euBudgetMin,
    euBudgetLimit,
    euBudgetPercentageLimit
  } = useGetBudgetRestrictions();

  console.log(    euBudgetMin,
    euBudgetLimit,
    euBudgetPercentageLimit )

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const arr = [];
    const localActArray = [];
    if (formStore.formSectionData) {
      if (formStore.formSectionData[0].activities) {
        formStore.formSectionData[0].activities.forEach((element, index) => {
          arr.push({
            activityTypeId: element.activityType ? element.activityType.id : '',
            duration: setNumberFieldValue(element.duration),
            description: setStringFieldValue(element.description),
            fromDateStr: element.fromDate ? new Date(element.fromDate) : '',
            toDateStr: element.toDate ? new Date(element.toDate) : '',
            participants: setNumberFieldValue(element.participants),
            experts: setNumberFieldValue(element.experts),
            daysRemote: setNumberFieldValue(element.daysRemote),
            daysOnsite: setNumberFieldValue(element.daysOnsite),
            format: String(element.format) || '0' ,
            countryId: element.country ? element.country.id : '',
            cityName: setStringFieldValue(element.cityName),
            stateName: setStringFieldValue(element.stateName),
            countriesAdditional: setStringFieldValue(element.countriesAdditional),
            ueBudget: setNumberFieldValue(element.ueBudget),
            counterPartBudget: setNumberFieldValue(element.counterPartBudget),
            justBudget: setStringFieldValue(element.justBudget),
            name: setStringFieldValue(element.name),
            activityNumber: element.activityNumber ? element.activityNumber : null,
          });
          localActArray.push({
            name: setStringFieldValue(element.name),
            activityNumber: element.activityNumber ? element.activityNumber : null,
            localActivityNumber: index,
          });
        });
      }
      setLocalActivitiesNumber(localActArray);
      setInitialValues({
        methodology: setStringFieldValue(formStore.formSectionData[0].methodology),
        estFromDateStr: formStore.formSectionData[0].estFromDate
          ? parseDateString(formStore.formSectionData[0].estFromDate)
          : '',
        estToDateStr: formStore.formSectionData[0].estToDate
          ? parseDateString(formStore.formSectionData[0].estToDate)
          : '',
        activities: arr,
      });
    }
  }, [formStore.formSectionData]);

  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const [clarification, setClarification] = useState({
    show: false,
    msg: '',
  });
  useEffect(() => {
    if (initiativeStore.formStateName === f1statusNamesConst.DRAFT) {
      if (initiativeStore.formVersion === 2) {
        if (formStore.formSectionClarification) {
          setNonEditable(false);
          setClarification({
            show: true,
            msg: formStore.formSectionClarification
              ? formStore.formSectionClarification
              : t('No_clarifications'),
          });
        } else {
          setNonEditable(true);
        }
      } else {
        setNonEditable(false);
      }
    } else {
      setNonEditable(true);
    }
  }, [
    initiativeStore.currentFormStateName,
    initiativeStore.formVersion,
    formStore.formSectionClarification,
  ]);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  const openModalRemove = key => {
    setModalRemove(true);
    setSelectedKey(key);
  };

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  };

  const initializeValues = values => {
    values.activities.forEach(el => {
      if (el.activityTypeId === '4' || el.activityTypeId === '7') {
        el.participants = 1;
        el.duration = 1;
      }
    });
  };

  const handleAddActivity = arrayHelpers => {
    arrayHelpers.push(activityObject);
    setLocalActivitiesNumber([
      ...localActivitiesNumber,
      {
        name: '',
        activityNumber: '',
        localActivityNumber: localActivitiesNumber.length + 1,
      },
    ]);
  };

  const handleReorderLocalActivities = arrayCopy => {
    arrayCopy.forEach((element, index) => {
      element.localActivityNumber = index + 1;
    });
    setLocalActivitiesNumber(arrayCopy);
  };

  const onSubmit = async (values, { resetForm }) => {
    try {
      resetForm({ values });
      initializeValues(values);
      const valuesCopy = JSON.parse(JSON.stringify(values));
      valuesCopy['estFromDateStr'] = valuesCopy['estFromDateStr']
        ? new Date(valuesCopy['estFromDateStr']).toISOString()
        : '';
      valuesCopy['estToDateStr'] = valuesCopy['estToDateStr']
        ? new Date(valuesCopy['estToDateStr']).toISOString()
        : '';
      if (valuesCopy?.activities) {
        valuesCopy?.activities.forEach(element => {
          element.totalBudget = (element.ueBudget || 0) + (element.counterPartBudget || 0);
          element.fromDateStr = new Date(element.fromDateStr).toISOString();
          element.toDateStr = new Date(element.toDateStr).toISOString();
          element.format = parseInt(element.format);
        });
      }
      const response = await saveFormSection(id, formId, sectionId, valuesCopy);
      let bool = true && response.activities;
      if (response.activities) {
        response.activities.forEach(element => {
          bool = bool && element.complete;
        });
      }
      formStore.setFormSectionData([
        {
          ...response,
        },
      ]);
      formStore.setSectionStatus(sectionId, bool);
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const setStringFieldValue = newValue => {
    return newValue ? newValue : '';
  };

  const setNumberFieldValue = newValue => {
    return newValue ? newValue : 0;
  };

  const onValidateSubmit = (e, isValid, values, resetForm) => {
    e.preventDefault();
    isValid ? onSubmit(values, { resetForm }) : alert(t('not_saved_alert'));
  };

  return (
    <FormLayout>
      <FormContainer
        number={7}
        title={t('F1_S7_title')}
        isHelp={true}
        formId="form1section7"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
      >
        <StyledIntroParagraph>{t('F1_S7_intro')}</StyledIntroParagraph>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {props => (
            <form
              onSubmit={e => {
                onValidateSubmit(e, props.isValid, props.values, props.resetForm);
              }}
              id="form1section7"
            >
              {props.dirty && setSaved(false)}
              {clarification.show && (
                <Form.Group style={{ marginLeft: '50px', paddingTop: '10px' }} as={Row}>
                  <Form.Label>{t('Clarification_requests')}</Form.Label>
                  <Form.Control
                    name="overview"
                    as="textarea"
                    style={{ border: '2px solid red', resize: 'none' }}
                    rows={4}
                    disabled={true}
                    value={clarification.msg}
                  />
                </Form.Group>
              )}

              <StyledBudgetDiv>
                <StyledSubtitle style={{ textAlign: 'center' }}>
                  {t('F1_S7_Title_aggregated_planning')}
                </StyledSubtitle>

                <Form.Group as={Row}>
                  <Col>
                    <Form.Label
                      xs={5}
                      lg={4}
                      style={{
                        marginTop: '10px',
                        AlignItems: 'center',
                        lineHeight: '1.3',
                        textAlign: 'justify',
                      }}
                    >
                      {t('F1_S7_Intervention_methodology')}
                      <StyledRequiredSpan style={{ marginLeft: '7px' }}> *</StyledRequiredSpan>:
                      <HelpIcon padding={'0 0 0 0.75rem'} info={t('F1_S7_Intervention_methodology_INFO')} />
                    </Form.Label>
                    <Form.Control
                      name="methodology"
                      as="textarea"
                      rows={3}
                      style={{ resize: 'none', marginRight: '18px' }}
                      placeholder={t('Maximum_1300')}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.methodology}
                      className={
                        props.touched.methodology && props.errors.methodology ? 'error' : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={props.touched.methodology}
                      errors={props.errors.methodology}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: 'center' }}>
                  <Form.Label column xs={3}>
                    {t('F1_S7_Estimated_start_day')} <StyledRequiredSpan>*</StyledRequiredSpan>:
                  </Form.Label>
                  <Col>
                    <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={props.values.estFromDateStr}
                        onChange={date => props.setFieldValue('estFromDateStr', date)}
                        className={props.errors.estFromDateStr ? 'error' : null}
                        disabled={nonEditable}
                      />
                    </InputGroup>
                    <FormErrorMessage
                      touched
                      errors={props.errors.estFromDateStr}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: 'center' }}>
                  <Form.Label column xs={3}>
                    {t('F1_S7_Estimated_end_day')} <StyledRequiredSpan>*</StyledRequiredSpan>:
                  </Form.Label>
                  <Col>
                    <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={props.values.estToDateStr}
                        onChange={date => props.setFieldValue('estToDateStr', date)}
                        className={
                          props.touched.estToDateStr && props.errors.estToDateStr ? 'error' : null
                        }
                        disabled={nonEditable}
                      />
                    </InputGroup>
                    <FormErrorMessage
                      touched
                      errors={props.errors.estToDateStr}
                    />
                  </Col>
                </Form.Group>

                <Form.Label xs={6} style={{ marginBottom: '30px', marginTop: '20px' }}>
                  {t('F1_S7_Total_budget_TCI')}:
                </Form.Label>

                <Form.Group as={Row}>
                  <Form.Label column xs={3}>
                    {t('F1_S7_EU_budget')}:
                  </Form.Label>
                  <Col xs={3}>
                    <InputGroup>
                      <MoneyInput
                        value={props.values.activities
                          .map(item => item.ueBudget || 0)
                          .reduce((prev, next) => prev + next, 0)}
                        disabled={true}
                        customInput={Form.Control}
                      />
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: 'center' }}>
                  <Form.Label column xs={3}>
                    {t('F1_S7_counterpart_budget')}:
                  </Form.Label>
                  <Col xs={3}>
                    <InputGroup>
                      <MoneyInput
                        value={props.values.activities
                          .map(item => item.counterPartBudget || 0)
                          .reduce((prev, next) => prev + next, 0)}
                        disabled={true}
                        customInput={Form.Control}
                      />
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: 'center' }}>
                  <Form.Label column xs={3}>
                    {t('F1_S7_total_budget')}:
                  </Form.Label>
                  <Col xs={3}>
                    <InputGroup>
                      <MoneyInput
                        value={
                          props.values.activities
                            .map(item => item.ueBudget || 0)
                            .reduce((prev, next) => prev + next, 0) +
                          props.values.activities
                            .map(item => item.counterPartBudget || 0)
                            .reduce((prev, next) => prev + next, 0)
                        }
                        disabled={true}
                        customInput={Form.Control}
                      />
                    </InputGroup>
                  </Col>
                </Form.Group>

                {
                  mustShowFootNote(
                    calculateTotalBudgetFromActivities(props?.values?.activities),
                    calculateTotalEuBudgetFromActivities(props?.values?.activities),
                    euBudgetMin,
                    euBudgetLimit,
                    euBudgetPercentageLimit
                  ) && (
                    <StyledNoticeParagraph>{t('F1_S7_budget_foot_note2024')}</StyledNoticeParagraph>
                  )
                }

                {htmlParser(t('F1_S7_budget_foot_link'))}
              </StyledBudgetDiv>

              <FieldArray
                name="activities"
                render={arrayHelpers => (
                  <>
                    {modalRemove && (
                      <ModalConfirm
                        show={modalRemove}
                        confirmText={t('Button_remove')}
                        onConfirm={() => {
                          arrayHelpers.remove(selectedKey);
                          const arrayCopy = [...localActivitiesNumber];
                          arrayCopy.splice(selectedKey, 1);
                          handleReorderLocalActivities(arrayCopy);
                          closeModalRemove();
                        }}
                        onClose={closeModalRemove}
                      >
                        <p>{t('Confirmation remove activity') + ' ' + (selectedKey + 1)}?</p>
                      </ModalConfirm>
                    )}
                    <Accordion>
                      {initialValues.activities
                        ? props.values.activities.map((activity, key) => (
                          <AccordionItem key={key + 1}>
                            <AccordionItemHeader>
                              <AccordionTitle eventKey={key + 1}>
                                {t('F1_S7_Accordion') + '  '}
                                {activity.name + '  '}
                                {activity.activityTypeId
                                  ? ` - ${t(`activity_types_${activity.activityTypeId}`)}`
                                  : ' '
                                }
                                {activity.fromDateStr ? ` (${formatDateDDMMYY(activity.fromDateStr)})` : ' '}
                              </AccordionTitle>
                            </AccordionItemHeader>
                            <Accordion.Collapse eventKey={key + 1}>
                              <AccordionItemBody>
                                <ActivityForm
                                  formikInstance={props}
                                  activityIndex={key}
                                  activityTypes={activityTypes}
                                  countries={filteredCountries}
                                  nonEditable={nonEditable}
                                />
                                <ErrorButton
                                  onClick={() => openModalRemove(key)}
                                  disabled={nonEditable}
                                >
                                  {t('F1_S7_Button_remove_activity')}
                                </ErrorButton>
                              </AccordionItemBody>
                            </Accordion.Collapse>
                          </AccordionItem>
                        ))
                        : null}
                    </Accordion>
                    {typeof props.errors.activities === 'string' ? (
                      <FormErrorMessage
                        touched
                        errors={props.errors.activities}
                      />
                    ) : null}
                    <SeparatorDotted />
                    <PrimaryButton
                      onClick={() => handleAddActivity(arrayHelpers)}
                      disabled={nonEditable}
                    >
                      {t('F1_S7_Button_add_activity')}
                    </PrimaryButton>
                  </>
                )}
              />

            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section7);
