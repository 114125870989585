export const formInitialValues = {
  activityType: "",
  fromDate: "",
  toDate: "",
  description: "",
  country: "",
  stateName: "",
  cityName: "",
  duration: 0,
  participants: 0,
  experts: 0,
  format: 0,
  daysRemote: 0,
  daysOnsite: 0,
  workLang1: false,
  workLang2: false,
  workLang3: false,
  workLang4: false,
  justification: "",
  objective: "",
  learning: "",
  results: "",
  speakers: "",
  descSchedule: "",
  trainer: false,
  workPlans: ""
};
  