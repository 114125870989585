import React, { useState, useEffect } from 'react'
import { getEntitiesByCountrySummary } from 'services/initiative'
import Header1 from '../components/Header1'
import Table1 from '../components/Table1'
import Row from 'react-bootstrap/Row'
import { StyledDiv, StyledRow2 } from '../../CommonStyled/Tabs.styled'
import { T7RenderRows, orderEntitiesByCountry } from '../helpers/helpers'
import { getFundsEntitySummaryByCountry } from 'services/fundsStatistics'
import { useTranslation } from 'react-i18next'
import Table6 from '../components/Table6'

const EntitiesByCountrySummary = () => {
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const [data2, setData2] = useState(null)
  const [waiting2, setWaiting2] = useState(false)

  const getData = async () => {
    setWaiting(true)
    try {
      let response = await getEntitiesByCountrySummary({})
      response = orderEntitiesByCountry(response)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }


  const getData2 = async (filters) => {
    setWaiting2(true)
    try {
      let response = await getFundsEntitySummaryByCountry({})
      response = orderEntitiesByCountry(response)
      setData2(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting2(false)
    }
  }

  useEffect(() => {
    getData()
    getData2()
  }, [])


  return (
    <StyledDiv>
      <Row>
        <Header1 data={data} data2={data2} waiting={waiting} />
      </Row>
      <StyledRow2>
        <Table1 data={data ? data : null} waiting={waiting} setData={setData} />
      </StyledRow2>
      <StyledRow2>
        <Table6
          title={t('country_report_subtitle9')}
          data={data2}
          waiting={waiting2}
          renderRows={T7RenderRows}
          setData={setData2}
          orderField='country'
          customWidth='63%'
          customWidthRow1='84%'
          customWidthRow2='16%'
          total={true}
        />
      </StyledRow2>
    </StyledDiv>
  )
}

export default EntitiesByCountrySummary
