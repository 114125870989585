import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  assessment: Yup.string().max(6500, i18n.t('F2_S9_validation1')).nullable(),
  quality: Yup.string().max(6500, i18n.t('F2_S9_validation1')).nullable(),
  theme: Yup.string().max(6500, i18n.t('F2_S9_validation1')).nullable(),
  participants: Yup.string().max(6500, i18n.t('F2_S9_validation1')).nullable(),
  triangular: Yup.string().max(6500, i18n.t('F2_S9_validation1')).nullable(),
  alignment: Yup.string().max(6500, i18n.t('F2_S9_validation1')).nullable(),
  euPriorities: Yup.string().max(6500, i18n.t('F2_S9_validation1')).nullable(),
  coherence: Yup.string().max(6500, i18n.t('F2_S9_validation1')).nullable(),
  opportunity: Yup.string().max(6500, i18n.t('F2_S9_validation1')).nullable(),
});