import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import Accordion from "react-bootstrap/Accordion";
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from "components/CustomAccordion";
import isEmpty from "lodash/isEmpty";

import { useRootStore } from "stores";
import FormLayout from "../../FormLayout";
import FormContainer from "components/FormContainer";
import CoapplicantForm from "./CoapplicantForm";

import { formInitialValues } from "./Section3.initialValues";
import { StyledIntroParagraph, StyledSeparator } from "./Section3.styled";
import RequestClarification from "../RequestClarification";
import { useTranslation } from "react-i18next";
import { arrayOrderHelper } from 'helpers/arrayOrderHelper';

const Section3 = () => {
  const { formStore } = useRootStore();
  const [saved, setSaved] = useState(false);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [nonEditable, setNonEditable] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = [...formStore.formSectionData];
      setFormValues(arrayOrderHelper(formSectionData));
    }
    formStore.setCurrentSection(3);
  }, [formStore.formSectionData]);

  const handleSaved = (value) => {
    setSaved(value);
  };

  const handleButton = (value) => {
    setNonEditable(value);
  };

  return (
    <FormLayout>
      <FormContainer
        number={3}
        title={t("F1_S3_title")}
        formId="form2section3"
        showSavedMessageF2={saved}
        showButtonClarif={!nonEditable}
        showFooter={false}
        buttonText="SAVE CLARIFICATION REQUEST"
      >
        <StyledIntroParagraph>{t("F1_S3_intro")}</StyledIntroParagraph>

        <Accordion>
          {formValues &&
            formValues.map((coapplicant, key) => (
              <AccordionItem key={key + 1}>
                <AccordionItemHeader>
                  <AccordionTitle eventKey={key + 1}>{`${t(
                    "F1_S3_Accordion"
                  )} ${key + 1} ${
                    isEmpty(coapplicant.entityName)
                      ? ""
                      : `- ${coapplicant.entityName}`
                  }`}</AccordionTitle>
                </AccordionItemHeader>
                <Accordion.Collapse eventKey={key + 1}>
                  <AccordionItemBody>
                    <CoapplicantForm
                      coapplicantIndex={key}
                      data={coapplicant}
                    />
                  </AccordionItemBody>
                </Accordion.Collapse>
              </AccordionItem>
            ))}
        </Accordion>

        <StyledSeparator />
        <RequestClarification onSaved={handleSaved} onButton={handleButton} />
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section3);
