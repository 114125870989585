import React, { useState, useEffect } from 'react';
import { Formik, FieldArray } from 'formik';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { saveFormSection } from 'services/initiative';
import isEmpty from 'lodash/isEmpty';

import FormLayout from '../../FormLayout';
import Accordion from 'react-bootstrap/Accordion';
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from 'components/CustomAccordion';
import FormContainer from 'components/FormContainer';
import { PrimaryButton, ErrorButton } from 'components/Buttons';
import { SeparatorDotted } from 'components/CommonStyled';
import ModalConfirm from 'components/ModalConfirm';
import CoapplicantForm from './CoapplicantForm';

import { StyledIntroParagraph } from './Section3.styled';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useConstantsForm1 } from 'customHooks/useConstantsForm1';
import { validationSchema } from './Section3.validations';
import { f1statusNamesConst } from 'constants/appConstants';
import { arrayOrderHelper } from 'helpers/arrayOrderHelper';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const coapplicantObject = {
  entityName: '',
  acronym: '',
  entityTypeId: '',
  role: '0',
  optionA: false,
  optionB: false,
  optionC: false,
  optionD: false,
  other: '',
  cityName: '',
  stateName: '',
  countryId: '',
  web: '',
  firstNameCoApplicant: '',
  lastNameCoApplicant: '',
  position: '',
  email: '',
  phone: '',
  auth: true,
  number: '',
};

const Section3 = () => {
  const { t } = useTranslation();

  const { formStore, initiativeStore } = useRootStore();

  const { filteredCountries, entityTypes } = useConstantsForm1();
  const { id, formId, sectionId } = useParams();
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [initialValues, setInitialValues] = useState({
    coapplicants: [{ ...coapplicantObject }, { ...coapplicantObject }],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const arr = [];
    if (formStore.formSectionData) {
      formStore.formSectionData.forEach(element => {
        arr.push({
          entityName: setStringFieldValue(element.entityName),
          acronym: setStringFieldValue(element.acronym),
          entityTypeId: element.entityType ? element.entityType.id : '',
          role: element.role ? element.role.toString() : '0',
          optionA: element.optionA,
          optionB: element.optionB,
          optionC: element.optionC,
          optionD: element.optionD,
          other: setStringFieldValue(element.other),
          cityName: setStringFieldValue(element.cityName),
          stateName: setStringFieldValue(element.stateName),
          countryId: element.country ? element.country.id : '',
          web: setStringFieldValue(element.web),
          firstNameCoApplicant: setStringFieldValue(element.firstNameCoApplicant),
          lastNameCoApplicant: setStringFieldValue(element.lastNameCoApplicant),
          position: setStringFieldValue(element.position),
          email: setStringFieldValue(element.email),
          phone: setStringFieldValue(element.phone),
          auth: element.auth,
          number:setStringFieldValue(element.number),
        });
      });

      setInitialValues({
        coapplicants: arrayOrderHelper(arr),
      });
    } else {
      setInitialValues({
        coapplicants: [{ ...coapplicantObject }, { ...coapplicantObject }],
      });
    }
  }, [formStore.formSectionData]);

  const [clarification, setClarification] = useState({
    show: false,
    msg: '',
  });
  useEffect(() => {
    if (initiativeStore.formStateName === f1statusNamesConst.DRAFT) {
      if (initiativeStore.formVersion === 2) {
        if (formStore.formSectionClarification) {
          setNonEditable(false);
          setClarification({
            show: true,
            msg: formStore.formSectionClarification
              ? formStore.formSectionClarification
              : t('No_clarifications'),
          });
        } else {
          setNonEditable(true);
        }
      } else {
        setNonEditable(false);
      }
    } else {
      setNonEditable(true);
    }
  }, [
    initiativeStore.currentFormStateName,
    initiativeStore.formVersion,
    formStore.formSectionClarification,
  ]);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  const openModalRemove = key => {
    setModalRemove(true);
    setSelectedKey(key);
  };

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  };

  const setStringFieldValue = newValue => {
    return newValue ? newValue : '';
  };

  const onSubmit = async (values, { resetForm }) => {
    try {
      for (let i = 0; i< values.coapplicants.length; i++) {
        values.coapplicants[i].number = `${i}`;
      }
      const response = await saveFormSection(id, formId, sectionId, [...values.coapplicants]);
      let bool = true && response.length;
      if (response) {
        response.forEach(element => {
          bool = bool && element.complete;
        });
      }
      formStore.setSectionStatus(sectionId, bool);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, resetForm) => {
    e.preventDefault();
    isValid ? onSubmit(values, { resetForm }) : alert(t('not_saved_alert'));
  };

  return (
    <FormLayout>
      <FormContainer
        number={3}
        title={t('F1_S3_title')}
        isHelp={true}
        showSavedMessage={saved}
        formId="form1section3"
        showSaveButton={!nonEditable}
      >
        <StyledIntroParagraph>{t('F1_S3_intro')}</StyledIntroParagraph>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {props => (
            <form
              onSubmit={e => {
                onValidateSubmit(e, props.isValid, props.values, props.resetForm);
              }}
              id="form1section3"
            >
              {props.dirty && setSaved(false)}

              {clarification.show && (
                <Form.Group style={{ marginLeft: '50px', paddingTop: '10px' }} as={Row}>
                  <Form.Label>{t('Clarification_requests')}</Form.Label>
                  <Form.Control
                    name="overview"
                    as="textarea"
                    style={{ border: '2px solid red', resize: 'none' }}
                    rows={4}
                    disabled={true}
                    value={clarification.msg}
                  />
                </Form.Group>
              )}

              <FieldArray
                name="coapplicants"
                render={arrayHelpers => (
                  <>
                    {modalRemove && (
                      <ModalConfirm
                        show={modalRemove}
                        confirmText={t('Button_remove')}
                        onConfirm={() => {
                          arrayHelpers.remove(selectedKey);
                          closeModalRemove();
                        }}
                        onClose={closeModalRemove}
                      >
                        <p>{t('Confirmation remove coapp') + ' ' + (selectedKey + 1)}?</p>
                      </ModalConfirm>
                    )}
                    <Accordion>
                      {initialValues.coapplicants
                        ? props.values.coapplicants.map((coapplicant, key) => (
                          <AccordionItem key={key + 1}>
                            <AccordionItemHeader>
                              <AccordionTitle eventKey={key + 1}>
                                {`${t('F1_S3_Accordion')} ${key + 1} ${isEmpty(coapplicant.entityName) ? '' : `- ${
                                  coapplicant.entityName
                                }`}`}
                              </AccordionTitle>
                            </AccordionItemHeader>
                            <Accordion.Collapse eventKey={key + 1}>
                              <AccordionItemBody>
                                <CoapplicantForm
                                  formikInstance={props}
                                  coapplicantIndex={key}
                                  countries={filteredCountries}
                                  entityTypes={entityTypes}
                                  nonEditable={nonEditable}
                                />
                                <ErrorButton
                                  onClick={() => openModalRemove(key)}
                                  disabled={nonEditable}
                                >
                                  {t('F1_S3_Button_remove_entity')}
                                </ErrorButton>
                              </AccordionItemBody>
                            </Accordion.Collapse>
                          </AccordionItem>
                        ))
                        : null}
                    </Accordion>
                    {typeof props.errors.coapplicants === 'string' ? (
                      <FormErrorMessage
                        touched
                        errors={props.errors.coapplicants}
                      /> 
                    ) : null}
                    <SeparatorDotted />
                    <PrimaryButton
                      onClick={() => arrayHelpers.push(coapplicantObject)}
                      disabled={nonEditable}
                    >
                      {t('F1_S3_Button_add_entity')}
                    </PrimaryButton>
                  </>
                )}
              />
            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section3);
