import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getCountries } from 'services/initiative'

import { renderCountriesOptions } from 'helpers/renderCountriesHelper'

import { Form } from 'react-bootstrap'
import { PrimaryButton } from 'components/Buttons'
import {
  Col1,
  Col2,
  StyledFormGroup,
  StyledFormLabel,
} from '../../CommonStyled/Components.styled'

const Header = ({ getData, waiting, renderExportButton }) => {
  const { t } = useTranslation()
  const [countriesOptions, setCountriesOptions] = useState([])
  const [countryFilter, setCountryFilter] = useState(0)
  
  useEffect(() => {
    getCountriesOptions()
  }, [])

  const getCountriesOptions = async () => {
    try {
      const response = await getCountries()
      setCountriesOptions(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilter = () => {
    getData(countryFilter)
  }

  return (
    <>
      <Col1>
        <StyledFormGroup>
          <StyledFormLabel>{t('F1_S2_Country')} <b>*</b></StyledFormLabel>
          <Form.Control
            name="countryId"
            as="select"
            onChange={(e) => {
              setCountryFilter(e.target.value)
            }}
            value={countryFilter}
            disabled={false}
          >
            {renderCountriesOptions(countriesOptions)}
          </Form.Control>
        </StyledFormGroup>
        <PrimaryButton
          onClick={() => {
            handleFilter()
          }}
          disabled={waiting}
        >
          {t('Button_search')}
        </PrimaryButton>
      </Col1>
      <Col2>
        {renderExportButton()}
      </Col2>
    </>
  )
}

export default Header
