import React, { useState, useEffect } from 'react';
import { formInitialValues } from './Section6.initialValues';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { useConstantsForm1 } from 'customHooks/useConstantsForm1';

import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import TextValue from 'components/TextValue'
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import { useTranslation } from 'react-i18next';

import {
  StyledFormLabel,
  StyledSubtitle,
  StyledSeparator,
  TextValueLeftSpaced,
} from './Section6.styled';
import RequestClarification from '../RequestClarification';

const Section6 = () => {

  const {t} = useTranslation();
  const { formStore } = useRootStore();
  const [saved, setSaved] = useState(false);
  const [formValues, setFormValues] = useState(formInitialValues);
  const { developmentGoals } = useConstantsForm1();
  const [nonEditable, setNonEditable] = useState(false);

  const developmentGoalName = (id) => developmentGoals.find( devGoal => devGoal.id === id).name;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = { ...formInitialValues, ...formStore.formSectionData[0] };
      setFormValues(formSectionData);
    }
    formStore.setCurrentSection(6);
  }, [formStore.formSectionData])

  const handleSaved = (value) => {
    setSaved(value);
  }

  const handleButton = (value) => {
    setNonEditable(value);
  }

  return (
    <FormLayout>
      <FormContainer
        number={6}
        title={t('F1_S6_title')}
        formId='form2section6'
        showSavedMessage={false}
        showSavedMessageF2={saved}
        showButtonClarif= {!nonEditable}
        showFooter={false}
        buttonText="SAVE CLARIFICATION REQUEST"
      >
        <StyledSubtitle>{t('F1_S6_Subtitle_1')}</StyledSubtitle>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>{t('F1_S6_Question_1')}:</StyledFormLabel>
          <TextValue value={formValues.name} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>{t('F1_S6_Question_2')}:</StyledFormLabel>
          <TextValue value={formValues.description} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>{t('F1_S6_Question_3')}:</StyledFormLabel>
          <TextValue value={formValues.objective} />
        </Form.Group>

        <StyledSubtitle> {t('F1_S6_Subtitle_2')} </StyledSubtitle>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_8')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.contribution} />
          </TextValueLeftSpaced>
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_4')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.latamKnowledge} />
          </TextValueLeftSpaced>
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_5')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.euKnowledge} />
          </TextValueLeftSpaced>
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_6')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.newKnowledge} />
          </TextValueLeftSpaced>
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_7')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.application} />
          </TextValueLeftSpaced>
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_9')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.leap} />
          </TextValueLeftSpaced>
        </Form.Group>

        <StyledSubtitle> {t('F1_S6_Subtitle_3')} </StyledSubtitle>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_10')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.benEntities} />
          </TextValueLeftSpaced>
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_11')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.directBen} />
          </TextValueLeftSpaced>
        </Form.Group>

        <StyledSubtitle> {t('F1_S6_Subtitle_4')} </StyledSubtitle>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={5}>{t('F1_S6_Question_12')}:</StyledFormLabel>
          <TextValue value={(formValues.goalPrimaryType && formValues.goalPrimaryType.id)? t(`SDG_Goal${formValues.goalPrimaryType.id}`): ' - '} />
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_13')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.primaryJustification} />
          </TextValueLeftSpaced>
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={5}>{t('F1_S6_Question_14')}:</StyledFormLabel>
          <TextValue value={(formValues.goalSecondaryType && formValues.goalSecondaryType.id)? t(`SDG_Goal${formValues.goalSecondaryType.id}`): ' - '} />
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_15')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.secondaryJustification} />
          </TextValueLeftSpaced>
        </Form.Group>

        <StyledSubtitle> {t('F1_S6_Subtitle_5')} </StyledSubtitle>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_16')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.rights} />
          </TextValueLeftSpaced>
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_17')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.environment} />
          </TextValueLeftSpaced>
        </Form.Group>

        <StyledSubtitle> {t('F1_S6_Subtitle_6')} </StyledSubtitle>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_18')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.innovation} />
          </TextValueLeftSpaced>
        </Form.Group>


        <StyledSubtitle> {t('F1_S6_Subtitle_7')}</StyledSubtitle>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_19')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.valueTC} />
          </TextValueLeftSpaced>
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_20')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.valueEU} />
          </TextValueLeftSpaced>
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_21')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.valueTP} />
          </TextValueLeftSpaced>
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_21b')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.valueSR} />
          </TextValueLeftSpaced>
        </Form.Group>

        <StyledSubtitle> {t('F1_S6_Subtitle_8')} </StyledSubtitle>

        <Form.Group>
          <StyledFormLabel>{t('F1_S6_Question_22')}:</StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.covid19} />
          </TextValueLeftSpaced>
        </Form.Group>

        <StyledSeparator />
        <RequestClarification onSaved={handleSaved}
                              onButton={handleButton} />

      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section6);
