import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  knowledge: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .nullable(true),
  impact: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .nullable(true),
  value: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .nullable(true),
  elements: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .nullable(true),
  overall: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(6500, 'F2_S9_validation1')
    .nullable(true),
  level: Yup.boolean()
    .nullable(true)
    .required('F3_S7_validation3'),
  oo1: Yup.boolean(),
  oo2: Yup.boolean(),
  so3: Yup.boolean(),
  so4: Yup.boolean(),
  out5: Yup.boolean(),
  out6: Yup.boolean(),
  out7: Yup.boolean(),
  out8: Yup.boolean(),
  out9: Yup.boolean(),
  marker1: Yup.boolean(),
  marker2: Yup.boolean(),
  marker3: Yup.boolean(),
  marker4: Yup.boolean(),
  marker5: Yup.boolean(),
  marker6: Yup.boolean(),
  marker7: Yup.boolean(),
  marker8: Yup.boolean(),
  marker9: Yup.boolean(),
  marker10: Yup.boolean(),
  marker11: Yup.boolean(),
  marker12: Yup.boolean(),
  marker13: Yup.boolean(),
  marker14: Yup.boolean(),
  marker15: Yup.boolean(),
  marker16: Yup.boolean(),
  marker17: Yup.boolean(),
  marker18: Yup.boolean(),
  marker19: Yup.boolean(),
});