import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { formInitialValues } from './ProviderForm.initialValues';
import { validationSchema } from './ProviderForm.validations';
import { getAllProvidersF5S7, modifyF5S7ActivityDetailProvider, addF5S7ActivityDetailProvider, saveFile, getDownload } from 'services/initiative';
import { getUploadFormatsConstants } from "services/apiConstants";
import { useRootStore } from 'stores';
import { roleNamesConst } from 'constants/appConstants';

import FileUploader from 'components/FileUploader';
import { StyledFormLabel } from './ProviderForm.styled'
import TextValue from 'components/TextValue';
import MoneyInput from 'components/MoneyInput';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ErrorButton, PrimaryButton, StyledSendButton} from 'components/Buttons'
import { Form } from 'react-bootstrap'
import { FormFielErrorMessage, RequiredMark, SeparatorDotted, CenteredModalButtonContainer } from 'components/CommonStyled';

const ProviderForm = ({ activity, detail, handleCloseOk, handleClose, handleCloseSubModalOnError, setModalInfo, setBudgetDetailAdminModalData, nonEditable }) => {
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { authStore } = useRootStore();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [currentProvider, setCurrentProvider] = useState({});
  const [providers, setProviders] = useState([]);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const role = authStore.roles[0];
  const isAtke = role === roleNamesConst.ATKE
  const [validFileFormats, setValidFileFormats] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getUploadFormatsConstants();
        if(response && response.F3S7){
          setValidFileFormats(response.F3S7);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAPI();
   }, [])

  useEffect(() => {
    if(detail.detailProviderObj){
      setIsEditing(true);
      setInitialValues({
        ...detail.detailProviderObj,
        providerId: detail.detailProviderObj.mgmtF5S7Provider.id,
      });
      setCurrentProvider(detail.detailProviderObj.mgmtF5S7Provider);
    }
  }, [detail])

  const handleSubmit = async (values) => {
    try {
      if(isEditing){
        await modifyF5S7ActivityDetailProvider(id, activity.id, detail.id, detail.detailProviderObj.id, values);
      }
      else{
        await addF5S7ActivityDetailProvider(id, activity.id, detail.id, values);
      }
      handleCloseOk();
    } catch (error) {
      handleCloseSubModalOnError();
    }
  };

  useEffect(() => {
    async function getValues() {
      let response = await getAllProvidersF5S7();
      const allProviders = [...response.data];
      setProviders(allProviders);
    }
    getValues();
  }, []);

  const handleFileUpload = async (e, setFieldValue, handleChange) => {
    try{
      const file = document.getElementById("fileInput").files[0];
      const fileExtension = file.name.split('.').pop();
      if(file.size > 20971520){
        setModalInfo({
          message: t('Error_file4'),
          type: "error",
          isOpen: true,
        });
      }else if(!validFileFormats.includes(fileExtension.toUpperCase())){
        setModalInfo({
          message: t('Error_file5'),
          type: "error",
          isOpen: true,
        });
      }
      else{
        setIsUploadingFile(true);
        const uid = await saveFile(id, formId, sectionId, file);
        setFieldValue('file.fileName', file.name);
        setFieldValue('file.uuid', uid);
        setFieldValue('fileUploaded', true);
        handleChange(e);
      }
    } catch (error) {
      setModalInfo({
        message: t('Error_file2'),
        type: "error",
        isOpen: true,
      });
    }
    finally {
      setIsUploadingFile(false);
    }
  }

  const handleDownload = async (uuid, downloadName) => {
    try {
      const file = await getDownload(uuid)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download", downloadName
        );
        document.body.appendChild(link);
        link.click();
    });
    } catch (error) {
      setModalInfo({
        message: t('Error_file1'),
        type: "error",
        isOpen: true,
      });
    }    
  }

  const handleButtonNewProvider = async () => {
    setBudgetDetailAdminModalData({
      isShow: true,
      type: 1,
      title: t('F5_S7_Budget_Admins_Title1.1'),
      detail: detail
    })
  }

  const handleButtonEditProvider = () => {
    setBudgetDetailAdminModalData({
      isShow: true,
      type: 1,
      title: t('F5_S7_Budget_Admins_Title1.2'),
      detail: {
        ...detail,
        providerObj: currentProvider
      }
    })
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
         }) => (
          <Form onSubmit={handleSubmit} id="provider" >
            
            <Form.Group as={Row}>
              <StyledFormLabel column lg={4}>{t('F1_S2_Budget_Provider_Name')}<RequiredMark>*</RequiredMark>:</StyledFormLabel>
              <Col lg={4}>
                <Form.Control 
                  name="providerId"
                  as="select"
                  onChange={(e) => {
                    handleChange(e)
                    setCurrentProvider({ ...providers.find(prov => prov.id == e.target.value)});     
                  }}
                  value={values.providerId}
                  disabled={nonEditable}
                >
                  <option value="">{t('Placeholder_select')}</option>
                  {providers.map(provider => (
                      <option key={provider.id} value={provider.id}>{provider.name}</option>
                  ))}
                </Form.Control>
                {touched.providerId && errors.providerId ? (
                  <FormFielErrorMessage>{errors.providerId}</FormFielErrorMessage>
                ): null}
              </Col>
              <Col>
                <PrimaryButton onClick={handleButtonNewProvider} disabled={nonEditable}> 
                  {t('F1_S2_Budget_Provider_Button_New')} 
                </PrimaryButton>
                <PrimaryButton onClick={handleButtonEditProvider} disabled={!values.providerId || nonEditable} style={{marginLeft: '15px'}}>
                  {t('Button_edit')}
                </PrimaryButton>
              </Col>
            </Form.Group>  

            <Form.Group as={Row}>
              <StyledFormLabel xs={4}>{t('F1_S2_Budget_Provider_DTGCode')}:</StyledFormLabel>
              <TextValue value={currentProvider? currentProvider.dtGlobalCode: '-'} />
            </Form.Group>

            <Row>
              <Col xs={4}>
                <Form.Group as={Row}>
                  <StyledFormLabel xs={4}>{t('F1_S2_City')}:</StyledFormLabel>
                  <TextValue value={currentProvider? currentProvider.city: '-'} />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group as={Row}>
                  <StyledFormLabel xs={4}>{t('F1_S2_Country')}:</StyledFormLabel>
                  <TextValue value={(currentProvider && currentProvider.country)? t(currentProvider.country.name) : '-'} />         
                </Form.Group>
              </Col>
            </Row>

            <Form.Group as={Row}>
              <StyledFormLabel xs={4}>{t('F1_S2_Budget_Provider_Contact')}:</StyledFormLabel>
              <TextValue value={currentProvider? currentProvider.contact: '-' } />
            </Form.Group>
            
            <SeparatorDotted />

            <Row style={{marginBottom: '30px'}}>
              <Col> {t('F1_S2_Budget_Provider_About')} </Col>
            </Row>

            <Form.Group as={Row}>
              <Form.Label column md={3} xs={3}>{t('F1_S2_Budget_Provider_Total')}<RequiredMark>*</RequiredMark>:</Form.Label>
              <Col xs={3}>
                <MoneyInput
                  name="totalAmount"
                  style={{ width: '170px' }}
                  value={values.totalAmount} 
                  onChange={val => setFieldValue("totalAmount", val)}
                  onBlur={handleBlur}
                  customInput={Form.Control}
                  className={errors.totalAmount ? "error" : null}
                  disabled={nonEditable}
                />
                {errors.totalAmount ? (
                  <FormFielErrorMessage>{errors.totalAmount}</FormFielErrorMessage>
                ): null}
              </Col>
            </Form.Group>

            <FileUploader
              file={values?.file}
              handleFileUpload={(e) => handleFileUpload(e, setFieldValue, handleChange)}
              handleDownload={handleDownload}
              isUploadingFile={isUploadingFile}
              uploadLabel={t('F1_S2_Budget_Provider_CV_Upload')}
              nonEditable={nonEditable}
            />

            <Form.Group>
              <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}} >{t('F1_S2_Budget_Provider_Comments')}: </Form.Label>
              <Form.Control
                name="comments"
                as="textarea" 
                rows={5} 
                placeholder={t('Maximum_1300')}
                style={{resize: 'none'}}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.comments}
                className={errors.comments ? "error" : null}
                disabled={nonEditable}
              />
              {errors.comments ? (
                <FormFielErrorMessage>{errors.comments}</FormFielErrorMessage>
              ): null}
            </Form.Group>

            <CenteredModalButtonContainer>
              {
                (nonEditable && !(isAtke))
                ?
                  <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
                :
                  <>
                    <ErrorButton style={{marginRight: '20px'}} onClick={handleClose} disabled={nonEditable && !(isAtke)}> {t('Button_cancel')} </ErrorButton>
                    <StyledSendButton type="submit" form="provider" disabled={nonEditable && !(isAtke)}> {t('Button_save')} </StyledSendButton>
                  </>
              }
            </CenteredModalButtonContainer>

          </Form>
        )}
      </Formik>
    </div>
  )
}

export default observer(ProviderForm);