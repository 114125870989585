import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { f5ActivityBudgetStatesConst } from 'constants/appConstants';
import completeIcon from 'assets/complete-mark.png';
import { CompleteImage } from 'components/Forms/Form5/Section7/ActivityItem/ActivityItem.styled';

import {StyledRow, StyledCol, StyledText, StyledDiv, StyledColTitle, StyledDivTotal, StyledNotice, StyledButtonLink} from 'components/Forms/Form5/Section7/ResourcesF3Form/ResourcesF3Form.styled';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MoneyTextMask from 'components/MoneyTextMask';
import { StyledTitle } from '../CommonStyles';

const Section7ExecutedBudget = ({ activity, data }) => {
  const {t} = useTranslation();
  const [formValues, setFormValues] = useState([]);
  const [activityTotal, setActivityTotal] = useState({
    ueBudget: 0,
    counterpartBudget: 0,
    totalCost: 0,
  });

  useEffect(() => {
    if (data) {
      let total = {
        ueBudget: 0,
        counterpartBudget: 0,
        totalCost: 0,
      }
      data.forEach(({ueOrCounterpart, totalRealMoney}) => {
        total.ueBudget += ueOrCounterpart == 0? totalRealMoney: 0;
        total.counterpartBudget += ueOrCounterpart == 1? totalRealMoney: 0;
        total.totalCost += totalRealMoney;
      })
      setActivityTotal(total);
      setFormValues(data);
    }
  }, [data]);

  return (
    <div>
      <StyledTitle style={{marginBottom: '30px'}}> {t('F1_S7_Accordion')} - {activity ? activity.name : ''} - {t('F5_S9_Subtitle_executed_budget')} </StyledTitle>

      {formValues.length !== 0 && <Row>
        <Col sm={6}>
        </Col>
        <Col sm={6} style={{display:'flex'}}>
          <StyledColTitle sm={4}>
            {t('F3_S7_Resource_column_EU')}
          </StyledColTitle>
          <StyledColTitle sm={4}>
            {t('F3_S7_Resource_column_counterpart')}
          </StyledColTitle>
          <StyledColTitle sm={4}>
            {t('F5_S7_Resource_column_total')}
          </StyledColTitle>
        </Col>
      </Row>}

      {formValues && formValues.map((detail) => ( 
         <StyledRow>
            <Col sm={6} style={{display: 'flex'}}>
              <StyledButtonLink variant="link" size="lg" style={{marginRight: '10px'}}>
                {t(`resource_type_${detail.resourceType.id}`)}
              </StyledButtonLink>
              {(detail.resourceDetailState.name === f5ActivityBudgetStatesConst.FINALIZED 
                || detail.resourceDetailState.name === f5ActivityBudgetStatesConst.CANCELLED) && <CompleteImage src={completeIcon} />}
            </Col>
            <Col sm={6} style={{display:'flex'}}>
              <StyledCol sm={4}>
                <MoneyTextMask value={detail.ueOrCounterpart == 0? detail.totalRealMoney: "-"}/>
              </StyledCol>
              <StyledCol sm={4}>
              <MoneyTextMask value={detail.ueOrCounterpart == 1? detail.totalRealMoney: "-"}/>
              </StyledCol>
              <StyledCol sm={4}>
                <MoneyTextMask value={detail.totalRealMoney}/>
              </StyledCol>
            </Col>
            <StyledDiv> 
              <StyledText>
                {detail.description? detail.description: "-" }
              </StyledText>
              <div style={{display: 'flex'}}>
                {t('F5_S7_provider')}:
                <StyledText style={{marginLeft: '10px'}}>
                  {detail.provider? detail.provider: "-" }
                </StyledText>
              </div>
              <div style={{display: 'flex'}}>
                {t('F5_S7_status')}:
                <StyledText style={{marginLeft: '10px'}}>
                  {(detail.resourceDetailState.name === 'CONTRACTING' 
                    && (detail.providerSelected && !detail.budgetConfirmed) 
                    && detail.resourceType && (detail.resourceType.id != 8 && detail.resourceType.id != 9)) ? (
                      <div style={{display: 'flex'}}>
                        {detail.resourceDetailState.name+" - "}
                        <StyledNotice>
                          {t('F5_S7_waiting_confirmation')}
                        </StyledNotice> 
                      </div> 
                    ) : (
                      detail.resourceDetailState.name 
                    )}
                </StyledText>
              </div>
            </StyledDiv>   
          </StyledRow> 
      ))}

      {formValues.length !== 0 &&
        <StyledDivTotal>
            <Col sm={6} style={{fontSize: '18px'}}>
              {t('F3_S7_Resource_total_activity')+" "+ (activity ? activity.name : '')}
            </Col>
            <Col sm={6} style={{display:'flex'}}>
                <StyledCol sm={4}>
                  <MoneyTextMask value={activityTotal.ueBudget} />
                </StyledCol>
                <StyledCol sm={4}>
                  <MoneyTextMask value={activityTotal.counterpartBudget} />
                </StyledCol>
                <StyledCol sm={4}>
                  <MoneyTextMask value={activityTotal.totalCost} />
                </StyledCol>
            </Col>
        </StyledDivTotal>}
    </div>
  )

}

export default Section7ExecutedBudget;
