import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { formInitialValues } from "./AssessmentForm.initialValues";
import { validationSchema } from "./AssessmentForm.validations";
import {
  saveAssessmentsF5S7,
  getAssessmentsF5S7,
  saveAssessmentsMgmF5S7,
} from "services/initiative";
import { formatDateDDMMYY } from "helpers/dateHelpers";
import { useRootStore } from "stores";
import { roleNamesConst } from "constants/appConstants";

import HelpIcon from "components/HelpIcon";
import { PrimaryButton } from "components/Buttons";
import { StyledCheck } from "./AssessmentForm.styled";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorButton, StyledSendButton } from "components/Buttons";
import { Form } from "react-bootstrap";
import {
  FormFielErrorMessage,
  RequiredMark,
  CenteredModalButtonContainer,
} from "components/CommonStyled";
import { isWindows2021 } from 'helpers/isWindow2021Helper'

const AssessmentForm = ({
  handleCloseOk,
  handleClose,
  activity,
  handleCloseSubModalOnSave,
  handleCloseSubModalOnError,
  nonEditable,
  nonEditableMgm,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { authStore, initiativeStore} = useRootStore();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [disabled, setDisabled] = useState(nonEditable);
  const role = authStore.roles[0];
  const isAtke = role === roleNamesConst.ATKE;
  const isPublic = role === roleNamesConst.PUBLIC;
  const [window2021, setWindow2021] = useState(true)

  const handleSubmit = async (values) => {
    try {
      if (isAtke) await saveAssessmentsMgmF5S7(id, activity.id, values);
      else await saveAssessmentsF5S7(id, activity.id, values);
      handleCloseSubModalOnSave();
    } catch (error) {
      handleCloseSubModalOnError();
    }
  };

  useEffect(() => {
    if (initiativeStore.code) {
      setWindow2021(isWindows2021(initiativeStore.code))
    }
  }, [initiativeStore.formStateName, initiativeStore.code])

  useEffect(() => {
    async function getValues() {
      let response = await getAssessmentsF5S7(id, activity.id);
      const formValues = { ...formInitialValues, ...response.data };
      setInitialValues(formValues);

      if (!isPublic) setDisabled(true);
      else setDisabled(formValues.assessmentConfirmed);

      if (activity.activityState.name === "CANCELLED") {
        setDisabled(true);
      }
    }
    getValues();
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <Form onSubmit={handleSubmit} id="assessments">
            <Form.Group>
              <Form.Label
                style={{
                  alignItems: "center",
                  textAlign: "justify",
                  lineHeight: "1.3",
                }}
              >
                {t("F5_S7_Activity_assessment_10")}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Form.Control
                name="activityAsst10"
                as="textarea"
                rows={3}
                placeholder={t("Maximum_1300")}
                style={{ resize: "none" }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.activityAsst10}
                className={
                  touched.activityAsst10 && errors.activityAsst10
                    ? "error"
                    : null
                }
                disabled={disabled}
              />
              {touched.activityAsst10 && errors.activityAsst10 ? (
                <FormFielErrorMessage>
                  {errors.activityAsst10}
                </FormFielErrorMessage>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: "center",
                  textAlign: "justify",
                  lineHeight: "1.3",
                }}
              >
                {t("F5_S7_Activity_assessment_6")}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Form.Control
                name="activityAsst6"
                as="textarea"
                rows={3}
                placeholder={t("Maximum_1300")}
                style={{ resize: "none" }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.activityAsst6}
                className={
                  touched.activityAsst6 && errors.activityAsst6 ? "error" : null
                }
                disabled={disabled}
              />
              {touched.activityAsst6 && errors.activityAsst6 ? (
                <FormFielErrorMessage>
                  {errors.activityAsst6}
                </FormFielErrorMessage>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: "center",
                  textAlign: "justify",
                  lineHeight: "1.3",
                }}
              >
                {t("F5_S7_Activity_assessment_12")}
                <RequiredMark>*</RequiredMark>:
                <HelpIcon info={t("F5_S7_Activity_assessment_12_INFO")} />
              </Form.Label>
              <Form.Control
                name="activityAsst12"
                as="textarea"
                rows={3}
                placeholder={t("Maximum_1300")}
                style={{ resize: "none" }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.activityAsst12}
                className={
                  touched.activityAsst12 && errors.activityAsst12
                    ? "error"
                    : null
                }
                disabled={disabled}
              />
              {touched.activityAsst12 && errors.activityAsst12 ? (
                <FormFielErrorMessage>
                  {errors.activityAsst12}
                </FormFielErrorMessage>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: "center",
                  textAlign: "justify",
                  lineHeight: "1.3",
                }}
              >
                {t("F5_S7_Activity_assessment_2")}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Form.Control
                name="activityAsst2"
                as="textarea"
                rows={3}
                placeholder={t("Maximum_1300")}
                style={{ resize: "none" }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.activityAsst2}
                className={
                  touched.activityAsst2 && errors.activityAsst2 ? "error" : null
                }
                disabled={disabled}
              />
              {touched.activityAsst2 && errors.activityAsst2 ? (
                <FormFielErrorMessage>
                  {errors.activityAsst2}
                </FormFielErrorMessage>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label
                style={{
                  alignItems: "center",
                  textAlign: "justify",
                  lineHeight: "1.3",
                }}
              >
                {t("F5_S7_Activity_assessment_3")}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Form.Control
                name="activityAsst3"
                as="textarea"
                rows={3}
                placeholder={t("Maximum_1300")}
                style={{ resize: "none" }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.activityAsst3}
                className={
                  touched.activityAsst3 && errors.activityAsst3 ? "error" : null
                }
                disabled={disabled}
              />
              {touched.activityAsst3 && errors.activityAsst3 ? (
                <FormFielErrorMessage>
                  {errors.activityAsst3}
                </FormFielErrorMessage>
              ) : null}
            </Form.Group>

            {window2021 ? (
              <>
                <Form.Group>
                  <Form.Label
                    style={{
                      alignItems: "center",
                      textAlign: "justify",
                      lineHeight: "1.3",
                    }}
                  >
                    {t("F5_S7_Activity_assessment_1")}
                    <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Form.Control
                    name="activityAsst1"
                    as="textarea"
                    rows={3}
                    placeholder={t("Maximum_1300")}
                    style={{ resize: "none" }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.activityAsst1}
                    className={
                      touched.activityAsst1 && errors.activityAsst1 ? "error" : null
                    }
                    disabled={disabled}
                  />
                  {touched.activityAsst1 && errors.activityAsst1 ? (
                    <FormFielErrorMessage>
                      {errors.activityAsst1}
                    </FormFielErrorMessage>
                  ) : null}
                </Form.Group>

                <Form.Group>
                  <Form.Label
                    style={{
                      alignItems: "center",
                      textAlign: "justify",
                      lineHeight: "1.3",
                    }}
                  >
                    {t("F5_S7_Activity_assessment_4")}
                    <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Form.Control
                    name="activityAsst4"
                    as="textarea"
                    rows={3}
                    placeholder={t("Maximum_1300")}
                    style={{ resize: "none" }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.activityAsst4}
                    className={
                      touched.activityAsst4 && errors.activityAsst4 ? "error" : null
                    }
                    disabled={disabled}
                  />
                  {touched.activityAsst4 && errors.activityAsst4 ? (
                    <FormFielErrorMessage>
                      {errors.activityAsst4}
                    </FormFielErrorMessage>
                  ) : null}
                </Form.Group>

                <Form.Group>
                  <Form.Label
                    style={{
                      alignItems: "center",
                      textAlign: "justify",
                      lineHeight: "1.3",
                    }}
                  >
                    {t("F5_S7_Activity_assessment_5")}
                    <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Form.Control
                    name="activityAsst5"
                    as="textarea"
                    rows={3}
                    placeholder={t("Maximum_1300")}
                    style={{ resize: "none" }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.activityAsst5}
                    className={
                      touched.activityAsst5 && errors.activityAsst5 ? "error" : null
                    }
                    disabled={disabled}
                  />
                  {touched.activityAsst5 && errors.activityAsst5 ? (
                    <FormFielErrorMessage>
                      {errors.activityAsst5}
                    </FormFielErrorMessage>
                  ) : null}
                </Form.Group>

                <Form.Group>
                  <Form.Label
                    style={{
                      alignItems: "center",
                      textAlign: "justify",
                      lineHeight: "1.3",
                    }}
                  >
                    {t("F5_S7_Activity_assessment_7")}
                    <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Form.Control
                    name="activityAsst7"
                    as="textarea"
                    rows={3}
                    placeholder={t("Maximum_1300")}
                    style={{ resize: "none" }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.activityAsst7}
                    className={
                      touched.activityAsst7 && errors.activityAsst7 ? "error" : null
                    }
                    disabled={disabled}
                  />
                  {touched.activityAsst7 && errors.activityAsst7 ? (
                    <FormFielErrorMessage>
                      {errors.activityAsst7}
                    </FormFielErrorMessage>
                  ) : null}
                </Form.Group>

                <Form.Group>
                  <Form.Label
                    style={{
                      alignItems: "center",
                      textAlign: "justify",
                      lineHeight: "1.3",
                    }}
                  >
                    {t("F5_S7_Activity_assessment_8")}
                    <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Form.Control
                    name="activityAsst8"
                    as="textarea"
                    rows={3}
                    placeholder={t("Maximum_1300")}
                    style={{ resize: "none" }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.activityAsst8}
                    className={
                      touched.activityAsst8 && errors.activityAsst8 ? "error" : null
                    }
                    disabled={disabled}
                  />
                  {touched.activityAsst8 && errors.activityAsst8 ? (
                    <FormFielErrorMessage>
                      {errors.activityAsst8}
                    </FormFielErrorMessage>
                  ) : null}
                </Form.Group>

                <Form.Group>
                  <Form.Label
                    style={{
                      alignItems: "center",
                      textAlign: "justify",
                      lineHeight: "1.3",
                    }}
                  >
                    {t("F5_S7_Activity_assessment_9")}
                    <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Form.Control
                    name="activityAsst9"
                    as="textarea"
                    rows={3}
                    placeholder={t("Maximum_1300")}
                    style={{ resize: "none" }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.activityAsst9}
                    className={
                      touched.activityAsst9 && errors.activityAsst9 ? "error" : null
                    }
                    disabled={disabled}
                  />
                  {touched.activityAsst9 && errors.activityAsst9 ? (
                    <FormFielErrorMessage>
                      {errors.activityAsst9}
                    </FormFielErrorMessage>
                  ) : null}
                </Form.Group>

                <Form.Group>
                  <Form.Label
                    style={{
                      alignItems: "center",
                      textAlign: "justify",
                      lineHeight: "1.3",
                    }}
                  >
                    {t("F5_S7_Activity_assessment_11")}
                    <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Form.Control
                    name="activityAsst11"
                    as="textarea"
                    rows={3}
                    placeholder={t("Maximum_1300")}
                    style={{ resize: "none" }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.activityAsst11}
                    className={
                      touched.activityAsst11 && errors.activityAsst11
                        ? "error"
                        : null
                    }
                    disabled={disabled}
                  />
                  {touched.activityAsst11 && errors.activityAsst11 ? (
                    <FormFielErrorMessage>
                      {errors.activityAsst11}
                    </FormFielErrorMessage>
                  ) : null}
                </Form.Group>
              </>
            ) : null}

            <StyledCheck>
              <Form.Group>
                <Form.Check
                  disabled={
                    (disabled && !isAtke) ||
                    (isAtke && nonEditableMgm) ||
                    activity.activityState.name === "CANCELLED"
                  }
                  style={{ marginLeft: "15px", textAlign: "justify" }}
                  name="assessmentConfirmed"
                  type="checkbox"
                  checked={values.assessmentConfirmed}
                  value={values.assessmentConfirmed}
                  onChange={handleChange}
                  label={t("F5_S7_Assessment_confirmation_check")}
                  className={
                    touched.assessmentConfirmed && errors.assessmentConfirmed
                      ? "error"
                      : null
                  }
                />
                {touched.assessmentConfirmed && errors.assessmentConfirmed ? (
                  <FormFielErrorMessage>
                    {errors.assessmentConfirmed}
                  </FormFielErrorMessage>
                ) : null}
              </Form.Group>
            </StyledCheck>

            {values.assessmentTimestamp && (
              <div style={{ marginTop: "10px" }}>
                {`${t("F5_S7_Confirmed")}: ${
                  values.assessmentTimestamp
                    ? formatDateDDMMYY(values.assessmentTimestamp)
                    : ""
                }`}
              </div>
            )}

            <CenteredModalButtonContainer>
              {(disabled && !isAtke) ||
              (isAtke && nonEditableMgm) ||
              activity.activityState.name === "CANCELLED" ? (
                <PrimaryButton
                  onClick={() => {
                    handleCloseOk();
                  }}
                >
                  
                  OK
                </PrimaryButton>
              ) : (
                <>
                  <ErrorButton
                    style={{ marginRight: "20px" }}
                    onClick={handleClose}
                  >
                    
                    {t("Button_cancel")}
                  </ErrorButton>
                  <StyledSendButton type="submit" form="assessments">
                    
                    {t("Button_save")}
                  </StyledSendButton>
                </>
              )}
            </CenteredModalButtonContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default observer(AssessmentForm);
