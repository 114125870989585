import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

export const StyledSubtitle = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    margin-bottom: 10px;
    padding-left: 15px;
    color: ${theme.brandColors.blue};
    letter-spacing: 2px;
    font-weight: 650;
  `}
`;

export const StyledFormLabel = styled(Form.Label)`
  padding-left: 12px;
  margin-bottom: -10px;
`;

export const StyledSeparator = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 50px;
  height: 2px;
  background-image: linear-gradient(to right, #bac5cc 33%, rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 6px 2px;
  background-repeat: repeat-x;
`;

export const StyledTextValue = styled(Col)`
  padding-left: 65px;
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    color: ${theme.colors.gray[5]};
  `}
`;

export const TextValueLeftSpaced = styled.div`
  padding-left: 65px;
`;

export const StyledDivWrapper = styled.div`
  margin-left: 50px;
`;