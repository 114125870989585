import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { formInitialValues } from "./BudgetLineForm.initialValues";
import { validationSchema } from "./BudgetLineForm.validations";
import {
  saveResourceDetailF3S7,
  saveFile,
  getDownload,
} from "services/initiative";
import { useConstantsForm3 } from "customHooks/useConstantsForm3";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useRootStore } from "stores";
import { useTranslation } from "react-i18next";
import htmlParser from "html-react-parser";
import { WORKING_DAYS_CONSTANT } from 'constants/budgetConstants';

import { PrimaryButton } from "components/Buttons";
import InputGroup from "react-bootstrap/InputGroup";
import HelpIcon from "components/HelpIcon";
import MoneyInput from "components/MoneyInput";
import Modal from "react-bootstrap/Modal";
import { StyledFormLabel } from "../Section7.styled";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ErrorButton, StyledSendButton } from "components/Buttons";
import { Form } from "react-bootstrap";
import {
  RequiredMark,
  CenteredModalButtonContainer,
} from "components/CommonStyled";
import {
  StyledModalBody,
  ModalTitle,
} from "./BudgetLineForm.styled";
import FileUploader from "components/FileUploader";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const BudgetLineForm = ({
  handleCloseOk,
  setNewToLocalList,
  setLocalTotalBudget,
  show,
  handleClose,
  handleCloseOnSave,
  handleCloseOnError,
  activity,
  currentBudget,
  validFileFormats,
  setModalInfo,
  nonEditable,
}) => {
  const { formStore } = useRootStore();
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const { resourceTypes } = useConstantsForm3();
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const PERDIEM_CONSTANT = 250;

  useEffect(() => {
    if (currentBudget !== null) {
      document.getElementById("EURadio").checked =
        currentBudget.ueOrCounterpart == 0;
      document.getElementById("CPRadio").checked =
        currentBudget.ueOrCounterpart == 1;
      setInitialValues(currentBudget);
    } else setInitialValues(formInitialValues);
  }, [currentBudget]);

  const onSave = async (values) => {
    try {
      values.totalMoney =
        WORKING_DAYS_CONSTANT * values.workingDays +
        PERDIEM_CONSTANT * values.perDiemDays +
        values.estimatedCost;
      const response = await saveResourceDetailF3S7(id, activity.id, values);
      const currentActivitiesCopy = [
        ...formStore.formSectionData[0].activities,
      ];
      currentActivitiesCopy.splice(
        currentActivitiesCopy.findIndex(function (i) {
          return i.id == response.id;
        }),
        1
      );
      const sortedActivities = [...currentActivitiesCopy, response];
      sortedActivities.sort((a, b) => a.id - b.id);
      formStore.setFormSectionData([
        {
          activities: sortedActivities,
        },
      ]);
      setLocalTotalBudget({
        ueBudget: response.ueBudget,
        counterpartBudget: response.counterpartBudget,
        totalBudget: response.totalBudget,
      });
      setNewToLocalList(response.resourceList);
      handleCloseOnSave();
    } catch (error) {
      console.log(error);
      handleCloseOnError();
    }
  };

  const handleFileUpload = async (e, setFieldValue, handleChange) => {
    try {
      const file = document.getElementById("fileInput").files[0];
      const fileExtension = file.name.split(".").pop();
      if (file.size > 20971520) {
        setModalInfo({
          message: "The selected file is too big. Max file size is 20MB.",
          type: "error",
          isOpen: true,
        });
      } else if (!validFileFormats.includes(fileExtension.toUpperCase())) {
        setModalInfo({
          message: "Wrong file type.",
          type: "error",
          isOpen: true,
        });
      } else {
        setIsUploadingFile(true);
        const uid = await saveFile(id, formId, sectionId, file);
        setFieldValue("file.fileName", file.name);
        setFieldValue("file.uuid", uid);
        handleChange(e);
      }
    } catch (error) {
      setModalInfo({
        message: "Failed to upload file. Please try again.",
        type: "error",
        isOpen: true,
      });
    } finally {
      setIsUploadingFile(false);
    }
  };

  const handleDownload = async (uuid, downloadName) => {
    try {
      const file = await getDownload(uuid).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadName);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      setModalInfo({
        message: "Error fetching file. Please try again.",
        type: "error",
        isOpen: true,
      });
    }
  };

  const renderResourceTypes = () => {
    let resourceTypesFiltered = [...resourceTypes];
    if (activity.activityType.id != 4) {
      resourceTypesFiltered.shift();
    }
    if (activity.activityType.id == 6) {
      resourceTypesFiltered.splice(0, 3);
    }
    return resourceTypesFiltered.map((resource) => (
      <option key={resource.id} value={resource.id}>
        {t(`resource_type_${resource.id}`)}
      </option>
    ));
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      backdrop="static"
      animation={false}
    >
      <StyledModalBody>
        <ModalTitle>{`${t("F3_S7_Budgetline_subtitle")} ${
          !activity.name ? "" : `- ${t("F1_S7_Accordion")} ${activity.name}`
        }`}</ModalTitle>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group as={Row}>
                <StyledFormLabel column lg={4}>
                  {t("F3_S7_Budgetline_resources_cat")}
                  <RequiredMark>*</RequiredMark>:
                  <HelpIcon info={t("F3_S7_Budgetline_resources_cat_INFO")} />
                </StyledFormLabel>
                <Col lg={4}>
                  <Form.Control
                    name="resourceTypeId"
                    as="select"
                    onChange={(e) => {
                      setFieldValue("perDiemDays", 0);
                      setFieldValue("workingDays", 0);
                      setFieldValue("estimatedCost", 0);
                      if (e.currentTarget.value == 12) {
                        setFieldValue("ueOrCounterpart", 1);
                        document.getElementById('CPRadio').checked = true
                      }
                      handleChange(e);
                    }}
                    value={values.resourceTypeId}
                    disabled={nonEditable}
                  >
                    <option value="">{t("Placeholder_select")}</option>
                    {renderResourceTypes()}
                  </Form.Control>
                  <FormErrorMessage
                    touched={touched.resourceTypeId}
                    errors={errors.resourceTypeId}
                  />
                </Col>
              </Form.Group>

              <Form.Group>
                <Form.Label
                  style={{
                    alignItems: "center",
                    textAlign: "justify",
                    lineHeight: "1.3",
                  }}
                >
                  {t("F3_S7_Budgetline_description")}
                  <RequiredMark>*</RequiredMark>:
                  <HelpIcon info={t("F3_S7_Budgetline_description_INFO")} />
                </Form.Label>
                <Form.Control
                  name="description"
                  as="textarea"
                  rows={3}
                  placeholder="A maximum of 600 characters"
                  style={{ resize: "none" }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  className={
                    touched.description && errors.description ? "error" : null
                  }
                  disabled={nonEditable}
                />
                <FormErrorMessage
                  touched={touched.description}
                  errors={errors.description}
                />
              </Form.Group>

              <Form.Group>
                <Row>
                  <StyledFormLabel>
                    {t("F3_S7_Budgetline_who_will_pay")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <label>
                    <input
                      disabled={nonEditable || values.resourceTypeId == 12}
                      id="EURadio"
                      type="radio"
                      name="ueOrCounterpart"
                      value={"0"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ marginLeft: "30px" }}
                    />
                    <span style={{ marginLeft: "5px" }}>

                      {t("F3_S7_Budgetline_EU_pays")}
                    </span>
                  </label>
                  <label>
                    <input
                      disabled={nonEditable || values.resourceTypeId == 12}
                      id="CPRadio"
                      type="radio"
                      name="ueOrCounterpart"
                      value={"1"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ marginLeft: "50px" }}
                    />
                    <span style={{ marginLeft: "5px" }}>

                      {t("F3_S7_Budgetline_EU_parnership")}
                    </span>
                  </label>
                </Row>
                <div style={{ marginLeft: "0" }}>
                  <FormErrorMessage
                    touched={touched.ueOrCounterpart}
                    errors={errors.ueOrCounterpart}
                  />
                </div>
              </Form.Group>

              {values.resourceTypeId == 1 && (
                <Form.Group as={Row}>
                  <Form.Label column md={2} xs={3}>
                    {t("F3_S7_Budgetline_working_days")}
                    <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Col xs={3}>
                    <Form.Control
                      type="number"
                      min="0"
                      name="workingDays"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.workingDays}
                      className={
                        touched.workingDays && errors.workingDays
                          ? "error"
                          : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.workingDays}
                      errors={errors.workingDays}
                    />
                  </Col>
                </Form.Group>
              )}

              {values.resourceTypeId == 9 && (
                <Form.Group as={Row}>
                  <Form.Label column md={2} xs={3}>
                    {t("F3_S7_Budgetline_perdime_days")}
                    <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Col xs={3}>
                    <Form.Control
                      type="number"
                      min="0"
                      name="perDiemDays"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.perDiemDays}
                      className={
                        touched.perDiemDays && errors.perDiemDays
                          ? "error"
                          : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.perDiemDays}
                      errors={errors.perDiemDays}
                    />
                  </Col>
                </Form.Group>
              )}

              {values.resourceTypeId != 9 && values.resourceTypeId != 1 && (
                <Form.Group as={Row} style={{ alignItems: "center" }}>
                  <Form.Label column md={2} xs={3}>
                    {t("F3_S7_Budgetline_estimated_cost")}
                    <RequiredMark>*</RequiredMark>:
                  </Form.Label>
                  <Col xs={3}>
                    <InputGroup style={{ width: "240px" }}>
                      <MoneyInput
                        name="estimatedCost"
                        value={values.estimatedCost}
                        onChange={(val) => setFieldValue("estimatedCost", val)}
                        onBlur={handleBlur}
                        customInput={Form.Control}
                        className={
                          touched.estimatedCost && errors.estimatedCost
                            ? "error"
                            : null
                        }
                        disabled={nonEditable}
                      />
                    </InputGroup>
                    <FormErrorMessage
                      touched={touched.estimatedCost}
                      errors={errors.estimatedCost}
                    />
                  </Col>
                </Form.Group>
              )}

              <Form.Group as={Row} style={{ alignItems: "center" }}>
                <Form.Label column md={2} xs={3}>
                  {t("F3_S7_Budgetline_total_amount")}:
                </Form.Label>
                <Col xs={3}>
                  <InputGroup style={{ width: "240px" }}>
                    <MoneyInput
                      name="estimatedCost"
                      value={
                        WORKING_DAYS_CONSTANT * values.workingDays +
                        PERDIEM_CONSTANT * values.perDiemDays +
                        values.estimatedCost
                      }
                      customInput={Form.Control}
                      disabled={true}
                    />
                  </InputGroup>
                </Col>
                <Col xs={4}>
                  {htmlParser(t("F3_S7_Budgetline_eur_conversion_link"))}
                </Col>
              </Form.Group>

              <FileUploader
                file={values?.file}
                handleFileUpload={(e) => handleFileUpload(e, setFieldValue, handleChange)}
                handleDownload={handleDownload}
                isUploadingFile={isUploadingFile}
                uploadLabel={`${t("F3_S7_Upload_quote")} (${validFileFormats.join(", ")})`}
                nonEditable={nonEditable}
              />

              <CenteredModalButtonContainer>
                {nonEditable ? (
                  <PrimaryButton
                    onClick={() => {
                      handleCloseOk();
                    }}
                  >
                    OK
                  </PrimaryButton>
                ) : (
                  <>
                    <ErrorButton
                      style={{ marginRight: "20px" }}
                      onClick={handleClose}
                      disabled={nonEditable}
                    >

                      {t("Button_cancel")}
                    </ErrorButton>
                    <StyledSendButton type="submit" disabled={nonEditable}>

                      {t("Button_save")}
                    </StyledSendButton>
                  </>
                )}
              </CenteredModalButtonContainer>
            </Form>
          )}
        </Formik>
      </StyledModalBody>
    </Modal>
  );
};

export default observer(BudgetLineForm);
