import React, {useState} from 'react';
import { observer } from 'mobx-react-lite';
import { useFormikContext } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { sendF8, modifyF8 } from 'services/initiative';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { useRootStore } from "stores";
import Proptypes from 'prop-types';

import CustomModal from 'components/Modal';
import Modal from 'react-bootstrap/Modal';
import { ModalFooter, FooterLeft, FooterCenter, FooterRight } from './SendDialog.styled';
import { ErrorButton, PrimaryButton } from 'components/Buttons';


const SendDialog = ({ displayDialog, onCloseModal }) => {
  const { formId } = useParams();
  const [sending, setSending] = useState(false);
  const history = useHistory();
  const {t} = useTranslation();
  const { authStore } = useRootStore();
  const [modalError, setModalError] = useState(false);
  const { values } = useFormikContext() ?? {};

  const sendAction = async () => {
    try {
      setSending(true);
      await modifyF8(formId, values);
      await sendF8(formId);
      history.push(`${authStore.getBaseRouteByRole()}/management/expost-reporting`)
    } catch (error) {
      setModalError(true);
      console.log(error);
    }
    finally{
      onCloseModal();
      setSending(false);
    }
  }

  return (
    <>
      <CustomModal
        show={modalError}
        onHide={() => setModalError(false)}
        message={t("Error try again later")}
        type="error"
      />

      <Modal show={displayDialog} onHide={onCloseModal} size="lg">
        <Modal.Body>
          {htmlParser(t('F8_confirmation_to_send'))}
        </Modal.Body>
        <ModalFooter>
          <FooterLeft />
          <FooterCenter>
            <PrimaryButton disabled={sending} onClick={() => sendAction()}>{t('Yes')}</PrimaryButton>
            <ErrorButton disabled={sending} onClick={onCloseModal}>{t('No')}</ErrorButton>
          </FooterCenter>
          <FooterRight />
        </ModalFooter>
      </Modal>
    </>

  )
}

SendDialog.propTypes = {
  title: Proptypes.string,
  displayDialog: Proptypes.bool,
  onCloseModal: Proptypes.func,
};

export default observer(SendDialog);
