import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { mapActivityItems } from 'helpers/f5Helpers';

import Objective from './Objective';
import Form from 'react-bootstrap/Form';
import { RequiredMark, SeparatorDotted } from 'components/CommonStyled';
import {
  StyledFormLabel,
  BlackSubtitle,
  Title,
} from 'components/Forms/Form5/Section11/Section11.styled';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody 
} from '../CommonStyles'

const formInitialValues = [
  {
    overviewSDG: "",
    contribution2030: "",
  },
];

const Section11 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(formInitialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data]);
  
  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`11 - ${t('F3_S11_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>      
        <Title> {t('F5_Subtitle_Assessment_TCI_Implementation')} </Title>
        
        <BlackSubtitle> {t('F5_S7_Activity_assessment_9')} </BlackSubtitle>
        {(formValues && formValues[1]) && mapActivityItems(formValues[1])}

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_Overview_of_TCI')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="overviewSDG"
            as="textarea" 
            rows={7} 
            style={{resize: 'none'}}
            value={formValues[0].overviewSDG}
            disabled={true}
            placeholder={t('Maximum_1300')}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S11_From_contribution_agenda')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="contribution2030"
            as="textarea" 
            rows={7} 
            style={{resize: 'none'}}
            value={formValues[0].contribution2030}
            disabled={true}
            placeholder={t('Maximum_1300')}
          />
        </Form.Group>
        
        <Title> {t('F5_S11_Subtitle_new_SDGs')} </Title>

        <Objective title={t('F3_S11_Subtitle_SDG_primary')} data={{
          goalId: formValues[0] && formValues[0].goalPrimaryType ? formValues[0].goalPrimaryType.id : '',
          justification: formValues[0].primaryJustification,
          targetList: formValues[0].primaryTargetList,
        }} />

        <Objective title={t('F3_S11_Subtitle_SDG_secondary')} data={{
          goalId: formValues[0] && formValues[0].goalSecondaryType ? formValues[0].goalSecondaryType.id : '',
          justification: formValues[0].secondaryJustification,
          targetList: formValues[0].secondaryTargetList,
        }} />

        <Objective title={t('F3_S11_Subtitle_SDG_10')} data={{
          goalId: formValues[0] && formValues[0].goal10Type ? formValues[0].goal10Type.id : '',
          justification: formValues[0].goal10Justification,
          targetList: formValues[0].goal10TargetList,
        }} />

        <Objective title={t('F3_S11_Subtitle_SDG_17')} data={{
          goalId: formValues[0] && formValues[0].goal17Type ? formValues[0].goal17Type.id : '',
          justification: formValues[0].goal17Justification,
          targetList: formValues[0].goal17TargetList,
        }} />

      </StyledBody>
    </SectionContainer>
  )
}

export default observer(Section11);
