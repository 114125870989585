import styled, { css } from 'styled-components'

export const ActivitySubItem = styled.div`
  margin-bottom: 20px;
  cursor: pointer;
  font-weight: 700;
`;

export const CompleteImage = styled.img`
  margin-bottom: 5px;
  height: auto; 
  width: auto; 
  max-width: 30px; 
  max-height: 30px;
`;