import React from 'react'
import { useTranslation } from 'react-i18next'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import { StyledRequiredSpan } from '../styled'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { RadioLabel } from 'components/Forms/Forms.styled'
import { useConstantsForm1 } from 'customHooks/useConstantsForm1'
import { renderCountriesOptions } from 'helpers/renderCountriesHelper'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'
import _get from 'lodash/get'

const fieldError = (formikInstance, field) => {
  return (
    <FormErrorMessage
      touched={_get(formikInstance?.touched, field, false)}
      errors={_get(formikInstance?.errors, field, false)}
    />
  )
}

const ActivityForm = ({ formikInstance, activityIndex }) => {
  const { t } = useTranslation()
  const { filteredCountries, activityTypes } = useConstantsForm1()
  const {
    getFieldProps,
    touched,
    errors,
    values,
    setFieldValue,
    handleChange,
  } = formikInstance
  const fieldPath = (field) => `activities.${activityIndex}.${field}`

  return (
    <div style={{marginLeft: "30px", marginBottom: "10px"}}>
      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F5_S7_Title_of_the_activity')} <StyledRequiredSpan>*</StyledRequiredSpan>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            as="textarea"
            rows={2}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_500')}
            name={fieldPath('title')}
            {...getFieldProps(fieldPath('title'))}
            className={
              touched[fieldPath('title')] && errors[fieldPath('title')]
                ? 'error'
                : null
            }
          />
          {fieldError(formikInstance, fieldPath('title'))}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('Country_host')} <StyledRequiredSpan>*</StyledRequiredSpan>:
        </Form.Label>
        <Col xs={4}>
          <Form.Control
            name={fieldPath('countryId')}
            {...getFieldProps(fieldPath('countryId'))}
            as="select"
            className={
              touched[fieldPath('countryId')] && errors[fieldPath('countryId')]
                ? 'error'
                : null
            }
          >
            {renderCountriesOptions(filteredCountries)}
          </Form.Control>
          {fieldError(formikInstance, fieldPath('countryId'))}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S7_Format')} <StyledRequiredSpan>*</StyledRequiredSpan>:
        </Form.Label>
        <Col lg={8} style={{ marginTop: '20px' }}>
          <RadioLabel>
            <input
              name={fieldPath('format')}
              type="radio"
              value={'0'}
              checked={values.activities[activityIndex].format === '0'}
              onChange={handleChange}
              style={{ marginRight: '10px' }}
            />
            {t('F1_S7_Format0')}
          </RadioLabel>

          <RadioLabel>
            <input
              name={fieldPath('format')}
              type="radio"
              value={'1'}
              checked={values.activities[activityIndex].format === '1'}
              onChange={handleChange}
              style={{ marginRight: '10px' }}
            />
            {t('F1_S7_Format1')}
          </RadioLabel>

          <RadioLabel>
            <input
              name={fieldPath('format')}
              type="radio"
              value={'2'}
              checked={values.activities[activityIndex].format === '2'}
              onChange={handleChange}
              style={{ marginRight: '10px' }}
            />
            {t('F1_S7_Format2')}
          </RadioLabel>

          <Row lg={8} style={{ marginLeft: '0px' }}>
            {fieldError(formikInstance, fieldPath('format'))}
          </Row>
        </Col>
      </Form.Group>

      <Form.Group as={Row} style={{ alignItems: 'center' }}>
        <Form.Label column lg={4}>
          {t('F1_S7_type')} <StyledRequiredSpan>*</StyledRequiredSpan>:
        </Form.Label>
        <Col xs={4}>
          <Form.Control
            as="select"
            name={fieldPath('activityTypeId')}
            {...getFieldProps(fieldPath('activityTypeId'))}
            className={
              touched[fieldPath('activityTypeId')] &&
              errors[fieldPath('activityTypeId')]
                ? 'error'
                : null
            }
          >
            <option value="">{t('Placeholder_select')}</option>
            {activityTypes.map((activityType) => (
              <option key={activityType.id} value={activityType.id}>
                {t(`activity_types_${activityType.id}`)}
              </option>
            ))}
          </Form.Control>
          {fieldError(formikInstance, fieldPath('activityTypeId'))}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('Funds_S7_product_count')} <StyledRequiredSpan>*</StyledRequiredSpan>:
        </Form.Label>
        <Col xs={4}>
          <Form.Control
            type="number"
            name={fieldPath('productCount')}
            {...getFieldProps(fieldPath('productCount'))}
            className={
              touched[fieldPath('productCount')] &&
              errors[fieldPath('productCount')]
                ? 'error'
                : null
            }
          />
          {fieldError(formikInstance, fieldPath('productCount'))}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('Funds_S7_main_products')} <StyledRequiredSpan>*</StyledRequiredSpan>:
        </Form.Label>
        <Col xs={8}>
          <Form.Control
            as="textarea"
            rows={3}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_1300')}
            name={fieldPath('mainProducts')}
            {...getFieldProps(fieldPath('mainProducts'))}
            className={
              touched[fieldPath('mainProducts')] &&
              errors[fieldPath('mainProducts')]
                ? 'error'
                : null
            }
          />
          {fieldError(formikInstance, fieldPath('mainProducts'))}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('fund_observations')} <StyledRequiredSpan>*</StyledRequiredSpan>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            as="textarea"
            rows={5}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_6500')}
            name={fieldPath('observations')}
            {...getFieldProps(fieldPath('observations'))}
            className={
              touched[fieldPath('observations')] && errors[fieldPath('observations')]
                ? 'error'
                : null
            }
          />
          {fieldError(formikInstance, fieldPath('observations'))}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S7_start_day')}:
        </Form.Label>
        <Col xs={6}>
          <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={values.activities[activityIndex].fromDate}
              onChange={(date) => {
                setFieldValue(fieldPath('fromDate'), date)
              }}
              className={
                touched[fieldPath('fromDate')] &&
                errors[fieldPath('fromDate')]
                  ? 'error'
                  : null
              }
              popperPlacement='top'
            />
          </InputGroup>
          {fieldError(formikInstance, fieldPath('fromDate'))}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S7_End_day')}:
        </Form.Label>
        <Col xs={6}>
          <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={values.activities[activityIndex].toDate}
              onChange={(date) => setFieldValue(fieldPath('toDate'), date)}
              className={
                touched[fieldPath('toDate')] &&
                errors[fieldPath('toDate')]
                  ? 'error'
                  : null
              }
              popperPlacement='top'
            />
          </InputGroup>
          {fieldError(formikInstance, fieldPath('toDate'))}
        </Col>
      </Form.Group>
    </div>
  )
}

export default ActivityForm
