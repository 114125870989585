import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {CenteredModalButtonContainer } from 'components/CommonStyled';
import { useTranslation } from 'react-i18next';
import { ErrorButton, StyledButtonLink} from 'components/Buttons';
import { generateCsv, mkConfig, download } from 'export-to-csv';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ModalParticipantsLits = (props) => {
  const {t} = useTranslation();
  
  const convertToCSV = (list, txt) => {
    const data = [];
    list.forEach(participant => {
       if(participant.confirmed) {
        data.push({
          [t('F1_S2_First_name')]: participant.firstName, 
          [t('F1_S2_Last_Name')]: participant.lastName,
          [t('F3_S7_Participant_nationality')]: t(participant.nationality.name),
          [t('F1_S2_City')]: participant.city,
          [t('organization')]: participant.organizationName,
          [t('F3_S7_Participant_type_entity')]: t(`entity_type_${participant.entityType.id}`),
          [t('forgot_password_email')]: participant.email,
          [t('F1_S2_Phone_number')]: participant.phone,
          [t('F5_S7_Participant_attend')]: participant.willAttend? t('Yes'): t('No')
        })
       }
    })

    const options = mkConfig({ 
      fieldSeparator: ',',
      quoteStrings: true,
      filename: t('F5_S7_Participants_list'),
      showTitle: true,
      title: t('F5_S7_Participants_list'),
      useTextFile: txt,
      useBom: true,
      useKeysAsHeaders: false,
      columnHeaders: [
        t('F1_S2_First_name'), 
        t('F1_S2_Last_Name'),
        t('F3_S7_Participant_nationality'),
        t('F1_S2_City'),
        t('organization'),
        t('F3_S7_Participant_type_entity'),
        t('forgot_password_email'),
        t('F1_S2_Phone_number'),
        t('F5_S7_Participant_attend'),
      ] 
    });
    const csv = generateCsv(options)(data);
    download(options)(csv)
  }

  const showList = (list) => {
    let csv = [t('F1_S2_First_name'), t('F1_S2_Last_Name'), t('F3_S7_Participant_nationality'), t('F1_S2_City'), t('organization'), t('F3_S7_Participant_type_entity'), t('forgot_password_email'), t('F1_S2_Phone_number'), t('F5_S7_Participant_attend')];
    
    csv.join(',');
    csv = csv+'\n';

    list.forEach(participant => {
      if(participant.confirmed)
        csv = csv+`${participant.firstName},${participant.lastName},${t(participant.nationality.name)},${participant.city},${participant.organizationName},${t(`entity_type_${participant.entityType.id}`)},${participant.email},${participant.phone},${participant.willAttend? t('Yes'): t('No')}\n`
    })

    return csv;
  }

    return (
      <Modal
        {...props}
        centered
        size="xl"
        animation={false}
      >
        <Modal.Body>  
          <Form.Group>
            <Form.Label style={{marginTop: '20px', marginBottom: '20px'}}>{t('F5_S7_Participants_list')}</Form.Label>
            <Form.Control
              name="comment"
              as="textarea" 
              rows={10}
              value={showList(props.participantsList)}
              style={{resize: 'none'}}
            />
          </Form.Group> 

          <Col>
            <Row>
              <StyledButtonLink variant="link" onClick={() => {convertToCSV(props.participantsList,false)}}>
                {t('F5_S7_Download_CSV')}
              </StyledButtonLink>
            </Row>
            <Row>
              <StyledButtonLink variant="link" onClick={() => {convertToCSV(props.participantsList,true)}}>
                {t('F5_S7_Download_TXT')}
              </StyledButtonLink>
            </Row>
          </Col>
           
          <CenteredModalButtonContainer>
            <ErrorButton onClick={() => {props.onHide()}}>{t('Button_close')}</ErrorButton>
          </CenteredModalButtonContainer>

        </Modal.Body >
      </Modal>
    );
  }

export default ModalParticipantsLits; 