import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RequiredMark } from 'components/CommonStyled';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  const getFieldTouched = getIn(formikInstance.touched, field);
  return {
    touched: getFieldTouched,
    error: (
      <FormErrorMessage
        touched={getFieldTouched}
        errors={getFieldError}
      />
    )
  }
}
  
const MediaForm = ({ formikInstance, mediaToolIndex, mediaNames, nonEditable }) => {
  const { t } = useTranslation();
  const { getFieldProps, touched, errors, values, setFieldValue} = formikInstance;
  const fieldPath = (field) => `items.${mediaToolIndex}.${field}`;

  useEffect(() => {
    let value = values.items[mediaToolIndex].mediaTypeId > 5 ? "-n/a-":values.items[mediaToolIndex].url;
    setFieldValue(fieldPath('url'),value);
  }, [values.items, mediaToolIndex]) 

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F1_S8_Media_type')}:<RequiredMark>*</RequiredMark></Form.Label>
        <Col lg={8}>
          <Form.Control
            name={fieldPath('mediaTypeId')}
            as="select"
            { ...getFieldProps(fieldPath('mediaTypeId')) }
            className={touched[fieldPath('mediaTypeId')] && errors[fieldPath('mediaTypeId')] ? "error" : null}
            disabled={nonEditable}
          >
            <option value="">{t('Placeholder_select')}</option>
            {mediaNames.map(media => (
              <option key={media.id} value={media.id}>{t(`media_type_${media.id}`)}</option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath('mediaTypeId')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F1_S8_Link_account')}: <RequiredMark>*</RequiredMark></Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath('url')}
            { ...getFieldProps(fieldPath('url')) }
            className={touched[fieldPath('url')] && errors[fieldPath('url')] ? "error" : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('url')).error}
        </Col>
      </Form.Group>  

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F1_S8_Media_name')}: </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath('comments')}
            { ...getFieldProps(fieldPath('comments')) }
            className={touched[fieldPath('comments')] && errors[fieldPath('comments')] ? "error" : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('comments')).error}
        </Col>
      </Form.Group>  

      {/* See AD2-1656 */}
      <div style={{ display: 'none'}}>
        <Form.Group as={Row} style={{alignItems: 'center'}}>
          <Form.Label column lg={4}>{t('F3_S14_Number_users')}: <RequiredMark>*</RequiredMark></Form.Label>
          <Col lg={8}>
            <Form.Control
              type="number"
              min={0}
              name={fieldPath('quantity')}
              { ...getFieldProps(fieldPath('quantity')) }
              className={touched[fieldPath('quantity')] && errors[fieldPath('quantity')] ? "error" : null}
              disabled={nonEditable}
            />
            {fieldInfo(formikInstance, fieldPath('quantity')).error}
          </Col>
        </Form.Group> 
      </div>
 
    </>
  )

}

export default MediaForm;
