import React from 'react'
import Header from './Header'
import Report from './Report'
import { AdelanteCommunityFeaturesContextProvider } from 'customHooks/useAdelanteCommunityFeatures'

const CommunityReport = () => {
  return (
    <>
      <Header />
      <Report />
    </>
  )
}

const CommunityReportContainer = () => {
  return (
    <AdelanteCommunityFeaturesContextProvider>
      <CommunityReport />
    </AdelanteCommunityFeaturesContextProvider>
  )
};

export default CommunityReportContainer
