import React from 'react'
import editIcon from 'assets/icon-edit.png'

import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

const DeadlineButton = ({ onClick }) => {
  return (
    <Button onClick={() => onClick()} style={{all: 'unset', cursor: 'pointer'}}variant="outline-light"> <Image src={editIcon} /> </Button>
  )
}

export default DeadlineButton;