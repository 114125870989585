import React from 'react';
import {  Link } from 'react-router-dom';
import { useRootStore } from 'stores';
import { formatDateDDMMYY } from 'helpers/dateHelpers';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { f2statusNamesConst } from 'constants/appConstants';

import {
  StyledTr,
  StyledSpan
} from './TableRow.styled'

const TableRow = ({ item, isIntpa, isEud, isATKE, isCONSORTIUM}) => {
  const { t } = useTranslation();
  const { initiativeStore, authStore } = useRootStore();
  const todayDate = new Date();

  const setInitiativeOnStore = (initiative) => {
    initiativeStore.setInitiativeData(initiative);
  }

  return (
    <StyledTr key={item.id}>
      <td style={{minWidth:"100px"}}>
        <Link onClick={() => setInitiativeOnStore(item)} to={`${authStore.getBaseRouteByRole()}/expost-reporting/${item.id}?initiative=${item.initiative.code}`}>{item.initiative.code}</Link>
      </td>
      <td style={{maxWidth:"600px", overflowWrap: "break-word"}}>{(item.initiative && item.initiative.name)? item.initiative.name: t('Name_not_defined_yet')}</td>
      <td style={{minWidth:"100px"}}>{t(item.statusF8)}</td>
      <td>
        {
          item.deadlineF8 && item.statusF8 !== f2statusNamesConst.DONE
            ? (
              <StyledSpan expired={todayDate > new Date(item.deadlineF8)}>
                {formatDateDDMMYY(item.deadlineF8)}
              </StyledSpan>
            )
            : ""
        }
      </td>
      <td style={{minWidth:"100px"}}>{t(item.statusF8b)}</td>
      <td>
        {
          item.deadlineF8b && item.statusF8b !== f2statusNamesConst.DONE
            ? (
              <StyledSpan expired={todayDate > new Date(item.deadlineF8b)}>
                {formatDateDDMMYY(item.deadlineF8b)}
              </StyledSpan>
            )
            : ""
        }
      </td>
      {
        (isATKE || isCONSORTIUM) && 
        (
          <td style={{minWidth:"100px"}}>{t(item.statusF9c)}</td>
        )
      }
      {
        (isEud) && 
        (
          <>
            <td style={{minWidth:"100px"}}>{t(item.statusF9a)}</td>
            <td>
              {
                item.deadlineF9a && item.statusF9a !== f2statusNamesConst.DONE
                  ? (
                    <StyledSpan expired={todayDate > new Date(item.deadlineF9a)}>
                      {formatDateDDMMYY(item.deadlineF9a)}
                    </StyledSpan>
                  )
                  : ""
              }
            </td>
          </>
        )
      }
      {
        (isIntpa) && 
        (
          <>
            <td style={{minWidth:"100px"}}>{t(item.statusF9c)}</td>
            <td>
              {
                item.deadlineF9c && item.statusF9c !== f2statusNamesConst.DONE
                  ? (
                    <StyledSpan expired={todayDate > new Date(item.deadlineF9c)}>
                      {formatDateDDMMYY(item.deadlineF9c)}
                    </StyledSpan>
                  )
                  : ""
              }
            </td>
          </>
        )
      }
      {
        (isATKE || isCONSORTIUM) && 
        (
          <td style={{minWidth:"100px"}}>{item.statusF9c !== f2statusNamesConst.DONE ? '' : item.dateNewExPost ? formatDateDDMMYY(item.dateNewExPost) : t('discard_label')}</td>
        )
      }
    </StyledTr>

  )
}

export default observer(TableRow);