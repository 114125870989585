import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledFormLabel,
  StyledTitle,
  StyledCheckSubtitle,
  StyledSavedButton
} from './ATAssessmentForm.styled'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { RequiredMark } from 'components/CommonStyled';
import { PrimaryButton, SuccessButton } from 'components/Buttons';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const ATAssessmentForm = ({ formikInstance, nonEditable, handleSendToMainAssessor, sending, saved, onValidateSubmit, isSent, levelChecked }) => {
  const {t} = useTranslation();
  const { getFieldProps, touched, errors, setErrors, resetForm, values, handleBlur, handleChange, setFieldValue, isValid } = formikInstance;

  useEffect(() => {
    const isLevelChecked = !!levelChecked;
    document.getElementById('LevelYesRadio').checked = isLevelChecked;
    document.getElementById('LevelNoRadio').checked = !isLevelChecked;
  }, [levelChecked]);

  return (
    <div style={{marginBottom: '100px', marginLeft: "-15px"}}>
      <StyledTitle style={{marginLeft: '15px'}}>{t('F6_S18_subtitle_at_assessment')}</StyledTitle>

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F6_AT_assessment_question1')} <RequiredMark>*</RequiredMark>:</StyledFormLabel>
          <Form.Control
            name='knowledge'
            as="textarea"
            style={{resize: "none"}}
            rows={8}
            className={touched.knowledge && errors.knowledge ? "error" : null}
            { ...getFieldProps('knowledge') }
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.knowledge}
            errors={errors.knowledge}
          />
        </Col>
      </Form.Group>

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F6_AT_assessment_question2')} <RequiredMark>*</RequiredMark>:</StyledFormLabel>
          <Form.Control
            name='impact'
            as="textarea"
            style={{resize: "none"}}
            rows={8}
            className={touched.impact && errors.impact ? "error" : null}
            { ...getFieldProps('impact') }
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.impact}
            errors={errors.impact}
          />
        </Col>
      </Form.Group>

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F6_AT_assessment_question3')} <RequiredMark>*</RequiredMark>:</StyledFormLabel>
          <Form.Control
            name='value'
            as="textarea"
            style={{resize: "none"}}
            rows={8}
            className={touched.value && errors.value ? "error" : null}
            { ...getFieldProps('value') }
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.value}
            errors={errors.value}
          />
        </Col>
      </Form.Group>

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F6_AT_assessment_question4')} <RequiredMark>*</RequiredMark>:</StyledFormLabel>
          <Form.Control
            name='elements'
            as="textarea"
            style={{resize: "none"}}
            rows={8}
            className={touched.elements && errors.elements ? "error" : null}
            { ...getFieldProps('elements') }
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.elements}
            errors={errors.elements}
          />
        </Col>
      </Form.Group>

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F6_AT_assessment_question5')} <RequiredMark>*</RequiredMark>:</StyledFormLabel>
          <Form.Control
            name='overall'
            as="textarea"
            style={{resize: "none"}}
            rows={8}
            className={touched.overall && errors.overall ? "error" : null}
            { ...getFieldProps('overall') }
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.overall}
            errors={errors.overall}
          />
        </Col>
      </Form.Group>

      <div style={{marginLeft: '15px'}}>
        <StyledTitle>{t('F6_S18_subtitle_at_indicators')}</StyledTitle>
        <Form.Group style={{marginLeft: '15px'}}>
          <Row>
            <StyledFormLabel>{t('F6_AT_assessment_question6')}<RequiredMark>*</RequiredMark>:</StyledFormLabel>
            <label>
              <input
                disabled={nonEditable}
                id="LevelYesRadio"
                type="radio"
                name="level"
                value={"true"}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{marginLeft: '30px'}}
              />
              <span style={{marginLeft: '5px'}}> {t('Yes')} </span>
            </label>
            <label>
              <input
                disabled={nonEditable}
                id="LevelNoRadio"
                type="radio"
                name="level"
                value={"false"}
                onChange={(e) => {
                  setFieldValue('oo1', false);
                  setFieldValue('oo2', false);
                  setFieldValue('so3', false);
                  setFieldValue('so4', false);
                  setFieldValue('out5', false);
                  setFieldValue('out6', false);
                  setFieldValue('out7', false);
                  setFieldValue('out8', false);
                  setFieldValue('out9', false);
                  for (var i = 1; i < 20; i++) {
                    setFieldValue(`marker${i}`, false);
                  }
                  handleChange(e);
                }}
                onBlur={handleBlur}
                style={{marginLeft: '50px'}}
              />
              <span style={{marginLeft: '5px'}}> {t('No')} </span>
            </label>
          </Row>
          <div style={{marginLeft: '0'}}>
            <FormErrorMessage
              touched={touched.level}
              errors={errors.level}
            />
          </div>
        </Form.Group>

        <StyledCheckSubtitle> {t('F6_AT_overall_objective')} </StyledCheckSubtitle>
        <Form.Check
          name="oo1"
          type="checkbox"
          checked={values.oo1}
          disabled={nonEditable || values.level === 'false'}
          value={values.oo1}
          onChange={handleChange}
          label={t('F6_AT_checkbox1')}
        />

        <Form.Check
          name="oo2"
          type="checkbox"
          checked={values.oo2}
          disabled={nonEditable || values.level === 'false'}
          value={values.oo2}
          onChange={handleChange}
          label={t('F6_AT_checkbox2')}
        />

        <StyledCheckSubtitle> {t('F6_AT_specific_objective')} </StyledCheckSubtitle>
        <Form.Check
          name="so3"
          type="checkbox"
          checked={values.so3}
          disabled={nonEditable || values.level === 'false'}
          value={values.so3}
          onChange={handleChange}
          label={t('F6_AT_checkbox3')}
        />

        <Form.Check
          name="so4"
          type="checkbox"
          checked={values.so4}
          disabled={nonEditable || values.level === 'false'}
          value={values.so4}
          onChange={handleChange}
          label={t('F6_AT_checkbox4')}
        />

        <Form.Check
          name="out5"
          type="checkbox"
          checked={values.out5}
          disabled={nonEditable || values.level === 'false'}
          value={values.out5}
          onChange={handleChange}
          label={t('F6_AT_checkbox5')}
        />

        <StyledCheckSubtitle> {t('F6_AT_outputs')} </StyledCheckSubtitle>

        <Form.Check
          name="out6"
          type="checkbox"
          checked={values.out6}
          disabled={nonEditable || values.level === 'false'}
          value={values.out6}
          onChange={handleChange}
          label={t('F6_AT_checkbox6')}
        />

        <Form.Check
          name="out7"
          type="checkbox"
          checked={values.out7}
          disabled={nonEditable || values.level === 'false'}
          value={values.out7}
          onChange={handleChange}
          label={t('F6_AT_checkbox7')}
        />

        <Form.Check
          name="out8"
          type="checkbox"
          checked={values.out8}
          disabled={nonEditable || values.level === 'false'}
          value={values.out8}
          onChange={handleChange}
          label={t('F6_AT_checkbox8')}
        />

        <Form.Check
          name="out9"
          type="checkbox"
          checked={values.out9}
          disabled={nonEditable || values.level === 'false'}
          value={values.out9}
          onChange={handleChange}
          label={t('F6_AT_checkbox9')}
        />

        <StyledTitle>{t('F6_S18_subtitle_markers')}</StyledTitle>

        <StyledCheckSubtitle> {t('F6_AT_transversal')} </StyledCheckSubtitle>

        <Form.Check
          name="marker1"
          type="checkbox"
          checked={values.marker1}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker1}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox1')}
        />

        <Form.Check
          name="marker2"
          type="checkbox"
          checked={values.marker2}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker2}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox2')}
        />

        <Form.Check
          name="marker3"
          type="checkbox"
          checked={values.marker3}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker3}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox3')}
        />

        <Form.Check
          name="marker4"
          type="checkbox"
          checked={values.marker4}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker4}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox4')}
        />

        <StyledCheckSubtitle> {t('F6_AT_relevance')} </StyledCheckSubtitle>

        <Form.Check
          name="marker5"
          type="checkbox"
          checked={values.marker5}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker5}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox5')}
        />

        <Form.Check
          name="marker6"
          type="checkbox"
          checked={values.marker6}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker6}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox6')}
        />

        <Form.Check
          name="marker7"
          type="checkbox"
          checked={values.marker7}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker7}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox7')}
        />

        <Form.Check
          name="marker8"
          type="checkbox"
          checked={values.marker8}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker8}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox8')}
        />

        <StyledCheckSubtitle> {t('F6_AT_efficiency')} </StyledCheckSubtitle>

        <Form.Check
          name="marker9"
          type="checkbox"
          checked={values.marker9}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker9}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox9')}
        />

        <Form.Check
          name="marker10"
          type="checkbox"
          checked={values.marker10}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker10}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox10')}
        />

        <Form.Check
          name="marker11"
          type="checkbox"
          checked={values.marker11}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker11}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox11')}
        />



        <StyledCheckSubtitle> {t('F6_AT_effectiveness')} </StyledCheckSubtitle>

        <Form.Check
          name="marker12"
          type="checkbox"
          checked={values.marker12}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker12}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox12')}
        />

        <Form.Check
          name="marker13"
          type="checkbox"
          checked={values.marker13}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker13}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox13')}
        />

        <Form.Check
          name="marker14"
          type="checkbox"
          checked={values.marker14}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker14}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox14')}
        />

        <Form.Check
          name="marker15"
          type="checkbox"
          checked={values.marker15}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker15}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox15')}
        />

        <Form.Check
          name="marker16"
          type="checkbox"
          checked={values.marker16}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker16}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox16')}
        />

        <StyledCheckSubtitle> {t('F6_AT_impact')} </StyledCheckSubtitle>

        <Form.Check
          name="marker17"
          type="checkbox"
          checked={values.marker17}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker17}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox17')}
        />

        <StyledCheckSubtitle> {t('F6_AT_sustainability')} </StyledCheckSubtitle>

        <Form.Check
          name="marker18"
          type="checkbox"
          checked={values.marker18}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker18}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox18')}
        />

        <Form.Check
          name="marker19"
          type="checkbox"
          checked={values.marker19}
          disabled={nonEditable || values.level === 'false'}
          value={values.marker19}
          onChange={handleChange}
          label={t('F6_AT_marcador_checkbox19')}
        />
      </div>

      {
        !nonEditable
        &&
        <div style={{marginTop: '50px', marginLeft: '15px'}}>
          <Row style={{justifyContent: 'space-between'}}>
            <Col>
              <>
                {!saved && <PrimaryButton disabled={nonEditable || sending} onClick={() => {onValidateSubmit(isValid, values, resetForm)}}> {t('Button_Save_Later')} </PrimaryButton>}
                {saved && <StyledSavedButton disabled={true} >
                  {t('Button_saved')}
                </StyledSavedButton> }
              </>
            </Col>
            <Col  style={{marginBottom: "40px"}} className="col-auto">
              <SuccessButton disabled={nonEditable || sending || !isSent} onClick={() => { handleSendToMainAssessor(errors, setErrors, values) }}> {t('Button_Send_Assessor')} </SuccessButton>
            </Col>
          </Row>
        </div>
      }


    </div>
  )
}

export default ATAssessmentForm;