import React from 'react'
import { useTranslation } from 'react-i18next';

import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import { PrimaryButton } from 'components/Buttons';
import "react-datepicker/dist/react-datepicker.css";
import { CenteredModalButtonContainer } from 'components/CommonStyled';
import ConsultantForm from './ConsultantForm';

const ConsultantsForm = ({ handleCloseOk, activity }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Accordion>
        {activity.profileList
          ? ( activity.profileList.map((consultant, key) => (
              <AccordionItem key={key+1}>
                <AccordionItemHeader>
                  <AccordionTitle eventKey={key+1}>{t('F3_S7_Accordion_consultant')+" "+(key+1)}</AccordionTitle>
                </AccordionItemHeader>
                <Accordion.Collapse eventKey={key+1}>
                  <AccordionItemBody>
                    <ConsultantForm data={consultant} /> 
                  </AccordionItemBody>
                </Accordion.Collapse>
              </AccordionItem>
            )))
          : null}
      </Accordion>
    
      <CenteredModalButtonContainer>
        <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
      </CenteredModalButtonContainer>
    </div>
  )
}

export default ConsultantsForm;