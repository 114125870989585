import React, { useState, useEffect } from 'react';
import { getPreFormulationInitiatives } from 'services/initiative';
import Table from 'react-bootstrap/Table';
import PageTitle from 'components/PageTitle';
import { PrimaryButton } from 'components/Buttons';
import CreateInitiativeDialog from './CreateInitiativeDialog'
import { PageHeader, PageContent } from './ConceptNotes.styled';
import TableRow from './TableRow';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { getMaintenanceVariables } from "services/apiConstants";
import WarningModal from 'components/WarningModal';
import { useHistory } from "react-router-dom";

import {
  f1statusNamesConst
} from 'constants/appConstants'


const ConceptNotes = () => {

  const { t } = useTranslation();
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [initiatives, setIinitiatives] = useState([]);
 
  const [modal, setModal] = useState({isShow: false, id: ""});

  const handleOpenModal = () => setDisplayModal(true);
  const handleCloseModal = () => setDisplayModal(false);

  const onModalData = (value) => {
    setModal(value);
  }

  useEffect(() => {
    (async function () {
      try {
        const response = await getPreFormulationInitiatives();
        setIinitiatives(response);
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const [closedWindow, setClosedWindow] = useState(false);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getMaintenanceVariables();
        if(response && response[2]){
           setClosedWindow(!response[2].keyValue);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAPI();
   }, [])

  return (
    <>
      { displayModal && <CreateInitiativeDialog
        displayDialog={displayModal}
        title={t('F1_title_creation_warning')}
        onCloseModal={handleCloseModal}
      />}
      { <WarningModal
          show={modal.isShow}
          onHide={() => {setModal({isShow: false, id: ""});
          history.push(`/applicant/initiative/${modal.id}/form/1/section/1`)
        }}
          content={htmlParser(t('F1_Edition_warning_window_closed'))}
        />}
      <PageHeader>
        <PageTitle title={t('menu_app_1')} />
        <PrimaryButton onClick={handleOpenModal}>{t('Button_new_concept_note')}</PrimaryButton>
      </PageHeader>
      <PageContent>
        {loading
          ? <p>{t('Loading')}</p>
          : (
            <Table>
              <thead>
                <tr>
                  <th>{t('Grid_title_code')}</th>
                  <th>{t('Grid_title')}</th>
                  <th>{t('Grid_submission date')}</th>
                  <th>{t('Grid_status')}</th>
                  <th>{t('Grid_deadline')}</th>
                  <th>{t('Grid_messages')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  !(initiatives && initiatives.length)
                    ? <tr><td colSpan={4} style={{ textAlign: "center" }}>{t('No_data_available')}</td></tr>
                    : initiatives.map(item => {
                      if (item.formStateName && item.formStateName !== f1statusNamesConst.DRAFT) {
                        item.formDeadline = null;
                      }
                      return (
                        <TableRow key={item.id} item={item} onModalData={onModalData} closedWindow={closedWindow} />
                      )
                    })
                }
              </tbody>
            </Table>
          )
        }
      </PageContent>
    </>
  )
};

export default ConceptNotes;
