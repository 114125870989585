import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'stores';
import { roleNamesConst } from 'constants/appConstants';
import { useParams } from 'react-router-dom';
import { cancelActivityAT, getF5S7TDR } from 'services/initiative';
import htmlParser from 'html-react-parser';
import DayJS from 'dayjs';

import ConfirmCloseModal from 'components/ConfirmCloseModal';
import VerticallyCenteredModal from './ModalHtml';
import { ActivitySubItem, CompleteImage } from './ActivityItem.styled';
import { ErrorButton, PrimaryButton} from 'components/Buttons';

import completeIcon from 'assets/complete-mark.png';

const ActivityItem = ({ handleRefreshList, handleOpenGeneralModal, activity, setModalInfo }) => {

  const {t} = useTranslation();
  const { id } = useParams();
  const { authStore } = useRootStore();
  const [sending, setSending] = useState(false);
  const role = authStore.roles[0];
  const isAtke = role === roleNamesConst.ATKE;
  const isConsortium = role === roleNamesConst.CONSORTIUM;
  const [showModalRemove, setShowModalRemove] = useState(false);
  const [modalData, setModalData] = useState({show: false, data: null});

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    let displayExecutedBudget = params.get('displayExecutedBudget')
    let activityNumber = params.get('activityNumber')
    if(displayExecutedBudget === 'true' && (activity.id == activityNumber)){
      handleOpenBudgetExecuted();
    }
  }, [])

  const handleCancelActivity = async () => {
      try {
        await cancelActivityAT(id, activity.id);
        window.location.replace('');
      } catch (e) {
        console.log(e);
      }
  }

  const handleOpenGeneralInfo = () => {
    handleOpenGeneralModal({
      handleRefreshList: handleRefreshList,
      show: true,
      formType: 0,
      title: t('F3_S7_Subtitle_General_Information'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const handleOpenProducts = () => {
    handleOpenGeneralModal({
      handleRefreshList: handleRefreshList,
      show: true,
      formType: 1,
      title: t('F3_S7_Subtitle_Products'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const handleOpenConsultants = () => {
    handleOpenGeneralModal({
      handleRefreshList: handleRefreshList,
      show: true,
      formType: 2,
      title: t('F3_S7_Subtitle_Consultants'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const handleOpenParticipants = () => {
    handleOpenGeneralModal({
      handleRefreshList: handleRefreshList,
      show: true,
      formType: 3,
      title: t('F3_S7_Subtitle_Participants_assistance'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const handleOpenBudgetProposal = () => {
    handleOpenGeneralModal({
      handleRefreshList: handleRefreshList,
      show: true,
      formType: 4,
      title: t('F5_S9_Subtitle_consolidated_budget'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const handleOpenBudgetExecuted = () => {
    handleOpenGeneralModal({
      handleRefreshList: handleRefreshList,
      show: true,
      formType: 5,
      title: t('F5_S9_Subtitle_executed_budget'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const handleOpenAssessment = () => {
    handleOpenGeneralModal({
      handleRefreshList: handleRefreshList,
      show: true,
      formType: 6,
      title: t('F5_S7_Subtitle_activity_assessment'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const handleOpenDifussionMaterial = () => {
    handleOpenGeneralModal({
      handleRefreshList: handleRefreshList,
      show: true,
      formType: 8, // the 7 one is for new activities
      title: t('F5_S14_difussion_material'),
      activity: activity,
      activityCode: activity.name,
    });
  }

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr);
    return date.format("DD/MM/YYYY");
  }

  const generateHtml = (data) => {
    let i = 0;
    let text =  `<strong>${t('F3_S7_Subtitle_General_Information')} - ${t('Activity')} ${data.name}</strong><br/>
                <strong>${data.title}</strong><br/>
                <br/>
                <strong>${t('F1_S7_start_day')}:</strong> ${formatDate(data.fromDate)}<br/>
                <strong>${t('F1_S7_End_day')}:</strong> ${formatDate(data.toDate)}<br/>
                <br/>
                <strong>${t('F1_S6_Question_2')}:</strong><br/>${data.description === null ? "-" : data.description}<br/>
                <br/>
                <strong>${t('F1_S7_Country_activity')}:</strong> ${t(data.country.name)}<br/>
                <strong>${t('F1_S2_State_Province_Region')}: </strong> ${data.stateName  === null ? "-": data.stateName}<br/>
                <strong>${t('F1_S2_City')}:</strong> ${data.cityName}<br/>
                <strong>${t('F1_S7_Countries_additional')}:</strong> ${data.countriesAdditional  === null ? "-" : data.countriesAdditional}<br/>
                <strong>${t('F1_S7_Duration')}:</strong> ${data.duration}<br/>
                <br/>
                <strong>${t('F1_S7_Number_experts')}:</strong> ${data.experts}<br/>
                <strong>${t('F1_S7_Number_wd')}:</strong><br/>
                <strong>${t('F1_S7_Remote')}:</strong> ${data.daysRemote}<br/>
                <strong>${t('F1_S7_Onsite')}:</strong> ${data.daysOnsite}<br/>
                <strong>${t('F1_S7_Total')}:</strong> ${data.daysRemote + data.daysOnsite}<br/>
                <br/>
                <strong>${t('F3_S7_General_work_language')}:</strong> ${data.workLang1 ? t('English')+", ": ""}${data.workLang2 ? t('French')+", ": ""}${data.workLang3 ? t('Portuguese')+", ": ""}${data.workLang4 ? t('Spanish'): ""} <br/>
                <br/>
                <strong>${t('F1_S7_Justification_activity')}:</strong><br/>
                ${data.justification  === null ? "-" : data.justification}<br/>
                <br/>
                <strong>${t('F3_S7_General_objectives')}:</strong><br/>
                ${data.objective === null ? "-" : data.objective}<br/>
                <br/>
                <strong>${t('F3_S7_General_learning_objectives')}:</strong><br/>
                ${data.learning === null ? "-" : data.learning}<br/>
                <br/>
                <strong>${t('F3_S7_General_speakers')}:</strong><br/>
                ${data.speakers === null ? "-" : data.speakers}<br/>
                <br/>
                <strong>${t('F3_S7_General_brief_description_schedule')}:</strong><br/>
                ${data.descSchedule === null ? "-" : data.descSchedule} <br/>
                <br/>
                <strong>${t('F3_S7_General_workplan')}:</strong><br/>
                ${data.workPlans === null ? "-" : data.workPlans}<br/>
                <br/>
                <strong>${t('tdr_activity_products')}:</strong>`;


      data.productList && data.productList.map( (product) => {
          i++;
          text = text + `<br/><br/><strong>${t('F3_S7_Accordion_product')} #${i}:</strong>
          <br/><strong>${t('F3_S7_Product_type')}:</strong> ${t(`product_type_${product.productType.id}`)}
          <br/><strong>${t('F3_S7_Product_title')}:</strong> ${product.name}
          <br/><strong>${t('F3_S7_Product_Due_date')}:</strong> ${formatDate(product.dueDate)}
          <br/><strong>${t('F3_S7_Product_Description')}:</strong><br/>
          ${product.description === null ? "-" : product.description}
          <br/><strong>${t('F1_S8_Media_name')}:</strong><br/>
          ${product.comments === null ? "-" : product.comments}`
      })

    return text;
  }

  const generateTDR = async () => {
    setSending(true);
    try {
      const response = await getF5S7TDR(id, activity.id);
      const doc = generateHtml(response.data);
      setModalData({show: true, data: doc});
      setSending(false);
    } catch (e) {
      setSending(false);
    }

  }


  return (
    <div style={{marginBottom: '-40px'}}>

    {showModalRemove &&
      <ConfirmCloseModal
        show={showModalRemove}
        text={htmlParser(t('F5_confirmation_cancel_activity'))}
        handleActionOnConfirm={() => {handleCancelActivity()}}
        handleCloseConfirm={() => {setShowModalRemove(false)}}
      />
    }

    {modalData.show &&
      <VerticallyCenteredModal
        show={modalData.show}
        onHide={() => setModalData({show: false, data: null})}
        data={modalData.data}
      />
    }

      <ActivitySubItem onClick={() => {handleOpenGeneralInfo()}}> {t('F3_S7_Subtitle_General_Information')} {(activity.general_info_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>
      <ActivitySubItem onClick={() => {handleOpenProducts()}}> {t('F3_S7_Subtitle_Products')} {(activity.product_list_complete) &&  <CompleteImage src={completeIcon} />}</ActivitySubItem>
      {(activity.activityType && activity.activityType.id == 4 ) && <ActivitySubItem onClick={() => {handleOpenConsultants()}}> {t('F3_S7_Subtitle_Consultants')} {(activity.profile_list_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>}
      {(activity.activityType && activity.activityType.id != 4 ) && <ActivitySubItem onClick={() => {handleOpenParticipants()}}> {t('F3_S7_Subtitle_Participants_assistance')} {(activity.participant_list_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>}
      <ActivitySubItem onClick={() => {handleOpenBudgetProposal()}}> {t('F5_S9_Subtitle_consolidated_budget')} <CompleteImage src={completeIcon}/> </ActivitySubItem>
      <ActivitySubItem onClick={() => {handleOpenBudgetExecuted()}}> {t('F5_S9_Subtitle_executed_budget')} {(activity.resource_list_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>
      <ActivitySubItem onClick={() => {handleOpenDifussionMaterial()}}> {String(t('F5_S14_difussion_material')).toUpperCase()} <CompleteImage src={completeIcon} /> </ActivitySubItem>
      <ActivitySubItem onClick={() => {handleOpenAssessment()}}> {t('F5_S7_Subtitle_activity_assessment')} {(activity.assessment_complete) &&  <CompleteImage src={completeIcon} />} </ActivitySubItem>

      <div style={{display: 'flex'}}>
        {(isAtke && activity.activityState.name !== 'CANCELLED') &&
          <ErrorButton style={{marginRight: '20px', marginTop: '15px'}} disabled={activity.activityState.name === 'CONFIRMED'} onClick={() => {setShowModalRemove(true)}}> {t('Button_cancel_activity')} </ErrorButton>
        }
        {((isAtke || isConsortium) && activity.activityState.name === 'CONFIRMED') &&
          <PrimaryButton style={{marginRight: '20px', marginTop: '15px'}} disabled={sending} onClick={() => {generateTDR()}}> {t('Button_tdr')} </PrimaryButton>
        }
      </div>

    </div>
  )

}

export default ActivityItem;
