import Button from 'react-bootstrap/esm/Button';
import styled, { css } from 'styled-components'

export const StyledTr = styled.tr`
  ${({ theme }) => css`
    color: ${theme.brandColors.gray1};
  `}
`;

export const StyledSpan = styled.span`
  ${({ theme }) => css`
    color: ${theme.brandColors.gray1};
  `}
`;

export const StyledButtonLink = styled(Button)`
  all: unset;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none !important;
  margin-bottom: 4px;
  font-size: 12px;
  color: blue;
  &:hover {
    background: none;
    box-shadow: none !important;
    color: blue;
  }
`;
