import { useAdelanteCommunityFeaturesContext } from 'customHooks/useAdelanteCommunityFeatures'
import React, { useMemo } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Table from 'react-bootstrap/esm/Table'
import { useTranslation } from 'react-i18next'
import TableRow from './TableRow'

const ClickableTh = ({ onClick = undefined, children }) => {
  return (
    <th onClick={onClick} style={{ fontSize: '14px', cursor: onClick ? 'pointer' : undefined }}>
      {children}
    </th>
  )
};

const Report = () => {
  const { t } = useTranslation()

  const { 
    filteredItems, 
    onOpenContact, 
    onOrderDataByField 
  } = useAdelanteCommunityFeaturesContext();

  const partnershipsQuantity = useMemo(() => {
    return filteredItems?.length || 0;
  }, [filteredItems]);
  
  const entitiesQuantity = useMemo(() => {
    return filteredItems?.reduce((accumulator, item) => {
      return accumulator += item?.entities?.length || 0;
    }, 0) || 0;
  }, [filteredItems]);
  
  return (
    <>
      <Table variant='bordered'>
        <thead>
          <tr>
            <ClickableTh onClick={() => { onOrderDataByField('title') }}>
              {t('community_report_partnership')} &#8645;<br/>
              <span style={{ fontWeight: 'normal'}}>{t('community_partnership_number', { quantity: partnershipsQuantity })}</span>
            </ClickableTh>
            <ClickableTh>
              {t('community_report_entities')}<br/>
              <span style={{ fontWeight: 'normal'}}>{t('community_entity_number', { quantity: entitiesQuantity })}</span>
            </ClickableTh>
            <ClickableTh onClick={() => { onOrderDataByField('instrument') }}>{t('community_report_tool')} &#8645; </ClickableTh>
            <ClickableTh>{t('community_report_objective')}</ClickableTh>
            <ClickableTh onClick={() => { onOrderDataByField('primaryGoalId') }}>{t('community_report_primary_ods')} &#8645; </ClickableTh>
            <ClickableTh onClick={() => { onOrderDataByField('secondaryGoalId') }}>{t('community_report_secondary_ods')} &#8645; </ClickableTh>
            <ClickableTh>{t('community_report_website')}</ClickableTh>
            <ClickableTh>{t('community_report_contact')}</ClickableTh>
          </tr>
        </thead>
        <tbody>
          {!filteredItems?.length ? (
            <tr>
              <td colSpan={4} style={{ textAlign: 'center' }}>
                {t('No_data_available')}
              </td>
            </tr>
          ) : (
            filteredItems?.map((item) => {
              return (
                <TableRow
                  key={`${item?.instrument}_${item.id}`}
                  item={item}
                  onContact={() => onOpenContact(item?.id, item?.instrumentRaw)}
                />
              )
            })
          )}
        </tbody>
      </Table>
    </>
  )
}

export default Report
