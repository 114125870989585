import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRootStore } from 'stores'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import MoneyTextMask from 'components/MoneyTextMask'
import {
  getF5S7BuyBudget,
  getImplementationInitiatives,
} from 'services/initiative'

import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import CustomExportXLSXButton from './CustomExportXLSXButton'
import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageTitle from 'components/PageTitle'
import {
  PageHeader,
  PageContent,
  CenteredRow,
} from './ContractManagement.styled'
import { PrimaryButton } from 'components/Buttons'

const ContractManagement = () => {
  const { t } = useTranslation()
  const { authStore } = useRootStore()

  const [waiting, setWaiting] = useState(false)

  const [filterInitiative, setFilterInitiative] = useState(0)
  const [filterStatus, setFilterStatus] = useState(0)
  const [filterCategory, setFilterCategory] = useState(0)

  const [tableValues, setTableValues] = useState([])

  const [initiativesOptions, setInitiativesOptions] = useState([])

  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(0)

  const [pageTotal, setPageTotal] = useState({
    implementation: 0,
    authorized: 0,
    paid: 0,
  })
  const [grandTotal, setGrandTotal] = useState({
    implementation: 0,
    authorized: 0,
    paid: 0,
  })

  const [dataToExport, setDataToExport] = useState([])

  const [params, setParams] = useState({})

  const PAGE_SIZE = 100

  useEffect(() => {
    getInitiatives()
    getValues(0, {})
    getAllPages({})
  }, [])

  async function getInitiatives() {
    try {
      const response = await getImplementationInitiatives()
      setInitiativesOptions(response)
    } catch (error) {
      console.log(error)
    }
  }

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber)
    getValues(newPageNumber, params)
  }

  async function getValues(pageNumber, filterParams) {
    setWaiting(true)
    try {
      let params = {
        page: pageNumber,
        pageSize: PAGE_SIZE,
      }
      if (filterParams) {
        params = {
          ...params,
          ...filterParams,
        }
      }
      const response = await getF5S7BuyBudget(params)
      const data = response.data.page.content
      setLastPage(
        response.data.page.totalPages == 0
          ? 0
          : response.data.page.totalPages - 1,
      )
      data.forEach((item) => {
        item.category = t(`resource_type_${item.category}`)
        item.budget = t(`table_budget_${item.budget}`)
        item.status = t(`table_status_${item.status}`)
      })
      let totals = {
        implementation: response.data.totals.implementation
          ? response.data.totals.implementation
          : 0,
        authorized: response.data.totals.authorized
          ? response.data.totals.authorized
          : 0,
        paid: response.data.totals.paid
        ? response.data.totals.paid
        : 0,
      }
      setGrandTotal(totals)
      setPageTotal({
        implementation: data.reduce(function (acc, obj) {
          return acc + obj.implementation
        }, 0),
        authorized: data.reduce(function (acc, obj) {
          return acc + obj.authorized
        }, 0),
        paid: data.reduce(function (acc, obj) {
          return acc + obj.paid
        }, 0),
      })
      setTableValues([...data])
      setWaiting(false)
    } catch (error) {
      console.log(error)
      setWaiting(false)
    }
  }

  async function getAllPages(filterParams) {
    try {
      const response = await getF5S7BuyBudget(filterParams)
      const data = response.data.page.content
      data.forEach((item) => {
        item.category = t(`resource_type_${item.category}`)
        item.budget = t(`table_budget_${item.budget}`)
        item.status = t(`table_status_${item.status}`)
      })
      data.push({
        initiative: `${t('Authorized')} (${t('Purchasing_mgmnt_total_grand')})`,
        activity: response.data.totals.authorized,
      })
      data.push({
        initiative: `${t('Implementation')} (${t(
          'Purchasing_mgmnt_total_grand',
        )})`,
        activity: response.data.totals.implementation,
      })
      data.push({
        initiative: `${t('Paid')} (${t('Purchasing_mgmnt_total_grand')})`,
        activity: response.data.totals.paid,
      })
      setDataToExport(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeStatus = (e) => {
    setFilterStatus(e.target.value)
  }

  const handleChangeInitiative = (e) => {
    setFilterInitiative(e.target.value)
  }

  const handleChangeCategory = (e) => {
    setFilterCategory(e.target.value)
  }

  const handleFilter = () => {
    let params = {}

    if (filterInitiative != 0) params.initiativeId = filterInitiative
    if (filterCategory != 0) params.categoryId = filterCategory
    if (filterStatus != 0) params.statusId = filterStatus

    setParams(params)
    getValues(0, params)
    getAllPages(params)
  }

  const renderCategoryOptions = () => {
    let options = []
    options.push(<option value={0}> {t('ALL')} </option>)
    for (let i=1; i <= 12; i++) {
      options.push(<option value={i}> {t(`resource_type_${i}`)} </option>)
    }
    return options
  }

  const renderStatusOptions = () => {
    let options = []
    options.push(<option value={0}> {t('ALL')} </option>)
    for (let i=1; i <= 5; i++) {
      options.push(<option value={i}> {t(`table_status_${i}`)} </option>)
    }
    return options
  }

  const columns = [
    {
      dataField: 'initiative',
      text: t('Initiative'),
      headerStyle: { width: '110px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
      footer: t('Purchasing_mgmnt_total_page'),
      footerFormatter: (cell, row, rowIndex, extraData) => (
        <Col>
          <Row style={{ fontSize: '13px' }}>
            {t('Purchasing_mgmnt_total_page')}
          </Row>
          <Row style={{ marginTop: '30px', fontSize: '13px' }}>
            {t('Purchasing_mgmnt_total_grand')}
          </Row>
        </Col>
      ),
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <Link
            to={`${authStore.getBaseRouteByRole()}/initiative/${
              row.initiativeId
            }/form/5/section/7?displayExecutedBudget=true&activityNumber=${
              row.activityId
            }`}
            target="_blank"
            rel="noreferrer"
          >
            {row.initiative}
          </Link>
        </div>
      ),
    },
    {
      dataField: 'initiativeId',
      text: '',
      hidden: true,
      csvExport: false,
    },
    {
      dataField: 'activityId',
      text: '',
      hidden: true,
      csvExport: false,
    },
    {
      dataField: 'activity',
      text: t('Activity'),
      headerStyle: { width: '80px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
      footer: '',
      formatter: (cell, row, rowIndex, extraData) => `A${row.activity}`,
    },
    {
      dataField: 'category',
      text: t('Category'),
      headerStyle: { width: '120px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
      footer: '',
    },
    {
      dataField: 'description',
      text: t('Description'),
      headerStyle: { minWidth: '250px', textAlign: 'center', fontSize: '12px' },
      footer: '',
      formatter: (cell, row, rowIndex, extraData) => (
        <div style={{ fontSize: '12px', textAlign: 'justify' }}>
          {`${row.description.split(' ').splice(0, 20).join(' ')} ${
            row.description.split(' ').length > 20 ? '...' : ''
          }`}
        </div>
      ),
    },
    {
      dataField: 'provider',
      text: t('Provider'),
      headerStyle: { width: '110px', textAlign: 'center', fontSize: '12px' },
      footer: '',
      style: { fontSize: '12px', textAlign: 'center' },
    },
    {
      dataField: 'status',
      text: t('Status'),
      headerStyle: { width: '115px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '13px', textAlign: 'center' },
      footer: '',
    },
    {
      dataField: 'workingDays',
      text: t('Working_Days'),
      headerStyle: { width: '80px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
      footer: '',
    },
    {
      dataField: 'authorized',
      text: t('Authorized'),
      headerStyle: { width: '120px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
      footer: (columnData) => (
        <MoneyTextMask
          value={columnData.reduce((acc, item) => acc + item, 0)}
        />
      ),
      footerFormatter: (col, colIndex) => (
        <Col>
          <Row>
            <MoneyTextMask value={pageTotal.authorized} />
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <MoneyTextMask value={grandTotal.authorized} />
          </Row>
        </Col>
      ),
      footerStyle: { textAlign: 'center', fontSize: '13px' },
      formatter: (cell, row, rowIndex, extraData) => (
        <MoneyTextMask value={row.authorized} />
      ),
    },
    {
      dataField: 'implementation',
      text: t('Implementation'),
      headerStyle: { width: '120px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
      footer: (columnData) => (
        <MoneyTextMask
          value={columnData.reduce((acc, item) => acc + item, 0)}
        />
      ),
      footerFormatter: (col, colIndex) => (
        <Col>
          <Row>
            <MoneyTextMask value={pageTotal.implementation} />
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <MoneyTextMask value={grandTotal.implementation} />
          </Row>
        </Col>
      ),
      footerStyle: { textAlign: 'center', fontSize: '13px' },
      formatter: (cell, row, rowIndex, extraData) => (
        <MoneyTextMask value={row.implementation} />
      ),
    },
    {
      dataField: 'deviation',
      text: t('Deviation'),
      headerStyle: { width: '85px', textAlign: 'center', fontSize: '12px' },
      footer: '',
      style: (cell, row, rowIndex, colIndex) => {
        if (row.deviation < 0)
          return { color: 'red', textAlign: 'center', fontSize: '12px' }
        else return { textAlign: 'center', fontSize: '12px' }
      },
      formatter: (cell, row, rowIndex, extraData) =>
        row.deviation === null ? null : `${row.deviation}%`,
    },
    {
      dataField: 'paid',
      text: t('Paid'),
      headerStyle: { width: '120px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
      formatter: (cell, row, rowIndex, extraData) => (
        <MoneyTextMask value={row.paid} />
      ),
      footer: (columnData) => (
        <MoneyTextMask
          value={columnData.reduce((acc, item) => acc + item, 0)}
        />
      ),
      footerStyle: { textAlign: 'center', fontSize: '13px' },
      footerFormatter: (col, colIndex) => (
        <Col>
          <Row>
            <MoneyTextMask value={pageTotal.paid} />
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <MoneyTextMask value={grandTotal.paid} />
          </Row>
        </Col>
      ),
    },
    {
      dataField: 'budget',
      text: t('Budget'),
      headerStyle: { width: '70px', textAlign: 'center', fontSize: '12px' },
      style: { fontSize: '12px', textAlign: 'center' },
      footer: '',
    },
  ]

  const pagination = paginationFactory({
    sizePerPage: 100,
    hideSizePerPage: true,
  })

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={tableValues}
        columns={columns}
        pagination={pagination}
        exportCSV={{
          fileName: `${t('menu_admin_9')}.csv`,
          separator: ',',
          ignoreHeader: false,
          noAutoBOM: false,
          blobType: 'text/csv;charset=UTF-8',
        }}
      >
        {(props) => (
          <>
            <PageHeader>
              <PageTitle title={t('menu_admin_9')} />
            </PageHeader>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              {t('Initiative')}
              <Form.Control
                style={{ marginLeft: '10px', marginRight: '10px' }}
                as="select"
                onChange={handleChangeInitiative}
                value={filterInitiative}
              >
                <option value={0}> {t('ALL')} </option>
                {initiativesOptions &&
                  initiativesOptions.map((e) => (
                    <option value={e.id}> {e.code} </option>
                  ))}
              </Form.Control>

              {t('Status')}
              <Form.Control
                style={{ marginLeft: '10px', marginRight: '10px' }}
                as="select"
                onChange={handleChangeStatus}
                value={filterStatus}
              >
                {renderStatusOptions()}
              </Form.Control>

              {t('Category')}
              <Form.Control
                style={{ marginLeft: '10px', marginRight: '15px' }}
                as="select"
                onChange={handleChangeCategory}
                value={filterCategory}
              >
                 {renderCategoryOptions()}
              </Form.Control>

              <PrimaryButton
                onClick={() => {
                  handleFilter()
                }}
                disabled={waiting}
              >
                {t('Button_filter')}
              </PrimaryButton>
              <CustomExportXLSXButton
                {...props.csvProps}
                values={dataToExport}
                disabled={waiting}
              />
            </Col>
            <PageContent>
              <BootstrapTable {...props.baseProps} />
            </PageContent>
          </>
        )}
      </ToolkitProvider>
      <CenteredRow>
        <Pagination>
          <Pagination.First
            disabled={currentPage == 0}
            onClick={() => handlePageChange(0)}
          />
          <Pagination.Prev
            disabled={currentPage == 0}
            onClick={() => handlePageChange(currentPage - 1)}
          />
          <Pagination.Next
            disabled={currentPage == lastPage}
            onClick={() => handlePageChange(currentPage + 1)}
          />
          <Pagination.Last
            disabled={currentPage == lastPage}
            onClick={() => handlePageChange(lastPage)}
          />
        </Pagination>
      </CenteredRow>
    </>
  )
}

export default observer(ContractManagement)
