import Form2Section1 from 'components/Forms/Form2/Section1';
import Form2Section2 from 'components/Forms/Form2/Section2';
import Form2Section3 from 'components/Forms/Form2/Section3';
import Form2Section4 from 'components/Forms/Form2/Section4';
import Form2Section5 from 'components/Forms/Form2/Section5';
import Form2Section6 from 'components/Forms/Form2/Section6';
import Form2Section7 from 'components/Forms/Form2/Section7';
import Form2Section8 from 'components/Forms/Form2/Section8';
import Form2Section9 from 'components/Forms/Form2/Section9';
import Form2Section10 from 'components/Forms/Form2/Section10';

export const form2Dictionary = {
  1: <Form2Section1 />,
  2: <Form2Section2 />,
  3: <Form2Section3 />,
  4: <Form2Section4 />,
  5: <Form2Section5 />,
  6: <Form2Section6 />,
  7: <Form2Section7 />,
  8: <Form2Section8 />,
  9: <Form2Section9 />,
  10: <Form2Section10 />,
};