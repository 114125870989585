import React from 'react'
import { Formik } from 'formik';
import { formInitialValues } from './NewProductModal.initialValues'
import { validationSchema } from './NewProductModal.validations';
import { addProductF5S7 } from 'services/initiative';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useConstantsForm5 } from 'customHooks/useConstantsForm5';
import DatePicker from "react-datepicker";
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ErrorButton, StyledSendButton} from 'components/Buttons'
import { Form } from 'react-bootstrap'
import { RequiredMark, CenteredModalButtonContainer } from 'components/CommonStyled';
import { 
  StyledModalBody,
  ModalTitle,
} from './NewProductModal.styled'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const NewProductModal = ({show, setNewToLocalList, handleClose, handleCloseOnSave, handleCloseOnError, activity, nonEditable }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { productTypes } = useConstantsForm5();

  const onSave = async (values) => {
    try {
      const valuesCopy = JSON.parse(JSON.stringify(values));
      valuesCopy["dueDate"] = valuesCopy["dueDate"]? new Date(valuesCopy["dueDate"]).toISOString(): "";
      const response = await addProductF5S7(id, activity.id, valuesCopy)
      setNewToLocalList(response.data);
      handleCloseOnSave();
    } catch (error) {
      console.log(error);
      handleCloseOnError();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl" backdrop="static" animation={false}>

      <StyledModalBody>

        <ModalTitle>{`${t('F5_S7_Products_new_product_title')} ${!(activity && activity.name)? "": `- ${t('F1_S7_Accordion')} ${activity.name}`}`}</ModalTitle>

        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={onSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue
          }) => (
            <Form onSubmit={handleSubmit}>
              
              <Form.Group as={Row}>
                <Form.Label column lg={4}>{t('F3_S7_Product_type')}<RequiredMark>*</RequiredMark>:</Form.Label>
                <Col lg={8}>
                  <Form.Control
                    name="productTypeId"
                    as="select"
                    onChange={handleChange}
                    value={values.resourceTypeId}
                    disabled={nonEditable}
                  >
                    <option value="">{t('Placeholder_select')}</option>
                    {productTypes.map(product => (
                      <option key={product.id} value={product.id}>{t(`product_type_${product.id}`)}</option>
                    ))}
                  </Form.Control>
                  <FormErrorMessage
                    touched={touched.productTypeId}
                    errors={errors.productTypeId}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column lg={4}>{t('F3_S7_Product_title')}<RequiredMark>*</RequiredMark>:</Form.Label>
                <Col lg={8}>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    className={touched.name && errors.name ? "error" : null}
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.name}
                    errors={errors.name}
                  />
                </Col>
              </Form.Group>  

              <Form.Group as={Row}>
                <Form.Label column lg={4}>{t('F3_S7_Product_Due_date')}<RequiredMark>*</RequiredMark>:</Form.Label>
                <Col>
                  <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={values.dueDate}
                      onChange={(date) => { setFieldValue('dueDate', date) }}
                      className={touched.dueDate && errors.dueDate ? "error" : null}
                      disabled={nonEditable}
                    />
                  </InputGroup>
                  <FormErrorMessage
                    touched={touched.dueDate}
                    errors={errors.dueDate}
                  />
                </Col>
              </Form.Group> 

              <Form.Group as={Row}>
                <Col>
                  <Form.Label lg={4} style={{ alignItems: "center" }} >{t('F3_S7_Product_Description')} <RequiredMark style={{ marginLeft: "7px" }}>*</RequiredMark>:
                  </Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea" 
                    rows={3} 
                    placeholder={t('Maximum_650')}
                    style={{resize: 'none'}}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    className={touched.description && errors.description ? "error" : null}
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.description}
                    errors={errors.description}
                  />
                </Col>
              </Form.Group>

              <CenteredModalButtonContainer>
                <ErrorButton style={{marginRight: '20px'}} onClick={handleClose} disabled={nonEditable}> {t('Button_cancel')} </ErrorButton>
                <StyledSendButton type="submit" disabled={nonEditable}> {t('Button_save')} </StyledSendButton>
              </CenteredModalButtonContainer>
            </Form>
          )}
        </Formik>
      </StyledModalBody>
    </Modal>
  )
}

export default observer(NewProductModal);