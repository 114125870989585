import React from 'react'
import Tab from 'react-bootstrap/Tab'
import { StyledTabs } from './StatisticsTabs.styled'

const StatisticsTabs = ({ titles, contents }) => {
  const renderTabs = () =>
    contents.map((content, index) => (
      <Tab eventKey={index} title={titles[index]} unmountOnExit mountOnEnter>
        {content()}
      </Tab>
    ))

  return (
    <StyledTabs defaultActiveKey="0" unmountOnExit mountOnEnter transition={false}>
      {renderTabs()}
    </StyledTabs>
  )
}

export default StatisticsTabs
