import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import { FormFielErrorMessage, CenteredModalButtonContainer } from 'components/CommonStyled';
import { useTranslation } from 'react-i18next';
import { addComment } from 'services/initiative';
import { validationSchema } from './NewComment.validations';
import CustomModal from 'components/Modal';

import { StyledSendButton } from 'components/FormContainer/FormContainer.styled';

import { ErrorButton } from 'components/Buttons';

const NewComment = props => {
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
    message: '',
    type: 'default',
  });

  const onHideModal = () => {
    setModal({ ...modal, isOpen: false });
    props.onHide();
  };

  const addNewComment = newComment => {
    var list = props.commentsList;
    if (props.id) list.find(element => element.id == props.id).responses.push(newComment);
    else list.unshift(newComment);

    props.onSetComments(list);
  };

  const handleSubmit = async values => {
    setSending(true);
    try {
      values.parentId = props.id;
      const response = await addComment(props.params.id, props.params.formId, props.params.sectionId, values);
      addNewComment(response.data);
      props.onHide();
    } catch (error) {
      setSending(false);
      console.log(error);
    }
  };

  return (
    <Modal {...props} centered size="lg" animation={false}>
      <Modal.Body>
        {modal.isOpen && (
          <CustomModal
            show={modal.isOpen}
            onHide={onHideModal}
            message={modal.message}
            type={modal.type}
          />
        )}

        <Formik
          enableReinitialize
          initialValues={{ comment: '', parentId: null }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label style={{ marginTop: '20px', marginBottom: '20px' }}>
                  {t('Your_message')}:
                </Form.Label>
                <Form.Control
                  name="comment"
                  as="textarea"
                  rows={6}
                  onChange={handleChange}
                  value={values.comment}
                  style={{ resize: 'none' }}
                  placeholder={t('Maximum_1300')}
                />
                {touched.comment && errors.comment ? (
                  <FormFielErrorMessage>{errors.comment}</FormFielErrorMessage>
                ) : null}
              </Form.Group>

              <CenteredModalButtonContainer>
                <ErrorButton
                  onClick={() => {
                    props.onHide();
                  }}
                >
                  {t('Button_cancel')}
                </ErrorButton>
                <StyledSendButton
                  type="submit"
                  disabled={sending}
                  style={{ marginTop: '15px', marginBottom: '15px', marginLeft: '15px' }}
                >
                  {sending ? t('Button_sending') : t('Button_send')}
                </StyledSendButton>
              </CenteredModalButtonContainer>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default NewComment;
