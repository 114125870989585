import styled, { css } from 'styled-components';

export const StyledCommentDiv = styled.div`
margin-left: 20px;
`;

export const StyledTextDiv = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.gray[5]};
  `}
`;