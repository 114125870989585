import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form'

export const StyledAccordionBodyDiv = styled.div`
  margin-left: 20px;
`;

export const StyledFormLabel = styled(Form.Label)`
  ${({ theme }) => css`
    color: ${theme.colors.gray[1]};
  `}
  font-weight: 500;
`;

