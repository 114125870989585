import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DayJS from 'dayjs'

import { PrimaryButton } from 'components/Buttons';
import InviteDialog from './InviteDialog'
import { StyledEUDLink} from './EUDAssessments.styled';
import { SeparatorDotted } from 'components/CommonStyled';
import EUDAssessmentDialog from './EUDAssessmentDialog';
import { initialValues } from 'pages/manager/ExpostReportForm/IntpaForm/EUDAssessments/EUDAssessments.initialValues';

const EUDAssessments = ({ assessmentList, nonEditable }) => {
  const { t } = useTranslation();
  const [displayModalInvite, setDisplayModalInvite] = useState(false);
  const handleOpenModalInvite = () => setDisplayModalInvite(true);
  const handleCloseModalInvite = () => setDisplayModalInvite(false);

  const [displayModalF8A, setDisplayModalF8A] = useState(false);
  const handleOpenModalF8A = () => setDisplayModalF8A(true);
  const handleCloseModalF8A = () => setDisplayModalF8A(false);

  const [currentF8AValues, setCurrentF8AValues] = useState(initialValues);

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr);
    return date.format("DD/MM/YYYY");
  }

  const handleClick = (eudAssessment) => {
    let obj = {comment1: eudAssessment?.comment1, comment2: eudAssessment?.comment2, recommendation: eudAssessment?.recommendation }
    setCurrentF8AValues(obj);
    handleOpenModalF8A();
  }

  const renderEudList = () => {
    return assessmentList && assessmentList.map(eudAssessment => (
      <div key={eudAssessment.user.email}>
        {`${eudAssessment.user.organization}, ${eudAssessment.user.name} ${eudAssessment.user.lastName} - `}
        {(eudAssessment?.status?.stateEnum === 'DONE')
          ?
          <StyledEUDLink key={eudAssessment.user.email} value={eudAssessment.user.email} onClick={() => { handleClick(eudAssessment) }}>
            {t('Received_on')+" "+((eudAssessment && eudAssessment.statusTimestamp) ? formatDate(eudAssessment.statusTimestamp) : '-')}
          </StyledEUDLink>
          :
          t('Pending')
        }
      </div>
    ))
  }

  return (
    <>
      { displayModalInvite && <InviteDialog
        displayDialog={displayModalInvite}
        onCloseModal={handleCloseModalInvite}
      />}

      { displayModalF8A && <EUDAssessmentDialog
        values={currentF8AValues}
        displayDialog={displayModalF8A}
        onCloseModal={handleCloseModalF8A}
      />}

      <div>
        {renderEudList()}

        <div style={{ width: '400px' }}>
          <SeparatorDotted />
        </div>
        
        <div style={{ marginTop: '30px' }}>
          <PrimaryButton disabled={nonEditable} onClick={handleOpenModalInvite}>{"+" + " " + t('F2_invite_assessors')}</PrimaryButton>
        </div>
      </div>

    </>
  )
}

export default EUDAssessments;