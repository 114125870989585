import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import DayJS from 'dayjs';
import { formInitialValues } from './Section8.initialValues';
import { useTranslation } from 'react-i18next';
import { SVGGantt } from 'gantt';

import Form from 'react-bootstrap/Form';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import Comments from 'components/Forms/Form4/Comments';
import {SeparatorDotted} from 'components/CommonStyled';
import TextValue from 'components/TextValue';
import {
  StyledFormLabel,
  StyledDivWrapper
} from './Section8.styled';

const Section8 = () => {

  const { t } = useTranslation();
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const { formStore } = useRootStore();
  const [formValues, setFormValues] = useState(formInitialValues);

  const handleSaved = (value) => {
    setSaved(value);
  }

  const handleButton = (value) => {
    setNonEditable(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const handleGanttData = (data) => {
    const ganttData = [];
    data.forEach((activity, index) => {
      ganttData.push({
        id: index,
        text: `${t(`activity_types_${activity.activityType.id}`)} / ${activity.name}`,
        start: new Date(activity.fromDate),
        end: new Date(activity.toDate),
      })
    })
    ganttData.sort(function(a,b){
      return new Date(a.start) - new Date(b.start);
    });
    new SVGGantt('#gantt', ganttData, {
      viewMode: 'week'
    });
  }

  useEffect(() => {
    if (formStore.formSectionData) {
      handleGanttData(formStore.formSectionData[0].gantt);
      const formSectionData = { ...formInitialValues, ...formStore.formSectionData[1] };
      setFormValues(formSectionData);
      formStore.setCurrentSection(8);
    }
  }, [formStore.formSectionData])

  return (
    <FormLayout>
      <FormContainer 
        number={8} 
        title={t('F3_S8_title')}
        formId="form4section8"
        showSavedMessage= {saved}
        showButtonClarif= {!nonEditable}
        showFooter={false}
      >
        <StyledDivWrapper>
          <div style={{marginBottom: '20px', overflowX: 'auto', maxWidth: '100vw'}}>
            <div id="gantt"/>
          </div>

          <Form.Group style={{display: 'flex', justifyContent: 'space-between'}}>
            <StyledFormLabel style={{marginTop: '8px'}}>{t('F3_S8_Start_day')}:</StyledFormLabel>
                <TextValue value={formStore.formSectionData? DayJS(formStore.formSectionData[0].startDate).format("DD/MM/YYYY"): "-"} />

            <StyledFormLabel style={{marginTop: '8px'}}>{t('F3_S8_End_day')}:</StyledFormLabel>
                <TextValue value={formStore.formSectionData? DayJS(formStore.formSectionData[0].endDate).format("DD/MM/YYYY"): "-"} />
          </Form.Group>
  
          <Form.Group>
            <StyledFormLabel> {t('F1_S7_Intervention_methodology')}: </StyledFormLabel>
            <TextValue value={formValues.methodology} />
          </Form.Group>  

          <Form.Group>
            <StyledFormLabel> {t('F3_S8_justification_deviation')}: </StyledFormLabel>
            <TextValue value={formValues.justificationDeviation} />
          </Form.Group>  

          <Form.Group>
            <StyledFormLabel> {t('F3_S8_risks')}: </StyledFormLabel>
            <TextValue value={formValues.covid19} />
          </Form.Group>  
        </StyledDivWrapper>

        <SeparatorDotted />

        <Comments 
          onSaved={handleSaved} 
          onButton={handleButton} 
        />
        
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section8);
