import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import TextValue from 'components/TextValue';

import Form from 'react-bootstrap/Form';
import { RequiredMark } from 'components/CommonStyled';
import {
  StyledFormLabel,
  StyledTitle as StyledTitleLocal,
  StyledSubtitle,
  StyledSubtitle2,
} from 'components/Forms/Form5/Section12/Section12.styled';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody 
} from '../CommonStyles'

export const formInitialValues = [
  {
    overviewTriangular: "",
    overviewEuropean: "",
  }
];

const Section12 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(formInitialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data]);

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`12 - ${t('F3_S12_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <StyledTitleLocal style={{marginBottom: '30px'}}>
          {t('F5_Subtitle_Assessment_TCI_Implementation')}
        </StyledTitleLocal>

        <StyledSubtitle>
          {t('F5_S7_Activity_assessment_10')}:
        </StyledSubtitle>

        <div style={{marginTop: '20px'}}>
          {formValues && formValues[1] && formValues[1].map(activity => (
            <Form.Group style={{display: 'flex'}}>
              <StyledFormLabel style={{margin: '0px'}}>{activity.activityName}:</StyledFormLabel>
              <TextValue value={activity.assessment} />
            </Form.Group>  
          ))}
        </div>

        <Form.Group style={{marginTop: '25px'}}>
            <StyledFormLabel>
            {t('F5_Overview_of_TCI')}<RequiredMark>*</RequiredMark>:
            </StyledFormLabel>
            <Form.Control
              name="overviewTriangular"
              as="textarea" 
              rows={6} 
              style={{resize: 'none'}}
              placeholder={t('Maximum_1300')}
              value={formValues[0].overviewTriangular}
              disabled={true}
            />
        </Form.Group>

        <StyledSubtitle>
            {t('F5_S7_Activity_assessment_11')}:
        </StyledSubtitle>

        <div style={{marginTop: '20px'}}>
          {formValues && formValues[2] && formValues[2].map(activity => (
            <Form.Group style={{display: 'flex'}}>
              <StyledFormLabel style={{margin: '0px'}}>{activity.activityName}:</StyledFormLabel>
              <TextValue value={activity.assessment} />
            </Form.Group>  
          ))}
        </div>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_Overview_of_TCI')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="overviewEuropean"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].overviewEuropean}
            disabled={true}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
          {t('F5_S12_Value_added_TCI')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="valueAddedTCI"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].valueAddedTCI}
            disabled={true}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S12_Contribution_beneficiary_entities')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="contributionBeneficiaryEntities"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].contributionBeneficiaryEntities}
            disabled={true}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S12_Benefits_for_entitities_additional')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="benefitsForEntitiesAdditional"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].benefitsForEntitiesAdditional}
            disabled={true}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S12_Benefits_for_entitities_provider')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="benefitsForEntitiesProvider"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].benefitsForEntitiesProvider}
            disabled={true}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S12_Value_partnership_itself')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="valuePartnershipItself"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].valuePartnershipItself}
            disabled={true}
          />
        </Form.Group>

        <StyledSubtitle2>{t('F5_S12_Subtitle_Added_TC')}</StyledSubtitle2>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S12_Ownership')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="ownership"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].ownership}
            disabled={true}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S12_Complementarity')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="complementarity"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].complementarity}
            disabled={true}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S12_Sharing_knowledge')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="sharingKnowledge"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].sharingKnowledge}
            disabled={true}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S12_Joint_creation')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="jointCreation"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].jointCreation}
            disabled={true}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S12_Increasing_volume')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="increasingVolume"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].increasingVolume}
            disabled={true}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S12_Global_and_regional')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="globalAndRegional"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].globalAndRegional}
            disabled={true}
          />
        </Form.Group>
      </StyledBody>
    </SectionContainer>
  )
}

export default Section12;


