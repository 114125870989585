import React from 'react';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import { StyledWarningP, StyledRequiredSpan } from '../Section7.styled';
import { SeparatorDotted } from 'components/CommonStyled';
import HelpIcon from 'components/HelpIcon';
import MoneyInput from 'components/MoneyInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { RadioLabel } from 'components/Forms/Forms.styled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  const getFieldTouched = getIn(formikInstance.touched, field);
  return {
    touched: getFieldTouched,
    error: (
      <FormErrorMessage
        touched
        errors={getFieldError}
      />
    )
  };
};

const ActivityForm = ({ formikInstance, activityIndex, activityTypes, countries, nonEditable }) => {
  const { t } = useTranslation();
  const { getFieldProps, touched, errors, values, setFieldValue } = formikInstance;
  const fieldPath = field => `activities.${activityIndex}.${field}`;

  const currentActivity = values?.activities?.[activityIndex];

  const handleChangeRadio = (e) => {
    setFieldValue(fieldPath('format'), e?.target?.value);
    if (e?.target?.value === '2') {
      setFieldValue(fieldPath('countryId'), '');
      setFieldValue(fieldPath('stateName'), '');
      setFieldValue(fieldPath('cityName'), '');
      setFieldValue(fieldPath('countriesAdditional'), '');
    }
  };

  return (
    <>
      <Form.Group as={Row} style={{ alignItems: 'center' }}>
        <Form.Label column lg={2}>
          {t('F1_S7_type')} <StyledRequiredSpan style={{ marginLeft: '7px' }}>*</StyledRequiredSpan>
          :
        </Form.Label>
        <Col xs={6}>
          <Form.Control
            as="select"
            name={fieldPath('activityTypeId')}
            {...getFieldProps(fieldPath('activityTypeId'))}
            className={
              touched[fieldPath('activityTypeId')] && errors[fieldPath('activityTypeId')]
                ? 'error'
                : null
            }
            disabled={nonEditable}
          >
            <option value="">{t('Placeholder_select')}</option>
            {activityTypes.map(activityType => (
              <option key={activityType.id} value={activityType.id}>
                {t(`activity_types_${activityType.id}`)}
              </option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath('activityTypeId')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column xs={2}>
          {t('F1_S7_start_day')} <StyledRequiredSpan>*</StyledRequiredSpan>:
        </Form.Label>
        <Col>
          <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={values.activities[activityIndex].fromDateStr}
              onChange={date => {
                setFieldValue(fieldPath('fromDateStr'), date);
              }}
              className={
                touched[fieldPath('fromDateStr')] && errors[fieldPath('fromDateStr')]
                  ? 'error'
                  : null
              }
              disabled={nonEditable}
            />
          </InputGroup>
          {fieldInfo(formikInstance, fieldPath('fromDateStr')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row} style={{ alignItems: 'center' }}>
        <Form.Label column xs={2}>
          {t('F1_S7_End_day')} <StyledRequiredSpan>*</StyledRequiredSpan>:
        </Form.Label>
        <Col>
          <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={values.activities[activityIndex].toDateStr}
              onChange={date => setFieldValue(fieldPath('toDateStr'), date)}
              className={
                touched[fieldPath('toDateStr')] && errors[fieldPath('toDateStr')] ? 'error' : null
              }
              disabled={nonEditable}
            />
          </InputGroup>
          {fieldInfo(formikInstance, fieldPath('toDateStr')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <Form.Label xs={5} lg={4} style={{ marginTop: '10px' }}>
            {t('F1_S7_Short_description')} <StyledRequiredSpan>*</StyledRequiredSpan>:
            <HelpIcon padding={'0 0 0 0.75rem'} info={t('F1_S7_Short_description_INFO')}/>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_1300')}
            name={fieldPath('description')}
            {...getFieldProps(fieldPath('description'))}
            className={
              touched[fieldPath('description')] && errors[fieldPath('description')] ? 'error' : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('description')).error}
        </Col>
      </Form.Group>

      <SeparatorDotted />

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S7_Format')} <StyledRequiredSpan>*</StyledRequiredSpan>:
          <HelpIcon info={t('F1_S7_Format_INFO')} />
        </Form.Label>
        <Col lg={8} style={{ marginTop: '20px' }}>
          <RadioLabel>
            <input
              type="radio"
              value="0"
              checked={currentActivity?.format === '0'}
              onChange={handleChangeRadio}
              style={{ marginRight: '10px' }}
              disabled={nonEditable}
            />
            {t('F1_S7_Format0')}
          </RadioLabel>

          <RadioLabel>
            <input
              type="radio"
              value="1"
              checked={currentActivity?.format === '1'}
              onChange={handleChangeRadio}
              style={{ marginRight: '10px' }}
              disabled={nonEditable}
            />
            {t('F1_S7_Format1')}
          </RadioLabel>

          <RadioLabel>
            <input
              type="radio"
              value="2"
              checked={currentActivity?.format === '2'}
              onChange={handleChangeRadio}
              style={{ marginRight: '10px' }}
              disabled={nonEditable}
            />
            {t('F1_S7_Format2')}
          </RadioLabel>

          <Row lg={8} style={{marginLeft: '0px'}}>
            {fieldInfo(formikInstance, fieldPath('format')).error}
          </Row>
        </Col>

      </Form.Group>
      
      {currentActivity?.format !== '2' && (
        <>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>
              {t('F1_S7_Country_activity')} <StyledRequiredSpan>*</StyledRequiredSpan>:
              <HelpIcon info={t('F1_S7_Country_activity_INFO')} />
            </Form.Label>
            <Col lg={5}>
              <Form.Control
                as="select"
                name={fieldPath('countryId')}
                {...getFieldProps(fieldPath('countryId'))}
                className={
                  touched[fieldPath('countryId')] && errors[fieldPath('countryId')] ? 'error' : null
                }
                disabled={nonEditable}
              >
                <option value="">{t('Placeholder_select')}</option>
                {countries.map(country => (
                  <option key={country.id} value={country.id}>
                    {t(country.name)}
                  </option>
                ))}
              </Form.Control>
              {fieldInfo(formikInstance, fieldPath('countryId')).error}
            </Col>
          </Form.Group>
      
          <Form.Group as={Row} style={{ alignItems: 'center' }}>
            <Form.Label column xs={4}>
              {t('F1_S2_State_Province_Region')}:
            </Form.Label>
            <Col xs={5}>
              <Form.Control
                type="text"
                name={fieldPath('stateName')}
                {...getFieldProps(fieldPath('stateName'))}
                className={
                  touched[fieldPath('stateName')] && errors[fieldPath('stateName')] ? 'error' : null
                }
                disabled={nonEditable}
              />
              {fieldInfo(formikInstance, fieldPath('stateName')).error}
            </Col>
          </Form.Group>
      
          <Form.Group as={Row}>
            <Form.Label column lg={4}>
              {t('F1_S2_City')} <StyledRequiredSpan>*</StyledRequiredSpan>:
            </Form.Label>
            <Col lg={5}>
              <Form.Control
                type="text"
                name={fieldPath('cityName')}
                {...getFieldProps(fieldPath('cityName'))}
                className={
                  touched[fieldPath('cityName')] && errors[fieldPath('cityName')] ? 'error' : null
                }
                disabled={nonEditable}
              />
              {fieldInfo(formikInstance, fieldPath('cityName')).error}
            </Col>
          </Form.Group>
      
          <Form.Group as={Row}>
            <Form.Label column lg={4}>
              {t('F1_S7_Countries_additional')}:
            </Form.Label>
            <Col lg={5}>
              <Form.Control
                type="text"
                name={fieldPath('countriesAdditional')}
                {...getFieldProps(fieldPath('countriesAdditional'))}
                className={
                  touched[fieldPath('countriesAdditional')] && errors[fieldPath('countriesAdditional')]
                    ? 'error'
                    : null
                }
                disabled={nonEditable}
              />
              {fieldInfo(formikInstance, fieldPath('countriesAdditional')).error}
            </Col>
          </Form.Group>
        </>
      )}


      {!(
        values.activities[activityIndex].activityTypeId == '4' ||
        values.activities[activityIndex].activityTypeId == '7'
      ) && (
        <>
          <SeparatorDotted />

          <Form.Group as={Row}>
            <Form.Label column xs={4}>
              {t('F1_S7_Duration')} <StyledRequiredSpan>*</StyledRequiredSpan>:
            </Form.Label>
            <Col xs={3}>
              <InputGroup>
                <Form.Control
                  type="number"
                  min="0"
                  name={fieldPath('duration')}
                  {...getFieldProps(fieldPath('duration'))}
                  className={
                    touched[fieldPath('duration')] && errors[fieldPath('duration')] ? 'error' : null
                  }
                  disabled={nonEditable}
                />
              </InputGroup>
              {fieldInfo(formikInstance, fieldPath('duration')).error}
            </Col>
          </Form.Group>

          <Form.Group as={Row} style={{alignItems: 'center'}}>
            <Form.Label column xs={4}>
              {t('F1_S7_Number_participants')} <StyledRequiredSpan>*</StyledRequiredSpan>
              :<HelpIcon info={t('F1_S7_Number_participants_INFO')} />
            </Form.Label>
            <Col xs={3}>
              <InputGroup>
                <Form.Control
                  type="number"
                  min="0"
                  name={fieldPath('participants')}
                  {...getFieldProps(fieldPath('participants'))}
                  className={
                    touched[fieldPath('participants')] && errors[fieldPath('participants')]
                      ? 'error'
                      : null
                  }
                  disabled={nonEditable}
                />
              </InputGroup>
              {fieldInfo(formikInstance, fieldPath('participants')).error}
            </Col>
          </Form.Group>
        </>
      )}

      {values.activities[activityIndex].activityTypeId == '4' && (
        <>
          <SeparatorDotted />

          <Form.Group as={Row}>
            <Form.Label column xs={3}>
              {t('F1_S7_Number_experts')}:
            </Form.Label>
            <Col xs={3}>
              <InputGroup>
                <Form.Control
                  type="number"
                  min="0"
                  name={fieldPath('experts')}
                  {...getFieldProps(fieldPath('experts'))}
                  disabled={nonEditable}
                  className={
                    touched[fieldPath('experts')] && errors[fieldPath('experts')] ? 'error' : null
                  }
                />
              </InputGroup>
              {fieldInfo(formikInstance, fieldPath('experts')).error}
            </Col>
          </Form.Group>

          <Form.Label xs={3} style={{ marginBottom: '30px' }}>
            {t('F1_S7_Number_wd')}:
          </Form.Label>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {t('F1_S7_Remote')}
            </Form.Label>
            <Col xs={2}>
              <InputGroup>
                <Form.Control
                  type="number"
                  min="0"
                  name={fieldPath('daysRemote')}
                  {...getFieldProps(fieldPath('daysRemote'))}
                  disabled={nonEditable}
                  className={
                    touched[fieldPath('daysRemote')] && errors[fieldPath('daysRemote')]
                      ? 'error'
                      : null
                  }
                />
              </InputGroup>
              {fieldInfo(formikInstance, fieldPath('daysRemote')).error}
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {t('F1_S7_Onsite')}
            </Form.Label>
            <Col xs={2}>
              <InputGroup>
                <Form.Control
                  type="number"
                  min="0"
                  name={fieldPath('daysOnsite')}
                  {...getFieldProps(fieldPath('daysOnsite'))}
                  disabled={nonEditable}
                  className={
                    touched[fieldPath('daysOnsite')] && errors[fieldPath('daysOnsite')]
                      ? 'error'
                      : null
                  }
                />
              </InputGroup>
              {fieldInfo(formikInstance, fieldPath('daysOnsite')).error}
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {t('F1_S7_Total')}
            </Form.Label>
            <Col xs={2}>
              <InputGroup>
                <Form.Control
                  type="number"
                  disabled={true}
                  value={
                    values.activities[activityIndex].daysRemote +
                    values.activities[activityIndex].daysOnsite
                  }
                />
              </InputGroup>
            </Col>
          </Form.Group>
        </>
      )}

      <SeparatorDotted />

      <Form.Label style={{ alignItems: 'center' }}>
        {t('F1_S7_estimated_budget')}
        <StyledRequiredSpan style={{ marginLeft: '7px' }}>*</StyledRequiredSpan>:
      </Form.Label>

      <Form.Group as={Row}>
        <Form.Label column md={4} xs={5}>
          {t('F1_S7_EU_budget')}:
        </Form.Label>
        <Col xs={3}>
          <InputGroup style={{ width: '160px' }}>
            <MoneyInput
              name={fieldPath('ueBudget')}
              value={values[fieldPath('ueBudget')]}
              {...getFieldProps(fieldPath('ueBudget'))}
              onChange={val => setFieldValue(fieldPath('ueBudget'), val)}
              customInput={Form.Control}
              className={
                touched[fieldPath('ueBudget')] && errors[fieldPath('ueBudget')] ? 'error' : null
              }
              disabled={nonEditable}
            />
          </InputGroup>
          {fieldInfo(formikInstance, fieldPath('ueBudget')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row} style={{ alignItems: 'center' }}>
        <Form.Label column md={4} xs={5}>
          {t('F1_S7_counterpart_budget')}:
        </Form.Label>
        <Col xs={3}>
          <InputGroup style={{ width: '160px' }}>
            <MoneyInput
              name={fieldPath('counterPartBudget')}
              value={values[fieldPath('counterPartBudget')]}
              {...getFieldProps(fieldPath('counterPartBudget'))}
              onChange={val => setFieldValue(fieldPath('counterPartBudget'), val)}
              customInput={Form.Control}
              className={
                touched[fieldPath('counterPartBudget')] && errors[fieldPath('counterPartBudget')]
                  ? 'error'
                  : null
              }
              disabled={nonEditable}
            />
          </InputGroup>
          {fieldInfo(formikInstance, fieldPath('counterPartBudget')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column md={4} xs={5}>
          {t('F1_S7_total_budget')}:
        </Form.Label>
        <Col xs={3}>
          <InputGroup style={{ width: '160px' }}>
            <MoneyInput
              value={
                (values.activities[activityIndex].counterPartBudget || 0) +
                (values.activities[activityIndex].ueBudget || 0)
              }
              disabled={true}
              customInput={Form.Control}
            />
          </InputGroup>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <Form.Label lg={4} style={{ alignItems: 'center' }}>
            {t('F1_S7_Justification_activity')}
            <StyledRequiredSpan style={{ marginLeft: '7px' }}>*</StyledRequiredSpan>:
            <HelpIcon info={t('F1_S7_Justification_activity_INFO')}/>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_1300')}
            name={fieldPath('justBudget')}
            {...getFieldProps(fieldPath('justBudget'))}
            className={
              touched[fieldPath('justBudget')] && errors[fieldPath('justBudget')] ? 'error' : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('justBudget')).error}
        </Col>
      </Form.Group>
    </>
  );
};

export default ActivityForm;
