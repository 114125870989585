import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { roleNamesConst, f5statusNamesConst } from 'constants/appConstants';
import SidebarNavigationItem from './SidebarNavigationItem';
import {
  Container,
  SectionContainer,
  SectionTitle,
  MyAssessmentDiv,
  StyledLink,
  CommentsNumber,
} from './SidebarNavigation.styled';

import { useTranslation } from 'react-i18next';

const SidebarNavigation = () => {
  const { t } = useTranslation();
  const { id, formId } = useParams();
  const { authStore, initiativeStore, formStore } = useRootStore();
  const [newCommentsNumber, setNewCommentsNumber] = useState(0);

  const role = authStore.roles[0];
  const baseRoute = authStore.getBaseRouteByRole();
  const isManager =
    role === roleNamesConst.INTPA ||
    role === roleNamesConst.EUD ||
    role === roleNamesConst.ATKE ||
    role === roleNamesConst.ATNKE ||
    role === roleNamesConst.CONSORTIUM;
  const isEud = role === roleNamesConst.EUD;
  const mustShowStatusF5=
    role === roleNamesConst.ATKE ||
    role === roleNamesConst.ATNKE ||
    role === roleNamesConst.CONSORTIUM ||
    role === roleNamesConst.INTPA;

  const canApplicantSeeStatusF5 = role === roleNamesConst.PUBLIC;

  const canSeeF55Valoration =
    role === roleNamesConst.INTPA ||
    role === roleNamesConst.ATKE ||
    role === roleNamesConst.CONSORTIUM;

  const [isF7, setIsF7] = useState(false);

  useEffect(() => {
    if (initiativeStore.formStateName)
      setIsF7(
        isEud &&
          initiativeStore.formStateName !== f5statusNamesConst.DRAFT &&
          initiativeStore.formStateName !== f5statusNamesConst.SENT,
      );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (formStore.unreadComments && formStore.unreadComments > 0) {
      setNewCommentsNumber(formStore.unreadComments);
    }
  }, [formStore.unreadComments]);

  const Form1SidebarItems = (
    <>
      <SectionContainer>
        <SidebarNavigationItem number={1} title={t('F1_S1_title')} />
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>{t('F1_menu_subtitle1')}</SectionTitle>
        <SidebarNavigationItem number={2} title={t('F1_s2_title')} />
        <SidebarNavigationItem number={3} title={t('F1_S3_title')} />
        <SidebarNavigationItem number={4} title={t('F1_S4_title')} />
        <SidebarNavigationItem number={5} title={t('F1_S5_title')} />
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>{t('F1_menu_subtitle2')}</SectionTitle>
        <SidebarNavigationItem number={6} title={t('F1_S6_title')} />
        <SidebarNavigationItem number={7} title={t('F1_S7_title')} />
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>{t('F1_menu_subtitle3')}</SectionTitle>
        <SidebarNavigationItem number={8} title={t('F1_S8_title')} />
      </SectionContainer>
    </>
  );

  const Form3SidebarItems = (
    <>
      <SectionContainer>
        <SidebarNavigationItem number={1} title={t('F1_S1_title')} />
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>{t('F1_menu_subtitle1')} </SectionTitle>
        <SidebarNavigationItem number={2} title={t('F1_s2_title')} />
        <SidebarNavigationItem number={3} title={t('F1_S3_title')} />
        <SidebarNavigationItem number={4} title={t('F1_S4_title')} />
        <SidebarNavigationItem number={5} title={t('F1_S5_title')} />
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>{t('F1_menu_subtitle2')} </SectionTitle>
        <SidebarNavigationItem number={6} title={t('F1_S6_title')} />
        <SidebarNavigationItem number={7} title={t('F3_S7_title')} />
        <SidebarNavigationItem number={8} title={t('F3_S8_title')} />
        <SidebarNavigationItem number={9} title={t('F3_S9_title')} />
        <SidebarNavigationItem number={10} title={t('F3_S10_title')} />
        <SidebarNavigationItem number={11} title={t('F3_S11_title')} />
        <SidebarNavigationItem number={12} title={t('F3_S12_title')} />
        <SidebarNavigationItem number={13} title={t('F3_S13_title')} />
      </SectionContainer>
      <SectionContainer>
        <SectionTitle>{t('F3_menu_subtitle3')}</SectionTitle>
        <SidebarNavigationItem number={14} title={t('F3_S14_title')} />
        {formId === '5' && (
          <>
            <SidebarNavigationItem number={15} title={t('F5_S15_title')} />
            <SidebarNavigationItem number={16} title={t('F5_S16_title')} />
          </>
        )}
      </SectionContainer>
    </>
  );

  const Form55SidebarItems = (
    <>
      <SectionContainer>
        <SidebarNavigationItem number={1} title={t('F1_S1_title')} />
        <SidebarNavigationItem number={2} title={t('F1_s2_title')} />
        <SidebarNavigationItem number={3} title={t('F1_S3_title')} />
        <SidebarNavigationItem number={4} title={t('F1_S4_title')} />
        <SidebarNavigationItem number={5} title={t('F55_S5_title')} />
        <SidebarNavigationItem number={6} title={t('F55_S6_title')} />
        <SidebarNavigationItem number={7} title={t('F55_S7_title')} />
        <SidebarNavigationItem number={8} title={t('F3_S8_title')} />
        <SidebarNavigationItem number={9} title={t('F55_S9_title')} />
        <SidebarNavigationItem number={10} title={t('F3_S10_title')} />
        <SidebarNavigationItem number={11} title={t('F3_S11_title')} />
      </SectionContainer>
    </>
  );

  const getSidebarItemsByForm = formId => {
    const sidebarByForm = {
      ['1']: Form1SidebarItems,
      ['2']: Form1SidebarItems,
      ['3']: Form3SidebarItems,
      ['4']: Form3SidebarItems,
      ['5']: Form3SidebarItems,
      ['55']: Form55SidebarItems,
    };
    return sidebarByForm[formId];
  };

  return (
    <Container>
      {getSidebarItemsByForm(formId)}

      {isManager && formId !== '5' && ((formId === '55' && canSeeF55Valoration) || formId !== '55') && (
        <StyledLink
          to={`${baseRoute}/initiative/${id}/form/${formId}/section/${formId === '2' ? '9' : formId === '55' ? '12' : '15'}`}
        >
          <MyAssessmentDiv>
            {t(formId === '55' ? 'Dashboard_Preform_assessment' : 'Button_my_assessment')}
          </MyAssessmentDiv>
        </StyledLink>
      )}
      {formId !== '55' ? (
        <StyledLink
          to={`${baseRoute}/initiative/${id}/form/${formId}/section/${
            formId === '5' ? '17' : (formId === '1' || formId === '2')? '10' : '16'
          }`}
        >
          <div>
            <MyAssessmentDiv>
              {newCommentsNumber > 0 ? (
                <CommentsNumber>
                  {`${newCommentsNumber} ${newCommentsNumber > 1 ? t('new_messages') : t('new_message')}`}
                </CommentsNumber>
              ) : null}
              {t('Button_comments_and_status')}
            </MyAssessmentDiv>
          </div>
        </StyledLink>
      ) : null}

      {formId === '5' && (
        <>
          {(canApplicantSeeStatusF5 || mustShowStatusF5 || isF7) && (
            <StyledLink to={`${baseRoute}/initiative/${id}/form/${formId}/section/18`}>
              <MyAssessmentDiv style={{ marginTop: '10px' }}>{t('F6_S18_button_assessment')}</MyAssessmentDiv>
            </StyledLink>
          )}
        </>
      )}
    </Container>
  );
};

export default observer(SidebarNavigation);
