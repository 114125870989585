import React, { useState, useEffect } from 'react';
import { formInitialValues } from './Section6.initialValues';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import TextValue from 'components/TextValue';
import { useTranslation } from 'react-i18next';
import Comments from 'components/Forms/Form4/Comments';

import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';

import Form from 'react-bootstrap/Form';

import {
  StyledSubtitle,
  StyledDivWrapper,
  StyledSeparator,
  StyledText,
} from './Section6.styled';


const Section6 = () => {

  const { formStore } = useRootStore();
  const {t} = useTranslation();
  const [formValues, setFormValues] = useState(formInitialValues);
  const [nonEditable, setNonEditable] = useState(false);
  const [saved, setSaved] = useState(false);


  const handleSaved = (value) => {
    setSaved(value);
  }

  const handleButton = (value) => {
    setNonEditable(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = { ...formInitialValues, ...formStore.formSectionData[0] };
      setFormValues(formSectionData);
      formStore.setCurrentSection(6);
    }
  }, [formStore.formSectionData])


  return (
    <FormLayout>
      <FormContainer
        number={6}
        title={t('F1_S6_title')}
        formId='form4section6'
        showSavedMessage= {saved}
        showButtonClarif= {!nonEditable}
      >
        <StyledDivWrapper>
          <StyledSubtitle>{t('F1_S6_Subtitle_1')}</StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_1')}:</StyledText>
            <TextValue value={formValues.name} />
          </Form.Group>

          <Form.Group style={{display: 'flex'}}>
            <div style={{float: 'left'}}>{t('F1_S6_Question_2')}:</div>
            <TextValue value={formValues.description} />
          </Form.Group>

          <Form.Group style={{display: 'flex'}}>
            <div style={{float: 'left'}}>{t('F1_S6_Question_3')}:</div>
            <TextValue value={formValues.objective} />
          </Form.Group>

          <StyledSubtitle>{t('F1_S6_Subtitle_2')}</StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_8')}:</StyledText>
            <TextValue value={formValues.contribution} />
          </Form.Group>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_4')}:</StyledText>
            <TextValue value={formValues.latamKnowledge} />
          </Form.Group>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_5')}:</StyledText>
            <TextValue value={formValues.euKnowledge} />
          </Form.Group>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_6')}:</StyledText>
            <TextValue value={formValues.newKnowledge} />
          </Form.Group>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_7')}:</StyledText>
            <TextValue value={formValues.application} />
          </Form.Group>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_9')}:</StyledText>
            <TextValue value={formValues.leap} />
          </Form.Group>

          <StyledSubtitle>{t('F1_S6_Subtitle_3')}</StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_10')}:</StyledText>
            <TextValue value={formValues.benEntities} />
          </Form.Group>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_11')}:</StyledText>
            <TextValue value={formValues.directBen} />
          </Form.Group>

          <StyledSubtitle>{t('F1_S6_Subtitle_4')}</StyledSubtitle>
          <StyledText> {t('F3_S6_Subtitle_4_intro')} </StyledText>

          {/* See SA2-773 */}
          <div style={{ display: 'none' }}>
            <Form.Group style={{display: 'flex'}}>
              <div style={{float: 'left'}}>{t('F1_S6_Question_12')}:</div>
              <TextValue value={formValues.goalPrimaryType? formValues.goalPrimaryType.name: " - "} />
            </Form.Group>

            <Form.Group>
              <StyledText>{t('F1_S6_Question_13')}:</StyledText>
              <TextValue value={formValues.primaryJustification} />
            </Form.Group>

            <Form.Group style={{display: 'flex'}}>
              <div style={{float: 'left'}}> {t('F1_S6_Question_14')}:</div>
              <TextValue value={formValues.goalSecondaryType? formValues.goalSecondaryType.name: " - " } />
            </Form.Group>

            <Form.Group>
              <StyledText>{t('F1_S6_Question_15')}:</StyledText>
              <TextValue value={formValues.secondaryJustification} />
            </Form.Group>
          </div>

          <StyledSubtitle> {t('F1_S6_Subtitle_5')}  </StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_16')}:</StyledText>
            <TextValue value={formValues.rights} />
          </Form.Group>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_17')}:</StyledText>
            <TextValue value={formValues.environment} />
          </Form.Group>

          <StyledSubtitle> {t('F1_S6_Subtitle_6')} </StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_18')}:</StyledText>
            <TextValue value={formValues.innovation} />
          </Form.Group>

          <StyledSubtitle> {t('F1_S6_Subtitle_7')} </StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_19')}:</StyledText>
            <TextValue value={formValues.valueTC} />
          </Form.Group>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_20')}:</StyledText>
            <TextValue value={formValues.valueEU} />
          </Form.Group>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_21')}:</StyledText>
            <TextValue value={formValues.valueTP} />
          </Form.Group>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_21b')}:</StyledText>
            <TextValue value={formValues.valueSR} />
          </Form.Group>

          <StyledSubtitle> {t('F1_S6_Subtitle_8')} </StyledSubtitle>

          <Form.Group>
            <StyledText>{t('F1_S6_Question_22')}:</StyledText>
            <TextValue value={formValues.covid19} />
          </Form.Group>

        </StyledDivWrapper>

        <StyledSeparator />

        <Comments onSaved={handleSaved}
                  onButton={handleButton} />

      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section6);
