import React, { useState, useEffect } from 'react'
import { Formik, FieldArray } from 'formik';
import { formInitialValues } from './ParticipantsForm.initialValues'
import { validationSchema } from './ParticipantsForm.validations';
import { useTranslation } from 'react-i18next';
import { useConstantsForm3 } from 'customHooks/useConstantsForm3';
import { saveParticipantF3S7 } from 'services/initiative';
import { useParams } from 'react-router-dom';
import htmlParser from "html-react-parser";

import ParticipantForm from './ParticipantForm';
import ConfirmCloseModal from 'components/ConfirmCloseModal';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import { PrimaryButton, ErrorButton, StyledSendButton } from 'components/Buttons';
import "react-datepicker/dist/react-datepicker.css";
import { Form } from 'react-bootstrap';
import { FormFielErrorMessage, SeparatorDotted, CenteredModalButtonContainer } from 'components/CommonStyled';
import { DisclaimerDiv } from './ParticipantsForm.styled';

const productObject = {
  firstName: "",
  lastName: "",
  nationalityId: "",
  city: "",
  email: "",
  phone: "",
  entityType: "",
  organizationName: ""
}

const ParticipantsForm = ({ handleCloseOk, handleClose, activity, handleCloseSubModalOnSave, handleCloseSubModalOnError, nonEditable }) => {

  const { t } = useTranslation();
  const { id } = useParams();
  const { entityTypes, countries } = useConstantsForm3();
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [initialValues, setInitialValues] = useState(formInitialValues);

  const openModalRemove = (key) => {
    setModalRemove(true);
    setSelectedKey(key);
  }

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  }

  useEffect(() => {
    const formValues = {...formInitialValues, ...activity};
    if(formValues.participantList)
      formValues.participantList.forEach(participant => {
        participant.entityTypeId = participant.entityType? participant.entityType.id: "";
        participant.nationalityId = participant.nationality? participant.nationality.id: "";
      });
    setInitialValues(formValues);
  }, [])

  const onSave = async (values) => {
    try {
      await saveParticipantF3S7(id, activity.id, values.participantList);
      handleCloseSubModalOnSave();
    } catch (error) {
      handleCloseSubModalOnError();
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSave}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <FieldArray 
              name='participantList'
              render={arrayHelpers => (
                <>
                  {modalRemove && (
                    <ConfirmCloseModal
                      show={modalRemove}
                      handleCloseConfirm={closeModalRemove}
                      handleActionOnConfirm={() => {
                        arrayHelpers.remove(selectedKey);
                        closeModalRemove();
                      }}
                      text={`Delete participant ${selectedKey+1}?`}
                    />
                  )}
                  <Accordion>
                    {props.values.participantList
                      ? ( props.values.participantList.map((participant, key) => (
                          <AccordionItem key={key+1}>
                            <AccordionItemHeader>
                              <AccordionTitle eventKey={key+1}>{t('F3_S7_Accordion_Participant')+" "+(key+1)}</AccordionTitle>
                            </AccordionItemHeader>
                            <Accordion.Collapse eventKey={key+1}>
                              <AccordionItemBody>
                                <ParticipantForm formikInstance={props} participantIndex={key} entityTypes={entityTypes} countries={countries} nonEditable={nonEditable}/> 
                                <ErrorButton disabled={nonEditable} onClick={() => openModalRemove(key)}>{t('F3_S7_Participant_button_delete')}</ErrorButton>
                              </AccordionItemBody>
                            </Accordion.Collapse>
                          </AccordionItem>
                        )))
                      : null}
                  </Accordion>
                  { 
                    typeof props.errors.participantList === 'string' 
                      ? <FormFielErrorMessage>{props.errors.participantList}</FormFielErrorMessage> 
                      : null
                  }
                  <SeparatorDotted />
                  <PrimaryButton disabled={nonEditable} onClick={() => arrayHelpers.push(productObject)} >{t('F3_S7_Participant_button_add')}</PrimaryButton>
                </>
              )}
            />
            
            <br />

            <DisclaimerDiv>
              {htmlParser(t('F3_S7_Participant_disclaimer'))}
            </DisclaimerDiv>

            <CenteredModalButtonContainer>
              {
                nonEditable
                ?
                  <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
                :
                  <>
                    <ErrorButton style={{marginRight: '20px'}} onClick={handleClose} disabled={nonEditable}> {t('Button_cancel')} </ErrorButton>
                    <StyledSendButton onClick={() => {onSave(props.values)}} disabled={nonEditable}> {t('Button_save')} </StyledSendButton>
                  </>
              }
            </CenteredModalButtonContainer>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ParticipantsForm;