import { getFormulationExportValues } from 'pages/statistics/Formulation/helpers/helpers'
import { getPreformulationExportValues } from 'pages/statistics/Preformulation/helpers/helpers'
import { getExecutionSummaryExportValues } from 'pages/statistics/Execution/helpers/helpers'
import {
  getEntitiesByRoleSummaryExportValues,
  getEntitiesByFunctionSummaryExportValues,
  getEntitiesByTypeSummaryExportValues,
  getEntitiesDetailExportValues,
} from 'pages/statistics/Entities/helpers/helpers'
import {
  getActivitiesDetailExportValues,
  getActivitiesByRoleSummaryExportValues,
  getActivitiesByTypeSummaryExportValues,
} from 'pages/statistics/Activities/helpers/helpers'
import {
  getBeneficiariesDetailExportValues,
  getBeneficiaryByTypeSummaryExportValues,
} from 'pages/statistics/DirectBeneficiaries/helpers/helpers'

export const getExportValues = (exportData) => {
  let data = {
    preFormulationData: getPreformulationExportValues(
      exportData.preFormulationData,
    ),
    formulationData: getFormulationExportValues(exportData.formulationData),
    implementationData: getExecutionSummaryExportValues(
      exportData.implementationData,
    ),
    entitiesByRoleData: getEntitiesByRoleSummaryExportValues(
      exportData.entitiesByRoleData,
    ),
    entitiesByTypeData: getEntitiesByTypeSummaryExportValues(
      exportData.entitiesByTypeData,
    ),
    entitiesByFunctionData: getEntitiesByFunctionSummaryExportValues(
      exportData.entitiesByFunctionData,
    ),
    entitiesFromCountryData: getEntitiesDetailExportValues(
      exportData.entitiesFromCountryData,
    ),
    activitiesFromCountryData: getActivitiesDetailExportValues(
      exportData.activitiesFromCountryData,
    ),
    activitiesByRoleData: getActivitiesByRoleSummaryExportValues(
      exportData.activitiesByRoleData,
    ),
    activitiesByTypeData: getActivitiesByTypeSummaryExportValues(
      exportData.activitiesByTypeData,
    ),
    beneficiariesByTypeData: getBeneficiaryByTypeSummaryExportValues(
      exportData.beneficiariesByTypeData,
    ),
    beneficiariesDetailData: getBeneficiariesDetailExportValues(
      exportData.beneficiariesDetailData,
    ),
    includeOnExport: exportData.includeOnExport,
  }
  return data
}
