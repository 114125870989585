import React, { useState, useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import htmlParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'stores';
import { saveFormSection } from 'services/initiative';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import { RequiredMark } from 'components/CommonStyled';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { f1statusNamesConst, roleNamesConst } from 'constants/appConstants';
import {
  StyledVideosContainer,
  StyledVideoItem,
  StyledCheckboxContainer,
  StyledImageContainer
} from './Section1.styled';

const initialValues = {
  checkOne: false,
  checkTwo: false,
  checkThree: false,
  checkFour: false,
  checkFive: false,
};

const Section1 = () => {

  const { formStore, initiativeStore } = useRootStore()
  const { id, formId, sectionId } = useParams();
  const [formValues, setFormValues] = useState(initialValues)
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const [finalAssessment, setFinalAssessment] = useState({
    show: false,
    msg: "",
  });

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  useEffect(() => {
    if((initiativeStore.formStateName === f1statusNamesConst.DRAFT) && (initiativeStore.role !== roleNamesConst.GUEST)){
      if(initiativeStore.formVersion === 1){
        setNonEditable(false);
      }
      else{
        setNonEditable(true);
      }
    }
    else{
      setNonEditable(true);
    }
    
  }, [initiativeStore.formStateName, initiativeStore.formStateName])

  useEffect(() => {
    if((initiativeStore.formStateName === f1statusNamesConst.APPROVED) || (initiativeStore.formStateName === f1statusNamesConst.REJECTED)){
      setFinalAssessment({
        show: true,
        msg: (formStore.formSectionData? formStore.formSectionData[0].finalAssessment: "")
      });
    }
  }, [initiativeStore.formStateName, initiativeStore.currentFormStateName, formStore.formSectionData])

  useEffect(() => {
    let newData = initialValues;
    if (formStore.formSectionData) {
      newData = { ...formStore.formSectionData[0] }
      delete newData.id;
      delete newData.complete;
    }
    setFormValues(newData);
  }, [formStore.formSectionData])
  
  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);  
  },[saved])

  const onSubmit = async (values, {resetForm} ) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values);
      formStore.setSectionStatus(sectionId, complete);
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormLayout>
      <FormContainer 
        number={1} 
        title={t('F1_S1_title')}
        formId="form1section1"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
      >
        { finalAssessment.show &&
        <Form.Group
          style={{marginLeft: '50px', marginTop: '10px', borderRadius: '5px' , border: '2px solid red'}}
          as={Row}
        >
          <Form.Control
            as="textarea"
            style={{resize: 'none'}}
            rows={4} 
            disabled={true}
            value={finalAssessment.msg}
          />
        </Form.Group>}

        <div  style={{marginLeft: '50px', paddingTop: '10px', textAlign: 'justify'}}>
          { htmlParser(t('F1_S1_intro')) }
        </div>
        
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={onSubmit}
        >
          {({
            values, 
            handleChange, 
            handleSubmit,
            dirty
          }) => (
            <form onSubmit={handleSubmit} id="form1section1">
              {dirty && (setSaved(false))}
              <StyledVideosContainer>
                <StyledVideoItem>
                  <StyledCheckboxContainer>
                  <input 
                      type="checkbox" 
                      name="checkTwo" 
                      checked={values.checkTwo} 
                      onChange={handleChange}
                      disabled={nonEditable}
                  />                    
                    <RequiredMark>*</RequiredMark>
                  </StyledCheckboxContainer>
                  <StyledImageContainer>
                   
                   { htmlParser(t('F1_S1_aknowledge1'))}
                 
                  </StyledImageContainer>
                </StyledVideoItem>

                <StyledVideoItem>
                  <StyledCheckboxContainer>
                    <input 
                        type="checkbox" 
                        name="checkThree" 
                        checked={values.checkThree} 
                        onChange={handleChange}
                        disabled={nonEditable}
                    />                    
                    <RequiredMark>*</RequiredMark>
                  </StyledCheckboxContainer>
                    <StyledImageContainer>
                     
                      {  htmlParser(t('F1_S1_aknowledge2'))}
                    
                    </StyledImageContainer>
                </StyledVideoItem>

                <StyledVideoItem>
                  <StyledCheckboxContainer>
                    <input 
                        type="checkbox" 
                        name="checkFour" 
                        checked={values.checkFour} 
                        onChange={handleChange}
                        disabled={nonEditable}
                    />                    
                    <RequiredMark>*</RequiredMark>
                  </StyledCheckboxContainer>
                    <StyledImageContainer>
                      
                      {  htmlParser(t('F1_S1_aknowledge3')) }
                    
                    </StyledImageContainer>
                </StyledVideoItem>

                <StyledVideoItem>
                  <StyledCheckboxContainer>
                    <input 
                        type="checkbox" 
                        name="checkFive" 
                        checked={values.checkFive} 
                        onChange={handleChange}
                        disabled={nonEditable}
                    />                    
                    <RequiredMark>*</RequiredMark>
                  </StyledCheckboxContainer>
                    <StyledImageContainer>
                      
                      {  htmlParser(t('F1_S1_aknowledge5')) }
                    
                    </StyledImageContainer>
                </StyledVideoItem>

                <StyledVideoItem>
                  <StyledCheckboxContainer >
                  <input 
                      type="checkbox" 
                      name="checkOne" 
                      checked={values.checkOne} 
                      onChange={handleChange}
                      disabled={nonEditable}
                  />   
                  <RequiredMark>*</RequiredMark>
                  </StyledCheckboxContainer>
                  <StyledImageContainer style={{textAlign: 'justify'}}>

                  { t('F1_S1_aknowledge4') }

                  </StyledImageContainer>
                </StyledVideoItem>

              </StyledVideosContainer>
            </form> 
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section1);


