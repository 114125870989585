export const initialValues = {
  primaryTargetList: [],
  secondaryTargetList: [],
  goal10TypeId: "",
  goal10Justification: "",
  goal10TargetList: [],
  goal17TypeId: "",
  goal17Justification: "",
  goal17TargetList: []
};
