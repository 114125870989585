export const formInitialValues = {
    assessment: "",
    quality: "",
    theme: "",
    participants: "",
    triangular: "",
    alignment: "",
    euPriorities: "",
    coherence: "",
    opportunity: "",
  }