import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RequiredMark } from 'components/CommonStyled';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  const getFieldTouched = getIn(formikInstance.touched, field);
  return {
    touched: getFieldTouched,
    error: (
      <FormErrorMessage
        touched
        errors={getFieldError}
      />
    )
  }
}
  
const TargetForm = ({ formikInstance, targetIndex, devGoalTargets, indicatorsList, nonEditable }) => {
  const { t } = useTranslation();
  const { getFieldProps, touched, errors, values, setFieldValue} = formikInstance;
  const fieldPath = (field) => `targetList.${targetIndex}.${field}`;
  const [devIndicatorsFiltered, setDevIndicatorsFiltered] = useState([]);
  const [devTargetsFiltered, setDevTargetsFiltered] = useState([]);

  const filterDevGoalsTargets = (goalTypeId) => {
    setDevTargetsFiltered(devGoalTargets.filter(e => e.developmentGoal.id == goalTypeId));
  }

  const filterIndicatorsOnTargetChange = (targetTypeId) => {
    const filteredArray = indicatorsList.filter(e => e.developmentGoalTarget.id == targetTypeId);
    filteredArray.forEach(indicator => {
      indicator.targetGoalIndicatorId = indicator.id;
      indicator.id = indicator.id;
      indicator.selectedIndicator = false;
    });
    setDevIndicatorsFiltered(filteredArray);
    setFieldValue(fieldPath('targetIndicatorList'), filteredArray);
  }

  useEffect(() => {
    if(values.goal){
      filterDevGoalsTargets(values.goal.id);
      if(values.targetList[targetIndex].targetGoal){
        values.targetList[targetIndex].targetGoalId = values.targetList[targetIndex].targetGoal.id;
        if(values.targetList[targetIndex].targetIndicatorList){
          const indicatorList = [...values.targetList[targetIndex].targetIndicatorList];
          indicatorList.forEach(e => {
            e.targetGoalIndicatorId = e.targetGoalIndicator.id;
          })
          setDevIndicatorsFiltered(indicatorList);
          setFieldValue(fieldPath('targetIndicatorList'), indicatorList);
        }
      }
    }
  }, [])

  return (
    <>
      <Form.Group as={Row}>
        <Col>
          <Form.Control
            name={fieldPath('targetGoalId')}
            as="select"
            size="sm"
            { ...getFieldProps(fieldPath('targetGoalId')) }
            onChange={(e) => {
              setFieldValue(fieldPath('targetGoalId'), e.target.value);
              filterIndicatorsOnTargetChange(e.target.value);
            }}
            className={touched[fieldPath('targetGoalId')] && errors[fieldPath('targetGoalId')] ? "error" : null}
            disabled={nonEditable}
          >
            <option value="">{t('Placeholder_select')}</option>
            {devTargetsFiltered.map(targetGoal => (
              <option key={targetGoal.id} value={targetGoal.id}>{t(`SDG_Target${targetGoal.id}`)}</option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath('targetGoalId')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col> 
          <Form.Label xs={5} lg={4} style={{marginTop: "10px"}}>{t('F3_S11_Justification_target')}<RequiredMark>*</RequiredMark>: </Form.Label>
          <Form.Control
            as="textarea" 
            rows={3}
            style={{resize: 'none', marginRight: '18px'}}
            placeholder={t('Maximum_650')}
            name={fieldPath('targetJustification')}
            { ...getFieldProps(fieldPath('targetJustification')) }
            className={touched[fieldPath('targetJustification')] && errors[fieldPath('targetJustification')] ? "error" : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('targetJustification')).error}
        </Col>
      </Form.Group>  

      {(devIndicatorsFiltered.length > 0) &&
        <Form.Group as={Row}>
          <Col xs={4} lg={3}  className="align-self-start"> 
            <Form.Label style={{marginTop: "10px"}}>{t('F3_S11_Indicators')}<RequiredMark>*</RequiredMark>: </Form.Label>
          </Col>
          <Col xs={10} lg={9} className="align-self-end"> 
            {devIndicatorsFiltered.map(indicator => (
              <Form.Check
                style={{marginBottom: '20px'}}
                key={indicator.targetGoalIndicatorId}
                type="checkbox" 
                label={t(`SDG_Indicator${indicator.targetGoalIndicatorId}`)}
                checked={indicator.selectedIndicator}
                onChange={() => {
                  const newArr = values.targetList[targetIndex].targetIndicatorList;
                  const elementPos = newArr.map((e) => e.id).indexOf(indicator.id);
                  newArr[elementPos].selectedIndicator = !newArr[elementPos].selectedIndicator;
                  setFieldValue(fieldPath('targetIndicatorList'), newArr);
                }}
              />
            ))}
            {fieldInfo(formikInstance, fieldPath('targetIndicatorList')).error}
          </Col>
        </Form.Group> 
      }
    </>
  )

}

export default TargetForm;
