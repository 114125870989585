import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'F1_S6_validation1')
    .max(200, 'F1_S6_validation2'),
  description: Yup.string()
    .min(10, 'F1_S6_validation3')
    .max(1300, 'F1_S6_validation4'),
  objective: Yup.string()
    .min(5, 'F1_S6_validation5')
    .max(350, 'F1_S6_validation6'),
  latamKnowledge: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(1300, 'F1_S6_validation8'),
  euKnowledge: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(1300, 'F1_S6_validation8'),
  newKnowledge: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(1300, 'F1_S6_validation8'),
  application: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(1300, 'F1_S6_validation8'),
  leap: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(1300, 'F1_S6_validation8'),
  contribution: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(1300, 'F1_S6_validation8'),
  benEntities: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(1300, 'F1_S6_validation8'),
  directBen: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(1300, 'F1_S6_validation8'),
  goalPrimaryTypeId: Yup.string(),
  primaryJustification: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(650, 'F1_S6_validation9'),
  goalSecondaryTypeId: Yup.string(),
  secondaryJustification: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(650, 'F1_S6_validation9'),
  rights: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(650, 'F1_S6_validation9'),
  environment: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(650, 'F1_S6_validation9'),
  innovation: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(650, 'F1_S6_validation9'),
  valueTC: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(1300, 'F1_S6_validation8'),
  valueEU: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(1300, 'F1_S6_validation8'),
  valueTP: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(1300, 'F1_S6_validation8'),
  valueSR: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(1300, 'F1_S6_validation8'),
  covid19: Yup.string()
    .min(10, 'F1_S6_validation7')
    .max(650, 'F1_S6_validation9'),
});
