import React, { useState, useEffect }  from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import {
  StyledFooter,
  StyledLeftContent,
  StyledRightContent,
  StyledIcon,
  StyledA,
  StyledContent,
  StyledPublicFooter,
  StyledPrivateFooter,
  StyledButtonLink,
  StyledHelpDesk
} from './Footer.styled';
import {Row, Col} from 'react-bootstrap';
import VerticallyCenteredModal from 'components/ModalHtml';
import ContactSupportForm from 'components/ContactSupportForm';
import CookiesFooter from 'components/CookiesFooter';

import {
  TW_LINK,
  FB_LINK,
  LK_LINK,
  AD_LINK,
  PP_EN_LINK,
  PP_ES_LINK,
  CP_LINK,
  PC_LINK,
  AL_LINK,
  LN_LINK,
  D_EN_LINK,
  D_ES_LINK,
} from './constants';

import iconFB from 'assets/icon-facebook.png';
import iconTW from 'assets/icon-twitter.png';
import iconLK from 'assets/icon-linkedin.png';


const Footer = (props) => {
  const { t, i18n } = useTranslation();

  const privacyPolicy = i18n.language==='en'? PP_EN_LINK: PP_ES_LINK;
  const documents = i18n.language==='en'? D_EN_LINK: D_ES_LINK;
  const cookiePolicy = i18n.language==='en'? CP_LINK: PC_LINK;
  const legalNotice = i18n.language==='en'? LN_LINK: AL_LINK;

  const cookie_footer = Cookies.get('user-cookie-decision-footer');
  const EXPIRATION_DAYS = 180; //6 months
  const [showCookieFooter, setShowCookieFooter] = useState(true);
  
  useEffect(() => {
    if(cookie_footer === 'true'){
      setShowCookieFooter(false);
    }
  }, [cookie_footer]);

  const [modalData, setModalData] = useState({
    isShow: false, 
    link: ""
  });

  const [helpDialogData, setHelpDialogData] = useState({
    isShow: false
  });

  const handleHideCookieFooter = () => {
    Cookies.set('user-cookie-decision-footer', 'true', { expires: EXPIRATION_DAYS });
    setShowCookieFooter(false);
  }

  const handleShowPrivacyPolicy = () => {
    setModalData({isShow:true, link: privacyPolicy});
  }

  const handleShowLegalNotice = () => {
    setModalData({isShow: true, link: legalNotice});
  }

  const handleShowCookiePolicy = () => {
    setModalData({isShow:true, link: cookiePolicy});
  }

  return (
    <>
      
      {helpDialogData.isShow && <ContactSupportForm
        show={helpDialogData.isShow}
        handleClose={() => {setHelpDialogData({
          isShow: false
        })}} 
        initiativeCode=""
        formName="" 
        sectionName=""
        sectionNumber=""
        sendTo="ICTHelpdesk"
      />}

      {
        showCookieFooter && (
          <CookiesFooter 
            handleShowCookiePolicy={handleShowCookiePolicy} 
            handleHideCookieFooter={handleHideCookieFooter} 
          />
        )
      }

      <StyledFooter>
        {!props.public && 
          <StyledPrivateFooter>
            <StyledContent>
              <StyledButtonLink variant="link" onClick={() => setModalData({isShow: true, link: t("faq_file_General")})}>
                {t('faq')}
              </StyledButtonLink>  
              {` | `} 
              <StyledButtonLink variant="link" onClick={() => setModalData({isShow:true, link: documents })}>
                {t('documents')}
              </StyledButtonLink> 
              {` | `} 
              <StyledHelpDesk onClick={() => {setHelpDialogData({isShow: true})}} > 
                {t('it_helpdesk')} 
              </StyledHelpDesk>
            </StyledContent>
          </StyledPrivateFooter>
        }

        <StyledPublicFooter style={{height: showCookieFooter ? '130px' : '45px' }}>
          <StyledLeftContent>
            Copyright © 2021 -  
            <StyledA href={AD_LINK} target="_blank" rel="noreferrer">
              www.adelante2.eu
            </StyledA>
            <a href={TW_LINK} target="_blank" rel="noreferrer" style={{textDecoration:'none'}}> 
              <StyledIcon src={iconTW} /> 
            </a>
            <a href={FB_LINK} target="_blank" rel="noreferrer" style={{textDecoration:'none'}}> 
              <StyledIcon src={iconFB} /> 
            </a>
            <a href={LK_LINK} target="_blank" rel="noreferrer" style={{textDecoration:'none'}}> 
              <StyledIcon src={iconLK} /> 
            </a>
          </StyledLeftContent>

          <StyledRightContent>
            <Row>
              <Col sm={11}>
                <StyledButtonLink variant="link" onClick={handleShowPrivacyPolicy} >
                  {t('privacy_policy')}
                </StyledButtonLink> 
                {` | `}  
                <StyledButtonLink variant="link" onClick={handleShowLegalNotice} >
                  {t('legal_advice')}
                </StyledButtonLink> 
                {` | `}  
                <StyledButtonLink variant="link" onClick={handleShowCookiePolicy} >
                  {t('cookie_policy')}
                </StyledButtonLink>
              </Col>
              <Col sm={1}>
                {`V${process.env.REACT_APP_VERSION}`}
              </Col>
            </Row>   
          </StyledRightContent>
        </StyledPublicFooter>
      </StyledFooter>

      <VerticallyCenteredModal
        show={modalData.isShow}
        onHide={() => setModalData({isShow: false, link: ""})}
        data={modalData.link}
      />

    </>
  )
}

export default Footer
