import React, {useState} from 'react'
import { useTranslation } from 'react-i18next';

import NewActivityForm from '../NewActivityForm';
import BudgetDetailAdminModal from '../BudgetDetailAdminModal';
import ConfirmCloseModal from 'components/ConfirmCloseModal';
import BudgetLineModal from '../BudgetLineModal';
import NewProductModal from '../NewProductModal';
import GeneralInfoForm from '../GeneralInfoForm';
import ProductsForm from '../ProductsForm';
import ConsultantsForm from '../ConsultantsForm';
import ParticipantsForm from '../ParticipantsForm';
import ResourcesF3Form from '../ResourcesF3Form';
import ResourcesExecutedForm from '../ResourcesExecutedForm';
import AssessmentForm from '../AssessmentForm';
import Modal from 'react-bootstrap/Modal';
import {
  StyledModalBody,
  ModalTitle
} from './ActivityGeneralModal.styled'
import DifussionMaterialsForm from '../DifussionMaterialsForm';

const ActivityGeneralModal = ({handleRefreshList, show, formType, handleClose, handleOpenGeneralModal, handleCloseOnSave, setModalInfo, handleCloseOnError, title, activity, activityCode, handleCloseOk, nonEditable, nonEditableMgm }) => {
  const { t } = useTranslation();
  const [showNewProductModal, setShowNewProductModal] = useState(false);
  const [newProductObj, setNewProductObj] = useState({});
  const [budgetDetailAdminModalData, setBudgetDetailAdminModalData] = useState({
    isShow: false,
    type: null,
    title: '',
    isManagerNonEditable: false,
    detail: null
  });
  const [budgetLineModalData, setBudgetLineModalData] = useState({
    isShow: false,
    detail: null
  });
  const [isCloseBudgetModalAlertShow, setIsCloseBudgetModalAlertShow] = useState(false);

  const handleCloseSubModalOnSave = async () => {
    await handleRefreshList();
    handleCloseOnSave();
  }

  //New product
  const handleOpenNewProductModal = () => {
    setShowNewProductModal(true);
  }
  const setNewProductToLocalList = (newProductObj) => {
    setNewProductObj(newProductObj);
  }

  //Budget Line Modal
  const handleBudgetLineModalClose = () => {
    setBudgetLineModalData({
      isShow: false,
      detail: null
    });
    handleOpenGeneralModal();
  }

  const handleBudgetLineModalOpen = (detail) => {
    handleCloseOnSave();
    setBudgetLineModalData({
      isShow: true,
      detail: detail
    });
  }

  const getFormByType = (type) => {
    const fromByType = {
      [0]:  <GeneralInfoForm handleCloseOk={handleCloseOk} handleClose={handleClose} activity={activity} handleCloseSubModalOnSave={handleCloseSubModalOnSave} handleCloseSubModalOnError={handleCloseOnError} nonEditable={nonEditable} nonEditableMgm={nonEditableMgm}/>,
      [1]:  <ProductsForm handleCloseOk={handleCloseOk} handleClose={handleClose} setModalInfo={setModalInfo} activity={activity} handleCloseSubModalOnSave={handleCloseSubModalOnSave} handleCloseSubModalOnError={handleCloseOnError} handleOpenNewProductModal={handleOpenNewProductModal} newProductObj={newProductObj} nonEditable={nonEditable} nonEditableMgm={nonEditableMgm} />,
      [2]:  <ConsultantsForm handleCloseOk={handleCloseOk} handleClose={handleClose} activity={activity} handleCloseSubModalOnSave={handleCloseSubModalOnSave} handleCloseSubModalOnError={handleCloseOnError} nonEditable={nonEditable} />,
      [3]:  <ParticipantsForm handleCloseOk={handleCloseOk} handleClose={handleClose} setModalInfo={setModalInfo} activity={activity} handleCloseSubModalOnSave={handleCloseSubModalOnSave} handleCloseSubModalOnError={handleCloseOnError} nonEditable={nonEditable} nonEditableMgm={nonEditableMgm}/>,
      [4]:  <ResourcesF3Form handleCloseOk={handleCloseOk} activity={activity} />,
      [5]:  <ResourcesExecutedForm handleCloseOk={handleCloseOk} handleBudgetLineModalOpen={handleBudgetLineModalOpen} activity={activity} setBudgetDetailAdminModalData={setBudgetDetailAdminModalData}/>,
      [6]:  <AssessmentForm handleCloseOk={handleCloseOk} handleClose={handleClose} activity={activity} handleCloseSubModalOnSave={handleCloseSubModalOnSave} handleCloseSubModalOnError={handleCloseOnError} nonEditable={nonEditable} nonEditableMgm={nonEditableMgm} />,
      [7]:  <NewActivityForm handleClose={handleCloseOnSave} handleRefreshList={handleRefreshList}  handleCloseOnError={handleCloseOnError}/>,
      [8]:  <DifussionMaterialsForm
              activity={activity}
              handleCloseOk={handleCloseOk}
            />,
    }
    return fromByType[type];
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl" backdrop="static" animation={false}>
        <StyledModalBody>

          <ModalTitle>{`${title} ${!activityCode? "": `- ${t('F1_S7_Accordion')} ${activityCode}`}`}</ModalTitle>

          <div style={{marginTop: '40px'}}>
            {getFormByType(formType)}
          </div>

        </StyledModalBody>
      </Modal>

      <NewProductModal
        show={showNewProductModal}
        setNewToLocalList={setNewProductToLocalList}
        handleClose={() => setShowNewProductModal(false)}
        handleCloseOnSave={() => setShowNewProductModal(false)}
        handleCloseOnError={handleCloseOnError}
        activity={activity}
        nonEditable={nonEditable}
      />

      <ConfirmCloseModal
        show={isCloseBudgetModalAlertShow}
        handleCloseConfirm={() => setIsCloseBudgetModalAlertShow(false)}
        handleActionOnConfirm={handleBudgetLineModalClose}
        text={t('Confirmation close modal')}
      />

      <BudgetLineModal
        show={budgetLineModalData.isShow}
        detail={budgetLineModalData.detail}
        handleClose={() => setIsCloseBudgetModalAlertShow(true)}
        handleCloseOnSave={handleBudgetLineModalClose}
        handleCloseOnError={handleCloseOnError}
        activity={activity}
        setBudgetDetailAdminModalData={setBudgetDetailAdminModalData}
        setModalInfo={setModalInfo}
        nonEditable={nonEditable}
      />

      <BudgetDetailAdminModal
        show={budgetDetailAdminModalData.isShow}
        type={budgetDetailAdminModalData.type}
        title={budgetDetailAdminModalData.title}
        activity={activity}
        detail={budgetDetailAdminModalData.detail}
        setBudgetDetailAdminModalData={setBudgetDetailAdminModalData}
        setModalInfo={setModalInfo}
        handleBudgetLineModalOpen={() => handleBudgetLineModalOpen(budgetDetailAdminModalData.detail)}
        payments={budgetDetailAdminModalData.payments}
      />
    </>
  )
}

export default ActivityGeneralModal;