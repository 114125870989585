import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { Container, StyledNumberCircle, StyledItemTitle, StyledLink } from './SidebarNavigationItem.styled';

const SidebarNavigationItem = ({ number, title, disabled = false}) => {

  const { id, formId, sectionId } = useParams();
  const { formStore, authStore } = useRootStore();

  const currentSection = number === +sectionId;
  const sectionStatus = formStore.sectionsStatus[number];

  const baseRoute = authStore.getBaseRouteByRole();

  return (
    <StyledLink
      to={`${baseRoute}/initiative/${id}/form/${formId}/section/${number}`}
      style={disabled ?  {pointerEvents: "none"} : undefined}
    >
      <Container>
        <StyledNumberCircle status={sectionStatus}>{number}</StyledNumberCircle>
        <StyledItemTitle current={currentSection} >{title}</StyledItemTitle>
      </Container>
    </StyledLink>
  )
}

SidebarNavigationItem.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string
}

export default observer(SidebarNavigationItem);
