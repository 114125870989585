import React from 'react'
import { useTranslation } from 'react-i18next'
import { getExecutionSummaryExportValues, getFundsExecutionSummaryExportValues } from '../helpers/helpers'

import { Col2 } from '../../CommonStyled/Components.styled'
import { ExportStatisticsByYearsToXLSX } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'

const Header1 = ({ data, data2, waiting }) => {
  const { t } = useTranslation()

  return (
    <>
      <Col2>
        <ExportToXLSXButton
          data={data}
          mapData={getExecutionSummaryExportValues}
          data2={data2}
          mapData2={getFundsExecutionSummaryExportValues}
          disabled={waiting}
          exportName={t('Statistics_execution_tab1')}
        >
          <ExportStatisticsByYearsToXLSX label={t('Statistics_execution_tab1')} data={[]} />
        </ExportToXLSXButton>
      </Col2>
    </>
  )
}

export default Header1
