import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import { Table, Th3Pointer } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'

import {
  T4RenderRows,
  renderHeaders,
  orderDataByFieldToSummary,
} from '../helpers/helpers'
import { TARGET_YEARS } from 'constants/appConstants'
import { StyledRow2 } from 'pages/statistics/CommonStyled/Tabs.styled'

const Table4 = ({ title, data, waiting, setData }) => {
  const { t } = useTranslation()
  const [ascendingOrder, setAscendingOrder] = useState({
    category: true,
    2021: true,
    2022: true,
    2023: true,
    2024: true,
    total: true,
  })

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data && !isEmpty(data) ? (
    <StyledRow2 style={{ width: '100%', marginTop: '24px'}}>
      <b>{t('triang_coop_initiatives')}</b>
      <Table style={{ marginTop: '16px'}}>
        <colgroup>
          <col span="5" />
          <col span="5" style={{ backgroundColor: '#a6b3bb' }} />
        </colgroup>
        <tr>
          <Th3Pointer
            width="50%"
            onClick={() =>
              orderDataByFieldToSummary(
                data,
                'category',
                setData,
                setAscendingOrder,
                ascendingOrder,
                4,
              )
            }
          >
            {title ? title : t('Statistics_activities_tab4')} &#8645;
          </Th3Pointer>
          {renderHeaders(
            TARGET_YEARS,
            data,
            setData,
            setAscendingOrder,
            ascendingOrder,
          )}
        </tr>
        {T4RenderRows(data, TARGET_YEARS)}
      </Table>
    </StyledRow2>
  ) : (
    t('No_data_available_2')
  )
}

export default Table4
