import i18n from 'i18n'
import { Td } from '../../CommonStyled/Components.styled'
import _map from 'lodash/map'
import _fill from 'lodash/fill'
import { renderTotals, total } from 'pages/statistics/CommonHelpers/helpers'

export const table1TransformData = (data, years) => {
  let rows = {
      submitted: [],
      pendingReview: [],
      rejected: [],
      notRejected: [],
      requestsForClarifications: [],
      pendingReview2: [],
      notAccepted: [],
      accepted: [],
    },
    aux = []

  for (let i = 0; i < years.length; i++) {
    aux = data.filter((item) => item.year == years[i])[0]
    rows.submitted[i] = aux ? aux.submitted : '-'
    rows.pendingReview[i] = aux ? aux.pendingReview : '-'
    rows.rejected[i] = aux ? aux.rejected : '-'
    rows.notRejected[i] = aux ? aux.notRejected : '-'
    rows.requestsForClarifications[i] = aux
      ? aux.requestsForClarifications
      : '-'
    rows.pendingReview2[i] = aux ? aux.pendingReview2 : '-'
    rows.notAccepted[i] = aux ? aux.notAccepted : '-'
    rows.accepted[i] = aux ? aux.accepted : '-'
  }
  return rows
}

export const renderRows = (row, length) => {
  if (row) {
    let aux = []
    for (let i = 0; i <= length - 1; i++)
      aux.push(<Td>{row[i] ? row[i] : row[i] == 0 ? 0 : '-'}</Td>)
    aux.push(renderTotals(row))
    return aux
  }
}

export const getPreformulationExportValues = (exportData) => {
  let data = []

  data.push({
    label: i18n.t('Submitted'),
    col1: exportData.submitted[0],
    col2: exportData.submitted[1],
    col3: exportData.submitted[2],
    col4: exportData.submitted[3],
    col5: total(exportData.submitted),
  })
  data.push({
    label: i18n.t('Pending_review'),
    col1: exportData.pendingReview[0],
    col2: exportData.pendingReview[1],
    col3: exportData.pendingReview[2],
    col4: exportData.pendingReview[3],
    col5: total(exportData.pendingReview),
  })
  data.push({
    label: i18n.t('Rejected_first_filter'),
    col1: exportData.rejected[0],
    col2: exportData.rejected[1],
    col3: exportData.rejected[2],
    col4: exportData.rejected[3],
    col5: total(exportData.rejected),
  })
  data.push({
    label: i18n.t('Not_rejected_first_filter'),
    col1: exportData.notRejected[0],
    col2: exportData.notRejected[1],
    col3: exportData.notRejected[2],
    col4: exportData.notRejected[3],
    col5: total(exportData.notRejected),
  })
  data.push({
    label: i18n.t('Request_for_clarifications'),
    col1: exportData.requestsForClarifications[0],
    col2: exportData.requestsForClarifications[1],
    col3: exportData.requestsForClarifications[2],
    col4: exportData.requestsForClarifications[3],
    col5: total(exportData.requestsForClarifications),
  })

  data.push({
    label: i18n.t('Pending_review2'),
    col1: exportData.pendingReview2[0],
    col2: exportData.pendingReview2[1],
    col3: exportData.pendingReview2[2],
    col4: exportData.pendingReview2[3],
    col5: total(exportData.pendingReview2),
  })

  data.push({
    label: i18n.t('Not_acepted'),
    col1: exportData.notAccepted[0],
    col2: exportData.notAccepted[1],
    col3: exportData.notAccepted[2],
    col4: exportData.notAccepted[3],
    col5: total(exportData.notAccepted),
  })

  data.push({
    label: i18n.t('Acepted'),
    col1: exportData.accepted[0],
    col2: exportData.accepted[1],
    col3: exportData.accepted[2],
    col4: exportData.accepted[3],
    col5: total(exportData.accepted),
  })
  return data
}
