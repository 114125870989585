import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { StyledModalHeader, StyledModalBody, StyledModalFooter } from './Modal.styled';

const CustomModal = ({
  show,
  onHide,
  size = 'md',
  header = null,
  message,
  footer = null,
  type
}) => {
  return (
    <Modal 
      show={show} 
      onHide={onHide} 
      size={size}
      header={header}
      animation={false}
    >
      {(header !== null) && (<StyledModalHeader closeButton>
        {header}
      </StyledModalHeader>)}
      <StyledModalBody type={type}>
        {message} 
      </StyledModalBody>
      {(footer !== null) && <StyledModalFooter>
        {footer}
      </StyledModalFooter>}
    </Modal>
  )
}

CustomModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  header: PropTypes.element,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  footer: PropTypes.element
}

export default CustomModal; 
