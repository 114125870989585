import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { roleNamesConst, f2statusNamesConst } from 'constants/appConstants';
import { useRootStore } from 'stores';
import { saveFormSection } from 'services/initiative';
import { useGetClarifications } from 'customHooks/useGetClarifications';
import { PrimaryButton } from 'components/Buttons';
import Modal from 'components/Modal';
import { FakeLink, FormFielErrorMessage } from 'components/CommonStyled';
import { 
  ClarificationsLinkContainer, 
  RequestClarificationLabel, 
  StyledDiv, 
  ButtonContainer,
  ModalTitle,
  StyledSavedClarifButton,
} from './RequestClarification.styled';

import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

const EudClarifications = ({ clarifications }) => (
  <>
    {clarifications?.map((item, index) => {
      const { user, state: status, clarification } = item;
      const name = `${user.name} ${user.lastName}`;
      const organization = "("+i18n.t('organization')+": "+(user.organization ? user.organization : '-')+")";
      const text  = clarification ? clarification : '-';
      return (
        <p key={index}>
         {i18n.t('CR_for')+" "} <i>{`${name} ${organization}`}</i>: {`[${status}]`} <br/> <i style={{whiteSpace:"pre-wrap"}}>{text}</i>
        </p>
      )
    })}
  </>
)

const IntpaClarification = ({ clarification }) => (
  <p>{i18n.t('INTPA_clarification:')+" "}<i>{clarification}</i></p>
)

const EudClarificationsModal = () => {
  const { eudClarifications } = useGetClarifications();  
  const { sectionId } = useParams();
  const [modal, setModal] = useState(false);
  const {t} = useTranslation();

  return (
    <>
    {modal && <Modal
      size="lg"
      show={modal}
      onHide={() => setModal(false)}
      header={<ModalTitle>{t('CR_for_section')+" "+sectionId+" "+"(EUD)"}</ModalTitle>}
      message={<EudClarifications clarifications={eudClarifications} />}
      footer={<PrimaryButton onClick={() => setModal(false)}>{t('Button_close')}</PrimaryButton>}
    />}
    <ClarificationsLinkContainer>
      <FakeLink onClick={() => setModal(true)}>{t('F2_view_clarifications_EUD')}</FakeLink>
    </ClarificationsLinkContainer>
    </>
  )
}

const IntpaClarificationsModal = () => {
  const { inptaClarification } = useGetClarifications();  
  const { sectionId } = useParams();
  const [eudModal, setModal] = useState(false);
  const {t} = useTranslation();

  return (
    <>
    {eudModal && <Modal
      size="lg"
      show={eudModal}
      onHide={() => setModal(false)}
      header={<ModalTitle>{t('CR_for_section')+" "+sectionId+" "+"(INTPA)"}</ModalTitle>}
      message={<IntpaClarification clarification={inptaClarification} />}
      footer={<PrimaryButton onClick={() => setModal(false)}>{t('Button_close')}</PrimaryButton>}
      animation={false}
    />}
    <ClarificationsLinkContainer>
      <FakeLink onClick={() => setModal(true)}>{t('F2_view_clarifications_INTPA')}</FakeLink>
    </ClarificationsLinkContainer>
    </>
  )
}

const RequestClarification = (props) => {

  const {t} = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { formStore, authStore, initiativeStore } = useRootStore();
  const [clarification, setClarification] = useState('');
  const { inptaClarification, eudClarifications } = useGetClarifications(); 
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(true);
  const [error, setError] = useState(false);

  
  useEffect(() => {
      setNonEditable(!(initiativeStore.assessmentStatus === f2statusNamesConst.PENDING || initiativeStore.assessmentStatus === f2statusNamesConst.ONGOING) || (initiativeStore.formVersion == 2) || isATKNKE || isCONSORTIUM);
      if(props.onButton)
        props.onButton(nonEditable);
      
  },[initiativeStore,props.onButton])

  useEffect(() => {
    if (isIntpa && inptaClarification!= null) {
        setClarification(inptaClarification);
    }
    else
      if (isEud && eudClarifications!= null) {
         setClarification(eudClarifications[0].clarification);
      }
    
  }, [inptaClarification, eudClarifications])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);
  }, [saved]);

  const handleClarificationChange = (event) => {
    const { value } = event.target;
    setClarification(value);
    setSaved(false);
    props.onSaved(false);
    if (value.length) {
      setError(false)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!clarification.length) {
      setError(true);
      return;
    }
    try {
      await saveFormSection(id, formId, sectionId, { clarification });
      setSaved(true);
      props.onSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const role = authStore.roles[0];

  const isIntpa = role === roleNamesConst.INTPA;
  const isEud = role === roleNamesConst.EUD;
  const isTa = role === roleNamesConst.ATKE || role === roleNamesConst.ATNKE || role === roleNamesConst.CONSORTIUM;
  const isATKNKE = role === roleNamesConst.ATNKE;
  const isCONSORTIUM = role === roleNamesConst.CONSORTIUM;

  const shouldDisplayEudModal = isIntpa || isTa;
  const shouldDisplayIntpaModal = isTa;
  const shouldDisplayForm = isIntpa || isEud;

  if(formStore.formSectionNumber !== sectionId)
    return null;

  return (
    <>
      {shouldDisplayEudModal && <EudClarificationsModal />}
      {shouldDisplayIntpaModal && <IntpaClarificationsModal />}
      {shouldDisplayForm && (
        <form onSubmit={onSubmit} id={`form2section${sectionId}`}>
          <Form.Group>
            <Row>
              <RequestClarificationLabel>{t('F2_Request_clarifications')}</RequestClarificationLabel>
            </Row>
            <StyledDiv>
              <Form.Control 
                as="textarea"
                style={{resize: 'none'}}
                rows={4}
                value= { clarification }
                onChange={handleClarificationChange}
                disabled={nonEditable}
              />
              { error && <FormFielErrorMessage>{t('F3_S14_validation1')}</FormFielErrorMessage> }
            </StyledDiv>
            <ButtonContainer>
            
             {!nonEditable && <StyledSavedClarifButton type="submit" disabled={saved} >
                {!saved? t('Button_Save_Request_Clarification'): t('Button_Saved_Request_Clarification')}
              </StyledSavedClarifButton> }
            </ButtonContainer>
          </Form.Group>
        </form>
      )}
    </>
  )
}

export default RequestClarification;
