// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Proptypes from 'prop-types';
import { getIn, FieldArray, useFormikContext } from 'formik';
import Modal from 'react-bootstrap/Modal';
import {
  ModalFooter,
  FooterLeft,
  FooterCenter,
  FooterRight,
  RoundedRemoveDiv,
  RoundedAddDiv,
  StyledButtonLink,
  DivButtonWrapper } from './Styled';
import { PrimaryButton } from 'components/Buttons';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import { SeparatorDotted } from 'components/CommonStyled';
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const countryObject = {
  countryId: "28",
  quantity: 0,
};

const CountryRow = ({ rowIndex, beneficiaryIndex, removeCountry, countries }) => {
  const { t } = useTranslation();
  const { getFieldProps, touched, errors } = useFormikContext();
  const fieldPath = (field) => `benList.${beneficiaryIndex}.answersFundS10BenCountryDTOList.${rowIndex}.${field}`;

  const fieldInfo = (field) => {
    return (
      <FormErrorMessage
        touched={getIn(touched, field)}
        errors={getIn(errors, field)}
      />
    )
  }

  return (
    <Form.Group as={Row}>
      <Col xs={7}>
        <Form.Control
          name={fieldPath('countryId')}
          as="select"
          { ...getFieldProps(fieldPath('countryId')) }
          className={touched[fieldPath('countryId')] && errors[fieldPath('countryId')] ? "error" : null}
        >
          {countries.map(country => (
              <option key={country.id} value={country.id}>{t(country.name)}</option>
          ))}
        </Form.Control>
        {fieldInfo(fieldPath('countryId'))}
      </Col>
      <Col xs={3}>
        <Form.Control
          type="number"
          min="0"
          name={fieldPath('quantity')}
          { ...getFieldProps(fieldPath('quantity')) }
          className={touched[fieldPath('quantity')] && errors[fieldPath('quantity')] ? "error" : null}
        />
        {fieldInfo(fieldPath('quantity'))}
      </Col>
      <Col xs={1}>
        <StyledButtonLink type="button" onClick={() => {removeCountry(rowIndex)}}>
          <RoundedRemoveDiv>x</RoundedRemoveDiv>
        </StyledButtonLink>
      </Col>
    </Form.Group>
  )
}

const AddBeneficiaryDialog = ({  beneficiaryIndex, countries, displayDialog, onCloseModal }) => {
  const { values } = useFormikContext();
  const fieldPath = (field) => `benList.${beneficiaryIndex}.${field}`;
  const {t} = useTranslation();

  return (
    <Modal show={displayDialog} onHide={onCloseModal} size="md" animation={false}>
      <Modal.Body>
        <FieldArray
          name={fieldPath('answersFundS10BenCountryDTOList')}
          render={arrayHelpers => (
            <>
              <ListGroup>
                {values.benList[beneficiaryIndex].answersFundS10BenCountryDTOList
                  ? ( values.benList[beneficiaryIndex].answersFundS10BenCountryDTOList.map((country, key) => (
                      <ListGroup.Item key={key+1}>
                        <CountryRow
                          rowIndex={key}
                          beneficiaryIndex={beneficiaryIndex}
                          removeCountry={arrayHelpers.remove}
                          countries={countries}
                        />
                      </ListGroup.Item>
                    )))
                  : null}
              </ListGroup>

              <SeparatorDotted />
              <StyledButtonLink type="button" onClick={() => arrayHelpers.push(countryObject)}>
                <DivButtonWrapper> <RoundedAddDiv>+</RoundedAddDiv>{t('Add_country')}</DivButtonWrapper>
              </StyledButtonLink>
            </>
          )}
        />
      </Modal.Body>
      <ModalFooter>
        <FooterLeft />
        <FooterCenter>
          <PrimaryButton onClick={onCloseModal}>OK</PrimaryButton>
        </FooterCenter>
        <FooterRight />
      </ModalFooter>
    </Modal>
  )
}

AddBeneficiaryDialog.propTypes = {
  displayDialog: Proptypes.bool,
  onCloseModal: Proptypes.func,
};

export default observer(AddBeneficiaryDialog);
