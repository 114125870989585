import React, { useState } from 'react'
import Header1 from '../components/Header1'
import { getF8bExpostSummary } from 'services/initiative'
import Row from 'react-bootstrap/Row'
import { StyledDiv } from '../../CommonStyled/Tabs.styled'
import F8BSummaryTable from '../components/F8BSummaryTable'
import { useTranslation } from 'react-i18next'

const ExPostSummary = () => {
  const { t } = useTranslation();
  
  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState(null);

  const getData = async (params) => {
    setWaiting(true)
    try {
      setAppliedFilters(params)
      const response = await getF8bExpostSummary(params)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  return (
    <StyledDiv>
      <Row>
        <Header1 data={data} waiting={waiting} getData={getData} appliedFilters={appliedFilters} />
      </Row>
      <div>
        <F8BSummaryTable data={data} waiting={waiting} isTab2 />
      </div>
    </StyledDiv>
  )
}

export default ExPostSummary
