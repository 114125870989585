import React from 'react'
import { getProductsByTypeSummarySummaryExportValues } from '../helpers/helpers'

import {
  Col2,
} from '../../CommonStyled/Components.styled'
import { ExportStatisticsByYearsToXLSX } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'

const Header5 = ({ label, data, waiting }) => {
  return (
    <Col2>
      <ExportToXLSXButton
        data={data}
        mapData={getProductsByTypeSummarySummaryExportValues}
        disabled={waiting}
        exportName={label}
      >
        <ExportStatisticsByYearsToXLSX label={label} data={[]} />
      </ExportToXLSXButton>
    </Col2>
  )
}

export default Header5
