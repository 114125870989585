import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  justificationBeneficiaries: Yup.string()
    .min(10, 'F1_S5_validation1')
    .max(2000, 'Maximum_2000')
    .required('F3_S7_validation3'),
  benList: Yup.array()
    .of(
      Yup.object().shape({
        beneficiaryTypeId: Yup.string()
          .typeError('F3_S10_validation1')
          .required('F3_S7_validation3'),
        answersFundS10BenCountryDTOList: Yup.array()
          .of(
            Yup.object().shape({
              countryId: Yup.string().required('F3_S7_validation3'),
              quantity: Yup.number().typeError('F3_S10_validation2').min(1, 'F3_S10_validation3').integer().required('F3_S7_validation3'),
            })
          )
          .required('F3_S7_validation3'),
      })
    )
    .min(1, 'F3_S10_validation4')
    .required('F3_S7_validation3'),
});
