import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formInitialValues } from '../Section18.initialValues'
import DayJS from 'dayjs'
import { PrimaryButton } from 'components/Buttons'
import InviteDialog from './InviteDialog'
import { StyledTitle, StyledEUDLink } from './EUDAssessments.styled'
import { SeparatorDotted } from 'components/CommonStyled'
import EUDAssessmentDialog from './EUDAssessmentDialog'

const EUDAssessments = ({ assessmentList, nonEditable, isIntpa, intpaAssessment }) => {
  const { t } = useTranslation()
  const [displayModalInvite, setDisplayModalInvite] = useState(false)
  const handleOpenModalInvite = () => setDisplayModalInvite(true)
  const handleCloseModalInvite = () => setDisplayModalInvite(false)

  const [displayModalF7A, setDisplayModalF7A] = useState(false)
  const handleOpenModalF7A = () => setDisplayModalF7A(true)
  const handleCloseModalF7A = () => setDisplayModalF7A(false)

  const [currentF7AValues, setCurrentF7AValues] = useState(formInitialValues)

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr)
    return date.format('DD/MM/YYYY')
  }

  const handleClick = (eudAssessment) => {
    setCurrentF7AValues(eudAssessment)
    handleOpenModalF7A()
  }

  const renderEudList = () => {
    return (
      assessmentList &&
      assessmentList.map((eudAssessment) => (
        <div key={eudAssessment.user.email}>
          {`${eudAssessment.user.organization}, ${eudAssessment.user.name} ${eudAssessment.user.lastName} - `}
          {eudAssessment.state === 'DONE' ? (
            <StyledEUDLink
              key={eudAssessment.user.email}
              value={eudAssessment.user.email}
              onClick={() => {
                handleClick({...eudAssessment.assessment, stateTimestamp: eudAssessment.stateTimestamp} )
              }}
            >
              {t('Received_on') +
                ' ' +
                (eudAssessment && eudAssessment.stateTimestamp
                  ? formatDate(eudAssessment.stateTimestamp)
                  : '-')}
            </StyledEUDLink>
          ) : (
            t('Pending')
          )}
        </div>
      ))
    )
  }

  return (
    <>
      {displayModalInvite && (
        <InviteDialog
          displayDialog={displayModalInvite}
          onCloseModal={handleCloseModalInvite}
        />
      )}

      {displayModalF7A && (
        <EUDAssessmentDialog
          values={currentF7AValues}
          displayDialog={displayModalF7A}
          onCloseModal={handleCloseModalF7A}
          isIntpa
        />
      )}

      <StyledTitle>{t('F2_EUDs_assessments')}</StyledTitle>

      <div>
        {renderEudList()}

        {!isIntpa && (
          <>
            {`INTPA - `}
            {intpaAssessment && (intpaAssessment?.state === 'DONE') ? (
              <StyledEUDLink
                onClick={() => {
                  handleClick({...intpaAssessment.assessment, stateTimestamp:  intpaAssessment.stateTimestamp})
                }}
              >
                {t('Received_on') +
                ' ' +
                (intpaAssessment && intpaAssessment.stateTimestamp
                  ? formatDate(intpaAssessment.stateTimestamp)
                  : '-')}
              </StyledEUDLink>
            ) : (
              t('Pending')
            )}
          </>
        )}

        <div style={{ width: '400px' }}>
          <SeparatorDotted />
        </div>

        <div style={{ marginTop: '30px' }}>
          <PrimaryButton disabled={nonEditable} onClick={handleOpenModalInvite}>
            {'+' + ' ' + t('F2_invite_assessors')}
          </PrimaryButton>
        </div>
      </div>
    </>
  )
}

export default EUDAssessments
