export const initialValues = {
  rights: "",
  environment: "",
  link: "",
  continued: "",
  leaps: "",
  projection: "",
  advantage: "",
  consolidation: "",
};
