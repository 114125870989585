import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { formInitialValues } from './Section9.initialValues';
import { useTranslation } from 'react-i18next';

import MoneyTextMask from 'components/MoneyTextMask';
import Table from 'react-bootstrap/Table';
import {
  TableHeader,
  RowBorder,
  BorderlessTr,
  ResourceNameSpan,
  ResourceNumberSpan
} from './Section9.styled';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import { SeparatorDotted } from 'components/CommonStyled';
import Comments from 'components/Forms/Form4/Comments';

const Section9 = () => {
  const { t } = useTranslation();
  const { formStore } = useRootStore();
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [totalBudget, setTotalBudget] = useState({
    eu_budget: 0,
    counterpart_budget: 0,
    total_budget: 0
  });
  
  const handleSaved = (value) => {
    setSaved(value);
  }

  const handleButton = (value) => {
    setNonEditable(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  useEffect(() => {
    const formSectionData = formStore.formSectionData? formStore.formSectionData[0]: formInitialValues;
    if(formSectionData && Array.isArray(formSectionData) && formSectionData.length ){
      const total = formSectionData.pop();
      setFormValues(formSectionData);
      setTotalBudget(total);
      formStore.setCurrentSection(9);
    }
  }, [formStore.formSectionData])
 

  return (
    <FormLayout>
      <FormContainer 
        number={9} 
        title={t('F3_S9_title')}
        formId='form4section9'
      >
        <Table bordered={ false } style={{marginLeft: '30px'}}>
          <thead>
            <BorderlessTr>
              <TableHeader width={"40"}></TableHeader>
              <TableHeader width={"10"}>{t('F3_S7_Resource_column_EU')}</TableHeader>
              <TableHeader width={"10"}>{t('F3_S7_Resource_column_counterpart')}</TableHeader>
              <TableHeader width={"10"}>{t('F3_S7_Resource_column_total')}</TableHeader>
            </BorderlessTr>
          </thead>
          <tbody>
            {formValues.map(resource => (
              <>
                <BorderlessTr>
                  <td colSpan="4">
                    <RowBorder />
                  </td>
                </BorderlessTr>
                <BorderlessTr key={resource.resourceType? t(`resource_type_${resource.resourceType.id}`): ' - '}>
                  <td><ResourceNameSpan>{resource.resourceType? t(`resource_type_${resource.resourceType.id}`): ' - '}</ResourceNameSpan></td>
                  <td><ResourceNumberSpan> <MoneyTextMask value={resource.eu_budget} /> </ResourceNumberSpan></td>
                  <td><ResourceNumberSpan> <MoneyTextMask value={resource.counterpart_budget} /> </ResourceNumberSpan></td>
                  <td><ResourceNumberSpan> <MoneyTextMask value={resource.total_budget} /> </ResourceNumberSpan></td>
                </BorderlessTr>
              </>
            ))}
            <BorderlessTr>
              <td colSpan="4">
                <RowBorder />
              </td>
            </BorderlessTr>
            <BorderlessTr>
              <td> <ResourceNameSpan total={true}>{t('Total TCI budget')}</ResourceNameSpan></td>
              <td> <ResourceNumberSpan weight={600}> <MoneyTextMask value={totalBudget.eu_budget} /> </ResourceNumberSpan></td>
              <td> <ResourceNumberSpan weight={600}> <MoneyTextMask value={totalBudget.counterpart_budget} /> </ResourceNumberSpan></td>
              <td> <ResourceNumberSpan weight={600}> <MoneyTextMask value={totalBudget.total_budget} /> </ResourceNumberSpan></td>
            </BorderlessTr>
          </tbody>
        </Table>

        <SeparatorDotted />

        <Comments 
          onSaved={handleSaved} 
          onButton={handleButton} 
        />

      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section9);
