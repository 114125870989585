import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import {
  CenteredModalButtonContainer,
  FormFielErrorMessage,
} from 'components/CommonStyled'
import { ErrorButton, StyledSendButton } from 'components/Buttons'
import { Formik } from 'formik'
import { Form } from 'react-bootstrap'
import { initialValues } from './DocumentsUpleaderModal.initialValues'
import { validationSchema } from './DocumentsUploaderModal.validations'
import FileUploading from './FileUploader'
import CustomModal from 'components/Modal'
import { getImplementationInitiatives } from 'services/initiative'
import ConfirmCloseModal from 'components/ConfirmCloseModal'
import { saveDocument } from 'services/initiative'

const DocumentsUploaderModal = (props) => {
  const { t } = useTranslation()
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    message: t('Error try again later'),
    type: 'error',
  })
  const [waiting, setWaiting] = useState(false)
  const [initiativesOptions, setInitiativesOptions] = useState([])
  const [missingField, setMissingField] = useState(false)
  const [closeModalShow, setCloseModalShow] = useState(false)

  const handleSubmit = async (values) => {
    if (values.file) {
      setWaiting(true)
      try {
        if (values.initiativeId == 0) {
          values.initiativeId = null
        }
        await saveDocument(values)
        props.onHide()
        window.location.replace('')
      } catch (error) {
        setModalInfo({
          message: t('Error_submit'),
          type: 'error',
          isOpen: true,
        })
        setWaiting(false)
      }
    } else {
      setMissingField(true)
    }
  }

  async function getInitiatives() {
    try {
      const response = await getImplementationInitiatives()
      setInitiativesOptions(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getInitiatives()
  }, [])

  return (
    <>
      <CustomModal
        show={modalInfo.isOpen}
        onHide={() => setModalInfo(false)}
        message={modalInfo.message}
        type="error"
      />
      <ConfirmCloseModal
        show={closeModalShow}
        handleCloseConfirm={() => setCloseModalShow(false)}
        handleActionOnConfirm={() => {
          setCloseModalShow(false)
          props.onHide()
        }}
        text={t('Confirmation close modal')}
      />
      <Modal {...props} size="lg" centered>
        <Modal.Body>
          <h5>{t('Document')}</h5>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
            }) => (
              <div style={{ marginTop: '35px' }}>
                <FileUploading
                  setModalInfo={setModalInfo}
                  params={{ id: 69420, formId: 0, sectionId: 0 }}
                  handleChange={handleChange}
                  values={values}
                  setFieldValue={setFieldValue}
                  nonEditable={false}
                  setMissingField={setMissingField}
                />
                {missingField ? (
                  <FormFielErrorMessage
                    style={{ marginBottom: '30px', marginTop: '-25px' }}
                  >
                    {t('F5_S7_required')}
                  </FormFielErrorMessage>
                ) : null}
                <Form
                  style={{ marginTop: '-15px' }}
                  onSubmit={handleSubmit}
                  id="documents"
                >
                  <Form.Label>{t('Documents_description')}:</Form.Label>
                  <Form.Control
                    name="description"
                    as="textarea"
                    rows={2}
                    style={{ resize: 'none', marginBottom: '20px' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    className={
                      touched.description && errors.description ? 'error' : null
                    }
                    disabled={false}
                  />
                  {touched.description && errors.description ? (
                    <FormFielErrorMessage
                      style={{ marginBottom: '10px', marginTop: '-5px' }}
                    >
                      {errors.description}
                    </FormFielErrorMessage>
                  ) : null}

                  <Form.Label>{t('Documents_initiative')}:</Form.Label>
                  <Form.Control
                    name="initiativeId"
                    as="select"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.initiativeId}
                    disabled={false}
                    style={{ width: '40%', marginBottom: '40px' }}
                  >
                    <option option={0}>{t('Document_general')}</option>
                    {initiativesOptions &&
                      initiativesOptions.map((e) => (
                        <option value={e.id}>
                          {e.code} - {e.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form>
              </div>
            )}
          </Formik>
          <CenteredModalButtonContainer>
            <ErrorButton
              style={{ marginRight: '20px' }}
              onClick={() => setCloseModalShow(true)}
            >
              {t('Button_cancel')}
            </ErrorButton>
            <StyledSendButton type="submit" form="documents" disabled={waiting}>
              {t('Button_save')}
            </StyledSendButton>
          </CenteredModalButtonContainer>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DocumentsUploaderModal
