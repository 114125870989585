import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportPurchasingToXLSX } from 'helpers/xlsxExporter';
import { PrimaryButton } from 'components/Buttons';

const CustomXLSXExportButton = (props) => {
  const { t } = useTranslation();
  const [exporting, setExporting] = useState(false);
  const [exportData, setExportData] = useState(null);
  const [buttonReady, setButtonReady] = useState(false);

  const handleClick = async () => {
    setExporting(true);
    setExportData(props.values);
    setButtonReady(true);
    setExporting(false);
  };

  const onDownloadReady = () => {
    setExportData(null);
    setButtonReady(false);
  };

  return (
    <>
      <ExportPurchasingToXLSX data={exportData} isReady={buttonReady} onReady={onDownloadReady} />
      <PrimaryButton
        onClick={handleClick}
        style={{width: '1200px', marginLeft: '-70px'}}
        disabled={exporting || props.disabled}
      >
        { t('Export_to_XLSX') }
      </PrimaryButton>
    </>
  );
};

export default CustomXLSXExportButton;