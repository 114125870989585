import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { getResourcesFromProposalF5S7, getDownload } from 'services/initiative';
import MoneyTextMask from 'components/MoneyTextMask';
import CustomModal from "components/Modal";

import {StyledTitle, StyledRow, StyledCol, StyledText, StyledDiv, StyledColTitle, StyledButtonLink, StyledDivTotal} from './ResourcesF3Form.styled';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { PrimaryButton } from 'components/Buttons';
import { CenteredModalButtonContainer } from 'components/CommonStyled';


const ResourcesF3Form = ({handleCloseOk, activity}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [formValues, setFormValues] = useState([]);
  const [activityTotal, setActivityTotal] = useState({
    ueBudget: 0,
    counterpartBudget: 0,
    totalCost: 0,
  });
  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
    type: "default",
  });

  const onHideModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  const handleDownload = async (uuid, downloadName) => {
    try {
      const file = await getDownload(uuid)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download", downloadName
        );
        document.body.appendChild(link);
        link.click();
    });
    } catch (error) {
      setModal({
        ...modal,
        message: t('Error_file1'),
        type: "error",
        isOpen: true,
      });
    }
  }

  useEffect(() => {
    async function getValues() {
        let response = await getResourcesFromProposalF5S7(id, activity.id);
        let total = {
          ueBudget: 0,
          counterpartBudget: 0,
          totalCost: 0,
        }

        response?.data?.forEach(({ueBudget, counterpartBudget, totalCost}) => {
          total.ueBudget += ueBudget;
          total.counterpartBudget += counterpartBudget;
          total.totalCost += totalCost;
        })

        setActivityTotal(total);
        setFormValues(response?.data);
    }
    getValues();
  }, []);

  return (
    <>
     {modal.isOpen && <CustomModal
          show={modal.isOpen}
          onHide={onHideModal}
          message={modal.message}
          type={modal.type}
      />}

      {formValues?.length !== 0 && <Row>
        <Col sm={6}>
        </Col>
        <Col sm={6} style={{display:'flex'}}>
          <StyledColTitle sm={4}>
            {t('F3_S7_Resource_column_EU')}
          </StyledColTitle>
          <StyledColTitle sm={4}>
            {t('F3_S7_Resource_column_counterpart')}
          </StyledColTitle>
          <StyledColTitle sm={4}>
            {t('F3_S7_Resource_column_total')}
          </StyledColTitle>
        </Col>
      </Row>}

      {formValues && formValues.map(({resourceType, details}) =>(
        details.map(({ueOrCounterpart, totalMoney, description, file})=> (

         <StyledRow>
            <Col sm={6}>
              <StyledTitle>
                {t(`resource_type_${resourceType.id}`)}
              </StyledTitle>
            </Col>
            <Col sm={6} style={{display:'flex'}}>
              <StyledCol sm={4}>
                <MoneyTextMask value={ueOrCounterpart == 0 ? totalMoney: "-"}/>
              </StyledCol>
              <StyledCol sm={4}>
              <MoneyTextMask value={ueOrCounterpart == 1 ? totalMoney: "-"}/>
              </StyledCol>
              <StyledCol sm={4}>
                <MoneyTextMask value={totalMoney}/>
              </StyledCol>
            </Col>
            <StyledDiv>
              {t('Description')}:
              <StyledText>
                {description? description: "-" }
              </StyledText>
              {file && <StyledButtonLink variant="link" onClick={() => {handleDownload(file.uuid, file.fileName)}}>
                {t('F5_S7_See_quote_proposal')}
              </StyledButtonLink>}
            </StyledDiv>
          </StyledRow>

        ))
      ))}

      {formValues?.length !== 0 &&
        <StyledDivTotal>
            <Col sm={6} style={{fontSize: '18px'}}>
              {t('F3_S7_Resource_total_activity')+" "+activity.name}
            </Col>
            <Col sm={6} style={{display:'flex'}}>
                <StyledCol sm={4}>
                  <MoneyTextMask value={activityTotal.ueBudget} />
                </StyledCol>
                <StyledCol sm={4}>
                  <MoneyTextMask value={activityTotal.counterpartBudget} />
                </StyledCol>
                <StyledCol sm={4}>
                  <MoneyTextMask value={activityTotal.totalCost} />
                </StyledCol>
            </Col>
        </StyledDivTotal>}

      <CenteredModalButtonContainer>
        <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
      </CenteredModalButtonContainer>
    </>
  )
}

export default observer(ResourcesF3Form);