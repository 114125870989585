import styled, {css} from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledFormLabel = styled(Form.Label)`
    text-align: justify;
    line-height: 1.3;
`;

export const StyledDivWrapper = styled.div`
  margin-left: 50px;
`;

export const StyledTitle = styled.h5`
  ${({ theme }) => css`
    color: ${theme.brandColors.blue};
  `}
`;

export const StyledSubtitle = styled.b`
    color: black;
    text-align: justify;
    line-height: 1.3;
`;

export const StyledSubtitle2 = styled.div`
    margin-top: 35px;
    margin-bottom: 15px;
    text-align: justify;
    font-weight: 650;
    font-size: 700;
    line-height: 1.3;
`;
