import React from 'react'
import { useTranslation } from 'react-i18next'
import { Th3, Table, Th1 } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'
import { f8bSummaryRenderRows, f8bSummaryRenderRowsTab2 } from '../helpers/helpers'

const F8BTable = ({ data, waiting, isTab2 = false }) => {
  const { t } = useTranslation()

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : (
    <>
      <Table>
        <colgroup>
          <col span="5" />
          <col span="2" style={{ backgroundColor: '#a6b3bb' }} />
          <col span="2" style={{ backgroundColor: '#a6b3bb' }} />
          <col span="2" style={{ backgroundColor: '#a6b3bb' }} />
        </colgroup>
        <tr>
          <Th3 style={{ width: '50%' }}></Th3>
          <Th1 scope="col">{t('F8b_expost_1')}</Th1>
          <Th1 scope="col">{t('F8b_expost_2')}</Th1>
          <Th1 scope="col">{t('F8b_expost_3')}</Th1>
        </tr>
        {isTab2 ? f8bSummaryRenderRowsTab2(data) : f8bSummaryRenderRows(data)}
      </Table>
    </>
  )
}

export default F8BTable
