import { defaultBudgetConstants, defaultBudgetConstantsOld } from 'constants/budgetConstants';
import { useState, useEffect } from 'react';
//import { getBudgetRestriction } from 'services/initiative';

export const useGetBudgetRestrictions = (useOldValues = false) => {
  const [values, setValues] = useState(defaultBudgetConstants)

  // const fetchAPI = async () => {
  //   try {
  //     const response = await getBudgetRestriction();
  //     setValues(response?.data);
  //   }
  //   catch(e){
  //     setValues(useOldValues ? defaultBudgetConstantsOld : defaultBudgetConstants);
  //     console.log(e);
  //   }
  // }

  useEffect(() => {
    //fetchAPI();
    setValues(useOldValues ? defaultBudgetConstantsOld : defaultBudgetConstants);
  }, [useOldValues])

  return values;
}

