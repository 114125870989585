import React from 'react';

import Form55Section1 from 'components/Forms/Funds/Section1';
import Form55Section2 from 'components/Forms/Funds/Section2';
import Form55Section3 from 'components/Forms/Funds/Section3';
import Form55Section4 from 'components/Forms/Funds/Section4';
import Form55Section5 from 'components/Forms/Funds/Section5';
import Form55Section6 from 'components/Forms/Funds/Section6';
import Form55Section7 from 'components/Forms/Funds/Section7';
import Form55Section8 from 'components/Forms/Funds/Section8';
import Form55Section9 from 'components/Forms/Funds/Section9';
import Form55Section10 from 'components/Forms/Funds/Section10';
import Form55Section11 from 'components/Forms/Funds/Section11';
import Form55Section12 from 'components/Forms/Funds/Section12';

export const form55Dictionary = {
  1: <Form55Section1 />,
  2: <Form55Section2 />,
  3: <Form55Section3 />,
  4: <Form55Section4 />,
  5: <Form55Section5 />,
  6: <Form55Section6 />,
  7: <Form55Section7 />,
  8: <Form55Section8 />,
  9: <Form55Section9 />,
  10: <Form55Section10 />,
  11: <Form55Section11 />,
  12: <Form55Section12 />
}