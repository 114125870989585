import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Proptypes from 'prop-types';
import { getIn, FieldArray } from 'formik';
import Modal from 'react-bootstrap/Modal';
import { 
  ModalFooter, 
  FooterLeft, 
  FooterCenter, 
  FooterRight, 
  RoundedRemoveDiv, 
  RoundedAddDiv,
  StyledButtonLink,
  DivButtonWrapper } from './AddBeneficiaryDialog.styled';
import { PrimaryButton } from 'components/Buttons';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import { SeparatorDotted } from 'components/CommonStyled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const countryObject = {
  countryId: "4",
  quantity: 0,
};

const CountryRow = ({ formikInstance, rowIndex, beneficiaryIndex, removeCountry, countries, nonEditable }) => {

  const { t } = useTranslation();
  const { getFieldProps, touched, errors } = formikInstance;
  const fieldPath = (field) => `benList.${beneficiaryIndex}.answersF3S10BenCountryList.${rowIndex}.${field}`;

  const fieldInfo = (formikInstance, field) => {
    const getFieldError = getIn(formikInstance.errors, field);
    const getFieldTouched = getIn(formikInstance.touched, field);
    return {
      touched: getFieldTouched,
      error: (
        <FormErrorMessage
          touched={getFieldTouched}
          errors={getFieldError}
        />
      )
    }
  }
  
  return (
    <Form.Group as={Row}>
      <Col xs={7}>
        <Form.Control 
          name={fieldPath('countryId')}
          as="select"
          { ...getFieldProps(fieldPath('countryId')) }
          disabled={nonEditable}
          className={touched[fieldPath('countryId')] && errors[fieldPath('countryId')] ? "error" : null}
        >
          {countries.map(country => (
              <option key={country.id} value={country.id}>{t(country.name)}</option>
          ))}
        </Form.Control>
        {fieldInfo(formikInstance, fieldPath('countryId')).error}
      </Col>
      <Col xs={3}>
        <Form.Control
          type="number"
          min="0"
          name={fieldPath('quantity')}
          { ...getFieldProps(fieldPath('quantity')) }
          disabled={nonEditable}
          className={touched[fieldPath('quantity')] && errors[fieldPath('quantity')] ? "error" : null}
        />
        {fieldInfo(formikInstance, fieldPath('quantity')).error}
      </Col>
      <Col xs={1}>
        <StyledButtonLink type="button" onClick={() => {removeCountry(rowIndex)}} disabled={nonEditable}> <RoundedRemoveDiv>x</RoundedRemoveDiv> </StyledButtonLink>
      </Col>
    </Form.Group>
  )
}

const AddBeneficiaryDialog = ({ formikInstance, beneficiaryIndex, countries, nonEditable, displayDialog, onCloseModal }) => {
  const { values } = formikInstance;
  const fieldPath = (field) => `benList.${beneficiaryIndex}.${field}`;
  const {t} = useTranslation();

  return (
    <Modal show={displayDialog} onHide={onCloseModal} size="md" animation={false}>
      <Modal.Body>
        <FieldArray 
          name={fieldPath('answersF3S10BenCountryList')}
          render={arrayHelpers => (
            <>
              <ListGroup>
                {values.benList[beneficiaryIndex].answersF3S10BenCountryList
                  ? ( values.benList[beneficiaryIndex].answersF3S10BenCountryList.map((country, key) => (
                      <ListGroup.Item key={key+1}>
                        <CountryRow formikInstance={formikInstance} rowIndex={key} beneficiaryIndex={beneficiaryIndex} removeCountry={arrayHelpers.remove} countries={countries} nonEditable={nonEditable}/>  
                      </ListGroup.Item>
                    )))
                  : null}
              </ListGroup>

              <SeparatorDotted />
              <StyledButtonLink type="button" onClick={() => arrayHelpers.push(countryObject)} disabled={nonEditable}> <DivButtonWrapper> <RoundedAddDiv>+</RoundedAddDiv>{t('Add_country')}</DivButtonWrapper> </StyledButtonLink>
            </>
          )}
        />
      </Modal.Body>
      <ModalFooter>
        <FooterLeft />
        <FooterCenter>
          <PrimaryButton onClick={onCloseModal}>OK</PrimaryButton>
        </FooterCenter>
        <FooterRight />
      </ModalFooter>
    </Modal>
  )
}

AddBeneficiaryDialog.propTypes = {
  displayDialog: Proptypes.bool,
  onCloseModal: Proptypes.func,
};

export default observer(AddBeneficiaryDialog);
