import styled, {css} from 'styled-components';

export const StyledTitle = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    margin-bottom: 10px;
    color: ${theme.brandColors.blue};
    font-weight: 600;
    font-size: 1.7rem;
  `}
`;

export const StyledEUDLink = styled.div`
  display: inline;
  text-decoration: underline;
  color: blue;
  font-weight: 550;
  cursor: pointer;
`;