import React from 'react'
import PageTitle from 'components/PageTitle'
import { PageHeader } from '../CommonStyled/Tabs.styled'
import { useTranslation } from 'react-i18next'

import Formulationsummary from './tabs/FormulationSummary'
import StatisticsTabs from 'components/Tabs'

const StatsFormulation = () => {
  const { t } = useTranslation()
  const titles = [
    t('Statistics_formulation_tab2'),
  ]
  const renderTab2Content = () => <Formulationsummary />

  const contents = [renderTab2Content]

  return (
    <>
      <PageHeader>
        <PageTitle title={t('menu_admin_6_1_2')} />
      </PageHeader>
      <StatisticsTabs titles={titles} contents={contents} />
    </>
  )
}

export default StatsFormulation
