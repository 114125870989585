import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledNumberCircle, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  StyledFormLabel,
  StyledIntroParagraph,
  StyledParagraph,
  TextValueLeftSpaced,
} from 'components/Forms/Form2/Section8/Section8.styled';
import MediaForm from 'components/Forms/Form2/Section8/MediaForm';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import TextValue from 'components/TextValue';
import FakeAccordionItem from '../../FakeAccordionItem';
import { SeparatorDotted } from 'components/CommonStyled';

const Section7 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledNumberCircle> 8 </StyledNumberCircle>
          <StyledTitle> {t('F1_S8_title')} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <StyledIntroParagraph>
          {t('F1_S8_intro')}
        </StyledIntroParagraph>

        <Form.Group>
          <Form.Label as={Row} style={{marginBottom: '20px', marginLeft: '50px' }}>{t('F1_S8_Objetive')}: </Form.Label>
          <TextValueLeftSpaced>
            <TextValue value={formValues?.objective} />
          </TextValueLeftSpaced>
        </Form.Group> 

        <StyledParagraph style={{textAlign: 'justify', lineHeight: '1.3', marginLeft: '50px' }}>
          {t('F1_S8_Subtitle_communication_tools')}
        </StyledParagraph>

        {(formValues?.campaigns && formValues?.campaigns.map((comtool, key) => (
          <React.Fragment key={key}>
            <FakeAccordionItem title={t('F1_S8_Accordion')+" "+(key+1)}>
              <MediaForm data={comtool} /> 
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}
      </StyledBody>
    </SectionContainer>
  )
}


export default Section7;