import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
	participantId: Yup.string(),
  description: Yup.string()
    .min(2,  'F3_S7_validation4')
    .max(1300, 'Error max length 1300')
    .required("F3_S7_validation3"),
  amountPayment: Yup.number()
    .required('F3_S7_validation3'),
  comments: Yup.string()
    .min(2,  'F3_S7_validation4')
    .max(1300, 'Error max length 1300')
    .nullable(true),
  paymentEnabled: Yup.boolean(),
  paid: Yup.boolean(),
});