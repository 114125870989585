import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

export const StyledUploadButton = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 4px;
  margin-right: 10px;
`;