import styled, { css } from 'styled-components';
import Button from 'react-bootstrap/Button';

export const StepTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.brandColors.gray1};
    font-weight: 500;
    text-decoration: underline;
  `}
`;

export const ItemSeparator = styled.div`
  margin-top: 30px;
`;

export const ShortDescription = styled.div`
  width: 300px;
  overflow:hidden; 
  white-space:nowrap; 
  text-overflow: ellipsis;
`;

export const StyledButtonLink = styled(Button)`
  border: none;
  background: none;
  box-shadow: none !important;
  &:hover, &:active, &:focus{
    border: none !important;
    background: none !important;
    text-decoration: none !important;
  }
`;

export const RoundedRemoveDiv = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.brandColors.red};
    color: white;
    width: 15px;
    height: 15px;
    line-height: 0.8;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
  `}
`;

export const OpenItemButton = styled(Button)`
  padding: 0;
  margin: 0;
  margin-top: -1px;
  cursor: 'pointer';
  font-weight: 600;
  text-decoration: underline;
`;