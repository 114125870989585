import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import { Th1, Table, Th1Pointer } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'

import { renderRows } from '../helpers/helpers'
import { orderDataByField } from '../helpers/helpers'

const Table1 = ({ data, waiting, setData }) => {
  const { t } = useTranslation()
  const [ascendingOrder, setAscendingOrder] = useState({
    code: true,
    name: true,
    region: true,
    coordRole: true,
  })

  const handleOrderData = (field) => {
    orderDataByField(data, field, setData, setAscendingOrder, ascendingOrder)
  }

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data && !isEmpty(data) ? (
    <Table style={{ fontSize: '14px' }}>
      <tr>
        <Th1Pointer width="8%" onClick={() => handleOrderData('code')}>
          {t('Initiative_code')} &#8645;
        </Th1Pointer>
        <Th1Pointer onClick={() => handleOrderData('name')}>
          {t('Title_initiative')} &#8645;
        </Th1Pointer>
        <Th1Pointer width="8%" onClick={() => handleOrderData('region')}>
          {t('Country_coordinating_entity')} &#8645;
        </Th1Pointer>
        <Th1Pointer width="8%" onClick={() => handleOrderData('coordRole')}>
          {t('Role_coordinating_entity')} &#8645;
        </Th1Pointer>
        <Th1 width="8%">{t('Participating_entities')}</Th1>
        <Th1 width="8%">{t('Beneficiary_entities')}</Th1>
        <Th1 width="8%">{t('First_provider_entities')}</Th1>
        <Th1 width="8%">{t('Second_provider_entities')}</Th1>
        <Th1 width="8%">{t('Countries_participating_entities')}</Th1>
        <Th1 width="8%">{t('Collaborating_entities')}</Th1>
      </tr>
      {renderRows(data)}
    </Table>
  ) : (
    t('No_data_available_2')
  )
}

export default Table1
