import React from 'react'
import PageTitle from 'components/PageTitle'
import { PageHeader } from '../CommonStyled/Tabs.styled'
import { useTranslation } from 'react-i18next'
import CountrySummary from './tabs/CountrySummary'
import CountryReport from './tabs/CountryReport'
import StatisticsTabs from 'components/Tabs'

const StatsCountry = () => {
  const { t } = useTranslation()
  const titles = [t('Statistics_execution_tab1'), t('country_initiative_report_tab_name')]
  const renderTab1Content = () => <CountrySummary />
  const renderTab2Content = () => <CountryReport />


  const contents = [
    renderTab1Content,
    renderTab2Content
  ]

  return (
    <>
      <PageHeader>
        <PageTitle title={t('menu_admin_6_8.1')} />
      </PageHeader>
      <StatisticsTabs titles={titles} contents={contents} />
    </>
  )
}

export default StatsCountry
