import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { title } from 'helpers/commentsHelpers';
import { formatDateWithHour } from 'helpers/dateHelpers';

import { SectionContainer, StyledTitleHead, TitleContainer, StyledTitle, StyledBody } from '../CommonStyles';
import { MessagesSectionStyles } from 'components/CommonStyled';

const Section15 = ({ data }) => {
  const { t } = useTranslation();
  const [comments, setComments] = useState([]);
  const { StyledComment, StyledResponse, StyledUserResponse, StyledUserComment, StyledDate } = MessagesSectionStyles;
  
  const handleComments = list => {
    if (list.length > 0) {
      setComments(list);
    }
  };

  useEffect(() => {
    if (data) {
      handleComments(data);
    }
  }, [data]);

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`15 - ${t('Button_comments_and_status')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        {comments &&
          comments.map(({ id, role, user, comment, responses, creation_date }) => (
            <>
              {role && user && creation_date && (
                <>
                  <StyledComment key={id}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                      <StyledUserComment>{`${title(role, user)} - ${user.name} ${user.lastName}`}</StyledUserComment>
                      <StyledDate>{formatDateWithHour(creation_date)}</StyledDate>
                    </div>
                    {comment}
                  </StyledComment>

                  {responses &&
                    responses.map(({ role, user, comment, creation_date }) => (
                      <StyledResponse>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                          <StyledUserResponse>
                            {`${title(role, user)} - ${user.name} ${user.lastName}`}
                          </StyledUserResponse>
                          <StyledDate>{formatDateWithHour(creation_date)}</StyledDate>
                        </div>
                        {comment}
                      </StyledResponse>
                    ))}
                </>
              )}
            </>
          ))}
      </StyledBody>
    </SectionContainer>
  );
};

export default Section15;
