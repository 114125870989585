import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { RequiredMark } from "components/CommonStyled";
import { getIn } from "formik";
import { useTranslation } from "react-i18next";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  const getFieldTouched = getIn(formikInstance.touched, field);
  return {
    touched: getFieldTouched,
    error: (
      <FormErrorMessage
        touched={getFieldTouched}
        errors={getFieldError}
      />
    )
  };
};

const TargetForm = ({
  formikInstance,
  targetListName,
  targetIndex,
  goalId,
  developmentGoalTargets,
  targetIndicatorsList,
  nonEditable,
  setSaved,
}) => {
  const { t } = useTranslation();
  const {
    getFieldProps,
    touched,
    errors,
    values,
    setFieldValue,
  } = formikInstance;
  const fieldPath = (field) => `${targetListName}.${targetIndex}.${field}`;

  const currentTargetId = values[targetListName][targetIndex].targetGoalId;

  const [devIndicatorsFiltered, setDevIndicatorsFiltered] = useState([]);

  const handleTargetGoalChange = (selectedId) => {
    const filteredArray = targetIndicatorsList.filter(
      (e) => e.developmentGoalTarget.id == selectedId
    );
    const newTargetIndicatorList = [];
    filteredArray.forEach((indicator) => {
      newTargetIndicatorList.push({
        targetGoalIndicatorId: indicator.id,
        id: indicator.id,
        name: indicator.name,
        selectedIndicator: false,
      });
    });
    setDevIndicatorsFiltered(newTargetIndicatorList);
    setFieldValue(fieldPath("targetIndicatorList"), newTargetIndicatorList);
  };

  const updateList = () => {
    if (values) {
      if (!values[targetListName][targetIndex].targetIndicatorList) {
        const devGoalTargetId =
          values[targetListName][targetIndex].targetGoalId;
        const filteredArray = targetIndicatorsList.filter(
          (e) => e.developmentGoalTarget.id == devGoalTargetId
        );
        const newTargetIndicatorList = [];
        filteredArray.forEach((indicator) => {
          newTargetIndicatorList.push({
            id: indicator.id,
            targetGoalIndicatorId: indicator.id,
            name: indicator.name,
            selectedIndicator: false,
          });
        });
        setDevIndicatorsFiltered(newTargetIndicatorList);
        setFieldValue(fieldPath("targetIndicatorList"), newTargetIndicatorList);
      } else {
        const devGoalTargetId = values[targetListName][targetIndex].targetGoal
          ? values[targetListName][targetIndex].targetGoal.id
          : "";
        const currentTargetIndicatorList =
          values[targetListName][targetIndex].targetIndicatorList;
        setFieldValue(fieldPath("targetGoalId"), devGoalTargetId);
        currentTargetIndicatorList.forEach((indicator) => {
          if (indicator.targetGoalIndicator) {
            indicator.targetGoalIndicatorId = indicator.targetGoalIndicator.id;
            indicator.name = indicator.targetGoalIndicator.name;
          }
        });
        setDevIndicatorsFiltered(currentTargetIndicatorList);
        setFieldValue(
          fieldPath("targetIndicatorList"),
          currentTargetIndicatorList
        );
      }
    }
  };

  useEffect(() => {
    updateList();
  }, [targetIndicatorsList]);

  return (
    <>
      <Form.Group as={Row}>
        <Col>
          <Form.Control
            name={fieldPath("targetGoalId")}
            as="select"
            size="sm"
            {...getFieldProps(fieldPath("targetGoalId"))}
            onChange={(e) => {
              setFieldValue(fieldPath("targetGoalId"), e.target.value);
              handleTargetGoalChange(e.target.value);
            }}
            className={
              touched[fieldPath("targetGoalId")] &&
              errors[fieldPath("targetGoalId")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          >
            <option value="">{t("Placeholder_select")}</option>
            {developmentGoalTargets.map((targetGoal) => (
              <option key={targetGoal.id} value={targetGoal.id}>
                {t(`SDG_Target${targetGoal.id}`)}
              </option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath("targetGoalId")).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <Form.Label xs={5} lg={4} style={{ marginTop: "10px" }}>
            {t("F3_S11_Justification_target")}
            <RequiredMark>*</RequiredMark>:
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            style={{ resize: "none", marginRight: "18px" }}
            placeholder={t("Maximum_650")}
            name={fieldPath("targetJustification")}
            {...getFieldProps(fieldPath("targetJustification"))}
            className={
              touched[fieldPath("targetJustification")] &&
              errors[fieldPath("targetJustification")]
                ? "error"
                : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath("targetJustification")).error}
        </Col>
      </Form.Group>
      
      {(currentTargetId !== "" && goalId !== "" && devIndicatorsFiltered.length > 0) && (
        <Form.Group as={Row}>
          <Col xs={4} lg={3} className="align-self-start">
            <Form.Label style={{ marginTop: "10px" }}>
              {t("F3_S11_Indicators")}
              <RequiredMark>*</RequiredMark>:
            </Form.Label>
          </Col>
          <Col xs={10} lg={9} className="align-self-end">
            {devIndicatorsFiltered.map((indicator) => (
              <Form.Check
                style={{ marginBottom: "20px" }}
                key={indicator.id}
                type="checkbox"
                label={t(`SDG_Indicator${indicator.targetGoalIndicatorId}`)}
                checked={indicator.selectedIndicator}
                onChange={() => {
                  const newArr =
                    values[targetListName][targetIndex].targetIndicatorList;
                  const elementPos = newArr
                    .map((e) => e.id)
                    .indexOf(indicator.id);
                  newArr[elementPos].selectedIndicator = !newArr[elementPos]
                    .selectedIndicator;
                  setFieldValue(fieldPath("targetIndicatorList"), newArr);
                  setSaved(false);
                }}
              />
            ))}
            {fieldInfo(formikInstance, fieldPath("targetIndicatorList")).error}
          </Col>
        </Form.Group>
      )}
    </>
  );
};

export default TargetForm;
