import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import isEmpty from 'lodash/isEmpty';

import { useRootStore } from 'stores';
import FormLayout from '../../FormLayout';
import FormContainer from 'components/FormContainer';
import CoapplicantForm from './CoapplicantForm';

import { formInitialValues } from './Section3.initialValues';
import {
  StyledIntroParagraph,
} from './Section3.styled';
import { arrayOrderHelper } from 'helpers/arrayOrderHelper';

import { useTranslation } from 'react-i18next';

const Section3 = () => {

  const { formStore } = useRootStore();
  const {t} = useTranslation();
  const [formValues, setFormValues] = useState(formInitialValues);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = [...formStore.formSectionData];
      setFormValues(arrayOrderHelper(formSectionData));
    }
  }, [formStore.formSectionData]);

  return (
    <FormLayout>
      <FormContainer
        number={3}
        title={t('F1_S3_title')}
        formId="form3section3"
        isHelp
      >
        <StyledIntroParagraph>
          {t('F3_S3_Intro')}
        </StyledIntroParagraph>

        <Accordion style={{paddingLeft: '35px'}}>
          {(formValues && formValues.map((coapplicant, key) => (
            <AccordionItem key={key+1}>
              <AccordionItemHeader>
                <AccordionTitle eventKey={key+1}>{`${t('F1_S3_Accordion')} ${key + 1} ${
                  isEmpty(coapplicant.entityName) ? '' : `- ${coapplicant.entityName}`
                }`}</AccordionTitle>
              </AccordionItemHeader>
              <Accordion.Collapse eventKey={key+1}>
                <AccordionItemBody>
                  <CoapplicantForm coapplicantIndex={key} data={coapplicant} />
                </AccordionItemBody>
              </Accordion.Collapse>
            </AccordionItem>
          ))
          )}
        </Accordion>

      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section3);