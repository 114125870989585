import React, { useState, useEffect } from 'react'
import Header5 from '../components/Header5'
import Table5 from '../components/Table5'
import PaginationController from 'components/PaginationController'
import {
  StyledDiv,
  StyledRow,
  StyledRow3,
} from '../../CommonStyled/Tabs.styled'
import { useTranslation } from 'react-i18next'
import { getEntitiesDetail } from 'services/initiative'
import _chunk from 'lodash/chunk'
import { getParamObjectDetail } from '../helpers/helpers'

const InitiativesDetail = () => {
  const { t } = useTranslation()
  const [paginatedData, setPaginatedData] = useState([])
  const [flatData, setFlatData] = useState(null)
  const [currentPageData, setCurrentPageData] = useState([])
  const [waiting, setWaiting] = useState(false)

  const getData = async (param) => {
    setWaiting(true)
    try {
      const response = await getEntitiesDetail(param)
      setPaginatedData(_chunk(response, 100))
      setFlatData(response)
      setCurrentPageData(_chunk(response, 100)[0])
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  useEffect(() => {
    getData(getParamObjectDetail(true, true, true, 0, null, null, 0, null))
  }, [])

  return (
    <StyledDiv>
      <Header5 data={flatData} getData={getData} waiting={waiting} />
      {flatData?.length ? (
        <StyledRow3>
          <b>{`${t('total_entities')}: ${flatData?.length}`}</b>
        </StyledRow3>
      ) : null}
      <StyledRow>
        <Table5
          data={flatData ? currentPageData : null}
          waiting={waiting}
          setData={setCurrentPageData}
        />
      </StyledRow>
      <StyledRow>
        <PaginationController
          visibility={flatData ? (waiting ? false : true) : false}
          totalPages={paginatedData.length}
          onPageChange={(pageNumber) =>
            setCurrentPageData(paginatedData[pageNumber])
          }
        />
      </StyledRow>
    </StyledDiv>
  )
}

export default InitiativesDetail
