import React, { useState, useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from "formik";
import Form from 'react-bootstrap/Form';
import * as Yup from "yup";
import { useRootStore } from 'stores';
import { roleNamesConst, f5statusNamesConst } from 'constants/appConstants';
import { getF6SectionComments, saveSectionComments } from 'services/initiative';
import { StyledSendButton, StyledButtonLink } from 'components/Buttons';
import ModalComments from './ModalComments';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
    StyledTitle,
    StyledSeparator,
} from './Comments.styled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const Comments = (props) => {
    
    const {t} = useTranslation();
    const { id, sectionId } = useParams();
    const [saved, setSaved] = useState(false);
    const { initiativeStore, authStore } = useRootStore();
    const [disabledAtke, setDisabledAtke] = useState(false);
    const [disabledIntpa_Eud, setDisabledIntpa_Eud] = useState(false);
    const [showLinks, setShowLinks] = useState(false);
    const [values, setValues] = useState({
        intpaComment: null,
        atComment: null,
        eudComments: null
    });
    const [noEudComments, setNoEudComments] = useState(true);

    const currentRole = authStore.roles[0];
    const isIntpa = currentRole === roleNamesConst.INTPA;
    const isEud = currentRole === roleNamesConst.EUD;
    const isAtke = currentRole === roleNamesConst.ATKE;
    const [modalComments, setModalComments] = useState({show: false, data: ""});

    useEffect(() => {
        if(saved)
          window.scrollTo(0, 0);
    }, [saved]);

    useEffect(() => {
        getValues();
    }, []);

    useEffect(() => {
        setShowLinks(initiativeStore.assessmentStatus === f5statusNamesConst.SENT);
        setDisabledIntpa_Eud(!(initiativeStore.assessmentStatus === "PENDING" || initiativeStore.assessmentStatus === "ONGOING"));
        setDisabledAtke((initiativeStore.formStateName === f5statusNamesConst.FINALIZED) || (initiativeStore.formStateName === f5statusNamesConst.CANCELLED) || (initiativeStore.formStateName === f5statusNamesConst.ASSESSMENT));
        if(isAtke) 
            props.onButton((initiativeStore.formStateName === f5statusNamesConst.FINALIZED) || (initiativeStore.formStateName === f5statusNamesConst.CANCELLED) || (initiativeStore.formStateName === f5statusNamesConst.ASSESSMENT));
        else 
            props.onButton(!(initiativeStore.assessmentStatus === "PENDING" || initiativeStore.assessmentStatus === "ONGOING"));  
    }, [initiativeStore.assessmentStatus, initiativeStore.formStateName]);

    async function getValues() {
        try {
          const response = await getF6SectionComments(id, sectionId);
          setValues(response.data);
          setNoEudComments(isAllCommentsNull(response.data.eudComments));
        } catch (error) {
          console.log(error)
        }
    }

    const handleSubmit = async (values, {resetForm}) => {
        try {
          await saveSectionComments(id, sectionId, values, isAtke? 'F6': 'F7');
          resetForm({values});
          setSaved(true);
          props.onSaved(true);
        } catch (error) {
          console.log(error);
        }
    };

    const isAllCommentsNull = (comments) => {  
       for (let i=0; i<= comments.length; i++){
           if (comments[i].comment !== null)
                return false; 
       }
       return true;
    }

    return (
        <>

        <ModalComments 
            show={modalComments.show}
            onHide={() => setModalComments({show: false, data: modalComments.data})}
            data={modalComments.data}
            sectionId={sectionId}
        />

        <StyledSeparator/>

        <StyledTitle>{t('F6_subtitle_reporting')}</StyledTitle>

        {(isAtke && showLinks) && 
            <Col style={{marginBottom: '20px'}}>
                <Row style={{marginBottom: '10px'}}>
                    <StyledButtonLink variant="link" style={{fontWeight: '500'}} onClick={() => {setModalComments({show: true, data: {rol: 0, value: values.intpaComment}})}} disabled={values.intpaComment === null}>
                        {`${t('F6_intpa_comments')} ${values.intpaComment === null ? `- [${t('F7_no_comments')}]`: ""}`}     
                    </StyledButtonLink>
                </Row>
                <Row>
                    <StyledButtonLink variant="link" style={{fontWeight: '500'}} onClick={() => {setModalComments({show: true, data: {rol: 1, value: values.eudComments}})}} disabled={noEudComments}>
                        {`${t('F6_eu_assessors_comments')} ${noEudComments ? `- [${t('F7_no_comments')}]`: ""}`}     
                    </StyledButtonLink>
                </Row>
            </Col>
        }

        {isIntpa && 
            <Col style={{marginBottom: '20px'}}>
                <Row style={{marginBottom: '10px'}}>
                    <StyledButtonLink variant="link" style={{fontWeight: '500'}} onClick={() => {setModalComments({show: true, data: {rol: 2, value: values.atComment}})}} disabled={values.atComment === null}>
                        {`${t('F6_adelante_team_comments')} ${values.atComment === null ? `- [${t('F7_no_comments')}]`: ""}`}
                    </StyledButtonLink>
                </Row>
                <Row>
                    <StyledButtonLink variant="link" style={{fontWeight: '500'}} onClick={() => {setModalComments({show: true, data: {rol: 1, value: values.eudComments}})}} disabled={noEudComments}>
                        {`${t('F6_eu_assessors_comments')} ${noEudComments ? `- [${t('F7_no_comments')}]`: ""}`}     
                    </StyledButtonLink>
                </Row>
            </Col>
        }

        {isEud && 
            <Row style={{marginBottom: '10px', marginLeft: '0px'}}>
                <StyledButtonLink variant="link" style={{fontWeight: '500'}} onClick={() => {setModalComments({show: true, data: {rol: 2, value: values.atComment}})}} disabled={values.atComment === null}>
                    {`${t('F6_adelante_team_comments')} ${values.atComment === null ? `- [${t('F7_no_comments')}]`: ""}`}
                </StyledButtonLink>
            </Row>    
        }

        <Formik 
            enableReinitialize
            initialValues={{comment: isAtke? values.atComment : isIntpa ? values.intpaComment : values.eudComments ? values.eudComments[0].comment : ""}}
            validationSchema={Yup.object().shape({
                comment: Yup.string()
                  .min(2, t('F1_S4_validation1'))
                  .max(6500, t('F2_S9_validation1')),
            })}
            onSubmit={handleSubmit}
            >
            {( {values,
                errors,
                touched,
                dirty,
                handleChange,
                handleSubmit,
            }) => (
           
                <form onSubmit={handleSubmit} id={`form5section${sectionId}Mgm`} >

                    {dirty && (setSaved(false), props.onSaved(false))}

                    <Form.Group style={{marginBottom: '30px'}}>
                        <Form.Label>{t('F6_my_comments')}:</Form.Label>
                        <Form.Control
                            name="comment"
                            as="textarea" 
                            rows={8} 
                            disabled={isAtke? disabledAtke: disabledIntpa_Eud}
                            onChange={handleChange}
                            value={values.comment}
                            style={{resize: 'none'}}
                            placeholder= {t('Maximum_6500')}
                        />
                        <FormErrorMessage
                            touched={touched.comment}
                            errors={errors.comment}
                        />
                    </Form.Group> 
                   
                   {((isAtke && !disabledAtke) || ((isIntpa || isEud) && !disabledIntpa_Eud)) && <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <StyledSendButton type="submit" disabled={saved}>{saved? t('Button_saved'): t('Button_save')}</StyledSendButton>
                    </div>}
                </form>
            )}
            </Formik> 

        </>
    )
}

export default observer(Comments);