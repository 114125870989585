import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { renderCountriesOptions } from 'helpers/renderCountriesHelper'
import { getEntityTypes, getCountries } from 'services/initiative'

import { Form } from 'react-bootstrap'
import { PrimaryButton } from 'components/Buttons'
import {
  Col1,
  Col2,
  FullRow,
  StyledFormGroup2,
  StyledFormLabel,
} from '../../CommonStyled/Components.styled'
import Row from 'react-bootstrap/Row'

import {
  renderFunctionOptions,
  renderYearOptions,
  renderRoleOptions,
  renderRegionOptions,
  renderEntityTypeOptions,
  renderTypeOptions,
} from '../../CommonHelpers/helpers'
import {
  getParamObjectDetail,
  getEntitiesDetailExportValues,
  isIncludeCoordinatorFilter,
  isIncludePartnerFilter,
  isIncludeCollaboratorFilter,
} from '../helpers/helpers'
import { TARGET_YEARS } from 'constants/appConstants'
import { ExportStatisticsEntitiesDetailToXLSX } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'

const Header5 = ({ data, getData, waiting }) => {
  const { t } = useTranslation()

  //filter options
  const [countriesOptions, setCountriesOptions] = useState([])
  const [entityTypesOptions, setEntityTypesOptions] = useState([])
  //filter values
  const [function1Filter, setFunction1Filter] = useState(0)
  const [entityRoleFilter, setEntityRoleFilter] = useState(0)
  const [typeIdFilter, setTypeIdFilter] = useState(0)
  const [countryIdFilter, setCountryIdFilter] = useState(0)
  const [regionFilter, setRegionFilter] = useState(0)
  const [yearFilter, setYearFilter] = useState(0)
  const [typeFilter, setTypeFilter] = useState('*')

  useEffect(() => {
    getCountriesOptions()
    getEntityTypesOptions()
  }, [])

  const handleFilter = () => {
    getData(
      getParamObjectDetail(
        isIncludeCoordinatorFilter(function1Filter),
        isIncludePartnerFilter(function1Filter),
        isIncludeCollaboratorFilter(function1Filter),
        entityRoleFilter,
        typeIdFilter,
        countryIdFilter,
        regionFilter,
        yearFilter,
        typeFilter
      ),
    )
  }

  const getCountriesOptions = async () => {
    try {
      const response = await getCountries()
      setCountriesOptions(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getEntityTypesOptions = async () => {
    try {
      const response = await getEntityTypes()
      setEntityTypesOptions(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <FullRow>
        <StyledFormGroup2>
          <StyledFormLabel>{t('F1_S2_Role')}</StyledFormLabel>
          <Form.Control
            name="role"
            as="select"
            onChange={(e) => {
              setEntityRoleFilter(e.target.value)
            }}
            value={entityRoleFilter}
          >
            {renderRoleOptions()}
          </Form.Control>
        </StyledFormGroup2>

        <StyledFormGroup2 style={{ marginRight: '0px' }}>
          <StyledFormLabel>{t('F1_S2_Country')}</StyledFormLabel>
          <Form.Control
            name="countryId"
            as="select"
            onChange={(e) => {
              setCountryIdFilter(e.target.value)
            }}
            value={countryIdFilter}
          >
            {renderCountriesOptions(countriesOptions)}
          </Form.Control>
        </StyledFormGroup2>

        <StyledFormGroup2>
          <StyledFormLabel>{t('F1_S2_Type_of_entity')}</StyledFormLabel>
          <Form.Control
            name="entityType"
            as="select"
            onChange={(e) => {
              setTypeIdFilter(e.target.value)
            }}
            value={typeIdFilter}
          >
            {renderEntityTypeOptions(entityTypesOptions)}
          </Form.Control>
        </StyledFormGroup2>

        <StyledFormGroup2>
          <StyledFormLabel>{t('Function_1')}</StyledFormLabel>
          <Form.Control
            name="function_1"
            as="select"
            onChange={(e) => {
              setFunction1Filter(e.target.value)
            }}
            value={function1Filter}
          >
            {renderFunctionOptions()}
          </Form.Control>
        </StyledFormGroup2>
      </FullRow>
      <Row>
        <Col1 xs={10}>
          <StyledFormGroup2>
            <StyledFormLabel>{t('Region')}</StyledFormLabel>
            <Form.Control
              name="region"
              as="select"
              onChange={(e) => {
                setRegionFilter(e.target.value)
              }}
              value={regionFilter}
            >
              {renderRegionOptions()}
            </Form.Control>
          </StyledFormGroup2>

          <StyledFormGroup2>
            <StyledFormLabel>{t('Dashboard_Year')}</StyledFormLabel>
            <Form.Control
              name="year"
              as="select"
              onChange={(e) => {
                setYearFilter(e.target.value)
              }}
              value={yearFilter}
            >
              {renderYearOptions(TARGET_YEARS)}
            </Form.Control>
          </StyledFormGroup2>

          <StyledFormGroup2>
            <StyledFormLabel>{t('type_filter_stat')}</StyledFormLabel>
            <Form.Control
              name="type"
              as="select"
              onChange={(e) => {
                setTypeFilter(e.target.value)
              }}
              value={typeFilter}
            >
              {renderTypeOptions()}
            </Form.Control>
          </StyledFormGroup2>

          <PrimaryButton
            onClick={() => {
              handleFilter()
            }}
            disabled={waiting}
          >
            {t('Button_search')}
          </PrimaryButton>
        </Col1>
        <Col2>
          <ExportToXLSXButton
            data={data}
            mapData={getEntitiesDetailExportValues}
            disabled={waiting}
            exportName={t('Statistics_entities_tab5')}
          >
            <ExportStatisticsEntitiesDetailToXLSX data={[]} />
          </ExportToXLSXButton>
        </Col2>
      </Row>
    </>
  )
}

export default Header5
