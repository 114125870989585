import * as Yup from 'yup';
import i18n from 'i18n';

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

export const validationSchema = Yup.object().shape({
  participantList: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string()
          .min(2, 'F3_S7_validation4')
          .max(100, 'F3_S7_validation14'),
        lastName: Yup.string()
          .min(2, 'F3_S7_validation4')
          .max(100, 'F3_S7_validation14'),
        nationalityId: Yup.string()
          .min(1, 'F1_S2_validation5'),
        city: Yup.string()
          .min(2, 'F3_S7_validation4')
          .max(100, 'F3_S7_validation14'),
        email: Yup.string()
          .email('F1_S2_validation23')
          .max(100, 'F1_S2_validation24'),
        phone: Yup.string()
          .min(2, 'F1_S2_validation20')
          .max(100, 'F1_S2_validation21')
          .matches(phoneRegExp, 'F1_S2_validation22'),
        entityType: Yup.string()
          .min(1, 'F1_S2_validation5'),
        organizationName: Yup.string()
          .min(2, 'F3_S7_validation4')
          .max(100, 'F3_S7_validation14'),
      })
    )
});