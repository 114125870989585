import React, {useState} from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import Proptypes from 'prop-types';
import { sendConceptNote } from 'services/initiative';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { useRootStore } from "stores";

import Modal from 'react-bootstrap/Modal';
import { ModalFooter, FooterLeft, FooterCenter, FooterRight } from './SendProposalDialog.styled';
import { ErrorButton, StyledSendButton } from 'components/Buttons';

const SendProposalDialog = ({ title, displayDialog, onCloseModal, isDraftv1 }) => {
  const [sending, setSending] = useState(false);
  const { id, formId } = useParams();
  const history = useHistory();
  const {t} = useTranslation();
  const { authStore } = useRootStore();

  const sendProposalAction = async () => {
    try {
      setSending(true);
      await sendConceptNote(id, formId);
      history.push(`${authStore.getBaseRouteByRole()}/proposals`)
    } catch (error) {
      console.log(error);
    } finally{
      onCloseModal();
      setSending(false);
    }
  }
  return (      
    <Modal show={displayDialog} onHide={onCloseModal} size="lg" animation={false}>
      <Modal.Header>
        <h5>{ title }</h5>
      </Modal.Header>
      <Modal.Body>
        {
        isDraftv1
        ?
          htmlParser(t('F3_confirmation_to_send_draft'))
          :
          htmlParser(t('F3_confirmation_to_send'))
        }
      </Modal.Body>
      <ModalFooter>
        <FooterLeft />
        <FooterCenter>
          <ErrorButton variant="secondary" disabled={sending} onClick={onCloseModal}>
            {t('No')}
          </ErrorButton>
          <StyledSendButton variant="primary" disabled={sending} onClick={() => sendProposalAction()}>
            {t('Yes')}
          </StyledSendButton>
        </FooterCenter>
        <FooterRight />
      </ModalFooter>
    </Modal>
  )
}

SendProposalDialog.propTypes = {
  title: Proptypes.string,
  displayDialog: Proptypes.bool,
  onCloseModal: Proptypes.func,
  isDraftv1: Proptypes.bool
};

export default observer(SendProposalDialog);
