import styled from 'styled-components';


export const StyledDivWrapper = styled.div`
    text-align: left;
    padding:10px;
    padding-left: 150px;
    padding-right: 140px;
    background-color: #F8F9F9;
`;

export const StyledRequiredSpan = styled.span`
    color: red;
`;

export const StyledWarningP = styled.p`
    font-style: italic;
    color: #E74C3C;
`;