import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { validationSchema } from './Section8.validations'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { saveFormSection } from 'services/initiative'
import { useTranslation } from 'react-i18next'
import { useRootStore } from 'stores'
import { formInitialValues } from './Section8.initialValues'
import { f5statusNamesConst, roleNamesConst } from 'constants/appConstants'
import { SVGGantt } from 'gantt'
import TextValue from 'components/TextValue'
import Comments from '../Comments'
import { parseDateString } from 'helpers/dateHelpers'
import { formatDateDDMMYY } from 'helpers/dateHelpers';

import CustomDatePicker from 'components/CustomDatePicker';
import { FormFielErrorMessage, RequiredMark } from 'components/CommonStyled'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer'
import {
  StyledFormLabel,
  StyledTitle,
  StyledDivWrapper,
} from 'components/Forms/Form5/Section8/Section8.styled'
import DeadlineButton from 'components/DeadlineButton'
import DateModal from './DateModal'

const Section8 = () => {
  const { t } = useTranslation()
  const { id, formId, sectionId } = useParams()
  const { formStore, initiativeStore, authStore } = useRootStore()
  const [saved, setSaved] = useState(false)
  const [nonEditable, setNonEditable] = useState(false)
  const [disabledComments, setDisabledComments] = useState(true)
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const currentRole = authStore.roles[0]
  const isConsortium = currentRole === roleNamesConst.CONSORTIUM
  const isAtke = currentRole === roleNamesConst.ATKE
  const isIntpa = currentRole === roleNamesConst.INTPA
  const isEud = currentRole === roleNamesConst.EUD
  const [isF7, setIsF7] = useState(false)

  const [modal, setModal] = useState({
    show: false,
    data: {
      startDate: '',
      endDate: '',
      field: '',
    },
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (saved) window.scrollTo(0, 0)
  }, [saved])

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f5statusNamesConst.DRAFT ||
        initiativeStore.role !== roleNamesConst.APPLICANT,
    )
  }, [initiativeStore.formStateName])

  useEffect(() => {
    if (initiativeStore.formStateName)
      setIsF7(
        (isIntpa || isEud) &&
          initiativeStore.formStateName !== f5statusNamesConst.DRAFT &&
          initiativeStore.formStateName !== f5statusNamesConst.SENT,
      )
  }, [initiativeStore.formStateName])

  useEffect(() => {
    if (formStore.formSectionData) {
      handleGanttData1(formStore.formSectionData[1].gantt)
      handleGanttData2(formStore.formSectionData[2].gantt)
      const formSectionData = {
        ...formInitialValues,
        ...formStore.formSectionData[0],
        ...{
          startDate: parseDateString(formStore.formSectionData[2].startDate),
          endDate: parseDateString(formStore.formSectionData[2].endDate),
        },
      }
      for (const key in formSectionData) {
        if (!formSectionData[key]) {
          formSectionData[key] = ''
        }
      }
      setInitialValues(formSectionData)
    }
  }, [formStore.formSectionData])

  const handleGanttData1 = (data) => {
    const ganttData = []
    data.forEach((activity, index) => {
      ganttData.push({
        id: index,
        text: `${activity.name} / ${t(
          `activity_types_${activity.activityType.id}`,
        )}`,
        start: new Date(activity.fromDate),
        end: new Date(activity.toDate),
      })
    })
    ganttData.sort(function (a, b) {
      return new Date(a.start) - new Date(b.start)
    })
    new SVGGantt('#gantt1', ganttData, {
      viewMode: 'week',
    })
  }

  const handleGanttData2 = (data) => {
    const ganttData = []
    data.forEach((activity, index) => {
      ganttData.push({
        id: index,
        text: `${activity.name} / ${t(
          `activity_types_${activity.activityType.id}`,
        )}`,
        start: new Date(activity.fromDate),
        end: new Date(activity.toDate),
      })
    })
    ganttData.sort(function (a, b) {
      return new Date(a.start) - new Date(b.start)
    })
    new SVGGantt('#gantt2', ganttData, {
      viewMode: 'week',
    })
  }

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values)
      formStore.setSectionStatus(sectionId, complete)
      resetForm({ values })
      setSaved(true)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSaved = (value) => {
    setSaved(value)
  }

  const handleButton = (value) => {
    setDisabledComments(value)
  }

  return (
    <FormLayout>
      <FormContainer
        number={8}
        title={t('F3_S8_title')}
        formId="form5section8"
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
        showButtonClarif={!disabledComments}
      >
        <DateModal
          show={modal.show}
          onHide={() =>
            setModal({
              show: false,
              data: {
                startDate: '',
                endDate: '',
                field: '',
              },
            })
          }
          startDate={modal.data.startDate}
          endDate={modal.data.endDate}
          field={modal.data.field}
        />
        <StyledDivWrapper>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              dirty,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} id="form5section8">
                {dirty && setSaved(false)}

                <StyledTitle>
                  {t('F5_Subtitle_Description_from_proposal')}
                </StyledTitle>

                <div
                  style={{
                    marginBottom: '20px',
                    overflowX: 'auto',
                    maxWidth: '100vw',
                  }}
                >
                  <div id="gantt1" />
                </div>

                <Form.Group
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <StyledFormLabel style={{ marginTop: '8px' }}>
                    {t('F3_S8_Start_day')}:
                  </StyledFormLabel>
                  <TextValue
                    value={
                      formStore.formSectionData
                        ? formatDateDDMMYY(formStore.formSectionData[1].startDate)
                        : '-'
                    }
                  />

                  <StyledFormLabel style={{ marginTop: '8px' }}>
                    {t('F3_S8_End_day')}:
                  </StyledFormLabel>
                  <TextValue
                    value={
                      formStore.formSectionData
                        ? formatDateDDMMYY(formStore.formSectionData[1].endDate)
                        : '-'
                    }
                  />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel>
                    {t('F1_S7_Intervention_methodology')}
                  </StyledFormLabel>
                  <TextValue value={values.methodology} />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel> {t('F3_S8_justification_deviation')} </StyledFormLabel>
                  <TextValue value={values.justificationDeviation} />
                </Form.Group>

                <Form.Group>
                  <StyledFormLabel> {t('F3_S8_risks')} </StyledFormLabel>
                  <TextValue value={values.covid19} />
                </Form.Group>

                <StyledTitle style={{ marginTop: '30px' }}>
                  {t('F5_Subtitle_Assessment_TCI_Implementation')}
                </StyledTitle>

                <div
                  style={{
                    marginBottom: '20px',
                    overflowX: 'auto',
                    maxWidth: '100vw',
                  }}
                >
                  <div id="gantt2" />
                </div>

                <Form.Group
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Col>
                    <Row>
                      <StyledFormLabel>{t('F3_S8_Start_day')}:</StyledFormLabel>
                    </Row>
                    <Row style={{ alignItems: 'center' }}>
                      <CustomDatePicker
                        value={values.startDate}
                        onChange={date => setFieldValue('startDate',date)}
                        disabled
                      />
                      {isIntpa && (
                        <div style={{ marginLeft: '20px' }}>
                          <DeadlineButton
                            onClick={() =>
                              setModal({
                                show: true,
                                data: {
                                  startDate: values.startDate,
                                  endDate: values.endDate,
                                  field: 'startDate',
                                },
                              })
                            }
                          />
                        </div>
                      )}
                    </Row>
                    {errors.startDate ? (
                      <Row>
                        <FormFielErrorMessage>
                          {errors.startDate}
                        </FormFielErrorMessage>
                      </Row>
                    ) : null}
                  </Col>

                  <Col>
                    <Row>
                      <StyledFormLabel>{t('F3_S8_End_day')}:</StyledFormLabel>
                    </Row>
                    <Row style={{ alignItems: 'center' }}>
                      <CustomDatePicker
                        value={values.endDate}
                        onChange={date => setFieldValue('endDate',date)}
                        disabled
                      />
                      {isIntpa && (
                        <div style={{ marginLeft: '20px' }}>
                          <DeadlineButton
                            onClick={() =>
                              setModal({
                                show: true,
                                data: {
                                  startDate: values.startDate,
                                  endDate: values.endDate,
                                  field: 'endDate',
                                },
                              })
                            }
                          />
                        </div>
                      )}
                    </Row>
                    {errors.endDate ? (
                      <Row>
                        <FormFielErrorMessage>
                          {errors.endDate}
                        </FormFielErrorMessage>
                      </Row>
                    ) : null}
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '25px' }}>
                  <StyledFormLabel>
                    {t('F5_S8_The_intervention_logic')}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Form.Control
                    name="interventionLogic"
                    as="textarea"
                    rows={6}
                    style={{ resize: 'none' }}
                    placeholder={t('Maximum_1300')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.interventionLogic}
                    disabled={nonEditable}
                    className={
                      touched.interventionLogic && errors.interventionLogic
                        ? 'error'
                        : null
                    }
                  />
                  {touched.interventionLogic && errors.interventionLogic ? (
                    <FormFielErrorMessage>
                      {errors.interventionLogic}
                    </FormFielErrorMessage>
                  ) : null}
                </Form.Group>

                <Form.Group style={{ marginTop: '25px' }}>
                  <StyledFormLabel>
                    {t('F5_S8_Assessment_adaptation')}:
                  </StyledFormLabel>
                  <Form.Control
                    name="assessmentCovid19"
                    as="textarea"
                    rows={6}
                    style={{ resize: 'none' }}
                    placeholder={t('Maximum_1300')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.assessmentCovid19}
                    disabled={nonEditable}
                    className={
                      touched.assessmentCovid19 && errors.assessmentCovid19
                        ? 'error'
                        : null
                    }
                  />
                  {touched.assessmentCovid19 && errors.assessmentCovid19 ? (
                    <FormFielErrorMessage>
                      {errors.assessmentCovid19}
                    </FormFielErrorMessage>
                  ) : null}
                </Form.Group>
              </form>
            )}
          </Formik>

          {(isAtke || isConsortium || isF7) && (
            <Comments onSaved={handleSaved} onButton={handleButton} />
          )}
        </StyledDivWrapper>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section8)
