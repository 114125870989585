import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Form from "react-bootstrap/Form";
import { useRootStore } from "stores";
import { loginService, getToken, confirmationService } from "services/auth";
import { getMaintenanceVariables } from "services/apiConstants";
import CustomModal from "components/Modal";
import PublicHeader from "components/PublicHeader";
import Footer from "components/Footer";
import {
  StyledWarningP,
  StyledContainer,
  StyledTitle,
  StyledP,
  StyledButton,
  ConfirmationDiv,
} from "./Login.styled";

import { useTranslation } from "react-i18next";
import htmlParser from "html-react-parser";


const defaultFieldsState = {
  incEmail: false,
  incPass: false,
};

const LogIn = () => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  let history = useHistory();
  const isLoggedIn = !!getToken();

  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
    type: "default",
  });

  const [disableLogin, setDisableLogin] = useState(true);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getMaintenanceVariables();
        if(response && response[1]){
          setDisableLogin(!response[1].keyValue);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAPI();
   }, [])

  useEffect(() => {
    async function fetchAPI() {
      if (isLoggedIn && authStore.roles) {
        history.push(`${authStore.getBaseRouteByRole()}/concept-notes`);
      }
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");
      if (token) {
        try {
          await confirmationService(token);
          setConfirmed(true);
        } catch (error) {
          console.log(error);
          setModal({
            type: "error",
            message: t('Error confirmation token not valid'),
            isOpen: true,
          });
        }
      }
    }
    fetchAPI();
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fieldsState, setFieldsState] = useState(defaultFieldsState);

  const onHideModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (!email.length) {
      setFieldsState({ ...defaultFieldsState, incEmail: true });
      return;
    }
    if (!password.length) {
      setFieldsState({ ...defaultFieldsState, incPass: true });
      return;
    }
    try {
      const response = await loginService(email, password);
      authStore.login(response.email, response.roles);
      history.push(`${authStore.getBaseRouteByRole()}/concept-notes`);
    } catch (error) {
      setModal({
        type: "error",
        message: t('Error incorrect user or password'),
        isOpen: true,
      });
      console.log(error);
    }
  }

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {modal.isOpen && (
        <CustomModal
          show={modal.isOpen}
          onHide={onHideModal}
          message={modal.message}
          type={modal.type}
        />
      )}
      <PublicHeader />
      {confirmed && (
        <ConfirmationDiv>
          {htmlParser(t('Registration_validation'))}
        </ConfirmationDiv>
      )}
      <StyledContainer>
        <StyledTitle> {t("Home_login")} </StyledTitle>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder={t('F1_S2_Email')}
              onChange={(e) => setEmail(e.target.value)}
            />
            {fieldsState.incEmail && (
              <StyledWarningP>{t('Error email cannot be empty')}</StyledWarningP>
            )}
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder={t('Registration_password')}
              onChange={(e) => setPassword(e.target.value)}
            />
            {fieldsState.incPass && (
              <StyledWarningP>{t('Error password empty')}</StyledWarningP>
            )}
          </Form.Group>
          <StyledButton type="submit" disabled={disableLogin}>{t("Home_sign_in")}</StyledButton>
          <StyledP> {htmlParser(t("Home_forgot_password"))} </StyledP>
        </Form>
      </StyledContainer>
      <Footer public={true} />
    </div>
  );
};

export default observer(LogIn);
