export const formInitialValues = {
    name: "",
    description: "",
    objective: "",
    latamKnowledge: "",
    euKnowledge: "",
    newKnowledge: "",
    application: "",
    leap: "",
    contribution: "",
    benEntities: "",
    directBen: "",
    goalPrimaryType: {
      id: 1,
      name: "No poverty",
    },
    primaryJustification: "",
    goalSecondaryType: {
      id: 1,
      name: "No poverty",
    },
    secondaryJustification: "",
    rights: "",
    environment: "",
    innovation: "",
    valueTC: "",
    valueEU: "",
    valueTP: "",
    covid19: ""
  };
  