import styled, { css } from 'styled-components'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

export const StyledSubtitle = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    margin-bottom: 10px;
    padding-left: 15px;
    color: ${theme.brandColors.blue};
    letter-spacing: 2px;
    font-weight: 650;
  `}
`;

export const StyledFormLabel = styled(Form.Label)`
  padding-left: 12px;
  margin-bottom: -10px;
`;

export const StyledTextValue = styled(Col)`
  padding-left: 65px;
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    color: ${theme.colors.gray[5]};
  `}
`;

export const TextValueLeftSpaced = styled.div`
  padding-left: 65px;
`;

export const StyledBudgetDiv = styled.div`
  ${({ theme }) => css`
    margin-top: 40px;
    padding: 50px;
    border-radius: 9px;
    border: 2px solid ${theme.brandColors.blue};
  `}
`;

export const StyledNoticeParagraph = styled.p`
  font-size: .8em;
  line-height: 1.3rem;
  margin-bottom: 0px;
  text-align: justify;
  color: red;
`;

export const BlackSpan = styled.span`
  color: black;
`;

export const ActivityStateSpan = styled.span`
  ${({ theme }) => css`
    color: ${props => props.cancelled ? "red" : `${theme.brandColors.blue}`};
  `}
`;