import React from 'react';
import { observer } from 'mobx-react-lite';
import  { useRootStore } from 'stores';
import { useTranslation } from 'react-i18next';

import { Title, StyledParagraph } from './UserInformation.styled';

const UserInformation = (props) => {
  const { t } = useTranslation();

  const { authStore: {
    isLoggedIn,
    userName,
    role
  }} = useRootStore();

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div>
      <Title>{t('user_info_title')}</Title>
      <br/>
      <StyledParagraph><b>{t('Grid_Name')}:</b> {props.profile.lastName ?? ''}, {props.profile.name ?? ''}</StyledParagraph>
      <StyledParagraph><b>{t('forgot_password_email')}:</b> {props.profile.email ?? ''}</StyledParagraph>
      <StyledParagraph><b>{t('Registration_organization')}:</b> {props.profile.organization ?? ''}</StyledParagraph>
      <StyledParagraph><b>{t('F1_S2_Country')}:</b> {props.profile.country?.name ?? ''}</StyledParagraph>
    </div>
  )
}

export default observer(UserInformation);