import { action, makeObservable, observable } from 'mobx';
import { getFormSection } from 'services/initiative';
import { getFundSection }from 'services/funds';

export class FormStore {
  rootStore;
  formSectionNumber = null;
  formSectionData = null;
  formStatus = null;
  formSectionClarification = null;
  unreadComments = null;
  inptaClarification = null;
  eudClarifications = null;
  intpaComment = null;
  eudComments = null;
  sectionsStatus = {
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
  };

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      formSectionNumber: observable,
      formSectionData: observable,
      formStatus: observable,
      inptaClarification: observable,
      eudClarifications: observable,
      intpaComment: observable,
      eudComments: observable,
      sectionsStatus: observable,
      formSectionClarification: observable,
      unreadComments: observable,
      setFormSectionData: action,
      setFormStatus: action,
      setSectionStatus: action,
      setCurrentSection: action,
      setFormSectionNumber: action,
      setUnreadComments: action,
    });
  }

  setFormSectionNumber(number) {
    this.formSectionNumber = number;
  }

  setFormSectionClarification(clarification) {
    this.formSectionClarification = clarification;
  }

  setUnreadComments(number) {
    this.unreadComments = number;
  }

  setFormSectionData(data) {
    this.formSectionData = data && data.length ? data : null;
  }

  setFormStatus(data) {
    const { form, sections } = data;
    this.formStatus = form;
    this.sectionsStatus = { ...this.sectionsStatus, ...sections };
  }

  setSectionStatus(sectionNumber, complete) {
    const status = complete ? 'COMPLETE' : 'INCOMPLETE';
    this.sectionsStatus = { ...this.sectionsStatus, [sectionNumber]: status };
  }

  setCurrentSection(sectionNumber) {
    const newSectionStatus = { ...this.sectionsStatus };
    Object.keys(this.sectionsStatus).forEach(key => {
      if (this.sectionsStatus[key]) newSectionStatus[key] = 'INCOMPLETE';
    });
    this.sectionsStatus = { ...newSectionStatus, [sectionNumber]: 'COMPLETE' };
  }

  async loadForm1Data(id, sectionId) {
    try {
      const { section, status, initiative, clarification, unreadComments } = await getFormSection(id, 1, sectionId);
      this.setFormSectionNumber(sectionId);
      this.setFormSectionData(section);
      this.setFormStatus(status);
      this.setFormSectionClarification(clarification);
      this.setUnreadComments(unreadComments);
      this.rootStore.initiativeStore.setInitiativeData(initiative);
    } catch (error) {
      console.log(error);
    }
  }

  async loadForm2Data(id, sectionId) {
    try {
      const { section, inptaClarification, eudClarifications, initiative, unreadComments } = await getFormSection(
        id,
        2,
        sectionId,
      );
      this.setFormSectionNumber(sectionId);
      this.setFormSectionData(section);
      this.setUnreadComments(unreadComments);
      this.inptaClarification = inptaClarification;
      this.eudClarifications = eudClarifications;
      this.rootStore.initiativeStore.setInitiativeData(initiative);
    } catch (error) {
      console.log(error);
    }
  }

  async loadForm3Data(id, sectionId) {
    try {
      const { section, status, initiative, clarification, unreadComments } = await getFormSection(id, 3, sectionId);
      this.setFormSectionNumber(sectionId);
      this.setFormSectionData(section);
      this.setFormStatus(status);
      this.setFormSectionClarification(clarification);
      this.setUnreadComments(unreadComments);
      this.rootStore.initiativeStore.setInitiativeData(initiative);
    } catch (error) {
      console.log(error);
    }
  }

  async loadForm4Data(id, sectionId) {
    try {
      const { section, intpaComment, eudComments, initiative, unreadComments } = await getFormSection(id, 4, sectionId);
      this.setFormSectionNumber(sectionId);
      this.setFormSectionData(section);
      this.setUnreadComments(unreadComments);
      this.intpaComment = intpaComment;
      this.eudComments = eudComments;
      this.rootStore.initiativeStore.setInitiativeData(initiative);
    } catch (error) {
      console.log(error);
    }
  }

  async loadForm5Data(id, sectionId) {
    try {
      const { section, status, initiative, clarification, unreadComments } = await getFormSection(id, 5, sectionId);
      this.setFormSectionNumber(sectionId);
      this.setFormSectionData(section);
      this.setFormStatus(status);
      this.setFormSectionClarification(clarification);
      this.setUnreadComments(unreadComments);
      this.rootStore.initiativeStore.setInitiativeData(initiative);
    } catch (error) {
      console.log(error);
    }
  }

  async loadForm55Data(id, sectionId) {
    try {
      const { section, status, fund, clarification, unreadComments } = await getFundSection(id, sectionId);
      this.setFormSectionNumber(sectionId);
      this.setFormSectionData(section);
      this.setFormStatus(status);
      this.setFormSectionClarification(clarification);
      this.setUnreadComments(unreadComments);
      this.rootStore.initiativeStore.setInitiativeData(fund);
    } catch (error) {
      console.log(error);
    }
  }
}
