import React, { useState, useEffect } from "react";
import { Formik, FieldArray } from "formik";
import { useTranslation } from "react-i18next";
import htmlParser from "html-react-parser";
import { validationSchema } from "./Section11.validations";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { saveFormSection } from "services/initiative";
import { f3statusNamesConst, roleNamesConst } from "constants/appConstants";
import { useRootStore } from "stores";
import { formInitialValues } from "./Section11.initialValues";
import { useConstantsForm3 } from "customHooks/useConstantsForm3";
import {
  Separator,
  StyledSubtitle,
  StyledIntroParagraph,
  StyledContainer,
} from "./Section11.styled";
import Accordion from "react-bootstrap/Accordion";
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from "components/CustomAccordion";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { PrimaryButton, ErrorButton } from "components/Buttons";
import TargetForm from "./TargetForm";
import ConfirmCloseModal from "components/ConfirmCloseModal";
import { FormFielErrorMessage, SeparatorDotted } from "components/CommonStyled";
import FormLayout from "../../FormLayout";
import FormContainer from "components/FormContainer";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const targetObject = {
  targetGoalId: 1,
  targetJustification: "",
  targetIndicatorList: null,
};

const Section11 = () => {
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { formStore, initiativeStore } = useRootStore();
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const {
    developmentGoals,
    developmentGoalTargets,
    developmentGoalTargetIndicators,
  } = useConstantsForm3();

  const filterDevGoalsTargets = (goalTypeId) => {
    return developmentGoalTargets.filter(
      (e) => e.developmentGoal.id == goalTypeId
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName !== f3statusNamesConst.DRAFT ||
        initiativeStore.role === roleNamesConst.GUEST
    );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {
        ...formInitialValues,
        ...formStore.formSectionData[0],
      };
      for (const key in formSectionData) {
        if (!formSectionData[key]) {
          formSectionData[key] = "";
        }
      }
      formSectionData.goalPrimaryTypeId = formSectionData.goalPrimaryType
        ? formSectionData.goalPrimaryType.id
        : "";
      formSectionData.goalSecondaryTypeId = formSectionData.goalSecondaryType
        ? formSectionData.goalSecondaryType.id
        : "";
      formSectionData.goal10TypeId = formSectionData.goal10Type
        ? formSectionData.goal10Type.id
        : "10";
      formSectionData.goal17TypeId = formSectionData.goal17Type
        ? formSectionData.goal17Type.id
        : "17";
      setFormValues(formSectionData);
    }
  }, [formStore.formSectionData]);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  const openModalRemove = (key, arrayHelpers) => {
    setSelectedKey({
      currentKey: key,
      currentArrayHelper: arrayHelpers,
    });
    setModalRemove(true);
  };

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values);
      formStore.setSectionStatus(sectionId, complete);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, resetForm) => {
    e.preventDefault();
    isValid ? handleSubmit(values, { resetForm }) : alert(t("not_saved_alert"));
  };

  const setTargetIdsAndIndicatorsEmpty = (targetListRef) => {
    console.log(targetListRef)
    targetListRef?.forEach(target => {
      target.targetGoalId = '';
      target.targetIndicatorList = [];
    });
  };

  return (
    <FormLayout>
      {modalRemove && (
        <ConfirmCloseModal
          show={modalRemove}
          text={`${t("Delete_target")} ${selectedKey.currentKey + 1}?`}
          handleActionOnConfirm={() => {
            selectedKey.currentArrayHelper.remove(selectedKey.currentKey);
            closeModalRemove();
          }}
          handleCloseConfirm={closeModalRemove}
        />
      )}

      <FormContainer
        number={11}
        title={t("F3_S11_title")}
        formId="form3section11"
        isHelp={true}
        showSavedMessage={saved}
        showSaveButton={!nonEditable}
      >
        <Formik
          enableReinitialize
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <form
              onSubmit={(e) => {
                onValidateSubmit(
                  e,
                  props.isValid,
                  props.values,
                  props.resetForm
                );
              }}
              id="form3section11"
            >
              {props.dirty && setSaved(false)}
              <StyledContainer>
                <StyledIntroParagraph>
                  {htmlParser(t("F3_S11_Intro"))}
                </StyledIntroParagraph>

                <StyledSubtitle>
                  {htmlParser(t("F3_S11_Subtitle_SDG_primary"))}
                </StyledSubtitle>
                <Separator />
                <Form.Group as={Row}>
                  <Col>
                    <Form.Control
                      name="goalPrimaryTypeId"
                      as="select"
                      onChange={(e) => {
                        props.handleChange(e);
                        setTargetIdsAndIndicatorsEmpty(props.values.primaryTargetList);
                      }}
                      onBlur={props.handleBlur}
                      value={props.values.goalPrimaryTypeId}
                      className={
                        props.touched.goalPrimaryTypeId &&
                        props.errors.goalPrimaryTypeId
                          ? "error"
                          : null
                      }
                      disabled={nonEditable}
                    >
                      <option value="">{t("Placeholder_select")}</option>
                      {developmentGoals.map((devGoal) => (
                        <option key={devGoal.id} value={devGoal.id}>
                          {t(`SDG_Goal${devGoal.id}`)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched={props.touched.goalPrimaryTypeId}
                      errors={props.errors.goalPrimaryTypeId}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col>
                    <Form.Label>{t("F3_S11_Justification")}:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      style={{ resize: "none" }}
                      name="primaryJustification"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      placeholder="A maximum of 650 characters"
                      value={props.values.primaryJustification}
                      className={
                        props.touched.primaryJustification &&
                        props.errors.primaryJustification
                          ? "error"
                          : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={props.touched.primaryJustification}
                      errors={props.errors.primaryJustification}
                    />
                  </Col>
                </Form.Group>

                <FieldArray
                  name="primaryTargetList"
                  render={(arrayHelpers) => (
                    <>
                      <Accordion>
                        {props.values.primaryTargetList &&
                        props.values.goalPrimaryType
                          ? props.values.primaryTargetList.map(
                              (target, key) => (
                                <AccordionItem key={key + 1}>
                                  <AccordionItemHeader>
                                    <AccordionTitle eventKey={key + 1}>
                                      {t("F3_S11_Target")} {key + 1}
                                    </AccordionTitle>
                                  </AccordionItemHeader>
                                  <Accordion.Collapse eventKey={key + 1}>
                                    <AccordionItemBody>
                                      <TargetForm
                                        formikInstance={props}
                                        targetListName={"primaryTargetList"}
                                        targetIndex={key}
                                        goalId={props.values.goalPrimaryTypeId}
                                        developmentGoalTargets={filterDevGoalsTargets(
                                          props.values.goalPrimaryTypeId
                                        )}
                                        targetIndicatorsList={
                                          developmentGoalTargetIndicators
                                        }
                                        nonEditable={nonEditable}
                                        setSaved={setSaved}
                                      />
                                      <ErrorButton
                                        onClick={() =>
                                          openModalRemove(key, arrayHelpers)
                                        }
                                        disabled={nonEditable}
                                      >
                                        {t("F3_S11_Target_button_delete")}
                                      </ErrorButton>
                                    </AccordionItemBody>
                                  </Accordion.Collapse>
                                </AccordionItem>
                              )
                            )
                          : null}
                      </Accordion>
                      {typeof props.errors.primaryTargetList === "string" ? (
                        <FormErrorMessage
                          touched
                          errors={props.errors.primaryTargetList}
                        />
                      ) : null}
                      <SeparatorDotted />
                      <PrimaryButton
                        onClick={() => arrayHelpers.push(targetObject)}
                        disabled={nonEditable}
                      >
                        {t("F3_S11_Target_button_add")}
                      </PrimaryButton>
                    </>
                  )}
                />

                <SeparatorDotted />

                <StyledSubtitle>
                  {t("F3_S11_Subtitle_SDG_secondary")}:
                </StyledSubtitle>
                <Separator />
                <Form.Group as={Row}>
                  <Col>
                    <Form.Control
                      name="goalSecondaryTypeId"
                      as="select"
                      onChange={(e) => {
                        props.handleChange(e);
                        setTargetIdsAndIndicatorsEmpty(props.values.secondaryTargetList);
                      }}
                      onBlur={props.handleBlur}
                      value={props.values.goalSecondaryTypeId}
                      className={
                        props.touched.goalSecondaryTypeId &&
                        props.errors.goalSecondaryTypeId
                          ? "error"
                          : null
                      }
                      disabled={nonEditable}
                    >
                      <option value="">{t("Placeholder_select")}</option>
                      {developmentGoals.map((devGoal) => (
                        <option key={devGoal.id} value={devGoal.id}>
                          {t(`SDG_Goal${devGoal.id}`)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched={props.touched.goalSecondaryTypeId}
                      errors={props.errors.goalSecondaryTypeId}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col>
                    <Form.Label>{t("F3_S11_Justification")}:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      style={{ resize: "none" }}
                      name="secondaryJustification"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      placeholder="A maximum of 650 characters"
                      value={props.values.secondaryJustification}
                      className={
                        props.touched.secondaryJustification &&
                        props.errors.secondaryJustification
                          ? "error"
                          : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={props.touched.secondaryJustification}
                      errors={props.errors.secondaryJustification}
                    />
                  </Col>
                </Form.Group>

                <FieldArray
                  name="secondaryTargetList"
                  render={(arrayHelpers) => (
                    <>
                      <Accordion>
                        {props.values.secondaryTargetList &&
                        props.values.goalSecondaryTypeId
                          ? props.values.secondaryTargetList.map(
                              (target, key) => (
                                <AccordionItem key={key + 1}>
                                  <AccordionItemHeader>
                                    <AccordionTitle eventKey={key + 1}>
                                      {t("F3_S11_Target")} {key + 1}
                                    </AccordionTitle>
                                  </AccordionItemHeader>
                                  <Accordion.Collapse eventKey={key + 1}>
                                    <AccordionItemBody>
                                      <TargetForm
                                        formikInstance={props}
                                        targetListName={"secondaryTargetList"}
                                        targetIndex={key}
                                        goalId={props.values.goalSecondaryTypeId}
                                        developmentGoalTargets={filterDevGoalsTargets(
                                          props.values.goalSecondaryTypeId
                                        )}
                                        targetIndicatorsList={
                                          developmentGoalTargetIndicators
                                        }
                                        nonEditable={nonEditable}
                                        setSaved={setSaved}
                                      />
                                      <ErrorButton
                                        onClick={() =>
                                          openModalRemove(key, arrayHelpers)
                                        }
                                        disabled={nonEditable}
                                      >
                                        {t("F3_S11_Target_button_delete")}
                                      </ErrorButton>
                                    </AccordionItemBody>
                                  </Accordion.Collapse>
                                </AccordionItem>
                              )
                            )
                          : null}
                      </Accordion>
                      {typeof props.errors.secondaryTargetList === "string" ? (
                        <FormErrorMessage
                          touched
                          errors={props.errors.secondaryTargetList}
                        />
                      ) : null}
                      <SeparatorDotted />
                      <PrimaryButton
                        onClick={() => {
                          arrayHelpers.push(targetObject);
                        }}
                        disabled={
                          nonEditable || !props.values.goalSecondaryTypeId
                        }
                      >
                        {t("F3_S11_Target_button_add")}
                      </PrimaryButton>
                    </>
                  )}
                />

                <SeparatorDotted />

                <StyledSubtitle> {t("F3_S11_Subtitle_SDG_10")} </StyledSubtitle>
                <Separator />
                <Form.Group as={Row}>
                  <Col>
                    <Form.Control
                      name="goal10TypeId"
                      as="select"
                      value="10"
                      disabled
                    >
                      <option value="">{t("Placeholder_select")}</option>
                      {developmentGoals.map((devGoal) => (
                        <option key={devGoal.id} value={devGoal.id}>
                          {t(`SDG_Goal${devGoal.id}`)}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col>
                    <Form.Label>{t("F3_S11_Justification")}:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      style={{ resize: "none" }}
                      name="goal10Justification"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      placeholder="A maximum of 650 characters"
                      value={props.values.goal10Justification}
                      className={
                        props.touched.goal10Justification &&
                        props.errors.goal10Justification
                          ? "error"
                          : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={props.touched.goal10Justification}
                      errors={props.errors.goal10Justification}
                    />
                  </Col>
                </Form.Group>

                <FieldArray
                  name="goal10TargetList"
                  render={(arrayHelpers) => (
                    <>
                      <Accordion>
                        {props.values.goal10TargetList &&
                        props.values.goal10TypeId
                          ? props.values.goal10TargetList.map((target, key) => (
                              <AccordionItem key={key + 1}>
                                <AccordionItemHeader>
                                  <AccordionTitle eventKey={key + 1}>
                                    {t("F3_S11_Target")} {key + 1}
                                  </AccordionTitle>
                                </AccordionItemHeader>
                                <Accordion.Collapse eventKey={key + 1}>
                                  <AccordionItemBody>
                                    <TargetForm
                                      formikInstance={props}
                                      targetListName={"goal10TargetList"}
                                      targetIndex={key}
                                      goalId="10"
                                      developmentGoalTargets={filterDevGoalsTargets("10")}
                                      targetIndicatorsList={
                                        developmentGoalTargetIndicators
                                      }
                                      nonEditable={nonEditable}
                                      setSaved={setSaved}
                                    />
                                    <ErrorButton
                                      onClick={() =>
                                        openModalRemove(key, arrayHelpers)
                                      }
                                      disabled={nonEditable}
                                    >
                                      {t("F3_S11_Target_button_delete")}
                                    </ErrorButton>
                                  </AccordionItemBody>
                                </Accordion.Collapse>
                              </AccordionItem>
                            ))
                          : null}
                      </Accordion>
                      {typeof props.errors.goal10TargetList === "string" ? (
                        <FormErrorMessage
                          touched
                          errors={props.errors.goal10TargetList}
                        />
                      ) : null}
                      <SeparatorDotted />
                      <PrimaryButton
                        onClick={() => arrayHelpers.push(targetObject)}
                        disabled={nonEditable}
                      >
                        {t("F3_S11_Target_button_add")}
                      </PrimaryButton>
                    </>
                  )}
                />

                <SeparatorDotted />

                <StyledSubtitle> {t("F3_S11_Subtitle_SDG_17")}</StyledSubtitle>
                <Separator />
                <Form.Group as={Row}>
                  <Col>
                    <Form.Control
                      name="goal17TypeId"
                      as="select"
                      value="17"
                      disabled
                    >
                      <option value="">{t("Placeholder_select")}</option>
                      {developmentGoals.map((devGoal) => (
                        <option key={devGoal.id} value={devGoal.id}>
                          {t(`SDG_Goal${devGoal.id}`)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched={props.touched.goal17TypeId}
                      errors={props.errors.goal17TypeId}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Col>
                    <Form.Label>{t("F3_S11_Justification")}:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      style={{ resize: "none" }}
                      name="goal17Justification"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      placeholder="A maximum of 650 characters"
                      value={props.values.goal17Justification}
                      className={
                        props.touched.goal17Justification &&
                        props.errors.goal17Justification
                          ? "error"
                          : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={props.touched.goal17Justification}
                      errors={props.errors.goal17Justification}
                    />
                  </Col>
                </Form.Group>

                <FieldArray
                  name="goal17TargetList"
                  render={(arrayHelpers) => (
                    <>
                      <Accordion>
                        {props.values.goal17TargetList &&
                        props.values.goal17TypeId
                          ? props.values.goal17TargetList.map((target, key) => (
                              <AccordionItem key={key + 1}>
                                <AccordionItemHeader>
                                  <AccordionTitle eventKey={key + 1}>
                                    {t("F3_S11_Target")} {key + 1}
                                  </AccordionTitle>
                                </AccordionItemHeader>
                                <Accordion.Collapse eventKey={key + 1}>
                                  <AccordionItemBody>
                                    <TargetForm
                                      formikInstance={props}
                                      targetListName={"goal17TargetList"}
                                      targetIndex={key}
                                      goalId="17"
                                      developmentGoalTargets={filterDevGoalsTargets("17")}
                                      targetIndicatorsList={
                                        developmentGoalTargetIndicators
                                      }
                                      nonEditable={nonEditable}
                                      setSaved={setSaved}
                                    />
                                    <ErrorButton
                                      onClick={() =>
                                        openModalRemove(key, arrayHelpers)
                                      }
                                      disabled={nonEditable}
                                    >
                                      {t("F3_S11_Target_button_delete")}
                                    </ErrorButton>
                                  </AccordionItemBody>
                                </Accordion.Collapse>
                              </AccordionItem>
                            ))
                          : null}
                      </Accordion>
                      {typeof props.errors.goal17TargetList === "string" ? (
                        <FormErrorMessage
                          touched
                          errors={props.errors.goal17TargetList}
                        />
                      ) : null}
                      <SeparatorDotted />
                      <PrimaryButton
                        onClick={() => arrayHelpers.push(targetObject)}
                        disabled={nonEditable}
                      >
                        {t("F3_S11_Target_button_add")}
                      </PrimaryButton>
                    </>
                  )}
                />
              </StyledContainer>
            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section11);
