import axios from "axios";
import { getToken, refreshService, getUser } from "./auth";
import jwt_decode from "jwt-decode";

export const instance = axios.create({
  // TODO: use env vars
  baseURL: process.env.REACT_APP_API_URL,
});

// Set the AUTH token for any request
instance.interceptors.request.use(async (config) => {
  try {
    let token = getToken();
    if (token) {
      let refreshToken = getUser().refreshToken;
      var decoded = jwt_decode(token);
      if (refreshToken && config.url && config.url !== "/api/auth/refresh") {
        if (Date.now() >= decoded.exp * 1000 - 2000) {
          await refreshService(refreshToken);
          token = getToken();
          refreshToken = getUser().refreshToken;
        }
      }
      if (config.url !== "/api/auth/refresh") {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  } catch (error) {
    console.log(error);
  }
});

export default instance;
