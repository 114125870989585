import React from 'react';

import Form from 'react-bootstrap/Form';

import { StyledGreenFormLabel } from '../Section15.styled';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const ATCommentForm = ({ formikInstance, nonEditable }) => {

  const { getFieldProps, touched, errors } = formikInstance;
  const {t} = useTranslation();

  return (
    <Form.Group style={{ marginLeft: "10px", marginRight: "20px", marginTop: "40px" }}>
      <StyledGreenFormLabel>{t('F2_Adelante_comments')}</StyledGreenFormLabel>
      <Form.Control
        as="textarea" 
        rows={10} 
        style={{resize: "none"}}
        name={'assessment'}
        className={touched.assessment && errors.assessment ? "error" : null}
        { ...getFieldProps('assessment') }
        disabled={nonEditable}
      />
      <FormErrorMessage
        touched={touched.assessment}
        errors={errors.assessment}
      />
    </Form.Group>
  )
}

export default ATCommentForm;