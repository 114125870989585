import React, { createContext, useContext } from 'react';
import { RootStore } from './RootStore'

const RootStoreContext = createContext();

const RootStoreProvider = ({ children }) => {
  return (
    <RootStoreContext.Provider value={new RootStore() }>
      { children }
    </RootStoreContext.Provider>
  )
}

const useRootStore = () => {
  const context = useContext(RootStoreContext);
  if (!context) {
    throw new Error('useRootStoreContext must be used within a RootStoreProvider');
  }
  return context;
}

export { RootStoreContext, RootStoreProvider, useRootStore };
