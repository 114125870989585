import styled, { css } from 'styled-components'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export const StyledFormLabel = styled(Form.Label)`
  padding-left: 15px;
  margin-bottom: 0px;
`;

export const StyledCheck = styled.div`
   border-radius: 15px;
   border: red 2px solid;
   padding: 20px 5px 5px 5px;
`;