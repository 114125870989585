import React, { useState, useEffect } from 'react';
import { getFormulationInitiatives } from 'services/initiative';
import Table from 'react-bootstrap/Table';
import PageTitle from 'components/PageTitle';
import { PageHeader, PageContent } from './Proposals.styled';
import TableRow from './TableRow';
import { useTranslation } from 'react-i18next';

const ConceptNotes = () => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [initiatives, setIinitiatives] = useState([]);

  useEffect(() => {
    (async function() {
      try {
        const response = await getFormulationInitiatives();
        setIinitiatives(response);
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <>
      <PageHeader>
        <PageTitle title={t('menu_app_2')}/>
      </PageHeader>
      <PageContent>
        {loading
          ? <p>{t('Loading')}</p>
          : (
            <Table>
              <thead>
                <tr>
                  <th>{t('Grid_title_code')}</th>
                  <th>{t('Grid_title')}</th>
                  <th>{t('Grid_status')}</th>
                  <th>{t('Grid_deadline')}</th>
                  <th>{t('Grid_messages')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  !(initiatives && initiatives.length)
                    ? <tr><td colSpan={4} style={{ textAlign: "center" }}>{t('No_data_available')}</td></tr>
                    : initiatives.map(item => (
                      <TableRow key={item.id} item={item} />
                    ))
                }
              </tbody>
            </Table>
          )
        }
      </PageContent>
    </>
  )
};

export default ConceptNotes;
