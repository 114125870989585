import React from 'react';
import { useTranslation } from 'react-i18next';

import TextValue from 'components/TextValue';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RequiredMark } from 'components/CommonStyled';

const TargetForm = ({ data }) => {

  const { t } = useTranslation();

  return (
    <>
      <TextValue value={data.targetGoal? t(`SDG_Target${data.targetGoal.id}`): ' - '} />

      <Form.Group style={{marginTop: '15px', marginLeft: '15px'}}>
        <Form.Label>{t('F3_S11_Justification_target')}:</Form.Label>
        <TextValue value={data.targetJustification} />
      </Form.Group> 
      
      {(data.targetIndicatorList) &&
        <Form.Group as={Row} style={{marginLeft: '0px'}}>
          <Col xs={4} lg={3}  className="align-self-start"> 
            <Form.Label style={{marginTop: "10px"}}>Indicators<RequiredMark>*</RequiredMark>: </Form.Label>
          </Col>
          <Col xs={10} lg={9} className="align-self-end" style={{marginTop: '8px'}}> 
            {data.targetIndicatorList.map(indicator => (
              <Form.Check
                readOnly
                disabled={true}
                key={indicator.id}
                style={{marginBottom: '20px', textAlign: 'justify'}}
                type="checkbox" 
                label={t(`SDG_Indicator${indicator.targetGoalIndicator.id}`)}
                checked={indicator.selectedIndicator}
                />
            ))}
          </Col>
        </Form.Group> 
      }
    </>
  )

}

export default TargetForm;
