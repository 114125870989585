import styled, { css } from 'styled-components';
import Modal from 'react-bootstrap/Modal';

export const StyledModalHeader = styled(Modal.Header)`
  ${({ theme, type }) => css`
    color: ${!theme.colorGroups[type] ? theme.colors.gray[1] : theme.colorGroups[type].color} ;
    background-color: ${!theme.colorGroups[type] ? theme.colors.gray[4] : theme.colorGroups[type].bgColor};
  `}
`;

export const StyledModalBody = styled(Modal.Body)`
  ${({ theme, type }) => css`
    color: ${!theme.colorGroups[type] ? theme.colors.gray[1] : theme.colorGroups[type].color} ;
    background-color: ${!theme.colorGroups[type] ? theme.colors.gray[4] : theme.colorGroups[type].bgColor};
  `}
`;

export const StyledModalFooter = styled(Modal.Footer)`
  ${({ theme, type }) => css`
    color: ${!theme.colorGroups[type] ? theme.colors.gray[1] : theme.colorGroups[type].color} ;
    background-color: ${!theme.colorGroups[type] ? theme.colors.gray[4] : theme.colorGroups[type].bgColor};
  `}
`;