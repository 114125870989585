import React, {useEffect, useState} from 'react';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import DayJS from 'dayjs';
import { IsAssistanceCheckboxDisabled } from './ParticipantForm.helper';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RequiredMark } from 'components/CommonStyled';
import { 
  StyledCheck 
} from './ParticipantForm.styled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  const getFieldTouched = getIn(formikInstance.touched, field);
  return {
    touched: getFieldTouched,
    error: (
      <FormErrorMessage
        touched
        errors={getFieldError}
      />
    )
  }
}
  
const ParticipantForm = ({ formikInstance, participantIndex, entityTypes, countries, nonEditable, isAtke, isConsortium, isPublic, nonEditableMgm, cancelled}) => {
  const {t} = useTranslation();
  const { getFieldProps, touched, errors, values, setFieldValue, handleChange} = formikInstance;
  const fieldPath = (field) => `participantList.${participantIndex}.${field}`;
  const [disabled, setDisabled] = useState(nonEditable);

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr);
    return date.format("DD/MM/YYYY");
  }

  useEffect(() => {
    setDisabled(values.participantList[participantIndex].confirmed);
  }, []);

  useEffect(() => {
    document.getElementById(`WACheck${participantIndex}`).checked = values.participantList[participantIndex].willAttendTrue === true;
    document.getElementById(`WNACheck${participantIndex}`).checked = values.participantList[participantIndex].willNotAttend === true;
  }, [])

  return (
    <div style={{marginLeft: '30px'}}>
      <Row>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F1_S2_First_name')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                type="text"
                name={fieldPath('firstName')}
                { ...getFieldProps(fieldPath('firstName')) }
                className={touched[fieldPath('firstName')] && errors[fieldPath('firstName')] ? "error" : null}
                disabled={nonEditable || disabled}
              />
              {fieldInfo(formikInstance, fieldPath('firstName')).error}
            </Col>
          </Form.Group>  
        </Col>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F1_S2_Last_Name')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                type="text"
                name={fieldPath('lastName')}
                { ...getFieldProps(fieldPath('lastName')) }
                className={touched[fieldPath('lastName')] && errors[fieldPath('lastName')] ? "error" : null}
                disabled={nonEditable || disabled}
              />
              {fieldInfo(formikInstance, fieldPath('lastName')).error}
            </Col>
          </Form.Group>  
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F3_S7_Participant_nationality')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                name={fieldPath('nationalityId')}
                as="select"
                { ...getFieldProps(fieldPath('nationalityId')) }
                className={touched[fieldPath('nationalityId')] && errors[fieldPath('nationalityId')] ? "error" : null}
                disabled={nonEditable || disabled}
              >
                <option value="">{t('Placeholder_select')}</option>
                {countries.map(media => (
                  <option key={media.id} value={media.id}>{t(media.name)}</option>
                ))}
              </Form.Control>
              {fieldInfo(formikInstance, fieldPath('nationalityId')).error}
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F3_S7_Participant_city_departure')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                type="text"
                name={fieldPath('city')}
                { ...getFieldProps(fieldPath('city')) }
                className={touched[fieldPath('city')] && errors[fieldPath('city')] ? "error" : null}
                disabled={nonEditable || disabled}
              />
              {fieldInfo(formikInstance, fieldPath('city')).error}
            </Col>
          </Form.Group>  
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F3_S7_Participant_organization')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                type="text"
                name={fieldPath('organizationName')}
                { ...getFieldProps(fieldPath('organizationName')) }
                className={touched[fieldPath('organizationName')] && errors[fieldPath('organizationName')] ? "error" : null}
                disabled={nonEditable || disabled}
              />
              {fieldInfo(formikInstance, fieldPath('organizationName')).error}
            </Col>
          </Form.Group>  
        </Col>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F3_S7_Participant_type_entity')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                name={fieldPath('entityTypeId')}
                as="select"
                { ...getFieldProps(fieldPath('entityTypeId')) }
                className={errors[fieldPath('entityTypeId')] ? "error" : null}
                disabled={nonEditable || disabled}
              >
                <option value="">{t('Placeholder_select')}</option>
                {entityTypes.map(media => (
                  <option key={media.id} value={media.id}>{t(`entity_type_${media.id}`)}</option>
                ))}
              </Form.Control>
              {fieldInfo(formikInstance, fieldPath('entityTypeId')).error}
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F1_S2_Email')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                type="text"
                name={fieldPath('email')}
                { ...getFieldProps(fieldPath('email')) }
                className={touched[fieldPath('email')] && errors[fieldPath('email')] ? "error" : null}
                disabled={nonEditable || disabled}
              />
              {fieldInfo(formikInstance, fieldPath('email')).error}
            </Col>
          </Form.Group>  
        </Col>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F1_S2_Phone_number')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                type="text"
                name={fieldPath('phone')}
                { ...getFieldProps(fieldPath('phone')) }
                className={touched[fieldPath('phone')] && errors[fieldPath('phone')] ? "error" : null}
                disabled={nonEditable || disabled}
              />
              {fieldInfo(formikInstance, fieldPath('phone')).error}
            </Col>
          </Form.Group>  
        </Col>
      </Row>

      <StyledCheck style={{marginTop: '20px'}}>
        <Form.Group>
          {t('F5_S7_Participant_confirmation_check1')}
          <Row style={{marginTop: '10px'}}>
            <label>
              <Form.Check
                id={`WACheck${participantIndex}`}
                disabled={IsAssistanceCheckboxDisabled(nonEditable, disabled, isAtke)} 
                style={{marginLeft: '15px'}} 
                name="willAttendTrue" 
                type="checkbox" 
                checked={values.participantList[participantIndex].willAttendTrue}
                { ...getFieldProps(fieldPath('willAttendTrue')) }
                onChange={(e) => {
                  setFieldValue(fieldPath('willAttendTrue'), true);
                  setFieldValue(fieldPath('willNotAttend'), false);
                  handleChange(e);
                }}
                label={t('F5_S7_Participant_attend')} 
              />
            </label>
            <label>
              <Form.Check
                id={`WNACheck${participantIndex}`}
                disabled={IsAssistanceCheckboxDisabled(nonEditable, disabled, isAtke)} 
                style={{marginLeft: '15px'}} 
                name="willNotAttend" 
                checked={values.participantList[participantIndex].willNotAttend}
                type="checkbox" 
                { ...getFieldProps(fieldPath('willNotAttend')) }
                onChange={(e) => {
                  setFieldValue(fieldPath('willNotAttend'), true);
                  setFieldValue(fieldPath('willAttendTrue'), false);
                  handleChange(e);
                }}
                label={t('F5_S7_Participant_Not_attend')} 
              />
            </label>
          </Row>
          {t('F5_S7_Participant_confirmation_check2')}
        </Form.Group>
      </StyledCheck>

      {values.confirmed 
        &&
        <div style={{marginTop: '10px'}}>
          {`${t('F5_S7_Confirmed')}: ${values.confirmTimestamp? formatDate(values.confirmTimestamp): ""}`}
        </div>
      }
      
      {!isPublic && <>
        <StyledCheck style={{marginTop: '20px'}}>
          <Form.Group style={{marginBottom: '10px'}}>
              <Form.Check
                style={{marginLeft: '15px'}} 
                name={fieldPath('mgmtTicketPurchased')}
                disabled={!(isAtke || isConsortium) ||  (nonEditableMgm && (isAtke || isConsortium) || cancelled)}
                type="checkbox" 
                { ...getFieldProps(fieldPath('mgmtTicketPurchased')) }
                checked={values.participantList[participantIndex].mgmtTicketPurchased }
                label={t('F5_S7_Ticket_purchased')} 
              />
          </Form.Group>
      </StyledCheck>

      {
        values.participantList[participantIndex].mgmtTicketTimestamp  &&
        <div style={{marginTop: '10px'}}>
            {`${t('F5_S7_Confirmed')}: ${values.participantList[participantIndex].mgmtTicketTimestamp? formatDate(values.participantList[participantIndex].mgmtTicketTimestamp): ""}`}
        </div>
      }

      </>}

    </div>
  )

}

export default ParticipantForm;
