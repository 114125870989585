


import { FormFielErrorMessage } from "components/CommonStyled";
import React from "react";
import { useTranslation } from "react-i18next";

const FormErrorMessage = ({ touched, errors, style = undefined }) => {
  const { t } = useTranslation();
  return (
    touched && errors && typeof errors === 'string' ? (
      <FormFielErrorMessage style={style}>
        {t(errors)}
      </FormFielErrorMessage>
    ) : null
  );
};

export default FormErrorMessage;
