import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextValue from 'components/TextValue';
import {
  StyledFormLabel,
  StyledSeparator,
  StyledRadioSection,
  StyledRadioLabel,
} from '../Section3.styled';

import { useTranslation } from 'react-i18next';

const CoapplicantForm = ({ coapplicantIndex, data }) => {
  const {t} = useTranslation();

  return (
    <>
      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Entity_name')}:</StyledFormLabel>
        <TextValue value={data.entityName} />
      </Form.Group>  
                  
      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Official_acronym')}:</StyledFormLabel>
        <TextValue value={data.acronym} />
      </Form.Group>  
                  
      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Type_of_entity')}:</StyledFormLabel>
        <TextValue value={data.entityType? t(`entity_type_${data.entityType.id}`): ' - '} />
      </Form.Group>  
                  
      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Role')}: </StyledFormLabel>
        <Col lg={8} style={{marginTop: '10px'}}>
          
        <StyledRadioLabel>
            <input 
              disabled
              type="radio" 
              value="0"
              checked={data.role == 0}
              style={{marginRight: '10px'}}
            />
            {t('F1_S2_Recipient')}
          </StyledRadioLabel>
          
          <StyledRadioLabel>
            <input 
              disabled
              type="radio" 
              value="1"
              checked={data.role == 1}
              style={{marginRight: '10px'}}
            />
             {t('F1_S2_Provider1')}
          </StyledRadioLabel>

          <StyledRadioLabel>
            <input 
              disabled
              type="radio" 
              value="2"
              checked={data.role == 2}
              style={{marginRight: '10px'}}
            />
             {t('F1_S2_Provider2')}
          </StyledRadioLabel>
        </Col>
      </Form.Group>  

      <Form.Group as={Row} style={{alignItems: "center"}}>
        <StyledFormLabel className="align-self-start" column lg={4} > {t('F1_S2_Contribution')}: </StyledFormLabel>
        <Col lg={8}>
          <StyledRadioSection>
            <Form.Check name="optionA" type="checkbox" checked={data.optionA} value={data.optionA} disabled label={t('F1_S2_Provider_detail_A')} />
            <Form.Check name="optionB" type="checkbox" checked={data.optionB} value={data.optionB} disabled label={t('F1_S2_Provider_detail_B')} />
            <Form.Check name="optionC" type="checkbox" checked={data.optionC} value={data.optionC} disabled label={t('F1_S2_Provider_detail_C')} />
            <Form.Check name="optionD" type="checkbox" checked={data.optionD} value={data.optionD} disabled label={t('F1_S2_Provider_detail_D')} />
            <TextValue value={data.other} />
          </StyledRadioSection>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Entity_address')}:</StyledFormLabel>
        <TextValue value={data.address} />
      </Form.Group>  

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Country')}:</StyledFormLabel>
        <TextValue value={data.country? t(data.country.name): ' - '} />
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_State_Province_Region')}:</StyledFormLabel>
        <TextValue value={data.stateName} />
      </Form.Group>  

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_City')}:</StyledFormLabel>
        <TextValue value={data.cityName} />
      </Form.Group>  

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Website')}:</StyledFormLabel>
        <TextValue value={data.web} />
      </Form.Group>  
                  
      <StyledSeparator />
                  
      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Responsisble_person')}:</StyledFormLabel>
        <TextValue value={data.firstNameCoApplicant} />
        <TextValue value={data.lastNameCoApplicant} />
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Position')}:</StyledFormLabel>
        <TextValue value={data.position} />
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Email')}:</StyledFormLabel>
        <TextValue value={data.email} />
      </Form.Group>

      <Form.Group as={Row}>
        <StyledFormLabel column lg={4}>{t('F1_S2_Phone_number')}:</StyledFormLabel>
        <TextValue value={data.phone} />
      </Form.Group>
                  
      <Form.Group as={Row}>
        <Col lg={12}>
          <Form.Check
            disabled
            name="auth"
            type="checkbox" 
            value={data.auth} 
            checked={data.auth}
            style={{marginLeft: "50px", marginTop: "10px"}}
            label={t('F1_S3_Authorize_user')}
          />
        </Col>
      </Form.Group>
    </>
  )

}

export default CoapplicantForm;
