import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getContacts2 } from 'services/initiative';
import { ExportContactsToXLSX } from 'helpers/xlsxExporter';
import { PrimaryButton } from 'components/Buttons';

const CustomExportXLSXButton = (props) => {
  const { t } = useTranslation();
  const [exporting, setExporting] = useState(false);
  const [exportData, setExportData] = useState(null);
  const [buttonReady, setButtonReady] = useState(false);

  const handleClick = async () => {
    setExporting(true);
    const data = await getAllValues();  
    setExportData(data);
    setButtonReady(true);
    setExporting(false);
  };

  async function getAllValues() {
    try {
      let params = {};
      if(props.filter.initiative != 0)
        params.initiativeId = props.filter.initiative;
      if(props.filter.type != 0)
        params.type = props.filter.type;
      if(props.filter.keyword != null)
        params.keyword = props.filter.keyword;
      const response = await getContacts2(params);
      const rowValues = response.content;
      response.content.forEach(e => {
        e.type = e.type !== null ? t(`type_${e.type+1}`) : "-";
        e.country = e.country !== null ? t(e.country) : "-";
        e.positionR = e.positionR !== null ? e.positionR : "-";
        e.relation = e.relation !== null ? e.relation : "-";
        e.phone = e.phone !== null ? e.phone : "-";
        e.email = e.email !== null ? e.email : "-";
      })
      return rowValues;
    } catch (error) {
      console.log(error)
    }
  }

  const onDownloadReady = () => {
    setExportData(null);
    setButtonReady(false);
  };

  return (
    <>
      <ExportContactsToXLSX data={exportData} isReady={buttonReady} onReady={onDownloadReady} />
      <PrimaryButton
        onClick={handleClick}
        style={{width: '220px', marginLeft: '-130px'}}
        disabled={exporting}
      >
        { t('Export_to_XLSX') }
      </PrimaryButton>
    </>
  );
};

export default CustomExportXLSXButton;