import React from 'react'
import { useTranslation } from 'react-i18next';
import TargetForm from 'components/Forms/Form4/Section11/TargetForm';
import TextValue from 'components/TextValue';
import Row from 'react-bootstrap/Row';
import { Form } from 'react-bootstrap'
import { SeparatorDotted } from 'components/CommonStyled';
import FakeAccordionItem from '../../../FakeAccordionItem';

const ObjectiveForm = ({ title, data }) => {
  const { t } = useTranslation();
  
  return (
    <div>     
      <Form.Group as={Row}>
        <Form.Label column xs={6}>{title}:</Form.Label>
        <TextValue value={data.goalId? t(`SDG_Goal${data.goalId}`): " - "} />
      </Form.Group>

      <Form.Group>
        <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}} >{t('F3_S11_Justification')}: </Form.Label>
        <Form.Control
          name="justification"
          as="textarea" 
          rows={3} 
          placeholder={t('Maximum_650')}
          style={{resize: 'none'}}
          value={data.justification? data.justification: '-'}
          disabled={true}
        />
      </Form.Group>

      {(data.targetList && data.targetList.map((target, key) => (
        <React.Fragment  key={key}>
          <FakeAccordionItem title={`${t('F3_S11_Target')} ${key+1}`}>
            <div style={{marginLeft: '50px'}}>
              <TargetForm 
                targetIndex={key} 
                data={target}
              /> 
            </div>
          </FakeAccordionItem>
          <SeparatorDotted />
        </React.Fragment>
      )))}
    </div>            
  )
}

export default ObjectiveForm;