export const formInitialValues = {
  overviewRelationship: '',
  overviewParticipation: '',
  overviewKnowledge: '',
  explainDerivation: '',
  explainData: '',
  justificationBeneficiaries: '',
  beneficiaryStrengthening: '',
  additionalBenefits: '',
}
