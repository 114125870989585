import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { parseDateString } from 'helpers/dateHelpers'
import { formatDateDDMMYY } from 'helpers/dateHelpers'

import { PrimaryButton } from 'components/Buttons'
import TextValue from 'components/TextValue'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import 'react-datepicker/dist/react-datepicker.css'
import { Form } from 'react-bootstrap'
import {
  SeparatorDotted,
  CenteredModalButtonContainer,
} from 'components/CommonStyled'
import { RadioLabel } from 'components/Forms/Forms.styled'
import { StyledFormLabel } from '../Section7.styled'

const GeneralInfoForm = ({ handleCloseOk, activity }) => {
  const { t } = useTranslation()
  const [formValues, setFormValues] = useState(activity)

  useEffect(() => {
    const formValuesCopy = { ...activity }
    formValuesCopy.fromDate = formValuesCopy.fromDate
      ? parseDateString(formValuesCopy.fromDate)
      : ''
    formValuesCopy.toDate = formValuesCopy.toDate
      ? parseDateString(formValuesCopy.toDate)
      : ''
    setFormValues(formValuesCopy)
  }, [])

  return (
    <div>
      <Form.Group as={Row}>
        <Form.Label column xs={1}>
          {t('F1_S7_type')}:
        </Form.Label>
        <TextValue
          value={
            formValues.activityType
              ? t(`activity_types_${formValues.activityType.id}`)
              : ' - '
          }
        />
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column xs={2}>
          {t('F1_S7_start_day')}:
        </Form.Label>
        <TextValue value={formatDateDDMMYY(formValues.fromDate)} />
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column xs={2}>
          {t('F1_S7_End_day')}:
        </Form.Label>
        <TextValue value={formatDateDDMMYY(formValues.toDate)} />
      </Form.Group>

      <Form.Label row xs={2}>
        {t('F1_S7_Short_description')}:
      </Form.Label>
      <Form.Group>
        <TextValue value={formValues.description} />
      </Form.Group>

      <SeparatorDotted />

      <Form.Group as={Row}>
        <StyledFormLabel
          as={Row}
          style={{ marginTop: '30px', marginLeft: '0px' }}
        >
          {t('F1_S7_Format')}:
        </StyledFormLabel>
        <Col lg={8} style={{ marginLeft: '50px', marginTop: '30px' }}>
          <RadioLabel>
            <input
              type="radio"
              checked={String(formValues.format) === '0'}
              style={{ marginRight: '10px' }}
              disabled
            />
            {t('F1_S7_Format0')}
          </RadioLabel>

          <RadioLabel>
            <input
              type="radio"
              checked={String(formValues.format) === '1'}
              style={{ marginRight: '10px' }}
              disabled
            />
            {t('F1_S7_Format1')}
          </RadioLabel>

          <RadioLabel>
            <input
              type="radio"
              checked={String(formValues.format) === '2'}
              style={{ marginRight: '10px' }}
              disabled
            />
            {t('F1_S7_Format2')}
          </RadioLabel>
        </Col>
      </Form.Group>

      {String(formValues.format) !== '2' && (
        <>
          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {t('F1_S7_Country_activity')}:
            </Form.Label>
            <TextValue
              value={formValues.country ? t(formValues.country.name) : ' - '}
            />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={3}>
              {t('F1_S2_State_Province_Region')}:
            </Form.Label>
            <TextValue value={formValues.stateName} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {t('F1_S2_City')}:
            </Form.Label>
            <TextValue value={formValues.cityName} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={4}>
              {t('F1_S7_Countries_additional')}:
            </Form.Label>
            <TextValue value={formValues.countriesAdditional} />
          </Form.Group>
        </>
      )}

      <SeparatorDotted />

      <Form.Group as={Row}>
        <Form.Label column xs={2}>
          {t('F1_S7_Duration')}:
        </Form.Label>
        <TextValue value={formValues.duration} />
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column xs={2}>
          {t('F1_S7_Number_participants')}:
        </Form.Label>
        <TextValue value={formValues.participants} />
      </Form.Group>

      <SeparatorDotted />

      {formValues.activityType && formValues.activityType.id == 4 && (
        <>
          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {t('F1_S7_Number_experts')}:
            </Form.Label>
            <TextValue value={formValues.experts} />
          </Form.Group>

          <Form.Label
            xs={4}
            as={Row}
            style={{ marginTop: '30px', marginBottom: '15px' }}
          >
            {t('F1_S7_Number_wd')}:
          </Form.Label>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {t('F1_S7_Remote')}:
            </Form.Label>
            <TextValue value={formValues.daysRemote} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {t('F1_S7_Onsite')}:
            </Form.Label>
            <TextValue value={formValues.daysOnsite} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {t('F1_S7_Total')}:
            </Form.Label>
            <TextValue value={formValues.daysRemote + formValues.daysOnsite} />
          </Form.Group>

          <SeparatorDotted />
        </>
      )}
      <Form.Group>
        <Row style={{ alignItems: 'center' }}>
          <Form.Label column xs={2}>
            {t('F3_S7_General_work_language')}
          </Form.Label>
          <Col xs={2}>
            <Form.Check
              disabled={true}
              style={{ marginLeft: '15px' }}
              name="workLang1"
              type="checkbox"
              checked={formValues.workLang1}
              label={t('English')}
            />
          </Col>
          <Col xs={2}>
            <Form.Check
              disabled={true}
              style={{ marginLeft: '15px' }}
              name="workLang2"
              type="checkbox"
              checked={formValues.workLang2}
              label={t('French')}
            />
          </Col>
          <Col xs={2}>
            <Form.Check
              disabled={true}
              style={{ marginLeft: '15px' }}
              name="workLang3"
              type="checkbox"
              checked={formValues.workLang3}
              label={t('Portugues')}
            />
          </Col>
          <Col xs={2}>
            <Form.Check
              disabled={true}
              style={{ marginLeft: '15px' }}
              name="workLang4"
              type="checkbox"
              checked={formValues.workLang4}
              label={t('Spanish')}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>
          {t('F3_S7_General_learning_objectives')}:
        </Form.Label>
        <TextValue value={formValues.learning} />
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>{t('F3_S7_General_alignment')}:</Form.Label>
        <TextValue value={formValues.generalAlignment} />
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>
          {t('F3_S7_General_participation_members')}:
        </Form.Label>
        <TextValue value={formValues.generalParticipationMembers} />
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>
          {t('F3_S7_General_proposal_cross_issues')}:
        </Form.Label>
        <TextValue value={formValues.generalProposalCrossIssues} />
      </Form.Group>

      <Form.Group>
        <Form.Label xs={4}>
          {t('F3_S7_General_brief_description_schedule')}:
        </Form.Label>
        <TextValue value={formValues.descSchedule} />
      </Form.Group>

      <CenteredModalButtonContainer>
        <PrimaryButton
          onClick={() => {
            handleCloseOk()
          }}
        >
          OK
        </PrimaryButton>
      </CenteredModalButtonContainer>
    </div>
  )
}

export default GeneralInfoForm
