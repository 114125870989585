import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  fundContribution: Yup.number()
    .min(0, 'F1_S7_validation10')
    .required('F1_S7_validation11'),
    otherContributions: Yup.number()
    .min(0, 'F1_S7_validation10')
    .required('F1_S7_validation11'),
});
