import React from 'react';
import { observer } from 'mobx-react-lite';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import { useTranslation } from 'react-i18next';
import { ResourceNameSpan } from './Styled';

const Section8 = () => {
  const { t } = useTranslation();

  return (
    <FormLayout>
      <FormContainer
        number={8}
        title={t('F3_S8_title')}
        formId="form55section8"
        showSavedMessage={false}
      >
        <div style={{ marginLeft: '50px'}}>
          <ResourceNameSpan>{t('not_available_for_funds')}</ResourceNameSpan>
        </div>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section8);