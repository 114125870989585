import React, { createRef, useEffect, useMemo } from "react";
import { StrGantt } from 'gantt';

export default function GenericGantt ({ data }) {
  const ganttRef = createRef();

  const gantt = useMemo(() => {
    return new StrGantt(data, {
      viewMode: 'week'
    });
  }, [data]);

  useEffect(() => {
    ganttRef.current.innerHTML = gantt.render();
  });

  useEffect(() => {
    gantt.setData(data)
  }, [data, gantt]);

  return (
    <div
      style={{
        marginBottom: '20px',
        overflowX: 'auto',
        maxWidth: '100vw'
      }}
      ref={ganttRef}
    />
  )
};