import axiosInstance from "./axiosInstance";

export const getMaintenanceVariables = async () => {
  const url = "/api/public/feature/";
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export const getUploadFormatsConstants = async () => {
  const url = "/api/constants/uploadTypes/";
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}



