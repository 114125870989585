import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
    overviewMeasures: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
    tciContribute:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
    tciApproach:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
});