import styled, {css} from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledTitle = styled.h5`
  ${({ theme }) => css`
    color: ${theme.brandColors.blue};
  `}
  margin-bottom: 30px;
`;

export const StyledFormLabel = styled(Form.Label)`
    text-align: justify;
    line-height: 1.3;
`;

export const StyledDivWrapper = styled.div`
  margin-left: 50px;
`;