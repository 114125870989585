import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  entityName: Yup.string()
    .min(2, 'F1_S2_validation1')
    .max(200, 'F1_S2_validation2')
    .required('F3_S7_validation3'),
  acronym: Yup.string()
    .min(2, 'F1_S2_validation1')
    .max(200, 'F1_S2_validation2')
    .nullable(true),
  entityTypeId: Yup.string().required('F3_S7_validation3'),
  countryId: Yup.string().required('F3_S7_validation3'),
  role: Yup.string()
    .oneOf(["","0","1","2"])
    .required('F3_S7_validation3'),
});
