import React from 'react'
import { useTranslation } from 'react-i18next'
import StatisticsTabs from 'components/Tabs'
import Tab2 from './tabs/Tab2'
import Tab1 from './tabs/Tab1'
import { PageHeader } from '../CommonStyled/Tabs.styled'
import PageTitle from 'components/PageTitle'

const StatsResults = () => {
  const { t } = useTranslation()
  const titles = [t('Statistics_results_tab2'), t('Statistics_results_tab1')]
  const renderTab1Content = () => <Tab1 />
  const renderTab2Content = () => <Tab2 />

  const contents = [renderTab2Content, renderTab1Content]

  return (
    <>
      <PageHeader>
        <PageTitle title={t('menu_admin_6_7b')} />
      </PageHeader>
      <StatisticsTabs titles={titles} contents={contents} />
    </>
  )
}

export default StatsResults

