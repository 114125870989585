import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  rights: Yup.string(),
  environment: Yup.string(),
  link: Yup.string(),
  // continued: Yup.string()
  //  .min(10, 'F3_S5_validation1')
  //  .max(650, 'F1_S6_validation9'),
  leaps: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(650, 'F1_S6_validation9')
    .nullable(true),
  projection: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(650, 'F1_S6_validation9')
    .nullable(true),
  advantage: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(650, 'F1_S6_validation9'),
  consolidation: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(650, 'F1_S6_validation9'),
});
