import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextValue from 'components/TextValue'
import { ErrorButton } from 'components/Buttons/Buttons.styled'
import { deleteF5S2Applicant, deleteF5S3CoApplicant } from 'services/initiative'
import ConfirmCloseModal from 'components/ConfirmCloseModal'
import { useParams } from 'react-router-dom'

const AddedPersonsList = ({ addedUsersList, s2 }) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [closeModalShow, setCloseModalShow] = useState({show: false, element: null})
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async (value) => {
    setDeleting(true);
    let response;
    try {
      if (s2) {
        response = await deleteF5S2Applicant(id, value)
      } else {
        response = await deleteF5S3CoApplicant(id, value)
      }
      window.location.replace('')
    } catch (error) {
      console.log(error)
    }

    if(!response) {
      setDeleting(false)
    }
  }

  return (
    <>
      {closeModalShow.show && (
        <ConfirmCloseModal
          show={closeModalShow.show}
          handleCloseConfirm={() => {
            setCloseModalShow({show: false, element: null})
          }}
          handleActionOnConfirm={() => {
            handleDelete({ email: closeModalShow.element.email })
          }}
          text={t('Confirmation delete user')}
        />
      )}
      <div
        style={{
          marginTop: '30px',
          marginLeft: '50px',
          marginBottom: '20px',
          fontSize: '17px',
        }}
      >
        {s2 ? t('F5_S2_added_users') : t('F5_S3_added_users')}:
      </div>

      {addedUsersList?.map((element) => (
        <div style={{ marginTop: '30px', marginLeft: '50px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            {t('F1_S2_Entity_name')}
            <TextValue value={`${element.lastName}, ${element.name}`} />,
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            {t('F1_S2_Entity_address')}:
            <TextValue value={element.email} />
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            {t('F1_S2_Type_of_entity')}:
            <TextValue value={element.organization} />
          </div>
          <ErrorButton
            style={{ marginTop: '10px' }}
            size="sm"
            disabled={deleting}
            onClick={() => setCloseModalShow({show: true, element: element})}
          >
            {t('Delete_person_button')}
          </ErrorButton>
        </div>
      ))}
    </>
  )
}

export default AddedPersonsList
