import * as Yup from 'yup';
import i18n from 'i18n';

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'F1_S2_validation16')
    .max(200, 'F1_S2_validation17'),
  lastName: Yup.string()
    .min(2, 'F1_S2_validation16')
    .max(200, 'F1_S2_validation17'),
  position: Yup.string()
    .min(2, 'F1_S2_validation18')
    .max(100, 'F1_S2_validation19'),
  phone: Yup.string()
    .min(2, 'F1_S2_validation20')
    .max(100, 'F1_S2_validation21')
    .matches(phoneRegExp, 'F1_S2_validation22'),
  email: Yup.string()
    .email('F1_S2_validation23')
    .max(100, 'F1_S2_validation24'),
});
