import React, { useState, useEffect } from 'react'
import htmlParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledNumberCircle, 
  StyledTitle, 
  StyledBody 
} from '../CommonStyles'
import {
  StyledVideosContainer,
  StyledVideoItem,
  StyledCheckboxContainer,
  StyledImageContainer,
  StyledIntroParagraph
} from 'components/Forms/Form2/Section1/Section1.styled';

const Section1 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledNumberCircle> 1 </StyledNumberCircle>
          <StyledTitle> {t('F1_S1_title')} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <StyledIntroParagraph>
          { htmlParser(t('F1_S1_intro')) }
        </StyledIntroParagraph>

        <StyledVideosContainer>
          <StyledVideoItem>
            <StyledCheckboxContainer>
              <input 
                type="checkbox" 
                name="checkOne" 
                checked={formValues?.checkOne} 
                disabled
              />                    
            </StyledCheckboxContainer>
            <StyledImageContainer>
              { htmlParser(t('F1_S1_aknowledge1'))}
            </StyledImageContainer>
          </StyledVideoItem>

          <StyledVideoItem>
            <StyledCheckboxContainer > 
              <input 
                type="checkbox" 
                name="checkTwo" 
                checked={formValues?.checkTwo} 
                disabled
              />                    
            </StyledCheckboxContainer>
            <StyledImageContainer>
            { htmlParser(t('F1_S1_aknowledge2'))}
            </StyledImageContainer>
          </StyledVideoItem>
          <StyledVideoItem>
            <StyledCheckboxContainer>
              <input 
                type="checkbox" 
                name="checkThree" 
                checked={formValues?.checkThree} 
                disabled
              />                    
            </StyledCheckboxContainer>
            <StyledImageContainer>
              { htmlParser(t('F1_S1_aknowledge3'))}
            </StyledImageContainer>
          </StyledVideoItem>
          <StyledVideoItem>
            <StyledCheckboxContainer>
              <input 
                type="checkbox" 
                name="checkFive" 
                checked={formValues?.checkFive} 
                disabled
              />                    
            </StyledCheckboxContainer>
            <StyledImageContainer >
              {htmlParser(t('F1_S1_aknowledge5'))}
            </StyledImageContainer>
          </StyledVideoItem>
          <StyledVideoItem>
            <StyledCheckboxContainer>
              <input 
                type="checkbox" 
                name="checkFour" 
                checked={formValues?.checkFour} 
                disabled
              />                    
            </StyledCheckboxContainer>
            <StyledImageContainer >
              {t('F1_S1_aknowledge4')}
            </StyledImageContainer>
          </StyledVideoItem>
        </StyledVideosContainer>
      </StyledBody>
    </SectionContainer>
  )
}

export default Section1;