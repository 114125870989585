import React, { useState, useEffect } from 'react'
import _chunk from 'lodash/chunk'
import { getProductsByTypeSummary } from 'services/initiative'
import { useTranslation } from 'react-i18next'

import Header5 from '../components/Header5'
import Table5 from '../components/Table5'
import Row from 'react-bootstrap/Row'
import { StyledDiv, StyledRow } from '../../CommonStyled/Tabs.styled'

const ProductsByTypeSummary = () => {
  const { t } = useTranslation()
  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const getData = async (param) => {
    setWaiting(true)
    try {
      const response = await getProductsByTypeSummary(param)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <StyledDiv>
      <Row>
        <Header5
          data={data}
          label={t('Statistics_activities_tab5')}
          waiting={waiting}
        />
      </Row>
      <StyledRow>
        <Table5 data={data} waiting={waiting} setData={setData} />
      </StyledRow>
    </StyledDiv>
  )
}

export default ProductsByTypeSummary
