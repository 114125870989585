import React from 'react'
import PageTitle from 'components/PageTitle'
import { PageHeader } from '../CommonStyled/Tabs.styled'
import { useTranslation } from 'react-i18next'
import StatisticsTabs from 'components/Tabs'
import ExecutionSummary from './tabs/ExecutionSummary'

const StatsExecution = () => {
  const { t } = useTranslation()
  const titles = [
    t('Statistics_execution_tab1'),
  ]
  const renderTab1Content = () => <ExecutionSummary />


  const contents = [renderTab1Content]

  return (
    <>
      <PageHeader>
        <PageTitle title={t('menu_admin_6_2')} />
      </PageHeader>
      <StatisticsTabs titles={titles} contents={contents} />
    </>
  )
}

export default StatsExecution
