import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RequiredMark } from 'components/CommonStyled';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  const getFieldTouched = getIn(formikInstance.touched, field);
  return {
    touched: getFieldTouched,
    error: (
      <FormErrorMessage
        touched={getFieldTouched}
        errors={getFieldError}
      />
    )
  }
}
  
const LessonForm = ({ formikInstance, lessonIndex, countries, lessonTypes, lessonMoments, lessonUseForList, defaultValue, nonEditable }) => {
  const {t} = useTranslation();
  const { values, getFieldProps, touched, errors, setFieldValue} = formikInstance;
  const fieldPath = (field) => `lessons.${lessonIndex}.${field}`;

  return (
    <>
      <Form.Group>
        <Form.Label>{t('F5_S15_Lesson_title')}<RequiredMark>*</RequiredMark>:</Form.Label>
        <Form.Control
          type="text"
          name={fieldPath('lessonTitle')}
          { ...getFieldProps(fieldPath('lessonTitle')) }
          className={touched[fieldPath('lessonTitle')] && errors[fieldPath('lessonTitle')] ? "error" : null}
          disabled={nonEditable}
        />
        {fieldInfo(formikInstance, fieldPath('lessonTitle')).error}
      </Form.Group>  

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S15_Lesson_type')}<RequiredMark>*</RequiredMark>:</Form.Label>
        <Col lg={8}>
          <Form.Control
            name={fieldPath('lessonTypeId')}
            as="select"
            { ...getFieldProps(fieldPath('lessonTypeId')) }
            className={touched[fieldPath('lessonTypeId')] && errors[fieldPath('lessonTypeId')] ? "error" : null}
            disabled={nonEditable}
          >
            <option value="">{t('Placeholder_select')}</option>
            {lessonTypes.map(lessonType => (
              <option key={lessonType.id} value={lessonType.id}>{t(`lesson_type_${lessonType.id}`)}</option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath('lessonTypeId')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column xs={7}>{t('F5_S15_Lesson_moment')}<RequiredMark>*</RequiredMark>:</Form.Label>
        <Col xs={3}>
          <Form.Control
            name={fieldPath('lessonMomentId')}
            as="select"
            { ...getFieldProps(fieldPath('lessonMomentId')) }
            className={touched[fieldPath('lessonMomentId')] && errors[fieldPath('lessonMomentId')] ? "error" : null}
            disabled={nonEditable}
          >
            <option value="">{t('Placeholder_select')}</option>
            {lessonMoments.map(lessonMoment => (
              <option key={lessonMoment.id} value={lessonMoment.id}>{t(`lesson_moment_${lessonMoment.id}`)}</option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath('lessonMomentId')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <Form.Label xs={5} lg={4} style={{ marginTop: "10px" }}>{t('F5_S15_Lesson_description')} <RequiredMark>*</RequiredMark>:</Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_1300')}
            name={fieldPath('lessonEvent')}
            {...getFieldProps(fieldPath('lessonEvent'))}
            className={touched[fieldPath('lessonEvent')] && errors[fieldPath('lessonEvent')] ? "error" : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('lessonEvent')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <Form.Label xs={5} lg={4} style={{ marginTop: "10px" }}>{t('F5_S15_Lesson_decision')} <RequiredMark>*</RequiredMark>:</Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_1300')}
            name={fieldPath('lessonDecisions')}
            {...getFieldProps(fieldPath('lessonDecisions'))}
            className={touched[fieldPath('lessonDecisions')] && errors[fieldPath('lessonDecisions')] ? "error" : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('lessonDecisions')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <Form.Label xs={5} lg={4} style={{ marginTop: "10px" }}>{t('F5_S15_Lesson_derived_from')} <RequiredMark>*</RequiredMark>:</Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_1300')}
            name={fieldPath('lessonLearning')}
            {...getFieldProps(fieldPath('lessonLearning'))}
            className={touched[fieldPath('lessonLearning')] && errors[fieldPath('lessonLearning')] ? "error" : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('lessonLearning')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <Form.Label xs={5} lg={4} style={{ marginTop: "10px" }}>{t('F5_S15_Lesson_Opportunities')} <RequiredMark>*</RequiredMark>:</Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_1300')}
            name={fieldPath('lessonImprovement')}
            {...getFieldProps(fieldPath('lessonImprovement'))}
            className={touched[fieldPath('lessonImprovement')] && errors[fieldPath('lessonImprovement')] ? "error" : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('lessonImprovement')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S15_Lesson_This_knowledge')}<RequiredMark>*</RequiredMark>:</Form.Label>
        <Col xs={8}>
          <Form.Control
            name={fieldPath('lessonUseForId')}
            as="select"
            { ...getFieldProps(fieldPath('lessonUseForId')) }
            className={touched[fieldPath('lessonUseForId')] && errors[fieldPath('lessonUseForId')] ? "error" : null}
            disabled={nonEditable}
          >
            <option value="">{t('Placeholder_select')}</option>
            {lessonUseForList.map(useFor => (
              <option key={useFor.id} value={useFor.id}>{t(`lesson_use_for_${useFor.id}`)}</option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath('lessonUseForId')).error}
        </Col>
      </Form.Group>
      
      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F5_S15_Lesson_Countries_reference')}<RequiredMark>*</RequiredMark>:</Form.Label>
        <Col xs={4}>
          <Select
            isMulti
            options={countries}
            onChange={val => {
              const valuesArray = val.map(a => a.value);
              setFieldValue(fieldPath('lessonCountryIdList'), valuesArray)
            }}
            placeholder={t('Placeholder_select')}
            closeMenuOnSelect={false}
            defaultValue={defaultValue}
          />

          {fieldInfo(formikInstance, fieldPath('lessonCountryIdList')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <Form.Label xs={5} lg={4} style={{ marginTop: "10px" }}>{t('F5_S15_Lesson_Actors_involved')} <RequiredMark>*</RequiredMark>:</Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_1300')}
            name={fieldPath('lessonActors')}
            {...getFieldProps(fieldPath('lessonActors'))}
            className={touched[fieldPath('lessonActors')] && errors[fieldPath('lessonActors')] ? "error" : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('lessonActors')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Col>
          <Form.Label xs={5} lg={4} style={{ marginTop: "10px" }}>{t('F5_S15_Lesson_Keywords')} <RequiredMark>*</RequiredMark>:</Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_1300')}
            name={fieldPath('lessonKeywords')}
            {...getFieldProps(fieldPath('lessonKeywords'))}
            className={touched[fieldPath('lessonKeywords')] && errors[fieldPath('lessonKeywords')] ? "error" : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('lessonKeywords')).error}
        </Col>
      </Form.Group>
    </>
  )

}

export default LessonForm;
