export const formInitialValues = {
  methodology: '',
  activities: [
    {
      title: '',
      countryId: '',
      format: '',
      activityTypeId: '',
      productCount: null,
      mainProducts: '',
      fromDate: '',
      toDate: '',
    },
  ],
}
