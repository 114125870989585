import React, { useState } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { formInitialValues } from './NewCommunicationToolForm.initialValues'
import { validationSchema } from './NewCommunicationToolForm.validations'
import { addF5S14CommunicationTool } from 'services/initiative'
import { useConstantsForm5 } from 'customHooks/useConstantsForm5'
import CustomModal from 'components/Modal'
import Modal from 'react-bootstrap/Modal'

import { ErrorButton, StyledSendButton } from 'components/Buttons'
import Form from 'react-bootstrap/Form'
import {
  RequiredMark,
  CenteredModalButtonContainer,
} from 'components/CommonStyled'
import {
  StyledModalBody,
  ModalTitle,
  StyledCol,
  StyledDiv,
} from './NewCommunicationToolForm.styled'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const NewCommunicationToolForm = ({
  handleCloseOk,
  handleClose,
  handleCloseOnError,
  nonEditable,
  setToolsLocalList,
  show,
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { mediaTypes } = useConstantsForm5()
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    message: t('Error try again later'),
    type: 'error',
  })

  const handleSubmit = async (values) => {
    try {
      const response = await addF5S14CommunicationTool(id, values)
      setToolsLocalList(response)
      handleCloseOk()
    } catch (error) {
      handleCloseOnError()
    }
  }

  return (
    <>
      <CustomModal
        show={modalInfo.isOpen}
        onHide={() => setModalInfo({ ...modalInfo, isOpen: false })}
        message={modalInfo.message}
        type="error"
      />

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        animation={false}
      >
        <StyledModalBody>
          <ModalTitle>
            <span>{t('F5_S14_Modal_Title1')}</span>
          </ModalTitle>
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              handleBlur,
            }) => (
              <Form onSubmit={handleSubmit} id="newCommunicationTool">
                <StyledDiv>
                  <StyledCol>
                    <Form.Label>
                      {t('F1_S8_Media_type')}
                      <RequiredMark>*</RequiredMark>:
                    </Form.Label>
                  </StyledCol>
                  <StyledCol>
                    <Form.Control
                      name="mediaTypeId"
                      as="select"
                      onChange={handleChange}
                      value={values.mediaTypeId}
                      disabled={nonEditable}
                    >
                      <option value="">{t('Placeholder_select')}</option>
                      {mediaTypes.map((mediaType) => (
                        <option key={mediaType.id} value={mediaType.id}>
                          {t(`media_type_${mediaType.id}`)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched={touched.mediaTypeId}
                      errors={errors.mediaTypeId}
                    />
                  </StyledCol>
                </StyledDiv>

                <StyledDiv>
                  <StyledCol sm={3}>
                    <Form.Label>{t('F1_S8_Link_account')}:</Form.Label>
                  </StyledCol>
                  <StyledCol sm={9}>
                    <Form.Control
                      type="text"
                      name="url"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.url}
                      className={touched.url && errors.url ? 'error' : null}
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.url}
                      errors={errors.url}
                    />
                  </StyledCol>
                </StyledDiv>

                <StyledCol>
                  <Form.Label>{t('F1_S8_Media_name')}:</Form.Label>

                  <Form.Control
                    name="comments"
                    as="textarea"
                    rows={2}
                    placeholder={t('Maximum_1000')}
                    style={{ resize: 'none' }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.comments}
                    className={
                      touched.comments && errors.comments ? 'error' : null
                    }
                    disabled={nonEditable}
                  />
                  <FormErrorMessage
                    touched={touched.comments}
                    errors={errors.comments}
                  />
                </StyledCol>

                <CenteredModalButtonContainer>
                  <ErrorButton
                    style={{ marginRight: '20px' }}
                    onClick={handleClose}
                  >
                    {t('Button_cancel')}
                  </ErrorButton>
                  <StyledSendButton type="submit" form="newCommunicationTool">
                    {t('Button_save')}
                  </StyledSendButton>
                </CenteredModalButtonContainer>
              </Form>
            )}
          </Formik>
        </StyledModalBody>
      </Modal>
    </>
  )
}

export default NewCommunicationToolForm
