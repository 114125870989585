
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';


export const StyledLeftContent = styled(Col)`
  text-align: right;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

export const StyledContainer = styled.div`
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 170px;
  margin-top: 2%;
`;

export const StyledTitle = styled.div`
  margin-bottom: 10px;
  color: #88c564;
  font-size: 40px;
  font-weight: 600;
`;

export const StyledTextLeft = styled.div`
  margin-bottom: 10px;
  color: #88c564;
  font-size: 18px;
  font-weight: 500;
`;

export const StyledTextCenter = styled.div`
  margin-bottom: 10px;
  color: #88c564;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
`;

export const StyledDiv = styled.div`
  border: 3px #88c564 solid;
  width: 400px;
  border-radius: 15px;
`;

export const StyledTextRight = styled.div`
  margin-bottom: 25px;
  color: #88c564;
  font-size: 25px;
  font-weight: 600;
`;


export const StyledTextTitle = styled.div`
  margin-bottom: 13px;
  color: #7c8f9c;
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  text-align: justify;
`;

export const StyledButton = styled(Button)`
  background: white;
  color: #15A7CB;
  border: 1px solid #a6b3bb;
  font-weight: 450;
  box-shadow: none !important;
  margin-left: 5px;
`;

export const StyledButtonLink = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  box-shadow: none !important;
  color: #88c564;
  margin-bottom: 4px;
  font-size: 19px;
  font-weight: 600;
`;


export const StyledText = styled.p`
  text-align: justify;
  color: #7c8f9c;
  font-weight: 600;
`;

export const DocumentsImg = styled.img`
  object-fit: contain;
  margin-left: 40px;
`;

export const DocumentImg = styled.img`
  object-fit: contain;
  margin-top:'40px';
  width: 22%;
  height: 22%;
`;


