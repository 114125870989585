import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  date: Yup.date()
    .required('F5_S7_required'),
  title: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .required('F5_S7_required'),
  comments: Yup.string()
    .min(2, 'F3_S7_validation4')
    .max(1300, 'F3_S7_validation8')
    .nullable(true),
  genInfoConfirmed: Yup.boolean(),
});
