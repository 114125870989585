import { useState, useEffect } from 'react';
import { getConstantsForm } from 'services/initiative';

const initialValues = {
  countries: [],
  filteredCountries: [],
  entityTypes: [],
  developmentGoals: [],
  activityTypes: [],
  mediaTypes: [],

}

export const useConstantsForm1 = () => {

  const [values, setValues] = useState(initialValues)

  useEffect(() => {
    async function fetchAPI() {
      try {
        const { 
          countries,
          filteredCountries,
          entityTypes,
          developmentGoals,
          activityTypes,
          mediaTypes
        } = await getConstantsForm('1', {'applicantCountries': 'true'});
        
        setValues(prevState => ({
          ...prevState,
          countries,
          filteredCountries,
          entityTypes,
          developmentGoals,
          activityTypes,
          mediaTypes
        }));
      }
      catch(e){
        console.log(e);
      }
    }
    fetchAPI();
  }, []);

  return values;
}

