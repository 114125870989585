import React from 'react'
import PageTitle from 'components/PageTitle'
import { PageHeader } from '../CommonStyled/Tabs.styled'
import { useTranslation } from 'react-i18next'
import StatisticsTabs from 'components/Tabs'
// components
import InitiativesSummary from './tabs/InitiativesSummary'
import InitiativesDetail from './tabs/InitiativesDetail'
import InitiativesGoalsIndicators from './tabs/InitiativesGoalsIndicators'
import InitiativesSummaryTarget from './tabs/InitiativesSummaryTarget'

const StatsInitiatives = () => {
  const { t } = useTranslation()
  const titles = [
    //t('Statistics_initiatives_tab2'), 
    t('goals_indicators_list'),
    t('Statistics_initiatives_tab1'),
    t('Statistics_initiatives_tab4'),
  ]
  //const renderTab1Content = () => <InitiativesDetail />
  const renderTab2Content = () => <InitiativesSummary />
  const renderTab3Content = () => <InitiativesGoalsIndicators />
  const renderTab4Content = () => <InitiativesSummaryTarget />

  const contents = [
    //renderTab1Content, // See https://dev4world.atlassian.net/browse/SA2-963
    renderTab3Content,
    renderTab2Content,
    renderTab4Content,
  ]

  return (
    <>
      <PageHeader>
        <PageTitle title={t('menu_admin_6_10')} />
      </PageHeader>
      <StatisticsTabs titles={titles} contents={contents} />
    </>
  )
}

export default StatsInitiatives
