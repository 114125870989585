import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledSeparator = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 50px;
  height: 2px;
  background-image: linear-gradient(to right, #bac5cc 33%, rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 6px 2px;
  background-repeat: repeat-x;
`;

export const StyledTextValue = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.gray[5]};
  `}
`;

export const DescripcionDiv = styled.div`
  ${({ theme }) => css`
    padding: 15px;
    border: 2px solid ${theme.colors.gray[3]};
    margin-bottom: 20px;
  `}
`;

export const Text = styled.span`
  line-height: 1.3;
`;

export const Separator = styled.div`
  margin-bottom: 20px;
`;

export const StyledSectionWrapper = styled.div`
  padding-left: 50px;
`;

export const StyledFormLabel = styled(Form.Label)`
  text-align: justify;
  line-height: 1.3;
`;