import React from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { RequiredMark } from 'components/CommonStyled'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage'

const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field)
  const getFieldTouched = getIn(formikInstance.touched, field)
  return {
    touched: getFieldTouched,
    error:
      <FormErrorMessage
        touched={getFieldTouched}
        errors={getFieldError}
      />,
  }
}

const MediaForm = ({
  formikInstance,
  mediaTypeIndex,
  mediaNames,
  nonEditable,
}) => {
  const { t } = useTranslation()
  const {
    getFieldProps,
    touched,
    errors,
    setFieldValue,
    handleChange,
  } = formikInstance
  const fieldPath = (field) => `campaigns.${mediaTypeIndex}.${field}`

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S8_Media_type')}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            name={fieldPath('mediaTypeId')}
            as="select"
            {...getFieldProps(fieldPath('mediaTypeId'))}
            className={
              touched[fieldPath('mediaTypeId')] &&
              errors[fieldPath('mediaTypeId')]
                ? 'error'
                : null
            }
            onChange={ e => {
              if (e.currentTarget.value > 5) {
                setFieldValue(fieldPath('url'), 'n/a')
              } else {
                if(getFieldProps(fieldPath('url')).value === 'n/a') {
                  setFieldValue(fieldPath('url'),'') 
                }
              }
              handleChange(e)
            }}
            disabled={nonEditable}
          >
            <option value="">{t('Placeholder_select')}</option>
            {mediaNames.map((media) => (
              <option key={media.id} value={media.id}>
                {t(`media_type_${media.id}`)}
              </option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath('mediaTypeId')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S8_Link_account')}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath('url')}
            {...getFieldProps(fieldPath('url'))}
            className={
              touched[fieldPath('url')] && errors[fieldPath('url')]
                ? 'error'
                : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('url')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t('F1_S8_Media_name')}:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath('comments')}
            {...getFieldProps(fieldPath('comments'))}
            className={
              touched[fieldPath('comments')] && errors[fieldPath('comments')]
                ? 'error'
                : null
            }
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('comments')).error}
        </Col>
      </Form.Group>
    </>
  )
}

export default MediaForm
