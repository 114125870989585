import React from 'react';

import {
  StyledCommentDiv,
  StyledTextDiv,
} from './IntpaATComment.styled';

import { StyledTitle } from '../Section9.styled';
import { useTranslation } from 'react-i18next';

const TextValue = ({ value }) => (
  <StyledTextDiv>
    <span style={{whiteSpace:"pre-wrap"}}>{value ? value : '-'}</span>
  </StyledTextDiv>
);

const IntpaATComment = ({ atComment }) => {

  const {t} = useTranslation();

  return (
    <>
      <StyledTitle>{t('F2_Adelante_comments')}</StyledTitle>
      <StyledCommentDiv>
        <TextValue value={atComment} />
      </StyledCommentDiv>
    </>
  )
}

export default IntpaATComment;