import React, { useState } from 'react'
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

import ConfirmCloseModal from 'components/ConfirmCloseModal';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import { PrimaryButton, ErrorButton } from 'components/Buttons';
import "react-datepicker/dist/react-datepicker.css";
import { SeparatorDotted } from 'components/CommonStyled';
import ConsultantForm from './ConsultantForm';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const consultantObject = {
  skills: "",
  generalExp: "",
  specificExp: "",
  interpretation: ""
}


const ProfileListAccordion = ({ formikInstance }) => {
  const { t } = useTranslation();
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);

	const openModalRemove = (key) => {
    setModalRemove(true);
    setSelectedKey(key);
  }

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  }


	return (
		<FieldArray
			name='profileList'
			render={arrayHelpers => (
				<>
					{modalRemove && (
						<ConfirmCloseModal
							show={modalRemove}
							text={`Delete consultant ${selectedKey+1}?`}
							handleActionOnConfirm={() => {
								arrayHelpers.remove(selectedKey);
								closeModalRemove();
							}}
							handleCloseConfirm={closeModalRemove}
						/>
					)}
					<Accordion style={{marginLeft: '-15px'}}>
						{formikInstance.values.profileList
							? ( formikInstance.values.profileList.map((consultant, key) => (
									<AccordionItem key={key+1}>
										<AccordionItemHeader>
											<AccordionTitle eventKey={key+1}>{t('F3_S7_Accordion_consultant')+" "+(key+1)}</AccordionTitle>
										</AccordionItemHeader>
										<Accordion.Collapse eventKey={key+1}>
											<AccordionItemBody>
												<ConsultantForm formikInstance={formikInstance} consultantIndex={key} />
												<ErrorButton onClick={() => openModalRemove(key)}>{t('F3_S7_Consultant_button_delete')}</ErrorButton>
											</AccordionItemBody>
										</Accordion.Collapse>
									</AccordionItem>
								)))
							: null}
					</Accordion>
					<FormErrorMessage
						touched
						errors={formikInstance.errors.profileList}
					/>

					<SeparatorDotted />
					<PrimaryButton onClick={() => arrayHelpers.push(consultantObject)} >{t('F3_S7_Consultant_button_add')}</PrimaryButton>
				</>
			)}
		/>
	)
}

export default ProfileListAccordion;