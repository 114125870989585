import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';
import { PrimaryButton} from 'components/Buttons';

export const StyledSeparator = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 35px;
  margin-right: 20px;
  height: 2px;
  background-image: linear-gradient(to right, #bac5cc 33%, rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 6px 2px;
  background-repeat: repeat-x;
`;

export const StyledGreenFormLabel = styled(Form.Label)`
  ${({ theme }) => css`
    color: ${theme.brandColors.green};
  `}
  font-weight: 500;
`;

export const  StyledSavedButton = styled(PrimaryButton)`
  ${({ theme }) => css`
    &:disabled {
      background-color:${theme.brandColors.green};
      color: white;
      border: ${theme.colorGroups.success.bgColor};
    }
  `}
`;

export const StyledTitle = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    margin-bottom: 10px;
    margin-left: 10px;
    color: ${theme.brandColors.blue};
    font-weight: 600;
    font-size: 23px;
  `}
`;
