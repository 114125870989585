import { roleNamesConst } from 'constants/appConstants';
import i18n from 'i18n';

export const title = (role, user) => {
  let title = '';
  if (role && user) {
    if (role.name === roleNamesConst.EUD) title = `${role.name} ${user.country}`;
    else if (role.name === roleNamesConst.ATKE) title = i18n.t('AT_team');
    else if (role.name === roleNamesConst.PUBLIC || role.name === roleNamesConst.APPLICANT) title = i18n.t('Applicant');
    else title = role.name;
  }
  return title;
};

export const handleComments = (list, comments, commentsNumber) => {
  if (list.length != 0) {
    const newList = list.slice(0, commentsNumber);
    const commentsCopy = comments;
    return { allComments: list.slice(commentsNumber), comments: commentsCopy.concat(newList) };
  }
};
