import * as Yup from 'yup';
import i18n from 'i18n';

const yesterday = new Date(Date.now() - 86400000);

export const validationSchema = Yup.object().shape({
  methodology: Yup.string()
    .min(2, 'F1_S7_validation1')
    .max(1300, 'F1_S7_validation2')
    .nullable(true),
  estFromDateStr: Yup.date()
    .min(yesterday, 'F1_S7_validation3')
    .nullable(true),
  estToDateStr: Yup.date()
    .min(Yup.ref('estFromDateStr'), 'F1_S7_validation4')
    .nullable(true),
  activities: Yup.array()
    .of(
      Yup.object().shape({
        activityTypeId: Yup.string()
          .required('F1_S7_validation5'),
        description: Yup.string()
          .min(10, 'F1_S7_validation6')
          .max(1300, 'F1_S7_validation7')
          .required('F1_S7_validation8'),
        fromDateStr: Yup.date()
          .min(yesterday, 'F1_S7_validation3')
          .typeError( 'F1_S7_validation9'),
        toDateStr: Yup.date()
          .min(Yup.ref('fromDateStr'), 'F1_S7_validation4')
          .typeError('F1_S7_validation9'),
        duration: Yup.number().when('activityTypeId', {
          is: activityType => !(activityType === "4" || activityType === "7"),
          then: Yup.number()
            .min(0, 'F1_S7_validation10')
            .required('F1_S7_validation11'),
          otherwise: Yup.number().nullable(true)
        }),
        participants: Yup.number().when('activityTypeId', {
          is: activityType => !(activityType === "4" || activityType === "7"),
          then: Yup.number()
            .min(0, 'F1_S7_validation10')
            .required('F1_S7_validation11'),
          otherwise: Yup.number().nullable(true)
        }),
        experts: Yup.number().when('activityTypeId', {
          is: activityType => activityType === "4",
          then: Yup.number()
            .min(0, 'F1_S7_validation10')
            .required('F1_S7_validation11'),
          otherwise: Yup.number().nullable(true)
        }),

        daysRemote: Yup.number().when(['activityTypeId', 'daysOnsite'], {
          is: (activityType, days) => activityType === "4" && !(Number.parseInt(days) > 0),
          then: Yup.number()
            .min(0, 'F1_S7_validation10')
            .required('F1_S7_validation11'),
          otherwise: Yup.number().nullable(true)
        }),
        daysOnsite: Yup.number().when(['activityTypeId', 'daysRemote'], {
          is: (activityType, days) => activityType === "4" && !(Number.parseInt(days) > 0),
          then: Yup.number()
            .min(0, 'F1_S7_validation10')
            .required('F1_S7_validation11'),
          otherwise: Yup.number().nullable(true)
        }),
        format: Yup.string()
          .oneOf(['0', '1', '2'])
          .required('F1_S7_validation20'),
        countryId: Yup.string().when(['format'], {
          is: (format) => format === "0" || format === "1",
          then: Yup.string()
            .required('F1_S7_validation12'),
          otherwise: Yup.string().nullable(true)
        }),
        cityName: Yup.string().when(['format'], {
          is: (format) => format === "0" || format === "1",
          then: Yup.string()
            .min(3, 'F1_S2_validation11')
            .max(100, 'F1_S2_validation12')
            .required('F1_S7_validation13'),
          otherwise: Yup.string().nullable(true)
        }),
        stateName: Yup.string()
          .min(3, 'F1_S2_validation13')
          .max(100, 'F1_S2_validation14'),
        countriesAdditional: Yup.string()
          .max(300, 'F1_S7_validation14')
          .nullable(true),
        ueBudget: Yup.number().when('counterPartBudget', {
          is: budget => !(Number.parseInt(budget) > 0),
          then: Yup.number()
            .min(0, 'F1_S7_validation10')
            .required('F1_S7_validation11'),
          otherwise: Yup.number()
            .nullable(true)
            .min(0, 'F1_S7_validation15'),
        }),
        counterPartBudget: Yup.number().when('ueBudget', {
          is: budget => !(Number.parseInt(budget) > 0),
          then: Yup.number()
            .min(0, 'F1_S7_validation10')
            .required('F1_S7_validation11'),
          otherwise: Yup.number()
            .nullable(true)
            .min(0, 'F1_S7_validation15'),
        }),

        justBudget: Yup.string()
          .min(10, 'F1_S7_validation16')
          .max(650, 'F1_S7_validation17')
          .required('F1_S7_validation18'),
      }, [['daysRemote', 'daysOnsite'], ['ueBudget', 'counterPartBudget']])
    )
    .min(1,'F1_S7_validation19')
});
