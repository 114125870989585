import styled, { css } from 'styled-components';
import Modal from 'react-bootstrap/Modal'
import { PrimaryButton } from 'components/Buttons'


export const StyledModalBody = styled(Modal.Body)`
  ${({ theme }) => css`
    color: #6f8d9d;
    padding: 30px 40px;
    background-color: ${theme.brandColors.gray4};
  `}
`;

export const Separator = styled.div`
  margin-top: 20px;
`;

export const  StyledSendButton = styled(PrimaryButton)`
  ${({ theme }) => css`
    background-color: ${theme.brandColors.lightBlue};
    color: ${theme.colors.white};
    border: none;
    text-align: center;
    display: inline-block;
    font-weight: 600;
    border-radius: 16px;
    margin: -5px 0px;
    width: auto;
    height: 2em;
    padding: 0px 20px;
    letter-spacing: 2px;
    &:hover {
      background-color: ${theme.brandColors.lightBlueHover};
    }
    &:disabled {
      background-color:${theme.brandColors.green};
      color: white;
      border: ${theme.colorGroups.success.bgColor};
      &:hover: none !important;
    }
  `}
`;
