import styled, { css } from 'styled-components';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export const StyledModalBody = styled(Modal.Body)`
  ${({ theme }) => css`
    color: #6f8d9d;
    padding: 30px 40px;
    background-color: ${theme.brandColors.gray4};
  `}
`;

export const ModalTitle = styled.div`
  font-weight: 700;
  margin-left: -3px;
  margin-bottom: 20px;
`;

export const StyledDiv = styled.div`
  margin-left: 50px;
  line-height: 20px;
`;

export const StyledButtonLink = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  box-shadow: none !important;
  margin-bottom: 2px;
`;
