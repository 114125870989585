import React from 'react'
import i18n from 'i18n'
import {
  Td,
  Th1,
  Td2,
  Th4,
  Th1Pointer,
} from 'pages/statistics/CommonStyled/Components.styled'
import _orderBy from 'lodash/orderBy'

const getTotals = (data) => {
  let totals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  data.forEach(
    ({ level, oo1, oo2, so3, so4, out5, out6, out7, out8, out9 }) => {
      if (level) totals[0]++
      if (oo1) totals[1]++
      if (oo2) totals[2]++
      if (so3) totals[3]++
      if (so4) totals[4]++
      if (out5) totals[5]++
      if (out6) totals[6]++
      if (out7) totals[7]++
      if (out8) totals[8]++
      if (out9) totals[9]++
    },
  )
  return totals
}

export const renderRows = (data) => {
  let rows = []
  data.forEach(
    ({
      code,
      name,
      f6StatusId,
      level,
      oo1,
      oo2,
      so3,
      so4,
      out5,
      out6,
      out7,
      out8,
      out9,
    }) => {
      rows.push(
        <tr>
          <Td>{code}</Td>
          <Td>{name}</Td>
          <Td>{i18n.t(`F6_state_${f6StatusId}`)}</Td>
          <Td>
            {f6StatusId === 1 ? '-' : level ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>{f6StatusId === 1 ? '-' : oo1 ? i18n.t('Yes') : i18n.t('No')}</Td>
          <Td>{f6StatusId === 1 ? '-' : oo2 ? i18n.t('Yes') : i18n.t('No')}</Td>
          <Td>{f6StatusId === 1 ? '-' : so3 ? i18n.t('Yes') : i18n.t('No')}</Td>
          <Td>{f6StatusId === 1 ? '-' : so4 ? i18n.t('Yes') : i18n.t('No')}</Td>
          <Td>
            {f6StatusId === 1 ? '-' : out5 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {f6StatusId === 1 ? '-' : out6 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {f6StatusId === 1 ? '-' : out7 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {f6StatusId === 1 ? '-' : out8 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {f6StatusId === 1 ? '-' : out9 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
        </tr>,
      )
    },
  )
  rows.push(
    <tr>
      <Th4 colSpan={3}>{i18n.t('F1_S7_Total')}</Th4>
      {getTotals(data).map((element) => (
        <Td2>{element}</Td2>
      ))}
    </tr>,
  )
  return rows
}

export const renderHeader = (handleOrderData) => (
  <tr>
    <Th1>{i18n.t('Code')}</Th1>
    <Th1>{i18n.t('Title_initiative')}</Th1>
    <Th1Pointer onClick={() => handleOrderData('f6StatusId')}>
      {i18n.t('Grid_status_F6')} &#8645;
    </Th1Pointer>
    <Th1>{i18n.t('Minimum_level')}</Th1>
    <Th1 style={{ width: '4%' }}>{i18n.t('OG1')}</Th1>
    <Th1 style={{ width: '4%' }}>{i18n.t('OG2')}</Th1>
    <Th1 style={{ width: '4%' }}>{i18n.t('OE1')}</Th1>
    <Th1 style={{ width: '4%' }}>{i18n.t('OE2')}</Th1>
    <Th1 style={{ width: '4%' }}>{i18n.t('OE3')}</Th1>
    <Th1 style={{ width: '4%' }}>{i18n.t('R1')}</Th1>
    <Th1 style={{ width: '4%' }}>{i18n.t('R2')}</Th1>
    <Th1 style={{ width: '4%' }}>{i18n.t('R3')}</Th1>
    <Th1 style={{ width: '4%' }}>{i18n.t('R4')}</Th1>
  </tr>
)

export const orderDataByField = (data, field, setData, setAsc, asc) => {
  let aux = { ...asc }
  let orderedData = []
  for (const property in aux) {
    aux[property] = true
  }

  switch (field) {
    case 'year':
      orderedData = _orderBy(
        data,
        [field],
        asc[field] ? ['asc', 'asc'] : ['desc', 'desc'],
      )
      break
    case 'f6StatusId':
      orderedData = _orderBy(
        data,
        [(item) => i18n.t(`F6_state_1${item.f6StatusId}`)],
        asc[field] ? ['asc'] : ['desc'],
      )
      break
  }

  aux[field] = !asc[field]
  setAsc(aux)
  setData(orderedData)
}

export const getResultExportValues = (exportData) => {
  let data = []
  exportData?.forEach((row) => {
    data.push({
      col1: row?.code,
      col2: row?.name,
      col3: row?.f6StatusId ? i18n.t(`F6_state_1${row.f6StatusId}`) : '-',
      col4: row?.level ? i18n.t('Yes') : i18n.t('No'),
      col5: row?.oo1 ? i18n.t('Yes') : i18n.t('No'),
      col6: row?.oo2 ? i18n.t('Yes') : i18n.t('No'),
      col7: row?.so3 ? i18n.t('Yes') : i18n.t('No'),
      col8: row?.so4 ? i18n.t('Yes') : i18n.t('No'),
      col9: row?.out5 ? i18n.t('Yes') : i18n.t('No'),
      col10: row?.out6 ? i18n.t('Yes') : i18n.t('No'),
      col11: row?.out7 ? i18n.t('Yes') : i18n.t('No'),
      col12: row?.out8 ? i18n.t('Yes') : i18n.t('No'),
      col13: row?.out9 ? i18n.t('Yes') : i18n.t('No'),
    })
  })
  const totals = getTotals(exportData)
  data.push({
    col1: '',
    col2: '',
    col3: i18n.t('F1_S7_Total'),
    col4: totals[0],
    col5: totals[1],
    col6: totals[2],
    col7: totals[3],
    col8: totals[4],
    col9: totals[5],
    col10: totals[6],
    col11: totals[7],
    col12: totals[8],
    col13: totals[9],
  })
  return data
}







export const renderRows2 = (data) => {
  let rows = []
  data.forEach(
    ({
      code,
      name,
      marker1,
      marker2,
      marker3,
      marker4,
      marker5,
      marker6,
      marker7,
      marker8,
      marker9,
      marker10,
      marker11,
      marker12,
      marker13,
      marker14,
      marker15,
      marker16,
      marker17,
      marker18,
      marker19,
    }) => {
      rows.push(
        <tr>
          <Td>{code}</Td>
          <Td>{name}</Td>
          <Td>
            {marker1 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker2 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker3 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker4 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker5 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker6 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker7 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker8 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker9 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker10 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker11 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker12 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker13 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker14 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker15 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker16 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker17 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker18 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
          <Td>
            {marker19 ? i18n.t('Yes') : i18n.t('No')}
          </Td>
        </tr>,
      )
    },
  )
  rows.push(
    <tr>
      <Th4 colSpan={2}>{i18n.t('F1_S7_Total')}</Th4>
      {getTotals2(data).map((element) => (
        <Td2>{element}</Td2>
      ))}
    </tr>,
  )
  return rows
}

export const renderHeader2 = (handleOrderData) => (
  <>
    <tr>
      <Th1 colSpan={2}></Th1>
      <Th1 colSpan={4}>{i18n.t('F6_AT_transversal')}</Th1>
      <Th1 colSpan={4}>{i18n.t('F6_AT_relevance')}</Th1>
      <Th1 colSpan={3}>{i18n.t('F6_AT_efficiency')}</Th1>
      <Th1 colSpan={5}>{i18n.t('F6_AT_effectiveness')}</Th1>
      <Th1 colSpan={1}>{i18n.t('F6_AT_impact')}</Th1>
      <Th1 colSpan={2}>{i18n.t('F6_AT_sustainability')}</Th1>
    </tr>
    <tr>
      <Th1>{i18n.t('Code')}</Th1>
      <Th1 style={{ maxWidth: '4%'}}>{i18n.t('Title_initiative')}</Th1>
      <Th1 style={{ width: '1%' }}>M1</Th1>
      <Th1 style={{ width: '1%' }}>M2</Th1>
      <Th1 style={{ width: '1%' }}>M3</Th1>
      <Th1 style={{ width: '1%' }}>M4</Th1>
      <Th1 style={{ width: '1%' }}>M1</Th1>
      <Th1 style={{ width: '1%' }}>M2</Th1>
      <Th1 style={{ width: '1%' }}>M3</Th1>
      <Th1 style={{ width: '1%' }}>M4</Th1>
      <Th1 style={{ width: '1%' }}>M1</Th1>
      <Th1 style={{ width: '1%' }}>M2</Th1>
      <Th1 style={{ width: '1%' }}>M3</Th1>
      <Th1 style={{ width: '1%' }}>M1</Th1>
      <Th1 style={{ width: '1%' }}>M2</Th1>
      <Th1 style={{ width: '1%' }}>M3</Th1>
      <Th1 style={{ width: '1%' }}>M4</Th1>
      <Th1 style={{ width: '1%' }}>M5</Th1>
      <Th1 style={{ width: '1%' }}>M1</Th1>
      <Th1 style={{ width: '1%' }}>M1</Th1>
      <Th1 style={{ width: '1%' }}>M2</Th1>
    </tr>
  </>
)

const getTotals2 = (data) => {
  let totals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  data.forEach(
    ({
      marker1,
      marker2,
      marker3,
      marker4,
      marker5,
      marker6,
      marker7,
      marker8,
      marker9,
      marker10,
      marker11,
      marker12,
      marker13,
      marker14,
      marker15,
      marker16,
      marker17,
      marker18,
      marker19,
    }) => {
      if (marker1) totals[0]++
      if (marker2) totals[1]++
      if (marker3) totals[2]++
      if (marker4) totals[3]++
      if (marker5) totals[4]++
      if (marker6) totals[5]++
      if (marker7) totals[6]++
      if (marker8) totals[7]++
      if (marker9) totals[8]++
      if (marker10) totals[9]++
      if (marker11) totals[10]++
      if (marker12) totals[11]++
      if (marker13) totals[12]++
      if (marker14) totals[13]++
      if (marker15) totals[14]++
      if (marker16) totals[15]++
      if (marker17) totals[16]++
      if (marker18) totals[17]++
      if (marker19) totals[18]++
    },
  )
  return totals
}


export const getResultExportValues2 = (exportData) => {
  let data = [
    {
      col1: i18n.t('Code'),
      col2: i18n.t('F1_S6_Question_1'),
      col3: 'M1',
      col4: "M2",
      col5: "M3",
      col6: "M4",
      col7: "M1",
      col8: "M2",
      col9: "M3",
      col10: "M4",
      col11: "M1",
      col12: "M2",
      col13: "M3",
      col14: "M1",
      col15: "M2",
      col16: "M3",
      col17: "M4",
      col18: "M5",
      col19: "M1",
      col20: "M1",
      col21: "M2",
    },

  ]
  exportData?.forEach((row) => {
    data.push({
      col1: row?.code,
      col2: row?.name,
      col3: row?.marker1 ? i18n.t('Yes') : i18n.t('No'),
      col4: row?.marker2 ? i18n.t('Yes') : i18n.t('No'),
      col5: row?.marker3 ? i18n.t('Yes') : i18n.t('No'),
      col6: row?.marker4 ? i18n.t('Yes') : i18n.t('No'),
      col7: row?.marker5 ? i18n.t('Yes') : i18n.t('No'),
      col8: row?.marker6 ? i18n.t('Yes') : i18n.t('No'),
      col9: row?.marker7 ? i18n.t('Yes') : i18n.t('No'),
      col10: row?.marker8 ? i18n.t('Yes') : i18n.t('No'),
      col11: row?.marker9 ? i18n.t('Yes') : i18n.t('No'),
      col12: row?.marker10 ? i18n.t('Yes') : i18n.t('No'),
      col13: row?.marker11 ? i18n.t('Yes') : i18n.t('No'),
      col14: row?.marker12 ? i18n.t('Yes') : i18n.t('No'),
      col15: row?.marker13 ? i18n.t('Yes') : i18n.t('No'),
      col16: row?.marker14 ? i18n.t('Yes') : i18n.t('No'),
      col17: row?.marker15 ? i18n.t('Yes') : i18n.t('No'),
      col18: row?.marker16 ? i18n.t('Yes') : i18n.t('No'),
      col19: row?.marker17 ? i18n.t('Yes') : i18n.t('No'),
      col20: row?.marker18 ? i18n.t('Yes') : i18n.t('No'),
      col21: row?.marker19 ? i18n.t('Yes') : i18n.t('No'),
    })
  })
  const totals = getTotals2(exportData)
  data.push({
    col1: '',
    col2: i18n.t('F1_S7_Total'),
    col3: totals[0],
    col4: totals[1],
    col5: totals[2],
    col6: totals[3],
    col7: totals[4],
    col8: totals[5],
    col9: totals[6],
    col10: totals[7],
    col11: totals[8],
    col12: totals[9],
    col13: totals[10],
    col14: totals[11],
    col15: totals[12],
    col16: totals[13],
    col17: totals[14],
    col18: totals[15],
    col19: totals[16],
    col20: totals[17],
    col21: totals[18],
  })
  return data
}
