// @ts-nocheck
import React, { useEffect } from 'react'
import ReactExport from 'react-export-excel'
import i18n from 'i18n'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const getExcelColumnsByYears = (data, exportName, label) => (
  <ExcelSheet data={data} name={exportName}>
    <ExcelColumn label={label} value="label" />
    <ExcelColumn label="2021" value="col1" />
    <ExcelColumn label="2022" value="col2" />
    <ExcelColumn label="2023" value="col3" />
    <ExcelColumn label="2024" value="col4" />
    <ExcelColumn label={i18n.t('F1_S7_Total')} value="col5" />
  </ExcelSheet>
)

const getExcelColumnsEntitiesDetail = (data, exportName) => (
  <ExcelSheet data={data} name={exportName}>
    <ExcelColumn label={i18n.t('Initiative_code')} value="col1" />
    <ExcelColumn label={i18n.t('Entity_name')} value="col2" />
    <ExcelColumn label={i18n.t('F1_S2_Official_acronym')} value="col3" />
    <ExcelColumn label={i18n.t('F1_S2_Role')} value="col4" />
    <ExcelColumn label={i18n.t('F1_S2_Country')} value="col5" />
    <ExcelColumn label={i18n.t('F1_S2_Type_of_entity')} value="col6" />
    <ExcelColumn label={i18n.t('Function')} value="col7" />
  </ExcelSheet>
)

const getExcelColumnsActivitiesDetail = (data, exportName) => (
  <ExcelSheet data={data} name={exportName}>
    <ExcelColumn label={i18n.t('Statistics_activities_tab1')} value="label" />
    <ExcelColumn label={i18n.t('Code')} value="col1" />
    <ExcelColumn label={i18n.t('F5_S7_Title_of_the_activity')} value="col2" />
    <ExcelColumn label={i18n.t('F1_S2_Country')} value="col3" />
    <ExcelColumn label={i18n.t('Region')} value="col4" />
    <ExcelColumn label={i18n.t('F1_S7_Format')} value="col5" />
    <ExcelColumn label={i18n.t('Type')} value="col6" />
    <ExcelColumn label={i18n.t('Grid_start_date_from')} value="col7" />
    <ExcelColumn label={i18n.t('Grid_start_date_to')} value="col8" />
  </ExcelSheet>
)

const getExcelColumnsBeneficiariesDetail = (data, exportName) => (
  <ExcelSheet data={data} name={exportName}>
    <ExcelColumn
      label={i18n.t('Statistics_beneficiaries_tab1')}
      value="label"
    />
    <ExcelColumn label={i18n.t('Initiative_code')} value="col1" />
    <ExcelColumn label={i18n.t('Title_initiative')} value="col2" />
    <ExcelColumn label={i18n.t('Dashboard_ben_type')} value="col3" />
    <ExcelColumn label={i18n.t('F1_S2_Country')} value="col4" />
    <ExcelColumn label={i18n.t('Region')} value="col5" />
    <ExcelColumn label={i18n.t('F1_S7_Total')} value="col6" />
  </ExcelSheet>
)

export const ExportStatisticsFormulationToXLSX = (props) => {
  useEffect(() => {
    if (props?.isReady && props?.data) {
      document.getElementById('download').click()
      props?.onReady()
    }
  }, [props?.isReady, props?.data])

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={props?.exportName}
    >
      {getExcelColumnsByYears(props?.data, props?.exportName, '')}
    </ExcelFile>
  )
}

export const ExportStatisticsByYearsToXLSX = (props) => {
  useEffect(() => {
    if (props?.isReady && props?.data) {
      document.getElementById('download').click()
      props?.onReady()
    }
  }, [props?.isReady, props?.data])

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={props?.exportName}
    >
      {getExcelColumnsByYears(props?.data, props?.exportName, props?.label)}
    </ExcelFile>
  )
}

export const ExportStatisticsInitiativesDetailToXLSX = (props) => {
  useEffect(() => {
    if (props?.isReady && props?.data) {
      document.getElementById('download').click()
      props?.onReady()
    }
  }, [props?.isReady, props?.data])

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={props?.exportName}
    >
      <ExcelSheet data={props?.data} name={props?.exportName}>
        <ExcelColumn label={i18n.t('Initiative_code')} value="code" />
        <ExcelColumn label={i18n.t('Title_initiative')} value="title" />
        <ExcelColumn label={i18n.t('Grid_ODS_P')} value="odsp" />
        <ExcelColumn label={i18n.t('Grid_ODS_S')} value="odss" />
      </ExcelSheet>
    </ExcelFile>
  )
}

export const ExportStatisticsF8BSummaryToXLSX = (props) => {
  useEffect(() => {
    if (props?.isReady && props?.data) {
      document.getElementById('download').click()
      props?.onReady()
    }
  }, [props?.isReady, props?.data])

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={props?.exportName}
    >
      <ExcelSheet data={props?.data} name={props?.exportName}>
        <ExcelColumn label={i18n.t('')} value="label" />
        <ExcelColumn label={i18n.t('F8b_expost_1')} value="col1" />
        <ExcelColumn label={i18n.t('F8b_expost_2')} value="col2" />
        <ExcelColumn label={i18n.t('F8b_expost_3')} value="col3" />
      </ExcelSheet>
    </ExcelFile>
  )
}

export const ExportStatisticsEntitiesDetailToXLSX = (props) => {
  useEffect(() => {
    if (props?.isReady && props?.data) {
      document.getElementById('download').click()
      props?.onReady()
    }
  }, [props?.isReady, props?.data])

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={props?.exportName}
    >
      {getExcelColumnsEntitiesDetail(props?.data, props?.exportName)}
    </ExcelFile>
  )
}

export const ExportActivitiesDetailToXLSX = (props) => {
  useEffect(() => {
    if (props?.isReady && props?.data) {
      document.getElementById('download').click()
      props?.onReady()
    }
  }, [props?.isReady, props?.data])

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={props?.exportName}
    >
      {getExcelColumnsActivitiesDetail(props?.data, props?.exportName)}
    </ExcelFile>
  )
}

export const ExportBeneficiariesDetailToXLSX = (props) => {
  useEffect(() => {
    if (props?.isReady && props?.data) {
      document.getElementById('download').click()
      props?.onReady()
    }
  }, [props?.isReady, props?.data])

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={props?.exportName}
    >
      {getExcelColumnsBeneficiariesDetail(props?.data, props?.exportName)}
    </ExcelFile>
  )
}

export const ExportResultsToXLSX = (props) => {
  useEffect(() => {
    if (props?.isReady && props?.data) {
      document.getElementById('download').click()
      props?.onReady()
    }
  }, [props?.isReady, props?.data])

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={props?.exportName}
    >
      <ExcelSheet data={props?.data} name={props?.exportName}>
        <ExcelColumn label={i18n.t('Code')} value="col1" />
        <ExcelColumn label={i18n.t('F1_S6_Question_1')} value="col2" />
        <ExcelColumn label={i18n.t('Grid_status_F6')} value="col3" />
        <ExcelColumn label={i18n.t('Minimum_level')} value="col4" />
        <ExcelColumn label={i18n.t('OG1')} value="col5" />
        <ExcelColumn label={i18n.t('OG2')} value="col6" />
        <ExcelColumn label={i18n.t('OE1')} value="col7" />
        <ExcelColumn label={i18n.t('OE2')} value="col8" />
        <ExcelColumn label={i18n.t('OE3')} value="col9" />
        <ExcelColumn label={i18n.t('R1')} value="col10" />
        <ExcelColumn label={i18n.t('R2')} value="col11" />
        <ExcelColumn label={i18n.t('R3')} value="col12" />
        <ExcelColumn label={i18n.t('R4')} value="col13" />
      </ExcelSheet>
    </ExcelFile>
  )
}


export const ExportResults2ToXLSX = (props) => {
  useEffect(() => {
    if (props?.isReady && props?.data) {
      document.getElementById('download').click()
      props?.onReady()
    }
  }, [props?.isReady, props?.data])

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={props?.exportName}
    >
      <ExcelSheet data={props?.data} name={props?.exportName}>
        <ExcelColumn label={""} value="col1" />
        <ExcelColumn label={""} value="col2" />
        <ExcelColumn label={i18n.t('F6_AT_transversal')} value="col3" />
        <ExcelColumn label={""} value="col4" />
        <ExcelColumn label={""} value="col5" />
        <ExcelColumn label={""} value="col6" />
        <ExcelColumn label={i18n.t('F6_AT_relevance')} value="col7" />
        <ExcelColumn label={""} value="col8" />
        <ExcelColumn label={""} value="col9" />
        <ExcelColumn label={""} value="col10" />
        <ExcelColumn label={i18n.t('F6_AT_efficiency')} value="col11" />
        <ExcelColumn label={""} value="col12" />
        <ExcelColumn label={""} value="col13" />
        <ExcelColumn label={i18n.t('F6_AT_effectiveness')} value="col14" />
        <ExcelColumn label={""} value="col15" />
        <ExcelColumn label={""} value="col16" />
        <ExcelColumn label={""} value="col17" />
        <ExcelColumn label={""} value="col18" />
        <ExcelColumn label={i18n.t('F6_AT_impact')} value="col19" />
        <ExcelColumn label={i18n.t('F6_AT_sustainability')} value="col20" />
        <ExcelColumn label={""} value="col21" />
      </ExcelSheet>
    </ExcelFile>
  )
}


export const ExportPartnershipsToXLSX = (props) => {
  useEffect(() => {
    if (props?.isReady && props?.data) {
      document.getElementById('download').click()
      props?.onReady()
    }
  }, [props?.isReady, props?.data])

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={props?.exportName}
    >
      <ExcelSheet data={props?.data} name={props?.exportName}>
        <ExcelColumn label={i18n.t('Initiative_code')} value="col1" />
        <ExcelColumn label={i18n.t('Title_initiative')} value="col2" />
        <ExcelColumn
          label={i18n.t('Country_coordinating_entity')}
          value="col3"
        />
        <ExcelColumn label={i18n.t('Role_coordinating_entity')} value="col4" />
        <ExcelColumn label={i18n.t('Participating_entities')} value="col5" />
        <ExcelColumn label={i18n.t('Beneficiary_entities')} value="col6" />
        <ExcelColumn label={i18n.t('First_provider_entities')} value="col7" />
        <ExcelColumn label={i18n.t('Second_provider_entities')} value="col8" />
        <ExcelColumn
          label={i18n.t('Countries_participating_entities')}
          value="col9"
        />
        <ExcelColumn label={i18n.t('Collaborating_entities')} value="col10" />
      </ExcelSheet>
    </ExcelFile>
  )
}

export const ExportGoalsIndicatorsList = (props) => {
  useEffect(() => {
    if (props?.isReady && props?.data) {
      document.getElementById('download').click()
      props?.onReady()
    }
  }, [props?.isReady, props?.data])

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={props?.exportName}
    >
      <ExcelSheet data={props?.data} name={props?.exportName}>
        <ExcelColumn label={i18n.t('Initiative_code')} value="col1" />
        <ExcelColumn label={i18n.t('Title_initiative')} value="col2" />
        <ExcelColumn label={i18n.t('SDG')} value="col3" />
        <ExcelColumn label={i18n.t('F3_S11_Target')} value="col4" />
        <ExcelColumn label={i18n.t('F3_S11_Indicators')} value="col5" />
        <ExcelColumn label={i18n.t('type_SDG')} value="col6" />
      </ExcelSheet>
    </ExcelFile>
  )
}

export const ExportCountryReportToXLSX = (props) => {
  useEffect(() => {
    if (props?.isReady && props?.data) {
      document.getElementById('download').click()
      props?.onReady()
    }
  }, [props?.isReady, props?.data])

  const sheets = [
    getExcelColumnsByYears(
      props?.data?.preFormulationData,
      i18n.t('country_report_subtitle1_1'),
      '',
    ),
    getExcelColumnsByYears(
      props?.data?.formulationData,
      i18n.t('country_report_subtitle2_1'),
      '',
    ),
    getExcelColumnsByYears(
      props?.data?.implementationData,
      i18n.t('country_report_subtitle3_1'),
      '',
    ),
    getExcelColumnsByYears(
      props?.data?.entitiesByRoleData,
      i18n.t('country_report_subtitle6'),
      '',
    ),
    getExcelColumnsByYears(
      props?.data?.entitiesByFunctionData,
      i18n.t('country_report_subtitle7'),
      '',
    ),
    getExcelColumnsByYears(
      props?.data?.entitiesByTypeData,
      i18n.t('country_report_subtitle8'),
      '',
    ),
    getExcelColumnsByYears(
      props?.data?.activitiesByRoleData,
      i18n.t('country_report_subtitle14'),
      '',
    ),
    getExcelColumnsByYears(
      props?.data?.activitiesByTypeData,
      i18n.t('country_report_subtitle15'),
      '',
    ),
    getExcelColumnsByYears(
      props?.data?.beneficiariesByTypeData,
      i18n.t('country_report_subtitle16'),
      '',
    ),
  ]

  if (props?.data?.includeOnExport) {
    sheets.push(
      getExcelColumnsEntitiesDetail(
        props?.data?.entitiesFromCountryData,
        i18n.t('country_report_subtitle4_1'),
      ),
    )
    sheets.push(
      getExcelColumnsActivitiesDetail(
        props?.data?.activitiesFromCountryData,
        i18n.t('country_report_subtitle5_1'),
      ),
    )
    sheets.push(
      getExcelColumnsBeneficiariesDetail(
        props?.data?.beneficiariesDetailData,
        i18n.t('country_report_subtitle13'),
      ),
    )
  }

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={props?.exportName}
    >
      {sheets}
    </ExcelFile>
  )
}


export const ExportCommunityToXLSX = (props) => {
  useEffect(() => {
    if (props?.isReady && props?.data) {
      document.getElementById('download').click()
      props?.onReady()
    }
  }, [props?.isReady, props?.data])

  return (
    <ExcelFile
      element={
        <button id="download" style={{ visibility: 'hidden' }}>
          Download Data
        </button>
      }
      filename={props?.exportName}
    >
      <ExcelSheet data={props?.data} name={props?.exportName}>
        <ExcelColumn label={i18n.t('community_report_partnership')} value="label" />
        <ExcelColumn label={i18n.t('community_report_entities')} value="col1" />
        <ExcelColumn label={i18n.t('community_report_tool')} value="col2" />
        <ExcelColumn label={i18n.t('community_report_objective')} value="col3" />
        <ExcelColumn label={i18n.t('community_report_primary_ods')} value="col4" />
        <ExcelColumn label={i18n.t('community_report_secondary_ods')} value="col5" />
        <ExcelColumn label={i18n.t('community_report_website')} value="col6" />
      </ExcelSheet>
    </ExcelFile>
  )
}
