import React from 'react';
import {  Link } from 'react-router-dom';
import { useRootStore } from 'stores';
import DayJS from 'dayjs';
import { useTranslation } from 'react-i18next';
import { f2statusNamesConst } from 'constants/appConstants';

import {
  StyledTr,
  StyledSpan
} from './TableRow.styled'

const TableRow = ({item}) => {
  const { t } = useTranslation();
  const { initiativeStore } = useRootStore();
  const todayDate = new Date();

  const setInitiativeOnStore = (initiative) => {
    initiativeStore.setInitiativeData(initiative);
  }

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr);
    return date.format("DD/MM/YYYY");
  }

  return (
    <StyledTr key={item.id}>
      <td style={{minWidth:"100px"}}>
        <Link onClick={() => setInitiativeOnStore(item)} to={`/applicant/expost-reporting/${item.id}?initiative=${item.initiative.code}`}>{item.initiative.code}</Link>
      </td>
      <td style={{maxWidth:"600px", overflowWrap: "break-word"}}>{(item.initiative && item.initiative.name)? item.initiative.name: t('Name_not_defined_yet')}</td>
      <td style={{minWidth:"100px"}}>{t(item.statusF8)}</td>
      <td>
        {
          item.deadlineF8 && item.statusF8 !== f2statusNamesConst.DONE
            ? (
              <StyledSpan expired={todayDate > new Date(item.deadlineF8)}>
                {formatDate(item.deadlineF8)}
              </StyledSpan>
            )
            : ""
        }
      </td>
    </StyledTr>

  )
}

export default TableRow;