import React from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import { Th3, Table } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'

import { renderHeaders } from '../../CommonHelpers/helpers'
import { T2RenderRows } from '../helpers/helpers'
import { TARGET_YEARS } from 'constants/appConstants'


const Table2 = ({ data, waiting }) => {
  const { t } = useTranslation()

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data && !isEmpty(data) ? (
    <>
      <b>{t('triang_coop_initiatives')}</b>
      <Table>
        <colgroup>
          <col span="5" />
          <col span="5" style={{ backgroundColor: '#a6b3bb' }} />
        </colgroup>
        <tr>
          <Th3 style={{ width: '50%' }}>
            {t(t('Statistics_execution_tab1'))}
          </Th3>
          {renderHeaders(TARGET_YEARS)}
        </tr>
        {T2RenderRows(data, TARGET_YEARS)}
      </Table>
    </>
  ) : (
    t('No_data_available_2')
  )
}

export default Table2
