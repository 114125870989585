import styled, { css } from 'styled-components';

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const PageContent = styled.section`
  margin: 3rem 0px;
`;

export const StyledNoNotifDiv = styled.div`
  ${({ theme }) => css`
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    color: ${theme.colors.black};
    font-size: 14px;
    font-style: italic;
  `}
`;

export const StyledContainer = styled.tr`
  font-weight: ${props => props.unread ? "bold" : "normal"};
  color: #6f8d9d;
`;