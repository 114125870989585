import React from 'react';
import _isNil from 'lodash/isNil';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { StyledFormLabel } from '../MyAssessmentForm/MyAssessmentForm.styled'
import { StyledGreenFormLabel } from '../Section9.styled';
import { useTranslation } from 'react-i18next';
import { RequiredMark } from 'components/CommonStyled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const ATCommentForm = ({ formikInstance, nonEditable, decisionNonEditable, isSentStatus }) => {

  const { values, getFieldProps, touched, errors, setFieldValue } = formikInstance;
  const {t} = useTranslation();


  return (
    <>
      <Col style={{ marginTop: '30px', display: 'flex' }}>
        <StyledFormLabel xs={5} style={{ fontWeight: 'bold' }}>{t('AT_recommendation')}:</StyledFormLabel>
        <Form.Check
          style={{ marginLeft: '30px', marginRight: '30px' }}
          type="radio"
          label={t('AT_recommendation_option1')}
          disabled={nonEditable || decisionNonEditable}
          checked={values.recommends}
          onChange={() => setFieldValue('recommends', true)}
        />
        <Form.Check
          type="radio"
          label={t('AT_recommendation_option2')}
          disabled={nonEditable || decisionNonEditable}
          checked={!_isNil(values.recommends) && !values.recommends}
          onChange={() => setFieldValue('recommends', false)}
        />
      </Col>

      <Col style={{ marginTop: '20px', marginBottom: '50px' }}>
        <StyledFormLabel xs={5}>{t('AT_justification')}:</StyledFormLabel>
        <RequiredMark>*</RequiredMark>
        <Form.Control
          as="textarea" 
          rows={15} 
          style={{resize: "none"}}
          name={'justification'}
          className={touched.justification && errors.justification ? "error" : null}
          { ...getFieldProps('justification') }
          disabled={nonEditable || decisionNonEditable}
        />
        <FormErrorMessage
          touched={touched.justification}
          errors={errors.justification}
        />
      </Col>

      <Form.Group style={{ marginLeft: "10px", marginRight: "20px", marginTop: "40px" }}>
        <StyledGreenFormLabel>{t('F2_Adelante_comments')}</StyledGreenFormLabel>
        <Form.Control
          as="textarea" 
          rows={10} 
          style={{resize: "none"}}
          name={'assessment'}
          className={touched.assessment && errors.assessment ? "error" : null}
          { ...getFieldProps('assessment') }
          disabled={nonEditable || !isSentStatus}
        />
        <FormErrorMessage
          touched={touched.assessment}
          errors={errors.assessment}
        />
      </Form.Group>
    </>
  )
}

export default ATCommentForm;