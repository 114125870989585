import React, { useState, useEffect } from 'react'
import PageTitle from 'components/PageTitle'

import { useTranslation } from 'react-i18next'
import Header from './components/Header'
import Table from './components/Table'

import { PageHeader } from '../CommonStyled/Tabs.styled'
import { StyledRow, StyledRow3 } from '../CommonStyled/Tabs.styled'

import { getPartnerships } from '../../../services/initiative'

const StatsPartnerships = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [waiting, setWaiting] = useState(false)

  const getData = async (params) => {
    setWaiting(true)
    try {
      const results = await getPartnerships(params)
      setData(results)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  useEffect(() => {
    getData({})
  }, [])

  return (
    <>
      <PageHeader>
        <PageTitle title={t('menu_admin_6_4')} />
      </PageHeader>
      <Header data={data} waiting={waiting} getData={getData} />
      {data?.length ? (
        <StyledRow3>
          <b>{`${t('total_partnerships')}*: ${data?.length}`}</b>
        </StyledRow3>
      ) : null}
      <StyledRow>
        <Table data={data} waiting={waiting} setData={setData} />
      </StyledRow>
      <div style={{marginTop: '20px'}}>{t('Statistics_footnote_12')}</div>
    </>
  )
}

export default StatsPartnerships
