import React, { useEffect, useState } from 'react';
import { useRootStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { f1statusNamesConst, roleNamesConst } from 'constants/appConstants';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { getMaintenanceVariables } from 'services/apiConstants';
import { useParams } from 'react-router-dom';

import { StyledSendButton, StyledFooterParagraph, StyledExportButton, StyledFooter } from './Form1Footer.styled';
import PDFGenerationModal from 'components/PDFGenerationModal';
import SendCNDialog from './SendCNDialog';

import { sectionStateF1Const } from 'constants/appConstants';
const { INCOMPLETE, NEW, CLARIFICATION } = sectionStateF1Const;

const Form1Footer = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const [displayPDFModal, setDisplayPDFModal] = useState(false);
  const { id, sectionId } = useParams();

  const [disableButton, setDisableButton] = useState(true);
  const { formStore, initiativeStore } = useRootStore();
  const [closedWindow, setClosedWindow] = useState(true);

  const handleOpenModal = () => setDisplayModal(true);
  const handleCloseModal = () => setDisplayModal(false);

  const handleOpenPDFModal = () => setDisplayPDFModal(true);
  const handleClosePDFModal = () => setDisplayPDFModal(false);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getMaintenanceVariables();
        if (response && response[2]) {
          setClosedWindow(!response[2].keyValue);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAPI();
  }, []);

  useEffect(() => {
    if (formStore.sectionsStatus) {
      setDisableButton(
        !allTrue(formStore.sectionsStatus) ||
          !(formStore.formStatus === f1statusNamesConst.DRAFT) ||
          initiativeStore.role === roleNamesConst.GUEST ||
          (formStore.formStatus === f1statusNamesConst.DRAFT && initiativeStore.formVersion === 1 && closedWindow),
      );
    }
  }, [formStore.sectionsStatus, formStore.formStatus, closedWindow, initiativeStore, initiativeStore.formVersion]);

  function allTrue(obj) {
    for (var o in obj) {
      if (obj[o] === INCOMPLETE || obj[o] === NEW || obj[o] === CLARIFICATION) return false;
    }
    return true;
  }

  return sectionId !== '10' ? (
    <>
      <SendCNDialog displayDialog={displayModal} title={t('modal_title')} onCloseModal={handleCloseModal} />

      <PDFGenerationModal show={displayPDFModal} onClose={handleClosePDFModal} initiativeId={id} formId={1} />

      <Container fluid>
        <StyledFooterParagraph>{htmlParser(t('F1_footer'))}</StyledFooterParagraph>

        <StyledFooter>
          <StyledExportButton onClick={handleOpenPDFModal}>{t('pdf_gen_export')}</StyledExportButton>
          <StyledSendButton disabled={disableButton} onClick={handleOpenModal}>
            {t('F1_send_button')}
          </StyledSendButton>
        </StyledFooter>
      </Container>
    </>
  ) : null;
};

export default observer(Form1Footer);
