export const initialValues = {
  entityName:"", 
  acronym:"", 
  entityType:{
    id:"1",
    name:"Public - national level",
  },        
  role:"0",
  optionA: false,
  optionB: false,
  optionC: false,
  optionD: false,
  other: "",
  address:"",
  cityName:"",
  stateName:"",
  country:{
    id:"1",
    name:"Aphganistan"
  },
  web:"",
  ownerIsSigner: false,
  firstNameSigner:"",
  lastNameSigner:"",
  positionSigner:"",
  emailSigner:"",
  phoneSigner:"",
  firstNameTciApplicant:"",
  lastNameTciApplicant:"",
  positionResp:"",
  emailResp:"",
  phoneResp:"",
  triangulardoc:{},
  triangulardocUploaded: false
};
