import { validationSchema as ATKE_CONSORTIUM_VALIDATION_SCHEMA } from './AtkeConsortiumForm/AtkeConsortiumForm.validations';
import { validationSchema as EUD_VALIDATION_SCHEMA } from './EudForm/EudForm.validations';
import { validationSchema as INTPA_VALIDATION_SCHEMA } from './IntpaForm/IntpaForm.validations';

export const getValidationSchemaByRole = (role) => {
  if (role == 0) {
    return ATKE_CONSORTIUM_VALIDATION_SCHEMA;
  } else if (role == 1) {
    return EUD_VALIDATION_SCHEMA;
  } else if (role == 2) {
    return INTPA_VALIDATION_SCHEMA;
  }
};