import styled from 'styled-components';


export const StyledIntroParagraph = styled.p`
  line-height: 1.3rem;
  padding-left: 50px;
`;

export const StyledParagraph = styled.p`
  line-height: 1.3rem;
`;

export const StyledWarningP = styled.p`
  color: #FF6565;
  margin: 0px;
  margin-top: 2px;
  font-size: .8em;
`;

export const StyledRequiredSpan = styled.span`
  color: red;
`;
