import React, {useEffect} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Theme from 'theme';
import AppRouter from 'routes';
import { RootStoreProvider } from 'stores';
import { useTranslation } from 'react-i18next';
import { CookiesProvider } from 'react-cookie';
import ErrorBoundary from 'components/ErrorBoundary';

const App = () => {
  const { t } = useTranslation();
  
  useEffect(() => {
    document.getElementsByTagName("META")[3].content= t('Meta_description');
   }, [])

  return (
    <Theme>
      <CookiesProvider>
        <RootStoreProvider>
          <Router>
            <ErrorBoundary />
            <AppRouter />
          </Router>
        </RootStoreProvider>
      </CookiesProvider>
    </Theme>
  );
}

export default App;
