import styled, { css } from 'styled-components'
import Form from 'react-bootstrap/Form';

export const StyledSubtitle = styled.div`
  ${({ theme }) => css`
    margin-top: 5px;
    margin-bottom: 10px;
    padding-left: 15px;
    color: ${theme.brandColors.blue};
    letter-spacing: 2px;
    font-weight: 650;
    align-items: center;
  `}
`;

export const StyledFormLabel = styled(Form.Label)`
  padding-left: 30px;
`;