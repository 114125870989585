import React from 'react';
import htmlParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody 
} from '../CommonStyles'
import {
  StyledText
} from 'components/Forms/Form5/Section1/Section1.styled';

const Section1 = () => {
  const { t } = useTranslation();
  
  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`1 - ${t('F1_S1_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <StyledText>
          {htmlParser(t('F5_S1_Intro'))}
        </StyledText>    
      </StyledBody>
    </SectionContainer>
  )
}

export default Section1;