import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { getAllService, markAllAsReadService, markReadByID, markDoneByID, markUndoneByID } from 'services/notifications';
import {
  roleNamesConst
} from 'constants/appConstants';
import { useRootStore } from 'stores';
import { useTranslation } from 'react-i18next';
import DayJS from 'dayjs';

import Table from 'react-bootstrap/Table';
import CustomModal from 'components/Modal';
import PageTitle from 'components/PageTitle';
import { PrimaryButton } from 'components/Buttons';
import {
  PageHeader,
  PageContent,
  StyledNoNotifDiv,
  StyledContainer
} from './Notifications.styled';


const { PUBLIC, INTPA, EUD, ATKE, ATNKE } = roleNamesConst;

const baseRoute = {
  [PUBLIC]: "/applicant/initiative/",
  [INTPA]: "/intpa/initiative/",
  [EUD]: "/eud/initiative/",
  [ATKE]: "/ta/initiative/",
  [ATNKE]: "/ta/initiative/"
}

const adminRoles = [INTPA, EUD, ATKE, ATNKE];


const Notifications = () => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const { authStore } = useRootStore();
  const role = authStore.roles[0];

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr);
    return date.format("DD/MM/YYYY");
  }

  async function getNotifications() {
    try {
      const response = await getAllService();
      const isAdmin = adminRoles.includes(role);
      response.forEach(e => {
        if (e.initiative) {
          let formNumber = parseInt(e.form.substring(1));
         if (formNumber < 5) {
            if (isAdmin) {
              // on a F1 sent notification admin should go see F2x
              if (formNumber % 2 != 0) { formNumber += 1; }
            } else {
              // on a F2c sent notification public should go see F1
              if (formNumber % 2 == 0) { formNumber -= 1; }
            }
         } 
         else
            formNumber = 5;
          e.link = `${baseRoute[role]}${e.initiative.id}/form/${formNumber}/section/${e.notificationType == 27 || e.notificationType == 28 ? "17" : "1"}`;
          e.label = `${t(`notification_type_${e.notificationType}`)} ${e.initiative.code}`
        } else {
          e.label = t(`notification_type_${e.notificationType}`);
        }
      })
      return response;
    }
    catch (e) {
      console.log(e);
    }
  }

  async function updateNotifications() {
    const response = await getNotifications();
    setNotifications(response);
  }

  useEffect(() => {
    updateNotifications();
  }, []);

  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
    type: 'default'
  });

  const onHideModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  const handleMarkAllAsRead = async (e) => {
    try {
      await markAllAsReadService();
      updateNotifications();
    }
    catch (e) {
      console.log(e);
    }
  }

  /*
  ////////////// DEPRECATED /////////////////////
  const handleMarkAsDone = async (e, notifID) => {
    try {
      if (e.target.checked) {
        await markDoneByID(notifID);
      }
      else {
        await markUndoneByID(notifID);
      }
      updateNotifications();
    }
    catch (e) {
      console.log(e);
    }
  }
  */

  const handleMarkAsRead = async (id) => {
    try {
      await markReadByID(id);
      updateNotifications();
    }
    catch (e) {
      console.log(e);
    }
  }

  const renderTable = () => (
    <Table>
      <thead>
        <tr>
          <th>{t("Notifications_date")}</th>
          <th>{t("Notifications_subject")}</th>
        </tr>
      </thead>
      <tbody>
        {notifications.map(e => (
          <StyledContainer key={e.id} unread={!e.readDate}>
            <td>{e.creationDate? formatDate(e.creationDate): '-'}</td>
            <td>
              {e.initiative && (
                <Link
                  to={e.link}
                  onClick={() => { handleMarkAsRead(e.id) }}
                >{e.label}</Link>
              )}
            </td>
          </StyledContainer>
        ))}
      </tbody>
    </Table>
  )

  return (
    <>
      { modal.isOpen && <CustomModal
        show={modal.isOpen}
        onHide={onHideModal}
        message={modal.message}
        type={modal.type}
      />}
      <PageHeader>
        <PageTitle title={t("Notifications_title")} />
        <PrimaryButton disabled={!(notifications && notifications.length)} onClick={handleMarkAllAsRead}>
          {t("Notifications_markall")}
        </PrimaryButton>
      </PageHeader>
      <PageContent>
        {(notifications && notifications.length > 0) ?
          renderTable() :
          <StyledNoNotifDiv>
            {t("Notifications_no_notif")}
          </StyledNoNotifDiv>}
      </PageContent>
    </>
  )
};

export default Notifications;
