import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  StyledRadioLabel,
} from "../Styled";
import { FormFielErrorMessage, RequiredMark } from "components/CommonStyled";
import { getIn, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const PartnerEntityForm = ({
  index,
  countries,
  entityTypes,
}) => {
  const { t } = useTranslation();

  const {
    getFieldProps,
    touched,
    errors,
    values,
    handleChange,
  } = useFormikContext();

  const fieldPath = (field) => `partners.${index}.${field}`;

  const fieldInfo = (field) => {
    const getFieldError = getIn(errors, field);
    const getFieldTouched = getIn(touched, field);
    return {
      touched: getFieldTouched,
      error:
        getFieldError && getFieldTouched ? (
          <FormFielErrorMessage>{getFieldError}</FormFielErrorMessage>
        ) : null,
    };
  }
  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_Entity_name")}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath("entityName")}
            {...getFieldProps(fieldPath("entityName"))}
            className={
              touched[fieldPath("entityName")] &&
              errors[fieldPath("entityName")]
                ? "error"
                : null
            }
          />
          <FormErrorMessage
            touched
            errors={fieldInfo(fieldPath("entityName")).error}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_Official_acronym")}:
        </Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath("acronym")}
            {...getFieldProps(fieldPath("acronym"))}
            className={
              touched[fieldPath("acronym")] && errors[fieldPath("acronym")]
                ? "error"
                : null
            }
          />
          <FormErrorMessage
            touched
            errors={fieldInfo(fieldPath("acronym")).error}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} style={{ alignItems: "center" }}>
        <Form.Label column lg={4}>
          {t("F1_S2_Role")}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={8} style={{ marginTop: "10px" }}>
          <StyledRadioLabel>
            <input
              type="radio"
              name={fieldPath("role")}
              value="0"
              checked={values.partners[index].role === "0"}
              onChange={handleChange}
              style={{ marginRight: "10px" }}
            />
            {t("F1_S2_Recipient")}
          </StyledRadioLabel>

          <StyledRadioLabel>
            <input
              type="radio"
              name={fieldPath("role")}
              value="1"
              checked={values.partners[index].role === "1"}
              onChange={handleChange}
              style={{ marginRight: "10px" }}
            />
            {t("F1_S2_Provider1")}
          </StyledRadioLabel>

          <StyledRadioLabel>
            <input
              type="radio"
              name={fieldPath("role")}
              value="2"
              checked={values.partners[index].role === "2"}
              onChange={handleChange}
              style={{ marginRight: "10px" }}
            />
            {t("F1_S2_Provider2")}
          </StyledRadioLabel>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_Country")}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={4}>
          <Form.Control
            as="select"
            name={fieldPath("countryId")}
            {...getFieldProps(fieldPath("countryId"))}
            className={
              touched[fieldPath("countryId")] && errors[fieldPath("countryId")]
                ? "error"
                : null
            }
          >
            <option value="">{t("Placeholder_select")}</option>
            {countries?.map((country) => (
              <option key={country.id} value={country.id}>
                {t(country.name)}
              </option>
            ))}
          </Form.Control>
          <FormErrorMessage
            touched
            errors={fieldInfo(fieldPath("countryId")).error}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>
          {t("F1_S2_Type_of_entity")}
          <RequiredMark>*</RequiredMark>:
        </Form.Label>
        <Col lg={4}>
          <Form.Control
            as="select"
            name={fieldPath("entityTypeId")}
            {...getFieldProps(fieldPath("entityTypeId"))}
            className={
              touched[fieldPath("entityTypeId")] &&
              errors[fieldPath("entityTypeId")]
                ? "error"
                : null
            }
          >
            <option value="">{t("Placeholder_select")}</option>
            {entityTypes?.map((entity) => (
              <option key={entity.id} value={entity.id}>
                {t(`entity_type_${entity.id}`)}
              </option>
            ))}
          </Form.Control>
          <FormErrorMessage
            touched
            errors={fieldInfo(fieldPath("entityTypeId")).error}
          />
        </Col>
      </Form.Group>
    </>
  );
};

export default PartnerEntityForm;
