import React from 'react'
import { handleDownload } from '../helper'
import { Row, Col } from 'react-bootstrap'
import { StyledButtonLink } from 'components/Buttons/Buttons.styled'
import { RoundedRemoveDiv } from './DifussionMaterial.styled'
import i18n from 'i18n';

export const renderItemsByType = (items, handleOpenModal, handleClose, handleDelete, setModalInfo  ) => {
  let list = []
  let filteredItems = []

  for (let i = 1; i <= 7; i++) {
    filteredItems = items.filter((material) => material?.materialType?.id == i)
    if (filteredItems.length > 0) {
      list.push(
        <Col key={i}>
          <Row style={{ marginBottom: '15px', marginTop: '15px' }}>
            <b>{i18n.t(`material_type_${i}`)}</b>
          </Row>
          {filteredItems.map((material) => (
            <Row>
              <Col style={{ padding: '0px', textAlign: 'justify' }} sm={7}>
                {material.description}
              </Col>
              <Col
                sm={5}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingLeft: '30px',
                }}
              >
                <StyledButtonLink
                  variant="link"
                  onClick={() =>
                    handleOpenModal({
                      show: true,
                      isEdit: true,
                      data: material,
                    })
                  }
                >
                  {i18n.t('Button_edit')}
                </StyledButtonLink>
                {material.url ? (
                  <StyledButtonLink
                    variant="link"
                    href={
                      material.url.split(':')[0].toLowerCase() == 'http'
                        ? material.url
                        : 'http://' + material.url
                    }
                    target="_blank"
                  >
                    {i18n.t('F1_S8_Link_account')}
                  </StyledButtonLink>
                ) : null}

                {material.file ? (
                  <StyledButtonLink
                    variant="link"
                    onClick={() =>
                      handleDownload(
                        material.file.uuid,
                        material.file.fileName,
                        setModalInfo,
                      )
                    }
                  >
                    {i18n.t('F5_S14_File')}
                  </StyledButtonLink>
                ) : null}

                <StyledButtonLink
                  variant="button"
                  onClick={() =>
                    handleClose({
                      isOpen: true,
                      text: i18n.t('Confirmation delete material'),
                      action: () => handleDelete(material.id),
                    })
                  }
                  style={{ fontSize: '10px' }}
                >
                  <RoundedRemoveDiv>x</RoundedRemoveDiv>
                </StyledButtonLink>
              </Col>
            </Row>
          ))}
        </Col>,
      )
    }
  }
  return list
}