import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledFormLabel,
} from './Styled'
import Form from 'react-bootstrap/Form';
import { useFormikContext } from 'formik';
import { Col } from 'react-bootstrap';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const MainQuestions = () => {
  const {t} = useTranslation();
  const { getFieldProps, touched, errors, handleBlur, handleChange, setFieldValue } = useFormikContext();

  return (
    <div style={{marginBottom: '100px'}}>
      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F6_AT_assessment_question1')}:</StyledFormLabel>
          <Form.Control
            name='knowledge'
            as="textarea"
            style={{resize: "none"}}
            rows={8}
            className={touched.knowledge && errors.knowledge ? "error" : null}
            { ...getFieldProps('knowledge') }
          />
          <FormErrorMessage
            touched={touched.knowledge}
            errors={errors.knowledge}
          />
        </Col>
      </Form.Group>

      <Form.Group>
      <Col>
        <StyledFormLabel xs={5}>{t('F6_AT_assessment_question2')}:</StyledFormLabel>
        <Form.Control
          name='impact'
          as="textarea"
          style={{resize: "none"}}
          rows={8}
          className={touched.impact && errors.impact ? "error" : null}
          { ...getFieldProps('impact') }
        />
        <FormErrorMessage
          touched={touched.impact}
          errors={errors.impact}
        />
      </Col>
      </Form.Group>

      <Form.Group>
      <Col>
        <StyledFormLabel xs={5}>{t('F6_AT_assessment_question3')}:</StyledFormLabel>
        <Form.Control
          name='value'
          as="textarea"
          style={{resize: "none"}}
          rows={8}
          className={touched.value && errors.value ? "error" : null}
          { ...getFieldProps('value') }
        />
        <FormErrorMessage
          touched={touched.value}
          errors={errors.value}
        />
      </Col>
      </Form.Group>

      <Form.Group>
      <Col>
        <StyledFormLabel xs={5}>{t('F55_S18_assessment_question4')}:</StyledFormLabel>
        <Form.Control
          name='elements'
          as="textarea"
          style={{resize: "none"}}
          rows={8}
          className={touched.elements && errors.elements ? "error" : null}
          { ...getFieldProps('elements') }
        />
        <FormErrorMessage
          touched={touched.elements}
          errors={errors.elements}
        />
      </Col>
      </Form.Group>

      <Form.Group>
      <Col>
        <StyledFormLabel xs={5}>{t('F6_AT_assessment_question5')}:</StyledFormLabel>
        <Form.Control
          name='overall'
          as="textarea"
          style={{resize: "none"}}
          rows={8}
          className={touched.overall && errors.overall ? "error" : null}
          { ...getFieldProps('overall') }
        />
        <FormErrorMessage
          touched={touched.overall}
          errors={errors.overall}
        />
      </Col>
      </Form.Group>
    </div>
  )
};

export default MainQuestions;