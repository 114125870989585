import { ADELANTE_1_INITIATIVES } from 'components/CommunityReport/constants';
import ContactInitiativeModal from 'components/CommunityReport/ContactInitiativeModal';
import React, { useState, useEffect, useMemo, createContext, useContext } from 'react';
import { canAccessCommunity, getCommunityReport } from 'services/community';
import _orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';

const AdelanteCommunityFeaturesContext = createContext({
  allItems: [],
  filteredItems: [],
  searchItems: (keyword) => {},
  onOpenContact: async (initiativeId, type) => {},
  onOrderDataByField: (fieldName) => {}
});

const AdelanteCommunityFeaturesContextProvider = ({ children }) => {
  const { t } = useTranslation();
  
  const [contactModalInfo, setContactModalInfo] = useState({
    open: false,
    initiativeId: null,
    type: 'initiative'
  });
  const [canSeeComunityLink, setCanSeeComunityLink] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [itemsKeywords, setItemsKeywords] = useState([]);
  const [fieldsOrder, setFieldsOrder] = useState({
    title: 'asc',
    instrument: 'asc',
    primaryGoalId: 'desc',
    secondaryGoalId: 'desc'
  });

  const onCloseContact = () => {
    setContactModalInfo({
      open: false,
      initiativeId: null,
      type: 'initiative'
    })
  }

  async function fetchCommunityAccess() {
    try {
      const result = await canAccessCommunity();
      setCanSeeComunityLink(!!result?.canAccess)
    }
    catch(e){
      console.log(e);
    }
  }

  async function fetchCommunityReportData() {
    try {
      const result = await getCommunityReport();
      let expandedResult = _orderBy([
        ...(result || []),
        ...ADELANTE_1_INITIATIVES
      ], ["title"], "asc");
      expandedResult = expandedResult?.map(item => {
        return {
          ...item,
          instrumentRaw: item.instrument,
          instrument: item.instrument ? 
          item.instrument !== 'initiative' && item.instrument !== 'adelante1' ?
            t(`fund_type_${item.instrument}`)
            :
            t(`instrument_${item.instrument}`, { year: item?.year || "2022"}) 
            : 
            ' - '
        }
      })
      setAllItems(expandedResult);
      setFilteredItems(expandedResult);
      const keywordsList = [];
      expandedResult?.forEach(item => {
        keywordsList.push(`${String(item?.title).toLowerCase()} ${String(item?.objective).toLowerCase()}`)
      })
      setItemsKeywords(keywordsList)
    }
    catch(e){
      console.log(e);
    }
  }

  useEffect(() => {
    fetchCommunityAccess();
  }, []);

  useEffect(() => {
    if (canSeeComunityLink) {
      fetchCommunityReportData();
    }
  }, [canSeeComunityLink]);

  const searchItems = (keyword = '') => {
    const filtered = [];
    // loop over items to search
    const lowerCaseKeyword = keyword?.toLocaleLowerCase()?.trim();
    itemsKeywords?.forEach((itemKeywords, index) => {
      if (itemKeywords.includes(lowerCaseKeyword)) {
        filtered.push(allItems[index]);
      }
    }); 
    setFilteredItems(filtered);
  };

  const onOpenContact = async (initiativeId, type) => {
    setContactModalInfo({
      open: true,
      initiativeId,
      type
    })
  };

  const onOrderDataByField = (fieldName) => {
    const newOrder = fieldsOrder[fieldName] === 'asc' ? 'desc' : 'asc';
    setFieldsOrder({
      ...fieldsOrder,
      [fieldName]: newOrder
    })
    const sortedArray = _orderBy(filteredItems, [fieldName], newOrder);
    setFilteredItems(sortedArray);
  }

  const value = useMemo(() => {
    return {
      allItems,
      filteredItems,
      searchItems,
      onOpenContact,
      onOrderDataByField
    }
  }, [
    allItems,
    filteredItems,
    searchItems,
    onOpenContact,
    onOrderDataByField
  ]);

  return (
    <AdelanteCommunityFeaturesContext.Provider value={value}>
      <ContactInitiativeModal
        isOpen={contactModalInfo.open}
        onClose={onCloseContact}
        initiativeId={contactModalInfo.initiativeId}
        type={contactModalInfo.type}
      />
      { children }
    </AdelanteCommunityFeaturesContext.Provider>
  )
}

const useAdelanteCommunityFeaturesContext = () => useContext(AdelanteCommunityFeaturesContext);

export {
  AdelanteCommunityFeaturesContextProvider,
  useAdelanteCommunityFeaturesContext
}

