import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { validationSchema } from "./Section2.validations";
import { useRootStore } from "stores";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import TextValue from 'components/TextValue'

import FormLayout from "../../FormLayout";
import { useTranslation } from "react-i18next";
import htmlParser from "html-react-parser";
import { StyledCustomLink } from 'components/Forms/Forms.styled';
import CustomModal from "components/Modal";
import FormContainer from "components/FormContainer";
import HelpIcon from "components/HelpIcon";
import { formInitialValues } from "./Section2.initialValues";
import { useConstantsForm1 } from "customHooks/useConstantsForm1";
import { saveFormSection, saveFile, getDownload } from "services/initiative";
import { RequiredMark } from "components/CommonStyled";
import { f1statusNamesConst } from "constants/appConstants";
import {
  StyledIntroParagraph,
  StyledFormLabel,
  StyledSeparator,
  StyledDiv,
  StyledRadioSection,
  StyledRadioSectionTitle,
  StyledRadioLabel,
} from "./Section2.styled";
import FileUploader from 'components/FileUploader';
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const Section2 = () => {
  const { formStore, authStore, initiativeStore } = useRootStore();
  const [clarification, setClarification] = useState({
    show: false,
    msg: "",
  });
  const [nonEditable, setNonEditable] = useState(false);
  const [modal, setModal] = React.useState({
    isOpen: false,
    message: "",
    type: "default",
  });

  const onHideModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (initiativeStore.formStateName === f1statusNamesConst.DRAFT) {
      if (initiativeStore.formVersion === 2) {
        if (formStore.formSectionClarification) {
          setNonEditable(false);
          setClarification({
            show: true,
            msg: formStore.formSectionClarification
              ? formStore.formSectionClarification
              : t("No_clarifications"),
          });
        } else {
          setNonEditable(true);
        }
      } else {
        setNonEditable(false);
      }
    } else {
      setNonEditable(true);
    }
  }, [
    initiativeStore.currentFormStateName,
    initiativeStore.formVersion,
    formStore.formSectionClarification,
  ]);

  const { id, formId, sectionId } = useParams();

  const { filteredCountries, entityTypes } = useConstantsForm1();
  const [saved, setSaved] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {
        ...formInitialValues,
        ...formStore.formSectionData[0],
      };
      for (const key in formSectionData) {
        if (
          !formSectionData[key] &&
          key !== "triangulardoc" &&
          key !== "role"
        ) {
          formSectionData[key] = setStringFieldValue(formSectionData[key]);
        }
      }
      formSectionData.entityTypeId = formSectionData.entityType
        ? formSectionData.entityType.id
        : "";
      formSectionData.countryId = formSectionData.country
        ? formSectionData.country.id
        : "";
      formSectionData.triangulardocUploaded =
        formSectionData.triangulardoc !== null;
      delete formSectionData["id"];
      delete formSectionData["country"];
      delete formSectionData["entityType"];
      setInitialValues(formSectionData);
    } else {
      setInitialValues(formInitialValues);
    }
  }, [formStore.formSectionData]);

  useEffect(() => {
    if (saved) window.scrollTo(0, 0);
  }, [saved]);

  const setStringFieldValue = (newValue) => {
    return newValue ? newValue : "";
  };

  const handleFileUpload = async (e, setFieldValue, handleChange) => {
    try {
      const file = document.getElementById("fileInput").files[0];
      const fileExtension = file.name.split(".").pop();
      if (file.size > 20971520) {
        setModal({
          ...modal,
          message: t("Error_file4"),
          type: "error",
          isOpen: true,
        });
      } else if (fileExtension.toUpperCase() !== "PDF") {
        setModal({
          ...modal,
          message: t("Error_file3"),
          type: "error",
          isOpen: true,
        });
      } else {
        setIsUploadingFile(true);
        const uid = await saveFile(id, formId, sectionId, file);
        setFieldValue("triangulardoc.fileName", file.name);
        setFieldValue("triangulardoc.uuid", uid);
        setFieldValue("triangulardocUploaded", true);
        handleChange(e);
      }
    } catch (error) {
      setModal({
        ...modal,
        message: t("Error_file2"),
        type: "error",
        isOpen: true,
      });
    } finally {
      setIsUploadingFile(false);
    }
  };

  const handleDownload = async (uuid, downloadName) => {
    try {
      const file = await getDownload(uuid).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadName);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      setModal({
        ...modal,
        message: t("Error_file1"),
        type: "error",
        isOpen: true,
      });
    }
  };

  const onSubmit = async (values, { resetForm }) => {
    values["emailResp"] = authStore.email;
    try {
      const { complete } = await saveFormSection(id, formId, sectionId, values);
      formStore.setSectionStatus(sectionId, complete);
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid ? onSubmit(values, { resetForm }) : alert(t("not_saved_alert"));
  };

  return (
    <FormLayout>
      {modal.isOpen && (
        <CustomModal
          show={modal.isOpen}
          onHide={onHideModal}
          message={modal.message}
          type={modal.type}
        />
      )}
      <FormContainer
        number={2}
        title={t("F1_s2_title")}
        isHelp={true}
        showSavedMessage={saved}
        formId="form1section2"
        showSaveButton={!nonEditable}
      >
        <StyledIntroParagraph>
          {htmlParser(t("F1_S2_intro"))}
        </StyledIntroParagraph>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            isValid,
            resetForm,
            setFieldValue,
            handleChange,
            handleBlur,
          }) => {
            return (
              <form
                onSubmit={(e) => {
                  onValidateSubmit(e, isValid, values, { resetForm });
                }}
                id="form1section2"
              >
                {dirty && setSaved(false)}

                {clarification.show && (
                  <Form.Group
                    style={{ marginLeft: "50px", paddingTop: "10px" }}
                    as={Row}
                  >
                    <Form.Label>{t("Clarification_requests")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      style={{ border: "2px solid red", resize: "none" }}
                      rows={4}
                      disabled={true}
                      value={clarification.msg}
                    />
                  </Form.Group>
                )}

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Entity_name")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="entityName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.entityName}
                      className={
                        touched.entityName && errors.entityName ? "error" : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.entityName}
                      errors={errors.entityName}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Official_acronym")}:
                  </StyledFormLabel>
                  <Col lg={4}>
                    <Form.Control
                      type="text"
                      name="acronym"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.acronym}
                      className={
                        touched.acronym && errors.acronym ? "error" : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.acronym}
                      errors={errors.acronym}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Type_of_entity")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={4}>
                    <Form.Control
                      name="entityTypeId"
                      as="select"
                      onChange={handleChange}
                      value={values.entityTypeId}
                      disabled={nonEditable}
                    >
                      <option value="">{t("Placeholder_select")}</option>
                      {entityTypes.map((entity) => (
                        <option key={entity.id} value={entity.id}>
                          {t(`entity_type_${entity.id}`)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched={touched.entityTypeId}
                      errors={errors.entityTypeId}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: "center" }}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Role")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={8} style={{ marginTop: "10px" }}>
                    <StyledRadioLabel>
                      <input
                        type="radio"
                        name="role"
                        value={"0"}
                        checked={values.role == 0}
                        onChange={handleChange}
                        style={{ marginRight: "10px" }}
                        disabled={nonEditable}
                      />
                      {t("F1_S2_Recipient")}
                    </StyledRadioLabel>

                    <StyledRadioLabel>
                      <input
                        type="radio"
                        name="role"
                        value={"1"}
                        checked={values.role == 1}
                        onChange={handleChange}
                        style={{ marginRight: "10px" }}
                        disabled={nonEditable}
                      />
                      {t("F1_S2_Provider1")}
                    </StyledRadioLabel>

                    <StyledRadioLabel>
                      <input
                        type="radio"
                        name="role"
                        value={"2"}
                        checked={values.role == 2}
                        onChange={handleChange}
                        style={{ marginRight: "10px" }}
                        disabled={nonEditable}
                      />
                      {t("F1_S2_Provider2")}
                    </StyledRadioLabel>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel className="align-self-start" column lg={4}>
                    {t("F1_S2_Contribution")}
                    <RequiredMark>*</RequiredMark>:{" "}
                  </StyledFormLabel>
                  <Col lg={8}>
                    <StyledRadioSection>
                      <StyledRadioSectionTitle>
                        {" "}
                        {t("F1_S2_Role_help")}{" "}
                      </StyledRadioSectionTitle>
                      <Form.Check
                        name="optionA"
                        type="checkbox"
                        checked={values.optionA}
                        disabled={nonEditable}
                        value={values.optionA}
                        onChange={handleChange}
                        label={t("F1_S2_Provider_detail_A")}
                      />
                      <Form.Check
                        name="optionB"
                        type="checkbox"
                        checked={values.optionB}
                        disabled={nonEditable}
                        value={values.optionB}
                        onChange={handleChange}
                        label={t("F1_S2_Provider_detail_B")}
                      />
                      <Form.Check
                        name="optionC"
                        type="checkbox"
                        checked={values.optionC}
                        disabled={nonEditable}
                        value={values.optionC}
                        onChange={handleChange}
                        label={t("F1_S2_Provider_detail_C")}
                      />
                      <Form.Check
                        name="optionD"
                        type="checkbox"
                        checked={values.optionD}
                        disabled={nonEditable}
                        value={values.optionD}
                        onChange={handleChange}
                        label={t("F1_S2_Provider_detail_D")}
                      />
                      <Form.Control
                        type="text"
                        name="other"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.other}
                        className={
                          touched.other && errors.other ? "error" : null
                        }
                        disabled={!values.optionD || nonEditable}
                      />
                      <FormErrorMessage
                        touched={touched.other}
                        errors={errors.other}
                      />
                    </StyledRadioSection>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: "center" }}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Entity_address")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={8}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      style={{ resize: "none" }}
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                      className={
                        touched.address && errors.address ? "error" : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.address}
                      errors={errors.address}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Country")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={4}>
                    <Form.Control
                      name="countryId"
                      as="select"
                      onChange={handleChange}
                      value={values.countryId}
                      disabled={nonEditable}
                    >
                      <option value="">{t("Placeholder_select")}</option>
                      {filteredCountries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {t(country.name)}
                        </option>
                      ))}
                    </Form.Control>
                    <FormErrorMessage
                      touched={touched.countryId}
                      errors={errors.countryId}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_State_Province_Region")}:
                  </StyledFormLabel>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="stateName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.stateName}
                      className={
                        touched.stateName && errors.stateName ? "error" : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.stateName}
                      errors={errors.stateName}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_City")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="cityName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cityName}
                      className={
                        touched.cityName && errors.cityName ? "error" : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.cityName}
                      errors={errors.cityName}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Website")}:
                  </StyledFormLabel>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="web"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.web}
                      className={touched.web && errors.web ? "error" : null}
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.web}
                      errors={errors.web}
                    />
                  </Col>
                </Form.Group>

                <StyledSeparator />

                <Form.Group as={Row} style={{ alignItems: "center" }}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Responsisble_person")}
                    <RequiredMark>*</RequiredMark>:
                    <HelpIcon
                      padding={"0 0 0 0.75rem"}
                      info={t("F1_S2_Responsible_person_INFO")}
                    />
                  </StyledFormLabel>
                  <Col lg={4}>
                    <Form.Control
                      type="text"
                      name="firstNameTciApplicant"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstNameTciApplicant}
                      placeholder={t("F1_S2_First_name")}
                      className={
                        touched.firstNameTciApplicant &&
                        errors.firstNameTciApplicant
                          ? "error"
                          : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.firstNameTciApplicant}
                      errors={errors.firstNameTciApplicant}
                    />
                  </Col>
                  <Col lg={4}>
                    <Form.Control
                      type="text"
                      name="lastNameTciApplicant"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastNameTciApplicant}
                      placeholder={t("F1_S2_Last_Name")}
                      className={
                        touched.lastNameTciApplicant &&
                        errors.lastNameTciApplicant
                          ? "error"
                          : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.lastNameTciApplicant}
                      errors={errors.lastNameTciApplicant}
                    /> 
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Position")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="positionResp"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.positionResp}
                      className={
                        touched.positionResp && errors.positionResp
                          ? "error"
                          : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.positionResp}
                      errors={errors.positionResp}
                    /> 
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Phone_number")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="phoneResp"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneResp}
                      placeholder={t("Example") + " +12133734253"}
                      className={
                        touched.phoneResp && errors.phoneResp ? "error" : null
                      }
                      disabled={nonEditable}
                    />
                    <FormErrorMessage
                      touched={touched.phoneResp}
                      errors={errors.phoneResp}
                    /> 
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Email")}:
                  </StyledFormLabel>
                  <TextValue value={authStore.email} />
                </Form.Group>

                <StyledSeparator />

                <Form.Group as={Row}>
                  <Col lg={11}>
                    <Form.Check
                      name="ownerIsSigner"
                      type="checkbox"
                      value={values.ownerIsSigner}
                      onChange={(e) => {
                        setFieldValue(
                          "firstNameSigner",
                          values.firstNameTciApplicant
                        );
                        setFieldValue(
                          "lastNameSigner",
                          values.lastNameTciApplicant
                        );
                        setFieldValue("positionSigner", values.positionResp);
                        setFieldValue("emailSigner", authStore.email);
                        setFieldValue("phoneSigner", values.phoneResp);
                        handleChange(e);
                      }}
                      checked={values.ownerIsSigner}
                      style={{
                        marginLeft: "50px",
                        marginTop: "10px",
                        lineHeight: "1.3",
                      }}
                      label={t("F1_S2_Duly_signer_checkbox") + "."}
                      disabled={nonEditable}
                    />
                  </Col>
                  <Col lg={1} style={{ justifyContent: "contents" }}>
                    <HelpIcon info={t("F1_S2_Duly_signer_disclaimer_INFO")} />
                  </Col>
                </Form.Group>

                <StyledSeparator />

                <Form.Group as={Row} style={{ alignItems: "center" }}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Duly_signer")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={4}>
                    <Form.Control
                      type="text"
                      name="firstNameSigner"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("F1_S2_First_name")}
                      value={values.firstNameSigner}
                      className={
                        touched.firstNameSigner && errors.firstNameSigner
                          ? "error"
                          : null
                      }
                      disabled={values.ownerIsSigner || nonEditable}
                    />
                    {!values.ownerIsSigner ? (
                      <FormErrorMessage
                        touched={touched.firstNameSigner}
                        errors={errors.firstNameSigner}
                      /> 
                    ) : null}
                  </Col>
                  <Col lg={4}>
                    <Form.Control
                      type="text"
                      name="lastNameSigner"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t("F1_S2_Last_Name")}
                      value={values.lastNameSigner}
                      className={
                        touched.lastNameSigner && errors.lastNameSigner
                          ? "error"
                          : null
                      }
                      disabled={values.ownerIsSigner || nonEditable}
                    />
                    {!values.ownerIsSigner ? (
                      <FormErrorMessage
                        touched={touched.lastNameSigner}
                        errors={errors.lastNameSigner}
                      /> 
                    ) : null}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: "center" }}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Position")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="positionSigner"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.positionSigner}
                      className={
                        touched.positionSigner && errors.positionSigner
                          ? "error"
                          : null
                      }
                      disabled={values.ownerIsSigner || nonEditable}
                    />
                    {!values.ownerIsSigner ? (
                      <FormErrorMessage
                        touched={touched.positionSigner}
                        errors={errors.positionSigner}
                      /> 
                    ) : null}
                  </Col>
                </Form.Group>

                <Form.Group as={Row} style={{ alignItems: "center" }}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Email")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="emailSigner"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emailSigner}
                      className={
                        touched.emailSigner && errors.emailSigner
                          ? "error"
                          : null
                      }
                      disabled={values.ownerIsSigner || nonEditable}
                    />
                    {!values.ownerIsSigner ? (
                      <FormErrorMessage
                        touched={touched.emailSigner}
                        errors={errors.emailSigner}
                      /> 
                    ) : null}
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <StyledFormLabel column lg={4}>
                    {t("F1_S2_Phone_number")}
                    <RequiredMark>*</RequiredMark>:
                  </StyledFormLabel>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      name="phoneSigner"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneSigner}
                      placeholder={t("Example") + " +12133734253"}
                      className={
                        touched.phoneSigner && errors.phoneSigner
                          ? "error"
                          : null
                      }
                      disabled={values.ownerIsSigner || nonEditable}
                    />
                    {!values.ownerIsSigner ? (
                      <FormErrorMessage
                        touched={touched.phoneSigner}
                        errors={errors.phoneSigner}
                      /> 
                    ) : null}
                  </Col>
                </Form.Group>

                <StyledIntroParagraph>
                  {t("F1_S2_Duly_signer_disclaimer")}
                </StyledIntroParagraph>

                <StyledSeparator />

                <StyledDiv style={{ alignItems: "center" }}>
                  <FormErrorMessage
                    touched={touched.triangulardoc}
                    errors={errors.triangulardoc}
                  /> 
                  {t("F1_S2_Formalisation_document")}
                  <RequiredMark>*</RequiredMark>:
                </StyledDiv>

                <StyledDiv>
                  <FileUploader
                    file={values?.triangulardoc}
                    handleFileUpload={(e) => handleFileUpload(e, setFieldValue, handleChange)}
                    handleDownload={handleDownload}
                    isUploadingFile={isUploadingFile}
                    uploadLabel={t("F1_S2_Upload")}
                    nonEditable={nonEditable}
                  />
                </StyledDiv>

                <StyledDiv style={{ alignItems: "center" }}>
                  {t("F1_S2_Template_download")}{" "}
                  <StyledCustomLink
                    style={{ marginLeft: "5px" }}
                    href={t("F1_S2_Download_url")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("F1_S2_Download")}
                  </StyledCustomLink>
                </StyledDiv>
              </form>
            );
          }}
        </Formik>
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section2);
