import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { validationSchema } from './Validations';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { saveFundSection } from 'services/funds';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import { FormFielErrorMessage, RequiredMark } from 'components/CommonStyled';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { useConstantsFunds } from 'customHooks/useConstantsFunds';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';


const formInitialValues = {
  background: "",
  relationshipBackgroundId: 1
};

const Section5 = () => {
  const {t} = useTranslation();

  const { relationshipBackgrounds } = useConstantsFunds();

  console.log(relationshipBackgrounds)
  const { formStore } = useRootStore();
  const { id } = useParams();

  const [saved, setSaved] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    let newValues = formInitialValues;
    if (formStore.formSectionData) {
      newValues = {
        background: formStore?.formSectionData?.[0]?.background,
        relationshipBackgroundId: formStore?.formSectionData?.[0]?.relationshipBackground?.id ?? 1,
      }
    }
    setInitialValues(newValues);
  }, [formStore.formSectionData])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);
  }, [saved])

  const onSubmit = async (values, {resetForm}) => {
    try {
      const { complete } = await saveFundSection(id, 5, values);
      formStore.setSectionStatus(5, complete)
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid
      ? onSubmit(values, { resetForm })
      : alert(t('not_saved_alert'));
  }

  return (
    <FormLayout>
      <FormContainer
        number={5}
        title={t('F1_S5_title')}
        formId="form55section5"
        showSavedMessage={saved}
        showSaveButton
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {( {values,
              errors,
              touched,
              dirty,
              resetForm,
              handleChange,
              handleBlur,
              isValid }) => (
                <form
                  onSubmit={(e) => {onValidateSubmit(e, isValid, values, { resetForm })}}
                  id="form55section5"
                >
                  {dirty && (setSaved(false))}

                  <Form.Group style={{marginLeft: '50px', paddingTop: '10px'}}>
                    <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}}>
                      {t('Funds_S5_background')}
                      <RequiredMark>*</RequiredMark>:
                    </Form.Label>
                    <Form.Control
                      name="background"
                      as="textarea"
                      rows={4}
                      placeholder={t('Maximum_2500')}
                      style={{resize: 'none'}}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.background}
                      className={touched.background && errors.background ? "error" : null}
                    />
                    <FormErrorMessage
                      touched={touched.background}
                      errors={errors.background}
                    />
                  </Form.Group>

                  <Form.Group style={{marginLeft: '50px', paddingTop: '10px'}} as={Row}>
                    <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '2'}}>
                      {t("Funds_S5_clasification")}
                      <RequiredMark>*</RequiredMark>:
                    </Form.Label>
                    <Col lg={4}>
                      <Form.Control
                        as="select"
                        name="relationshipBackgroundId"
                        onChange={handleChange}
                        value={values.relationshipBackgroundId}
                      >
                        <option value="">{t("Placeholder_select")}</option>
                        {relationshipBackgrounds?.map((entity) => (
                          <option key={entity.id} value={entity.id}>
                            {t(`relationship_background_${entity.id}`)}
                          </option>
                        ))}
                      </Form.Control>
                      <FormErrorMessage
                        touched={touched.relationshipBackgroundId}
                        errors={errors.relationshipBackgroundId}
                      />
                    </Col>
                  </Form.Group>
                </form>
              )}
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section5);

