import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import {ErrorButton, PrimaryButton } from 'components/Buttons'
import Modal from 'react-bootstrap/Modal';

const ConfirmCloseModal = ({show, handleCloseConfirm, handleActionOnConfirm, text}) => {
  const [sending, setSending] = useState(false);
  const {t} = useTranslation();

  const handleConfirm = async () => {
    try{
      setSending(true);
      await handleActionOnConfirm();
    }
    catch(e){
      console.log(e);
    } finally{
      setSending(false);
      handleCloseConfirm();
    }
  }

  return (
    <Modal show={show} onHide={handleCloseConfirm} animation={false} backdrop="static">
      <Modal.Body>
        {text}
      </Modal.Body>
      <Modal.Footer style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ErrorButton variant="secondary" disabled={sending} onClick={handleCloseConfirm}>
          {t('No')}
        </ErrorButton>
        <PrimaryButton disabled={sending} onClick={handleConfirm}>
          {t('Yes')}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmCloseModal;
