import React from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import { Th3, Table } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'

import { renderHeaders } from '../../CommonHelpers/helpers'
import { T1RenderRows } from '../helpers/helpers'
import { TARGET_YEARS } from 'constants/appConstants'

import { StyledRow2 } from '../../CommonStyled/Tabs.styled'
import htmlParser from 'html-react-parser'

const Table1 = ({ data, waiting }) => {
  const { t } = useTranslation()

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data && !isEmpty(data) ? (
    <>
      <Table>
        <colgroup>
          <col span="5" />
          <col span="5" style={{ backgroundColor: '#a6b3bb' }} />
        </colgroup>
        <tr>
          <Th3 style={{ width: '50%' }}>
            {t(t('Statistics_execution_tab1'))}
          </Th3>
          {renderHeaders(TARGET_YEARS)}
        </tr>
        {T1RenderRows(data, TARGET_YEARS)}
      </Table>
      <StyledRow2>{htmlParser(t('Statistics_footnote_7'))}</StyledRow2>
      <StyledRow2>{htmlParser(t('Statistics_footnote_5'))}</StyledRow2>
      <StyledRow2><b><span style={{fontSize: '17px'}}>{t('Statistics_footnote_8')}</span></b></StyledRow2>
      <StyledRow2>{htmlParser(t('Statistics_footnote_8.1'))}</StyledRow2>
    </>
  ) : (
    t('No_data_available_2')
  )
}

export default Table1
