import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  partners: Yup.array()
    .of(
      Yup.object().shape({
        entityName: Yup.string().min(2, 'F1_S2_validation1').max(200, 'F1_S2_validation2'),
        acronym: Yup.string().min(2, 'F1_S2_validation3').max(100, 'F1_S2_validation4'),
        entityTypeId: Yup.string().min(1, 'F1_S2_validation5'),
        role: Yup.string().oneOf(["","0","1","2"]),
        countryId: Yup.string(),
      })
    )
    .required('F1_S3_validation1')
    .min(1, 'Funds_S3_validation2')
});