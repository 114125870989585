export const formInitialValues = {
  name: "",
  description: "",
  objective: "",
  latamKnowledge: "",
  euKnowledge: "",
  newKnowledge: "",
  application: "",
  leap: "",
  contribution: "",
  benEntities: "",
  directBen: "",
  goalPrimaryType: {},
  primaryJustification: "",
  goalSecondaryType: {},
  secondaryJustification: "",
  rights: "",
  environment: "",
  innovation: "",
  valueTC: "",
  valueEU: "",
  valueTP: "",
  covid19: ""
};
