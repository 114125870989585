import styled from 'styled-components';

export const MeetingsContainer = styled.div`
  margin-left: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ButtonTitle = styled.button`
  all: unset;
  cursor: pointer;
  text-decoration: underline;
  text-overflow: ellipsis;
`;