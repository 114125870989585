import * as Yup from 'yup';
import i18n from 'i18n';

const yesterday = new Date(Date.now() - 86400000);

export const validationSchema = Yup.object().shape({
  activityTypeId: Yup.string()
    .required('F3_S7_validation9'),
  description: Yup.string()
    .min(10, 'F3_S7_validation10')
    .max(1300, 'F3_S7_validation11')
    .required('F3_S7_validation12'),
  fromDateStr: Yup.date()
    .min(yesterday, 'F1_S7_validation3')
    .typeError('F1_S7_validation9'),
  toDateStr: Yup.date()
    .min(Yup.ref('fromDateStr'), 'F1_S7_validation4')
    .typeError('F1_S7_validation9'),

  duration: Yup.number().when('activityTypeId', {
    is: activityType => (activityType !== "4" && activityType !== "7"),
    then: Yup.number()
      .min(0, 'F1_S7_validation10')
      .required('F1_S7_validation11'),
    otherwise: Yup.number().nullable(true)
  }),
  participants: Yup.number().when('activityTypeId', {
    is: activityType => (activityType !== "4" && activityType !== "7"),
    then: Yup.number()
      .min(0, 'F1_S7_validation10')
      .required('F1_S7_validation11'),
    otherwise: Yup.number().nullable(true)
  }),
  experts: Yup.number().when('activityTypeId', {
    is: activityType => activityType === "4",
    then: Yup.number()
      .min(0, 'F1_S7_validation10')
      .required('F1_S7_validation11'),
    otherwise: Yup.number().nullable(true)
  }),

  daysRemote: Yup.number().when(['activityTypeId', 'daysOnsite'], {
    is: (activityType, days) => activityType === "4" && !(Number.parseInt(days) > 0),
    then: Yup.number()
      .min(0, 'F1_S7_validation10')
      .required('F1_S7_validation11'),
    otherwise: Yup.number().nullable(true)
  }),
  daysOnsite: Yup.number().when(['activityTypeId', 'daysRemote'], {
    is: (activityType, days) => activityType === "4" && !(Number.parseInt(days) > 0),
    then: Yup.number()
      .min(0, 'F1_S7_validation10')
      .required('F1_S7_validation11'),
    otherwise: Yup.number().nullable(true)
  }),
  countryId: Yup.string()
    .required('F3_S7_validation13'),
  cityName: Yup.string()
    .min(3, 'F1_S2_validation11')
    .max(100, 'F1_S2_validation12')
    .required('F1_S7_validation13'),
  stateName: Yup.string()
    .min(3, 'F1_S2_validation13')
    .max(100, 'F1_S2_validation14'),
  countriesAdditional: Yup.string()
    .max(300, 'F1_S7_validation14')
    .nullable(true),
  justBudget: Yup.string()
    .min(10, 'F1_S7_validation16')
    .max(650, 'F1_S7_validation17')
    .required('F1_S7_validation18'),
}, [['daysRemote', 'daysOnsite'], ['ueBudget', 'counterPartBudget']]);