import { useState, useEffect } from 'react';
import { getConstantsForm } from 'services/initiative';

const initialValues = {
  countries: [],
  la_ca_eu_countries: [],
  entityTypes: [],
  beneficiaryTypes: [],
  developmentGoals: [],
  developmentGoalTargets: [],
  developmentGoalTargetIndicators: [],
  activityTypes: [],
  mediaTypes: [],
  resourceTypes: [],
  productTypes: [],
  lessonTypes: [],
  lessonMoments: [],
  lessonUseForList: [],
}

export const useConstantsForm5 = () => {

  const [values, setValues] = useState(initialValues)

  useEffect(() => {
    async function fetchAPI() {
      try {
        const { 
          countries,
          la_ca_eu_countries,
          entityTypes,
          beneficiaryTypes,
          developmentGoals,
          developmentGoalTargets,
          developmentGoalTargetIndicators,
          activityTypes,
          mediaTypes,
          resourceTypes,
          productTypes,
          lessonTypes,
          lessonMoments,
          lessonUseForList,
        } = await getConstantsForm('5', {'applicantCountries': 'true'});
        
        setValues(prevState => ({
          ...prevState,
          countries,
          la_ca_eu_countries,
          entityTypes,
          beneficiaryTypes,
          developmentGoals,
          developmentGoalTargets,
          developmentGoalTargetIndicators,
          activityTypes,
          mediaTypes,
          resourceTypes,
          productTypes,
          lessonTypes,
          lessonMoments,
          lessonUseForList,
        }));
      }
      catch(e){
        console.log(e);
      }
    }
    fetchAPI();
  }, []);

  return values;
}

