import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledFormLabel = styled(Form.Label)`
  padding-left: 0;
  text-align:justify;
  line-height: 1.3;
`;

export const Text = styled.div`
  line-height: 1.25;
  text-align:justify;
`;

export const StyledSectionWrapper = styled.div`
  padding-left: 50px;
`;

export const Separator = styled.div`
  margin-bottom: 20px;
`;
