import { action, makeObservable, observable} from 'mobx';

export class InitiativeStore {
  rootStore;
  id = null;
  role = null;
  ownerName = null;
  statusName = null;
  code = null;
  language = null;
  name = null;
  formDeadline = null;
  formStateName = null;
  formVersion = null;
  assessmentDeadline = null;
  assessmentStatus = null;
  f2bDecided = null;
  f2cDecided = null;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      id: observable,
      role: observable,
      ownerName: observable,
      statusName: observable,
      code: observable,
      language: observable,
      name: observable,
      formDeadline: observable,
      formStateName: observable,
      formVersion: observable,
      assessmentDeadline: observable,
      assessmentStatus: observable,
      f2bDecided: observable,
      f2cDecided: observable,
      setInitiativeData: action
    });
  }

  setInitiativeData(initiative) {
    this.id = initiative.id;
    this.role = initiative.role;
    this.ownerName = initiative.ownerName;
    this.statusName = initiative.statusName;
    this.code = initiative.code;
    this.language = initiative.language;
    this.name = initiative.name;
    this.formDeadline = initiative.formDeadline;
    this.formStateName = initiative.formStateName;
    this.formVersion = initiative.formVersion;
    this.assessmentDeadline = initiative.assessmentDeadline;
    this.f2bDecided = initiative.f2bDecided,
    this.f2cDecided = initiative.f2cDecided,
    this.assessmentStatus = initiative.assessmentStatus;
    
  }
}
