import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { f5statusNamesConst, roleNamesConst } from 'constants/appConstants';
import { useRootStore } from 'stores';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { MessagesSectionStyles } from 'components/CommonStyled';
import NewComment from 'components/NewComment';
import { StyledSendButton } from 'components/FormContainer/FormContainer.styled';
import FormLayout from '../../FormLayout';
import { PrimaryButton, StyledButtonLink } from 'components/Buttons';
import { title, handleComments } from 'helpers/commentsHelpers';
import { StyledIntroParagraph } from 'components/Forms/Forms.styled';

const Section17 = () => {
  const { t } = useTranslation();
  const { id, formId, sectionId } = useParams();
  const { formStore, initiativeStore } = useRootStore();
  const [nonEditable, setNonEditable] = useState(false);
  const [comments, setComments] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [modalData, setModalData] = useState({ show: false, id: null });
  const {
    StyledComment,
    StyledSectionWrapper,
    StyledResponse,
    StyledButton,
    StyledTitle,
    StyledUserResponse,
    StyledUserComment,
    StyledDate,
  } = MessagesSectionStyles;

  const commentsNumber = 20;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName === f5statusNamesConst.FINALIZED ||
        initiativeStore.formStateName === f5statusNamesConst.CANCELLED ||
        initiativeStore.role === roleNamesConst.ATNKE,
    );
  }, [initiativeStore.formStateName]);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formData = [...formStore.formSectionData];
      if (formData.length > 0) {
        const data = handleComments(formData, comments, commentsNumber);
        setComments(data.comments);
        setAllComments(data.allComments);
      }
    }
  }, [formStore.formSectionData]);

  return (
    <FormLayout>
      {modalData.show && (
        <NewComment
          show={modalData.show}
          params={{id, formId, sectionId}}
          onHide={() => {
            setModalData({ show: false, id: null });
          }}
          commentsList={comments}
          onSetComments={setComments}
          id={modalData.id}
        />
      )}
      <StyledSectionWrapper>
        <StyledTitle>{t('Button_comments_and_status')}</StyledTitle>

        <StyledIntroParagraph>
          {t('F5_messages_intro')}
        </StyledIntroParagraph>

        <div style={{ marginBottom: '30px' }}>
          <PrimaryButton
            onClick={() => {
              setModalData({ show: true, id: null });
            }}
            disabled={nonEditable}
          >
            {t('New_comment')}
          </PrimaryButton>
        </div>

        {comments &&
          comments.map(({ id, role, user, comment, responses, creation_date }) => (
            <>
              {role && user && creation_date && (
                <>
                  <StyledComment key={id}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                      <StyledUserComment>{`${title(role, user)} - ${user.name} ${user.lastName}`}</StyledUserComment>
                      <StyledDate>{dayjs(creation_date).format('DD/MM/YYYY HH:mm')}</StyledDate>
                    </div>
                    {comment}
                    <StyledButton>
                      <StyledSendButton
                        size="sm"
                        onClick={() => {
                          setModalData({ show: true, id: id });
                        }}
                        disabled={nonEditable}
                      >
                        {t('Reply')}
                      </StyledSendButton>
                    </StyledButton>
                  </StyledComment>

                  {responses &&
                    responses.map(({ role, user, comment, creation_date }) => (
                      <StyledResponse>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                          <StyledUserResponse>
                            {`${title(role, user)} - ${user.name} ${user.lastName}`}
                          </StyledUserResponse>
                          <StyledDate>{dayjs(creation_date).format('DD/MM/YYYY HH:mm')}</StyledDate>
                        </div>
                        {comment}
                      </StyledResponse>
                    ))}
                </>
              )}
            </>
          ))}

        <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
          <StyledButtonLink
            variant="link"
            disabled={allComments.length == 0}
            onClick={() => {
              const data = handleComments(allComments, comments, commentsNumber);
              setComments(data.comments);
              setAllComments(data.allComments);
            }}
          >
            {t('Load_more_comments')}
          </StyledButtonLink>
        </div>
      </StyledSectionWrapper>
    </FormLayout>
  );
};

export default observer(Section17);
