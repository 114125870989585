import React from 'react';
import { useRootStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { Col, Row} from 'react-bootstrap';
import PageTitle from 'components/PageTitle';
import SidebarNavigation from 'components/SidebarNavigation';
import Form1Footer from 'components/Forms/Form1/Form1Footer';
import Form3Footer from 'components/Forms/Form3/Form3Footer';
import Form4Footer from 'components/Forms/Form4/Form4Footer';
import Form5Footer from 'components/Forms/Form5/Form5Footer';
import { useTranslation } from 'react-i18next';

const footer = {
  1: <Form1Footer />,
  3: <Form3Footer />,
  4: <Form4Footer />,
  5: <Form5Footer />
}

const getTitle = (formId,t) => {
  const titles = {
    1:  t('F1_main_title'),
    2: t('F2_main_title'),
    3: t('F3_main_title'),
    4: t('F4_main_title'),
    5: t('F5_main_title'),
    55: t('F55_main_title'),
  }
  return titles[formId];
}


const FormLayout = ({ children }) => {
  const { initiativeStore: { code }} = useRootStore();
  const { formId } = useParams();
  const { t } = useTranslation();
  const pageTitle = `${getTitle(formId,t)} #${code ? code : ''}`;

  return (
    <>
      <Row>
        <Col xs={12}><PageTitle title={pageTitle} /></Col>
        <Col xs={9}>
          <Row>
            {children}
          </Row>
          <Row>
            { footer[formId] }
          </Row>
        </Col>
        <Col xs={3}><SidebarNavigation /></Col>
      </Row>

    </>
  );
}

export default observer(FormLayout);