import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextValue from 'components/TextValue';
import { formatDateDDMMYY } from 'helpers/dateHelpers';

import { RequiredMark } from 'components/CommonStyled';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import {
  StyledFormLabel,
  StyledTitle as StyledTitleLocal,
} from 'components/Forms/Form5/Section8/Section8.styled';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody 
} from '../CommonStyles'

export const formInitialValues = [
  {
    interventionLogic: "",
    assessmentCovid19: "",
    startDate: "",
    endDate: "",
  }
];

const Section8 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(formInitialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data]);

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`8 - ${t('F3_S8_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <StyledTitleLocal>
          {t('F5_Subtitle_Description_from_proposal')}
        </StyledTitleLocal>

        <Form.Group style={{display: 'flex', justifyContent: 'space-between'}}>
          <StyledFormLabel style={{marginTop: '8px'}}>{t('F3_S8_Start_day')}:</StyledFormLabel>
          <TextValue value={formValues && formValues[1]? formatDateDDMMYY(formValues[1].startDate) : "-"} />
          
          <StyledFormLabel style={{marginTop: '8px'}}>{t('F3_S8_End_day')}:</StyledFormLabel>
          <TextValue value={formValues && formValues[1]? formatDateDDMMYY(formValues[1].endDate) : "-"} />
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>{t('F1_S7_Intervention_methodology')} </StyledFormLabel>
          <TextValue value={formValues[0].methodology} />
        </Form.Group>

        <Form.Group>
          <StyledFormLabel> {t('F1_S6_Question_22')} </StyledFormLabel>
          <TextValue value={formValues[0].covid19} />
        </Form.Group>

        <StyledTitleLocal style={{marginTop: '30px', marginBottom: '30px'}}>
          {t('F5_Subtitle_Assessment_TCI_Implementation')}
        </StyledTitleLocal>

        <Form.Group style={{display: 'flex', justifyContent: 'space-between', alignItems:"center"}}>
          <Col>
            <Row>
              <StyledFormLabel>{t('F3_S8_Start_day')}:</StyledFormLabel>
            </Row>
            <Row>
              <TextValue value={formValues[0].startDate? formatDateDDMMYY(formValues[0].startDate) : "-"} />
            </Row>
          </Col>

          <Col>
            <Row>
              <StyledFormLabel>{t('F3_S8_End_day')}:</StyledFormLabel>
            </Row>
            <Row>
              <TextValue value={formValues[0].endDate? formatDateDDMMYY(formValues[0].endDate) : "-"} />
            </Row>
          </Col>
        </Form.Group>
        
        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S8_The_intervention_logic')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="interventionLogic"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].interventionLogic}
            disabled={true}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S8_Assessment_adaptation')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="assessmentCovid19"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].assessmentCovid19}
            disabled={true}
          />
        </Form.Group> 
      </StyledBody>
    </SectionContainer>
  )
}

export default Section8;


