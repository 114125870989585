export const formInitialValues = [
  {
    entityName: '',
    acronym: '',
    entityType: {
      id: '1',
      name: "Public - national level",
    },
    function: '',
    country: {
      id: '4',
      name: 'Aphganistan',
    },
    web:'',
    firstName: '',
    lastName: '',
    position: '',
    phone: '',
    email: '',
  }
];