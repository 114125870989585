import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
	detailProviderId: Yup.string()
    .required("F3_S7_validation3"),
  justification: Yup.string()
    .min(2,  'F3_S7_validation4')
    .max(1300, 'Error max length 1300')
    .required('F3_S7_validation3'),
  unitPrice: Yup.number()
    .min(0, 'F1_S7_validation10')
    .required('F1_S7_validation11'),
  quantity: Yup.number()
    .min(0, 'F1_S7_validation10')
    .required('F1_S7_validation11'),
  totalAmount: Yup.number(),
	sheetEval: Yup.object()
    .nullable(),
  confirmAvailability: Yup.boolean()
});