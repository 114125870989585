import React, { useState, useEffect } from 'react';
import { getFormSection } from 'services/initiative';
import { initialValues } from './constants';
import html2pdf from 'html2pdf.js';

import Section1 from './Section1/Section1';
import Section2 from './Section2/Section2';
import Section3 from './Section3/Section3';
import Section4 from './Section4/Section4';
import Section5 from './Section5/Section5';
import Section6 from './Section6/Section6';
import Section7 from './Section7/Section7';
import Section8 from './Section8/Section8';
import Section9 from './Section9/Section9';
import Section10 from './Section10/Section10';
import Section11 from './Section11/Section11';
import Section12 from './Section12/Section12';
import Section13 from './Section13/Section13';
import Section14 from './Section14/Section14';
import Section15 from './Section15/Section15';

const F3PDFGen = ({ initiativeId, initiativeCode, handleError, onCreatePDF }) => {
  const [sectionsData, setSectionsData] = useState(initialValues);
  
  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    if(sectionsData.ready){
      generatePDF();
    }
  }, [sectionsData]);

  const generatePDF = () => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      const pages = Array.from(document.querySelectorAll('div[aria-label^="pdf-page-"]'))
      const pdfOptions = {
        margin: 1,
        filename: `proposal-${initiativeId}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { 
          scale: 2.0,
          width: 1200,
        },
        jsPDF: { unit: 'in', format: 'a1', orientation: 'portrait' },
      };
      let worker = html2pdf()
        .set(pdfOptions)
        .from(pages[0])
    
      if (pages.length > 1) {
        worker = worker.toPdf() // worker is now a jsPDF instance 
        // add each element/page individually to the PDF render process
        pages.slice(1).forEach((element, index) => {
          worker = worker
            .get('pdf')
            .then(pdf => {
              pdf.addPage()
            })
            .from(element)
            .toContainer()
            .toCanvas()
            .toPdf()
        })
      }
      worker = worker.save();
      onCreatePDF();
      setSectionsData(initialValues);
    }, 5000);
  };

  const fetchSections = async () => {
    let response;
    let sectionDataCopy = {...sectionsData};
    try {
      for (let i = 1; i < 15; i++) {
        response = await getFormSection(initiativeId, 3, i);
        sectionDataCopy[`section${i}Data`] = response.section;
      }
      response = await getFormSection(initiativeId, 3, 16);
      sectionDataCopy['section15Data'] = response.section;
      sectionDataCopy['ready'] = true;
      setSectionsData(sectionDataCopy);
    } catch (e) {
      handleError();
    }
  };

  return (
    <div id='pdfTargetDiv'>
      <div aria-label="pdf-page-1" style={{width: '1200px'}}>
        <Section1 data={sectionsData.section1Data} />
        <Section2 data={sectionsData.section2Data} />
        <Section3 data={sectionsData.section3Data} /> 
      </div>
      <div aria-label="pdf-page-2" style={{width: '1200px'}}>
        <Section4 data={sectionsData.section4Data} /> 
        <Section5 data={sectionsData.section5Data} /> 
        <Section6 data={sectionsData.section6Data} /> 
      </div>
      <div aria-label="pdf-page-3" style={{width: '1200px'}}>
        <Section7 data={sectionsData.section7Data} />
      </div>
      <div aria-label="pdf-page-4" style={{width: '1200px'}}>
        <Section8 data={sectionsData.section8Data} /> 
        <Section9 data={sectionsData.section9Data} /> 
      </div>
      <div aria-label="pdf-page-5" style={{width: '1200px'}}>
        <Section10 data={sectionsData.section10Data} /> 
        <Section11 data={sectionsData.section11Data} /> 
        <Section12 data={sectionsData.section12Data} code={initiativeCode} />
      </div>
      <div aria-label="pdf-page-6" style={{width: '1200px'}}>
        <Section13 data={sectionsData.section13Data} /> 
        <Section14 data={sectionsData.section14Data} /> 
        <Section15 data={sectionsData.section15Data} /> 
      </div>
    </div>
  )
};

export default F3PDFGen;
