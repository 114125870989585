import React, {useState} from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { useRootStore } from "stores";
import { sendF5 } from "services/initiative";

import CustomModal from 'components/Modal';
import Modal from 'react-bootstrap/Modal';
import { ModalFooter, FooterLeft, FooterCenter, FooterRight } from './SendDialog.styled';
import { ErrorButton, PrimaryButton } from 'components/Buttons';

const SendDialog = ({ title, displayDialog, onCloseModal}) => {
  const [sending, setSending] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const {t} = useTranslation();
  const { authStore } = useRootStore();
  const [modalErrorOpen, setModalErrorOpen] = useState(false);

  const sendAction = async () => {
    try {
      setSending(true);
      await sendF5(id);
      history.push(`${authStore.getBaseRouteByRole()}/management/implementation-forms`)
    } catch (error) {
      setModalErrorOpen(true);
      console.log(error);
    } finally{
      onCloseModal();
      setSending(false);
    }
  }

  return (
    <>
      <CustomModal
        show={modalErrorOpen}
        onHide={() => setModalErrorOpen(false)}
        message={t("Error try again later")}
        type="error"
      />

      <Modal show={displayDialog} onHide={onCloseModal} size="lg" animation={false}>
        <Modal.Header>
          <h5>{ title }</h5>
        </Modal.Header>
        <Modal.Body>
          {htmlParser(t('F5_confirmation_to_send'))}
        </Modal.Body>
        <ModalFooter>
          <FooterLeft />
          <FooterCenter>
            <ErrorButton variant="secondary" disabled={sending} onClick={onCloseModal}>
              {t('No')}
            </ErrorButton>
            <PrimaryButton variant="primary" disabled={sending} onClick={() => sendAction()}>
              {t('Yes')}
            </PrimaryButton>
          </FooterCenter>
          <FooterRight />
        </ModalFooter>
      </Modal>
    </>
  )
}

SendDialog.propTypes = {
  title: Proptypes.string,
  displayDialog: Proptypes.bool,
  onCloseModal: Proptypes.func,
};

export default observer(SendDialog);
