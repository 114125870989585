import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { saveFile, getDownload } from 'services/initiative';
import Spinner from 'react-bootstrap/Spinner';
import { StyledUploadButton } from '../ProductForm.styled';
import Form from 'react-bootstrap/Form';
import { StyledCustomLink } from 'components/Forms/Forms.styled';

const FileUploading = ({
  setModalInfo,
  params,
  validFileFormats,
  handleChange,
  currentProductReference,
  setFieldValue,
  fieldPath,
  productIndex,
  fileId,
  nonEditable,
}) => {
  const { t } = useTranslation();
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const handleDownload = async (uuid, downloadName) => {
    try {
      await getDownload(uuid).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      setModalInfo({
        message: t('Error_file1'),
        type: 'error',
        isOpen: true,
      });
    }
  };

  const handleFileUpload = async e => {
    try {
      const file = document.getElementById(`fileInput${productIndex}${fileId}`).files[0];
      const fileExtension = file.name.split('.').pop();
      if (file.size > 20971520) {
        setModalInfo({
          message: t('Error_file4'),
          type: 'error',
          isOpen: true,
        });
      } else if (!validFileFormats.includes(fileExtension.toUpperCase())) {
        setModalInfo({
          message: t('Error_file5'),
          type: 'error',
          isOpen: true,
        });
      } else {
        setIsUploadingFile(true);
        const uid = await saveFile(params.id, params.formId, params.sectionId, file);
        setFieldValue(fieldPath(`media.${fileId}.fileName`), file.name);
        setFieldValue(fieldPath(`media.${fileId}.uuid`), uid);
        handleChange(e);
      }
    } catch (error) {
      setModalInfo({
        message: t('Error_file2'),
        type: 'error',
        isOpen: true,
      });
    } finally {
      setIsUploadingFile(false);
    }
  };
  return isUploadingFile ? (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
      <Spinner animation="border" size="sm" />
      <span style={{ marginLeft: '10px', fontStyle: 'italic' }}> {t('file_uploader_uploading_doc')} </span>
    </div>
  ) : (
    <>
      {currentProductReference.media && currentProductReference.media[fileId] ? (
        <div style={{ marginTop: '30px' }}>
          <StyledUploadButton
            variant="link"
            onClick={() => {
              document.getElementById(`fileInput${productIndex}${fileId}`).click();
            }}
          >
            {t('F5_S7_Products_upload_document2')} ({validFileFormats.join(', ')})
          </StyledUploadButton>
          <div>
            <span>
              ({t('F5_S7_products_documents_upload')},
              <StyledCustomLink
                style={{ marginLeft: '5px', marginRight: '2px' }}
                onClick={() =>
                  handleDownload(
                    currentProductReference.media[fileId].uuid,
                    currentProductReference.media[fileId].fileName,
                  )
                }
              >
                {currentProductReference.media[fileId] ? currentProductReference.media[fileId].fileName : null}
              </StyledCustomLink>
              )
            </span>
          </div>
        </div>

      ) : (
        nonEditable ? null :
        <>
          <StyledUploadButton
            variant="link"
            onClick={() => {
              document.getElementById(`fileInput${productIndex}${fileId}`).click();
            }}
          >
            {t('F5_S7_Products_upload_document')} ({validFileFormats.join(', ')})
          </StyledUploadButton>
        </>
      )}
       <Form.File
          id={`fileInput${productIndex}${fileId}`}
          style={{ visibility: 'hidden', background: 'red' }}
          onChange={e => {
            handleFileUpload(e);
          }}
          type="file"
        />
    </>
  );
};

export default observer(FileUploading);
