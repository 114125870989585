import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FormFielErrorMessage, RequiredMark } from "components/CommonStyled";
import { getIn, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const TargetForm = ({
  targetListName,
  targetIndex,
  goalId,
  developmentGoalTargets,
  targetIndicatorsList,
  setSaved,
}) => {
  const { t } = useTranslation();
  const {
    getFieldProps,
    touched,
    errors,
    values,
    setFieldValue,
  } = useFormikContext();

  const fieldPath = (field) => `${targetListName}.${targetIndex}.${field}`;

  const fieldInfo = (formikInstance, field) => {
    return (
      <FormErrorMessage
        touched={getIn(formikInstance.touched, field)}
        errors={getIn(formikInstance.errors, field)}
      />
    )
  };

  const currentTargetId = values[targetListName][targetIndex].targetGoalId;

  const [devIndicatorsFiltered, setDevIndicatorsFiltered] = useState([]);

  const handleTargetGoalChange = (selectedId) => {
    const filteredArray = targetIndicatorsList.filter(
      (e) => e.developmentGoalTarget.id == selectedId
    );
    const newTargetIndicatorList = [];
    filteredArray.forEach((indicator) => {
      newTargetIndicatorList.push({
        targetGoalIndicatorId: indicator.id,
        id: indicator.id,
        name: indicator.name,
        selectedIndicator: false,
      });
    });
    setDevIndicatorsFiltered(newTargetIndicatorList);
    setFieldValue(fieldPath("targetIndicatorList"), newTargetIndicatorList);
  };

  const updateList = () => {
    if (values) {
      const devGoalTargetId =
        values[targetListName][targetIndex].targetGoalId ?? '';
      if (!values[targetListName][targetIndex].targetIndicatorList) {
        const filteredArray = targetIndicatorsList.filter(
          (e) => e.developmentGoalTarget.id == devGoalTargetId
        );
        const newTargetIndicatorList = [];
        filteredArray.forEach((indicator) => {
          newTargetIndicatorList.push({
            id: indicator.id,
            targetGoalIndicatorId: indicator.id,
            name: indicator.name,
            selectedIndicator: false,
          });
        });
        setDevIndicatorsFiltered(newTargetIndicatorList);
        setFieldValue(fieldPath("targetIndicatorList"), newTargetIndicatorList);
      } else {
        const currentTargetIndicatorList =
          values[targetListName][targetIndex].targetIndicatorList;
        setFieldValue(fieldPath("targetGoalId"), devGoalTargetId);
        currentTargetIndicatorList.forEach((indicator) => {
          if (indicator.targetGoalIndicator) {
            indicator.targetGoalIndicatorId = indicator.targetGoalIndicator.id;
            indicator.name = indicator.targetGoalIndicator.name;
          }
        });
        setDevIndicatorsFiltered(currentTargetIndicatorList);
        setFieldValue(
          fieldPath("targetIndicatorList"),
          currentTargetIndicatorList
        );
      }
    }
  };

  useEffect(() => {
    updateList();
  }, [targetIndicatorsList]);

  return (
    <>
      <Form.Group as={Row}>
        <Col>
          <Form.Control
            name={fieldPath("targetGoalId")}
            as="select"
            size="sm"
            {...getFieldProps(fieldPath("targetGoalId"))}
            onChange={(e) => {
              setFieldValue(fieldPath("targetGoalId"), e.target.value);
              handleTargetGoalChange(e.target.value);
            }}
            className={
              touched[fieldPath("targetGoalId")] &&
              errors[fieldPath("targetGoalId")]
                ? "error"
                : null
            }
          >
            <option value="">{t("Placeholder_select")}</option>
            {developmentGoalTargets.map((targetGoal) => (
              <option key={targetGoal.id} value={targetGoal.id}>
                {t(`SDG_Target${targetGoal.id}`)}
              </option>
            ))}
          </Form.Control>
          {fieldInfo(fieldPath("targetGoalId"))}
        </Col>
      </Form.Group>


      {(currentTargetId !== "" && goalId !== "" && devIndicatorsFiltered.length > 0) && (
        <Form.Group as={Row}>
          <Col xs={4} lg={3} className="align-self-start">
            <Form.Label style={{ marginTop: "10px" }}>
              {t("F3_S11_Indicators")}
              <RequiredMark>*</RequiredMark>:
            </Form.Label>
          </Col>
          <Col xs={10} lg={9} className="align-self-end">
            {devIndicatorsFiltered.map((indicator) => (
              <Form.Check
                style={{ marginBottom: "20px" }}
                key={indicator.id}
                type="checkbox"
                label={t(`SDG_Indicator${indicator.targetGoalIndicatorId}`)}
                checked={indicator.selectedIndicator}
                onChange={() => {
                  const newArr =
                    values[targetListName][targetIndex].targetIndicatorList;
                  const elementPos = newArr
                    .map((e) => e.id)
                    .indexOf(indicator.id);
                  newArr[elementPos].selectedIndicator = !newArr[elementPos]
                    .selectedIndicator;
                  setFieldValue(fieldPath("targetIndicatorList"), newArr);
                  setSaved(false);
                }}
              />
            ))}
            {fieldInfo(fieldPath("targetIndicatorList"))}
          </Col>
        </Form.Group>
      )}
    </>
  );
};

export default TargetForm;
