import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { formInitialValues } from './PaymentsForm.initialValues'
import { validationSchema } from './PaymentsForm.validations'
import {
  getParticipantsF5S7,
  addF5S7ActivityDetailPayment,
  modifyF5S7ActivityDetailPayment,
} from 'services/initiative'
import { useRootStore } from 'stores'
import { roleNamesConst } from 'constants/appConstants'
import DayJS from 'dayjs'

import { StyledFormLabel, StyledCheck } from './PaymentsForm.styled'
import MoneyInput from 'components/MoneyInput'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
  ErrorButton,
  PrimaryButton,
  StyledSendButton,
} from 'components/Buttons'
import { Form } from 'react-bootstrap'
import {
  FormFielErrorMessage,
  RequiredMark,
  CenteredModalButtonContainer,
} from 'components/CommonStyled'

const PaymentsForm = ({
  activity,
  detail,
  handleCloseOk,
  handleClose,
  handleCloseSubModalOnError,
  nonEditable,
  nonEditable2,
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { authStore } = useRootStore()
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [participants, setParticipants] = useState([])
  const role = authStore.roles[0]
  const isAtke = role === roleNamesConst.ATKE
  const [isEditing, setIsEditing] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const formatDate = (dateStr) => {
    const date = DayJS(dateStr)
    return date.format('DD/MM/YYYY')
  }

  useEffect(() => {
    if (detail.currentPayment) {
      setIsEditing(true)
      setInitialValues({
        ...detail.currentPayment,
        paymentId: detail.currentPayment.id,
        participantId: detail.currentPayment.participant
          ? detail.currentPayment.participant.id
          : '',
      })
      setDisabled(detail.currentPayment.paid)
    } else {
      setDisabled(false)
    }
  }, [detail])

  const handleSubmit = async (values) => {
    try {
      if (isEditing) {
        await modifyF5S7ActivityDetailPayment(
          id,
          activity.id,
          detail.id,
          values.paymentId,
          values,
        )
      } else {
        await addF5S7ActivityDetailPayment(id, activity.id, detail.id, values)
      }
      handleCloseOk()
    } catch (error) {
      handleCloseSubModalOnError()
    }
  }

  useEffect(() => {
    async function getValues() {
      const response = await getParticipantsF5S7(id, activity.id)
      const allParticipants = [...response.data.participantList]
      setParticipants(allParticipants)
    }
    getValues()
  }, [])

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} id="payments">
            <Form.Group style={{ marginLeft: '-15px' }}>
              <Form.Label column>
                {t('F3_S10_Description')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  className={
                    touched.description && errors.description ? 'error' : null
                  }
                  disabled={nonEditable || disabled}
                />
                {touched.description && errors.description ? (
                  <FormFielErrorMessage>
                    {errors.description}
                  </FormFielErrorMessage>
                ) : null}
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <StyledFormLabel column lg={4}>
                {t('F5_S7_Budget_Select_Payments_Submodal_select')}:
              </StyledFormLabel>
              <Col lg={4}>
                <Form.Control
                  name="participantId"
                  as="select"
                  onChange={handleChange}
                  value={values.participantId}
                  disabled={nonEditable || disabled}
                >
                  <option value="">{t('Placeholder_select')}</option>
                  {participants.map((participant) => (
                    <option key={participant.id} value={participant.id}>
                      {participant.firstName} {participant.lastName}
                    </option>
                  ))}
                </Form.Control>
                {touched.participantId && errors.participantId ? (
                  <FormFielErrorMessage>
                    {errors.participantId}
                  </FormFielErrorMessage>
                ) : null}
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column md={3} xs={3}>
                {t('F5_S7_Budget_Select_Provider_Total')}
                <RequiredMark>*</RequiredMark>:
              </Form.Label>
              <Col xs={3}>
                <MoneyInput
                  name="amountPayment"
                  style={{ width: '170px' }}
                  value={values.amountPayment}
                  onChange={(val) => setFieldValue('amountPayment', val)}
                  onBlur={handleBlur}
                  customInput={Form.Control}
                  className={errors.amountPayment ? 'error' : null}
                  disabled={nonEditable || disabled}
                />
                {errors.amountPayment ? (
                  <FormFielErrorMessage>
                    {errors.amountPayment}
                  </FormFielErrorMessage>
                ) : null}
              </Col>
            </Form.Group>

            <Form.Group style={{ marginLeft: '-15px' }}>
              <Form.Label column>
                {t('F5_S7_Budget_Select_Payments_Steps_Comments')}:
              </Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  name="comments"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.comments}
                  className={
                    touched.comments && errors.comments ? 'error' : null
                  }
                  disabled={nonEditable || disabled}
                />
                {touched.comments && errors.comments ? (
                  <FormFielErrorMessage>{errors.comments}</FormFielErrorMessage>
                ) : null}
              </Col>
            </Form.Group>

            <StyledCheck>
              <Form.Group>
                <Form.Check
                  disabled={nonEditable || disabled}
                  style={{ marginLeft: '15px', textAlign: 'justify' }}
                  name="paymentEnabled"
                  type="checkbox"
                  checked={values.paymentEnabled}
                  value={values.paymentEnabled}
                  onChange={e => {
                    if(e.target.value) {
                      setFieldValue('invoiced', false);
                      setFieldValue('paid', false);
                    }
                    handleChange(e);
                  }}
                  label={t('F5_S7_Budget_Select_Payments_Steps_Check1')}
                  className={
                    touched.paymentEnabled && errors.paymentEnabled
                      ? 'error'
                      : null
                  }
                />
                {touched.paymentEnabled && errors.paymentEnabled ? (
                  <FormFielErrorMessage>
                    {errors.paymentEnabled}
                  </FormFielErrorMessage>
                ) : null}
              </Form.Group>
            </StyledCheck>
            {values.paymentEnabled && (
              <div>
                {t('F5_S7_Confirmed')}: {values.paymentEnabledUsername} -
                {values.paymentEnabledTimestamp
                  ? formatDate(values.paymentEnabledTimestamp)
                  : ''}
              </div>
            )}

            <StyledCheck style={{ marginTop: '30px' }}>
              <Form.Group>
                <Form.Check
                  disabled={nonEditable || values.paid || !values.paymentEnabled}
                  style={{ marginLeft: '15px', textAlign: 'justify' }}
                  name="paid"
                  type="checkbox"
                  checked={values.paid}
                  value={values.paid}
                  onChange={handleChange}
                  label={t('F5_S7_Budget_Select_Payments_Steps_Check2')}
                  className={touched.paid && errors.paid ? 'error' : null}
                />
                {touched.paid && errors.paid ? (
                  <FormFielErrorMessage>{errors.paid}</FormFielErrorMessage>
                ) : null}
              </Form.Group>
            </StyledCheck>
            {values.paid && (
              <div>
                {t('F5_S7_Confirmed')}: {values.paidUsername} -
                {values.paidTimestamp ? formatDate(values.paidTimestamp) : ''}
              </div>
            )}

            <StyledCheck style={{ marginTop: '30px' }}>
              <Form.Group>
                <Form.Check
                  disabled={nonEditable2 || !values.paymentEnabled}
                  style={{ marginLeft: '15px', textAlign: 'justify' }}
                  name="invoiced"
                  type="checkbox"
                  checked={values.invoiced}
                  value={values.invoiced}
                  onChange={handleChange}
                  label={t('F5_S7_Budget_Select_Payments_Steps_Check3')}
                  className={
                    touched.invoiced && errors.invoiced ? 'error' : null
                  }
                />
                {touched.invoiced && errors.invoiced ? (
                  <FormFielErrorMessage>{errors.invoiced}</FormFielErrorMessage>
                ) : null}
              </Form.Group>
            </StyledCheck>
            {values.invoiced && (
              <div>
                {t('F5_S7_Confirmed')}: {values.invoicedUsername} -
                {values.invoicedTimestamp
                  ? formatDate(values.invoicedTimestamp)
                  : ''}
              </div>
            )}

            <CenteredModalButtonContainer>
              {nonEditable && !isAtke ? (
                <PrimaryButton
                  onClick={() => {
                    handleCloseOk()
                  }}
                >
                  OK
                </PrimaryButton>
              ) : (
                <>
                  <ErrorButton
                    style={{ marginRight: '20px' }}
                    onClick={handleClose}
                    disabled={nonEditable && !isAtke}
                  >
                    {t('Button_cancel')}
                  </ErrorButton>
                  <StyledSendButton
                    type="submit"
                    form="payments"
                    disabled={nonEditable && !isAtke}
                  >
                    {t('Button_save')}
                  </StyledSendButton>
                </>
              )}
            </CenteredModalButtonContainer>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default observer(PaymentsForm)
