import { AuthStore } from '../AuthStore';
import { InitiativeStore } from '../InitiativeStore';
import { FormStore } from '../FormStore';
import { ErrorStore } from '../ErrorStore';

export class RootStore {
  constructor() {
    this.authStore = new AuthStore();
    this.initiativeStore = new InitiativeStore(this);
    this.formStore = new FormStore(this);
    this.errorStore = new ErrorStore(this);
  }
}