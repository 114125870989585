export const formInitialValues = {
  activityAsst1: "",
  activityAsst2: "",
  activityAsst3: "",
  activityAsst4: "",
  activityAsst5: "",
  activityAsst6: "",
  activityAsst7: "",
  activityAsst8: "",
  activityAsst9: "",
  activityAsst10: "",
  activityAsst11: "",
  activityAsst12: "",
  assessmentConfirmed: false
};