import React from 'react'
import { useTranslation } from 'react-i18next'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Items = ({items, itemType}) => {
    const { t } = useTranslation()
    
    return (items.map((item, key) => (
      <Row style={{ marginTop: '10px', alignItems: 'center' }} key={item.id}>
        <Col xs={4}>
          {item.url ? (
            <a
              href={item.url.includes('http') ? item.url : `//${item.url}`}
              target="_blank"
            >
              {`${t(`media_type_${itemType}`)} ${t(
                'F5_S14_Communication_Tools_account',
              )} ${key + 1}`}
            </a>
          ) : (
            <span>
              {`${t(`media_type_${itemType}`)} ${t(
                'F5_S14_Communication_Tools_account',
              )} ${key + 1}`}
            </span>
          )}
        </Col>
        <Col xs={8} style={{ textAlign: 'justify', lineHeight: '1.2 rem' }}>
          {item.comments}
        </Col>
      </Row>
    )))
  }

export default Items