import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledFormLabel = styled(Form.Label)`
  padding-left: 0;
  line-height: 1.3;
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: 640;
  line-height: 1.3;
`;

export const Separator = styled.div`
  margin-bottom: 20px;
`;

export const StyledSectionWrapper = styled.div`
  padding-left: 50px;
`;
