

import React, { useState, useEffect } from 'react'
import htmlParser from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import Header2 from '../components/Header2'
import Table2 from '../components/Table2'

import {
  StyledRow,
  StyledRow3,
  StyledSubtitle3,
} from '../../CommonStyled/Tabs.styled'

import { getF6ResultsMarkers } from '../../../../services/initiative'
import { getResultExportValues2 } from '../helpers/helpers'

const Tab2 = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([1])
  const [waiting, setWaiting] = useState(false)

  const getData = async (params) => {
    setWaiting(true)
    try {
      const results = await getF6ResultsMarkers(params)
      setData(results)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <div style={{ marginBottom: '32px'}} />
      <Header2 data={data} waiting={waiting} getData={getData} getExportValues={getResultExportValues2} />
      {data?.length ? (
        <StyledRow3>
          <b>{`${t('total_results')}: ${data?.length}`}</b>
        </StyledRow3>
      ) : null}
      <StyledSubtitle3>
        <a href="#footnote"> {t('Quality_markers')}</a>
      </StyledSubtitle3>
      <StyledRow>
        <Table2 data={data} waiting={waiting} setData={setData} />
      </StyledRow>
      <div id="footnote">
        {htmlParser(t('Statistics_footnote_9'))}
      </div>
    </>
  )
}

export default Tab2
