import { f1statusNamesConst } from 'constants/appConstants';
import i18n from 'i18n';

export const getCNStateHelper = (formStateName, formVersion, f2bDecided, f2cDecided) => {
  let stateName = '', version = '';
  if (formStateName === f1statusNamesConst.SENT || formStateName === f1statusNamesConst.DRAFT) {
    version = 'v' + formVersion;
  }
  if (formStateName === f1statusNamesConst.SENT) {
    if (!f2bDecided && !f2cDecided) {
      stateName = 'REVIEW_TA';
    } else if (f2bDecided && !f2cDecided) {
      stateName = 'REVIEW_INTPA';
    } else {
      stateName = 'SENT';
    }
  } else {
    stateName = formStateName;
  }
  return `${i18n.t(stateName)} ${version}`;
};

export const isSentStatus = (formStateName, f2bDecided, f2cDecided) => {
  if (formStateName === f1statusNamesConst.SENT) {
    if (!f2bDecided && !f2cDecided) {
      return false;
    } else if (f2bDecided && !f2cDecided) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};
