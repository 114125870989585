import React, { useState, useEffect } from 'react';
import { FieldArray, Formik } from 'formik';
import { validationSchema } from './Validations';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { saveFundSection } from 'services/funds';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import Form from 'react-bootstrap/Form'
import { RequiredMark, SeparatorDotted } from 'components/CommonStyled';
import { useTranslation } from 'react-i18next';
import ConfirmCloseModal from 'components/ConfirmCloseModal';
import { useConstantsFunds } from 'customHooks/useConstantsFunds';
import { Accordion } from 'react-bootstrap';
import { AccordionItem, AccordionItemBody, AccordionItemHeader, AccordionTitle } from 'components/CustomAccordion';
import { ErrorButton, PrimaryButton } from 'components/Buttons';
import BeneficiaryForm  from './BeneficiaryForm';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const beneficiaryObject = {
  beneficiaryTypeId: '',
  answersFundS10BenCountryDTOList: [],
}

const formInitialValues = {
  justificationBeneficiaries: "",
  benList: [],
};

const Section10 = () => {
  const {t} = useTranslation();

  const { formStore } = useRootStore();
  const { id } = useParams();

  const [saved, setSaved] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);

  const { beneficiaryTypes, la_ca_eu_countries } = useConstantsFunds()
  const [modalRemove, setModalRemove] = useState(false)
  const [selectedKeyEntity, setSelectedKeyEntity] = useState(null)

  const openModalRemove = (key) => {
    setModalRemove(true)
    setSelectedKeyEntity(key)
  }

  const closeModalRemove = () => {
    setModalRemove(false)
    setSelectedKeyEntity(null)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    let newValues = formInitialValues;
    if (formStore.formSectionData) {
      newValues = {
        justificationBeneficiaries: formStore?.formSectionData?.[0]?.justificationBeneficiaries,
        benList: formStore?.formSectionData?.[0]?.benList,
      }
      if (newValues.benList) {
        newValues = {
          ...newValues,
          benList: newValues?.benList?.map(item => {
            return {
              beneficiaryTypeId: item?.beneficiaryType?.id,
              answersFundS10BenCountryDTOList: item?.answersFundS10BenCountryList?.map(listItem => {
                return {
                  quantity: listItem?.quantity,
                  countryId: listItem?.country?.id
                }
              })
            }
          })
        }
      }
    }
    setInitialValues(newValues);
  }, [formStore.formSectionData])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);
  }, [saved])

  const onSubmit = async (values, {resetForm}) => {
    try {
      const { complete } = await saveFundSection(id, 10, values);
      formStore.setSectionStatus(10, complete)
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid
      ? onSubmit(values, { resetForm })
      : alert(t('not_saved_alert'));
  }

  return (
    <FormLayout>
      <FormContainer
        number={10}
        title={t('F3_S10_title')}
        formId="form55section10"
        showSavedMessage={saved}
        showSaveButton
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {( {values,
              errors,
              touched,
              dirty,
              resetForm,
              handleChange,
              handleBlur,
              isValid }) => {
                return (
                  <form
                    onSubmit={(e) => {onValidateSubmit(e, isValid, values, { resetForm })}}
                    id="form55section10"
                  >
                    {dirty && (setSaved(false))}

                    <Form.Group style={{marginLeft: '50px', paddingTop: '10px'}}>
                      <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}}>
                        {t('fund_direct_ben_desc')}
                        <RequiredMark>*</RequiredMark>:
                      </Form.Label>
                      <Form.Control
                        name="justificationBeneficiaries"
                        as="textarea"
                        rows={4}
                        placeholder={t('Maximum_2000')}
                        style={{resize: 'none'}}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.justificationBeneficiaries}
                        className={touched.justificationBeneficiaries && errors.justificationBeneficiaries ? "error" : null}
                      />
                      <FormErrorMessage
                        touched={touched?.justificationBeneficiaries}
                        errors={errors?.justificationBeneficiaries}
                      />
                    </Form.Group>

                    <div style={{marginLeft: '50px', paddingTop: '10px'}}>
                      <FieldArray
                        name="benList"
                        render={(arrayHelpers) => (
                          <>
                            <ConfirmCloseModal
                              show={modalRemove}
                              text={t('remove_beneficiary', { name: selectedKeyEntity + 1 })}
                              handleActionOnConfirm={() => {
                                arrayHelpers.remove(selectedKeyEntity)
                                closeModalRemove()
                              }}
                              handleCloseConfirm={closeModalRemove}
                            />
                            <Accordion>
                              {initialValues.benList
                                ? values.benList?.map((beneficiary, key) => (
                                    <AccordionItem key={key + 1}>
                                      <AccordionItemHeader>
                                        <AccordionTitle eventKey={key + 1}>
                                          {`${t('F3_S10_Beneficiary')} ${key + 1}`}
                                          {beneficiary.beneficiaryTypeId
                                            ? ` - ${t(
                                                `beneficiary_type_${beneficiary.beneficiaryTypeId}`,
                                              )}`
                                            : ''}
                                        </AccordionTitle>
                                      </AccordionItemHeader>
                                      <Accordion.Collapse eventKey={key + 1}>
                                        <AccordionItemBody>
                                          <BeneficiaryForm
                                            beneficiaryIndex={key}
                                            countries={la_ca_eu_countries}
                                            benTypes={beneficiaryTypes}
                                          />
                                          <ErrorButton
                                            onClick={() => openModalRemove(key)}
                                          >
                                            {t('F3_S10_Beneficiary_button_delete')}
                                          </ErrorButton>
                                        </AccordionItemBody>
                                      </Accordion.Collapse>
                                    </AccordionItem>
                                  ))
                                : null}
                            </Accordion>

                            {typeof errors.benList === 'string' ? (
                              <FormErrorMessage
                                touched
                                errors={errors?.benList}
                              />
                            ) : null}
                            <SeparatorDotted />
                            <PrimaryButton
                              onClick={() => arrayHelpers.push(beneficiaryObject)}
                            >
                              {t('F3_S10_Beneficiary_button_add')}
                            </PrimaryButton>
                          </>
                        )}
                      />
                    </div>
                  </form>
                )
              }
            }
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section10);

