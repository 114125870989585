// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Formik, FieldArray } from 'formik';
import Accordion from 'react-bootstrap/Accordion';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { validationSchema } from './utils/validations';
import { formatDateDDMMYY } from 'helpers/dateHelpers';
import 'react-datepicker/dist/react-datepicker.css';
import FormLayout from '../../FormLayout';
import {
  AccordionItem,
  AccordionItemHeader,
  AccordionTitle,
  AccordionItemBody,
} from 'components/CustomAccordion';
import FormContainer from 'components/FormContainer';
import { PrimaryButton, ErrorButton } from 'components/Buttons';
import {
  FormFielErrorMessage,
  RequiredMark,
  SeparatorDotted,
} from 'components/CommonStyled';
import ModalConfirm from 'components/ModalConfirm';
import ActivityForm from './ActivityForm';

import { useTranslation } from 'react-i18next';
import { formInitialValues } from './initialValues';
import { dataTransformer } from './utils/transformer';
import { Form } from 'react-bootstrap';
import { saveFundSection } from 'services/funds';
import lodashClonedeep from 'lodash/cloneDeep';

const activityObject = {}

const Section7 = () => {
  const { t } = useTranslation()
  const { formStore } = useRootStore()
  const { id } = useParams()
  const [modalRemove, setModalRemove] = useState(false)
  const [selectedKey, setSelectedKey] = useState(null)
  const [saved, setSaved] = useState(false)
  const [initialValues, setInitialValues] = useState(formInitialValues)
  const [localActivities, setLocalActivities] = useState([
    {
      name: '',
      number: null,
      index: 1,
    },
  ])

  useEffect(() => {
    if (saved) window.scrollTo(0, 0)
  }, [saved])

  useEffect(() => {
    if (
      formStore?.formSectionData &&
      formStore.formSectionData[0]?.activities
    ) {
      const transformedData = dataTransformer(formStore.formSectionData[0])
      setLocalActivities(transformedData.localActivities)
      setInitialValues(transformedData.data)
    }
  }, [formStore.formSectionData])

  const openModalRemove = (key) => {
    setModalRemove(true)
    setSelectedKey(key)
  }

  const closeModalRemove = () => {
    setModalRemove(false)
    setSelectedKey(null)
  }

  const handleAddActivity = (activities) => {
    activities.push(activityObject)
    setLocalActivities([
      ...localActivities,
      {
        name: '',
        number: null,
        index: localActivities.length + 1,
      },
    ])
  }

  const handleReorderLocalActivities = (activities) => {
    activities.forEach((element, index) => {
      element.number = index + 1
    })
    setLocalActivities(activities)
  }

  const onSubmit = async (values, { resetForm }) => {
    try {
      const valuesCopy = lodashClonedeep(values);

      resetForm({ values });

      if (valuesCopy?.activities) {
        valuesCopy.activities.forEach((element) => {
          element.fromDate = element.fromDate ? new Date(element.fromDate).toISOString() : '',
          element.toDate = element.toDate ? new Date(element.toDate).toISOString(): '',
          element.format = parseInt(element.format)
        })
      }

      const { complete } = await saveFundSection(id, 7, valuesCopy)

      formStore.setSectionStatus(7, complete)

      setSaved(true)
    } catch (error) {
      console.log(error)
    }
  }

  const onValidateSubmit = (e, isValid, values, resetForm) => {
    e.preventDefault()
    isValid ? onSubmit(values, { resetForm }) : alert(t('not_saved_alert'))
  }

  return (
    <FormLayout>
      <FormContainer
        number={7}
        title={t('F55_S7_title')}
        isHelp={false}
        formId="form55section7"
        showSavedMessage={saved}
        showSaveButton
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <form
              onSubmit={(e) => {
                onValidateSubmit(
                  e,
                  props.isValid,
                  props.values,
                  props.resetForm,
                )
              }}
              id="form55section7"
            >
              {props.dirty && setSaved(false)}
              <Form.Group style={{ marginLeft: '50px', paddingTop: '10px' }}>
                <Form.Label
                  style={{
                    alignItems: 'center',
                    textAlign: 'justify',
                    lineHeight: '1.3',
                  }}
                >
                  {t('F55_S7_title')}
                  <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  name="methodology"
                  as="textarea"
                  rows={6}
                  placeholder={t('Maximum_1300')}
                  style={{ resize: 'none' }}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.methodology}
                  className={
                    props.touched.methodology && props.errors.methodology
                      ? 'error'
                      : null
                  }
                />
                {props.touched.methodology && props.errors.methodology ? (
                  <FormFielErrorMessage>
                    {props.errors.methodology.toString()}
                  </FormFielErrorMessage>
                ) : null}
              </Form.Group>

              <FieldArray
                name="activities"
                render={(arrayHelpers) => (
                  <>
                    {modalRemove && (
                      <ModalConfirm
                        show={modalRemove}
                        confirmText={t('Button_remove')}
                        onConfirm={() => {
                          arrayHelpers.remove(selectedKey)
                          const arrayCopy = [...localActivities]
                          arrayCopy.splice(selectedKey, 1)
                          handleReorderLocalActivities(arrayCopy)
                          closeModalRemove()
                        }}
                        onClose={closeModalRemove}
                      >
                        <p>
                          {t('Confirmation remove activity') +
                            ' ' +
                            (selectedKey + 1)}
                          ?
                        </p>
                      </ModalConfirm>
                    )}
                    <Accordion style={{marginLeft: "30px"}}>
                      {props?.values?.activities
                        ? props.values.activities.map((activity, key) => (
                            <AccordionItem key={key + 1}>
                              <AccordionItemHeader>
                                <AccordionTitle
                                  eventKey={key + 1}
                                  callback={() => {}}
                                >
                                  {t('F1_S7_Accordion') + '  '}
                                  {activity.name || '' + '  '}
                                  {activity.activityTypeId
                                    ? ` - ${t(
                                        `activity_types_${activity.activityTypeId}`,
                                      )}`
                                    : ' '}
                                  {activity.fromDate
                                    ? ` (${formatDateDDMMYY(
                                        activity.fromDate,
                                      )})`
                                    : ' '}
                                  {' - '}
                                  {activity.title}
                                </AccordionTitle>
                              </AccordionItemHeader>
                              <Accordion.Collapse eventKey={key + 1}>
                                <AccordionItemBody>
                                  <ActivityForm
                                    formikInstance={props}
                                    activityIndex={key}
                                  />
                                  <ErrorButton
                                    onClick={() => openModalRemove(key)}
                                  >
                                    {t('F1_S7_Button_remove_activity')}
                                  </ErrorButton>
                                </AccordionItemBody>
                              </Accordion.Collapse>
                            </AccordionItem>
                          ))
                        : null}
                    </Accordion>
                    <SeparatorDotted />
                    <PrimaryButton
                      onClick={() => handleAddActivity(arrayHelpers)}
                    >
                      {t('F1_S7_Button_add_activity')}
                    </PrimaryButton>
                  </>
                )}
              />
            </form>
          )}
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section7)
