import React from 'react';
import { Content, StyledContainer } from './Layout.styled';
import Header from './Header';
import Navbar from './Navbar';
import Footer from 'components/Footer';

const Layout = ({ children }) => {

  return (
  <div style={{position: 'relative', minHeight: '100vh'}}>
    <StyledContainer fluid>
      <Header />
      <Navbar />
      <Content style={{marginBottom: '100px'}}>
        { children }
      </Content>
      <Footer public={false}/>
    </StyledContainer>
  </div>
  )
}

export default Layout;
