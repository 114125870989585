import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Col';
import TextValue from 'components/TextValue';
import Accordion from 'react-bootstrap/Accordion';
import FormLayout from '../../FormLayout';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import FormContainer from 'components/FormContainer';
import {
  StyledIntroParagraph,
  StyledSeparator,
  StyledParagraph,
  StyledFormLabel,
  TextValueLeftSpaced,
} from './Section8.styled';
import MediaForm from 'components/Forms/Form2/Section8/MediaForm';
import RequestClarification from '../RequestClarification';

import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { formInitialValues } from './Section8.initialValues';
import { useConstantsForm1 } from 'customHooks/useConstantsForm1';

import { useTranslation } from 'react-i18next';

const Section8 = () => {
  const { t } = useTranslation();
  const { formStore } = useRootStore();
  const { mediaTypes } = useConstantsForm1();
  const [saved, setSaved] = useState(false);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [nonEditable, setNonEditable] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = { ...formInitialValues, ...formStore.formSectionData[0] };
      setFormValues(formSectionData);
    }
    formStore.setCurrentSection(8);
  }, [formStore.formSectionData]);

  const handleSaved = value => {
    setSaved(value);
  };

  const handleButton = value => {
    setNonEditable(value);
  };

  return (
    <FormLayout>
      <FormContainer
        number={8}
        title={t('F1_S8_title')}
        showSavedMessage={false}
        showSavedMessageF2={saved}
        showFooter={false}
        showButtonClarif={!nonEditable}
        formId="form2section8"
        buttonText="SAVE CLARIFICATION REQUEST"
      >
        <StyledIntroParagraph>{t('F1_S8_intro')}</StyledIntroParagraph>

        <Form.Group>
          <StyledFormLabel as={Row} style={{ marginBottom: '20px' }}>
            {t('F1_S8_Objetive')}:
          </StyledFormLabel>
          <TextValueLeftSpaced>
            <TextValue value={formValues.objective} />
          </TextValueLeftSpaced>
        </Form.Group>

        <StyledParagraph style={{ textAlign: 'justify', lineHeight: '1.3' }}>
          {t('F1_S8_Subtitle_communication_tools')}
        </StyledParagraph>

        <Accordion>
          {formValues.campaigns &&
            formValues.campaigns.map((comtool, key) => (
              <AccordionItem key={key + 1}>
                <AccordionItemHeader>
                  <AccordionTitle eventKey={key + 1}>
                    {`${t('F1_S8_Accordion')} ${key + 1}`}
                    {comtool.mediaType.id ? ` - ${t(`media_type_${comtool.mediaType.id}`)}` : ''}
                  </AccordionTitle>
                </AccordionItemHeader>
                <Accordion.Collapse eventKey={key + 1}>
                  <AccordionItemBody>
                    <MediaForm mediaTypes={mediaTypes} data={comtool} />
                  </AccordionItemBody>
                </Accordion.Collapse>
              </AccordionItem>
            ))}
        </Accordion>

        <StyledSeparator />
        <RequestClarification onSaved={handleSaved} onButton={handleButton} />
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section8);
