import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import { Table, Th1Pointer } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'

import { T5RenderRows, orderDataByField } from '../helpers/helpers'

const Table5 = ({ data, waiting, setData }) => {
  const { t } = useTranslation()
  const [ascendingOrder, setAscendingOrder] = useState({
    name: true,
    code: true,
    acronym: true,
    countryName: true,
    entityRole: true,
    func: true,
    typeId: true,
  })

  const handleOrderData = (field) => {
    orderDataByField(data, field, setData, setAscendingOrder, ascendingOrder)
  }

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data && !isEmpty(data) ? (
    <Table>
      <tr>
        <Th1Pointer width="12%" onClick={() => handleOrderData('code')}>
          {t('Initiative_code')} &#8645;
        </Th1Pointer>
        <Th1Pointer onClick={() => handleOrderData('name')}>
          {t('Entity_name')} &#8645;
        </Th1Pointer>
        <Th1Pointer width="12%" onClick={() => handleOrderData('acronym')}>
          {t('F1_S2_Official_acronym')} &#8645;
        </Th1Pointer>
        <Th1Pointer width="12%" onClick={() => handleOrderData('entityRole')}>
          {t('F1_S2_Role')} &#8645;
        </Th1Pointer>
        <Th1Pointer width="12%" onClick={() => handleOrderData('countryName')}>
          {t('F1_S2_Country')} &#8645;
        </Th1Pointer>
        <Th1Pointer width="12%" onClick={() => handleOrderData('typeId')}>
          {t('F1_S2_Type_of_entity')} &#8645;
        </Th1Pointer>
        <Th1Pointer width="12%" onClick={() => handleOrderData('func')}>
          {t('Function')} &#8645;
        </Th1Pointer>
      </tr>
      {T5RenderRows(data)}
    </Table>
  ) : (
    t('No_data_available_2')
  )
}

export default Table5
