export const formInitialValues = {
  form8: {
      expect1: '',
      expect2: '',
      expect3: '',
      expect4: '',
      expect5: '',
      expect6: '',
      done: '',
      notDone: '',
      conclusion1: '',
      conclusion2: '',
      conclusion3: '',
      conclusion4: '',
      conclusion5: '',
      conclusion6: '',
      conclusion7: '',
  },
  form9c: {},
}
