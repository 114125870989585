import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { formInitialValues } from './Section14.initialValues';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';

import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MediaForm from './MediaForm';
import { RequiredMark, SeparatorDotted } from 'components/CommonStyled';
import TextValue from 'components/TextValue';
import Form from 'react-bootstrap/Form';
import FormLayout from '../../FormLayout';
import FormContainer from 'components/FormContainer';
import Comments from 'components/Forms/Form4/Comments';
import { StyledFormLabel, StyledSectionWrapper, Separator, Title } from './Section14.styled';

const Section14 = () => {
  const { t } = useTranslation();
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const { formStore } = useRootStore();
  const [formValues, setFormValues] = useState(formInitialValues);

  const handleSaved = value => {
    setSaved(value);
  };

  const handleButton = value => {
    setNonEditable(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = { ...formInitialValues, ...formStore.formSectionData[0] };
      setFormValues(formSectionData);
      formStore.setCurrentSection(14);
    }
  }, [formStore.formSectionData]);

  return (
    <FormLayout>
      <FormContainer
        number={14}
        title={t('F3_S14_title')}
        formId="form4section14"
        showSavedMessage={saved}
        showButtonClarif={!nonEditable}
        showFooter={false}
      >
        <StyledSectionWrapper>
          <Form.Group>
            <StyledFormLabel>{htmlParser(t('F1_S8_Objetive'))}:</StyledFormLabel>
            <TextValue value={formValues.objective} />
          </Form.Group>

          <div style={{ textAlign: 'justify', lineHeight: '1.3' }}>
            {htmlParser(t('F3_S14_Subtitle_communication_tools'))}
          </div>

          <div style={{ marginBottom: '20px' }} />

          <Accordion style={{ marginLeft: '-20px' }}>
            {formValues.items
              ? formValues.items.map((item, key) => (
                <AccordionItem key={key + 1}>
                  <AccordionItemHeader>
                    <AccordionTitle eventKey={key + 1}>
                      {`${t('F1_S8_Accordion')} ${key + 1}`}
                      {item.mediaType.id ? ` - ${t(`media_type_${item.mediaType.id}`)}` : ''}
                    </AccordionTitle>
                  </AccordionItemHeader>
                  <Accordion.Collapse eventKey={key + 1}>
                    <AccordionItemBody>
                      <MediaForm data={item} />
                    </AccordionItemBody>
                  </Accordion.Collapse>
                </AccordionItem>
              ))
              : null}
          </Accordion>

          <Separator />

          <Title> {t('F3_S14_Subtitle_focal_point')} </Title>

          <Form.Group as={Row}>
            <Col lg={12}>
              <Form.Check
                type="checkbox"
                checked={formValues.samePerson}
                style={{ marginTop: '20px', marginBottom: '20px' }}
                label={t('F3_S14_Checkbox_same_person')}
                disabled={true}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {t('F1_S2_First_name')} <RequiredMark>*</RequiredMark>:
            </Form.Label>
            <Col xs={3} className="align-self-center">
              <TextValue value={formValues.firstname} />
            </Col>
            <Form.Label className="align-self-center" column xs={2}>
              {t('F1_S2_Last_Name')} <RequiredMark>*</RequiredMark>:
            </Form.Label>
            <Col xs={3} className="align-self-center">
              <TextValue value={formValues.lastname} />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column xs={2}>
              {t('F1_S2_Email')} <RequiredMark>*</RequiredMark>:
            </Form.Label>
            <Col xs={3} className="align-self-center">
              <TextValue value={formValues.email} />
            </Col>
            <Form.Label className="align-self-center" column xs={2}>
              {t('F3_S14_Entity')} <RequiredMark>*</RequiredMark>:
            </Form.Label>
            <Col xs={3} className="align-self-center">
              <TextValue value={formValues.entityName} />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column lg={2}>
              {t('F1_S2_Phone_number')}:<RequiredMark>*</RequiredMark>
            </Form.Label>
            <Col lg={4} className="align-self-center">
              <TextValue value={formValues.phone} />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Col>
              <Form.Check type="checkbox" checked={formValues.auth} disabled={true} label={t('F1_S3_Authorize_user')} />
            </Col>
          </Form.Group>

          <SeparatorDotted />
        </StyledSectionWrapper>

        <Comments onSaved={handleSaved} onButton={handleButton} />
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section14);
