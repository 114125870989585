import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { PrimaryButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';

const VerticallyCenteredModal = (props) => {
  const {t} = useTranslation();

    return (
      <Modal
        {...props}
        centered
        dialogClassName="my-modal"
        size= "lg"
        animation={false}
      >
        <Modal.Body style={{height: "80vh", width: "70vw !important"}}>
       
        <iframe height="100%" width="100%" frameBorder="0" allowFullScreen src={props.data} />
        
        </Modal.Body>
        <Modal.Footer>
        <PrimaryButton onClick={props.onHide}>{t('Button_close')}</PrimaryButton>
       </Modal.Footer>
      </Modal>
    );
  }

  export default VerticallyCenteredModal; 