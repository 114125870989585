import React, { useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { useParams } from 'react-router-dom';

import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import VerticallyCenteredModal from 'components/ModalHtml';
import ContactSupportForm from 'components/ContactSupportForm'
import {StyledSavedClarifButton} from 'components/Forms/Form2/RequestClarification/RequestClarification.styled';
import FAQImage from 'assets/icon-chat.png';
import { roleNamesConst } from 'constants/appConstants';
import {
  StyledDivWrapper,
  StyledTitleHead,
  StyledNumberCircle,
  StyledTitle,
  StyledHelpDiv,
  StyledHelpTitle,
  StyledBody,
  StyledFooter,
  StyledSendButton,
  TitleContainer,
  StyledButtonLink,
  StyledSupport,
} from './FormContainer.styled';

const getFAQByForm= (formId) => {
  const faqs = {
    1: 'faq_file_F1',
    3: 'faq_file_F3',
  }
  return faqs[formId];
}

const FormContainer = ({ 
  number, 
  title, 
  formId, 
  children, 
  isHelp = false, 
  showSavedMessage =false, 
  showSavedMessageF2 = false, 
  showFooter = true, 
  showSaveButton = false, 
  showButtonClarif = false
}) => {

  const getFormTitle = (formId) => {
    const titles = {
      1: t('F1_main_title'),
      2: t('F2_main_title'),
      3: t('F3_main_title'),
      4: t('F4_main_title'),
      5: t('F5_main_title'),
      55: t('F5_main_title'),
    }
    return titles[formId];
  }

  const getSectionTitle = (formId, sectionId) => {
    const titles = {
      1: t('F1_S1_title'),
      2: t('F1_s2_title'),
      3: t('F1_S3_title'),
      4: t('F1_S4_title'),
      5: t('F1_S5_title'),
      6: `${(formId == 1)? t('F1_S6_title'): t('F1_S6_title')}}`,
      7: `${(formId == 1)? t('F1_S7_title'): t('F3_S7_title')}`,
      8: `${(formId == 1)? t('F1_S8_title'): t('F3_S8_title')}`,
      9: t('F3_S9_title'),
      10: t('F3_S10_title'),
      11: t('F3_S11_title'),
      12: t('F3_S12_title'),
      13: t('F3_S13_title'),
      14: t('F3_S14_title')
    }
    return titles[sectionId];
  }

  const [helpDialogData, setHelpDialogData] = useState({
    isShow: false
  });
  const [modalShow, setModalShow] = useState(false);
  const { formStore, authStore, initiativeStore } = useRootStore();
  const params = useParams();
  const id = params.formId;
  const role = authStore.roles[0];
  const isTa = role === roleNamesConst.ATKE || role === roleNamesConst.ATNKE;

  const isManager = role === roleNamesConst.CONSORTIUM || role === roleNamesConst.ATKE || role === roleNamesConst.INTPA || role === roleNamesConst.EUD ;

  const hideSaveButton = 
    !showSaveButton || 
    (
      params.formId === '55' && 
      (initiativeStore?.role === 'GUEST' || role === roleNamesConst.ATNKE)
    );
  
  const [wrongSectionDataLoaded, setWrongSectionDataLoaded] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    setWrongSectionDataLoaded(formStore.formSectionNumber !== params.sectionId);
  },[formStore.formSectionNumber, params.sectionId])

  if (wrongSectionDataLoaded)
    return (
      <Col style={{marginTop: '10%', textAlign: 'center'}}>
        <Spinner animation="border" variant="secondary"/>
      </Col>
    );

  return (
    <StyledDivWrapper>
      {helpDialogData.isShow && <ContactSupportForm
        show={helpDialogData.isShow}
        handleClose={() => {setHelpDialogData({
          isShow: false
        })}}
        initiativeCode={initiativeStore.code}
        formName={getFormTitle(params.formId)}
        sectionName={getSectionTitle(params.formId, params.sectionId)}
        sectionNumber={params.sectionId}
        sendTo="SUPPORT"
      />}

      <StyledTitleHead>
        <TitleContainer>
          <StyledNumberCircle> {number} </StyledNumberCircle>
          <StyledTitle> {title} </StyledTitle>
        </TitleContainer>

        <div>
        {((id==2) && (!(formId === "form2section1") && (!isTa) && showButtonClarif )) &&
           ( <StyledSavedClarifButton type="submit" form={formId} disabled={showSavedMessageF2} >
                {!showSavedMessageF2? t('Button_Save_Request_Clarification'): t('Button_Saved_Request_Clarification')}
            </StyledSavedClarifButton>
           )
         }

        {((id==4) && (!(formId === "form4section1") && (!isTa) && showButtonClarif )) &&
           ( <StyledSavedClarifButton type="submit" form={formId} disabled={showSavedMessage} >
                {!showSavedMessage? t('Button_Save_Comments'): t('Button_Saved_Comments')}
            </StyledSavedClarifButton>
           )
        }

        { (id==5 && formId !== "form5section1" && isManager && showButtonClarif ) && (
          <StyledSendButton type='submit' form={`${formId}Mgm`} disabled={showSavedMessage} >
            {showSavedMessage? t('Button_saved'): t('Button_save')}
          </StyledSendButton>
        )}

         { (showSaveButton && (id==1 || id==3 || id==5)) && (
          <StyledSendButton type='submit' form={formId} disabled={showSavedMessage} >
            {showSavedMessage? t('Button_saved'): t('Button_save')}
          </StyledSendButton>
        )}
        </div>

      </StyledTitleHead>
      <StyledBody>
        {children}
      </StyledBody>
      { showFooter && <StyledFooter>
        <div>
          {isHelp && (
            <>
            <StyledHelpDiv>
              <Row>
                <Col md={2}>
                  <Image src={FAQImage} style={{
                    marginBottom:'-30px',
                    width: '50px',
                    height: '40px'
                  }}/>
                </Col>
                <Col md={9} >
                  <StyledHelpTitle> {t('need_help')} </StyledHelpTitle>
                  {t('consult_our')} <StyledButtonLink variant="link" onClick={() => setModalShow(true)}>{t('faq')}</StyledButtonLink>{" "+t('or')+" "}<StyledSupport onClick={() => {setHelpDialogData({isShow: true})}}> {t('request_support')} </StyledSupport>.
                </Col>
              </Row>
            </StyledHelpDiv>

            <VerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              data={t(getFAQByForm(params.formId))}
            />
          </>
          )}
        </div>

        { hideSaveButton ? null : (
          <StyledSendButton type='submit' form={formId} disabled={showSavedMessage} >
            {showSavedMessage? t('Button_saved'): t('Button_save')}
          </StyledSendButton>
        )}

      </StyledFooter>}
    </StyledDivWrapper>
  )
};

export default observer(FormContainer);
