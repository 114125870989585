import React from 'react';
import { useTranslation } from 'react-i18next';

import { 
  StyledFormLabel,
  StyledTitle,
  StyledSavedButton,
  StyledGreenFormLabel
} from './EUDAssessmentForm.styled'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { RequiredMark } from 'components/CommonStyled';
import { PrimaryButton, SuccessButton } from 'components/Buttons';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const EUDAssessmentForm = ({ formikInstance, nonEditable, handleSendToMainAssessor, onValidateSubmit, saved, sending }) => {
  const {t} = useTranslation();
  const { getFieldProps, touched, errors, resetForm, values, handleBlur, handleChange, isValid } = formikInstance;

  return (
    <div style={{marginBottom: '100px'}}>
      <StyledTitle style={{marginLeft: '15px'}}>{t('Button_my_assessment')}</StyledTitle>

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F7_EUD_assessment_question1')} <RequiredMark>*</RequiredMark>: </StyledFormLabel>
          <Form.Control
            name='assessmentQ1'
            as="textarea"
            style={{resize: "none"}}
            rows={8} 
            className={touched.assessmentQ1 && errors.assessmentQ1 ? "error" : null}
            { ...getFieldProps('assessmentQ1') }
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.assessmentQ1}
            errors={errors.assessmentQ1}
          />          
        </Col>
      </Form.Group>  

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F7_EUD_assessment_question2')} <RequiredMark>*</RequiredMark>: </StyledFormLabel>
          <Form.Control
            name='assessmentQ2'
            as="textarea"
            style={{resize: "none"}}
            rows={8} 
            className={touched.assessmentQ2 && errors.assessmentQ2 ? "error" : null}
            { ...getFieldProps('assessmentQ2') }
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.assessmentQ2}
            errors={errors.assessmentQ2}
          />           
        </Col>
      </Form.Group>  

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F7_EUD_assessment_question3')} <RequiredMark>*</RequiredMark>: </StyledFormLabel>
          <Form.Control
            name='assessmentQ3'
            as="textarea"
            style={{resize: "none"}}
            rows={8} 
            className={touched.assessmentQ3 && errors.assessmentQ3 ? "error" : null}
            { ...getFieldProps('assessmentQ3') }
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.assessmentQ3}
            errors={errors.assessmentQ3}
          />              
        </Col>
      </Form.Group>  

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F7_EUD_assessment_question4')} <RequiredMark>*</RequiredMark>: </StyledFormLabel>
          <Form.Control
            name='assessmentQ4'
            as="textarea"
            style={{resize: "none"}}
            rows={8} 
            className={touched.assessmentQ4 && errors.assessmentQ4 ? "error" : null}
            { ...getFieldProps('assessmentQ4') }
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.assessmentQ4}
            errors={errors.assessmentQ4}
          />             
        </Col>
      </Form.Group>  

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F7_EUD_assessment_question5')} <RequiredMark>*</RequiredMark>: </StyledFormLabel>
          <Form.Control
            name='assessmentQ5'
            as="textarea"
            style={{resize: "none"}}
            rows={8} 
            className={touched.assessmentQ5 && errors.assessmentQ5 ? "error" : null}
            { ...getFieldProps('assessmentQ5') }
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.assessmentQ5}
            errors={errors.assessmentQ5}
          />            
        </Col>
      </Form.Group>  

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F7_EUD_assessment_question6')} <RequiredMark>*</RequiredMark>: </StyledFormLabel>
          <Form.Control
            name='assessmentQ6'
            as="textarea"
            style={{resize: "none"}}
            rows={8} 
            className={touched.assessmentQ6 && errors.assessmentQ6 ? "error" : null}
            { ...getFieldProps('assessmentQ6') }
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.assessmentQ6}
            errors={errors.assessmentQ6}
          />                
        </Col>
      </Form.Group>  

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F7_EUD_assessment_question7')} <RequiredMark>*</RequiredMark>: </StyledFormLabel>
          <Form.Control
            name='assessmentQ7'
            as="textarea"
            style={{resize: "none"}}
            rows={8} 
            className={touched.assessmentQ7 && errors.assessmentQ7 ? "error" : null}
            { ...getFieldProps('assessmentQ7') }
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.assessmentQ7}
            errors={errors.assessmentQ7}
          />             
        </Col>
      </Form.Group>  

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F7_EUD_assessment_question8')} <RequiredMark>*</RequiredMark>: </StyledFormLabel>
          <Form.Control
            name='assessmentQ8'
            as="textarea"
            style={{resize: "none"}}
            rows={8} 
            className={touched.assessmentQ8 && errors.assessmentQ8 ? "error" : null}
            { ...getFieldProps('assessmentQ8') }
            disabled={nonEditable}
          />
          <FormErrorMessage
            touched={touched.assessmentQ8}
            errors={errors.assessmentQ8}
          />            
        </Col>
      </Form.Group>  

      <Form.Group>
        <Col>
          <StyledGreenFormLabel> {t('F7_EUD_assessment_question9')} </StyledGreenFormLabel> <RequiredMark>*</RequiredMark>:
          <Form.Control
            as="textarea"
            style={{resize: "none"}}
            rows={8} 
            name="assessmentQ9"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.assessmentQ9}
            disabled={nonEditable}
            className={touched.assessmentQ9 && errors.assessmentQ9 ? "error" : null}
          />
          <FormErrorMessage
            touched={touched.assessmentQ9}
            errors={errors.assessmentQ9}
          />   
        </Col>
      </Form.Group>


      <div style={{marginTop: '50px', marginLeft: '15px'}}>
        <Row style={{justifyContent: 'space-between'}}>
          <Col>
            <> 
              {!saved && <PrimaryButton disabled={nonEditable || sending} onClick={() => {onValidateSubmit(isValid, values, resetForm)}}> {t('Button_Save_Later')} </PrimaryButton>}
              {saved && <StyledSavedButton disabled={true} > 
                {t('Button_saved')}
              </StyledSavedButton> } 
            </>
          </Col>
          <Col  style={{marginBottom: "40px"}} className="col-auto">
            <SuccessButton disabled={nonEditable || sending || !values.assessmentQ9} onClick={() => { handleSendToMainAssessor() }}> {t('Button_Send_Assessor')} </SuccessButton>
          </Col>
        </Row>
      </div>

    </div>
  )
}

export default EUDAssessmentForm;