import React from 'react'
import GreenArrow from 'assets/flecha-verde-big-on.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ArrowImage } from 'components/CustomAccordion/CustomAccordion.styled';

const FakeAccordionItem = ({ title, children, noMargin }) => {
  return (
    <div>
      <Col>
        <Row style={{marginLeft: noMargin ? '-10px' : '35px'}}>
          <ArrowImage src={GreenArrow} alt='Arrow' style={{ marginBottom: '15px' }}/>
          <span style={{fontSize: '18px'}}> {title} </span>
        </Row>
        {children}
      </Col>
    </div>
  )
}

export default FakeAccordionItem;