import React, { useEffect } from 'react'
import { getDownload } from 'services/initiative';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';
import { WORKING_DAYS_CONSTANT } from 'constants/budgetConstants';

import TextValue from 'components/TextValue';
import { PrimaryButton } from 'components/Buttons'
import InputGroup from 'react-bootstrap/InputGroup';
import MoneyInput from 'components/MoneyInput';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { RequiredMark, CenteredModalButtonContainer } from 'components/CommonStyled';
import { 
  StyledModalBody,
  ModalTitle,
  StyledDiv,
  StyledButtonLink
} from './BudgetLineForm.styled'

const BudgetLineForm = ({handleCloseOk, show, currentBudget }) => {
  const { t } = useTranslation();

  const PERDIEM_CONSTANT = 250;

  const handleDownload = async (uuid, downloadName) => {
    try {
      const file = await getDownload(uuid)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download", downloadName
        );
        document.body.appendChild(link);
        link.click();
    });
    } catch (error) {
      console.log(error);
    }    
  }

  return (
    <Modal show={show} onHide={handleCloseOk} size="xl" backdrop="static" animation={false}>

      <StyledModalBody>

        <ModalTitle>{`${t('F3_S7_Budgetline_subtitle')} ${!currentBudget.name? "": `- ${t('F1_S7_Accordion')} ${currentBudget.name}`}`}</ModalTitle>
  
        <Form.Group as={Row}>
          <Form.Label column xs={2}>{t('F1_S7_type')}:</Form.Label>
          <TextValue value={currentBudget.resourceType ? t(`resource_type_${currentBudget.resourceType.id}`) : ' - '} />
        </Form.Group>

        <Form.Group>
          <Form.Label xs={4}>{t('F3_S7_Budgetline_description')}:</Form.Label>
          <TextValue value={currentBudget.description} />
        </Form.Group>

        <Form.Group>
          <Row>
            <Col xs={4}>
              <Form.Label> {t('F3_S7_Budgetline_who_will_pay')}<RequiredMark>*</RequiredMark>:</Form.Label>
            </Col>
            <Col>
              <label>
                <input disabled={true} type="radio" checked={currentBudget.ueOrCounterpart == 0} style={{marginLeft: '30px'}} />
                <span style={{marginLeft: '5px'}}> {t('F3_S7_Budgetline_EU_pays')} </span>
              </label>
              <label>
                <input disabled={true} type="radio" checked={currentBudget.ueOrCounterpart == 1} style={{marginLeft: '50px'}} />
                <span style={{marginLeft: '5px'}}> {t('F3_S7_Budgetline_EU_parnership')} </span> 
              </label>   
            </Col>
          </Row>
        </Form.Group>

        {(currentBudget.resourceType && currentBudget.resourceType.id == 1) &&
          <Form.Group as={Row}>
            <Form.Label column xs={2}>{t('F3_S7_Budgetline_working_days')}:</Form.Label>
            <TextValue value={currentBudget.workingDays} />
          </Form.Group>
        }

        {(currentBudget.resourceType && currentBudget.resourceType.id == 9) &&
          <Form.Group as={Row}>
            <Form.Label column xs={2}>{t('F3_S7_Budgetline_perdime_days')}:</Form.Label>
            <TextValue value={currentBudget.perDiemDays} />
          </Form.Group>
        }

        {(currentBudget.resourceType && currentBudget.resourceType.id != 9 && currentBudget.resourceType.id != 1) &&
          <Form.Group as={Row} style={{ alignItems: "center" }}>
            <Form.Label column xs={2}>{t('F3_S7_Budgetline_estimated_cost')}:</Form.Label>
            <Col xs={2}>
              <InputGroup style={{ width: '140px' }}>
                <MoneyInput
                  name="estimatedCost"
                  value={currentBudget.estimatedCost}
                  customInput={Form.Control}
                  disabled={true}
                />
              </InputGroup>
            </Col>
          </Form.Group>
        }

        <Form.Group as={Row} style={{ alignItems: "center" }}>
          <Form.Label column xs={2}>{t('F3_S7_Budgetline_total_amount')}:</Form.Label>
          <Col xs={2}>
            <InputGroup style={{ width: '140px' }}>
              <MoneyInput
                name="estimatedCost"
                value={WORKING_DAYS_CONSTANT * currentBudget.workingDays  +  PERDIEM_CONSTANT * currentBudget.perDiemDays + currentBudget.estimatedCost}
                customInput={Form.Control}
                disabled={true}
              />
            </InputGroup>
          </Col>
          <Col xs={4}>
            {htmlParser(t('F3_S7_Budgetline_eur_conversion_link'))}
          </Col>
        </Form.Group>

        {currentBudget.file &&
          <StyledDiv>
            <StyledButtonLink variant="link" onClick={() => handleDownload(currentBudget.file.uuid, currentBudget.file.fileName)}> {currentBudget.file.fileName} </StyledButtonLink>
          </StyledDiv>
        }

        <CenteredModalButtonContainer>
          <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
        </CenteredModalButtonContainer>

      </StyledModalBody>
    </Modal>
  )
}

export default observer(BudgetLineForm);