import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import TextValue from 'components/TextValue';

import Form from 'react-bootstrap/Form';
import {
  StyledFormLabel,
  StyledTitle as StyledTitleLocal
} from 'components/Forms/Form5/Section5/Section5.styled';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody 
} from '../CommonStyles'
import FakeAccordionItem from '../../FakeAccordionItem';
import { RequiredMark } from 'components/CommonStyled';

export const formInitialValues = {
  overview: "",
  relations1: "",
  relations2: "",
  relations3: "",
  relations4: "",
  assessment: "",
  perspectives: "",
};

const Section5 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(formInitialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data]);

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`5 - ${t('F1_S5_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <FakeAccordionItem title={t('F5_Subtitle_Description_from_CN')}>
          <Form.Group>
            <StyledFormLabel>{t('F1_S5_Provide_an_overview')}:</StyledFormLabel>
            <TextValue value={formValues.overview} />
          </Form.Group> 
        </FakeAccordionItem>

        <FakeAccordionItem title={t('F5_Subtitle_Description_from_proposal')}>
            <Form.Group>
              <StyledFormLabel>{t('F3_S5_Question_1')}:</StyledFormLabel>
              <TextValue value={formValues.relations1} />
            </Form.Group> 
            <Form.Group>
              <StyledFormLabel>{t('F3_S5_Question_2')}:</StyledFormLabel>
              <TextValue value={formValues.relations2} />
            </Form.Group>  
            <Form.Group>
              <StyledFormLabel>{t('F3_S5_Question_3')}:</StyledFormLabel>
              <TextValue value={formValues.relations3} />
            </Form.Group> 
            <Form.Group>
              <StyledFormLabel>{t('F3_S5_Question_4')}:</StyledFormLabel>
              <TextValue value={formValues.relations4} />
            </Form.Group>  
        </FakeAccordionItem>

        <StyledTitleLocal>
          {t('F5_Subtitle_Assessment_TCI_Implementation')}
        </StyledTitleLocal>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S5_Assessment_joint_work')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="assessment"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues.assessment}
            disabled={true}
          />
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>
            {t('F5_S5_Perspectives_joint_work')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="perspectives"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues.perspectives}
            disabled={true}
          />
        </Form.Group>
      </StyledBody>
    </SectionContainer>
  )
}

export default Section5;


