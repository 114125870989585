import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import htmlParser from "html-react-parser";
import { deleteResourceDetailF3S7 } from 'services/initiative';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores';
import { getUploadFormatsConstants } from "services/apiConstants";

import CustomBudgetDetailsTable from 'components/CustomBudgetDetailsTable';
import ConfirmCloseModal from 'components/ConfirmCloseModal';
import BudgetLineForm from '../BudgetLineForm';
import MoneyTextMask from 'components/MoneyTextMask';
import Table from 'react-bootstrap/Table';
import { StyledFormLabel } from '../Section7.styled';
import TextValue from 'components/TextValue';
import { PrimaryButton } from 'components/Buttons';
import "react-datepicker/dist/react-datepicker.css";
import { Form } from 'react-bootstrap';
import { CenteredModalButtonContainer } from 'components/CommonStyled';
import {
  RowBorder,
  BorderlessTr,
  TableHeader,
  ResourceNameSpan,
  ResourceNumberSpan,
  EstimatedBugetDiv,
  TotalBugetDiv,
  TotalBudgetSpan,
} from './ResourcesForm.styled';

const ResourcesForm = ({ handleCloseOk, activity, handleRefreshList, setModalInfo, nonEditable }) => {
  const { t } = useTranslation();
  const { formStore } = useRootStore();
  const { id } = useParams();
  const [currentBudget, setCurrentBudget] = useState(null);
  const [selectedDetailId, setSelectedDetailId] = useState("");
  const [budgetLineModalShow, setBudgetLineModalShow] = useState(false);
  const [modalClose, setModalClose] = useState(false);
  const [removeItemModalShow, setRemoveItemModalShow] = useState(false);
  const [localBudgetLineList, setLocalBudgetLineList] = useState([...activity.resourceList]);
  const [validFileFormats, setValidFileFormats] = useState([]);
  const [localTotalBudget, setLocalTotalBudget] = useState({
    ueBudget: activity.ueBudget,
    counterpartBudget: activity.counterpartBudget,
    totalBudget: activity.totalBudget
  });


  useEffect(() => {
    const flattedArray = handleFlatArray(activity.resourceList);
    setLocalBudgetLineList([...flattedArray]);
  }, [activity, activity.resourceList])
  
  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await getUploadFormatsConstants();
        if(response && response.F3S7){
          setValidFileFormats(response.F3S7);
        }
      } catch (e) {
        console.log(e);
      }
    }
    fetchAPI();
   }, [])

  const handleOpenBudgetLine = async (detailExtended) => {
    setCurrentBudget(detailExtended);
    setBudgetLineModalShow(true);
  }

  const handleFlatArray = (array) => {
    const flatArray = array.map(resource => {
      if(resource.details){
        return resource.details.map(detail => {
          return {
            ...detail,
            resourceType: resource.resourceType
          }
        })
      }
    });
    return flatArray.flat();
  }

  const setNewToLocalList = (newList) => {
    const flattedArray = handleFlatArray(newList);
    setLocalBudgetLineList([...flattedArray]);
  }

  const handleRemoveButton = (detailId) => {
    setSelectedDetailId(detailId); 
    setRemoveItemModalShow(true);
  }

  const handleRemoveResource = async (detailId) => {
    try {
      const response = await deleteResourceDetailF3S7(id, activity.id, detailId);
      const currentActivitiesCopy = [...formStore.formSectionData[0].activities];
      currentActivitiesCopy.splice(currentActivitiesCopy.findIndex(function(i){
        return i.id == response.id;
      }), 1);
      const sortedActivities = [...currentActivitiesCopy, response];
      sortedActivities.sort((a, b) => a.id - b.id);
      formStore.setFormSectionData([
        {
          activities: sortedActivities
        }
      ]);
      setLocalTotalBudget({
        ueBudget: response.ueBudget,
        counterpartBudget: response.counterpartBudget,
        totalBudget: response.totalBudget
      })
      setNewToLocalList(response.resourceList);
      setModalInfo({
        message: "Budget line removed.",
        type: "success",
        isOpen: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>

      <ConfirmCloseModal
        show={removeItemModalShow}
        handleCloseConfirm={() => setRemoveItemModalShow(false)}
        handleActionOnConfirm={() => {handleRemoveResource(selectedDetailId)}}
        text={t('Confirmation remove budget resource')}
      />

      <BudgetLineForm 
        handleCloseOk={handleCloseOk}
        setNewToLocalList={setNewToLocalList}
        setLocalTotalBudget={setLocalTotalBudget}
        show={budgetLineModalShow}
        handleClose={() => { setModalClose(true) }} 
        handleCloseOnSave={async () => {
          setBudgetLineModalShow(false);
          await handleRefreshList();
          setModalInfo({
            message: "Budget line saved.",
            type: "success",
            isOpen: true,
          });
          setCurrentBudget(null);
          setTimeout((function() { setModalInfo(
            {isOpen: false,
            message: "",
            type: "info",})})
          ,2000);
        }}
        handleCloseOnError={() => {
          setModalInfo({
            message: "Error. Please try again later",
            type: "error",
            isOpen: true,
          });
        }}
        activity={activity}
        currentBudget={currentBudget}
        validFileFormats={validFileFormats}
        setModalInfo={setModalInfo}
        nonEditable={nonEditable}
      />
        <ConfirmCloseModal 
          show={modalClose}
          text={t('Confirmation close modal')}
          handleActionOnConfirm={() => {
            setModalClose(false);
            setBudgetLineModalShow(false);
            setCurrentBudget(null);
          }}
          handleCloseConfirm={() => {setModalClose(false)}}
        />
        <Form>

          {htmlParser(t('F3_S7_Resource_help_text'))}

          <CustomBudgetDetailsTable
            items={localBudgetLineList}
            isOpenable={true}
            hasRemoveButton={true}
            handleOpenBudgetLine={handleOpenBudgetLine}
            nonEditable={nonEditable}
            handleRemoveButton={handleRemoveButton}
          />
          
          <PrimaryButton disabled={nonEditable} onClick={() => {setBudgetLineModalShow(true)}}> {t('F3_S7_Resource_button_add')} </PrimaryButton>
          
          <EstimatedBugetDiv>
            <Table bordered={ false }>
              <thead>
                <BorderlessTr>
                  <TableHeader width={"45"}></TableHeader>
                  <TableHeader width={"15"}>{t('F3_S7_Resource_column_EU')}</TableHeader>
                  <TableHeader width={"15"}>{t('F3_S7_Resource_column_counterpart')}</TableHeader>
                  <TableHeader width={"15"}>{t('F3_S7_Resource_column_total')}</TableHeader>
                </BorderlessTr>
              </thead>
              <tbody>
                <BorderlessTr>
                  <td colSpan="4">
                    <RowBorder />
                  </td>
                </BorderlessTr>
                <BorderlessTr>
                  <td><ResourceNameSpan>{t('F3_S7_Resource_estimated_totals')}</ResourceNameSpan></td>
                  <td><ResourceNumberSpan> <MoneyTextMask value={activity.estimatedUeBudget} /> </ResourceNumberSpan></td>
                  <td><ResourceNumberSpan> <MoneyTextMask value={activity.estimatedCounterpartBudget} /> </ResourceNumberSpan></td>
                  <td><ResourceNumberSpan> <MoneyTextMask value={activity.estimatedTotalBudget} /> </ResourceNumberSpan></td>
                </BorderlessTr>
              </tbody>
            </Table>

            <Form.Group>
              <StyledFormLabel>{t('F3_S7_Resource_justification')}:</StyledFormLabel>
              <TextValue value={activity.justBudget} />
            </Form.Group>  
          </EstimatedBugetDiv>
          
          <TotalBugetDiv>
            <Table bordered={ false }>
              <thead>
                <BorderlessTr>
                  <TableHeader width={"45"}><TotalBudgetSpan> {t('F3_S7_Resource_total_activity')} {activity.name} </TotalBudgetSpan> </TableHeader>
                  <TableHeader width={"15"}>
                    <ResourceNumberSpan style={{color: 'black'}}>
                      <MoneyTextMask value={localTotalBudget.ueBudget} />
                    </ResourceNumberSpan>
                  </TableHeader>
                  <TableHeader width={"15"}>
                    <ResourceNumberSpan style={{color: 'black'}}>
                      <MoneyTextMask value={localTotalBudget.counterpartBudget} />
                    </ResourceNumberSpan>
                  </TableHeader>
                  <TableHeader width={"15"}>
                    <ResourceNumberSpan style={{color: 'black'}}>
                      <MoneyTextMask value={localTotalBudget.totalBudget} />
                    </ResourceNumberSpan>
                  </TableHeader>
                </BorderlessTr>
              </thead>
            </Table>
          </TotalBugetDiv>

          <CenteredModalButtonContainer>
            <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
          </CenteredModalButtonContainer>

        </Form>
    </div>
  )
}

export default observer(ResourcesForm);