import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useRootStore } from "stores";
import { formInitialValues } from "./Section2.initialValues";
import TextValue from "components/TextValue";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormLayout from "../../FormLayout";
import FormContainer from "components/FormContainer";
import CustomModal from "components/Modal";
import { StyledCustomLink } from 'components/Forms/Forms.styled';
import { useTranslation } from "react-i18next";
import htmlParser from "html-react-parser";

import Comments from "components/Forms/Form4/Comments";

import { getDownload } from "services/initiative";

import {
  StyledDiv,
  StyledIntroParagraph,
  StyledFormLabel,
  StyledSeparator,
  StyledRadioSection,
  StyledRadioLabel,
} from "./Section2.styled";

const Section2 = () => {
  const { t } = useTranslation();
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const { formStore } = useRootStore();
  const [formValues, setFormValues] = useState(formInitialValues);
  const [modal, setModal] = React.useState({
    isOpen: false,
    message: "",
    type: "default",
  });

  const onHideModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  const handleSaved = (value) => {
    setSaved(value);
  };

  const handleButton = (value) => {
    setNonEditable(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = {
        ...formInitialValues,
        ...formStore.formSectionData[0],
      };
      setFormValues(formSectionData);
      formStore.setCurrentSection(2);
    }
  }, [formStore.formSectionData]);

  const handleDownload = async (uuid, downloadName) => {
    try {
      const file = await getDownload(uuid).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadName);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      setModal({
        ...modal,
        message: t("Error_file1"),
        type: "error",
        isOpen: true,
      });
    }
  };

  return (
    <FormLayout>
      {modal.isOpen && (
        <CustomModal
          show={modal.isOpen}
          onHide={onHideModal}
          message={modal.message}
          type={modal.type}
        />
      )}
      <FormContainer
        number={2}
        title={t("F1_s2_title")}
        formId="form4section2"
        showSavedMessage={saved}
        showButtonClarif={!nonEditable}
        showFooter={false}
      >
        <StyledIntroParagraph>{t("F3_S2_Intro")}</StyledIntroParagraph>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Entity_name")}:
          </StyledFormLabel>
          <TextValue value={formValues.entityName} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Official_acronym")}:
          </StyledFormLabel>
          <TextValue value={formValues.acronym} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Type_of_entity")}:
          </StyledFormLabel>
          <TextValue
            value={
              formValues.entityType
                ? t(`entity_type_${formValues.entityType.id}`)
                : " - "
            }
          />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Role")}:
          </StyledFormLabel>
          <Col lg={8} style={{ marginTop: "10px" }}>
            <StyledRadioLabel>
              <input
                disabled
                type="radio"
                value="R"
                checked={formValues.role == 0}
                style={{ marginRight: "10px" }}
              />
              {t("F1_S2_Recipient")}
            </StyledRadioLabel>

            <StyledRadioLabel>
              <input
                disabled
                type="radio"
                value="P1"
                checked={formValues.role == 1}
                style={{ marginRight: "10px" }}
              />
              {t("F1_S2_Provider1")}
            </StyledRadioLabel>

            <StyledRadioLabel>
              <input
                disabled
                type="radio"
                value="P2"
                checked={formValues.role == 2}
                style={{ marginRight: "10px" }}
              />
              {t("F1_S2_Provider2")}
            </StyledRadioLabel>
          </Col>
        </Form.Group>

        <Form.Group as={Row} style={{ alignItems: "center" }}>
          <StyledFormLabel className="align-self-start" column lg={4}>
            {t("F1_S2_Contribution")}:
          </StyledFormLabel>
          <Col lg={8}>
            <StyledRadioSection>
              <Form.Check
                name="optionA"
                type="checkbox"
                checked={formValues.optionA}
                value={formValues.optionA}
                disabled
                label={t("F1_S2_Provider_detail_A")}
              />
              <Form.Check
                name="optionB"
                type="checkbox"
                checked={formValues.optionB}
                value={formValues.optionB}
                disabled
                label={t("F1_S2_Provider_detail_B")}
              />
              <Form.Check
                name="optionC"
                type="checkbox"
                checked={formValues.optionC}
                value={formValues.optionC}
                disabled
                label={t("F1_S2_Provider_detail_C")}
              />
              <Form.Check
                name="optionD"
                type="checkbox"
                checked={formValues.optionD}
                value={formValues.optionD}
                disabled
                label={t("F1_S2_Provider_detail_D")}
              />
              <TextValue value={formValues.other} />
            </StyledRadioSection>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Entity_address")}:
          </StyledFormLabel>
          <TextValue value={formValues.address} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Country")}:
          </StyledFormLabel>
          <TextValue value={formValues.country && t(formValues.country.name)} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_State_Province_Region")}:
          </StyledFormLabel>
          <TextValue value={formValues.stateName} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_City")}:
          </StyledFormLabel>
          <TextValue value={formValues.cityName} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Website")}:
          </StyledFormLabel>
          <TextValue value={formValues.web} />
        </Form.Group>

        <StyledSeparator />

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Responsisble_person")}:
          </StyledFormLabel>
          <TextValue value={formValues.firstNameTciApplicant} />
          <TextValue value={formValues.lastNameTciApplicant} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Position")}:
          </StyledFormLabel>
          <TextValue value={formValues.positionResp} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Email")}:
          </StyledFormLabel>
          <TextValue value={formValues.emailResp} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Phone_number")}:
          </StyledFormLabel>
          <TextValue value={formValues.phoneResp} />
        </Form.Group>

        <StyledSeparator />

        <Form.Group as={Row}>
          <Col lg={12}>
            <Form.Check
              disabled
              name="ownerIsSigner"
              type="checkbox"
              value={formValues.ownerIsSigner}
              checked={formValues.ownerIsSigner}
              style={{ marginLeft: "50px", marginTop: "10px" }}
              label={t("F1_S2_Duly_signer_checkbox")}
            />
          </Col>
        </Form.Group>

        <StyledSeparator />

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Duly_signer")}:
          </StyledFormLabel>
          <TextValue value={formValues.firstNameSigner} />
          <TextValue value={formValues.lastNameSigner} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Position")}:
          </StyledFormLabel>
          <TextValue value={formValues.positionSigner} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Email")}:
          </StyledFormLabel>
          <TextValue value={formValues.emailSigner} />
        </Form.Group>

        <Form.Group as={Row}>
          <StyledFormLabel column lg={4}>
            {t("F1_S2_Phone_number")}:
          </StyledFormLabel>
          <TextValue value={formValues.phoneSigner} />
        </Form.Group>

        <StyledSeparator />

        <StyledDiv>
          <StyledCustomLink
            onClick={() =>
              handleDownload(
                formValues.triangulardoc.uuid,
                formValues.triangulardoc.fileName
              )
            }
          >
            {htmlParser(t("F2_S2_Download"))}
          </StyledCustomLink>
        </StyledDiv>

        <StyledSeparator />

        <Comments onSaved={handleSaved} onButton={handleButton} />
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section2);
