import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { mapActivityItems } from 'helpers/f5Helpers';

import TextValue from 'components/TextValue';
import Form from 'react-bootstrap/Form';
import { RequiredMark } from 'components/CommonStyled';
import {
  StyledFormLabel,
  StyledTitle as StyledTitleLocal,
  StyledSubtitle,
} from 'components/Forms/Form5/Section10/Section10.styled';
import {
  SectionContainer,
  StyledTitleHead,
  TitleContainer,
  StyledTitle,
  StyledBody
} from '../CommonStyles'
import FakeAccordionItem from '../../FakeAccordionItem';
import { SeparatorDotted } from 'components/CommonStyled';

export const formInitialValues = [
  {
    overviewRelationship: "",
    overviewParticipation: "",
    overviewKnowledge: "",
    explainDerivation: "",
    explainData: "",
    justificationBeneficiaries: "",
  }
];

const Section10 = ({ data }) => {
  const { t } = useTranslation();
  const [beneficiariesList, setBeneficiariesList] = useState([]);
  const [formValues, setFormValues] = useState(formInitialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data);
      setBeneficiariesList(data[0].benList);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`10 - ${t('F3_S10_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <StyledTitleLocal style={{marginBottom: '30px'}}>
          {t('F5_Subtitle_Assessment_TCI_Implementation')}
        </StyledTitleLocal>

        <StyledSubtitle>
          {t('F5_S7_Activity_assessment_6')}:
        </StyledSubtitle>

        <div style={{marginTop: '20px'}}>
          {formValues? mapActivityItems(formValues[1]): "-"}
        </div>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_Overview_of_TCI')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="overviewRelationship"
            as="textarea"
            rows={6}
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].overviewRelationship}
            disabled={true}
          />
        </Form.Group>

        <StyledSubtitle>
          {t('F5_S7_Activity_assessment_7')}:
        </StyledSubtitle>

        <div style={{marginTop: '20px'}}>
          {formValues? mapActivityItems(formValues[2]): "-"}
        </div>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_Overview_of_TCI')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="overviewParticipation"
            as="textarea"
            rows={6}
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].overviewParticipation}
            disabled={true}
          />
        </Form.Group>

        <StyledSubtitle>
          {t('F5_S7_Activity_assessment_8')}:
        </StyledSubtitle>

        <div style={{marginTop: '20px'}}>
          {formValues? mapActivityItems(formValues[3]): "-"}
        </div>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_Overview_of_TCI')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="overviewKnowledge"
            as="textarea"
            rows={6}
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].overviewKnowledge}
            disabled={true}
          />
        </Form.Group>

        {(beneficiariesList && beneficiariesList.map((beneficiary, key) => (
          <React.Fragment  key={key}>
            <FakeAccordionItem title={t('F3_S10_Beneficiary')+" "+(key+1)}>
              <div style={{marginLeft: '50px'}}>
                <Form.Group style={{display: 'flex'}}>
                  <StyledFormLabel style={{marginTop: '7px'}}>{t('F5_S10_type_of_beneficiary')}:</StyledFormLabel>
                  <TextValue value={beneficiary.beneficiaryType? t(`beneficiary_type_${beneficiary.beneficiaryType.id}`) : ""} />
                </Form.Group>

                <Form.Group style={{display: 'flex'}}>
                  <StyledFormLabel style={{marginTop: '7px'}}>{t('F5_S10_beneficiary_quantification')}:</StyledFormLabel>
                  <TextValue value={beneficiary.beneficiaryQuantification} />
                </Form.Group>

                <Form.Group style={{display: 'flex'}}>
                  <StyledFormLabel style={{marginTop: '10px'}}>{t('F5_S10_quantity_all_countries')}:</StyledFormLabel>
                  <TextValue value={beneficiary.quantityAllCountries} />
                </Form.Group>
              </div>
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}

        <Form.Group style={{marginTop: '30px'}}>
          <StyledFormLabel>
            {t('F5_S10_Justification_beneficiaries')}:
          </StyledFormLabel>
          <Form.Control
            name="justificationBeneficiaries"
            as="textarea"
            rows={6}
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].justificationBeneficiaries}
            disabled={true}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S10_Explain_deviation')}:
          </StyledFormLabel>
          <Form.Control
            name="explainDerivation"
            as="textarea"
            rows={6}
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].explainDerivation}
            disabled={true}
          />
        </Form.Group>

        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_S10_Explain_other_data')}:
          </StyledFormLabel>
          <Form.Control
            name="explainData"
            as="textarea"
            rows={6}
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].explainData}
            disabled={true}
          />
        </Form.Group>
      </StyledBody>
    </SectionContainer>
  )
}

export default observer(Section10);