import React, {useEffect, useState} from 'react';
import { useRootStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { roleNamesConst, f5statusNamesConst} from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PDFGenerationModal from 'components/PDFGenerationModal';
import SendDialog from './SendDialog';
import { 
  StyledSendButton, 
  StyledFooter,
  FooterContainer,
  StyledExportButton,
} from './Form5Footer.styled'

const Form5Footer = () => {
  const { t } = useTranslation(); 
  const { id } = useParams();
  const { formStore, authStore } = useRootStore();

  const [disableButton, setDisableButton] = useState(true);

  const [displayPDFModal, setDisplayPDFModal] = useState(false);
  const handleOpenPDFModal = () => setDisplayPDFModal(true);
  const handleClosePDFModal = () => setDisplayPDFModal(false);

  const [displayModal, setDisplayModal] = useState(false);
  const handleOpenModal = () => setDisplayModal(true);
  const handleCloseModal = () => setDisplayModal(false);

  const currentRole = authStore.roles[0];
  const isPublic = currentRole === roleNamesConst.PUBLIC;
  
  useEffect(() => {
    if (formStore.sectionsStatus) {
      setDisableButton(!allTrue(formStore.sectionsStatus) || (formStore.formStatus !== f5statusNamesConst.DRAFT));
    }
  }, [formStore.sectionsStatus, formStore.formStatus])

  
  function allTrue(obj)
  {
    for(var o in obj) {
      if(obj[o] === "INCOMPLETE" || obj[o] === "NEW") return false;
    }
    return true;
  }


  return (
    <>
      <SendDialog 
        displayDialog={displayModal}
        title={t('modal_title')} 
        onCloseModal={handleCloseModal} 
      />

      <PDFGenerationModal
        show={displayPDFModal}
        onClose={handleClosePDFModal}
        initiativeId={id}
        formId={5}
      />

      <FooterContainer>
        <StyledFooter>
          <StyledExportButton onClick={handleOpenPDFModal}>
            {t('pdf_gen_export')}
          </StyledExportButton>
          {isPublic && (
            <StyledSendButton 
              disabled={disableButton} 
              onClick={handleOpenModal}
            >
              {t('F5_Button_send_evaluation')}
            </StyledSendButton>
          )}
        </StyledFooter> 
      </FooterContainer>
    </>
  )
}

export default observer(Form5Footer);