import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import Proptypes from "prop-types";
import { getIn, FieldArray } from "formik";
import { addBeneficiaryCountry } from "services/initiative";
import { validationSchema } from "./DirectBeneficiaryForm.validations";
import Modal from "react-bootstrap/Modal";
import {
  RoundedRemoveDiv,
  RoundedAddDiv,
  StyledButtonLink,
  DivButtonWrapper,
} from "../Section10.styled";
import { ErrorButton } from "components/Buttons";
import { StyledSendButton } from "components/FormContainer/FormContainer.styled";
import CustomModal from "components/Modal";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import {
  SeparatorDotted,
  CenteredModalButtonContainer,
} from "components/CommonStyled";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const countryObject = {
  countryId: "4",
  quantity: 0,
};

const CountryRow = ({ formikInstance, rowIndex, removeCountry, countries }) => {
  const { t } = useTranslation();
  const { getFieldProps, touched, errors } = formikInstance;
  const fieldPath = (field) => `items.${rowIndex}.${field}`;

  const fieldInfo = (formikInstance, field) => {
    const getFieldError = getIn(formikInstance.errors, field);
    const getFieldTouched = getIn(formikInstance.touched, field);
    return {
      touched: getFieldTouched,
      error: (
        <FormErrorMessage
          touched={getFieldTouched}
          errors={getFieldError}
        />
      )
    };
  };

  return (
    <Form.Group as={Row}>
      <Col xs={7}>
        <Form.Control
          name={fieldPath("countryId")}
          as="select"
          {...getFieldProps(fieldPath("countryId"))}
          className={
            touched[fieldPath("countryId")] && errors[fieldPath("countryId")]
              ? "error"
              : null
          }
        >
          <option value="">{t("Placeholder_select")}</option>
          {countries.map((country) => (
            <option key={country.id} value={country.id}>
              {t(country.name)}
            </option>
          ))}
        </Form.Control>
        {fieldInfo(formikInstance, fieldPath("countryId")).error}
      </Col>
      <Col xs={3}>
        <Form.Control
          type="number"
          name={fieldPath("quantity")}
          {...getFieldProps(fieldPath("quantity"))}
          className={
            touched[fieldPath("quantity")] && errors[fieldPath("quantity")]
              ? "error"
              : null
          }
        />
        {fieldInfo(formikInstance, fieldPath("quantity")).error}
      </Col>
      <Col xs={1}>
        <StyledButtonLink
          type="button"
          onClick={() => {
            removeCountry(rowIndex);
          }}
        >
          <RoundedRemoveDiv>x</RoundedRemoveDiv>
        </StyledButtonLink>
      </Col>
    </Form.Group>
  );
};

const DirectBeneficiaryForm = ({
  countries,
  show,
  content,
  initiativeId,
  onHide,
  onSetBenList,
}) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(content.list);
  const [disabled, setDisabled] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
    type: "default",
  });
  
  useEffect(() => {
    if (formValues) {
      setDisabled(false);
      const array = [...formValues];
      array.forEach((e) => {
        if (!e.countryId) {
          e.countryId = e.country ? e.country.id : "";
        }
      });
      setFormValues(array);
    }
  }, [content.list]);

  const onHideModal = () => {
    setModal({ ...modal, isOpen: false });
    onHide();
  };

  const handleSubmit = async (values) => {
    try {
      const response = await addBeneficiaryCountry(
        initiativeId,
        values.items,
        content.id
      );
      onSetBenList(response.data.benList);
      setDisabled(true);
      onHide();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered animation={false}>
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={{ items: formValues }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} id="DirectBeneficiary">
              {modal.isOpen && (
                <CustomModal
                  show={modal.isOpen}
                  onHide={onHideModal}
                  message={modal.message}
                  type={modal.type}
                />
              )}

              <FieldArray
                name="items"
                render={(list) => (
                  <>
                    <ListGroup>
                      {props.values.items
                        ? props.values.items.map((country, key) => (
                            <ListGroup.Item key={key + 1}>
                              <CountryRow
                                formikInstance={props}
                                rowIndex={key}
                                removeCountry={list.remove}
                                countries={countries}
                              />
                            </ListGroup.Item>
                          ))
                        : null}
                    </ListGroup>

                    <SeparatorDotted />
                    <StyledButtonLink
                      type="button"
                      onClick={() => list.push(countryObject)}
                    >
                      <DivButtonWrapper>
                        <RoundedAddDiv>+</RoundedAddDiv>
                        {t("Add_country")}
                      </DivButtonWrapper>
                    </StyledButtonLink>
                  </>
                )}
              />

              <CenteredModalButtonContainer>
                <ErrorButton
                  onClick={() => {
                    onHide();
                  }}
                >
                  {t("Button_cancel")}
                </ErrorButton>
                <StyledSendButton
                  type="submit"
                  form="DirectBeneficiary"
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    marginLeft: "20px",
                  }}
                  disabled={disabled}
                >
                  {disabled ? t("Button_saved") : t("Button_save")}
                </StyledSendButton>
              </CenteredModalButtonContainer>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

DirectBeneficiaryForm.propTypes = {
  show: Proptypes.bool,
  onHide: Proptypes.func,
};

export default observer(DirectBeneficiaryForm);
