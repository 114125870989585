import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { formatDateDDMMYY } from 'helpers/dateHelpers';

import ActivityItem from './ActivityItem';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody 
} from '../CommonStyles'
import MoneyTextMask from 'components/MoneyTextMask';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {
  StyledBudgetDiv,
  StyledNoticeParagraph,
  BlackSpan,
} from 'components/Forms/Form5/Section7/Section7.styled';
import FakeAccordionItem from '../../FakeAccordionItem';
export const initialValues = {
  activities: [],
  estFromDate: null,
  estToDate: null,
  ueBudget: 0.00,
  allianceBudget: 0.00,
  totalBudget: 0.00
};

const Section7 = ({ data }) => {
  const {t} = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data]);

  const getActivityName = (activity) => {
    const activityTitle = t('F1_S7_Accordion');
    const activityName = activity.name;
    const activityType = `- ${activity.activityType? t(`activity_types_${activity.activityType.id}`) : ' '} (${activity.fromDate? formatDateDDMMYY(activity.fromDate): " "})  `;
    const activityState = activity.activityState ? `[${t(activity.activityState.name)}]` : '';
    return `${activityTitle}  ${activityName}  ${activityType}  ${activityState}`;
  };

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`7 - ${t('F3_S7_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <div style={{paddingLeft: '30px'}}>
          {(formValues.activities && formValues.activities.map((activity, key) => (
            <React.Fragment key={key}>
              <FakeAccordionItem title={getActivityName(activity)}>
                <div style={{marginLeft: '50px', marginBottom: '30px'}}>
                  <ActivityItem activity={activity} /> 
                </div>
              </FakeAccordionItem>
            </React.Fragment>
          )))}
        </div>

        <StyledBudgetDiv style={{marginLeft: '50px'}}>
          <Form.Group as={Row}>
            <Col xs={4}>
              {t('F3_S8_Start_day')}:
            </Col>
            <Col xs={2}>
              <BlackSpan>{formValues.estFromDate? formatDateDDMMYY(formValues.estFromDate): ' - '} </BlackSpan>
            </Col>
            <Col xs={4}>
              {t('F3_S8_End_day')}:
            </Col>
            <Col xs={1}>
              <BlackSpan>{formValues.estToDate? formatDateDDMMYY(formValues.estToDate): ' - '} </BlackSpan>
            </Col>
          </Form.Group>
      
          <Form.Group as={Row} style={{alignItems:"center"}}>
            <Form.Label column xs={5}>{t('F1_S7_EU_budget')}:</Form.Label>
            <Col xs={3}>
              <InputGroup>
                <BlackSpan>
                  <MoneyTextMask
                    value={formValues.ueBudget}
                    disabled={true}
                    customInput={Form.Control}
                  />
                </BlackSpan>
              </InputGroup>
            </Col>
          </Form.Group>

          <Form.Group as={Row} style={{alignItems:"center"}}>
            <Form.Label column xs={5}>{t('F1_S7_counterpart_budget')}:</Form.Label>
            <Col xs={3}>
              <InputGroup>
                <BlackSpan>
                  <MoneyTextMask
                    value={formValues.allianceBudget}
                    disabled={true}
                    customInput={Form.Control}
                  />
                </BlackSpan>
              </InputGroup>
            </Col>
          </Form.Group>

          <Form.Group as={Row} style={{alignItems:"center"}}>
            <Form.Label column xs={5}>{t('F5_S7_Total_amount_executed')}:</Form.Label>
            <Col xs={3}>
              <InputGroup>
                <BlackSpan>
                  <MoneyTextMask
                    value={formValues.totalBudget}
                    disabled={true}
                    customInput={Form.Control}
                  />
                </BlackSpan>
                </InputGroup>
            </Col>
          </Form.Group>
          
          {(
            (formValues.totalBudget > 0) && 
            (formValues.ueBudget <= formValues.totalBudget) && 
            (
              (formValues.ueBudget > 75000) || 
              (formValues.ueBudget > (0.75 * formValues.totalBudget)) 
            ) 
          ) &&
            <StyledNoticeParagraph>
              {t('F1_S7_budget_foot_note')}
            </StyledNoticeParagraph>
          }
        </StyledBudgetDiv>
      </StyledBody>
    </SectionContainer>
  )
}

export default observer(Section7);
