import * as Yup from 'yup';
import i18n from 'i18n';

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

const urlReg = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

export const validationSchema = Yup.object().shape({
  entityName: Yup.string()
    .min(2, 'F1_S2_validation1')
    .max(200, 'F1_S2_validation2'),
  acronym: Yup.string()
    .min(2, 'F1_S2_validation3')
    .max(100, 'F1_S2_validation4'),
  entityTypeId: Yup.string()
    .min(1, 'F1_S2_validation5'),
  role: Yup.string()
    .oneOf(["","0","1","2"]),
  optionA: Yup.boolean(),
  optionB: Yup.boolean(),
  optionC: Yup.boolean(),
  optionD: Yup.boolean(),
  other: Yup.string()
    .min(5, 'F1_S2_validation6')
    .max(100, 'F1_S2_validation7'),
  address: Yup.string()
    .min(5, 'F1_S2_validation9')
    .max(100, 'F1_S2_validation10'),
  cityName: Yup.string()
    .min(3, 'F1_S2_validation11')
    .max(100, 'F1_S2_validation12'),
  stateName: Yup.string()
  .min(3, 'F1_S2_validation13')
  .max(100, 'F1_S2_validation14'),
  countryId: Yup.string(),
  web: Yup.string()
    .min(5, 'F1_S2_validation6')
    .max(200, 'F1_S2_validation8')
    .matches(urlReg, 'F1_S2_validation15'),
  firstNameTciApplicant: Yup.string()
    .min(2, 'F1_S2_validation16')
    .max(200, 'F1_S2_validation17'),
  lastNameTciApplicant: Yup.string()
    .min(2, 'F1_S2_validation16')
    .max(200, 'F1_S2_validation17'),
  positionResp: Yup.string()
    .min(2, 'F1_S2_validation18')
    .max(100, 'F1_S2_validation19'),
  phoneResp: Yup.string()
    .min(2, 'F1_S2_validation20')
    .max(100, 'F1_S2_validation21')
    .matches(phoneRegExp, 'F1_S2_validation22'),
  triangulardoc: Yup.object().nullable(),
  firstNameSigner: Yup.string()
    .min(2,  'F1_S2_validation16')
    .max(200, 'F1_S2_validation17'),
  lastNameSigner: Yup.string()
    .min(2,  'F1_S2_validation16')
    .max(200, 'F1_S2_validation17'),
  positionSigner: Yup.string()
    .min(2, 'F1_S2_validation18')
    .max(100, 'F1_S2_validation19'),
  emailSigner: Yup.string()
    .email('F1_S2_validation23')
    .max(100, 'F1_S2_validation24'),
  phoneSigner: Yup.string()
    .min(2, 'F1_S2_validation20')
    .max(100, 'F1_S2_validation21')
    .matches(phoneRegExp, 'F1_S2_validation22'),
});
