import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { validationSchema } from './Validations';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { saveFundSection } from 'services/funds';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import Form from 'react-bootstrap/Form'
import { FormFielErrorMessage, RequiredMark } from 'components/CommonStyled';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const formInitialValues = {
  challenge: "",
  objective: "",
  knowledge: ""
};

const Section6 = () => {
  const {t} = useTranslation();

  const { formStore } = useRootStore();
  const { id } = useParams();

  const [saved, setSaved] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    let newValues = formInitialValues;
    if (formStore.formSectionData) {
      newValues = {
        challenge: formStore?.formSectionData?.[0]?.challenge,
        objective: formStore?.formSectionData?.[0]?.objective,
        knowledge: formStore?.formSectionData?.[0]?.knowledge
      }
    }
    setInitialValues(newValues);
  }, [formStore.formSectionData])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);
  }, [saved])

  const onSubmit = async (values, {resetForm}) => {
    try {
      const { complete } = await saveFundSection(id, 6, values);
      formStore.setSectionStatus(6, complete)
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid
      ? onSubmit(values, { resetForm })
      : alert(t('not_saved_alert'));
  }

  return (
    <FormLayout>
      <FormContainer
        number={6}
        title={t('F55_S6_title')}
        formId="form55section6"
        showSavedMessage={saved}
        showSaveButton
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {( {values,
              errors,
              touched,
              dirty,
              resetForm,
              handleChange,
              handleBlur,
              isValid }) => (
                <form
                  onSubmit={(e) => {onValidateSubmit(e, isValid, values, { resetForm })}}
                  id="form55section6"
                >
                  {dirty && (setSaved(false))}

                  <Form.Group style={{marginLeft: '50px', paddingTop: '10px'}}>
                    <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}}>
                      {t('Funds_S6_challenge')}
                      <RequiredMark>*</RequiredMark>:
                    </Form.Label>
                    <Form.Control
                      name="challenge"
                      as="textarea"
                      rows={4}
                      placeholder={t('Maximum_2500')}
                      style={{resize: 'none'}}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.challenge}
                      className={touched.challenge && errors.challenge ? "error" : null}
                    />
                    <FormErrorMessage
                      touched={touched.challenge}
                      errors={errors.challenge}
                    />
                  </Form.Group>

                  <Form.Group style={{marginLeft: '50px', paddingTop: '10px'}}>
                    <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}}>
                      {t('Funds_S6_objective')}
                      <RequiredMark>*</RequiredMark>:
                    </Form.Label>
                    <Form.Control
                      name="objective"
                      as="textarea"
                      rows={4}
                      placeholder={t('Maximum_350')}
                      style={{resize: 'none'}}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.objective}
                      className={touched.objective && errors.objective ? "error" : null}
                    />
                    <FormErrorMessage
                      touched={touched.objective}
                      errors={errors.objective}
                    />
                  </Form.Group>

                  <Form.Group style={{marginLeft: '50px', paddingTop: '10px'}}>
                    <Form.Label style={{alignItems: "center", textAlign: 'justify', lineHeight: '1.3'}}>
                      {t('Funds_S6_knowledge')}
                      <RequiredMark>*</RequiredMark>:
                    </Form.Label>
                    <Form.Control
                      name="knowledge"
                      as="textarea"
                      rows={4}
                      placeholder={t('Maximum_2500')}
                      style={{resize: 'none'}}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.knowledge}
                      className={touched.knowledge && errors.knowledge ? "error" : null}
                    />
                    <FormErrorMessage
                      touched={touched.knowledge}
                      errors={errors.knowledge}
                    />
                  </Form.Group>
                </form>
              )}
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section6);

