import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Header1 from '../components/Header1'
import Table1 from '../components/Table1'

import {
  StyledDiv,
  StyledRow,
  StyledRow3,
} from '../../CommonStyled/Tabs.styled'
import Row from 'react-bootstrap/Row'

import { getBeneficiariesDetail } from 'services/initiative'

import { getTotalBeneficiaries } from '../helpers/helpers'

const DirectBeneficiariesDetail = () => {
  const { t } = useTranslation()
  const [data, setData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const getData = async (param) => {
    setWaiting(true)
    try {
      const response = await getBeneficiariesDetail(param)
      setData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setWaiting(false)
    }
  }

  useEffect(() => {
    getData({})
  }, [])

  return (
    <StyledDiv>
      <Row>
        <Header1 data={data} getData={getData} waiting={waiting} />
      </Row>
      {data?.length ? (
        <StyledRow3>
          <b>{`${t('total_direct_beneficiaries')}: ${getTotalBeneficiaries(
            data,
          )}`}</b>
        </StyledRow3>
      ) : null}
      <StyledRow style={{ marginTop: '-72px' }}>
        <Table1 data={data} waiting={waiting} setData={setData} />
      </StyledRow>
    </StyledDiv>
  )
}

export default DirectBeneficiariesDetail
