import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  comment1: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(650, i18n.t('F1_S6_validation9'))
    .nullable(true),
  comment2: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(650, i18n.t('F1_S6_validation9'))
    .nullable(true),
  recommendation: Yup.string()
    .min(2, i18n.t('F1_S2_validation1'))
    .max(650, i18n.t('F1_S6_validation9'))
    .nullable(true),
});