import styled, { css } from 'styled-components'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { FormGroup, FormLabel } from 'react-bootstrap'

export const FullRow = styled(Row)`
  width: 100%;
  margin: 0px 0px 10px 0px;
  justify-content: space-between;
`
export const StyledRow = styled(Row)`
  width: 100%;
  margin: 10px 15px 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Col1 = styled(Col)`
  display: flex;
  align-items: center;
`
export const Col2 = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 0px;
  width: 60%;
`
export const StyledFormGroup2 = styled(FormGroup)`
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 0px;
  width: 22%;
`
export const StyledFormLabel = styled(FormLabel)`
  margin-right: 20px;
  margin-bottom: 0px;
  white-space: nowrap;
`
export const Th2 = styled.th`
  background: rgb(194, 222, 177);
  border: 1px solid black;
  padding: 5px;
`
export const Th1 = styled(Th2)`
  text-align: center;
`
export const Th1Pointer = styled(Th1)`
  cursor: pointer;
`

export const Th4 = styled(Th2)`
  text-align: end;
  padding-right: 20px;
`
export const Th5 = styled.th`
  background: #a4b4bc;
  border: 1px solid black;
  padding: 5px;
`

export const Th3 = styled(Th2)`
  background: none;
  border-left: 1px solid white;
  border-top: 1px solid white;
  text-align: center;
`
export const Th3Pointer = styled(Th3)`
  cursor: pointer;
`
export const Td = styled.td`
  text-align: center;
  border: 1px solid black;
  padding: 5px;
`
export const Td2 = styled(Td)`
  background: #a6b3bb;
`
export const Table = styled.table`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.gray[1]};
    border-collapse: collapse;
    width: 100%;
  `}
`

export const CompleteImage = styled.img`
  margin-bottom: 5px;
  height: auto; 
  width: auto; 
  max-width: 30px; 
  max-height: 30px;
`;