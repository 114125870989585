import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
  overviewRelationship: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  overviewTriangular: Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  overviewEuropean:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  valueAddedTCI:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  contributionBeneficiaryEntities:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  benefitsForEntitiesAdditional:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  benefitsForEntitiesProvider:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  valuePartnershipItself:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  ownership:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  complementarity:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  sharingKnowledge:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  jointCreation:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  increasingVolume:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  globalAndRegional:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
  coordinationEU:  Yup.string()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F3_S5_validation2')
    .nullable(true),
});