import React from 'react';

import { StyledTextValue } from './TextValue.styled'

const TextValue = ({ value }) => (
  <StyledTextValue>
    <span style={{ whiteSpace: "pre-wrap", lineHeight: '1.3', lineBreak: 'anywhere' }}>{value ? value : '-'}</span>
  </StyledTextValue>
);

export default TextValue;