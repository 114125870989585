import React from 'react'
import { useTranslation } from 'react-i18next';

import MoneyTextMask from 'components/MoneyTextMask';
import Table from 'react-bootstrap/Table';
import {
  TableHeader,
  RowBorder,
  BorderlessTr,
  MarginlessTd,
  ResourceNameSpan,
  ResourceNumberSpan,
  BudgetLineButton,
  StyledButtonLink,
  RoundedRemoveDiv
} from './CustomBudgetDetailsTable.styled';

const CustomBudgetDetailsTable = ({ items, isOpenable, hasRemoveButton = false, nonEditable, handleOpenBudgetLine, handleRemoveButton }) => {
  const { t } = useTranslation();

  const renderTableRows = () => {
    return items.map(detail => (
      <>
        <BorderlessTr key={"bord" + detail.id}>
          <td colSpan="4">
            <RowBorder />
          </td>
        </BorderlessTr>
        <BorderlessTr key={detail.id}>
          <MarginlessTd>
            <ResourceNameSpan>
              {
                isOpenable
                ?
                  <BudgetLineButton 
                    variant="link" 
                    onClick={() => {
                      detail.resourceTypeId = detail.resourceType.id;
                      handleOpenBudgetLine(detail);
                    }}
                  >
                    {detail.resourceType? t(`resource_type_${detail.resourceType.id}`): ' - '}
                  </BudgetLineButton>
                :
                  <span> {detail.resourceType? t(`resource_type_${detail.resourceType.id}`): ' - '} </span>
              }
            </ResourceNameSpan>
          </MarginlessTd>
          <MarginlessTd><ResourceNumberSpan> <MoneyTextMask value={detail.ueOrCounterpart == 0? detail.totalMoney: 0} /> </ResourceNumberSpan></MarginlessTd>
          <MarginlessTd><ResourceNumberSpan> <MoneyTextMask value={detail.ueOrCounterpart == 1? detail.totalMoney: 0} /> </ResourceNumberSpan></MarginlessTd>
          <MarginlessTd><ResourceNumberSpan> <MoneyTextMask value={detail.totalMoney} />  </ResourceNumberSpan></MarginlessTd>
          { hasRemoveButton && <MarginlessTd>{!nonEditable && <StyledButtonLink type="button" onClick={() => {handleRemoveButton(detail.id)}} > <RoundedRemoveDiv>x</RoundedRemoveDiv> </StyledButtonLink>}</MarginlessTd>}
        </BorderlessTr>
        <BorderlessTr key={"lowBord" + detail.id}>
          <MarginlessTd colSpan="4">
            <div style={{overflowWrap: 'break-word', wordWrap: 'break-word', maxWidth:'135vh'}}> {detail.description}</div>
          </MarginlessTd>
        </BorderlessTr>
      </>
    ))
  }

  return (
    <Table bordered={ false }>
      <thead>
        <BorderlessTr>
          <TableHeader width={"45"}></TableHeader>
          <TableHeader width={"15"}>{t('F3_S7_Resource_column_EU')}</TableHeader>
          <TableHeader width={"15"}>{t('F3_S7_Resource_column_counterpart')}</TableHeader>
          <TableHeader width={"15"}>{t('F3_S7_Resource_column_total')}</TableHeader>
          {hasRemoveButton && <TableHeader width={"15"}></TableHeader>}
        </BorderlessTr>
      </thead>
      <tbody>
        {renderTableRows()}
      </tbody>
    </Table>
  )
}

export default CustomBudgetDetailsTable;