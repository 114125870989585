import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from 'services/auth';

const PrivateRoute = ({
  isAuth = false,
  component: Component, 
  layout: Layout,
  ...rest 
}) => {
  const isLoggedIn = !!getToken();
  return(
    <Route {...rest} render={props => (
      isLoggedIn
        ? <Layout>
            <Component {...props} />
          </Layout>
        : <Redirect to="/login" />
    )} />
  );
}

export default PrivateRoute;
