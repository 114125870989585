import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTitle, StyledSubtitle } from '../ExpostReportForm.styles';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useFormikContext } from 'formik';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const Markers = ({ nonEditable }) => {
  const {t} = useTranslation();
  const {  touched, errors, values, handleBlur, handleChange } = useFormikContext();

  useEffect(() => {
    const isLevelChecked = !!values?.assessment4;
    document.getElementById('LevelYesRadio').checked = typeof values?.assessment4 === 'boolean' && isLevelChecked;
    document.getElementById('LevelNoRadio').checked = typeof values?.assessment4 === 'boolean' && !isLevelChecked;
  }, [values]);

  return (
    <div style={{marginBottom: '100px'}}>
       <Form.Group style={{marginLeft: '15px'}}>
        <Row>
          <Form.Label>{t('F8b_assessment_4')}:</Form.Label>
          <label>
            <input
              id="LevelYesRadio"
              type="radio"
              name="assessment4"
              value={"true"}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{marginLeft: '30px'}}
              disabled={nonEditable}
            />
            <span style={{marginLeft: '5px'}}> {t('Yes')} </span>
          </label>
          <label>
            <input
              id="LevelNoRadio"
              type="radio"
              name="assessment4"
              value={"false"}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={handleBlur}
              style={{marginLeft: '50px'}}
              disabled={nonEditable}
            />
            <span style={{marginLeft: '5px'}}> {t('No')} </span>
          </label>
        </Row>
        <div style={{marginLeft: '0'}}>
          <FormErrorMessage
            touched={touched.level}
            errors={errors.level}
          />
        </div>
      </Form.Group>

      <StyledTitle style={{marginTop: '16px'}}>{t('F8b_assessment_title_5')}:</StyledTitle>
      <StyledSubtitle style={{marginBottom: '16px'}}>{t('F8b_assessment_subtitle_5')}:</StyledSubtitle>

      <Form.Check
        name="assessment5"
        type="checkbox"
        checked={values.assessment5}
        value={values.assessment5}
        onChange={handleChange}
        label={t('F8b_assessment_5')}
        disabled={nonEditable}
      />

      <Form.Check
        name="assessment6"
        type="checkbox"
        checked={values.assessment6}
        value={values.assessment6}
        onChange={handleChange}
        label={t('F8b_assessment_6')}
        disabled={nonEditable}
      />

      <Form.Check
        name="assessment7"
        type="checkbox"
        checked={values.assessment7}
        value={values.assessment7}
        onChange={handleChange}
        label={t('F8b_assessment_7')}
        disabled={nonEditable}
      />

      <Form.Check
        name="assessment8"
        type="checkbox"
        checked={values.assessment8}
        value={values.assessment8}
        onChange={handleChange}
        label={t('F8b_assessment_8')}
        disabled={nonEditable}
      />

      <StyledTitle style={{marginTop: '16px'}}>{t('F8b_assessment_title_9')}:</StyledTitle>

      <Form.Check
        name="assessment9"
        type="checkbox"
        checked={values.assessment9}
        value={values.assessment9}
        onChange={handleChange}
        label={t('F8b_assessment_9')}
        disabled={nonEditable}
      />


      <Form.Check
        name="assessment10"
        type="checkbox"
        checked={values.assessment10}
        value={values.assessment10}
        onChange={handleChange}
        label={t('F8b_assessment_10')}
        disabled={nonEditable}
      />


      <Form.Check
        name="assessment11"
        type="checkbox"
        checked={values.assessment11}
        value={values.assessment11}
        onChange={handleChange}
        label={t('F8b_assessment_11')}
        disabled={nonEditable}
      />

      <StyledTitle style={{marginTop: '16px'}}>{t('F8b_assessment_title_12')}:</StyledTitle>
      <StyledSubtitle style={{marginBottom: '16px'}}>{t('F8b_assessment_subtitle_12')}:</StyledSubtitle>

      <Form.Check
        name="assessment12"
        type="checkbox"
        checked={values.assessment12}
        value={values.assessment12}
        onChange={handleChange}
        label={t('F8b_assessment_12')}
        disabled={nonEditable}
      />

      <Form.Check
        name="assessment13"
        type="checkbox"
        checked={values.assessment13}
        value={values.assessment13}
        onChange={handleChange}
        label={t('F8b_assessment_13')}
        disabled={nonEditable}
      />

      <Form.Check
        name="assessment14"
        type="checkbox"
        checked={values.assessment14}
        value={values.assessment14}
        onChange={handleChange}
        label={t('F8b_assessment_14')}
        disabled={nonEditable}
      />

      <StyledTitle style={{marginTop: '16px'}}>{t('F8b_assessment_title_15')}:</StyledTitle>


      <Form.Check
        name="assessment15"
        type="checkbox"
        checked={values.assessment15}
        value={values.assessment15}
        onChange={handleChange}
        label={t('F8b_assessment_15')}
        disabled={nonEditable}
      />

    </div>
  )
}

export default Markers;