import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { initialValues } from './constants';
import htmlParser from 'html-react-parser';

import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledNumberCircle, 
  StyledTitle, 
  StyledBody,
} from '../CommonStyles'
import {
  StyledFormLabel,
  Title,
  Separator,
} from 'components/Forms/Form4/Section14/Section14.styled';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import TextValue from 'components/TextValue';
import MediaForm from 'components/Forms/Form4/Section14/MediaForm';
import FakeAccordionItem from '../../FakeAccordionItem';
import { SeparatorDotted, RequiredMark } from 'components/CommonStyled';

const Section14 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data])

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`14 - ${t('F3_S14_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <Form.Group>
          <StyledFormLabel>{htmlParser(t('F1_S8_Objetive'))}:</StyledFormLabel>
          <TextValue value={formValues.objective} />
        </Form.Group>  

        <div style={{textAlign: 'justify', lineHeight: '1.3'}}>
          {htmlParser(t('F3_S14_Subtitle_communication_tools'))}
        </div>
        
        <div style={{marginBottom: '20px'}} />

        {(formValues && formValues.items.map((item, key) => (
          <React.Fragment key={key}>
            <FakeAccordionItem title={`${t('F1_S8_Accordion')} ${key+1}`} noMargin>
              <MediaForm data={item}/> 
            </FakeAccordionItem>
            <SeparatorDotted />
          </React.Fragment>
        )))}
  
        <Separator />
        
        <Title> {t('F3_S14_Subtitle_focal_point')}  </Title> 
        
        <Form.Group as={Row}>
          <Col lg={12}>
            <Form.Check 
              type="checkbox" 
              checked={formValues.samePerson}
              style={{marginTop: "20px", marginBottom: "20px"}}
              label={t('F3_S14_Checkbox_same_person')}
              disabled={true}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column xs={2}>{t('F1_S2_First_name')} <RequiredMark>*</RequiredMark>: </Form.Label>
          <Col xs={3} className="align-self-center">
            <TextValue value={formValues.firstname} />
          </Col>
          <Form.Label className="align-self-center" column xs={2}>{t('F1_S2_Last_Name')} <RequiredMark>*</RequiredMark>: </Form.Label>
          <Col xs={3} className="align-self-center" >
            <TextValue value={formValues.lastname} />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column xs={2}>{t('F1_S2_Email')} <RequiredMark>*</RequiredMark>: </Form.Label>
          <Col xs={3} className="align-self-center" >
            <TextValue value={formValues.email} />        
          </Col>
          <Form.Label className="align-self-center" column xs={2}>{t('F3_S14_Entity')} <RequiredMark>*</RequiredMark>: </Form.Label>
          <Col xs={3} className="align-self-center" >
            <TextValue value={formValues.entityName} />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column lg={2}>{t('F1_S2_Phone_number')}:<RequiredMark>*</RequiredMark></Form.Label>
          <Col lg={4} className="align-self-center" >
            <TextValue value={formValues.phone} />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Col>
            <Form.Check
              type="checkbox" 
              checked={formValues.auth}
              disabled={true}
              label={t('F1_S3_Authorize_user')}
            />
          </Col>
        </Form.Group>
      </StyledBody>
    </SectionContainer>
  )
}


export default Section14;