import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { PrimaryButton } from 'components/Buttons'
import 'react-datepicker/dist/react-datepicker.css'
import {
  SeparatorDotted,
  CenteredModalButtonContainer,
} from 'components/CommonStyled'
import { formatDateDDMMYY } from 'helpers/dateHelpers'
import { renderItemsByType } from '../../Section14/DiffusionMaterial/Constants'
import NewDifussionMaterialForm from '../../Section14/NewDifussionMaterialForm'
import ConfirmCloseModal from 'components/ConfirmCloseModal'
import { f5statusNamesConst, roleNamesConst } from 'constants/appConstants'
import { useRootStore } from 'stores'
import _isEmpty from 'lodash/isEmpty'
import { deleteF5S14Material } from 'services/initiative'
import { useParams } from 'react-router-dom'
import axiosInstance from 'services/axiosInstance'
import CustomModal from 'components/Modal'

const DifussionMaterialsForm = ({
  activity,
  handleCloseOk,
}) => {
  const { t } = useTranslation()

  const { id } = useParams()

  const { initiativeStore } = useRootStore()

  const [nonEditable, setNonEditable] = useState(true)
  const [materials, setMaterials] = useState([]);

  const [difussionMaterialFormModal, setDifussionMaterialFormModal] = useState({
    show: false,
    isEdit: false,
    data: null,
  })

  const [closingModalInfo, setClosingModalInfo] = useState({
    isOpen: false,
    text: '',
    action: null,
  })

  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    message: t('Error try again later'),
    type: 'error',
  })

  useEffect(() => {
    getSection14Info()
  }, [])

  const getSection14Info = async () => {
    const url = `/api/initiative/${id}/form/F5/section/14`
    try {
      const response = await axiosInstance.get(url)
      const responseMaterials = response?.data?.section?.[0]?.materials ?? [];
      const filteredMaterials = responseMaterials?.filter(item => item?.activity?.id === activity?.id);
      setMaterials(filteredMaterials)
    } catch (error) {
      throw error
    }
  };

  useEffect(() => {
    setNonEditable(
      initiativeStore.formStateName === f5statusNamesConst.FINALIZED ||
        initiativeStore.formStateName === f5statusNamesConst.CANCELLED ||
        initiativeStore.role !== roleNamesConst.APPLICANT,
    )
  }, [initiativeStore.formStateName])


  const handleDelete = async (materialId) => {
    try {
      const response = await deleteF5S14Material(id, materialId)
      const responseMaterials = response?.materials ?? [];
      const filteredMaterials = responseMaterials?.filter(item => item?.activity?.id === activity?.id);
      setMaterials(filteredMaterials)
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <div>
      <ConfirmCloseModal
        show={closingModalInfo.isOpen}
        handleCloseConfirm={() =>
          setClosingModalInfo({
            isOpen: false,
            text: '',
            action: null,
          })
        }
        handleActionOnConfirm={closingModalInfo.action}
        text={closingModalInfo.text}
      />

      {modalInfo.isOpen && (
        <CustomModal
          show={modalInfo.isOpen}
          onHide={() => setModalInfo({ ...modalInfo, isOpen: false })}
          message={modalInfo.message}
          type="error"
        />
      )}

      {difussionMaterialFormModal.show ? (
        <NewDifussionMaterialForm
          handleCloseOk={() => {
            setDifussionMaterialFormModal({
              show: false,
              isEdit: false,
              data: null,
            })
            getSection14Info()
          }}
          handleClose={() =>
            setClosingModalInfo({
              isOpen: true,
              text: t('Confirmation close modal'),
              action: () =>
                setDifussionMaterialFormModal({
                  show: false,
                  isEdit: false,
                  data: null,
                }),
            })
          }
          handleCloseOnError={() => {
            setDifussionMaterialFormModal({
              show: false,
              isEdit: false,
              data: null,
            })
          }}
          nonEditable={nonEditable}
          setMaterialList={setMaterials}
          show={difussionMaterialFormModal.show}
          activities={[]}
          fixedActivity={{ id: activity?.id, name: activity?.name}}
          isEdit={difussionMaterialFormModal.isEdit}
          data={difussionMaterialFormModal.data}
          setModalInfo={setModalInfo}
        />
      ) : null}
      <div>
        {t('F1_S7_Accordion') + '  '}
        {activity?.name + '  '}
        {`- ${
          activity?.activityType
            ? t(`activity_types_${activity?.activityType.id}`)
            : ' '
        } (${
          activity?.fromDate ? formatDateDDMMYY(activity?.fromDate) : ' '
        })`}
      </div>
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Col>
            {`${t('F1_S7_type')}: ${
              activity?.activityType
                ? t(`activity_types_${activity?.activityType.id}`)
                : '-'
            }`}
          </Col>
          <Col>
            {`${t('F1_S7_start_day')}: ${
              activity?.fromDate
                ? formatDateDDMMYY(activity?.fromDate)
                : '-'
            }`}
          </Col>
          <Col>
            {`${t('F1_S7_End_day')}: ${
              activity?.toDate ? formatDateDDMMYY(activity?.toDate) : '-'
            }`}
          </Col>
        </Row>
        <Row>
          <Col>
            {`${t('F1_S7_Format')}: ${
              activity?.format === 0
                ? t('F1_S7_Format0')
                : activity?.format === 1
                ? t('F1_S7_Format1')
                : t('F1_S7_Format2')
            }`}
          </Col>
          <Col>
            {`${t('F1_S7_Country_activity')}: ${activity?.cityName ?? ''} ${activity?.country?.name ?? ''}`}
          </Col>
        </Row>
        <SeparatorDotted />
        {renderItemsByType(_isEmpty(materials) ? [] : materials, setDifussionMaterialFormModal, setClosingModalInfo, handleDelete, setModalInfo)}
        <div style={{ marginTop: '20px' }}/>
        <PrimaryButton
          onClick={() =>
            setDifussionMaterialFormModal({
              show: true,
              isEdit: false,
              data: null,
            })
          }
        >
          {t('F5_S14_add_difussion_material_button')}
        </PrimaryButton>
      </div>

      <CenteredModalButtonContainer>
        <PrimaryButton onClick={handleCloseOk}> OK </PrimaryButton>
      </CenteredModalButtonContainer>
    </div>
  )
}

export default DifussionMaterialsForm
