import React, { useState } from 'react';
import DayJS from 'dayjs'

import { expostStatesConst } from 'constants/appConstants';

import F9ADialog from '../F9ADialog'
import {formInitialValues} from '../EudForm/EudForm.initialValues'
import {
  StyledAccordionBodyDiv,
  StyledEUDLink,
} from './EUDAssessments.styled';
import { SeparatorDotted } from 'components/CommonStyled'
import { StyledTitle } from '../../../../components/Forms/Form4/Section15/Section15.styled';
import { useTranslation } from 'react-i18next';
import { formatDateDDMMYY } from 'helpers/dateHelpers';

const EUDAssessments = ({  F9AValues, F9CValues, initiativeCode }) => {
  
  const { t } = useTranslation();
  const [currentF9AValues, setCurrentF9AValues] = useState(formInitialValues);
  const [isINTPAAssessment, setIsINTPAAssessment] = useState(false)

  const [displayModalF4A, setDisplayModalF4A] = useState(false);
  const handleOpenModalF4A = () => setDisplayModalF4A(true);
  const handleCloseModalF4A = () => {
    setDisplayModalF4A(false);
    setIsINTPAAssessment(false)
  }

  const handleClick = (f9a, intpa) => {
    setIsINTPAAssessment(!!intpa)
    setCurrentF9AValues(f9a);
    handleOpenModalF4A();
  }

  const renderF9AValues = () => {
    return F9AValues?.map(F9A => (
      <div>
        {`${F9A.user.organization}, ${F9A.user.name} ${F9A.user.lastName} - `}
        {(F9A?.status?.stateEnum === expostStatesConst.DONE)
          ?
          <StyledEUDLink key={F9A.id} value={F9A.id} onClick={() => { handleClick(F9A) }}>
            {t('Received_on')+" "+((F9A && F9A.statusTimestamp) ? formatDateDDMMYY(F9A.statusTimestamp) : '-')}
          </StyledEUDLink>
          :
          t('Pending')
        }
      </div>
    ))
  }

  return (
    <>
      { displayModalF4A && <F9ADialog
        values={currentF9AValues}
        displayDialog={displayModalF4A}
        onCloseModal={handleCloseModalF4A}
        initiative={initiativeCode}
        isINTPAAssessment={isINTPAAssessment}
      />}

      <StyledTitle>{t('F2_EUDs_assessments')}</StyledTitle>

      <StyledAccordionBodyDiv>
        {renderF9AValues()}

        <div style={{ width: '400px' }}>
          <SeparatorDotted />
            
          {`INTPA - `}
          {
            F9CValues && (F9CValues?.status?.stateEnum === expostStatesConst.DONE)
              ?
              <StyledEUDLink onClick={() => { handleClick(F9CValues, true) }}>
                {t('Received_on')+" "+(F9CValues.statusTimestamp) ? formatDateDDMMYY(F9CValues.statusTimestamp) : '-'}
              </StyledEUDLink>
              :
              t('Pending')
          }
            
        </div>
      </StyledAccordionBodyDiv>

    </>
  )
}

export default EUDAssessments;