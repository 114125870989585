import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';

export const StyledFormLabel = styled(Form.Label)`
    text-align: justify;
    line-height: 1.3;
`;

export const StyledSeparator = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 50px;
  height: 2px;
  background-image: linear-gradient(to right, #bac5cc 33%, rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 6px 2px;
  background-repeat: repeat-x;
`;

export const StyledDivWrapper = styled.div`
  margin-left: 50px;
`;

export const DescripcionDiv = styled.div`
  ${({ theme }) => css`
    padding: 15px;
    border: 2px solid ${theme.colors.gray[3]};
    margin-bottom: 20px;
  `}
`;

