import React from 'react';
import { Route } from 'react-router-dom';

import PassRecoveryConfirm from 'pages/public/PassRecoveryConfirm';
import Profile from 'pages/Profile';
import Login from 'pages/public/Login';
import Register from 'pages/public/Register';
import PassRecovery from 'pages/public/PassRecovery';

const CommonRoutes = () => [
  <Route exact path="/register" component={Register} />,
  <Route exact path="/login" component={Login} />,
  <Route exact path="/passrecovery" component={PassRecovery} />,
  <Route exact path="/passrecoveryconfirm" component={PassRecoveryConfirm} />,
  <Route exact path="/profile" component={Profile} />,
];

export default CommonRoutes;
