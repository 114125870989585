import React from 'react';
import { Link } from 'react-router-dom';
import { useRootStore } from 'stores';
import { formatDateDDMMYY } from 'helpers/dateHelpers';
import { useTranslation } from 'react-i18next';

import { StyledTr, StyledSpan } from './TableRow.styled';
import NewMessagesTableIndicator from 'components/NewMessagesTableIndicator';
import { NewMessagesTableContainer } from 'components/Forms/Forms.styled';

const TableRow = ({ item }) => {
  const { t } = useTranslation();
  const { initiativeStore, authStore } = useRootStore();
  const todayDate = new Date();
  const baseRoute = authStore.getBaseRouteByRole();

  const setInitiativeOnStore = initiative => {
    initiativeStore.setInitiativeData(initiative);
  };

  return (
    <StyledTr key={item.id}>
      <td style={{ minWidth: '100px' }}>
        <Link
          onClick={() => setInitiativeOnStore(item)}
          to={`${baseRoute}/initiative/${item.id}/form/5/section/1`}
        >
          {item.code}
        </Link>
      </td>
      <td style={{ maxWidth: '600px', overflowWrap: 'break-word' }}>
        {item.name ? item.name : t('Name_not_defined_yet')}
      </td>
      <td style={{ minWidth: '100px' }}>{t(item.formStateName)}</td>
      <td>
        {item.formDeadline ? (
          <StyledSpan expired={todayDate > new Date(item.formDeadline)}>
            {formatDateDDMMYY(item.formDeadline)}
          </StyledSpan>
        ) : (
          ' - '
        )}
      </td>
      <td style={{ minWidth: '100px' }}>{t(item.assessmentStatus)}</td>
      <td>
        {item.assessmentDeadline ? (
          <StyledSpan expired={todayDate > new Date(item.assessmentDeadline)}>
            {formatDateDDMMYY(item.assessmentDeadline)}
          </StyledSpan>
        ) : (
          ' - '
        )}
      </td>
      <td>
        <NewMessagesTableContainer>
          <NewMessagesTableIndicator hasNewMessages={item.unreadComments > 0} />
        </NewMessagesTableContainer>
      </td>
    </StyledTr>
  );
};

export default TableRow;
