import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CommunicationToolList from 'components/Forms/Form5/Section14/CommunicationToolList';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextValue from 'components/TextValue';
import { StyledFormLabel, Title } from 'components/Forms/Form5/Section14/Section14.styled';
import { RequiredMark } from 'components/CommonStyled';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody 
} from '../CommonStyles'

export const formInitialValues = {
  objective: "",
  samePerson: false,
  firstname: "",
  lastname: "",
  email: "",
  entityName: "",
  phone: "",
  auth: false,
  communicationTools: [],
}

const Section14 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(formInitialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data[0]);
    }
  }, [data]);

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`14 - ${t('F3_S14_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F1_S8_Objetive')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="objective"
            as="textarea" 
            rows={7} 
            style={{resize: 'none'}}
            value={formValues.objective}
            disabled={true}
            placeholder={t('Maximum_1300')}
          />
        </Form.Group>

        <Title> {t('F5_S14_Communication_Tools_title')} </Title>
        <CommunicationToolList 
          items={formValues.communicationTools}
          handleOpenModal={null}
        />

        <Title> {t('F3_S14_Subtitle_focal_point')}  </Title> 
        
        <Form.Group as={Row}>
          <Form.Label column xs={2}>{t('F1_S2_First_name')} <RequiredMark>*</RequiredMark>: </Form.Label>
          <Col xs={3} className="align-self-center">
            <TextValue value={formValues.firstname} />
          </Col>
          <Form.Label className="align-self-center" column xs={2}>{t('F1_S2_Last_Name')} <RequiredMark>*</RequiredMark>: </Form.Label>
          <Col xs={3} className="align-self-center" >
            <TextValue value={formValues.lastname} />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column xs={5}>{t('F1_S2_Email')} <RequiredMark>*</RequiredMark>: </Form.Label>
          <Col xs={5} className="align-self-center" >
            <TextValue value={formValues.email} />        
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label className="align-self-center" column xs={2}>{t('F3_S14_Entity')} <RequiredMark>*</RequiredMark>: </Form.Label>
          <Col xs={3} className="align-self-center" >
            <TextValue value={formValues.entityName} />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column lg={2}>{t('F1_S2_Phone_number')}:<RequiredMark>*</RequiredMark></Form.Label>
          <Col lg={4} className="align-self-center" >
            <TextValue value={formValues.phone} />
          </Col>
        </Form.Group>
      </StyledBody>
    </SectionContainer>
  )
}

export default Section14;