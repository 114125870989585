import React from 'react'

import { getAllService } from 'services/notifications';

import Dropdown from 'react-bootstrap/Dropdown';

import CustomToggle from './CustomToggle'
import HeaderUserMenu from './HeaderUserMenu'
import CustomModal from 'components/Modal'

import {StyledBadge, StyledImageButton} from './HeaderButtonGroup.styled'

import headerIcon from 'assets/icon-account-notification.png';
import { useTranslation } from 'react-i18next';

const HeaderButtonGroup = () => {
  const [notifications, setNotifications] = React.useState([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    async function fetchAPI() {
      let response;
      try {
        response = await getAllService();
        setNotifications(response); 
      }
      catch(e){
        console.log(e);
      }
    }
    fetchAPI();
  }, []);

  const [modal, setModal] = React.useState({
    isOpen: false,
    message: "",
    type: 'default'
  });

  const onHideModal = () => {
    setModal({...modal, isOpen: false});
  };

  return (
    <Dropdown>
      { modal.isOpen && <CustomModal 
        show={modal.isOpen}
        onHide={onHideModal}
        message={modal.message}
        type={modal.type}
      /> }

       
      <Dropdown.Toggle as={CustomToggle} variant="light" >
        <StyledImageButton src={headerIcon} rounded/>
        <StyledBadge twodigits={(notifications.filter(notif => !notif.readDate)).length > 9} className="bg-transparent" variant="light" > 
          {!notifications.length? 
            0 
            : 
            ((notifications.filter(notif => !notif.readDate)).length > 99)? "9+" : (notifications.filter(notif => !notif.readDate)).length
          } 
        </StyledBadge>
        {t('My_Account')}
        &#x25bc;
      </Dropdown.Toggle>

      <HeaderUserMenu />

    </Dropdown>
  )
}

export default HeaderButtonGroup



