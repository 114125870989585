import * as Yup from 'yup';
import i18n from 'i18n';

const yesterday = new Date(Date.now() - 86400000);

export const validationSchema = Yup.object().shape({
  productList: Yup.array()
    .of(
      Yup.object().shape({
        productTypeId: Yup.string()
          .min(1, 'F1_S2_validation5'),
        name: Yup.string()
          .min(2, 'F3_S7_validation4')
          .max(100, 'F3_S7_validation14'),
        dueDate: Yup.date()
          .min(yesterday, 'F1_S7_validation3')
          .typeError('F1_S7_validation9'),
        description: Yup.string()
          .min(2, 'F3_S7_validation4')
          .max(650, 'F1_S6_validation9'),
      })
    )
    .required('F3_S7_validation15')
    .min(1, 'F3_S7_validation16')
});