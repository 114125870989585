import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { validationSchema } from "./ReplacePersonForm.validations";
import {
  RequiredMark,
  CenteredModalButtonContainer,
} from "components/CommonStyled";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CustomModal from "components/Modal";
import { ErrorButton, PrimaryButton } from "components/Buttons";
import { formInitialValues } from "./ReplacePersonForm.initialValues";
import ConfirmCloseModal from "components/ConfirmCloseModal";
import { patchUpdatePerson } from "services/initiative";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const ReplacePersonForm = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [closeModalShow, setCloseModalShow] = useState(false);
  const [sending, setSending] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
    type: "default",
  });

  useEffect(() => {}, []);

  const handleSubmit = async (values) => {
    setSending(true);
    try {
      values.isOwner = props.isResponsiblePerson;
      const response = await patchUpdatePerson(id, values);
      setModal({
        ...modal,
        message: t("Success"),
        type: "success",
        isOpen: true,
      });
      setTimeout(function () {
        setModal({ isOpen: false, message: "", type: "info" });
        setSending(false);
        window.location.replace("");
        props.onHide();
      }, 2000);
    } catch (error) {
      throw new Error(error);
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal {...props} centered size="lg" animation={false}>
      <Modal.Body>
        {modal.isOpen && (
          <CustomModal
            show={modal.isOpen}
            onHide={() => {
              setModal({ isOpen: false, message: "", type: "info" });
              setSending(false);
              window.location.replace("");
              props.onHide();
            }}
            message={modal.message}
            type={modal.type}
          />
        )}

        {closeModalShow && (
          <ConfirmCloseModal
            show={closeModalShow}
            handleCloseConfirm={() => {
              setCloseModalShow(false);
            }}
            handleActionOnConfirm={() => {
              props.onHide();
            }}
            text={t("Confirmation close modal")}
          />
        )}

        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit}>
              <Form.Group as={Row} style={{ alignItems: "center" }}>
                <Form.Label column lg={4}>
                  {props.isResponsiblePerson
                    ? t("F1_S2_Responsisble_person")
                    : t("F1_S2_Duly_signer")}
                  <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Col lg={4}>
                  <Form.Control
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    placeholder={t("F1_S2_First_name")}
                    className={
                      touched.firstName && errors.firstName ? "error" : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.firstName}
                    errors={errors.firstName}
                  />  
                </Col>
                <Col lg={4}>
                  <Form.Control
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    placeholder={t("F1_S2_Last_Name")}
                    className={
                      touched.lastName && errors.lastName ? "error" : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.lastName}
                    errors={errors.lastName}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column lg={4}>
                  {t("F1_S2_Position")}
                  <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Col lg={8}>
                  <Form.Control
                    type="text"
                    name="position"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.position}
                    className={
                      touched.position && errors.position ? "error" : null
                    }
                  />
                  <FormErrorMessage
                    touched={touched.position}
                    errors={errors.position}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column lg={4}>
                  {t("F1_S2_Phone_number")}
                  <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Col lg={8}>
                  <Form.Control
                    type="text"
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    placeholder={t("Example") + " +12133734253"}
                    className={touched.phone && errors.phone ? "error" : null}
                  />
                  <FormErrorMessage
                    touched={touched.phone}
                    errors={errors.phone}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} style={{ alignItems: "center" }}>
                <Form.Label column lg={4}>
                  {t("F1_S2_Email")}
                  <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Col lg={8}>
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className={touched.email && errors.email ? "error" : null}
                  />
                  <FormErrorMessage
                    touched={touched.email}
                    errors={errors.email}
                  />
                </Col>
              </Form.Group>

              {props.isResponsiblePerson && (
                <Form.Group>
                  <Col>
                    <Form.Check
                      name="ownerIsSigner"
                      type="checkbox"
                      value={values.ownerIsSigner}
                      onChange={handleChange}
                      checked={values.ownerIsSigner}
                      style={{
                        marginLeft: "50px",
                        marginTop: "10px",
                        lineHeight: "1.3",
                      }}
                      label={t("F1_S2_Duly_signer_checkbox") + "."}
                    />
                  </Col>
                </Form.Group>
              )}

              <CenteredModalButtonContainer>
                <ErrorButton
                  disabled={sending}
                  onClick={() => setCloseModalShow(true)}
                >
                  {t("Button_cancel")}
                </ErrorButton>
                <PrimaryButton
                  type="submit"
                  disabled={sending}
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    marginLeft: "15px",
                  }}
                >
                  {t("Button_save")}
                </PrimaryButton>
              </CenteredModalButtonContainer>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ReplacePersonForm;
