import styled from 'styled-components';
import Container from 'react-bootstrap/Container';

export const StyledContainer = styled(Container)`
  padding-bottom: 150px;
  padding-left: 0;
`;

export const Content = styled.div`
  padding: 20px 40px;
`;