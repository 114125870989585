import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useRootStore } from 'stores'
import { useTranslation } from 'react-i18next'
import {
  roleNamesConst,
  f5ActivityBudgetStatesConst,
} from 'constants/appConstants'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import ConfirmCloseModal from 'components/ConfirmCloseModal'
import NewEUBudgetLineForm from './NewEUBudgetLineForm'
import FinalizeForm from './FinalizeForm'
import PaymentsForm from './PaymentsForm'
import UploadDocumentForm from './UploadDocumentForm'
import SelectProviderForm from './SelectProviderForm'
import NewProviderForm from './NewProviderForm'
import ProviderForm from './ProviderForm'
import Modal from 'react-bootstrap/Modal'
import { StyledModalBody, ModalTitle } from './BudgetDetailAdminModal.styled'

const BudgetDetailAdminModal = ({
  show,
  type,
  title,
  activity,
  detail,
  setBudgetDetailAdminModalData,
  setModalInfo,
  handleBudgetLineModalOpen,
  payments,
}) => {
  const { authStore } = useRootStore()
  const { t } = useTranslation()
  const { id } = useParams()
  const [
    isCloseBudgetModalAlertShow,
    setIsCloseBudgetModalAlertShow,
  ] = useState(false)
  const role = authStore.roles[0]
  let history = useHistory()

  const isAtke = role === roleNamesConst.ATKE
  const isConsortium = role === roleNamesConst.CONSORTIUM
  const isManager = role !== roleNamesConst.PUBLIC

  const isManagerNonEditable =
    detail &&
    detail.resourceDetailState &&
    isManager &&
    ((!isAtke && !isConsortium) ||
      detail.resourceDetailState.stateEnum ===
        f5ActivityBudgetStatesConst.FINALIZED ||
      detail.resourceDetailState.stateEnum ===
        f5ActivityBudgetStatesConst.CANCELLED)

  const isManagerNonEditable2 =
    detail &&
    detail.resourceDetailState &&
    isManager &&
    ((!isAtke && !isConsortium) ||
      detail.resourceDetailState.stateEnum ===
        f5ActivityBudgetStatesConst.CANCELLED)

  const handleClose = () => {
    setIsCloseBudgetModalAlertShow(true)
  }

  const handleCloseOk = () => {
    setBudgetDetailAdminModalData({
      isShow: false,
      type: null,
      title: '',
      detail: null,
    })
    handleBudgetLineModalOpen()
  }

  const handleCloseAll = () => {
    setBudgetDetailAdminModalData({
      isShow: false,
      type: null,
      title: '',
      detail: null,
    })
    history.push(
      `${authStore.getBaseRouteByRole()}/initiative/${id}/form/5/section/7?displayExecutedBudget=true&activityNumber=${
        activity.id
      }`,
    )
  }

  const handleCloseSubModalOnError = () => {
    setBudgetDetailAdminModalData({
      isShow: false,
      type: null,
      title: '',
      detail: null,
    })
    handleBudgetLineModalOpen()
    setModalInfo({
      isOpen: true,
      message: t('Error try again later'),
      type: 'error',
    })
  }

  const getFormByType = (type) => {
    const fromByType = {
      [0]: (
        <ProviderForm
          activity={activity}
          detail={detail}
          handleCloseOk={handleCloseOk}
          handleClose={handleClose}
          handleCloseSubModalOnError={handleCloseSubModalOnError}
          setModalInfo={setModalInfo}
          nonEditable={isManagerNonEditable}
          setBudgetDetailAdminModalData={setBudgetDetailAdminModalData}
        />
      ),
      [1]: (
        <NewProviderForm
          detail={detail}
          handleCloseOk={handleCloseOk}
          handleClose={handleClose}
          handleCloseSubModalOnError={handleCloseSubModalOnError}
          nonEditable={isManagerNonEditable}
          setBudgetDetailAdminModalData={setBudgetDetailAdminModalData}
        />
      ),
      [2]: (
        <SelectProviderForm
          activity={activity}
          detail={detail}
          handleCloseOk={handleCloseOk}
          handleClose={handleClose}
          handleCloseSubModalOnError={handleCloseSubModalOnError}
          setModalInfo={setModalInfo}
          nonEditable={isManagerNonEditable}
          payments={payments}
        />
      ),
      [3]: (
        <UploadDocumentForm
          activity={activity}
          detail={detail}
          handleCloseOk={handleCloseOk}
          handleClose={handleClose}
          handleCloseSubModalOnError={handleCloseSubModalOnError}
          setModalInfo={setModalInfo}
          nonEditable={isManagerNonEditable}
        />
      ),
      [4]: (
        <PaymentsForm
          activity={activity}
          detail={detail}
          handleCloseOk={handleCloseOk}
          handleClose={handleClose}
          handleCloseSubModalOnError={handleCloseSubModalOnError}
          nonEditable={isManagerNonEditable}
          nonEditable2={isManagerNonEditable2}
        />
      ),
      [5]: (
        <FinalizeForm
          activity={activity}
          detail={detail}
          handleCloseOk={handleCloseOk}
          handleClose={handleClose}
          handleCloseSubModalOnError={handleCloseSubModalOnError}
          nonEditable={isManagerNonEditable}
        />
      ),
      [6]: (
        <NewEUBudgetLineForm
          activity={activity}
          detail={detail}
          handleCloseAll={handleCloseAll}
          handleClose={handleClose}
          handleCloseSubModalOnError={handleCloseSubModalOnError}
          setModalInfo={setModalInfo}
          nonEditable={isManagerNonEditable}
        />
      ),
    }
    return fromByType[type]
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        backdrop="static"
        animation={false}
      >
        <StyledModalBody>
          <ModalTitle>{`${title}`}</ModalTitle>
          <div style={{ marginTop: '40px' }}>{getFormByType(type)}</div>
        </StyledModalBody>
      </Modal>

      <ConfirmCloseModal
        show={isCloseBudgetModalAlertShow}
        handleCloseConfirm={() => setIsCloseBudgetModalAlertShow(false)}
        handleActionOnConfirm={handleCloseOk}
        text={t('Confirmation close modal')}
      />
    </>
  )
}

export default observer(BudgetDetailAdminModal)
