import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextValue from 'components/TextValue';

import Form from 'react-bootstrap/Form';
import { RequiredMark } from 'components/CommonStyled';
import {
  StyledFormLabel,
  StyledTitle as StyledTitleLocal,
  StyledSubtitle
} from 'components/Forms/Form5/Section13/Section13.styled';
import { 
  SectionContainer, 
  StyledTitleHead, 
  TitleContainer, 
  StyledTitle, 
  StyledBody 
} from '../CommonStyles'

export const formInitialValues = [
  {
    overviewMeasures: "",
    tciContribute: "",
    tciApproach: "",
  },
];

const Section13 = ({ data }) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(formInitialValues);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data]);

  return (
    <SectionContainer>
      <StyledTitleHead>
        <TitleContainer>
          <StyledTitle> {`13 - ${t('F3_S13_title')}`} </StyledTitle>
        </TitleContainer>
      </StyledTitleHead>
      <StyledBody>
        <StyledTitleLocal style={{marginBottom: '30px'}}>
          {t('F5_Subtitle_Assessment_TCI_Implementation')}
        </StyledTitleLocal>

        <StyledSubtitle>
          {t('F5_S7_Activity_assessment_12')}:
        </StyledSubtitle>

        <div style={{marginTop: '20px'}}>
          {formValues && formValues[1] && formValues[1].map(activity => (
            <Form.Group style={{display: 'flex'}}>
            <StyledFormLabel style={{margin: '0px'}}>{activity.activityName}:</StyledFormLabel>
                <TextValue value={activity.assessment} />
            </Form.Group>  
          ))}
        </div>
        
        <Form.Group style={{marginTop: '25px'}}>
          <StyledFormLabel>
            {t('F5_Overview_of_TCI')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="overviewMeasures"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].overviewMeasures}
            disabled={true}
          />
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>
            {t('F5_S13_TCI_contribute_awarness')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="tciContribute"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].tciContribute}
            disabled={true}
          />
        </Form.Group>

        <Form.Group>
          <StyledFormLabel>
            {t('F5_S13_TCI_promoted_cross_cutting')}<RequiredMark>*</RequiredMark>:
          </StyledFormLabel>
          <Form.Control
            name="tciApproach"
            as="textarea" 
            rows={6} 
            style={{resize: 'none'}}
            placeholder={t('Maximum_1300')}
            value={formValues[0].tciApproach}
            disabled={true}
          />
        </Form.Group>
      </StyledBody>
    </SectionContainer>
  )
}

export default Section13;


