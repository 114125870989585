import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { getActivityDetailsF5S7 } from 'services/initiative';
import MoneyTextMask from 'components/MoneyTextMask';
import { CompleteImage } from 'components/Forms/Form5/Section7/ActivityItem/ActivityItem.styled';
import completeIcon from 'assets/complete-mark.png';
import { useRootStore } from 'stores';
import { roleNamesConst, f5ActivityBudgetStatesConst } from 'constants/appConstants';

import {StyledRow, StyledCol, StyledText, StyledDiv, StyledColTitle, StyledDivTotal, StyledNotice, StyledButtonLink} from '../ResourcesF3Form/ResourcesF3Form.styled';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { PrimaryButton } from 'components/Buttons';
import { CenteredModalButtonContainer } from 'components/CommonStyled';

const ResourcesExecutedForm = ({handleCloseOk, handleBudgetLineModalOpen, setBudgetDetailAdminModalData, activity}) => {

  const { t } = useTranslation();
  const { id } = useParams();
  const [formValues, setFormValues] = useState([]);
  const { authStore } = useRootStore();
  const role = authStore.roles[0];
  const isPublic = role === roleNamesConst.PUBLIC;
  const isAtke = role === roleNamesConst.ATKE;
  const isConsortium = role === roleNamesConst.CONSORTIUM;
  const [activityTotal, setActivityTotal] = useState({
      ueBudget: 0,
      counterpartBudget: 0,
      totalCost: 0,
  });

  useEffect(() => {
    async function getValues() {
        let response = await getActivityDetailsF5S7(id, activity.id);
        let total = {
          ueBudget: 0,
          counterpartBudget: 0,
          totalCost: 0,
        }

        response.data.forEach(({ueOrCounterpart, totalRealMoney}) => {
          total.ueBudget += ueOrCounterpart == 0? totalRealMoney: 0;
          total.counterpartBudget += ueOrCounterpart == 1? totalRealMoney: 0;
          total.totalCost += totalRealMoney;
        })

        setActivityTotal(total);
        setFormValues(response?.data);
    }
    getValues();
  }, []);

  const handleNewBudgetLine = () => {
    handleCloseOk();
    setBudgetDetailAdminModalData({
      isShow: true,
      type: 6,
      title: t('F5_S7_Budget_Admins_Title6'),
      detail: {
        resourceDetailState: {
          "id": 1,
          "stateEnum": "PLANNED",
          "name": "PLANNED"
        },
        detailProviderObj: null,
        currentSelectedProvider: null,
        currentDocument: null,
        currentPayment: null,
      }
    })  
  }

  return (
    <>
      {formValues.length !== 0 && <Row>
        <Col sm={6}>
        </Col>
        <Col sm={6} style={{display:'flex'}}>
          <StyledColTitle sm={4}>
            {t('F3_S7_Resource_column_EU')}
          </StyledColTitle>
          <StyledColTitle sm={4}>
            {t('F3_S7_Resource_column_counterpart')}
          </StyledColTitle>
          <StyledColTitle sm={4}>
            {t('F5_S7_Resource_column_total')}
          </StyledColTitle>
        </Col>
      </Row>}

      {formValues && formValues.map((detail) => ( 
         <StyledRow>
            <Col sm={6} style={{display: 'flex'}}>
              <StyledButtonLink variant="link" onClick={() => handleBudgetLineModalOpen(detail)} size="lg" style={{marginRight: '10px'}}>
                {t(`resource_type_${detail.resourceType.id}`)}
              </StyledButtonLink>
              {(detail.resourceDetailState.name === f5ActivityBudgetStatesConst.FINALIZED 
                || detail.resourceDetailState.name === f5ActivityBudgetStatesConst.CANCELLED) && <CompleteImage src={completeIcon} />}
            </Col>
            <Col sm={6} style={{display:'flex'}}>
              <StyledCol sm={4}>
                <MoneyTextMask value={detail.ueOrCounterpart == 0? detail.totalRealMoney: "-"}/>
              </StyledCol>
              <StyledCol sm={4}>
              <MoneyTextMask value={detail.ueOrCounterpart == 1? detail.totalRealMoney: "-"}/>
              </StyledCol>
              <StyledCol sm={4}>
                <MoneyTextMask value={detail.totalRealMoney}/>
              </StyledCol>
            </Col>
            <StyledDiv> 
              <StyledText>
                {detail.description? detail.description: "-" }
              </StyledText>
              <div style={{display: 'flex'}}>
                {t('F5_S7_provider')}:
                <StyledText style={{marginLeft: '10px'}}>
                  {detail.provider? detail.provider: "-" }
                </StyledText>
              </div>
              <div style={{display: 'flex'}}>
                {t('F5_S7_status')}:
                <StyledText style={{marginLeft: '10px'}}>
                {isPublic ? (
                  <>
                    {(detail.resourceDetailState.name === 'CONTRACTING' 
                      && (detail.providerSelected && !detail.budgetConfirmed) 
                      && detail.resourceType && (detail.resourceType.id != 8 && detail.resourceType.id != 9)) ? (
                        <div style={{display: 'flex'}}>
                          {detail.resourceDetailState.name+" - "}
                          <StyledNotice>
                            {t('F5_S7_waiting_confirmation')}
                          </StyledNotice> 
                        </div> 
                      ) : (
                        detail.resourceDetailState.name 
                      )}
                  </> 
                ) : ( 
                  <>
                    {detail.resourceDetailState.name === 'CONTRACTING' 
                    && !detail.mgmtFinalizeConfirmed 
                    && detail.budgetConfirmed ?
                    <div style={{display: 'flex'}}>
                      {detail.resourceDetailState.name+" - "}
                      <StyledNotice style={{color: 'green'}}>
                        {t('F5_S7_Confirmation_mgm')}
                      </StyledNotice> 
                    </div> : detail.resourceDetailState.name } 
                  </>
                )}
                </StyledText>
              </div>
            </StyledDiv>   
          </StyledRow> 
      ))}

      
      { (isAtke || isConsortium) && <div style={{marginTop: '10px', marginBottom: '10px'}}>
        <PrimaryButton type="buttom" onClick={() => handleNewBudgetLine()}> {t('F5_S7_Add_resource')} </PrimaryButton>
        </div>
      }

      {formValues.length !== 0 &&
        <StyledDivTotal>
            <Col sm={6} style={{fontSize: '18px'}}>
              {t('F3_S7_Resource_total_activity')+" "+activity.name}
            </Col>
            <Col sm={6} style={{display:'flex'}}>
                <StyledCol sm={4}>
                  <MoneyTextMask value={activityTotal.ueBudget} />
                </StyledCol>
                <StyledCol sm={4}>
                  <MoneyTextMask value={activityTotal.counterpartBudget} />
                </StyledCol>
                <StyledCol sm={4}>
                  <MoneyTextMask value={activityTotal.totalCost} />
                </StyledCol>
            </Col>
        </StyledDivTotal>}

      <CenteredModalButtonContainer>
        <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
      </CenteredModalButtonContainer>
    </>
  )
}

export default observer(ResourcesExecutedForm);