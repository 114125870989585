import styled, { css } from 'styled-components'

export const StyledSubtitle = styled.div`
  ${({ theme }) => css`
    margin-top: 35px;
    margin-bottom: 15px;
    text.align: justify;
    color: ${theme.brandColors.blue};
    letter-spacing: 2px;
    font-weight: 650;
  `}
`;

export const StyledDivWrapper = styled.div`
  margin-left: 50px;
`;

export const StyledText = styled.div`
  line-height: 1.3;
  text-align: justify;
  margin-bottom: 10px;
`;