import styled, { css } from 'styled-components'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

export const StyledSubtitle = styled.div`
  ${({ theme }) => css`
    margin-top: 30px;
    margin-bottom: 10px;
    padding-left: 15px;
    color: ${theme.brandColors.blue};
    letter-spacing: 2px;
    font-weight: 650;
  `}
`;

export const StyledWarningP = styled.p`
  color: #FF6565;
  margin-top: 2px;
  font-size: .8em;
`;

export const StyledFormLabel = styled(Form.Label)`
  padding-left: 12px;
  margin-bottom: -10px;
`;

export const StyledSeparator = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 50px;
  height: 2px;
  background-image: linear-gradient(to right, #bac5cc 33%, rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 6px 2px;
  background-repeat: repeat-x;
`;

export const StyledTextValue = styled(Col)`
  padding-left: 65px;
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    color: ${theme.colors.gray[5]};
  `}
`;

export const DisabledSectionDiv = styled.div`
  ${({ theme }) => css`
    font-weight: 450;
    font-size: 18px;
    line-height: 1.3;
    min-height: 150px;
    margin-left: 65px;
    padding: 20px 20px 20px 20px;
    margin-right: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    background-color: ${theme.brandColors.green};
    color: white;
  `}
`;

export const TextValueLeftSpaced = styled.div`
  padding-left: 65px;
`;
