import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import { formInitialValues } from "./BeneficiaryFormModal.initialValues";
import Form from "react-bootstrap/Form";
import {
  RequiredMark,
  CenteredModalButtonContainer,
} from "components/CommonStyled";
import { useTranslation } from "react-i18next";
import { addBeneficiary, editBeneficiary } from "services/initiative";
import { useParams } from "react-router-dom";
import { validationSchema } from "./BeneficiaryFormModal.validations";
import { useConstantsForm3 } from "customHooks/useConstantsForm3";
import { StyledSendButton } from "components/FormContainer/FormContainer.styled";
import { ErrorButton } from "components/Buttons";
import CustomModal from "components/Modal";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";

const BeneficiaryFormModal = (props) => {
  const { t } = useTranslation();
  const { id: initiativeId } = useParams();
  const [saved, setSaved] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const { beneficiaryTypes } = useConstantsForm3();
  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
    type: "default",
  });

  useEffect(() => {
    if (props.content !== null) {
      setSaved(false);
      const values = {
        beneficiaryTypeId: props.content.beneficiaryType
          ? props.content.beneficiaryType.id
          : "",
        beneficiaryDescription: props.content.beneficiaryDescription,
        beneficiaryJustification: props.content.beneficiaryJustification,
        beneficiaryProspect: props.content.beneficiaryProspect,
        fromF3: props.content.fromF3,
      };
      setInitialValues(values);
    } else setInitialValues(formInitialValues);
  }, [props.content]);

  const onHideModal = () => {
    setModal({ ...modal, isOpen: false });
    setSaved(false);
    props.onHide();
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      var response = null;
      if (props.content) {
        response = await editBeneficiary(
          initiativeId,
          values,
          props.content.id
        );
        props.onSetBenList(response.data.benList);
        props.onHide();
      } else {
        response = await addBeneficiary(initiativeId, values);
        props.onSetBenList(response.data.benList);
        props.onHide();
      }
      resetForm({ values });
      setSaved(true);
    } catch (error) {
      setModal({
        ...modal,
        message: t("Error_submit"),
        type: "error",
        isOpen: true,
      });
    }
  };

  return (
    <Modal {...props} centered size="lg" animation={false}>
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleSubmit, dirty }) => (
            <form onSubmit={handleSubmit}>
              {dirty && setSaved(false)}

              {modal.isOpen && (
                <CustomModal
                  show={modal.isOpen}
                  onHide={onHideModal}
                  message={modal.message}
                  type={modal.type}
                />
              )}

              <Form.Group style={{ marginTop: "25px" }}>
                <Form.Label>
                  {t("F3_S10_Type_beneficiary")}
                  <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  name="beneficiaryTypeId"
                  as="select"
                  onChange={handleChange}
                  value={values.beneficiaryTypeId}
                >
                  <option value="">{t("Placeholder_select")}</option>
                  {beneficiaryTypes.map((beneficiary) => (
                    <option key={beneficiary.id} value={beneficiary.id}>
                      {t(`beneficiary_type_${beneficiary.id}`)}
                    </option>
                  ))}
                </Form.Control>
                <FormErrorMessage
                  touched={touched.beneficiaryTypeId}
                  errors={errors.beneficiaryTypeId}
                />
              </Form.Group>

              <Form.Group style={{ marginTop: "25px" }}>
                <Form.Label>
                  {t("F3_S10_Description")}
                  <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  name="beneficiaryDescription"
                  as="textarea"
                  onChange={handleChange}
                  value={values.beneficiaryDescription}
                  style={{ resize: "none" }}
                  placeholder={t("Maximum_650")}
                />
                <FormErrorMessage
                  touched={touched.beneficiaryDescription}
                  errors={errors.beneficiaryDescription}
                />
              </Form.Group>

              <Form.Group style={{ marginTop: "25px" }}>
                <Form.Label>
                  {t("F3_S10_Justification")}
                  <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  name="beneficiaryJustification"
                  as="textarea"
                  onChange={handleChange}
                  value={values.beneficiaryJustification}
                  style={{ resize: "none" }}
                  placeholder={t("Maximum_650")}
                />
                <FormErrorMessage
                  touched={touched.beneficiaryJustification}
                  errors={errors.beneficiaryJustification}
                />
              </Form.Group>

              <Form.Group style={{ marginTop: "25px" }}>
                <Form.Label>
                  {t("F3_S10_Prospects_for_harnessing")}
                  <RequiredMark>*</RequiredMark>:
                </Form.Label>
                <Form.Control
                  name="beneficiaryProspect"
                  as="textarea"
                  onChange={handleChange}
                  value={values.beneficiaryProspect}
                  style={{ resize: "none" }}
                  placeholder={t("Maximum_650")}
                />
                <FormErrorMessage
                  touched={touched.beneficiaryProspect}
                  errors={errors.beneficiaryProspect}
                />
              </Form.Group>

              <CenteredModalButtonContainer>
                <ErrorButton
                  onClick={() => {
                    props.onHide();
                  }}
                >
                  {t("Button_cancel")}
                </ErrorButton>
                <StyledSendButton
                  type="submit"
                  disabled={saved}
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    marginLeft: "15px",
                  }}
                >
                  {saved ? t("Button_saved") : t("Button_save")}
                </StyledSendButton>
              </CenteredModalButtonContainer>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default BeneficiaryFormModal;
