import styled, {css} from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';

export const StyledModalBody = styled(Modal.Body)`
  ${({ theme }) => css`
    color: #6f8d9d;
    padding: 30px 40px;
    background-color: ${theme.brandColors.gray4};
  `}
`;

export const ModalTitle = styled.div`
  font-weight: 700;
  margin-bottom: 30px
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const StyledCol = styled(Col)`
  padding: 0px;
`;
