import React from 'react';
import NumberFormat from 'react-number-format';

const MoneyInput = ({onChange = () => {}, ...props}) => {
  return (
    <NumberFormat
      isNumericString={true}
      decimalSeparator=','
      thousandSeparator='.'
      prefix='€  '
      onValueChange={val => onChange(val.floatValue)}
      {...props}
    />
  )
}


export default MoneyInput;