import styled, { css } from 'styled-components';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

export const StyledFooter = styled.footer`
${({ theme }) => css`
  background-color: ${theme.colors.gray[2]} ;
  min-height: 50px;
  color: white;
  padding: 20px; 
  position:absolute;
  bottom:0;
  width: 100%;
`}
`;

export const StyledLeftContent = styled.div`
  margin-left: 50px;
  align-items: center;
`;


export const StyledRightContent = styled.div`
  margin-right: 50px;
  align-items: center;
  
`;

export const StyledIcon = styled(Image)`
  margin-left: 15px;
`;

export const StyledA = styled.a`
  margin-left: 10px;
  color: white;
  font-weight: 600;
  font-size: 17px;
`;

export const StyledHelpDesk = styled.button`
  padding: 0;
  border: none;
  background: none;
  box-shadow: none !important;
  margin-bottom: 4px;
  color: white;
`;

export const StyledContent = styled.div`
 border-bottom: 1px dotted white;
 width: 90%;
 padding-top: 5px;
 padding-bottom: 20px;
 margin-left: 50px;
`;

export const StyledPublicFooter = styled.div`
  object-fit: contain;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const StyledPrivateFooter = styled.div`
 margin-bottom: 20px;
`;

export const StyledButtonLink = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  box-shadow: none !important;
  color: white;
  margin-bottom: 4px;
`;
