import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTr, StyledButtonLink } from './Styled';
import _isNil from 'lodash/isNil';

const TableRow = ({ item, onContact }) => {
  const { t, i18n } = useTranslation();

  const renderEntities = (entities) => {
    return (
      <ul>
        {
          entities?.map(item => {
            return (
              <>
                <li>
                  <span style={{ fontSize: '12px'}}>
                    {`${item?.name} - ${t(item?.countryName)}`}
                  </span>
                </li>
                {!_isNil(item?.typeId) && !_isNil(item?.entityRole) ? (
                  <span style={{ fontSize: '12px', fontWeight: 300, fontStyle: 'italic' }}>
                    {`${t(`entity_type_${item?.typeId}`)} - ${t(`coordRole${item?.entityRole}`)}`}
                  </span>
                ) : !_isNil(item?.typeId) && _isNil(item?.entityRole) ? (
                  <span style={{ fontSize: '12px', fontWeight: 300, fontStyle: 'italic' }}>
                    {`${t(`entity_type_${item?.typeId}`)} - ${t('Collaborating_entity')}`}
                  </span>
                ) : null}
              </>
            )
          })
        }
      </ul>
    )
  };

  const title = useMemo(() => {
    return item.instrument === 'adelante1' && i18n.language === 'es' && item.title_ES ? item.title_ES : item.title;
  }, [item, i18n.language]);

  const objective = useMemo(() => {
    return item.instrument === 'adelante1' && i18n.language === 'es' && item.objective_ES ? item.objective_ES : item.objective;
  }, [item, i18n.language]);

  const websiteLink = useMemo(() => {
    const link = i18n.language === 'es' && item.websiteEs ? item.websiteEs : item.website;
    return link?.includes('http') ? link: `https://${link}`;
  }, [item, i18n.language]);

  return (
    <StyledTr key={item.id}>
      <td style={{ maxWidth: '10vw', fontSize: '12px', overflowWrap: 'break-word' }}>
        <b>{title}</b>
      </td>
      <td style={{ maxWidth: '30vw', fontSize: '12px', overflowWrap: 'break-word' }}>
        {item.entities ? renderEntities(item.entities) : ' - '}
      </td>
      <td style={{ maxWidth: '10vw', fontSize: '12px', overflowWrap: 'break-word' }}>
        {item.instrument}
      </td>
      <td style={{ maxWidth: '10vw', fontSize: '12px', overflowWrap: 'break-word' }}>
        {objective}
      </td>
      <td style={{ maxWidth: '10vw', fontSize: '12px', overflowWrap: 'break-word' }}>
        {item.primaryGoalId ? t(`SDG_Goal${item.primaryGoalId}`) : ' - '}
      </td>
      <td style={{ maxWidth: '10vw', fontSize: '12px', overflowWrap: 'break-word' }}>
        {item.secondaryGoalId ? t(`SDG_Goal${item.secondaryGoalId}`) : ' - '}
      </td>
      <td style={{ maxWidth: '10vw', fontSize: '12px', overflowWrap: 'break-word' }}>
        {item.website ? (
          <StyledButtonLink href={websiteLink} target="_blank">
            {t('community_minisite')}
          </StyledButtonLink>
        ) : ' - '}
      </td>
      <td style={{ maxWidth: '10vw', fontSize: '12px', overflowWrap: 'break-word' }}>
        <StyledButtonLink onClick={onContact}>
          {t('community_connect')}
        </StyledButtonLink>
      </td>
    </StyledTr>
  );
};

export default TableRow;