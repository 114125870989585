import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  renderTypeODSOptions,
  getInitiativesGoalIndicatorsList,
} from '../helpers/helpers'
import { renderAllODSOptions, renderTypeOptions } from '../../CommonHelpers/helpers'
import { getODS } from 'services/initiative'

import { Form, Row } from 'react-bootstrap'
import { PrimaryButton } from 'components/Buttons'
import {
  Col1,
  Col2,
  StyledFormGroup,
  StyledFormGroup2,
  StyledFormLabel,
} from '../../CommonStyled/Components.styled'
import { ExportGoalsIndicatorsList } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'
import {
  renderYearOptions,
} from '../../CommonHelpers/helpers'
import { TARGET_YEARS } from 'constants/appConstants'

const Header3 = ({ data, getData, waiting }) => {
  const { t } = useTranslation()
  const [yearFilter, setYearFilter] = useState(0)
  const [SDGFilter, setSDGFilter] = useState(0)
  const [typeSDGFilter, setTypeSDGFilter] = useState('')
  const [ODSOptions, setODSOptions] = useState([])
  const [typeFilter, setTypeFilter] = useState('*')

  useEffect(() => {
    getListODS()
  }, [])

  const getListODS = async () => {
    try {
      const response = await getODS()
      setODSOptions(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilter = () => {
    let params = {}
    if (SDGFilter) params.ods = SDGFilter
    if (typeSDGFilter) params.odsType = typeSDGFilter
    if (yearFilter && yearFilter != '0') params.year = yearFilter
    if (typeFilter) {
      if (typeFilter === '*') {
        params.includeFunds = true;
        params.includeInitiatives = true;
      }
      if (typeFilter === 'FUNDS') {
        params.includeFunds = true;
        params.includeInitiatives = false;
      }
      if (typeFilter === 'INITIATIVES') {
        params.includeFunds = false;
        params.includeInitiatives = true;
      }
    }
    getData(params)
  }

  return (
    <>
      <Col1 xs={14}>
        <StyledFormGroup style={{ width: '30%' }}>
          <StyledFormLabel>{t('Dashboard_Year')}</StyledFormLabel>
          <Form.Control
            as="select"
            onChange={(e) => {
              setYearFilter(e.target.value)
            }}
            value={yearFilter}
          >
            {renderYearOptions(TARGET_YEARS)}
          </Form.Control>
        </StyledFormGroup>

        <StyledFormGroup style={{ width: '45%'}}>
          <StyledFormLabel>{t('SDG')}</StyledFormLabel>
          <Form.Control
            name="SDG"
            as="select"
            onChange={(e) => {
              setSDGFilter(e.target.value)
            }}
            value={SDGFilter}
          >
            {renderAllODSOptions(ODSOptions)}
          </Form.Control>
        </StyledFormGroup>
        <StyledFormGroup style={{ width: '40%'}}>
          <StyledFormLabel>{t('type_SDG')}</StyledFormLabel>
          <Form.Control
            name="SDG"
            as="select"
            onChange={(e) => {
              setTypeSDGFilter(e.target.value)
            }}
            value={typeSDGFilter}
          >
            {renderTypeODSOptions()}
          </Form.Control>
        </StyledFormGroup>

        <StyledFormGroup>
          <StyledFormLabel>{t('type_filter_stat')}</StyledFormLabel>
          <Form.Control
            name="type"
            as="select"
            onChange={(e) => {
              setTypeFilter(e.target.value)
            }}
            value={typeFilter}
          >
            {renderTypeOptions()}
          </Form.Control>
        </StyledFormGroup>

        <Row style={{ width: '40%', marginLeft: '24px'}}>
          <PrimaryButton
            onClick={() => {
              handleFilter()
            }}
            disabled={waiting}
          >
            {t('Button_search')}
          </PrimaryButton>
        </Row>

        <Row style={{ width: '40%', alignItems: 'center'}}>
          <ExportToXLSXButton
            data={data}
            mapData={getInitiativesGoalIndicatorsList}
            disabled={waiting}
            exportName={t('goals_indicators_list')}
          >
            <ExportGoalsIndicatorsList
              label={t('goals_indicators_list')}
              data={[]}
            />
          </ExportToXLSXButton>
        </Row>
      </Col1>
    </>
  )
}

export default Header3
