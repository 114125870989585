import * as Yup from 'yup';
import i18n from 'i18n';

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/


export const validationSchema = Yup.object().shape({
  objective: Yup.string()
    .ensure()
    .min(10, 'F3_S5_validation1')
    .max(1300, 'F1_S6_validation8'),
  samePerson: Yup.boolean()
  .test(
    'allRequired',
    'error',
    function(item) {
      return (item === true) || (this.parent.firstname && this.parent.lastname && this.parent.email && this.parent.entityName && this.parent.phone)
    }
  ),
  firstname: Yup.string()
    .when('samePerson', {
      is: false,
      then: Yup.string()
        .ensure()
        .min(2, 'F1_S4_validation1')
        .max(200, 'F1_S4_validation2')
        .required('F3_S14_validation1')
    }),
  lastname: Yup.string()
    .when('samePerson', {
      is: false,
      then: Yup.string()
        .ensure()
        .min(2, 'F1_S4_validation1')
        .max(200, 'F1_S4_validation2')
        .required('F3_S14_validation1')
    }),
  email: Yup.string()
    .when('samePerson', {
      is: false,
      then: Yup.string()
        .ensure()
        .email('F1_S2_validation23')
        .max(650, 'F1_S6_validation9')
        .required('F3_S14_validation1')
    }),
  entityName: Yup.string()
    .when('samePerson', {
      is: false,
      then: Yup.string()
        .ensure()
        .min(2, 'F3_S5_validation1')
        .max(650, 'F1_S6_validation9')
        .required('F3_S14_validation1')
    }),
  phone: Yup.string()
    .when('samePerson', {
      is: false,
      then: Yup.string()
        .ensure()
        .min(6, 'F3_S14_validation2')
        .max(650, 'F1_S6_validation9')
        .matches(phoneRegExp,'F1_S2_validation22')
        .required('F3_S14_validation1')
    }),
  auth: Yup.boolean(),
  items: Yup.array()
    .of(
      Yup.object().shape({
        mediaTypeId: Yup.string(),
        url: Yup.string().ensure().min(5, 'F3_S14_validation3').max(100, 'F3_S14_validation4'),
        comments: Yup.string().min(5, 'F3_S14_validation5').max(100, 'F3_S14_validation6').nullable(true),
        //quantity: Yup.number().typeError('F3_S14_validation7')).min(0,'F3_S14_validation8')).integer(),
      })
    )
});
