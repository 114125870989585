import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledFormLabel,
  StyledTitle,
  StyledCheckSubtitle
} from './ReadOnlyATAssessment.styled'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const ReadOnlyATAssessment = ({ data }) => {
  const {t} = useTranslation();

  return (
    <div style={{marginBottom: '100px', marginLeft: "-15px"}}>
      <StyledTitle style={{marginLeft: '15px'}}>{t('F6_S18_subtitle_at_assessment')}</StyledTitle>

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F6_AT_assessment_question1')}:</StyledFormLabel>
          <Form.Control
            name='knowledge'
            as="textarea"
            style={{resize: "none"}}
            rows={8}
            value={data? data.knowledge: ''}
            disabled={true}
          />
        </Col>
      </Form.Group>

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F6_AT_assessment_question2')}:</StyledFormLabel>
          <Form.Control
            name='impact'
            as="textarea"
            style={{resize: "none"}}
            rows={8}
            value={data? data.impact: ''}
            disabled={true}
          />
        </Col>
      </Form.Group>

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F6_AT_assessment_question3')}:</StyledFormLabel>
          <Form.Control
            name='value'
            as="textarea"
            style={{resize: "none"}}
            rows={8}
            value={data? data.value: ''}
            disabled={true}
          />
        </Col>
      </Form.Group>

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F6_AT_assessment_question4')}:</StyledFormLabel>
          <Form.Control
            name='elements'
            as="textarea"
            style={{resize: "none"}}
            rows={8}
            value={data? data.elements: ''}
            disabled={true}
          />
        </Col>
      </Form.Group>

      <Form.Group>
        <Col>
          <StyledFormLabel xs={5}>{t('F6_AT_assessment_question5')}:</StyledFormLabel>
          <Form.Control
            name='overall'
            as="textarea"
            style={{resize: "none"}}
            rows={8}
            value={data? data.overall: ''}
            disabled={true}
          />
        </Col>
      </Form.Group>

      <div style={{marginLeft: '15px'}}>
        <StyledTitle>{t('F6_S18_subtitle_at_indicators')}</StyledTitle>
        <Form.Group style={{marginLeft: '15px'}}>
          <Row>
            <StyledFormLabel>{t('F6_AT_assessment_question6')}:</StyledFormLabel>
            <label>
              <input
                disabled={true}
                id="LevelYesRadio"
                type="radio"
                name="level"
                checked={(data && data.level)? true : false}
                style={{marginLeft: '30px'}}
              />
              <span style={{marginLeft: '5px'}}> {t('Yes')} </span>
            </label>
            <label>
              <input
                disabled={true}
                id="LevelNoRadio"
                type="radio"
                name="level"
                checked={(data && !data.level)? true : false}
                style={{marginLeft: '50px'}}
              />
              <span style={{marginLeft: '5px'}}> {t('No')} </span>
            </label>
          </Row>
        </Form.Group>

        <StyledCheckSubtitle> {t('F6_AT_overall_objective')} </StyledCheckSubtitle>
        <Form.Check
          name="oo1"
          type="checkbox"
          checked={data? data.oo1: false}
          disabled={true}
          label={t('F6_AT_checkbox1')}
        />

        <Form.Check
          name="oo2"
          type="checkbox"
          checked={data? data.oo2: false}
          disabled={true}
          label={t('F6_AT_checkbox2')}
        />

        <StyledCheckSubtitle> {t('F6_AT_specific_objective')} </StyledCheckSubtitle>
        <Form.Check
          name="so3"
          type="checkbox"
          checked={data? data.so3: false}
          disabled={true}
          label={t('F6_AT_checkbox3')}
        />

        <Form.Check
          name="so4"
          type="checkbox"
          checked={data? data.so4: false}
          disabled={true}
          label={t('F6_AT_checkbox4')}
        />

        <StyledCheckSubtitle> {t('F6_AT_outputs')} </StyledCheckSubtitle>
        <Form.Check
          name="out5"
          type="checkbox"
          checked={data? data.out5: false}
          disabled={true}
          label={t('F6_AT_checkbox5')}
        />

        <Form.Check
          name="out6"
          type="checkbox"
          checked={data? data.out6: false}
          disabled={true}
          label={t('F6_AT_checkbox6')}
        />

        <Form.Check
          name="out7"
          type="checkbox"
          checked={data? data.out7: false}
          disabled={true}
          label={t('F6_AT_checkbox7')}
        />

        <Form.Check
          name="out8"
          type="checkbox"
          checked={data? data.out8: false}
          disabled={true}
          label={t('F6_AT_checkbox8')}
        />

        <Form.Check
          name="out9"
          type="checkbox"
          checked={data? data.out9: false}
          disabled={true}
          label={t('F6_AT_checkbox9')}
        />

        <StyledTitle>{t('F6_S18_subtitle_markers')}</StyledTitle>

        <StyledCheckSubtitle> {t('F6_AT_transversal')} </StyledCheckSubtitle>

        <Form.Check
          name="marker1"
          type="checkbox"
          checked={data? data.marker1: false}
          disabled
          label={t('F6_AT_marcador_checkbox1')}
        />

        <Form.Check
          name="marker2"
          type="checkbox"
          checked={data? data.marker2: false}
          disabled
          label={t('F6_AT_marcador_checkbox2')}
        />

        <Form.Check
          name="marker3"
          type="checkbox"
          checked={data? data.marker3: false}
          disabled
          label={t('F6_AT_marcador_checkbox3')}
        />

        <Form.Check
          name="marker4"
          type="checkbox"
          checked={data? data.marker4: false}
          disabled
          label={t('F6_AT_marcador_checkbox4')}
        />

        <StyledCheckSubtitle> {t('F6_AT_relevance')} </StyledCheckSubtitle>

        <Form.Check
          name="marker5"
          type="checkbox"
          checked={data? data.marker5: false}
          disabled
          label={t('F6_AT_marcador_checkbox5')}
        />

        <Form.Check
          name="marker6"
          type="checkbox"
          checked={data? data.marker6: false}
          disabled
          label={t('F6_AT_marcador_checkbox6')}
        />

        <Form.Check
          name="marker7"
          type="checkbox"
          checked={data? data.marker7: false}
          disabled
          label={t('F6_AT_marcador_checkbox7')}
        />

        <Form.Check
          name="marker8"
          type="checkbox"
          checked={data? data.marker8: false}
          disabled
          label={t('F6_AT_marcador_checkbox8')}
        />

        <StyledCheckSubtitle> {t('F6_AT_efficiency')} </StyledCheckSubtitle>

        <Form.Check
          name="marker9"
          type="checkbox"
          checked={data? data.marker9: false}
          disabled
          label={t('F6_AT_marcador_checkbox9')}
        />

        <Form.Check
          name="marker10"
          type="checkbox"
          checked={data? data.marker10: false}
          disabled
          label={t('F6_AT_marcador_checkbox10')}
        />

        <Form.Check
          name="marker11"
          type="checkbox"
          checked={data? data.marker11: false}
          disabled
          label={t('F6_AT_marcador_checkbox11')}
        />


        <StyledCheckSubtitle> {t('F6_AT_effectiveness')} </StyledCheckSubtitle>

        <Form.Check
          name="marker12"
          type="checkbox"
          checked={data? data.marker12: false}
          disabled
          label={t('F6_AT_marcador_checkbox12')}
        />


        <Form.Check
          name="marker13"
          type="checkbox"
          checked={data? data.marker13: false}
          disabled
          label={t('F6_AT_marcador_checkbox13')}
        />

        <Form.Check
          name="marker14"
          type="checkbox"
          checked={data? data.marker14: false}
          disabled
          label={t('F6_AT_marcador_checkbox14')}
        />

        <Form.Check
          name="marker15"
          type="checkbox"
          checked={data? data.marker15: false}
          disabled
          label={t('F6_AT_marcador_checkbox15')}
        />

        <Form.Check
          name="marker16"
          type="checkbox"
          checked={data? data.marker16: false}
          disabled
          label={t('F6_AT_marcador_checkbox16')}
        />

        <StyledCheckSubtitle> {t('F6_AT_impact')} </StyledCheckSubtitle>

        <Form.Check
          name="marker17"
          type="checkbox"
          checked={data? data.marker17: false}
          disabled
          label={t('F6_AT_marcador_checkbox17')}
        />

        <StyledCheckSubtitle> {t('F6_AT_sustainability')} </StyledCheckSubtitle>

        <Form.Check
          name="marker18"
          type="checkbox"
          checked={data? data.marker18: false}
          disabled
          label={t('F6_AT_marcador_checkbox18')}
        />

        <Form.Check
          name="marker19"
          type="checkbox"
          checked={data? data.marker19: false}
          disabled
          label={t('F6_AT_marcador_checkbox19')}
        />
      </div>
    </div>
  )
}

export default ReadOnlyATAssessment;