import React, { useState, useEffect } from 'react'
import { Formik, FieldArray } from 'formik';
import { formInitialValues } from './ProductsForm.initialValues'
import { validationSchema } from './ProductsForm.validations';
import { useTranslation } from 'react-i18next';
import { useConstantsForm3 } from 'customHooks/useConstantsForm3';
import { useParams } from 'react-router-dom';
import { saveProductsF3S7 } from 'services/initiative';

import ProductForm from './ProductForm';
import ConfirmCloseModal from 'components/ConfirmCloseModal';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import { PrimaryButton, ErrorButton, StyledSendButton } from 'components/Buttons';
import "react-datepicker/dist/react-datepicker.css";
import { Form } from 'react-bootstrap';
import { FormFielErrorMessage, SeparatorDotted, CenteredModalButtonContainer } from 'components/CommonStyled';

const productObject = {
  productTypeId: "",
  name: "",
  dueDate: "",
  description: ""
}

const ProductsForm = ({ handleCloseOk, handleClose, activity, handleCloseSubModalOnSave, handleCloseSubModalOnError, nonEditable }) => {

  const { t } = useTranslation();
  const { id } = useParams();
  const { productTypes } = useConstantsForm3();
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [initialValues, setInitialValues] = useState(formInitialValues);

  const openModalRemove = (key) => {
    setModalRemove(true);
    setSelectedKey(key);
  }

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  }

  useEffect(() => {
    const formValues = {...formInitialValues, ...activity};
    formValues.productList.forEach((product) => {
      product.dueDate = product.dueDate? new Date(product.dueDate): "";
      product.productTypeId = product.productType? product.productType.id: "";
    });
    setInitialValues(formValues);
  }, [])

  const onSave = async (values) => {
    try {
      await saveProductsF3S7(id, activity.id, values.productList);
      handleCloseSubModalOnSave();
    } catch (error) {
      handleCloseSubModalOnError();
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSave}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <FieldArray 
              name='productList'
              render={arrayHelpers => (
                <>
                  {modalRemove && (
                    <ConfirmCloseModal
                      show={modalRemove}
                      handleCloseConfirm={closeModalRemove}
                      handleActionOnConfirm={() => {
                        arrayHelpers.remove(selectedKey);
                        closeModalRemove();
                      }}
                      text={`Delete product ${selectedKey+1}?`}
                    />
                  )}
                  <Accordion>
                    {props.values.productList
                      ? ( props.values.productList.map((product, key) => (
                          <AccordionItem key={key+1}>
                            <AccordionItemHeader>
                              <AccordionTitle eventKey={key+1}>{t('F3_S7_Accordion_product')+" "+(key+1)}</AccordionTitle>
                            </AccordionItemHeader>
                            <Accordion.Collapse eventKey={key+1}>
                              <AccordionItemBody>
                                <ProductForm formikInstance={props} productIndex={key} productTypes={productTypes} nonEditable={nonEditable} /> 
                                <ErrorButton disabled={nonEditable} onClick={() => openModalRemove(key)}>{t('F3_S7_Product_button_delete')}</ErrorButton>
                              </AccordionItemBody>
                            </Accordion.Collapse>
                          </AccordionItem>
                        )))
                      : null}
                  </Accordion>
                  { 
                    typeof props.errors.productList === 'string' 
                      ? <FormFielErrorMessage>{props.errors.productList}</FormFielErrorMessage> 
                      : null
                  }
                  <SeparatorDotted />
                  <PrimaryButton disabled={nonEditable} onClick={() => arrayHelpers.push(productObject)} >{t('F3_S7_Product_button_add')}</PrimaryButton>
                </>
              )}
            />

            <CenteredModalButtonContainer>
              {
                nonEditable
                ?
                  <PrimaryButton onClick={() => {handleCloseOk()}}> OK </PrimaryButton>
                :
                  <>
                    <ErrorButton style={{marginRight: '20px'}} onClick={handleClose} disabled={nonEditable}> {t('Button_cancel')} </ErrorButton>
                    <StyledSendButton onClick={() => {onSave(props.values)}} disabled={nonEditable}> {t('Button_save')} </StyledSendButton>
                  </>
              }
            </CenteredModalButtonContainer>

          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ProductsForm;