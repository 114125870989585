import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { formatDateDDMMYY } from 'helpers/dateHelpers';
import { useRootStore } from 'stores';
import { formInitialValues } from './Section7.initialValues';
import { useConstantsForm1 } from 'customHooks/useConstantsForm1';
import FormLayout from '../../FormLayout';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem, AccordionItemHeader, AccordionTitle, AccordionItemBody } from 'components/CustomAccordion';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FormContainer from 'components/FormContainer';
import ActivityForm from './ActivityForm';
import TextValue from 'components/TextValue';
import {
  StyledIntroParagraph,
  StyledBudgetDiv,
  StyledSeparator,
  StyledSubtitle,
  TextValueLeftSpaced,
} from './Section7.styled';
import RequestClarification from '../RequestClarification';
import { useTranslation } from 'react-i18next';

const Section7 = () => {
  const { t } = useTranslation();
  const { formStore } = useRootStore();
  const { countries, activityTypes } = useConstantsForm1();
  const [saved, setSaved] = useState(false);
  const [formValues, setFormValues] = useState(formInitialValues);
  const [nonEditable, setNonEditable] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formStore.formSectionData) {
      const formSectionData = { ...formInitialValues, ...formStore.formSectionData[0] };
      setFormValues(formSectionData);
    }
    formStore.setCurrentSection(7);
  }, [formStore.formSectionData]);

  const handleSaved = value => {
    setSaved(value);
  };
  const handleButton = value => {
    setNonEditable(value);
  };

  return (
    <FormLayout>
      <FormContainer
        number={7}
        title={t('F1_S7_title')}
        formId="form2section7"
        showSavedMessage={false}
        showSavedMessageF2={saved}
        showButtonClarif={!nonEditable}
        showFooter={false}
        buttonText="SAVE CLARIFICATION REQUEST"
      >
        <StyledIntroParagraph>{t('F1_S7_intro')}</StyledIntroParagraph>

        <StyledBudgetDiv>
          <StyledSubtitle style={{ textAlign: 'center' }}>{t('F1_S7_Title_aggregated_planning')}</StyledSubtitle>

          <Form.Group>
            <Form.Label as={Row} style={{ marginBottom: '20px', textAlign: 'justify', lineHeight: '1.3' }}>
              {t('F1_S7_Intervention_methodology')}:
            </Form.Label>
            <TextValue style={{ marginLeft: '30px' }} value={formValues.methodology} />
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label xs={4}>{t('F1_S7_Estimated_start_day')}:</Form.Label>
            <TextValueLeftSpaced>
              <TextValue value={formValues.estFromDate.split('T')[0]} />
            </TextValueLeftSpaced>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label xs={4}>{t('F1_S7_Estimated_end_day')}:</Form.Label>
            <TextValueLeftSpaced>
              <TextValue value={formValues.estToDate.split('T')[0]} />
            </TextValueLeftSpaced>
          </Form.Group>

          <Form.Label lg={8} as={Row} style={{ marginBottom: '40px', marginTop: '40px' }}>
            {t('F1_S7_Total_budget_TCI')}
          </Form.Label>

          <Form.Group as={Row}>
            <Form.Label xs={4}>{t('F1_S7_EU_budget')}:</Form.Label>
            <TextValueLeftSpaced>
              <TextValue
                value={formValues.activities.map(item => item.ueBudget).reduce((prev, next) => prev + next, 0) + ' €'}
              />
            </TextValueLeftSpaced>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label xs={4}>{t('F1_S7_counterpart_budget')}:</Form.Label>
            <TextValueLeftSpaced>
              <TextValue
                value={
                  formValues.activities.map(item => item.counterPartBudget).reduce((prev, next) => prev + next, 0) +
                  ' €'
                }
              />
            </TextValueLeftSpaced>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label xs={4}>{t('F1_S7_total_budget')}:</Form.Label>
            <TextValueLeftSpaced>
              <TextValue
                value={
                  formValues.activities.map(item => item.ueBudget).reduce((prev, next) => prev + next, 0) +
                  formValues.activities.map(item => item.counterPartBudget).reduce((prev, next) => prev + next, 0) +
                  ' €'
                }
              />
            </TextValueLeftSpaced>
          </Form.Group>
        </StyledBudgetDiv>

        <Accordion>
          {formValues.activities &&
            formValues.activities.map((activity, key) => (
              <AccordionItem key={key + 1}>
                <AccordionItemHeader>
                  <AccordionTitle eventKey={key + 1}>
                    {t('F1_S7_Accordion') + '  '}
                    {activity.name + '  '}
                    {activity.activityType ? ` - ${t(`activity_types_${activity.activityType.id}`)}` : ' '}
                    {activity.fromDate ? ` (${formatDateDDMMYY(activity.fromDate)})` : ' '}
                  </AccordionTitle>
                </AccordionItemHeader>
                <Accordion.Collapse eventKey={key + 1}>
                  <AccordionItemBody>
                    <ActivityForm
                      activityIndex={key}
                      activityTypes={activityTypes}
                      countries={countries}
                      data={activity}
                    />
                  </AccordionItemBody>
                </Accordion.Collapse>
              </AccordionItem>
            ))}
        </Accordion>

        <StyledSeparator />
        <RequestClarification onSaved={handleSaved} onButton={handleButton} />
      </FormContainer>
    </FormLayout>
  );
};

export default observer(Section7);
