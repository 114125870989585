import React from 'react'
import { useTranslation } from 'react-i18next'
import { getEntitiesByCountrySummaryExportValues, getFundsEntitiesByCountrySummaryExportValues } from '../helpers/helpers'

import {
  Col2,
} from '../../CommonStyled/Components.styled'
import { ExportStatisticsByYearsToXLSX } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'

const Header1 = ({ data, data2, waiting }) => {
  const { t } = useTranslation()

  return (
    <Col2>
      <ExportToXLSXButton
        data={data}
        mapData={getEntitiesByCountrySummaryExportValues}
        data2={data2}
        mapData2={getFundsEntitiesByCountrySummaryExportValues}
        disabled={waiting}
        exportName={t('Statistics_entities_tab4')}
      >
        <ExportStatisticsByYearsToXLSX label={t('Statistics_entities_tab4')} data={[]} />
      </ExportToXLSXButton>
    </Col2>
  )
}

export default Header1
