import styled, { css } from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

export const StyledTitle = styled.h5`
  ${({ theme }) => css`
    color: ${theme.brandColors.blue};
  `}
`;

export const StyledRow = styled(Row)`
 width: 100%;
 min-height: 120px;
 border-width: 3px;
 border-style: dotted none none none;
 padding-top: 10px;
 margin-left: 0px;
`;

export const StyledCol = styled(Col)`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: flex-end; 
        color: ${theme.brandColors.green} 
    `}
`;

export const StyledColTitle = styled(Col)`
 display: flex;
 align-items: center;
 justify-content: center; 
 margin-bottom: 15px;
 text-align: center;
`;

export const StyledText = styled.p`
   color: black;
   text-align: justify;
`;

export const StyledDiv = styled.div`
   width: 100%;
   margin-right: 15px;
   padding-left: 15px; 
   margin-top: 10px;
`;

export const StyledButtonLink = styled(Button)`
  padding: 0;
  border: none;
  background: none;
  box-shadow: none !important;
  margin-bottom: 4px;
`;

export const StyledDivTotal = styled.div`
    ${({ theme }) => css`
        border: 2px solid ${theme.brandColors.blue};
    `} 
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    display: flex;
`;

export const StyledNotice = styled.p`
    color: red;
    margin-left: 10px;
`;
