import i18n from 'i18n'
import { Td } from '../../CommonStyled/Components.styled'
import _map from 'lodash/map'
import _fill from 'lodash/fill'
import { renderTotals, total } from 'pages/statistics/CommonHelpers/helpers'

export const table2TransformData = (data, years) => {
  let rows = {
      submitted: [],
      approved: [],
      notApproved: [],
      pendingReview: [],
    },
    aux = []

  for (let i = 0; i < years.length; i++) {
    aux = data.filter((item) => item.year == years[i])[0]
    rows.submitted[i] = aux ? aux.submitted : '-'
    rows.approved[i] = aux ? aux.approved : '-'
    rows.notApproved[i] = aux ? aux.notApproved : '-'
    rows.pendingReview[i] = aux ? aux.pendingReview : '-'
  }
  return rows
}

export const renderRows = (row, length) => {
  if (row) {
    let aux = []
    for (let i = 0; i <= length - 1; i++)
      aux.push(<Td>{row[i] ? row[i] : row[i] == 0 ? 0 : '-'}</Td>)
    aux.push(renderTotals(row))
    return aux
  }
}

export const getFormulationExportValues = (exportData) => {
  let data = []

  data.push({
    label: i18n.t('Submitted'),
    col1: exportData.submitted[0],
    col2: exportData.submitted[1],
    col3: exportData.submitted[2],
    col4: exportData.submitted[3],
    col5: total(exportData.submitted),
  })
  data.push({
    label: i18n.t('Pending_review'),
    col1: exportData.pendingReview[0],
    col2: exportData.pendingReview[1],
    col3: exportData.pendingReview[2],
    col4: exportData.pendingReview[3],
    col5: total(exportData.pendingReview),
  })
  data.push({
    label: i18n.t('Not_approved'),
    col1: exportData.notApproved[0],
    col2: exportData.notApproved[1],
    col3: exportData.notApproved[2],
    col4: exportData.notApproved[3],
    col5: total(exportData.notApproved),
  })
  data.push({
    label: i18n.t('Approved'),
    col1: exportData.approved[0],
    col2: exportData.approved[1],
    col3: exportData.approved[2],
    col4: exportData.approved[3],
    col5: total(exportData.approved),
  })

  return data
}
