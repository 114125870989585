import * as Yup from 'yup';
import i18n from 'i18n';

export const validationSchema = Yup.object().shape({
	providerId: Yup.string().required("F3_S7_validation3"),
	totalAmount: Yup.number()
    .min(0, 'F1_S7_validation10')
    .required('F1_S7_validation11'),
	file: Yup.object().nullable(),
	comments: Yup.string()
    .min(2,  'F3_S7_validation4')
    .max(1300, 'Error max length 1300')
    .nullable()
});