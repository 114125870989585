import React, { useState, useEffect } from 'react';
import { getFormSection } from 'services/initiative';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { initialValues } from './constants';

import Section1 from './Section1/Section1';
import Section2 from './Section2/Section2';
import Section3 from './Section3/Section3';
import Section4 from './Section4/Section4';
import Section5 from './Section5/Section5';
import Section6 from './Section6/Section6';
import Section7 from './Section7/Section7';
import Section8 from './Section8/Section8';

const F1PDFGen = ({ initiativeId, handleError, onCreatePDF }) => {
  const [sectionsData, setSectionsData] = useState(initialValues);
  
  useEffect(() => {
    fetchSections();
  }, []);

  useEffect(() => {
    if(sectionsData.ready){
      generatePDF();
    }
  }, [sectionsData]);


  const generatePDF = () => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      const divToPrint = document.getElementById('pdfTargetDiv');
      html2canvas(divToPrint).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 190;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        const doc = new jsPDF('pt', 'mm');
        let position = 0;
        doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
            heightLeft -= pageHeight;
        }
        doc.save(`concept-note-${initiativeId}.pdf`);
        onCreatePDF();
        setSectionsData(initialValues);
      });
    }, 1500);
  };

  const fetchSections = async () => {
    let response;
    let sectionDataCopy = {...sectionsData};
    try {
      for (let i = 0; i < 9; i++) {
        response = await getFormSection(initiativeId, 1, i+1);
        sectionDataCopy[`section${i+1}Data`] = response.section;
      }
      sectionDataCopy['ready'] = true;
      setSectionsData(sectionDataCopy);
    } catch (e) {
      handleError();
    }
  };

  return (
    <div id='pdfTargetDiv'>
      <Section1 data={sectionsData.section1Data} />
      <Section2 data={sectionsData.section2Data} />
      <Section3 data={sectionsData.section3Data} /> 
      <Section4 data={sectionsData.section4Data} /> 
      <Section5 data={sectionsData.section5Data} /> 
      <Section6 data={sectionsData.section6Data} /> 
      <Section7 data={sectionsData.section7Data} /> 
      <Section8 data={sectionsData.section8Data} /> 
    </div>
  )
};

export default F1PDFGen;
