import React from 'react';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
import { RequiredMark } from 'components/CommonStyled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';


const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  const getFieldTouched = getIn(formikInstance.touched, field);
  return {
    touched: getFieldTouched,
    error: (
      <FormErrorMessage
        touched={getFieldTouched}
        errors={getFieldError}
      />
    )
  }
}
  
const ProductForm = ({ formikInstance, productIndex, productTypes, nonEditable }) => {

  const {t} = useTranslation();
  const { getFieldProps, touched, errors, values, setFieldValue} = formikInstance;
  const fieldPath = (field) => `productList.${productIndex}.${field}`;

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F3_S7_Product_type')}<RequiredMark>*</RequiredMark>:</Form.Label>
        <Col lg={8}>
          <Form.Control
            name={fieldPath('productTypeId')}
            as="select"
            { ...getFieldProps(fieldPath('productTypeId')) }
            className={touched[fieldPath('productTypeId')] && errors[fieldPath('productTypeId')] ? "error" : null}
            disabled={nonEditable}
          >
            <option value="">{t('Placeholder_select')}</option>
            {productTypes.map(product => (
              <option key={product.id} value={product.id}>{t(`product_type_${product.id}`)}</option>
            ))}
          </Form.Control>
          {fieldInfo(formikInstance, fieldPath('productTypeId')).error}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F3_S7_Product_title')}<RequiredMark>*</RequiredMark>:</Form.Label>
        <Col lg={8}>
          <Form.Control
            type="text"
            name={fieldPath('name')}
            { ...getFieldProps(fieldPath('name')) }
            className={touched[fieldPath('name')] && errors[fieldPath('name')] ? "error" : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('name')).error}
        </Col>
      </Form.Group>  

      <Form.Group as={Row}>
        <Form.Label column lg={4}>{t('F3_S7_Product_Due_date')}<RequiredMark>*</RequiredMark>:</Form.Label>
        <Col>
          <InputGroup style={{ marginLeft: '0', marginTop: '5px' }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={values.productList[productIndex].dueDate}
              onChange={(date) => { setFieldValue(fieldPath('dueDate'), date) }}
              className={touched[fieldPath('dueDate')] && errors[fieldPath('dueDate')] ? "error" : null}
              disabled={nonEditable}
            />
          </InputGroup>
          {fieldInfo(formikInstance, fieldPath('dueDate')).error}
        </Col>
      </Form.Group> 

      <Form.Group as={Row}>
        <Col>
          <Form.Label lg={4} style={{ alignItems: "center" }} >{t('F3_S7_Product_Description')} <RequiredMark style={{ marginLeft: "7px" }}>*</RequiredMark>:
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_650')}
            name={fieldPath('description')}
            {...getFieldProps(fieldPath('description'))}
            className={touched[fieldPath('description')] && errors[fieldPath('description')] ? "error" : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('description')).error}
        </Col>
      </Form.Group>

    </>
  )

}

export default ProductForm;
