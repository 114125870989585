import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useRootStore } from 'stores';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { getValidationSchemaByRole } from './ExpostReportForm.helper';
import { modifyF8b, modifyF9a, modifyF9c } from 'services/initiative';
import { expostStatesConst } from 'constants/appConstants';

import { StyledDivWrapper } from 'components/FormContainer/FormContainer.styled';
import PageTitle from 'components/PageTitle';
import Footer from './Footer';
import AtkeConsortiumForm from './AtkeConsortiumForm/AtkeConsortiumForm';
import EudForm from './EudForm/EudForm';
import IntpaForm from './IntpaForm/IntpaForm';
import Header from './Header/Header';

import { roleNamesConst } from 'constants/appConstants';

import EUDAssessments from './EUDAssessments'

const ExpostReporting = () => {
  const { t } = useTranslation();
  const [initiativeCode, setInitiativeCode] = useState("");
  const { authStore } = useRootStore();
  const [saved, setSaved] = useState(false);
  const [nonEditable, setNonEditable] = useState(false);
  const [formInitialValues, setFormInitialValues ]= useState({});
  const role = authStore.roles[0] === (roleNamesConst.CONSORTIUM || roleNamesConst.ATKE) ? 0 : authStore.roles[0] === roleNamesConst.EUD ? 1 : authStore.roles[0] === roleNamesConst.INTPA && 2;
  const { formId } = useParams();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let initiative = params.get('initiative');
    setInitiativeCode(initiative);
  }, [])

  const onSubmitF8b = async (values, { resetForm }) => {
    try {
      await modifyF8b(formId,values);
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
      setSaved(false);
    }
  };

  const onSubmitF9a = async (values, {resetForm}) => {
    try {
      await modifyF9a(formId,values);
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
      setSaved(false);
    }
  };

  const onSubmitF9c = async (values, {resetForm}) => {
    try {
      await modifyF9c(formId, values);
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
      setSaved(false);
    }
  };

  const getSubmitFunctionByRole = () => {
    if (role == 0) {
      return onSubmitF8b;
    } else if (role == 1) {
      return onSubmitF9a;
    } else if (role == 2) {
      return onSubmitF9c;
    }
  };

  const validationSchema = getValidationSchemaByRole(role);
  const submitFunction = getSubmitFunctionByRole();

  return (
    <>
      <Formik 
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={submitFunction}
      >
        <>
          <PageTitle title={`${t('F8/F9_main_title')} #${initiativeCode}`} />
          <div style={{paddingRight: '300px'}}>
            <StyledDivWrapper>
              <Header role={role} saved={saved} nonEditable={nonEditable}/>
              {role == 0  && <AtkeConsortiumForm 
                                setSaved={setSaved} 
                                saved={saved} 
                                nonEditable={nonEditable} 
                                setNonEditable={setNonEditable}
                                setFormInitialValues={setFormInitialValues}
                              />
              }
              {role == 1  && <EudForm 
                                setSaved={setSaved} 
                                saved={saved} 
                                nonEditable={nonEditable} 
                                setNonEditable={setNonEditable}
                                setFormInitialValues={setFormInitialValues}
                              />
              }
              {role == 2  && <IntpaForm 
                                setSaved={setSaved} 
                                saved={saved} 
                                nonEditable={nonEditable} 
                                setNonEditable={setNonEditable}
                                setFormInitialValues={setFormInitialValues}
                              />
              }

              {role == 0 || role == 1 ? (
                <EUDAssessments
                  F9AValues={formInitialValues?.form9aList} 
                  F9CValues={formInitialValues?.form9c} 
                  initiativeCode={initiativeCode} 
                />
              ) : null}

              <Footer
                role={role} 
                saved={saved} 
                nonEditable={nonEditable} 
                f8aDone={formInitialValues?.form8?.status?.stateEnum !== expostStatesConst.DONE || formInitialValues?.form8?.status?.stateEnum !== expostStatesConst.SENT} 
              /> 
            </StyledDivWrapper>
          </div>
        </>
      </Formik>
    </>
  )
};

export default ExpostReporting;
