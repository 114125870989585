import React from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { Th3, Table, Th1 } from '../../CommonStyled/Components.styled'
import Spinner from 'react-bootstrap/Spinner'
import { T3RenderRows } from '../helpers/helpers'
import { StyledRow2 } from '../../CommonStyled/Tabs.styled'

const Table3 = ({ data, waiting }) => {
  const { t } = useTranslation()

  return waiting ? (
    <Spinner animation="border" variant="secondary" />
  ) : data && !isEmpty(data) ? (
    <StyledRow2 style={{ maxWidth: '60%'}}>
      <b>{t('triang_coop_funds_proj')}</b>
      <Table style={{ width: '100%'}}>
        <tr>
          <Th3 style={{ width: '25%' }}>
            {t(t('Statistics_execution_tab1'))}
          </Th3>
          <Th1 scope="col" style={{ width: '5%' }}></Th1>
        </tr>
        {T3RenderRows(data)}
      </Table>
    </StyledRow2>
  ) : (
    t('No_data_available_2')
  )
}

export default Table3
