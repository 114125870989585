import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateDDMMYY } from 'helpers/dateHelpers';
import { handleLabel } from 'helpers/f5Helpers';

import FakeAccordionItem from '../../FakeAccordionItem';
import { RequiredMark, SeparatorDotted } from 'components/CommonStyled';
import { StyledCheck } from 'components/Forms/Form5/Section7/ParticipantsForm/ParticipantForm/ParticipantForm.styled'
import TextValue from 'components/TextValue';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { StyledTitle } from '../CommonStyles';

const Section7Participants = ({ activity, data }) => {
  const {t} = useTranslation();
  const [formValues, setFormValues] = useState([]);

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data]);

  return (
    <div>
      <StyledTitle style={{marginBottom: '30px'}}> {t('F1_S7_Accordion')} - {activity ? activity.name : ''} - {t('F3_S7_Subtitle_Participants_assistance')} </StyledTitle>

      {(formValues && formValues.participantList.map((participant, key) => (
        <React.Fragment  key={key}>
          <FakeAccordionItem title={handleLabel(participant)} >
            <div style={{marginLeft: '50px'}}>
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column lg={4}>{t('F1_S2_First_name')}<RequiredMark>*</RequiredMark>:</Form.Label>
                    <TextValue value={participant.firstName} />
                  </Form.Group>  
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column lg={4}>{t('F1_S2_Last_Name')}<RequiredMark>*</RequiredMark>:</Form.Label>
                    <TextValue value={participant.lastName} />
                  </Form.Group>  
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column lg={4}>{t('F3_S7_Participant_nationality')}<RequiredMark>*</RequiredMark>:</Form.Label>
                    <TextValue value={participant.nationality ? t(participant.nationality.name) : ''} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column lg={4}>{t('F3_S7_Participant_city_departure')}<RequiredMark>*</RequiredMark>:</Form.Label>
                    <TextValue value={participant.city} />
                  </Form.Group>  
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column lg={4}>{t('F3_S7_Participant_organization')}<RequiredMark>*</RequiredMark>:</Form.Label>
                    <TextValue value={participant.organizationName} />
                  </Form.Group>  
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column lg={4}>{t('F3_S7_Participant_type_entity')}<RequiredMark>*</RequiredMark>:</Form.Label>
                    <TextValue value={participant.entityType ? t(`entity_type_${participant.entityType.id}`) : ''} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column lg={4}>{t('F1_S2_Email')}<RequiredMark>*</RequiredMark>:</Form.Label>
                    <TextValue value={participant.email} />
                  </Form.Group>  
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column lg={4}>{t('F1_S2_Phone_number')}<RequiredMark>*</RequiredMark>:</Form.Label>
                    <TextValue value={participant.phone} />
                  </Form.Group>  
                </Col>
              </Row>

              <StyledCheck style={{marginTop: '20px'}}>
                <Form.Group>
                  {t('F5_S7_Participant_confirmation_check1')}
                  <Row style={{marginTop: '10px'}}>
                    <label>
                      <Form.Check
                        style={{marginLeft: '15px'}} 
                        type="checkbox" 
                        checked={participant.willAttend && participant.confirmed}
                        label={t('F5_S7_Participant_attend')} 
                      />
                    </label>
                    <label>
                      <Form.Check
                        style={{marginLeft: '15px'}} 
                        type="checkbox" 
                        checked={!participant.willAttend && participant.confirmed}
                        label={t('F5_S7_Participant_Not_attend')} 
                      />
                    </label>
                  </Row>
                  {t('F5_S7_Participant_confirmation_check2')}
                </Form.Group>
              </StyledCheck>

              {participant.confirmed 
                &&
                <div style={{marginTop: '10px'}}>
                  {`${t('F5_S7_Confirmed')}: ${participant.confirmTimestamp? formatDateDDMMYY(participant.confirmTimestamp): ""}`}
                </div>
              }
            </div>
          </FakeAccordionItem>
          <SeparatorDotted />
        </React.Fragment>
      )))}
    </div>
  )

}

export default Section7Participants;
