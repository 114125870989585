import React from 'react';
import { Link } from 'react-router-dom';
import { useRootStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { roleNamesConst, f3statusNamesConst, f4statusNamesConst } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { formatDateDDMMYY } from 'helpers/dateHelpers';

import DeadlineButton from 'components/DeadlineButton';
import { StyledTr, StyledSpan } from './TableRow.styled';
import NewMessagesTableIndicator from 'components/NewMessagesTableIndicator';
import { NewMessagesTableContainer } from 'components/Forms/Forms.styled';

const TableRow = ({ item, setdeadlineModalData }) => {
  const { initiativeStore, authStore } = useRootStore();

  const setInitiativeOnStore = initiative => {
    initiativeStore.setInitiativeData(initiative);
  };

  const todayDate = new Date();
  const baseRoute = authStore.getBaseRouteByRole();
  const { t } = useTranslation();

  return (
    <StyledTr key={item.id}>
      <td style={{ minWidth: '100px' }}>
        <Link
          onClick={() => setInitiativeOnStore(item)}
          to={`${baseRoute}/initiative/${item.id}/form/4/section/1`}
        >
          {item.code}
        </Link>
      </td>
      <td style={{ maxWidth: '550px', overflowWrap: 'break-word' }}>
        {item.name ? item.name : t('Name_not_defined_yet')}
      </td>
      <td style={{ minWidth: '100px' }}>
        {t(item.formStateName) +
          (item.formStateName === f3statusNamesConst.DRAFT ||
          item.formStateName === f3statusNamesConst.SENT
            ? ' v' + item.formVersion
            : '')}
      </td>
      <td>
        {item.formDeadline ? (
          <>
            {formatDateDDMMYY(item.formDeadline)}
            {authStore.roles && authStore.roles[0] === roleNamesConst.INTPA && (
              <DeadlineButton
                onClick={() => {
                  setdeadlineModalData({
                    show: true,
                    initiativeId: item.id,
                    deadlineDateString: item.formDeadline,
                  });
                }}
              />
            )}
          </>
        ) : (
          ' - '
        )}
      </td>
      {!(
        authStore.roles[0] === roleNamesConst.ATNKE ||
        authStore.roles[0] === roleNamesConst.CONSORTIUM
      ) && (
        <>
          <td>
            {item.assessmentStatus && item.formStateName !== f3statusNamesConst.DRAFT
              ? item.assessmentStatus +
                (item.assessmentStatus !== f4statusNamesConst.DONE &&
                item.assessmentStatus !== f4statusNamesConst.EXPIRED &&
                item.formVersion === 2
                  ? ' v2'
                  : '')
              : ' - '}
          </td>

          <td>
            {item.assessmentDeadline && item.formStateName !== f3statusNamesConst.DRAFT ? (
              <StyledSpan expired={todayDate > new Date(item.assessmentDeadline)}>
                {formatDateDDMMYY(item.assessmentDeadline)}
              </StyledSpan>
            ) : (
              ' - '
            )}
          </td>
          <td>
            <NewMessagesTableContainer>
              <NewMessagesTableIndicator hasNewMessages={item.unreadComments > 0} />
            </NewMessagesTableContainer>
          </td>
        </>
      )}
    </StyledTr>
  );
};

export default observer(TableRow);
