import React from 'react';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextValue from 'components/TextValue';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { RequiredMark } from 'components/CommonStyled';
import FileUploading from './FileUploading';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  const getFieldTouched = getIn(formikInstance.touched, field);
  return {
    touched: getFieldTouched,
    error: (
      <FormErrorMessage
        touched
        errors={getFieldError}
      />
    )
  };
};

const ProductForm = ({ formikInstance, productIndex, setModalInfo, validFileFormats, nonEditable }) => {
  const { t } = useTranslation();
  const params = useParams();
  const { getFieldProps, touched, errors, values, setFieldValue, handleChange } = formikInstance;
  const fieldPath = field => `productList.${productIndex}.${field}`;

  const currentProductReference = values.productList[productIndex];

  const getFileUploading = value => {
    let elements = [];
    for (var i = 0; i < value; i++) {
      elements.push(
        <FileUploading
          setModalInfo={setModalInfo}
          params={params}
          validFileFormats={validFileFormats}
          handleChange={handleChange}
          currentProductReference={currentProductReference}
          setFieldValue={setFieldValue}
          fieldPath={fieldPath}
          productIndex={productIndex}
          fileId={i}
          nonEditable={nonEditable}
        />,
      );
    }
    return elements;
  };

  return (
    <div style={{ marginLeft: '30px' }}>
      <>
        <Form.Group as={Row}>
          <Form.Label column xs={2}>
            {t('F3_S7_Product_type')}:
          </Form.Label>
          <TextValue
            value={
              currentProductReference.productType && currentProductReference.productType.id
                ? t(`product_type_${currentProductReference.productType.id}`)
                : '-'
            }
          />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column xs={2}>
            {t('F3_S7_Product_title')}:
          </Form.Label>
          <TextValue value={currentProductReference.name} />
        </Form.Group>

        <Form.Group>
          <Form.Label style={{ marginRight: '50px' }}>{t('F3_S7_Product_Due_date')}:</Form.Label>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={currentProductReference.dueDate}
            onChange={date => setFieldValue(fieldPath('dueDate'), date)}
            className={touched[fieldPath('dueDate')] && errors[fieldPath('dueDate')] ? 'error' : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('dueDate')).error}
        </Form.Group>

        <Form.Group>
          <Form.Label style={{ textAlign: 'justify', lineHeight: '1.3' }}>{t('F3_S7_Product_Description')}:</Form.Label>
          <TextValue value={currentProductReference.description} />
        </Form.Group>

        {getFileUploading(3)}

        <Form.Group>
          <Form.Label lg={4} style={{ alignItems: 'center' }}>
            {t('F5_S7_Products_document_comments')}
            <RequiredMark style={{ marginLeft: '7px' }}>*</RequiredMark>:
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            style={{ resize: 'none', marginRight: '18px' }}
            placeholder={t('Maximum_650')}
            name={fieldPath('comments')}
            {...getFieldProps(fieldPath('comments'))}
            className={touched[fieldPath('comments')] && errors[fieldPath('comments')] ? 'error' : null}
            disabled={nonEditable}
          />
          {fieldInfo(formikInstance, fieldPath('comments')).error}
        </Form.Group>
      </>
    </div>
  );
};

export default ProductForm;
