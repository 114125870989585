import React from 'react';
import { form1Dictionary } from './form1Dictionary';
import { form2Dictionary} from './form2Dictionary';
import { form3Dictionary} from './form3Dictionary';
import { form4Dictionary} from './form4Dictionary';
import { form55Dictionary } from './form55Dictionary';
import { form5Dictionary} from './form5Dictionary';
import SectionNotFound from 'components/Forms/Form1/SectionNotFound';

const formsSectionsDictionary = {
  1: form1Dictionary,
  2: form2Dictionary,
  3: form3Dictionary,
  4: form4Dictionary,
  5: form5Dictionary,
  55: form55Dictionary,
}

export const getFormSectionComponent = (form, section) => {
  const formSection = formsSectionsDictionary[form][section];
  return formSection ? formSection : <SectionNotFound />
}
