import DayJS from 'dayjs';
import i18n from 'i18n';

export const formatDateDDMMYY = (date) => {
  return date ? DayJS(date).format("DD/MM/YYYY") : '';
};

export const formatDateWithHour = (date) => {
  return date? DayJS(date).format('DD/MM/YYYY HH:mm') : '';
};

export const formatDateWithHourAndSecs = (date) => {
  return date? DayJS(date).format("DD/MM/YYYY HH:mm:ss") : '';
};

//Parse the dates this way in order to avoid the "day off" bug reported on SA2-523
//type of date : string
export const parseDateString = (date) => {
  const nonParsedDate = new Date(date);
  return new Date( nonParsedDate.getTime() + Math.abs(nonParsedDate.getTimezoneOffset()*60000));
};

export const renderYears = () => {
  var indents = [];
  indents.push(<option value={0}> {i18n.t('ALL')} </option>);
  for (var i = 2021; i < 2025; i++) {
    indents.push(<option value={i}> {i} </option>);
  }
  return indents;
};