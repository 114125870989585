import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { intpaF1DeadlineChange } from 'services/initiative';

import CustomModal from "components/Modal";
import Modal from 'react-bootstrap/Modal';
import {PrimaryButton, ErrorButton} from 'components/Buttons';
import { CenteredModalButtonContainer, FormFielErrorMessage } from 'components/CommonStyled';
import DatePicker from "react-datepicker";
import { ModalBodyDiv } from './DeadlineChangeModal.styled';

const DeadlineChangeModal = ({ show, onClose, initiativeId, formId, deadlineDateString, handleRefreshList }) => {
  const {t} = useTranslation();
  const [waiting, setWaiting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [errorVisible, setErrorVisible] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
    type: "error",
  });

  const onHideModal = () => {
    setModal({ ...modal, isOpen: false });
  };

  const getInitialDate = (deadlineDate) => {
    const today = new Date();
    return ((deadlineDate > today)? deadlineDate : today.setDate(today.getDate() + 1) );
  }

  const onSave = async () => {
    setWaiting(true)
    try{
      if(!startDate){
        setErrorVisible(true);
        setWaiting(false)
      }
      else{
        await intpaF1DeadlineChange(initiativeId, formId, startDate.toISOString());
        setWaiting(false)
        await handleRefreshList();
        onClose();
      }
    }
    catch(error){
      console.log(error)
      setWaiting(false)
    }
  }

  return (
    <>
      <CustomModal
        show={modal.isOpen}
        onHide={onHideModal}
        message={modal.message}
        type={modal.type}
      />
      <Modal show={show} animation={false}>
        <Modal.Body>
          <ModalBodyDiv> 
            <DatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText={t('New_deadline')}
              selected={startDate}
              minDate={getInitialDate(new Date(deadlineDateString))}
              onChange={date => {
                setErrorVisible(false);
                setStartDate(date);
              }}
            />
            {errorVisible && <FormFielErrorMessage>{t('Valid_date')}</FormFielErrorMessage>}
            <div style={{marginTop: '20px'}}>
              {t('Modify_deadline_helptext')}
            </div>
          </ModalBodyDiv>
        </Modal.Body>
        <CenteredModalButtonContainer style={{marginBottom: '10px'}}>
          <ErrorButton style={{marginRight: '20px'}} onClick={onClose} variant="secondary">{t('Button_cancel')}</ErrorButton>
          <PrimaryButton onClick={() => {onSave()}} variant="primary" disabled={waiting}>{t('Button_save')}</PrimaryButton>
        </CenteredModalButtonContainer>
      </Modal>
    </>
  )
}

export default DeadlineChangeModal;
