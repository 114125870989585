import React from 'react';
import { getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RequiredMark } from 'components/CommonStyled';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';


const fieldInfo = (formikInstance, field) => {
  const getFieldError = getIn(formikInstance.errors, field);
  const getFieldTouched = getIn(formikInstance.touched, field);
  return {
    touched: getFieldTouched,
    error: (
      <FormErrorMessage
        touched={getFieldTouched}
        errors={getFieldError}
      />
    )
  }
}
  
const ParticipantForm = ({ formikInstance, participantIndex, entityTypes, countries, nonEditable }) => {

  const {t} = useTranslation();
  const { getFieldProps, touched, errors} = formikInstance;
  const fieldPath = (field) => `participantList.${participantIndex}.${field}`;

  return (
    <>
      <Row>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F1_S2_First_name')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                type="text"
                name={fieldPath('firstName')}
                { ...getFieldProps(fieldPath('firstName')) }
                className={touched[fieldPath('firstName')] && errors[fieldPath('firstName')] ? "error" : null}
                disabled={nonEditable}
              />
              {fieldInfo(formikInstance, fieldPath('firstName')).error}
            </Col>
          </Form.Group>  
        </Col>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F1_S2_Last_Name')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                type="text"
                name={fieldPath('lastName')}
                { ...getFieldProps(fieldPath('lastName')) }
                className={touched[fieldPath('lastName')] && errors[fieldPath('lastName')] ? "error" : null}
                disabled={nonEditable}
              />
              {fieldInfo(formikInstance, fieldPath('lastName')).error}
            </Col>
          </Form.Group>  
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F3_S7_Participant_nationality')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                name={fieldPath('nationalityId')}
                as="select"
                { ...getFieldProps(fieldPath('nationalityId')) }
                className={touched[fieldPath('nationalityId')] && errors[fieldPath('nationalityId')] ? "error" : null}
                disabled={nonEditable}
              >
                <option value="">{t('Placeholder_select')}</option>
                {countries.map(media => (
                  <option key={media.id} value={media.id}>{t(media.name)}</option>
                ))}
              </Form.Control>
              {fieldInfo(formikInstance, fieldPath('nationalityId')).error}
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F3_S7_Participant_city_departure')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                type="text"
                name={fieldPath('city')}
                { ...getFieldProps(fieldPath('city')) }
                className={touched[fieldPath('city')] && errors[fieldPath('city')] ? "error" : null}
                disabled={nonEditable}
              />
              {fieldInfo(formikInstance, fieldPath('city')).error}
            </Col>
          </Form.Group>  
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F3_S7_Participant_organization')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                type="text"
                name={fieldPath('organizationName')}
                { ...getFieldProps(fieldPath('organizationName')) }
                className={touched[fieldPath('organizationName')] && errors[fieldPath('organizationName')] ? "error" : null}
                disabled={nonEditable}
              />
              {fieldInfo(formikInstance, fieldPath('organizationName')).error}
            </Col>
          </Form.Group>  
        </Col>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F3_S7_Participant_type_entity')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                name={fieldPath('entityTypeId')}
                as="select"
                { ...getFieldProps(fieldPath('entityTypeId')) }
                className={touched[fieldPath('entityTypeId')] && errors[fieldPath('entityTypeId')] ? "error" : null}
                disabled={nonEditable}
              >
                <option value="">{t('Placeholder_select')}</option>
                {entityTypes.map(media => (
                  <option key={media.id} value={media.id}>{t(`entity_type_${media.id}`)}</option>
                ))}
              </Form.Control>
              {fieldInfo(formikInstance, fieldPath('entityTypeId')).error}
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F1_S2_Email')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                type="text"
                name={fieldPath('email')}
                { ...getFieldProps(fieldPath('email')) }
                className={touched[fieldPath('email')] && errors[fieldPath('email')] ? "error" : null}
                disabled={nonEditable}
              />
              {fieldInfo(formikInstance, fieldPath('email')).error}
            </Col>
          </Form.Group>  
        </Col>
        <Col>
          <Form.Group as={Row}>
            <Form.Label column lg={4}>{t('F1_S2_Phone_number')}<RequiredMark>*</RequiredMark>:</Form.Label>
            <Col lg={8}>
              <Form.Control
                type="text"
                name={fieldPath('phone')}
                { ...getFieldProps(fieldPath('phone')) }
                className={touched[fieldPath('phone')] && errors[fieldPath('phone')] ? "error" : null}
                disabled={nonEditable}
              />
              {fieldInfo(formikInstance, fieldPath('phone')).error}
            </Col>
          </Form.Group>  
        </Col>
      </Row>

    </>
  )

}

export default ParticipantForm;
