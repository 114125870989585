import React from 'react';
import { useTranslation } from 'react-i18next';
import htmlParser from 'html-react-parser';

import { StyledS7SubsectionTitle } from '../../CommonStyles';
import { SeparatorDotted } from 'components/CommonStyled';
import FakeAccordionItem from '../../../FakeAccordionItem';
import ParticipantForm from 'components/Forms/Form4/Section7/ParticipantsForm/ParticipantForm';
import { DisclaimerDiv } from 'components/Forms/Form4/Section7/ParticipantsForm/ParticipantsForm.styled';

const Participants = ({ activity }) => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledS7SubsectionTitle> {t('F3_S7_Subtitle_Participants_assistance')} </StyledS7SubsectionTitle>
      {(activity && activity.participantList && activity.participantList.map((participant, key) => (
        <React.Fragment key={key}>
          <FakeAccordionItem title={t('F3_S7_Accordion_Participant')+" "+(key+1)} noMargin>
            <ParticipantForm data={participant} />  
          </FakeAccordionItem>
          <SeparatorDotted />
        </React.Fragment>
      )))}

      <DisclaimerDiv>
        {htmlParser(t('F3_S7_Participant_disclaimer'))}
      </DisclaimerDiv>

    </div>
  )
}

export default Participants;