import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Col2,
  FullRow,
  StyledFormGroup2,
  StyledFormLabel,
} from '../../CommonStyled/Components.styled'
import { getExPostF8BSummaryExportValues } from '../helpers/helpers'
import { ExportStatisticsF8BSummaryToXLSX } from 'helpers/xlsxStatisticsExporter'
import ExportToXLSXButton from 'components/ExportToXLSXButton'
import { Form } from 'react-bootstrap'
import { renderInitiativeOptions3 } from 'pages/statistics/CommonHelpers/helpers'
import { PrimaryButton } from 'components/Buttons'
import { getImplementationInitiatives } from 'services/initiative'
import { getExpostInitiativeLink } from '../constants'

const Header1 = ({ data, waiting, getData, appliedFilters }) => {
  const { t, i18n } = useTranslation()

  const [initiativesOptions, setInitiativesOptions] = useState([])
  const [initiativesFilter, setInitiativesFilter] = useState('')

  const handleFilter = () => {
    let param = {}
    if (initiativesFilter && initiativesFilter != '')
      param.initiativeId = initiativesFilter
    getData(param)
  }

  const getInitiativesOptions = async () => {
    try {
      const response = await getImplementationInitiatives()
      setInitiativesOptions(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getInitiativesOptions()
  }, [])

  const selectedInitiative = useMemo(() => {
    return initiativesOptions?.find(item => String(item.id) === String(appliedFilters?.initiativeId));
  }, [appliedFilters?.initiativeId])

  const renderCurrentInitiative = () => {
    const currentLang = i18n.language;
    const link = getExpostInitiativeLink(currentLang, selectedInitiative?.code)
    return (
      <FullRow style={{ alignItems: 'center', marginLeft: '20px', marginBottom: '16px'}}>
        <span style={{ color: 'gray', textDecoration: 'underline' }}>
          <a href={link} style={{ textDecoration: 'none', color: 'inherit'}} target="_blank">
            {`${selectedInitiative?.code} - ${selectedInitiative?.name}`}
          </a>
        </span>
      </FullRow>
    )
  }

  return (
    <>
      <FullRow style={{ alignItems: 'center', marginLeft: '20px'}}>
        <StyledFormGroup2 style={{ width: '60%' }}>
          <StyledFormLabel>{t('Initiative')}</StyledFormLabel>
          <Form.Control
            name="initiativeId"
            as="select"
            onChange={(e) => {
              setInitiativesFilter(e.target.value)
            }}
            value={initiativesFilter}
          >
            {renderInitiativeOptions3(initiativesOptions)}
          </Form.Control>
        </StyledFormGroup2>

        <PrimaryButton
          onClick={() => {
            handleFilter()
          }}
          disabled={waiting || !initiativesFilter }
        >
          {t('Button_search')}
        </PrimaryButton>

        <Col2>
          <ExportToXLSXButton
            data={data}
            mapData={getExPostF8BSummaryExportValues}
            disabled={waiting || !initiativesFilter || !data}
            exportName={t('Statistics_expost_tab2')}
          >
              <ExportStatisticsF8BSummaryToXLSX
                label={t('Statistics_expost_tab2')}
                data={[]}
              />
          </ExportToXLSXButton>
        </Col2>
      </FullRow>

      {selectedInitiative ? (
        renderCurrentInitiative()
      ) : null}
    </>
  )
}

export default Header1
