import React from "react";
import { StyledCustomLink, StyledUploadButton } from 'components/Forms/Forms.styled';
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

const FileUploader = ({ file, handleFileUpload, handleDownload, isUploadingFile, uploadLabel, nonEditable }) => {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ visibility: isUploadingFile ? "visible" : "hidden" }}>
        <Spinner animation="border" />
        <span> {t('file_uploader_uploading_doc')} </span>
      </div>

      <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
        <StyledUploadButton
          variant="link"
          disabled={nonEditable}
          onClick={(e) => {
            document.getElementById("fileInput").click();
          }}
        >
          {uploadLabel}
        </StyledUploadButton>

        <span style={{ visibility: file ? "visible" : "hidden", marginLeft: '5px'}}>
          ({t('file_uploader_uploaded_doc')+" "}
          <StyledCustomLink
            onClick={() =>
              handleDownload(file.uuid, file.fileName)
            }
          >
            {file ? file.fileName : null}
          </StyledCustomLink>
          )
        </span>

        <Form.File
          id="fileInput"
          style={{ visibility: "hidden" }}
          onChange={(e) => {
            handleFileUpload(e);
          }}
          type="file"
        />
      </div>
    </>
  );
};

export default FileUploader;
