/* eslint-disable no-useless-catch */
import axiosInstance from './axiosInstance'

export const getFunds = async (filters) => {
  const url = `/api/funds`
  try {
    const response = await axiosInstance.get(url, { params: filters })
    return response?.data
  } catch (error) {
    throw error
  }
}

export const saveFundSection = async (initiative, section, data) => {
  const url = `/api/funds/${initiative}/section/${section}`
  try {
    const response = await axiosInstance.put(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFundSection = async (initiative, section) => {
  const url = `/api/funds/${initiative}/section/${section}`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getFundsConstants = async () => {
  const url = `/api/constants/fund`
  try {
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    throw error
  }
}

export const addFund = async () => {
  const url = `/api/funds/`
  try {
    const response = await axiosInstance.post(url, {})
    return response.data
  } catch (error) {
    throw error
  }
}

export const setFundState = async (fundId, state) => {
  const url = `/api/funds/${fundId}/state`
  try {
    const response = await axiosInstance.patch(url, { state })
    return response.data
  } catch (error) {
    throw error
  }
}

export const addFundGuest = async (fundId, data) => {
  const url = `/api/funds/${fundId}/guest/`
  try {
    const response = await axiosInstance.post(url, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export const removeFundGuest = async (fundId, userFundRoleId) => {
  const url = `/api/funds/${fundId}/guest/${userFundRoleId}`
  try {
    const response = await axiosInstance.delete(url)
    return response.data
  } catch (error) {
    throw error
  }
}