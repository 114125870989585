import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateDDMMYY } from 'helpers/dateHelpers';

import FakeAccordionItem from '../../FakeAccordionItem';
import { RequiredMark, SeparatorDotted } from 'components/CommonStyled';
import TextValue from 'components/TextValue';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { StyledTitle } from '../CommonStyles';

const Section7Products = ({ activity, data }) => {
  const {t} = useTranslation();
  const [formValues, setFormValues] = useState({ productList: []});

  useEffect(() => {
    if (data) {
      setFormValues(data);
    }
  }, [data]);

  return (
    <div style={{marginLeft: '15px'}}>
      <StyledTitle style={{marginBottom: '30px'}}> {t('F1_S7_Accordion')} - {activity ? activity.name : ''} - {t('F3_S7_Subtitle_Products')} </StyledTitle>

      {(formValues && formValues.productList && formValues.productList.map((product, key) => (
        <React.Fragment  key={key}>
          <FakeAccordionItem title={t('F3_S7_Accordion_product')+" "+(key+1)} >
            <div style={{marginLeft: '50px'}}>
              <Form.Group as={Row}>
                <Form.Label column xs={2}>{t('F3_S7_Product_type')}:</Form.Label>
                <TextValue value={product.productType ? t(`product_type_${product.productType.id}`): '-'} />
              </Form.Group>  

              <Form.Group as={Row}>
                <Form.Label column xs={2}>{t('F3_S7_Product_title')}:</Form.Label>
                <TextValue value={product.name} />
              </Form.Group>  

              <Form.Group>
                <Form.Label style={{marginRight: '50px'}}>{t('F3_S7_Product_Due_date')}:</Form.Label>
                <TextValue value={product.dueDate ? formatDateDDMMYY(product.dueDate): '-'} />
              </Form.Group>

              <Form.Group>
                <Form.Label style={{textAlign: 'justify', lineHeight: '1.3'}}>{t('F3_S7_Product_Description')}:</Form.Label>
                <TextValue value={product.description} />
              </Form.Group>  

              <span style={{visibility: (product.media? "visible":"hidden")}}> 
                (Document already uploaded, 
                  {product.media? product.media.fileName: null}
                ) 
              </span>
                
              <Form.Group>
                <Form.Label lg={4} style={{ alignItems: "center" }} >{t('F5_S7_Products_document_comments')} <RequiredMark style={{ marginLeft: "7px" }}>*</RequiredMark>:
                </Form.Label>
                <TextValue value={product.comments} />
              </Form.Group>
            </div>
          </FakeAccordionItem>
          <SeparatorDotted />
        </React.Fragment>
      )))}
    </div>
  )

}

export default Section7Products;
